import { sanitizeUrl } from '@braintree/sanitize-url';
import dompurify from 'dompurify';
import linkifyHtml from 'linkifyjs/html';
// import linkifyHashtag from 'linkifyjs/plugins/hashtag';
// import linkifyMention from 'linkifyjs/plugins/mention';
// import linkifyTicket from 'linkifyjs/plugins/ticket';
import linkifyString from 'linkifyjs/string';

import eventBus from '@/eventBus';

// linkifyMention(linkifyHtml);
// linkifyHashtag(linkifyHtml);
// linkifyTicket(linkifyHtml);
// linkifyMention(linkifyString);
// linkifyHashtag(linkifyString);
// linkifyTicket(linkifyString);

const linkifyOptions = {
  attributes: { rel: 'noopener' },
  className: 'linkified open-external',
  target: {
    url: '_blank',
  },
  validate: true,
};

/**
 * @param event String
 * @param instantResolve
 * @param callback
 * @param maxWait
 * @returns {Promise<any>}
 */
export const waitForEvent = (event, instantResolve = false, callback = false, maxWait = 0) => {
  return new Promise((resolve, reject) => {
    if (instantResolve) {
      resolve();
      return;
    }
    const eventCallback = (data) => {
      if (!callback) {
        resolve(data);
      } else {
        if (callback(data)) {
          resolve(data);
        }
      }
    };

    eventBus.$once(event, eventCallback);

    if (maxWait) {
      setTimeout(() => {
        eventBus.$off(event, eventCallback);
        reject('maxwait');
      }, maxWait);
    }
  });
};

/**
 * @param {DataTransfer} dataTransfer
 * @returns {boolean}
 */
window.isDragSourceExternalFile = function (dataTransfer) {
  // Source detection for Safari v5.1.7 on Windows.
  if (typeof Clipboard != 'undefined') {
    if (dataTransfer.constructor == Clipboard) {
      if (dataTransfer.files.length > 0) return true;
      else return false;
    }
  }

  const DragDataType = dataTransfer.types;
  // Source detection for Firefox on Windows.
  if (typeof DOMStringList != 'undefined') {
    if (DragDataType.constructor == DOMStringList) {
      if (DragDataType.contains('Files')) return true;
      else return false;
    }
  }

  // Source detection for Chrome on Windows.
  if (typeof Array != 'undefined') {
    if (DragDataType.constructor == Array) {
      if (DragDataType.indexOf('Files') != -1) return true;
      else return false;
    }
  }
};

window.cumulativeElOffset = function (elem) {
  var box = elem.getBoundingClientRect();

  var body = document.body;
  var docEl = document.documentElement;

  var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  var clientTop = docEl.clientTop || body.clientTop || 0;
  var clientLeft = docEl.clientLeft || body.clientLeft || 0;

  var top = box.top + scrollTop - clientTop;
  var left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
};

window.isIe11 = function () {
  return !!window.MSInputMethodContext && !!document.documentMode;
};

/**
 * @returns {boolean}
 */
window.is_mobile_device = function () {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.isLoadedFromApp == true;
};

/**
 * @returns {boolean}
 */
window.isIphoneDevice = function () {
  return /iPhone/i.test(navigator.userAgent);
};

window.isInt = function (int) {
  return typeof int === 'number';
};

/**
 * @param html
 * @returns {*}
 */
window.htmlEntityDecode = function (html) {
  var elem = document.createElement('textarea');
  elem.innerHTML = html;
  return elem.value;
};

/**
 * @param string
 * @returns {string}
 */
window.capitalizeFirstLetter = function (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * @param url
 */
window.vue_redirect = function (url) {
  window.router.push(url);
};

window.escapeRegex = function (string) {
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
};

/**
 * Removes javascript URLs.
 */
window.safeUrl = sanitizeUrl;

/**
 * Strips double quotes, please don't use single quotes for your attribute.
 * @param text
 * @returns {void | string}
 */
window.stripHtmlAttribute = function (text) {
  if (!text) {
    return '';
  }

  return text.replace('"', '');
};

/**
 * removes html-tags (like strip_tags + html_entities_decode combined)
 *
 * @param html
 * @returns {string}
 */
window.stripHtml = function (html) {
  let dom = new DOMParser().parseFromString(html, 'text/html');
  return dom.documentElement.innerText;
};

window.sanitizeHtml = dompurify.sanitize;
window.lokaliseHTMLSanitizer = (text) =>
  dompurify.sanitize(text, { ADD_ATTR: ['target', 'rel', 'type', 'referrerpolicy', 'hreflang'] });

/**
 * TODO: Add mentions/tickets/groups: https://soapbox.github.io/linkifyjs/docs/plugin-mention.html
 *
 * @param html
 * @param options
 * @param extraClasses
 * @returns {string}
 */
window.linkifyHtml = function (html, options = {}, extraClasses = []) {
  let doc = new DOMParser().parseFromString(html, 'text/html');

  // replace existing external URLs
  doc.documentElement.querySelectorAll('a').forEach((a) => {
    a.setAttribute('href', window.safeUrl(a.getAttribute('href') || ''));

    if (!a.getAttribute('href').startsWith('/')) {
      a.setAttribute('rel', (a.getAttribute('rel') || '') + ' noopener');
      a.setAttribute('target', '_blank');
      a.classList.add('linkified');
      a.classList.add('open-external');
    }
    if (extraClasses.length) {
      a.classList.add(...extraClasses);
    }
  });

  return linkifyHtml(doc.body.innerHTML, { ...linkifyOptions, ...options });
};

/**
 * TODO: Add mentions/tickets/groups: https://soapbox.github.io/linkifyjs/docs/plugin-mention.html
 *
 * @param str
 * @param options
 * @returns {string}
 */
window.linkifyString = function (str, options = {}) {
  return linkifyString(str, { ...linkifyOptions, ...options });
};

/**
 * @param {number} $bytes
 * @returns {*}
 */
window.formatBytes = function ($bytes) {
  if ($bytes >= 1073741824) {
    $bytes = ($bytes / 10737418242).toFixed(2) + ' GB';
  } else if ($bytes >= 1048576) {
    $bytes = ($bytes / 1048576).toFixed(2) + ' MB';
  } else if ($bytes >= 1024) {
    $bytes = ($bytes / 1024).toFixed(2) + ' kB';
  } else if ($bytes > 1) {
    $bytes = $bytes + ' bytes';
  } else if ($bytes == 1) {
    $bytes = $bytes + ' byte';
  } else {
    $bytes = '0 bytes';
  }

  return $bytes;
};

window.toHHMMSS = function (sec_num, format = 'HHMMSS') {
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  if (format === 'MMSS') {
    return minutes + ':' + seconds;
  } else if (format === 'HHMMSS') {
    return hours + ':' + minutes + ':' + seconds;
  }
};

// TODO replace with an imported function @/util/helpers/copyToClipboard
window.CopyToClipboard = function (containerid) {
  this.SelectText(containerid);
  document.execCommand('copy');
};

// TODO replace with an imported function @/util/helpers/copyToClipboard
window.copyToClipboard = function (text) {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text);
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    var textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.error('Copy to clipboard failed.', ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
};

window.SelectText = function (element) {
  var doc = document,
    text = doc.getElementById(element),
    range,
    selection;
  if (doc.body.createTextRange) {
    range = document.body.createTextRange();
    range.moveToElementText(text);
    range.select();
  } else if (window.getSelection) {
    selection = window.getSelection();
    range = document.createRange();
    range.selectNodeContents(text);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};
window.getSelectedText = function () {
  var text = '';
  if (typeof window.getSelection != 'undefined') {
    text = window.getSelection().toString();
  } else if (typeof document.selection != 'undefined' && document.selection.type == 'Text') {
    text = document.selection.createRange().text;
  }
  return text;
};

window.replaceSelection = function () {
  if (window.getSelection) {
    return function (content) {
      var range,
        sel = window.getSelection();
      var node = typeof content === 'string' ? document.createTextNode(content) : content;
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();
        //range.insertNode(document.createTextNode(''));
        range.insertNode(node);
        range.setStart(node, 0);

        window.setTimeout(function () {
          range = document.createRange();
          range.setStartAfter(node);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }, 0);
      }
    };
  } else if (document.selection && document.selection.createRange) {
    return function (content) {
      var range = document.selection.createRange();
      if (typeof content === 'string') {
        range.text = content;
      } else {
        range.pasteHTML(content.outerHTML);
      }
    };
  }
};

window.insertAtCursor = function (myField, myValue) {
  //IE support
  if (document.selection) {
    myField.focus();
    const sel = document.selection.createRange();
    sel.text = myValue;
  }
  //MOZILLA and others
  else if (myField.selectionStart || myField.selectionStart == '0') {
    var startPos = myField.selectionStart;
    var endPos = myField.selectionEnd;
    myField.innerText =
      myField.innerText.substring(0, startPos) +
      myValue +
      myField.innerText.substring(endPos, myField.innerText.length);
  } else {
    myField.innerText += myValue;
  }
};

window.placeCaretAtEnd = function (el) {
  el.focus();
  if (typeof window.getSelection != 'undefined' && typeof document.createRange != 'undefined') {
    var range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange != 'undefined') {
    var textRange = document.body.createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(false);
    textRange.select();
  }
};

window.getExtFromFileName = function (file_name) {
  return file_name.split('.').pop();
};

window.isChrome = function () {
  return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
};

window.isIeOrEdge = function () {
  let userAgent = userAgent || navigator.userAgent;
  return userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1 || userAgent.indexOf('Edge/') > -1;
};

// Fixme: this does not work if one of the two objects is parsed from PHP's json_encode(), because the key order changes. JS objects are unsortable.
window.isEqualObject = function (a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
};

/**
 * @param uri
 * @param key
 * @param value
 * @returns {*}
 */
window.updateQueryStringParameter = function (uri, key, value) {
  var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  var separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return uri + separator + key + '=' + value;
  }
};

window.timeToString = function (time) {
  var d = new Date(time);
  return (
    d.getFullYear() +
    '-' +
    ('0' + (d.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + d.getDay()).slice(-2) +
    ' ' +
    ('0' + d.getHours()).slice(-2) +
    ':' +
    ('0' + d.getMinutes()).slice(-2)
  );
};

/**
 * WARNING: Make sure secure = true when using an external URL. Also make sure URL is safe by using Vue.safeUrl().
 * @param url
 * @param target
 * @param w
 * @param h
 * @param secure must be set for external URLs. Set to false if you want to control the window.
 * @returns {Window}
 */
window.PopupCenter = function (url, target = '_blank', w = null, h = null, secure = true) {
  if (window.isLoadedFromApp) {
    parent.postMessage('app-browse-url:' + encodeURI(url), '*');
    return null;
  }

  if (window.is_mobile_device()) {
    return window.open(url);
  }

  if (w === null) {
    w = (window.screen.availWidth * 80) / 100;
  }

  if (h === null) {
    h = (window.screen.availHeight * 80) / 100;
  }

  // Fixes dual-screen position                         Most browsers      Firefox
  let dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  let dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  let width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
  let height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

  let left = width / 2 - w / 2 + dualScreenLeft;
  let top = height / 2 - h / 2 + dualScreenTop;

  return window.open(
    url,
    target,
    'width=' + w + ',height=' + h + ',top=' + top + ', left=' + left + (secure ? ',noopener,noreferrer' : ''),
  );
};

window.isSafariDesktop = function () {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent) && !window.is_mobile_device();
};

String.prototype.replaceAll = function (search, replacement) {
  // eslint-disable-next-line
  var target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};

let call = {};
window.axiosRequestToken = function (requestType) {
  if (call[requestType]) {
    call[requestType].cancel('');
  }
  call[requestType] = axios.CancelToken.source();
  return call[requestType].token;
};
