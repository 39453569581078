<template>
  <div data-test="onboarding-slides-navigation" class="navigation align-center flex items-center justify-center">
    <atomic-button
      data-test="onboarding-slides-prev-button"
      class="md navigation-buttons secondary mr-1 flex w-10 content-center items-center justify-center bg-white"
      tabindex="1"
      :disabled="this.isFirst"
      btn-style="secondary"
      @click="$emit('prev')"
    >
      <arrow-left-linear :size="20" class="arrow-left" />
    </atomic-button>
    <t-navigation-dots v-if="showNavigator" :active="active === 0" />
    <t-navigation-dots v-if="showNavigator" :active="active === 1" />
    <t-navigation-dots v-if="showNavigator" :active="active === 2" />
    <atomic-button
      tabindex="2"
      data-test="onboarding-slides-next-button"
      class="md navigation-buttons primary ml-1 flex w-10 content-center items-center justify-center"
      :class="[active === 2 ? 'less-rounded-button' : 'circle-button']"
      btn-style="primary"
      @click="$emit('next')"
    >
      <span v-if="active === 2" class="fade-in">Let’s go!</span>
      <arrow-right-linear v-else :size="20" />
    </atomic-button>
  </div>
</template>

<script>
import { ArrowLeftLinear, ArrowRightLinear } from '@trengo/trengo-icons';

import breakpoints from '@/util/breakpoints';

export default {
  name: 'Navigation',
  emits: ['next', 'prev'],
  components: { ArrowRightLinear, ArrowLeftLinear },
  props: {
    active: {
      type: Number,
      default: 0,
    },
    isFirst: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      breakpoints,
    };
  },
  computed: {
    showNavigator() {
      return (this.isMobile && this.active < 2) || this.isDesktop;
    },
    isMobile() {
      const { comparisons } = this.breakpoints;
      return comparisons.eq_xs;
    },
    isDesktop() {
      const { comparisons } = this.breakpoints;
      return comparisons.eq_md || comparisons.eq_lg || comparisons.eq_xl;
    },
  },
};
</script>

<style lang="scss" scoped src="./Navigation.scss" />
