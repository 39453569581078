<script lang="ts">
import { includes, map } from 'lodash';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import { PERMISSION } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useUserStore } from '@/store/pinia';

import Dropdown from './ReplyForm/Dropdown';

export default defineComponent({
  emits: ['addLabel', 'deleteLabel', 'dropdown-open'],
  data: function () {
    return {
      channelLabels: [{ name: 'loading...' }],
      labelsLoaded: false,
      resultCount: 1,
      more: true,
      term: '',
      colors: [
        '#5D9CEC',
        '#4FC1E9',
        '#48CFAD',
        '#A0D468',
        '#FFCE54',
        '#FC6E51',
        '#ED5565',
        '#AC92EC',
        '#EC87C0',
        '#E6E9ED',
        '#AAB2BD',
        '#434A54',
      ],
      PERMISSION,
    };
  },

  components: {
    Dropdown,
  },

  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    selection: {
      type: Array,
      default: () => [],
    },
    ticket: {
      type: Object,
      default: () => ({}),
    },
    wrap: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapStores(useUserStore),
    chunkedLabels() {
      const chunkSize = this.more ? 999 : 1;
      return this.ticket.labels.slice(0, chunkSize);
    },
  },

  mounted: function () {
    if (this.$root.mobileDevice) {
      this.more = false;
    }
  },

  methods: {
    addLabel(label) {
      this.ticket.labels.push(label);
      this.$emit('addLabel', label);
      eventBus.$emit('ticket-label-added');
    },

    deleteLabel(label) {
      this.$emit('deleteLabel', label);
      this.ticket.labels.splice(this.ticket.labels.indexOf(label), 1);
    },
    getLabels() {
      axios
        .get('/api/v2/labels?channel=' + this.ticket.channel.id)
        .then((r) => {
          this.labelsLoaded = true;
          this.channelLabels = r.data.data.filter((label) => {
            return includes(map(this.selection, 'id', label.id)) === false;
          });
        })
        .catch((e) => {
          console.log('Error getting labels', e);
        });
    },
    dropdownOpen() {
      this.$emit('dropdown-open');
      if (!this.labelsLoaded) {
        this.getLabels();
      }
    },
    filterLabel(label) {
      eventBus.$emit('tickets.filters.apply', 'labels', label.id);
    },

    setTerm(val) {
      this.term = val;
    },
    setResultLength(val) {
      this.resultCount = val;
    },
    selectedAddLabel(label) {
      if (this.resultCount > 0) {
        this.addLabel(label);
      }
    },
    createSearchTermLabel(e) {
      if (this.userStore.hasPermission(PERMISSION.SETTINGS__LABELS__MANAGE) && this.term !== '') {
        const label = {
          name: this.term,
          color: this.colors[Math.floor(Math.random() * this.colors.length)],
        };
        axios
          .post('/api/v2/labels', label)
          .then((res) => {
            this.channelLabels.push(res.data);
            this.resultCount++;

            // if its not a mouse event
            if (!e?.target) {
              this.addLabel(res.data);
            }

            this.term = '';
          })
          .catch((e) => {
            console.log('Error creating label', e);
          });
      }
    },
  },
});
</script>

<template>
  <div class="flex" :class="{ 'labels-wrapper': ticket.labels.length === 0 }">
    <div class="labels-inner-wrapper flex" :class="{ 'flex-wrap': more }">
      <span v-for="(label, i) in chunkedLabels" :key="label.id" class="mb-2 mr-2 lg:mb-0 lg:mt-2">
        <span
          class="label default _400 select-none text-sm"
          :style="{ background: 'white', border: '2px solid ' + label.color, color: label.color }"
          style="display: flex"
        >
          <span class="label-description inline-block cursor-pointer truncate lg:inline" @click="filterLabel(label)">
            {{ label.name }}
          </span>
          <a><i class="fa fa-fw fa-close select-none" @click="deleteLabel(label)"></i></a>
        </span>
      </span>
      <span
        v-if="$root.mobileDevice"
        v-show="ticket.labels && ticket.labels.length > 1"
        class="
          mr-2
          flex
          cursor-pointer
          select-none
          items-center
          border-2 border-grey-200
          px-1
          text-xs
          font-bold
          leading-none
        "
        style="border-radius: 5px; height: 23px"
        @click="more = !more"
      >
        <span v-text="more ? '-' : '+'"></span>
        {{ ticket.labels.length - 1 }}
      </span>
    </div>

    <div>
      <dropdown
        :options="channelLabels"
        :create-on-no-results="true"
        label="name"
        max-height="370px"
        @open="dropdownOpen"
        @selected="selectedAddLabel"
        @search="setTerm"
        @inputEnter="createSearchTermLabel"
        @count="setResultLength"
      >
        <template #heading>
          <div class="_500 pt-4 text-center text-base leading-none text-black">
            {{ $t('tickets.label_ticket') }}
          </div>
        </template>
        <template #toggle>
          <i
            class="
              material-icons
              selector-conversation-add-label
              cursor-pointer
              select-none
              text-grey-600
              hover:brightness-90
              lg:mt-2
            "
            role="button"
          >
            loyalty
          </i>
        </template>
        <template #option="prop">
          <template v-if="labelsLoaded">
            <i class="fa fa-dot-circle-o" :style="{ color: prop.option.color }"></i>
            <span class="ml-2">{{ prop.option.name }}</span>
          </template>
          <template v-else>
            <div class="w-100 loader-inner ball-scale-ripple text-center">
              <div class="m-x-auto"></div>
            </div>
          </template>
        </template>
        <template v-if="userStore.hasPermission(PERMISSION.SETTINGS__LABELS__MANAGE) && term" #no-results>
          <div class="pointer border-t-2 border-grey-200 p-4 text-grey-600" @click="createSearchTermLabel">
            {{ $t('tickets.label_create') }} "
            <span class="label default _400 select-none text-sm" style="background: #14b29f; color: #fff">
              {{ term }}
            </span>
            "
          </div>
        </template>
      </dropdown>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.labels-inner-wrapper {
  padding-bottom: 21px;
}
.labels-wrapper {
  margin-bottom: 6px;
}
@media (max-width: 1800px) {
  .label-description {
    max-width: 280px;
  }
}
@media (max-width: 1750px) {
  .label-description {
    max-width: 230px;
  }
}
@media (max-width: 1675px) {
  .label-description {
    max-width: 175px;
  }
}
@media (max-width: 1430px) {
  .label-description {
    max-width: 150px;
  }
}
@media (max-width: 1380px) {
  .label-description {
    max-width: 120px;
  }
}
@media (max-width: 1350px) {
  .label-description {
    max-width: 105px;
  }
}
@media (max-width: 1330px) {
  .label-description {
    max-width: 90px;
  }
}
@media (max-width: 991px) {
  .labels-inner-wrapper {
    padding-bottom: 0;
  }
}
@media (max-width: 414px) {
  .label-description {
    max-width: 140px;
  }
}
@media (max-width: 360px) {
  .label-description {
    max-width: 106px;
  }
}
</style>
