<template>
  <div v-if="loaded" class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis" v-text="record.title"></span>
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <div class="p-a p-b-0 container">
            <div class="p-a">
              <form @submit.prevent="save">
                <div class="box">
                  <div class="box-header">
                    <h2>{{ record.title }}</h2>
                  </div>
                  <div class="box-divider m-a-0"></div>
                  <div class="box-body">
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('apps_integration.url') }}</label>
                      <div class="col-sm-10">
                        <input v-model="record.url" type="text" autocomplete="off" class="form-control" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('apps_integration.event') }}</label>
                      <div class="col-sm-10">
                        <select v-model="record.type" class="form-control">
                          <optgroup label="Messages">
                            <option value="INBOUND">{{ $t('apps_integration.inbound_message') }}</option>
                            <option value="OUTBOUND">{{ $t('apps_integration.outbound_message') }}</option>
                            <option value="NOTE">{{ $t('apps_integration.internal_message') }}</option>
                          </optgroup>
                          <optgroup label="Ticketing">
                            <option value="TICKET_LABEL_ADDED">
                              {{ $t('apps_integration.label_added_to_ticket') }}
                            </option>
                            <option value="TICKET_LABEL_DELETED">
                              {{ $t('apps_integration.label_removed_from_ticket') }}
                            </option>
                            <option value="TICKET_ASSIGNED">{{ $t('apps_integration.ticket_assigned') }}</option>
                            <option value="TICKET_CLOSED">{{ $t('apps_integration.ticket_closed') }}</option>
                            <option value="TICKET_REOPENED">{{ $t('apps_integration.ticket_reopened') }}</option>
                            <option value="TICKET_MARKED_AS_SPAM">
                              {{ $t('apps_integration.ticket_marked_as_spam') }}
                            </option>
                            <option value="TICKET_UNMARKED_AS_SPAM">
                              {{ $t('apps_integration.ticket_unmarked_as_spam') }}
                            </option>
                          </optgroup>
                          <optgroup label="Voice">
                            <option value="VOICE_CALL_STARTED">{{ $t('apps_integration.voice_call_started') }}</option>
                            <option value="VOICE_CALL_ENDED">{{ $t('apps_integration.voice_call_ended') }}</option>
                            <option value="VOICE_CALL_RECORDED">
                              {{ $t('apps_integration.voice_call_recorded') }}
                            </option>
                            <option value="VOICE_CALL_MISSED">{{ $t('apps_integration.voice_call_missed') }}</option>
                            <option value="VOICE_CALL_ROUTE_NUMBER">
                              {{ $t('apps_integration.voice_call_ivr_route') }}
                            </option>
                          </optgroup>
                        </select>
                      </div>
                    </div>
                    <div v-if="action === 'edit'" class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('apps_integration.signing_secret') }}</label>
                      <div class="col-sm-10">
                        <pre class="p-3">{{ record.signing_secret }}</pre>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="alert alert-info" v-html="$t('apps_integration.check_trengo_documentation_for_webhook')" />

                <div class="box">
                  <div class="box-body flow-root">
                    <button
                      type="submit"
                      :class="{ loader: saving }"
                      class="btn success text-white"
                      :disabled="saving || !ready"
                      v-text="saving ? this.$t('apps_integration.saving') : this.$t('apps_integration.save_changes')"
                    ></button>

                    <button
                      v-if="action === 'edit'"
                      type="button"
                      class="btn white pull-right"
                      @click="deleteWebhook()"
                    >
                      {{ $t('apps_integration.delete_webhook') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

import RouterWarningOnEdit from '../../../mixins/RouterWarningOnEdit';

export default {
  emits: ['call-loadlist-method'],
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },

  mixins: [RouterWarningOnEdit],

  computed: {
    ready() {
      if (this.record.url === '') {
        return;
      }

      return true;
    },

    redirectUrl() {
      if (useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.INTEGRATION_HUB)) {
        return '/admin/integrations/webhooks/';
      }

      return '/admin/developers/webhooks/';
    },
  },

  data() {
    return {
      loaded: false,
      record: {
        title: '',
        url: '',
        type: 'INBOUND',
      },
      saving: false,
    };
  },

  mounted() {
    if (this.action === 'edit') {
      axios.get('/api/v2/webhooks/' + this.$route.params.id).then((result) => {
        this.record = result.data;
        this.initWarning();
      });
    } else {
      this.initWarning();
    }
  },

  methods: {
    save() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      if (this.action === 'create') {
        return this.storeWebhook();
      } else {
        this.updateWebhook();
      }
    },

    storeWebhook() {
      axios
        .post('/api/v2/webhooks', this.record)
        .then((res) => {
          this.saving = false;
          this.initWarning();
          this.$emit('call-loadlist-method');
          this.$nextTick(() => {
            this.$router.push(this.redirectUrl + res.data.id);
          });
          this.flashSuccess(this.$t('apps_integration.the_webhook_has_been_created_successfully'));
        })
        .catch(() => {
          this.saving = false;
        });
    },

    updateWebhook() {
      axios
        .put('/api/v2/webhooks/' + this.record.id, this.record)
        .then((res) => {
          this.saving = false;
          this.initWarning();
          this.$emit('call-loadlist-method');
          this.flashSuccess(this.$t('apps_integration.the_webhook_has_been_updated_successfully'));
        })
        .catch(() => {
          this.saving = false;
        });
    },

    async deleteWebhook() {
      const shouldDeleteWebhook = await this.$tConfirm(
        this.$t('apps_integration.are_you_sure_you_want_to_delete_this_webhook'),
        {
          delete: true,
          title: this.$t('general.are_you_sure'),
        }
      );
      if (shouldDeleteWebhook) {
        this.initWarning();

        axios.delete('/api/v2/webhooks/' + this.record.id).then(() => {
          this.$emit('call-loadlist-method');
          this.$router.push(this.redirectUrl);
          this.flashSuccess(this.$t('apps_integration.the_webhook_has_been_deleted_successfully'));
        });
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.onBeforeRouteLeaveGuard(to, from, next);
  },
  beforeRouteUpdate(to, from, next) {
    this.onBeforeRouteUpdateGuard(to, from, next);
  },
};
</script>
