<template>
  <div>
    <div
      v-if="element.meta.message == '' || !element.meta.message"
      class="rounded-xl p-3 text-grey-600"
      style="background-color: #f7f7f7"
    >
      <div class="px-2">
        {{ $t('flowbot.step_presenter_user_input_click') }}
        <i class="material-icons text-sm">create</i>
        {{ $t('flowbot.step_presenter_user_input_edit_the_question') }}
      </div>
    </div>
    <div v-else class="rounded-xl p-3" style="background-color: #f7f7f7">
      <div class="px-2 text-grey-600" v-html="formatText(element.meta.message)"></div>
      <div v-if="element.meta.custom_field" class="mt-2">
        <div v-if="element.meta.input_type !== 'list'" class="input-group-btn">
          <input
            disabled
            :placeholder="
              element.meta.custom_field
                ? element.meta.custom_field.title
                : element.meta.input_type
                ? element.meta.input_type.charAt(0).toUpperCase() + element.meta.input_type.slice(1)
                : 'Input'
            "
            type="text"
            id="user-input"
            class="form-control rounded"
            style="background-color: white"
          />
          <div class="input-group-prepend">
            <button
              disabled
              type="submit"
              class="btn btn-sm success inline-block-32px h-32 rounded-full"
              style="position: relative; left: 2px"
            >
              <i class="material-icons pos-relative" style="left: -4px">keyboard_arrow_right</i>
            </button>
          </div>
        </div>
        <div v-else style="text-align: center; display: block; width: 100%">
          <div
            v-for="item in element.meta.list"
            class="m-1 inline break-words border border-green pb-2 pl-4 pr-4 pt-2 text-green"
            style="border-radius: 10px; font-size: 1rem; background-color: #fcfcfc; cursor: not-allowed"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
      <div
        v-if="
          ['number', 'url', 'email', 'phone', 'list', 'name'].includes(this.element.meta.input_type) &&
          element.meta.fallback_message
        "
        v-html="
          formatText(element.meta.fallback_message + ' ' + $t('flowbot.step_presenter_user_input_fallback_message'))
        "
        class="font-italic px-2 pt-2 text-grey-600"
      ></div>
    </div>

    <div class="m-t-1 font-italic valign-center w-full rounded border p-2 text-grey-600">
      <i class="material-icons text-md mx-2 text-base">more_horiz</i>
      {{ $t('flowbot.step_presenter_user_input_waiting_for_user_input') }}
    </div>
  </div>
</template>
<script>
import textFormatter from '../mixins/TextFormatter';

export default {
  props: ['element'],

  mixins: [textFormatter],
};
</script>
<style>
.input-group-prepend {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
