<template>
  <span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-stroke">
      <circle
        cx="12"
        cy="12"
        r="10.25"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="svg-stroke"
      />
      <path
        d="M12 1.75V10.2738C12 10.8946 12 11.205 11.8962 11.4693C11.8046 11.7027 11.6559 11.9093 11.4638 12.0704C11.2463 12.2529 10.952 12.3516 10.3634 12.5491L2.54254 15.1732"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="svg-stroke"
      />
    </svg>

    <span class="icon-active">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_9260_95549)">
          <path
            d="M12 1.75V8.54753C12 9.7892 12 10.41 11.7925 10.9386C11.6093 11.4053 11.3119 11.8187 10.9277 12.1409C10.4926 12.5058 9.90405 12.7032 8.72688 13.0982L2.54258 15.1732C2.19505 10.9488 3.6 2.35 12 1.75Z"
            fill="white"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <circle
            cx="12"
            cy="12"
            r="10.25"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 1.75V10.2738C12 10.8946 12 11.205 11.8962 11.4693C11.8046 11.7027 11.6559 11.9093 11.4638 12.0704C11.2463 12.2529 10.952 12.3516 10.3634 12.5491L2.54254 15.1732"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_9260_95549">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  </span>
</template>
