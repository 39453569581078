<script type="text/babel">
export default {
  name: 'Request',
  emits: ['unauthorized'],
  methods: {
    get(plugin, url, data) {
      return new Promise((resolve, error) => {
        url = updateQueryStringParameter(url, 'plugin_id', plugin.id);
        axios
          .get(url, data)
          .then((res) => {
            if (this.authErrorInResponse(res, plugin)) {
              return this.$emit('unauthorized');
            }
            resolve(res);
          })
          .catch((e) => {
            error(e);
          });
      });
    },

    post(plugin, url, data) {
      return new Promise((resolve, error) => {
        url = updateQueryStringParameter(url, 'plugin_id', plugin.id);
        axios
          .post(url, data)
          .then((res) => {
            if (this.authErrorInResponse(res, plugin)) {
              return this.$emit('unauthorized');
            }
            resolve(res);
          })
          .catch((e) => {
            error(e);
          });
      });
    },

    delete(plugin, url, data) {
      return new Promise((resolve, error) => {
        url = updateQueryStringParameter(url, 'plugin_id', plugin.id);
        axios
          .delete(url, data)
          .then((res) => {
            if (this.authErrorInResponse(res, plugin)) {
              return this.$emit('unauthorized');
            }
            resolve(res);
          })
          .catch((e) => {
            error(e);
          });
      });
    },

    put(plugin, url, data) {
      return new Promise((resolve, error) => {
        url = updateQueryStringParameter(url, 'plugin_id', plugin.id);
        axios
          .put(url, data)
          .then((res) => {
            if (this.authErrorInResponse(res, plugin)) {
              return this.$emit('unauthorized');
            }
            resolve(res);
          })
          .catch((e) => {
            error(e);
          });
      });
    },

    authErrorInResponse(res, plugin) {
      if (plugin.type === 'slack') {
        if (
          res.data.error === 'not_authed' ||
          (res.data.channels != null && res.data.channels.error === 'not_authed')
        ) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
