<script lang="ts" setup>
import { ArrowLeftLinear, EyeLinear, UserPlusLinear, ArrowTopRightLinear, InfoLinear } from '@trengo/trengo-icons';
import { computed, onMounted, reactive } from 'vue';
import { useI18n } from 'vue-i18n';

import { PLANS_SEAT_BASED } from '@/api/mocks/chargebee';
import {
  fetchCustomerSubscriptionCheckout,
  postSubscriptionCheckout,
  postSubscriptionCheckoutUpdate,
} from '@/api/modules/subscriptionChargebee';
import ServiceIsDown from '@/components/Subscription/ServiceIsDown.vue';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import { STATUS_CODE } from '@/Configs/Constants/StatusCodes';
import { useSubscriptionStore } from '@/store/pinia';
import { flashError } from '@/util/flashNotification';

import EditPlan from './EditPlan.vue';
import EditSummaryModal from './EditSummaryModal.vue';
import ResultModal from './ResultModal.vue';
import { dateToString } from '../utils';

import type { BillingFrequency, ChargebeeSubscriptionCheckout, PlanId, SubscriptionEditState } from '@/types';

const { reload } = useSubscriptionStore();

type Status = 'upgrade' | 'downgrade';

const STATUS: Record<Status, Status> = {
  upgrade: 'upgrade',
  downgrade: 'downgrade',
};

const initialState: SubscriptionEditState = {
  subscription: null,
  isLoading: true,
  selectedFrequency: '',
  selectedPlan: 'tier0',
  userSeats: 5,
  observerSeats: 5,
  maximumUserSeats: undefined,
  previousUserSeats: 5,
  summary: null,
  nextBillingDate: '',
  modalStatus: { isOpen: false, type: 'summary' },
};

const { t } = useI18n();

const state = reactive<SubscriptionEditState>(initialState);

const hasUserSeatsError = computed(() => state.userSeats < (state.subscription?.user_seats?.min ?? 5));

const hasObserverSeatsError = computed(() => state.observerSeats < (state.subscription?.observer_seats?.min ?? 0));

const changeStatus = computed(() => {
  const isFrequencyUpgraded =
    state.selectedFrequency === 'annually' && state.subscription?.billing_frequency === 'monthly';
  if (isFrequencyUpgraded) return STATUS.upgrade;

  const oldTotalAmount = state.summary?.old.items.reduce((acc: any, currentItem: any) => acc + currentItem.amount, 0);

  if ((state.summary?.new.total ?? 0) > oldTotalAmount) return STATUS.upgrade;

  return STATUS.downgrade;
});

const isContinueEnabled = computed(() => {
  if (hasObserverSeatsError.value || hasUserSeatsError.value) {
    return false;
  }

  return (
    state.observerSeats !== (state.subscription?.observer_seats?.current ?? 0) ||
    state.userSeats !== (state.subscription?.user_seats?.current ?? 0) ||
    state.selectedFrequency !== state.subscription?.billing_frequency ||
    state.selectedPlan !== state.subscription?.plan
  );
});

const userSeatPrice = computed(() => {
  const selectedPlan = PLANS_SEAT_BASED.find((item) => item.type === state.selectedPlan);
  return selectedPlan?.prices.find((item) => item.type === state.selectedFrequency)?.price;
});

const loadSubscription = () => {
  fetchCustomerSubscriptionCheckout()
    .then(({ data }: { data: ChargebeeSubscriptionCheckout }) => {
      state.subscription = data;
      state.selectedFrequency = data.billing_frequency;
      state.selectedPlan = data.plan;
      state.userSeats = Math.max(data.user_seats?.current, 5);
      state.observerSeats = data.observer_seats?.current ?? 0;
      state.previousUserSeats = state.userSeats;
    })
    .finally(() => {
      state.isLoading = false;
    });
};

const handleChangeFrequency = (event: Event) => {
  state.selectedFrequency = event.target?.value;
};

const handlePlanClick = (planId: PlanId) => {
  const plan = PLANS_SEAT_BASED.find((item) => item.type === planId);
  if (!plan || !state.subscription) {
    return;
  }
  state.maximumUserSeats = plan.maximumUsers;

  if (plan.maximumUsers && plan.maximumUsers < state.userSeats) {
    const minUserSeats = Math.max(state.subscription?.user_seats?.current, state.subscription?.user_seats?.min ?? 5);
    state.userSeats = Math.min(minUserSeats, plan.maximumUsers);
  } else if (state.userSeats !== state.previousUserSeats) {
    state.userSeats = state.previousUserSeats;
  }

  state.selectedPlan = planId;
};

const handleContinue = () => {
  postSubscriptionCheckout({
    plan: state.selectedPlan,
    user_seats: state.userSeats,
    observer_seats: state.observerSeats,
    billing_frequency: state.selectedFrequency as BillingFrequency,
  })
    .then(({ data }) => {
      state.summary = {
        old: {
          ...data.old,
          plan: state.subscription?.plan || initialState.selectedPlan,
          billing_frequency:
            state.subscription?.billing_frequency || (initialState.selectedFrequency as BillingFrequency),
        },
        new: { ...data.new, plan: state.selectedPlan, billing_frequency: state.selectedFrequency as BillingFrequency },
      };
      state.modalStatus = { isOpen: true, type: 'summary' };
    })
    .catch((error) => {
      if (error.response.status === STATUS_CODE.GONE) {
        flashError(t('edit_subscription.seat_based_downgrade_is_not_allowed'));
        return;
      }
      console.error(error);
    });
};

const handleConfirm = () => {
  state.modalStatus.isOpen = false;

  postSubscriptionCheckoutUpdate({
    plan: state.selectedPlan,
    user_seats: state.userSeats,
    observer_seats: state.observerSeats,
    billing_frequency: state.selectedFrequency as BillingFrequency,
  })
    .then(({ data }) => {
      const status: Status = changeStatus.value;
      state.nextBillingDate = dateToString(data.subscription.next_billing_at);
      state.modalStatus = { type: status, isOpen: true };
    })
    .catch((error) => {
      console.error(error);
    });
};

const handleCloseModal = () => (state.modalStatus.isOpen = false);

const handleResultClose = async () => {
  await handleCloseModal();
  reload();
  router.push(SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS);
};

onMounted(() => {
  loadSubscription();
});
</script>

<template>
  <div>
    <div class="mb-2 flex justify-center">
      <div class="xs:w-[500px] md:w-[700px] xl:w-[836px]">
        <div v-if="state.isLoading" class="mt-32 flex justify-center">
          <t-spinner />
        </div>
        <service-is-down v-else-if="!state.subscription" />
        <div v-else>
          <div class="flex w-full items-center pb-4 pt-5">
            <t-icon-button size="lg" class="mr-6" @click="$router.push(SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS)">
              <arrow-left-linear />
            </t-icon-button>
            <h4 class="m-0 font-bold text-grey-800">Edit subscription</h4>
          </div>

          <t-inline-banner v-if="state.subscription?.legacy" class="mb-4">
            <template #icon>
              <info-linear />
            </template>
            You are on an old pricing model. If you upgrade or modify your subscription, you automatically agree to the
            pricing as listed below.
          </t-inline-banner>

          <div class="w-full rounded-xl border-1 border-grey-300 bg-white">
            <div class="p-6">
              <div class="flex">
                <div class="flex-grow">
                  <h5 class="font-bold text-grey-800">Your subscription</h5>
                  <div class="t-text-sm mt-2 text-grey-600">You can make changes to your subscription here</div>
                </div>
                <t-button-text href="https://trengo.com/prices" target="_blank">
                  View Plans
                  <arrow-top-right-linear size="1.2rem" class="ml-1" />
                </t-button-text>
              </div>
              <div class="mb-4 mt-4 flex gap-4">
                <div
                  class="
                    w-full
                    cursor-pointer
                    rounded-xl
                    border-1 border-grey-300
                    bg-white
                    p-3
                    shadow-100
                    hover:bg-grey-100
                  "
                  :class="{
                    '!bg-grey-100': state.selectedFrequency === 'monthly',
                    'border-leaf-500': state.selectedFrequency === 'monthly',
                  }"
                >
                  <t-input-radio
                    v-model="state.selectedFrequency"
                    name="frequency"
                    value="monthly"
                    class="mb-0"
                    @change="handleChangeFrequency"
                  >
                    <span class="text-grey-800">Billed Monthly</span>
                  </t-input-radio>
                </div>
                <div
                  :class="{
                    '!bg-grey-100': state.selectedFrequency === 'annually',
                    'border-leaf-500': state.selectedFrequency === 'annually',
                  }"
                  class="
                    flex
                    w-full
                    cursor-pointer
                    justify-between
                    rounded-xl
                    border-1 border-grey-300
                    bg-white
                    p-3
                    shadow-100
                    hover:bg-grey-100
                  "
                >
                  <t-input-radio
                    v-model="state.selectedFrequency"
                    name="frequency"
                    value="annually"
                    class="mb-0 w-full"
                    @change="handleChangeFrequency"
                  >
                    <span class="text-grey-800">Billed Annually</span>
                  </t-input-radio>
                  <t-badge text="Save 20%" variant="upgrade" class="w-24 cursor-default" />
                </div>
              </div>
              <div class="flex gap-4">
                <edit-plan
                  v-for="plan in PLANS_SEAT_BASED"
                  :key="plan.title"
                  :plan="plan"
                  :selected-plan="state.selectedPlan"
                  :selected-frequency="state.selectedFrequency"
                  @click="handlePlanClick"
                />
              </div>
            </div>
            <div class="border-t-1 border-grey-300" />
            <div class="p-6">
              <div class="text-md font-semibold text-grey-800">Seats</div>
              <div class="t-text-sm mb-4 text-grey-600">
                All plans start with a base of 5 seats. You can add extra seats as needed here.
              </div>
              <div class="mb-2 flex items-center gap-3 rounded-xl bg-grey-200 p-3 text-grey-800">
                <t-thumbnail class="bg-white">
                  <user-plus-linear size="1rem" />
                </t-thumbnail>
                <div class="flex-grow">
                  <div class="t-text-sm font-semibold">User seats</div>
                  <div class="t-text-sm text-grey-600">€{{ userSeatPrice }} per seat/month billed monthly</div>
                </div>
                <t-input-stepper
                  v-model="state.userSeats"
                  class="bg-white"
                  :min="5"
                  :max="state.maximumUserSeats ?? 99999"
                  :has-error="hasUserSeatsError"
                  @increment="state.previousUserSeats = state.userSeats"
                  @decrement="state.previousUserSeats = state.userSeats"
                />
              </div>
              <t-error-item
                v-if="hasUserSeatsError"
                class="mb-4"
                danger-icon
                text="Users must first be removed before the amount of user seats can be lowered."
              />
              <div class="mb-2 flex items-center gap-3 rounded-xl bg-grey-200 p-3 text-grey-800">
                <t-thumbnail class="bg-white">
                  <eye-linear size="1rem" />
                </t-thumbnail>
                <div class="flex-grow">
                  <div class="t-text-sm font-semibold">Observer seats</div>
                  <div class="t-text-sm text-grey-600">€3 per seat/month billed monthly</div>
                </div>
                <t-input-stepper
                  v-model="state.observerSeats"
                  class="bg-white"
                  :max="state.subscription?.observer_seats?.max ?? 99999"
                  :has-error="hasObserverSeatsError"
                />
              </div>

              <t-error-item
                v-if="hasObserverSeatsError"
                danger-icon
                text="Observers must first be removed before the amount of observer seats can be lowered."
              />
            </div>
            <div class="border-t-1 border-grey-300" />
            <div class="flex justify-end p-6">
              <t-button :disabled="!isContinueEnabled" size="md" btn-style="primary" @click="handleContinue">
                Continue
              </t-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <edit-summary-modal
      v-if="state.summary && state.selectedFrequency && state.subscription?.billing_frequency"
      :is-open="state.modalStatus.type === 'summary' && state.modalStatus.isOpen"
      :summary="state.summary"
      @cancel="handleCloseModal"
      @confirm="handleConfirm"
    />
    <result-modal
      :is-open="state.modalStatus.type !== 'summary' && state.modalStatus.isOpen"
      :type="state.modalStatus.type"
      @close="handleResultClose"
    />
  </div>
</template>
