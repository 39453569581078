<template>
  <div class="break-words rounded-xl p-3" style="background-color: rgb(247, 247, 247)">
    <div v-if="element.meta.message == '' || !element.meta.message" class="px-2 text-grey-600">
      {{ $t('flowbot.step_presenter_text_click') }}
      <i class="material-icons text-sm">create</i>
      {{ $t('flowbot.step_presenter_text_to_edit_this_text') }}
    </div>
    <div v-else v-html="formatText(element.meta.message)" class="px-2 text-grey-600"></div>
  </div>
</template>

<script>
import textFormatter from '../mixins/TextFormatter';

export default {
  props: ['element'],

  mixins: [textFormatter],
};
</script>
