export function animateMessageUpdate(
  context: { message: string; processDraft: (instant: boolean) => void },
  message?: string,
  intervalTime = 4
): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!message) {
      reject(new Error('No message provided'));
      return;
    }

    const messageArray = message.split('');
    let i = 0;
    const interval = setInterval(() => {
      if (i < messageArray.length) {
        context.message = messageArray.slice(0, i + 1).join('');
        i++;
      } else {
        clearInterval(interval);
        context.processDraft(true);
        resolve(message);
      }
    }, intervalTime);
  });
}
