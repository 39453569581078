<template>
  <div class="h-full w-full">
    <main-header max-width="1440px" :title="$t('reports.dashboard')">
      <template #left>
        <div class="ml-8">
          <t-thumbnail class="bg-white">
            <Lightning-linear size="1rem" />
          </t-thumbnail>
        </div>
      </template>
    </main-header>
    <div class="-mt-20 flex h-full w-full flex-col items-center justify-center">
      <img :src="`${assetsUrl}img/reporting/desktop.svg`" class="mb-6" />
      <h3 class="t-text-h3 text-center">{{ $t('reports.dashboard_mobile') }}</h3>
      <t-button btn-style="secondary" class="mt-2 flex items-center justify-center" @click.prevent="goToPreviousRoute">
        <arrow-left-linear class="mr-1" />
        {{ $t('reports.back_overview') }}
      </t-button>
    </div>
  </div>
</template>

<script lang="ts">
import { LightningLinear, ArrowLeftLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import MainHeader from '@/components/common/PageHeaders/MainHeader';

export default defineComponent({
  components: { ArrowLeftLinear, MainHeader, LightningLinear },

  computed: {
    assetsUrl() {
      return this.$root.assetsURL;
    },
  },

  methods: {
    goToPreviousRoute() {
      this.$router.push('/admin');
    },
  },
});
</script>
