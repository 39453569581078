import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "relative mb-6 overflow-hidden rounded-xl before:absolute before:h-full before:w-full before:rounded-xl before:border-1 before:border-[rgba(0,0,0,0.1)]"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "t-text-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_modal = _resolveComponent("t-modal")!

  return (_openBlock(), _createBlock(_component_t_modal, {
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isOpen) = $event)),
    title: _ctx.translations.title,
    "data-test": "two-factor-authentication-setup-step-three",
    variant: "narrow",
    "is-closable": "",
    onClose: _ctx.handleClose
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_t_button, {
        size: "md",
        onClick: _ctx.handleClose
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translations.close), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ]),
    default: _withCtx(() => [
      (!_ctx.isResetFlow)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("img", {
              src: `${
          /* @ts-ignore */
          _ctx.$root.assetsURL
        }images/2fa_enabled.gif`,
              alt: "two factor illustration"
            }, null, 8 /* PROPS */, _hoisted_2)
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.translations.description), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title", "onClose"]))
}