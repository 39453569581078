<script type="text/babel">
import eventBus from '@/eventBus';

import profile from '../Profiles/Profile.vue';

export default {
  name: 'ContactProfileModal',
  data: function () {
    return {
      profile: {},
      contact: {},
    };
  },

  components: {
    profile,
  },

  mounted: function () {
    eventBus.$on('modals.contact-profile.open', (data) => {
      this.profile = data.profile;
      this.contact = data.contact;
      $('#ContactProfileModal').modal();
    });

    eventBus.$on('modals.contact-profile.close', (data) => {
      this.profile = data.profile;
      $('#ContactProfileModal').modal('hide');
    });
  },

  unmounted() {
    eventBus.$off('modals.contact-profile.open');
    eventBus.$off('modals.contact-profile.close');
  },

  methods: {
    reload() {
      eventBus.$emit('ticket.current.reload');
      $('#ContactProfileModal').modal('hide');
    },

    onChannelChanged() {},
  },
};
</script>

<template>
  <div id="ContactProfileModal" class="modal modal-wide">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('tickets.profile') }}</h5>
          <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div v-if="profile.id != null" class="modal-body">
          <profile :profile_id="profile.id"></profile>
        </div>
      </div>
    </div>
  </div>
</template>
