<template>
  <div>
    <header class="mobile-header">
      <div class="left flex w-1/6 flex-shrink-0 justify-center text-sm">
        <button class="back-btn" @click="$emit('close')">
          <i class="material-icons">arrow_back</i>
        </button>
      </div>

      <div class="title w-full text-center">
        <h3 class="text-lg font-bold">{{ $t('email_settings.create_email_channel') }}</h3>
      </div>

      <div class="right flex w-1/6 flex-shrink-0 justify-center"></div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'CreateEmailMobileHeader',
  emits: ['close'],
};
</script>

<style scoped lang="scss">
.mobile-header {
  @apply fixed flex items-center justify-between bg-white;
  top: 0;
  z-index: 99;
  left: 0;
  right: 0;
  height: 58px;
  box-shadow: 0 12px 56px rgba(0, 0, 0, 0.02), 0 2.6px 24px rgba(0, 0, 0, 0.04), 0 0.8px 4px rgba(0, 0, 0, 0.03);

  .back-btn {
    @apply flex h-6 w-6 items-center justify-center;
  }

  @screen md {
    @apply hidden;
  }
}
</style>
