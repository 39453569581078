<template>
  <div ref="backdrop" class="trengo-modal flex items-center justify-center p-8" @click.self="close">
    <div
      class="
        box
        custom-shadow-outline custom-border-radius
        relative
        h-full
        w-full
        p-6
        pt-16
        sm:p-16
        lg:h-auto lg:w-auto
      "
    >
      <i class="material-icons text-muted pointer absolute" role="button" style="top: 2rem; right: 2rem" @click="close">
        close
      </i>

      <h2 v-if="modal.htmlTitle" class="mb-2 sm:mb-4" v-html="modal.htmlTitle"></h2>
      <h2 v-else-if="modal.title" class="mb-2 sm:mb-4">{{ modal.title }}</h2>

      <h3 v-if="modal.htmlSubtitle" class="text-muted mb-2 sm:mb-4" v-html="modal.htmlSubtitle"></h3>
      <h3 v-else-if="modal.subtitle" class="text-muted mb-2 sm:mb-4">{{ modal.subtitle }}</h3>

      <div v-if="modal.htmlBody" class="mb-4" v-html="modal.htmlBody"></div>
      <div v-else-if="modal.body" class="mb-4">{{ modal.body }}</div>

      <div v-if="modal.buttons" class="row mt-4">
        <template v-for="button in modal.buttons">
          <!-- internal link -->
          <router-link
            v-if="isInternalLink(button.url) && !isJsLink(button.url)"
            class="btn m-2 block rounded sm:inline-block"
            :class="button.class || 'btn-default'"
            :to="button.url"
            @click="click(button.click, true)"
          >
            {{ button.text }}
          </router-link>
          <!-- external link or javascript link -->
          <a
            v-else-if="button.url || button.click"
            class="btn m-2 block rounded sm:inline-block"
            :class="button.class || 'btn-default'"
            :href="button.url"
            :rel="!isJsLink(button.url) ? 'noreferrer noopener' : ''"
            :target="!isJsLink(button.url) ? '_blank' : ''"
            @click="click(button.click, isJsLink(button.url))"
          >
            {{ button.text }}
          </a>
        </template>
      </div>

      <p v-if="modal.htmlFooter" class="text-muted mb-0 mt-8" v-html="modal.htmlFooter"></p>
      <p v-else-if="modal.footer" class="text-muted mb-0 mt-8">{{ modal.footer }}</p>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus';

export default {
  name: 'Modal',

  props: {
    modal: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    isInternalLink(link = '') {
      return (
        link.startsWith(window.location.protocol + '//' + window.location.host) ||
        link.startsWith('//' + window.location.host) ||
        this.isJsLink(link) ||
        this.isRelativePath(link)
      );
    },
    isRelativePath(path = '') {
      return !!path.match(/^\/[a-z0-9-_%?#.]/i); // SHOULDDO: add some more special characters for valid url path
    },
    isJsLink(link = '') {
      return link.startsWith('javascript:');
    },

    click(callback = null, close = true) {
      if (close) {
        eventBus.$emit('modal', null);
      }
      if (callback) {
        callback();
      }
    },
    close(e) {
      if (this.modal.closable !== false) {
        eventBus.$emit('modal', null);
      }
    },
  },
};
</script>

<style lang="scss">
.trengo-modal {
  img {
    width: 100% !important;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>

<style scoped lang="scss">
.box {
  max-width: 800px;
}

.custom-shadow-outline {
  box-shadow: rgba(0, 0, 0, 0.11) 0 0 9px 0;
}

.custom-border-radius {
  border-radius: 10px;
}
</style>
