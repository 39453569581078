<script type="text/babel">
export default {
  data: function () {
    return {
      status: this.$root.user.user_status,
    };
  },

  methods: {
    toggleStatus: function () {
      if (this.status === 'ONLINE') {
        this.status = 'AWAY';
      } else if (this.status === 'AWAY' || this.status === 'OFFLINE') {
        this.status = 'ONLINE';
      }
      axios.get('/auth/set-status/' + this.status);
    },
  },
  computed: {
    inActiveBorder() {
      return this.computedStatus ? 'border-grey-700' : 'border-grey-800';
    },
    computedStatus: {
      get: function () {
        return this.status === 'ONLINE';
      },
      set: function (value) {
        this.status = value ? 'ONLINE' : 'AWAY';
      },
    },
  },
};
</script>

<template>
  <div
    class="
      selector-toggle-status-web-chat
      mb-1
      flex
      cursor-pointer
      items-center
      rounded-lg
      border-1
      p-2
      px-3
      leading-none
      text-white
      hover:border-grey-600 hover:brightness-90
    "
    :class="inActiveBorder"
    role="button"
    data-test="profile-settings-item-user-status"
    @click="toggleStatus"
  >
    <i
      class="material-icons text-md"
      :class="{ 'text-green': status === 'ONLINE', 'text-red': status === 'AWAY' || status === 'OFFLINE' }"
    >
      chat_bubble
    </i>
    <span class="ml-2">
      <span v-if="status === 'ONLINE'" v-html="$t('tickets.status_chat_online')"></span>
      <span
        v-if="status === 'AWAY' || status === 'OFFLINE'"
        class="text-grey-500"
        v-html="$t('tickets.status_chat_away')"
      ></span>
    </span>
    <div class="ml-auto text-xs leading-none text-grey-500">
      <t-switch v-model="computedStatus" label off-color="red" size="sm" />
    </div>
  </div>
</template>
