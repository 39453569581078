import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex max-h-[250px] flex-col overflow-y-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message_attachment = _resolveComponent("message-attachment")!
  const _component_sidebar_dropdown = _resolveComponent("sidebar-dropdown")!

  return (_openBlock(), _createBlock(_component_sidebar_dropdown, {
    title: _ctx.$tc('ticket_sidebar.attachments'),
    "collapse-key": "attachmentsDropdown",
    "data-test": "attachments-dropdown"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attachments, (attachment) => {
          return (_openBlock(), _createBlock(_component_message_attachment, {
            key: attachment.full_url,
            attachment: attachment,
            "is-deletable": false,
            "has-download-button": true,
            class: "!bg-transparent"
          }, null, 8 /* PROPS */, ["attachment"]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]))
}