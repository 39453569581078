<template>
  <div class="flex flex-col" data-test="sidebar-infobox-wrapper">
    <div class="flex flex-row items-center">
      <h2
        v-if="!isEditingName"
        class="t-text-md-emphasize m-0 flex flex-row items-center text-ellipsis p-0 text-grey-800"
        data-test="sidebar-contact-name"
      >
        <avatar class="mr-2" :color="contact.color" :abbr="contact.abbr" width="32" :image="contact.profile_image" />
        {{ contact.full_name }}
      </h2>

      <t-input-text
        v-else
        id="contactFullName"
        v-model="contact.full_name"
        class="mr-2"
        :placeholder="contact.full_name"
        data-test="contact-edit-fullname"
        @keydown.enter="updateContactName"
        @keydown.esc="cancelName"
      />

      <t-action-menu
        id="sidebar-action-menu"
        v-model="isModalOpen"
        class="ml-auto"
        position="left"
        :trigger-on-click="true"
        width=""
        data-test="sidebar-modal-action-menu"
      >
        <template #trigger>
          <t-icon-button class="ml-auto">
            <more-vert-fill size="1.5rem" />
          </t-icon-button>
        </template>
        <template #content>
          <infobox-dropdown
            :has-email="!!contact.email"
            :has-phone="contact.is_phone"
            @edit-name="enableNameEdit"
            @start-conversation="startConversation"
            @edit-email="enableEmailEdit"
            @show-contact="showContact"
            @search-email="searchItem('email')"
            @copy-email="copyItem('email')"
            @call-contact="callContact"
            @search-phone="searchItem('phone')"
            @copy-phone="copyItem('phone')"
          />
        </template>
      </t-action-menu>
    </div>

    <div class="mt-2 flex flex-row items-center">
      <span>
        <email-linear size="1.25rem" />
      </span>

      <p
        v-if="contact.email"
        class="t-text-sm my-0 ml-6 text-ellipsis p-0 pr-2 text-grey-700"
        data-test="sidebar-contact-email"
      >
        {{ contact.email }}
      </p>

      <t-icon-button v-if="contact.email" class="ml-auto" @click="copyItem('email')">
        <copy-linear size="1.1rem" />
      </t-icon-button>

      <t-button-text
        v-else-if="!isAddingEmail"
        class="my-0 ml-3"
        data-test="add-email-button"
        @click="isAddingEmail = true"
      >
        {{ $tc('email_settings.add_email') }}
      </t-button-text>

      <div v-else class="absolute">
        <attach-email class="top-0" :contact="contact" @close-email-edit="isAddingEmail = false" />
      </div>

      <edit-email-modal
        v-if="isEditingEmail"
        :email="contact.email"
        :contact-id="contact.id"
        @close-modal="isEditingEmail = false"
      />
    </div>

    <div v-if="contact.is_phone" class="mt-2 flex flex-row items-center">
      <span>
        <phone-linear size="1.25rem" />
      </span>

      <p class="t-text-sm sm my-0 ml-6 text-ellipsis p-0 pr-2 text-grey-700" data-test="sidebar-contact-phone">
        {{ contact.formatted_phone }}
      </p>

      <t-icon-button class="ml-auto" @click="copyItem('phone')">
        <copy-linear size="1.1rem" />
      </t-icon-button>
    </div>

    <div class="mt-2 flex flex-row items-center">
      <span>
        <user-square-linear size="1.25rem" />
      </span>

      <router-link
        v-if="contact.profile.length > 0"
        :to="`/profiles/${contact.profile[0].id}`"
        class="t-text-sm sm my-0 ml-6 text-ellipsis p-0 text-grey-700 underline hover:text-grey-900 hover:underline"
        data-test="sidebar-contact-profile-link"
      >
        {{ contact.profile[0].name }}
      </router-link>

      <t-button-text v-else class="my-0 ml-3" data-test="sidebar-add-contact-button" @click="isAddingProfile = true">
        {{ $tc('settings.add_profile') }}
      </t-button-text>

      <div v-if="isAddingProfile" class="absolute">
        <on-click-outside @trigger="isAddingProfile = false">
          <attach-profile :contact="contact" :channel="channel" @close-profile-edit="isAddingProfile = false" />
        </on-click-outside>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PhoneLinear, EmailLinear, MoreVertFill, UserSquareLinear, CopyLinear } from '@trengo/trengo-icons';
import { OnClickOutside } from '@vueuse/components';
import { defineComponent } from 'vue';

import Avatar from '@/components/Avatar.vue';
import { updateContactFullName } from '@/components/TicketSidebar/Api/api';
import AttachEmail from '@/components/TicketSidebar/AttachEmail.vue';
import AttachProfile from '@/components/TicketSidebar/AttachProfile.vue';
import EditEmailModal from '@/components/TicketSidebar/EditEmailModal.vue';
import InfoboxDropdown from '@/components/TicketSidebar/InfoboxDropdown.vue';
import eventBus from '@/eventBus';

import type { Channel } from '@/types';
import type Contact from '@/types/contact';
import type { PropType } from 'vue';
export default defineComponent({
  name: 'SidebarInfobox',

  components: {
    EditEmailModal,
    AttachEmail,
    AttachProfile,
    CopyLinear,
    MoreVertFill,
    Avatar,
    InfoboxDropdown,
    EmailLinear,
    PhoneLinear,
    UserSquareLinear,
    OnClickOutside,
  },

  props: {
    contact: {
      type: Object as PropType<Contact>,
      default: () => ({}),
    },
    channel: {
      type: Object as PropType<Channel>,
      default: () => ({}),
    },
  },

  data() {
    return {
      isEditingName: false,
      isEditingEmail: false,
      isAddingEmail: false,
      isAddingProfile: false,
      dropdownItems: [{}],
      isModalOpen: false,
      originalName: '',
    };
  },

  methods: {
    async updateContactName() {
      if (!this.contact.full_name) {
        return;
      }

      try {
        await updateContactFullName(this.contact.id, this.contact.full_name)
          .then(() => {
            eventBus.$emit('tickets.list.reload');
          })
          .finally(() => {
            this.isEditingName = false;
          });
      } catch (e) {
        console.error(e);
      }
    },

    enableNameEdit() {
      this.originalName = this.contact.full_name;
      this.isEditingName = true;
      this.isModalOpen = false;

      this.$nextTick(() => {
        // Refs don't work here since the t-input element has multiple wrappers around the actual input element, so it's not focusable
        // It does however pass the provided ID to the input element itself
        document?.getElementById('contactFullName')?.focus();
      });
    },

    cancelName() {
      this.contact.full_name = this.originalName;
      this.isEditingName = false;
    },

    startConversation() {
      this.isModalOpen = false;

      eventBus.$emit('modals.create-ticket.open', {
        contact: this.contact,
        channelType: 'EMAIL',
      });
    },

    showContact() {
      this.isModalOpen = false;
      this.$router.push(`/contacts/${this.contact.id}`);
    },

    searchItem(item: 'phone' | 'email') {
      eventBus.$emit('tickets.search', this.contact[item]);
    },

    async copyItem(item: 'phone' | 'email') {
      this.isModalOpen = false;

      const copyText = item === 'phone' ? this.contact.phone : this.contact.email;

      try {
        await navigator.clipboard.writeText(copyText).then(() => {
          this.flashSuccess(this.$tc('email_settings.copied_to_clipboard'));
        });

        return Promise.resolve(true);
      } catch (err) {
        console.error(err);
        this.flashError(err?.message);

        return Promise.reject(false);
      }
    },

    callContact() {
      this.isModalOpen = false;

      eventBus.$emit('voip.call', {
        phone: `+${this.contact.phone}`,
      });
    },

    enableEmailEdit() {
      this.isModalOpen = false;
      this.isEditingEmail = true;
    },
  },
});
</script>
