<template>
  <t-modal
    v-model="openSandboxModal"
    data-test="whatsapp-sandbox-modal"
    :title="getModalTitle()"
    :subheader="getSubheaderText()"
    :variant="getModalVariant()"
    @close="() => close()"
  >
    <whatsapp-business-sandbox :step="step" :ticket-id="ticketId" :messages="messages" />

    <template #footer>
      <t-button
        v-if="String(step) === steps.CONNECT_WHATSAPP"
        data-test="send-button"
        :disabled="isSendDisabled"
        class="flex items-center justify-center"
        @click="submit"
      >
        <t-spinner v-if="isLoading" class="mr-2" />
        {{ $t('whatsapp.send_message') }}
      </t-button>
      <t-button
        v-if="String(step) === steps.TRYING_TEMPLATES_SUCCESS"
        data-test="continue-inbox"
        class="flex items-center justify-center"
        @click="completeTemplatesSandbox"
      >
        {{ $t('whatsapp.continue_to_inbox') }}
      </t-button>
    </template>
  </t-modal>
</template>

<script lang="ts">
import WhatsappBusinessSandbox from '@/components/Channels/WhatsApp/WhatsappBusinessSandbox';
import { Steps } from '@/components/Channels/WhatsApp/WhatsappBusinessSandbox/constants';
import eventBus from '@/eventBus';
import breakpoints from '@/util/breakpoints';

export default {
  emits: ['close', 'open', 'process-completed'],
  components: { WhatsappBusinessSandbox },
  props: {
    initialIsOnboarding: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      messages: [],
      sent: false,
      ticketId: null,
      replied: false,
      isOnboarding: this.initialIsOnboarding,
      openSandboxModal: this.modelValue,
      step: Steps.CONNECT_WHATSAPP,
      steps: Steps,
      isSendDisabled: true,
      isLoading: false,
      phoneNumber: '',
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.openSandboxModal;
      },
      set(newValue) {
        this.openSandboxModal = newValue;
        this.$emit('close');
      },
    },
    isMobile() {
      return !breakpoints.comparisons.gt_sm;
    },
  },
  watch: {
    modelValue(newValue) {
      this.openSandboxModal = newValue;
      if (newValue) {
        this.openOnboardingModal();
      }
    },
  },
  created() {
    eventBus.$on('go-to-step', this.goToStep);
    eventBus.$on('on-reply', this.onReply);
    eventBus.$on('complete-sandbox', this.completeSandboxProcess);
    eventBus.$on('validate-phone', this.validatePhone);
    this.$router.beforeEach((to, from, next) => {
      this.close();
      setTimeout(() => {
        next(true);
      }, 100);
    });
  },
  unmounted() {
    eventBus.$off('go-to-step', this.goToStep);
    eventBus.$off('on-reply', this.onReply);
    eventBus.$off('complete-sandbox', this.completeSandboxProcess);
    eventBus.$off('validate-phone', this.validatePhone);
  },
  methods: {
    completeSandboxProcess() {
      if (this.ticketId) {
        this.$emit('process-completed', `/tickets/${this.ticketId}`);
        this.$router.push('/tickets/' + this.ticketId);
      }
    },
    completeTemplatesSandbox() {
      this.isLoading = true;
      setTimeout(() => {
        this.completeSandboxProcess();
        this.isLoading = false;
      }, 1000);
    },
    submit() {
      if (this.isLoading) return;
      this.isLoading = true;

      eventBus.$emit('submit-connect-whatsapp');
    },
    openOnboardingModal() {
      if (this.$store.state.onboardingSteps?.enabled && this.$root.onboarding?.type === 'whatsapp') {
        this.isOnboarding = true;
      }
      this.$emit('open');
    },
    close() {
      this.$emit('close');
      this.resetValues();

      if (this.$store.state.onboardingSteps?.enabled && this.$root.onboarding?.type === 'whatsapp') {
        this.isOnboarding = false;
      }
    },
    getModalTitle() {
      const modalTitleByStep = {
        [Steps.CONNECT_WHATSAPP]: this.$t('whatsapp.experience_whatsapp'),
        [Steps.MESSAGE_RECIEVED]: this.$t('whatsapp.message_recieved_title'),
        [Steps.ESTABLISHING_CONNECTION]: this.$t('whatsapp.lets_make_some_magic'),
        [Steps.VALIDATING_CONNECTION]: this.$t('whatsapp.we_sent_you_a_message'),
        [Steps.TRYING_TEMPLATES]: this.$t('whatsapp.great_work'),
        [Steps.TRYING_TEMPLATES_SUCCESS]: this.$t('whatsapp.templates_success_title'),
      };

      return modalTitleByStep[this.step];
    },
    getModalVariant() {
      const templateSteps = [Steps.TRYING_TEMPLATES, Steps.TRYING_TEMPLATES_SUCCESS];
      return templateSteps.includes(this.step) ? 'default' : 'narrow';
    },
    getSubheaderText() {
      return !this.isMobile && this.step === Steps.TRYING_TEMPLATES_SUCCESS
        ? this.$t('whatsapp.templates_success_subheader')
        : '';
    },
    goToStep(newStep) {
      this.step = newStep;
    },
    resetValues() {
      this.computedValue = false;
      this.isSendDisabled = true;
      this.isLoading = false;
      this.step = Steps.CONNECT_WHATSAPP;
      this.messages = [];
    },
    onReply(replyData) {
      if (replyData.nextStep === Steps.TRYING_TEMPLATES_SUCCESS && replyData.messages) {
        this.messages = [...this.messages, ...replyData.messages];
      }

      this.ticketId = replyData.ticketId;

      eventBus.$emit('go-to-step', replyData.nextStep);
      eventBus.$emit('channels.reload');
    },
    validatePhone(isPhoneNumberValid) {
      if (!isPhoneNumberValid) {
        this.isLoading = false;
        this.isSendDisabled = true;
      } else {
        this.isSendDisabled = false;
      }
    },
  },
};
</script>
