import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-224a6fa6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "t-text-md m-0 mb-1 flex flex-row items-center" }
const _hoisted_2 = { class: "mr-1" }
const _hoisted_3 = {
  key: 0,
  class: "animation-loading mt-4 h-6 w-20 rounded-3xl bg-grey-300"
}
const _hoisted_4 = {
  key: 1,
  class: "flex w-full flex-row items-center justify-between"
}
const _hoisted_5 = { class: "t-text-h1 m-0 mt-1 cursor-pointer" }
const _hoisted_6 = { class: "t-text-h1 m-0 mt-1 cursor-pointer" }
const _hoisted_7 = { class: "t-text-md m-0 mb-1 flex flex-row items-center" }
const _hoisted_8 = { class: "mr-1" }
const _hoisted_9 = {
  key: 0,
  class: "animation-loading mt-4 h-6 w-20 rounded-3xl bg-grey-300"
}
const _hoisted_10 = {
  key: 1,
  class: "flex w-full flex-row items-center justify-between"
}
const _hoisted_11 = { class: "t-text-h1 m-0 mt-1 cursor-pointer" }
const _hoisted_12 = { class: "t-text-h1 m-0 mt-1 cursor-pointer" }
const _hoisted_13 = { class: "flex flex-col items-start" }
const _hoisted_14 = { class: "t-text-md m-0 mb-1 flex flex-row items-center" }
const _hoisted_15 = { class: "mr-1" }
const _hoisted_16 = {
  key: 0,
  class: "animation-loading mt-4 h-6 w-20 rounded-3xl bg-grey-300"
}
const _hoisted_17 = {
  key: 1,
  class: "flex w-full flex-row items-center justify-between"
}
const _hoisted_18 = { class: "t-text-h1 m-0 mt-1 cursor-pointer" }
const _hoisted_19 = { class: "t-text-h1 m-0 mt-1 cursor-pointer" }
const _hoisted_20 = { class: "t-text-md m-0 mb-1 flex flex-row items-center" }
const _hoisted_21 = { class: "mr-1" }
const _hoisted_22 = {
  key: 0,
  class: "animation-loading mt-4 h-6 w-20 rounded-3xl bg-grey-300"
}
const _hoisted_23 = {
  key: 1,
  class: "flex w-full flex-row items-center justify-between"
}
const _hoisted_24 = { class: "t-text-h1 m-0 mt-1 cursor-pointer" }
const _hoisted_25 = { class: "t-text-h1 m-0 mt-1 cursor-pointer" }
const _hoisted_26 = { class: "t-text-md m-0 mb-1 flex flex-row items-center" }
const _hoisted_27 = { class: "mr-1" }
const _hoisted_28 = {
  key: 0,
  class: "animation-loading mt-4 h-6 w-20 rounded-3xl bg-grey-300"
}
const _hoisted_29 = {
  key: 1,
  class: "flex w-full flex-row items-center justify-between"
}
const _hoisted_30 = { class: "t-text-h1 m-0 mt-1 cursor-pointer" }
const _hoisted_31 = { class: "t-text-h1 m-0 mt-1 cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_fill = _resolveComponent("info-fill")!
  const _component_t_tooltip = _resolveComponent("t-tooltip")!
  const _component_t_card = _resolveComponent("t-card")!
  const _component_stat_row = _resolveComponent("stat-row")!

  return (_openBlock(), _createBlock(_component_stat_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_t_card, {
        focusable: true,
        class: "bg-white"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "flex flex-col items-start",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleRedirectToInsightTicketsPage('created_conversations')))
          }, [
            _createElementVNode("p", _hoisted_1, [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('reports_v2.created_converstions')), 1 /* TEXT */),
              _createVNode(_component_t_tooltip, {
                text: _ctx.$t('reports_v2.created_conversations_tooltip'),
                position: "top"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_info_fill, {
                    class: "text-grey-600",
                    size: "1rem"
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["text"])
            ]),
            (!_ctx.isLoaded)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("p", _hoisted_5, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.showError ? 0 : _ctx.createdConversations), 1 /* TEXT */)
                  ])
                ]))
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_t_card, {
        focusable: true,
        class: "bg-white"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "flex flex-col items-start",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleRedirectToInsightTicketsPage('closed_conversations')))
          }, [
            _createElementVNode("p", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('reports_v2.closed_conversations')), 1 /* TEXT */),
              _createVNode(_component_t_tooltip, {
                text: _ctx.$t('reports_v2.closed_conversations_tooltip'),
                position: "top"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_info_fill, {
                    class: "text-grey-600",
                    size: "1rem"
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["text"])
            ]),
            (!_ctx.isLoaded)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9))
              : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("p", _hoisted_11, [
                    _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.showError ? 0 : _ctx.closedConversations), 1 /* TEXT */)
                  ])
                ]))
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_t_card, { class: "bg-white" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("p", _hoisted_14, [
              _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t('reports_v2.re_opened_conversations')), 1 /* TEXT */),
              _createVNode(_component_t_tooltip, {
                text: _ctx.$t('reports_v2.reopened_conversations_tooltip'),
                position: "left"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_info_fill, {
                    class: "text-grey-600",
                    size: "1rem"
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["text"])
            ]),
            (!_ctx.isLoaded)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16))
              : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("p", _hoisted_18, [
                    _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.showError ? 0 : _ctx.reOpenedConversations), 1 /* TEXT */)
                  ])
                ]))
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_t_card, {
        focusable: true,
        class: "bg-white"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "flex flex-col items-start",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleRedirectToInsightTicketsPage('resolution_time')))
          }, [
            _createElementVNode("p", _hoisted_20, [
              _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t('reports_v2.total_resolution_time_title')), 1 /* TEXT */),
              _createVNode(_component_t_tooltip, {
                text: _ctx.$t('reports_v2.resolution_time_tooltip'),
                position: "right"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_info_fill, {
                    class: "text-grey-600",
                    size: "1rem"
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["text"])
            ]),
            (!_ctx.isLoaded)
              ? (_openBlock(), _createElementBlock("div", _hoisted_22))
              : (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createElementVNode("p", _hoisted_24, [
                    _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.showError ? '0m 0s' : _ctx.resolutionTime), 1 /* TEXT */)
                  ])
                ]))
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_t_card, {
        focusable: true,
        class: "bg-white"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "flex flex-col items-start",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleRedirectToInsightTicketsPage('first_response_time')))
          }, [
            _createElementVNode("p", _hoisted_26, [
              _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.$t('statistics.report_first_response_time')), 1 /* TEXT */),
              _createVNode(_component_t_tooltip, {
                text: _ctx.$t('reports_v2.first_response_time_tooltip'),
                position: "right"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_info_fill, {
                    class: "text-grey-600",
                    size: "1rem"
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["text"])
            ]),
            (!_ctx.isLoaded)
              ? (_openBlock(), _createElementBlock("div", _hoisted_28))
              : (_openBlock(), _createElementBlock("div", _hoisted_29, [
                  _createElementVNode("p", _hoisted_30, [
                    _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.showError ? '0m 0s' : _ctx.firstResponseTime), 1 /* TEXT */)
                  ])
                ]))
          ])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}