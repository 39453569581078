<template>
  <div class="flex flex-row items-stretch">
    <div class="col-md-4 py-4" style="border-right: 1px solid #dee0e2">
      <div class="row mb-3">
        <div class="col-xs-12">
          <label for="fromType" class="font-bold">{{ $t('widget_automation.page_greeting_from') }}</label>
        </div>
        <div class="col-xs-12">
          <multiselect
            id="fromType"
            v-model="fromType"
            :show-labels="false"
            :allow-empty="false"
            :preselect-first="true"
            track-by="id"
            label="text"
            :multiple="false"
            :options="allFromTypes"
            :placeholder="$t('widget_automation.placeholder_from')"
          ></multiselect>
        </div>
      </div>

      <div v-if="fromType.id === 'custom'" class="row mb-3">
        <div class="col-xs-12">
          <label for="fromType" class="font-bold">
            {{ $t('widget_automation.page_greeting_enter_greeting_title') }}
          </label>
        </div>
        <div class="col-xs-12">
          <input
            id="fromType"
            v-model="action.payload.from.name"
            type="text"
            class="form-control"
            :placeholder="$t('widget_automation.placeholder_select_greeting')"
          />
        </div>
      </div>
      <div v-else class="row mb-3">
        <div class="col-xs-12">
          <label for="from" class="font-bold">
            {{ $t('widget_automation.page_greeting_select') }} {{ fromType.id === 'random' ? 'team' : fromType.id }}
          </label>
        </div>
        <div class="col-xs-12">
          <multiselect
            id="from"
            v-model="action.payload.from"
            :show-labels="false"
            :preselect-first="true"
            :allow-empty="false"
            track-by="id"
            label="name"
            :searchable="true"
            :multiple="false"
            :options="selectFromOptions"
            :placeholder="multiselectPlaceholder"
          ></multiselect>
        </div>
      </div>

      <div v-for="(message, index) in action.payload.message" :key="index" class="row mb-3">
        <div class="col-xs-12">
          <label for="message" class="font-bold">
            <div class="flex items-center">
              {{ $t('widget_automation.page_greeting_compose_greeting') }} ({{ message.language }})
              <i
                v-if="action.payload.message.length > 1"
                class="material-icons text-md text-muted ml-1"
                @click.prevent="deleteMessageLanguage(message)"
              >
                clear
              </i>
            </div>
          </label>

          <div v-if="index === 0" class="dropdown pull-right">
            <button
              id="dropdownMenuButton"
              class="btn btn-secondary dropdown-toggle btn-xs"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              + {{ $t('widget_automation.page_greeting_translate') }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="overflow-y: auto; height: 340px">
              <a v-for="(lang, i) in allLanguages" :key="i" class="dropdown-item" @click="addTranslation(lang)">
                {{ lang.name }}
              </a>
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <textarea
            id="message"
            v-model="message.message"
            rows="3"
            class="form-control"
            :placeholder="$t('widget_automation.placeholder_greeting_message')"
            @focus="setFakeDefault(message.language)"
          ></textarea>
        </div>
        <div v-show="action.payload.message.length > 1" class="col-xs-12 pt-1">
          <div class="row">
            <div class="col-xs-6">
              <label class="text-muted mt-1 text-xs">
                <input
                  v-model="message.default"
                  type="checkbox"
                  :true-value="true"
                  :false-value="false"
                  @change="uncheckAllOtherMessageLanguageDefaults(message)"
                />
                {{ $t('widget_automation.page_greeting_default_language') }}
              </label>
            </div>
            <!--<div class="col-xs-6">
                            <button class="btn btn-default btn-xs ml-2 pull-right" @click.prevent="deleteMessageLanguage(message)">delete</button>
                        </div>-->
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-8" style="min-height: 360px; position: relative">
      <div
        class="greeter custom-border-radius custom-shadow-outline white absolute"
        style="cursor: pointer; bottom: calc(108px + 3rem); right: 1rem; width: 300px; max-width: 100%"
      >
        <i class="fa fa-close close"></i>
        <div class="p-6" style="padding-top: 1.1rem">
          <div class="flex pb-2 font-bold" style="height: 38px">
            <span v-if="fromType.id === 'random'" class="text-truncate mr-3" style="align-self: center">
              {{ randomUserFromTeam ? randomUserFromTeam.name : 'User' }}
            </span>
            <span v-else class="text-truncate mr-3" style="align-self: center">
              {{ action.payload.from.name || fromTypeCapitalized }}
            </span>
            <div style="flex-shrink: 0" class="mr-4">
              <template v-if="fromType.id === 'random' && randomUserFromTeam">
                <div
                  v-for="avatar in randomUserFromTeamAvatars"
                  :key="avatar.abbr"
                  class="circle avatar inline-block-40px mr-1"
                  style="width: 30px; height: 30px; line-height: 1; border: 2px solid #fff"
                >
                  <img v-if="avatar.image" :src="avatar.image" alt="avatar" />
                  <span
                    v-else
                    class="circle text-white"
                    style="line-height: 26px; width: 26px; height: 26px; text-align: center; display: inline-block"
                    :style="{ 'background-color': avatar.color }"
                  >
                    {{ avatar.abbr.substring(0, 1) }}
                  </span>
                </div>
              </template>
              <template v-else-if="fromType.id !== 'random'">
                <div
                  v-for="avatar in actionPayloadFromAvatars"
                  :key="avatar.abbr"
                  class="circle avatar inline-block-40px mr-1"
                  style="width: 30px; height: 30px; line-height: 1; border: 2px solid #fff"
                >
                  <img v-if="avatar.image" :src="avatar.image" alt="avatar" />
                  <span
                    v-else
                    class="circle text-white"
                    style="line-height: 26px; width: 26px; height: 26px; text-align: center; display: inline-block"
                    :style="{ 'background-color': avatar.color }"
                  >
                    {{ avatar.abbr.substring(0, 1) }}
                  </span>
                </div>
              </template>
            </div>
          </div>
          <div
            class="pt-2"
            style="min-height: 21px; color: #9e9e9e"
            v-html="defaultMessage || $t('widget_automation.placeholder_greeting_message')"
          ></div>
        </div>
      </div>

      <div
        class="custom-border-radius custom-shadow-outline white absolute"
        style="
          z-index: 1;
          display: flex;
          padding: 0.75rem 1.5rem;
          padding-right: 0;
          cursor: text;
          bottom: calc(60px + 2rem);
          right: 1rem;
          width: 300px;
          max-width: 100%;
          color: #9e9e9e;
        "
      >
        <span style="margin-top: 2px; flex-grow: 1">{{ $t('widget_automation.page_greeting_write_a_reply') }}</span>
        <i class="material-icons" style="cursor: pointer; padding-right: 1.5rem">send</i>
      </div>

      <div class="trengo-shadow absolute" style="max-width: 100%">
        <div class="launcher-container">
          <span class="trengo_launcher">
            <span style="animation-delay: 0.1s"></span>
            <span style="animation-delay: 0.1s"></span>
            <span style="animation-delay: 0.1s"></span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { map } from 'lodash';
import Multiselect from 'vue-multiselect';

import eventBus from '@/eventBus';

import Automation from '../../Automation';

export default {
  name: 'Greeting',

  components: { Multiselect },

  data() {
    return {
      allFromTypes: [
        { id: 'team', text: this.$t('widget_automation.greeting_from_team_text') },
        { id: 'user', text: this.$t('widget_automation.greeting_from_user_text') },
        {
          id: 'random',
          text: this.$t('widget_automation.greeting_from_random_text'),
        },
        { id: 'custom', text: this.$t('widget_automation.greeting_from_custom_text') },
      ],
      fromType: { id: 'team', text: 'Team' },
      selectFromOptions: [],
      defaultMessageLang: 'en',
      allLanguages: map(this.$root.localeCodesList, (l) => {
        return { name: l.name, value: l.id };
      }),
      fakeDefaultLang: null,
    };
  },

  mounted() {
    this.init();
  },

  beforeUnmount() {
    eventBus.$off('automations.loaded');
  },

  computed: {
    defaultMessage() {
      let message = { message: '' };
      if (this.fakeDefaultLang !== null) {
        message = this.action.payload.message.find((v) => {
          return v.language === this.fakeDefaultLang;
        }) || { message: '' };
      } else {
        message = this.action.payload.message.find((v) => v.default) || { message: '' };
      }
      const nl2br = (str) => str.replace(/\n/g, '<br />');
      return nl2br(this.htmlEntities(message.message || ''));
    },
    randomUserFromTeam() {
      if (this.action.payload.from.team) {
        return this.action.payload.from.team[Math.floor(Math.random() * this.action.payload.from.team.length)];
      }
    },
    randomUserFromTeamAvatars() {
      return this.randomUserFromTeam.avatars.filter((avatar) => avatar);
    },
    actionPayloadFromAvatars() {
      return this.action.payload.from.avatars.filter((avatar) => avatar);
    },
    fromTypeCapitalized() {
      return this.fromType.id ? this.fromType.id.charAt(0).toUpperCase() + this.fromType.id.slice(1) : 'From';
    },
    multiselectPlaceholder() {
      return this.$t('widget_automation.placeholder_select', {
        name:
          this.fromType.id === 'random'
            ? this.$t('widget_automation.placeholder_select_team')
            : this.$t(`widget_automation.placeholder_select_${this.fromType.id}`),
      });
    },
  },

  watch: {
    'fromType.id'(newVal) {
      if (newVal) {
        this.initFromOptions(newVal);

        // reset from if current selection not in fromOptions
        if (
          newVal !== 'custom' &&
          !this.selectFromOptions.some(
            (option) =>
              this.action.payload.from.id === option.id && this.action.payload.from.fromType === option.fromType
          )
        ) {
          this.action.payload = Object.assign({}, this.action.payload, {
            from: {
              ...Automation.actionTypes.greeting.defaultPayload.from,
            },
          });
        }
      }
    },
  },

  methods: {
    init() {
      if (this.action.payload.from.fromType) {
        this.fromType = this.allFromTypes.find((item) => item.id === this.action.payload.from.fromType) || {
          id: 'team',
          text: 'Team',
        };
      }

      this.initFromOptions(this.fromType.id);

      // if from is set with relation
      if (this.action.payload.from.fromType !== 'custom' && this.action.payload.from.id) {
        // find currently selected from in select
        let currentlySelectedFrom =
          this.selectFromOptions.find((from) => from.id === this.action.payload.from.id) || {};
        this.action.payload = Object.assign({}, this.action.payload, {
          from: {
            ...currentlySelectedFrom,
          },
        });
      }
    },

    getAvatarsFromTeam(team) {
      return (
        this.$root.users
          .map((user) => {
            if (
              user.teams.some((userTeam) => {
                return team.id === userTeam.id;
              })
            ) {
              return { image: user.profile_image, color: user.color, abbr: user.abbr };
            }
          })
          .filter((avatar) => !!avatar) || []
      );
    },

    getUsersByTeam(team) {
      return (
        this.$root.users
          .map((user) => {
            if (user.teams.some((userTeam) => team.id === userTeam.id)) {
              return {
                name: user.first_name,
                avatars: [{ image: user.profile_image, color: user.color, abbr: user.abbr }],
              };
            }
          })
          .filter((user) => !!user) || []
      );
    },

    htmlEntities(str = '') {
      return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    },

    deleteMessageLanguage(v) {
      if (this.action.payload.message.length < 2) {
        return;
      }

      let wasDefault = v.default;

      this.action.payload.message.splice(this.action.payload.message.indexOf(v), 1);

      if (wasDefault) {
        this.action.payload = Object.assign({}, this.action.payload, {
          message: this.action.payload.message.map((v, i) => {
            return {
              ...v,
              default: i === 0,
            };
          }),
        });
      }
    },

    uncheckAllOtherMessageLanguageDefaults(message) {
      this.action.payload = Object.assign({}, this.action.payload, {
        message: this.action.payload.message.map((v) => {
          return {
            ...v,
            default: v.language === message.language,
          };
        }),
      });
    },
    addTranslation(lang = { name: 'English', value: 'en' }) {
      if (this.action.payload.message.some((v) => v.language === lang.value)) {
        this.flashWarning(lang.name + ' already added');
        return;
      }
      this.action.payload.message.push({ language: lang.value, message: '', default: false });
    },

    // https://stackoverflow.com/questions/951021/what-is-the-javascript-version-of-sleep
    sleep(ms = 0) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    async initFromOptions(fromType) {
      // wait for this.$root.teams to load
      for (let tries = 0; tries < 50 && !this.$root.teams.length; tries++) {
        await this.sleep(100);
      }

      switch (fromType) {
        case 'team':
          this.selectFromOptions = this.$root.teams.map((team) => {
            return {
              name: team.name,
              fromType: 'team',
              id: team.id,
              avatars: this.getAvatarsFromTeam(team).slice(0, 3),
            };
          });
          break;
        case 'random':
          this.selectFromOptions = this.$root.teams.map((team) => {
            return {
              name: team.name,
              fromType: 'random',
              id: team.id,
              avatars: [],
              team: this.getUsersByTeam(team),
            };
          });
          break;
        case 'user':
          this.selectFromOptions = this.$root.users.map((user) => {
            return {
              name: user.first_name,
              fromType: 'user',
              id: user.id,
              avatars: [{ image: user.profile_image, color: user.color, abbr: user.abbr }],
            };
          });
          break;
        case 'custom':
          this.selectFromOptions = [];
          // unset from's avatars etc.
          this.action.payload = Object.assign({}, this.action.payload, {
            from: {
              ...this.action.payload.from,
              avatars: [],
              id: null,
              fromType: 'custom',
            },
          });
      }
    },

    setFakeDefault(lang) {
      this.fakeDefaultLang = lang;
    },
  },

  props: {
    action: {
      default: {},
      type: Object,
    },
  },
};
</script>

<style scoped lang="scss">
.custom-shadow-outline {
  box-shadow: rgba(0, 0, 0, 0.11) 0 0 9px 0;
}

.custom-border-radius {
  border-radius: 10px;
}

.greeter {
  z-index: 1;

  i.close {
    position: absolute;
    right: 5px;
    top: 5px;
    border-radius: 100%;
    padding: 0;
    /* background: grey; */
    color: grey;
    width: 30px;
    height: 30px;
    font-weight: normal;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    cursor: pointer;
    transition: opacity 0.2s 0.2s ease;
  }

  .avatar {
    margin-left: -17px;
    z-index: 1;
    position: relative;
    margin-right: 2px;

    &:nth-of-type(1) {
      margin-left: 0;
      z-index: 0;
    }

    &:nth-of-type(3) {
      z-index: 0;
    }
  }
}

.trengo-shadow {
  z-index: 0 !important;
  position: absolute !important;
  width: 500px;
  height: 360px;
  bottom: 0;
  right: 0;
  content: '';
  pointer-events: all !important;
  background: radial-gradient(ellipse at bottom right, rgba(29, 39, 54, 0.16) 0, rgba(29, 39, 54, 0) 72%);
}

.launcher-container {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  width: 60px;
  height: 60px;
  background: #14b29f;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  font-size: 30px;
  line-height: 60px;

  .trengo_launcher {
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 60px;
    overflow: hidden;

    span {
      background: white;
      width: 20px;
      border-radius: 5px;
      margin-left: 17px;
      height: 5px;
      animation: slideIn 0.3s ease forwards;
      animation-delay: 0.5s;
      opacity: 0;
    }

    span:first-child {
      margin-left: 13px;
      margin-bottom: 3px;
      width: 30px;
      opacity: 0;
    }

    span:last-child {
      width: 22px;
      margin-left: 21px;
      margin-top: 3px;
      opacity: 0;
    }
  }
}
</style>
