<template>
  <div id="app" class="app w-full">
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { initializeFaro } from '@grafana/faro-web-sdk';
import * as Sentry from '@sentry/vue';
import { includes, map } from 'lodash';
import moment from 'moment';
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { createI18n } from 'vue-i18n';
import { mapGetters } from 'vuex';

import { fetchAllChannels, fetchChannels } from '@/api';
import { fetchAdminEvents, getAuthActivity, getDirectLoginUrl } from '@/api/modules/auth';
import OnboardingStepsMethods from '@/components/Onboarding/OnboardingStepsMethods';
import { CHANNEL_TYPE, FEATURE_FLAG_ACTIVATION, PERMISSION } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import notifyMixin from '@/mixins/Notify';
import pusherMixin from '@/mixins/PusherHelper';
import mobileAppHandler from '@/MobileAppHandler';
import ticketRepository from '@/repositories/Ticket';
import userRepository from '@/repositories/User';
import VuexStore from '@/store';
import {
  useAgencyStore,
  useAuthStore,
  useBillingStore,
  useChannelsStore,
  useCompanyProfileStore,
  useFeatureFlagStore,
  useSubscriptionStore,
  useUserStore,
  useUsersStore,
} from '@/store/pinia';
import { reMapColonIntoCurlyBracketInTranslation } from '@/util';
import { initializeSegmentAnalytics } from '@/util/analytics/segmentAnalytics';
import { formatDate } from '@/util/date';
import {
  registerBlurEvent,
  registerDragOverEvent,
  registerDropEvent,
  registerFocusEvent,
  unRegisterBlurEvent,
  unRegisterDragOverEvent,
  unRegisterDropEvent,
  unRegisterFocusEvent,
} from '@/util/eventListeners';
import PusherHelper from '@/util/PusherHelper';
import { createToolbarElement } from '@/util/toolbar';
import UsersUtil from '@/util/Users';

import type { Channel, User } from '@/types';

const i18n = createI18n({
  locale: 'en-GB',
  fallbackLocale: 'fallback',
});

export default defineComponent({
  name: 'App',
  mixins: [notifyMixin, pusherMixin],

  data() {
    return {
      aggregates: null,
      tickets: null,
      ticket: null,
      notifyCount: 0,
      localeCodes: {
        'en-GB': 'English',
        'nl-NL': 'Dutch',
        'de-DE': 'German',
        'es-ES': 'Spanish',
        'fr-FR': 'French',
        'ar-AR': 'Arabic',
        'id-ID': 'Indonesian',
        'pt-BR': 'Brazilian Portuguese',
        'ru-RU': 'Russian',
        'it-IT': 'Italian',
      },
      voipReady: false,
      loadedFromApp: window.isLoadedFromApp,
      waEnabled: window.HWA === true,
      prettyDates: false,
      pendingBulk: false,
      mobileDevice: window.is_mobile_device(),
      anonymousModule: window.App.anonymousModule,
      isDesktopApp: window.isElectron,
      agencyChannel: {},
      userChannel: {},
      hasFocus: true,
      stepCompleteAudio: new Audio('/sound/other/coin.mp3'),
      voipPanelOpen: false,
      hasAcccessToVoipChannel: false,
      hasAcccessToChatChannel: false,
      lastSleepCheck: null,

      // initialDataRootAliases
      user: {} as User,
      allChannels: null as Channel[] | null,
      channels: null as Channel[] | null,
      users: null,
      teams: null,
      contactGroups: null,
      labels: null,
      customTicketFields: null,
      customProfileFields: null,
      customUserFields: null,
      customContactFields: null,
      ticketResults: null,
      accessToken: null,
      localeCodesList: null,
      onboarding: null,
      isInOnboardingFlow: false,
      translations: null,
      countries: null,
      plugins: null,
      doNotDisturb: null,
      customViews: null,
      legacyPlan: null,
      hasHelpcenter: null,
      companyProfile: {},
      agency: {},
      usedShortCut: false,
      agencyHasChatChannel: false,
      agencyHasVoipChannel: false,
      surveys: null,
      assetsURL: '',
      // end initialDataRootAliases
    };
  },

  computed: {
    // pinia
    ...mapState(useAuthStore, ['isAuthenticated']),

    // vuex
    ...mapGetters({
      shouldRedirectToMFASetup: 'initialData/shouldRedirectToMFASetup',
    }),
    documentTitle() {
      const total = this.notifyCount; /*Tickets*/

      return total ? '(' + total + ') Trengo' : 'Trengo';
    },
  },

  watch: {
    user: {
      handler(newUser) {
        const userStore = useUserStore();
        userStore.updateUser(newUser);
      },
      deep: true,
    },
    isAuthenticated(value) {
      if (value) {
        this.initApplication();
      }
    },
    // FIXME: https://linear.app/trengo/issue/TFA-25/create-global-locales-handler-for-the-monolith
    translations(value) {
      if (this.$i18n && this.user) {
        const messages = reMapColonIntoCurlyBracketInTranslation(value);
        this.$i18n.locale = this.user.locale_code;
        this.$i18n.setLocaleMessage(this.user.locale_code, messages);
        this.$i18n.setLocaleMessage('fallback', messages.fallback);
        this.localeCodes = {
          'en-GB': this.$t('user_profile.language_en_GB'),
          'nl-NL': this.$t('user_profile.language_nl_NL'),
          'de-DE': this.$t('user_profile.language_de_DE'),
          'es-ES': this.$t('user_profile.language_es_ES'),
          'fr-FR': this.$t('user_profile.language_fr_FR'),
          'ar-AR': this.$t('user_profile.language_ar_AR'),
          'id-ID': this.$t('user_profile.language_id_ID'),
          'pt-BR': this.$t('user_profile.language_pt_BR'),
          'ru-RU': this.$t('user_profile.language_ru_RU'),
          'it-IT': this.$t('user_profile.language_it_IT'),
        };
      }
    },
    documentTitle() {
      document.title = this.documentTitle;
    },
  },

  beforeMount() {
    this.handleSleepCheck();
    this.handleToolbar();
    this.initApplication();
  },
  async created() {
    this.prettyDates = await this.$tStorage.getItem('prettyDates');
  },
  beforeUnmount() {
    this.unRegisterAppEventListeners();
    try {
      this.agencyChannel.unbind();
      this.userChannel.unbind();
      window.PusherInstance.unsubscribe('private-agency-' + this.companyProfile.profile.agency_id);
      window.PusherInstance.unsubscribe('private-user-' + this.user.id);
    } catch (e) {
      console.error(e);
    }
  },

  mounted() {
    // if logged in (after beforeMount())
    if (VuexStore.state.initialData.user.id) {
      this.afterInitialDataMounted();
    } else {
      eventBus.$once('initial-data.loaded', this.afterInitialDataMounted);
    }
    this.assetsURL = Assets_path;

    formatDate();

    initializeSegmentAnalytics();
  },

  methods: {
    ...UsersUtil,
    ...OnboardingStepsMethods,
    setCacheRequestFeatureFlag() {
      this.isCacheRequestEnabled = useFeatureFlagStore().isEnabled(FEATURE_FLAG_ACTIVATION.TA_CACHE_REQUEST);
    },
    initiateFeatureFlag() {
      useFeatureFlagStore().updateContext(this.user.id, this.user.agency_id, this.agency.beta_enrollment);
      useFeatureFlagStore().start();
    },
    i18n() {
      return i18n;
    },

    handleSleepCheck() {
      // start interval to check if application was asleep
      this.lastSleepCheck = new Date().getTime();
      window.setInterval(() => {
        if (this.lastSleepCheck < new Date().getTime() - 5000 * 2) {
          console.info('Resuming after sleep');
          eventBus.$emit('interval-resume');
        }
        this.lastSleepCheck = new Date().getTime();
      }, 2000);
    },

    handleToolbar() {
      // show a toolbar for dynamic builds
      if (typeof window.DYNAMIC_BUILD_VERSION !== 'undefined' && window.DYNAMIC_BUILD_VERSION !== '') {
        createToolbarElement();
      }
    },

    async initApplication() {
      if (!this.isAuthenticated) {
        return;
      }

      try {
        const [initialData] = await Promise.all([this.fetchLegacyInitialData(), this.initializePiniaStores()]);
        await this.initializeSubscriptionStore();
        this.onInitialDataLoaded(initialData);
        this.loggedInBeforeMount();
      } catch (error) {
        console.error('App initialisation failed', error);
      }
    },

    async fetchLegacyInitialData() {
      return ticketRepository.loadInitialData();
    },

    async initializePiniaStores() {
      return Promise.allSettled([
        useAgencyStore().init(),
        useBillingStore().init(),
        useCompanyProfileStore().init(),
        useUserStore().init(),
        useUsersStore().init(),
        useChannelsStore().init(),
      ]);
    },

    async initializeSubscriptionStore() {
      const hasSubscriptionPermission = useUserStore().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE);
      const subscriptionStore = useSubscriptionStore();
      const companyProfileStore = useCompanyProfileStore();

      if (hasSubscriptionPermission) {
        subscriptionStore.init();
      } else {
        subscriptionStore.setPricingModel(companyProfileStore.pricingModel); // FIXME: legacy hack, but we really should only have one source of truth for the pricing model, which is available to everyone regardless of permissions
      }
    },

    afterInitialDataMounted(data) {
      if (data) {
        this.setInitialDataRootAliases(data);
      }
      this.loggedInMounted();
    },

    inIframe() {
      try {
        return window.self !== window.top;
      } catch {
        return true;
      }
    },

    setSentryScope(initialData) {
      try {
        Sentry.getCurrentScope().setUser({
          id: initialData.user.id, // id is required by Sentry
          name: initialData.user.full_name,
          email: initialData.user.email,
          locale: initialData.user.locale_code,
          role: initialData.user.role.name,
          agency_id: initialData.company_profile.profile.agency_id,
          agency_name: initialData.company_profile.profile.company_name,
          agency_locale: initialData.company_profile.agency.locale_code,
        });
      } catch (e) {
        console.error('Error setting Sentry scope', e);
      }
    },

    detectMobileApp(user) {
      window.isLoadedFromApp = /*this.isLoadedFromApp =*/ this.inIframe() && document.body.clientWidth < 5000;

      if (window.isLoadedFromApp) {
        mobileAppHandler.init(user);
      }
    },

    onInitialDataLoaded(initialData) {
      this.setSentryScope(initialData);
      this.detectMobileApp(initialData.user);
      this.redirectToSetup2FAIfMandatory();
      this.redirectToComDomain(initialData.user);

      if (initialData.company_profile.subscription.stripe_customer_id) {
        try {
          profitwell('start', { user_id: initialData.company_profile.subscription.stripe_customer_id });
        } catch {}
      }

      VuexStore.dispatch('initialData/init', initialData);
      document.getElementById('TrengoLoader').classList.add('fadeout');

      window.TicketOverviewChannel = window.PusherInstance.subscribe(
        initialData.agency.channel_prefix + '@ticket_overview_channel',
      );

      moment.tz.setDefault(initialData.user.timezone);
      moment.locale(initialData.user.locale_code);
      eventBus.$emit('initial-data.loaded', initialData);
    },

    setInitialDataRootAliases(data) {
      this.user = data.user as User;
      this.allChannels = data.allChannels.filter((c) => c.type !== CHANNEL_TYPE.HELP_CENTER) as Channel[] | null;
      this.channels = data.channels.filter((c) => c.type !== CHANNEL_TYPE.HELP_CENTER) as Channel[] | null;
      this.users = data.users;
      this.teams = data.teams;
      this.contactGroups = data.contact_groups;
      this.labels = data.labels;
      this.customTicketFields = data.ticket_custom_fields;
      this.customProfileFields = data.profile_custom_fields;
      this.customUserFields = data.user_custom_fields;
      this.customContactFields = data.contact_custom_fields;
      this.ticketResults = data.ticket_results;
      this.accessToken = data.access_token;
      this.localeCodesList = data.locale_codes;
      this.onboarding = data.onboarding;
      this.translations = data.translations;
      this.countries = data.countries;
      this.plugins = data.plugins;
      this.doNotDisturb = data.doNotDisturb;
      this.customViews = data.custom_views;
      this.legacyPlan =
        data.company_profile.subscription.trial_ends_at === null &&
        data.company_profile.subscription.plan_id === null &&
        !data.company_profile.subscription.graced;
      this.hasHelpcenter = data.channels.filter((c) => c.type === CHANNEL_TYPE.HELP_CENTER).length > 0;
      this.companyProfile = data.company_profile;
      this.agency = data.agency;
      this.hasAcccessToChatChannel = data.channels.filter((c) => c.type === 'CHAT').length > 0;
      this.agencyHasChatChannel = data.company_profile.has_chat;
      this.agencyHasVoipChannel = data.company_profile.has_voip;
      this.surveys = data.surveys;

      if (data.onboarding) {
        this.isInOnboardingFlow = true;
      }
    },

    loggedInBeforeMount() {
      this.initPusher();
      this.initStore();
    },

    setWindowAgencyVariables() {
      window.CHANNEL_PREFIX = this.agency.channel_prefix;
      window.LOCALE_CODE = this.agency.locale_code;
      window.AGENCY = this.agency;
      window.SUBSCRIPTION = this.agency.trial_ends_at;
      window.HWA = this.agency.enable_whatsapp;
      window.AWA = this.agency.add_wa_contacts;
      window.App.anonymousModule = this.agency.anonymous_module;
    },

    loggedInMounted() {
      this.setWindowAgencyVariables();

      registerDragOverEvent();
      registerDropEvent();
      registerFocusEvent(this.checkWindowFocus);
      registerBlurEvent(this.checkWindowFocus);

      this.registerNotificationHandler();

      this.registerAdminEventHandlers();

      setTimeout(() => {
        this.initActivityHandler();
      }, 1000);

      this.initUserList();

      if (window.isElectron) {
        document.querySelectorAll('a.electron-external').forEach((e) => {
          e.target = '_blank';
        });
      }

      this.initOnboardingSteps();

      this.listenOnboardingSteps();

      // This initializes gamification on the fly. E.g. when user enables gamification after page load.
      eventBus.$on('onboarding-steps-init', () => {
        setTimeout(() => this.initOnboardingSteps(), 1000);
      });

      this.initVitally();
      this.initFaroWebSdk();
      window.USER_TIMEZONE = this.user.timezone;
    },

    async initPusher() {
      this.teamChannel = [];

      // only accessible for current agency
      this.agencyChannel = window.PusherInstance.subscribe('private-agency-' + this.companyProfile.profile.agency_id);
      this.agencyChannel.bind('pusher:subscription_succeeded', () =>
        eventBus.$emit('pusher.private-agency-' + this.companyProfile.profile.agency_id + '.subscribed'),
      );

      // only accessible for current user. 1 on 1 connection with back-end: [Client] <--- [Server]
      this.userChannel = window.PusherInstance.subscribe('private-user-' + this.user.id);
      this.userChannel.bind('pusher:subscription_succeeded', () =>
        eventBus.$emit('pusher.private-user-' + this.user.id + '.subscribed'),
      );

      eventBus.$on('pusher.private-user-' + this.user.id + '.subscribed', () => {
        this.userChannel.bind('NOTIFY', (data) => this.onPusherNotify(data));
      });
    },
    async initStore() {
      this.initUserStore();

      if (this.user.config) {
        VuexStore.commit('userConfig/init', this.user.config);
      }

      if (this.doNotDisturb) {
        VuexStore.commit('misc/setDoNotDisturb', this.doNotDisturb);
      }

      if (this.agency.data) {
        VuexStore.commit('onboardingSteps/setEnabled', this.agency.data.onboarding_steps_enabled);
      }

      VuexStore.dispatch('chat/init');

      // play audio
      this.stepCompleteAudio.load();
    },

    translateOnboardingStep(type, prop) {
      return this.$t('onboarding_steps.' + type.replace('.', '_') + '_' + prop) || type;
    },

    unRegisterAppEventListeners() {
      unRegisterDragOverEvent();
      unRegisterDropEvent();
      unRegisterFocusEvent(this.checkWindowFocus);
      unRegisterBlurEvent(this.checkWindowFocus);
    },

    checkWindowFocus(e) {
      this.hasFocus = e.target.window.hasWindowFocus;
    },

    registerNotificationHandler() {
      TicketOverviewChannel.bind('NOTIFY', (data) => {
        this.onPusherNotify(data);
      });

      //TODO: Duplicate eventBus needs to be removed.
      eventBus.$on('notification-clicked', () => {
        this.notifyCount = 0;
      });

      eventBus.$on('notification-append', () => {
        this.notifyCount++;
      });

      $(window).on('focus', () => {
        this.notifyCount = 0;
      });

      if ('Notification' in window) {
        Notification.requestPermission();
      }
    },

    onPusherNotify(data) {
      try {
        if (!data.message) {
          return;
        }

        const ticket = data.ticket;

        const channels = map(this.channels, 'id');

        if (data?.watchers?.includes(this.user.id)) {
          this.notify(
            data.message.contact,
            data.message.message,
            data.ticket.subject,
            data.ticket.channel_id,
            '/tickets/' + data.ticket.id,
            null,
            true,
          );
        }

        if (ticket.team_id != null && !this.isInTeam(ticket.team_id)) {
          return;
        }

        if (ticket.user_id != null && ticket.user_id != this.user.id) {
          return;
        }

        if (ticket.status == 'OPEN' && !useUserStore().hasPermission(PERMISSION.INBOX__CONVERSATIONS__READ_FOR_TEAM)) {
          return;
        }

        if (!includes(channels, ticket.channel_id) && ticket.team_id == null) {
          return;
        }

        // if channel muted
        if (VuexStore.getters['userConfig/get']('channels.muted').includes(ticket.channel_id)) {
          return;
        }

        if (!document.hasFocus()) {
          this.notifyCount++;
        }

        this.notify(
          data.message.contact,
          data.message.message,
          data.ticket.subject,
          data.ticket.channel_id,
          '/tickets/' + data.ticket.id,
        );
      } catch (e) {
        console.error('Something went wrong sending browser notification', e);
      }
    },

    registerAdminEventHandlers() {
      eventBus.$on('admin.list.changed', (e) => {
        fetchAdminEvents(e.resource).then((res) => (this[e.property] = res.data.data));
      });
    },

    /**
     * @param socket_id
     */
    setLastActivity: function (socket_id) {
      getAuthActivity(socket_id);
    },

    initActivityHandler: function () {
      if (window.isComposerWindow) {
        return;
      }

      setInterval(() => {
        this.setLastActivity();
      }, 300 * 1000);

      this.setLastActivity(window.PusherInstance.connection.socket_id);
    },

    fetchUsers() {
      const isCacheRequestEnabled = useFeatureFlagStore().isEnabled(FEATURE_FLAG_ACTIVATION.TA_CACHE_REQUEST);
      userRepository.fetchAllUsers(isCacheRequestEnabled).then((res) => {
        this.users = res.users;
        this.teams = res.teams;
      });
    },

    initUserList() {
      eventBus.$on('pusher.private-agency-' + this.companyProfile.profile.agency_id + '.subscribed', () => {
        PusherHelper.bindPusherEvent(this.agencyChannel, 'UserOnlineStatusChangedEvent', (_user) => {
          this.fetchUsers();
        });
      });
    },

    isInTeam(team_id) {
      return includes(map(this.user.teams, 'id'), team_id);
    },

    loadAuthorizedChannels() {
      return new Promise((resolve) => {
        fetchChannels().then((res) => {
          this.hasHelpcenter = res.data.data.filter((c) => c.type === CHANNEL_TYPE.HELP_CENTER).length > 0;
          this.channels = res.data.data.filter((c) => c.type !== CHANNEL_TYPE.HELP_CENTER) as Channel[];
          resolve();
        });
      });
    },

    loadAllChannels() {
      return new Promise((resolve) => {
        fetchAllChannels().then((res) => {
          this.allChannels = res.data.data.filter((c) => c.type !== CHANNEL_TYPE.HELP_CENTER) as Channel[];
          resolve();
        });
      });
    },

    initVitally() {
      if (!window.VITALLY_SERVICE_KEY) {
        return;
      }
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://cdn.vitally.io/vitally.js/v1/vitally.js';
      /* prettier-ignore */
      // eslint-disable-next-line
      const callback = function (n, t, r) { for (var i = n[t] = n[t] || [], o = function (r) { i[r] = i[r] || function () { for (var n = [], t = 0; t < arguments.length; t++)n[t] = arguments[t]; return i.push([r, n]) } }, u = 0, c = ["init", "user", "account", "track"]; u < c.length; u++) { o(c[u]) } };
      script.onload = () => {
        callback(window, 'Vitally');
        Vitally.init(window.VITALLY_SERVICE_KEY);
        Vitally.account({
          accountId: this.companyProfile.settings.agency_id,
          traits: {
            name: this.companyProfile.profile.company_name, // required
          },
        });
        Vitally.user({
          userId: this.user.id, // A unique ID assigned by your codebase to each user (i.e. a database ID)
          accountId: this.companyProfile.settings.agency_id, // Same account ID used in Vitally.account above - used to associate the user to the account
          traits: {
            name: this.user.full_name,
            role_id: this.user.role?.id,
            role_name: this.user.role?.name,
            email: this.user.email,
            createdAt: this.user.created_at, // When possible, we recommending setting this to the date the user first started an account with your product
            // Add any other traits here you'd like to attach to your users in Vitally
          },
        });
        Vitally.track({
          event: 'loaded-trengo',
          userId: this.user.id,
          properties: {
            date: new Date().toLocaleString('en-GB'),
          },
        });
        if (typeof Vitally.nps === 'function') {
          Vitally.nps('survey');
        }
      };

      document.head.appendChild(script);
    },

    initFaroWebSdk() {
      if (!window.FARO_API_URL) {
        return;
      }
      const faro = initializeFaro({
        url: window.FARO_API_URL,
        app: {
          name: window.APP_ENV === 'production' ? 'app-fe-prod' : 'app-fe-stg',
          environment: window.APP_ENV,
          // version: window.GHA_COMMIT_SHA, // Ideally we should use semver or the commit sha here
        },
        instrumentations: [], // overwriting default instrumentations,
        dedupe: false, // deduplication
      });

      if (!faro) {
        return;
      }

      faro?.api?.setUser({
        id: '' + this.user.id,
        attributes: {
          agencyId: '' + this.companyProfile.profile.id,
        },
      });
    },

    rememberPrivatePusherChannel(name, value) {
      if (!value) {
        return;
      }
      if (!window.privatePusherChannels) {
        window.privatePusherChannels = {};
      }
      window.privatePusherChannels[name] = value;
    },

    redirectToSetup2FAIfMandatory() {
      if (VuexStore.getters['initialData/shouldRedirectToMFASetup']) {
        this.$router.push({ name: 'setup-2fa' });
      }
    },

    async redirectToComDomain(user) {
      // Ignore on local machines
      if (window.APP_ENV !== 'production') {
        return;
      }

      const sourceDomain = 'app.trengo.eu';
      const targetDomain = 'app.trengo.com';

      // Restore the state when passed to the URL
      try {
        if (this.$route.query.euDomainState) {
          const state = JSON.parse(this.$route.query.euDomainState);
          Object.keys(state).forEach(function (key) {
            this.$tStorage.setItem(key, state[key]);
          });
          this.$router.push(await this.$tStorage.getItem('path_before_redirect'));
        }
      } catch {}

      // Ignore if using Desktop/Mobile or already using the target domain
      if (window.isElectron || window.isLoadedFromApp || window.location.hostname === targetDomain) {
        return;
      }

      // Ignore if not active on the source domain
      if (window.location.hostname !== sourceDomain) {
        return;
      }

      // Redirect right away if already redirected before (this means the state has been transferred before already)
      if (await this.$tStorage.getItem('redirected_to_com_domain')) {
        window.location.href = window.location.href.replace(window.location.hostname, targetDomain);
        return;
      }

      this.$tStorage.setItem('path_before_redirect', window.location.pathname);

      // Determine which localStorage keys should be transferred
      const ls = [
        'sidebar_state',
        user.id + '_me',
        user.id + '_filters.sort',
        user.id + '_teams',
        user.id + '_labels',
        user.id + '_users',
        user.id + '_channels',
        user.id + '_views',
        user.id + '_selected_folder',
        user.id + '_plugins',
        'voip.ip_device',
        'voip.rt_device',
        'voip.op_device',
        'sidebarHeight',
        'mainHeight',
        'path_before_redirect',
        'ticket_channel',
      ];
      const localStorageToTransfer = {};

      this.$tStorage.getAll((value, key) => {
        if (ls.includes(key)) {
          localStorageToTransfer[key] = value;
        }
      });

      // Create a DirectLogin URL and redirect the user
      getDirectLoginUrl().then((res) => {
        this.$tStorage.setItem('redirected_to_com_domain', 1);
        window.location.href =
          'https://' +
          targetDomain +
          '/auth/direct-login/' +
          res.data.token +
          '?euDomainState=' +
          JSON.stringify(localStorageToTransfer).substr(0, 1000); // substr safeguard
      });
    },
  },
});
</script>
