<script type="text/babel">
import 'froala-editor/js/languages/en_gb.js';
import 'froala-editor/js/languages/nl.js';
import 'froala-editor/js/languages/de.js';
import 'froala-editor/js/languages/fr.js';
import 'froala-editor/js/languages/es.js';
import 'froala-editor/js/languages/ru.js';
import 'froala-editor/js/languages/id.js';
import 'froala-editor/js/languages/pt_br.js';
import FroalaEditor from 'froala-editor';

import eventBus from '@/eventBus';
import { froalaLocaleCodes } from '@/util/froalaLocale';

export default {
  name: 'FroalaEditor',
  emits: ['update:modelValue', 'change', 'input'],
  data() {
    return {
      instance: null,
      oldValue: '',
    };
  },

  props: {
    modelValue: {
      type: String,
      default: '',
    },
    stickyToolbar: {
      type: Boolean,
      default: false,
    },
    stickyToolbarOffset: {
      type: Number,
      default: 0,
    },
    bottomToolbar: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: null,
    },
  },
  watch: {
    modelValue(v) {
      if (this.oldValue != v && this.instance.html) {
        this.oldValue = v;
        this.instance.html.set(v);
      }
    },
  },

  unmounted() {
    this.instance.destroy();
  },

  mounted() {
    if (!this.options) {
      var options = [
        'bold',
        'italic',
        'paragraphFormat',
        'textColor',
        'formatOL',
        'formatUL',
        '|',
        'insertTable',
        'insertLink',
        'insertImage',
        'insertVideo',
        '|',
        'undo',
        'redo',
        'clearFormatting',
        'html',
      ];
    } else {
      var options = this.options;
    }

    this.$refs.area.innerHTML = this.modelValue;

    this.instance = new FroalaEditor(this.$refs.area, {
      key: 'pe1G2wF1C1B1C2C7E7C5oCe1ZSc2XHe1Cd1f1KIWCWMJHXCLSwD1D1D1D1F1H4A11B1D6E4==',
      attribution: false,
      videoUpload: false,
      toolbarButtons: options,
      toolbarButtonsXS: options,
      htmlUntouched: true,
      pastePlain: false,
      htmlExecuteScripts: false,
      toolbarButtonsMD: options,
      imageUploadURL: '/client-api/help_center/media',
      placeholderText: this.$t('help_center.write_your_article_placeholder'),
      imageDefaultWidth: 0,
      language: froalaLocaleCodes[this.$root.user.locale_code],
      // toolbarSticky: this.stickyToolbar,
      // toolbarBottom: this.bottomToolbar,
      // toolbarStickyOffset: this.stickyToolbarOffset,
      toolbarBottom: true,
      imageEditButtons: [
        'imageReplace',
        'imageRemove',
        'imageSize',
        '|',
        'imageLink',
        'linkOpen',
        'linkEdit',
        'linkRemove',
        'imageAlt',
      ],
      events: {
        initialized: () => {
          this.instance.el.addEventListener('keydown', (e) => eventBus.$emit('froalaKeyDown', e));
          this.instance.el.addEventListener('keyup', (e) => eventBus.$emit('froalaKeyUp', e));
        },
        contentChanged: () => {
          var v = this.instance.html.get();
          this.oldValue = v;
          this.$emit('input', v);
          this.$emit('update:modelValue', v);
        },
      },
    });
  },
};
</script>

<template>
  <div class="hc-editor">
    <textarea ref="area" cols="30" rows="10"></textarea>
  </div>
</template>

<style lang="scss">
.hc-editor {
  .fr-toolbar {
    border: none !important;
  }

  .fr-box.fr-basic .fr-wrapper {
    border: none !important;
  }
}
</style>
