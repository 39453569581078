<template>
  <div class="step-one">
    <div class="step-one__heading">
      <p v-html="markupSanitizer($t('whatsapp.before_starting'))" />
    </div>
    <div class="must-have">
      <p>{{ $t('whatsapp.to_get_started_make_sure') }}</p>
    </div>
    <ul class="step-one__info">
      <icon-text-list-item v-for="item in listItemRecord" :key="item.id" :bg-color="item.bgColor">
        <template #icon>
          <key-linear v-if="item.icon === 'key'" size="24" />
          <call-received-linear v-else-if="item.icon === 'phone'" size="24" />
          <pin-check-linear v-else-if="item.icon === 'pin'" size="24" />
          <verified-linear v-else-if="item.icon === 'verified'" size="24" />
        </template>
        <template #text><p v-html="$t(item.text)"></p></template>
      </icon-text-list-item>
    </ul>
    <div class="step-one__foot-note">
      <p v-html="markupSanitizer($t('whatsapp.turkish_numbers_are_not_supported'))"></p>
    </div>
  </div>
</template>

<script lang="ts">
import { CallReceivedLinear, KeyLinear, PinCheckLinear, VerifiedLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import markupSanitizer from '@/util/markupSanitizer';

import IconTextListItem from '../IconTextListItem';

import type { ListItem } from '../types';

export default defineComponent({
  name: 'StepOne',
  components: {
    IconTextListItem,
    CallReceivedLinear,
    KeyLinear,
    VerifiedLinear,
    PinCheckLinear,
  },
  data() {
    return {
      listItemRecord: [
        {
          id: 1,
          icon: 'key',
          text: 'whatsapp.access_to_facebook_business_manager',
          bgColor: 'purple',
        },
        {
          id: 2,
          icon: 'phone',
          text: 'whatsapp.suitable_phone_number_for_whatsapp',
          bgColor: 'galaxy',
        },
        {
          id: 3,
          icon: 'pin',
          text: 'whatsapp.legal_address_and_business_details_of_your_company',
          bgColor: 'peach',
        },
        {
          id: 4,
          icon: 'verified',
          text: 'whatsapp.verified_facebook_business_account',
          bgColor: 'sky',
        },
      ] as ListItem[],
    };
  },
  methods: {
    markupSanitizer: markupSanitizer,
  },
});
</script>

<style lang="scss" src="./StepOne.scss" scoped />
