import store from '../../../store';

// the higher the score, the later in the list
const threadListSort = (a, b) => {
  // get data
  const userA = a.getUser();
  const userB = b.getUser();
  let aScore = 0;
  let bScore = 0;

  // points if user doesnt exist
  if (userA?.exists === false) {
    aScore += 1000;
  }
  if (userB?.exists === false) {
    bScore += 1000;
  }

  // points for being muted
  if (a.muted) {
    aScore += 100;
  }
  if (b.muted) {
    bScore += 100;
  }

  /*// 1 point for having less messages
    if(a.userMessageCount < b.userMessageCount) {
        aScore++;
    }
    if(b.userMessageCount < a.userMessageCount) {
        bScore++;
    }*/

  // points for having older last message
  if (a.lastMessageTime < b.lastMessageTime) {
    aScore += 10;
  }
  if (b.lastMessageTime < a.lastMessageTime) {
    bScore += 10;
  }

  // points for alphabetic
  if (a.name.localeCompare(b.name) > 0) {
    aScore++;
  }
  if (b.name.localeCompare(a.name) > 0) {
    bScore++;
  }

  return aScore > bScore ? 1 : -1;
};

const getUserThreadUnreadMessageCount = (userThreads) => {
  let total = 0;
  userThreads.forEach((t) => {
    if (!t.getUser()?.isDeleted()) {
      total += t.getUnreadMessageCount();
    }
  });
  return total;
};

export default {
  currentThread: (state) => {
    return state.threads.find((t) => {
      return t.identifier === state.currentThread;
    });
  },
  userThreads: (state) => {
    return state.threads
      .filter((t) => {
        return t.isUser();
      })
      .sort(threadListSort);
  },
  groupThreads: (state) => {
    return state.threads
      .filter((t) => {
        return t.isGroup();
      })
      .sort(threadListSort);
  },
  threadById: (state) => (threadId) => {
    return state.threads.find((t) => t.id && t.id === parseInt(threadId));
  },
  threadByIdentifier: (state) => (identifier) => {
    return state.threads.find((t) => t.identifier && t.identifier === identifier);
  },
  threadByUserId: (state) => (userId) => {
    return state.threads.find((t) => t.isUser() && t.getUserId() === userId);
  },
  unreadMessages: () => (thread) => {
    if (thread.muted) {
      return [];
    }
    return (
      thread.messages.filter(
        (m) =>
          store.state.usersInternalChat.currentUserId !== m.userId &&
          (!thread.lastRead || m.createdAt >= thread.lastRead)
      ) || []
    );
  },
  totalUnreadMentionsCount: (state, getters) => (type) => {
    let mentionsCount = 0;

    if (type === 'groups') {
      getters.groupThreads.forEach((t) => (mentionsCount += t.getUnreadMentionsCount()));
    } else if (type === 'users') {
      // consider dms mentions
      mentionsCount += getUserThreadUnreadMessageCount(getters.userThreads);
    } else {
      getters.groupThreads.forEach((t) => (mentionsCount += t.getUnreadMentionsCount()));
      // consider dms mentions
      mentionsCount += getUserThreadUnreadMessageCount(getters.userThreads);
    }

    return mentionsCount;
  },
  totalUnreadMessageCount: (state, getters) => (type) => {
    let total = 0;

    if (type === 'groups') {
      getters.groupThreads.forEach((t) => (total += t.getUnreadMessageCount()));
    } else if (type === 'users') {
      total += getUserThreadUnreadMessageCount(getters.userThreads);
    } else {
      getters.groupThreads.forEach((t) => (total += t.getUnreadMessageCount()));
      total += getUserThreadUnreadMessageCount(getters.userThreads);
    }

    return total;
  },

  messageById: () => (thread, messageId) => {
    if (!thread || !messageId) {
      return;
    }
    return thread.messages.find((m) => m.id === messageId);
  },
  chatStatus: () => (user) => {
    return user.isOnline
      ? (user.isCurrentUser() ? !!store.getters['userConfig/get']('internal_chat.online') : user.chatStatus)
        ? 'online'
        : 'away'
      : 'offline';
  },
};
