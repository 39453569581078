import plugins from './plugins';
import rest from './rest';
import webhooks from './webhooks';
import zapier from './zapier';

import type { RouteRecord } from '@/routes/types';

const legacyRoutes: RouteRecord[] = [...webhooks, ...rest, ...zapier, ...plugins];

export default legacyRoutes;
