<template>
  <t-modal v-if="modalInfo" v-model="shouldOpenModal" variant="narrow" :title="modalInfo.title" @close="$emit('close')">
    <div class="t-text-md mb-6 text-grey-700">
      {{ modalInfo.detail }}
    </div>

    <div v-if="modalInfo.subDetail" class="t-text-sm mb-6 text-grey-600">
      {{ modalInfo.subDetail }}
    </div>

    <template #footer>
      <t-button btn-style="primary" @click="$emit('close')">Got it!</t-button>
    </template>
  </t-modal>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
});

defineEmits(['close']);

const modalInfos = {
  upgrade: {
    title: 'Thank you for using Trengo',
    detail: 'We are updating your subscription. Your changes will take effect immediately.',
    subDetail: null,
  },
  downgrade: {
    title: 'Changes scheduled',
    detail:
      'Your changes will take effect at the start of the next billing cycle. You can continue to use your current subscription until then.',
    subDetail:
      'Scheduled downgrades can be cancelled before the end of the current billing cycle. Your new rate will be charged at the start of the next billing cycle.',
  },
};

const modalInfo = computed(() => modalInfos[props.type]);
const shouldOpenModal = computed(() => props.isOpen);
</script>
