<template>
  <div class="box">
    <div class="box-header">
      <h2>{{ $t('whatsapp.verification_needed') }}</h2>
    </div>
    <div class="box-divider m-a-0"></div>
    <div class="px-4 py-6">
      <div class="t-text-desktop-paragraph-md mb-6 text-grey-800">
        {{ $t('whatsapp.verify_after_30_days_of_trial') }}
      </div>
      <a
        href="https://www.facebook.com/business/help/2058515294227817?id=180505742745347"
        class="open-external"
        target="_blank"
      >
        <atomic-button>
          <div>{{ $t('whatsapp.verify_your_business') }}</div>
        </atomic-button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WabVerificationReminder',
};
</script>
