<script type="text/babel">
export default {
  data() {
    return {
      saving: false,
      password: null,
      password_confirmation: null,
      current_password: null,
    };
  },

  methods: {
    save() {
      if (this.saving == true) {
        return;
      }

      this.saving = true;

      let data = new FormData();
      data.append('current_password', this.current_password);
      data.append('new_password', this.password);
      data.append('new_password_confirmation', this.password_confirmation);

      axios
        .post('/client-api/me/password', data)
        .then((res) => {
          this.password = null;
          this.password_confirmation = null;
          this.current_password = null;
          this.flashSuccess(this.$t('user_profile.password_saved_successfully'));
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        })
        .catch(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <div class="box">
        <div class="box-header b-b flow-root">
          <h2>{{ $t('user_profile.general_change_password') }}</h2>
        </div>
        <div class="box-body">
          <form @submit.prevent="save">
            <div class="form-group row">
              <label class="col-md-3 form-control-label">{{ $t('user_profile.password_current') }}</label>
              <div class="col-md-9">
                <input
                  v-model="current_password"
                  class="form-control"
                  :placeholder="$t('user_profile.password_current')"
                  type="password"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 form-control-label">{{ $t('user_profile.password_new') }}</label>
              <div class="col-md-9">
                <input
                  v-model="password"
                  class="form-control"
                  :placeholder="$t('user_profile.password_new')"
                  type="password"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 form-control-label">{{ $t('user_profile.password_confirm_new') }}</label>
              <div class="col-md-9">
                <input
                  v-model="password_confirmation"
                  class="form-control"
                  :placeholder="$t('user_profile.password_confirm_new')"
                  type="password"
                />
              </div>
            </div>
            <div class="form-grou row form-actions">
              <div class="col-md-3 offset-md-3">
                <button type="submit" class="btn success" :disabled="saving">
                  <span v-if="!saving">{{ $t('user_profile.password_save') }}</span>
                  <span v-if="saving"><i class="fa fa-spin fa-spinner"></i></span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
