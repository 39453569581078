import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_uninstall_modal = _resolveComponent("uninstall-modal")!

  return (_openBlock(), _createBlock(_component_uninstall_modal, {
    "in-progress": _ctx.inProgress,
    installation: _ctx.installation,
    integration: _ctx.integration,
    onClose: _ctx.closeModal,
    onUninstall: _ctx.uninstall
  }, null, 8 /* PROPS */, ["in-progress", "installation", "integration", "onClose", "onUninstall"]))
}