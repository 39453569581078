import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mb-8 flex flex-row items-center" }
const _hoisted_2 = { class: "flex h-44px w-44px items-center justify-center rounded-xl bg-galaxy-600 p-2 sm:h-48px sm:w-48px" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "ml-4 flex flex-col" }
const _hoisted_5 = { class: "t-text-mobile-body-md sm:t-text-button-lg sm:mb-1" }
const _hoisted_6 = { class: "t-text-desktop-body-xs sm:t-text-desktop-body-sm text-grey-800" }
const _hoisted_7 = { class: "mb-4 grid xs3:grid-cols-2 xs3:place-items-center xs3:gap-4 xs3:px-8 xs:grid-cols-4 xs:place-items-start xs:gap-6 xs:px-0 sm:mb-3 lg:gap-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_user_modal = _resolveComponent("add-user-modal")!
  const _component_add_user = _resolveComponent("add-user")!
  const _component_gray_card = _resolveComponent("gray-card")!

  return (_openBlock(), _createBlock(_component_gray_card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "w-24",
            src: `${_ctx.$root.assetsURL}img/users_white.svg`
          }, null, 8 /* PROPS */, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('getting_started.title_invite_a_colleague')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('getting_started.subtitle_invite_a_colleague')), 1 /* TEXT */)
        ])
      ]),
      (_ctx.openAddUser && _ctx.userStore.hasPermission(_ctx.PERMISSION.PERMISSIONS__USERS__MANAGE))
        ? (_openBlock(), _createBlock(_component_add_user_modal, {
            key: 0,
            modelValue: _ctx.openAddUser,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openAddUser) = $event)),
            onClose: _ctx.closeAddUserModal
          }, null, 8 /* PROPS */, ["modelValue", "onClose"]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
          return (_openBlock(), _createBlock(_component_add_user, {
            key: user.id,
            user: user,
            onClick: _ctx.handleOpenModal
          }, null, 8 /* PROPS */, ["user", "onClick"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", {
          class: "t-text-button-md cursor-pointer text-leaf-500 hover:underline",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleOpenModal && _ctx.handleOpenModal(...args)))
        }, _toDisplayString(_ctx.$t('getting_started.button_invite_a_colleague')), 1 /* TEXT */)
      ])
    ]),
    _: 1 /* STABLE */
  }))
}