<template>
  <div class="grid gap-4 pb-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl3:grid-cols-4">
    <router-link
      v-for="integration in integrations"
      :key="integration.name"
      :to="`/admin/integrations/${integration.filterType}/${integration.id}`"
      data-test="integration-card-link-to-details"
    >
      <t-card class="h-full gap-3 bg-white" :focusable="true">
        <div class="flex flex-row">
          <integration-card-icon :icon="integration.icon" class="mb-4 rounded-md" />
          <t-badge
            v-if="integration.isInstalled"
            class="ml-auto flex flex-row items-center"
            variant="success"
            :text="$t('integration_hub.badge_installed')"
            data-test="integration-card-installed-badge"
          >
            <template #icon>
              <check-circle-linear size="1rem" class="mr-0.5" />
            </template>
          </t-badge>

          <t-badge
            v-else-if="!isEntitledToPremium && integration.is_premium"
            class="ml-auto flex flex-row items-center"
            variant="upgrade"
            :text="$t(badgeText)"
            data-test="integration-card-enterprise-badge"
          >
            <template #icon>
              <arrow-top-circle-linear size="1rem" class="mr-0.5" />
            </template>
          </t-badge>
        </div>

        <div>
          <h1 class="t-text-lg-emphasize mb-0" data-test="integration-card-name">{{ integration.name }}</h1>
          <p class="mb-0 text-grey-600" data-test="integration-card-description">
            {{ $tc(integration.shortDescription) }}
          </p>
        </div>
      </t-card>
    </router-link>
  </div>
</template>

<script lang="ts">
import { ArrowTopCircleLinear, CheckCircleLinear } from '@trengo/trengo-icons';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import IntegrationCardIcon from '@/components/IntegrationHub/Components/IntegrationCardIcon.vue';
import { FEATURE } from '@/Configs/Constants/Feature';
import { useCompanyProfileStore, useEntitlementsStore } from '@/store/pinia';

import type { Integration } from '@/store/types/integrations';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'IntegrationCards',

  components: {
    ArrowTopCircleLinear,
    CheckCircleLinear,
    IntegrationCardIcon,
  },

  computed: {
    ...mapStores(useCompanyProfileStore, useEntitlementsStore),
    isEntitledToPremium() {
      return this.entitlementsStore?.isEntitledToEnforce(FEATURE.CUSTOMER_SERVICE__INBOX__INTEGRATIONS__PREMIUM);
    },
  },

  props: {
    integrations: {
      type: Array as PropType<Integration[]>,
      default: () => [],
    },
  },

  data() {
    return {
      badgeText: '',
    };
  },

  async mounted() {
    try {
      if (this.companyProfileStore.isSeatBasedPricingModel) {
        this.badgeText = 'integration_hub.badge_enterprise';
      } else if (this.companyProfileStore.isUsageBasedPricingModel) {
        this.badgeText = 'integration_hub.badge_pro';
      }
    } catch (e) {
      console.error(e);
    }
  },
});
</script>
