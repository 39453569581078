import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center" }
const _hoisted_2 = { class: "t-text-sm-emphasize m-0 p-0 text-grey-700" }
const _hoisted_3 = {
  key: 0,
  class: "my-2 w-full border-b-2 border-grey-300 border-opacity-20"
}
const _hoisted_4 = {
  key: 1,
  class: "flex flex-row items-center"
}
const _hoisted_5 = {
  class: "t-text-md-emphasize m-0 p-0 text-grey-800",
  "data-test": "timetracking-total-time"
}
const _hoisted_6 = {
  key: 2,
  class: "mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clock_linear = _resolveComponent("clock-linear")!
  const _component_time_entry = _resolveComponent("time-entry")!
  const _component_timer2_linear = _resolveComponent("timer2-linear")!
  const _component_time_entry_card = _resolveComponent("time-entry-card")!
  const _component_t_spinner = _resolveComponent("t-spinner")!
  const _component_sidebar_dropdown = _resolveComponent("sidebar-dropdown")!

  return (_openBlock(), _createBlock(_component_sidebar_dropdown, {
    title: _ctx.$tc('ticket_sidebar.time_tracking'),
    "collapse-key": "timetrackingDropdown",
    "data-test": "timetracking-dropdown",
    onToggled: _ctx.setToggleState,
    onMounted: _ctx.setToggleState
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_clock_linear, {
          size: "1.1rem",
          class: "mr-2 text-grey-700"
        }),
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$tc('ticket_sidebar.time_tracking_working_time')), 1 /* TEXT */)
      ]),
      _createVNode(_component_time_entry, {
        class: "mt-2 flex flex-col",
        "ticket-id": _ctx.ticket.id,
        onAddLogEntry: _ctx.addLogEntry
      }, null, 8 /* PROPS */, ["ticket-id", "onAddLogEntry"]),
      (_ctx.logs.length > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3))
        : _createCommentVNode("v-if", true),
      (_ctx.logs.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_timer2_linear, {
              class: "mr-2",
              size: "1.3rem"
            }),
            _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.$tc('ticket_sidebar.time_tracking_total_time')) + ": " + _toDisplayString(_ctx.totalTrackedTime), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true),
      (!_ctx.isLoading && _ctx.logs.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reversedLogs, (entry) => {
              return (_openBlock(), _createBlock(_component_time_entry_card, {
                key: entry.id,
                class: "mb-1",
                entry: entry,
                "user-id": entry.user_id,
                "ticket-id": _ctx.ticket.id,
                onDeleteEntry: ($event: any) => (_ctx.deleteEntry(entry)),
                onEditEntry: _ctx.editEntry
              }, null, 8 /* PROPS */, ["entry", "user-id", "ticket-id", "onDeleteEntry", "onEditEntry"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_t_spinner, {
              key: 3,
              class: "m-auto",
              size: "1.5rem"
            }))
          : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "onToggled", "onMounted"]))
}