import { request } from '@/util';

import type { TicketInsightsResponses } from './utils';
import type { AxiosPromise } from 'axios';

export type Sort = {
  order_by: string;
  sort_order: 'asc' | 'desc';
};

const baseURL = 'api/v2/reporting/';

type Columns = {
  columns: string[];
};

export const fetchTicketInsights = (
  ticketUrl: string,
  page: number,
  filter: string,
  sort?: Sort
): AxiosPromise<TicketInsightsResponses> => {
  let URL = `api/v2/reporting/${ticketUrl}?page=${page}${filter ? '&' + filter : ''}`;
  if (sort && sort.order_by && sort.order_by) {
    URL += `&sort_by=${sort.order_by}&sort_order=${sort.sort_order}`;
  }
  return request(URL, 'GET');
};

export const callExportAPI = (columns: Columns, sort?: Sort, filters?: string): AxiosPromise => {
  const serializedFilters = filters && parseFilters(filters);
  let URL = `${baseURL}export?${serializedFilters ? serializedFilters : ''}`;
  if (columns) {
    const queryString = columns.columns.map((column: string) => `columns[]=${column}`).join('&');
    URL += `&${queryString}`;
  }
  if (sort && sort.order_by && sort.order_by) {
    URL += `&sort_by=${sort.order_by}&sort_order=${sort.sort_order}`;
  }
  return request(URL, 'GET');
};

function parseFilters(filters: string) {
  let serializedFilters = '';
  const filtersObject = JSON.parse(filters);

  for (const key in filtersObject) {
    const currentFilter = filtersObject[key];
    let filterString;
    if (typeof currentFilter === 'object') {
      filterString = currentFilter.map((filter: number | string) => `${key}[]=${filter}`).join('&');
    } else {
      filterString = `${key}=${currentFilter}`;
    }
    serializedFilters = `${serializedFilters ? serializedFilters + '&' : ''}${filterString}`;
  }
  return serializedFilters;
}
