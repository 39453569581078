<template>
  <button
    class="mb-2 mt-1 flex items-center justify-center self-start font-semibold"
    data-test="attachmentCollapseToggleButton"
    @click="toggleAttachments"
  >
    {{ $tc('attachment.attachment_count', attachmentCount, { count: attachmentCount }) }}
    <span class="ml-1 text-xs" data-test="attachmentCollapseStatusSymbol">{{ areAttachmentsVisible ? '▼' : '▲' }}</span>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FileCount',
  emits: ['toggle-attachments'],
  props: {
    attachmentCount: {
      type: Number,
      default: 0,
    },
    areAttachmentsVisible: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    toggleAttachments() {
      this.$emit('toggle-attachments');
    },
  },
});
</script>
