export default {
  send(message) {
    return axios.post('/api/v2/internal_chat/messages', { ...message });
  },

  update(message) {
    return axios.put('/api/v2/internal_chat/messages/' + message.id, { ...message });
  },

  delete(messageId, threadId) {
    return axios.delete('/api/v2/internal_chat/messages/' + messageId, { data: { thread_id: threadId } });
  },

  pin(messageId, value) {
    return axios.post('/api/v2/internal_chat/messages/' + messageId + '/pin', { value: !!value });
  },

  star(messageId, value) {
    return axios.post('/api/v2/internal_chat/messages/' + messageId + '/star', { value: !!value });
  },

  listBefore(messageId, threadId) {
    return axios.get(`/api/v2/internal_chat/messages?direction=before&threadId=${threadId}&id=${messageId}`);
  },

  listAfter(messageId, threadId) {
    return axios.get(`/api/v2/internal_chat/messages?direction=after&threadId=${threadId}&id=${messageId}`);
  },

  listAround(messageId, threadId) {
    return axios.get(`/api/v2/internal_chat/messages?direction=around&threadId=${threadId}&id=${messageId}`);
  },

  search(search, page) {
    return axios.get('/api/v2/internal_chat/messages?query=' + encodeURIComponent(search) + '&page=' + page);
  },

  postReaction(messageId, emoji) {
    return axios.post('/api/v2/internal_chat/messages/' + messageId + '/reaction', { emoji: emoji });
  },

  deleteReaction(messageId, emoji) {
    return axios.delete('/api/v2/internal_chat/messages/' + messageId + '/reaction', { data: { emoji: emoji } });
  },
};
