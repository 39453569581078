<script>
import _ from 'lodash';

export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      collapsed: true,
    };
  },
  computed: {
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    addEmailCustomField() {
      if (this.record.email.custom_fields == null) {
        this.record.email.custom_fields = [];
      }

      var title = {};
      _.each(this.record.languages, (k) => {
        title[k] = '';
      });
      this.record.email.custom_fields.push({
        title: title,
      });
    },
  },
};
</script>

<template>
  <div class="box">
    <div class="box-header b-b">
      <h3>{{ $t('web_widget.email') }}</h3>
    </div>
    <div class="box-body">
      <div class="form-group row">
        <label class="col-md-2 form-control-label">{{ $t('web_widget.enabled') }}</label>
        <div class="col-md-10">
          <p class="form-control-static">
            <label class="ui-switch success m-t-xs m-r">
              <input v-model="record.email.enabled" type="checkbox" />
              <i></i>
            </label>
          </p>
        </div>
      </div>
      <div v-if="record.email.enabled">
        <div class="form-group row">
          <label class="col-md-2 form-control-label">{{ $t('web_widget.recipient_email') }}</label>
          <div class="col-md-10">
            <input v-model="record.email.email" type="email" class="form-control" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 form-control-label">{{ $t('web_widget.custom_fields') }}</label>
          <div class="col-md-10">
            <table class="b-a table">
              <tbody>
                <tr v-for="(custom_field, i) in record.email.custom_fields">
                  <td width="30%">
                    <label>{{ $t('web_widget.title') }}</label>
                    <div v-for="lang in record.languages">
                      <div class="input-group">
                        <div class="input-group-addon" style="width: 45px; background: #ececec">{{ lang }}</div>
                        <input v-model="custom_field.title[lang]" type="text" class="form-control" />
                      </div>
                    </div>
                  </td>
                  <td width="30%">
                    <div class="translation-input">
                      <label>{{ $t('web_widget.sort_order') }}</label>
                      <input v-model="custom_field.sort_order" type="number" class="form-control" />
                    </div>
                  </td>
                  <td width="30%">
                    <div style="margin-top: 30px">
                      <label class="checkbox">
                        <input v-model="custom_field.required" type="checkbox" value="1" />
                        {{ $t('web_widget.required') }}
                      </label>
                    </div>
                  </td>
                  <td width="10%" align="right">
                    <a
                      style="margin-top: 30px"
                      class="btn btn-xs btn-danger"
                      @click="record.email.custom_fields.splice(i, 1)"
                    >
                      <i class="fa fa-remove text-white"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4">
                    <span style="cursor: pointer" @click="addEmailCustomField()">
                      <i class="fa fa-plus"></i>
                      {{ $t('web_widget.add_custom_field') }}
                    </span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
