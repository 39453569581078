<template>
  <div class="rule-card">
    <div class="flex flex-col">
      <div class="flex flex-row items-center">
        <div
          class="flex flex-grow flex-row items-center"
          :class="{
            pointer: amountRules > 1,
          }"
          @click="expanded = !expanded"
        >
          <h6 v-if="amountRules === 1">
            {{ $t('csat.rule_name') }}
          </h6>
          <ActiveIndicator v-if="amountRules > 1" :is_active="rule.active" :show_label="false"></ActiveIndicator>
          <h5>{{ rule.name }}</h5>
        </div>
        <i v-if="amountRules > 1 && !expanded" class="far fa-2x fa-angle-up pointer" @click="expanded = !expanded"></i>
        <i v-if="amountRules > 1 && expanded" class="far fa-2x fa-angle-down pointer" @click="expanded = !expanded"></i>
      </div>
      <transition name="rule" mode="out-in">
        <div v-show="amountRules === 1 || expanded">
          <div class="mb-3 mt-3 flex flex-row">
            <h6>{{ $t('csat.rule_status') }}</h6>
            <ActiveIndicator :is_active="rule.active"></ActiveIndicator>
          </div>
          <div class="mb-3 flex flex-row">
            <h6>{{ $t('csat.rule_channels') }}</h6>
            <div class="flex flex-col">
              <p v-for="channel in rule.channels" :key="channel.id" class="channel">
                {{ channel.display_name }}
              </p>
            </div>
          </div>
          <div class="mb-4 flex flex-row">
            <h6>{{ $t('csat.rule_when') }}</h6>
            <div class="flex flex-col">
              <p v-for="trigger in rule.triggers" :key="trigger.trigger">
                {{ trigger.name }}
              </p>
            </div>
          </div>
          <div class="border-top mb-3 flex flex-row">
            <h6>{{ $t('csat.rule_conditions') }}</h6>
            <div class="flex flex-col">
              <div v-for="statement in rule.statements" :key="statement.trigger">
                <p>{{ conditionsText(statement.conditions) }}</p>
              </div>
            </div>
          </div>
          <div class="border-top flex flex-row">
            <h6>{{ $t('csat.rule_actions') }}</h6>
            <div class="flex flex-col">
              <p v-for="action in rule.actions" :key="action.type">
                {{ actionText(action) }}
              </p>
            </div>
          </div>
          <div class="flex flex-row">
            <atomic-button size="md" class="mt-8" @click="editRule(rule.id)">
              {{ $t('csat.edit') }}
            </atomic-button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import ActiveIndicator from './ActiveIndicator';
import { field, operator, type } from '../../enums/rules';
export default {
  name: 'RuleCard',
  components: {
    ActiveIndicator,
    field,
  },
  data: function () {
    return {
      fieldEnum: field,
      operatorEnum: operator,
      typeEnum: type,
      expanded: true,
    };
  },
  props: {
    rule: {
      type: Object,
      default: {},
    },
    csatId: {
      type: Number,
    },
    amountRules: {
      type: Number,
    },
  },
  methods: {
    conditionsText(conditions) {
      return conditions
        .map((condition) => {
          if (condition.payload instanceof Object) {
            if (condition.field === 'contact.group' || condition.field === 'contact.profile') {
              return (
                this.fieldEnum[condition.field] +
                ' ' +
                this.operatorEnum[condition.operator] +
                ' ' +
                condition.payload
                  .map(function (value) {
                    return value.name;
                  })
                  .join(', ')
              );
            }
            return (
              this.fieldEnum[condition.field] +
              ' ' +
              condition.payload.amount +
              ' ' +
              condition.payload.interval.toLowerCase() +
              ' ' +
              this.operatorEnum[condition.operator]
            );
          } else if (condition.meta) {
            return (
              this.fieldEnum[condition.field] +
              ' ' +
              condition.meta +
              ' ' +
              this.operatorEnum[condition.operator] +
              ' ' +
              condition.payload
            );
          } else {
            return (
              this.fieldEnum[condition.field] +
              ' ' +
              this.operatorEnum[condition.operator] +
              ' ' +
              condition.payload.toString().toLowerCase()
            );
          }
        })
        .join(' or ');
    },
    actionText(action) {
      if (action.payload instanceof Object && Array.isArray(action.payload)) {
        return (
          this.typeEnum[action.type] +
          ' ' +
          action.payload
            .map((payload) => {
              return payload.name;
            })
            .join(', ')
        );
      } else if (action.payload instanceof Object) {
        return this.typeEnum[action.type] + ' ' + action.payload.name;
      } else if (action.payload) {
        return this.typeEnum[action.type] + ' ' + action.payload;
      } else {
        return this.typeEnum[action.type];
      }
    },
    editRule(id) {
      this.$router.push({
        name: 'ruleEditFromCsat',
        params: { sentFromCsat: true, id: id, csatId: this.csatId },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.rule-card {
  background: var(--color-grey-100);
  border-radius: 12px;
  padding: 1rem 1.5rem;
  margin-top: 1rem;
  h5 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }
  h6 {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    min-width: 96px;
    margin: 0;
  }
  .active-label {
    line-height: 24px;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    &.channel:not(:first-child) {
      margin-top: 0.5rem;
    }
  }
  .border-top {
    border-top: 1px solid var(--color-grey-300);
    padding-top: 1rem;
  }
  i {
    color: var(--color-grey-800);
  }
  .rule-enter-active {
    transition: all 0.2s ease;
    max-height: 400px;
  }
  .rule-leave-active {
    transition: all 0.1s ease;
    max-height: 400px;
  }
  .rule-enter-from,
  .rule-leave-to {
    opacity: 0;
    max-height: 0px;
  }
  .rule-enter-to,
  .rule-leave-from {
    opacity: 1;
  }
}
</style>
