<template>
  <div class="users-list-content-loader" :style="{ maxWidth }"></div>
</template>

<script>
export default {
  name: 'UsersListContentLoader',
  props: {
    maxWidth: {
      type: String,
      default: '100%',
    },
  },
};
</script>

<style scoped lang="scss" src="./UsersListContentLoader.scss" />
