<template>
  <get-started-card :is-done="isDone" data-test="connect-first-channel">
    <template #title>{{ $t('getting_started.connect_your_first_channel') }}</template>
    <template #description>{{ $t('getting_started.start_receiving_messages_in_trengo') }}</template>
    <template #icon>
      <img class="w-48px rounded-2xl" :src="`${$root.assetsURL}img/get_started_page/message.svg`" />
    </template>
    <div class="sm:px-2">
      <div class="flex h-32px items-center justify-between" data-test="item-added-first-channel">
        <div class="flex flex-row">
          <img :src="stateImage" class="mr-2" />
          <span :class="[stateTextColor]" class="t-text-desktop-label-sm mr-2 sm:mr-0">
            {{ $t('getting_started.connect_your_first_channel') }}
          </span>
        </div>
        <atomic-button
          v-if="!connectFirstChannel"
          btn-style="secondary"
          size="xs"
          class="ml-auto inline-flex h-32px items-center px-4 text-sm leading-4"
          @click="handleCreateChannelModal"
        >
          <span class="t-text-desktop-label-sm mr-1 text-grey-900">{{ $t('getting_started.start') }}</span>
          <img :src="`${$root.assetsURL}img/arrow_right.svg`" class="w-16px" />
        </atomic-button>
      </div>
    </div>
    <add-channel-modal v-model="openAddChannel" />
  </get-started-card>
</template>

<script>
import { mapGetters } from 'vuex';

import AddChannelModal from '@/components/OverviewPage/components/AddChannel/AddChannelModal';
import eventBus from '@/eventBus';

import GetStartedCard from '../GetStartedCard';

export default {
  name: 'ConnectFirstChannel',
  components: { AddChannelModal, GetStartedCard },
  data() {
    return {
      openAddChannel: false,
      connectFirstChannel: false,
      isDone: false,
    };
  },
  computed: {
    ...mapGetters({
      getStepByType: 'gamification/getStepByType',
    }),
    stateImage() {
      return this.connectFirstChannel
        ? this.$root.assetsURL + 'img/get_started_page/successful.svg'
        : this.$root.assetsURL + 'img/get_started_page/unsuccessful.svg';
    },
    stateTextColor() {
      return this.connectFirstChannel ? 'state-text-grey-500' : 'state-text-grey-800';
    },
  },
  mounted() {
    this.connectFirstChannel = this.getStepByType('user.add_first_channel')?.completed;
    this.isDone = this.connectFirstChannel;
    eventBus.$on('choose-channel', this.handleChooseChannel);
  },
  unmounted() {
    eventBus.$off('choose-channel', this.handleChooseChannel);
  },
  methods: {
    handleChooseChannel({ type, id }) {
      this.$store.dispatch('onboardingSteps/putInterestsSelected', [type]);
      let route;
      if (type === 'help_center') {
        route = `/help_center`;
      } else {
        route = `/admin/channels2/${id}`;
      }
      eventBus.$emit('close-overview-modal');

      eventBus.$off('choose-channel', this.handleChooseChannel);
      setTimeout(() => {
        router.push(route);
        eventBus.$off('close-overview-modal');
      }, 200);
    },
    handleCreateChannelModal() {
      this.openAddChannel = true;
    },
  },
};
</script>
<style scoped src="../styles.scss" lang="scss" />
