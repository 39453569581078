<template>
  <div class="flex flex-1">
    <portal to="sidebar">
      <div v-if="hasAdminPermission" class="get-started-page-item mt-2 border-b-1 border-grey-300 px-4 pb-4">
        <div>
          <router-link
            data-test="get-started-link"
            to="/admin/get-started"
            class="row flex items-center justify-between pl-2 pr-3"
          >
            <div class="row flex cursor-pointer items-center lg:ml-0.5">
              <question-fill size="1rem" />
              <span class="t-text-desktop-h7 ml-2">{{ $t('settings.navigation_get_started') }}</span>
            </div>
            <transition name="fade">
              <span
                v-if="showNewBadge"
                class="fade-in t-text-desktop-paragraph-xs ml-auto rounded bg-leaf-500 px-2 text-center text-white"
              >
                {{ $t('settings.navigation_badge_new') }}
              </span>
            </transition>
          </router-link>
        </div>
      </div>
      <div class="-mt-6 pb-20 pl-4 pt-4">
        <collapse v-for="(nav, i) in navigation" :key="i" :collapse-key="nav.title" class="mt-6" :default-open="false">
          <template #collapse-title>
            <div>
              <h6>{{ nav.title }}</h6>
            </div>
          </template>
          <template #collapse-content>
            <div class="flex flex-col">
              <template v-for="(item, index) in nav.items">
                <router-link
                  v-if="item.url && !item.hidden"
                  :key="index"
                  :to="item.url"
                  :class="{ isEmail: item.email }"
                  class="collapse-menu-item relative flex cursor-pointer items-center rounded text-grey-700"
                  active-class="bg-grey-200 text-grey-900"
                  tag="a"
                >
                  <div style="width: 28px">
                    <i v-if="item.icon" :class="item.icon" class="fa-fw md-18"></i>
                    <component :is="item.iconComponent" v-else-if="item.iconComponent" size="1.1rem" />
                  </div>
                  <span v-if="!item.email" class="self-start">{{ item.title }}</span>
                  <t-badge
                    v-if="item.title === 'Custom channel' && !isEntitledToCustomChannel"
                    id="badge"
                    variant="upgrade"
                    :text="upgradeCustomChannelBadgeText"
                  >
                    <template #icon><arrow-top-circle-linear size="1rem" /></template>
                  </t-badge>
                  <t-badge
                    v-if="item.hasBetaBadge"
                    variant="default"
                    :text="$t('settings.navigation_badge_beta')"
                    class="absolute -right-0 ml-auto mr-2"
                  />
                  <span v-if="item.email" v-tooltip="{ content: item.title }" class="label-text">{{ item.title }}</span>
                  <t-badge
                    v-if="item.new"
                    variant="new"
                    :text="$t('settings.navigation_badge_new')"
                    class="absolute -right-3 ml-auto mr-2"
                  />
                  <div v-show="item.email" class="email-container"><i class="fas fa-envelope"></i></div>
                </router-link>
              </template>
            </div>
          </template>
        </collapse>
      </div>
    </portal>
    <div class="dark-light w-full overflow-auto">
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { QuestionFill, IntegrationLinear, ArrowTopCircleLinear, InboxLinear } from '@trengo/trengo-icons';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import {
  FEATURE_FLAG_INBOX,
  PERMISSION,
  SETTINGS_PERMISSIONS,
  FEATURE,
  ENTITLEMENT_STATUS,
  FEATURE_FLAG_CHANNELS,
  CHANNEL_TYPE,
  FEATURE_FLAG_SETTINGS,
} from '@/Configs/Constants';
import { NAVIGATION_ITEM_ID } from '@/Configs/Constants/navigation/navigationItemId';
import { useCompanyProfileStore, useEntitlementsStore, useFeatureFlagStore, useUserStore } from '@/store/pinia';
import { getNavigationByUserPermissions } from '@/util/userHelpers/navigationHelper';

import Collapse from './Collapse.vue';

import type { NavigationGroupItem, NavigationItem } from '@/types/navigation';

export default defineComponent({
  name: 'Admin',
  components: { Collapse, QuestionFill, IntegrationLinear, ArrowTopCircleLinear, InboxLinear },
  data() {
    return {
      navigation: [] as NavigationGroupItem[],
      path: '',
      PERMISSION,
      ENTITLEMENT_STATUS,
      FEATURE,
      isHideGbmEnabled: false,
    };
  },

  computed: {
    ...mapStores(useCompanyProfileStore, useEntitlementsStore, useUserStore),
    ...mapGetters({
      showNewBadge: 'gamification/getShowNewBadge',
    }),
    hasAdminPermission() {
      return this.userStore.hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE);
    },
    isEntitledToCustomChannel() {
      return (
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__CHANNELS__CUSTOM) ===
        ENTITLEMENT_STATUS.ENTITLED
      );
    },
    upgradeCustomChannelBadgeText() {
      return this.entitlementsStore?.getMinimumRequiredPlanFor(FEATURE.CUSTOMER_SERVICE__CHANNELS__CUSTOM);
    },
    hasAnySettingsPermission() {
      return this.userStore.hasAnyPermission(SETTINGS_PERMISSIONS);
    },
  },

  watch: {
    $route() {
      if (window.innerWidth > 1000) {
        return;
      }

      if ($('.modal').length > 0) {
        $('.modal').modal('hide');
      }
    },
  },

  async mounted() {
    this.isHideGbmEnabled = useFeatureFlagStore().isEnabled(FEATURE_FLAG_CHANNELS.TC_HIDE_GBM);

    if (!this.hasAnySettingsPermission) {
      this.$router.push('/unauthorized');
    }

    // Protect mobile app users from using the admin
    if (this.$root.loadedFromApp) {
      parent.postMessage('app-browse-url:' + encodeURI(window.location.href), '*');
      this.$router.push('/tickets');
      return;
    }

    await this.loadNavigation();
  },

  methods: {
    localizeNavigation(navigationItems: NavigationGroupItem[]) {
      const shouldHideItem = (item: NavigationItem) => {
        if (item.hidden !== undefined) {
          return item.hidden;
        }

        const isIntegrationHubEnabled = useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.INTEGRATION_HUB);

        // TODO TI-1181: This is a hacky workaround and should be removed when the IH hits live for all users
        if (item.id === 'integrationHub') {
          return !isIntegrationHubEnabled;
        } else if (item.id === 'legacyIntegrations') {
          return isIntegrationHubEnabled;
        }

        if (item.id === CHANNEL_TYPE.GBM.toLowerCase() && this.isHideGbmEnabled) {
          return true;
        }
      };

      return navigationItems.map((navigationGroupItem) => ({
        ...navigationGroupItem,
        title: this.$t(navigationGroupItem.title),
        items: navigationGroupItem.items.map((navigationItem) => ({
          ...navigationItem,
          hidden: shouldHideItem(navigationItem),
          title: this.$t(navigationItem.title),
        })),
      }));
    },
    async loadNavigation() {
      await this.companyProfileStore.init();
      const navigationItems = getNavigationByUserPermissions(
        this.userStore.user.permissions,
        this.companyProfileStore.pricingModel
      );

      const removeNavigationItem = (navigationItems: NavigationGroupItem[], groupTitle: string, itemId: string) => {
        navigationItems.forEach((group) => {
          if (group.title === groupTitle) {
            group.items = group.items.filter((item) => item.id !== itemId);
          }
        });
      };

      if (useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.TI_CG_FRONTEND)) {
        removeNavigationItem(
          navigationItems,
          'settings.navigation_settings',
          NAVIGATION_ITEM_ID.SETTINGS__ADMIN__CONTACT_GROUPS
        );
      }

      if (!useFeatureFlagStore().isEnabled(FEATURE_FLAG_SETTINGS.SETTINGS_INBOX_BETA)) {
        removeNavigationItem(
          navigationItems,
          'settings.navigation_settings',
          NAVIGATION_ITEM_ID.SETTINGS__ADMIN__INBOX
        );
      }

      this.navigation = this.localizeNavigation(navigationItems);
    },
  },
});
</script>

<style lang="scss">
.get-started-page-item {
  width: 275px;
}

@media (max-width: 768px) {
  .get-started-page-item {
    width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 1440px) {
  .get-started-page-item {
    width: 225px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1599px) {
  .get-started-page-item {
    width: 225px;
  }
}

.row-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.row-body {
  height: 100%;
  width: 100%;
  position: relative;
}

.row-col {
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  height: 100%;
}

.row-row {
  display: table-row;
  height: 100%;
}

.email-container {
  padding: 4px 8px;
  background: var(--color-galaxy-900);
  border-radius: 12px;

  i {
    color: var(--color-white);
  }
}

.isEmail .label-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fade-enter-active {
  transition: opacity 0.25s ease;
}

.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
