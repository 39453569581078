import { sortBy } from 'lodash';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { fetchItemEntitlements, fetchSubscriptionEntitlements } from '@/api';
import { ENTITLEMENT_STATUS, FEATURE_FLAG_SUBSCRIPTION, PLAN_NAME } from '@/Configs/Constants';
import { isTestEnv } from '@/util/jest';

import { filterEntitlementsByParams, isQuantityWithinEntitlementLimit, isTrialEntitlement } from './utils';
import { useFeatureFlagStore } from '../featureFlag';

import type {
  ClientAddonItemEntitlement,
  ClientItemEntitlement,
  ClientSubscriptionEntitlement,
  Entitlement,
  EntitlementStatus,
  FeatureId,
  PlanId,
  PlanName,
  QuantityEntitlement,
  ServerItemEntitlement,
} from '@/types';

/* Utils */
const convertServerToClient = (serverData: ServerItemEntitlement[]): ClientItemEntitlement[] =>
  serverData.map((entitlement) => {
    if (entitlement.item_type === 'addon') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newEntitlement: Record<string, any> = { ...entitlement };
      // sort & enrich entitlement data with planIds and planNames to calculate minimum required plan later
      const plans = [...entitlement.plans];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sortedPlans = sortBy(plans, (o: any) => o.level) as typeof plans;
      const planIds = sortedPlans.map((plan) => plan.tier);
      const planNames = sortedPlans.map((plan) => plan.name);
      newEntitlement.plans = sortedPlans;
      newEntitlement.planIds = planIds;
      newEntitlement.planNames = planNames;
      return newEntitlement as ClientAddonItemEntitlement;
    }
    return entitlement as ClientItemEntitlement;
  });

export const useEntitlementsStore = defineStore('entitlements', () => {
  /* State */
  const itemEntitlements = ref<ClientItemEntitlement[]>([]);
  const subscriptionEntitlements = ref<ClientSubscriptionEntitlement[]>([]);
  const subscriptionPlan = ref<PlanId | null>(null);
  const isInitialized = ref(false);

  /* Actions */
  const getItemEntitlements = async () => {
    try {
      const { data } = await fetchItemEntitlements();
      itemEntitlements.value = convertServerToClient(data);
    } catch (err) {
      console.error(err);
    }
  };
  const getSubscriptionEntitlements = async () => {
    try {
      const { data } = await fetchSubscriptionEntitlements();
      subscriptionEntitlements.value = data.entitlements;
      subscriptionPlan.value = data.plan;
    } catch (err) {
      console.error(err);
    }
  };
  const init = async () => {
    const itemEntitlements = isInitialized.value ? Promise.resolve() : getItemEntitlements();
    const subscriptionEntitlements = isInitialized.value ? Promise.resolve() : getSubscriptionEntitlements();
    try {
      await Promise.all([itemEntitlements, subscriptionEntitlements]);
      isInitialized.value = true;
    } catch (err) {
      console.error(err);
    }
  };

  /* Private methods */
  const _subscriptionContainsEntitlement = (feature: FeatureId) =>
    filterEntitlementsByParams({ feature_id: feature }, subscriptionEntitlements.value).length > 0;

  const _pricingModelContainsEntitlement = (feature: FeatureId) =>
    filterEntitlementsByParams({ feature_id: feature }, itemEntitlements.value).length > 0;

  const _featureIsAddon = (feature: FeatureId) =>
    filterEntitlementsByParams({ feature_id: feature, item_type: 'addon' }, itemEntitlements.value).length > 0;

  const _addonExistsOnCurrentPlan = (feature: FeatureId) =>
    filterEntitlementsByParams(
      { feature_id: feature, item_type: 'addon', planIds: subscriptionPlan.value ? [subscriptionPlan.value] : [] },
      itemEntitlements.value
    ).length > 0;

  const _featureIsCharge = (feature: FeatureId) =>
    filterEntitlementsByParams({ feature_id: feature, item_type: 'charge' }, itemEntitlements.value).length > 0;

  const _isQuantityWithinPlanLimit = (feature: FeatureId, quantity?: number) => {
    const [currentPlanEntitlement] = filterEntitlementsByParams(
      { feature_id: feature, item_type: 'plan', type: 'quantity', item_id: subscriptionPlan.value?.toString() },
      itemEntitlements.value
    );
    return (
      Boolean(currentPlanEntitlement) &&
      isQuantityWithinEntitlementLimit(currentPlanEntitlement as QuantityEntitlement, quantity ?? 0)
    );
  };

  const _isQuantityWithinSubscriptionLimit = (feature: FeatureId, quantity?: number) => {
    const [subscriptionEntitlement] = filterEntitlementsByParams(
      { feature_id: feature, type: 'quantity' },
      subscriptionEntitlements.value
    );
    return (
      Boolean(subscriptionEntitlement) &&
      isQuantityWithinEntitlementLimit(subscriptionEntitlement as QuantityEntitlement, quantity ?? 0)
    );
  };

  const _requiresAddonAndPlanUpgrade = (feature: FeatureId) => {
    const subscriptionContainsEntitlement = _subscriptionContainsEntitlement(feature);
    const featureIsAddon = _featureIsAddon(feature);
    const addonExistsOnCurrentPlan = _addonExistsOnCurrentPlan(feature);
    return !subscriptionContainsEntitlement && featureIsAddon && !addonExistsOnCurrentPlan;
  };

  const _requiresAddon = (feature: FeatureId) => {
    const subscriptionContainsEntitlement = _subscriptionContainsEntitlement(feature);
    const featureIsAddon = _featureIsAddon(feature);
    const addonExistsOnCurrentPlan = _addonExistsOnCurrentPlan(feature);
    return !subscriptionContainsEntitlement && featureIsAddon && addonExistsOnCurrentPlan;
  };

  const _requiresCharge = (feature: FeatureId) => {
    const subscriptionContainsEntitlement = _subscriptionContainsEntitlement(feature);
    const featureIsCharge = _featureIsCharge(feature);
    return !subscriptionContainsEntitlement && featureIsCharge;
  };

  const _requiresPlanUpgrade = (feature: FeatureId, quantity?: number) => {
    const pricingModelContainsEntitlement = _pricingModelContainsEntitlement(feature);
    const subscriptionContainsEntitlement = _subscriptionContainsEntitlement(feature);
    const isQuantityWithinPlanLimit = _isQuantityWithinPlanLimit(feature, quantity);
    const [itemEntitlement] = filterEntitlementsByParams({ feature_id: feature }, itemEntitlements.value);
    const isSwitch = itemEntitlement?.type === 'switch';
    const isAddon = itemEntitlement?.item_type === 'addon';
    switch (true) {
      case isSwitch:
      case isAddon:
        return pricingModelContainsEntitlement && !subscriptionContainsEntitlement;
      default:
        return pricingModelContainsEntitlement && (!subscriptionContainsEntitlement || !isQuantityWithinPlanLimit);
    }
  };

  const _requiresSubscriptionChange = (feature: FeatureId, quantity?: number) => {
    const subscriptionContainsEntitlement = _subscriptionContainsEntitlement(feature);
    const isQuantityWithinPlanLimit = _isQuantityWithinPlanLimit(feature, quantity);
    const isQuantityWithinSubscriptionLimit = _isQuantityWithinSubscriptionLimit(feature, quantity);
    return subscriptionContainsEntitlement && isQuantityWithinPlanLimit && !isQuantityWithinSubscriptionLimit;
  };

  const _hasEntitlement = (feature: FeatureId, quantity?: number) => {
    const [itemEntitlement] = filterEntitlementsByParams({ feature_id: feature }, itemEntitlements.value);
    if (itemEntitlement?.type === 'switch') {
      // switch
      return _subscriptionContainsEntitlement(feature);
    } else {
      // quantity
      const [subscriptionEntitlement] = filterEntitlementsByParams(
        { feature_id: feature },
        subscriptionEntitlements.value
      );
      return Boolean(subscriptionEntitlement) && _isQuantityWithinSubscriptionLimit(feature, quantity);
    }
  };

  const _featureExists = (feature: FeatureId) => {
    const res = filterEntitlementsByParams({ feature_id: feature }, itemEntitlements.value).length > 0;
    if (!res) {
      throw new TypeError(`Unhandled featureId: ${feature}`);
    }
    return res;
  };

  /* Public methods */
  const isEntitledTo = (feature: FeatureId, quantity?: number): boolean =>
    getEntitlementStatusFor(feature, quantity) === ENTITLEMENT_STATUS.ENTITLED;

  const isEntitledToEnforce = (feature: FeatureId, quantity?: number): boolean =>
    getEntitlementStatusFor(feature, quantity, true) === ENTITLEMENT_STATUS.ENTITLED;

  // return plan name if one is required, otherwise return ''
  const getEntitlementStatusFor = (feature: FeatureId, quantity?: number, enforce?: boolean): EntitlementStatus => {
    try {
      const featureFlag = useFeatureFlagStore();
      switch (true) {
        case featureFlag.isInitialized &&
          !featureFlag.isEnabled(FEATURE_FLAG_SUBSCRIPTION.TW_ENABLE_ENTITLEMENTS) &&
          !enforce:
          return ENTITLEMENT_STATUS.ENTITLED;
        case _requiresAddonAndPlanUpgrade(feature):
          // e.g. addon available only on tier2, user is on tier 1
          return ENTITLEMENT_STATUS.REQUIRES_ADDON_AND_PLAN_UPGRADE;
        case _requiresAddon(feature):
          // e.g. broadcasting available on every tier, but is an addon
          return ENTITLEMENT_STATUS.REQUIRES_ADDON;
        case _requiresCharge(feature):
          // e.g. one-time charge, e.g. onboarding
          return ENTITLEMENT_STATUS.REQUIRES_CHARGE;
        case _requiresPlanUpgrade(feature, quantity):
          return ENTITLEMENT_STATUS.REQUIRES_PLAN_UPGRADE;
        case _requiresSubscriptionChange(feature, quantity):
          // subscription limit overrides plan limit (e.g. seats)
          return ENTITLEMENT_STATUS.REQUIRES_SUBSCRIPTION_CHANGE;
        case _hasEntitlement(feature, quantity):
          return ENTITLEMENT_STATUS.ENTITLED;
        case !_featureExists(feature) as boolean:
        default:
          return ENTITLEMENT_STATUS.UNHANDLED;
      }
    } catch (err) {
      if (!isTestEnv()) {
        console.error(err);
      }
      return ENTITLEMENT_STATUS.UNHANDLED;
    }
  };

  const getMinimumRequiredPlanFor = (feature: FeatureId, quantity?: number): PlanName | '' => {
    const entitlementStatus = getEntitlementStatusFor(feature, quantity);
    switch (true) {
      case entitlementStatus === ENTITLEMENT_STATUS.REQUIRES_ADDON_AND_PLAN_UPGRADE: {
        const addon = itemEntitlements.value.find(
          ({ item_type, feature_id }) => item_type === 'addon' && feature_id === feature
        ) as ClientAddonItemEntitlement;
        return (
          addon?.planNames?.find(
            (name) => name !== PLAN_NAME.USAGE_BASED_TIER_0 // exclude trial plans
          ) ?? ''
        );
      }
      case entitlementStatus === ENTITLEMENT_STATUS.REQUIRES_PLAN_UPGRADE: {
        const matchingItemEntitlements = itemEntitlements.value.filter(
          (entitlement) =>
            entitlement.item_type === 'plan' && entitlement.feature_id === feature && !isTrialEntitlement(entitlement)
        );
        const sortedEntitlements = sortBy(matchingItemEntitlements, (o: any) => o.item_id);
        const minimumEntitlement = sortedEntitlements.find((itemEntitlement: Entitlement) => {
          const quantityEntitlement = itemEntitlement as QuantityEntitlement;
          if (typeof quantity === 'undefined') {
            return true;
          } else {
            const existingQuantityEntitlement = subscriptionEntitlements.value.find(
              (subscriptionEntitlement: ClientSubscriptionEntitlement) => subscriptionEntitlement.feature_id === feature
            ) as QuantityEntitlement;
            return (
              quantityEntitlement.max_quantity === null ||
              (existingQuantityEntitlement?.max_quantity ?? 0) < quantityEntitlement?.max_quantity
            );
          }
        });

        return (minimumEntitlement?.item_name as PlanName) ?? '';
      }
      default: {
        if (!isTestEnv()) {
          console.error(`Unhandled minimum required plan: feature ${feature}, quantity: ${quantity}`);
        }
        return '';
      }
    }
  };

  return {
    itemEntitlements,
    subscriptionEntitlements,
    subscriptionPlan,
    getItemEntitlements,
    getSubscriptionEntitlements,
    init,
    isEntitledTo,
    isEntitledToEnforce,
    isInitialized,
    getEntitlementStatusFor,
    getMinimumRequiredPlanFor,
  };
});
