<template>
  <page-container
    v-if="!isMobile"
    class="flex h-auto flex-col overflow-auto rounded-xl border-1 border-grey-200 bg-white"
  >
    <hero class="mb-4 mt-8 xl2:mx-3">
      <template #top>
        <page-title>{{ $t('reports.csat_title') }}</page-title>
      </template>
    </hero>
    <filters v-model="filters" :show-rating="true" :show-survey="true" :compact-style="true"></filters>
    <report report="csats" :filters="filters" :metrics="['average_rating', 'surveys_received']"></report>
  </page-container>
  <mobile-status v-else data-test="reporting-mobile" />
</template>

<script lang="ts">
import Filters from '@/components/Reporting/Components/Filters';
import PageContainer from '@/components/Reporting/Components/PageContainer';
import PageTitle from '@/components/Reporting/Components/PageTitle';
import Report from '@/components/Reporting/Components/Report';
import MobileStatus from '@/components/Reporting/Pages/MobileStatus.vue';
import breakpoints from '@/util/breakpoints';

export default {
  components: {
    Report,
    Filters,
    PageTitle,
    MobileStatus,
    PageContainer,
  },
  data() {
    return {
      filters: {},
      surveys: {},
      breakpoints,
    };
  },
  computed: {
    isMobile() {
      return this.breakpoints.w <= 991;
    },
  },
  mounted() {},
};
</script>
