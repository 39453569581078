export const formatWhatsAppText = (text: string): string =>
  text
    .replace(/\*(.*?)\*/g, '<b>$1</b>')
    // Replace _italic_ with <i>italic</i>
    .replace(/_(.*?)_/g, '<i>$1</i>')
    // Replace ~strikethrough~ with <s>strikethrough</s>
    .replace(/~(.*?)~/g, '<s>$1</s>')
    // preserve line breaks
    .replace(/\n/g, '<br>');

export const isDesktopApp = window?.isElectron;
