<template>
  <sidebar-dropdown
    :title="
      profiles.length > 0 ? this.$tc('ticket_sidebar.profile_moments') : this.$tc('ticket_sidebar.contact_moments')
    "
    :collapse-key="`${momentType}MomentsDropdown`"
    :data-test="`${momentType}-moments-dropdown`"
    @toggled="setToggleState"
    @mounted="setToggleState"
  >
    <div v-if="hasFetched">
      <t-input-text
        id="contactMomentSearch"
        v-model="searchTerm"
        class="mb-2"
        :placeholder="$tc('general.searching')"
        size="sm"
        :allow-clear="true"
        data-test="contact-moments-searchbar"
        @clear="clearSearch"
        @keydown.esc.prevent="clearSearch"
        @keydown.enter.prevent="searchTicket(false, false, true)"
      />

      <div v-if="!isLoading || !isTicketListEmpty" class="max-h-[350px] overflow-y-auto pr-1">
        <contact-moment-card
          v-for="ticket in tickets.data"
          :key="ticket.id"
          :ticket="ticket"
          :current-ticket="currentTicket"
          :profiles="profiles"
        />

        <h2 v-if="isTicketListEmpty" class="t-text-lg-emphasize text-center text-grey-700" data-test="no-data-text">
          {{ $tc('ticket_sidebar.no_contact_moments') }}
        </h2>

        <t-button
          v-if="tickets.links && tickets.links.next !== null"
          class="mx-auto mt-3 w-fit"
          data-test="load-more-button"
          @click="searchTicket(true)"
        >
          {{ $tc('tickets.load_more') }}
        </t-button>
      </div>
    </div>

    <t-spinner v-if="isLoading || !hasFetched" class="m-auto" size="1.5rem" />
  </sidebar-dropdown>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { fetchTickets } from '@/components/TicketSidebar/Api/requests';
import ContactMomentCard from '@/components/TicketSidebar/ContactMomentCard.vue';
import SidebarDropdown from '@/components/TicketSidebar/SidebarDropdown.vue';

import type { TicketSearchParams } from '@/components/TicketSidebar/Api/requests';
import type Contact from '@/types/contact';
import type Profile from '@/types/profile';
import type Ticket from '@/types/tickets';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'ContactMomentsDropdown',

  components: { SidebarDropdown, ContactMomentCard },

  props: {
    currentTicket: {
      type: Object as PropType<Ticket>,
      default: () => ({}),
    },
    profiles: {
      type: Array as PropType<Profile[]>,
      default: () => [],
    },
    contact: {
      type: Object as PropType<Contact>,
      default: () => ({}),
    },
  },

  data() {
    return {
      searchTerm: '',
      isLoading: false,
      isDropdownToggled: false,
      tickets: [] as Ticket[],
      loadedTickets: [] as Ticket[],
      hasFetched: false,
    };
  },

  computed: {
    isTicketListEmpty() {
      return this.tickets?.data?.length === 0;
    },

    momentType(): 'contact' | 'profile' {
      return this.profiles.length > 0 ? 'profile' : 'contact';
    },
  },

  methods: {
    setToggleState(state: boolean) {
      this.isDropdownToggled = state;

      if (state && !this.hasFetched) {
        this.searchTicket(false, true);
      }
    },

    async searchTicket(fetchNext = false, initialize = false, search = false) {
      this.isLoading = true;
      let page = 1;

      if (fetchNext) {
        page = this.tickets.meta.current_page + 1;
      }

      if (search || initialize || fetchNext) {
        let searchParams = {
          page,
          sort: '-date',
          term: this.searchTerm || '',
        } as TicketSearchParams;

        if (this.profiles.length > 0) {
          searchParams.profile_id = this.profiles[0].id;
        } else {
          searchParams.contact_id = this.contact.id;
        }

        try {
          const response = await fetchTickets(searchParams);

          if (fetchNext) {
            this.tickets = Object.assign({}, this.tickets, {
              data: [...(this.tickets.data || []), ...(response?.data?.data || [])],
              meta: response.data.meta,
              links: response.data.links,
            });
          } else {
            this.tickets = { data: response?.data?.data };
          }

          if (this?.loadedTickets?.data?.length < 1 || initialize) {
            this.loadedTickets = response.data;
          }

          this.isLoading = false;
          this.hasFetched = true;
        } catch (err) {
          console.error(err);
          this.flashError(err);
          this.isLoading = false;
        }
      } else {
        this.isSearching = false;
        this.searchTerm = '';
        this.tickets = this.originalTickets;

        if (this?.tickets?.data?.length < 1) {
          await this.searchTicket(false, true);
        }
      }
    },

    async clearSearch() {
      this.searchTerm = '';
      await this.searchTicket(false, true);
    },
  },

  async mounted() {
    if (this.isDropdownToggled && !this.hasFetched) {
      await this.searchTicket(false, true);
    } else {
      this.tickets = [];
    }
  },
});
</script>
