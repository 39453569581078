<script type="text/babel">
import _, { forEach } from 'lodash';

export default {
  name: 'HelpCenterArticles',
  props: {
    helpCenter: {
      type: Object,
      default: () => ({}),
    },
    localeCodes: {
      type: Object,
      default: () => ({}),
    },
  },

  mounted() {
    this.loadFilters();
    this.loadArticles();
  },

  data() {
    return {
      articles: [],
      localeCode: this.helpCenter.default_language,
      searchTerm: '',
      filter: null,
    };
  },

  computed: {
    filteredHelpCenterLanguages() {
      return this.helpCenter.languages.filter((lang) => this.localeCodes[lang]);
    },
    filters() {
      let filters = [
        { id: null, text: this.$t('help_center.all_articles') },
        { id: 'draft', text: this.$t('help_center.draft_articles') },
        { id: 'published', text: this.$t('help_center.published_articles') },
      ];

      forEach(this.helpCenter.languages),
        (lang) => {
          filters.push({
            id: 'untranslated_' + lang,
            text: this.$t('help_center.not_translated_in') + this.localeCodes?.[lang]?.text,
          });
        };

      return filters;
    },
  },

  methods: {
    create() {
      router.push('/help_center/' + this.$route.params.id + '/articles/create');
    },

    setFilter(filter) {
      this.filter = filter;
      this.loadArticles();
    },

    setLanguage(lang) {
      this.localeCode = lang;
      this.loadArticles();
    },

    getCurrentFilter() {
      return this.filters.filter((filter) => {
        return filter.id == this.filter;
      })[0].text;
    },

    getTranslation(record) {
      var trans = record.translations.filter((trans) => {
        return trans.locale_code == this.localeCode;
      })[0];

      if (trans == null) {
        trans = {
          published: false,
          fresh: true,
          title: null,
        };
      }

      return trans;
    },

    loadArticles() {
      //this.articles = [];
      this.loading = true;
      axios
        .get(
          '/api/v2/help_center/' +
            this.$route.params.id +
            '/articles?localeCode=' +
            this.localeCode +
            '&term=' +
            this.searchTerm +
            '&filter=' +
            this.filter
        )
        .then((res) => {
          this.articles = res.data;
          this.loading = false;
        });

      this.saveFilters();
    },

    search: _.debounce(function () {
      this.loadArticles();
    }, 100),

    saveFilters() {
      this.$tStorage.setItem('hc.articles.filers', {
        localeCode: this.localeCode,
        term: this.searchTerm,
        filter: this.filter,
      });
    },

    async loadFilters() {
      try {
        let filters = await this.$tStorage.getItem('hc.articles.filers');
        this.filter = filters.filter;
        this.localeCode = filters.localeCode;
        this.searchTerm = filters.term;
      } catch (e) {}
    },
  },
};
</script>

<template>
  <div>
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis">
            {{ $t('help_center.articles') }}
            <small class="text-muted">({{ articles.length }})</small>
          </span>
          <div class="m-l-auto">
            <a class="btn btn-success btn-md text-white" @click="create()">
              {{ $t('help_center.new_article') }}
            </a>
          </div>
        </li>
      </ul>
    </div>
    <div class="p-a container">
      <div class="display-flex m-b-1">
        <div style="width: 300px" class="m-r-xs">
          <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input
              v-model="searchTerm"
              type="text"
              class="form-control"
              :placeholder="$t('help_center.find_by_title_or_content')"
              @keyup="search"
            />
          </div>
        </div>
        <div class="dropdown m-r-xs">
          <button v-if="localeCodes[localeCode]" class="btn white dropdown-toggle" type="button" data-toggle="dropdown">
            {{ localeCodes[localeCode].text }}
          </button>
          <div class="dropdown-menu">
            <a v-for="lang in filteredHelpCenterLanguages" :key="lang" class="dropdown-item" @click="setLanguage(lang)">
              {{ localeCodes[lang].text }}
            </a>
          </div>
        </div>
        <div class="dropdown m-r-xs">
          <button class="btn white dropdown-toggle" type="button" data-toggle="dropdown">
            {{ getCurrentFilter() }}
          </button>
          <div class="dropdown-menu">
            <a v-for="filter in filters" :key="filter.id" class="dropdown-item" @click="setFilter(filter.id)">
              {{ filter.text }}
            </a>
          </div>
        </div>
      </div>
      <div class="box">
        <table class="table-bordered table-hover table">
          <thead>
            <tr>
              <th width="40%">{{ $t('help_center.article') }}</th>
              <th width="10%">{{ $t('help_center.category') }}</th>
              <th width="10%">{{ $t('help_center.author') }}</th>
              <th width="10%">{{ $t('help_center.votes') }}</th>
              <th width="10%"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="article in articles" :key="article.id">
              <td>
                <span v-if="getTranslation(article).fresh" class="text-muted">
                  {{ $t('help_center.not_translated') }} (#{{ article.id }})
                </span>
                <span v-else>
                  <b v-if="getTranslation(article).published" class="label label-xs success status-label rounded"></b>
                  <b v-if="!getTranslation(article).published" class="label label-xs warning status-label rounded"></b>
                  <span class="p-l-xs" v-text="getTranslation(article).title"></span>
                  <span v-if="getTranslation(article).title == ''" class="p-l-xs">
                    {{ $t('help_center.missing_title') }} (#{{ article.id }})
                  </span>
                </span>
              </td>
              <td>
                <span v-if="article.category !== null">{{ getTranslation(article.category).title }}</span>
              </td>
              <td><span v-if="article.user" v-text="article.user.name"></span></td>
              <td style="white-space: nowrap">
                <span class="votes">😃{{ article.positive_rating_count }}</span>
                <span class="votes">😐{{ article.neutral_rating_count }}</span>
                <span class="votes">😞{{ article.negative_rating_count }}</span>
              </td>
              <td>
                <router-link class="text-info" :to="'/help_center/' + helpCenter.id + '/articles/' + article.id">
                  {{ $t('general.edit') }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped>
.votes {
  background-color: #efefef;
  border-radius: 10px;
  padding: 2px 7px;
  margin-right: 8px;
}
</style>
