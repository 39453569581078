<template>
  <div class="mt-6 flex flex-col">
    <t-input-text
      id="url"
      v-model="$props.modelValue.url"
      size="sm"
      :label="$tc('integrations.magento1_label_your_shop_url')"
      :sub-label="$tc('integrations.magento1_subtitle_your_shop_url')"
      :class="{ 'mb-6': !errors.url }"
      :has-error="errors.url"
      @blur="checkUrl"
    />
    <t-error-item v-if="errors.url" :text="$tc('integration_hub.error_url_must_be_valid')" danger-icon class="mb-4" />

    <t-input-text
      id="url"
      v-model="$props.modelValue.admin_url"
      size="sm"
      :label="$tc('integrations.magento1_label_shop_admin_url')"
      :sub-label="$tc('integrations.magento1_subtitle_shop_admin_url')"
      :class="{ 'mb-6': !errors.adminUrl }"
      :has-error="errors.adminUrl"
      @blur="checkAdminUrl"
    />
    <t-error-item
      v-if="errors.adminUrl"
      :text="$tc('integration_hub.error_url_must_be_valid')"
      danger-icon
      class="mb-4"
    />

    <t-input-text
      id="url"
      v-model="$props.modelValue.consumer_key"
      type="password"
      size="sm"
      :label="$tc('integrations.magento2_label_consumer_key')"
      :sub-label="$tc('integrations.magento2_subtitle_consumer_key')"
      :class="{ 'mb-6': !errors.consumerKey }"
      :has-error="errors.consumerKey"
      @blur="checkConsumerKey"
    />
    <t-error-item
      v-if="errors.consumerKey"
      :text="$tc('integration_hub.error_consumer_key_must_be_filled_out')"
      danger-icon
      class="mb-4"
    />

    <t-input-text
      id="url"
      v-model="$props.modelValue.customer_secret"
      type="password"
      size="sm"
      :label="$tc('integrations.magento2_label_consumer_secret')"
      :sub-label="$tc('integrations.magento2_subtitle_consumer_secret')"
      :class="{ 'mb-6': !errors.customerSecret }"
      :has-error="errors.customerSecret"
      @blur="checkCustomerSecret"
    />
    <t-error-item
      v-if="errors.customerSecret"
      :text="$tc('integration_hub.error_consumer_secret_must_be_filled_out')"
      danger-icon
      class="mb-4"
    />

    <t-input-text
      id="url"
      v-model="$props.modelValue.access_token"
      type="password"
      size="sm"
      :label="$tc('integrations.magento2_label_access_token')"
      :sub-label="$tc('integrations.magento2_subtitle_access_token')"
      :class="{ 'mb-6': !errors.accessToken }"
      :has-error="errors.accessToken"
      @blur="checkAccessToken"
    />
    <t-error-item
      v-if="errors.accessToken"
      :text="$tc('integration_hub.error_access_token_must_be_filled_out')"
      danger-icon
      class="mb-4"
    />

    <t-input-text
      id="url"
      v-model="$props.modelValue.access_token_secret"
      type="password"
      size="sm"
      :label="$tc('integrations.magento2_label_access_token_secret')"
      :sub-label="$tc('integrations.magento2_subtitle_access_token_secret')"
      :class="{ 'mb-6': !errors.accessTokenSecret }"
      :has-error="errors.accessTokenSecret"
      @blur="checkAccessTokenSecret"
    />
    <t-error-item
      v-if="errors.accessTokenSecret"
      :text="$tc('integration_hub.error_access_token_secret_must_be_filled_out')"
      danger-icon
      class="mb-4"
    />
  </div>
</template>

<script setup lang="ts">
import validUrlLibrary from 'valid-url';
import { ref } from 'vue';

import type { CustomMagento2Integration } from '@/store/types/integrations';

const props = defineProps<{ modelValue: CustomMagento2Integration['meta'] }>();
const emit = defineEmits(['on-validated', 'on-invalidated']);

const errors = ref({
  url: false,
  adminUrl: false,
  consumerKey: false,
  customerSecret: false,
  accessToken: false,
  accessTokenSecret: false,
});

function validateFields() {
  checkUrl();
  checkAdminUrl();
  checkConsumerKey();
  checkCustomerSecret();
  checkAccessToken();
  checkAccessTokenSecret();

  Object.values(errors.value).some((item) => item) ? emit('on-invalidated') : emit('on-validated');
}

defineExpose({ validateFields });

function checkUrl() {
  errors.value.url =
    !props.modelValue.url || props.modelValue.url === '' || !validUrlLibrary.isWebUri(props.modelValue.url);
}

function checkAdminUrl() {
  errors.value.adminUrl =
    !props.modelValue.admin_url ||
    props.modelValue.admin_url === '' ||
    !validUrlLibrary.isWebUri(props.modelValue.admin_url);
}

function checkConsumerKey() {
  errors.value.consumerKey = !props.modelValue.consumer_key || props.modelValue.consumer_key === '';
}

function checkCustomerSecret() {
  errors.value.customerSecret = !props.modelValue.customer_secret || props.modelValue.customer_secret === '';
}

function checkAccessToken() {
  errors.value.accessToken = !props.modelValue.access_token || props.modelValue.access_token === '';
}

function checkAccessTokenSecret() {
  errors.value.accessTokenSecret = !props.modelValue.access_token_secret || props.modelValue.access_token_secret === '';
}
</script>
