<template>
  <main-header v-if="integration" class="border-b-1 border-grey-300 bg-white" :title="integration.name" width="66.67%">
    <template #left>
      <integration-card-icon
        class="rounded-lg border-1 border-grey-300 border-opacity-10 bg-white p-1"
        :icon="integration.icon"
      />
    </template>

    <template #right>
      <t-button
        v-if="showButton"
        :btn-style="hasInstallations ? 'secondary' : 'primary'"
        data-test="install-integration-button"
        class="flex flex-row items-center"
        @click="showInstallModal"
      >
        <plus-linear v-if="hasInstallations" size="1.5rem" class="mr-1" />
        {{ $t('integration_hub.install_integration') }}
      </t-button>

      <install-modal v-show="isInstallModalOpen" :is-open="isInstallModalOpen" @close="showModal" />
      <installed-modal
        :is-open="isInstalledModalOpen"
        :integration="integration"
        @close="isInstalledModalOpen = false"
      />
      <installation-failed-modal
        :is-open="isFailedModalOpen"
        :integration="integration"
        @close="isFailedModalOpen = false"
      />
    </template>

    <template v-if="showTabs" #bottom>
      <t-tab-item
        :label="$t('integration_hub.description')"
        :is-active="page === 'description'"
        size="sm"
        @click="setPage('description')"
      />

      <t-tab-item
        v-if="integration.installations"
        :label="$t('integration_hub.installed')"
        :is-active="page === 'installed'"
        size="sm"
        @click="setPage('installed')"
      >
        <template #postfix>
          <t-badge :text="integration.installations.length" />
        </template>
      </t-tab-item>
    </template>
  </main-header>
</template>

<script lang="ts">
import { PlusLinear } from '@trengo/trengo-icons';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import MainHeader from '@/components/common/PageHeaders/MainHeader';
import IntegrationCardIcon from '@/components/IntegrationHub/Components/IntegrationCardIcon.vue';
import InstallationFailedModal from '@/components/IntegrationHub/Components/Modals/InstallationFailedModal.vue';
import InstalledModal from '@/components/IntegrationHub/Components/Modals/InstalledModal.vue';
import InstallModal from '@/components/IntegrationHub/Components/Modals/InstallModal.vue';
import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import { FEATURE } from '@/Configs/Constants/Feature';
import eventBus from '@/eventBus';
import { useEntitlementsStore, useFeatureFlagStore } from '@/store/pinia';

import type { DetailPage } from '@/components/IntegrationHub/types';

export default defineComponent({
  name: 'DetailsHeader',
  emits: ['showModal'],
  components: {
    InstallModal,
    InstalledModal,
    InstallationFailedModal,
    IntegrationCardIcon,
    MainHeader,
    PlusLinear,
  },

  computed: {
    ...mapStores(useEntitlementsStore),
    ...mapGetters({
      integration: 'integrations/getItem',
    }),

    showButton() {
      if (useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.INTEGRATIONS_ENTITLEMENT_BYPASS)) {
        return true;
      }
      if (this.integration.is_premium) {
        return this.entitlementsStore?.isEntitledToEnforce(FEATURE.CUSTOMER_SERVICE__INBOX__INTEGRATIONS__PREMIUM);
      }
      return this.entitlementsStore?.isEntitledToEnforce(FEATURE.CUSTOMER_SERVICE__INBOX__INTEGRATIONS__BASIC);
    },

    hasInstallations() {
      return this.integration.isInstalled;
    },
  },

  data() {
    return {
      isInstalledModalOpen: false,
      isFailedModalOpen: false,
      page: 'description' as DetailPage,
      isInstallModalOpen: false,
      showTabs: false,
    };
  },

  methods: {
    async showModal({ hasCancelled, success }: { hasCancelled: boolean; success: boolean }) {
      if (hasCancelled) {
        this.isInstallModalOpen = false;
        return;
      }
      this.isInstallModalOpen = false;
      if (success) {
        await this.$store.dispatch('integrations/fetchIntegration', parseInt(this.$route.params.integrationId));
        this.isInstalledModalOpen = true;
      } else {
        this.isFailedModalOpen = true;
      }
    },

    setPage(page: DetailPage) {
      this.page = page;
      this.$router.replace({ query: { page } });
    },

    showInstallModal() {
      this.isInstallModalOpen = true;
    },
  },

  watch: {
    integration: {
      handler: function () {
        if (this.integration?.installations?.length > 0) {
          this.showTabs = true;
        }
      },
      deep: true,
    },
  },

  mounted() {
    const referrer = this.$route.query?.referrer;
    const error = this.$route.query?.error_type;
    if (referrer === 'apideck') {
      this.setPage('installed');
      if (error) {
        this.isFailedModalOpen = true;
      } else {
        this.isInstalledModalOpen = true;
        setTimeout(() => {
          eventBus.$emit('confetti');
        }, 500);
      }
    } else {
      const page = this.$route.query?.page;
      if (page) {
        this.setPage(page);
      }
    }
  },

  beforeRouteLeave(_to, _from, next) {
    this.isFailedModalOpen = false;
    this.isInstallModalOpen = false;
    this.isInstalledModalOpen = false;

    next();
  },
});
</script>
