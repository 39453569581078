export const PLAN = {
  TIER_0: 'tier0', // corresponds to Trial plan
  TIER_1: 'tier1',
  TIER_2: 'tier2',
  TIER_3: 'tier3',
} as const;

export const PLAN_NAME = {
  SEAT_BASED_TIER_0: 'Trial Plan',
  SEAT_BASED_TIER_1: 'Grow',
  SEAT_BASED_TIER_2: 'Scale',
  SEAT_BASED_TIER_3: 'Enterprise',
  USAGE_BASED_TIER_0: 'Trial Plan',
  USAGE_BASED_TIER_1: 'Essentials',
  USAGE_BASED_TIER_2: 'Boost',
  USAGE_BASED_TIER_3: 'Pro',
} as const;
