<template>
  <div class="preview-card thank-you-card flex flex-col items-center justify-center">
    <div class="green-circle mb-10">
      <i class="far fa-check fa-3x"></i>
    </div>
    <h2 class="text-center">
      {{ thank_you_message ? thank_you_message : $t('csat.thank_you_message_preview') }}
    </h2>
    <div class="mt-6" v-if="enable_button">
      <atomic-button size="md" btn-style="secondary">
        {{ button_text ? button_text : $t('csat.button_text_preview') }}
      </atomic-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThankYouPreviewCard',

  props: {
    thank_you_message: {
      type: String,
      default: '',
    },
    enable_button: {
      type: Boolean,
      default: true,
    },
    button_text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.thank-you-card {
  padding: 96px 47px 122px 47px;
  .green-circle {
    width: 96px;
    height: 96px;
    background: var(--color-leaf-400);
    border-radius: 96px;
    position: relative;
    i {
      color: var(--color-white);
      position: absolute;
      left: 26px;
      top: 25px;
    }
  }
  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin: 0;
  }
  button.ui-btn {
    cursor: default;
    &:hover {
      box-shadow: none;
    }
  }
}
</style>
