import { PERMISSION } from '@/Configs/Constants';
import { ROUTE_NAME } from '@/routes/constants';

import type { RouteRecord } from '@/routes/types';

const restRoutes: RouteRecord[] = [
  {
    path: '/admin/integrations/rest',
    component: () => import(/* webpackChunkName; "Rest" */ '@/components/IntegrationHub/Pages/RestApi/Index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
    name: ROUTE_NAME.INTEGRATIONS_LEGACY_REST,
  },
];

export default restRoutes;
