<template>
  <t-modal v-model="isOpen" :close-on-backdrop-click="true" :close-icon="true" variant="narrow" @close="$emit('close')">
    <template #header>
      <h3 class="t-text-h3 my-auto max-w-[90%] p-0">{{ $t('integration_hub.installation_failed_modal_title') }}</h3>
    </template>

    <p class="t-text-md text-grey-800" data-test="integration-description">
      {{ $t('integration_hub.installation_failed_modal_description', { name: integration.name }) }}
    </p>

    <template #footer>
      <t-button data-test="done-button" @click="$emit('close')">
        {{ $t('integration_hub.done_button') }}
      </t-button>
    </template>
  </t-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import type { Integration } from '@/store/types/integrations';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'InstallationFailedModal',
  emits: ['close'],
  props: {
    integration: {
      type: Object as PropType<Integration>,
      default: () => ({}),
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
