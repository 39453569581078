<template>
  <div class="preview-card email-content-card">
    <h6 class="company_name_title_preview">
      {{ company_name ? company_name : $t('csat.company_name_preview') }}
    </h6>
    <h6 class="question_preview">
      {{ question ? question : $t('csat.question_preview') }}
    </h6>
    <div class="flex flex-row">
      <img :src="`${$root.assetsURL}img/csat/very_dissatisfied_emoji.svg`" width="44px;" height="44px;" />
      <img :src="`${$root.assetsURL}img/csat/dissatisfied_emoji.svg`" width="44px;" height="44px;" />
      <img :src="`${$root.assetsURL}img/csat/neutral_emoji.svg`" width="44px;" height="44px;" />
      <img :src="`${$root.assetsURL}img/csat/satisfied_emoji.svg`" width="44px;" height="44px;" />
      <img :src="`${$root.assetsURL}img/csat/very_satisfied_emoji.svg`" width="44px;" height="44px;" />
    </div>
    <atomic-button size="md" class="mb-3 mt-4 block">
      {{ button_text ? button_text : $t('csat.review_your_experience') }}
    </atomic-button>
    <p class="mb-3">
      {{ $t('csat.kind_regards') + ',' }}
    </p>
    <div class="flex flex-row">
      <avatar
        :color="$root.user.color"
        :abbr="$root.user.abbr"
        width="46"
        font-size="1em"
        :image="$root.user.profile_image"
        class="mr-2"
      ></avatar>
      <div>
        <p class="agent_name_preview">
          {{ user.first_name }}
        </p>
        <p class="company_name_preview">
          {{ $t('csat.team') }}
          {{ company_name ? company_name : $t('csat.company_name_preview') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import avatar from '../Avatar';
export default {
  name: 'EmailContentPreviewCard',

  props: {
    company_name: {
      type: String,
      default: '',
    },
    question: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      default: {},
    },
    button_text: {
      type: String,
      default: '',
    },
  },
  components: {
    avatar,
  },
};
</script>

<style scoped lang="scss">
.email-content-card {
  padding: 66px 48px;
  img {
    margin-right: 0.75rem;
  }
  .company_name_title_preview {
    color: var(--color-grey-600);
  }
  h6 {
    font-weight: bold;
    font-size: 17px;
    line-height: 24px;
  }
  .agent_name_preview {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }
  .company_name_preview {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }
  button.ui-btn {
    cursor: default;
    &:hover {
      box-shadow: none;
    }
  }
}
</style>
