<template>
  <div class="flex w-full flex-col">
    <straight class="mb-5" :width="160" :height="12" />

    <straight class="mb-2" :width="109" :height="12" />
    <straight class="mb-2" :width="'100%'" :height="12" />
    <straight class="mb-5" :width="'100%'" :height="12" />

    <straight class="mb-2" :width="109" :height="12" />
    <straight class="mb-5" :width="'100%'" :height="12" />

    <straight class="mb-2" :width="109" :height="12" />
    <straight class="mb-2" :width="'100%'" :height="12" />
    <straight class="mb-2" :width="'100%'" :height="12" />
    <straight class="mb-5" :width="'100%'" :height="12" />
  </div>
</template>

<script setup lang="ts">
import Straight from '@/components/Channels/CreateChannelModals/WhatsApp/SkeletonLoader/Straight.vue';
</script>
