<template>
  <div class="max-h-[512px] overflow-auto">
    <integration-loading v-if="isLoading" />

    <t-inline-banner v-else-if="hasError" type="error">
      <template #icon>
        <cross-octagon-linear />
      </template>

      <p class="t-text-sm-emphasize m-0 p-0">
        {{ $t('integration_hub.sidebar_error_title') }}
      </p>
      <p class="t-text-sm m-0 p-0">
        {{ error ?? $t('integration_hub.sidebar_error_description') }}
      </p>
    </t-inline-banner>

    <div v-else-if="orders?.length === 0 || invalidState">
      <t-inline-banner type="default">
        <template #icon>
          <info-linear />
        </template>

        <p class="t-text-sm-emphasize m-0 p-0">
          {{ $t('integration_hub.sidebar_no_data_found', { name: installation.name }) }}
        </p>
      </t-inline-banner>
    </div>

    <div v-else>
      <template v-if="customer?.full_name">
        <h1 class="t-text-md-emphasize m-0 mb-1 p-0 pl-2 text-grey-800">
          {{ $t('integration_hub.sidebar_crm_customer') }}
        </h1>
        <commerce-customer :customer="customer" />
      </template>

      <h1 v-if="orders && orders.length > 0" class="t-text-md-emphasize m-0 mb-1 p-0 pl-2 text-grey-800">Orders</h1>
      <commerce-order v-for="order in orders" :key="order.order_id" :order="order" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { InfoLinear, CrossOctagonLinear } from '@trengo/trengo-icons';
import { ref } from 'vue';

import { fetchCommerceOrders } from '@/api';
import CommerceCustomer from '@/components/TicketSidebar/Integrations/Commerce/CommerceCustomer.vue';
import CommerceOrder from '@/components/TicketSidebar/Integrations/Commerce/CommerceOrder.vue';
import IntegrationLoading from '@/components/TicketSidebar/Integrations/IntegrationLoading.vue';

import type { ECommerceOrder, EcommerceCustomer } from '@/api';
import type { InstalledIntegration } from '@/store/types/integrations';
import type Ticket from '@/types/tickets';
import type { AxiosError } from 'axios';

const props = defineProps<{
  installation: InstalledIntegration;
  ticket: Ticket;
}>();

const orders = ref<ECommerceOrder[]>();
const isLoading = ref(false);
const hasError = ref(false);
const invalidState = ref(false);
const error = ref('');
const customer = ref<EcommerceCustomer>();

const getSupportedFiltersForEcom = (service_id: string): 'email' | 'email & phone' | '' => {
  const emailOnlySupported = [
    'ecommerce+shopware',
    'ecommerce+prestashop',
    'ecommerce+picqer',
    'ecommerce+lightspeed',
    'ecommerce+woocommerce',
  ];
  const emailAndPhoneSupported = ['ecommerce+magento', 'ecommerce+shopify', 'ecommerce+bigcommerce', 'ecommerce+wix'];

  if (emailOnlySupported.includes(service_id)) {
    return 'email';
  }
  if (emailAndPhoneSupported.includes(service_id)) {
    return 'email & phone';
  }

  return '';
};

const getPhone = (): string => {
  if (!props.ticket.contact?.is_phone) {
    return '';
  }
  return props.ticket.contact?.phone ?? '';
};

const email = props.ticket.contact?.email;
let phone = getPhone();

const shouldLoadOrders = (): boolean => {
  const supportedFilters = getSupportedFiltersForEcom(props.installation.service_id);
  if (!supportedFilters) {
    invalidState.value = true;
    return false;
  }

  // if email is the only supported filter, and ticket doesn't have email, then we show empty state
  if (supportedFilters === 'email' && !email) {
    invalidState.value = true;
    return false;
  }

  if (supportedFilters === 'email & phone' && !email && !phone) {
    invalidState.value = true;
    return false;
  }

  return true;
};

const loadOrders = async () => {
  if (!shouldLoadOrders()) {
    return;
  }

  // prioritize email over phone for now (this might change later)
  if (email && phone) {
    phone = '';
  }

  try {
    isLoading.value = true;
    const { data: _orders } = await fetchCommerceOrders(props.installation.installation_id, email, phone);
    orders.value = _orders;
    if (_orders.length > 0) {
      customer.value = _orders[0].customer;
    }
  } catch (err: unknown) {
    const e = err as AxiosError;
    hasError.value = true;
    console.error(e);
    error.value = e.response?.data?.message;
  } finally {
    isLoading.value = false;
  }
};
loadOrders();
</script>
