import { includes, map, sortBy } from 'lodash';

export default {
  filters: null,
  tickets: null,
  isSearching: false,
  userId: null,

  setFilters(filters) {
    this.filters = filters;
    return this;
  },

  setTickets(tickets) {
    this.tickets = tickets;
    return this;
  },

  setIsSearching(isSearching) {
    this.isSearching = isSearching;
    return this;
  },

  setUserId(setUserId) {
    this.userId = setUserId;
    return this;
  },

  getFilteredResults() {
    let result = this.tickets.filter((ticket) => {
      try {
        // hide tickets that should be deleted (quick-fix)
        if (!ticket.channel) {
          return false;
        }

        if (this.isSearching) {
          return true;
        }

        if (ticket.trashed) {
          return false;
        }

        if (
          this.filters.hasOwnProperty('status') &&
          this.filters.status !== null &&
          this.filters.status !== ticket.status
        ) {
          return false;
        }

        if (
          this.filters.hasOwnProperty('users') &&
          this.filters.users !== null &&
          this.filters.users !== ticket.user_id
        ) {
          return false;
        }

        if (
          this.filters.hasOwnProperty('teams') &&
          typeof ticket.teams !== 'undefined' &&
          !includes(ticket.teams, this.filters.teams)
        ) {
          return false;
        }

        if (
          this.filters.hasOwnProperty('channels') &&
          this.filters.channels !== null &&
          this.filters.channels !== ticket.channel.id
        ) {
          return false;
        }

        if (
          this.filters.hasOwnProperty('labels') &&
          this.filters.labels !== null &&
          !includes(map(ticket.labels, 'id'), this.filters.labels)
        ) {
          return false;
        }

        if (
          this.filters.hasOwnProperty('favorited') &&
          this.filters.favorited !== null &&
          ticket.starred &&
          !includes(ticket.starred, this.userId)
        ) {
          return false;
        }

        if (
          this.filters.hasOwnProperty('watching') &&
          this.filters.watching !== null &&
          ticket.watchers &&
          !includes(ticket.watchers, this.userId)
        ) {
          return false;
        }

        if (
          this.filters.hasOwnProperty('mentioned') &&
          this.filters.mentioned === 'unchecked' &&
          ticket.mentioned_unchecked &&
          !includes(ticket.mentioned_unchecked, this.userId)
        ) {
          return false;
        }

        if (
          this.filters.hasOwnProperty('mentioned') &&
          this.filters.mentioned === 'checked' &&
          ticket.mentioned_checked &&
          !includes(ticket.mentioned_checked, this.userId)
        ) {
          return false;
        }

        if (
          this.filters.hasOwnProperty('reminders') &&
          this.filters.reminders !== null &&
          ticket.reminders &&
          !includes(ticket.reminders, this.userId)
        ) {
          return false;
        }

        if (this.filters.hasOwnProperty('scope') && this.filters.scope === 'public' && ticket.channel.is_private) {
          return false;
        }

        if (this.filters.hasOwnProperty('scope') && this.filters.scope === 'private' && !ticket.channel.is_private) {
          return false;
        }

        if (this.filters.last_message_type === 'INBOUND') {
          if (ticket.latest_message.type !== 'INBOUND') {
            return false;
          }
        }
      } catch (e) {
        console.error(e);
        //temp
        return true;
      }

      return true;
    });

    // sort on frontend when not searching
    if (!this.filters.term) {
      result = sortBy(result, (ticket) => {
        let value;
        if (this.filters.status === 'CLOSED') {
          value = new Date(ticket.closed_at).getTime();
        } else {
          value = new Date(ticket.latest_message?.created_at || ticket.created_at).getTime();
        }

        if (this.filters.sort === 'date') {
          return value;
        }
        if (this.filters.sort === '-date') {
          return value * -1;
        }
      });
    }

    return result;
  },
};
