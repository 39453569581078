<template>
  <div>
    <div
      v-if="element.meta.message == '' || !element.meta.message"
      class="rounded-xl p-3 text-grey-600"
      style="background-color: #f7f7f7"
    >
      <div class="px-2">
        {{ $t('flowbot.step_presenter_lf_click') }}
        <i class="material-icons text-sm">create</i>
        {{ $t('flowbot.step_presenter_lf_edit_the_question') }}
      </div>
    </div>
    <div v-else class="rounded-xl p-3" style="background-color: #f7f7f7">
      <div class="px-2 text-grey-600" v-html="formatText(element.meta.message)"></div>
      <div v-if="element.meta.custom_field" class="mt-2">
        <div class="input-group-btn">
          <input
            disabled
            :placeholder="
              element.meta.custom_field
                ? element.meta.custom_field.title
                : element.meta.input_type
                ? element.meta.input_type.charAt(0).toUpperCase() + element.meta.input_type.slice(1)
                : $t('flowbot.step_presenter_lf_input_suffix')
            "
            type="text"
            id="user-input"
            class="form-control rounded"
            style="background-color: white"
          />
          <div class="input-group-prepend">
            <button
              disabled
              type="submit"
              class="btn btn-sm success inline-block-32px h-32 rounded-full"
              style="position: relative; left: 2px"
            >
              <i class="material-icons pos-relative" style="left: -4px">keyboard_arrow_right</i>
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="element.meta.fallback_message_unknown_location"
        v-html="
          formatText(
            element.meta.fallback_message_unknown_location +
              ' ' +
              $t('flowbot.step_presenter_lf_fallback_message_unknown_location')
          )
        "
        class="font-italic px-2 pt-2 text-grey-600"
      ></div>
      <div
        v-if="element.meta.fallback_message_too_many_locations"
        v-html="
          formatText(
            element.meta.fallback_message_too_many_locations +
              ' ' +
              $t('flowbot.step_presenter_lf_fallback_message_too_many_locations')
          )
        "
        class="font-italic px-2 pt-2 text-grey-600"
      ></div>
    </div>

    <div class="m-t-1 font-italic valign-center w-full rounded border p-2 text-grey-600">
      <i class="material-icons text-md mx-2 text-base">more_horiz</i>
      {{ $t('flowbot.step_presenter_lf_waiting_for_user_input') }}
    </div>

    <!--        <div v-if="!locationFinderActionIsApplied" class="text-center text-orange px-2 pt-2">-->
    <!--            <i class="material-icons md-18">error</i> Apply the <span class="font-italic">"Assign to location finder team"</span> action in any step-->
    <!--        </div>-->

    <div
      v-if="!locationFinderActionIsApplied"
      class="
        m-t-1
        font-italic
        valign-center
        w-full
        rounded
        border border-orange
        p-2
        px-2
        pt-2
        text-grey-600 text-orange
      "
    >
      <i class="material-icons md-18 mx-2">error</i>
      {{ $t('flowbot.step_presenter_lf_apply_assign_action_info_text') }}
    </div>
  </div>
</template>
<script>
import textFormatter from '../mixins/TextFormatter';

export default {
  props: ['element', 'locationFinderActionIsApplied'],

  mixins: [textFormatter],
};
</script>
<style>
.input-group-prepend {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
