<template>
  <div>
    <div class="mb-5 max-w-full rounded-xl border-1 border-grey-300 bg-white p-6">
      <div class="flex flex-col flex-wrap">
        <div class="mb-2 flex items-center justify-between">
          <div class="flex w-full items-center gap-2">
            <div>
              <div class="mb-1 flex gap-4">
                <span class="t-text-h5 m-0 text-grey-800">
                  {{ $t('usage_overview.overview_section_heading') }}
                </span>
                <t-badge
                  v-if="isUpcoming"
                  class="w-fit"
                  variant="default"
                  :text="$t(`usage_overview.overview_section_badge_${selectedBreakdown.periodType}`)"
                />
              </div>
              <span class="t-text-sm text-grey-600">
                {{ $t('usage_overview.overview_section_subheading') }}
              </span>
            </div>
          </div>
        </div>

        <t-inline-banner v-if="isUpcoming" class="mb-2 mt-2">
          <template #icon>
            <info-linear />
          </template>
          <div class="t-text-sm-emphasize mb-1">
            {{ $t('usage_overview.overview_section_upcoming_banner_title') }}
          </div>
          <div class="t-text-sm">
            {{ $t('usage_overview.overview_section_upcoming_banner_description') }}
          </div>
        </t-inline-banner>

        <div class="flex flex-col">
          <conversation-overview />
          <balance-overview />
          <div class="pt-2">
            <div v-for="(info, i) in OVERVIEW_INFORMATIONS" :key="i" class="float-left mb-2 mt-4 w-1/2 pl-1 pr-2">
              <div class="t-text-sm-emphasize mb-3 flex items-center gap-2">
                <component :is="info.icon" size="20px"></component>
                <span>{{ $t(info.title) }}</span>
              </div>
              <div class="t-text-sm text-grey-600">
                {{ $t(info.description) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <add-conversation-blocks-modal v-if="!hasConversationInvoice" @success="handleSuccessModalOpen('conversation')" />
    <add-carrier-costs-modal v-if="!hasCarrierCostInvoice" @success="handleSuccessModalOpen('balance')" />
    <add-blocks-success-modal
      v-if="successModalStatus !== 'none'"
      :type="successModalStatus"
      @close="handleSuccessModalClose"
    />
  </div>
</template>

<script lang="ts" setup>
import { InfoLinear } from '@trengo/trengo-icons';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

import { useBillingStore } from '@/store/pinia';

import AddBlocksSuccessModal from './AddBlocksSuccessModal.vue';
import AddCarrierCostsModal from './AddCarrierCostsModal.vue';
import AddConversationBlocksModal from './AddConversationBlocksModal.vue';
import BalanceOverview from './BalanceOverview.vue';
import ConversationOverview from './ConversationOverview.vue';
import { OVERVIEW_INFORMATIONS } from '../constants';
import { useUsageOverviewStore } from '../store';

type BreakdownType = 'balance' | 'conversation';
const successModalStatus = ref<BreakdownType | 'none'>('none');
const { isUpcoming, selectedBreakdown } = storeToRefs(useUsageOverviewStore());
const { hasCarrierCostInvoice, hasConversationInvoice } = storeToRefs(useBillingStore());

const refresh = async () => {
  await useUsageOverviewStore().getUsageOverview();
  useBillingStore().getInvoices();
};

const handleSuccessModalOpen = (type: BreakdownType) => {
  successModalStatus.value = type;
};
const handleSuccessModalClose = () => {
  successModalStatus.value = 'none';
  refresh();
};
</script>
