export const FEATURE_ICON = {
  AUTO_REPLIES: 'auto-replies',
  BROADCASTING: 'broadcasting',
  BUSINESS_HOURS: 'business-hours',
  CSAT: 'csat',
  CUSTOM_FIELDS: 'custom-fields',
  FLOWBOTS: 'flowbots',
  HELP_CENTRE: 'help-centre',
  HELPMATE: 'helpmate',
  LABELS: 'labels',
  LOCATIONS: 'locations',
  QUICK_REPLY: 'quick-reply',
  RULES: 'rules',
  TEAMS: 'teams',
  TICKET_RESULTS: 'ticket-results',
  VIEWS: 'views',
  WIDGET: 'widget',
} as const;
