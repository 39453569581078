<template>
  <div>
    <div class="mb-2 mt-2 font-bold">{{ $t('flowbot.step_file') }}</div>
    <label
      for="a"
      class="btn btn-default mb-5 mt-2"
      style="cursor: pointer; border-radius: 0.5rem; display: inherit; margin-bottom: 0"
    >
      <div class="valign-center text-grey-600 hover:text-grey-600">
        <i class="material-icons mr-2">attach_file</i>
        <template v-if="uploading">
          <i class="fa fa-spin fa-spinner" style="font-size: 20px"></i>
        </template>
        <template v-else>
          <template v-if="element.meta.media_url">{{ $t('flowbot.step_file_change_file') }}</template>
          <template v-else>{{ $t('flowbot.step_file_add_file') }}</template>
        </template>
      </div>
      <input id="a" ref="input" type="file" :accept="accept" hidden @change="addFile($event)" />
    </label>
  </div>
</template>
<script>
export default {
  name: 'File',
  emits: ['input'],
  props: {
    element: {
      type: Object,
      required: true,
    },
    accept: {
      type: String,
      default: '*',
    },
  },

  data() {
    return {
      attachment: {},
      uploading: false,
    };
  },

  methods: {
    addFile(event) {
      this.uploading = true;
      let formData = new FormData();
      formData.append('file', event.target.files[0]);

      axios
        .post('/api/v2/attachments', formData)
        .then((res) => {
          this.uploading = false;
          this.attachment = res.data;

          this.element.meta.client_name = res.data.client_name;
          this.element.meta.file_name = res.data.file_name;
          this.element.meta.file_path = res.data.file_path;
          this.element.meta.file_size = res.data.file_size;
          this.element.meta.file_size_human = this.humanFileSize(res.data.file_size);
          this.element.meta.file_type = res.data.file_type;

          this.$emit('input', this.element);
        })
        .catch(() => {
          this.uploading = false;
          this.flashError(this.$t('flowbot.step_file_error_uploading_the_file'));
        });
    },

    // https://stackoverflow.com/a/14919494/3344029
    humanFileSize(bytes, si = true) {
      let thresh = si ? 1000 : 1024;
      if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }
      let units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      let u = -1;
      do {
        bytes /= thresh;
        ++u;
      } while (Math.abs(bytes) >= thresh && u < units.length - 1);
      return bytes.toFixed(1) + ' ' + units[u];
    },
  },
};
</script>
