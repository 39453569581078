<script lang="ts" setup>
import { useSubscriptionStore, useCompanyProfileStore } from '@/store/pinia';

import SeatBasedEdit from './SeatBasedSubscription/components/EditSubscription.vue';
import UsageBasedEdit from './UsageBasedSubscription/components/EditSubscription.vue';

const companyProfileStore = useCompanyProfileStore();
const subscriptionStore = useSubscriptionStore();
</script>

<template>
  <t-spinner v-if="subscriptionStore.subscription.isLoading" />
  <usage-based-edit v-else-if="companyProfileStore.isUsageBasedPricingModel" />
  <seat-based-edit v-else />
</template>
