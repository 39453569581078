import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const contactGroupsRoutes: RouteRecord[] = [
  {
    path: '/admin/contact_groups',
    component: require('../../components/ContactGroups/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__CONTACT_GROUPS__MANAGE },
    children: [
      {
        path: '/admin/contact_groups/create',
        component: require('../../components/ContactGroups/Edit').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CONTACT_GROUPS__MANAGE },
      },
      {
        path: '/admin/contact_groups/:id',
        component: require('../../components/ContactGroups/Edit').default,
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CONTACT_GROUPS__MANAGE },
      },
      {
        path: '',
        component: require('../../components/AdminIndexBox').default,
        props: { boxName: 'contact_groups' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CONTACT_GROUPS__MANAGE },
      },
    ],
  },
];

export default contactGroupsRoutes;
