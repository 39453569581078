<template>
  <div class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis" v-text="ivrMenu.name"></span>
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <div class="p-a p-b-0 container">
            <div class="p-a">
              <form @submit.prevent="save">
                <div class="box">
                  <div class="box-header">
                    <h2>{{ $t('voice.ivr_menu') }}</h2>
                  </div>
                  <div class="box-divider m-a-0"></div>
                  <div class="box-body">
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('voice.ivr_name') }}</label>
                      <div class="col-sm-10">
                        <input v-model="ivrMenu.name" type="text" autocomplete="off" class="form-control" required />
                        <span class="form-text">{{ $t('voice.the_internal_ivr_name') }}</span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('voice.ivr_greeting') }}</label>
                      <div class="col-sm-10">
                        <select v-model="ivrMenu.greeting_id" class="form-control">
                          <option :value="null">{{ $t('voice.ivr_none') }}</option>
                          <option v-for="greeting in greetings" :value="greeting.id">{{ greeting.title }}</option>
                        </select>
                        <span class="form-text">
                          {{ $t('voice.this_greeting_will_be_played_when_the_caller_enters_the_menu_click') }}
                          <a class="btn btn-xs btn-default" @click="openGreetingsModal">{{ $t('voice.ivr_here') }}</a>
                          {{ $t('voice.to_manage_greetings') }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('voice.ivr_routes') }}</label>
                      <div class="col-sm-10">
                        <div v-for="(route, index) in ivrMenu.routes" class="mb-2">
                          <div class="box flex p-4">
                            <div class="flex-1">
                              <div class="form-group row">
                                <label class="col-sm-3 form-control-label">{{ $t('voice.ivr_keystroke') }}</label>
                                <div class="col-sm-9">
                                  <select v-model="route.trigger" class="form-control" required>
                                    <option v-if="!route.trigger" value="" selected disabled>
                                      {{ $t('voice.ivr_select_a_trigger') }}
                                    </option>
                                    <option
                                      v-for="option in triggerOptions"
                                      :value="option"
                                      :disabled="optionAvailable(index, option)"
                                    >
                                      {{ option }}
                                    </option>
                                  </select>
                                  <span class="form-text">
                                    {{ $t('voice.ivr_the_keystroke_to_trigger_the_action') }}
                                  </span>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-sm-3 form-control-label">{{ $t('voice.ivr_action') }}</label>
                                <div class="col-sm-9">
                                  <select v-model="route.action" class="form-control" required>
                                    <option v-if="!route.action" value="" selected disabled>
                                      {{ $t('voice.ivr_select_an_action') }}
                                    </option>
                                    <option value="voicemail">{{ $t('voice.ivr_route_to_voicemail') }}</option>
                                    <option value="team">{{ $t('voice.ivr_route_to_team') }}</option>
                                    <option value="ivr">{{ $t('voice.ivr_route_to_ivr_menu') }}</option>
                                    <option value="phone">{{ $t('voice.ivr_route_to_phone_number') }}</option>
                                    <option value="end_call">{{ $t('voice.ivr_end_call') }}</option>
                                  </select>
                                  <span class="form-text">{{ $t('voice.ivr_the_route_action') }}</span>
                                </div>
                              </div>

                              <div v-if="route.action === 'team'" class="form-group row">
                                <label class="col-sm-3 form-control-label">{{ $t('voice.ivr_team') }}</label>
                                <div class="col-sm-9">
                                  <select v-model="route.team_id" class="form-control" required>
                                    <option v-if="!route.team_id" value="" selected disabled>
                                      {{ $t('voice.ivr_select_a_team') }}
                                    </option>
                                    <option v-for="team in $root.teams" :value="team.id">{{ team.name }}</option>
                                  </select>
                                  <span class="form-text">
                                    {{ $t('voice.ivr_the_team_that_the_call_will_be_routed_to') }}
                                  </span>
                                </div>
                              </div>
                              <div v-if="route.action === 'ivr'" class="form-group row">
                                <label class="col-sm-3 form-control-label">{{ $t('voice.ivr') }}</label>
                                <div class="col-sm-9">
                                  <select v-model="route.target_ivr_menu_id" class="form-control" required>
                                    <option v-if="!route.target_ivr_menu_id" value="" selected disabled>
                                      {{ $t('voice.select_a_ivr_menu') }}
                                    </option>
                                    <option v-for="ivrMenu in ivrMenus" :value="ivrMenu.id">{{ ivrMenu.name }}</option>
                                  </select>
                                </div>
                              </div>
                              <div v-if="route.action === 'phone'" class="form-group row">
                                <label class="col-sm-3 form-control-label">{{ $t('voice.ivr_phone_number') }}</label>
                                <div class="col-sm-9">
                                  <tel-input v-model="route.phone_number" required />
                                  <span class="form-text">
                                    {{ $t('voice.ivr_enter_the_phone_number_in_international_format') }}
                                  </span>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 form-control-label">{{ $t('voice.ivr_greeting') }}</label>
                                <div class="col-sm-9">
                                  <select v-model="route.greeting_id" class="form-control">
                                    <option :value="null">{{ $t('voice.ivr_none') }}</option>
                                    <option v-for="greeting in greetings" :value="greeting.id">
                                      {{ greeting.title }}
                                    </option>
                                  </select>
                                  <span class="form-text">
                                    {{
                                      $t(
                                        'voice.ivr_this_optional_greeting_will_be_played_before_taking_the_action_on_the_call_click'
                                      )
                                    }}
                                    <a class="btn btn-xs btn-default" @click="openGreetingsModal">
                                      {{ $t('voice.ivr_here') }}
                                    </a>
                                    {{ $t('voice.ivr_to_manage_greetings') }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="text-muted">
                                <i class="fa fa-remove text-muted ml-4 cursor-pointer" @click="removeRoute(route)"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-4 pt-4 text-center">
                          <a class="text-muted" @click="addRoute">{{ $t('voice.ivr_add_route') }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div class="box-body flow-root">
                    <button
                      type="submit"
                      :class="{ loader: saving }"
                      class="btn btn-success"
                      :disabled="saving || !ready_to_save"
                      v-text="saving ? 'Saving..' : 'Save changes'"
                    ></button>
                    <button
                      v-if="action == 'edit'"
                      type="button"
                      class="btn btn-link text-muted pull-right"
                      @click="destroy()"
                    >
                      {{ $t('voice.delete') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <greetings-modal ref="greetingsManager" :greetings="greetings" @changed="getGreetings"></greetings-modal>
  </div>
</template>

<script type="text/babel">
import { includes, map } from 'lodash';

import GreetingsModal from '../Channels/Voip/Greetings';

export default {
  name: 'IvrEdit',
  emits: ['call-loadlist-method'],
  props: {
    action: {
      type: String,
      default: 'create',
    },
    ivrMenus: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    GreetingsModal,
  },

  computed: {
    ready_to_save() {
      if (this.ivrMenu.name === '') {
        return;
      }
      return true;
    },
  },

  data() {
    return {
      loaded: false,
      saving: false,
      greetings: [],
      ivrMenu: {
        greeting_id: null,
        routes: [],
      },
      triggerOptions: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '#'],
    };
  },

  mounted() {
    if (this.action === 'edit') {
      axios.get('/api/v2/ivr_menus/' + this.$route.params.id).then((result) => {
        this.ivrMenu = result.data;
      });
    } else {
      this.addRoute();
    }
    this.getGreetings();
  },

  methods: {
    optionAvailable(index, option) {
      if (this.ivrMenu.routes[index].trigger === option || !includes(map(this.ivrMenu.routes, 'trigger'), option)) {
        return false;
      }
      return true;
    },

    save() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      if (this.action === 'create') {
        return this.store();
      } else {
        this.update();
      }
    },

    addRoute() {
      this.ivrMenu.routes.push({
        id: null,
        ivr_menu_id: this.ivrMenu.id,
        greeting_id: null,
        phone_number: null,
        target_ivr_menu_id: null,
        team_id: null,
        action: '',
        trigger: '',
      });
    },

    removeRoute(route) {
      if (this.ivrMenu.routes.length <= 1) {
        return;
      }

      this.ivrMenu.routes.splice(this.ivrMenu.routes.indexOf(route), 1);
    },

    store() {
      axios
        .post('/api/v2/ivr_menus', this.ivrMenu)
        .then((res) => {
          this.saving = false;
          this.$emit('call-loadlist-method');
          router.push('/admin/ivr/' + res.data.id);
          this.flashSuccess(this.$t('voice.the_ivr_has_been_created_successfully'));
        })
        .catch(() => {
          this.saving = false;
        });
    },

    update() {
      axios
        .put('/api/v2/ivr_menus/' + this.ivrMenu.id, this.ivrMenu)
        .then((res) => {
          this.saving = false;
          this.$emit('call-loadlist-method');
          this.flashSuccess(this.$t('voice.the_ivr_has_been_updated_successfully'));
        })
        .catch(() => {
          this.saving = false;
        });
    },

    async destroy() {
      const shouldDeleteIvr = await this.$tConfirm(this.$t('voice.are_you_sure_you_want_to_delete_this_ivr'), {
        delete: true,
        title: this.$t('general.are_you_sure'),
      });
      if (shouldDeleteIvr) {
        axios.delete('/api/v2/ivr_menus/' + this.ivrMenu.id).then(() => {
          this.$emit('call-loadlist-method');
          router.push('/admin/ivr/create');
          this.flashSuccess(this.$t('voice.the_ivr_has_been_deleted_successfully'));
        });
      }
    },

    openGreetingsModal() {
      $(this.$refs.greetingsManager.$refs.modal).modal('show');

      $(this.$refs.greetingsManager.$refs.newModal).on('hidden.bs.modal', () => {
        $(this.$refs.greetingsManager.$refs.modal).modal('show');
      });
    },

    getGreetings() {
      axios.get('/api/v2/voip/greetings').then((result) => {
        this.greetings = result.data.data;
      });
    },
  },
};
</script>
