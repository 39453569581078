<template>
  <div class="fade-in" :style="InfoBarStyle">
    <t-inline-banner type="warning">
      <template #icon>
        <alert-triangle-linear />
      </template>
      <div>Are you sure you want to leave without activating workload balancing? All progress will be lost.</div>
      <template #action>
        <t-button size="sm" btn-style="secondary" @click="onCancel">Yes, leave</t-button>
      </template>
    </t-inline-banner>
  </div>
</template>

<script>
import { AlertTriangleLinear } from '@trengo/trengo-icons';

export default {
  name: 'CloseDialog',
  emits: ['cancel'],
  components: {
    AlertTriangleLinear,
  },
  props: {
    element: {
      type: HTMLDivElement,
      default: () => ({}),
    },
  },
  data() {
    return {
      InfoBarStyle: {
        top: 0,
        left: 0,
        width: '100%',
        position: 'fixed',
      },
    };
  },
  mounted() {
    this.infoBarPosition();
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    infoBarPosition() {
      const elementPadding = 8;
      const footerElement = this.element;
      const footerElementRect = footerElement?.getBoundingClientRect();
      const TModalMain = document.querySelector('.t-modal-main');
      const scrollBarWidth = TModalMain.offsetWidth - TModalMain.clientWidth;
      if (!footerElementRect) {
        return false;
      }
      this.InfoBarStyle.top = footerElementRect.bottom - footerElementRect.height * 3 - elementPadding + 'px';
      this.InfoBarStyle.width = footerElementRect.width - scrollBarWidth + 'px';
      this.InfoBarStyle.left = footerElementRect.left + 'px';
      this.InfoBarStyle.right = footerElementRect.right + 'px';
    },
  },
};
</script>
