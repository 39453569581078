<template>
  <t-modal
    v-model="computedIsOpen"
    :close-on-backdrop-click="true"
    :close-on-esc="true"
    :close-icon="true"
    :title="$tc('profile.merge_profiles')"
    variant="narrow"
    data-test="integration-hub-install-modal"
    @close="$emit('close', false)"
  >
    <div class="flex flex-col">
      <p class="t-text-md-emphasize m-0 p-0 text-grey-800">
        {{ $tc('profile.profiles_selected', profiles.length, { count: profiles.length }) }}
      </p>

      <p class="t-text-md m-0 mb-6 overflow-auto break-words p-0 font-mono text-grey-700">
        <span v-for="(profile, index) in profiles" :key="profile">
          {{ profile }}{{ index === profiles.length - 1 ? '' : ',' }}
        </span>
      </p>

      <p class="t-text-md-emphasize m-0 mb-2 p-0 text-grey-800">
        {{ $tc('profile.destination_profile') }}
      </p>

      <t-input-search
        v-model="searchText"
        size="sm"
        :disabled="isSearching"
        @keydown.enter="search"
        @input="onSearch"
        @clear="clearSearch"
      />

      <t-select-input
        :options="listItems"
        :placeholder="$t('profile.destination_profile')"
        :disabled="isSearching"
        @change="selectDestination"
      />
    </div>

    <template #footer>
      <div class="flex flex-row items-center">
        <t-button btn-style="secondary" class="mr-2" @click.prevent="$emit('close', false)">
          {{ $tc('general.cancel') }}
        </t-button>

        <t-button :disabled="!isMergePossible" @click.prevent="mergeProfiles">
          {{ $tc('profile.merge_profiles') }}
        </t-button>
      </div>
    </template>
  </t-modal>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';

import { vm } from '@/ApplicationHandler';
import { mergeProfiles as mergeProfileRequest } from '@/components/Profiles/Api/api';
import { request } from '@/util';

import type Profile from '@/types/profile';
import type { AxiosPromise } from 'axios';

type Props = {
  isOpen: boolean;
  profiles: Profile['id'][];
};

const props = withDefaults(defineProps<Props>(), { isOpen: false });
const emit = defineEmits<{
  (e: 'close', reset: boolean): void;
}>();

const isSearching = ref(false);

onBeforeMount(async () => {
  await search();
});

const searchText = ref('');
const destinationProfiles = ref<Profile[]>([]);

const listItems = computed(() => {
  return destinationProfiles.value.map((profile) => {
    return { text: `${profile.name} (ID: ${profile.id})`, value: profile.id };
  });
});

async function getProfiles(searchTerm = ''): Promise<AxiosPromise<Profile[]>> {
  return request('/api/v2/profiles', 'GET', {}, {}, { term: searchTerm });
}

async function search() {
  isSearching.value = true;

  if (!searchText.value || searchText.value === '') {
    await getProfiles()
      .then((response) => {
        destinationProfiles.value = response?.data.data;
      })
      .finally(() => {
        isSearching.value = false;
      });
  } else {
    await getProfiles(searchText.value)
      .then((response) => {
        destinationProfiles.value = response?.data.data;
      })
      .finally(() => {
        isSearching.value = false;
      });
  }
}

async function clearSearch() {
  searchText.value = '';
  await search();
}

const isMerging = ref(false);

async function mergeProfiles() {
  if (props.profiles.some((profileId) => profileId === selectedDestination.value)) {
    vm.flashError(vm.$tc('profile.you_cant_merge_into_self'));
    return;
  }

  isMerging.value = true;

  await mergeProfileRequest(props.profiles, selectedDestination.value)
    .then(() => {
      vm.flashSuccess(vm.$tc('profile.successfully_merged'));
      emit('close', true);
    })
    .catch((err) => {
      vm.flashError(err?.message);
      console.error(err);
    })
    .finally(() => {
      isMerging.value = false;
    });
}

const selectedDestination = ref<Profile['id']>(0);
const isMergePossible = computed(() => {
  return selectedDestination.value !== 0;
});

function selectDestination(profileId: Profile['id']) {
  selectedDestination.value = parseInt(String(profileId));
}

function onSearch(value: string) {
  searchText.value = value;
}

const computedIsOpen = computed(() => {
  return props.isOpen;
});
</script>
