import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7c52c6a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { "data-test": "trying-templates" }
const _hoisted_2 = { class: "t-text-md mb-6 text-grey-600" }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "t-text-md-emphasize mb-2 text-grey-800" }
const _hoisted_7 = {
  key: 0,
  class: "t-text-sm mt-2 flex items-center text-leaf-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_messages = _resolveComponent("messages")!
  const _component_t_spinner = _resolveComponent("t-spinner")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_check_circle_fill = _resolveComponent("check-circle-fill")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('whatsapp.value_of_templates')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.backgroundImageSrc,
          class: "mb-4 h-80 w-140 rounded-lg"
        }, null, 8 /* PROPS */, _hoisted_4),
        _createElementVNode("img", {
          src: _ctx.phoneImageSrc,
          class: _normalizeClass(`absolute bottom-0 left-0 right-0 m-auto ${_ctx.isMobile && 'h-88'}`)
        }, null, 10 /* CLASS, PROPS */, _hoisted_5),
        _createVNode(_component_messages, {
          messages: _ctx.messages,
          "wrapper-classes": "absolute\n            left-0\n            right-0\n            bottom-0\n            m-auto\n            flex flex-col\n            overflow-y-auto overflow-x-hidden\n            p-2\n            xs2:h-56 xs2:w-5/6\n            sm:h-52 sm:w-79"
        }, null, 8 /* PROPS */, ["messages"])
      ]),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('whatsapp.give_it_a_go')), 1 /* TEXT */),
      _createElementVNode("div", {
        class: _normalizeClass(`template-wrapper template-wrapper-${_ctx.templateSent ? 'inactive' : 'active'}`)
      }, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('whatsapp.template_example_text', { name: _ctx.defaultTemplateName })), 1 /* TEXT */),
        _createVNode(_component_t_button, {
          "data-test": "send-template-button",
          size: "sm",
          class: "flex items-center justify-center",
          disabled: _ctx.templateSent,
          onClick: _ctx.sendTemplateMessage
        }, {
          default: _withCtx(() => [
            (_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_t_spinner, {
                  key: 0,
                  class: "mr-2"
                }))
              : _createCommentVNode("v-if", true),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('whatsapp.send_template')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled", "onClick"])
      ], 2 /* CLASS */),
      (_ctx.templateSent)
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
            _createVNode(_component_check_circle_fill, {
              class: "mr-1",
              size: "18"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.getSentMessage()), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}