import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center" }
const _hoisted_2 = {
  class: "t-text-md-emphasize m-0 max-w-[33%] text-ellipsis p-0 text-grey-700",
  "data-test": "related-ticket-name"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "t-text-md m-0 mt-1 text-ellipsis p-0 text-grey-700",
  "data-test": "related-ticket-subject"
}
const _hoisted_5 = { class: "t-text-md-emphasize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_trash_linear = _resolveComponent("trash-linear")!
  const _component_sidebar_dropdown = _resolveComponent("sidebar-dropdown")!

  return (_openBlock(), _createBlock(_component_sidebar_dropdown, {
    title: _ctx.$tc('ticket_sidebar.linked_conversations'),
    "collapse-key": "relatedTicketsDropdown",
    "data-test": "related-tickets-dropdown"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relatedTickets, (ticket) => {
        return (_openBlock(), _createElementBlock("div", {
          key: ticket.id,
          class: "border-opacity-300 flex flex-col border-b border-grey-300 py-1 last:border-none last:pb-0 only:border-none only:py-0",
          "data-test": "related-ticket-item-wrapper"
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.displayedName(ticket)), 1 /* TEXT */),
            _createVNode(_component_router_link, {
              class: "t-text-md ml-3 text-ellipsis underline",
              to: `/tickets/${ticket.id}`,
              "data-test": "related-ticket-number"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" #" + _toDisplayString(ticket.id), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]),
            _createElementVNode("button", {
              class: "ml-auto p-1 text-grey-500 transition-colors hover:text-grey-700 active:text-grey-800",
              "data-test": "remove-related-ticket-button",
              onClick: _withModifiers(($event: any) => (_ctx.removeRelation(ticket.id)), ["prevent"])
            }, [
              _createVNode(_component_trash_linear, { size: "1.1rem" })
            ], 8 /* PROPS */, _hoisted_3)
          ]),
          (ticket.subject)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('tickets.subject')) + ":", 1 /* TEXT */),
                _createTextVNode(" " + _toDisplayString(ticket.subject), 1 /* TEXT */)
              ]))
            : _createCommentVNode("v-if", true)
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]))
}