<template>
  <div class="row-col">
    <edit v-if="action === 'edit'" action="edit" @reloadChannelIndexList="$emit('reloadChannelIndexList')" />
    <create-email v-else action="create" />
  </div>
</template>

<script type="text/babel">
import CreateEmail from './Pages/CreateEmail';
import Edit from './Pages/Edit';

export default {
  emits: ['reloadChannelIndexList'],
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },
  components: {
    CreateEmail,
    Edit,
  },
};
</script>
