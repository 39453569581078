import { request } from '@/util';

export function storeProfileCustomField(profileId, customFieldId, customFieldValue) {
  return axios.post('/api/v2/profiles/' + profileId + '/custom_fields', {
    custom_field_id: customFieldId,
    value: customFieldValue,
  });
}

/**
 * @param { number[] } sourceProfileIds Array of profile IDs to be merged into the destination
 * @param { number } destinationProfileId ID of the destination profile
 */
export async function mergeProfiles(sourceProfileIds, destinationProfileId) {
  return request('/api/v2/profiles/merge', 'POST', {
    sourceProfileIds,
    destinationProfileId,
  });
}
