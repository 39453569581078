<template>
  <div data-test="team-members" class="flex flex-col items-start">
    <div v-if="!isUserEmpty" class="flex w-full flex-col items-start justify-between sm:flex-row sm:items-end">
      <div class="flex flex-row flex-col items-start justify-start">
        <div class="t-text-mobile-h5 sm:t-text-desktop-h5 mb-1 flex text-grey-800 sm:flex-row">
          <span>{{ $t('team.team_members_title') }}</span>
        </div>
        <div class="t-text-desktop-paragraph-sm text-grey-600">{{ $t('team.team_members_description') }}</div>
      </div>
      <div class="mt-4 sm:mt-0">
        <add-button data-test="add-team-members" @click="showAddEntityModal">
          {{ $t('team.add_team_member_button') }}
        </add-button>
      </div>
    </div>
    <div class="w-full" :class="tableHeadClass">
      <team-table :is-empty="isUserEmpty">
        <template #head>
          <div class="grid w-full grid-cols-4 gap-0">
            <div class="col-span-4 flex flex-row items-center sm:justify-between">
              <div class="t-text-desktop-label-xs m-0 p-0 text-grey-800">{{ $t('team.team_member_name') }}</div>
            </div>
            <div v-if="hasLimit" class="col-end-6 flex flex-row items-center justify-between">
              <div class="t-text-desktop-label-xs m-0 w-max p-0 text-left text-grey-800">
                {{ $t('team.team_member_limit') }}
              </div>
              <div class="ml-1 w-96px" />
            </div>
          </div>
        </template>

        <template #empty>
          <div data-test="team-members-empty" class="p-6 sm:p-10">
            <div class="flex flex-col items-center justify-center">
              <div class="t-text-desktop-h7 text-center text-grey-800">{{ $t('team.no_team_member_error') }}</div>
              <div class="t-text-desktop-paragraph-md mb-4 mt-1 text-center text-grey-600">
                {{ $t('team.add_team_members_by_clicking_the_button_description') }}
              </div>
              <add-button data-test="add-team-members" @click="showAddEntityModal">
                {{ $t('team.add_team_member_button') }}
              </add-button>
            </div>
          </div>
        </template>

        <template #rows>
          <div>
            <table-row
              v-for="user in clonedUsers"
              :key="user.id"
              :is-last="isTheLastOne(user)"
              data-test="team-members-row"
            >
              <user-row-workload-balance
                removable
                :init-limit="user.load_balancing_ticket_limit"
                :has-limit="hasLimit"
                :user="user"
                @input-value-changed="handleChangeInputValue"
                @remove="handleRemoveUser"
                @change_limit="handleChangeUserLimit"
              />
            </table-row>
          </div>
        </template>
      </team-table>
      <add-entity-modal
        v-model="addEntityModalIsOpen"
        entity-type="member"
        :entity="validUsers"
        @submit="handleSubmitAddMember"
      />
    </div>
  </div>
</template>

<script>
import { cloneDeep, differenceBy, last } from 'lodash';

import { deleteTeamMemberRequest, updateTeamRequestNewAPI } from '@/components/Teams/api';
import UserRowWorkloadBalance from '@/components/Teams/components/EnableWorkLoadModal/components/UserRowWorkloadBalance/UserRowWorkloadBalance';
import UserStatus from '@/Configs/Constants/UserStatus';
import { useUsersStore } from '@/store/pinia';

import AddButton from '../AddButton';
import AddEntityModal from '../AddEntityModal';
import TableRow from '../TableRow';
import TeamTable from '../TeamTable';

export default {
  name: 'TeamMembers',
  emits: ['change-users', 'remove-user', 'input-value-changed'],
  components: { UserRowWorkloadBalance, AddEntityModal, AddButton, TableRow, TeamTable },
  data() {
    return {
      addEntityModalIsOpen: false,
      status: UserStatus,
      clonedUsers: {},
    };
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    teamName: {
      type: String,
      default: '',
    },
    hasLimit: {
      type: Boolean,
      default: false,
    },
    teamId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    users(val) {
      this.initial(val);
    },
  },
  mounted() {
    this.initial(this.users);
  },
  computed: {
    tableHeadClass() {
      return this.isUserEmpty ? 'table-heading-user-is-empty' : 'table-heading';
    },
    computedUsers: {
      get() {
        return this.clonedUsers;
      },
      set(val) {
        this.initial(val);
      },
    },
    isUserEmpty() {
      return !this.computedUsers.length;
    },
    validUsers() {
      return differenceBy(useUsersStore().users, this.users, 'id') ?? [];
    },
  },
  methods: {
    handleChangeInputValue(value) {
      this.$emit('input-value-changed', value);
    },
    initial(value) {
      this.clonedUsers = cloneDeep(value);
    },
    getUserById(userId) {
      const getUserById = this.computedUsers.filter((u) => userId === u.id)?.[0];
      if (!getUserById) {
        return false;
      }
      return getUserById || false;
    },
    handleChangeUserLimit(user) {
      const getUserById = this.getUserById(user.id);
      const updatedUser = this.updateUserLimitByUserId(getUserById, user);
      this.reMapUsersByUserId(updatedUser);
      this.$emit('change', this.computedUsers);
    },
    updateUserLimitByUserId(getUserById, user) {
      getUserById.load_balancing_ticket_limit = user.load_balancing_ticket_limit;
      return getUserById;
    },
    reMapUsersByUserId(user) {
      this.computedUsers = this.computedUsers.filter((u) => {
        return u.id === user.id;
      });
    },
    isTheLastOne(user) {
      return last(this.computedUsers)?.id === user.id;
    },
    handleSubmitAddMember(members) {
      if (!members.length) {
        return false;
      }

      const data = { name: this.teamName, users: {} };
      members.map(function (user) {
        data['users'][user.id] = 0;
      });
      updateTeamRequestNewAPI(this.teamId, data).then(() => {
        this.computedUsers = [...this.computedUsers, ...members];
        this.$emit('change-users', this.computedUsers);
        useUsersStore().reload();
        const flashMessage =
          members.length > 1
            ? this.$t('team.users_were_added_successfully')
            : this.$t('team.user_was_added_successfully');
        this.flashSuccess(flashMessage);
      });
    },
    showAddEntityModal() {
      this.addEntityModalIsOpen = true;
    },
    async handleRemoveUser(user) {
      const shouldDeleteUser = await this.$tConfirm(
        this.$t('team.are_you_sure_to_remove_user_from_team', { userName: user.name, teamName: this.teamName }),
        {
          delete: true,
          hideDeleteIcon: true,
          title: this.$t('team.remove_from_team_title'),
          confirmText: this.$t('general.remove'),
        }
      );
      if (!shouldDeleteUser) {
        return false;
      }
      deleteTeamMemberRequest(user.id, this.teamId)
        .then(() => {
          this.computedUsers = this.computedUsers.filter((u) => u.id !== user.id);
          useUsersStore().reload();
          this.$emit('remove-user', this.computedUsers);
          this.flashSuccess(this.$t('team.user_removed_successfully_message'));
        })
        .catch((err) => {
          console.error(err?.response?.data);
        });
    },
  },
};
</script>

<style src="./TeamMembers.scss" scoped lang="scss" />
