<script lang="ts" setup>
import { FolderLinear, ExternalLinkLinear } from '@trengo/trengo-icons';
import { onMounted, ref, type Ref } from 'vue';

import { vm } from '@/ApplicationHandler';
import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import router from '@/routes/routes';
import { useFeatureFlagStore } from '@/store/pinia';

import ContactGroupsOverview from './ContactGroupsOverview.vue';
import NoContactGroups from './NoContactGroups.vue';
import SkeletonLoader from './SkeletonLoader.vue';
import { getContactGroups } from '../api';
import Sidebar from '../Sidebar/Index.vue';
import { type ContactGroup } from '../types';

const contactGroups: Ref<ContactGroup[]> = ref([]);
const isDataFetched = ref(false);

function contactGroupsFeatureFlagEnabled() {
  return useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.TI_CG_FRONTEND);
}

function handleManageGroups() {
  vm.$router.push('/admin/contact_groups');
}

onMounted(() => {
    setTimeout(() => {
        if (!contactGroupsFeatureFlagEnabled()) {
          router.push('/contacts');
        }
    }, 10);
  getContactGroups().then((response) => {
      isDataFetched.value = true;
      contactGroups.value = response.data.filter((item: ContactGroup) => item.id!==null) as ContactGroup[];
    });
});
</script>

<template>
  <div class="h-full w-full flex-1 bg-grey-100 pl-4">
    <sidebar>
      <div class="flex flex-row items-center justify-between">
        <div class="border-gray-900 rounded-xl border-1 border-opacity-10 bg-white p-2">
          <FolderLinear width="24px" height="24px" />
        </div>
        <p class="t-text-h3 mb-0 ml-3">{{ vm.$t('broadcast.all_contact_groups') }}</p>
      </div>
      <t-button
        class="t-button t-button-primary flex flex-row items-center justify-between"
        @click="handleManageGroups"
      >
        {{ vm.$t('broadcast.manage_contact_groups') }}
        <ExternalLinkLinear class="ml-1" width="20px" height="20px" />
      </t-button>
    </sidebar>
    <div class="max-h-[85vh] w-full flex-1 !overflow-x-hidden rounded-xl pl-4 pr-6">
      <div v-if="isDataFetched">
        <no-contact-groups v-if="!contactGroups.length" />
        <contact-groups-overview v-else :items="contactGroups" />
      </div>
      <skeleton-loader v-else />
    </div>
  </div>
</template>
