export const QUOTE_TOOLTIPS = [
  {
    key: 'eur25-credits',
    tooltip: 'Replaces your Wallet. Is used for carrier costs.',
  },
] as const;

export const SEGMENT_TRACKING_DETAILS = {
  BILLING_HISTORY: {
    event_name: 'billing_history',
    action: 'open',
    object_title: 'Billing History',
  },
  CANCEL_BEGIN: {
    event_name: 'subscription_cancel',
    action: 'begin',
    object_title: 'Cancel Subscription',
  },
  CANCELLED: {
    event_name: 'subscription_cancel',
    action: 'completed',
    object_title: 'Cancel Subscription',
  },
  EDIT: {
    event_name: 'subscription_edit',
    action: 'begin',
    object_title: 'Edit Subscription',
  },
};
