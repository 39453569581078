import { useMediaQuery } from '@vueuse/core';

type BreakpointSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

// this constant is derived from tailwind.config
const screens: Record<BreakpointSize, number> = {
  xs: 375,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const useMaxWidth = (size: BreakpointSize) => useMediaQuery(`(max-width: ${screens[size]}px)`);

// FIXME: refactor these utils to useMediaQuery from vueuse
const xs = (val: number) => val < screens.sm;
const sm = (val: number) => val >= screens.sm && val < screens.md;
const md = (val: number) => val >= screens.md && val <= screens.lg;
const lg = (val: number) => val >= screens.lg && val <= screens.xl;
const xl = (val: number) => val >= screens.xl;

const calculateBreakPoints = (w: number): BreakpointSize => {
  if (xs(w)) {
    return 'xs';
  } else if (sm(w)) {
    return 'sm';
  } else if (md(w)) {
    return 'md';
  } else if (lg(w)) {
    return 'lg';
  } else if (xl(w)) {
    return 'xl';
  } else {
    return 'sm';
  }
};

export const handleBreakpointsComparisonObjects = (breakpoint: BreakpointSize) => {
  const instanceBreakPoints = {
    gt_xs: false,
    eq_xs: false,

    gt_sm: false,
    eq_sm: false,

    gt_md: false,
    eq_md: false,

    gt_lg: false,
    eq_lg: false,
    eq_xl: false,
  };

  if (breakpoint === 'xs') {
    instanceBreakPoints.eq_xs = true;
  }

  if (breakpoint === 'sm') {
    instanceBreakPoints.gt_xs = true;
    instanceBreakPoints.eq_sm = true;
  }
  if (breakpoint === 'md') {
    instanceBreakPoints.gt_sm = true;
    instanceBreakPoints.eq_md = true;
  }
  if (breakpoint === 'lg') {
    instanceBreakPoints.gt_md = true;
    instanceBreakPoints.gt_sm = true;
    instanceBreakPoints.eq_lg = true;
  }
  if (breakpoint === 'xl') {
    instanceBreakPoints.gt_md = true;
    instanceBreakPoints.gt_sm = true;
    instanceBreakPoints.gt_lg = true;
    instanceBreakPoints.eq_xl = true;
  }

  return instanceBreakPoints;
};

// We will return 'sm' at first for mobile first
export const getBreakpoint = (w: number) => {
  return calculateBreakPoints(w);
};
