<template>
  <div>
    <div class="box">
      <div class="box-body">
        <div v-if="mode === 'view'" @mouseover="hover = true" @mouseout="hover = false">
          <span v-show="hover" class="pull-right text-muted">
            <a
              v-if="getContactUrl(contact)"
              :href="getContactUrl(contact)"
              target="_blank"
              rel="noopener"
              class="open-external mr-2"
            >
              <i class="fa fa-external-link text-xs"></i>
            </a>
            <a @click="editContact()"><i class="fa fa-pencil text-xs"></i></a>
          </span>

          <label class="text-muted mb-1 mt-2">Company</label>
          <div v-if="hasProperty(contact, 'company')">{{ contact.company }}</div>
          <div v-else>-</div>
          <div v-show="settings.name">
            <label class="text-muted mb-1 mt-2">Name</label>
            <div v-if="hasProperty(contact, 'name')">{{ contact.name }}</div>
            <div v-else>-</div>
          </div>
          <div v-show="settings.firstName">
            <label class="text-muted mb-1 mt-2">First name</label>
            <div v-if="hasProperty(contact, 'first_name')">{{ contact.first_name }}</div>
            <div v-else>-</div>
          </div>
          <div v-show="settings.lastName">
            <label class="text-muted mb-1 mt-2">Last name</label>
            <div v-if="hasProperty(contact, 'last_name')">{{ contact.last_name }}</div>
            <div v-else>-</div>
          </div>
          <div v-if="settings.hasContactStatus">
            <label class="text-muted mb-1 mt-2">Status</label>
            <div v-if="hasProperty(contact, 'status')">{{ getStatus(contact.status) }}</div>
            <div v-else>-</div>
          </div>
          <label class="text-muted mb-1 mt-2">Email</label>
          <div v-if="hasProperty(contact, 'email')">{{ contact.email }}</div>
          <div v-else>-</div>
          <label class="text-muted mb-1 mt-2">Phone</label>
          <div v-if="hasProperty(contact, 'phone')">{{ contact.phone }}</div>
          <div v-else>-</div>
          <label class="text-muted mb-1 mt-2">Account Manager</label>
          <div v-if="hasProperty(contact, 'owner_id')">{{ getOwner(contact.owner_id).full_name }}</div>
          <div v-else>-</div>
        </div>

        <div v-if="mode === 'create' || mode === 'edit'">
          <form @submit.prevent="updateContact">
            <div class="form-group">
              <label class="text-muted mb-1">Company name</label>
              <input v-model="form.company" type="text" class="form-control mb-2" placeholder="Company name" />
            </div>
            <div v-show="settings.firstName" class="form-group">
              <label class="text-muted mb-1">First name</label>
              <input v-model="form.first_name" type="text" class="form-control mb-2" placeholder="First name" />
            </div>
            <div v-show="settings.lastName" class="form-group">
              <label class="text-muted mb-1">Last name</label>
              <input v-model="form.last_name" type="text" class="form-control mb-2" placeholder="Last name" />
            </div>
            <div v-show="settings.name" class="form-group">
              <label class="text-muted mb-1">Name</label>
              <input v-model="form.name" type="text" class="form-control mb-2" placeholder="Name" />
            </div>
            <div class="form-group">
              <label class="text-muted mb-1">Email</label>
              <input v-model="form.email" type="text" class="form-control mb-2" placeholder="Email" />
            </div>
            <div class="form-group">
              <label class="text-muted mb-1">Phone</label>
              <input v-model="form.phone" type="text" class="form-control mb-2" placeholder="Phone" />
            </div>
            <div v-show="settings.hasContactStatus" class="form-group">
              <label class="text-muted mb-1">Status</label>
              <select v-model="form.status" class="form-control mb-2">
                <option v-for="(v, k) in attributes.contact_statuses" :key="k" :value="k">{{ v }}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="text-muted mb-1">Owner</label>
              <select v-model="form.owner_id" class="form-control mb-2">
                <option :value="null"></option>
                <option v-for="owner in owners" :key="owner" :value="owner.owner_id">
                  {{ owner.full_name }} ({{ owner.email }})
                </option>
              </select>
            </div>
            <button type="submit" class="btn success btn-block" :class="{ loader: saving }" :disabled="saving">
              Update contact
            </button>
            <div class="text-muted mt-2 text-center">
              <a @click="mode = 'view'">Cancel</a>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="text-muted text-center">
      <a class="text-muted text-xs" @click="detach()">Detach contact</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CrmContact',
  emits: ['call-parent-loadData-method'],
  props: {
    plugin: {
      type: Object,
      default: () => ({}),
    },
    ticket: {
      type: Object,
      default: () => ({}),
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
    attributes: {
      type: Object,
      default: () => ({}),
    },
    owners: {
      type: Array,
      default: () => [],
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    hasProperty: {
      type: Function,
      default: () => () => false,
    },
    getStatus: {
      type: Function,
      default: () => () => '',
    },
    getOwner: {
      type: Function,
      default: () => () => '',
    },
  },

  data() {
    return {
      mode: 'view',
      notes: [],
      form: {},
      hover: false,
      saving: false,
    };
  },

  methods: {
    detach() {
      // integrations/crm/attach_contacts
      axios
        .delete(
          '/api/v2/integrations/crm/attach_contacts/0?plugin_id=' +
            this.plugin.id +
            '&contact_id=' +
            this.ticket.contact.id
        )
        .then(() => {
          this.$emit('call-parent-loadData-method');
        });
    },

    editContact() {
      this.mode = 'edit';
      this.form = {
        company: this.contact.company,
        first_name: this.contact.first_name,
        last_name: this.contact.last_name,
        name: this.contact.name,
        email: this.contact.email,
        phone: this.contact.phone,
        owner_id: this.contact.owner_id,
        status: this.contact.status,
      };
    },

    updateContact() {
      if (this.saving) return;
      this.saving = true;
      axios
        .put('/api/v2/integrations/crm/contacts/' + this.ticket.id + '?plugin_id=' + this.plugin.id, this.form)
        .then((res) => {
          this.$emit('call-parent-loadData-method');
          this.mode = 'view';
          this.saving = false;
        })
        .catch(() => {
          this.saving = false;
        });
    },

    getContactUrl(contact) {
      if (this.plugin.type === 'hubspot') {
        return (
          'https://app.hubspot.com/contacts/' +
          this.attributes.account_details.portalId +
          '/contact/' +
          contact.contact_id
        );
      }
    },
  },
};
</script>
