import type { CustomTooltipItem } from '../types/CustomTooltipItem';
import type { TranslationFunction } from '@/types';

export type DataSet = (number | null)[];

export function getChartConfiguration(t: TranslationFunction, showError: boolean, isLoading: boolean) {
  return {
    scales: {
      x: {
        ticks: {
          maxRotation: 90,
          autoSkip: false,
          suggestedMin: 0,
          padding: 8,
          color: '#000',
          font: {
            size: 12,
          },
        },
        suggestedMin: 0,
        min: 0,
        display: true,
        grid: {
          drawTicks: false,
          display: false,
          color: '#E1E3E5',
          borderColor: '#E1E3E5',
          lineWidth: 1,
        },
        title: {
          display: false,
        },
        border: {
          color: '#E1E3E5',
          lineWidth: 1,
        },
      },
      y: {
        ticks: {
          suggestedMin: 0,
          padding: 16,
          color: isLoading || showError ? 'transparent' : '#70767B',
          font: {
            size: 14,
          },
        },
        min: 0,
        suggestedMin: 0,
        grid: {
          drawTicks: false,
        },
        beginAtZero: true,
        title: {
          display: false,
        },
      },
    },
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          mode: 'xy' as const,
          speed: 100,
        },
        pan: {
          enabled: true,
          mode: 'xy' as const,
          speed: 100,
        },
      },
      ...(!isLoading
        ? {
            tooltip: {
              yAlign: 'bottom' as const,
              position: 'average' as const,
              backgroundColor: '#292C2E',
              titleAlign: 'center' as const,
              titleFont: { family: 'Inter', size: 14, style: 'normal', weight: 'bold' } as const,
              titleColor: '#FFF',
              bodyAlign: 'center' as const,
              bodyFont: { family: 'Inter', size: 24, weight: 'bold' },

              callbacks: {
                label: (tooltipContext: CustomTooltipItem) => {
                  return tooltipContext.formattedValue;
                },
                title: (tooltipContext: CustomTooltipItem[]) => {
                  if (tooltipContext[0].dataset.label == t('reports_v2.today')) {
                    return t('reports_v2.today');
                  } else {
                    return t('reports_v2.average');
                  }
                },
              },

              padding: {
                left: 12,
                right: 12,
                top: 6,
                bottom: 6,
              },
              cornerRadius: 8,
              displayColors: false,
            },
          }
        : {
            tooltip: {
              enabled: false,
            },
          }),

      legend: {
        labels: {
          padding: 24,
          pointStyle: 'rectRounded',
          usePointStyle: true,
        },
        display: true,
        position: 'bottom' as const,
        align: 'center' as const,
        onClick: null,
      },
    },
  };
}

export const manipulatedDataSet = (label: string | undefined, data: DataSet, barColor: string) => {
  return {
    label: label,
    data,
    backgroundColor: barColor,
    borderRadius: 4,
    inflateAmount: 'auto' as const,
  };
};

type WeekDayIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export function getPluralDayKey(dayOfWeek: WeekDayIndex) {
  const pluralDayTranslationKey = {
    0: 'sundays',
    1: 'mondays',
    2: 'tuesdays',
    3: 'wednesdays',
    4: 'thursdays',
    5: 'fridays',
    6: 'saturdays',
  };

  return pluralDayTranslationKey[dayOfWeek];
}
