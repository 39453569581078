<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useInboundTranslationStore } from '@/store/pinia/translation/useInboundTranslationStore';

import { useOutboundTranslationStore } from './useOutboundTranslationStore';

const outboundTranslationStore = useOutboundTranslationStore();
const inboundTranslationStore = useInboundTranslationStore();

const props = defineProps<{
  message: string;
}>();

const emit = defineEmits<{
  (e: 'revert', message: string): void;
}>();

const revert = () => {
  emit('revert', outboundTranslationStore.prompt);
};

const show = computed(
  () =>
    inboundTranslationStore.isActive &&
    outboundTranslationStore.translation.length &&
    outboundTranslationStore.translation === props.message
);

const { t } = useI18n();
</script>

<template>
  <button v-if="show" type="button" class="absolute z-10 text-grey-600 hover:text-grey-700" @click="revert">
    {{ t('tickets.revert_to_original') }}
  </button>
</template>
