import eventBus from '@/eventBus';
export default {
  methods: {
    canBeCalledWithVoip() {
      return this.$root.voipReady == true && this.ticket.contact.is_phone == true;
    },

    callContact() {
      eventBus.$emit('voip.call', {
        phone: '+' + this.ticket.contact.phone,
      });
    },
  },
};
