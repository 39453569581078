import Actions from './Actions';
import Getters from './Getters';
import Mutations from './Mutations';

export default {
  namespaced: true,
  state: {
    threads: [],
    currentThread: null,
  },
  mutations: Mutations,
  actions: Actions,
  getters: Getters,
};
