<script type="text/babel">
import VoipStatus from './VoipStatus';
export default {
  data() {
    return {
      forward_to_phone: this.$root.user.voip_device == 'MOBILE',
      outputDevices: [],
      inputDevices: [],

      ringtoneDevice: null,
      outputDevice: null,
      inputDevice: null,
      inputVolume: null,
      testVolume: false,
      isChrome: window.isChrome(),
    };
  },

  components: {
    VoipStatus,
  },

  mounted() {
    if (!this.isChrome || this.$root.mobileDevice) {
      return;
    }

    if (!this.$root.deviceListenerInitialized) {
      this.$root.deviceListenerInitialized = true;
      Twilio.Device.audio.on('deviceChange', () => {
        this.setAvailableDevices();
      });
    }

    this.setAvailableDevices();
    this.setDefaultValues();
    this.setInputDevice();
    this.setOutputDevice();
    this.setRingtoneDevice();
  },

  methods: {
    async setDefaultValues() {
      if (await this.$tStorage.getItem('voip.rt_device')) {
        this.ringtoneDevice = await this.$tStorage.getItem('voip.rt_device');
      } else {
        this.ringtoneDevice = this.outputDevices[0].id;
      }

      if (await this.$tStorage.getItem('voip.op_device')) {
        this.outputDevice = await this.$tStorage.getItem('voip.op_device');
      } else {
        this.outputDevice = this.outputDevices[0].id;
      }

      if (await this.$tStorage.getItem('voip.ip_device')) {
        this.inputDevice = await this.$tStorage.getItem('voip.ip_device');
      } else {
        this.inputDevice = this.inputDevices[0].id;
      }
    },

    setAvailableDevices() {
      this.outputDevices = [];
      this.inputDevices = [];
      Twilio.Device.audio.availableOutputDevices.forEach((device, id) => {
        this.outputDevices.push({
          id: id,
          label: device.label,
        });
      });

      Twilio.Device.audio.availableInputDevices.forEach((device, id) => {
        this.inputDevices.push({
          id: id,
          label: device.label,
        });
      });
    },

    format(time) {
      return window.toHHMMSS(time);
    },

    setDevice() {
      this.forward_to_phone = !this.forward_to_phone;
      if (!this.$root.user.phone && this.forward_to_phone) {
        return;
      }
      let voip_device = this.forward_to_phone ? 'MOBILE' : 'WEB';
      this.$root.user.voip_device = voip_device;
      axios.post('/auth/set-voip-device/' + voip_device);
    },

    setRingtoneDevice() {
      if (!this.ringtoneDevice) return;
      this.$tStorage.setItem('voip.rt_device', this.ringtoneDevice);
    },

    setInputDevice() {
      if (
        !this.inputDevice ||
        (Twilio.Device.audio.inputDevice != null && this.inputDevice == Twilio.Device.audio.inputDevice.deviceId)
      )
        return;

      console.log('set IP device to: ' + this.inputDevice);

      Twilio.Device.audio
        .setInputDevice(this.inputDevice)
        .then(() => {
          this.$tStorage.setItem('voip.ip_device', this.inputDevice);
        })
        .catch(() => {
          this.inputDevice = this.inputDevices[0].id;
        });
    },

    setOutputDevice() {
      var current = null;
      //                Twilio.Device.audio.speakerDevices._activeDevices.forEach((d) => { current = d.deviceId; });

      if (!this.outputDevice) return;

      console.log('set OP device to: ' + this.outputDevice);

      Twilio.Device.audio.speakerDevices
        .set(this.outputDevice)
        .then(() => {
          this.$tStorage.setItem('voip.op_device', this.outputDevice);
        })
        .catch(() => {
          this.outputDevice = this.outputDevices[0].id;
        });
    },

    restoreDevices() {},

    testAudio() {
      Twilio.Device.audio.speakerDevices.test();
    },

    testRingtone() {
      this.$refs.inboundAudio.setSinkId(this.ringtoneDevice);
      this.$refs.inboundAudio.play();
    },

    redirectToProfile() {
      router.push('/profile');
    },
  },
};
</script>

<template>
  <div class="voip-panel-max-h-full select-none p-4 pt-2 text-white">
    <audio ref="inboundAudio">
      <source src="https://media.twiliocdn.com/sdk/js/client/sounds/releases/1.0.0/incoming.mp3" type="audio/mpeg" />
    </audio>

    <div class="mb-2 inline rounded-lg bg-grey-800 p-3 py-2 leading-none text-grey-500">
      {{ $t('voip.voip_status') }}
    </div>

    <voip-status></voip-status>

    <div
      class="
        mb-1
        mt-1
        flex
        cursor-pointer
        items-center
        rounded-lg
        border-2 border-grey-800
        p-2
        px-3
        leading-none
        text-white
        hover:border-grey-600 hover:brightness-90
      "
      @click="setDevice()"
      v-if="$root.user.voip_status === 'ONLINE'"
    >
      <i
        class="material-icons text-md"
        :class="{ 'text-green': $root.user.phone, 'text-orange': !$root.user.phone }"
        v-if="forward_to_phone"
      >
        phone_forwarded
      </i>
      <i class="material-icons text-md text-green" v-if="!forward_to_phone">{{ $t('voip.voip_computer') }}</i>
      <span class="ml-2 leading-none" v-if="forward_to_phone">
        {{ $t('voip.voip_forward_calls_to_your') }}
        <strong>{{ $t('voip.voip_phone') }}</strong>
      </span>
      <span class="ml-2 leading-none" v-else>
        {{ $t('voip.voip_receive_calls_in_your') }}
        <strong>{{ $t('voip.voip_browser') }}</strong>
      </span>
      <span class="ml-auto text-xs leading-none text-grey-500">{{ $t('voip.voip_change') }}</span>
    </div>

    <div v-if="forward_to_phone && !$root.user.phone && $root.user.voip_status === 'ONLINE'">
      <div
        v-if="!$root.user.phone"
        class="mt-2 flex items-center rounded-lg border-2 border-orange p-2 px-3 text-orange"
      >
        <div>
          <strong>Calls are not forwarded yet</strong>
          <br />
          {{ $t('voip.voip_enter_a_phone_number_in_your') }}
          <router-link to="/profile" style="text-decoration: underline">{{ $t('voip.voip_your_profile') }}</router-link>
          {{ $t('voip.voip_to_enable_call_forwarding') }}
        </div>
      </div>
    </div>
    <div v-if="forward_to_phone && $root.user.phone && $root.user.voip_status === 'ONLINE'">
      <div class="mt-2 rounded-lg border-2 border-grey-800 p-2">
        <div class="flex">
          <div>
            {{ $t('voip.voip_inbound_and_outbound_phone_calls_will_be_forwarded_to') }}
            <router-link to="/profile" style="text-decoration: underline">{{ $root.user.phone }}</router-link>
          </div>
        </div>
      </div>
    </div>

    <!--        <div class="alert alert-grey mt-4" v-show="$root.user.voip_status === 'ONLINE' && $root.mobileDevice && $root.user.phone != null">-->
    <!--            <strong>Mobile device detected</strong><br />Outbound calls will be routed via {{ $root.user.phone }}-->
    <!--        </div>-->
    <!--        <div class="alert alert-grey mt-4" v-show="$root.user.voip_status === 'ONLINE' && $root.mobileDevice && $root.user.phone == null">-->
    <!--            <strong>Missing phone number</strong><br />Enter a phone number in your user profile to enable call forwarding.-->
    <!--        </div>-->

    <div v-if="isChrome && !$root.mobileDevice && !forward_to_phone">
      <div class="mt-4 inline rounded-lg bg-grey-800 p-3 py-2 leading-none text-grey-500">
        {{ $t('voip.voip_audio_settings') }}
      </div>
      <div class="flex items-center py-2">
        <div class="flex-1">
          <div style="font-size: 14px" class="flex items-center">{{ $t('voip.voip_ringtone_device') }}</div>
          <div class="mt-1 flex items-center overflow-hidden rounded-lg bg-white">
            <select v-model="ringtoneDevice" class="w-full border-none p-2 text-black" @change="setRingtoneDevice">
              <option v-for="device in outputDevices" :value="device.id" v-text="device.label"></option>
            </select>
            <div class="success ml-2 flex-1 flex-shrink-0 cursor-pointer px-3 py-2 text-sm" @click="testRingtone">
              test
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center py-2">
        <div class="flex-1">
          <div style="font-size: 14px" class="flex items-center">
            {{ $t('voip.voip_the_output_device_during_phone_calls') }}
          </div>
          <div class="mt-1 flex items-center overflow-hidden rounded-lg bg-white">
            <select v-model="outputDevice" class="w-full border-none p-2 text-black" @change="setOutputDevice">
              <option v-for="device in outputDevices" :value="device.id" v-text="device.label"></option>
            </select>
            <div class="success ml-2 flex-1 flex-shrink-0 cursor-pointer px-3 py-2 text-sm" @click="testAudio">
              test
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center pt-2">
        <div class="flex-1">
          <div style="font-size: 14px" class="flex">{{ $t('voip.voip_the_input_device_during_phone_calls') }}</div>
          <div class="mt-1 flex items-center overflow-hidden rounded-lg bg-white">
            <select v-model="inputDevice" class="w-full border-none p-2 text-black" @change="setInputDevice">
              <option v-for="device in inputDevices" :value="device.id" v-text="device.label"></option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
