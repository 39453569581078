<template>
  <span>{{ timeago }}</span>
</template>

<script lang="ts" setup>
import { formatDistance , ru, ar, de, enGB, fr, id, it, nl, ptBR } from 'date-fns';
import { computed } from 'vue';

import { vm } from '@/ApplicationHandler';

const props = defineProps({
  dateTime: {
    type: String,
    required: true,
    validator(value: string) {
      return !isNaN(Date.parse(value));
    },
  },
});

const translations = computed(() => {
  const trans = {
    'ar-AR': ar,
    'de-DE': de,
    'en-GB': enGB,
    'fr-FR': fr,
    'id-ID': id,
    'it-IT': it,
    'nl-NL': nl,
    'pt-BR': ptBR,
    'ru-RU': ru,
  };
  return trans[vm.$root.user.locale_code];
});

const timeago = computed(() => {
  const date = new Date(props.dateTime);
  if (isNaN(date.getTime())) {
    return 'Invalid date';
  }
  return formatDistance(date, new Date(), { addSuffix: true, locale: translations.value });
});
</script>
