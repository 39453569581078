<script type="text/babel">
import { AlertTriangleLinear } from '@trengo/trengo-icons';

import { topUpBalanceForInvoice } from '@/api';
import { currencyHelpers } from '@/util';

import TopUpResultModal from './TopUpResultModal.vue';

export default {
  components: {
    AlertTriangleLinear,
    TopUpResultModal,
  },
  data: function () {
    return {
      amount: 45,
      payment_method: 'ideal',
      loading: false,
      balance: 0,
      minValue: 0,
      returnedFromChargebee: false,
      isResultModalOpen: false,
    };
  },
  props: {
    return_url: {
      type: String,
    },
    min: {
      type: Number,
      default: 0,
    },
    record: {
      type: Object,
      default: null,
    },
    default: {},
  },
  computed: {
    isInvoiceTopUp() {
      return this.record?.pay_by_invoice;
    },
  },
  mounted() {
    this.getBalance();

    if (this.default) {
      this.amount = this.default;
    }

    this.returnedFromChargebee = this.$route.query.state;
    if (this.returnedFromChargebee) {
      this.$router.replace({}); // remove all query params from URL
    }

    this.minValue = this.min;
  },

  methods: {
    getBalance() {
      axios.get('/api/v2/wallet/balance').then((res) => {
        this.balance = res.data.balance;
        if (this.min) {
          this.minValue -= res.data.balance_unformatted;
        }
      });
    },

    topUp() {
      axios
        .post('/client-api/balance/add', {
          amount: this.amount,
          payment_method: this.payment_method,
          return_url: this.return_url,
        })
        .then((res) => {
          $(window).unbind();
          if (
            confirm(
              'We would like to draw your attention to the following: ' +
                '\n\n- SEPA Direct Debit: be aware that it might take a couple ' +
                'of business days before you will see the credits on your balance.\n' +
                '\n-Payments via Ideal: after completing the payment you will see an error page. ' +
                'This is a know issue and can be ignored safely. Please head back to Trengo ' +
                'where you will see the updated balance within a few minutes.'
            )
          ) {
            window.location.href = res.data.redirect_url;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    topupForInvoice() {
      topUpBalanceForInvoice({ amount: this.amount * 100 })
        .then(({ data }) => {
          if (data.balance) {
            this.balance = currencyHelpers.formatCurrency(data.balance * 100);
          }
          this.isResultModalOpen = true;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addBalance() {
      this.loading = true;
      if (this.isInvoiceTopUp) {
        this.topupForInvoice();
      } else {
        this.topUp();
      }
    },

    closeResultModal() {
      this.isResultModalOpen = false;
    },
  },
};
</script>

<template>
  <div>
    <!-- when customer return on this page after completing the flow,
    the webhook might be still pending. This result in a UI race-condition where the customer
    thinks nothing happened. -->
    <div v-if="returnedFromChargebee" class="font-bold">
      Not seeing your recent top up? Try reloading this page after a minute.
      <hr />
    </div>
    <form class="form-horizontal form-bordered" @submit.prevent="addBalance">
      <div class="form-group row">
        <div class="col-md-3">
          <p class="form-control-label">{{ $t('company_profile.subscription_page_current_balance') }}</p>
        </div>
        <div class="col-md-9">
          <p class="form-control-static">&euro; {{ balance }}</p>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3">
          <label for="balanceAmountSelect" class="form-control-label">
            {{ $t('company_profile.subscription_page_amount') }}
          </label>
        </div>
        <div class="col-md-9">
          <select id="balanceAmountSelect" v-model="amount" class="form-control">
            <option v-if="minValue <= 10 && !isInvoiceTopUp" value="10">&euro; 10</option>
            <option v-if="minValue <= 25" value="25">&euro; 25</option>
            <option v-if="minValue <= 45" value="45">&euro; 45</option>
            <option v-if="minValue <= 50" value="50">&euro; 50</option>
            <option v-if="minValue <= 100" value="100">&euro; 100</option>
            <option v-if="minValue <= 200" value="200">&euro; 200</option>
            <option v-if="minValue <= 300" value="300">&euro;300</option>
            <option v-if="minValue <= 350" value="350">&euro; 350</option>
            <option value="500">&euro; 500</option>
            <option value="750">&euro; 750</option>
            <option value="1000">&euro; 1.000</option>
            <template v-if="!isInvoiceTopUp">
              <option value="1250">&euro; 1.250</option>
              <option value="1500">&euro; 1.500</option>
              <option value="1750">&euro; 1.750</option>
              <option value="2000">&euro; 2.000</option>
            </template>
          </select>
        </div>
      </div>
      <button type="submit" class="btn success text-white">
        <i v-show="loading" class="fa fa-spin fa-spinner"></i>
        <span v-show="!loading">{{ $t('company_profile.subscription_page_submit') }}</span>
      </button>
      <t-inline-banner type="warning" class="mt-2">
        <template #icon>
          <alert-triangle-linear />
        </template>

        <p class="t-text-sm m-0 p-0">
          Direct debit top ups can take up to 5 days to become available on your balance. For instant balance top ups we
          recommend using a credit card payment.
        </p>
      </t-inline-banner>
    </form>
    <top-up-result-modal :is-open="isResultModalOpen" @close="closeResultModal" />
  </div>
</template>
