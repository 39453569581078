<template>
  <div class="wa-template-btn">
    <form-title
      v-model="computedValue"
      :badge-text="badgeText"
      :is-toggle-disabled="isToggleDisabled"
      :is-text-disabled="isTitleTextDisabled"
      :heading-text="headingText"
      :should-show-badge="shouldShowBadge"
      :should-show-switch="shouldShowSwitch"
      :subtitle="subtitle"
      :tooltip-text="tooltipText"
    />
    <div v-if="computedValue" class="wa-template-btn__input-wrapper">
      <div v-for="(button, index) in buttonsData" :key="index" class="wa-template-btn__input-inner-wrapper">
        <wa-template-input
          v-model="button.text"
          :is-valid-input="button.isValid"
          :character-count="characterCount(button.text)"
          :show-popup-menu="showPopupMenu && !disabled"
          :error-msg="button.error"
          :auto-focus-input="autoFocusInput"
          :disabled="disabled"
          :label="label"
          :placeholder="placeholder"
          :max-length="maxLength"
          :show-template-end="showTemplateEnd"
          :sub-label="label"
          @handle-input-change="$emit('handle-change-value', $event, button, index)"
          @handle-delete-button="$emit('handle-delete-button', button.id, index)"
        />
      </div>
      <div class="wa-template-btn__create-btn">
        <t-button
          class="banner__buttons-first"
          btn-style="secondary"
          type="button"
          :disabled="disableAddNewBtn || disabled"
          @click="$emit('add-new-button')"
        >
          <plus-linear class="inline" />
          {{ $t('whatsapp.whatsapp_template_quick_reply_add_button') }}
        </t-button>
      </div>
    </div>
    <t-button
      v-if="showActionButton"
      :disabled="disableMigrationButton"
      type="button"
      size="sm"
      @click="$emit('go-to-migration')"
    >
      {{ btnText }}
    </t-button>
  </div>
</template>

<script lang="ts">
import { PlusLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import WaButtonsInfo from './WaButtonsInfo.vue';
import FormTitle from '../FormTitle/FormTitle.vue';
import WaTemplateInput from '../WaTemplateInput';

const MAX_CHARACTERS = 25;

export default defineComponent({
  name: 'WaTemplateButtons',
  emits: ['handle-change-value', 'handle-delete-button', 'add-new-button', 'go-to-migration', 'update:modelValue'],
  components: { WaTemplateInput, PlusLinear, WaButtonsInfo, FormTitle },
  props: {
    autoFocusInput: {
      type: Boolean,
      default: false,
    },
    badgeText: {
      type: String,
      default: '',
    },
    buttonsData: {
      type: Array,
      default: () => [],
    },
    btnText: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableAddNewBtn: {
      type: Boolean,
      default: false,
    },
    disableMigrationButton: {
      type: Boolean,
      default: false,
    },
    isTitleTextDisabled: {
      type: Boolean,
      default: false,
    },
    isToggleDisabled: {
      type: Boolean,
      default: false,
    },
    hasTemplateButtons: {
      type: Boolean,
      default: false,
    },
    headingText: {
      type: String,
      default: '',
    },
    inputId: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    showPopupMenu: {
      type: Boolean,
      default: false,
    },
    showActionButton: {
      type: Boolean,
      default: false,
    },
    showTemplateEnd: {
      type: Boolean,
      default: false,
    },
    shouldShowBadge: {
      type: Boolean,
      default: false,
    },
    shouldShowSwitch: {
      type: Boolean,
      default: false,
    },
    subLabel: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    tooltipText: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    characterCount(text: string): string {
      return `${text.length}/${MAX_CHARACTERS}`;
    },
  },
});
</script>

<style lang="scss" src="./WaTemplateButton.scss" />
