import type { VueWrapper } from '@vue/test-utils';
import type { AxiosResponse } from 'axios';

export const decorateMockedAxiosResponse = <T>(data: T): AxiosResponse<T> => ({
  data,
  status: 200,
  statusText: 'OK',
  headers: {},
  config: {},
});

export const getDataTest = (wrapper: VueWrapper, id: string) => wrapper.find(`[data-test="${id}"]`);

export const isTestEnv = () => process?.env?.NODE_ENV === 'test';
