<script lang="ts" setup>
import { ArrowLeftLinear } from '@trengo/trengo-icons';
import { unionBy, debounce } from 'lodash';
import { onMounted, ref, watch, type Ref, onUnmounted } from 'vue';

import { vm } from '@/ApplicationHandler';
import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

import ContactGroupTable from './ContactGroupTable.vue';
import NoContacts from './NoContacts.vue';
import NoResultOnSearch from './NoResultOnSearch.vue';
import { getContactGroup, getContactsInContactGroup, searchByContactGroup, removeContactInContactGroup } from '../api';
import Sidebar from '../Sidebar/Index.vue';

import type { ContactGroup, Contact } from '../types';

const PAGINATION_ITEMS = 25;
const contactGroup: Ref<ContactGroup | undefined> = ref(undefined);
const searchKeyword = ref('');
const sort = ref<{ type: string, direction: 'asc' | 'desc' }>({ type: 'created', direction: 'desc' });
const contactGroupId = ref(0);
const page = ref(1);
const allDataLoaded = ref(false);
const isLoading = ref(false);
const isLoaded = ref(false);
const isInSearchMode = ref(false);
const contacts: Ref<Contact[]> = ref([]);
const scrollContainer: Ref<HTMLElement | null> = ref(null);

const debouncedOnScroll = debounce(() => {
  fetchMoreData();
}, 300);

onMounted(() => {
  setTimeout(() => {
    if (!contactGroupsFeatureFlagEnabled()) {
      vm.$router.push('/contacts');
    }
  }, 10);
  contactGroupId.value = parseInt(vm.$route.params.id);
  getContacts();
});

onUnmounted(() => {
  scrollContainer.value && scrollContainer.value.removeEventListener('scroll', debouncedOnScroll);
});

watch(searchKeyword, (newVal) => {
  callSearch(newVal);
});

function getContacts() {
  getContactGroup(contactGroupId.value).then((res) => {
    contactGroup.value = res.data;
    getContactsInContactGroup(contactGroupId.value, page.value, sort.value).then((res) => {
      isLoaded.value = true;
      contacts.value = res.data;
      page.value += 1;
      scrollContainer.value && scrollContainer.value.addEventListener('scroll', debouncedOnScroll);
      isLoading.value = true;
    });
  });
}

function isNearBottom() {
  const THRESHOLD = 5;
  return scrollContainer.value && scrollContainer.value.scrollHeight - scrollContainer.value.scrollTop <= scrollContainer.value.clientHeight + THRESHOLD;
}

async function handleSortData({ type, direction }: { type: string, direction: 'asc' | 'desc' }) {
  sort.value = { type, direction };
  page.value = 1;
  const fetchDataAPIBasedOnSearchMode = (group_id: number, page: number) =>
    isInSearchMode.value ? searchByContactGroup(searchKeyword.value, group_id, page, sort.value) : getContactsInContactGroup(group_id, page, sort.value);
  const getData = (await fetchDataAPIBasedOnSearchMode(contactGroupId.value, page.value)).data;
  contacts.value = getData;
}

function handleBackButton() {
  vm.$router.push({ name: 'contact-groups-overview' });
}

function contactGroupsFeatureFlagEnabled() {
  const contactGroupsFeatureFlagEnabled = FEATURE_FLAG_INBOX.TI_CG_FRONTEND;
  return useFeatureFlagStore().isEnabled(contactGroupsFeatureFlagEnabled);
}

function onSearchKeywordChange(keyPhrase: string) {
  searchKeyword.value = keyPhrase;
}

function onClearSearch() {
  searchKeyword.value = '';
  isInSearchMode.value = false;
  page.value = 1;
  getContactsInContactGroup(contactGroupId.value, page.value, sort.value).then((res) => {
    contacts.value = res.data;
    allDataLoaded.value = false;
    page.value += 1;
    scrollContainer.value?.scrollTo(0, 0);
  })
}

function callSearch(keyPhrase: string) {
  const searchDebounce = debounce(handleSearch, 1000);
  searchDebounce(keyPhrase);
}

function handleSearch(keyPhrase: string) {
  if (keyPhrase.length >= 3) {
    isInSearchMode.value = true;
    page.value = 1;
    searchByContactGroup(keyPhrase, contactGroupId.value, page.value, sort.value).then((res) => {
      contacts.value = res.data;
      allDataLoaded.value = false;
      page.value += 1;
      scrollContainer.value?.scrollTo(0, 0);
    });
  } else if (keyPhrase.length === 0) {
    onClearSearch();
  }
}

async function fetchMoreData() {
  if (!isNearBottom() || allDataLoaded.value) {
    return;
  }

  isLoading.value = false;
  const fetchDataAPIBasedOnSearchMode = (group_id: number, page: number) =>
    isInSearchMode.value ? searchByContactGroup(searchKeyword.value, group_id, page, sort.value) : getContactsInContactGroup(group_id, page, sort.value);
  const getData = (await fetchDataAPIBasedOnSearchMode(contactGroupId.value, page.value)).data;

  if (getData.length < PAGINATION_ITEMS) {
    allDataLoaded.value = true;
  } else {
    page.value += 1;
  }

  contacts.value = unionBy(contacts.value, getData, 'id');;
  isLoading.value = true;
}

function handleDeleteItem(item: Contact) {
  removeContactInContactGroup(contactGroupId.value, item.id).then(() => {
    contacts.value = contacts.value.filter((contact) => contact.id !== item.id);
    page.value = 1;
    getContacts();
  });
}
</script>

<template>
  <div v-if="contactGroup" class="h-full w-full flex-1 bg-grey-100 pl-4">
    <sidebar>
      <div class="flex flex-row items-center justify-between">
        <t-icon-button size="lg" @click="handleBackButton">
          <ArrowLeftLinear width="20px" height="20px" />
        </t-icon-button>
        <p class="t-text-h3 mb-0 ml-6 mr-2 max-w-sm">
          <span class="w-full truncate text-ellipsis">
            {{ contactGroup.name }}
          </span>
        </p>
        <t-badge :text="contactGroup.contacts_count || 0" />
      </div>
      <div class="w-80">
        <t-input-search
          v-model="searchKeyword"
          :placeholder="vm.$t('contacts.search_contact')"
          data-test="search-contact"
          @input="onSearchKeywordChange"
          @clear="onClearSearch"
        />
      </div>
    </sidebar>
    <div v-show="isLoaded">
      <div class="max-h-[86vh] w-full flex-1 !overflow-x-hidden rounded-xl pl-4 pr-6">
        <no-result-on-search v-if="contacts.length === 0 && isInSearchMode" :search-key="searchKeyword" />
        <no-contacts v-if="contacts.length === 0 && !isInSearchMode" />
      </div>
      <div
        v-show="contacts.length"
        ref="scrollContainer"
        class="max-h-[86vh] w-full flex-1 !overflow-x-hidden rounded-xl pl-4 pr-6"
      >
        <contact-group-table
          :contacts="contacts"
          :group="contactGroup.name"
          @sort="handleSortData"
          @delete="handleDeleteItem"
        >
          <div v-if="!isLoading" class="flex items-center justify-center">
            <t-spinner />
          </div>
        </contact-group-table>
      </div>
    </div>
  </div>
</template>
