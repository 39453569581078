<template>
  <div class="relative mt-6 h-88">
    <canvas ref="report" height="200" class="h-full"></canvas>

    <error-status
      v-if="showError"
      icon-name="WalletMoveLinear"
      :title="$t('reports_v2.no_data_available')"
      :sub-title="$t('reports_v2.api_failed')"
      classes=" h-[calc(100%-100px)]
      w-[calc(100%-56px)]
      min-[900px]:h-[calc(100%-97px)]
      min-[1100px]:h-[calc(100%-95px)]
      min-[1200px]:h-[calc(100%-93px)]
      min-[1300px]:h-[calc(100%-92px)]
      min-[1400px]:h-[calc(100%-77px)]
      min-[1500px]:h-[calc(100%-77px)]"
    />
  </div>
</template>

<script lang="ts">
import Chart from 'chart.js/auto';
import { map } from 'lodash';
import moment from 'moment';
import { defineComponent } from 'vue';

import {
  ConversationsVolumeAverageEmptyStateMockData,
  ConversationsVolumeTodayEmptyStateMockData,
  WorkloadHourlyAverageConversationsCreatedMockData,
} from '@/components/Reporting/api/mocks';
import ErrorStatus from '@/components/Reporting/Components/V2/ErrorStatus.vue';

import { getChartConfiguration, getPluralDayKey } from '../utilities';

import type { ConversationVolumeBucket } from '@/components/Reporting/api/types';
import type { ChartConfiguration, ChartData } from 'chart.js';

type AverageTicketCreatedData = {
  chartInstance?: ChartConfiguration;
  data?: ChartData;
  conversationsVolumeTodayEmptyState: ConversationVolumeBucket[];
  conversationsVolumeAvgEmptyState: ConversationVolumeBucket[];
  dayOfLast4Weeks?: string;
};

export default defineComponent({
  name: 'AverageTicketsCreatedChart',
  components: { ErrorStatus },

  props: {
    conversationsVolumeToday: {
      type: Array,
      default: () => [] as ConversationVolumeBucket[],
    },
    conversationsVolumeAvg: {
      type: Array,
      default: () => [] as ConversationVolumeBucket[],
    },
    showError: {
      type: Boolean,
      default: false,
    },
  },

  chartInstance: undefined,
  data(): AverageTicketCreatedData {
    return {
      data: undefined,
      conversationsVolumeTodayEmptyState: ConversationsVolumeTodayEmptyStateMockData,
      conversationsVolumeAvgEmptyState: ConversationsVolumeAverageEmptyStateMockData,
      dayOfLast4Weeks: undefined,
    };
  },

  mounted() {
    this.setCurrentDayOfWeek();

    this.setChart();
  },

  computed: {
    chartConfiguration() {
      return getChartConfiguration((key) => this.$t(key), this.showError, false);
    },

    showErrorStatus() {
      return this.showError;
    },

    datasets() {
      return !this.showError
        ? [
            {
              label: this.$t('reports_v2.today'),
              data: map(this.conversationsVolumeToday, (value: ConversationVolumeBucket) => {
                return value.value;
              }),
              backgroundColor: '#249888',
              borderColor: '#20897A',
              hoverBackgroundColor: '#0C5D51',
              hoverBorderColor: '#0B5449',
              borderWidth: 1,
              borderRadius: 4, // Rounded corners
            },
            {
              label: this.$t('reports_v2.average_of_last_days', { day: this.dayOfLast4Weeks }),
              data: map(this.conversationsVolumeAvg, (value: ConversationVolumeBucket) => {
                return value.value;
              }),
              backgroundColor: '#ACB5D1',
              borderColor: '#9096AB',
              hoverBackgroundColor: '#5E678C',
              hoverBorderColor: '#565D7B',
              borderWidth: 1,
              borderRadius: 4, // Rounded corners
            },
          ]
        : [
            {
              label: this.$t('reports_v2.today'),
              data: map(this.conversationsVolumeTodayEmptyState, (value: ConversationVolumeBucket) => {
                return value.value;
              }),
              backgroundColor: '#249888',
              borderColor: 'rgba(0, 0, 0, 0.10)',
              borderWidth: 1,
              borderRadius: 4, // Rounded corners
            },
            {
              label: this.$t('reports_v2.average_of_last_days', { day: this.dayOfLast4Weeks }),
              data: map(this.conversationsVolumeAvgEmptyState, (value: ConversationVolumeBucket) => {
                return value.value;
              }),
              backgroundColor: '#E1E3E5',
              borderColor: 'rgba(0, 0, 0, 0.10)',
              borderWidth: 1,
              borderRadius: 4, // Rounded corners
            },
          ];
    },
  },

  methods: {
    setChart() {
      const chart = this.$refs.report.getContext('2d');

      const today = moment().tz(this.$root.user.timezone).startOf('day');
      const format = 'YYYY-MM-DDTHH:mm:ssZ';
      const hoursRange = [];

      for (let i = 0; i < 24; i++) {
        hoursRange.push(today.format(format));
        today.add(1, 'hours');
      }

      this.data = {
        labels: map(hoursRange, (value: string) => {
          const label = moment(value).tz(this.$root.user.timezone).format('hhA');
          if (label[0] === '0') {
            return label.substring(1);
          }
          return label;
        }),
        datasets: this.datasets,
      };

      const data = this.data;

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: this.chartConfiguration.scales,
        plugins: this.chartConfiguration.plugins,
        categoryPercentage: 0.6,
        barPercentage: 0.8,
      };

      this.chartInstance = new Chart(chart, {
        type: 'bar',
        data,
        options,
      });
    },

    setCurrentDayOfWeek() {
      const currentDate = new Date();
      const currentDayKey = getPluralDayKey(currentDate.getDay());

      this.dayOfLast4Weeks = this.$t(`general.${currentDayKey}`);
    },
  },
});
</script>

<style scoped lang="scss">
.skeleton-background {
  background: linear-gradient(360deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}
</style>
