import { request } from '@/util/request';

import { ENDPOINT } from '../constants';

import type { AxiosPromise } from 'axios';

type TopUpRequest = {
  amount: number;
};

type TopUpResponse = {
  balance: number;
};

export function topUpBalanceForInvoice(topupRequest: TopUpRequest): AxiosPromise<TopUpResponse> {
  return request(ENDPOINT.CHARGEBEE_TOP_UP_INVOICE, 'POST', topupRequest);
}
