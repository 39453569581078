<script setup lang="ts">
import { useOutboundTranslationStore } from '@/components/ReplyForm/TranslationOption/useOutboundTranslationStore';
import { useInboundTranslationStore } from '@/store/pinia/translation/useInboundTranslationStore';

const outboundTranslationStore = useOutboundTranslationStore();
const inboundTranslationStore = useInboundTranslationStore();
</script>

<template>
  <div
    v-if="outboundTranslationStore.status === 'LOADING' || inboundTranslationStore.status === 'LOADING'"
    class="flex h-full items-center gap-1"
    data-test="translation-loader"
  >
    <t-spinner class="me-1" size="1rem" />
  </div>
</template>
