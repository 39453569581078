<template>
  <div class="flex py-2" :class="{ 'mb-3': !last }">
    <div ref="state-confetti" />
    <img :src="computedImage" />
    <div :class="[completedTextColor]" class="t-text-desktop-label-sm ml-2" v-html="text" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PopupItem',
  props: {
    last: {
      default: false,
      type: Boolean,
    },
    text: {
      default: '',
      type: String,
    },
    step: {
      default: '',
      type: String,
    },
  },
  methods: {
    handleIsStepCompleted() {
      return this.getStepByType(this.step)?.completed;
    },
  },
  computed: {
    ...mapGetters({
      getStepByType: 'gamification/getStepByType',
    }),
    computedImage() {
      return this.handleIsStepCompleted()
        ? this.$root.assetsURL + 'img/get_started_page/successful.svg'
        : this.$root.assetsURL + 'img/get_started_page/unsuccessful_tick.svg';
    },
    completedTextColor() {
      return this.handleIsStepCompleted() ? 'is_completed' : 'is_not_completed';
    },
  },
};
</script>

<style src="./PopupItem.scss" lang="scss" scoped />
