import { ref } from 'vue';

export const useModal = () => {
  const isModalOpen = ref(false);

  const toggleModal = () => (isModalOpen.value = !isModalOpen.value);
  const closeModal = () => (isModalOpen.value = false);

  return {
    isModalOpen,
    toggleModal,
    closeModal,
  };
};
