<template>
  <div class="flex w-full items-center rounded-xl border-1 border-grey-300 p-4 sm:justify-between">
    <div class="grid w-full grid-cols-6 items-center justify-between gap-2 sm:grid-cols-12">
      <div class="col-span-1 flex h-48px w-48px items-center justify-center rounded-xl bg-grey-200">
        <comments-linear class="text-grey-800" size="1.5rem" />
      </div>
      <div
        class="
          col-start-2 col-end-6
          ml-2
          flex flex-col
          break-words
          sm:col-start-2 sm:col-end-12 sm:ml-3 sm:mr-0 sm:max-w-sm sm:break-normal
        "
      >
        <div class="t-text-desktop-label-md mb-1 flex flex-row text-grey-800">
          <span>{{ $t('team.add_all_conversations_title') }}</span>
          <span class="ml-1">
            <info-fill
              v-tooltip="{
                popperClass: 'info-tooltip show-mobile z-50',
                content: this.$t('team.can_take_significantly_longer_hint'),
                position: 'top',
              }"
              class="text-grey-800 hover:text-grey-600"
              size="1.2rem"
            />
          </span>
        </div>
        <div class="t-text-desktop-paragraph-sm text-grey-700">
          <span>
            {{ $t('team.include_current_and_new_conversations_description') }}
          </span>
        </div>
      </div>
      <div class="col-span-1 flex items-center justify-end">
        <t-switch v-model="computedValue" data-test="add_all_conversations" />
      </div>
    </div>
  </div>
</template>

<script>
import { CommentsLinear, InfoFill } from '@trengo/trengo-icons';

export default {
  name: 'AddAllConversations',
  emits: ['update:modelValue'],
  components: { InfoFill, CommentsLinear },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style lang="scss" src="./AddAllConversations.scss" />
