export default {
  isDnd(dndSchedule) {
    // Check do not disturb settings
    if (dndSchedule.enabled) {
      return true; // Do not send, do not disturb is enabled
    }
    if (dndSchedule.expires_at && moment(dndSchedule.expires_at).isAfter(moment())) {
      return true; // Do not send, do not disturb expire time is after today
    }
    if (dndSchedule.automatically_enabled) {
      let format = 'hh:mm:ss';
      let time = moment(moment(), format),
        fromTime = moment(dndSchedule.from, format),
        toTime = moment(dndSchedule.to, format);
      if ((fromTime.hour() >= 12 && toTime.hour() <= 12) || toTime.isBefore(fromTime)) {
        toTime.add(1, 'days');
        if (time.hour() <= 12) {
          time.add(1, 'days');
        }
      }
      if (time.isBetween(fromTime, toTime)) {
        return true; // Do not send, time is in schedule
      }
    }
    return false;
  },
};
