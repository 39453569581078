import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-test"]
const _hoisted_2 = {
  class: "t-text-md-emphasize mb-0 mr-2.5 w-5 flex-1 truncate text-ellipsis text-left text-grey-800",
  "data-test": "item-name"
}
const _hoisted_3 = { class: "flex items-center justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_badge = _resolveComponent("t-badge")!
  const _component_list_row_action_menu = _resolveComponent("list-row-action-menu")!

  return (_openBlock(), _createElementBlock("div", {
    class: "flex w-full flex-wrap items-center justify-between",
    "data-test": _ctx.dataTestListRowItemChild
  }, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.isActive)
        ? (_openBlock(), _createBlock(_component_t_badge, {
            key: 0,
            text: _ctx.$t('general.list_label_inactive'),
            "data-test": "item-status"
          }, null, 8 /* PROPS */, ["text"]))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_list_row_action_menu, {
        id: _ctx.id,
        class: "pl-4",
        "data-test-list-row-action-menu": "item-action-menu",
        "data-test-delete-button": "item-delete-button",
        onHandleDeleteItem: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('handle-delete-item')))
      }, null, 8 /* PROPS */, ["id"])
    ])
  ], 8 /* PROPS */, _hoisted_1))
}