<template>
  <div v-if="showModal" class="onboarding-modal flex items-center justify-center" @click.self="clickAwayOnboarding">
    <div
      v-if="step < 6"
      class="box relative flex h-full overflow-y-scroll"
      style="width: 1200px; transition: height 1s"
    >
      <transition name="slide-fade" mode="out-in">
        <step-one v-if="step === 1" />
        <step-one v-else-if="step === 3" validate-email />
      </transition>
    </div>
  </div>
</template>

<script>
import { FEATURE_FLAG_ACTIVATION } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useFeatureFlagStore } from '@/store/pinia';

import StepOne from './OnboardingModalScreens/StepOne';

export default {
  name: 'OnboardingModal',
  components: {
    StepOne,
  },
  data() {
    return {
      show: true,
      isAfterRegister: true,
      showModalForce: false,
      step: 0,
    };
  },
  provide() {
    return {
      close: this.close,
      proceedStep: this.proceedStep,
      previousStep: this.previousStep,
      proceedToWa: this.proceedToWa,
    };
  },
  mounted() {
    this.step = this.$root.onboarding.step;
    this.showStepsBasedOnFeatureFlag();
    eventBus.$on('showOnBoardingModal', () => this.handleShow());
    eventBus.$on('closeOnBoardingModals', () => this.close());
  },
  computed: {
    showModal: {
      get() {
        const tokenExists = this.$route.query.access_token;
        const createdId = this.$route.query.createdId;
        const isOutlook = this.$route.query.type === 'OUTLOOK' && this.$route.query.outlook_id;
        return this.showModalForce || (!tokenExists && !createdId && !isOutlook && this.step < 6 && this.show);
      },
      set(value) {
        this.show = value;
      },
    },
  },
  methods: {
    showStepsBasedOnFeatureFlag() {
      if (useFeatureFlagStore().isEnabled(FEATURE_FLAG_ACTIVATION.TA_ONBOARDING_CHANNEL_SELECTION)) {
        if (this.step === 6) {
          this.$router.push('/onboard-channels');
        }
        if (this.step === 7) {
          this.$router.push('/welcome');
        }
      } else {
        if (this.step === 6) {
          this.$router.push('/welcome');
        }
      }
    },
    handleShow() {
      this.isAfterRegister = false;
      this.showModalForce = true;
    },
    close() {
      this.show = false;
      this.showModalForce = false;
      this.showModal = false;
      this.step = 7;
      if (this.$route.path === '/onboarding') {
        this.$root.onboarding.step = 5;
        this.step = 5;
        this.$router.push('/admin');
      }
    },
    proceedStep(stepNumber) {
      this.step = stepNumber;
      this.$root.onboarding.step = stepNumber;
      axios
        .post('/client-api/onboarding', {
          step: stepNumber,
        })
        .then(() => {
          this.showStepsBasedOnFeatureFlag();
        })
        .catch(() => {
          setTimeout(() => this.proceedStep(stepNumber--), 3000);
        });
    },
    previousStep() {
      axios
        .post('/client-api/onboarding', {
          step: --this.$root.onboarding.step,
        })
        .catch(() => {
          this.$root.onboarding.step++;
        });
    },
    clickAwayOnboarding() {
      if (this.step < 4) {
        return;
      }
      if (!confirm('Are you sure you want to exit the onboarding?')) {
        return;
      }
      this.close();
    },
    proceedToWa() {
      this.show = false;
      this.$nextTick(() => $('#CreateWaBusinessModal').modal());
    },
  },
};
</script>

<style scoped lang="scss">
.onboarding-modal {
  left: 0;
  top: 0;
  position: absolute;
  background: var(--color-white);
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  z-index: 1000;

  .box {
    box-shadow: rgba(0, 0, 0, 0.11) 0 0 50px 0;
    border-radius: 10px;
    border: none;
    overflow-x: hidden;
    max-height: 90%;
    height: auto;

    h2 {
      font-size: 300%;
      font-weight: 600;
    }
    h3 {
      font-size: 200%;
      font-weight: 600;
    }
  }

  .box-fixed-height {
    min-height: 800px;
    @media only screen and (max-width: 1080px) {
      width: 100%;
      height: 100%;
      border-radius: 0px;
    }
  }
}
</style>
<style lang="scss">
.icons-container {
  width: 100%;
  height: 100%;
  &.step-one {
    background: linear-gradient(-45deg, #d7f3ff 0%, rgb(255, 255, 255) 100%);
  }
  &.step-two {
    background: linear-gradient(-45deg, rgba(149, 191, 71, 0.2) 0%, rgb(255, 255, 255) 100%);
  }
  &.step-three {
    background: linear-gradient(-45deg, rgba(227, 229, 240, 1) 0%, rgb(255, 255, 255) 100%);
  }
}
</style>
