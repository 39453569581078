import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const developersRoutes: RouteRecord[] = [
  {
    path: '/admin/developers',
    component: require('../../components/Developers/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
    children: [
      {
        path: '/admin/developers/rest',
        component: require('../../components/Developers/Rest').default,
        meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
      },
      {
        path: '/admin/developers/webhooks',
        component: require('../../components/Developers/Webhooks/Index').default,
        meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
        children: [
          {
            path: '/admin/developers/webhooks/create',
            component: require('../../components/Developers/Webhooks/Edit').default,
            props: { action: 'create' },
            meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
          },
          {
            path: '/admin/developers/webhooks/:id',
            component: require('../../components/Developers/Webhooks/Edit').default,
            props: { action: 'edit' },
            meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
          },
          {
            path: '',
            component: require('../../components/AdminIndexBox').default,
            props: { boxName: 'webhooks' },
            meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
          },
        ],
      },
      {
        path: '/admin/developers/plugins',
        component: require('../../components/Developers/Plugins/Index').default,
        meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
        children: [
          {
            path: '/admin/developers/plugins/create',
            component: require('../../components/Developers/Plugins/Edit').default,
            props: { action: 'create' },
            meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
          },
          {
            path: '/admin/developers/plugins/create/:type',
            component: require('../../components/Developers/Plugins/Edit').default,
            props: { action: 'create' },
            meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
          },
          {
            path: '/admin/developers/plugins/:id',
            component: require('../../components/Developers/Plugins/Edit').default,
            props: { action: 'edit' },
            meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
          },
          {
            path: '',
            component: require('../../components/AdminIndexBox').default,
            props: { boxName: 'plugins' },
            meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
          },
        ],
      },
      {
        path: '/admin/developers/zapier',
        component: require('../../components/Developers/Zapier').default,
        meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
      },
      {
        path: '',
        component: require('../../components/AdminIndexBox').default,
        props: { boxName: 'developers' },
        meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
      },
    ],
  },
];

export default developersRoutes;
