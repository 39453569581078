<template>
  <t-action-menu v-model="computedValue" width="max-content" position="left" fade>
    <template #trigger>
      <more-option-icon-button :class="{ 'success-background': computedValue }" />
    </template>
    <template #content>
      <div class="flex flex-col">
        <slot></slot>
      </div>
    </template>
  </t-action-menu>
</template>
<script>
import MoreOptionIconButton from '../MoreOptionIconButton';

export default {
  components: { MoreOptionIconButton },
  emits: ['open', 'close', 'update:modelValue'],
  props: {
    width: {
      type: [String, Number],
      default: '300px',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleOpen() {
      this.$emit('open');
    },
    handleClose() {
      this.$emit('close');
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style src="./PopoverMenu.scss" scoped lang="scss" />
