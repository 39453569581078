<template>
  <referral-bol v-if="showReferralBol" />
</template>

<script>
import ReferralBol from '@/components/Notifications/Components/ReferralBol';
export default {
  name: 'Stickies',
  components: { ReferralBol },
  computed: {
    showReferralBol() {
      if (this.$root.companyProfile.subscription.plan_id === null) {
        return false;
      }
      if (moment().diff(this.$root.companyProfile.profile.created_at, 'months') < 3) {
        return false;
      }

      if (this.$root.companyProfile.profile.invoice_country !== 'NL') {
        return false;
      }

      return !this.$store.getters['userConfig/get']('referral_button_bol_pressed');
    },
  },
};
</script>
