<script type="text/babel">
export default {
  props: ['color', 'title', 'logo', 'name'],

  mounted() {},
};
</script>

<template>
  <div class="hc_browser_container">
    <div class="">
      <div class="hc_browser_body">
        <div class="hc_preview_top">
          <div class="hc_preview_container display-flex v-align">
            <div class="hc_preview_logo">
              <img :src="logo" v-if="logo != null" style="height: 40px" />
              <span class="hc_preview_empty_logo" v-if="logo == null">{{ name }}</span>
            </div>
            <div class="hc_preview_leave">
              <a>
                <i class="fa fa-external-link"></i>
                {{ $t('help_center.go_to') }} {{ name }}
              </a>
            </div>
          </div>
        </div>
        <div class="hc_preview_header">
          <div class="hc_preview_container text-center">
            <div class="hc_preview_title" v-text="title"></div>
            <div class="hc_preview_search">
              <div class="form-group row my-0">
                <div class="col-lg-8 col-sm-12 offset-lg-2">
                  <i class="search-icon material-icons">search</i>
                  <input
                    type="email"
                    class="form-control form-control-lg search hc_preview_search border-0"
                    :placeholder="$t('help_center.search_answers')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hc_preview_content">
          <div class="hc_preview_container">
            <div class="row">
              <div class="col-md-7">
                <div class="group pb-2 pt-4">
                  <div class="item mb-4">
                    <div class="row">
                      <div class="col-lg-2 hidden-lg-down">
                        <div class="d-flex container h-100">
                          <div class="row justify-content-center align-self-center m-auto" :style="{ color: color }">
                            <i class="material-icons md-48">motorcycle</i>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                        <h2 class="title">
                          <a href="#" :style="{ color: color }">{{ $t('help_center.your_first_article') }}</a>
                        </h2>
                        <div class="subtitle">{{ $t('help_center.a_short_description_for_this_article') }}</div>
                        <div class="total-items text-muted">
                          <small>
                            <i class="material-icons md-12">filter_none</i>
                            {{ $t('help_center.25_articles_in_this_section') }}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="group pb-2 pt-4">
                  <div class="row">
                    <div class="item mb-4">
                      <div class="col-lg-2 hidden-lg-down">
                        <div class="d-flex container h-100">
                          <div class="row justify-content-center align-self-center m-auto" :style="{ color: color }">
                            <i class="material-icons md-48">alarm</i>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                        <h2 class="title">
                          <a href="#" :style="{ color: color }">{{ $t('help_center.another_cool_article') }}</a>
                        </h2>
                        <div class="subtitle">{{ $t('help_center.a_short_description_for_this_article') }}</div>
                        <div class="total-items text-muted">
                          <small>
                            <i class="material-icons md-12">filter_none</i>
                            {{ $t('help_center.25_articles_in_this_section') }}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="offset-md-1 col-md-4">
                <div class="item mb-5">
                  <h2 class="title">{{ $t('help_center.in_the_spotlight') }}</h2>
                  <ul class="list-item">
                    <li class="text-truncate py-2">
                      <a href="#" class="text-color">
                        <i class="material-icons md-18" :style="{ color: color }">filter_none</i>
                        {{ $t('help_center.a_featured_article') }}
                      </a>
                    </li>
                    <li class="text-truncate py-2">
                      <a href="#" class="text-color">
                        <i class="material-icons md-18" :style="{ color: color }">filter_none</i>
                        {{ $t('help_center.another_featured_article') }}
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="item mb-5">
                  <h2 class="title">{{ $t('help_center.popular') }}</h2>
                  <ul class="list-item">
                    <li class="text-truncate py-2">
                      <a href="#" class="text-color">
                        <i class="material-icons md-18" :style="{ color: color }">filter_none</i>
                        {{ $t('help_center.a_popular_article') }}
                      </a>
                    </li>
                    <li class="text-truncate py-2">
                      <a href="#" class="text-color">
                        <i class="material-icons md-18" :style="{ color: color }">filter_none</i>
                        {{ $t('help_center.another_popular_article') }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hc_preview_footer">
          <div class="hc_preview_container display-flex v-align">
            <div class="pull-left">
              <img :src="logo" v-if="logo != null" style="height: 40px" />
              <span class="hc_preview_empty_logo" v-if="logo == null">{{ name }}</span>
            </div>
            <div class="pull-right hc_powered">
              {{ $t('help_center.we_run_on_trengo') }}
              <img :src="`${$root.assetsURL}build/img/trengo.png`" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
