import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex flex-shrink-0 items-center justify-start" }
const _hoisted_2 = {
  key: 0,
  class: "mr-2"
}
const _hoisted_3 = { class: "t-text-h3 text-grey-900" }
const _hoisted_4 = { class: "flex w-full justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex w-full flex-col items-center justify-center pb-4 pt-6 backdrop-blur-lg", [_ctx.fixedWidth, _ctx.$slots.bottom ? 'h-32' : 'h-20']])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex w-full items-center", [_ctx.topRowClass, _ctx.maxWidth && 'px-6']]),
      style: _normalizeStyle({ maxWidth: _ctx.maxWidth, width: _ctx.width })
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.$slots.left)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "left")
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "right")
      ])
    ], 6 /* CLASS, STYLE */),
    (_ctx.$slots.bottom)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["mt-auto flex w-full items-center", _ctx.maxWidth && 'px-6']),
          style: _normalizeStyle({ maxWidth: _ctx.maxWidth, width: _ctx.width })
        }, [
          _renderSlot(_ctx.$slots, "bottom")
        ], 6 /* CLASS, STYLE */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}