<template>
  <header
    class="sticky top-0 z-50 flex w-full items-center bg-white bg-opacity-50 px-6 backdrop-blur-md backdrop-filter"
  >
    <t-logo class="py-6" />
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LogoHeader',
});
</script>
