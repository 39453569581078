<template>
  <ul v-if="showNav" class="nav text-left">
    <li v-if="title" class="nav-item m-b-xs p">
      <h6>{{ $t('contacts.custom_fields') }}</h6>
    </li>
    <li v-for="field in fields" class="nav-item m-b-xs">
      <div class="mb-1 text-sm font-semibold text-grey-700">{{ field.title }}</div>
      <custom-field-item :field="field" @save="save(field)" />
    </li>
  </ul>
</template>

<script>
import { cloneDeep, find, intersectionBy, remove } from 'lodash';
import { mapState } from 'vuex';

import { storeContactCustomField as apiStoreContactCustomField } from './Api';
import CustomFieldItem from '../TicketSidebar/CustomFieldItem';

export default {
  props: {
    contact: {},
    title: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      fields: [],
    };
  },

  mounted() {
    this.loadList();
  },
  created() {
    this.fields = cloneDeep(this.storedFields);
    remove(this.fields, (field) => {
      return field.channels.length && !intersectionBy(field.channels, this.contact.channels, 'id').length;
    });
  },
  components: {
    CustomFieldItem,
  },
  watch: {
    customFieldsLoaded(loaded) {
      if (loaded) {
        this.loadList();
      }
    },
  },
  computed: {
    ...mapState({
      storedFields: (state) => state.customFields.customContactFields,
      customFieldsLoading: (state) => state.customFields.loading,
      customFieldsLoaded: (state) => state.customFields.loaded,
    }),
    showNav() {
      return (
        this.contact &&
        this.contact.formatted_custom_field_data != null &&
        Object.keys(this.contact.formatted_custom_field_data).length > 0
      );
    },
  },

  methods: {
    save(fieldToSave) {
      const field = find(this.fields, function (field) {
        return field.id === fieldToSave.id;
      });
      const fieldValue = field.value ? field.value : null;
      apiStoreContactCustomField(this.contact.id, field.id, fieldValue);
    },
    loadList() {
      this.fields = this.fields.map((field) => {
        return {
          ...field,
          value: this.contact?.custom_field_data ? this.contact.custom_field_data[field.title] : null,
        };
      });
    },
  },
};
</script>
