import { GOOGLE, TRENGO, OUTLOOK, SANDBOX, TRENGO_BSP } from '@/Configs/Constants/ChannelLandingPageTypes';

export type EmailProvider = 'GOOGLE' | 'OUTLOOK' | 'TRENGO';

export type BSPProvider = 'sandbox' | 'trengo_bsp';

export type AllowedSegmentChannelTypes = 'EMAIL' | 'WA_BUSINESS' | 'FACEBOOK' | 'INSTAGRAM' | 'CHAT' | 'skipped';

export class SegmentProviderTypes {
  static gmail: 'GOOGLE' = GOOGLE;
  static outlook: 'OUTLOOK' = OUTLOOK;
  static another_provider: 'TRENGO' = TRENGO;
  static facebook = undefined;
  static instagram = undefined;
  static livechat = undefined;
  static whatsapp_sandbox: 'sandbox' = SANDBOX;
  static whatsapp_number: 'trengo_bsp' = TRENGO_BSP;
  static get(channel_type: string): keyof typeof SegmentProviderTypes {
    return this[channel_type as keyof SegmentProviderTypes];
  }
}
