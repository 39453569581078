<template>
  <div>
    <div v-if="showButton" class="mt-3">
      <atomic-button @click="() => handleShowButton(false)" btn-style="secondary" size="sm">
        {{ $t('email_settings.email_instruction_to_colleague') }}
      </atomic-button>
    </div>
    <div v-else class="mt-8 rounded-xl bg-grey-300">
      <div class="align-center flex flex-row items-center">
        <span class="mr-3">
          <img class="h-20px w-20px" :src="`${$root.assetsURL}img/send.svg`" alt="info" />
        </span>
        <span class="text-base font-bold">{{ $t('email_settings.email_instructions_to_colleague') }}</span>
      </div>
      <div class="ml-8">
        <span>
          {{
            $t('email_settings.we_will_include_all_dns_records_in_a_table_for_your_colleague_or_specialist_to_insert')
          }}
        </span>
        <div class="mt-3 w-3/5">
          <atomic-input size="lg" v-model="email" :placeholder="$t('email_settings.email_address')" />
        </div>
        <div class="mt-3 flex flex-row items-start justify-start">
          <div class="mr-2">
            <atomic-button size="md" @click="() => handleShowButton(true)">
              {{ textCloseOrCancel }}
            </atomic-button>
            <atomic-button @click="handleSendEmail" size="md" :disabled="emailIsEmpty" btn-style="secondary">
              {{ loading ? 'Sending...' : 'Send' }}
            </atomic-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validateEmail } from '@/util/helpers';
import { sendSenderDomainEmail } from '@/components/Channels/EmailSettings/Pages/ChannelSettings/api';

export default {
  name: 'EmailInstruction',
  data() {
    return {
      showButton: true,
      email: '',
      loading: false,
      sent: false, // this flag is used to show the close or cancel text
    };
  },
  props: {
    id: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  computed: {
    textCloseOrCancel() {
      return this.sent ? this.$t('email_settings.close') : this.$t('email_settings.cancel');
    },
    emailIsEmpty() {
      return validateEmail(this.email) === false;
    },
  },
  methods: {
    handleSendEmail() {
      this.loading = true;
      sendSenderDomainEmail({ email_address: this.email }, this.id)
        .then(() => {
          this.flashSuccess(this.$t('email_settings.the_instruction_email_sent_successfully'));
          this.sent = true;
        })
        .catch(() => {
          this.sent = false;
          this.flashError(this.$t('email_settings.the_instruction_email_could_not_be_sent'));
        })
        .finally(() => {
          this.email = '';
          this.loading = false;
        });
    },
    handleShowButton(value) {
      this.showButton = value;
    },
  },
};
</script>
