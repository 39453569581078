import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sticky top-0 z-50 flex w-full items-center bg-white bg-opacity-50 px-6 backdrop-blur-md backdrop-filter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_logo = _resolveComponent("t-logo")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_t_logo, { class: "py-6" })
  ]))
}