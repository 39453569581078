<template>
  <div class="broadcast-details-item" :class="{ 'broadcast-details-item-center': center }">
    <div class="broadcast-details-item-title t-text-desktop-label-sm">
      <slot name="title">
        {{ title }}
      </slot>
    </div>

    <div class="broadcast-details-item-content t-text-desktop-paragraph-sm">
      <slot v-if="!loading" />
      <div v-else class="content-loader"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BroadcastDetailsItem',
  props: {
    title: {
      type: String,
      default: '',
    },
    center: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss" src="./BroadcastDetailsItem.scss" />
