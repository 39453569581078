<script type="text/babel">
import eventBus from '@/eventBus';

export default {
  name: 'History',
  data() {
    return {
      items: [],
      loading: false,
      twilioNumber: window.MIX_VOIP_SANDBOX_PHONE || '31858088215',
    };
  },

  mounted() {
    this.loadItems();
  },

  methods: {
    loadItems() {
      this.loading = true;
      $.getJSON('/client-api/voip/history').then((items) => {
        this.items = items;
        this.loading = false;
      });
    },

    call(phone) {
      eventBus.$emit('voip.call', {
        phone: '+' + phone,
      });
    },
  },
};
</script>

<template>
  <div class="scroll-on-hover scroll-dark-grey p-4">
    <div class="mb-4 inline rounded-lg bg-grey-800 p-3 py-2 leading-none text-grey-500">History</div>
    <div class="select-none">
      <div v-for="item in items" class="mb-2 flex items-center border-b border-grey-800 pb-2 text-white">
        <div v-show="!$root.isMobileDevice" class="mr-2">
          <span
            class="
              circle
              inline-block-40px
              avatar
              flex-shrink-0
              cursor-pointer
              items-center
              justify-center
              bg-green
              text-white
              hover:brightness-90
            "
            style="font-size: 14px; width: 35px; height: 35px; line-height: 35px; display: inline-flex"
            @click="call(item.contact.phone)"
          >
            <i class="material-icons text-md">ring_volume</i>
          </span>
        </div>
        <div class="min-w-0 flex-1 leading-none">
          <div v-if="item.contact.full_name" class="text-ellipsis">{{ item.contact.full_name }}</div>
          <div v-else class="select-default text-ellipsis">{{ item.contact.formatted_phone }}</div>
          <div class="flex pt-1 text-sm leading-none text-grey-500">
            <span class="flex items-center text-ellipsis">
              <i
                class="material-icons text-success text-sm"
                v-text="item.type === 'OUTBOUND' ? 'call_made' : 'call_received'"
              ></i>
              {{ item.channel.title }}
            </span>
            <div class="ml-auto flex flex-shrink-0 items-center">
              {{ item.formatted_date }}
              <i class="material-icons ml-1 text-sm">alarm</i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
