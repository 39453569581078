<template>
  <div>
    <t-inline-banner type="upgrade">
      <template #icon><arrow-top-circle-linear size="1.25rem" /></template>
      <div class="mr-4 flex flex-col">
        <div class="work-load-wrapper">
          <div class="t-text-desktop-label-xs sm:t-text-desktop-label-sm">
            {{ $t('team.balance_workload_effectively') }}
          </div>
        </div>
        <div class="sm:t-text-desktop-paragraph-xs t-text-desktop-paragraph-sm text-grey-600">
          {{ upgradeText }}
          <a href="https://help.trengo.com/en/articles/63517-select-or-update-your-trengo-plan" class="underline">
            {{ $tc('general.learn_more') }}
          </a>
        </div>
      </div>
      <template #action>
        <t-button
          class="twenty-four-hr-banner__btn"
          btn-style="secondary"
          size="sm"
          target="_blank"
          @click="handleUpgrade()"
        >
          <div class="flex flex-row items-center">
            <span class="t-text-sm-emphasize mr-1">{{ $t('entitlement.upgrade_plan_cta') }}</span>
            <arrow-right-linear class="text-grey-700" :size="20" />
          </div>
        </t-button>
        <t-button-text
          btn-style="secondary"
          class="ml-2 mt-2 sm:mt-0"
          data-test="load-balancing-enable-disable"
          size="md"
          @click="handleRemoveWorkload()"
        >
          {{ $t('team.no_thanks') }}
        </t-button-text>
      </template>
    </t-inline-banner>
  </div>
</template>

<script lang="ts">
import { ArrowTopCircleLinear, ArrowRightLinear } from '@trengo/trengo-icons';

import { PRICING_MODEL } from '@/Configs/Constants';
export default {
  name: 'UpgradePlan',
  emits: ['show', 'cancel'],
  components: { ArrowTopCircleLinear, ArrowRightLinear },
  props: {
    isEnabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleShowWorkLoadBalancing() {
      this.$emit('show');
    },
    handleRemoveWorkload() {
      this.$emit('cancel');
    },
    handleUpgrade() {
      this.$router.push({ name: 'subscription' });
    },
  },
  computed: {
    workLoadButtonStatusTitle() {
      return this.isEnabled ? 'Deactivate' : 'Setup';
    },
    upgradeText() {
      let plan = 'Pro';
      if (this.$root.companyProfile?.pricing_model === PRICING_MODEL.SEAT_BASED) {
        plan = 'Enterprise';
      }
      return this.$t('entitlement.upgrade_to_plan', { plan: plan });
    },
  },
};
</script>

<style lang="scss" src="./UpgradePlan.scss" scoped />
