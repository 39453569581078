import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "mt-2" }
const _hoisted_2 = { class: "my-4 text-sm font-semibold text-black" }
const _hoisted_3 = { class: "mr-2 inline-block" }
const _hoisted_4 = {
  key: 2,
  class: "mt-8 rounded-xl bg-grey-100 p-4 pb-1"
}
const _hoisted_5 = { class: "mb-1" }
const _hoisted_6 = { class: "my-4 mb-0 font-semibold text-black" }
const _hoisted_7 = { class: "mr-2 inline-block text-sm" }
const _hoisted_8 = {
  key: 3,
  class: "my-6 mb-1 mt-8 border-b border-grey-300 pt-2"
}
const _hoisted_9 = { class: "text-grey-900" }
const _hoisted_10 = { class: "text-grey-700" }
const _hoisted_11 = {
  key: 0,
  class: "flex flex-col items-center justify-between rounded-xl bg-grey-100 px-3 py-2 sm:items-start md:flex-row"
}
const _hoisted_12 = { class: "flex w-3/5 items-center" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_dropdown = _resolveComponent("t-dropdown")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_setting_item = _resolveComponent("setting-item")!
  const _component_atomic_input = _resolveComponent("atomic-input")!
  const _component_froala = _resolveComponent("froala")!
  const _component_t_multi_select_tag = _resolveComponent("t-multi-select-tag")!
  const _component_t_switch = _resolveComponent("t-switch")!
  const _component_arrow_top_circle_linear = _resolveComponent("arrow-top-circle-linear")!
  const _component_arrow_right_linear = _resolveComponent("arrow-right-linear")!
  const _component_t_button = _resolveComponent("t-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_setting_item, {
      "hide-form-text-on-mobile": true,
      "border-on-mobile": false,
      "show-border": false,
      "custom-class": { container: 'mb-3 mt-0 pt-0 sm:pt-3 sm:pb-4 pb-0' }
    }, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('email_settings.styling')), 1 /* TEXT */)
      ]),
      formText: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('email_settings.customize_how_your_emails_look_to_your_customers')), 1 /* TEXT */)
      ]),
      moreSettings: _withCtx(() => [
        _createVNode(_component_t_dropdown, {
          modelValue: _ctx.computedEmailTheme,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedEmailTheme) = $event)),
          "drop-down-class": "text-base font-medium p-4",
          items: _ctx.computedEmailItems,
          "value-index": "id",
          "text-index": "title"
        }, null, 8 /* PROPS */, ["modelValue", "items"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_router_link, {
            to: "/admin/email_themes/create",
            class: "font-semibold text-grey-600"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('email_settings.channels_email_create_new_style')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      _: 1 /* STABLE */
    }),
    (_ctx.senderNameCanBePersonalised)
      ? (_openBlock(), _createBlock(_component_setting_item, {
          key: 0,
          "hide-form-text-on-mobile": true,
          "show-border": false,
          "custom-class": { container: 'mb-4 mt-0 pt-5 sm:pt-0 sm:pb-3 pb-0' }
        }, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.senderNameTitle), 1 /* TEXT */)
          ]),
          formText: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('email_settings.sender_name_subtitle')), 1 /* TEXT */)
          ]),
          moreSettings: _withCtx(() => [
            _createVNode(_component_atomic_input, {
              modelValue: _ctx.computedRecord.emailChannel.sender_name_personal,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedRecord.emailChannel.sender_name_personal) = $event)),
              size: "lg"
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    (_ctx.hasReplyTo)
      ? (_openBlock(), _createBlock(_component_setting_item, {
          key: 1,
          "hide-form-text-on-mobile": true,
          "show-border": true,
          "custom-class": { container: 'mb-4 mt-0 pt-5 sm:pt-0 sm:pb-3 pb-0' }
        }, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('email_settings.reply_to_mail')), 1 /* TEXT */)
          ]),
          formText: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('email_settings.optional_add_a_custom_reply_to_email_address')), 1 /* TEXT */)
          ]),
          moreSettings: _withCtx(() => [
            _createVNode(_component_atomic_input, {
              modelValue: _ctx.computedRecord.emailChannel.sender_email,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedRecord.emailChannel.sender_email) = $event)),
              size: "lg"
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_setting_item, {
      "border-on-mobile": false,
      "show-border": true,
      "custom-class": { container: 'mb-6 mt-0 pt-0 sm:pt-0 sm:pb-1 pb-0' }
    }, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('email_settings.signature')), 1 /* TEXT */)
      ]),
      moreSettings: _withCtx(() => [
        _createVNode(_component_froala, {
          ref: "emailSignatureFroalaEditor",
          modelValue: _ctx.computedRecord.emailChannel.signature,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedRecord.emailChannel.signature) = $event)),
          class: "rounded-lg border-1 border-grey-300 bg-white p-4 py-3 pb-12 text-base font-medium text-grey-800",
          "image-prefix": "signature",
          "toolbar-position": "absolute",
          image: true,
          border: false,
          "custom-froala-options": {
            initOnClick: true,
            maxHeight: 150,
            placeholderText: _ctx.$t('email_settings.type_something'),
          },
          "save-selection-on-blur": true
        }, null, 8 /* PROPS */, ["modelValue", "custom-froala-options"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('email_settings.available_tags')), 1 /* TEXT */),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emailSignatureTags, (tag) => {
            return (_openBlock(), _createBlock(_component_t_multi_select_tag, {
              key: tag.id,
              "has-avatar": false,
              "is-closable": false,
              item: tag,
              class: "cursor-pointer",
              size: "sm",
              onClick: ($event: any) => (_ctx.insertIntoEmailSignature(tag.value))
            }, null, 8 /* PROPS */, ["item", "onClick"]))
          }), 128 /* KEYED_FRAGMENT */)),
          (_ctx.userStore.hasPermission(_ctx.PERMISSION.SETTINGS__CUSTOM_FIELDS__MANAGE))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: "/admin/custom_fields/create/USER",
                class: "text-sm font-semibold text-grey-800"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('email_settings.add_custom_field')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_setting_item, {
      "show-border": !_ctx.computedRecord.emailChannel.auto_reply_enabled,
      "custom-class": { container: _ctx.autoReplyEnabledContainerStyle },
      onClick: _cache[6] || (_cache[6] = () => _ctx.handleToggleSwitch('emailChannel.auto_reply_enabled'))
    }, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('email_settings.auto_replies')), 1 /* TEXT */)
      ]),
      formText: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('email_settings.enable_auto_replies_subtitle')), 1 /* TEXT */)
      ]),
      toggle: _withCtx(() => [
        _createVNode(_component_t_switch, {
          modelValue: _ctx.computedRecord.emailChannel.auto_reply_enabled,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedRecord.emailChannel.auto_reply_enabled) = $event)),
          onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show-border", "custom-class"]),
    (_ctx.computedRecord.emailChannel.auto_reply_enabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.computedRecord.emailChannel.auto_reply_enabled)
            ? (_openBlock(), _createBlock(_component_setting_item, {
                key: 0,
                "hide-form-text-on-mobile": true,
                "show-border": false,
                "custom-class": { container: 'mb-4 mt-0 pt-5 sm:pt-0 sm:pb-3 pb-0' }
              }, {
                title: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('email_settings.auto_reply_sender')), 1 /* TEXT */)
                ]),
                formText: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('email_settings.the_sender_company_name_visible_for_the_receivers_of_this_auto_reply')), 1 /* TEXT */)
                ]),
                moreSettings: _withCtx(() => [
                  _createVNode(_component_atomic_input, {
                    modelValue: _ctx.computedRecord.emailChannel.sender_name,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.computedRecord.emailChannel.sender_name) = $event)),
                    size: "lg"
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          (_ctx.computedRecord.emailChannel.auto_reply_enabled)
            ? (_openBlock(), _createBlock(_component_setting_item, {
                key: 1,
                "show-border": false,
                "custom-class": { container: 'mb-0 mt-0 pt-0 sm:pt-0 pb-0' }
              }, {
                title: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('email_settings.auto_reply_mail')), 1 /* TEXT */)
                ]),
                moreSettings: _withCtx(() => [
                  _createVNode(_component_froala, {
                    ref: "emailAutoReplyFroalaEditor",
                    modelValue: _ctx.computedRecord.emailChannel.auto_reply_body,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.computedRecord.emailChannel.auto_reply_body) = $event)),
                    class: "rounded-lg border-1 border-grey-300 bg-white p-4 py-3 pb-12 text-base font-medium text-grey-800",
                    border: false,
                    "toolbar-position": "absolute",
                    "custom-froala-options": {
              initOnClick: true,
              maxHeight: 150,
              placeholderText: _ctx.$t('email_settings.type_something'),
            },
                    "save-selection-on-blur": true
                  }, null, 8 /* PROPS */, ["modelValue", "custom-froala-options"]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('email_settings.available_tags')), 1 /* TEXT */),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.autoReplyTags, (tag) => {
                      return (_openBlock(), _createBlock(_component_t_multi_select_tag, {
                        key: tag.id,
                        "has-avatar": false,
                        "is-closable": false,
                        item: tag,
                        class: "cursor-pointer",
                        size: "sm",
                        onClick: ($event: any) => (_ctx.insertIntoAutoReply(tag.value))
                      }, null, 8 /* PROPS */, ["item", "onClick"]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.computedRecord.emailChannel.auto_reply_enabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_setting_item, {
      "show-border": false,
      "custom-class": {
        container: `mb-4 mt-6 pt-5 sm:pt-0 sm:pb-3 pb-0 cursor-pointer `,
      },
      onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.isEntitledToRemoveTrengoBranding && _ctx.handleToggleSwitch('emailChannel.branding')))
    }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('email_settings.trengo_branding')), 1 /* TEXT */)
      ]),
      formText: _withCtx(() => [
        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('email_settings.include_provided_by_trengo_below_every_email')), 1 /* TEXT */)
      ]),
      toggle: _withCtx(() => [
        _createVNode(_component_t_switch, {
          modelValue: _ctx.computedRecord.emailChannel.branding,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.computedRecord.emailChannel.branding) = $event)),
          disabled: !_ctx.isEntitledToRemoveTrengoBranding,
          onClick: _cache[10] || (_cache[10] = _withModifiers(() => {}, ["stop"]))
        }, null, 8 /* PROPS */, ["modelValue", "disabled"])
      ]),
      moreSettings: _withCtx(() => [
        (!_ctx.isEntitledToRemoveTrengoBranding)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_arrow_top_circle_linear, {
                  size: "1.25rem",
                  class: "text-leaf-400"
                }),
                _createElementVNode("span", {
                  class: "ml-2 text-sm font-medium",
                  innerHTML: _ctx.brandingText
                }, null, 8 /* PROPS */, _hoisted_13)
              ]),
              (_ctx.userStore.hasPermission(_ctx.PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE))
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createVNode(_component_t_button, {
                      "btn-style": "secondary",
                      size: "sm",
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$router.push(_ctx.SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('email_settings.upgrade_plan')) + " ", 1 /* TEXT */),
                        _createVNode(_component_arrow_right_linear, {
                          size: "1.125rem",
                          class: "ml-1 inline"
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]))
                : _createCommentVNode("v-if", true)
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ]))
}