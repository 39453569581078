export function storeTicketCustomField(ticketId, customFieldId, customFieldValue) {
  return axios.post('/api/v2/tickets/' + ticketId + '/custom_fields', {
    custom_field_id: customFieldId,
    value: customFieldValue,
  });
}

export function storeProfileCustomField(profileId, customFieldId, customFieldValue) {
  return axios.post('/api/v2/profiles/' + profileId + '/custom_fields', {
    custom_field_id: customFieldId,
    value: customFieldValue,
  });
}

export function storeContactCustomField(contactId, customFieldId, customFieldValue) {
  return axios.post('/api/v2/contacts/' + contactId + '/custom_fields', {
    custom_field_id: customFieldId,
    value: customFieldValue,
  });
}

export async function updateContactFullName(id, name) {
  return await axios.put(`/api/v2/contacts/${id}`, { name });
}

export async function updateContactEmail(id, email) {
  return await axios.put(`/api/v2/contacts/${id}`, { email });
}

export async function getProfiles(term) {
  return await axios.get('/api/v2/profiles', { params: { term } });
}

export async function createNewProfile(name) {
  return await axios.post('/api/v2/profiles', { name });
}

export async function assignProfile(profileId, contactId, channelType) {
  return await axios.post(`/api/v2/profiles/${profileId}/contacts`, {
    contact_id: contactId,
    type: channelType,
  });
}

export async function assignWatcher(id) {
  return await axios.post(`/api/v2/tickets/${id}/watchers`);
}

export async function unassignWatcher(id) {
  return await axios.delete(`/api/v2/tickets/${id}/watchers/0`);
}
