<script type="text/babel">
export default {
  props: ['ticket', 'message', 'publicProvider'],
  data() {
    return {};
  },
  computed: {
    messageReactionSums() {
      return this.message.reactionSums.filter((reaction) => reaction.total_count > 0);
    },
  },
  methods: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
  },
};
</script>
<template>
  <span v-if="message.reactionSums" class="_500 flex select-none flex-row flex-nowrap text-grey-600">
    <span v-for="reaction in messageReactionSums" :key="reaction.id" class="flex flex-shrink-0 items-center">
      <span class="mx-2">-</span>
      <span v-if="publicProvider === 'FACEBOOK'" class="flex flex-shrink-0 items-center">
        <img
          style="height: 1rem; padding-right: 1px"
          class="mr-1"
          :src="`${$root.assetsURL}img/facebook/${reaction.type}.png`"
          :alt="capitalize(reaction.type)"
          :title="capitalize(reaction.type)"
        />
        {{ reaction.total_count }}
      </span>
      <span v-else class="flex items-center">
        {{ $t('tickets.reaction_' + reaction.type) }} {{ reaction.total_count }}
      </span>
    </span>
  </span>
</template>
<style>
.slide-fade-delay-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-delay-leave-active {
  transition: all 0.2s ease;
}
.slide-fade-delay-enter-from,
.slide-fade-delay-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
