<template>
  <div class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis">{{ $t('integrations.zapier_title') }}</span>
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <div class="p-a p-b-0 container">
            <div class="p-a">
              <div class="box">
                <div class="box-header b-b">
                  <h2>{{ $t('integrations.zapier_heading') }}</h2>
                </div>
                <div class="box-body">
                  {{ $t('integrations.zapier_description') }}

                  <!--                                    <div v-if="googleChannels" class="mt-4">-->
                  <!--                                        Google e-mail channels require consent to comply with their requirements:-->
                  <!--                                        <div v-for="channel in googleChannels" class="mt-4">-->
                  <!--                                            <router-link tag="button" class="btn btn-secondary btn-md" :to="'/admin/channels2/email/'+channel.id+'#setup'">-->
                  <!--                                                {{ channel.display_name }}-->
                  <!--                                                <div v-if="channel.emailChannel.meta.zapier_consent" class="label success">granted</div>-->
                  <!--                                                <div v-else class="label danger">requires consent</div>-->
                  <!--                                            </router-link>-->
                  <!--                                        </div>-->
                  <!--                                    </div>-->
                </div>
                <div class="box-footer b-t">
                  <a class="btn success text-white" href="https://zapier.com/apps/trengo/integrations" target="_blank">
                    {{ $t('integrations.zapier_button_start_building') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      googleChannels: [],
    };
  },

  mounted() {
    // this.showGoogleZapierConsentChannels();
  },

  methods: {
    showGoogleZapierConsentChannels() {
      axios.get('/api/v2/channels', { params: { type: 'email' } }).then((res) => {
        if (res.data && res.data.data) {
          this.googleChannels = res.data.data.filter((c) => c.emailChannel && c.emailChannel.type === 'GOOGLE');
        }
      });
    },
  },
};
</script>
