<script type="text/babel">
import Multiselect from 'vue-multiselect';

export default {
  name: 'CreateProfileModal',
  data() {
    return {
      name: '',
      saving: false,
    };
  },
  methods: {
    createProfile() {
      this.saving = true;
      axios
        .post('/api/v2/profiles', { name: this.name })
        .then((res) => {
          this.$router.push('/profiles/' + res.data.id);
          this.name = '';
          $('#CreateProfileModal').modal('hide');
          this.saving = false;
        })
        .catch(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<template>
  <div id="CreateProfileModal" class="modal">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('profile.create_profile') }}</h5>
          <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label class="control-label">{{ $t('profile.profile_name') }}</label>
            <input v-model="name" :placeholder="$t('profile.profile_name')" type="text" class="form-control" />
          </div>
        </div>

        <div class="modal-footer">
          <button type="submit" :disabled="name == '' || saving" class="btn success" @click="createProfile">
            <span v-if="saving"><i class="fa fa-spinner fa-spin"></i></span>
            <span v-if="!saving">{{ $t('general.save') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
