<template>
  <account-page-panel
    :title="t('edit_subscription.title')"
    :has-back-button="true"
    @back-click="$router.push(SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS)"
  >
    <div class="w-full rounded-xl border-1 border-grey-300 bg-white">
      <plan-section />
      <div class="mb-6 px-6">
        <t-inline-banner type="upgrade">
          <template #icon>
            <arrow-top-circle-linear />
          </template>
          <div class="t-text-sm-emphasize">
            {{ t('edit_subscription.plan_or_seat_upgrades_are_not_available_title') }}
          </div>
          <span class="t-text-sm text-grey-800">
            {{ t('edit_subscription.plan_or_seat_upgrades_are_not_available_subtitle') }}
          </span>
          <template #action>
            <t-button size="sm" btn-style="secondary" href="https://trengo.com/contact" target="_blank">
              {{ t('general.contact_support') }}
              <arrow-top-right-linear size="1.2rem" class="ml-1" />
            </t-button>
          </template>
        </t-inline-banner>
      </div>
      <t-divider />
      <conversation-blocks-section />
      <t-divider />
      <carrier-costs-section />
    </div>
  </account-page-panel>
</template>

<script lang="ts" setup>
import { ArrowTopCircleLinear, ArrowTopRightLinear } from '@trengo/trengo-icons';
import { useI18n } from 'vue-i18n';

import AccountPagePanel from '@/components/common/AccountPagePanel/Index.vue';
import { SETTINGS_URL } from '@/Configs/Constants';
import { useSubscriptionChangesStore } from '@/store/pinia';

import CarrierCostsSection from './EditSubscription/CarrierCosts/CarrierCostsSection.vue';
import ConversationBlocksSection from './EditSubscription/Conversations/ConversationBlocksSection.vue';
import PlanSection from './EditSubscription/Plan/PlanSection.vue';

const { t } = useI18n();
useSubscriptionChangesStore().init();
</script>
