import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row-body" }
const _hoisted_2 = { class: "row-col" }
const _hoisted_3 = { class: "row-col" }
const _hoisted_4 = {
  key: 0,
  id: "settings-primary",
  class: "col-xs modal fade aside aside-md b-r w-medium w-200px"
}
const _hoisted_5 = { class: "row-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_admin_section_title = _resolveComponent("admin-section-title")!
  const _component_setting_sidebar = _resolveComponent("setting-sidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_create_email_modal_new_view = _resolveComponent("create-email-modal-new-view")!
  const _component_create_email_modal = _resolveComponent("create-email-modal")!
  const _component_create_voip_modal = _resolveComponent("create-voip-modal")!
  const _component_create_sms_modal = _resolveComponent("create-sms-modal")!
  const _component_create_wa_bsp_modal = _resolveComponent("create-wa-bsp-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_admin_section_title, {
      title: $options.getName($data.channel)
    }, null, 8 /* PROPS */, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        ($options.showChannelList)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_setting_sidebar, {
                channels: $data.channels,
                "channel-name": $options.getName($data.channel),
                onCreate: _cache[0] || (_cache[0] = ($event: any) => ($options.createNewChannel($data.channel)))
              }, null, 8 /* PROPS */, ["channels", "channel-name"])
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_5, [
          ($data.loaded)
            ? (_openBlock(), _createBlock(_component_router_view, {
                key: _ctx.$route.params.id,
                channels: $data.channels,
                onReloadChannelIndexList: $options.loadList
              }, null, 8 /* PROPS */, ["channels", "onReloadChannelIndexList"]))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ]),
    ($options.isNewEmailChannelView)
      ? (_openBlock(), _createBlock(_component_create_email_modal_new_view, {
          key: 0,
          modelValue: $data.modalOpen,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.modalOpen) = $event))
        }, null, 8 /* PROPS */, ["modelValue"]))
      : (_openBlock(), _createBlock(_component_create_email_modal, {
          key: 1,
          onUpdateLoaded: $options.handleLoadedUpdate
        }, null, 8 /* PROPS */, ["onUpdateLoaded"])),
    _createVNode(_component_create_voip_modal),
    _createVNode(_component_create_sms_modal),
    _createVNode(_component_create_wa_bsp_modal, {
      modelValue: $data.showWaBspModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.showWaBspModal) = $event))
    }, null, 8 /* PROPS */, ["modelValue"])
  ]))
}