<template>
  <div class="relative flex h-2 w-full rounded-lg bg-grey-300">
    <div
      v-for="(_, index) in hideMarkings ? 0 : markingsAmount - 1"
      :key="index"
      class="absolute top-1/2 h-1 w-[2px] -translate-y-1/2 transform rounded-[1px] bg-grey-400"
      :style="{ left: `${markingGaps[index]}%` }"
    ></div>
    <t-tooltip v-if="percentageFinished > 0" :style="`width: ${percentageFinished}%;`" :text="finishedTooltip">
      <div
        :class="[
          finishedColor,
          percentageInProgress > 0 ? '' : 'rounded-r-lg',
          'relative z-20 h-2 rounded-l-lg border-1  border-black border-opacity-10 transition-all duration-200 ease-in-out',
        ]"
      />
    </t-tooltip>
    <t-tooltip v-if="percentageInProgress > 0" :style="`width: ${percentageInProgress}%;`" :text="inProgressTooltip">
      <div
        :class="[
          inProgressColor,
          percentageFinished > 0 ? '' : 'rounded-l-lg',
          'striped-progress-bar relative z-10 h-2 overflow-hidden rounded-r-lg border-1 border-black border-opacity-10 transition-all duration-200 ease-in-out',
        ]"
      />
    </t-tooltip>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { calculateGaps } from './utils';

const props = defineProps({
  finishedColor: {
    type: String,
    default: 'bg-leaf-600',
  },
  inProgressColor: {
    type: String,
    default: 'bg-leaf-400',
  },
  finished: {
    type: Number,
    default: 0,
  },
  inProgress: {
    type: Number,
    default: 0,
  },
  total: {
    type: Number,
    default: 0,
  },
  hideMarkings: {
    type: Boolean,
    default: false,
  },
  markingsAmount: {
    type: Number,
    default: 10,
  },
  inProgressTooltip: {
    type: String,
    default: 'usage_overview.overview_section_conversations_in_progress',
  },
  finishedTooltip: {
    type: String,
    default: 'usage_overview.overview_section_conversations_finished',
  },
});

const markingGaps = computed(() => calculateGaps(props.markingsAmount));

const getPercentage = (value: number) => {
  if (value === 0 && props.total === 0) {
    return 0;
  }
  return Math.min(1, value / props.total) * 100;
};

const percentageInProgress = computed(() => getPercentage(props.inProgress));

const percentageFinished = computed(() => getPercentage(props.finished));
</script>

<style lang="scss" scoped>
.striped-progress-bar {
  background-image: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.08) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.08) 50%,
    rgba(0, 0, 0, 0.08) 75%,
    transparent 75%,
    transparent
  );
  background-size: 15px 15px;
  animation: progress-bar-stripes 2s linear infinite;
}
</style>
