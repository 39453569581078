import { vm } from '@/ApplicationHandler';

import Automation from '../Automation';

const OpenHelpCenterTemplate = class {
  constructor() {
    this.title = vm.$t('widget_automation.page_enter_a_title');
    this.active = false;
    this.payload = {
      actions: [Automation.Action(Automation.actionTypes.openHelpCenter)],
      conditions: [],
    };
  }
};

export default OpenHelpCenterTemplate;
