import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-493596e1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-dropdown-not-found mb-5 ml-auto mr-auto mt-5 flex min-h-[6.25rem] flex-col items-center justify-center text-center" }
const _hoisted_2 = { class: "t-text-sm mb-0 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_slash_linear = _resolveComponent("slash-linear")!
  const _component_t_thumbnail = _resolveComponent("t-thumbnail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_t_thumbnail, null, {
      default: _withCtx(() => [
        _createVNode(_component_slash_linear, { size: "1.25rem" })
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.text), 1 /* TEXT */)
  ]))
}