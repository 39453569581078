<script type="text/babel">
import { AtLinear, EmailLinear } from '@trengo/trengo-icons';

import { authenticateChannel } from '@/api/modules/email';
import { FEATURE_FLAG_CHANNELS } from '@/Configs/Constants';
import { NYLAS, NYLAS_API_V3 } from '@/Configs/Constants/ChannelLandingPageTypes';
import eventBus from '@/eventBus';
import { useFeatureFlagStore } from '@/store/pinia';

export default {
  name: 'CreateEmailModal',
  emits: ['update-loaded'],
  components: {
    AtLinear,
    EmailLinear,
  },
  mounted() {
    eventBus.$on('modal-email-create', () => {
      $('#CreateEmailModal').modal('show');
    });
  },
  data() {
    return {
      loading: false,
      provider: null,
    };
  },
  computed: {
    shouldDisplayNylasButton() {
      const featureFlagStore = useFeatureFlagStore();

      return (
        featureFlagStore.isEnabled(FEATURE_FLAG_CHANNELS.TC_NYLAS_EMAIL_CHANNEL) ||
        featureFlagStore.isEnabled(FEATURE_FLAG_CHANNELS.TC_NYLAS_EMAIL_CHANNEL_V3)
      );
    },
    nylasChannelName() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_CHANNELS.TC_NYLAS_EMAIL_CHANNEL_V3)
        ? NYLAS_API_V3
        : NYLAS.toLowerCase();
    },
  },
  methods: {
    authenticateChannel(channelName) {
      this.$emit('update-loaded', false);
      this.loading = true;
      authenticateChannel(channelName)
        .then((res) => {
          window.location.href = res.data.url;
        })
        .catch((err) => {
          this.$emit('update-loaded', true);
          this.loading = false;
        });
    },
  },

  beforeUnmount() {
    let createEmailModal = $('#CreateEmailModal');
    if (createEmailModal.length) {
      createEmailModal.modal('hide');
    }

    let modalBackdrop = $('.modal-backdrop.in');
    if (modalBackdrop.length) {
      modalBackdrop.remove();
    }
  },
};
</script>
<template>
  <div id="CreateEmailModal" class="modal" role="dialog">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <template v-if="provider !== null">
              <button type="button" class="float-left ml-1 mr-4 mt-1" @click="provider = null">
                <i class="fa fa-arrow-left" style="color: #dae1e7" aria-hidden="true"></i>
              </button>
            </template>
            <h5 class="modal-title">
              {{ $t('email_settings.email_connect_modal_title') }}
            </h5>
            <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div id="email_connect_modal_body" class="modal-body">
            <template v-if="!provider">
              <div class="form-group">
                <label class="control-label">
                  {{ $t('email_settings.email_connect_modal_subtitle') }}
                </label>
              </div>
              <div class="form-group">
                <div class="m-b-lg mt-8">
                  <atomic-button
                    size="md"
                    btn-style="secondary"
                    aria-label="Connect your number"
                    class="button"
                    @click="provider = 'gmail'"
                  >
                    <img :src="`${$root.assetsURL}img/google.svg`" alt="google" />
                    <div class="w-full text-center">
                      {{ $t('email_settings.email_connect_modal_connect') }}
                      Gmail
                    </div>
                  </atomic-button>
                  <atomic-button
                    size="md"
                    btn-style="secondary"
                    aria-label="Connect your number"
                    class="button"
                    @click="provider = 'outlook'"
                  >
                    <img :src="`${$root.assetsURL}img/outlook.svg`" alt="outlook" />
                    <div class="w-full text-center">
                      {{ $t('email_settings.email_connect_modal_connect') }}
                      Outlook
                    </div>
                  </atomic-button>
                  <atomic-button
                    v-if="shouldDisplayNylasButton"
                    size="md"
                    btn-style="secondary"
                    type="button"
                    aria-label="Connect your number"
                    class="button relative"
                    @click="authenticateChannel(nylasChannelName)"
                  >
                    <email-linear class="h-6 w-6" />
                    <div class="w-full text-center">
                      {{ $t('email_settings.email_connect_modal_connect_other_email_client') }}
                    </div>
                    <t-badge text="Beta" class="absolute right-2" />
                  </atomic-button>
                  <atomic-button size="md" btn-style="secondary" aria-label="Connect your number" class="button">
                    <router-link
                      to="/admin/channels2/email/create?type=TRENGO"
                      data-dismiss="modal"
                      class="flex w-full items-center"
                    >
                      <at-linear class="h-6 w-6" />
                      <div class="w-full text-center">
                        {{ $t('email_settings.email_connect_modal_create_forwarding_channel') }}
                      </div>
                    </router-link>
                  </atomic-button>
                </div>
              </div>
            </template>
            <template v-else-if="provider === 'gmail'">
              <div class="control-label">
                <p>
                  {{ $t('email_settings.email_connect_modal_subtitle_step_two_gmail') }}
                </p>
                <div class="my-4 text-center">
                  <button type="button" class="redirect-button" @click="authenticateChannel('gmail')">
                    <span class="redirect-button__icon">
                      <svg viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg">
                        <path
                          id="Shape"
                          d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z"
                          fill="#EA4335"
                        />
                        <path
                          id="Shape"
                          d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z"
                          fill="#FBBC05"
                        />
                        <path
                          id="Shape"
                          d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z"
                          fill="#4285F4"
                        />
                        <path
                          d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z"
                          fill="#34A853"
                        />
                      </svg>
                    </span>
                    <span class="redirect-button__text">{{ $t('email_settings.sign_in_with_google') }}</span>
                  </button>
                </div>
              </div>
            </template>
            <template v-else-if="provider === 'outlook'">
              <div class="control-label">
                <p>
                  {{ $t('email_settings.email_connect_modal_subtitle_step_two_outlook') }}
                </p>
                <div class="my-4 text-center">
                  <button type="button" class="redirect-button" @click="authenticateChannel('outlook')">
                    <span class="redirect-button__icon">
                      <svg xmlns="http://www.w3.org/2000/svg">
                        <path fill="#f3f3f3" d="M0 0h23v23H0z" />
                        <path fill="#f35325" d="M1 1h10v10H1z" />
                        <path fill="#81bc06" d="M12 1h10v10H12z" />
                        <path fill="#05a6f0" d="M1 12h10v10H1z" />
                        <path fill="#ffba08" d="M12 12h10v10H12z" />
                      </svg>
                    </span>
                    <span class="redirect-button__text">{{ $t('email_settings.sign_in_with_microsoft') }}</span>
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<style lang="scss" scoped>
.button {
  @apply mx-auto mb-6 flex w-full items-center;
  max-width: 376px;
  height: 64px;

  i {
    font-size: 24px;
  }

  img {
    width: 24px;
    height: 24px;
    @apply flex items-center;
  }

  @screen md {
    height: 56px;
  }
}

.redirect-button {
  height: 40px;
  border-width: 0;
  background: white;
  color: #737373;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;

  &:focus,
  &:hover {
    box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
  }

  &:active {
    background-color: #e5e5e5;
    box-shadow: none;
    transition-duration: 10ms;
  }
}

.redirect-button__icon {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0 8px 8px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.redirect-button__icon--plus {
  width: 27px;
}

.redirect-button__text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 24px;
  font-size: 14px;
  font-weight: bold;
}

.email-provider-button {
  padding: 13px 15px 8px 15px;
  margin: 20px auto;
  max-width: 350px;
  display: flex !important;
}

.email-provider-icon {
  height: 30px;
  width: 30px;
  line-height: 1;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0 !important;
  margin-right: 10px;
}
</style>
