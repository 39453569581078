<script type="text/babel">
import _ from 'lodash';

import ChannelIcon from './ChannelIcon';
import eventBus from '@/eventBus';

export default {
  name: 'Timeline',
  props: ['profile', 'contact'],

  data() {
    return {
      ticketsPage: 1,
      tickets: {
        links: {},
        data: [],
      },
      searchTerm: '',
    };
  },

  components: {
    ChannelIcon,
  },

  mounted() {
    this.loadTimeline();
    eventBus.$on('profile.timeline.reload', () => {
      this.loadTimeline();
    });
  },

  unmounted() {
    eventBus.$off('profile.timeline.reload');
  },

  methods: {
    loadTimeline: function () {
      let p;
      if (this.profile != null) {
        p = {
          page: this.ticketsPage,
          profile_id: this.profile.id,
        };
      } else if (this.contact != null) {
        p = {
          page: this.ticketsPage,
          contact_id: this.contact.id,
        };
      } else {
        return;
      }

      p.term = this.searchTerm;

      axios.get('/api/v2/tickets', { params: p }).then((res) => {
        let tickets = res.data;
        if (this.ticketsPage > 1) {
          _.each(tickets.data, (ticket) => {
            this.tickets.data.push(ticket);
          });
          this.tickets.links.next = res.data.links.next;
        } else {
          this.tickets = tickets;
        }
      });
    },

    loadMore() {
      this.ticketsPage++;
      this.loadTimeline();
    },

    ago(date) {
      return moment(date).format('DD/MM/YY');
    },

    openTicket(id) {
      this.$router.push('/tickets/' + id);
    },

    getIconClass(channel_type) {
      switch (channel_type) {
        case 'WHATSAPP':
          return 'whatsapp';
        case 'FACEBOOK':
          return 'facebook';
        case 'TELEGRAM':
          return 'telegram';
        case 'EMAIL':
          return 'email';
        case 'VOIP':
          return 'phone';
        case 'CHAT':
          return 'chat';
        case 'SMS':
          return 'phone';
      }
    },

    search: _.debounce(function () {
      this.loadTimeline();
    }, 250),

    getChannelNamePresenter(type) {
      switch (type) {
        case 'EMAIL':
          return 'Email';
        case 'CHAT':
          return 'Chat';
        case 'VOIP':
          return 'Phone call';
        case 'WHATSAPP':
        case 'WA_BUSINESS':
          return 'WhatsApp';
        case 'FACEBOOK':
          return 'Facebook';
        case 'SMS':
          return 'SMS';
        default:
          return type.toLocaleString();
      }
    },
  },
};
</script>

<template>
  <div class="flex flex-1 flex-col">
    <div class="flex-1">
      <input
        v-model="searchTerm"
        type="text"
        class="w-full rounded-lg bg-grey-200 px-4 py-2"
        :placeholder="$t('general.searching')"
        @keyup="search"
        @paste="search"
      />
    </div>
    <div class="w-full flex-1">
      <div v-if="tickets.data.length > 0">
        <div
          v-for="ticket in tickets.data"
          :to="'/tickets/' + ticket.id"
          class="mt-2 flex rounded-lg border-2 border-grey-200"
          @click="openTicket(ticket.id)"
        >
          <div class="overflow-hidden rounded-lg" style="margin-left: -2px; margin-top: -2px">
            <span
              class="circle inline-block-40px avatar -ml-1 -mt-1"
              :class="{
                'bg-orange text-white': ticket.status === 'OPEN',
                'bg-success white': ticket.status === 'ASSIGNED',
                'bg-grey-500 text-white': ticket.status === 'CLOSED',
              }"
            >
              <channel-icon class="" :channel="ticket.channel.type"></channel-icon>
            </span>
          </div>
          <div class="flex min-w-0 flex-1 flex-col p-4">
            <div class="flex">
              <strong>{{ getChannelNamePresenter(ticket.channel.type) }}</strong>
              <span class="ml-auto">
                <datetime
                  v-if="ticket.latest_message != null"
                  :pretty="$root.prettyDates"
                  class="text-muted text-xs"
                  :time="ticket.latest_message.created_at"
                ></datetime>
                <datetime
                  v-if="ticket.latest_message == null"
                  :pretty="$root.prettyDates"
                  class="text-muted text-xs"
                  :time="ticket.created_at"
                ></datetime>
              </span>
            </div>
            <div class="my-1 min-w-0 leading-none">
              <div v-if="ticket.channel.type == 'EMAIL'" class="text-ellipsis">{{ ticket.subject }}</div>
              <div v-if="ticket.channel.type != 'EMAIL' && ticket.latest_message" class="text-ellipsis">
                {{ ticket.latest_message.message }}
              </div>
            </div>
            <div v-if="profile != null" class="m-b-xs text-ellipsis text-sm text-grey-600">
              Contact: {{ ticket.contact.full_name }}
              <span v-if="ticket.agent != null">| Agent: {{ ticket.agent.first_name }}</span>
            </div>
          </div>
        </div>
        <div v-if="tickets.links.next != null" class="text-muted mt-2 text-center text-xs" @click="loadMore()">
          <div class="cursor-pointer">View more..</div>
        </div>
      </div>

      <div v-if="tickets.data.length === 0" class="text-muted mt-2 text-center text-xs">
        <div class="cursor-pointer">{{ $t('general.no_results') }}</div>
      </div>
    </div>
  </div>
</template>
