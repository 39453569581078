import moment from 'moment';

import { request } from '@/util';

import type {
  ConversationsVolumeResponse,
  TicketsAggregatesResponse,
  CsatParams,
  CsatResponse,
} from '@/components/Reporting/api/types';
import type { AxiosPromise } from 'axios';

export const fetchTicketsAggregates = (params: string[], filters: string): AxiosPromise<TicketsAggregatesResponse> => {
  const metricQueryString = params.map((metric) => `metric[]=${metric}`).join('&');
  const URL = `/api/v2/reporting/metrics?${metricQueryString}&${filters ? filters : ''}`;
  return request(URL, 'GET', { ...params });
};
export const fetchCsatScore = (): AxiosPromise<CsatResponse> => {
  const URL = '/api/v2/reports/reports/csat';
  return request(URL, 'GET');
};

export const fetchConversationsVolumeToday = (
  filters: string,
  timezone: string
): AxiosPromise<ConversationsVolumeResponse> => {
  const parsedFilters = filters ? parseFilters(filters) : '';
  const startDate = moment().tz(timezone).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
  const endDate = moment().tz(timezone).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
  const encodedStartDate = encodeURIComponent(startDate);
  const encodedEndDate = encodeURIComponent(endDate);
  const today = moment(new Date()).tz(timezone).format('dddd');

  const URL = `/api/v2/reporting/histograms?metric=created_tickets&interval=hour&start_date=${encodedStartDate}&end_date=${encodedEndDate}&compare_day=${today.toUpperCase()}&${parsedFilters}`;

  return request(URL, 'GET');
};

export const fetcAverageConversationsVolumeLast4weeks = (
  filters: string,
  timezone: string
): AxiosPromise<ConversationsVolumeResponse> => {
  const parsedFilters = filters ? parseFilters(filters) : '';
  const startDate = moment().tz(timezone).subtract(4, 'week').startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
  const endDate = moment().tz(timezone).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
  const encodedStartDate = encodeURIComponent(startDate);
  const encodedEndDate = encodeURIComponent(endDate);
  const today = moment(new Date()).tz(timezone).format('dddd');

  const URL = `/api/v2/reporting/histograms?metric=average_tickets_created_same_day&interval=hour&start_date=${encodedStartDate}&end_date=${encodedEndDate}&compare_day=${today.toUpperCase()}&${parsedFilters}`;

  return request(URL, 'GET');
};

function parseFilters(filters: string) {
  let serializedFilters = '';
  const filtersObject = JSON.parse(filters);

  for (const key in filtersObject) {
    const currentFilter = filtersObject[key];
    let filterString;
    if (typeof currentFilter === 'object') {
      filterString = currentFilter.map((filter: number | string) => `${key}[]=${filter}`).join('&');
    } else {
      filterString = `${key}=${currentFilter}`;
    }
    serializedFilters = `${serializedFilters ? serializedFilters + '&' : ''}${filterString}`;
  }
  return serializedFilters;
}
