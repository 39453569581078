<script>
export default {
  mounted() {},
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div class="flex flex-1 select-none rounded-lg border-2 border-grey-200 p-4">
    <div class="flex">
      <div>
        <slot name="left">dsa</slot>
      </div>
      <div class="ml-4 mt-1">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>
