<script lang="ts" setup>
import { ref } from 'vue';

import {vm} from '@/ApplicationHandler';
import TableSort from '@/components/Reporting/Components/V2/TableSort/Index.vue';

import DeleteActionMenu from './DeleteActionMenu.vue';
import { type Contact } from '../types';
const props = defineProps<{ contacts: Contact[], group: string }>();
const emit = defineEmits(['sort', 'delete']);

const table = ref<HTMLElement | null>(null);
function sortBy(type: string, direction: 'asc' | 'desc') {
  clickAway();
  emit('sort', { type, direction });
}

function clickAway() {
  table.value?.click();
}

async function handleDelete(item: Contact) {
  try {
    const shouldDelete = await vm.$tConfirm(
      vm.$t('contacts.remove_contact_from_group', { group: props.group }),
      {
        delete: true,
        title: vm.$t('contacts.remove_contact'),
        confirmText: vm.$t('contacts.remove'),
      }
    );

    if (!shouldDelete) {
      return;
    }
    emit('delete', item);
  } catch (e) {
    console.error('Error when deleting contact', e);
  }
}
</script>

<template>
  <div ref="table">
    <t-table-list data-test="table" class="mb-2" alternate>
      <t-table-list-row head>
        <t-table-list-head>
          <div class="flex flex-row items-center">
            <p class="mb-0 mr-2">{{ vm.$t('contacts.contact_name') }}</p>
            <table-sort>
              <template #item>
                <div>
                  <t-list-item :label="vm.$t('contacts.name_a_z')" @click="sortBy('name', 'asc')" />
                  <t-list-item :label="vm.$t('contacts.name_z_a')" @click="sortBy('name', 'desc')" />
                  <t-list-item :label="vm.$t('contacts.created_first_last')" @click="sortBy('created', 'asc')" />
                  <t-list-item :label="vm.$t('contacts.created_last_first')" @click="sortBy('created', 'desc')" />
                </div>
              </template>
            </table-sort>
          </div>
        </t-table-list-head>
        <t-table-list-head />
      </t-table-list-row>
      <t-table-list-row v-for="item in props.contacts" :key="item.id" data-test="table-rows">
        <t-table-list-cell>
          <div class="flex flex-row items-center">
            <t-avatar
              :width="32"
              :abbr="item.avatar.abbr"
              :color="item.avatar.color"
              :image="item.avatar.image"
              class="mr-2"
            />
            <p class="t-text-desktop-paragraph-sm sort fade-in mb-0 truncate text-grey-800">
              {{ item.name }}
            </p>
          </div>
        </t-table-list-cell>
        <t-table-list-cell>
          <div class="flex flex-row items-center justify-end">
            <p class="t-text-desktop-paragraph-sm mb-0 text-right text-grey-600">{{ item.phone }}</p>
            <div class="ml-8"><delete-action-menu :id="item.id" @delete="handleDelete(item)" /></div>
          </div>
        </t-table-list-cell>
      </t-table-list-row>
    </t-table-list>
    <div class="mt-24 h-10">
      <slot />
    </div>
  </div>
</template>
