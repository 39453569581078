<script type="text/babel">
export default {
  data() {
    return {
      loaded: true,
      ivrMenus: [],
    };
  },

  mounted() {
    this.loadList(false);
  },

  methods: {
    loadList(sync_with_root = true) {
      axios.get('/api/v2/ivr_menus').then((response) => {
        this.ivrMenus = response.data.data;
        this.loaded = true;
      });
    },
  },
};
</script>

<template>
  <div class="row-body">
    <admin-section-title title="IVR menus"></admin-section-title>
    <div class="row-col">
      <div class="row-col">
        <div id="settings-primary" class="col-xs modal fade aside aside-md b-r w-medium w-200px">
          <div class="nav-settings light left b-primary row-col">
            <ul>
              <li class="text-md white header">
                <strong>IVR menus</strong>
                <a data-dismiss="modal" class="hidden-md-up text-muted m-l-auto m-r-sm m-l-1 text-lg">×</a>
                <a class="m-l-auto btn btn-icon btn-sm success rounded text-white">
                  <i class="material-icons md-18" @click="$router.push('/admin/ivr/create')">add</i>
                </a>
              </li>
            </ul>
            <div class="row-row">
              <div class="row-body scrollable hove">
                <div class="row-inner">
                  <ul>
                    <li v-for="item in ivrMenus">
                      <router-link
                        class="nav-settings-link text-truncate"
                        :to="'/admin/ivr/' + item.id + ''"
                        data-dismiss="modal"
                        :class="{ active: $route.params.id == item.id }"
                      >
                        {{ item.name }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-col">
          <router-view
            v-if="loaded"
            :key="$route.params.id"
            :ivr-menus="ivrMenus"
            @call-loadlist-method="loadList"
          ></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
