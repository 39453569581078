<template>
  <div class="rounded-lg bg-white py-6" :class="{ 'px-10': report !== 'csats', ' mx-6 xl2:mx-10': report === 'csats' }">
    <aggregates
      v-if="showAggregates"
      :metrics="metrics"
      :current-data="firstSetData.aggregates"
      :previous-data="secondSetData.aggregates"
    ></aggregates>
    <csat-cards v-if="showCsatCards" :aggregates="aggregates" />
    <graph-report
      v-if="showGraphReport"
      :metrics="metrics"
      :first-set-data="(firstSetData && firstSetData.buckets) || firstSetData"
      :second-set-data="(secondSetData && secondSetData.buckets) || secondSetData"
      :interval="filters.interval"
      :current-metric="currentMetric"
      :report="report"
    ></graph-report>
    <table-report
      v-if="showTableReport"
      :time-columns="timeColumns"
      :current-data="firstSetData.buckets"
      :is-csat="report === 'csats'"
      :ticket-ids="tickets"
    ></table-report>
    <no-csats-message v-if="report === 'csats' && !aggregates.received" />
    <div v-if="loading" class="flex items-center justify-center py-8">
      <t-spinner />
    </div>
  </div>
</template>

<script lang="ts">
import { useTicketInsightsStore } from '@/components/Reporting/Pages/V2/TicketInsights/store';
import eventBus from '@/eventBus';
import { useToastMessageStore } from '@/store/pinia/toastMessage';
import { request } from '@/util';

import Aggregates from './Aggregates';
import GraphReport from './GraphReport';
import NoCsatsMessage from './NoCsatsMessage';
import TableReport from './TableReport';
import CsatCards from '../Components/CsatCards';

type PusherData = {
  status: 'COMPLETED' | 'PENDING' | 'FAILED';
  file_path: string;
  export_id: number;
};

export default {
  components: {
    TableReport,
    GraphReport,
    Aggregates,
    CsatCards,
    NoCsatsMessage,
  },
  props: {
    metrics: {
      type: Array,
      default: () => [],
    },
    timeColumns: {},
    report: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'line',
    },
    title: {
      type: Boolean,
      default: true,
    },
    previousPeriod: {
      type: Boolean,
      default: true,
    },
    filters: {},
  },

  setup() {
    const toastMessageStore = useToastMessageStore();
    const ticketInsightsStore = useTicketInsightsStore();

    return { toastMessageStore, ticketInsightsStore };
  },

  data: function () {
    return {
      currentMetric: null,
      firstSetData: null,
      secondSetData: null,
      barHeight: '400px',
      loading: false,
      tickets: null,
      aggregates: {},
      tableData: {},
    };
  },
  computed: {
    showAggregates() {
      return this.firstSetData && this.firstSetData.aggregates && this.report !== 'csats';
    },
    showCsatCards() {
      return this.report === 'csats' && this.firstSetData && this.aggregates.received;
    },
    showGraphReport() {
      return this.type === 'line' && this.firstSetData && !(this.report === 'csats' && !this.aggregates.received);
    },
    showTableReport() {
      return (
        this.firstSetData &&
        (this.type === 'table' || this.report === 'csats') &&
        !(this.report === 'csats' && !this.aggregates.received)
      );
    },
  },
  watch: {
    filters() {
      if (this.report === 'csats') {
        this.fetchCsat();
      } else {
        this.fetch();
      }
    },
  },

  mounted() {
    eventBus.$on('exportCSAT', () => this.exportCSAT());
  },

  beforeUnmount() {
    eventBus.$off('exportCSAT');
  },

  methods: {
    async fetchCsatExport(tickets: number[]) {
      return await request(`/api/v2/csat/reports/export`, 'POST', { tickets });
    },

    exportCSAT() {
      this.toastMessageStore.closeToastMessage();

      if (this.loading) {
        this.toastMessageStore.$patch({
          showToast: true,
          hasCloseIcon: true,
          text: this.$t('reports_v2.csat_being_fetched'),
        });
        return;
      }
      if (this?.tickets?.length === 0) {
        this.toastMessageStore.$patch({
          showToast: true,
          hasCloseIcon: true,
          text: this.$t('reports_v2.no_ticket_data_found'),
        });
        return;
      }

      this.fetchCsatExport(this.tickets).then(() => {
        this.ticketInsightsStore.$patch({ isDownloadInProgress: true });

        this.toastMessageStore.$patch({
          showToast: true,
          text: this.$t('reports_v2.preparing_download_file'),
        });

        this.$root.userChannel.bind('ReportingExportProgress', (data: PusherData) =>
          this.fetchDownloadReportingStatus(data),
        );
      });
    },

    fetchDownloadReportingStatus(data: PusherData) {
      this.ticketInsightsStore.$patch({
        isDownloadInProgress: false,
      });

      if (data.status === 'COMPLETED') {
        this.downloadReportingCompletedStatus(data.file_path);
        this.$root.userChannel.unbind('ReportingExportProgress');
      }

      if (data.status === 'FAILED') {
        this.toastMessageStore.$patch({
          hasCloseIcon: true,
          hasIcon: true,
          iconType: 'alert',
          text: this.$t('reports_v2.failed_to_download'),
        });

        this.$root.userChannel.unbind('ReportingExportProgress');
      }
    },

    downloadReportingCompletedStatus(file_path: string) {
      this.toastMessageStore.$patch({
        hasCloseIcon: false,
        iconType: 'success',
        hasIcon: true,
        text: this.$t('reports_v2.your_file_has_been_downloaded'),
      });

      window.open(file_path, '_blank');
    },

    fetch() {
      if (!this.filters.previousPeriod || this.loading) {
        return;
      }
      this.loading = true;
      axios
        .all([
          axios.get('/api/v2/reports/reports', {
            params: { ...this.getRequestParams(), ...{ report: this.report } },
          }),
          axios.get('/api/v2/reports/reports', {
            params: {
              ...this.getRequestParams(),
              ...{
                report: this.report,
                period_start: this.filters.previousPeriod.start,
                period_end: this.filters.previousPeriod.end,
              },
            },
          }),
        ])
        .then(
          axios.spread((current, previous) => {
            this.secondSetData = previous.data;
            this.firstSetData = current.data;
            this.loading = false;
          }),
        );
    },
    fetchCsat() {
      if (!this.filters.previousPeriod || this.loading) {
        return;
      }
      this.loading = true;
      axios
        .get('/api/v2/reports/reports/csat', {
          params: { ...this.firstCsatRequestParams() },
        })
        .then((data) => {
          this.firstSetData = data.data.buckets.interval_results.map(function (obj) {
            const el = {};
            el.average_rating = Math.round(Math.max(obj.average_rating - 1, 0) * 25);
            el.timestamp = obj.timestamp;
            return el;
          });
          this.secondSetData = data.data.buckets.interval_results.map(function (obj) {
            const el = {};
            el.surveys_received = obj.surveys_received;
            el.timestamp = obj.timestamp;
            return el;
          });
          this.aggregates = {
            csats: Math.round((data.data.buckets.total_average_rating - 1) * 25),
            received: data.data.buckets.received_surveys,
            sent: data.data.buckets.sent_surveys,
            response_rate: data.data.buckets.sent_surveys
              ? Math.round((data.data.buckets.received_surveys / data.data.buckets.sent_surveys) * 100)
              : 0,
            positive_responses:
              (data.data.buckets.response_type_counts[4] ? data.data.buckets.response_type_counts[4] : 0) +
              (data.data.buckets.response_type_counts[5] ? data.data.buckets.response_type_counts[5] : 0),
            neutral_responses: data.data.buckets.response_type_counts[3]
              ? data.data.buckets.response_type_counts[3]
              : 0,
            negative_responses:
              (data.data.buckets.response_type_counts[1] ? data.data.buckets.response_type_counts[1] : 0) +
              (data.data.buckets.response_type_counts[2] ? data.data.buckets.response_type_counts[2] : 0),
          };
          this.tickets = Object.values(data.data.buckets.csat_ticket_ids);
          this.loading = false;
        });
    },
    getRequestParams() {
      return {
        period_start: this.filters.currentPeriod.start,
        period_end: this.filters.currentPeriod.end,
        interval: this.filters.interval,
        labels: this.filters.labels,
        users: this.filters.users,
        channels: this.filters.channels,
        business_hours: this.filters.excludeBusinessHours,
      };
    },
    firstCsatRequestParams() {
      return {
        period_start: this.filters.currentPeriod.start,
        period_end: this.filters.currentPeriod.end,
        interval: this.filters.interval,
        labels: this.filters.labels,
        users: this.filters.users,
        channels: this.filters.channels,
        ratings: this.filters.ratings,
        surveys: this.filters.surveys,
        timezone: this.$root.user.timezone,
      };
    },
  },
};
</script>
