<template>
  <div class="mb-6 max-w-full rounded-xl border-1 border-grey-300 bg-white pt-6">
    <div class="mb-8 ml-6 flex items-center gap-3">
      <t-thumbnail>
        <chart-column-linear />
      </t-thumbnail>
      <div>
        <h5 class="t-text-h5 m-0 text-grey-800">
          {{ $t('transactions.chart_heading') }}
        </h5>
        <span class="text-sm text-grey-600">
          {{ $t('transactions.chart_subheading') }}
        </span>
      </div>
    </div>

    <div class="h-88 p-6 pt-0">
      <canvas ref="transactionsChart"></canvas>
    </div>
  </div>
</template>

<script lang="ts">
import { ChartColumnLinear } from '@trengo/trengo-icons';
import { Chart } from 'chart.js';
import { capitalize } from 'lodash';

import { TRANSACTION_CHART_ITEM, TRANSACTION_BORDER_COLORS, TRANSACTION_COLORS } from './constants';

import type { TransactionChartItem, TransactionMonth, Category } from '@/types';

export default {
  props: {
    transactions: {
      type: Array,
      required: true,
    },
  },
  components: {
    ChartColumnLinear,
  },

  mounted() {
    this.prepareChartData();
  },

  methods: {
    prepareChartData() {
      if (!this.transactions) return;

      const categories = this.transactions?.[0].categories.map((item: Category) => item.label);

      const dataSet = categories.map((category: string, index: number) => {
        const chartItem: TransactionChartItem = {
          ...TRANSACTION_CHART_ITEM,
          label: category,
          backgroundColor: TRANSACTION_COLORS[index],
          borderColor: TRANSACTION_BORDER_COLORS[index],
        };

        const data = this.transactions.map((transaction: TransactionMonth) => {
          const amount = transaction.categories?.find((item) => item.label === category)?.amount ?? 0;

          return amount > 0 ? amount / 100 : 0;
        });

        return { ...chartItem, data };
      });

      const chartData = {
        labels: this.transactions.map((item: TransactionMonth) => capitalize(item.month)),
        datasets: dataSet.reverse(),
      };
      this.renderChart(chartData);
    },
    renderChart(chartData: any) {
      const ctx = this.$refs.transactionsChart.getContext('2d');
      if (this.transactionsChart) {
        this.transactionsChart.destroy();
      }

      this.transactionsChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: chartData.labels,
          datasets: chartData.datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
              },
              title: {
                display: false,
              },
              ticks: {
                font: {
                  weight: '600',
                  size: 14,
                },
              },
            },
            y: {
              position: 'top',
              stacked: true,
              ticks: {
                crossAlign: 'center',
                maxTicksLimit: 6,
                font: {
                  size: 14,
                },
                callback: function (value) {
                  if (value === 0) return value;
                  return '€' + value;
                },
              },
            },
          },
          plugins: {
            tooltip: {
              titleSpacing: 4,
              displayColors: false,
              bodyFont: {
                weight: 'bold',
              },
              callbacks: {
                title: () => '',
                label: (context) => {
                  const transaction =
                    context.parsed.y.toLocaleString('nl-NL', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }) || '0,00';
                  return `${context.dataset.label}: €${transaction}`;
                },
              },
            },
            legend: {
              display: true,
              position: 'bottom',
              reverse: true,
              onClick: (e: any) => e.stopPropagation(),
              labels: {
                boxWidth: 16,
                boxHeight: 16,
                useBorderRadius: true,
                borderRadius: 4,
                padding: 20,
                font: {
                  size: 14,
                  lineHeight: 18,
                },
              },
            },
          },
          barThickness: 16,
          borderRadius: 4,
        },
      });
    },
  },
};
</script>
