import { PERMISSION, PRICING_MODEL } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';

import type { RouteRecord } from '../types';

const usageOverviewRoutes: RouteRecord[] = [
  {
    path: SETTINGS_URL.ADMIN__USAGE_OVERVIEW,
    component: require(/* webpackChunkName: "Transactions" */ '../../components/UsageOverview/Index').default,
    meta: {
      permissionRequired: PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE,
      pricingModelRequired: PRICING_MODEL.USAGE_BASED,
    },
  },
];

export default usageOverviewRoutes;
