import eventBus from '@/eventBus';
export default {
  /**
   * Simplifies binding to pusher events.
   * This defers the binding if subscription is pending.
   *
   * @param pusherChannel
   * @param event
   * @param callback
   * @param once  Prevent this event from bounding multiple times.
   * @return Promise<pusherChannel> Resolves after successful binding. Rejects on error. Never resolves when waiting for 'pusher.channelName.subscribed' event. (todo reject after x retries).
   */
  bindPusherEvent(pusherChannel, event, callback, once = false) {
    return new Promise((resolve, reject) => {
      if (once) {
        const exist = Object.keys(pusherChannel.callbacks._callbacks).find(
          (callbackName) => '_' + event === callbackName
        );
        if (exist) {
          resolve();
          return;
        }
      }

      this._bindPusherEvent(pusherChannel, event, callback, resolve, reject);
    });
  },

  /**
   * Private method. Use bindPusherEvent instead.
   * @param pusherChannel
   * @param event
   * @param callback
   * @param resolve
   * @param reject
   * @returns void
   */
  _bindPusherEvent(pusherChannel, event, callback, resolve, reject) {
    pusherChannel = window.PusherInstance.channel(pusherChannel) || pusherChannel;

    if (pusherChannel.subscribed) {
      pusherChannel.bind(event, callback);

      // unbind subscription errors immediately, we dont want an error for every try
      pusherChannel.unbind('pusher:subscription_error');

      // unbind after all bound events have called their callbacks.
      setTimeout(() => {
        pusherChannel.unbind('pusher:subscription_succeeded');
      }, 500);

      // successful binding, resolve Promise
      resolve(pusherChannel);
    } else {
      if (typeof pusherChannel !== 'object') {
        // todo: sleep, retry x times, reject after
        console.info('Channel not (yet) created');

        // this waits indefinitely, is this line even necessary?. todo reject on timeout.
        eventBus.$once('pusher.' + pusherChannel + '.subscribed', () =>
          this._bindPusherEvent(pusherChannel, event, callback, resolve, reject)
        );
      } else {
        try {
          pusherChannel.bind('pusher:subscription_succeeded', () =>
            this._bindPusherEvent(pusherChannel, event, callback, resolve, reject)
          );
          pusherChannel.bind('pusher:subscription_error', this.pusherOnSubscriptionError);
        } catch (e) {
          console.error('Pusher error:', e);
          reject(e);
        }
      }
    }
  },

  /**
   *
   * @param pusherChannelName
   * @returns {any|void|{subscribed}|Channel}
   */
  subscribeToPusherChannel(pusherChannelName) {
    let channel = window.PusherInstance.channel(pusherChannelName);
    if (channel) {
      if (!channel.subscribed) {
        channel.subscribe();
      }
      return channel;
    }

    return window.PusherInstance.subscribe(pusherChannelName);
  },

  /**
   * @param pusherChannel
   */
  unsubscribePusherChannel(pusherChannel) {
    if (!pusherChannel || !pusherChannel.subscribed) {
      return;
    }

    pusherChannel.unsubscribe();
  },

  /**
   * @param pusherChannel
   * @param eventName
   * @param callback
   * @return PusherChannel|void
   */
  async unbindPusherEvent(pusherChannel, eventName, callback = undefined) {
    if (!pusherChannel) {
      return;
    }

    return await pusherChannel.unbind(eventName, callback);
  },

  /**
   * @param status
   */
  pusherOnSubscriptionError(status) {
    throw new Error('Pusher subscription/authentication failed with status code ' + status);
  },

  /**
   * @param eventName
   * @param callback
   */
  bindToEventOnTeamAndUserChannels(eventName, callback) {
    // Bind to private user channel
    this.bindPusherEvent(this.$root.userChannel, eventName, callback);
    // Bind to private team channels
    this.bindToEventOnTeamChannels(eventName, callback);
  },

  bindToEventOnTeamChannels(eventName, callback) {
    if (typeof this.$root.teamChannel === 'undefined') {
      eventBus.$once('pusher.teamChannels.initialized', () => this.bindToEventOnTeamChannels(eventName, callback));
    } else {
      this.$root.teamChannel.forEach((c) => {
        this.bindPusherEvent(c, eventName, callback);
      });
    }
  },

  /**
   * @param eventName
   */
  unBindToEventOnTeamAndUserChannels(eventName) {
    this.unbindPusherEvent(this.$root.userChannel, eventName);

    if (this.$root.teams) {
      this.$root.teams.forEach((t) => {
        this.unbindPusherEvent(this.$root.teamChannel[t.id], eventName);
      });
    }
  },
};
