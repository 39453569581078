import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4388ed8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "step-one" }
const _hoisted_2 = { class: "step-one__heading" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "must-have" }
const _hoisted_5 = { class: "step-one__info" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "step-one__foot-note" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_key_linear = _resolveComponent("key-linear")!
  const _component_call_received_linear = _resolveComponent("call-received-linear")!
  const _component_pin_check_linear = _resolveComponent("pin-check-linear")!
  const _component_verified_linear = _resolveComponent("verified-linear")!
  const _component_icon_text_list_item = _resolveComponent("icon-text-list-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", {
        innerHTML: _ctx.markupSanitizer(_ctx.$t('whatsapp.before_starting'))
      }, null, 8 /* PROPS */, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('whatsapp.to_get_started_make_sure')), 1 /* TEXT */)
    ]),
    _createElementVNode("ul", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listItemRecord, (item) => {
        return (_openBlock(), _createBlock(_component_icon_text_list_item, {
          key: item.id,
          "bg-color": item.bgColor
        }, {
          icon: _withCtx(() => [
            (item.icon === 'key')
              ? (_openBlock(), _createBlock(_component_key_linear, {
                  key: 0,
                  size: "24"
                }))
              : (item.icon === 'phone')
                ? (_openBlock(), _createBlock(_component_call_received_linear, {
                    key: 1,
                    size: "24"
                  }))
                : (item.icon === 'pin')
                  ? (_openBlock(), _createBlock(_component_pin_check_linear, {
                      key: 2,
                      size: "24"
                    }))
                  : (item.icon === 'verified')
                    ? (_openBlock(), _createBlock(_component_verified_linear, {
                        key: 3,
                        size: "24"
                      }))
                    : _createCommentVNode("v-if", true)
          ]),
          text: _withCtx(() => [
            _createElementVNode("p", {
              innerHTML: _ctx.$t(item.text)
            }, null, 8 /* PROPS */, _hoisted_6)
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bg-color"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("p", {
        innerHTML: _ctx.markupSanitizer(_ctx.$t('whatsapp.turkish_numbers_are_not_supported'))
      }, null, 8 /* PROPS */, _hoisted_8)
    ])
  ]))
}