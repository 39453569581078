import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded-xl bg-grey-200 p-6" }
const _hoisted_2 = { class: "t-text-h5 text-grey-800" }
const _hoisted_3 = { class: "t-text-md mb-0 text-grey-600" }
const _hoisted_4 = { class: "mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_file_input = _resolveComponent("t-file-input")!
  const _component_t_input_text = _resolveComponent("t-input-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('whatsapp.samples_for_body_content')), 1 /* TEXT */),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('whatsapp.whatsapp_requires_you_to_prefill')), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("label", {
        for: "fileInput",
        class: "mb-2 text-base font-semibold text-grey-800"
      }, _toDisplayString(_ctx.$t('whatsapp.template_header_img_label')), 513 /* TEXT, NEED_PATCH */), [
        [_vShow, _ctx.selectedHeaderType === _ctx.TEMPLATE_HEADER_TYPE.IMAGE]
      ]),
      _withDirectives(_createVNode(_component_t_file_input, {
        class: "mb-2",
        "max-file-size": {
          size: _ctx.TEMPLATE_HEADER_FILE_LIMIT_IN_BYTES,
          text: _ctx.$t('whatsapp.image_sample_max_file_size', { fileSize: _ctx.TEMPLATE_HEADER_FILE_LIMIT_IN_BYTES / 1000000 }),
        },
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:image', $event))),
        onReset: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:image', [])))
      }, null, 8 /* PROPS */, ["max-file-size"]), [
        [_vShow, _ctx.selectedHeaderType === _ctx.TEMPLATE_HEADER_TYPE.IMAGE]
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templateVariablesCopy, (variable, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: variable.key,
          class: "mb-2"
        }, [
          _createVNode(_component_t_input_text, {
            id: `variableSample-${variable.key}`,
            modelValue: variable.value,
            "onUpdate:modelValue": ($event: any) => ((variable.value) = $event),
            modelModifiers: { trim: true },
            placeholder: 
            _ctx.$t('whatsapp.type_something_to_replace', {
              variable: variable.key,
            })
          ,
            type: "text",
            label: 
            _ctx.$t('whatsapp.samples_for_body_variable', {
              variable: variable.key,
            })
          ,
            disabled: _ctx.disabled,
            "data-test": "variable-sample-input",
            onInput: ($event: any) => (_ctx.updateTemplateVariable($event, index))
          }, null, 8 /* PROPS */, ["id", "modelValue", "onUpdate:modelValue", "placeholder", "label", "disabled", "onInput"])
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}