<template>
  <div
    class="
      cursor-pointer
      rounded-xl
      border-1 border-grey-300
      bg-white
      shadow-100
      hover:bg-grey-100
      xs:w-[140px]
      md:w-[210px]
      xl:w-[251px]
    "
    :class="{
      '!bg-grey-100': plan.type === selectedPlan,
      'border-leaf-500': plan.type === selectedPlan,
    }"
    @click="$emit('click', plan.type)"
  >
    <div class="flex flex-col gap-2 p-3 xs:h-[190px] md:h-[150px]">
      <t-input-radio
        v-model="selectedPlanId"
        :value="plan.type"
        name="plan"
        class="mb-0"
        @change="$emit('click', plan.type)"
      >
        <span class="text-grey-800">
          {{ plan.title }}
        </span>
      </t-input-radio>
      <div class="text-4xl font-bold text-grey-800">€{{ selectedFrequencyPrice }}</div>
      <div class="t-text-sm text-grey-700">
        Starting at €{{ selectedFrequencyPrice * plan.minimumUsers }}/month with a minimum of {{ plan.minimumUsers }}
        users
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, type PropType } from 'vue';

import type { BillingFrequency, ChargebeePlan, PlanId } from '@/types';


const props = defineProps({
  plan: {
    type: Object as PropType<ChargebeePlan>,
    required: true,
  },
  selectedPlan: {
    type: String as PropType<PlanId>,
    required: true,
  },
  selectedFrequency: {
    type: String as PropType<BillingFrequency | ''>,
    required: true,
  },
});

defineEmits(['click']);
const selectedPlanId = ref(props.selectedPlan);

watch(() => props.selectedPlan, (newVal) => {
  selectedPlanId.value = newVal;
});
const selectedFrequencyPrice = computed(() => {
  return props.plan?.prices.find((item) => item.type === props.selectedFrequency)?.price ?? 0;
});
</script>
