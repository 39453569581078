import { PERMISSION } from '@/Configs/Constants';
import { ROUTE_NAME } from '@/routes/constants';

import type { RouteRecord } from '@/routes/types';

const legacyReportingRoutes: RouteRecord[] = [
  {
    path: '/reports/old',
    component: require('@/components/Reporting/Pages/Availability.vue').default,
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
    name: ROUTE_NAME.REPORTING_LEGACY_DASHBOARD,
  },
  {
    path: '/reports/old/conversations',
    component: require('@/components/Reporting/Pages/Conversations.vue').default,
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
  },
  {
    path: '/reports/old/messages',
    component: require('@/components/Reporting/Pages/Messages.vue').default,
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
  },
  {
    path: '/reports/old/productivity',
    component: require('@/components/Reporting/Pages/Productivity.vue').default,
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
  },
  {
    path: '/reports/old/channels',
    component: require('@/components/Reporting/Pages/Channels.vue').default,
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
  },
  {
    path: '/reports/old/labels',
    component: require('@/components/Reporting/Pages/Labels.vue').default,
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
  },
  {
    path: '/reports/old/users',
    component: require('@/components/Reporting/Pages/Users.vue').default,
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
  },
  {
    path: '/reports/old/export',
    component: require('@/components/Reporting/Pages/Export.vue').default,
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
  },
  {
    path: '/reports/old/time-tracking',
    component: require('@/components/Reporting/Pages/TimeTracking.vue').default,
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
  },
  {
    path: '/reports/old/csats',
    component: require('@/components/Reporting/Pages/Csats.vue').default,
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
  },
];

export default legacyReportingRoutes;
