import { debounce } from 'lodash';
import { reactive } from 'vue';

import { getBreakpoint, handleBreakpointsComparisonObjects } from './breakpointHelpers';

const breakpoints = reactive({
  w: window?.innerWidth,
  h: window?.innerHeight,
  is: getBreakpoint(window?.innerHeight),
  comparisons: handleBreakpointsComparisonObjects(getBreakpoint(window?.innerWidth)),
});

window.addEventListener(
  'resize',
  debounce(() => {
    breakpoints.w = window?.innerWidth;
    breakpoints.h = window?.innerHeight;
    breakpoints.is = getBreakpoint(window?.innerWidth);
    breakpoints.comparisons = handleBreakpointsComparisonObjects(getBreakpoint(window?.innerWidth));
  }, 50),
  false
);

export default breakpoints;
