import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-156eb051"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mb-1 flex items-center"
}
const _hoisted_2 = { class: "t-text-sm m-0 p-0" }
const _hoisted_3 = {
  key: 2,
  "data-test": "header-wrapper"
}
const _hoisted_4 = { class: "m-0 p-0" }
const _hoisted_5 = { class: "flex flex-row items-center justify-between" }
const _hoisted_6 = {
  key: 0,
  class: "t-text-md-emphasize mb-1 truncate text-ellipsis px-2 py-1",
  "data-test": "list-item-title"
}
const _hoisted_7 = { class: "p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_left_linear = _resolveComponent("arrow-left-linear")!
  const _component_t_divider = _resolveComponent("t-divider")!
  const _component_t_list_item = _resolveComponent("t-list-item")!
  const _component_t_badge = _resolveComponent("t-badge")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["h-full w-full pt-4", _ctx.wrapperClass])
  }, [
    (_ctx.showBack)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("button", {
            class: "flex w-full flex-row items-center rounded-md p-2 text-grey-700 transition-colors hover:bg-grey-200 hover:text-grey-900",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$router.push('/admin')), ["prevent"]))
          }, [
            _createVNode(_component_arrow_left_linear, {
              size: "1.3rem",
              class: "mr-2"
            }),
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$tc('general.back_to_settings')), 1 /* TEXT */)
          ])
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.showBack)
      ? (_openBlock(), _createBlock(_component_t_divider, {
          key: 1,
          class: "mb-2 mt-1"
        }))
      : _createCommentVNode("v-if", true),
    (_ctx.hasHeaderListItem)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            _createVNode(_component_t_list_item, {
              label: _ctx.headerListItem.label,
              active: _ctx.headerListItem.isActive,
              size: "sm",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToRoute(_ctx.headerListItem.route)))
            }, {
              prefix: _withCtx(() => [
                _createVNode(_component_arrow_left_linear, { size: "0.938rem" })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label", "active"])
          ]),
          _createVNode(_component_t_divider, { class: "py-1" })
        ]))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listItems, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        "data-test": "items-wrapper"
      }, [
        _createElementVNode("div", _hoisted_5, [
          (item.title)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(item.title), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("ul", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child, childIndex) => {
            return (_openBlock(), _createBlock(_component_t_list_item, {
              key: childIndex,
              size: "sm",
              class: _normalizeClass(["mb-1", { firstItem: childIndex === 0 && !item.title }]),
              label: child.label,
              active: child.isActive,
              tabindex: "0",
              onClick: ($event: any) => (_ctx.goToRoute(child.route))
            }, _createSlots({ _: 2 /* DYNAMIC */ }, [
              (child.badgeCount)
                ? {
                    name: "postfix",
                    fn: _withCtx(() => [
                      _createVNode(_component_t_badge, {
                        class: "flex items-center",
                        text: child.badgeCount
                      }, null, 8 /* PROPS */, ["text"])
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "label", "active", "onClick"]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 2 /* CLASS */))
}