<template>
  <p class="t-text-sm m-0 text-right text-grey-600">{{ time }}</p>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { getHoursMinutesSeconds } from '@/components/Reporting/Util/getHoursMinutesSeconds';

const props = withDefaults(defineProps<{ time: number }>(), {
  time: 0,
});

const time = computed(() => {
  const { hours, minutes, seconds } = getHoursMinutesSeconds(props.time);

  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  } else if (minutes > 0) {
    return `${minutes}m`;
  } else if (seconds > 0) {
    return `${seconds}s`;
  } else {
    return `0s`;
  }
});
</script>
