<template>
  <stat-row>
    <t-card :focusable="true" class="bg-white">
      <div class="flex flex-col items-start" @click="handleRedirectToInsightTicketsPage('created_conversations')">
        <p class="t-text-md m-0 mb-1 flex flex-row items-center">
          <span class="mr-1">{{ $t('reports_v2.created_converstions') }}</span>
          <t-tooltip :text="$t('reports_v2.created_conversations_tooltip')" position="top">
            <info-fill class="text-grey-600" size="1rem" />
          </t-tooltip>
        </p>

        <div v-if="!isLoaded" class="animation-loading mt-4 h-6 w-20 rounded-3xl bg-grey-300"></div>

        <div v-else class="flex w-full flex-row items-center justify-between">
          <p class="t-text-h1 m-0 mt-1 cursor-pointer">
            <span class="t-text-h1 m-0 mt-1 cursor-pointer">{{ showError ? 0 : createdConversations }}</span>
          </p>
        </div>
      </div>
    </t-card>

    <t-card :focusable="true" class="bg-white">
      <div class="flex flex-col items-start" @click="handleRedirectToInsightTicketsPage('closed_conversations')">
        <p class="t-text-md m-0 mb-1 flex flex-row items-center">
          <span class="mr-1">{{ $t('reports_v2.closed_conversations') }}</span>
          <t-tooltip :text="$t('reports_v2.closed_conversations_tooltip')" position="top">
            <info-fill class="text-grey-600" size="1rem" />
          </t-tooltip>
        </p>

        <div v-if="!isLoaded" class="animation-loading mt-4 h-6 w-20 rounded-3xl bg-grey-300"></div>

        <div v-else class="flex w-full flex-row items-center justify-between">
          <p class="t-text-h1 m-0 mt-1 cursor-pointer">
            <span class="t-text-h1 m-0 mt-1 cursor-pointer">{{ showError ? 0 : closedConversations }}</span>
          </p>
        </div>
      </div>
    </t-card>

    <t-card class="bg-white">
      <div class="flex flex-col items-start">
        <p class="t-text-md m-0 mb-1 flex flex-row items-center">
          <span class="mr-1">{{ $t('reports_v2.re_opened_conversations') }}</span>
          <t-tooltip :text="$t('reports_v2.reopened_conversations_tooltip')" position="left">
            <info-fill class="text-grey-600" size="1rem" />
          </t-tooltip>
        </p>

        <div v-if="!isLoaded" class="animation-loading mt-4 h-6 w-20 rounded-3xl bg-grey-300"></div>

        <div v-else class="flex w-full flex-row items-center justify-between">
          <p class="t-text-h1 m-0 mt-1 cursor-pointer">
            <span class="t-text-h1 m-0 mt-1 cursor-pointer">{{ showError ? 0 : reOpenedConversations }}</span>
          </p>
        </div>
      </div>
    </t-card>

    <t-card :focusable="true" class="bg-white">
      <div class="flex flex-col items-start" @click="handleRedirectToInsightTicketsPage('resolution_time')">
        <p class="t-text-md m-0 mb-1 flex flex-row items-center">
          <span class="mr-1">{{ $t('reports_v2.total_resolution_time_title') }}</span>
          <t-tooltip :text="$t('reports_v2.resolution_time_tooltip')" position="right">
            <info-fill class="text-grey-600" size="1rem" />
          </t-tooltip>
        </p>

        <div v-if="!isLoaded" class="animation-loading mt-4 h-6 w-20 rounded-3xl bg-grey-300"></div>

        <div v-else class="flex w-full flex-row items-center justify-between">
          <p class="t-text-h1 m-0 mt-1 cursor-pointer">
            <span class="t-text-h1 m-0 mt-1 cursor-pointer">{{ showError ? '0m 0s' : resolutionTime }}</span>
          </p>
        </div>
      </div>
    </t-card>

    <t-card :focusable="true" class="bg-white">
      <div class="flex flex-col items-start" @click="handleRedirectToInsightTicketsPage('first_response_time')">
        <p class="t-text-md m-0 mb-1 flex flex-row items-center">
          <span class="mr-1">{{ $t('statistics.report_first_response_time') }}</span>
          <t-tooltip :text="$t('reports_v2.first_response_time_tooltip')" position="right">
            <info-fill class="text-grey-600" size="1rem" />
          </t-tooltip>
        </p>

        <div v-if="!isLoaded" class="animation-loading mt-4 h-6 w-20 rounded-3xl bg-grey-300"></div>

        <div v-else class="flex w-full flex-row items-center justify-between">
          <p class="t-text-h1 m-0 mt-1 cursor-pointer">
            <span class="t-text-h1 m-0 mt-1 cursor-pointer">{{ showError ? '0m 0s' : firstResponseTime }}</span>
          </p>
        </div>
      </div>
    </t-card>
  </stat-row>
</template>

<script lang="ts">
import { InfoFill } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import StatRow from '@/components/Reporting/Components/V2/StatRow.vue';
import { convertSecondsToMinutesAndSeconds } from '@/components/Reporting/Util';
import eventBus from '@/eventBus';

import { fetchWorkloadAggregates } from './api';
import { mapFilters } from './utils';

import type { DatePickerDateAndInterval } from '@/components/Reporting/api/types';
import type { Filter } from '@/components/Reporting/Components/V2/FilterDropdown/FilterDropdown.vue';
import type { PropType } from 'vue';
type Data = {
  createdConversations: string;
  closedConversations: string;
  reOpenedConversations: string;
  resolutionTime: string;
  firstResponseTime: string;
  isLoaded: boolean;
  showError: boolean;
  filtersFromRouteQuery: string;
};

export default defineComponent({
  name: 'WorkloadStats',
  components: { InfoFill, StatRow },

  data(): Data {
    return {
      createdConversations: '0',
      closedConversations: '0',
      reOpenedConversations: '0',
      resolutionTime: '0m 0s',
      firstResponseTime: '0m 0s',
      isLoaded: false,
      showError: false,
      filtersFromRouteQuery: '',
    };
  },

  props: {
    filters: {
      type: Object as PropType<DatePickerDateAndInterval>,
      default: () => ({ interval: 'day' }),
    },
  },

  mounted() {
    const filtersExcludeDates = this.excludeDates(
      this.$route.query.filters ? JSON.parse(this.$route.query.filters) : {}
    );
    this.filtersFromRouteQuery = mapFilters(filtersExcludeDates);
    this.getWorloadManagementAggregates();
    eventBus.$on('reporting-filter', this.fetchFilters);
  },

  unmounted() {
    eventBus.$off('reporting-filter', this.fetchFilters);
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        const filtersExcludeDates = this.excludeDates(
          this.$route.query.filters ? JSON.parse(this.$route.query.filters) : {}
        );
        this.filtersFromRouteQuery = mapFilters(filtersExcludeDates);
        this.getWorloadManagementAggregates();
      },
    },
  },

  methods: {
    excludeDates(filters: object) {
      delete filters['startDate'];
      delete filters['endDate'];
      delete filters['interval'];
      return filters;
    },
    fetchFilters(filters: Filter) {
      this.filtersFromRouteQuery = mapFilters(this.excludeDates(filters));
      this.getWorloadManagementAggregates();
    },
    async getWorloadManagementAggregates() {
      try {
        const response = await fetchWorkloadAggregates(
          this.filters.startDate,
          this.filters.endDate,
          this.filtersFromRouteQuery
        );

        this.createdConversations = this.parseNumber(response.data.aggregates.created_tickets);
        this.closedConversations = this.parseNumber(response.data.aggregates.closed_tickets);
        this.reOpenedConversations = this.parseNumber(response.data.aggregates.reopened_tickets);
        this.resolutionTime = convertSecondsToMinutesAndSeconds(response.data.aggregates.average_total_resolution_time);
        this.firstResponseTime = convertSecondsToMinutesAndSeconds(
          response.data.aggregates.average_first_response_time
        );

        this.isLoaded = true;
        this.showError = false;
      } catch (error) {
        console.error(error);
        this.isLoaded = true;
        this.showError = true;
      }
    },

    parseNumber(numberToParse: number) {
      const localString = numberToParse.toLocaleString();
      const numberWithDotSeparator = localString.replace(',', '.');

      return numberWithDotSeparator;
    },

    handleRedirectToInsightTicketsPage(page: string) {
      const parsedFilters = JSON.parse(this.$route.query.filters);

      if (page === 'created_conversations' || page === 'first_response_time') {
        parsedFilters.created_start_date = encodeURIComponent(parsedFilters.startDate);
        parsedFilters.created_end_date = encodeURIComponent(parsedFilters.endDate);
      }
      if (page === 'closed_conversations') {
        parsedFilters.closed_start_date = encodeURIComponent(parsedFilters.startDate);
        parsedFilters.closed_end_date = encodeURIComponent(parsedFilters.endDate);
        parsedFilters.statuses = ['CLOSED'];
      }
      if (page === 'resolution_time') {
        parsedFilters.closed_start_date = encodeURIComponent(parsedFilters.startDate);
        parsedFilters.closed_end_date = encodeURIComponent(parsedFilters.endDate);
      }

      delete parsedFilters.interval;
      delete parsedFilters.startDate;
      delete parsedFilters.endDate;

      this.$router.push({
        name: 'reportingTicketInsightsSpecificPage',
        params: { page },

        query: {
          filters: JSON.stringify(parsedFilters),
        },
      });
    },
  },
});
</script>

<style scoped lang="scss" src="../../../Components/V2/assets/skeleton.scss" />
