<template>
  <entitlements v-if="shouldShowEntitlementsPage" :widget-automations="allAutomations.length" />
  <div v-else class="row-col">
    <div class="white bg b-b">
      <div class="navbar no-radius box-shadow-z1">
        <ul class="nav navbar-nav">
          <li class="nav-item">
            <span class="navbar-item text-md">{{ $t('widget_automation.page_title_widget_automation') }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div id="automation-scroll-container" class="row-inner">
          <div style="display: flex; justify-content: center; width: 100%; min-height: 100%; overflow-x: auto">
            <div class="w-100 mx-32 mt-8">
              <div
                class="white custom-border-radius custom-shadow-outline w-100 overflow-hidden p-0 lg:overflow-visible"
              >
                <div class="relative flex py-2 pl-4 pr-2">
                  <h5 class="mb-0 flex-shrink-0 flex-grow py-2">
                    {{ $t('widget_automation.page_section_title_widget_automations') }}
                  </h5>

                  <button
                    id="dropdownMenuButton"
                    class="btn success ml-auto px-2 py-1 text-white"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="pr-2">+</span>
                    {{ $t('widget_automation.page_button_add_automation') }}
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                    style="overflow-y: auto; left: unset; right: 0.25rem; width: auto"
                  >
                    <a class="dropdown-item" @click.prevent="addGreetingAutomation()">
                      {{ $t('widget_automation.page_add_automation_dropdown_greet_the_website_visitor') }}
                    </a>
                    <a class="dropdown-item" @click.prevent="addFlowbotAutomation()">
                      {{ $t('widget_automation.page_add_automation_dropdown_trigger_a_flowbot') }}
                    </a>
                    <a class="dropdown-item" @click.prevent="addHelpCenterAutomation()">
                      {{ $t('widget_automation.page_add_automation_dropdown_open_article_from_help_center') }}
                    </a>
                  </div>
                </div>

                <div class="filter-bar flex items-center px-4 py-3">
                  <div class="mr-4 flex-shrink-0">
                    <button
                      v-for="availableFilter in availableFilters"
                      class="filter-btn custom-border-radius px-3 py-1"
                      :class="{ active: filter === availableFilter.key }"
                      @click.prevent="filter = availableFilter.key"
                    >
                      <span class="filter-text" v-text="availableFilter.name"></span>
                      <!-- <span class="filter-count">({{ filterAutomations(filterName).length }})</span> -->
                    </button>
                  </div>

                  <select
                    class="form-control ml-auto max-w-md"
                    style="min-width: 40px"
                    @change="automationType = $event.target.value"
                  >
                    <option
                      value="greeting"
                      class="filter-btn custom-border-radius px-3 py-1"
                      :class="{ active: automationType === 'greeting' }"
                    >
                      <span class="filter-text">{{ $t('widget_automation.page_greetings') }}</span>
                    </option>
                    <option
                      value="setFlowbot"
                      class="filter-btn custom-border-radius px-3 py-1"
                      :class="{ active: automationType === 'setFlowbot' }"
                    >
                      <span class="filter-text">{{ $t('widget_automation.page_flowbot_automations') }}</span>
                    </option>
                    <option
                      value="openHelpCenter"
                      class="filter-btn custom-border-radius px-3 py-1"
                      :class="{ active: automationType === 'openHelpCenter' }"
                    >
                      <span class="filter-text">{{ $t('widget_automation.page_help_center_automations') }}</span>
                    </option>
                  </select>
                </div>

                <div style="overflow-x: auto; overflow-y: hidden">
                  <greetings-list
                    v-if="automationType === 'greeting'"
                    :automations="automationsFiltered"
                    @loadMore="loadData"
                  ></greetings-list>
                  <flowbots-list
                    v-if="automationType === 'setFlowbot'"
                    :automations="automationsFiltered"
                    @loadMore="loadData"
                  ></flowbots-list>
                  <help-center-list
                    v-if="automationType === 'openHelpCenter'"
                    :automations="automationsFiltered"
                    @loadMore="loadData"
                  ></help-center-list>
                </div>
              </div>

              <div class="w-100 text-muted mt-2 text-center">
                <small class="text-muted">{{ $t('widget_automation.page_metrics_are_updated_every_4_hours') }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia';

import { FEATURE, PRICING_MODEL } from '@/Configs/Constants';
import { useEntitlementsStore, useSubscriptionStore } from '@/store/pinia';

import Entitlements from './Entitlements';
import FlowbotsList from './FlowbotsList';
import GreetingsList from './GreetingsList';
import HelpCenterList from './HelpCenterList';

export default {
  name: 'Index',
  components: { Entitlements, FlowbotsList, GreetingsList, HelpCenterList },
  data() {
    return {
      page: 0,
      allAutomations: [],
      filter: 'all',
      automationType: 'greeting',
      filters: [
        {
          key: 'all',
          name: this.$t('widget_automation.filter_all'),
        },
        {
          key: 'published',
          name: this.$t('widget_automation.filter_published'),
        },
        {
          key: 'unpublished',
          name: this.$t('widget_automation.filter_unpublished'),
        },
        {
          key: 'scheduled',
          name: this.$t('widget_automation.filter_scheduled'),
        },
        {
          key: 'your-greetings',
          name: this.$t('widget_automation.filter_your_greetings'),
        },
      ],
    };
  },

  watch: {
    availableFilters: {
      handler(val) {
        if (!val.find((filter) => filter.key === this.filter)) {
          this.filter = 'all';
        }
      },
      deep: true,
    },
    automationType: {
      handler() {
        this.resetInfiniteScroll();
      },
    },
  },

  computed: {
    ...mapStores(useEntitlementsStore, useSubscriptionStore),
    automationsFiltered() {
      return this.filterAutomations(this.filter);
    },
    availableFilters() {
      if (this.automationType === 'greeting') {
        return this.filters;
      } else {
        return this.filters.filter((f) => f.key !== 'your-greetings');
      }
    },
    shouldShowEntitlementsPage() {
      return !this.isEntitled && this.subscriptionStore?.subscription.pricingModel === PRICING_MODEL.USAGE_BASED;
    },
    isEntitled() {
      return this.entitlementsStore?.isEntitledTo(
        FEATURE.CUSTOMER_SERVICE__AUTOMATION__WIDGET,
        this.allAutomations.length + 1
      );
    },
  },

  beforeMount() {
    if (this.shouldShowEntitlementsPage) {
      this.loadData();
    }
  },

  methods: {
    resetInfiniteScroll() {
      this.page = 0;
      this.allAutomations = [];
    },

    loadData(scroll) {
      axios
        .get('/api/v2/widget_automation', {
          params: {
            page: this.page + 1,
            type: this.automationType,
          },
        })
        .then((result) => {
          const data = result.data;

          if (!data.data.length) {
            scroll && scroll.complete();
          } else {
            this.allAutomations.push(...data.data);
            this.page = parseInt(data.meta.current_page);

            scroll && scroll.loaded();

            if (data.meta.current_page === data.meta.last_page) {
              scroll && scroll.complete();
            }
          }
        })
        .catch(() => {
          scroll && scroll.complete();
        });
    },

    addGreetingAutomation() {
      this.$router.push('/admin/widget_automation/create/greeting');
    },

    addFlowbotAutomation() {
      this.$router.push('/admin/widget_automation/create/setFlowbot');
    },

    addHelpCenterAutomation() {
      this.$router.push('/admin/widget_automation/create/openHelpCenter');
    },

    moment(time) {
      return moment(time);
    },

    automationIsScheduled(automation) {
      return (
        // is active
        automation.active &&
        // has a widget
        automation.web_widget_id &&
        // has start_date condition
        automation.payload.conditions.some((condition) => {
          return condition.type === 'start_date';
        }) &&
        // all start_date conditions are in the future (should always be only 0-1 start_date condition though)
        automation.payload.conditions.every((condition) => {
          return condition.type !== 'start_date' || moment().isBefore(condition.value);
        })
      );
    },

    automationIsPublished(automation) {
      return automation.active && automation.web_widget_id && !this.automationIsScheduled(automation);
    },

    greetingAction(automation) {
      // todo: cache this
      automation.payload.actions.find((action) => action.type === 'greeting');
    },

    filterAutomations(filter) {
      let automations = this.allAutomations.filter(
        (automation) => !!automation.payload.actions.find((action) => action.type === this.automationType)
      );

      switch (filter) {
        case 'all':
          return automations;
        case 'published':
          return automations.filter((automation) => this.automationIsPublished(automation));
        case 'unpublished':
          return automations.filter((automation) => !automation.active || !automation.web_widget_id);
        case 'scheduled':
          return automations.filter((automation) => this.automationIsScheduled(automation));
        case 'your-greetings':
          return automations.filter((automation) => {
            return (
              this.greetingAction(automation) &&
              this.greetingAction(automation).payload.from.fromType === 'user' &&
              this.greetingAction(automation).payload.from.id === this.$root.user.id
            );
          });
        default:
          return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  border-radius: 15px;
}

.filter-bar {
  background-color: #f9f9f9;
  border-top: 1px solid #dee0e2;
  border-bottom: 1px solid #dee0e2;
}

.filter-btn {
  .filter-text {
    text-transform: capitalize;
    font-weight: bold;
    color: #000;
  }

  .filter-count {
    color: #e1e1e1;
  }

  &.active {
    background-color: #14b29f;

    .filter-text {
      color: #fff;
    }

    .filter-count {
      color: #85f5bf;
    }
  }
}

.custom-shadow-outline {
  box-shadow: rgba(0, 0, 0, 0.11) 0 0 9px 0;
}

.custom-border-radius {
  border-radius: 10px;
}
</style>
