<template>
  <div>
    <div class="flex flex-row items-center justify-between pt-2">
      <t-table-list-cell class="!pl-0">
        <div class="flex flex-row items-center justify-start">
          <div :class="animationClass" class="mr-2 h-8 w-8 rounded-full bg-grey-300" />
          <div :class="animationClass" class="h-2 w-16 rounded-xl bg-grey-300" />
        </div>
      </t-table-list-cell>
      <t-table-list-cell>
        <div class="flex flex-row items-center justify-center">
          <div :class="animationClass" class="h-2 w-16 rounded-xl bg-grey-300" />
        </div>
      </t-table-list-cell>
      <t-table-list-cell>
        <div class="flex flex-row items-center justify-center">
          <div :class="animationClass" class="h-2 w-16 rounded-xl bg-grey-300" />
        </div>
      </t-table-list-cell>
      <t-table-list-cell>
        <div class="flex flex-row items-center justify-center">
          <div :class="animationClass" class="h-2 w-16 rounded-xl bg-grey-300" />
        </div>
      </t-table-list-cell>
      <t-table-list-cell class="!pr-0">
        <div class="flex flex-row items-center justify-end">
          <div class="w-18 flex h-8 items-center justify-between rounded-lg border border-grey-300 px-2 py-3">
            <div :class="animationClass" class="h-2 w-16 rounded-xl bg-grey-300" />
          </div>
        </div>
      </t-table-list-cell>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'SingleRowSkeleton',
  props: {
    animation: {
      default: false,
      type: Boolean,
    },
    showError: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    animationClass() {
      return this.animation ? 'animation-loading' : '';
    },
  },
});
</script>

<style lang="scss" scoped>
@keyframes loading {
  to {
    background-position: 227px 0, 0 0, 0 157px, 23px 135px;
  }
}

.animation-loading {
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(var(--color-grey-300), var(--color-grey-300)),
    radial-gradient(38px circle at 19px 19px, var(--color-grey-300) 50%, transparent 51%),
    linear-gradient(var(--color-grey-300), var(--color-grey-300));
  background-repeat: no-repeat;
  animation: loading 1.5s linear infinite;
}

.empty-state {
  background-image: linear-gradient(0deg, #fff 30%, transparent 100%);
}
</style>
