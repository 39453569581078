<template>
  <div ref="modal" class="modal" role="dialog">
    <div class="modal-dialog" role="document" style="width: 80%; height: 80%">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t('flowbot.playground_playground_conversation') }}
            <template v-if="ticket.id">#{{ ticket.id }}</template>
          </h5>
          <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close-btn">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="">
          <div class="display-flex" style="flex-direction: column">
            <div class="box-header b-b">
              <div class="row">
                <div class="col-sm-6">
                  <h3>
                    {{ $t('flowbot.playground_chat') }}
                    <span v-if="ticket.id" class="text-sm text-grey-600">
                      ({{ ticket.status.substr(0, 1).toUpperCase() + ticket.status.substr(1).toLowerCase() }}
                      <template v-if="ticket.status === 'ASSIGNED'">
                        <template v-if="!ticket.user_id && !ticket.team_id">
                          {{ $t('flowbot.playground_assigned_to_flowbot') }}
                        </template>
                        <template v-else-if="!ticket.user_id && ticket.team_id">
                          {{ $t('flowbot.playground_assigned_to_a_team') }}
                        </template>
                        <template v-else-if="ticket.user_id && !ticket.team_id">
                          {{ $t('flowbot.playground_assigned_to_an_agent') }}
                        </template>
                      </template>
                      )
                    </span>
                  </h3>
                  <small>{{ $t('flowbot.playground_play_with_your_bot_to_see_how_it_flows') }}</small>
                </div>
                <div class="col-sm-6 text-right">
                  <div class="b-a-lightblue pointer valign-center rounded px-3" style="height: 36px" @click="newTicket">
                    <i class="material-icons md-18 mr-1 text-grey-600">replay</i>
                    {{ $t('flowbot.playground_action_start_again') }}
                  </div>
                </div>
              </div>
            </div>
            <div ref="container" class="light dk p-a" style="flex: 1 1 0%; overflow-y: scroll">
              <div style="max-height: 50vh !important">
                <template v-if="!timeline || timeline.length < 1">
                  <div class="my-32 text-center text-grey-600">{{ $t('flowbot.playground_info_send_a_message') }}</div>
                </template>
                <template v-for="message in timeline">
                  <div class="m-b">
                    <div style="max-width: 1100px; margin: 0 auto">
                      <div class="">
                        <div class="clear" :class="{ 'text-right': message.type === 'INBOUND' }">
                          <div class="mb-1 px-1 text-sm text-grey-600">
                            <div v-if="message.type === 'OUTBOUND'">
                              {{ $t('flowbot.playground_message_author_flowbot') }}
                            </div>
                            <div v-else-if="ticket.contact && ticket.contact.full_name">
                              {{ ticket.contact.full_name }}
                            </div>
                            <div v-else>{{ $t('flowbot.playground_message_author_playground_user') }}</div>
                            <datetime class="text-xs" :time="message.created_at" :pretty="$root.prettyDates"></datetime>
                          </div>
                          <div class="inline-flex flex-col" style="max-width: 70%">
                            <div v-if="message.body_type === 'BUTTONS' && message.meta" class="message-container flex">
                              <div class="buttons outbound">
                                <div style="text-align: center; display: block; width: 100%">
                                  <div v-for="button in message.meta.buttons" class="inline">
                                    <span
                                      class="message-button text-success white-overlay pointer"
                                      @click="
                                        () => {
                                          query = button.text;
                                          submit();
                                        }
                                      "
                                    >
                                      {{ button.text }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-else
                              class="rounded-corners chat-message inline rounded text-left"
                              :class="{
                                'p-y-sm px-4': message.body_type !== 'IMAGE' && message.body_type !== 'GIF',
                                'dark-white': message.type === 'OUTBOUND',
                                'b-a-lightblue': message.type === 'OUTBOUND',
                                success: message.type === 'INBOUND',
                                'light-grey': message.type === 'NOTE',
                              }"
                            >
                              <div v-if="message.body_type === 'TEXT'" v-html="getChatMessage(message.message)"></div>
                              <template v-if="message.body_type === 'IMAGE'">
                                <image-message
                                  :path="cdnPath(message.file_name)"
                                  :caption="message.file_caption"
                                  :class="{ 'image-with-parent-message': message.parent }"
                                ></image-message>
                              </template>
                              <div v-if="message.body_type === 'FILE'">
                                <i class="fa fa-paperclip fa-2x pull-left"></i>
                                <span class="pull-left msg-type">
                                  <a target="_blank" :href="cdnPath(message.file_name)" class="open-external">
                                    {{ message.file_name }}
                                  </a>
                                  {{ message.file_caption }}
                                </span>
                              </div>
                              <div v-if="message.body_type === 'AUDIO'">
                                <i class="fa fa-file-audio-o fa-2x pull-left"></i>
                                <span class="pull-left msg-type">
                                  <a target="_blank" :href="cdnPath(message.file_name)" class="open-external">
                                    {{ message.file_name }}
                                  </a>
                                  {{ message.file_caption }}
                                </span>
                              </div>
                              <div v-if="message.body_type === 'VIDEO'">
                                <video :src="cdnPath(message.file_name)" controls style="max-width: 100%"></video>
                                <div v-if="message.file_caption">{{ message.file_caption }}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <div v-show="typing">
                  <div class="white-overlay mb-4 ml-2 inline rounded" style="padding: 7px 5px 9px 12px">
                    <span class="loading">
                      <span class="dot one"></span>
                      <span class="dot two"></span>
                      <span class="dot three"></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer b-t">
              <form @submit.prevent="submit">
                <div class="input-group">
                  <input
                    ref="input"
                    v-model="query"
                    :disabled="ticket.status === 'CLOSED' || ticket.status === 'INVALID' || sending"
                    type="text"
                    :placeholder="$t('flowbot.playground_composer_say_something')"
                    class="form-control has-value"
                  />
                  <span class="input-group-btn">
                    <button type="submit" class="btn white b-a no-shadow btn-icon" style="border-color: #e4e6e8">
                      <i class="material-icons">send</i>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import linkifyStr from 'linkifyjs/string';
import { sortBy, toArray, uniqBy } from 'lodash';

import ImageMessage from './../ImageMessage.vue';
import PusherHelper from '../../mixins/PusherHelper';
import ticketRepository from '../../repositories/Ticket';

export default {
  name: 'FlowBuilderPlayground',
  props: {
    record: {
      type: Object,
      required: true,
    },
    playgroundRef: {
      type: [Object, null],
      required: true,
      default: () => ({}),
    },
  },
  components: {
    ImageMessage,
  },

  mixins: [PusherHelper],

  data() {
    return {
      query: null,
      messages: [],
      typing: false,
      sending: false,
      ticket: {
        id: null,
      },
      channel: {},
      pusherTicketChannel: null,
    };
  },

  computed: {
    timeline() {
      return toArray(
        uniqBy(
          sortBy(
            this.messages.filter((m) => ['BUTTONS', 'TEXT', 'IMAGE', 'FILE', 'VIDEO'].includes(m.body_type)),
            'id'
          ),
          'id'
        )
      );
    },
  },

  mounted() {
    this.initPlayground();
  },

  watch: {
    'ticket.id'(newVal, oldVal) {
      if (oldVal) {
        this.unsubscribeFromTicket(oldVal);
      }
      this.subscribeToTicket(newVal);
    },
  },

  methods: {
    initPlayground(force = false) {
      this.typing = false;
      this.sending = false;
      if (this.ticket.id) {
        this.unsubscribeFromTicket(this.ticket.id);
      }
      axios
        .get('/api/v2/playground', {
          flowbot_id: this.record.id,
          type: 'flowbot',
        })
        .then((res) => {
          if (res.data.ticket) {
            if (!force && this.ticket.id !== null && res.data.ticket.id !== this.ticket.id) {
              // ticket closed
              this.ticket.status = 'CLOSED';
            } else {
              this.ticket = res.data.ticket;
              this.subscribeToTicket(res.data.ticket.id);
              this.messages = res.data.ticket.messages;
              setTimeout(() => {
                this.scrollToBottom();
              }, 100);
            }
          }
        });
    },

    submit() {
      if (!this.query) {
        return null;
      }
      this.sending = true;

      axios
        .post('/api/v2/playground', {
          flowbot_id: this.record.id,
          type: 'flowbot',
          message: this.query,
        })
        .then((res) => {
          if (res.data.ticket.id !== this.ticket.id) {
            this.unsubscribeFromTicket(this.ticket.id);
            this.subscribeToTicket(res.data.ticket.id);
          }
          this.ticket = res.data.ticket;
          this.channel = res.data.channel;
          this.sending = false;
        });

      this.query = '';
      this.scrollToBottom();
      let inputField = this.$refs.input;
      if (inputField) inputField.focus();
    },

    scrollToBottom() {
      this.$nextTick(() => {
        let messageDisplay = this.$refs.container;
        if (messageDisplay) messageDisplay.scrollTop = messageDisplay.scrollHeight;
        this.$refs.input.focus();
      });
    },

    unsubscribeFromTicket(ticket_id) {
      if (this.ticket.id !== ticket_id) {
        this.unsubscribePusherChannel(this.pusherTicketChannel);
        PusherInstance.unsubscribe(CHANNEL_PREFIX + '@conversation-' + ticket_id);
      }
    },

    subscribeToTicket(ticket_id) {
      if (this.pusherTicketChannel === null || this.pusherTicketChannel.name !== 'private-ticket-' + ticket_id) {
        this.pusherTicketChannel = this.subscribeToPusherChannel('private-ticket-' + ticket_id);
        // TODO L remove after transition to private channels
        PusherInstance.subscribe(CHANNEL_PREFIX + '@conversation-' + ticket_id).bind('NEW_MESSAGE', (data) =>
          this.processEventNewMessage(data)
        );
        PusherInstance.subscribe(CHANNEL_PREFIX + '@conversation-' + ticket_id).bind('TYPING', (data) =>
          this.processEventTyping(data)
        );
        PusherInstance.subscribe(CHANNEL_PREFIX + '@conversation-' + ticket_id).bind('RELOAD_TICKET', (data) =>
          this.processEventReloadTicket(data)
        );
        this.bindPusherEvent(this.pusherTicketChannel, 'NEW_MESSAGE', (data) => this.processEventNewMessage(data));
        this.bindPusherEvent(this.pusherTicketChannel, 'TYPING', (data) => this.processEventTyping(data));
        this.bindPusherEvent(this.pusherTicketChannel, 'RELOAD_TICKET', (data) => this.processEventReloadTicket(data));
      }
    },
    processEventNewMessage(data) {
      if (data.type === 'AUDIT') {
        return;
      }
      if (data.message_id) {
        ticketRepository.getMessage(this.ticket.id, data.message_id).then((res) => {
          this.typing = false;
          this.messages.push(res.data);
          setTimeout(() => {
            this.$nextTick(() => {
              this.scrollToBottom(true);
            });
          }, 150);
        });
      } else {
        this.typing = false;
        this.messages.push(data);
        setTimeout(() => {
          this.$nextTick(() => {
            this.scrollToBottom(true);
          });
        }, 150);
      }
    },
    processEventTyping(data) {
      this.typing = true;
      this.$nextTick(() => {
        this.scrollToBottom(true);
      });
      if (data.seconds) {
        setTimeout(() => {
          this.typing = false;
        }, (data.seconds + 1) * 1000);
      }
    },
    processEventReloadTicket(data) {
      this.initPlayground();
    },

    newTicket() {
      axios
        .delete('/api/v2/playground/' + this.ticket.id, {
          flowbot_id: this.record.id,
          type: 'flowbot',
        })
        .then((res) => {
          this.initPlayground(true);
        });
    },

    cdnPath: function (name) {
      return CDN + 'media/' + name;
    },

    getChatMessage: function (msg) {
      if (msg == null) {
        return null;
      }

      msg = window.linkifyString(msg, {
        defaultProtocol: 'http',
        target: msg.indexOf('/tickets/') == -1 ? '_blank' : '_self',
        className: window.isLoadedFromApp ? 'open-external' : '',
      });

      msg = msg.replace(/\n/g, '<br />');

      return msg;
    },
  },

  beforeUnmount() {
    this.unsubscribeFromTicket(this.ticket.id);
    axios.delete('/api/v2/playground/' + this.ticket.id, {
      flowbot_id: this.record.id,
      type: 'flowbot',
    });
    $(this.playgroundRef.$el).modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  },
};
</script>
<style scoped>
.message-button {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 8px;
  margin-left: 8px;
  border: 1px solid #14b29f;
}
.chat-message {
  z-index: 1;
  max-width: none !important;
  min-height: 37px;
}
</style>
