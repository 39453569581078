import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "flex w-full flex-col",
  "data-test": "legacy-integration-dropdowns-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_external_link2_linear = _resolveComponent("external-link2-linear")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_sidebar_dropdown = _resolveComponent("sidebar-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.integrations, (integration) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (integration.visible)
          ? (_openBlock(), _createBlock(_component_sidebar_dropdown, {
              key: integration.id,
              title: integration.name,
              "has-icon": true,
              "collapse-key": `${integration.id}${integration.name.replace(/\s/gm, '')}DropdownItem`,
              "data-test": "legacy-integration-dropdown",
              "is-integration-dropdown": ""
            }, {
              default: _withCtx(() => [
                (_ctx.isAuthenticated(integration))
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent(integration.type), {
                      key: integration.id,
                      plugin: integration,
                      ticket: _ctx.ticket
                    }, null, 8 /* PROPS */, ["plugin", "ticket"]))
                  : (_ctx.requiresOAuth(integration) && !_ctx.isAuthenticated(integration))
                    ? (_openBlock(), _createBlock(_component_t_button, {
                        key: 1,
                        "btn-style": "secondary",
                        class: "mx-auto flex w-fit flex-row items-center",
                        "data-test": "oauth-login-button",
                        onClick: _withModifiers(($event: any) => (_ctx.authenticate(integration)), ["prevent"])
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$tc('auth.login')) + " ", 1 /* TEXT */),
                          _createVNode(_component_external_link2_linear, {
                            class: "ml-3",
                            size: "1.3rem"
                          })
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                    : _createCommentVNode("v-if", true)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "collapse-key"]))
          : _createCommentVNode("v-if", true)
      ], 64 /* STABLE_FRAGMENT */))
    }), 256 /* UNKEYED_FRAGMENT */))
  ]))
}