<template>
  <div class="mx-10 mb-4 mt-4 flex items-center justify-center text-center lg:mt-0">
    <div class="block min-w-0 select-none rounded-lg p-2 py-1 text-sm leading-none text-grey-600">
      <span data-hj-suppress class="text-ellipsis">{{ formattedMessage }}</span>
    </div>
  </div>
</template>

<script type="text/babel">
import { formatDateTime } from '@/util/date';
export default {
  props: {
    message: {},
    iteration: 0,
  },

  computed: {
    dateTimeLabel() {
      return formatDateTime(this.message.created_at);
    },

    formattedMessage() {
      return this.message.message.replace(/[0-9]{2}-[0-9]{2}-[0-9]{4}, [0-9]{2}:[0-9]{2}/, this.dateTimeLabel); // 'audit.date_format' => 'd-m-Y, H:i'
    },
  },
};
</script>
