import { PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';

import type { RouteRecord } from '../types';

const businessHoursRoutes: RouteRecord[] = [
  {
    path: '/admin/company_profile/business_hours',
    redirect: SETTINGS_URL.ADMIN__BUSINESS_HOURS,
  },
  {
    path: '/admin/company_profile/business_hours/*',
    redirect: SETTINGS_URL.ADMIN__BUSINESS_HOURS,
  },
  {
    path: SETTINGS_URL.ADMIN__BUSINESS_HOURS,
    component: require(/* webpackChunkName: "BusinessHoursIndex" */ '../../components/BusinessHours/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__BUSINESS_HOURS__MANAGE },
    children: [
      {
        path: SETTINGS_URL.ADMIN__BUSINESS_HOURS__CREATE,
        component: require(/* webpackChunkName: "BusinessHoursCreate" */ '../../components/BusinessHours/Edit').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__BUSINESS_HOURS__MANAGE },
      },
      {
        path: `${SETTINGS_URL.ADMIN__BUSINESS_HOURS}/:id`,
        component: require(/* webpackChunkName: "BusinessHoursEdit" */ '../../components/BusinessHours/Edit').default,
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__BUSINESS_HOURS__MANAGE },
      },
      {
        path: '',
        component: require(/* webpackChunkName: "AdminIndexBox" */ '../../components/AdminIndexBox').default,
        props: { boxName: 'business_hours' },
        meta: { permissionRequired: PERMISSION.SETTINGS__BUSINESS_HOURS__MANAGE },
      },
    ],
  },
];

export default businessHoursRoutes;
