<template>
  <div class="mb-1 flex items-center justify-between pb-4 pr-6 pt-6">
    <back-header :title="flowName" max-width="40%" @click="handleBackButtonClick" />
    <div class="ml-auto items-center">
      <button
        v-tooltip.top="{
          placement: 'top',
          content: $t('flowbot.button_hover_toggle_full_screen'),
          delay: { show: 500, hide: 0 },
        }"
        onclick="this.blur()"
        class="btn btn-md rounded-lg-i b-black text-muted valign-center hidden-md-down mx-2"
        @click="toggleFullScreen"
      >
        <arrows-expand-linear size="1.2rem" />
      </button>
      <button
        v-tooltip.top="{
          placement: 'bottom',
          content: tooltipContent,
        }"
        onclick="this.blur()"
        class="btn rounded-lg-i b-black text-muted mx-2"
        :class="{
          disabled: !isActive,
        }"
        @click="handleClickPlayGround"
      >
        <i class="material-icons md-18 mr-2" style="vertical-align: -3px">play_arrow</i>
        {{ $t('flowbot.button_playground') }}
      </button>
      <button onclick="this.blur()" class="btn rounded-lg-i b-black text-muted mx-2" @click="handleSave(true)">
        <i class="material-icons md-18 mr-2" style="vertical-align: -3px">filter_none</i>
        {{ $t('flowbot.button_copy') }}
      </button>
      <button onclick="this.blur()" class="btn rounded-lg-i b-black text-muted mx-2" @click="handleRemove()">
        <i class="material-icons md-18 mr-2" style="vertical-align: -3px">delete</i>
        {{ $t('flowbot.button_delete') }}
      </button>
      <button
        onclick="this.blur()"
        :disabled="isSaving"
        :class="{ loader: !loaded || asyncUpdate }"
        class="btn rounded-lg-i success ml-2"
        @click="handleSave()"
      >
        <i class="material-icons md-18 mr-2" style="vertical-align: -3px">save</i>
        {{ $t('flowbot.button_save') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { ArrowsExpandLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import BackHeader from '@/components/common/PageHeaders/BackHeader';
import eventBus from '@/eventBus';

export default defineComponent({
  name: 'EditPageHeader',
  components: {
    ArrowsExpandLinear,
    BackHeader,
  },
  props: {
    showPlayground: {
      type: Function,
      default: () => ({}),
    },
    save: {
      type: Function,
      default: () => ({}),
    },
    remove: {
      type: Function,
      default: () => ({}),
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    asyncUpdate: {
      type: Boolean,
      default: false,
    },
    loaded: {
      type: Boolean,
      default: false,
    },
    flowName: {
      type: String,
      default: '',
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tooltipContent() {
      const buttonHoverText = this.$t('flowbot.button_hover_test_your_flow');
      const activateFlowText = this.$t(
        'flowbot.button_hover_activate_your_flow_before_testing_no_channel_selection_required'
      );
      const isActive = this.isActive;
      let content = buttonHoverText;
      if (!isActive) {
        content += '<br>' + activateFlowText;
      }
      return content;
    },
  },
  methods: {
    handleClickPlayGround() {
      this.showPlayground();
    },
    handleSave(copy = false) {
      this.save(copy);
    },
    handleRemove() {
      this.remove();
    },
    toggleFullScreen() {
      eventBus.$emit('flowbot.editor.fullscreen');
    },
    handleBackButtonClick() {
      this.$router.push('/admin/flows');
      this.$nextTick(() => eventBus.$emit('flowbot.editor.closeFullscreen'));
    },
  },
});
</script>
