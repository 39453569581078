<template>
  <div class="flex h-full min-w-0 flex-1 flex-col pt-4">
    <div class="hidden-md-down w-1/6">
      <search-box v-model="computedSearchTerm" :placeholder="$t('general.searching')" @search="search"></search-box>
    </div>
    <div class="flex flex-shrink-0 items-center pb-4 pl-12 leading-none lg:pl-0 lg:pt-4">
      <div style="width: 45px" class="text-center">
        <i class="material-icons">supervisor_account</i>
      </div>
      <h6 class="mb-0">{{ $t('profile.all_profiles') }}</h6>
      <t-button
        v-if="isMergeFlagEnabled"
        :disabled="selectedIds.length === 0"
        class="ml-4"
        size="sm"
        @click.prevent="isMergeModalOpen = true"
        @close="isMergeModalOpen = false"
      >
        {{ $tc('profile.merge_profiles') }}
      </t-button>
      <t-button class="ml-4" btn-style="secondary" size="sm" @click.prevent="openCreateProfileModal">
        {{ $t('profile.create_profile') }}
      </t-button>
      <i class="material-icons hidden-lg-up ml-auto mr-4 text-grey-600" @click="showSearch = !showSearch">search</i>
    </div>
    <div v-if="showSearch" class="hidden-lg-up mb-4 flex flex-shrink-0 px-4">
      <search-box v-model="computedSearchTerm" :placeholder="$t('general.searching')" @search="search"></search-box>
    </div>
    <div class="scroll-on-hover px-4 lg:pl-0" infinite-wrapper>
      <div
        class="
          hidden-md-down
          mb-2
          flex
          select-none
          items-center
          rounded-lg
          border-2 border-grey-200
          py-3
          text-left text-grey-600
        "
      >
        <div class="flex flex-1 items-center leading-none">
          <span class="w-6" style="margin-left: 20px">
            <i class="material-icons md-16 leading-none">account_circle</i>
          </span>
          <span>{{ $t('profile.profile_name') }}</span>
          <p v-if="isMergeFlagEnabled && selectedIds.length > 0" class="t-text-xs-emphasize my-0 ml-2 py-0">
            {{ $tc('profile.profiles_selected', selectedIds.length, { count: selectedIds.length }) }}
          </p>
        </div>
      </div>
      <div class="min-w-0">
        <div
          v-for="profile in list"
          :key="profile.id"
          class="mb-2 flex flex-row items-center rounded-lg border-2 border-grey-200 pb-1"
        >
          <div class="flex w-full flex-row items-center overflow-hidden">
            <avatar
              class="-ml-1 -mt-1"
              :abbr="profile.abbr"
              :image="profile.profile_image"
              :color="profile.color"
              data-hj-suppress
            />
            <input
              v-if="isMergeFlagEnabled"
              v-model="selectedIds"
              type="checkbox"
              class="ml-2 cursor-pointer"
              :value="profile.id"
            />
            <router-link :to="`/profiles/${profile.id}`" class="ml-2 w-full text-ellipsis">
              {{ profile.full_name || profile.name }}
            </router-link>
          </div>
        </div>
      </div>
      <infinite-loading
        :identifier="listIdentifier"
        direction="bottom"
        force-use-infinite-wrapper="true"
        @infinite="fetchData"
      >
        <template #spinner>
          <div></div>
        </template>
        <template #no-more><div></div></template>
        <template #no-results><div></div></template>
        <template #error>
          <div>
            <t-button @click="fetchData">{{ $tc('voice.try_again') }}</t-button>
          </div>
        </template>
      </infinite-loading>
    </div>
    <create-profile-modal></create-profile-modal>
    <merge-profiles-modal
      v-if="isMergeFlagEnabled && isMergeModalOpen"
      :is-open="isMergeModalOpen"
      :profiles="selectedIds"
      @close="onMergeModalClosed"
    />
  </div>
</template>

<script type="text/babel">
import infiniteLoading from 'vue-infinite-loading';

import MergeProfilesModal from '@/components/Profiles/MergeProfilesModal.vue';
import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

import CreateProfileModal from './CreateProfileModal';
import avatar from '../Avatar.vue';
import SearchBox from '../SearchBox.vue';

export default {
  name: 'ProfilesIndex',
  emits: ['update-search-term'],
  props: {
    searchTerm: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      disableScroll: false,
      page: 0,
      list: [],
      timer: null,
      loading: false,
      listIdentifier: 0,
      showSearch: false,
      isMergeModalOpen: false,
      selectedIds: [],
    };
  },

  components: {
    SearchBox,
    avatar,
    infiniteLoading,
    CreateProfileModal,
    MergeProfilesModal,
  },

  mounted() {
    this.$nextTick(() => this.restoreSearchFromUrl());
  },
  computed: {
    computedSearchTerm: {
      get() {
        return this.searchTerm;
      },
      set(value) {
        this.$emit('update-search-term', value);
      },
    },
    isMergeFlagEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.PROFILE_MERGING);
    },
  },
  methods: {
    fetchData: function (scroll) {
      if (this.page === 0) {
        this.loading = true;
      }
      this.page++;
      this.disableScroll = true;

      return new Promise(() => {
        window
          .singleRequest('get', '/api/v2/profiles', { params: { term: this.computedSearchTerm, page: this.page } })
          .then((result) => {
            result.data.data.forEach((ticket) => this.list.push(ticket));
            this.loading = false;
            if (scroll) {
              this.$nextTick(() => {
                scroll.loaded();
                if (result.data.links.next == null) {
                  scroll.complete();
                }
              });
            }
          });
      });
    },

    restoreSearchFromUrl() {
      if (this.$route.query.term) {
        this.$emit('update-search-term', this.$route.query.term);
      }
    },

    search: function () {
      this.list = [];
      if (this.computedSearchTerm) {
        this.$router.replace({ path: '/profiles', query: { term: this.computedSearchTerm } });
      } else {
        this.$router.replace({ path: '/profiles' });
      }

      this.page = 0;
      this.fetchData(false);
    },
    openCreateProfileModal() {
      $('#CreateProfileModal').modal('show');
      $('#CreateProfileModal input:first').focus();
    },
    onMergeModalClosed(reset = false) {
      this.isMergeModalOpen = false;
      if (reset) {
        this.selectedIds = [];

        this.search();
      }
    },
  },
  watch: {
    searchTerm(newValue) {
      this.computedSearchTerm = newValue;
      this.search();
    },
  },
};
</script>
