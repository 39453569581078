<script type="text/babel">
import eventBus from '@/eventBus';

import Collapse from '../Collapse';
export default {
  components: { Collapse },
  data: function () {
    return {
      channels: [],
      path: '',
      default_channel: null,
      balance: 0,
    };
  },

  mounted() {
    this.fetchChannels();
    this.fetchBalance();

    eventBus.$on('sms.calculate-balance', () => {
      this.fetchBalance();
    });
  },

  methods: {
    fetchBalance() {
      axios.get('/api/v2/wallet/balance').then((res) => {
        this.balance = res.data.balance;
      });
    },

    fetchChannels() {
      this.channels = this.$root.channels.filter((c) => {
        return c.type === 'SMS';
      });

      if (this.channels.length === 0) {
        vue_redirect('/admin/channels2/sms');
        return;
      }

      this.default_channel = this.channels[0].id;
    },
  },

  unmounted() {
    eventBus.$off('sms.calculate-balance');
  },
};
</script>

<template>
  <div class="flex w-full flex-1">
    <template v-if="Object.keys(channels).length > 0">
      <portal to="sidebar">
        <div class="pl-4 pt-2">
          <collapse collapse-key="sms" :default-open="true">
            <template #collapse-title>
              <div>
                <h6 class="font-bold">SMS</h6>
              </div>
            </template>
            <template #collapse-content>
              <div>
                <div>
                  <router-link
                    class="collapse-menu-item flex items-center rounded text-grey-700"
                    active-class="bg-grey-200 text-grey-900"
                    to="/sms/send"
                  >
                    <div style="width: 28px"><i class="far fa-folder md-18"></i></div>
                    {{ $t('sms.quick_send') }}
                  </router-link>
                </div>
                <div>
                  <router-link
                    class="collapse-menu-item flex rounded text-grey-700"
                    active-class="bg-grey-200 text-grey-900"
                    to="/sms/send-bulk"
                  >
                    <div style="width: 28px"><i class="far fa-folder md-18"></i></div>
                    {{ $t('sms.bulk_sms') }}
                  </router-link>
                </div>
                <div>
                  <router-link
                    class="collapse-menu-item flex rounded text-grey-700"
                    active-class="bg-grey-200 text-grey-900"
                    to="/sms/balance"
                  >
                    <div style="width: 28px">
                      <i class="far fa-external-link md-18"></i>
                    </div>
                    <div>
                      <div>
                        {{ $t('sms.upgrade_balance') }}
                        <span class="text-xs text-grey-500">&euro;{{ balance == '' ? 0 : balance }}</span>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </template>
          </collapse>
        </div>
      </portal>
      <div class="flex w-full min-w-0 flex-col">
        <div
          class="
            hidden-lg-up
            flex flex-shrink-0
            items-center
            border-b-2 border-grey-200
            p-4
            pl-16
            leading-none
            lg:ml-4 lg:pl-0
          "
        >
          <div style="height: 30px" class=""></div>
          <h6 class="mb-0 leading-none">SMS</h6>
        </div>
        <div class="scroll-on-hover dark-light flex h-full w-full min-w-0 flex-1 p-4">
          <div class="container">
            <router-view
              :key="$route.fullPath"
              :default_channel="default_channel"
              :channels="channels"
              class="view"
            ></router-view>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style type="text/scss">
.stats-nav .nav-link {
  padding: 15px 20px;
}
</style>
