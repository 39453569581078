<template>
  <div class="inline-flex items-center" @click="triggerStep">
    <div
      class="mr-3 flex h-32px w-32px items-center justify-center rounded-lg"
      :class="!completed && !active ? 'bg-grey-400' : 'bg-leaf-600'"
    >
      <span class="flex h-16px w-16px items-center justify-center rounded-full bg-grey-100">
        <slot name="indicator" />
      </span>
    </div>
    <div class="flex items-center">
      <slot name="title" />
      <slot name="separator" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TStepIndicator',
  emits: ['triggerStep'],
  props: {
    step: {
      type: Number,
      default: 1,
    },
    active: {
      type: Boolean,
      default: true,
    },
    completed: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    triggerStep() {
      if (this.completed) {
        this.$emit('triggerStep', this.step);
      }
    },
  },
};
</script>
