<template>
  <div class="input-group bootstrap-timepicker">
    <input ref="input" type="text" class="form-control time-indicator input-timepicker24" />
    <span class="input-group-btn">
      <a href="javascript:void(0)" class="btn bg-grey-600 text-white"><i class="fa fa-clock-o"></i></a>
    </span>
  </div>
</template>

<script type="text/babel">
export default {
  name: 'TimePicker',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  mounted() {
    $(this.$refs.input)
      .val(this.modelValue)
      .timepicker({ defaultTime: false, minuteStep: 15, showSeconds: false, showMeridian: false })
      .on('changeTime.timepicker', (e) => {
        this.$emit('update:modelValue', e.time.value);
      });
  },
};
</script>
