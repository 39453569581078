<template>
  <a class="open-external select-none" :href="mediaUrl" target="_blank">
    <slot name="header" />
    <div class="border-l-2 border-grey-600 pl-2">
      <template v-if="!error">
        <div>
          <video
            v-if="fileContentType.startsWith('video') && !videoThumbnailUrl"
            :src="computedGetImageThumbnailMediaUrl"
            ref="video"
            class="media-video"
          ></video>
          <i class="icon-spinner fa fa-spin fa-spinner" v-show="!loaded"></i>
        </div>
        <div
          v-if="
            fileContentType.startsWith('image') ||
            videoThumbnailUrl ||
            (!fileContentType.startsWith('video') && mediaUrl)
          "
          :style="loaded ? 'background-image:url(' + computedGetImageThumbnailMediaUrl + ')' : ''"
          class="media-image"
        >
          <i class="icon-spinner fa fa-spin fa-spinner" v-show="!loaded"></i>
        </div>
      </template>
      <div
        v-if="error"
        class="error-message flex items-center justify-center text-center text-sm font-semibold text-white"
      >
        <slot name="errorMessage" />
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    mediaUrl: {
      type: String,
      default: '',
    },
    videoThumbnailUrl: {
      type: String,
      default: '',
    },
    fileContentType: {
      type: String,
      default: '',
    },
  },

  computed: {
    computedGetImageThumbnailMediaUrl() {
      return this.videoThumbnailUrl ? this.videoThumbnailUrl : this.mediaUrl;
    },
  },

  data() {
    return {
      loaded: false,
      error: false,
    };
  },

  mounted() {
    if (this.fileContentType?.startsWith('video')) {
      if (isIphoneDevice()) {
        this.$refs.video.autoplay = true;
        this.$refs.video.muted = true;
      }
      this.$refs.video.onloadeddata = () => {
        this.loaded = true;
      };
      this.$refs.video.onerror = () => {
        this.error = true;
      };
      return;
    }
    this.imageStatus();
  },
  methods: {
    imageStatus() {
      $('<img/>')
        .attr('src', this.computedGetImageThumbnailMediaUrl)
        .on('load', () => {
          $(this).remove(); // prevent memory leaks as @benweet suggested
          this.loaded = true;
        })
        .on('error', () => {
          this.loaded = true;
          this.error = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.media-image {
  background-size: cover;
  background-position: center;
  border: 1px solid #828282;
  border-radius: 22px;
  overflow: hidden;
  vertical-align: middle;
  width: 190px;
  height: 340px;
}
.media-video {
  max-width: 100%;
  height: 340px;
  border-radius: 22px;
}
.error-message {
  width: 190px;
  height: 340px;
  border-radius: 22px;
  background: grey;
}
.icon-spinner {
  font-size: 20px;
}
</style>
