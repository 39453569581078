import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-96" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_whatsapp_sandbox_modal = _resolveComponent("whatsapp-sandbox-modal", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_whatsapp_sandbox_modal, {
      modelValue: $options.computedValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.computedValue) = $event)),
      onOpen: $options.handleOpen,
      onClose: $options.handleClose,
      onProcessCompleted: $options.handleProcessCompleted
    }, null, 8 /* PROPS */, ["modelValue", "onOpen", "onClose", "onProcessCompleted"])
  ]))
}