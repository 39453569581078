<template>
  <t-table-list-row class="table-fixed">
    <t-table-list-cell>
      <broadcast-list-content-loader class="bg-grey-400" max-width="7.5rem" />
    </t-table-list-cell>
    <t-table-list-cell>
      <broadcast-list-content-loader class="bg-grey-300" max-width="14.375rem" />
    </t-table-list-cell>
    <t-table-list-cell>
      <broadcast-list-content-loader class="bg-grey-400" max-width="4rem" />
    </t-table-list-cell>
    <t-table-list-cell>
      <broadcast-list-content-loader class="bg-grey-400" max-width="7.5rem" />
    </t-table-list-cell>
    <t-table-list-cell>
      <broadcast-list-content-loader class="bg-grey-300" max-width="3.5rem" />
    </t-table-list-cell>
    <t-table-list-cell>
      <broadcast-list-content-loader class="bg-grey-300" max-width="1.875rem" />
    </t-table-list-cell>
    <t-table-list-cell>
      <broadcast-list-content-loader class="bg-grey-300" max-width="3rem" />
    </t-table-list-cell>
    <t-table-list-cell>
      <broadcast-list-content-loader class="bg-grey-300" max-width="1.5rem" />
    </t-table-list-cell>
  </t-table-list-row>
</template>

<script>
import BroadcastListContentLoader from '@/components/WabBroadcasting/components/BroadcastListContentLoader';

export default {
  name: 'BroadcastListLoader',
  components: { BroadcastListContentLoader },
};
</script>
