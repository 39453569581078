<template>
  <div data-test="message-box" class="mb-3 rounded-lg p-3 xs2:w-60 sm:w-64">
    <p class="t-text-sm mb-0 text-grey-700">{{ message }}</p>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MessageBox',
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  components: {},
  methods: {},
});
</script>
