<template>
  <canvas ref="report" height="200" class="h-full"></canvas>
</template>

<script lang="ts">
import Chart from 'chart.js/auto';
import { map } from 'lodash';
import { defineComponent } from 'vue';

import {
  WorkloadManagementClosedConversationsMockData,
  WorkloadManagementCreatedConversationsMockData,
} from '@/components/Reporting/api/mocks';

import { getWorkloadManagementChartConfiguration } from './utilities';
import { getDayOfWeek } from '../utilities';

import type { WorkloadManagementBucket } from '@/components/Reporting/api/types';
import type { ChartConfiguration, ChartData } from 'chart.js/auto';

type Data = {
  chartInstance?: ChartConfiguration;
  createdConversations: WorkloadManagementBucket[];
  closedConversations: WorkloadManagementBucket[];
  data?: ChartData;
};

export default defineComponent({
  name: 'WorkloadManagementLoaded',

  chartInstance: undefined,
  data(): Data {
    return {
      createdConversations: WorkloadManagementCreatedConversationsMockData.data.buckets,
      closedConversations: WorkloadManagementClosedConversationsMockData.data.buckets,
      data: undefined,
    };
  },

  mounted() {
    this.setChart();
  },

  computed: {
    chartConfiguration() {
      return getWorkloadManagementChartConfiguration((key, param) => this.$t(key, param), false, true);
    },

    datasets() {
      return [
        {
          label: this.$t('reports_v2.created_converstions'),
          data: map(this.createdConversations, (bucket: WorkloadManagementBucket) => {
            return bucket.value;
          }),
          borderWidth: 4,
          backgroundColor: '#E1E3E5',
          borderColor: '#E1E3E5',
          pointBorderColor: 'white',
          pointRadius: 0,
        },
        {
          label: this.$t('reports_v2.closed_conversations'),
          data: map(this.closedConversations, (bucket: WorkloadManagementBucket) => {
            return bucket.value;
          }),
          borderWidth: 4,
          backgroundColor: '#E1E3E5',
          borderColor: '#E1E3E5',
          pointBorderColor: 'white',
          pointRadius: 0,
        },
      ];
    },
  },

  methods: {
    setChart() {
      this.mapData();
      this.drawChart();
    },

    mapData() {
      const currentDataset = this.createdConversations;

      this.data = {
        labels: map(currentDataset, (value: WorkloadManagementBucket) => {
          return this.dayOfWeek(value.timestamp);
        }),
        datasets: this.datasets,
      };
    },

    drawChart() {
      const chart = this.$refs.report.getContext('2d');

      const options = {
        onclick: this.handleClickOnBar,
        responsive: true,
        maintainAspectRatio: false,
        scales: this.chartConfiguration.scales,
        plugins: this.chartConfiguration.plugins,
      };

      this.chartInstance = new Chart(chart, {
        type: 'line',
        data: this.data,
        options,
      });
    },

    dayOfWeek(timestamp: number) {
      return getDayOfWeek((key) => this.$t(key), timestamp);
    },
  },
});
</script>
