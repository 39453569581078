<template>
  <draggable v-bind="$attrs" class="draggable-container">
    <slot />
  </draggable>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next';

export default {
  components: {
    draggable: VueDraggableNext,
  },
};
</script>
