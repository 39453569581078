<template>
  <div class="mx-6 mb-5 rounded-xl border-1 bg-black px-6 py-5">
    <div class="flex items-center gap-4">
      <t-thumbnail class="bg-grey-800">
        <file-invoice-linear color="white" />
      </t-thumbnail>
      <div class="flex-grow text-white">
        <div class="t-text-h5 font-bold">{{ $t('quote.banner_title') }}</div>
        <div class="t-text-sm">{{ $t('quote.banner_subtitle') }}</div>
      </div>
      <t-button
        size="sm"
        btn-style="secondary"
        class="flex flex-nowrap items-center"
        @click="$emit('open-quote-modal')"
      >
        {{ $t('quote.banner_view') }}
        <chevron-right-linear size="1.2rem" class="ml-1" />
      </t-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ChevronRightLinear, FileInvoiceLinear } from '@trengo/trengo-icons';
</script>
