<template>
  <div>
    <t-segmented-control
      v-if="shouldShowSegmentedControl"
      size="sm"
      :active-index="activeIndex"
      :segments="segments"
      @change="switchSegment"
    />

    <t-inline-banner v-else-if="tabsHaveErrors" type="default">
      <template #icon>
        <info-linear />
      </template>

      <p class="t-text-sm-emphasize m-0 p-0">
        {{ $t('integration_hub.company_required_for_opportunities', { name: installation.name }) }}
      </p>
    </t-inline-banner>

    <div class="flex max-h-80 w-full flex-col overflow-y-auto">
      <integration-crm-contacts
        v-show="activeSegment === 'Contacts'"
        :installation="installation"
        :ticket="ticket"
        :selected="activeSegment === 'Contacts'"
        @set-company-id="setCompanyId"
        @set-error="setTabError"
      />

      <integration-crm-opportunities
        v-show="shouldShowOpportunities && activeSegment === 'Opportunities'"
        :selected="activeSegment === 'Opportunities'"
        :installation="installation"
        :ticket="ticket"
        :company-id="companyId"
      />

      <integration-crm-leads
        v-show="shouldShowLeads && activeSegment === 'Leads'"
        :selected="activeSegment === 'Leads'"
        :installation="installation"
        :ticket="ticket"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { InfoLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import { areLeadsSupported } from '@/util/integrationHub/leadSupport';
import { areOpportunitiesSupported, isCompanyFilteringSupported } from '@/util/integrationHub/opportunitySupport';

import IntegrationCrmContacts from './IntegrationCrmContacts.vue';
import IntegrationCrmLeads from './IntegrationCrmLeads.vue';
import IntegrationCrmOpportunities from './IntegrationCrmOpportunities.vue';

import type { InstalledIntegration } from '@/store/types/integrations';
import type Ticket from '@/types/tickets';
import type { PropType } from 'vue';

type Segment = {
  label: string;
  value: string;
};

type ActiveSegment = {
  index: number;
  segment: Segment;
};

export default defineComponent({
  name: 'IntegrationCrm',

  components: {
    IntegrationCrmLeads,
    IntegrationCrmContacts,
    IntegrationCrmOpportunities,
    InfoLinear,
  },

  props: {
    installation: {
      type: Object as PropType<InstalledIntegration>,
      default: () => ({}),
    },

    ticket: {
      type: Object as PropType<Ticket>,
      default: () => ({}),
    },
  },

  data() {
    return {
      activeIndex: 0,
      activeSegment: 'Contacts',

      baseSegments: [
        { label: 'Contacts', value: 'Contacts' },
        { label: 'Opportunities', value: 'Opportunities' },
        { label: 'Leads', value: 'Leads' },
      ],

      companyId: 0,

      tabErrors: {
        contacts: false,
        opportunities: false,
        leads: false,
      },
    };
  },

  computed: {
    shouldShowOpportunities(): boolean {
      return (
        areOpportunitiesSupported(this.installation.service_id) &&
        isCompanyFilteringSupported(this.installation.service_id) &&
        this.companyId !== 0
      );
    },

    shouldShowLeads(): boolean {
      return areLeadsSupported(this.installation.service_id);
    },

    // Filter segments based on supported features
    segments(): Array<{ label: string; value: string }> {
      return this.baseSegments.filter((segment: { label: string; value: string }) => {
        if (segment.value === 'Opportunities') {
          return this.shouldShowOpportunities;
        }
        if (segment.value === 'Leads') {
          return this.shouldShowLeads;
        }

        return true; // Always show Contacts
      });
    },

    shouldShowSegmentedControl(): boolean {
      return this.shouldShowOpportunities || this.shouldShowLeads;
    },

    tabsHaveErrors(): boolean {
      return Object.values(this.tabErrors).includes(true);
    },
  },

  methods: {
    switchSegment(activeSegment: ActiveSegment) {
      this.activeIndex = activeSegment.index;
      this.activeSegment = activeSegment.segment.value;
    },

    setCompanyId(companyId: number) {
      this.companyId = companyId;
    },

    setTabError(tab: 'contact' | 'opportunities', hasError: boolean) {
      this.tabErrors[tab] = hasError;
    },
  },
});
</script>
