<template>
  <t-action-menu
    v-model="isMenuOpen"
    position="left"
    fade
    width="max-content"
    :trigger-on-click="false"
    data-test="delete-contact-group-menu"
    @click.prevent="handleClick"
    @keyup.enter="handleClick"
  >
    <template #trigger>
      <slot name="trigger">
        <t-icon-button
          size="md"
          class="rounded-none"
          :class="{ 'bg-grey-300': isMenuOpen }"
          data-test="delete-contact-group-tirgger"
        />
      </slot>
    </template>
    <template #content>
      <div class="flex flex-col">
        <slot name="menu-content">
          <t-list-item is-delete size="sm" :label="$t('rules.delete_button')" @click.once="handleDelete">
            <template #prefix>
              <trash-linear size="1.25rem" />
            </template>
          </t-list-item>
        </slot>
      </div>
    </template>
  </t-action-menu>
</template>

<script lang="ts" setup>
import { TrashLinear } from '@trengo/trengo-icons';
import { ref, onMounted, onUnmounted } from 'vue';

const isMenuOpen = ref(false);
const emit = defineEmits(['delete']);
const props = defineProps<{
  id: number;
}>();

onMounted(() => {
  props.id && window.eventBus.$on('listRowActionMenuClickOutSide', clickOutSide);
});

onUnmounted(() => {
  props.id && window.eventBus.$off('listRowActionMenuClickOutSide', clickOutSide);
});

const clickOutSide = (id: number) => {
  if (id !== props.id) {
    close();
  }
};

const close = () => {
  isMenuOpen.value = false;
};

const handleClick = (e: Event) => {
  props.id && window.eventBus.$emit('listRowActionMenuClickOutSide', props.id);
  if (e) {
    e.stopPropagation();
  }
  isMenuOpen.value = !isMenuOpen.value;
};

const handleDelete = () => {
  emit('delete');
};
</script>
