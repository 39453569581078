import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_step_zero = _resolveComponent("step-zero")!
  const _component_step_one = _resolveComponent("step-one")!
  const _component_step_two = _resolveComponent("step-two")!

  return (_ctx.currentStep === 'disclaimer')
    ? (_openBlock(), _createBlock(_component_step_zero, {
        key: 0,
        onGoToStep: _ctx.handleTransition
      }, null, 8 /* PROPS */, ["onGoToStep"]))
    : (_ctx.currentStep === 'scanQRCode')
      ? (_openBlock(), _createBlock(_component_step_one, {
          key: 1,
          qr: _ctx.qr,
          secret: _ctx.secret,
          onGoToStep: _ctx.handleTransition
        }, null, 8 /* PROPS */, ["qr", "secret", "onGoToStep"]))
      : (_ctx.currentStep === 'enterVerificationCode')
        ? (_openBlock(), _createBlock(_component_step_two, {
            key: 2,
            onGoToStep: _ctx.handleTransition
          }, null, 8 /* PROPS */, ["onGoToStep"]))
        : _createCommentVNode("v-if", true)
}