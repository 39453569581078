<template>
  <t-modal
    v-model="requiredModalOpen"
    :title="$t('custom_fields.required_fields')"
    :subheader="$t('custom_fields.required_fields_desc')"
    variant="narrow"
    @update:modelValue="closeFromModal"
    @close="cancel"
  >
    <form>
      <custom-field-input-group
        v-for="(item, key) in fields"
        :key="key"
        :title="`${capitalize(key)} fields`"
        class="mb-4"
      >
        <div v-for="field in item" :key="field.id" class="required-input-group relative pb-2">
          <label class="required-input-label">
            {{ field.title }}
            <span class="req text-xs font-normal">{{ $t('custom_fields.required') }}</span>
          </label>
          <custom-field-item
            :field="field"
            :error="!!getError(field.id).length && dirty"
            @save="save(field)"
            @blur="dirty = true"
          />
          <t-input-errors :errors="dirty ? getError(field.id) : []" />
        </div>
      </custom-field-input-group>
    </form>
    <template #footer>
      <t-button btn-style="secondary" @click="cancel">
        {{ $t('general.cancel') }}
      </t-button>

      <t-button :disabled="!isValid || !serverSuccess" @click="close">
        {{ $t('tickets.close_ticket') }}
      </t-button>
    </template>
  </t-modal>
</template>

<script>
import { capitalize, cloneDeep, lowerCase } from 'lodash';
import { mapGetters } from 'vuex';

import {
  storeContactCustomField,
  storeProfileCustomField,
  storeTicketCustomField,
} from '@/components/TicketSidebar/Api';
import CustomFieldInputGroup from '@/components/TicketSidebar/CustomFieldInputGroup';
import CustomFieldItem from '@/components/TicketSidebar/CustomFieldItem';
import ticketSidebarMixins from '@/components/TicketSidebar/ticketSidebarMixins';
import { CUSTOM_FIELD } from '@/Configs/Constants';
import eventBus from '@/eventBus';

export default {
  name: 'RequiredCustomFieldModal',
  emits: ['update:modelValue', 'close', 'cancel'],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [ticketSidebarMixins],
  data() {
    return {
      fields: {},
      dirty: true,
      serverSuccess: false,
    };
  },
  components: {
    CustomFieldItem,
    CustomFieldInputGroup,
  },
  watch: {
    emptyFields: {
      handler(newValue) {
        this.fields = cloneDeep(newValue);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      emptyFields: 'customFields/requiredEmptyFields',
    }),
    requiredModalOpen: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    errors() {
      let errors = [];
      Object.keys(this.fields).forEach((key) => {
        this.fields[key].forEach((field) => {
          if (!field.value) {
            errors.push({
              field: field.id,
              text: this.$t('custom_fields.required_field_modal_custom_field_title_is_required', {
                customfield: field.title,
              }),
              dangerIcon: true,
            });
          }

          if (field.errors?.length) {
            errors = [
              ...errors,
              ...field.errors.map((error) => {
                return {
                  field: field.id,
                  text: error,
                  dangerIcon: true,
                };
              }),
            ];
          }
        });
      });

      return errors;
    },
    isValid() {
      return !this.errors.length;
    },
  },
  methods: {
    capitalize,
    async save({ type, id, value, profileId, ticketId, contactId }) {
      const { PROFILE, TICKET, CONTACT } = CUSTOM_FIELD;

      try {
        if (type === PROFILE) {
          await storeProfileCustomField(profileId, id, value);
        } else if (type === TICKET) {
          await storeTicketCustomField(ticketId, id, value);
        } else if (type === CONTACT) {
          await storeContactCustomField(contactId, id, value);
        }

        this.resetFieldServerError(lowerCase(type), id);
        this.serverSuccess = true;
      } catch (e) {
        this.serverSuccess = false;
        this.setFieldServerError(lowerCase(type), id, e.response?.data?.errors);
      }
    },
    getError(id) {
      return this.errors.filter((item) => item.field === id);
    },
    close() {
      this.dirty = true;
      if (this.isValid) {
        this.requiredModalOpen = false;
        this.$emit('close', true);
      }
    },
    cancel() {
      this.requiredModalOpen = false;
      this.$emit('cancel');
      eventBus.$emit('ticket.current.reload');
    },
    closeFromModal(val) {
      if (!val) {
        eventBus.$emit('ticket.current.reload');
      }
    },
  },
};
</script>

<style scoped lang="scss" src="./RequiredCustomFieldModal.scss" />
