<template>
  <div class="flex items-center border-none">
    <div class="circle mr-2" :class="{ 'mr-4': !show_label }"></div>
    <div class="active-label" v-if="show_label">
      {{ is_active ? $t('csat.active') : $t('csat.inactive') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActiveIndicator',
  props: {
    is_active: {
      type: Boolean,
      default: false,
    },
    show_label: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.inactive {
  &:not(.buttons-container) {
    color: var(--color-grey-600);
  }
  .circle {
    background: var(--color-grey-600);
  }
}
.circle {
  width: 8px;
  height: 8px;
  background: var(--color-success-500);
}
.active-label {
  font-weight: 600;
  font-size: 14px;
}
</style>
