<template>
  <div
    :tabindex="searchable ? -1 : tabindex"
    :class="{ 'multiselect--active': isOpen, 'multiselect--disabled': disabled, 'multiselect--above': isAbove }"
    class="multiselect"
    @focus="activate()"
    @blur="searchable ? false : deactivate()"
    @keydown.self.down.prevent="pointerForward()"
    @keydown.self.up.prevent="pointerBackward()"
    @keydown.enter.tab.stop.self="addPointerElement($event)"
    @keyup.esc="deactivate()"
  >
    <slot name="caret" :toggle="toggle">
      <div class="multiselect__select" @mousedown.prevent.stop="toggle()"></div>
    </slot>
    <slot name="clear" :search="search"></slot>
    <form autocomplete="off">
      <div ref="tags" class="multiselect__tags">
        <div v-show="visibleValues.length > 0" class="multiselect__tags-wrap">
          <template v-for="option of visibleValues" @mousedown.prevent>
            <slot name="tag" :option="option" :search="search" :remove="removeElement">
              <span class="multiselect__tag">
                <span v-text="getOptionLabel(option)"></span>
                <i
                  aria-hidden="true"
                  tabindex="1"
                  class="multiselect__tag-icon"
                  @keydown.enter.prevent="removeElement(option)"
                  @mousedown.prevent="removeElement(option)"
                ></i>
              </span>
            </slot>
          </template>
        </div>
        <template v-if="internalValue && internalValue.length > limit">
          <strong class="multiselect__strong" v-text="limitText(internalValue.length - limit)"></strong>
        </template>
        <transition name="multiselect__loading">
          <slot name="loading"><div v-show="loading" class="multiselect__spinner"></div></slot>
        </transition>
        <input
          v-if="searchable"
          :id="id"
          ref="search"
          :name="name"
          type="text"
          autocomplete="false"
          :placeholder="placeholder"
          :value="isOpen ? search : currentOptionLabel"
          :disabled="disabled"
          :tabindex="tabindex"
          class="multiselect__input"
          @input="updateSearch($event.target.value)"
          @focus.prevent="activate()"
          @blur.prevent="deactivate()"
          @keyup.esc="deactivate()"
          @keydown.down.prevent="pointerForward()"
          @keydown.up.prevent="pointerBackward()"
          @keydown.enter.prevent.stop.self="addPointerElement($event)"
          @keydown.delete.stop="removeLastElement()"
        />
        <span
          v-if="!searchable"
          class="multiselect__single"
          @mousedown.prevent="toggle"
          v-text="currentOptionLabel"
        ></span>
      </div>
    </form>

    <transition name="multiselect">
      <div
        v-show="isOpen"
        ref="list"
        class="multiselect__content-wrapper-voip"
        :style="{ maxHeight: optimizedHeight + 'px' }"
        @focus="activate"
        @mousedown.prevent
      >
        <ul class="multiselect__content" :style="contentStyle">
          <slot name="beforeList"></slot>
          <li v-if="multiple && max === internalValue.length">
            <span class="multiselect__option">
              <slot name="maxElements">
                Maximum of {{ max }} options selected. First remove a selected option to select another.
              </slot>
            </span>
          </li>
          <template v-if="!max || internalValue.length < max">
            <li v-for="(option, index) of filteredOptions" :key="index" class="multiselect__element">
              <span
                v-if="!(option && (option.$isLabel || option.$isDisabled))"
                :class="optionHighlight(index, option)"
                :data-select="option && option.isTag ? tagPlaceholder : selectLabelText"
                :data-selected="selectedLabelText"
                :data-deselect="deselectLabelText"
                class="multiselect__option"
                @click.stop="select(option)"
                @mouseenter.self="pointerSet(index)"
              >
                <slot name="option" :option="option" :search="search">
                  <span>{{ getOptionLabel(option) }}</span>
                </slot>
              </span>
              <span
                v-if="option && (option.$isLabel || option.$isDisabled)"
                :class="optionHighlight(index, option)"
                class="multiselect__option multiselect__option--disabled"
              >
                <slot name="option" :option="option" :search="search">
                  <span>{{ getOptionLabel(option) }}</span>
                </slot>
              </span>
            </li>
          </template>
          <li v-show="showNoResults && filteredOptions.length === 0 && search && !loading">
            <span class="multiselect__option">
              <slot name="noResult">No elements found. Consider changing the search query.</slot>
            </span>
          </li>
          <slot name="afterList"></slot>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script type="text/babel">
import multiSelect from 'vue-multiselect';

import eventBus from '@/eventBus';
export default {
  name: 'MultiselectVoip',
  emits: ['disable-search', 'close', 'input', 'search-change', 'update:model-value'],
  extends: multiSelect,

  mounted() {
    this.$nextTick(() => {
      this.$refs.search.focus();
    });

    eventBus.$on('voip.key-pressed', (data) => {
      if (data.key === 'dial') {
        eventBus.$emit('voip.call', {
          phone: this.search,
        });
      } else {
        this.$emit('disableSearch');
        let key = '' + data.key;
        this.isOpen = true;
        this.search += key;
      }
    });
  },

  unmounted() {
    eventBus.$off('voip.key-pressed');
  },
};
</script>

<style scoped>
.multiselect__content-wrapper-voip {
  position: absolute;
  display: block;
  width: 100%;
  z-index: 999999999 !important;
  background: black;
}
</style>
