<script type="text/babel">
export default {
  name: 'Select2',
  emits: ['update:modelValue'],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: [String, Array, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Select an option',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    allowClear: {
      type: Boolean,
      default: true,
    },
    template: {
      type: Function,
      default: null,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
  },

  mounted: function () {
    var vm = this;
    $(this.$el)
      .val(this.modelValue)
      .select2(this.getOptions())
      .on('change', function () {
        if ($(this).val()) {
          vm.$emit('update:modelValue', $(this).val());
        }
      });
  },

  watch: {
    options: function (options) {
      var opts = this.getOptions();

      if (this.allowClear == 'false') {
        opts.allowClear = false;
      }

      $(this.$el).val(this.modelValue).select2(opts);

      var e = this.$el;
      var v = this.modelValue;

      setTimeout(() => {
        $(e).val(v).trigger('change');
      }, 10);
    },

    modelValue(v) {
      $(this.$el).val(v).select2(this.getOptions());
    },
  },

  unmounted: function () {
    try {
      // $(this.$el).off().select2('destroy');
    } catch (e) {}
  },

  methods: {
    getOptions() {
      let opts = {
        multiple: this.multiple,
        allowClear: this.allowClear,
        placeholder: this.placeholder,
        minimumResultsForSearch: this.searchable ? 0 : Infinity,
      };

      if (this.template != null) {
        opts.templateResult = this.template;
      }

      return opts;
    },
  },
};
</script>

<template>
  <select class="form-control" style="width: 100%">
    <option v-if="multiple == false"></option>
    <option v-for="option in options" :key="option.id" :value="option.id">{{ option.text }}</option>
  </select>
</template>
