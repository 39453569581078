<template>
  <t-table-list-row class="table-fixed" infinite-scrolling>
    <t-table-list-cell>
      <div
        v-tooltip="{ content: name, placement: 'bottom', popperClass: 'tooltip-bigger' }"
        class="t-text-desktop-paragraph-sm w-full overflow-hidden truncate pr-4"
      >
        {{ name }}
      </div>
    </t-table-list-cell>
    <t-table-list-cell>
      <div
        v-tooltip="{ content: stripHtml(message), placement: 'bottom', popperClass: 'tooltip-bigger' }"
        class="t-text-desktop-paragraph-sm w-full truncate pr-4 text-grey-600"
      >
        {{ stripHtml(message) }}
      </div>
    </t-table-list-cell>
    <t-table-list-cell>
      <broadcast-status-badge
        :status-tooltip="statusTooltip"
        :status-type="statusType"
        :status="getBroadcastStatusDisplayText"
      />
    </t-table-list-cell>
    <t-table-list-cell>
      <div class="flex items-center">
        <broadcasting-channel-icon :type="channelType" class="mr-2" />
        <div
          v-tooltip="{ content: channelName, placement: 'bottom' }"
          class="t-text-desktop-paragraph-sm w-full truncate pr-4 text-grey-800"
        >
          {{ channelName }}
        </div>
      </div>
    </t-table-list-cell>
    <t-table-list-cell>
      <span
        v-tooltip="{ content: sentAtDetail, placement: 'bottom' }"
        class="t-text-desktop-paragraph-sm text-grey-600"
      >
        {{ sentAt }}
      </span>
    </t-table-list-cell>
    <t-table-list-cell v-if="isPartlySent" class="remove-white-space">
      <span class="t-text-desktop-paragraph-sm text-grey-800">{{ progress.processed_jobs }}</span>
      <span class="t-text-desktop-paragraph-sm text-grey-600">/{{ recipients }}</span>
    </t-table-list-cell>
    <t-table-list-cell v-else>
      <span class="t-text-desktop-paragraph-sm text-grey-600">{{ this.recipients }}</span>
    </t-table-list-cell>
    <t-table-list-cell>
      <span class="t-text-desktop-paragraph-sm text-grey-600">€{{ formattedCost }}</span>
    </t-table-list-cell>
    <t-table-list-cell>
      <div class="mr-3">
        <t-action-menu v-model="optionsDropdownIsOpen" position="left" fade width="max-content">
          <template #trigger>
            <div class="rounded-md p-1" :class="{ 'bg-grey-300': optionsDropdownIsOpen }">
              <more-horiz-fill
                class="pointer text-grey-600"
                size="24px"
                :class="{ 'text-grey-900': optionsDropdownIsOpen }"
              />
            </div>
          </template>
          <template #content>
            <div class="flex flex-col">
              <t-action-button data-test="view-broadcast" @click="viewBroadcast">
                <eye-linear size="1.25rem" class="mr-2 text-grey-900" />
                {{ $t('broadcast.overview_view') }}
              </t-action-button>
              <t-action-button v-if="this.status === 'SCHEDULED'" data-test="edit-broadcast" @click="editBroadcast(id)">
                <edit3-linear size="1.25rem" class="mr-2 text-grey-900" />
                {{ $t('broadcast.overview_edit') }}
              </t-action-button>
              <t-action-button
                v-if="this.status === 'SCHEDULED' || this.status === 'FAILED'"
                data-test="delete-broadcast"
                @click="deleteBroadcast(id, name)"
              >
                <trash-linear size="1.25rem" class="mr-2 text-grey-900" />
                {{ $t('broadcast.overview_delete') }}
              </t-action-button>
            </div>
          </template>
        </t-action-menu>
      </div>
    </t-table-list-cell>
  </t-table-list-row>
</template>

<script>
import { Edit3Linear, EyeLinear, MoreHorizFill, TrashLinear } from '@trengo/trengo-icons';
import { capitalize } from 'lodash';
import moment from 'moment';

import BroadcastingChannelIcon from '@/components/WabBroadcasting/components/BroadcastingChannelIcon';
import BroadcastStatusBadge from '@/components/WabBroadcasting/components/BroadcastStatusBadge';
import {
  broadcastStatusDisplayText,
  currency,
  statusTooltip,
  statusTypeConvert,
} from '@/components/WabBroadcasting/utils/Utils.js';
import { BROADCAST_STATUS, CHANNEL_TYPE } from '@/Configs/Constants';

export default {
  name: 'BroadcastingListItem',
  emits: ['delete', 'view'],
  components: {
    BroadcastingChannelIcon,
    BroadcastStatusBadge,
    MoreHorizFill,
    TrashLinear,
    Edit3Linear,
    EyeLinear,
  },
  data() {
    return {
      optionsDropdownIsOpen: false,
    };
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'SCHEDULED',
      validator: (value) => ['SCHEDULED', 'SENT', 'FAILED', 'SENDING', 'CREATED'].includes(value),
    },
    channelName: {
      type: String,
      default: '',
    },
    channelType: {
      type: String,
      default: CHANNEL_TYPE.WA_BUSINESS,
      validator: (value) => [CHANNEL_TYPE.WA_BUSINESS, CHANNEL_TYPE.SMS].includes(value),
    },
    recipients: {
      type: Number,
      default: 0,
    },
    cost: {
      type: [String, Number],
      default: '0',
    },
    name: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    statusText() {
      return capitalize(this.status);
    },
    statusType() {
      return statusTypeConvert(this.status);
    },
    sentAt() {
      const tz = this.$root.user.timezone;
      return this.date ? moment.tz(this.date, '', tz).format('MMM DD') : '-';
    },
    sentAtDetail() {
      const tz = this.$root.user.timezone;
      return this.date ? moment.tz(this.date, '', tz).format(`DD/MM/YYYY [${this.$t('general.at')}] HH:mm`) : '-';
    },
    statusTooltip() {
      if (this.isPartlySent) {
        return statusTooltip(BROADCAST_STATUS.LIST_PARTLY_SENT);
      } else if (this.status === BROADCAST_STATUS.FAILED) {
        return statusTooltip(BROADCAST_STATUS.LIST_FAILED);
      }

      return statusTooltip(this.status);
    },
    totalFailedRecipients() {
      return this.progress?.failed_jobs;
    },
    isPartlySent() {
      return this.status === BROADCAST_STATUS.SENT && this.totalFailedRecipients > 0;
    },
    getBroadcastStatusDisplayText() {
      const displayText = this.isPartlySent ? BROADCAST_STATUS.PARTLY_SENT : this.status;
      return broadcastStatusDisplayText(displayText);
    },
    formattedCost() {
      return currency(this.cost);
    },
  },

  methods: {
    deleteBroadcast() {
      const { id, name } = this;
      this.$emit('delete', { id, name });
      this.optionsDropdownIsOpen = false;
    },
    viewBroadcast() {
      const { id, name } = this;
      this.$emit('view', { id, name });
      this.optionsDropdownIsOpen = false;
    },
    editBroadcast(id) {
      this.$router.push('/broadcasting/' + id);
    },
  },
};
</script>

<style src="./BroadcastingListItemGlobal.scss" lang="scss" />
