<template>
  <div>
    <image3-linear v-if="fileType === 'image'" size="1.2rem" data-test="fileTypeImage" />
    <file-linear v-else-if="fileType === 'document'" size="1.2rem" data-test="fileTypeDocument" />
    <video-camera-linear v-else-if="fileType === 'video'" size="1.2rem" data-test="fileTypeVideo" />
    <volume-high-linear v-else-if="fileType === 'audio'" size="1.2rem" data-test="fileTypeAudio" />
    <paperclip-linear v-else size="1.2rem" data-test="fileTypeOther" />
  </div>
</template>

<script lang="ts">
import { FileLinear, Image3Linear, PaperclipLinear, VideoCameraLinear, VolumeHighLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import getFileType from '@/util/fileHelper';

export default defineComponent({
  name: 'FiletypeIcon',

  components: { VolumeHighLinear, FileLinear, Image3Linear, PaperclipLinear, VideoCameraLinear },

  props: {
    extension: {
      type: String,
      default: '',
    },
  },

  computed: {
    fileType() {
      return getFileType(this.extension);
    },
  },
});
</script>
