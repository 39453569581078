<script type="text/babel">
import Counter from './Timer.vue';
import TransferPanel from './TransferPanel.vue';

export default {
  name: 'CurrentCall',
  emits: [
    'end',
    'toggleMute',
    'toggleHold',
    'toggleTransfer',
    'transferSelected',
    'transferCall',
    'transferCancelled',
    'openPanel',
    'transferCompleted',
  ],
  data() {
    return {
      transferPanelVisible: false,
    };
  },

  components: {
    Counter,
    TransferPanel,
  },

  props: {
    call: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    endCall() {
      this.$emit('end');
    },

    toggleMute() {
      if (this.call.status !== 'in_progress') {
        return;
      }

      this.$emit('toggleMute');
    },

    toggleHold() {
      if (this.call.status === 'ringing' || this.call.intern) {
        return;
      }
      if (this.call.status !== 'in_progress' || this.transferPanelVisible) {
        return;
      }

      this.$emit('toggleHold');
    },

    toggleTransfer() {
      if (this.call.status === 'ringing' || this.call.intern) {
        return;
      }

      if (this.call.status !== 'in_progress') {
        return;
      }

      if (!this.call.hold) {
        this.toggleHold();
      }

      this.transferPanelVisible = !this.transferPanelVisible;
    },

    transferSelected(data) {
      this.$emit('transferSelected', data);
    },

    transferCancelled(data) {
      this.$emit('transferCancelled', data);
    },

    transferCall(data) {
      this.$emit('transferCall', data);
    },

    callerHasProfile() {
      return this.call.contact.profile && this.call.contact.profile[0];
    },

    openPanel() {
      this.$emit('openPanel');
    },
  },
};
</script>

<template>
  <div class="p-4 pt-2">
    <transfer-panel
      v-if="transferPanelVisible"
      :call="call"
      @closeTransfer="toggleTransfer"
      @transferSelected="transferSelected"
      @transferCall="transferCall"
      @transferCancelled="transferCancelled"
      @transferCompleted="$emit('transferCompleted')"
    ></transfer-panel>
    <div
      v-if="call.status != null"
      v-show="call.internCall.direction != 'outbound'"
      class="flex h-full w-full min-w-0 select-none flex-col items-center justify-center leading-none"
    >
      <div class="mx-8 mb-4 w-full text-ellipsis text-center text-sm text-grey-500">
        <i
          class="material-icons text-xs text-green"
          v-text="call.direction === 'inbound' ? 'call_received' : 'call_made'"
        ></i>
        <span v-text="call.direction === 'inbound' ? 'Inbound' : 'Outbound'"></span>
        call at:
        <strong>{{ call.channel.title }}</strong>
      </div>
      <div class="mb-4">
        <i class="material-icons voip-contact-circle">account_circle</i>
      </div>
      <div class="mb-1 w-full flex-1 text-ellipsis text-center text-lg font-bold text-white">
        {{ call.contact.full_name }}
      </div>
      <div class="mb-4 mt-1 w-full text-ellipsis text-center text-white">{{ call.contact.formatted_phone }}</div>

      <portal to="body-portal">
        <div
          v-show="(call.isInProgress() || call.isRinging()) && !call.isVoipSandbox"
          class="
            animated
            bounceIn
            an
            hidden-md-down
            flex
            cursor-pointer
            select-none
            items-center
            rounded
            bg-white
            p-3
            py-2
            text-center
            leading-none leading-none
            shadow
            hover:bg-grey-200
          "
          style="position: fixed; left: 80px; bottom: 160px; z-index: 9999"
          @click="openPanel"
        >
          <template v-if="call.isInProgress()">
            <span class="label label-xs status-label success mr-1 rounded"></span>
            <counter></counter>
          </template>
          <template v-else>Calling...</template>
        </div>
      </portal>
      <div class="flex h-10 items-center">
        <div v-if="callerHasProfile()" class="flex justify-center">
          <router-link
            :to="'/profiles/' + call.contact.profile[0].id"
            class="
              flex
              cursor-pointer
              items-center
              justify-center
              text-ellipsis
              rounded
              border-2 border-grey-600
              px-2
              py-1
              leading-none
              text-white
              hover:brightness-90
            "
            style="display: inline-flex; vertical-align: middle"
          >
            <i class="material-icons text-md text-grey">portrait</i>
            <span class="pl-1 text-sm text-grey-500">{{ call.contact.profile[0].name }}</span>
          </router-link>
        </div>
      </div>
      <div class="call-actions select-none text-grey-500" style="width: 90%">
        <div
          v-show="!$root.mobileDevice"
          class="call-action"
          :class="{ disabled: call.status == 'ringing' }"
          @click="toggleMute"
        >
          <div class="call-action-icon" :class="{ active: call.muted }">
            <i class="material-icons" v-text="call.muted ? 'mic_off' : 'mic'"></i>
          </div>
          <div class="call-action-title"><span>Mute</span></div>
        </div>
        <div
          class="call-action"
          :class="{ disabled: call.status == 'ringing' || call.intern == true }"
          @click="toggleHold"
        >
          <div class="call-action-icon" :class="{ active: call.hold == true }">
            <i class="material-icons" v-text="call.hold ? 'play_arrow' : 'pause'"></i>
            <!--                        <i class="fa fa-spinner fa-spin" v-if="call.holdLoading"></i>-->
          </div>
          <div class="call-action-title"><span>Pause</span></div>
        </div>
        <div
          class="call-action"
          :class="{ disabled: call.status == 'ringing' || call.intern == true }"
          @click="toggleTransfer"
        >
          <div class="call-action-icon" :class="{ active: transferPanelVisible }">
            <i class="material-icons">phone_forwarded</i>
          </div>
          <div class="call-action-title"><span>Forward</span></div>
        </div>
        <div class="call-action call-action-icon-fw" style="cursor: pointer">
          <div
            class="call-action-icon call-action-icon-red call-action-icon-rounded cursor-pointer hover:brightness-90"
            @click="endCall"
          >
            <i class="material-icons">call_end</i>
          </div>
          <div class="call-action-title"><span>End</span></div>
        </div>
      </div>
      <router-link
        v-if="call.ticketId"
        tag="span"
        :to="'/tickets/' + call.ticketId"
        class="
          mt-6
          flex
          w-full
          cursor-pointer
          items-center
          justify-center
          rounded
          border-2 border-grey-600
          px-4
          text-center
          leading-none
          text-grey-500
          hover:text-white
        "
        style="height: 45px"
      >
        Open conversation
      </router-link>
    </div>
  </div>
</template>

<style>
.voip-contact-circle {
  font-size: 60px;
  color: #464955;
  background: #000000;
}
</style>
