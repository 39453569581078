<template>
  <div
    class="flex w-full flex-col items-center justify-center pb-4 pt-6 backdrop-blur-lg"
    :class="[fixedWidth, $slots.bottom ? 'h-32' : 'h-20']"
  >
    <div
      class="flex w-full items-center"
      :class="[topRowClass, maxWidth && 'px-6']"
      :style="{ maxWidth: maxWidth, width: width }"
    >
      <div class="flex flex-shrink-0 items-center justify-start">
        <div v-if="$slots.left" class="mr-2">
          <slot name="left"></slot>
        </div>

        <span class="t-text-h3 text-grey-900">{{ title }}</span>
      </div>
      <div class="flex w-full justify-end">
        <slot name="right"></slot>
      </div>
    </div>

    <div
      v-if="$slots.bottom"
      class="mt-auto flex w-full items-center"
      :class="maxWidth && 'px-6'"
      :style="{ maxWidth: maxWidth, width: width }"
    >
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import type { PropType } from 'vue';
import type { TranslateResult } from 'vue-i18n';

export default defineComponent({
  props: {
    title: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '',
    },
  },
  computed: {
    fixedWidth() {
      return this.width || this.maxWidth ? 'm-auto' : 'px-6';
    },

    topRowClass() {
      return this.$slots.bottom ? 'mt-5 mb-2' : '';
    },
  },
});
</script>
