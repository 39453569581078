import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createBlock as _createBlock, withCtx as _withCtx, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-085d567b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "t-text-sm m-0 mr-10 text-grey-700" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "absolute -right-5 -top-1 z-50 mt-2 max-w-[296px] rounded-xl border border-grey-300 bg-white p-2 shadow-500" }
const _hoisted_4 = { class: "mb-0 max-h-[372px] w-full overflow-x-hidden overflow-y-scroll px-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_down_linear = _resolveComponent("chevron-down-linear")!
  const _component_t_list_item_checkbox = _resolveComponent("t-list-item-checkbox")!
  const _component_t_popover = _resolveComponent("t-popover")!

  return (_openBlock(), _createElementBlock("div", {
    "data-test": "available-columns",
    onKeypress: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.isOpen = true), ["enter"]))
  }, [
    _createVNode(_component_t_popover, {
      modelValue: _ctx.isOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isOpen) = $event)),
      "click-outside-close": ""
    }, {
      trigger: _withCtx(() => [
        _createElementVNode("div", {
          tabindex: "0",
          class: _normalizeClass(["dropdown-input", { active: _ctx.isOpen }])
        }, [
          _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('reports_v2.selected_columns')), 1 /* TEXT */),
          _createVNode(_component_chevron_down_linear, { size: "1.25rem" })
        ], 2 /* CLASS */)
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("ul", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.dropdownItems), (item) => {
                return (_openBlock(), _createBlock(_component_t_list_item_checkbox, {
                  id: `${item}-${_ctx.selectedItems[item]}`,
                  key: item,
                  label: _ctx.dropdownItems[item],
                  "model-value": _ctx.selectedItems[item],
                  onClick: _withModifiers(() => _ctx.handleClickCheckbox(item), ["prevent"])
                }, null, 8 /* PROPS */, ["id", "label", "model-value", "onClick"]))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ], 32 /* NEED_HYDRATION */))
}