import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { "data-test": "sidebar-watcher-display-wrapper" }
const _hoisted_2 = { class: "t-text-sm t-text-grey-800" }
const _hoisted_3 = { class: "t-text-sm m-0 p-0" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 2,
  class: "absolute flex flex-col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bell_linear = _resolveComponent("bell-linear")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_avatar = _resolveComponent("avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$tc('tickets.watchers')), 1 /* TEXT */),
    (_ctx.ticket.watchers.length < 1)
      ? (_openBlock(), _createBlock(_component_t_button, {
          key: 0,
          class: "flex w-fit flex-row items-center",
          "btn-style": "secondary",
          "data-test": "watcher-subscribe-button",
          onClick: _ctx.watchTicket
        }, {
          default: _withCtx(() => [
            _createVNode(_component_bell_linear, {
              class: "mr-2",
              size: "1.1rem"
            }),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('ticket_sidebar.receive_notifications')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]))
      : (_openBlock(), _createBlock(_component_t_button, {
          key: 1,
          class: "flex w-fit flex-row items-center active:!bg-grey-200",
          "btn-style": "secondary"
        }, {
          default: _withCtx(() => [
            _createElementVNode("button", {
              class: "-ml-1 mr-2 rounded-full p-1 transition-colors hover:bg-grey-300 active:bg-grey-400",
              "data-test": "unwatch-ticket-button",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.unwatchTicket && _ctx.unwatchTicket(...args)), ["prevent"]))
            }, [
              _createVNode(_component_bell_linear, {
                class: "text-leaf-600",
                size: "1.1rem"
              })
            ]),
            _createElementVNode("button", {
              class: "flex-inline flex flex-row rounded-full px-2 py-1 transition-colors hover:bg-grey-300 active:bg-grey-400",
              "data-test": "show-watchers-button",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.showWatchers = !_ctx.showWatchers), ["prevent"]))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ticket.watchers, (watcher, index) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: watcher.id,
                  class: "whitespace-nowrap",
                  "data-test": "watcher-list-item"
                }, [
                  (index < 6)
                    ? (_openBlock(), _createBlock(_component_avatar, {
                        key: 0,
                        class: "-ml-1 first:ml-0",
                        width: "25",
                        color: watcher.color,
                        abbr: watcher.abbr,
                        image: watcher.profile_image
                      }, null, 8 /* PROPS */, ["color", "abbr", "image"]))
                    : _createCommentVNode("v-if", true)
                ]))
              }), 128 /* KEYED_FRAGMENT */)),
              (_ctx.ticket.watchers.length > 6)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                    _createVNode(_component_avatar, {
                      class: "-ml-1.5",
                      width: "25",
                      color: "#000000",
                      abbr: `+${_ctx.ticket.watchers.length - 6}`
                    }, null, 8 /* PROPS */, ["abbr"])
                  ]))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _: 1 /* STABLE */
        })),
    (_ctx.showWatchers && _ctx.ticket.watchers.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("ul", {
            class: "relative flex max-h-48 cursor-pointer list-none flex-col overflow-y-auto rounded-lg bg-white p-4 shadow-md transition-colors hover:bg-grey-200 active:bg-grey-300",
            style: {"bottom":"3rem","left":"7.5rem"},
            "data-test": "hide-watchers-button",
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.showWatchers = false), ["prevent"]))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ticket.watchers, (watcher) => {
              return (_openBlock(), _createElementBlock("li", {
                key: watcher.id,
                class: "mb-3 select-none whitespace-nowrap last:mb-0"
              }, [
                _createVNode(_component_avatar, {
                  width: "25",
                  class: "mr-2",
                  color: watcher.color,
                  abbr: watcher.abbr,
                  image: watcher.profile_image
                }, null, 8 /* PROPS */, ["color", "abbr", "image"]),
                _createTextVNode(" " + _toDisplayString(watcher.name), 1 /* TEXT */)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}