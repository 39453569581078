import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useCredentialNotifications = defineStore('credentialNotifications', () => {
  const notification = ref('');

  const setNotification = (message: string) => {
    notification.value = message;
  };

  const resetNotification = () => {
    notification.value = '';
  };

  const hasNotification = computed(() => notification.value !== '');

  return {
    notification,
    hasNotification,
    setNotification,
    resetNotification,
  };
});
