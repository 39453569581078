import { request } from '@/util/request';

import type ChatInfo from '@/types/chatInfo';
import type Contact from '@/types/contact';
import type Profile from '@/types/profile';
import type Tickets from '@/types/tickets';
import type TimeEntry from '@/types/timeEntry';
import type { AxiosPromise } from 'axios';

export type TicketSearchParams = {
  page: number;
  sort: string;
  term: string;
  profile_id?: number;
  contact_id?: number;
};

export async function getVisitorDetails(id: Tickets['id']): Promise<AxiosPromise<Partial<ChatInfo>>> {
  return request(`/api/v2/tickets/${id}/chat_info`, 'GET');
}

export async function addTimeEntry(data: TimeEntry): Promise<AxiosPromise<TimeEntry>> {
  return request('/api/v2/time_tracking', 'POST', data);
}

export async function deleteTimeEntry(data: TimeEntry) {
  return request(`/api/v2/time_tracking/${data.id}`, 'DELETE', data);
}

export async function editTimeEntry(data: TimeEntry): Promise<AxiosPromise<TimeEntry>> {
  return request(`/api/v2/time_tracking/${data.id}`, 'PUT', data);
}

export async function getTimeTrackingEntries(id: Tickets['id']): Promise<AxiosPromise<TimeEntry>> {
  return request(`/api/v2/time_tracking?ticket_id=${id}`, 'GET');
}

export async function removeRelatedTicket(mainId: Tickets['id'], relatedId: Tickets['id']) {
  return request(`/api/v2/tickets/${mainId}/relations/${relatedId}`, 'DELETE');
}

export async function setContactGroupStatus(id: Contact['id'], selected: number[]) {
  return request(`/api/v2/contacts/${id}`, 'PUT', { contact_group_ids: selected });
}

export async function fetchTickets(searchParams: TicketSearchParams): Promise<AxiosPromise<Tickets>> {
  return request(`/api/v2/tickets`, 'GET', {}, {}, searchParams);
}

export async function getContactNotes(id: Contact['id']): Promise<AxiosPromise<unknown>> {
  return request(`/api/v2/contacts/${id}`, 'GET');
}

export async function saveNewContactNote(id: Contact['id'], data: string) {
  return request(`/api/v2/contacts/${id}/notes`, 'POST', { note: data });
}

export async function getProfileNotes(id: Profile['id']) {
  return request(`/api/v2/profiles/${id}`, 'GET');
}

export async function saveNewProfileNote(id: Profile['id'], data: string) {
  return request(`/api/v2/profiles/${id}/notes`, 'POST', { note: data });
}

export async function saveLoggedinPlugin(pluginId: number, key: string) {
  return request(`/api/v2/me/plugins?plugin_id=${pluginId}`, 'POST', { key });
}

export async function updateContactEmail(contactId: Contact['id'], email: string) {
  return request(`/api/v2/contacts/${contactId}`, 'PUT', { email });
}
