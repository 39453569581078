<template>
  <div class="mt-6 flex flex-col">
    <div :class="{ 'mb-6': !errors.apiKey }">
      <t-input-text
        id="customUrl"
        v-model="$props.modelValue.url"
        size="sm"
        :label="$tc('integrations.custom_label_app_url')"
        :sub-label="$tc('integrations.custom_subtitle_app_url')"
        placeholder="https://example.com"
        autocomplete="off"
        :has-error="errors.url"
        @blur="checkUrl"
      />
      <t-error-item v-if="errors.url" :text="$tc('integration_hub.error_url_must_be_valid')" danger-icon />
    </div>
    <div class="mb-6">
      <t-input-text
        id="customAuthToken"
        v-model="$props.modelValue.auth_token"
        size="sm"
        :label="$tc('integrations.custom_label_auth_token')"
        :sub-label="$tc('integrations.custom_subtitle_auth_token')"
        :placeholder="$tc('integrations.custom_placeholder_auth_token')"
        autocomplete="off"
        disabled
      />
    </div>

    <div :class="{ 'mb-6': !errors.height }">
      <t-input-text
        id="customHeight"
        v-model="$props.modelValue.height"
        size="sm"
        :label="$tc('integrations.custom_label_height')"
        :sub-label="$tc('integrations.custom_subtitle_height')"
        :placeholder="$tc('integrations.custom_placeholder_height')"
        autocomplete="off"
        :has-error="errors.height"
        @blur="checkHeight"
      />
      <t-error-item
        v-if="errors.height"
        :text="$tc('integration_hub.error_height_must_be_valid')"
        danger-icon
        class="mb-4"
      />
    </div>

    <t-inline-banner type="warning">
      <template #icon>
        <info-linear />
      </template>

      <p class="m-0 p-0" v-html="$t('integrations.custom_app_alert_information')"></p>
    </t-inline-banner>
  </div>
</template>

<script setup lang="ts">
import { InfoLinear } from '@trengo/trengo-icons';
import validUrlLibrary from 'valid-url';
import { ref } from 'vue';

import type { CustomCustomIntegration } from '@/store/types/integrations';

const props = defineProps<{ modelValue: CustomCustomIntegration['meta'] }>();
const emit = defineEmits(['on-validated', 'on-invalidated']);

const errors = ref({ url: false, height: false });

function validateFields() {
  checkUrl();
  checkHeight();

  Object.values(errors.value).some((item) => item) ? emit('on-invalidated') : emit('on-validated');
}

defineExpose({ validateFields });

function checkUrl() {
  errors.value.url = !props.modelValue.url || !validUrlLibrary.isUri(props.modelValue.url);
}

function checkHeight() {
  errors.value.height = !props.modelValue.height || !/\d+px/.test(props.modelValue.height);
}
</script>
