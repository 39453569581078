import type {
  CsatAggregatesResponse,
  OnlineStatusResponse,
  AgentResponse,
  TicketsAggregatesResponse,
  WorkloadManagementResponse,
  ConversationVolumeBucket,
} from '../types';

export const TicketsAggregatesMockData: TicketsAggregatesResponse = {
  aggregates: {
    new_tickets: 112,
    assigned_tickets: 54,
    average_total_resolution_time: 732,
    average_first_response_time: 480,
  },
};

export const CsatAggregateMockData: CsatAggregatesResponse = {
  data: {
    csat_score_percentage: 77,
  },
};

export const ConversationsVolumeNowMockData: ConversationVolumeBucket[] = [
  { value: 120, timestamp: 1691359200000, datetime: '' },
  { value: 90, timestamp: 1691362800000, datetime: '' },
  { value: 70, timestamp: 1691366400000, datetime: '' },
  { value: 100, timestamp: 1691370000000, datetime: '' },
  { value: 150, timestamp: 1691373600000, datetime: '' },
  { value: 200, timestamp: 1691377200000, datetime: '' },
  { value: 180, timestamp: 1691380800000, datetime: '' },
  { value: 160, timestamp: 1691384400000, datetime: '' },
  { value: 140, timestamp: 1691388000000, datetime: '' },
  { value: 130, timestamp: 1691391600000, datetime: '' },
  { value: 80, timestamp: 1691395200000, datetime: '' },
  { value: 110, timestamp: 1691398800000, datetime: '' },
  { value: 190, timestamp: 1691402400000, datetime: '' },
  { value: 210, timestamp: 1691406000000, datetime: '' },
  { value: 230, timestamp: 1691409600000, datetime: '' },
  { value: 220, timestamp: 1691413200000, datetime: '' },
  { value: 170, timestamp: 1691416800000, datetime: '' },
  { value: 130, timestamp: 1691420400000, datetime: '' },
  { value: 120, timestamp: 1691424000000, datetime: '' },
  { value: 100, timestamp: 1691427600000, datetime: '' },
  { value: 70, timestamp: 1691431200000, datetime: '' },
  { value: 90, timestamp: 1691434800000, datetime: '' },
  { value: 120, timestamp: 1691438400000, datetime: '' },
  { value: 140, timestamp: 1691442000000, datetime: '' },
];

export const ConversationsVolumeAvgMockData: ConversationVolumeBucket[] = [
  { value: 80, timestamp: 1691272800000, datetime: '' },
  { value: 110, timestamp: 1691276400000, datetime: '' },
  { value: 140, timestamp: 1691280000000, datetime: '' },
  { value: 120, timestamp: 1691283600000, datetime: '' },
  { value: 90, timestamp: 1691287200000, datetime: '' },
  { value: 60, timestamp: 1691290800000, datetime: '' },
  { value: 80, timestamp: 1691294400000, datetime: '' },
  { value: 100, timestamp: 1691298000000, datetime: '' },
  { value: 120, timestamp: 1691301600000, datetime: '' },
  { value: 140, timestamp: 1691305200000, datetime: '' },
  { value: 180, timestamp: 1691308800000, datetime: '' },
  { value: 150, timestamp: 1691312400000, datetime: '' },
  { value: 130, timestamp: 1691316000000, datetime: '' },
  { value: 110, timestamp: 1691319600000, datetime: '' },
  { value: 90, timestamp: 1691323200000, datetime: '' },
  { value: 100, timestamp: 1691326800000, datetime: '' },
  { value: 140, timestamp: 1691330400000, datetime: '' },
  { value: 160, timestamp: 1691334000000, datetime: '' },
  { value: 180, timestamp: 1691337600000, datetime: '' },
  { value: 200, timestamp: 1691341200000, datetime: '' },
  { value: 220, timestamp: 1691344800000, datetime: '' },
  { value: 190, timestamp: 1691348400000, datetime: '' },
  { value: 160, timestamp: 1691352000000, datetime: '' },
  { value: 130, timestamp: 1691355600000, datetime: '' },
];

export const ConversationsVolumeAverageEmptyStateMockData: ConversationVolumeBucket[] = [
  { value: 0, timestamp: 1691359200000, datetime: '' },
  { value: 0, timestamp: 1691362800000, datetime: '' },
  { value: 0, timestamp: 1691366400000, datetime: '' },
  { value: 0, timestamp: 1691370000000, datetime: '' },
  { value: 0, timestamp: 1691373600000, datetime: '' },
  { value: 0, timestamp: 1691377200000, datetime: '' },
  { value: 0, timestamp: 1691380800000, datetime: '' },
  { value: 50, timestamp: 1691384400000, datetime: '' },
  { value: 100, timestamp: 1691388000000, datetime: '' },
  { value: 130, timestamp: 1691391600000, datetime: '' },
  { value: 80, timestamp: 1691395200000, datetime: '' },
  { value: 180, timestamp: 1691398800000, datetime: '' },
  { value: 115, timestamp: 1691402400000, datetime: '' },
  { value: 100, timestamp: 1691406000000, datetime: '' },
  { value: 80, timestamp: 1691409600000, datetime: '' },
  { value: 160, timestamp: 1691413200000, datetime: '' },
  { value: 100, timestamp: 1691416800000, datetime: '' },
  { value: 50, timestamp: 1691420400000, datetime: '' },
  { value: 0, timestamp: 1691424000000, datetime: '' },
  { value: 0, timestamp: 1691427600000, datetime: '' },
  { value: 0, timestamp: 1691431200000, datetime: '' },
  { value: 0, timestamp: 1691434800000, datetime: '' },
  { value: 0, timestamp: 1691438400000, datetime: '' },
  { value: 0, timestamp: 1691442000000, datetime: '' },
];

export const ConversationsVolumeTodayEmptyStateMockData: ConversationVolumeBucket[] = [
  { value: 0, timestamp: 1691359200000, datetime: '' },
  { value: 0, timestamp: 1691362800000, datetime: '' },
  { value: 0, timestamp: 1691366400000, datetime: '' },
  { value: 0, timestamp: 1691370000000, datetime: '' },
  { value: 0, timestamp: 1691373600000, datetime: '' },
  { value: 0, timestamp: 1691377200000, datetime: '' },
  { value: 0, timestamp: 1691380800000, datetime: '' },
  { value: 0, timestamp: 1691384400000, datetime: '' },
  { value: 0, timestamp: 1691388000000, datetime: '' },
  { value: 0, timestamp: 1691391600000, datetime: '' },
  { value: 0, timestamp: 1691395200000, datetime: '' },
  { value: 0, timestamp: 1691398800000, datetime: '' },
  { value: 0, timestamp: 1691402400000, datetime: '' },
  { value: 0, timestamp: 1691406000000, datetime: '' },
  { value: 0, timestamp: 1691409600000, datetime: '' },
  { value: 0, timestamp: 1691413200000, datetime: '' },
  { value: 0, timestamp: 1691416800000, datetime: '' },
  { value: 0, timestamp: 1691420400000, datetime: '' },
  { value: 0, timestamp: 1691424000000, datetime: '' },
  { value: 0, timestamp: 1691427600000, datetime: '' },
  { value: 0, timestamp: 1691431200000, datetime: '' },
  { value: 0, timestamp: 1691434800000, datetime: '' },
  { value: 0, timestamp: 1691438400000, datetime: '' },
  { value: 0, timestamp: 1691442000000, datetime: '' },
];

export const ConversationsVolumeTodayLoadingStateMockData: ConversationVolumeBucket[] = [
  { value: 120, timestamp: 1691359200000, datetime: '' },
  { value: 90, timestamp: 1691362800000, datetime: '' },
  { value: 70, timestamp: 1691366400000, datetime: '' },
  { value: 100, timestamp: 1691370000000, datetime: '' },
  { value: 150, timestamp: 1691373600000, datetime: '' },
  { value: 200, timestamp: 1691377200000, datetime: '' },
  { value: 180, timestamp: 1691380800000, datetime: '' },
  { value: 160, timestamp: 1691384400000, datetime: '' },
  { value: 140, timestamp: 1691388000000, datetime: '' },
  { value: 130, timestamp: 1691391600000, datetime: '' },
  { value: 80, timestamp: 1691395200000, datetime: '' },
  { value: 110, timestamp: 1691398800000, datetime: '' },
  { value: 190, timestamp: 1691402400000, datetime: '' },
  { value: 210, timestamp: 1691406000000, datetime: '' },
  { value: 230, timestamp: 1691409600000, datetime: '' },
  { value: 220, timestamp: 1691413200000, datetime: '' },
  { value: 170, timestamp: 1691416800000, datetime: '' },
  { value: 130, timestamp: 1691420400000, datetime: '' },
  { value: 120, timestamp: 1691424000000, datetime: '' },
  { value: 100, timestamp: 1691427600000, datetime: '' },
  { value: 70, timestamp: 1691431200000, datetime: '' },
  { value: 90, timestamp: 1691434800000, datetime: '' },
  { value: 120, timestamp: 1691438400000, datetime: '' },
  { value: 140, timestamp: 1691442000000, datetime: '' },
];

export const ConversationsVolumeAverageLoadingStateMockData: ConversationVolumeBucket[] = [
  { value: 80, timestamp: 1691272800000, datetime: '' },
  { value: 110, timestamp: 1691276400000, datetime: '' },
  { value: 140, timestamp: 1691280000000, datetime: '' },
  { value: 120, timestamp: 1691283600000, datetime: '' },
  { value: 90, timestamp: 1691287200000, datetime: '' },
  { value: 60, timestamp: 1691290800000, datetime: '' },
  { value: 80, timestamp: 1691294400000, datetime: '' },
  { value: 100, timestamp: 1691298000000, datetime: '' },
  { value: 120, timestamp: 1691301600000, datetime: '' },
  { value: 140, timestamp: 1691305200000, datetime: '' },
  { value: 180, timestamp: 1691308800000, datetime: '' },
  { value: 150, timestamp: 1691312400000, datetime: '' },
  { value: 130, timestamp: 1691316000000, datetime: '' },
  { value: 110, timestamp: 1691319600000, datetime: '' },
  { value: 90, timestamp: 1691323200000, datetime: '' },
  { value: 100, timestamp: 1691326800000, datetime: '' },
  { value: 140, timestamp: 1691330400000, datetime: '' },
  { value: 160, timestamp: 1691334000000, datetime: '' },
  { value: 180, timestamp: 1691337600000, datetime: '' },
  { value: 200, timestamp: 1691341200000, datetime: '' },
  { value: 220, timestamp: 1691344800000, datetime: '' },
  { value: 190, timestamp: 1691348400000, datetime: '' },
  { value: 160, timestamp: 1691352000000, datetime: '' },
  { value: 130, timestamp: 1691355600000, datetime: '' },
];

export const OnlineStatusMockData: AgentResponse[] = Array.from({ length: 13 }).map((_, i) => ({
  id: i,
  name: `FullName${i}`,
  profile_image: `https://i.pravatar.cc/150?u=${i}`,
  email: `user${i}@test.com`,
  voip_status: i % 2 === 0 ? 'offline' : 'online',
  chat_status: i % 2 === 0 ? 'offline' : 'online',
}));

export const OnlineStatusMockResponse: OnlineStatusResponse = {
  data: {
    aggregates: {
      offline_users: 10,
      online_users: 15,
    },
    data: OnlineStatusMockData,
  },
};

export const WorkloadManagementCreatedConversationsMockData: WorkloadManagementResponse = {
  data: {
    buckets: [
      { value: 200, timestamp: 1693215347000, datetime: '' },
      { value: 400, timestamp: 1693301747000, datetime: '' },
      { value: 820, timestamp: 1693388147000, datetime: '' },
      { value: 422, timestamp: 1693474547000, datetime: '' },
      { value: 180, timestamp: 1693560947000, datetime: '' },
      { value: 400, timestamp: 1693647347000, datetime: '' },
      { value: 590, timestamp: 1693733747000, datetime: '' },
    ],
  },
};

export const WorkloadManagementClosedConversationsMockData: WorkloadManagementResponse = {
  data: {
    buckets: [
      { value: 425, timestamp: 1693215347000, datetime: '' },
      { value: 220, timestamp: 1693301747000, datetime: '' },
      { value: 60, timestamp: 1693388147000, datetime: '' },
      { value: 240, timestamp: 1693474547000, datetime: '' },
      { value: 350, timestamp: 1693560947000, datetime: '' },
      { value: 400, timestamp: 1693647347000, datetime: '' },
      { value: 370, timestamp: 1693733747000, datetime: '' },
    ],
  },
};

export const WorkloadHourlyAverageConversationsCreatedMockData: WorkloadManagementResponse = {
  data: {
    buckets: [
      { value: 50, timestamp: 1691190000000, datetime: '' },
      { value: 160, timestamp: 1691193600000, datetime: '' },
      { value: 350, timestamp: 1691197200000, datetime: '' },
      { value: 180, timestamp: 1691200800000, datetime: '' },
      { value: 50, timestamp: 1691204400000, datetime: '' },
      { value: 190, timestamp: 1691208000000, datetime: '' },
      { value: 50, timestamp: 1691211600000, datetime: '' },
      { value: 240, timestamp: 1691215200000, datetime: '' },
      { value: 270, timestamp: 1691218800000, datetime: '' },
      { value: 820, timestamp: 1691222400000, datetime: '' },
      { value: 500, timestamp: 1691226000000, datetime: '' },
      { value: 423, timestamp: 1691229600000, datetime: '' },
      { value: 180, timestamp: 1691233200000, datetime: '' },
      { value: 180, timestamp: 1691236800000, datetime: '' },
      { value: 180, timestamp: 1691240400000, datetime: '' },
      { value: 180, timestamp: 1691244000000, datetime: '' },
      { value: 180, timestamp: 1691247600000, datetime: '' },
      { value: 180, timestamp: 1691251200000, datetime: '' },
      { value: 180, timestamp: 1691254800000, datetime: '' },
      { value: 180, timestamp: 1691258400000, datetime: '' },
      { value: 180, timestamp: 1691262000000, datetime: '' },
      { value: 180, timestamp: 1691265600000, datetime: '' },
      { value: 180, timestamp: 1691269200000, datetime: '' },
      { value: 180, timestamp: 1691186400000, datetime: '' },
    ],
  },
};

export const WorkloadDailyAverageConversationsCreatedMockData: WorkloadManagementResponse = {
  data: {
    buckets: [
      { value: 150, timestamp: 1693215347000, datetime: '' },
      { value: 400, timestamp: 1693301747000, datetime: '' },
      { value: 910, timestamp: 1693388147000, datetime: '' },
      { value: 400, timestamp: 1693474547000, datetime: '' },
      { value: 175, timestamp: 1693560947000, datetime: '' },
      { value: 520, timestamp: 1693647347000, datetime: '' },
      { value: 210, timestamp: 1693733747000, datetime: '' },
    ],
  },
};
