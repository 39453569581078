<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  indicator: string;
  colorClasses: Record<string, string>;
}>();

const computedClass = computed(() => {
  return props.colorClasses[props.indicator] || 'bg-grey-500';
});
</script>

<template>
  <div :class="computedClass" class="h-1.5 w-1.5 flex-shrink-0 rounded-full"></div>
</template>
