<script type="text/babel">
import TransferInput from './TransferInput.vue';
import Avatar from '../../Avatar.vue';

export default {
  name: 'TransferPanel',
  emits: ['transferSelected', 'transferCall', 'transferCancelled', 'closeTransfer', 'transferCompleted'],
  props: {
    call: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    Avatar,
    TransferInput,
  },

  data() {
    return {
      cancelling: false,
    };
  },

  methods: {
    cancelTransfer() {
      this.$emit('transferCancelled');
    },

    transferCall() {
      this.$emit('transferCall');
    },

    transferSelected(data) {
      this.$emit('transferSelected', data);
    },
  },
};
</script>

<template>
  <div
    style="position: absolute; z-index: 99999; top: 10px; left: 10px; right: 10px"
    class="mt-12 rounded-lg bg-white p-4"
  >
    <transfer-input
      v-show="call.internCall.status == null"
      @transferSelected="transferSelected"
      @closeTransfer="$emit('closeTransfer')"
    ></transfer-input>
    <div v-if="call.internCall.status != null && call.internCall.direction === 'outbound'">
      <div class="row">
        <div class="col-md-12">
          <div class="voip-list-item rounded-lg" style="padding: 10px; height: 60px; margin-top: 0">
            <div class="voip-list-item-avatar">
              <avatar
                :abbr="call.contact.abbr"
                :color="call.contact.color"
                :image="call.contact.profile_image"
              ></avatar>
            </div>
            <div class="voip-list-item-contact">
              <div class="contact-name text-ellipsis">{{ call.contact.full_name }}</div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div
            class="text-muted text-center text-xs"
            style="display: flex; height: 35px; padding-left: 12px; font-size: 100px"
          >
            <i class="material-icons" style="line-height: 1; font-size: 39px">swap_vert</i>
          </div>
        </div>
        <div class="col-md-12">
          <div class="voip-list-item m-t-0 rounded-lg" style="padding: 10px; height: 60px; margin-bottom: 10px">
            <div class="voip-list-item-avatar">
              <avatar
                :abbr="call.internCall.user.abbr"
                :color="call.internCall.user.color"
                :image="call.internCall.user.profile_image"
              ></avatar>
            </div>
            <div class="voip-list-item-contact">
              <div class="contact-name text-ellipsis">{{ call.internCall.user.full_name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="call.internCall.status !== 'transferred'" class="mt-2 flex">
        <button
          class="
            success
            mr-2
            flex flex-1
            cursor-pointer
            items-center
            justify-center
            rounded-full
            bg-grey-200
            p-2
            px-3 px-4
            pr-4
            hover:brightness-90
          "
          :disabled="call.internCall.status !== 'in_progress'"
          @click="transferCall"
        >
          <span v-if="call.internCall.status !== 'transferring'">Transfer</span>
          <span v-if="call.internCall.status === 'transferring'"><i class="fa fa-spinner fa-spin"></i></span>
        </button>
        <button
          class="
            flex flex-1
            cursor-pointer
            items-center
            justify-center
            rounded-full
            bg-grey-200
            px-3 px-4
            pr-4
            hover:brightness-90
          "
          :disabled="cancelling"
          @click="cancelTransfer"
        >
          <span v-if="!cancelling">Cancel</span>
          <span v-if="cancelling"><i class="fa fa-spinner fa-spin"></i></span>
        </button>
      </div>
      <div v-if="call.internCall.status === 'rejected'" class="row ext-ellipsis">
        <div class="col-md-12 text-danger text-center" style="margin-top: 10px; font-weight: bold">
          {{ call.internCall.user.full_name }} is not available right now
        </div>
      </div>
      <div v-if="call.internCall.status === 'ringing'" class="row ext-ellipsis">
        <div class="col-md-12 text-center" style="margin-top: 10px; font-weight: bold">
          Calling {{ call.internCall.user.full_name }}...
        </div>
      </div>
      <div v-if="call.internCall.status === 'connecting'" class="row ext-ellipsis">
        <div class="col-md-12 text-center" style="margin-top: 10px; font-weight: bold">
          Connecting to {{ call.internCall.user.full_name }}...
        </div>
      </div>
      <div v-if="call.internCall.status === 'transferred'" class="row ext-ellipsis">
        <div class="col-md-12 text-success text-center" style="margin-top: 10px; font-weight: bold">
          Call transferred successfully.
          <i
            class="material-icons"
            style="
              display: flex;
              color: #525252;
              border-radius: 100%;
              border: 1px solid #525252;
              width: 40px;
              text-align: center;
              height: 40px;
              justify-content: center;
              align-items: center;
              margin: 20px auto;
              cursor: pointer;
            "
            @click="$emit('transferCompleted')"
          >
            close
          </i>
        </div>
      </div>
    </div>
  </div>
</template>
