import { CHANNEL_TYPE } from '@/Configs/Constants';
import { request } from '@/util';

export default {
  namespaced: true,
  state: {
    channels: [],
  },
  mutations: {
    setChannels(state, payload) {
      state.channels = payload;
    },
  },
  actions: {
    fetchChannels({ commit, state }, forceReload = false) {
      if (forceReload || state.channels.length === 0) {
        request('/api/v2/channels', 'GET').then((res) => {
          const channels = res.data.data;
          commit('setChannels', channels);
        });
      }
    },
  },
  getters: {
    channelsList(state) {
      return state.channels;
    },
    businessHoursChannelsList(state) {
      return state.channels.filter((c) => ![CHANNEL_TYPE.HELP_CENTER].includes(c.type));
    },
  },
};
