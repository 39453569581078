<template>
  <div :class="`skeleton-background absolute right-0 top-0 z-10 ${classes}`">
    <div class="flex h-full w-full flex-col items-center justify-center">
      <t-thumbnail class="bg-white">
        <component :is="iconName" />
      </t-thumbnail>
      <p class="t-text-md-emphasize mb-2 mt-4 text-grey-800">{{ title }}</p>
      <p class="t-text-sm mb-0 text-grey-700">{{ subTitle }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { ChartColumnLinear, ChartLineLinear, WalletMoveLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import type { Integration } from '@/store/types/integrations';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'ErrorStatus',
  components: { ChartColumnLinear, ChartLineLinear, WalletMoveLinear },

  props: {
    iconName: {
      type: String as PropType<Integration['icon']>,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
  },
});
</script>

<style scoped lang="scss">
.skeleton-background {
  background: linear-gradient(360deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}
</style>
