<template>
  <div>
    <install-modal
      v-if="isReauthModalOpen"
      :is-open="isReauthModalOpen"
      :installation-id="selectedInstallationID"
      @close="toggleReauthModal"
    />
    <div
      v-for="(installation, index) in installations"
      :key="installation.id"
      class="mb-8 rounded-md border border-grey-300"
    >
      <div class="title-bar">
        <div class="flex justify-between self-center">
          <span data-test="installed-item-title">
            {{ installation.name }}
          </span>
        </div>
        <div class="flex flex-row">
          <t-badge
            v-if="getStatusText(installation.status) !== ''"
            class="mr-2 self-center"
            :variant="getVariant(installation.status)"
            :text="$t(`integration_hub.${getStatusText(installation.status)}`)"
          />
          <t-action-menu :key="index" v-model="installation.isOpen" width="max-content" position="left">
            <template #trigger>
              <t-icon-button>
                <more-horiz-fill />
              </t-icon-button>
            </template>
            <template #content>
              <div>
                <t-list-item
                  :label="$tc('integration_hub.rename')"
                  data-test="rename-installed-integration"
                  @click="renameInstallation(installation)"
                >
                  <template #prefix>
                    <edit2-linear size="1.5rem" />
                  </template>
                </t-list-item>

                <t-list-item
                  :label="$tc('integration_hub.edit')"
                  data-test="edit-installed-integration"
                  @click="editInstallation(installation)"
                >
                  <template #prefix>
                    <edit-linear size="1.5rem" />
                  </template>
                </t-list-item>

                <t-list-item
                  v-if="
                    integration.auth_type === 'oauth2' &&
                    (integration.oauth_grant_type === 'authorization_code' || integration.oauth_grant_type === null)
                  "
                  :label="$tc('integration_hub.reauthenticate')"
                  data-test="reauthenticate-installed-integration"
                  @click.prevent="reauthenticateInstallation(installation)"
                >
                  <template #prefix>
                    <rotate-ccw-linear size="1.5rem" />
                  </template>
                </t-list-item>

                <t-list-item
                  :label="$tc('integration_hub.uninstall')"
                  :is-delete="true"
                  data-test="delete-installed-integration"
                  @click="uninstall(installation)"
                >
                  <template #prefix>
                    <trash-linear size="1.5rem" />
                  </template>
                </t-list-item>
              </div>
            </template>
          </t-action-menu>
        </div>
      </div>

      <div class="table-wrapper">
        <table class="w-full table-auto border-collapse">
          <tbody>
            <component :is="dataTemplateComponent" :installation="installation" />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { MoreHorizFill, EditLinear, Edit2Linear, TrashLinear, RotateCcwLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import InstallModal from '@/components/IntegrationHub/Components/Modals/InstallModal.vue';

import InstalledIntegrationCommerce from './InstalledIntegrationCommerce.vue';
import InstalledIntegrationCrm from './InstalledIntegrationCrm.vue';

import type { Integration, Installation, InstallationStatus } from '@/store/types/integrations';
import type { PropType } from 'vue';

type Installations = Array<Installation & { isOpen: boolean }>;

export default defineComponent({
  name: 'InstalledTable',

  components: {
    MoreHorizFill,
    InstalledIntegrationCrm,
    InstalledIntegrationCommerce,
    EditLinear,
    Edit2Linear,
    TrashLinear,
    RotateCcwLinear,
    InstallModal,
  },

  props: {
    type: {
      type: String,
      default: 'crm',
    },
    integration: {
      type: Object as PropType<Integration>,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({
      getInstallations: 'integrations/getInstallations',
    }),
    _installations() {
      return this.getInstallations(parseInt(this.$route.params.integrationId));
    },
    dataTemplateComponent() {
      if (this.integration.filterType === 'crm') {
        return 'InstalledIntegrationCrm';
      } else {
        return 'InstalledIntegrationCommerce';
      }
    },
  },

  data() {
    return {
      installations: [] as Installations,
      isReauthModalOpen: false,
      selectedInstallationID: 0,
    };
  },

  methods: {
    editInstallation(installation: Installations[number]) {
      const params = {
        filter: this.$route.params.filter,
        integrationId: this.$route.params.integrationId,
        installationId: String(installation.id),
      };

      installation.isOpen = false;
      this.$router.push({ name: 'installationEdit', params, query: { ...this.$route.query } });
    },

    renameInstallation(installation: Installations[number]) {
      const params = {
        filter: this.$route.params.filter,
        integrationId: this.$route.params.integrationId,
        installationId: String(installation.id),
      };

      installation.isOpen = false;
      this.$router.push({ name: 'installationRename', params, query: { ...this.$route.query } });
    },

    uninstall(installation: Installations[number]) {
      const params = {
        filter: this.$route.params.filter,
        integrationId: this.$route.params.integrationId,
        installationId: String(installation.id),
      };

      installation.isOpen = false;
      this.$router.push({ name: 'installationUninstall', params, query: { ...this.$route.query } });
    },

    listInstallations() {
      this.installations = [];
      this._installations.forEach((installation: Installation) => {
        this.installations.push({ ...installation, isOpen: false });
      });
    },

    getVariant(status: InstallationStatus) {
      if (['disabled', 'added', 'invalid'].includes(status)) {
        return 'error';
      } else if (['authorized', 'available'].includes(status)) {
        return 'warning';
      } else if (status === 'callable') {
        return 'success';
      }
      return 'default';
    },
    getStatusText(status: InstallationStatus): string {
      // available = dont show
      // added & oauth = Unauthorised
      // added & !oauth = Input required
      // invalid = Invalid configuration
      // authorized = Connected
      // callable = Connected
      if (status === 'available') {
        return '';
      }
      if (status === 'added') {
        if (this.integration.auth_type === 'oauth2') {
          return 'unauthorised';
        }
        return 'input_required';
      }
      if (status === 'invalid') {
        return 'invalid_configuration';
      }
      if (status === 'authorized' || status === 'callable') {
        return 'connected';
      }
      return '';
    },

    reauthenticateInstallation(installation: Installation) {
      this.selectedInstallationID = installation.id;
      this.isReauthModalOpen = true;
      installation.isOpen = false;
    },

    toggleReauthModal() {
      this.selectedInstallationID = 0;
      this.isReauthModalOpen = !this.isReauthModalOpen;
    },
  },

  mounted() {
    this.listInstallations();
  },

  watch: {
    _installations: {
      handler(value) {
        if (value) {
          this.listInstallations();
        }
      },
      deep: true,
    },
  },
});
</script>

<style scoped lang="scss">
.title-bar {
  @apply flex flex-row justify-between rounded-t-md bg-grey-300 px-6 py-3 text-base font-semibold text-grey-700;
}
.table-wrapper {
  @apply rounded-b-md bg-white px-6;
}
</style>
