import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_step_one = _resolveComponent("step-one")!
  const _component_step_two = _resolveComponent("step-two")!
  const _component_step_three = _resolveComponent("step-three")!

  return (_ctx.currentStep === 'scanQRCode')
    ? (_openBlock(), _createBlock(_component_step_one, {
        key: 0,
        "is-open": _ctx.isOpen,
        "is-reset-flow": _ctx.isResetFlow,
        qr: _ctx.qr,
        secret: _ctx.secret,
        onClose: _ctx.handleClose,
        onGoToStep: _ctx.handleTransition
      }, null, 8 /* PROPS */, ["is-open", "is-reset-flow", "qr", "secret", "onClose", "onGoToStep"]))
    : (_ctx.currentStep === 'enterVerificationCode')
      ? (_openBlock(), _createBlock(_component_step_two, {
          key: 1,
          "is-open": _ctx.isOpen,
          onClose: _ctx.handleClose,
          onGoToStep: _ctx.handleTransition
        }, null, 8 /* PROPS */, ["is-open", "onClose", "onGoToStep"]))
      : (_ctx.currentStep === 'success')
        ? (_openBlock(), _createBlock(_component_step_three, {
            key: 2,
            "is-open": _ctx.isOpen,
            "is-reset-flow": _ctx.isResetFlow,
            onClose: _ctx.handleClose,
            onGoToStep: _ctx.handleTransition
          }, null, 8 /* PROPS */, ["is-open", "is-reset-flow", "onClose", "onGoToStep"]))
        : _createCommentVNode("v-if", true)
}