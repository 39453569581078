<template>
  <div class="wab-recipients-groups">
    <contacts-search v-if="!noGroups" v-model="searchKey" class="search-field" />

    <div class="status">
      <loader-linear v-if="loading" size="1.5rem" class="spin" />
      <wab-contact-status v-else-if="noGroups">No Groups</wab-contact-status>
      <wab-contact-status v-else-if="noResults">No Groups found for ‘{{ searchKey }}’</wab-contact-status>
    </div>

    <div>
      <wab-recipients-item
        v-for="group in computedList"
        :key="group.id"
        :model-value="selected[group.id]"
        @update:modelValue="onInput($event, group.id, group.title)"
      >
        <template #name>
          {{ group.title }}
        </template>
        <template #right-text>
          <span class="font-normal not-italic"><t-badge :text="group.contacts_count" /></span>
        </template>
      </wab-recipients-item>
    </div>
  </div>
</template>

<script>
import { LoaderLinear } from '@trengo/trengo-icons';

import { getContactGroups } from '@/components/WabBroadcasting/api';
import ContactsSearch from '@/components/WabBroadcasting/components/ContactsSearch';
import WabContactStatus from '@/components/WabBroadcasting/components/WabContactStatus';
import WabRecipientsItem from '@/components/WabBroadcasting/components/WabRecipientsItem';

export default {
  name: 'WabRecipientsGroups',
  emits: ['select'],
  components: { WabRecipientsItem, ContactsSearch, WabContactStatus, LoaderLinear },
  data() {
    return {
      list: [],
      page: 1,
      loading: false,
      searchKey: '',
    };
  },
  props: {
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    computedList() {
      return this.list.filter((item) => item.title.toLowerCase().includes(this.searchKey?.toLowerCase()) && item.id);
    },
    noGroups() {
      return !this.searchKey && !this.computedList.length && !this.loading;
    },
    noResults() {
      return this.searchKey && !this.computedList.length;
    },
  },
  mounted() {
    this.requestContactGroups();
  },
  methods: {
    requestContactGroups() {
      this.loading = true;

      return getContactGroups()
        .then((groups) => {
          if (groups.data?.length) {
            this.list = groups.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onInput(isSelected, id, title) {
      this.$emit('select', {
        isSelected,
        id,
        title,
      });
    },
  },
};
</script>

<style scoped lang="scss" src="./WabRecipientsGroups.scss" />
