<template>
  <div id="settings-primary" class="col-xs modal fade aside aside-md b-r w-medium w-200px">
    <div class="nav-settings light left b-primary row-col">
      <ul>
        <li class="text-md white header">
          <strong>{{ title }}</strong>
          <a data-dismiss="modal" class="hidden-md-up text-muted m-l-auto m-r-sm m-l-1 text-lg">×</a>
          <a class="m-l-auto btn btn-icon btn-sm success rounded text-white">
            <i class="material-icons md-18" @click="handleClickAddNewItem">add</i>
          </a>
        </li>
      </ul>
      <div class="row-row">
        <div class="row-body scrollable hove">
          <div class="row-inner">
            <ul>
              <li v-for="item in items" :key="item.id">
                <router-link
                  class="nav-settings-link text-truncate"
                  :to="itemLink(item.id)"
                  :class="{ active: $route.params.id == item.id }"
                >
                  <span v-if="showActiveBadge" class="float-right">
                    <label v-if="!item.active" class="label m-l-xs m-b-0">
                      {{ $t('rules.list_label_inactive') }}
                    </label>
                  </span>
                  {{ item.name }}
                </router-link>
              </li>
              <infinite-loading
                v-if="isInfinite"
                direction="bottom"
                spinner="spiral"
                :identifier="refreshCounter"
                @infinite="loadMore"
              ></infinite-loading>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import infiniteLoading from 'vue-infinite-loading';

import type { PropType } from 'vue';

type Type = 'rules' | 'quick-replies';

export default defineComponent({
  name: 'SettingsItemList',
  emits: ['addNewItem'],
  components: { infiniteLoading },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    loadMore: {
      type: Function,
      default: () => {},
    },
    refreshCounter: {
      type: Number,
      default: 0,
    },
    isInfinite: {
      type: Boolean,
      default: false,
    },
    showActiveBadge: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: String,
      default: '',
      required: true,
    },
    type: {
      type: String as PropType<Type>,
      default: 'rules',
      validator: (value: Type) => ['rules', 'quick_replies'].includes(value),
    },
  },
  methods: {
    itemLink(id: number): { name: string; params: { id: number } } {
      return { name: this.routeName, params: { id } };
    },
    handleClickAddNewItem() {
      this.$router.push(`/admin/${this.type}/create`);
      this.$emit('addNewItem');
    },
  },
});
</script>
