<template>
  <portal to="body-portal">
    <div ref="modal" class="modal">
      <div class="modal-centered height-auto">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('voice.voip_outbound_phone_numbers') }}</h5>
            <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close-btn">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body" style="max-height: 500px; overflow-y: auto">
            <table id="general-table" class="table-striped table-vcenter table">
              <thead>
                <tr>
                  <th width="45%">{{ $t('voice.voip_phone_number') }}</th>
                  <th width="45%">{{ $t('voice.voip_status') }}</th>
                  <th width="10%" class="text-center"><i class="fa fa-flash"></i></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="record in outboundNumbers">
                  <td>{{ record['phone'] }}</td>
                  <td>
                    <span v-if="record['status'] === 'VERIFIED'">{{ record['status'] }}</span>
                    <span v-else>Code: {{ record['verification_code'] }}</span>
                  </td>
                  <td class="text-center">
                    <a @click="deleteRecord(record['id'])">
                      <i class="material-icons text-red">remove_circle_outline</i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer text-left">
            <button type="button" class="btn btn-default" @click="openCreateModal">
              {{ $t('voice.voip_add_phone_number') }}
            </button>
            <button type="button" class="btn btn-link text-muted" data-dismiss="modal">
              {{ $t('voice.voip_cancel') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div ref="newModal" class="modal">
      <div class="modal-centered height-auto">
        <div class="modal-content">
          <form class="form-horizontal" @submit.prevent="addRecord">
            <div>
              <div class="modal-header">
                <h5 class="modal-title">{{ $t('voice.voip_add_phone_number') }}</h5>
                <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close-btn">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <div v-if="!channelMeta" class="alert alert-grey">
                  {{ $t('voice.voip_enter_the_outbound_phone_number_in_an_international_format') }}
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">
                    {{ $t('voice.voip_phone_number') }}
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-sm-10">
                    <input v-model="record.phone" type="text" autocomplete="off" class="form-control" />
                  </div>
                </div>
                <div v-if="!channelMeta" class="form-group row">
                  <label class="col-sm-2 form-control-label">{{ $t('voice.voip_optional_extension') }}</label>
                  <div class="col-sm-10">
                    <input v-model="record.extension" type="text" autocomplete="off" class="form-control" />
                    <span class="form-text">
                      {{ $t('voice.voip_fill_out_this_field_if_you_are_verifying_a_caller_id') }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="modal-footer text-left">
                <button type="submit" class="btn success text-white">{{ $t('voice.voip_add_phone_number') }}</button>
                <button type="button" class="btn btn-link text-muted" data-dismiss="modal">
                  {{ $t('voice.voip_cancel') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </portal>
</template>

<script type="text/babel">
export default {
  name: 'OutboundPhoneNumbers',
  emits: ['changed'],
  props: {
    voipChannelMeta: {
      type: Object,
      default: () => ({}),
    },
    outboundNumbers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      record: {},
      saving: false,
    };
  },

  computed: {
    channelMeta() {
      return this.voipChannelMeta;
    },
  },

  mounted() {
    $(this.$refs.newModal).on('hidden.bs.modal', () => {
      $(this.$refs.modal).modal('show');
    });

    PusherInstance.subscribe(CHANNEL_PREFIX + '@voip_caller_ids').bind('RELOAD', (data) => {
      this.$emit('changed');
    });
  },

  unmounted() {
    PusherInstance.unsubscribe(CHANNEL_PREFIX + '@voip_caller_ids');
  },

  methods: {
    openCreateModal() {
      $(this.$refs.newModal).modal('show');
      $(this.$refs.modal).modal('hide');
    },

    async deleteRecord(id) {
      const shouldDeleteRecord = await this.$tConfirm(
        this.$t('voice.voip_are_you_sure_you_want_to_delete_this_record'),
        {
          delete: true,
          title: this.$t('general.are_you_sure'),
        }
      );
      if (shouldDeleteRecord) {
        axios.delete('/api/v2/voip/caller_ids/' + id).then(() => {
          this.$emit('changed');
        });
      }
    },

    addRecord() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      axios
        .post('/api/v2/voip/caller_ids', this.record)
        .then((result) => {
          this.$emit('changed');
          $(this.$refs.newModal).modal('hide');
          this.record = {};
          this.saving = false;
        })
        .catch(() => {
          this.saving = false;
        });
    },
  },
};
</script>
