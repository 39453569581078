import { sanitize } from 'dompurify';
import debounce from 'lodash/debounce';

import eventBus from '@/eventBus';

import MessageAttachmentModel from './MessageAttachmentModel';
import MessageModel from './MessageModel';
import store from '../../../store';
import PusherHelper from '../../../util/PusherHelper';
import { audio, clean, notifyThreadMessage, notifyViaBrowser } from '../Util/Chat';

export default class ThreadModel {
  constructor(thread) {
    this.id = parseInt(thread.id) || null;
    this.primary = !!thread.primary;
    this.participants = (thread.participants || []).map((p) => {
      return { id: parseInt(p.id) || null, owner: !!p.owner };
    });
    this.messages = (thread.messages || []).map((m) => new MessageModel(m));
    this.lastRead = parseInt(thread.lastRead) || null;
    this.subject = thread.subject;
    this.muted = !!thread.muted;
    this.unread = parseInt(thread.unread) || 0;
    this.markUnread = false;
    this.unreadMentions = parseInt(thread.unreadMentions) || 0;
    this.userMessageCount = parseInt(thread.userMessageCount) || 0;
    this.firstUnreadMessageId = parseInt(thread.firstUnreadMessageId) || null;
    this.lastMessageId = parseInt(thread.lastMessageId) || null;
    this.lastMessageTime = parseInt(thread.lastMessageTime) || null;
    this.attachments = (thread.attachments || []).map((a) => new MessageAttachmentModel(a));
    this.createdAt = thread.createdAt;

    this.setNameAndIdentifier(thread.subject);
    this.pusherChannel = null;

    this.personal = thread.personal;

    this.attachmentsPage = 1;
    this.attachmentsTotal = 0;
    this.typing = {};
  }

  static get personalFields() {
    return ['unread', 'markUnread', 'unreadMentions', 'lastRead', 'muted', 'firstUnreadMessageId', 'userMessageCount'];
  }

  setLastRead(max = null) {
    if (this.id) {
      const thread = store.getters['chat/threadById'](this.id);

      store.dispatch('chat/markMessageAsReadOrUnread', { thread: thread, max: max });
      store.commit('chat/setFirstUnreadMessageId', { thread: thread, messageId: null }); // unset after reading. TODO v2: return firstUnreadMessageId from api.setLastRead so we can update this with that value
    }
  }

  setUnread(message, max = null) {
    if (this.id) {
      const thread = store.getters['chat/threadById'](this.id);

      store.dispatch('chat/markMessageAsReadOrUnread', { thread: thread, markAsRead: false, message });
      store.commit('chat/setFirstUnreadMessageId', { thread: thread, messageId: null }); // unset after reading. TODO v2: return firstUnreadMessageId from api.setLastRead so we can update this with that value
    }
  }

  loadOlderAttachments(searchQuery = '') {
    store.dispatch('chat/loadOlderAttachments', { thread: this, searchQuery: searchQuery });
  }

  loadNewerAttachments(searchQuery = '') {
    store.dispatch('chat/loadNewerAttachments', { thread: this, searchQuery: searchQuery });
  }

  searchAttachments(searchQuery = '', page = 1) {
    store.dispatch('chat/searchAttachments', { thread: this, searchQuery: searchQuery, page: page });
  }

  reset() {
    if (this.id && this.messages.length) {
      const thread = store.getters['chat/threadById'](this.id);

      store.commit('chat/replaceMessages', { thread: thread, messages: [thread.messages[thread.messages.length - 1]] });
    }
  }

  async subscribe() {
    if (this.pusherChannel?.subscribed) {
      return new Promise((resolve) => resolve());
    }

    if (!this.id) {
      // placeholder threads don't have ID. (chat/init action)
      return new Promise((resolve) => resolve());
    }

    const existingThreadInStore = store.getters['chat/threadById'](this.id);

    if (existingThreadInStore?.pusherChannel) {
      return;
    }

    if (!this.pusherChannel) {
      this.pusherChannel =
        window.PusherInstance.channel('presence-thread-' + this.id) ||
        window.PusherInstance.subscribe('presence-thread-' + this.id);
    }

    if (this.pusherChannel?.subscribed) {
      return new Promise((resolve) => resolve());
    }

    if (this.pusherChannel) {
      await this.unsubscribe(false);
    }

    await PusherHelper.bindPusherEvent(
      this.pusherChannel,
      'client-new-message',
      (data, meta) => this.onReceiveMessage(data, meta),
      true
    );
    await PusherHelper.bindPusherEvent(
      this.pusherChannel,
      'client-update-message',
      (data, meta) => this.onUpdateMessage(data, meta),
      true
    );
    await PusherHelper.bindPusherEvent(
      this.pusherChannel,
      'client-delete-message',
      (data, meta) => this.onDeleteMessage(data, meta),
      true
    );
    await PusherHelper.bindPusherEvent(
      this.pusherChannel,
      'client-new-reaction',
      (data, meta) => this.onNewReaction(data, meta),
      true
    );
    await PusherHelper.bindPusherEvent(
      this.pusherChannel,
      'client-delete-reaction',
      (data, meta) => this.onDeleteReaction(data, meta),
      true
    );
    await PusherHelper.bindPusherEvent(
      this.pusherChannel,
      'client-start-typing',
      (data, meta) => this.onStartTyping(data, meta),
      true
    );
    await PusherHelper.bindPusherEvent(
      this.pusherChannel,
      'client-end-typing',
      (data, meta) => this.onEndTyping(data, meta),
      true
    );
    await PusherHelper.bindPusherEvent(
      this.pusherChannel,
      'client-video-call-response',
      (data, meta) => this.onVideoCallResponse(data, meta),
      true
    );

    eventBus.$on(['user-updated', 'user-deleted'], (userModelOrId) => (this.name = this.getName(this.subject)));

    return new Promise((resolve, reject) => {
      if (!this.pusherChannel) {
        reject('PusherChannel overwritten. Race condition');
      }
      if (this.pusherChannel.subscribed) {
        resolve();
      }
      PusherHelper.bindPusherEvent(this.pusherChannel, 'pusher:subscription_succeeded', (data, meta) => {
        resolve();
      });
      PusherHelper.bindPusherEvent(this.pusherChannel, 'pusher:subscription_error', (data, meta) => {
        reject(new Error('pusher:subscription_error: ' + data + '. Meta: ' + meta));
      });
    });
  }

  leave() {
    // can't leave user threads
    if (this.isUser()) {
      return;
    }

    return store.dispatch('chat/leaveThread', this);
  }

  /*onMessageAdded(messageModel) {
        store.commit('chat/appendAttachments', {attachments: messageModel.attachments, thread: this});
    }

    onUserAddDelete(userModelOrId) {
        this.name = this.getName(this.subject);
    }*/

  async unsubscribe(unsubscribePusher = true) {
    if (this.pusherChannel) {
      this.pusherChannel.unbind('client-new-message');
      this.pusherChannel.unbind('client-update-message');
      this.pusherChannel.unbind('client-delete-message');
      this.pusherChannel.unbind('client-new-reaction');
      this.pusherChannel.unbind('client-delete-reaction');
      this.pusherChannel.unbind('client-start-typing');
      this.pusherChannel.unbind('client-end-typing');
      this.pusherChannel.unbind('client-video-call-response');

      if (unsubscribePusher) {
        return await this.pusherChannel.unsubscribe();
      }
    }
    // todo eventBus.$on(['user-updated', 'user-deleted'], userModelOrId => this.name = this.getName(this.subject));
  }

  getLatestMessageLoaded() {
    return this.messages[this.messages.length - 1] || null;
  }

  getLoadedMessageById(id) {
    return store.getters['chat/messageById'](this, id);
  }

  getLoadedUnreadMessages() {
    return store.getters['chat/unreadMessages'](this);
  }

  getUnreadMessageCount() {
    if (this.muted) {
      return 0;
    }
    return this.unread;
  }

  getUnreadMentionsCount() {
    if (this.muted) {
      return 0;
    }
    return this.unreadMentions;
  }

  getMarkUnread() {
    return this.markUnread;
  }

  /**
   * @returns {*|UserModel}
   */
  getUser() {
    let id = this.getUserId();
    return store.getters['usersInternalChat/userById'](id);
  }

  /**
   * Gets first user id from participants that is not current user. except if only one user, then it would return current user id.
   * @returns number
   */
  getUserId() {
    return (
      (
        this.participants.find(
          (p) =>
            p.id &&
            (p.id !== store.state.usersInternalChat.currentUserId || // or chat with yourself
              (this.participants.length === 1 && p.id === store.state.usersInternalChat.currentUserId))
        ) || {}
      ).id || null
    );
  }

  // Note: Order of store commits is important for Thread.vue
  onReceiveMessage(messageResource, meta) {
    const thread = store.getters['chat/threadById'](this.id);
    let message = new MessageModel(messageResource);

    let realUserId = parseInt(meta.user_id);
    if (realUserId && message.userId !== realUserId) {
      console.error('User id spoofed, ignoring message from #' + realUserId);
      return;
    }

    if (store.getters['chat/messageById'](this, message.id)) {
      console.warn('duplicate message detected');
      return;
    }

    // only append the new message when the last message is loaded.
    if (
      !thread.messages.length ||
      (thread.lastMessageId && thread.lastMessageId === (thread.getLatestMessageLoaded() || {}).id)
    ) {
      store.commit('chat/appendMessage', { message: message, thread: thread });
    }

    // update lastMessageId
    store.commit('chat/setLastMessageId', { thread: thread, messageId: message.id });
    store.commit('chat/setLastMessageTime', { thread: thread, messageTime: message.createdAt });

    // increment unread counter for thread if not your own message
    if (message.userId !== store.state.usersInternalChat.currentUserId) {
      store.commit('chat/setUnread', { thread: thread, unread: thread.unread + 1 });
      if (message.body.includes(store.getters['usersInternalChat/currentUser'].getIdentifier())) {
        // Includes mention to current user
        store.commit('chat/setUnreadMentions', { thread: thread, unreadMentions: thread.unreadMentions + 1 });
      }
    }

    let attachments = message.attachments.concat(thread.attachments).slice(0, 10); // removes the last attachment if necessary (always 10 per page)
    // append the message attachments if we are on page 1 of the attachments.
    if (thread.attachmentsPage === 1) {
      store.commit('chat/setAttachments', { attachments: attachments, thread: thread });
    }

    // emit event
    eventBus.$emit('chat@AFTER_RECEIVE_MESSAGE', message);

    // video call status message
    if (
      message.bodyType === 'VIDEO_CALL_STATUS' &&
      message?.meta?.participant_response === 'declined' &&
      this.$root.user.voip_status !== 'CALLING'
    ) {
      audio.declined.play().catch(() => {});
    }

    // video calling modal
    let room, userId;
    if (message.bodyType === 'VIDEO_CALL') {
      room = parseInt(message?.meta?.video_room_id) || null;
      userId = parseInt(message?.meta?.initiator_user_id) || null;
    }
    if (message.bodyType === 'ZOOM_CALL' && message.meta?.join_url) {
      room = message?.meta?.join_url;
      userId = message.userId;
    }
    if (room && userId !== store.state.usersInternalChat.currentUserId) {
      eventBus.$emit('chat@VIDEO_CALL_STARTED', { userId, room, message });
    }

    // send browser notification
    notifyThreadMessage(thread, message);
  }

  onDeleteMessage(data, meta) {
    let messageId = data.messageId;
    let realUserId = parseInt(meta.user_id);
    let message = store.getters['chat/messageById'](this, messageId);
    let realUser = store.getters['usersInternalChat/userById'](realUserId);
    let threadParticipantOwner = this.participants.filter((p) => p.id === realUser.id && p.owner === true);

    if (meta.user_id && message && !threadParticipantOwner) {
      console.error('User not authorized to delete message, ignoring message-delete from #' + realUser.id);
      return;
    }

    if (this.lastRead < message.createdAt) {
      store.commit('chat/setUnread', { thread: this, unread: this.unread - 1 });
      if (message.body.includes(store.getters['usersInternalChat/currentUser'].getIdentifier())) {
        // Includes mention to current user
        store.commit('chat/setUnreadMentions', { thread: this, unreadMentions: this.unreadMentions - 1 });
      }
    }

    try {
      this.searchAttachments('', 1);
      store.commit('chat/rollbackMessage', { thread: this, message: message });
      if (messageId === this.lastMessageId) {
        this.lastMessageId = null;
      }
      this.messages.forEach((m) => {
        if (m.parent && m.parent.id === messageId) {
          m.parent = null;
        }
      });
    } catch (e) {
      // it's fine, message is not loaded
    }
  }

  onUpdateMessage(messageResource, meta) {
    let newMessage = new MessageModel(messageResource);
    let realUserId = parseInt(meta.user_id);
    if (realUserId && newMessage.userId !== realUserId) {
      console.error('User id spoofed, ignoring message-update from #' + realUserId);
      return;
    }

    try {
      store.commit('chat/updateMessage', { thread: this, messageId: newMessage.id, newMessage: newMessage });
    } catch (e) {
      // it's fine, message is not loaded
    }
  }

  onNewReaction(data, meta) {
    let realUserId = parseInt(meta.user_id);
    if (realUserId && data.user_id !== realUserId) {
      console.error('User id spoofed, ignoring reaction from #' + realUserId);
      return;
    }

    let message = store.getters['chat/messageById'](this, data.message_id);

    if (message) {
      store.commit('chat/incrementReaction', { messageModel: message, reactionResource: data });
      eventBus.$emit('chat@REACTION_RECEIVED', { message, emoji: data.emoji });
    }
  }

  onDeleteReaction(data, meta) {
    let realUserId = parseInt(meta.user_id);
    if (realUserId && data.user_id !== realUserId) {
      console.error('User id spoofed, ignoring message from #' + realUserId);
      return;
    }

    let message = store.getters['chat/messageById'](this, data.message_id);

    if (message) {
      store.commit('chat/decrementReaction', { messageModel: message, reactionResource: data });
    }
  }

  startTyping() {
    if (this.id && this.pusherChannel && this.pusherChannel.subscribed) {
      this.pusherChannel.trigger('client-start-typing', '');
    }
  }

  endTyping() {
    if (this.id && this.pusherChannel && this.pusherChannel.subscribed) {
      this.pusherChannel.trigger('client-end-typing', '');
    }
  }

  onStartTyping(data, meta) {
    const userId = meta.user_id;
    if (!userId) {
      return;
    }

    if (!this.typing[userId]) {
      // set typing object with key=userId and value=debounced-delete-function
      this.typing[userId] = debounce(
        (userId) => {
          delete this.typing[userId];
        },
        1500,
        { leading: false, trailing: true }
      );
    }

    // call debounced delete
    this.typing[userId](userId);
  }

  onVideoCallResponse(response, meta) {
    // someone declined a call started by current user
    if (
      parseInt(response.videoCall.userId) === store.state.usersInternalChat.currentUserId &&
      response.action === 'decline'
    ) {
      window.$.growl.warning({
        size: 'large',
        duration: 5000,
        namespace: 'growl',
        title: '',
        location: 'tc',
        message:
          store.getters['usersInternalChat/userById'](meta.user_id)?.getDisplayName() + ' declined your video meeting',
      });
      notifyViaBrowser(
        'Trengo Video Meeting',
        'Declined by ' + store.getters['usersInternalChat/userById'](meta.user_id)?.getDisplayName()
      );
    }
  }

  onEndTyping(data, meta) {
    const userId = meta.user_id;
    if (!userId) {
      return;
    }

    if (this.typing[userId]) {
      delete this.typing[userId];
    }
  }

  async sendMessage(msg, attachmentIds = [], parentId = null) {
    const messageBody = msg.messageBody;
    if (!window.stripHtml(messageBody).length && attachmentIds.length === 0) {
      return;
    }

    // msg before (only attachment ID's and parentId, not resources)
    let message = new MessageModel({
      userId: store.state.usersInternalChat.currentUserId,
      toUserId: this.isUser() ? this.getUserId() : null,
      threadId: this.id,
      body: clean(sanitize(messageBody)), // tags also get removed when loading. this is just to clean a bit, not to prevent xss
      createdAt: Math.floor(new Date().getTime() / 1000),
      attachmentIds: attachmentIds,
      parentId: parentId,
      bodyType: msg.bodyType ? msg.bodyType : null,
      meta: msg.meta ? msg.meta : null,
    });

    eventBus.$emit('chat@BEFORE_MESSAGE_SENT', message);
    let result = await store.dispatch('chat/sendMessage', { message: message, thread: this });
    if (result) {
      // broadcast to client-events
      if (this.id && this.pusherChannel?.subscribed) {
        //console.log('sending no prob, we already bound', this.pusherChannel);
        this.pusherChannel.trigger('client-new-message', result);
      } else if (this.id) {
        // id is set but not (yet) subscribed. (race condition?)
        //console.log('Not yet subscribed on send. (race condition?)', this.pusherChannel);
        this.subscribe()
          .then(() => {
            this.pusherChannel.trigger('client-new-message', result);
          })
          .catch(() => {
            $.growl.error({
              size: 'large',
              duration: 1000,
              namespace: 'growl',
              title: '',
              message: 'Something went wrong when sending your message',
              location: 'tc',
            });
            throw new Error('Something went wrong when sending your message');
          });
      }

      let messageModel = new MessageModel(result); // msg after (contains attachments and parent message)
      let attachments = messageModel.attachments.concat(this.attachments).slice(0, 10); // removes the last attachment if necessary (always 10 per page)

      // update lastMessageId
      store.commit('chat/setLastMessageId', { thread: this, messageId: messageModel.id });
      store.commit('chat/setLastMessageTime', { thread: this, messageTime: messageModel.createdAt });

      // append the message attachments if we are on page 1 of the attachments.
      if (this.attachmentsPage === 1) {
        store.commit('chat/setAttachments', { attachments: attachments, thread: this });
      }

      eventBus.$emit('chat@AFTER_MESSAGE_SENT', messageModel);
    }
    return result;
  }

  // refactor: update and send could/should be on messageModel
  async updateMessage(message) {
    if (message.attachments.length) message.attachmentIds = message.attachments.map((a) => a.id);

    let resource = await store.dispatch('chat/updateMessage', { thread: this, message: message });
    if (resource) {
      this.pusherChannel.trigger('client-update-message', resource);
    }
    return resource;
  }

  toggleMute() {
    return store.dispatch('chat/toggleMuted', this);
  }

  threadStarted() {
    return !!this.id;
  }

  isGroup() {
    return this.identifier.startsWith('#');
  }

  isUser() {
    return this.identifier.startsWith('@');
  }

  setNameAndIdentifier(subject) {
    this.name = this.getName(subject);
    this.identifier = this.getIdentifier(subject);
  }

  getName(subject) {
    // only groups have subject. otherwise assume it's a user
    if (!subject) {
      return this.getUser().getDisplayName();
    } else {
      return subject;
    }
  }

  userIsOwner() {
    return !!this.participants.find((p) => p.owner && p.id === store.getters['usersInternalChat/currentUser'].id);
  }

  userIsParticipant() {
    return !!this.participants.find((p) => p.id === store.getters['usersInternalChat/currentUser'].id);
  }

  getIdentifier(subject) {
    if (!subject) {
      return this.getUser().getIdentifier();
    } else {
      // outdated electron cannot use unicode properties in regex, luckily we don't really need identifiers since they are mostly used in URLs
      if (window.isElectron) {
        return '#' + this.name.toLowerCase().replace(/ +/g, '-').trim();
      } else {
        return (
          '#' + this.name.toLowerCase().replace(/ +/g, '-').replace(new RegExp('[^\\p{L}\\p{N}-]+', 'uig'), '').trim()
        );
      }
    }
  }
}
