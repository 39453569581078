import ThreadModel from '../Models/ThreadModel';

export default {
  // refactor: return AxiosPromise just like Messages.js. or refactor messages.js to return models as well
  async list() {
    let r = (await axios.get('/api/v2/internal_chat/threads')).data;

    return r.data.map((thread) => new ThreadModel(thread));
  },

  get(threadId) {
    return axios.get('/api/v2/internal_chat/threads/' + threadId);
  },

  getAttachments(threadId, page = 1, searchQuery) {
    return axios.get(
      '/api/v2/internal_chat/threads/' + threadId + '/attachments?page=' + page + '&search=' + searchQuery
    );
  },

  read(threadId) {
    return axios.post('/api/v2/internal_chat/threads/' + threadId + '/read');
  },

  unread(threadId, messageId) {
    return axios.post('/api/v2/internal_chat/threads/' + threadId + '/unread/' + messageId);
  },

  leave(threadId) {
    return axios.post('/api/v2/internal_chat/threads/' + threadId + '/leave');
  },

  mute(threadId, muted = true) {
    return axios.post('/api/v2/internal_chat/threads/' + threadId + '/mute', { value: muted });
  },

  // group owner actions
  create(thread) {
    return axios.post('/api/v2/internal_chat/threads', thread);
  },
  update({ id, subject, participants }) {
    return axios.put('/api/v2/internal_chat/threads/' + id, { participants: participants, subject: subject });
  },
  delete(threadId) {
    return axios.delete('/api/v2/internal_chat/threads/' + threadId);
  },
};
