import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_row_item_loader = _resolveComponent("list-row-item-loader")!
  const _component_list_row_item = _resolveComponent("list-row-item")!

  return (_openBlock(), _createBlock(_component_list_row_item, { "data-test-list-row-item": _ctx.dataTestListRowItem }, {
    default: _withCtx(() => [
      _createVNode(_component_list_row_item_loader, { "data-test-list-row-item-loader": _ctx.dataTestListRowItemLoader }, null, 8 /* PROPS */, ["data-test-list-row-item-loader"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["data-test-list-row-item"]))
}