import { PERMISSION, SETTINGS_URL } from '@/Configs/Constants';

import { ROUTE_NAME } from '../constants';

import type { RouteRecord } from '../types';

const settingsInboxRoutes: RouteRecord[] = [
  {
    name: ROUTE_NAME.SETTINGS_INBOX,
    path: SETTINGS_URL.ADMIN__APPS_INBOX,
    component: () => {
      return import(/* webpackChunkName: "SettingsInbox" */ '@/components/Admin/SettingsInbox/SettingsInbox.vue');
    },
    meta: {
      permissionRequired: PERMISSION.SETTINGS__INBOX,
    },
  },
];
export default settingsInboxRoutes;
