<template>
  <t-modal
    v-model="computedValue"
    class="web-recipients-modal"
    variant="narrow"
    :title="$t('broadcast.add_recipients')"
    initial-min-height="22rem"
    @close="onCancel"
  >
    <template #subheader>
      <div class="tabs">
        <t-tab-item
          :label="$t('broadcast.contacts')"
          :is-active="activeTab === 'contacts'"
          size="sm"
          data-test="contacts-tab-btn"
          @click="activeTab = 'contacts'"
        />
        <t-tab-item
          :label="$t('broadcast.groups')"
          :is-active="activeTab === 'groups'"
          size="sm"
          data-test="groups-tab-btn"
          @click="activeTab = 'groups'"
        />
        <t-tab-item
          :label="$t('broadcast.enter_manually')"
          :is-active="activeTab === 'manual'"
          size="sm"
          data-test="manual-tab-btn"
          @click="activeTab = 'manual'"
        />
      </div>
    </template>

    <main class="web-recipients-modal-main">
      <wab-recipients-contacts
        v-if="activeTab === 'contacts'"
        :selected="selectedContacts"
        :selected-groups="selectedGroups"
        :manual="manualSelected"
        @select="onSelect"
      />
      <wab-recipients-groups v-if="activeTab === 'groups'" :selected="selectedGroups" @select="onGroupSelect" />
      <wab-recipients-manual
        v-if="activeTab === 'manual'"
        v-model="computedManualSelected"
        :contacts="selectedContacts"
      />
    </main>

    <template #footer>
      <div
        class="t-text-desktop-label-md mr-0 flex items-center justify-center text-grey-900 md:mr-auto md:justify-start"
        data-test="selected-number"
      >
        {{ $t('broadcast.broadcast_number_recipients_selected', { total: selectedNumbers }) }}
      </div>

      <t-button btn-style="secondary" data-test="modal-cancel-recipient-btn" @click="onCancel">
        {{ $t('broadcast.cancel') }}
      </t-button>
      <t-button data-test="modal-add-recipient-btn" @click="onAdd">
        {{ edit ? $t('broadcast.update') : $t('broadcast.add') }}
      </t-button>
    </template>
  </t-modal>
</template>

<script>
import WabRecipientsContacts from '@/components/WabBroadcasting/components/WabRecipientsContacts';
import WabRecipientsGroups from '@/components/WabBroadcasting/components/WabRecipientsGroups';
import WabRecipientsManual from '@/components/WabBroadcasting/components/WabRecipientsManual';

export default {
  name: 'WabRecipientsModal',
  emits: ['update:modelValue', 'select', 'group-select', 'manual', 'add', 'cancel'],
  components: {
    WabRecipientsGroups,
    WabRecipientsContacts,
    WabRecipientsManual,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    selectedContacts: {
      type: Object,
      default: () => ({}),
    },
    selectedGroups: {
      type: Object,
      default: () => ({}),
    },
    manualSelected: {
      type: Array,
      default: () => [],
    },
    edit: {
      type: Boolean,
      default: false,
    },
    initialListLength: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    searchKey() {
      this.page = 1;
      this.list = [];
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    computedManualSelected: {
      get() {
        return this.manualSelected;
      },
      set(value) {
        this.$emit('manual', value);
      },
    },
    selectedNumbers() {
      return (
        Object.values(this.selectedContacts).filter((o) => o.isSelected).length + this.computedManualSelected.length
      );
    },
  },
  data() {
    return {
      activeTab: 'contacts',
    };
  },
  methods: {
    onSelect(item) {
      this.$emit('select', item);
    },
    onGroupSelect(item) {
      this.$emit('group-select', item);
    },
    onAdd() {
      const object = {};

      object.contacts = this.selectedContacts;
      object.manual = this.computedManualSelected;
      object.groups = this.selectedGroups;

      this.$emit('add', object);
      this.computedValue = false;
    },
    async onCancel() {
      this.$emit('cancel');
      this.computedValue = false;
    },
  },
};
</script>

<style lang="scss" src="./WebRecipientsModalGlobal.scss" />
