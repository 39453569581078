<template>
  <t-alert
    v-model="show"
    :title="title"
    :cancel-text="cancelText"
    :confirm-text="confirmText"
    :show-cancel="showCancel"
    :show-confirm="showConfirm"
    :show-close="showClose"
    :type="type"
    :delete-icon="deleteIcon"
    class="t-text-desktop-paragraph-sm sm:t-text-desktop-paragraph-md text-grey-800"
    @cancel="onCancel"
    @confirm="onConfirm"
  >
    <span v-html="message" />
  </t-alert>
</template>

<script>
import dompurify from 'dompurify';

import eventBus from '@/eventBus';

export default {
  name: 'TConfirm',
  data() {
    return {
      message: '',
      type: '',
      show: false,
      showCancel: true,
      showConfirm: true,
      cancelText: 'Cancel',
      confirmText: 'Confirm',
      deleteIcon: false,
      title: 'Confirm',
      showClose: true,
    };
  },
  mounted() {
    this.confirmText = this.$t('general.delete');
    this.cancelText = this.$t('general.cancel');
    this.title = this.$t('general.delete_item');

    eventBus.$on(
      't-alert-confirm',
      ({ message, type, showCancel, showClose, cancelText, confirmText, title, deleteIcon }) => {
        this.message = dompurify.sanitize(message);
        this.cancelText = cancelText || this.$t('general.cancel');
        this.confirmText = confirmText || this.$t('general.delete');
        this.showCancel = showCancel;
        this.type = type;
        this.title = title || this.$t('general.delete_item');
        this.showClose = showClose;
        this.show = true;
        this.deleteIcon = deleteIcon;
      }
    );
  },
  methods: {
    onCancel() {
      eventBus.$emit('t-alert-confirm-reject');
      this.show = false;
    },
    onConfirm() {
      eventBus.$emit('t-alert-confirm-resolve');
      this.show = false;
    },
    closeAlert() {
      this.show = false;
    },
  },
};
</script>
