<script>
import Generic from '../Crm/Generic';

export default {
  extends: Generic,

  data() {
    return {
      settings: {
        updateNotes: false,
        deleteNotes: false,
        name: false,
        firstName: true,
        lastName: true,
        hasStage: false,
        hasLinks: false,
        hasDealStatus: false,
        hasNotesSubject: true,
        hasCustomNoteOwner: true,
        hasContactStatus: true,
      },
    };
  },
};
</script>
