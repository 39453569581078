import { PERIOD_TYPE, CONVERSATION_TYPE } from '@/Configs/Constants';

import type { AgencyUsageResponse, PeriodBreakdownItem, UpcomingPeriodBreakdown } from '../modules/usage';
export const mockedUpcomingPeriod: UpcomingPeriodBreakdown = {
  upcoming_standard_conversations_count: 2,
  upcoming_ai_conversations_count: 0,
};

export const mockedMonthlyPeriod: PeriodBreakdownItem = {
  period_type: PERIOD_TYPE.MONTH,
  start_date: 1714341600000,
  end_date: 1716933599000,
  conversations: [
    {
      conversation_type: CONVERSATION_TYPE.STANDARD,
      finished_conversations_count: 950,
      in_progress_conversations_count: 500,
      commitment_in_bundles: 10,
    },
    {
      conversation_type: CONVERSATION_TYPE.INTELLIGENT,
      finished_conversations_count: 0,
      in_progress_conversations_count: 0,
      commitment_in_bundles: 0,
    },
  ],
  carrierCosts: {
    used: 0.04,
    commitment_in_bundles: 1,
  },
};

export const mockedYearlyPeriod: PeriodBreakdownItem = {
  period_type: PERIOD_TYPE.YEAR,
  start_date: 1708517806000,
  end_date: 1740053806000,

  conversations: [
    {
      conversation_type: CONVERSATION_TYPE.STANDARD,
      finished_conversations_count: 60,
      in_progress_conversations_count: 5,
      commitment_in_bundles: 3,
    },
    {
      conversation_type: CONVERSATION_TYPE.INTELLIGENT,
      finished_conversations_count: 0,
      in_progress_conversations_count: 0,
      commitment_in_bundles: 0,
    },
  ],
  carrierCosts: {
    used: 10,
    commitment_in_bundles: 1,
  },
};

export const mockedUsageOverview: AgencyUsageResponse = {
  upcoming_period_breakdown: mockedUpcomingPeriod,
  current_period_breakdown: mockedMonthlyPeriod,
  previous_periods_break_down: [mockedYearlyPeriod],
};
