<template>
  <div style="height: calc(100% - 80px)" class="flex justify-center overflow-auto">
    <div class="m-auto flex w-full max-w-md flex-col items-center justify-center gap-3 px-5">
      <t-thumbnail size="large" class="mb-3 flex-shrink-0">
        <lock-2-linear />
      </t-thumbnail>
      <h3 class="t-text-h3 text-center">
        {{ $t('auth.two_factor_authentication_setup_step_zero_title') }}
      </h3>
      <p class="t-text-md text-center text-grey-800">
        {{ $t('auth.two_factor_authentication_setup_step_zero_description') }}
      </p>
      <div
        class="mb-4 mt-auto flex w-full flex-col-reverse items-stretch justify-center gap-3 md:flex-row md:items-start"
      >
        <t-button btn-style="secondary" href="/auth/logout">
          {{ $t('auth.logout') }}
        </t-button>
        <t-button @click="handleContinue">
          {{ $t('auth.two_factor_authentication_setup_step_zero_cta_label') }}
        </t-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Lock2Linear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import { SETUP_STEP } from '../constants';

export default defineComponent({
  name: 'StepZero',
  emits: ['goToStep'],
  components: {
    Lock2Linear,
  },
  methods: {
    handleContinue() {
      this.$emit('goToStep', SETUP_STEP.ONE);
    },
  },
});
</script>
