<template>
  <div class="filter-dropdown flex flex-row items-center" data-test="filter-reporting-dropdown">
    <t-popover v-model="isFilterOpen" click-outside-close>
      <template #trigger>
        <t-button btn-style="secondary" size="sm" data-test="filter-reporting-dropdown-button">
          <div class="flex flex-row items-center">
            <span>{{ $t('reports_v2.filter_count', { count: filtersCount }) }}</span>
            <filter-linear class="ml-2 text-grey-400" size="1.5rem" />
          </div>
        </t-button>
      </template>

      <template #content>
        <div class="absolute z-50 mt-2 w-80 rounded-xl border border-grey-300 bg-white p-2 shadow-500">
          <ul class="mb-0 pl-0">
            <teams
              v-if="reportStore.reportingFilters.hasTeam"
              data-test="teams"
              :active-teams="selectedFilters.team_ids"
              @filter-updated="applyFilters"
            />
            <agents
              v-if="reportStore.reportingFilters.hasAgent"
              data-test="agents"
              :active-agents="selectedFilters.user_ids"
              @filter-updated="applyFilters"
            />
            <channels
              v-if="reportStore.reportingFilters.hasChannel"
              data-test="channels"
              :active-channels="selectedFilters.channel_ids"
              @filter-updated="applyFilters"
            />
            <conversation-direction
              v-if="reportStore.reportingFilters.hasConversation"
              data-test="conversation-direction"
              :active-direction="selectedFilters.direction"
              @filter-updated="applyFilters"
            />
            <labels
              v-if="reportStore.reportingFilters.hasLabel"
              data-test="labels"
              :active-labels="selectedFilters.label_ids"
              @filter-updated="applyFilters"
            />
            <status
              v-if="reportStore.reportingFilters.hasStatus"
              :active-status="selectedFilters.statuses"
              data-test="status"
              @filter-updated="applyFilters"
            />
            <business-hours
              v-if="reportStore.reportingFilters.hasBusinessHours"
              :active-item="selectedFilters.with_business_hours"
              data-test="business-hours"
              @filter-updated="applyFilters"
            />
            <date-filter
              v-if="reportStore.reportingFilters.hasTicketAssignedAt"
              data-test="assigned-at"
              :label="$t('reports_v2.assigned_at')"
              type="assigned"
              :active-dates="{ start: selectedFilters.assigned_start_date, end: selectedFilters.assigned_end_date }"
              @filter-updated="applyDateFilter"
            />
            <date-filter
              v-if="reportStore.reportingFilters.hasTicketClosedAt"
              data-test="closed-at"
              :label="$t('reports_v2.closed_at')"
              type="closed"
              :active-dates="{ start: selectedFilters.closed_start_date, end: selectedFilters.closed_end_date }"
              @filter-updated="applyDateFilter"
            />
            <date-filter
              v-if="reportStore.reportingFilters.hasTicketCreatedAt"
              data-test="created-at"
              :label="$t('reports_v2.created_at')"
              type="created"
              :active-dates="{ start: selectedFilters.created_start_date, end: selectedFilters.created_end_date }"
              @filter-updated="applyDateFilter"
            />
          </ul>
        </div>
      </template>
    </t-popover>
    <div v-if="ticketInsightsStore.ticketsAmount" class="ml-4">
      <p class="t-text-sm m-0 text-grey-600">
        {{
          $tc('reports_v2.tickets', ticketInsightsStore.ticketsAmount, {
            amount: ticketInsightsStore.ticketsAmount,
          })
        }}
      </p>
    </div>
    <div v-if="ticketInsightsStore.agentsAmount" class="ml-4">
      <p class="t-text-sm m-0 text-grey-600">
        {{
          $tc('reports_v2.agents', ticketInsightsStore.agentsAmount, {
            amount: ticketInsightsStore.agentsAmount,
          })
        }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { FilterLinear } from '@trengo/trengo-icons';
import moment from 'moment';
import { defineComponent } from 'vue';

import { useReportStore } from '@/components/Reporting/store';

import Agents from './components/Agents.vue';
import BusinessHours from './components/BusinessHours.vue';
import Channels from './components/Channels.vue';
import ConversationDirection from './components/ConversationDirection.vue';
import DateFilter from './components/DateFilter.vue';
import Labels from './components/Labels.vue';
import Status from './components/Status.vue';
import Teams from './components/Teams.vue';
import eventBus from '@/eventBus';

export type Filter = {
  channel_ids: number[];
  label_ids: number[];
  team_ids: number[];
  user_ids: number[];
  survey_ids: number[];
  closed_start_date: Date;
  closed_end_date: Date;
  created_start_date: Date;
  created_end_date: Date;
  assigned_start_date: Date;
  assigned_end_date: Date;
  direction: 'OUTBOUND' | 'INBOUND' | 'ALL';
  with_business_hours: boolean;
  statuses: [];
};

export default defineComponent({
  name: 'FilterDropdown',
  components: {
    Agents,
    DateFilter,
    FilterLinear,
    Teams,
    Channels,
    Status,
    ConversationDirection,
    Labels,
    BusinessHours,
  },
  data() {
    return {
      isFilterOpen: false,
      ticketInsightsStore: useReportStore(),
      reportStore: useReportStore(),
      selectedFilters: {} as Filter,
    };
  },

  beforeMount() {
    if (this.$route.query.filters) {
      this.selectedFilters = JSON.parse(this.$route.query.filters);
    }
  },
  unmounted() {
    eventBus.$off('reporting-filter', this.selectedFilters);
  },
  computed: {
    filtersCount() {
      const keysToCount = ['label_ids', 'channel_ids', 'team_ids', 'user_ids'];

      return keysToCount.reduce((count, key) => {
        return count + (Array.isArray(this.selectedFilters[key]) ? this.selectedFilters[key].length : 0);
      }, 0);
    },
  },
  methods: {
    removeEmptyKeys(filter: Filter): Filter {
      const result: Filter = {} as Filter;

      for (const key in filter) {
        const value = filter[key];

        if (Array.isArray(value) && value.length === 0) {
          continue;
        }

        if (typeof value === 'string' && value.trim() === '') {
          continue;
        }

        if (value === 'ALL') {
          continue;
        }

        result[key] = value;
      }

      return result;
    },
    applyDateFilter({ type, startDate, endDate }: { type: string; startDate: Date; endDate: Date }) {
      if (!startDate && !endDate) {
        this.applyFilters({ [`${type}_start_date`]: '', [`${type}_end_date`]: '' });
        return;
      }
      const start = moment(startDate).tz(this.$root.user.timezone).format('YYYY-MM-DDT00:00:ssZ');
      const end = moment(endDate).tz(this.$root.user.timezone).format('YYYY-MM-DDT23:59:ssZ');
      const encodedStartDate = encodeURIComponent(start);
      const encodedEndDate = encodeURIComponent(end);
      this.applyFilters({ [`${type}_start_date`]: encodedStartDate, [`${type}_end_date`]: encodedEndDate });
    },
    applyFilters(newValue: Filter) {
      const currentQuery = this.$route.query;

      const existingFilters = currentQuery.filters ? JSON.parse(currentQuery.filters) : {};

      const mergedFilters = { ...existingFilters, ...newValue };

      const filteredFilters = this.removeEmptyKeys(mergedFilters);

      this.$router.replace({ query: { filters: JSON.stringify(filteredFilters) } });

      this.selectedFilters = filteredFilters;

      this.isFilterOpen = false;
      this.$nextTick(() => {
        eventBus.$emit('reporting-filter', filteredFilters);
      });
    },
  },
});
</script>

<style lang="scss" src="./FilterDropdown.scss" />
