<template>
  <div v-show="isLoaded" class="onboarding-channel-landing-page">
    <div class="onboarding-channel-landing-page__wrapper">
      <div class="onboarding-channel-landing-page__channels">
        <channels-list />
      </div>
      <div class="onboarding-channel-landing-page__presentation">
        <presentation-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ArrowRightLinear } from '@trengo/trengo-icons';

import ChannelsList from './components/ChannelsList';
import OnboardingSkipModal from './components/OnboardingSkipModal';
import PresentationView from './components/PresentationView';

export default {
  name: 'OnboardingChannelLandingPage',
  components: { ArrowRightLinear, ChannelsList, OnboardingSkipModal, PresentationView },
  data() {
    return {
      isLoaded: false,
    };
  },
  created() {
    if (this.$root.onboarding?.step < 6) {
      this.$router.push('/onboard-channels');
      this.isLoaded = true;
      return;
    }
    if (!this.$root.onboarding?.step) {
      this.$router.push('/tickets');
      return;
    }
    if (!this.$root.onboarding?.step) {
      this.preventToGoBack();
      return;
    }
    this.isLoaded = true;
  },
  methods: {
    preventToGoBack() {
      window.history.forward();
    },
  },
};
</script>

<style src="./OnboardingChannelLandingPage.scss" lang="scss" scoped />
