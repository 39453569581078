import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row justify-end text-right" }
const _hoisted_2 = { class: "t-text-sm m-0 text-grey-800" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.score) + "/5", 1 /* TEXT */),
    _createElementVNode("img", {
      class: "ml-3 w-[20px]",
      src: _ctx.getEmoji
    }, null, 8 /* PROPS */, _hoisted_3)
  ]))
}