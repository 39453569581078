<template>
  <div :class="recipientClasses" v-bind="$attrs">
    <slot>
      <span class="truncate">
        <span class="name t-text-md">{{ title }}</span>
        <span v-if="showPhone" class="phone t-text-md">{{ phone }}</span>
      </span>
      <button v-if="showDelete" class="close-btn" data-test="delete-recipe-btn" @click="$emit('delete')">
        <cross-linear width="16px" height="16px" />
      </button>
    </slot>
  </div>
</template>

<script>
import { CrossLinear } from '@trengo/trengo-icons';

export default {
  name: 'RecipientsBadge',
  emits: ['delete'],
  props: {
    name: {
      type: String,
      default: '',
    },
    group: {
      type: String || null,
      default: null,
    },
    phone: {
      type: String,
      default: '',
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    isFailedRecipients: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: 'success',
      validate: (value) => ['success', 'failed'].includes(value),
    },
  },
  components: { CrossLinear },
  computed: {
    showPhone() {
      return !this.group && this.name;
    },
    title() {
      return this.group || this.name || this.phone;
    },
    recipientClasses() {
      let noDeleteClass = '';
      if (!this.showDelete) {
        noDeleteClass = 'no-delete';
      }
      return [
        'recipients-badge text-ellipsis text-truncate max-w-[200px]',
        noDeleteClass,
        this.group ? 'group-badge' : `${this.variant} regular-badge`,
      ];
    },
  },
};
</script>

<style scoped lang="scss" src="./RecipientsBadge.scss" />
