import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export const LOCALE_KEYS = {
  EN_GB: 'en-GB',
  NL_NL: 'nl-NL',
  FR_FR: 'fr-FR',
  DE_DE: 'de-DE',
  IT_IT: 'it-IT',
  ES_ES: 'es-ES',
} as const;

export const USER_LOCALE_KEYS = {
  EN_GB: 'en-GB',
  NL_NL: 'nl-NL',
  DE_DE: 'de-DE',
  ES_ES: 'es-ES',
  FR_FR: 'fr-FR',
  AR_AR: 'ar-AR',
  ID_ID: 'id-ID',
  PT_BR: 'pt-BR',
  RU_RU: 'ru-RU',
  IT_IT: 'it-IT',
} as const;

export type Locales = keyof typeof LOCALE_KEYS;
export type UserLocales = keyof typeof USER_LOCALE_KEYS;

export const useLocales = (isUserSettings?: boolean) => {
  const { t } = useI18n();
  const locales = computed(() => {
    const userSettingsLocales = {
      [USER_LOCALE_KEYS['EN_GB']]: t('user_profile.language_en_GB'),
      [USER_LOCALE_KEYS['NL_NL']]: t('user_profile.language_nl_NL'),
      [USER_LOCALE_KEYS['DE_DE']]: t('user_profile.language_de_DE'),
      [USER_LOCALE_KEYS['ES_ES']]: t('user_profile.language_es_ES'),
      [USER_LOCALE_KEYS['FR_FR']]: t('user_profile.language_fr_FR'),
      [USER_LOCALE_KEYS['AR_AR']]: t('user_profile.language_ar_AR'),
      [USER_LOCALE_KEYS['ID_ID']]: t('user_profile.language_id_ID'),
      [USER_LOCALE_KEYS['PT_BR']]: t('user_profile.language_pt_BR'),
      [USER_LOCALE_KEYS['RU_RU']]: t('user_profile.language_ru_RU'),
      [USER_LOCALE_KEYS['IT_IT']]: t('user_profile.language_it_IT'),
    };

    const outboundTranslationLocales = {
      [LOCALE_KEYS['EN_GB']]: t('tickets.lang__en_GB'),
      [LOCALE_KEYS['NL_NL']]: t('tickets.lang__nl_NL'),
      [LOCALE_KEYS['FR_FR']]: t('tickets.lang__fr_FR'),
      [LOCALE_KEYS['DE_DE']]: t('tickets.lang__de_DE'),
      [LOCALE_KEYS['IT_IT']]: t('tickets.lang__it_IT'),
      [LOCALE_KEYS['ES_ES']]: t('tickets.lang__es_ES'),
    };

    return isUserSettings ? userSettingsLocales : outboundTranslationLocales;
  });

  return { locales };
};
