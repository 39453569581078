<script type="text/babel">
export default {
  name: 'InboundCall',
  emits: ['ignore', 'accept', 'declineCall'],
  data() {
    return {};
  },

  props: {
    call: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    ignore() {
      if (this.call.accepting) {
        return;
      }

      this.$emit('ignore');
    },

    accept() {
      if (this.call.accepting) {
        return;
      }

      this.$emit('accept');
    },

    decline() {
      if (this.call.accepting) {
        return;
      }

      this.$emit('declineCall');
    },

    callerHasProfile() {
      return this.call.contact.profile && this.call.contact.profile[0];
    },
  },
};
</script>
<template>
  <div class="tab-inbound-call mt-16 flex h-full min-w-0 select-none flex-col items-center text-white">
    <div class="w-full">
      <div class="mx-4 mt-4 justify-center text-ellipsis text-sm text-white">
        <span class="text-ellipsis text-grey-500">
          <i class="material-icons text-xs text-green">call_received</i>
          Inbound call at:
          <strong>{{ call.channel.title }}</strong>
        </span>
      </div>

      <div class="my-4 mt-6">
        <i class="material-icons voip-contact-circle">account_circle</i>
      </div>
      <span class="text-md font-bold">
        {{ call.contact.full_name }}
      </span>
      <div>{{ call.contact.formatted_phone }}</div>

      <div v-if="callerHasProfile()" class="mb-2 flex justify-center">
        <router-link
          :to="'/profiles/' + call.contact.profile[0].id"
          class="
            mt-2
            flex
            cursor-pointer
            items-center
            justify-center
            text-ellipsis
            rounded
            border-2 border-grey-600
            px-2
            py-1
            leading-none
            text-white
            hover:brightness-90
          "
          style="display: inline-flex; vertical-align: middle"
        >
          <i class="material-icons material-icons-xs text-grey-500">portrait</i>
          <span class="pl-1 text-grey-500">{{ call.contact.profile[0].name }}</span>
        </router-link>
      </div>

      <div class="my-4 mt-12 flex select-none items-center justify-center">
        <div class="mr-12">
          <div
            class="flex cursor-pointer items-center justify-center rounded-full bg-orange hover:brightness-90"
            style="width: 50px; height: 50px"
            @click="ignore()"
          >
            <i class="material-icons">call_end</i>
          </div>
          <div class="mt-1 flex justify-center text-xs text-grey-500">Ignore</div>
        </div>
        <div>
          <div
            class="flex cursor-pointer items-center justify-center rounded-full bg-green hover:brightness-90"
            style="width: 50px; height: 50px"
            @click="accept()"
          >
            <span v-if="!call.accepting"><i class="material-icons">call</i></span>
            <span v-if="call.accepting" class="fa fa-spinner fa-spin"></span>
          </div>
          <div class="mt-1 flex justify-center text-xs text-grey-500">Accept</div>
        </div>
      </div>
    </div>
  </div>
</template>
