<template>
  <div>
    <t-table-list alternate class="relative p-0">
      <t-table-list-row head data-test="skeleton-table-header">
        <t-table-list-head />
        <t-table-list-head />
        <t-table-list-head />
        <t-table-list-head />
        <t-table-list-head />
      </t-table-list-row>

      <t-table-list-row v-for="item in [1, 2, 3, 4, 5, 6]" :key="item">
        <t-table-list-cell>
          <div class="flex flex-row items-center justify-start">
            <div :class="animationClass" class="mr-2 h-8 w-8 rounded-full bg-grey-300" />
            <div :class="animationClass" class="h-2 w-16 rounded-xl bg-grey-300" />
          </div>
        </t-table-list-cell>
        <t-table-list-cell>
          <div class="flex flex-row items-center justify-center">
            <div :class="animationClass" class="h-2 w-16 rounded-xl bg-grey-300" />
          </div>
        </t-table-list-cell>
        <t-table-list-cell>
          <div class="flex flex-row items-center justify-center">
            <div :class="animationClass" class="h-2 w-16 rounded-xl bg-grey-300" />
          </div>
        </t-table-list-cell>
        <t-table-list-cell>
          <div class="flex flex-row items-center justify-center">
            <div :class="animationClass" class="h-2 w-16 rounded-xl bg-grey-300" />
          </div>
        </t-table-list-cell>
        <t-table-list-cell>
          <div class="flex flex-row items-center justify-end">
            <div class="w-18 flex h-8 items-center justify-between rounded-lg border border-grey-300 px-2 py-3">
              <div :class="animationClass" class="h-2 w-16 rounded-xl bg-grey-300" />
            </div>
          </div>
        </t-table-list-cell>
      </t-table-list-row>
      <div v-if="showError" class="empty-state absolute top-0 h-full w-full" data-test="error-state">
        <div class="flex h-full w-full flex-col items-center justify-center">
          <div class="mb-5 rounded-xl border border-grey-500 bg-white p-2">
            <Grid07Linear size="1.4rem" />
          </div>
          <div class="mb-2">
            <p class="t-text-md-emphasize m-0 text-grey-800">{{ $t('reports_v2.no_result_match_your_filter') }}</p>
          </div>
          <div>
            <p class="m-0 text-grey-700">{{ $t('reports_v2.no_result_match_your_filter_description') }}</p>
          </div>
        </div>
      </div>
    </t-table-list>
  </div>
</template>

<script lang="ts">
import { Grid07Linear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'TableSkeleton',
  components: { Grid07Linear },
  props: {
    animation: {
      default: false,
      type: Boolean,
    },
    showError: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    animationClass() {
      return this.animation ? 'animation-loading' : '';
    },
  },
});
</script>

<style lang="scss" scoped>
@keyframes loading {
  to {
    background-position: 227px 0, 0 0, 0 157px, 23px 135px;
  }
}

.animation-loading {
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(var(--color-grey-300), var(--color-grey-300)),
    radial-gradient(38px circle at 19px 19px, var(--color-grey-300) 50%, transparent 51%),
    linear-gradient(var(--color-grey-300), var(--color-grey-300));
  background-repeat: no-repeat;
  animation: loading 1.5s linear infinite;
}

.empty-state {
  background-image: linear-gradient(0deg, #fff 30%, transparent 100%);
}
</style>
