<template>
  <div class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis" v-text="record.title"></span>
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <div class="p-a p-b-0 container">
            <div class="p-a">
              <form @submit.prevent="save">
                <div class="box">
                  <div class="box-header">
                    <h2>{{ $t('email_settings.email_theme') }}</h2>
                  </div>
                  <div class="box-divider m-a-0"></div>
                  <div class="box-body">
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('email_settings.title') }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-10">
                        <input v-model="record.title" type="text" autocomplete="off" class="form-control" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('email_settings.font_color') }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-10">
                        <color-picker v-model="record.font_color" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('email_settings.font_size') }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-10">
                        <select v-model="record.font_size" class="form-control">
                          <option value="10px">10px</option>
                          <option value="11px">11px</option>
                          <option value="12px">12px</option>
                          <option value="13px">13px</option>
                          <option value="14px">14px</option>
                          <option value="15px">15px</option>
                          <option value="16px">16px</option>
                          <option value="17px">17px</option>
                          <option value="18px">18px</option>
                          <option value="19px">19px</option>
                          <option value="20px">20px</option>
                          <option value="21px">21px</option>
                          <option value="22px">22px</option>
                          <option value="23px">23px</option>
                          <option value="24px">24px</option>
                          <option value="25px">25px</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('email_settings.line_height') }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-10">
                        <select v-model="record.line_height" class="form-control">
                          <option value="10px">10px</option>
                          <option value="11px">11px</option>
                          <option value="12px">12px</option>
                          <option value="13px">13px</option>
                          <option value="14px">14px</option>
                          <option value="15px">15px</option>
                          <option value="16px">16px</option>
                          <option value="17px">17px</option>
                          <option value="18px">18px</option>
                          <option value="19px">19px</option>
                          <option value="20px">20px</option>
                          <option value="21px">21px</option>
                          <option value="22px">22px</option>
                          <option value="23px">23px</option>
                          <option value="24px">24px</option>
                          <option value="25px">25px</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('email_settings.font_family') }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-10">
                        <input v-model="record.font_family" type="text" autocomplete="off" class="form-control" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('email_settings.text_direction') }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-10">
                        <select v-model="record.direction" class="form-control">
                          <option value="ltr">{{ $t('email_settings.left_to_right') }}</option>
                          <option value="rtl">{{ $t('email_settings.right_to_left') }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="box">
                  <div
                    class="box-body"
                    :style="{
                      fontFamily: record.font_family,
                      color: record.font_color,
                      lineHeight: record.line_height,
                      fontSize: record.font_size,
                      direction: record.direction,
                    }"
                  >
                    {{ $t('email_settings.preview_theme_description_text') }}
                  </div>
                </div>

                <div class="box">
                  <div class="box-body flow-root">
                    <button
                      type="submit"
                      :class="{ loader: saving }"
                      class="btn btn-success"
                      :disabled="saving"
                      v-text="saving ? this.$t('email_settings.saving') : this.$t('email_settings.save_changes')"
                    ></button>
                    <button
                      v-if="action == 'edit'"
                      type="button"
                      class="btn btn-link text-muted pull-right"
                      @click="deleteEmailTheme()"
                    >
                      {{ $t('email_settings.delete') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import ColorPicker from '../Elements/ColorPicker';

export default {
  emits: ['call-loadlist-method'],
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },

  components: {
    ColorPicker,
  },

  computed: {},

  data() {
    return {
      loaded: false,
      record: {
        title: this.$t('email_settings.custom_theme'),
        font_size: '13px',
        line_height: '18px',
        font_family: 'Helvetica, Arial',
        font_color: '#222222',
        direction: 'ltr',
      },
      saving: false,
    };
  },

  mounted() {
    if (this.action == 'edit') {
      axios.get('/api/v2/email/themes/' + this.$route.params.id).then((result) => {
        this.record = result.data;
      });
    }
  },

  methods: {
    save() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      if (this.action == 'create') {
        return this.storeEmailTheme();
      } else {
        this.updateEmailTheme();
      }
    },

    storeEmailTheme() {
      axios
        .post('/api/v2/email/themes', this.record)
        .then((res) => {
          this.saving = false;
          this.$emit('call-loadlist-method');
          router.push('/admin/email_themes/' + res.data.id);
          this.flashSuccess(this.$t('email_settings.the_email_theme_has_been_created_successfully'));
        })
        .catch(() => {
          this.saving = false;
        });
    },

    updateEmailTheme() {
      axios
        .put('/api/v2/email/themes/' + this.record.id, this.record)
        .then((res) => {
          this.saving = false;
          this.$emit('call-loadlist-method');
          this.flashSuccess(this.$t('email_settings.the_email_theme_has_been_updated_successfully'));
        })
        .catch(() => {
          this.saving = false;
        });
    },

    async deleteEmailTheme() {
      const shouldDeleteEmailTheme = await this.$tConfirm(
        this.$t('email_settings.are_you_sure_you_want_to_delete_this_email_theme'),
        {
          delete: true,
          title: this.$t('general.are_you_sure'),
        }
      );
      if (shouldDeleteEmailTheme) {
        axios.delete('/api/v2/email/themes/' + this.record.id).then(() => {
          this.$emit('call-loadlist-method');
          router.push('/admin/email_themes');
          this.flashSuccess(this.$t('email_settings.the_email_theme_has_been_deleted_successfully'));
        });
      }
    },
  },
};
</script>
