<template>
  <div>
    <setting-item
      :hide-form-text-on-mobile="true"
      :show-border="false"
      :custom-class="{ container: 'mb-4 mt-0 pt-4 sm:pb-3 pb-2' }"
    >
      <template #title>{{ $t('email_settings.channel_name') }}</template>
      <template #formText>
        <div class="mb-1">
          {{ $t('email_settings.subtitle_channel_name') }}
        </div>
      </template>
      <template #moreSettings>
        <atomic-input v-model="computedRecord.title" size="lg" :required="true" />
      </template>
    </setting-item>

    <setting-item :show-border="false" :custom-class="{ container: 'mb-4 mt-0 pt-1 sm:pb-3 pb-2' }">
      <template #title>{{ $t('email_settings.color') }}</template>
      <template #formText>
        <div class="mb-1">{{ $t('email_settings.subtitle_color') }}</div>
      </template>
      <template #moreSettings>
        <color-picker v-model="computedRecord.color" />
      </template>
    </setting-item>

    <setting-item
      v-if="hasNotificationSound"
      :hide-form-text-on-mobile="true"
      :show-border="false"
      :custom-class="{ container: 'mb-4 mt-0 pt-0 sm:pb-3 pb-2' }"
    >
      <template #title>{{ $t('email_settings.notification') }}</template>
      <template #formText>{{ $t('email_settings.subtitle_notification') }}</template>
      <template #moreSettings>
        <div class="flex">
          <t-dropdown
            v-model="computedRecord.notification_sound"
            drop-down-class="text-base font-medium p-3 my-0"
            :items="[
              { value: 'chat.mp3', text: $t('email_settings.sound_option_chat_default') },
              { value: 'hold-on.mp3', text: $t('email_settings.sound_option_hold_on') },
              { value: 'light.mp3', text: $t('email_settings.sound_option_light') },
              { value: 'open-ended.mp3', text: $t('email_settings.sound_option_open_ended') },
              { value: 'plucky.mp3', text: $t('email_settings.sound_option_plucky') },
              { value: '', text: $t('email_settings.sound_option_no_notification') },
            ]"
          />
          <atomic-button
            btn-style="secondary"
            style="height: 40px; border-radius: 0.75rem"
            class="ml-2"
            @click="playNotificationSound"
          >
            <i class="fas fa-play text-sm text-grey-600" />
          </atomic-button>
        </div>
      </template>
    </setting-item>

    <setting-item
      :hide-form-text-on-mobile="true"
      :show-border="false"
      :custom-class="{ container: 'mb-4 mt-0 pt-0 sm:pb-3 pb-2' }"
    >
      <template #title>{{ $t('email_settings.business_hours') }}</template>
      <template #formText>
        {{ $t('email_settings.subtitle_business_hours') }}
      </template>
      <template #moreSettings>
        <div>
          <t-dropdown v-model="computedBusinessHours" :items="businessHours" value-index="id" text-index="name">
            <template #end>
              <div
                class="flex cursor-pointer flex-row items-center justify-start p-3 text-grey-800"
                @click="openBusinessHoursModal"
              >
                <span class="material-icons text-md">add</span>
                <span class="text-sm font-semibold">{{ $t('email_settings.add_business_hours') }}</span>
              </div>
            </template>
          </t-dropdown>
          <div
            class="flex cursor-pointer flex-row items-center justify-start pt-2 text-grey-600 hover:text-black"
            @click="openBusinessHoursModal"
          >
            <span class="material-icons text-md">add</span>
            <span class="text-sm font-semibold">{{ $t('email_settings.manage_business_hours') }}</span>
          </div>
        </div>
      </template>
    </setting-item>

    <setting-item
      v-if="!computedRecord.is_private"
      :hide-form-text-on-mobile="true"
      :show-border="false"
      :custom-class="{ container: 'mb-4 mt-0 pt-0 sm:pb-4 pb-0' }"
    >
      <template #title>{{ $t('email_settings.teams') }}</template>
      <template #formText>{{ $t('email_settings.subtitle_teams') }}</template>
      <template #moreSettings>
        <div>
          <t-multi-select
            v-model="computedRecord.teams"
            :placeholder="$t('email_settings.select_one_or_more_teams')"
            :use-icon="false"
            :has-avatar="false"
            :is-closable="true"
            variant="leaf"
            size="md"
            container-size="md"
            :data="teamsList"
            @select="onSelectTeamOption"
          >
            <template #option="props">
              <div class="option__desc">
                <span
                  v-tooltip="
                    props.option.isDisabled && {
                      content: $t('email_settings.tooltip_workload_balancing_activated', { name: props.option.name }),
                      popperClass: 'team-select-tooltip',
                      placement: 'bottom-start',
                    }
                  "
                  class="option__title flex flex-row"
                  :class="{ 'team-item-disabled': props.option.isDisabled }"
                >
                  <span>{{ props.option.name }}</span>
                  <span v-if="props.option.isDisabled" class="ml-3">
                    <load-balance-linear class="text-grey-400" size="1rem" />
                  </span>
                </span>
              </div>
            </template>
          </t-multi-select>
          <span
            class="flex cursor-pointer flex-row items-center justify-start pt-2 text-grey-600 hover:text-black"
            @click="isModalVisible = true"
          >
            <span class="material-icons text-md">add</span>
            <span class="text-sm font-semibold">{{ $t('email_settings.create_new_team') }}</span>
          </span>
        </div>
      </template>
    </setting-item>
    <setting-item
      v-if="computedRecord.is_private"
      :show-border="false"
      :custom-class="{ container: 'mb-4 mt-0 pt-0 sm:pb-3 pb-0' }"
    >
      <template #title>{{ $t('email_settings.private_inbox') }}</template>
      <template #moreSettings>
        <edit-user-modal
          v-if="
            openEditUserModal &&
            computedRecord.users[0] &&
            userStore.hasPermission(PERMISSION.PERMISSIONS__USERS__MANAGE)
          "
          v-model="openEditUserModal"
          :initial-record-prop="computedRecord.users[0]"
          @update="handleSetUser"
        />
        <div class="d-inline-flex inline-block w-full cursor-pointer" @click="handleOpenEditUserModal">
          <div class="mt-2 flex select-none leading-none">
            <div class="mr-4">
              <avatar
                width="40"
                :abbr="computedRecord.users[0].abbr"
                :color="computedRecord.users[0].color"
                :image="computedRecord.users[0].profile_image"
              ></avatar>
            </div>
            <div class="min-w-0">
              <div class="mb-2 text-base font-bold">{{ computedRecord.users[0].first_name }}</div>
              <div v-if="computedRecord.users[0].teams.length" class="text-muted min-w-0 text-ellipsis text-sm">
                {{ computedRecord.users[0].teams.map((t) => t.name).join(', ') }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </setting-item>

    <div class="mb-4 border-b border-grey-300" />

    <atomic-button type="button" btn-style="secondary" class="mb-8 text-error-700" @click="$emit('deleteChannel')">
      <i class="far fa-trash mr-2" />
      {{ $t('email_settings.delete_channel') }}
    </atomic-button>

    <team-modal
      v-model="computedTeamValue"
      :is-open="isModalVisible"
      @submit="handleSubmit"
      @toggle="toggleModal"
      @close="closeModal"
    />
    <business-hours-modal
      v-if="isBusinessHoursModalVisible"
      :open="isBusinessHoursModalVisible"
      :close="closeBusinessHoursModal"
    />
  </div>
</template>

<script lang="ts">
import { LoadBalanceLinear } from '@trengo/trengo-icons';
import { map } from 'lodash';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import BusinessHoursModal from '@/components/BusinessHoursModal/BusinessHoursModal';
import { storeTeamRequest } from '@/components/Teams/api';
import TeamModal from '@/components/Teams/components/TeamModal/TeamModal';
import EditUserModal from '@/components/Users/components/EditUsers/EditUserModal';
import { PERMISSION } from '@/Configs/Constants';
import { useUserStore } from '@/store/pinia';

import Avatar from '../../../../Avatar';
import { Selectable } from '../../Components/ColorPicker';
import SettingItem from '../../Components/SettingItem';

let previewSound;

export default defineComponent({
  emits: ['input', 'deleteChannel'],
  components: {
    LoadBalanceLinear,
    TeamModal,
    BusinessHoursModal,
    'color-picker': Selectable,
    Avatar,
    EditUserModal,
    SettingItem,
  },
  props: {
    createChannel: {
      type: Function,
      default: () => ({}),
    },
    record: {
      type: Object,
      default: () => ({}),
    },
    action: {
      type: String,
      default: '',
    },
    businessHours: {
      type: Array,
      default: () => [],
    },
    teams: {
      type: Array,
      default: () => [],
    },
    hasNotificationSound: {
      type: Boolean,
    },
    hasReopenClosedTickets: {
      type: Boolean,
    },
    hasBusinessHours: {
      type: Boolean,
    },
  },
  data() {
    return {
      isModalVisible: false,
      isBusinessHoursModalVisible: false,
      openEditUserModal: false,
      team_record: {
        name: '',
        team_members: [],
        channels: [],
      },
      PERMISSION,
    };
  },
  methods: {
    onSelectTeamOption(item) {
      this.$nextTick(() => {
        if (item.isDisabled) {
          this.computedRecord.teams = this.computedRecord.teams.filter((team) => team.id !== item.id);
        }
      });
    },
    storeTeam() {
      const data = { ...{ channel_ids: this.mapChannelIds(), user_ids: this.mapUserIds() }, ...this.team_record };
      return storeTeamRequest(data);
    },
    mapChannelIds() {
      return map(this.team_record.channels, 'id');
    },
    mapUserIds() {
      return map(this.team_record.team_members, 'id');
    },
    handleSubmit() {
      this.storeTeam().then((res) => {
        this.createdTeam = res.data;
        const selectedTeams = [...this.computedRecord.teams, this.createdTeam];
        const teams = [...this.teams, this.createdTeam];
        this.$store.commit('teams/setTeams', teams);
        this.computedRecord.teams = selectedTeams;
        this.$emit('input', this.computedRecord);
        this.closeModal();
        this.flashSuccess(this.$t('email_settings.team_was_created_successfully'));
        this.resetTeamsModalStates();
      });
    },
    resetTeamsModalStates() {
      this.team_record = {
        name: '',
        team_members: [],
        channels: [],
      };
    },
    toggleModal() {
      if (this.isModalVisible) {
        this.closeModal();
        return false;
      }
      this.openModal();
    },
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    closeBusinessHoursModal({ createdBusinessHour }) {
      if (createdBusinessHour?.id) {
        const appendedBusinessHours = [...this.businessHours, createdBusinessHour];
        this.computedBusinessHours = createdBusinessHour.id;
        this.$store.commit('business_hours/setBusinessHours', appendedBusinessHours);
        this.$emit('input', this.computedRecord);
      }

      this.isBusinessHoursModalVisible = false;
    },
    openBusinessHoursModal() {
      this.isBusinessHoursModalVisible = true;
    },
    mapBusinessHours(id) {
      return this.businessHours.filter((item) => item.id === id);
    },
    playNotificationSound() {
      if (this.record.notification_sound) {
        let preview;
        if (!previewSound) {
          preview = new Audio('/sound/channel_notification/' + this.record.notification_sound);
          previewSound = preview;
        } else {
          previewSound.src = '/sound/channel_notification/' + this.record.notification_sound;
          previewSound.load();
          preview = previewSound;
        }
        preview.play().catch(() => {});
      }
    },
    handleOpenEditUserModal() {
      this.openEditUserModal = true;
    },
    handleSetUser(user) {
      this.computedRecord.users[0] = user;
    },
  },
  computed: {
    ...mapStores(useUserStore),
    ...mapGetters({
      teamsList: 'teams/teamsList',
    }),
    computedTeamValue: {
      get() {
        return this.team_record;
      },
      set(val) {
        this.team_record = val;
      },
    },
    computedRecord: {
      get() {
        return this.record;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    computedBusinessHours: {
      get() {
        return this.computedRecord.business_hour_id;
      },
      set(value) {
        this.computedRecord.business_hour_id = value;
        this.$emit('input', this.computedRecord);
      },
    },
  },
});
</script>

<style>
.spin-button-none {
  -moz-appearance: textfield;
}
.spin-button-none::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
