<script type="text/babel">
import eventBus from '@/eventBus';

import Avatar from '../../Avatar.vue';
export default {
  name: 'CallLog',
  emits: ['deQueueCall'],
  data() {
    return {
      callLog: [],
      twilioNumber: window.MIX_VOIP_SANDBOX_PHONE || '31858088215',
    };
  },

  components: {
    Avatar,
  },

  computed: {
    queuedCalls: function () {
      return this.callLog.filter((item) => {
        return item.status == 'QUEUED';
      });
    },
    inProgressCalls: function () {
      return this.callLog.filter((item) => {
        return item.status == 'IN_PROGRESS';
      });
    },
  },

  props: {
    status: {
      type: String,
      default: '',
    },
  },

  mounted() {
    eventBus.$on('voip.call_started', (call) => {
      if (!this.$root.channels.map((c) => c.id).includes(call.channel_id)) {
        return;
      }

      let i = this.callLog.map((item) => item.token).indexOf(call.token);

      if (typeof this.callLog[i] == 'undefined') {
        this.callLog.push(call);
      } else {
        this.callLog[i].status = call.status;
        this.callLog[i].user = call.user;
        this.callLog[i].contact = call.contact;
      }
    });

    eventBus.$on('voip.call_ended', (call) => {
      this.getQueueCall().then(() => {
        let i = this.callLog.map((item) => item.token).indexOf(call.token);
        if (this.callLog[i]) {
          this.callLog.splice(i, 1);
        }
      });
    });

    eventBus.$on('voip.user_call_accepted', (data) => {
      let i = this.callLog.map((item) => item.token).indexOf(data.call.token);
      if (typeof this.callLog[i] != 'undefined') {
        this.callLog[i].status = 'IN_PROGRESS';
        this.callLog[i].channel = data.call.channel;
        this.callLog[i].contact = data.call.contact;
      }
    });

    this.getQueueCall();
  },

  unmounted() {
    eventBus.$off('voip.call_started');
    eventBus.$off('voip.call_ended');
    eventBus.$off('voip.user_call_accepted');
  },

  methods: {
    getQueueCall() {
      return new Promise((resolve) => {
        axios.get('/client-api/voip/log').then((items) => {
          this.callLog = items.data?.filter((i) => this.$root.channels.map((c) => c.id).includes(i.channel_id));
          resolve();
        });
      });
    },
    /**
     * @param item
     */
    deQueueCall(item) {
      this.$emit('deQueueCall', item);
    },
  },
};
</script>

<template>
  <div class="scroll-on-hover scroll-dark-grey select-none p-4">
    <div v-show="!$root.mobileDevice">
      <div
        class="mb-4 items-center rounded-lg bg-grey-800 pl-2 leading-none text-grey-500"
        style="display: inline-flex"
      >
        <span>{{ $t('voip.voip_queue') }}</span>
        <span class="ml-2 rounded-lg bg-green px-2 py-2 font-bold text-white">{{ queuedCalls.length || 0 }}</span>
      </div>
      <div class="mb-2 border-b-2 border-grey-800" style="min-height: 75px">
        <div v-if="!queuedCalls.length" class="pb-8 text-grey-500">{{ $t('voip.voip_no_queued_calls') }}</div>
        <div v-for="item in queuedCalls" class="mb-2 flex items-center border-b border-grey-800 pb-2 text-white">
          <div v-show="!$root.isMobileDevice" class="mr-2">
            <span
              class="
                circle
                inline-block-40px
                avatar
                flex-shrink-0
                cursor-pointer
                items-center
                justify-center
                bg-green
                text-white
                hover:brightness-90
              "
              style="font-size: 14px; width: 35px; height: 35px; line-height: 35px; display: inline-flex"
              @click="deQueueCall(item)"
            >
              <i class="material-icons text-md">ring_volume</i>
            </span>
          </div>
          <div class="min-w-0 flex-1 leading-none">
            <div v-if="item.contact.full_name" class="text-ellipsis">{{ item.contact.full_name }}</div>
            <div v-else class="select-default text-ellipsis">{{ item.contact.formatted_phone }}</div>
            <div class="flex pt-1 text-sm leading-none text-grey-500">
              <span class="flex items-center text-ellipsis">
                <i class="material-icons text-success text-sm">call_received</i>
                {{ item.channel.title }}
              </span>
              <div class="ml-auto flex flex-shrink-0 items-center">
                {{ item.formatted_date }}
                <i class="material-icons ml-1 text-sm">alarm</i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mb-4 mt-2 items-center rounded-lg bg-grey-800 pl-2 leading-none text-grey-500"
      style="display: inline-flex"
    >
      <span>{{ $t('voip.voip_in_progress') }}</span>
      <span class="ml-2 rounded-lg bg-green px-2 py-2 font-bold text-white">{{ inProgressCalls.length || 0 }}</span>
    </div>
    <div class="select-none">
      <div v-if="!inProgressCalls.length" class="pb-8 text-grey-500">{{ $t('voip.voip_no_calls_in_progress') }}</div>
      <div v-for="item in inProgressCalls" class="mb-2 flex items-center border-b border-grey-800 pb-2 text-white">
        <div class="mr-2">
          <avatar
            width="35"
            :abbr="item.contact.abbr"
            :color="item.contact.color"
            :image="item.contact.profile_image"
          ></avatar>
        </div>

        <div class="min-w-0 flex-1 pt-1 leading-none">
          <div v-if="item.contact.full_name" class="text-ellipsis">{{ item.contact.full_name }}</div>
          <div v-else class="select-default text-ellipsis">{{ item.contact.formatted_phone }}</div>
          <div class="flex pt-1 text-sm leading-none text-grey-500">
            <span class="flex items-center text-ellipsis">
              <i
                class="material-icons text-sm"
                :class="{ 'text-danger': item.status == 'MISSED', 'text-success': item.status == 'COMPLETED' }"
                v-text="item.type == 'OUTBOUND' ? 'call_made' : 'call_received'"
              ></i>
              {{ item.channel.title }}
            </span>
            <div class="ml-auto flex flex-shrink-0 items-center">
              {{ item.formatted_date }}
              <i class="material-icons ml-1 text-sm">{{ $t('voip.voip_schedule') }}</i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
