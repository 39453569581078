<template>
  <wab-recipients-item
    v-model="computedValue"
    class="wab-recipients-contact-item"
    :disable="disable"
    :class="{ 'selected-in-group': isSelectedInGroup || isSelectedInManual }"
  >
    <template #name>
      <span class="name">{{ name }}</span>
      <span class="phone">{{ phone }}</span>
    </template>

    <template v-if="isSelectedInGroup || isSelectedInManual" #right-text>
      {{ isSelectedInGroup ? 'Selected from group' : 'Selected manually' }}
    </template>
  </wab-recipients-item>
</template>

<script>
import WabRecipientsItem from '@/components/WabBroadcasting/components/WabRecipientsItem/WabRecipientsItem';

export default {
  name: 'WabRecipientsContactItem',
  emits: ['update:modelValue'],
  components: { WabRecipientsItem },
  props: {
    name: {
      type: String,
      default: '',
    },
    phone: {
      type: String,
      default: '',
    },
    isSelectedInGroup: {
      type: Boolean,
      default: false,
    },
    isSelectedInManual: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue || this.isSelectedInManual;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style scoped lang="scss" src="./WabRecipientsContactItem.scss" />
