<template>
  <t-tooltip v-if="statusTooltip" :text="statusTooltip" variant="solid">
    <t-badge
      :variant="statusType"
      :text="$t(`broadcast.broadcast_status_${status.toLowerCase()}`)"
      class="inline-flex"
    />
  </t-tooltip>
  <t-badge
    v-else
    :variant="statusType"
    :text="$t(`broadcast.broadcast_status_${status.toLowerCase()}`)"
    class="inline-flex"
  />
</template>

<script>
export default {
  name: 'BroadcastStatusBadge',
  props: {
    statusTooltip: {
      type: String,
      default: '',
    },
    statusType: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
  },
};
</script>
