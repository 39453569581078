import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-2 my-16 flex flex-col items-center text-center" }
const _hoisted_2 = { class: "t-text-md-emphasize m-0 mb-2 p-0 text-grey-800" }
const _hoisted_3 = { class: "t-text-sm m-0 mb-1 p-0 text-grey-700" }
const _hoisted_4 = { class: "t-text-sm m-0 mb-5 p-0 text-grey-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_integration_linear = _resolveComponent("integration-linear")!
  const _component_t_thumbnail = _resolveComponent("t-thumbnail")!
  const _component_arrow_right_linear = _resolveComponent("arrow-right-linear")!
  const _component_t_button = _resolveComponent("t-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_t_thumbnail, {
      "mx-auto": "",
      class: "mb-5 bg-white",
      size: "large"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_integration_linear, { size: "1.5rem" })
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$tc('integration_hub.no_integrations_available')), 1 /* TEXT */),
    _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$tc('integration_hub.integrations_need_to_be_installed')), 1 /* TEXT */),
    _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$tc('integration_hub.check_if_integrations_are_active')), 1 /* TEXT */),
    (_ctx.hasIntegrationHubPermissions)
      ? (_openBlock(), _createBlock(_component_t_button, {
          key: 0,
          class: "flex flex-row items-center",
          "btn-style": "secondary",
          onClick: _withModifiers(_ctx.navigateToIntegrations, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$tc('integration_hub.go_to_integration_hub')) + " ", 1 /* TEXT */),
            _createVNode(_component_arrow_right_linear, {
              class: "ml-2",
              size: "1.4rem"
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]))
      : _createCommentVNode("v-if", true)
  ]))
}