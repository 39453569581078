import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-12 mt-4 grid grid-cols-1 justify-items-stretch rounded-xl border-1 border-galaxy-300 bg-galaxy-100 px-4 py-6 sm:px-8 sm:py-6" }
const _hoisted_2 = { class: "flex flex-row items-start sm:items-center" }
const _hoisted_3 = { class: "flex h-44px w-44px items-center justify-center overflow-hidden rounded-xl bg-galaxy-600 sm:h-48px sm:w-48px" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "ml-4 flex flex-col" }
const _hoisted_6 = { class: "t-text-mobile-body-md sm:t-text-desktop-label-lg sm:mb-1" }
const _hoisted_7 = { class: "t-text-desktop-body-xs sm:t-text-desktop-paragraph-sm text-grey-600" }
const _hoisted_8 = { class: "mt-8 flex flex-row gap-2 lg:flex-row" }
const _hoisted_9 = { class: "t-text-button-sm" }
const _hoisted_10 = { class: "t-text-button-sm" }
const _hoisted_11 = { class: "t-text-button-sm" }
const _hoisted_12 = { class: "t-text-button-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_call_calling_fill = _resolveComponent("call-calling-fill")!
  const _component_item_button = _resolveComponent("item-button")!
  const _component_comments_fill = _resolveComponent("comments-fill")!
  const _component_playlist_play_fill = _resolveComponent("playlist-play-fill")!
  const _component_question_fill = _resolveComponent("question-fill")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "h-full w-full",
          src: `${_ctx.$root.assetsURL}img/Alicia_Purple_2x.png`
        }, null, 8 /* PROPS */, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('getting_started.need_some_help_setting_things_up')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('getting_started.subtitle_need_help_let_us_know')), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      ($options.hasNotSubscription)
        ? (_openBlock(), _createBlock(_component_item_button, {
            key: 0,
            class: "w-full",
            onClick: $options.requestACall
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_call_calling_fill, {
                  size: "1.5rem",
                  fill: "#249888",
                  class: "mb-2"
                })
              ]),
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('getting_started.request_a_call')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"]))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_item_button, {
        class: "w-full",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.handleOpenWebChat('chat')))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_comments_fill, {
              size: "1.5rem",
              fill: "#249888",
              class: "mb-2"
            })
          ]),
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('getting_started.chat_with_us')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_item_button, {
        class: "w-full",
        onClick: $options.openVideoTutorials
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_playlist_play_fill, {
              size: "1.5rem",
              fill: "#249888",
              class: "mb-2"
            })
          ]),
          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('getting_started.watch_our_video_tutorials')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"]),
      _createVNode(_component_item_button, {
        class: "w-full",
        onClick: $options.visitOurHelpCentre
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_question_fill, {
              size: "1.5rem",
              fill: "#249888",
              class: "mb-2"
            })
          ]),
          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('getting_started.visit_our_help_centre')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ])
  ]))
}