<template>
  <div>
    <setting-item
      :custom-class="{ container: 'mb-1 mt-0 pb-6 cursor-pointer p-4 pl-0' }"
      @click="() => handleToggleSwitch('emailChannel.add_conversation_to_reply')"
    >
      <template #title>
        <span class="flex flex-row items-center">
          {{ $t('email_settings.attach_full_conversation_history_to_reply') }}
        </span>
      </template>
      <template #formText>
        {{ $t('email_settings.when_enabled_well_include_the_full_conversation_history_to_your_conversations') }}
      </template>
      <template #toggle>
        <t-switch v-model="computedRecord.emailChannel.add_conversation_to_reply" @click.stop />
      </template>
    </setting-item>

    <setting-item
      v-if="!isNylasChannel"
      :show-border="!computedRecord.emailChannel.split_by_sender"
      :custom-class="{ container: splitBySenderContainerStyle }"
      @click="() => handleToggleSwitch('emailChannel.split_by_sender')"
    >
      <template #title>
        <span class="flex flex-row items-center">
          {{ $t('email_settings.create_a_new_conversation_when_a_customer_sends_a_reply') }}
        </span>
      </template>
      <template #formText>
        {{ $t('email_settings.instead_of_merging_a_reply_into_the_existing_thread_a_new_conversation_is_created') }}
      </template>
      <template #toggle>
        <t-switch v-model="computedRecord.emailChannel.split_by_sender" @click.stop />
      </template>
    </setting-item>

    <template v-if="shouldShowTemplate">
      <setting-item
        v-if="hasReopenClosedTickets"
        :show-border="!computedRecord.reopen_closed_ticket"
        :custom-class="{ container: reOpenClosedTicketsContainerStyle }"
        @click="() => handleToggleSwitch('reopen_closed_ticket')"
      >
        <template #title>{{ $t('email_settings.reopen_closed_conversations') }}</template>
        <template #formText>
          <div class="w-4/5">
            {{ $t('email_settings.reopen_closed_conversations_subtitle') }}
          </div>
        </template>
        <template #toggle>
          <t-switch v-model="computedRecord.reopen_closed_ticket" @click.stop />
        </template>
      </setting-item>

      <setting-item
        v-if="computedRecord.reopen_closed_ticket"
        :show-border="false"
        :custom-class="{ container: ' mb-2 bg-grey-100 rounded-xl p-4' }"
      >
        <template #title>
          <div class="flex flex-wrap items-center">
            <div class="text-sm font-semibold">
              {{ $t('email_settings.do_not_reopen_conversations_after') }}
            </div>
            <input
              v-model="computedRecord.reopen_closed_ticket_time_window_days"
              type="number"
              autocomplete="off"
              class="spin-button-none mx-1 inline-block rounded-lg border-1 px-2 py-1 text-center text-sm font-medium hover:border-grey-400"
              min="1"
              max="999"
              style="width: 46px; height: 32px"
            />
            <span class="text-sm font-semibold">{{ $t('email_settings.days_of_no_message') }}</span>
          </div>
        </template>
      </setting-item>

      <setting-item
        v-if="computedRecord.type === 'EMAIL' && computedRecord.reopen_closed_ticket && !isNylasChannel"
        :custom-class="{
          container: 'mb-2 pb-3 border-none cursor-pointer bg-grey-100 rounded-xl p-4',
        }"
        @click="() => handleToggleSwitch('emailChannel.split_by_subject')"
      >
        <template #title>
          <span class="text-sm font-semibold">
            {{ $t('email_settings.create_a_new_conversation_when_subject_line_differs') }}
          </span>
        </template>
        <template #formText>
          <div class="w-4/5">
            {{ $t('email_settings.create_new_conversations_subtitle') }}
          </div>
        </template>
        <template #toggle>
          <t-switch v-model="computedRecord.emailChannel.split_by_subject" @click.stop />
        </template>
      </setting-item>

      <setting-item
        v-if="computedRecord.reopen_closed_ticket"
        :custom-class="{
          container: 'mb-2 bg-grey-100 border-none rounded-xl p-4 cursor-pointer',
        }"
        @click="!isNylasV2Channel && handleToggleSwitch('reassign_reopened_ticket')"
      >
        <template #title>
          <span class="text-sm font-semibold">
            {{ $t('email_settings.assign_reopened_conversations_to_the_previous_user') }}
          </span>
        </template>
        <template #toggle>
          <t-switch v-model="computedRecord.reassign_reopened_ticket" :disabled="isNylasV2Channel" @click.stop />
        </template>
        <template #formText>
          {{ $t('email_settings.reopen_and_assign_the_message_to_user_that_closed_the_conversation') }}
        </template>
      </setting-item>
    </template>

    <div v-if="computedRecord.reopen_closed_ticket" class="my-6 mb-1 border-b border-grey-300" />

    <setting-item
      v-if="showEmbedAttachments"
      :custom-class="{
        container: 'mb-1 mt-0 pt-4 sm:pt-5 pb-6 cursor-pointer p-4 pl-0',
      }"
      @click="() => handleToggleSwitch('emailChannel.embed_attachments')"
    >
      <template #title>{{ $t('email_settings.embed_attachments') }}</template>
      <template #formText>
        {{ $t('email_settings.embed_attachments_subtitle') }}
      </template>
      <template #toggle>
        <t-switch v-model="computedRecord.emailChannel.embed_attachments" :disabled="isNylasChannel" @click.stop />
      </template>
    </setting-item>

    <setting-item
      v-if="!isNylasChannel"
      :custom-class="{
        container: 'mb-1 mt-0 pt-4 sm:pt-5 pb-6 cursor-pointer p-4 pl-0',
      }"
      @click="() => handleToggleSwitch('emailChannel.prepend_ticket_number_to_subject')"
    >
      <template #title>{{ $t('email_settings.attach_conversation_number_to_subject_as_a_prefix') }}</template>
      <template #formText>
        {{ $t('email_settings.if_enabled_the_ticket_number_will_be_attached_before_the_email_subject') }}
      </template>
      <template #toggle>
        <t-switch v-model="computedRecord.emailChannel.prepend_ticket_number_to_subject" @click.stop />
      </template>
    </setting-item>

    <setting-item
      :custom-class="{
        container: 'mb-1 mt-0 pt-4 sm:pt-5 pb-6 cursor-pointer p-4 pl-0',
      }"
      @click="() => handleToggleSwitch('emailChannel.meta.reply_all')"
    >
      <template #title>
        <span>{{ $t('email_settings.reply_to_all_recipients') }}</span>
      </template>
      <template #formText>
        <span>
          {{ $t('email_settings.by_default_youll_respond_to_all_recipients_including_everyone_in_the_cc') }}
        </span>
      </template>
      <template #moreSettings>
        <div v-if="computedRecord.emailChannel.meta.reply_all" class="mt-2">
          {{
            $t(
              'email_settings.please_specify_your_own_email_addresses_and_aliases_to_prevent_sending_replies_back_to_yourself',
            )
          }}
          <multiselect
            v-model="computedRecord.emailChannel.meta.email_addresses"
            class="mt-2"
            tag-placeholder="Add email address"
            :placeholder="$t('email_settings.search_or_add_email')"
            label="email"
            track-by="email"
            :options="computedRecord.emailChannel.meta.email_addresses"
            :multiple="true"
            :taggable="true"
            :disabled="isNylasV2Channel"
            @click.stop="stopCallingToggleSwitch"
            @tag="addEmail"
          />
        </div>
      </template>
      <template #toggle>
        <t-switch v-model="computedRecord.emailChannel.meta.reply_all" :disabled="isNylasV2Channel" @click.stop />
      </template>
    </setting-item>

    <setting-item
      class="w-full"
      :show-border="false"
      :custom-class="{ container: 'mb-1 mt-0 pt-4 sm:pt-5 pb-6 p-4 pl-0 pr-0' }"
    >
      <template #title>{{ $t('email_settings.spam_filter') }}</template>
      <template #moreSettings>
        <div class="mt-4">
          <t-multi-choice v-model="computedRecord.emailChannel.spam_setting" :disabled="isNylasV2Channel">
            <template #0>
              <t-multi-choice-button
                class="mb-4"
                :title="isNylasChannel ? $t('email_settings.basic') : $t('email_settings.none')"
                :selected="selectedSpamSetting === 0"
                :disabled="isNylasV2Channel"
              >
                <template #desc>
                  {{
                    isNylasChannel
                      ? $t('email_settings.default_spam_protection_from_your_email_provider')
                      : $t('email_settings.receive_all_emails_in_one_place_even_your_private_ones')
                  }}
                </template>
              </t-multi-choice-button>
            </template>
            <template #1>
              <t-multi-choice-button
                class="mb-4"
                :title="isNylasChannel ? $t('email_settings.advanced') : $t('email_settings.basic')"
                :selected="selectedSpamSetting === 1"
                :disabled="isNylasV2Channel"
              >
                <template v-if="isNylasChannel" #desc>
                  {{ $t('email_settings.default_spam_protection_from_your_email_provider_plus_extra_blacklist_check') }}
                </template>
                <template v-else #desc>
                  <div class="flex flex-row">
                    <span v-html="$t('email_settings.checks_suspicious_content')"></span>
                    <img
                      v-tooltip="{
                        content: $t('email_settings.tooltip_dmarc'),
                      }"
                      :src="`${$root.assetsURL}img/info.svg`"
                      alt="info"
                      class="ml-2 h-20px w-20px"
                    />
                  </div>
                </template>
              </t-multi-choice-button>
            </template>
            <template v-if="!isNylasChannel" #2>
              <t-multi-choice-button :title="$t('email_settings.advanced')" :selected="selectedSpamSetting === 2">
                <template #desc>
                  <div class="flex flex-row">
                    <span v-html="$t('email_settings.checks_for_basic_spam_settings')"></span>
                    <img
                      v-tooltip="{
                        content: $t('email_settings.tooltip_basic_spam_and_spf'),
                      }"
                      :src="`${$root.assetsURL}img/info.svg`"
                      alt="info"
                      class="ml-2 h-20px w-20px"
                    />
                  </div>
                </template>
              </t-multi-choice-button>
            </template>
          </t-multi-choice>
        </div>
      </template>
    </setting-item>
  </div>
</template>

<script lang="ts">
import Multiselect from 'vue-multiselect';

import { NYLAS, NYLAS_V3 } from '@/Configs/Constants/ChannelLandingPageTypes';

import SettingItem from '../../Components/SettingItem';

export default {
  name: 'AdvancedEmailSettings',
  components: {
    Multiselect,
    SettingItem,
  },
  props: {
    action: {
      type: String,
      default: '',
    },
    record: {
      type: Object,
      default: () => ({}),
    },
    hasReopenClosedTickets: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['switch', 'input'],
  data() {
    return {
      allowedNylasChannelActions: ['reopen_closed_ticket'],
    };
  },
  computed: {
    reOpenClosedTicketsContainerStyle() {
      const padding = this.computedRecord.reopen_closed_ticket ? 'pb-0' : 'pb-6';
      return `mb-1 mt-0 pt-4 sm:pt-5 ${padding} cursor-pointer p-4 pl-0`;
    },
    splitBySenderContainerStyle() {
      const padding = this.computedRecord.emailChannel.split_by_sender ? 'pb-0' : 'pb-6';
      return `mb-1 mt-0 ${padding} cursor-pointer p-4 pl-0`;
    },
    computedRecord: {
      get() {
        return this.record;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    prependTicketNumberOption() {
      return this.computedRecord.emailChannel.type === 'TRENGO';
    },

    selectedSpamSetting() {
      return parseInt(this.computedRecord.emailChannel.spam_setting);
    },

    showEmbedAttachments() {
      return this.computedRecord.emailChannel.type === 'TRENGO';
    },
    isNylasV2Channel() {
      return this.record.emailChannel.type === NYLAS;
    },
    isNylasV3Channel() {
      return this.record.emailChannel.type === NYLAS_V3;
    },
    isNylasChannel() {
      return this.isNylasV2Channel || this.isNylasV3Channel;
    },
    shouldShowTemplate() {
      const { type, emailChannel } = this.computedRecord;

      return (
        type === 'EMAIL' && (!emailChannel.split_by_sender || (this.isNylasChannel && emailChannel.split_by_sender))
      );
    },
  },
  methods: {
    handleToggleSwitch(key) {
      this.$emit('switch', key);
    },
    stopCallingToggleSwitch(e) {
      e.stopPropagation();
    },
    addEmail(v) {
      this.computedRecord.emailChannel.meta.email_addresses.push({ email: v });
    },
  },
};
</script>
