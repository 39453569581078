<template>
  <t-modal :model-value="true" variant="narrow" @close="$emit('close')">
    <template #header>
      <h3 class="t-text-h3 my-auto max-w-[90%] p-0">{{ $t('integration_hub.rename') }}</h3>
    </template>

    <div class="flex flex-col">
      <t-input-text id="title" v-model="name" :required="true" class="mt-2" />
    </div>

    <template #footer>
      <t-button btn-style="secondary" :disabled="isSaving" @click="$emit('close')">{{ $t('general.cancel') }}</t-button>

      <t-button class="flex flex-row items-center justify-center" :disabled="isSaveDisabled" @click="save">
        <p class="m-0 p-0">{{ $t('general.save') }}</p>
        <t-spinner v-if="isSaving" size="1rem" class="ml-2" />
      </t-button>
    </template>
  </t-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import type { Installation } from '@/store/types/integrations';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'IntegrationHubEditModal',
  emits: ['close'],
  data() {
    return {
      name: '',
      isSaving: false,
    };
  },
  props: {
    installation: {
      type: Object as PropType<Installation>,
      default: () => ({}),
    },
  },

  computed: {
    isSaveDisabled() {
      return this.isSaving || this.name === '';
    },
  },

  methods: {
    async save() {
      this.isSaving = true;
      try {
        await this.$store.dispatch('integrations/renameInstallationItem', {
          integration_id: parseInt(this.$route.params.integrationId),
          installation_id: parseInt(this.$route.params.installationId),
          name: this.name,
        });
        this.$emit('close');
      } catch (err) {
        console.error(err);
      } finally {
        this.isSaving = false;
      }
    },
  },

  mounted() {
    this.name = this.installation.name;
  },
});
</script>
