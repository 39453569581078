import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex w-full flex-col",
  "data-test": "ticket-sidebar"
}
const _hoisted_2 = { class: "border-b border-grey-300 border-opacity-20 pb-3" }
const _hoisted_3 = { class: "flex flex-row items-center p-3" }
const _hoisted_4 = {
  class: "t-text-h5 mb-0 font-bold text-grey-800",
  "data-test": "sidebar-ticket-id"
}
const _hoisted_5 = {
  class: "mt-2 flex flex-row border-b border-grey-300 border-opacity-20",
  "data-test": "sidebar-tabs-wrapper"
}
const _hoisted_6 = {
  key: 0,
  class: "flex w-full flex-col overflow-y-auto",
  "data-test": "sidebar-contacts-tab-wrapper"
}
const _hoisted_7 = {
  key: 1,
  class: "flex w-full flex-col overflow-y-auto"
}
const _hoisted_8 = {
  key: 0,
  class: "mx-3 mt-3"
}
const _hoisted_9 = {
  key: 1,
  class: "flex w-full flex-col overflow-y-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cross_linear = _resolveComponent("cross-linear")!
  const _component_t_icon_button = _resolveComponent("t-icon-button")!
  const _component_sidebar_infobox = _resolveComponent("sidebar-infobox")!
  const _component_t_tab_item = _resolveComponent("t-tab-item")!
  const _component_conversation_dropdown = _resolveComponent("conversation-dropdown")!
  const _component_visitor_details_dropdown = _resolveComponent("visitor-details-dropdown")!
  const _component_ticket_fields_dropdown = _resolveComponent("ticket-fields-dropdown")!
  const _component_contact_fields_dropdown = _resolveComponent("contact-fields-dropdown")!
  const _component_contact_group_dropdown = _resolveComponent("contact-group-dropdown")!
  const _component_attachments_dropdown = _resolveComponent("attachments-dropdown")!
  const _component_timetracking_dropdown = _resolveComponent("timetracking-dropdown")!
  const _component_related_tickets_dropdown = _resolveComponent("related-tickets-dropdown")!
  const _component_contact_moments_dropdown = _resolveComponent("contact-moments-dropdown")!
  const _component_notes_dropdown = _resolveComponent("notes-dropdown")!
  const _component_profile_fields_dropdown = _resolveComponent("profile-fields-dropdown")!
  const _component_no_integrations_placeholder = _resolveComponent("no-integrations-placeholder")!
  const _component_integration_dropdowns = _resolveComponent("integration-dropdowns")!
  const _component_legacy_integration_dropdowns = _resolveComponent("legacy-integration-dropdowns")!
  const _component_t_spinner = _resolveComponent("t-spinner")!
  const _component_customise_sidebar_items = _resolveComponent("customise-sidebar-items")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h5", _hoisted_4, "# " + _toDisplayString(_ctx.ticket.id), 1 /* TEXT */),
        _createVNode(_component_t_icon_button, {
          class: "ml-auto",
          "data-test": "close-sidebar-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeSidebar')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_cross_linear, { size: "1.5rem" })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createVNode(_component_sidebar_infobox, {
        class: "px-3",
        contact: _ctx.ticket.contact,
        channel: _ctx.ticket.channel
      }, null, 8 /* PROPS */, ["contact", "channel"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_t_tab_item, {
        class: "ml-3",
        size: "sm",
        "is-active": _ctx.activeTab === 'contacts',
        label: _ctx.$tc('user_management.contacts'),
        "data-test": "sidebar-tab-contacts",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleTab('contacts')))
      }, null, 8 /* PROPS */, ["is-active", "label"]),
      _createVNode(_component_t_tab_item, {
        size: "sm",
        "is-active": _ctx.activeTab === 'integrations',
        label: _ctx.$tc('integration_hub.integrations'),
        "data-test": "sidebar-tab-integrations",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleTab('integrations')))
      }, null, 8 /* PROPS */, ["is-active", "label"])
    ]),
    (_ctx.activeTab === 'contacts')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_conversation_dropdown, { ticket: _ctx.ticket }, null, 8 /* PROPS */, ["ticket"]),
          (_ctx.ticket.channel.type === 'CHAT')
            ? (_openBlock(), _createBlock(_component_visitor_details_dropdown, {
                key: 0,
                ticket: _ctx.ticket,
                online: _ctx.visitorOnlineStatus
              }, null, 8 /* PROPS */, ["ticket", "online"]))
            : _createCommentVNode("v-if", true),
          (_ctx.isItemActive('ticketFields'))
            ? (_openBlock(), _createBlock(_component_ticket_fields_dropdown, {
                key: 1,
                ticket: _ctx.ticket
              }, null, 8 /* PROPS */, ["ticket"]))
            : _createCommentVNode("v-if", true),
          (_ctx.isItemActive('contactFields'))
            ? (_openBlock(), _createBlock(_component_contact_fields_dropdown, {
                key: 2,
                ticket: _ctx.ticket,
                contact: _ctx.ticket.contact
              }, null, 8 /* PROPS */, ["ticket", "contact"]))
            : _createCommentVNode("v-if", true),
          (_ctx.isItemActive('contactGroups') && _ctx.$root.contactGroups.length > 0)
            ? (_openBlock(), _createBlock(_component_contact_group_dropdown, {
                key: 3,
                contact: _ctx.ticket.contact
              }, null, 8 /* PROPS */, ["contact"]))
            : _createCommentVNode("v-if", true),
          (_ctx.isItemActive('attachments') && _ctx.ticket.attachments.length > 0)
            ? (_openBlock(), _createBlock(_component_attachments_dropdown, {
                key: 4,
                attachments: _ctx.ticket.attachments
              }, null, 8 /* PROPS */, ["attachments"]))
            : _createCommentVNode("v-if", true),
          (_ctx.isItemActive('timeTracking'))
            ? (_openBlock(), _createBlock(_component_timetracking_dropdown, {
                key: 5,
                ticket: _ctx.ticket
              }, null, 8 /* PROPS */, ["ticket"]))
            : _createCommentVNode("v-if", true),
          (_ctx.isItemActive('relatedTickets') && _ctx.ticket.related_tickets.length > 0)
            ? (_openBlock(), _createBlock(_component_related_tickets_dropdown, {
                key: 6,
                "related-tickets": _ctx.ticket.related_tickets,
                "main-ticket-id": _ctx.ticket.id,
                onRemoveRelated: _ctx.removeRelated
              }, null, 8 /* PROPS */, ["related-tickets", "main-ticket-id", "onRemoveRelated"]))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" Contact Moments "),
          (_ctx.isItemActive('contactMoments') && _ctx.ticket.contact !== undefined)
            ? (_openBlock(), _createBlock(_component_contact_moments_dropdown, {
                key: 7,
                contact: _ctx.ticket.contact,
                "current-ticket": _ctx.ticket
              }, null, 8 /* PROPS */, ["contact", "current-ticket"]))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" Contact Notes "),
          (_ctx.isItemActive('contactNotes'))
            ? (_openBlock(), _createBlock(_component_notes_dropdown, {
                key: 8,
                contact: _ctx.ticket.contact
              }, null, 8 /* PROPS */, ["contact"]))
            : _createCommentVNode("v-if", true),
          (_ctx.isItemActive('profileFields') && _ctx.ticket.contact.profile !== null && _ctx.ticket.contact.profile.length > 0)
            ? (_openBlock(), _createBlock(_component_profile_fields_dropdown, {
                key: 9,
                profile: _ctx.ticket.contact.profile[0]
              }, null, 8 /* PROPS */, ["profile"]))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" Profile Notes "),
          (_ctx.isItemActive('profileNotes') && _ctx.ticket.contact.profile !== null && _ctx.ticket.contact.profile.length > 0)
            ? (_openBlock(), _createBlock(_component_notes_dropdown, {
                key: 10,
                "note-type": "profile",
                profile: _ctx.ticket.contact.profile[0]
              }, null, 8 /* PROPS */, ["profile"]))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" Profile Moments "),
          (_ctx.isItemActive('profileMoments') && _ctx.ticket.contact.profile !== null && _ctx.ticket.contact.profile.length > 0)
            ? (_openBlock(), _createBlock(_component_contact_moments_dropdown, {
                key: 11,
                "current-ticket": _ctx.ticket,
                profiles: _ctx.ticket.contact.profile
              }, null, 8 /* PROPS */, ["current-ticket", "profiles"]))
            : _createCommentVNode("v-if", true)
        ]))
      : (!_ctx.loadingIntegrations && _ctx.activeTab === 'integrations')
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_ctx.visibleIntegrationInstallations.length === 0 && _ctx.visibleLegacyIntegrations.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_no_integrations_placeholder)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (_ctx.isIntegrationHubEnabled)
                    ? (_openBlock(), _createBlock(_component_integration_dropdowns, {
                        key: 0,
                        installations: _ctx.visibleIntegrationInstallations,
                        ticket: _ctx.ticket,
                        "data-test": "sidebar-integrations-tab-wrapper"
                      }, null, 8 /* PROPS */, ["installations", "ticket"]))
                    : _createCommentVNode("v-if", true),
                  _createVNode(_component_legacy_integration_dropdowns, {
                    integrations: _ctx.visibleLegacyIntegrations,
                    ticket: _ctx.ticket,
                    "data-test": "sidebar-integrations-tab-wrapper"
                  }, null, 8 /* PROPS */, ["integrations", "ticket"])
                ]))
          ]))
        : (_openBlock(), _createBlock(_component_t_spinner, {
            key: 2,
            class: "mx-auto my-auto",
            size: "2rem"
          })),
    (_ctx.activeTab === 'contacts')
      ? (_openBlock(), _createBlock(_component_customise_sidebar_items, {
          key: 3,
          class: "mt-auto",
          label: _ctx.$tc('user_management.contacts'),
          items: _ctx.sidebarItems,
          "item-tab": "contact",
          "data-test": "customiser-sidebar-items-contacts",
          onItemToggled: _ctx.persistItemState
        }, null, 8 /* PROPS */, ["label", "items", "onItemToggled"]))
      : _createCommentVNode("v-if", true),
    (_ctx.activeTab === 'integrations')
      ? (_openBlock(), _createBlock(_component_customise_sidebar_items, {
          key: 4,
          class: "mt-auto",
          label: _ctx.$tc('integration_hub.integrations'),
          items: _ctx.allIntegrations,
          "item-tab": "integrations",
          "data-test": "customiser-sidebar-items-integrations",
          onItemToggled: _ctx.persistIntegrationState
        }, null, 8 /* PROPS */, ["label", "items", "onItemToggled"]))
      : _createCommentVNode("v-if", true)
  ]))
}