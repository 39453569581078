<script type="text/babel">
import { map, uniq } from 'lodash';
import infiniteLoading from 'vue-infinite-loading';

import CreateContactModal from './CreateContactModal';
import avatar from '../Avatar.vue';
import SearchBox from '../SearchBox';

export default {
  name: 'ContactsIndex',
  emits: ['update-search-term'],
  data: function () {
    return {
      disableScroll: false,
      page: 0,
      list: [],
      timer: null,
      loading: false,
      listIdentifier: 0,
      showSearch: false,
      searchTerm: this.searchTermProp,
    };
  },

  props: {
    scope: {
      type: String,
      default: 'all',
    },
    searchTermProp: {
      type: String,
      default: '',
    },
  },

  components: {
    avatar,
    SearchBox,
    infiniteLoading,
    CreateContactModal,
  },

  mounted() {
    this.$nextTick(() => this.restoreSearchFromUrl());
    this.listIdentifier++;
  },

  methods: {
    fetchData: function (scroll) {
      if (this.page === 0) {
        this.loading = true;
      }
      this.page++;
      return new Promise((resolve, reject) => {
        window
          .singleRequest('get', '/api/v2/contacts', {
            params: { term: this.searchTerm, page: this.page, scope: this.scope },
          })
          .then((result) => {
            result.data.data.forEach((ticket) => this.list.push(ticket));
            this.loading = false;
            if (scroll) {
              this.$nextTick(() => {
                scroll.loaded();
                if (result.data.links.next == null) {
                  scroll.complete();
                }
              });
            }
          });
      });
    },

    restoreSearchFromUrl() {
      if (this.$route.query.term) {
        this.searchTerm = this.$route.query.term;
      } else {
        this.searchTerm = '';
      }
    },

    onKeyup: function () {
      this.loading = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.search();
      }, 250);
    },

    search: function () {
      this.list = [];
      if (this.searchTerm) {
        this.$router.replace({ path: this.$route.path, query: { term: this.searchTerm } });
      } else {
        this.$router.replace({ path: '/contacts' });
      }
      this.listIdentifier++;
      this.page = 0;
    },

    reload() {
      this.listIdentifier++;
      this.page = 0;
      this.list = [];
    },

    contactNameUpdated(contact) {
      this.list.find((c) => c.id === contact.id).full_name = contact.full_name;
    },

    getContactType(contacts) {
      let types = uniq(map(contacts, 'type'));
      let res = types.join(', ').toLowerCase();
      if (!res) {
        return '-';
      }
      return res;
    },

    createContactModal() {
      $('#CreateContactModal').modal('show');
      $('#CreateContactModal input:first').focus();
    },
  },
  watch: {
    searchTerm: {
      handler(newValue) {
        this.$emit('update-search-term', newValue);
      },
    },
  },
};
</script>

<template>
  <div class="flex h-full min-w-0 flex-1 flex-col pt-4">
    <div class="hidden-md-down flex w-1/6">
      <search-box v-model="searchTerm" :placeholder="$t('general.searching')" @search="search"></search-box>
    </div>
    <div class="flex flex-shrink-0 items-center pb-4 pl-12 leading-none lg:pl-0 lg:pt-4">
      <div style="width: 45px" class="text-center">
        <i class="material-icons">supervisor_account</i>
      </div>
      <h6 v-if="scope === 'private'" class="mb-0">{{ $t('contacts.my_contacts') }}</h6>
      <h6 v-else class="mb-0">{{ $t('contacts.all_contacts') }}</h6>
      <button class="btn success btn-xs selector-create-contact ml-4 rounded text-white" @click="createContactModal">
        {{ $t('contacts.create_contacts_btn') }}
      </button>
      <i class="material-icons hidden-lg-up ml-auto mr-4 text-grey-600" @click="showSearch = !showSearch">search</i>
    </div>
    <div v-if="showSearch" class="hidden-lg-up mb-4 flex flex-shrink-0 px-4">
      <search-box v-model="searchTerm" :placeholder="$t('general.searching')" @search="search"></search-box>
    </div>
    <div class="scroll-on-hover px-4 lg:pl-0" infinite-wrapper>
      <div
        class="
          hidden-md-down
          mb-2
          flex
          select-none
          items-center
          rounded-lg
          border-2 border-grey-200
          py-3
          text-left text-grey-600
        "
      >
        <div class="flex flex-1 items-center leading-none">
          <span class="w-6" style="margin-left: 20px">
            <i class="material-icons md-16 leading-none">account_circle</i>
          </span>
          <span>{{ $t('contacts.name') }}</span>
        </div>
        <div class="flex flex-1 items-center leading-none">
          <span class="w-6"><i class="material-icons md-16 leading-none">perm_identity</i></span>
          <span class="">Info</span>
        </div>
        <div class="flex flex-1 items-center leading-none">
          <span class="w-6"><i class="material-icons md-16 leading-none">help_outline</i></span>
          <span class="">Type</span>
        </div>
      </div>
      <div class="min-w-0">
        <router-link
          v-for="contact in list"
          :key="contact.id"
          class="mb-2 flex w-full items-center rounded-lg border-2 border-grey-200"
          data-tag="div"
          :to="'/contacts/' + contact.id"
        >
          <div class="flex min-w-0 flex-1">
            <div class="overflow-hidden rounded-lg" style="flex-shrink: 0; margin-top: -2px; margin-left: -2px">
              <avatar
                :abbr="contact.abbr"
                class="-ml-1 -mt-1"
                :image="contact.profile_image"
                :color="contact.color"
                data-hj-suppress
              ></avatar>
            </div>
            <div class="my-3 ml-2 text-ellipsis pr-4" data-hj-suppress>
              <span v-if="!contact.full_name" class="text-ellipsis">
                <i v-if="contact.is_private" class="fa fa-lock mr-1 text-grey-500"></i>
                {{ contact.identifier }}
              </span>
              <span v-else class="text-ellipsis" data-hj-suppress>
                <i v-if="contact.is_private" class="fa fa-lock mr-1 text-grey-500"></i>
                {{ contact.full_name }}
              </span>
            </div>
          </div>
          <div class="hidden-md-down min-w-0 flex-1" data-hj-suppress>
            <span class="text-ellipsis pr-4">
              {{ contact.identifier }}
            </span>
          </div>
          <div class="hidden-md-down flex-1">
            {{ getContactType(contact.channels) }}
          </div>
        </router-link>
      </div>
      <infinite-loading
        :identifier="listIdentifier"
        direction="bottom"
        force-use-infinite-wrapper="true"
        @infinite="fetchData"
      >
        <template #spinner>
          <div></div>
        </template>
        <template #no-more><div></div></template>
        <template #no-results><div></div></template>
        <template #error><span @click="fetchData">- Try again -</span></template>
      </infinite-loading>
    </div>
    <create-contact-modal :scope="scope" @call-reload-method="reload"></create-contact-modal>
  </div>
</template>
