<template>
  <div></div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
