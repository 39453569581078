import moment from 'moment';

import { getDayFromTooltipContext } from '../utilities';

import type { CustomTooltipItem } from '@/components/Reporting/Components/V2/Charts/types/CustomTooltipItem';
import type { TranslationFunction } from '@/types';

export function getAverageConversationsChartConfiguration(
  t: TranslationFunction,
  showError: boolean,
  isLoading: boolean,
  isHourly: boolean
) {
  return {
    scales: {
      x: {
        ticks: {
          maxRotation: 90,
          autoSkip: false,
          suggestedMin: 0,
          padding: 8,
          color: '#000',
          font: {
            size: 12,
          },
        },
        suggestedMin: 0,
        min: 0,
        display: true,
        grid: {
          drawTicks: false,
          display: false,
          color: '#E1E3E5',
          borderColor: '#E1E3E5',
          lineWidth: 1,
        },
        title: {
          display: false,
        },
        border: {
          color: '#E1E3E5',
          lineWidth: 1,
        },
      },
      y: {
        ticks: {
          suggestedMin: 0,
          padding: 16,
          color: isLoading || showError ? 'transparent' : '#70767B',
          font: {
            size: 14,
          },
          callback: function (value: unknown, index: number) {
            if (index % 2 === 0) {
              return value;
            }
          },
        },
        grace: isHourly ? '20%' : '10%',
        min: 0,
        suggestedMin: 0,
        grid: {
          drawTicks: false,
        },
        beginAtZero: true,
        title: {
          display: false,
        },
      },
    },
    plugins: {
      ...(!isLoading
        ? {
            tooltip: {
              yAlign: 'bottom' as const,
              position: 'average' as const,
              backgroundColor: '#292C2E',
              titleAlign: 'center' as const,
              titleFont: { family: 'Inter', size: 14, style: 'normal', weight: 'bold' } as const,
              titleColor: '#FFF',
              bodyAlign: 'center' as const,
              bodyFont: { family: 'Inter', size: 24, weight: 'bold' },

              callbacks: {
                label: (tooltipContext: CustomTooltipItem) => {
                  return tooltipContext.formattedValue;
                },
                title: (tooltipContext: CustomTooltipItem[]) => {
                  if (tooltipContext[0].dataset.label === t('reports_v2.hourly')) {
                    const formattedTime = moment(tooltipContext[0].label, ['HH:mm']).format('hA');

                    return t('reports_v2.conversations_created_at', { time: formattedTime });
                  } else {
                    const day = getDayFromTooltipContext(tooltipContext[0], t);

                    return t('reports_v2.conversations_created_on', { day });
                  }
                },
              },

              padding: {
                left: 12,
                right: 12,
                top: 6,
                bottom: 6,
              },
              cornerRadius: 8,
              displayColors: false,
            },
          }
        : {
            tooltip: {
              enabled: false,
            },
          }),

      legend: {
        display: false,
      },
    },
  };
}
