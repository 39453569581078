<template>
  <t-modal
    v-model="isOpen"
    :title="translations.title"
    data-test="two-factor-authentication-setup-step-three"
    variant="narrow"
    is-closable
    @close="handleClose"
  >
    <div
      v-if="!isResetFlow"
      class="
        relative
        mb-6
        overflow-hidden
        rounded-xl
        before:absolute before:h-full before:w-full before:rounded-xl before:border-1 before:border-[rgba(0,0,0,0.1)]
      "
    >
      <img
        :src="`${
          /* @ts-ignore */
          $root.assetsURL
        }images/2fa_enabled.gif`"
        alt="two factor illustration"
      />
    </div>
    <p class="t-text-md">{{ translations.description }}</p>
    <template #footer>
      <t-button size="md" @click="handleClose">
        {{ translations.close }}
      </t-button>
    </template>
  </t-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TwoFactorAuthSetupStepThree',
  emits: ['close', 'success'],
  props: {
    isOpen: Boolean,
    isResetFlow: Boolean,
  },
  data() {
    return {
      readMoreLink: 'https://help.trengo.com/en/articles/64182-setting-up-your-two-factor-authentication',
    };
  },
  computed: {
    translations() {
      return {
        title: this.isResetFlow
          ? this.$t('auth.two_factor_authentication_reset_step_three_title')
          : this.$t('auth.two_factor_authentication_setup_step_three_title'),
        description: this.isResetFlow
          ? this.$t('auth.two_factor_authentication_reset_step_three_description')
          : this.$t('auth.two_factor_authentication_setup_step_three_description'),
        close: this.$t('general.close'),
      };
    },
  },
  methods: {
    handleClose() {
      this.$emit('success');
      this.$emit('close');
    },
  },
});
</script>
