<template>
  <div>
    <atomic-input
      v-model="getInputValue"
      :size="inputSize"
      :placeholder="placeholder"
      :required="true"
      :class="inputClass"
      :error="isError"
      @input="getValueOnInput"
    />
    <t-error-item v-if="showErrorMsg" :class="errorItemClass" :text="errorMsg" />
  </div>
</template>

<script>
export default {
  name: 'MessageInput',
  emits: ['useInputValue'],
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    inputVal: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    showErrorMsg: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: '',
    },
    inputSize: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: '',
    },
    errorItemClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: this.getInputValue,
    };
  },
  computed: {
    getInputValue: {
      get() {
        return this.inputVal;
      },
      set(newValue) {
        this.value = newValue;
      },
    },
  },
  methods: {
    getValueOnInput(val) {
      this.$emit('useInputValue', val);
    },
  },
};
</script>
