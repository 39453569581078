<template>
  <div class="rounded-xl p-3" style="background-color: rgb(247, 247, 247); overflow: hidden">
    <video
      v-if="element.meta.file_path"
      :src="getCdnPath(element.meta.file_path)"
      controls
      class="-mb-4 -ml-3 -mt-3 w-full"
      :class="{ '': !element.meta.message }"
      style="width: 330px"
    ></video>
    <div
      v-if="!element.meta.file_path || element.meta.message"
      :class="[!element.meta.file_path ? '' : 'mt-6']"
      class="px-2 text-grey-600"
    >
      <div v-if="!element.meta.file_path && !element.meta.message" class="font-italic">
        {{ $t('flowbot.step_presenter_video_click') }}
        <i class="material-icons text-sm">create</i>
        {{ $t('flowbot.step_presenter_video_to_edit_this_video_with_optional_caption') }}
      </div>
      <div v-else v-html="formatText(element.meta.message)"></div>
    </div>
  </div>
</template>

<script>
import textFormatter from '../mixins/TextFormatter';

export default {
  props: ['element'],

  mixins: [textFormatter],

  methods: {
    getCdnPath(file_path) {
      return CDN + file_path;
    },
  },
};
</script>
