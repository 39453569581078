<template>
  <div>
    <t-inline-banner v-if="sanitizedMessage" class="mt-4">
      <span data-test="message" v-html="sanitizedMessage"></span>
    </t-inline-banner>
    <div v-if="computedInputTags.length" class="mt-4 flex flex-wrap" style="gap: 0.75rem">
      <div v-for="(tag, i) in tags" :key="tag" :style="{ width: computedWidth }">
        <search-bar
          v-model.trim="computedInputTags[i].value"
          :options="tagOptions(i)"
          :error="error && computedInputTags[i].value === ''"
          size="sm"
          :placeholder="tag"
          data-test="'tag-input-'+i"
          @input="(e) => handleFilterTags(e.target.value, i)"
          @keydown="(e) => onKeyDown(e, i)"
        >
          <template #item="slotProps">
            <div
              class="
                tag-item
                pointer
                t-text-desktop-paragraph-sm
                mx-3
                my-1
                rounded-xl
                px-2
                py-1
                text-grey-800
                hover:bg-grey-300
              "
              @click="(e) => handleItemClick(e.currentTarget.innerText, i)"
            >
              {{ slotProps.item.identifier }}
            </div>
          </template>
        </search-bar>
      </div>
      <div class="t-text-desktop-paragraph-sm text-grey-600">
        {{ $t('broadcast.select_an_existing_tag_to_replace_each_variable_or_enter_some_plain_text') }}
      </div>
      <t-error-item v-if="error" data-test="tags-error" :text="$t('broadcast.please_fill_in_all_tags')" />
    </div>
  </div>
</template>

<script>
import { sanitize } from 'dompurify';
import { cloneDeep, filter, map, uniqBy } from 'lodash';
import { mapState } from 'vuex';

import SearchBar from '@/components/WabBroadcasting/components/SearchBar';
export default {
  name: 'WhatsappComposer',
  emits: ['tags'],
  components: {
    SearchBar,
  },
  props: {
    template: {
      type: Object,
      default: () => ({}),
    },
    modelValue: {
      type: Array,
      default: () => [{}],
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tags: [],
      parsedOriginalMessage: '',
      sanitizedMessage: '',
      editedMessage: '',
      quickReplies: [],
      filteredAutocompleteTags: [],
      allAutocompleteTags: [
        { title: 'Ticket ID', identifier: '[ticket_id]' },
        { title: 'Contact name', identifier: '[contact_name]' },
        { title: 'Agent name', identifier: '[agent_name]' },
        { title: 'Profile name', identifier: '[profile_name]' },
      ],
      originalTemplateId: 0,
      backupTags: [],
    };
  },
  mounted() {
    this.loadAutocompleteTags();
  },
  computed: {
    computedInputTags: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    computedWidth() {
      if (this.tags.length === 1) {
        return '100%';
      } else if (this.tags.length === 2 || this.tags.length === 4) {
        return 'calc(50% - 6px)';
      } else return 'calc(33.33333% - 8px)';
    },
    ...mapState({
      customFieldsContact: (state) => state.customFields.customContactFields,
    }),
  },
  watch: {
    template: {
      handler(template) {
        if (!this.originalTemplateId) {
          this.originalTemplateId = template.id;
        }
        this.tags = template.message?.match(/{{(.*?)}}/g);
        this.parsedOriginalMessage = template.message;
        let emptyTags = [];
        this.tags?.forEach((tag, i) => {
          this.parsedOriginalMessage = this.parsedOriginalMessage.replace(
            tag,
            '<span class="template-tag text-leaf-500">' + tag + '</span>'
          );
          emptyTags[i] = {
            value:
              this.originalTemplateId === template.id &&
              this.backupTags[i]?.value !== undefined &&
              this.backupTags[i]?.value !== ''
                ? this.backupTags[i].value
                : '',
            key: tag,
          };
        });
        this.$emit('tags', emptyTags);
        this.editedMessage = this.parsedOriginalMessage;
      },
    },
    editedMessage: {
      handler(message) {
        this.sanitizedMessage = sanitize(message, { ALLOWED_TAGS: ['span'], ALLOWED_ATTR: ['class'] });
      },
      immediate: true,
    },
    computedInputTags: {
      deep: true,
      handler() {
        if (!this.backupTags.length && this.computedInputTags[0]?.value) {
          this.backupTags = cloneDeep(this.computedInputTags);
        }
        this.editedMessage = this.parsedOriginalMessage;
        this.tags?.forEach((tag, i) => {
          const tagValue =
            this.computedInputTags[i].value !== undefined && this.computedInputTags[i].value !== ''
              ? this.computedInputTags[i].value
              : tag;
          this.editedMessage = this.editedMessage.replace(
            tag,
            '<span class="template-tag text-leaf-500">' + tagValue + '</span>'
          );
        });
        this.$emit('tags', this.computedInputTags);
      },
      immediate: true,
    },
  },
  methods: {
    loadAutocompleteTags() {
      map(this.customFieldsContact, (f) => {
        if (f.identifier && f.identifier.toLowerCase() !== 'null') {
          this.allAutocompleteTags.push({ title: f.title, identifier: '[' + f.identifier + ']' });
        }
      });
      this.allAutocompleteTags = uniqBy(this.allAutocompleteTags, 'identifier');
    },
    handleFilterTags(value, i) {
      this.filteredAutocompleteTags.splice(
        i,
        1,
        this.allAutocompleteTags.filter((item) => item.identifier.toLowerCase().includes(value.toLowerCase()))
      );
    },
    tagOptions(i) {
      if (this.filteredAutocompleteTags[i] && this.filteredAutocompleteTags[i].length) {
        return this.filteredAutocompleteTags[i];
      } else return this.allAutocompleteTags;
    },
    handleItemClick(value, i) {
      this.computedInputTags.splice(i, 1, { value: value, key: this.tags[i] });
    },
    onKeyDown($event, i) {
      if (filter(this.allAutocompleteTags, (tag) => tag.identifier === $event.target.value).length) {
        if ($event.code === 'Backspace') {
          this.computedInputTags.splice(i, 1, { value: '', key: this.tags[i] });
        }
      }
    },
  },
};
</script>
