<template>
  <div class="w-full overflow-x-hidden">
    <entity-item
      v-for="entity in computedEntities"
      :key="entity.id"
      :model-value="isSelected(entity)"
      data-test="entity-item"
      :label-by="labelBy"
      :entity="entity"
      :has-workload="hasWorkload"
      :entity-type="entityType"
      :selected="isSelected(entity)"
      @select="handleSelect"
    />
  </div>
</template>

<script>
import EntityItem from '../EntityItem';

export default {
  name: 'ListEntities',
  emits: ['select-entity', 'update:modelValue'],
  components: { EntityItem },
  props: {
    entityType: {
      type: String,
      default: 'channel',
      validate: (value) => ['channel', 'member'].includes(value),
    },
    selectedEntities: {
      type: Array,
      default: () => [],
    },
    labelBy: {
      type: String,
      default: 'name',
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    hasWorkload: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isSelected(entity) {
      return this.selectedEntities?.filter((e) => e.id === entity.id).length > 0;
    },
    handleSelect(entity) {
      this.$emit('select-entity', entity);
    },
  },
  computed: {
    computedEntities: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
