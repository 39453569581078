<template>
  <t-modal
    :is-open="isOpen"
    :title="$t('quote.quote_ready_modal_title')"
    data-test="pricing-migration-quote-available-modal"
    variant="narrow"
    @close="handleClose"
  >
    <div
      class="
        relative
        mb-6
        overflow-hidden
        rounded-xl
        before:absolute before:h-full before:w-full before:rounded-xl before:border-1 before:border-[rgba(0,0,0,0.1)]
      "
    >
      <img
        :src="`${
          /* @ts-ignore */
          $root.assetsURL
        }images/pricing_migration_quote_available.png`"
        alt="pricing migration quote available illustration"
      />
    </div>
    <p class="t-text-md" v-html="bodyHtml" />
    <template #footer>
      <t-button
        btn-style="secondary"
        href="https://help.trengo.com/en/articles/417896-new-trengo-new-plan-pricing"
        target="__blank"
      >
        {{ $t('quote.quote_ready_modal_learn_more') }}
      </t-button>
      <t-button @click="handleRedirect">
        {{ $t('quote.quote_ready_modal_view_new_plan') }}
      </t-button>
    </template>
  </t-modal>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

import { vm } from '@/ApplicationHandler';
import { useSubscriptionStore } from '@/store/pinia';

const IS_DISMISSED_KEY = 'pricing_migration_quote_available_dismissed';

const isDismissed = ref(localStorage.getItem(IS_DISMISSED_KEY) ? true : false);

const { subscription } = useSubscriptionStore();

const hasQuote = computed(() => Boolean(subscription?.quote));
const isOpen = computed(() => Boolean(!isDismissed.value && hasQuote.value));

const bodyHtml = computed(() => {
  return vm.$t('quote.quote_ready_modal_description', {
    dueDate: useSubscriptionStore().subscription?.subscriptionDueDate || '',
  });
});

const handleClose = () => {
  localStorage.setItem(IS_DISMISSED_KEY, 'true');
  isDismissed.value = true;
};

const handleRedirect = () => {
  handleClose();
  vm.$router.push({ name: 'subscription', query: { quoteOverview: 'open' } });
};
</script>
