<template>
  <t-modal
    v-model="computedValue"
    class="onboarding_step_one"
    :close-on-backdrop-click="false"
    :close-on-esc="false"
    :is-closable="false"
  >
    <template v-if="!isStepThree" #default>
      <div class="flex flex-row justify-center">
        <div data-test="onboarding-step-1-form" class="onboarding-step-form">
          <div class="mx-1 sm:mx-10 xl:mx-20 xl2:mt-20 xl2:w-132">
            <div
              class="mb-4 mt-4 flex flex-col justify-between px-1 pb-2 sm:mt-32 md:items-baseline xl2:mt-24"
              data-test="onboarding-step-one"
            >
              <div class="t-text-sm-emphasize mb-2 text-grey-600">
                <span>{{ $t('onboarding.channels_step1_of_2') }}</span>
              </div>
              <div class="block flex w-full flex-row items-center justify-between">
                <h4 class="t-text-h2 text-grey-900">
                  {{ $t('onboarding.channels_step1_lets_start') }}
                  {{ $t('onboarding.channels_step1_company_profile') }}
                </h4>
              </div>
            </div>
            <div data-test="company-name" class="mb-6">
              <t-input-text
                id="company-name-input"
                v-model="companyName"
                :label="$t('onboarding.channels_step1_company_name_title')"
                data-test="company-name-input"
                :has-error="!validation.companyName"
                :size="inputSize"
                :placeholder="$t('onboarding.channels_step1_company_name_placeholder')"
                @focusout="handleValidation('companyName')"
              />
              <t-error-item
                v-if="!validation.companyName"
                data-test="company-name-error"
                :text="$t('onboarding.channels_step1_company_name_error')"
              />
            </div>
            <div class="mb-6">
              <label class="t-text-lg-emphasize">
                {{ $t('onboarding.channels_step1_mobile_number_title') }}
              </label>
              <t-phone-input
                v-model="mobilePhoneNumber"
                data-test="mobile-number-error"
                :placeholder="$t('general.custom_input_list_item_search_country')"
                :size="inputSize"
                :error-message="$t('onboarding.channels_step1_mobile_number_error')"
                default-country-code="+31"
                :error="validation.mobilePhoneNumber === false"
                @change="handlePhoneChange"
                @is-valid="isValid"
              />
            </div>
            <div class="mb-6">
              <div class="mb-2">
                <label class="t-text-lg-emphasize mb-0.5">
                  {{ $t('onboarding.channels_step1_people_company_title') }}
                </label>
                <div class="t-text-md text-grey-700">
                  <span>{{ $t('onboarding.channels_step1_people_company_subtitle') }}</span>
                </div>
              </div>
              <t-multi-choice
                v-model="company_size"
                data-test="company-size"
                child-class="w-full"
                class="grid grid-cols-2 justify-between gap-3 md:grid-cols-3 lg:grid-cols-5"
              >
                <template v-for="option in company_sizes_options" v-slot:[option]="{ selected }">
                  <modal-multi-item
                    :key="option"
                    :data-test="'company-size-option-' + option"
                    size="auto"
                    :title="option"
                    :selected="selected"
                  />
                </template>
              </t-multi-choice>
              <t-error-item
                v-if="!validation.company_size"
                data-test="company-size-error"
                :text="$t('onboarding.channels_step1_people_company_error')"
              />
            </div>

            <div class="mb-6">
              <t-select-input
                data-test="company-industry"
                class="rounded-xl"
                :options="industries"
                :size="inputSize"
                :label="$t('onboarding.channels_step1_industry_title')"
                :placeholder="$t('onboarding.channels_step1_industry_placeholder')"
                @change="handleChangeIndustry"
              />
              <t-error-item
                v-if="!validation.industry"
                data-test="company-industry-error"
                :text="$t('onboarding.channels_step1_industry_error')"
              />
            </div>

            <div v-if="showOtherIndustryTextField" class="mb-6">
              <t-input-text
                id="company-other-industry"
                v-model="otherIndustry"
                :label="$t('onboarding.channels_step1_other_industry_title')"
                data-test="company-other-industry"
                :has-error="!validation.otherIndustry"
                :size="inputSize"
                :placeholder="$t('onboarding.channels_step1_other_industry_placeholder')"
                @focusout="handleValidation('otherIndustry')"
              />
              <t-error-item
                v-if="!validation.otherIndustry"
                data-test="company-other-industry-error"
                :text="$t('onboarding.channels_step1_other_industry_error')"
              />
            </div>

            <div>
              <t-error-item
                v-if="!isSubmitButtonValid"
                data-test="submit-button-error"
                class="mb-1"
                :text="$t('onboarding.channels_step1_required_fields')"
              />
              <div class="mb-1 flex flex-col-reverse justify-start sm:mb-14 sm:flex-row">
                <t-button size="lg" data-test="submit-button" class="mb-24 sm:mb-4" @click="handleSubmit">
                  {{ $t('onboarding.channels_step1_next_step') }}
                </t-button>
              </div>
            </div>
          </div>
        </div>

        <div class="testimonial__wrapper">
          <img :src="testimonialImg" class="testimonial__image" />
          <div class="testimonial__inner">
            <p class="testimonial__description">{{ $t('onboarding.testimonial_content') }}</p>
            <p class="testimonial__author">{{ $t('onboarding.testimonial_author') }}</p>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="isStepThree" #default>
      <step-three @close-step-one="closeModal" />
    </template>
  </t-modal>
</template>

<script>
import StepThree from '@/components/Onboarding/OnboardingModalScreens/StepThree';
import { FEATURE_FLAG_ACTIVATION } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';
import breakpoints from '@/util/breakpoints';

import testimonialPng from './assets/testimonial.webp';
import ModalMultiItem from './components/ModalMultiItem';

export default {
  name: 'StepOne',
  components: {
    StepThree,
    ModalMultiItem,
  },
  data() {
    return {
      testimonialImg: testimonialPng,
      openModal: true,
      breakpoints,
      company_sizes_options: ['1-2', '3-10', '11-50', '51-250', '250+'],
      validation: {
        companyName: true,
        mobilePhoneNumber: null,
        company_size: true,
        industry: true,
        otherIndustry: true,
        submitButton: true,
      },
      isSubmitButtonValid: true,
      industries: [
        {
          value: 'automotive',
          text: this.$t('onboarding.channels_step1_industry_automotive'),
        },
        {
          value: 'banking_and_financial_services',
          text: this.$t('onboarding.channels_step1_industry_banking_and_financial_services'),
        },
        {
          value: 'business_services',
          text: this.$t('onboarding.channels_step1_industry_business_services'),
        },
        {
          value: 'consumer_services',
          text: this.$t('onboarding.channels_step1_industry_consumer_services'),
        },
        {
          value: 'education_and_e_learning',
          text: this.$t('onboarding.channels_step1_industry_education_and_e_learning'),
        },
        {
          value: 'entertainment_and_gaming',
          text: this.$t('onboarding.channels_step1_industry_entertainment_and_gaming'),
        },
        {
          value: 'government',
          text: this.$t('onboarding.channels_step1_industry_government'),
        },
        {
          value: 'healthcare',
          text: this.$t('onboarding.channels_step1_industry_healthcare'),
        },
        {
          value: 'marketing_and_research_services',
          text: this.$t('onboarding.channels_step1_industry_marketing_and_research_services'),
        },
        {
          value: 'media_and_pr_and_publishing',
          text: this.$t('onboarding.channels_step1_industry_media_and_pr_and_publishing'),
        },
        {
          value: 'non_profit_and_social_organization',
          text: this.$t('onboarding.channels_step1_industry_non_profit_and_social_organization'),
        },
        {
          value: 'real_estate_and_housing',
          text: this.$t('onboarding.channels_step1_industry_real_estate_and_housing'),
        },
        {
          value: 'restaurants_and_hospitality',
          text: this.$t('onboarding.channels_step1_industry_restaurants_and_hospitality'),
        },
        {
          value: 'retail_and_ecommerce',
          text: this.$t('onboarding.channels_step1_industry_retail_and_ecommerce'),
        },
        {
          value: 'transportation',
          text: this.$t('onboarding.channels_step1_industry_transportation'),
        },
        {
          value: 'travel_and_leisure_and_events',
          text: this.$t('onboarding.channels_step1_industry_travel_and_leisure_and_events'),
        },
        {
          value: 'utilities_and_energy',
          text: this.$t('onboarding.channels_step1_industry_utilities_and_energy'),
        },
      ],
      showOtherIndustryTextField: false,
      companyName: '',
      mobilePhoneNumber: '',
      company_size: '',
      industry: '',
      otherIndustry: '',
      isMobileValid: false,
    };
  },
  props: {
    validateEmail: {
      default: false,
      type: Boolean,
    },
  },
  inject: ['proceedStep', 'close'],
  mounted() {
    this.redirectBasedOnFeatureFlag();
  },
  computed: {
    breakLine() {
      return !this.isMobile && '<br/>';
    },
    computedValue: {
      get() {
        return this.openModal;
      },
      set(value) {
        this.openModal = value;
      },
    },
    isStepThree() {
      return this.validateEmail && this.openModal;
    },
    isMobile() {
      const { comparisons } = this.breakpoints;
      return !(comparisons.eq_lg || comparisons.eq_xl || comparisons.eq_md);
    },
    inputSize() {
      return this.isMobile ? 'md' : 'lg';
    },
  },
  watch: {
    company_size(value) {
      this.validation.company_size = value?.length > 0;
      this.handleSubmitButtonValidation();
    },
    industry(value) {
      this.showOtherIndustryTextField = value === 'other';
      this.validation.industry = value?.length > 0;
      this.handleSubmitButtonValidation();
    },
  },
  methods: {
    handleChangeIndustry(data) {
      this.industry = data;
    },
    redirectBasedOnFeatureFlag() {
      if (useFeatureFlagStore().isEnabled(FEATURE_FLAG_ACTIVATION.TA_ONBOARDING_CHANNEL_SELECTION)) {
        this.$router.push('/onboard-channels');
      } else {
        this.$router.push('/welcome');
      }
    },
    closeModal() {
      this.computedValue = false;
      this.$nextTick(() => {
        this.close();
        this.proceedStep(6);
      });
    },
    handleSubmit() {
      if (!this.validate()) {
        if (this.validation.mobilePhoneNumber === null) {
          this.validation.mobilePhoneNumber = false;
        }
        return false;
      }
      const data = {
        company_name: this.companyName,
        phone: this.mobilePhoneNumber,
        company_size: this.company_size,
        industry: this.industry,
        industry_other: this.otherIndustry,
      };
      this.submit(data);
    },
    handleValidation(field) {
      this.validation[field] = this.$data[field]?.length > 0;
      this.handleSubmitButtonValidation();
    },
    handleSubmitButtonValidation() {
      this.isSubmitButtonValid = !Object.values(this.validation).includes(false);
    },
    validate() {
      this.validation = {
        companyName: this.companyName?.length > 0,
        mobilePhoneNumber: this.isMobileValid,
        company_size: this.company_size?.length > 0,
        industry: this.industry?.length > 0,
        otherIndustry: this.industry === 'other' ? this.otherIndustry?.length > 0 : true,
      };
      this.handleSubmitButtonValidation();
      return this.isSubmitButtonValid;
    },
    handlePhoneChange(v) {
      if (v === '') {
        this.validation.mobilePhoneNumber = null;
        this.isMobileValid = false;
      }
    },
    isValid(v) {
      this.isMobileValid = v;
      this.validation.mobilePhoneNumber = v;
      this.handleSubmitButtonValidation();
    },
    submit(data) {
      axios.post('/api/v2/signup/data', data).then(() => {
        this.proceedStep(3);
      });
    },
  },
};
</script>

<style scoped lang="scss" src="./onboardingSteps.scss" />
<style lang="scss" src="./onboardingStepsGlobal.scss" />
