const froalaLocaleCodes = Object.seal({
  ['en-GB']: 'en_gb',
  ['nl-NL']: 'nl',
  ['de-DE']: 'de',
  ['fr-FR']: 'fr',
  ['es-ES']: 'es',
  ['ru-RU']: 'ru',
  ['id-ID']: 'id',
  ['pt-BR']: 'pt_br',
});

export { froalaLocaleCodes };
