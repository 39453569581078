<template>
  <t-modal
    v-model="shouldOpenModal"
    :close-on-backdrop-click="false"
    class="manage-roles-modal"
    variant="sidebar"
    :title="$t('user_management.user_roles_settings')"
    @close="$emit('handleOpenRolesModal')"
  >
    <template #sidebar>
      <roles-sidebar :active-role="activeRole" :roles="roles" @setActiveRole="handleSetActiveRole" />
    </template>
    <div class="manage-roles-modal__content">
      <div>
        <h3 class="t-text-lg-emphasize">{{ $t(`user_management.${activeRole.name}`) }}</h3>
        <p class="t-text-md text-grey-700">
          {{ $t(`user_management.${activeRole.name}_description`) }}
        </p>
      </div>

      <collapse-permissions-button
        :is-permissions-collapsed="isPermissionsExpanded"
        @click="handleIsPermissionsExpanded"
      />

      <transition name="slide" @before-enter="collapse" @enter="expand" @before-leave="expand" @leave="collapse">
        <div v-show="isPermissionsExpanded" ref="permissions_list" class="transition-all duration-100 ease-out">
          <permissions-list :active-role="activeRole" />
        </div>
      </transition>

      <t-button-text
        v-if="isMobile"
        class="open-external mt-8"
        href="https://help.trengo.com/en/articles/362659-user-roles"
        target="_blank"
      >
        {{ $t(`user_management.learn_more_about_user_roles`) }}
        <external-link-linear size="1rem" class="ml-2" />
      </t-button-text>

      <config-permissions
        v-if="configurablePermissions.length"
        :role="activeRole"
        :configurable-permissions="configurablePermissions"
        @change="handleToggleClick"
      />
    </div>
    <template #footer>
      <t-button btn-style="secondary" @click="$emit('handleOpenRolesModal')">
        {{ $t('user_management.done') }}
      </t-button>
    </template>
  </t-modal>
</template>

<script>
import { ExternalLinkLinear } from '@trengo/trengo-icons';
import { debounce } from 'lodash';

import CollapsePermissionsButton from '@/components/Users/components/ManageRoles/CollapsePermissionsButton';
import ConfigPermissions from '@/components/Users/components/ManageRoles/ConfigPermissions';
import PermissionsList from '@/components/Users/components/ManageRoles/PermissionsList';
import RolesSidebar from '@/components/Users/components/ManageRoles/RolesSidebar';
import { USER_ROLE } from '@/Configs/Constants/UserRoles';
import breakpoints from '@/util/breakpoints';

import { getAuthorizationRoles, updateAuthorizationRoles } from '../Api';

export default {
  name: 'ManageRolesModal',
  emits: ['handleOpenRolesModal'],
  components: {
    CollapsePermissionsButton,
    ConfigPermissions,
    ExternalLinkLinear,
    PermissionsList,
    RolesSidebar,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      roles: [],
      permissions: [],
      permissionsToUpdate: [],
      configurablePermissions: [],
      isPermissionsExpanded: true,
      activeRole: {},
    };
  },
  mounted() {
    getAuthorizationRoles().then((r) => {
      const { data } = r.data;
      this.roles = data.filter((role) => role.name !== 'trengo');
      this.activeRole = data.find((role) => role.name === USER_ROLE.ADMINISTRATOR);
      this.updatePermissions();
    });
  },
  methods: {
    collapse(e) {
      e.style.height = '0';
      e.style.opacity = '0';
    },
    expand(e) {
      e.style.height = `${e.scrollHeight}px`;
      e.style.opacity = `1`;
    },
    handleIsPermissionsExpanded() {
      this.isPermissionsExpanded = !this.isPermissionsExpanded;
    },
    handleSetActiveRole(role) {
      this.activeRole = this.roles.filter((r) => r.name === role.name)[0];
      this.updatePermissions();

      if (this.isPermissionsExpanded) {
        this.$refs.permissions_list.style.height = '100%';
      }
    },
    updatePermissions() {
      this.configurablePermissions = this.activeRole.permissions.filter((permission) => permission.configurable);
    },
    updateRoles(permissionId, roleId) {
      if (permissionId && roleId) {
        const { granted } = this.configurablePermissions.find((perm) => perm.id === permissionId);

        if (granted) {
          this.permissions = this.configurablePermissions.filter((perm) => perm.id !== permissionId && perm.granted);
          this.permissionsToUpdate = this.permissions.map((perm) => perm.id);

          this.configurablePermissions = this.configurablePermissions.map((perm) => {
            if (perm.id === permissionId) {
              perm.granted = false;
            }
            return perm;
          });
        } else {
          this.permissions = this.configurablePermissions.filter(
            (perm) => perm.id !== permissionId && perm.granted === true
          );

          const permissionsToUpdateArray = this.permissions.map((perm) => perm.id);
          this.permissionsToUpdate = [...permissionsToUpdateArray, permissionId];

          this.configurablePermissions = this.configurablePermissions.map((perm) => {
            if (perm.id === permissionId) {
              perm.granted = true;
            }
            return perm;
          });
        }
      }

      updateAuthorizationRoles({ id: roleId, permissions: this.permissionsToUpdate });

      this.updatePermissions();
    },
    handleToggleClick(permissionId, roleId) {
      if (!permissionId && roleId) return false;
      const updateRole = debounce(() => {
        this.updateRoles(permissionId, roleId);
      }, 500);
      updateRole();
    },
  },
  computed: {
    isMobile() {
      return !breakpoints.comparisons.gt_sm;
    },
    shouldOpenModal() {
      return this.isOpen;
    },
  },
};
</script>

<style src="./ManageRolesModal.scss" lang="scss" scoped />
