<template>
  <div>
    <div v-if="!loading" class="rounded-xl border-1 border-grey-300 xl:w-182">
      <info-card :id="id" v-model="showEditSenderDomain" :domain="domain" :is-valid="isValid" />
      <mobile-preview-card v-if="isMobile || isValid" :has-back="false">
        DNS setup only available on desktop
      </mobile-preview-card>
      <dns-details v-else :id="id" :dkim="dkim" :spf="spf" :domain="domain" />
    </div>
    <div v-show="loading" class="flex justify-center text-4xl font-extralight">
      <i class="fal fa-spinner m-auto animate-spin" />
    </div>
  </div>
</template>

<script>
import { getSenderDomains } from '@/components/Channels/EmailSettings/Pages/ChannelSettings/api';
import MobilePreviewCard from '@/components/common/MobilePreviewCard';
import eventBus from '@/eventBus';
import breakpoints from '@/util/breakpoints';

import { DnsDetails, InfoCard } from './components';

export default {
  name: 'ForwardingEmailRecordTable',
  emits: ['show'],
  components: { DnsDetails, InfoCard, MobilePreviewCard },
  props: {
    modelValue: {
      type: Boolean,
      default: true,
    },
    id: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      breakpoints,
      dkim: { value: '', name: '', error: '', is_valid: false },
      spf: { value: '', name: '', error: '', is_valid: false },
      domain: { value: '', error: '', is_valid: false, domain_verify_txt_key: '', address: '' },
      isValid: false,
      loading: true,
    };
  },
  mounted() {
    eventBus.$on('verifyDNS', this.handleVerifyDNS);
    this.fetchSenderDomains();
  },
  methods: {
    spfIsCorrect(data) {
      const { spf_error, spf_valid } = data;
      this.spf = Object.assign({}, this.spf, { is_valid: spf_valid, error: spf_error });
      return this.spf.is_valid;
    },

    domainIsVerified(data) {
      const { verified_at } = data;
      const is_valid = verified_at !== '' && verified_at !== null;
      this.domain = Object.assign({}, this.domain, { is_valid: is_valid });
      return is_valid;
    },

    dkimIsCorrect(data) {
      const { dkim_error, dkim_valid } = data;
      this.dkim.is_valid = dkim_valid;
      this.dkim.error = dkim_error;
      this.dkim = Object.assign({}, this.dkim, { is_valid: dkim_valid, error: dkim_error });
      return dkim_valid;
    },

    handleVerifyDNS(payload) {
      this.isValid = payload.domain_verified_at !== null;
      // const isProcessValid = { spf: false, dkim: false, domain: false };
      // if (payload.spf) {
      //   const { dkim } = payload;
      //   const { error: spf_error, valid: spf_valid } = dkim;
      //   isProcessValid.spf = this.spfIsCorrect({ spf_error, spf_valid });
      // }
      // if (payload.domain) {
      //   const { verified_at } = payload;
      //   isProcessValid.domain = this.domainIsVerified({ verified_at });
      // }
      // if (payload.dkim) {
      //   const { dkim } = payload;
      //   const { error: dkim_error, valid: dkim_valid } = dkim;
      //   isProcessValid.dkim = this.dkimIsCorrect({ dkim_error, dkim_valid });
      // }
      // this.isValid = Object.values(isProcessValid).every(Boolean);
    },

    mapData(key, data) {
      if (key === 'domain') {
        return {
          value: data.domain,
          is_valid: data.domain_verified_at !== '' && data.domain_verified_at !== null,
          domain_verify_txt_key: data.domain_verify_txt_key,
          address: data.address,
        };
      }
      if (key === 'dkim') {
        return {
          name: data['dkim_name'],
          value: data['dkim'],
          is_valid: true,
          error: '',
        };
      }
      if (key === 'spf') {
        return {
          name: data.domain + '.',
          value: data['spf'],
          is_valid: true,
          error: '',
        };
      }
    },
    fetchSenderDomains() {
      getSenderDomains(this.id)
        .then((r) => {
          const { data } = r;

          this.dkim = this.mapData('dkim', data);
          this.spf = this.mapData('spf', data);
          this.domain = this.mapData('domain', data);
          const isProcessValid = { spf: this.spf.is_valid, dkim: this.dkim.is_valid, domain: this.domain.is_valid };

          this.isValid = Object.values(isProcessValid).every(Boolean);
          this.domain_verify_txt_key = data.domain_verify_txt_key;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    isMobile() {
      const { eq_xs, gt_sm, gt_md, gt_lg } = this.breakpoints.comparisons;
      return (gt_sm || eq_xs) && !(gt_md || gt_lg);
    },
    showEditSenderDomain: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('show', value);
      },
    },
  },
};
</script>
