<template>
  <t-modal
    :model-value="isOpen"
    title="Your new API token"
    :close-on-esc="true"
    :close-on-backdrop-click="false"
    @close="$emit('close')"
  >
    <div class="flex flex-col">
      <t-inline-banner class="mb-6" type="warning">
        <template #icon>
          <alert-triangle-linear class="banner-icon" />
        </template>

        {{ $t('apps_integration.this_is_your_personal_access_token') }}
        <strong>{{ $t('apps_integration.only_time_token_will_ever_be_displayed') }}</strong>
        {{ $t('apps_integration.you_may_regenerate_the_token_at_any_time') }}
      </t-inline-banner>

      <div class="flex flex-row">
        <t-input-text id="apiToken" :model-value="token" size="sm" readonly class="flex-shrink select-all font-mono" />

        <t-button btn-style="secondary" size="sm" class="ml-3 flex flex-row items-center" @click.prevent="copyToken">
          <copy-linear size="1.4rem" class="mr-2" />
          {{ $tc('general.copy') }}
        </t-button>
      </div>
    </div>

    <template #footer>
      <div class="flex flex-row items-center">
        <t-button @click.prevent="$emit('close')">{{ $tc('general.close') }}</t-button>
      </div>
    </template>
  </t-modal>
</template>

<script setup lang="ts">
import { AlertTriangleLinear, CopyLinear } from '@trengo/trengo-icons';

import { vm } from '@/ApplicationHandler';

const props = defineProps<{ isOpen: boolean; token?: string }>();
defineEmits(['close']);

async function copyToken() {
  try {
    if (props.token) {
      await navigator.clipboard.writeText(props.token).then(() => {
        vm.flashSuccess('Token succesfuly copied');
      });
    }
  } catch (err) {
    if (err instanceof Error) {
      console.error(err);
      vm.flashError(err.message);
    }
  }
}
</script>
