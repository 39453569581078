<script type="text/babel">
export default {
  data() {
    return {
      path: '/tickets/search',
    };
  },

  mounted() {
    var url = this.$route.query.term == null ? '/tickets/search' : '/tickets/search?term=' + this.$route.query.term;
    $('iframe').attr('src', url);
  },

  watch: {
    $route(route) {
      $('iframe').attr('src', '/tickets' + route.fullPath);
    },
  },
};
</script>

<template>
  <iframe :src="path" width="100%" height="99%" frameborder="0"></iframe>
</template>
