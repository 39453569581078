import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-362bc856"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wab-landing max-h-screen w-full overflow-y-auto overflow-x-hidden pb-12" }
const _hoisted_2 = { class: "ml-6 mr-6 flex sm:justify-center xl:ml-0 xl:mr-0" }
const _hoisted_3 = { class: "wab-landing-content mt-18 flex-shrink-0 xs3:w-full md:w-1/2" }
const _hoisted_4 = { class: "wab-landing-header" }
const _hoisted_5 = { class: "t-text-desktop-h1 font-bold" }
const _hoisted_6 = { class: "m-0" }
const _hoisted_7 = { class: "t-text-desktop-body-sm pr-4 text-sun-900" }
const _hoisted_8 = { class: "m-0" }
const _hoisted_9 = { class: "t-text-desktop-body-sm pr-4 text-sun-900" }
const _hoisted_10 = { class: "actions mt-8 flex" }
const _hoisted_11 = {
  key: 0,
  class: "t-text-desktop-paragraph-sm mt-12 text-grey-600"
}
const _hoisted_12 = {
  key: 1,
  class: "mt-3"
}
const _hoisted_13 = { class: "t-text-sm text-grey-700" }
const _hoisted_14 = {
  key: 2,
  class: "t-text-desktop-paragraph-sm mt-3"
}
const _hoisted_15 = {
  class: "text-grey-600",
  "data-test": "contact-support-text"
}
const _hoisted_16 = {
  key: 3,
  class: "t-text-desktop-paragraph-sm mt-3"
}
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { class: "wab-landing-preview mt-24 flex w-full items-center xs3:w-0 md:w-1/2" }
const _hoisted_19 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_feature_icon = _resolveComponent("t-feature-icon")!
  const _component_arrow_top_circle_linear = _resolveComponent("arrow-top-circle-linear")!
  const _component_t_badge = _resolveComponent("t-badge")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!
  const _component_t_unordered_list = _resolveComponent("t-unordered-list")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_lifebuoy_linear = _resolveComponent("lifebuoy-linear")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("header", _hoisted_4, [
          _createVNode(_component_t_feature_icon, {
            icon: _ctx.FEATURE_ICON.BROADCASTING,
            class: "mb-8"
          }, null, 8 /* PROPS */, ["icon"]),
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('broadcast.landing_page_broadcast_title')), 1 /* TEXT */),
          (_ctx.requiresAddon)
            ? (_openBlock(), _createBlock(_component_t_badge, {
                key: 0,
                variant: "upgrade",
                text: "Requires add-on",
                class: "cursor-defailt mb-8 mt-3 w-fit",
                "data-test": "wab-broadcast-add-on-badge"
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_arrow_top_circle_linear, { size: "1rem" })
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          (_ctx.requiresSmsAndWhatsappUpgrade)
            ? (_openBlock(), _createBlock(_component_t_inline_banner, {
                key: 1,
                type: "warning",
                class: "my-8 w-max"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createElementVNode("ul", _hoisted_6, [
                      _createElementVNode("li", _hoisted_7, _toDisplayString(_ctx.$t('broadcast.landing_page_sms_available_on_scale')), 1 /* TEXT */)
                    ])
                  ])
                ]),
                _: 1 /* STABLE */
              }))
            : (_ctx.requiresWhatsappUpgrade)
              ? (_openBlock(), _createBlock(_component_t_inline_banner, {
                  key: 2,
                  type: "warning",
                  class: "my-8 w-max"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("ul", _hoisted_8, [
                        _createElementVNode("li", _hoisted_9, _toDisplayString(_ctx.$t('broadcast.landing_page_whatsapp_available_on_enterprise')), 1 /* TEXT */)
                      ])
                    ])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("main", null, [
          _createVNode(_component_t_unordered_list, { items: _ctx.featuresList }, null, 8 /* PROPS */, ["items"]),
          _createElementVNode("div", _hoisted_10, [
            (_ctx.hasCompanyProfilePermission && _ctx.requiresAddon)
              ? (_openBlock(), _createBlock(_component_t_button, {
                  key: 0,
                  class: "mr-4 flex items-center justify-center",
                  "data-test": "unlock-broadcast-btn",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redirectToPage('/admin/company_profile/subscription')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_arrow_top_circle_linear, {
                      size: "1.25rem",
                      class: "mr-1"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('broadcast.unlock_broadcast')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ((_ctx.requiresWhatsappUpgrade && _ctx.hasSmsEnabled) || _ctx.isEntitledToSmsAndWhatsapp)
              ? (_openBlock(), _createBlock(_component_t_button, {
                  key: 1,
                  class: "mr-4",
                  "data-test": "create-broadcast-btn",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.redirectToPage('/broadcasting/new')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('broadcast.landing_page_new_broadcast')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            (_ctx.hasCompanyProfilePermission && _ctx.requiresSmsAndWhatsappUpgrade)
              ? (_openBlock(), _createBlock(_component_t_button, {
                  key: 2,
                  class: "mr-4 flex items-center justify-center",
                  "data-test": "upgrade-broadcast-btn",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.redirectToPage(_ctx.SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS)))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_arrow_top_circle_linear, {
                      size: "1.25rem",
                      class: "mr-2"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('broadcast.landing_page_upgrade_your_plan')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            (_ctx.requiresWhatsappUpgrade && !_ctx.hasSmsEnabled)
              ? (_openBlock(), _createBlock(_component_t_button, {
                  key: 3,
                  class: "mr-4 flex items-center justify-center",
                  href: "mailto:support@trengo.com",
                  "data-test": "contact-support-btn"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_lifebuoy_linear, {
                      size: "1.25rem",
                      class: "mr-2"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('broadcast.landing_page_contact_support')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_t_button, {
              href: "https://help.trengo.com/en/articles/63677-sending-sms-to-a-group-of-people-with-trengo",
              target: "_blank",
              "btn-style": "secondary",
              "data-test": "learn-more-btn"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('broadcast.landing_page_learn_more')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          ((_ctx.requiresWhatsappUpgrade || _ctx.isEntitledToSmsAndWhatsapp) && _ctx.hasSmsEnabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createTextVNode(_toDisplayString(_ctx.$t('broadcast.landing_page_you_still_have_access_to')) + " ", 1 /* TEXT */),
                _createVNode(_component_router_link, {
                  to: "/sms",
                  class: "underline"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('broadcast.landing_page_previous_sms_broadcasts')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]))
            : _createCommentVNode("v-if", true),
          (!_ctx.hasCompanyProfilePermission && (_ctx.requiresAddon || _ctx.requiresSmsAndWhatsappUpgrade))
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('broadcast.ask_your_administrator_to_unlock_broadcasting')), 1 /* TEXT */)
              ]))
            : _createCommentVNode("v-if", true),
          (_ctx.requiresWhatsappUpgrade && !_ctx.hasSmsEnabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t('broadcast.landing_page_please_contact_support')), 1 /* TEXT */)
              ]))
            : _createCommentVNode("v-if", true),
          (_ctx.isEntitledToSmsAndWhatsapp && !_ctx.hasSmsEnabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createElementVNode("span", {
                  class: "text-grey-600",
                  "data-test": "contact-support-text-with-link",
                  innerHTML: _ctx.$t('broadcast.landing_page_please_contact_support_with_link')
                }, null, 8 /* PROPS */, _hoisted_17)
              ]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("img", {
          src: `${_ctx.$root.assetsURL}img/wab-broadcasting/broadcast-landing.svg`,
          class: "w-536px"
        }, null, 8 /* PROPS */, _hoisted_19)
      ])
    ])
  ]))
}