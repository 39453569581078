<template>
  <div class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis">{{ $t('chatbot.categories') }}</span>
          <div class="m-l-auto">
            <router-link
              class="btn success btn-md text-white"
              :to="'/admin/chatbots/' + record.id + '/categories/create'"
            >
              {{ $t('chatbot.add_category') }}
            </router-link>
          </div>
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body container">
        <div class="row-inner">
          <div class="p-a">
            <div class="box">
              <table class="table-bordered table-hover table">
                <thead>
                  <tr>
                    <th width="45%">{{ $t('chatbot.name') }}</th>
                    <th width="45%">{{ $t('chatbot.questions') }}</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="category in list">
                    <td width="45%">{{ category.name }}</td>
                    <td width="45%">{{ category.questions_count }}</td>
                    <td width="10%">
                      <router-link
                        class="text-info"
                        :to="'/admin/chatbots/' + record.id + '/categories/' + category.id"
                      >
                        {{ $t('chatbot.edit_category') }}
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  props: ['record'],

  data() {
    return {
      list: [],
    };
  },

  mounted() {
    axios.get('/api/v2/chatbot/' + this.record.id + '/categories').then((res) => {
      this.list = res.data;
    });
  },
};
</script>
