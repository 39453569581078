import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-693e1660"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "t-text-sm-emphasize text-grey-800" }
const _hoisted_4 = { class: "t-text-sm mt-1 text-grey-600" }
const _hoisted_5 = { class: "flex flex-row items-center gap-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_down_linear = _resolveComponent("chevron-down-linear")!
  const _component_chevron_up_linear = _resolveComponent("chevron-up-linear")!
  const _component_t_action_button = _resolveComponent("t-action-button")!
  const _component_channels_accordion_item = _resolveComponent("channels-accordion-item")!
  const _component_t_accordion_new = _resolveComponent("t-accordion-new")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_t_accordion_new, {
      ref: "accordion",
      "is-open": $data.isOpen,
      class: "channels_accordions",
      onChange: _cache[0] || (_cache[0] = (state) => ($data.isOpen = state)),
      onOpen: $options.getState
    }, {
      trigger: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass([$options.computedTriggerClass, "channel-accordion-item"])
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("img", {
              class: "w-12",
              src: $options.getIcon
            }, null, 8 /* PROPS */, _hoisted_1)
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString($props.item.title), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_4, _toDisplayString($props.item.sub_title), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_t_action_button, null, {
              default: _withCtx(() => [
                (!$data.isOpen)
                  ? (_openBlock(), _createBlock(_component_chevron_down_linear, {
                      key: 0,
                      size: "1.3rem",
                      class: "h-20px w-20px text-grey-800"
                    }))
                  : (_openBlock(), _createBlock(_component_chevron_up_linear, {
                      key: 1,
                      size: "1.3rem",
                      class: "h-20px w-20px text-grey-800"
                    }))
              ]),
              _: 1 /* STABLE */
            })
          ])
        ], 2 /* CLASS */)
      ]),
      list: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.channel_items, (item) => {
          return (_openBlock(), _createBlock(_component_channels_accordion_item, {
            key: item.channel,
            item: item,
            onClick: ($event: any) => ($options.handleClick(item))
          }, null, 8 /* PROPS */, ["item", "onClick"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["is-open", "onOpen"])
  ]))
}