import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "relative flex flex-col rounded-lg bg-white p-4 shadow-md",
  "data-test": "attach-email-wrapper"
}
const _hoisted_2 = { class: "t-text-md-emphasize mx-auto" }
const _hoisted_3 = { class: "flex flex-row items-center justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_input_text = _resolveComponent("t-input-text")!
  const _component_t_button = _resolveComponent("t-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$tc('email_settings.add_email')), 1 /* TEXT */),
    _createVNode(_component_t_input_text, {
      id: "emailEdit",
      modelValue: _ctx.email,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
      class: "mb-3",
      size: "sm",
      placeholder: "johndoe@example.com",
      "data-test": "sidebar-attach-email-input",
      onKeydown: [
        _withKeys(_withModifiers(_ctx.saveEmail, ["prevent"]), ["enter"]),
        _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('close-email-edit')), ["prevent"]), ["esc"]))
      ]
    }, null, 8 /* PROPS */, ["modelValue", "onKeydown"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_t_button, {
        "btn-style": "secondary",
        "data-test": "sidebar-cancel-email-button",
        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('close-email-edit')), ["prevent"]))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$tc('general.cancel')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_t_button, {
        "data-test": "sidebar-save-email-button",
        onClick: _withModifiers(_ctx.saveEmail, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$tc('general.save')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ])
  ]))
}