import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mb-8 flex flex-row items-center" }
const _hoisted_2 = { class: "flex h-[44px] w-[44px] items-center justify-center rounded-xl bg-galaxy-600 p-2 sm:h-[48px] sm:w-[48px]" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "ml-4 flex flex-col" }
const _hoisted_5 = { class: "t-text-mobile-body-md sm:t-text-button-lg sm:mb-1" }
const _hoisted_6 = { class: "t-text-desktop-body-xs sm:t-text-desktop-body-sm text-grey-800" }
const _hoisted_7 = { class: "mb-4 grid grid-cols-1 gap-2 sm:mb-3 lg:grid-cols-4" }
const _hoisted_8 = { class: "t-text-button-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_integration_card_icon = _resolveComponent("integration-card-icon")!
  const _component_item_button = _resolveComponent("item-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_gray_card = _resolveComponent("gray-card")!

  return (_openBlock(), _createBlock(_component_gray_card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "w-24",
            src: `${_ctx.$root.assetsURL}img/add-new-plugin.svg`,
            "aria-hidden": "true",
            alt: ""
          }, null, 8 /* PROPS */, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('getting_started.title_add_an_integration')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('getting_started.subtitle_add_an_integration')), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shownIntegrations, (integration) => {
          return (_openBlock(), _createBlock(_component_item_button, {
            key: integration.id,
            onClick: ($event: any) => (_ctx.navigateToIntegration(integration))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_integration_card_icon, {
                class: "mb-1",
                icon: integration.icon
              }, null, 8 /* PROPS */, ["icon"]),
              _createElementVNode("span", _hoisted_8, _toDisplayString(integration.name), 1 /* TEXT */)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createVNode(_component_router_link, {
        class: "t-text-button-md text-leaf-500 hover:text-leaf-500 hover:underline",
        to: "/admin/integrations/all"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('getting_started.button_see_all')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}