<script type="text/babel">
export default {
  props: {
    start: {
      default: 0,
    },
    toFormat: {
      default: 'HHMMSS',
    },
    id: '',
  },

  data() {
    return {
      time: '',
      timer: null,
    };
  },

  mounted() {
    this.time = this.start;

    this.timer = setInterval(() => {
      this.time++;
    }, 1000);
  },

  unmounted() {
    clearInterval(this.timer);
  },

  methods: {
    format(time) {
      return window.toHHMMSS(time, this.toFormat);
    },
  },
};
</script>

<template>
  <span>{{ format(this.time) }}</span>
</template>
