<template>
  <div @click="handleClick">
    <div v-if="isUserExists">
      <avatar
        class="bg-white"
        :font-size="avatarFontSize"
        :width="computedWidth"
        :abbr="user.abbr"
        :color="user.color"
        :image="user.profile_image"
      />
    </div>
    <div
      v-else
      class="
        add-user-container
        flex
        cursor-pointer
        flex-row
        items-center
        justify-center
        rounded-full
        border-1 border-dashed border-leaf-500
        bg-white
        hover:border-solid
        active:bg-leaf-100
      "
    >
      <img :src="`${$root.assetsURL}img/add_user.svg`" />
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';

import Avatar from '@/components/Avatar';
import breakpoints from '@/util/breakpoints';

export default {
  name: 'AddUser',
  emits: ['click'],
  components: { Avatar },
  data() {
    return {
      breakpoints,
    };
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleClick() {
      if (isEmpty(this.user)) {
        this.$emit('click');
      }
    },
    isLgOrXl() {
      const { comparisons } = this.breakpoints;
      return comparisons.eq_lg || comparisons.eq_xl;
    },
    is2Xs() {
      const { w } = this.breakpoints;
      return w >= 320 && w <= 360;
    },
  },
  computed: {
    avatarFontSize() {
      return this.isLgOrXl() ? '1.7rem' : '1.25rem';
    },
    isUserExists() {
      return !isEmpty(this.user);
    },
    computedWidth() {
      return this.isLgOrXl() || this.is2Xs() ? 84 : 64;
    },
  },
};
</script>

<style scoped lang="scss" src="./AddUser.scss" />
