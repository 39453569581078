<template>
  <div class="ml-16 flex flex-col">
    <h1 class="t-text-h4 m-0 mb-2 mt-8 p-0 text-grey-800">{{ $tc('apps_integration.rest_api') }}</h1>

    <t-card class="mt-4 flex flex-col bg-white">
      <h2 class="t-text-md-emphasize m-0 mb-2 p-0 text-grey-800">{{ $tc('apps_integration.rest_api') }}</h2>
      <p class="t-text-sm m-0 mb-6 p-0 text-grey-600">{{ $tc('apps_integration.rest_api_description') }}</p>

      <div class="flex flex-row items-center">
        <t-button
          v-if="!hasToken"
          :class="{ loader: isGenerating }"
          class="w-fit"
          :disabled="isGenerating"
          @click.prevent="generateToken(false)"
        >
          {{ $t('apps_integration.generate_api_token') }}
        </t-button>

        <t-button
          v-else
          :class="{ loader: isGenerating }"
          class="w-fit"
          :disabled="isGenerating"
          @click.prevent="isRegenerateModalOpen = true"
        >
          {{ $t('apps_integration.regenerate_api_token') }}
        </t-button>

        <t-button btn-style="secondary" class="ml-3 w-fit" @click.prevent="openDocumentation">
          {{ $tc('apps_integration.view_documentation') }}
        </t-button>
      </div>
    </t-card>

    <confirm-regeneration-modal
      :is-open="isRegenerateModalOpen"
      @confirm="generateToken(true)"
      @cancel="isRegenerateModalOpen = false"
    />
    <token-modal :is-open="isTokenModalOpen" :token="token" @close="isTokenModalOpen = false" />
  </div>
</template>

<script setup lang="ts">
import axios from 'axios';
import { onMounted, ref } from 'vue';

import { vm } from '@/ApplicationHandler';
import ConfirmRegenerationModal from '@/components/IntegrationHub/Pages/RestApi/ConfirmRegenerationModal.vue';
import TokenModal from '@/components/IntegrationHub/Pages/RestApi/TokenModal.vue';

const hasToken = ref(false);

onMounted(async () => {
  isGenerating.value = true;

  axios.get('api/v2/api_tokens').then((res) => {
    hasToken.value = res.data.id !== undefined;
    isGenerating.value = false;
  });
});

const token = ref('');

const isGenerating = ref(false);
const isTokenModalOpen = ref(false);

const openDocumentation = () => window.open('https://developers.trengo.com', '_blank');

const isRegenerateModalOpen = ref(false);

async function saveToken() {
  axios
    .post('/api/v2/api_tokens')
    .then((res) => {
      token.value = res.data.token;
      window.eventBus.$emit('api-token-generated');

      isTokenModalOpen.value = true;
    })
    .catch((err) => {
      console.error(err);
      vm.flashError(err.message);
    })
    .finally(() => {
      isGenerating.value = false;
    });
}

async function deleteToken() {
  await axios.delete('api/v2/api_tokens/1').catch((err) => {
    console.error(err);
    vm.flashError(err.message);
    isGenerating.value = false;
  });
}

async function generateToken(regenerate = false) {
  if (isGenerating.value) {
    return;
  }

  isGenerating.value = true;
  isRegenerateModalOpen.value = false;

  if (regenerate) {
    await deleteToken().then(() => saveToken());
  } else {
    await saveToken();
  }
}
</script>
