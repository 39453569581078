<template>
  <sidebar-dropdown
    :title="$tc('ticket_sidebar.contact_fields')"
    collapse-key="contactFieldsDropdown"
    data-test="contact-fields-dropdown"
  >
    <template v-if="fields.length > 0">
      <div v-for="field in fields" :key="field.id" data-test="contact-field-item-wrapper">
        <p class="t-text-md mb-0 p-0 text-grey-800" data-test="contact-field-item-title">{{ field.title }}</p>

        <custom-field-item class="mb-2" :field="field" @save="save(field)" />
      </div>
    </template>

    <template v-else>
      <t-inline-banner type="default">
        <template #icon>
          <info-linear />
        </template>

        <p class="t-text-sm-emphasize m-0 p-0">{{ $tc('ticket_sidebar.no_contact_fields_setup') }}</p>
      </t-inline-banner>
    </template>
  </sidebar-dropdown>
</template>

<script lang="ts">
import { InfoLinear } from '@trengo/trengo-icons';
import { cloneDeep, find, lowerCase, remove, some } from 'lodash';
import { mapState } from 'vuex';

import { storeContactCustomField } from '@/components/TicketSidebar/Api';
import CustomFieldItem from '@/components/TicketSidebar/CustomFieldItem';
import SidebarDropdown from '@/components/TicketSidebar/SidebarDropdown.vue';
import ticketSidebarMixins from '@/components/TicketSidebar/ticketSidebarMixins';

export default {
  name: 'ContactFieldsDropdown',

  components: {
    InfoLinear,
    CustomFieldItem,
    SidebarDropdown,
  },

  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    ticket: {
      type: Object,
      default: () => ({}),
    },
  },

  mixins: [ticketSidebarMixins],

  data: function () {
    return {
      fields: [],
    };
  },

  mounted() {
    this.loadList();
  },

  watch: {
    customFieldsLoaded(loaded) {
      if (loaded) {
        this.loadList();
      }
    },
  },

  computed: {
    ...mapState({
      storedFields: (state) => state.customFields.customContactFields,
      customFieldsLoading: (state) => state.customFields.loading,
      customFieldsLoaded: (state) => state.customFields.loaded,
    }),
  },

  methods: {
    save(fieldToSave) {
      const field = find(this.fields, function (field) {
        return field.id === fieldToSave.id;
      });
      const fieldValue = field.value || null;
      this.commitFields();

      storeContactCustomField(this.contact.id, field.id, fieldValue)
        .then(() => {
          this.resetFieldServerError(lowerCase(field.type), field.id);
        })
        .catch((error) => {
          this.setFieldServerError(lowerCase(field.type), field.id, error.response?.data?.errors);
        })
        .finally(() => {
          this.commitFields();
        });
    },

    commitFields() {
      this.$store.commit('customFields/setTicketContactFields', cloneDeep(this.fields));
    },

    loadList() {
      this.fields = cloneDeep(this.storedFields);

      remove(this.fields, (field) => {
        return field.channels.length && !some(field.channels, ['id', this.ticket.channel.id]);
      });

      this.fields = this.fields.map((field) => {
        return {
          ...field,
          value: this.contact?.custom_field_data ? this.contact?.custom_field_data[field.title] : null,
          contactId: this.contact?.id,
        };
      });

      this.commitFields();
    },
  },
};
</script>
