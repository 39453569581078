import { vm } from '@/ApplicationHandler';

import Automation from '../Automation';

const DefaultAutomation = class {
  constructor() {
    this.title = vm.$t('widget_automation.loading');
    this.active = false;
    this.payload = {
      actions: [],
      conditions: [],
    };
  }
};

export default DefaultAutomation;
