const channels_actions = {
  gmail: {
    auth_link: '/api/v2/gmail/authenticate',
  },
  outlook: {
    auth_link: '/api/v2/outlook/authenticate',
  },
  another_provider: {
    inner_link: '/admin/channels2/email/create?type=TRENGO',
    inner_skip_link: '/tickets',
  },
  instagram: {
    auth_link: '/api/v2/instagram/authenticate',
  },
  facebook: {
    auth_link: '/api/v2/facebook/authenticate',
  },
};
export default channels_actions;
