<template>
  <div class="h-full">
    <logo-header class="bg-grey-100" />
    <div class="justify-content flex h-full items-center bg-grey-100">
      <div style="max-width: 380px; margin: 0 auto; min-width: 350px">
        <div class="alert alert-danger" id="LoginErrors" v-if="errorMessage">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
          <li v-for="error in errorMessage">
            {{ error }}
          </li>
        </div>
        <div
          class="box box-shadow-z2 animated fadeIn shadow"
          style="
            animation-duration: 0.2s;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09) !important;
            border-radius: 10px;
            padding: 2.25rem;
          "
        >
          <div class="text-center">
            <img :src="`${$root.assetsURL}img/trengo-logo.png`" class="mb-8" alt="" style="width: 90%" />
          </div>
          <div class="box-body">
            <form class="form-horizontal" @submit.prevent="resetPassword">
              <div class="form-group row">
                <strong class="col-xs-12">New password:</strong>
                <div class="col-xs-12">
                  <input
                    type="password"
                    autofocus
                    name="password"
                    v-model="password"
                    class="form-control mt-2"
                    placeholder="New password"
                  />
                </div>
              </div>
              <div class="form-group row">
                <strong class="col-xs-12">Repeat new password:</strong>
                <div class="col-xs-12">
                  <input
                    type="password"
                    name="password_confirmation"
                    v-model="passwordConfirmation"
                    placeholder="Repeat new password"
                    class="form-control mt-2"
                  />
                </div>
              </div>
              <button type="submit" class="btn btn-lg btn-block success rounded">Reset password</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <help-center-button />
  </div>
</template>
<script type="text/babel">
import HelpCenterButton from '@/components/common/HelpCenterButton';
import LogoHeader from '@/components/common/PageHeaders/LogoHeader';

import { useCredentialNotifications } from './useCredentialNotifications';

export default {
  components: { LogoHeader, HelpCenterButton },
  data() {
    return {
      password: null,
      passwordConfirmation: null,
      errorMessage: null,
    };
  },

  methods: {
    resetPassword() {
      axios
        .post('/reset-password/reset-password-submit', {
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          reset_token: this.$route.params.token,
        })
        .then((res) => {
          useCredentialNotifications().setNotification(res.data.message);
          if (res.data.token) {
            router.push({ name: 'login' });
          } else {
            router.push({ name: 'request-email' });
          }
        })
        .catch((err) => {
          this.errorMessage = [];

          for (let i = 0; i < err.response.data.errors.password.length; i++) {
            this.errorMessage.push(err.response.data.errors.password[i]);
          }

          $('#growls').hide();
        });
    },
  },

  mounted() {
    $('#TrengoLoader').hide();
  },
};
</script>
