<template>
  <atomic-dropdown
    class="pointer text lg:text-lg"
    max-height="500px"
    width="428px"
    :options="options"
    :search="false"
    @selected="emitEvent"
  >
    <template #toggle>
      <div
        class="flex w-full items-center rounded-xl border border-grey-300 px-3 py-3 hover:border-grey-400 lg:px-4"
        :class="value.value ? 'text-grey-800' : 'text-grey-500'"
      >
        {{ value.label ? value.label : selectorEmptyLabel }}
        <i class="far fa-chevron-down ml-auto pl-1 pr-1 text-sm text-grey-600"></i>
      </div>
    </template>
    <template #option="prop">
      <div class="flex items-center">
        {{ prop.option.label }}
      </div>
    </template>
  </atomic-dropdown>
</template>

<script>
export default {
  name: 'DropdownSelector',
  emits: ['update:modelValue'],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    selectorValue: {
      type: String,
      default: '',
    },
    selectorEmptyLabel: {
      type: String,
      default: 'Select',
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    emitEvent(e) {
      this.$emit('update:modelValue', e);
    },
  },
};
</script>
