<template>
  <div data-test="establishing-connection" class="flex flex-col items-center justify-center">
    <t-spinner />
    <p class="t-text-md mt-4 text-grey-600">{{ $t('whatsapp.establishing_connection') }}</p>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import eventBus from '@/eventBus';

import { Steps } from '../../constants';

export default defineComponent({
  name: 'EstablishingConnection',
  mounted() {
    setTimeout(() => {
      eventBus.$emit('go-to-step', Steps.MESSAGE_RECIEVED);
    }, 2400);
  },
});
</script>
