export const BROADCAST_STATUS = {
  SCHEDULED: 'SCHEDULED',
  SENT: 'SENT',
  PARTLY_SENT: 'PARTLY_SENT',
  LIST_PARTLY_SENT: 'LIST_PARTLY_SENT',
  FAILED: 'FAILED',
  LIST_FAILED: 'LIST_FAILED',
  SENDING: 'SENDING',
  CREATED: 'CREATED',
  NONE: '',
} as const;

export const BROADCAST_STATUS_DISPLAY_TEXT = {
  SCHEDULED: 'Scheduled',
  SENT: 'Sent',
  PARTLY_SENT: 'Sent*',
  CREATED: 'Created',
  FAILED: 'Failed',
  SENDING: 'Sending',
} as const;
