import { matchesRegex } from './regexHelpers';
// function to check if the route is a ticket route
export const isTicketRoute = (route: string): boolean => {
  const ticketRouteRegex = /^\/tickets\/[^/]+/;
  return matchesRegex(route, ticketRouteRegex);
};

// find if path is '/ai/*'
export const isAiSubroute = (route: string): boolean => {
  const aiFeatureSubrouteRegex = /^\/ai\/[^/]+/;
  return matchesRegex(route, aiFeatureSubrouteRegex);
};

// function to check if the route is a reports subroute with a filter query string
export const isReportsRouteWithFilter = (route: string): boolean => {
  const reportsSubrouteRegex = /^\/reports(\/[^?]*)?\?filters=.*$/;
  return matchesRegex(route, reportsSubrouteRegex);
};
