<template>
  <t-card class="flex flex-col bg-white" data-test="integration-detail-wrapper">
    <div class="mb-4 flex flex-col rounded-lg" data-test="integration-image-carousel">
      <div v-if="integration.screenshots.length > 0" class="mb-2">
        <img
          class="rounded-lg border-1 border-solid border-grey-300"
          :src="`${$root.assetsURL}${integration.screenshots[selectedScreenshotIndex]}`"
          alt=""
        />
      </div>
      <div class="flex flex-row gap-2">
        <div v-for="(screenshot, index) in integration.screenshots" :key="index">
          <img
            class="screenshot-thumbnail"
            :class="{ selected: selectedScreenshotIndex === index }"
            :src="`${$root.assetsURL}${screenshot}`"
            alt=""
            @click="selectScreenshot(index)"
          />
        </div>
      </div>
    </div>
    <p
      class="text-base text-grey-800"
      data-test="integration-detail-long-description"
      v-html="$tc(integration.longDescription)"
    ></p>
  </t-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import type { Integration } from '@/store/types/integrations';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'IntegrationHubDetailCard',

  data() {
    return {
      selectedScreenshotIndex: 0,
    };
  },

  props: {
    integration: {
      type: Object as PropType<Integration>,
      default: () => ({}),
    },
  },
  methods: {
    selectScreenshot(id) {
      this.selectedScreenshotIndex = id;
    },
  },
});
</script>

<style lang="scss">
// <br> tags come from lokalize for longDescription
br {
  @apply mb-2;
}

.screenshot-thumbnail {
  @apply cursor-pointer rounded-lg border-2 border-solid border-grey-300 object-cover;
  height: 52px;
  width: 82px;
}

.selected {
  @apply border-leaf-400 ring-2 ring-leaf-200;
}
</style>
