<template>
  <div class="cursor-pointer">
    <div
      role="button"
      class="
        modal-item-button
        flex
        cursor-pointer
        select-none
        flex-col
        items-center
        justify-between
        rounded-xl
        border-1 border-grey-300
        bg-white
        hover:border-leaf-500
        active:bg-leaf-100
        sm:flex-row sm:justify-center
        lg:flex-col
      "
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemButton',
};
</script>

<style scoped lang="scss" src="./ItemButton.scss" />
