<template>
  <div>
    <div>
      <TextEditor v-bind="$props"></TextEditor>
    </div>

    <div class="mt-2">
      <div class="mb-2 mt-2 font-bold">{{ $t('flowbot.step_user_input_save_to') }}</div>
      <select v-model="element.meta.field_type" class="form-control">
        <option value="ticket">{{ $t('flowbot.step_user_input_ticket') }}</option>
        <option value="contact">{{ $t('flowbot.step_user_input_contact') }}</option>
      </select>
      <span class="form-text" style="">
        {{ $t('flowbot.step_user_input_ticket_contact_info_text') }}
      </span>
    </div>
    <div v-if="element.meta.field_type" class="mt-2">
      <div class="mb-2 mt-2 font-bold">
        {{ $t('flowbot.step_user_input_select_or_create_field_type_field', { field_type: element.meta.field_type }) }}
      </div>
      <multiselect
        ref="customFieldSelector"
        v-model="element.meta.custom_field"
        :tag-placeholder="$t('flowbot.step_user_input_add_this_as_new_custom_field')"
        :searchable="true"
        :options="customFieldsPlusCreateOption"
        :close-on-select="true"
        track-by="title"
        label="title"
        :placeholder="$t('flowbot.step_user_input_select_or_type_to_create_new_custom_field')"
        @select="customFieldChange"
        @search-change="searchChange"
      ></multiselect>
    </div>
    <div
      v-if="
        element.meta.field_type && element.meta.custom_field && typeof element.meta.custom_field.value !== 'undefined'
      "
      class="mt-2"
    >
      <div class="mb-2 mt-2 font-bold">{{ $t('flowbot.step_user_input_input_will_be_saved_in') }}</div>
      <div v-html="formatText('{ ' + element.meta.custom_field.value + ' }')"></div>
    </div>
    <div v-if="element.meta.field_type === 'contact'" class="mt-2">
      <div class="mb-2 mt-2 font-bold">{{ $t('flowbot.step_user_input_confirm_previous_input') }}</div>
      <label class="ui-switch success m-t-sm m-r">
        <input v-model="element.meta.confirm_last_input" type="checkbox" :checked="1" />
        <i></i>
      </label>
      <template v-if="element.meta.confirm_last_input">
        <TextEditor
          :element="element"
          :title="$t('flowbot.step_user_input_confirm_message_for_previous_input')"
          attribute="confirm_question"
          :custom-fields="customFields"
          :placeholder="
            $t('flowbot.step_user_input_is_custom_field_value_still_your_answer', {
              custom_field_value: element.meta.custom_field
                ? element.meta.custom_field.value
                : $t('flowbot.step_user_input_pick_a_field'),
            })
          "
        ></TextEditor>
        <TextEditor
          :element="element"
          :title="$t('flowbot.step_user_input_confirm_accept')"
          attribute="confirm_title"
          :custom-fields="customFields"
          :placeholder="$t('flowbot.step_user_input_confirm_yes')"
        ></TextEditor>
        <TextEditor
          :element="element"
          :title="$t('flowbot.step_user_input_confirm_decline')"
          attribute="refute_title"
          :custom-fields="customFields"
          :placeholder="$t('flowbot.step_user_input_confirm_no')"
        ></TextEditor>
      </template>
    </div>

    <div class="mt-2">
      <div class="mb-2 mt-2 font-bold">{{ $t('flowbot.step_user_input_reply_type') }}</div>
      <select v-model="element.meta.input_type" class="form-control">
        <option value="text">{{ $t('flowbot.step_user_input_reply_type_text') }}</option>
        <option value="name">{{ $t('flowbot.step_user_input_reply_type_name') }}</option>
        <option value="number">{{ $t('flowbot.step_user_input_reply_type_number') }}</option>
        <option value="phone">{{ $t('flowbot.step_user_input_reply_type_phone') }}</option>
        <option value="email">{{ $t('flowbot.step_user_input_reply_type_email') }}</option>
        <option value="url">{{ $t('flowbot.step_user_input_reply_type_url') }}</option>
        <option value="list">{{ $t('flowbot.step_user_input_reply_type_list') }}</option>
      </select>
      <span v-if="element.meta.input_type === 'name'" class="form-text ml-1">
        {{ $t('flowbot.step_user_input_reply_type_info_text') }}
      </span>
    </div>
    <div v-if="element.meta.input_type === 'list'" class="mt-2">
      <div class="mb-2 mt-2 font-bold">{{ $t('flowbot.step_user_input_answers_list_answers') }}</div>
      <multiselect
        v-model="element.meta.list"
        :options="element.meta.list"
        :multiple="true"
        :taggable="true"
        :close-on-select="false"
        :tag-placeholder="$t('flowbot.step_user_input_answers_list_add_option')"
        :placeholder="$t('flowbot.step_user_input_answers_list_add_an_option')"
        label="title"
        track-by="title"
        @tag="addOption"
      ></multiselect>
    </div>
    <div v-if="requiresFallback" class="mt-2">
      <TextEditor
        :element="element"
        :title="$t('flowbot.step_user_input_fallback_message_for_invalid_replies')"
        attribute="fallback_message"
        :custom-fields="customFields"
        :placeholder="$t('flowbot.step_user_input_please_try_again')"
      ></TextEditor>
    </div>
  </div>
</template>

<script>
import { toArray } from 'lodash';
import Multiselect from 'vue-multiselect';

import TextEditor from './Text';
import textFormatter from '../mixins/TextFormatter';

export default {
  props: ['element', 'customFields'],

  mixins: [textFormatter],

  components: {
    TextEditor,
    Multiselect,
  },

  data() {
    return {
      query: null,
    };
  },

  watch: {
    'element.meta.field_type'(oldVal, newVal) {
      this.element.meta.custom_field = null;
    },
  },

  computed: {
    requiresFallback() {
      // Changes here should be made in presenter as well
      return ['number', 'url', 'email', 'phone', 'list', 'name'].includes(this.element.meta.input_type);
    },

    customFieldsPlusCreateOption() {
      let options = this.customFields.filter((cf) => {
        return cf.type === this.element.meta.field_type.toUpperCase();
      });
      if (
        this.query &&
        options.filter((optie) => {
          return optie.title.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
        }).length === 0
      ) {
        options.push({
          title:
            '+ ' +
            this.query +
            ' ' +
            this.$t('flowbot.step_user_input_create_as_new_custom_field_type_field', {
              field_type: this.element.meta.field_type,
            }),
          titleToCreate: this.query,
          value: 'create_new_custom_field',
        });
      }
      return options;
    },
  },

  methods: {
    searchChange(query) {
      this.query = query;
    },

    addOption(tag) {
      if (this.element.meta.list.length >= 13) {
        alert(this.$t('flowbot.step_user_input_error_there_can_only_be_up_to_13_options'));
        return;
      }
      this.element.meta.list.push({
        title: tag,
      });
    },

    customFieldChange(customFieldToCreate) {
      let titleToCreate = customFieldToCreate.titleToCreate;
      let typeToCreate = this.element.meta.field_type.toUpperCase();

      if (
        customFieldToCreate.value === 'create_new_custom_field' &&
        ['TICKET', 'CONTACT'].includes(typeToCreate) &&
        titleToCreate
      ) {
        axios
          .post('/api/v2/custom_fields', {
            title: titleToCreate,
            type: typeToCreate,
          })
          .then((res) => {
            this.element.meta.custom_field = {
              id: res.data.id,
              title: 'Custom ' + typeToCreate.toLowerCase() + ' field: ' + titleToCreate,
              type: typeToCreate,
              sort_order: null,
            };
            axios.get('/api/v2/custom_fields').then((result) => {
              this.$root.customTicketFields = toArray(result.data.data).filter((field) => {
                return field.type === 'TICKET';
              });
              this.$root.customProfileFields = toArray(result.data.data).filter((field) => {
                return field.type === 'PROFILE';
              });
              this.$root.customContactFields = toArray(result.data.data).filter((field) => {
                return field.type === 'CONTACT';
              });

              setTimeout(
                function () {
                  let customField = this.customFields.find((cf) => {
                    return cf.value.toUpperCase().indexOf(typeToCreate + ' ' + titleToCreate.toUpperCase()) > -1;
                  });
                  if (customField) {
                    this.element.meta.custom_field = customField;
                  }
                }.bind(this),
                100
              );
            });
          })
          .catch((error) => {
            this.flashError(this.$t('flowbot.step_user_input_error_something_went_wrong_creating_custom_field'));
          });
      }
    },
  },
};
</script>
