<template>
  <li
    class="flex cursor-pointer list-none flex-col justify-center border-b border-grey-300 text-center last:border-none"
    :data-test="dataTestListRowItem"
  >
    <div
      ref="row_item"
      class="
        m-1
        box-border
        flex
        items-center
        justify-between
        rounded-lg
        p-4
        ring-inset ring-grey-300
        transition-all
        duration-200
        ease-linear
        hover:bg-grey-200
        active:bg-grey-300
      "
      :class="{
        'bg-grey-200 ring-2 ring-grey-300': isFocused,
      }"
      :data-test="dataTestListRowItemChild"
      tabindex="0"
      v-bind="$attrs"
      @focus="isFocused = true"
      @blur="isFocused = false"
    >
      <slot></slot>
    </div>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ListRowItem',
  data() {
    return {
      isFocused: false,
    };
  },
  props: {
    dataTestListRowItem: {
      type: String,
      default: 'list-row-item',
    },
    dataTestListRowItemChild: {
      type: String,
      default: 'list-row-item-child',
    },
  },
  mounted() {
    const rowItem = this.$refs.row_item;
    rowItem?.addEventListener('click', this.removeFocused);
  },
  unmounted() {
    const rowItem = this.$refs.row_item;
    rowItem?.removeEventListener('click', this.removeFocused);
  },
  methods: {
    removeFocused() {
      this.isFocused = false;
    },
  },
});
</script>
