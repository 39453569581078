<template>
  <i v-if="spinnerView === 'default'" class="loading-default"></i>

  <span v-else-if="spinnerView === 'bubbles'" class="loading-bubbles">
    <span v-for="x in 8" :key="x" class="bubble-item"></span>
  </span>

  <span v-else-if="spinnerView === 'circles'" class="loading-circles">
    <span v-for="x in 8" :key="x" class="circle-item"></span>
  </span>

  <i v-else-if="spinnerView === 'spiral'" class="loading-spiral"></i>

  <span v-else-if="spinnerView === 'wavedots'" class="loading-wave-dots">
    <span v-for="x in 5" :key="x" class="wave-item"></span>
  </span>
</template>

<script>
import { defineComponent } from 'vue';

const SPINNERS = ['bubbles', 'circles', 'spiral', 'wavedots'];

export default /* #__PURE__ */ defineComponent({
  name: 'Spinner',
  props: ['spinner'],
  computed: {
    spinnerView() {
      return !SPINNERS.includes(this.spinner) ? 'default' : this.spinner;
    },
  },
});
</script>
