<script type="text/babel">
export default {
  props: ['record', 'action'],

  data() {
    return {
      category: {},
      saving: false,
      deleting: false,
    };
  },

  mounted() {
    if (this.action == 'update') {
      axios.get('/api/v2/chatbot/' + this.record.id + '/categories/' + this.$route.params.category_id).then((res) => {
        this.category = res.data;
      });
    }
  },

  methods: {
    save() {
      this.saving = true;
      let url =
        this.action == 'create'
          ? '/api/v2/chatbot/' + this.record.id + '/categories'
          : '/api/v2/chatbot/' + this.record.id + '/categories/' + this.category.id;

      axios({
        url: url,
        data: this.category,
        method: this.action == 'create' ? 'POST' : 'PUT',
      })
        .then((res) => {
          this.category = res.data;
          this.saving = false;
          router.push('/chatbots/' + this.record.id + '/categories');
        })
        .catch(() => {
          this.saving = false;
        });
    },

    cancel() {
      router.push('/chatbots/' + this.record.id + '/categories');
    },

    remove() {
      if (!confirm('Are you sure you want to delete this category and all attached questions?')) {
        return;
      }

      this.deleting = true;

      axios.delete('/api/v2/chatbot/' + this.record.id + '/categories/' + this.category.id).then((res) => {
        router.push('/chatbots/' + this.record.id + '/categories');
        this.deleting = false;
      });
    },
  },
};
</script>

<template>
  <div class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis">{{ $t('chatbot.category') }}</span>
          <div class="m-l-auto">
            <a class="text-info btn-md" @click="cancel">{{ $t('chatbot.cancel') }}</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body container">
        <div class="row-inner">
          <div class="p-a">
            <form action="" @submit.prevent="save">
              <div class="box">
                <div class="box-header">
                  <h2>{{ $t('chatbot.category') }}</h2>
                </div>
                <div class="box-divider m-a-0"></div>
                <div class="box-body">
                  <div class="form-group row">
                    <label class="col-sm-2 form-control-label">{{ $t('chatbot.name') }}</label>
                    <div class="col-sm-8">
                      <input v-model="category.name" type="text" class="form-control" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="box">
                <div class="box-body">
                  <button
                    type="submit"
                    class="btn success text-white"
                    :class="{ loader: saving }"
                    :disabled="category.name == null"
                  >
                    {{ $t('general.save') }}
                  </button>
                  <a class="btn white m-x-xs" @click="cancel()">
                    {{ $t('chatbot.cancel') }}
                  </a>
                  <a
                    v-show="action == 'update' && category.type == 'DEFAULT' && category.questions_count == 0"
                    class="btn btn-link text-danger pull-right"
                    @click="remove()"
                  >
                    {{ $t('chatbot.delete') }}
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
