<template>
  <settings-base-layout>
    <template v-if="showList" #header>
      <main-header :title="headingTitle" class="hidden-md-down">
        <template #left>
          <t-thumbnail class="bg-white">
            <slot name="title-icon" />
          </t-thumbnail>
        </template>

        <template #right>
          <t-button
            v-if="showHelpButton"
            class="open-external"
            btn-style="secondary"
            icon-only
            :href="helpLink"
            rel="noreferrer noopener"
            target="_blank"
          >
            <template #icon>
              <question-linear size="1.042rem" />
            </template>
          </t-button>
          <t-button class="ml-3" @click="handleAddButtonPressed">
            {{ addButtonText }}
          </t-button>
        </template>
      </main-header>
      <div v-if="$slots.banner" class="w-full px-6" data-test="settings-banner">
        <slot name="banner" />
      </div>
      <admin-section-title :title="sectionTitle" />
    </template>
    <template #default>
      <div class="h-full px-6">
        <list-row-parent v-if="showList">
          <slot name="list" />
        </list-row-parent>
        <slot name="content" />
      </div>
    </template>
  </settings-base-layout>
</template>

<script lang="ts">
import { QuestionLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import ListRowParent from '@/components/common/ListRow/ListRowParent';
import MainHeader from '@/components/common/PageHeaders/MainHeader';
import SettingsBaseLayout from '@/components/common/SettingsBaseLayout';

export default defineComponent({
  name: 'SettingsPage',
  emits: ['add-button'],
  components: {
    SettingsBaseLayout,
    QuestionLinear,
    MainHeader,
    ListRowParent,
  },
  props: {
    addButtonText: {
      type: String,
      default: '',
    },
    sectionTitle: {
      type: String,
      default: '',
    },
    headingTitle: {
      type: String,
      default: '',
    },
    helpLink: {
      type: String,
      default: '',
    },
    showList: {
      type: Boolean,
      default: false,
    },
    showHelpButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleAddButtonPressed() {
      this.$emit('add-button');
    },
  },
});
</script>
