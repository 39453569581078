export const USER_ROLE = {
  ADMINISTRATOR: 'administrator',
  ADVANCED_AGENT: 'advanced_agent',
  BASIC_AGENT: 'basic_agent',
  OBSERVER: 'observer',
  SUPERVISOR: 'supervisor',
  TRENGO: 'trengo',
} as const;

export const ADMIN_ROLES: valueof<typeof USER_ROLE>[] = [USER_ROLE.TRENGO, USER_ROLE.ADMINISTRATOR];
