<script>
export default {
  props: ['attachments'],

  data: function () {
    return {};
  },

  watch: {},

  mounted() {},

  methods: {
    openInPopup(url, e) {
      if (url.match(/.(jpg|jpeg|png|gif|pdf)$/i) && !this.$root.loadedFromApp && !this.$root.isDesktopApp) {
        e.preventDefault();
        window.PopupCenter(this.safeUrl(url), 'Attachment', 1020, 700);
      }
    },
  },
};
</script>
<template>
  <div class="attachments p-b-xs flex flex-col">
    <div v-for="(attachment, index) in attachments" class="m-b-xs mx-3 flex flex-nowrap">
      <div class="text-ellipsis">
        <div
          class="text-success b-success inline border px-1 text-center uppercase"
          style="border-radius: 0.25rem; min-width: 40px; font-size: 11px"
        >
          {{ attachment.extension }}
        </div>
        <a
          :href="attachment.full_url"
          @click="openInPopup(attachment.full_url, $event)"
          target="_blank"
          class="open-external"
          style="color: inherit; text-decoration: none"
        >
          {{ attachment.client_name }}
        </a>
        <small class="text-muted m-r-xs whitespace-nowrap" v-if="typeof attachment.size === 'string'">
          {{ attachment.size }}
        </small>
      </div>
    </div>
  </div>
</template>
<style scoped>
.attachments {
  padding-top: calc(0.25rem + 37px);
  border: 2px solid #e9e9e9;
  border-radius: 10px;
  transition: all 250ms ease-out;
  margin-top: -37px;
}
</style>
