<template>
  <div class="broadcasting-list">
    <div class="wrapper">
      <broadcasting-list-header :balance="balance" :loading="BalanceLoading" />
      <broadcasting-list-main :list="list" :loading="loading" @infinite-scroll="onInfinite" />
    </div>
  </div>
</template>

<script>
import { getBalance } from '@/components/WabBroadcasting/api';
import BroadcastingListHeader from '@/components/WabBroadcasting/components/BroadcastingListHeader';
import BroadcastingListMain from '@/components/WabBroadcasting/components/BroadcastingListMain';

export default {
  name: 'BroadcastingList',
  emits: ['infinite-scroll'],
  components: { BroadcastingListMain, BroadcastingListHeader },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      balance: '0',
      BalanceLoading: true,
    };
  },
  created() {
    this.requestBalance();
  },
  methods: {
    requestBalance() {
      getBalance()
        .then(({ data }) => {
          this.balance = data.balance;
        })
        .finally(() => {
          this.BalanceLoading = false;
        });
    },
    onInfinite(scroll) {
      this.$emit('infinite-scroll', scroll);
    },
  },
};
</script>

<style scoped lang="scss" src="./BroadcastingList.scss" />
