<template>
  <settings-base-layout v-if="isFeatureFlagEnabled" :class="fullHeightInEdit">
    <template #header>
      <main-header :title="$t('rules.list_heading_title_rules')" class="hidden-md-down">
        <template #left>
          <t-thumbnail class="bg-white">
            <lightning-linear size="1rem" />
          </t-thumbnail>
        </template>

        <template #right>
          <t-button
            class="open-external"
            btn-style="secondary"
            icon-only
            href="https://help.trengo.com/en/articles/63757-the-basic-configuration-of-rules"
            rel="noreferrer noopener"
            target="_blank"
          >
            <template #icon>
              <question-linear size="1.042rem" />
            </template>
          </t-button>
          <t-button class="ml-3" @click="handleAddRule">
            {{ $t('admin_inboxes.add_rule') }}
          </t-button>
        </template>
      </main-header>

      <admin-section-title title="Rules" />
    </template>

    <template #default>
      <div class="h-full px-6">
        <list-row-parent v-if="showItems">
          <list-row-item
            v-for="item in rules"
            :key="item.id"
            :class="{ hide: item.deleted }"
            @click="handleItemClick(item.id)"
          >
            <list-row-item-child
              :id="item.id"
              :title="item.name"
              :is-active="item.active"
              data-test-list-row-item-child="rules-list-row-item-child"
              @handle-delete-item="handleDeleteRule(item.id)"
            />
          </list-row-item>

          <infinite-loading ref="infiniteScroller" :identifier="infiniteLoadingResetCounter" @infinite="fetchMoreData">
            <template #spinner>
              <list-row-item-loader
                data-test-list-row-item="rules-list-row-item"
                data-test-list-row-item-loader="list-row-item-loader"
              />
              <list-row-item-loader
                data-test-list-row-item="rules-list-row-item"
                data-test-list-row-item-loader="list-row-item-loader"
              />
            </template>
            <template #no-more>
              <div></div>
            </template>
            <template #no-results>
              <div></div>
            </template>
          </infinite-loading>
        </list-row-parent>
        <div v-if="showContent" class="row-col">
          <router-view v-if="loaded" :key="$route.fullPath"></router-view>
        </div>
      </div>

      <t-modal
        v-model="shouldShowUpgradeBanner"
        :title="$tc('admin_inboxes.rules')"
        :close-on-backdrop-click="true"
        @close="shouldShowUpgradeBanner = false"
      >
        <rules-upgrade-banner :plan="minimumRequiredPlan" />
      </t-modal>
    </template>
  </settings-base-layout>

  <div v-else class="row-body">
    <admin-section-title title="Rules"></admin-section-title>
    <div class="row-col">
      <div class="row-col">
        <settings-item-list
          :title="$t('rules.list_heading_title_rules')"
          :show-active-badge="true"
          route-name="ruleEdit"
          :items="rules"
        />
        <div class="row-col">
          <router-view v-if="loaded" :key="$route.fullPath" @call-parent-loadList-method="loadList"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { LightningLinear, QuestionLinear } from '@trengo/trengo-icons';
import { mapStores, mapState } from 'pinia';
import { defineComponent } from 'vue';
import InfiniteLoading from 'vue-infinite-loading';

import { deleteRule } from '@/api';
import ListRowItem from '@/components/common/ListRow/ListRowItem';
import ListRowParent from '@/components/common/ListRow/ListRowParent';
import MainHeader from '@/components/common/PageHeaders/MainHeader';
import SettingsBaseLayout from '@/components/common/SettingsBaseLayout';
import SettingsItemList from '@/components/common/SettingsItemList';
import RulesUpgradeBanner from '@/components/Rules/components/RulesUpgradeBanner.vue';
import { FEATURE, FEATURE_FLAG_SETTINGS } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useUserStore, useRoutesStore, useEntitlementsStore, useFeatureFlagStore } from '@/store/pinia';

import { fetchRules } from './utils';
import ListRowItemLoader from '../common/ListRow/ListRowItemLoader';
import ListRowItemChild from '../common/ListRowItemChild';

import type { Rule } from '@/types';
import type { StateChanger } from 'vue-infinite-loading';
const RULES_LIST_PAGE_SIZE = 25;

export default defineComponent({
  name: 'Rules',
  components: {
    InfiniteLoading,
    LightningLinear,
    ListRowItem,
    ListRowParent,
    MainHeader,
    QuestionLinear,
    ListRowItemChild,
    ListRowItemLoader,
    SettingsBaseLayout,
    SettingsItemList,
    RulesUpgradeBanner,
  },

  data() {
    return {
      loaded: false,
      rules: [] as Rule[],
      infiniteLoadingResetCounter: 0,
      page: 1,
      shouldShowUpgradeBanner: false,
    };
  },

  async beforeMount() {
    try {
      await this.loadList().then(() => {
        this.shouldShowUpgradeBanner = !this.entitlementsStore?.isEntitledTo(
          FEATURE.CUSTOMER_SERVICE__AUTOMATION__RULES,
          this.rules.length
        );

        /**
         * TODO: This is a workaround to the fact that we can't know how many Rules people have. In the Edit.vue
         * component, we let people clone rules, and in order to block them if they're entitled to, we need to somehow
         * fetch the total count of rules they have. This will require some backend changes, so this is a temporary fix
         */
        localStorage.setItem('ruleCount', this.rules.length);
      });
    } catch (err) {
      console.error(err);
    }
  },

  mounted() {
    eventBus.$on('loadList', this.loadList);
  },

  unmounted() {
    eventBus.$off('loadList', this.loadList);
  },

  computed: {
    ...mapStores(useEntitlementsStore),
    ...mapState(useUserStore, ['user']),
    ...mapState(useRoutesStore, ['currentRoutePath']),
    minimumRequiredPlan() {
      return this.entitlementsStore?.getMinimumRequiredPlanFor(
        FEATURE.CUSTOMER_SERVICE__AUTOMATION__RULES,
        this.rules.length
      );
    },

    fullHeightInEdit() {
      const rulesAreMorThanFive = this.rules.length > 5;
      return (this.isCreateRoute() || this.$route.params.id || rulesAreMorThanFive) && 'h-full';
    },

    isEntitledToAddRule() {
      return this.entitlementsStore?.isEntitledTo(FEATURE.CUSTOMER_SERVICE__AUTOMATION__RULES, this.rules.length + 1);
    },

    showItems() {
      const hasRules = this.rules?.length > 0;
      return this.loaded && hasRules && !this.isCreateRoute() && !this.$route.params.id;
    },

    isFeatureFlagEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_SETTINGS.TA_SETTINGS_PAGE);
    },
    showContent() {
      const isInEditPage = Boolean(this.$route.params.id) || this.isCreateRoute();
      return (this.loaded && isInEditPage) || (this.loaded && this.rules.length === 0);
    },
  },

  methods: {
    handleItemClick(id: number) {
      this.$router.push(`/admin/rules/${id}`);
      this.page = 1; //
    },

    handleAddRule(): void {
      if (this.isEntitledToAddRule) {
        this.$router.push('/admin/rules/create');
      } else {
        const requiredPlan = this.entitlementsStore?.getMinimumRequiredPlanFor(
          FEATURE.CUSTOMER_SERVICE__AUTOMATION__RULES,
          this.rules.length + 1
        );
        this.flashError(this.$t('user_management.must_upgrade_to_add_rules', { plan: requiredPlan }));

        this.$router.push('/admin/subscription_settings');
      }
    },

    isCreateRoute(): boolean {
      return this.$route.path.includes('/admin/rules/create');
    },

    async loadList(): Promise<void> {
      try {
        this.rules = await fetchRules(this.page, this.isFeatureFlagEnabled);
        this.loaded = true;

        this.infiniteLoadingResetCounter++;
      } catch (error) {
        console.error(error);
        this.loaded = true;
      }
    },

    async handleDeleteRule(id: number) {
      try {
        const shouldDeleteRule = await this.$tConfirm(
          this.$t('rules.delete_this_rule_will_be_permanently_deleted_this_cannot_be_undone'),
          {
            delete: true,
            title: this.$t('rules.delete_delete_this_rule'),
          }
        );

        if (!shouldDeleteRule) {
          return;
        }
        await deleteRule(id);
        this.flashSuccess(this.$t('rules.delete_the_rule_is_deleted'));
        const rule = this.rules.find((rule: Rule) => rule.id === id);
        if (rule) {
          this.rules.splice(this.rules.indexOf(rule), 1, { ...rule, deleted: true }); // set the 'deleted' field to true
        }
        if (this.$refs.infiniteScroller) {
          const { complete, loaded, reset, error } = this.$refs.infiniteScroller.stateChanger;
          this.fetchMoreData({ complete, loaded, reset, error });
        }
      } catch (e) {
        console.error('Error when deleting rule', e);
      }
    },

    async fetchMoreData(scroll: StateChanger) {
      if (this.rules?.length < RULES_LIST_PAGE_SIZE) {
        scroll.complete();
        scroll.loaded();
        return;
      }
      if (this.page === 1) {
        this.page++;
      }
      try {
        const data = await fetchRules(this.page, this.isFeatureFlagEnabled);
        if (data?.length > 0) {
          this.rules = [...this.rules, ...data];
          this.page++;
          scroll.loaded();
        } else {
          scroll.complete();
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
</script>
