import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  ref: "modal",
  "data-test": "connect-whatsapp",
  class: "overflow-auto"
}
const _hoisted_2 = {
  key: 0,
  "data-test": "qr_code"
}
const _hoisted_3 = { class: "t-text-md mb-0 text-grey-700" }
const _hoisted_4 = { class: "relative my-6 flex h-48 w-full items-center justify-center" }
const _hoisted_5 = {
  key: 0,
  class: "relative flex h-48 w-full items-center justify-center"
}
const _hoisted_6 = {
  ref: "qr",
  class: "border-gray-400 rounded-lg border p-2"
}
const _hoisted_7 = {
  key: 1,
  "data-test": "open_whatsapp_button"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_logo = _resolveComponent("t-logo")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_divider = _resolveComponent("t-divider")!
  const _component_t_phone_input = _resolveComponent("t-phone-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('whatsapp.scan_qr_code')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.qrCodeUrl)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_t_logo, {
                    class: "absolute h-16 w-16 bg-white px-2 py-3",
                    variant: 'glyph'
                  }),
                  _createElementVNode("canvas", _hoisted_6, null, 512 /* NEED_PATCH */)
                ]))
              : _createCommentVNode("v-if", true)
          ])
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("p", {
            class: "t-text-md mb-0 text-grey-700",
            innerHTML: _ctx.$t('whatsapp.click_whatsapp_button')
          }, null, 8 /* PROPS */, _hoisted_8),
          _createVNode(_component_t_button, {
            href: _ctx.qrCodeUrl,
            "btn-style": "secondary",
            class: "open-external my-4 flex w-full items-center justify-center"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: _ctx.getWhatsAppLogoUrl(),
                class: "mr-1",
                alt: "trengo logo"
              }, null, 8 /* PROPS */, _hoisted_9),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('whatsapp.open_whatsapp_and_connect')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["href"])
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_t_divider, {
      text: _ctx.$t('whatsapp.or_connect_phone')
    }, null, 8 /* PROPS */, ["text"]),
    _createVNode(_component_t_phone_input, {
      modelValue: _ctx.phone,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phone) = $event)),
      "data-test": "mobile-number",
      placeholder: _ctx.$t('general.custom_input_list_item_search_country'),
      "error-message": _ctx.$t('onboarding.channels_step1_mobile_number_error'),
      "default-country-code": "+31",
      error: !_ctx.isPhoneNumberValid,
      class: "mb-4 mt-6",
      onIsValid: _ctx.isValid,
      onChange: _ctx.onPhoneChange
    }, null, 8 /* PROPS */, ["modelValue", "placeholder", "error-message", "error", "onIsValid", "onChange"])
  ], 512 /* NEED_PATCH */))
}