import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  "data-test": "online-status-users",
  class: "relative mt-6 grid w-full grid-cols-1 flex-row gap-x-6 xl:grid-cols-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_online_status_user_item = _resolveComponent("online-status-user-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getUsers, (user, index) => {
      return (_openBlock(), _createBlock(_component_online_status_user_item, {
        key: user.id,
        user: user,
        "has-border": _ctx.showBorderBottom(index)
      }, null, 8 /* PROPS */, ["user", "has-border"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}