import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-d7bc5514"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "ForwardTicketModal",
  ref: "modal",
  class: "modal",
  role: "dialog"
}
const _hoisted_2 = {
  class: "modal-centered modal-dialog",
  style: {"height":"90vh","overflow-y":"auto","transform":"translate(-50%, -50%)","margin":"0"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_composer = _resolveComponent("composer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.open && !_ctx.channelError)
        ? (_openBlock(), _createBlock(_component_composer, {
            key: 0,
            "default-close": true,
            autofocus: true,
            "draft-key": _ctx.draftKey,
            ticket: _ctx.forwardingTicketContext,
            "forward-ticket-id": _ctx.ticket.id,
            "forward-message-id": _ctx.message_id,
            "new-ticket": true,
            forwarding: true,
            path: '/tickets/forward/' + this.ticket.id,
            onSent: _ctx.onSent,
            onPopout: _ctx.closeModal
          }, null, 8 /* PROPS */, ["draft-key", "ticket", "forward-ticket-id", "forward-message-id", "path", "onSent", "onPopout"]))
        : _createCommentVNode("v-if", true)
    ])
  ], 512 /* NEED_PATCH */))
}