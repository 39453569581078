import { CHANNEL_TYPE } from '@/Configs/Constants';
import { checkIfAfterDeadline } from '@/util/mandatoryTwoFactorAuth';

export default {
  namespaced: true,
  state: {
    ticketFolders: [],
    user: {},
    channels: null,
    users: null,
    teams: null,
    contactGroups: null,
    labels: null,
    customTicketFields: null,
    customProfileFields: null,
    customUserFields: null,
    customContactFields: null,
    ticketResults: null,
    accessToken: null,
    localeCodesList: null,
    onboarding: null,
    translations: null,
    navigation: null,
    countries: null,
    plugins: null,
    doNotDisturb: null,
    customViews: null,
    legacyPlan: null,
    hasHelpcenter: null,
    companyProfile: {},
    agency: {},
  },
  mutations: {
    set(state, { key, value }) {
      state[key] = value;
    },
    setUserHas2FAEnabled(state, value) {
      state.user.two_factor_authentication_enabled = value;
    },
    setFoldersState(state, value) {
      state.ticketFolders = value;
    },
    setIsMFAMandatoryState(state, value) {
      state.agency.mfa_is_mandatory = value;
    },
    setPricingModelState(state, value) {
      state.companyProfile.pricing_model = value;
    },
  },
  actions: {
    init({ commit, dispatch }, data) {
      let mapped = {
        user: data.user,
        channels: data.channels.filter((c) => c.type !== 'HELP_CENTER'),
        teams: data.teams,
        contactGroups: data.contact_groups,
        labels: data.labels,
        customTicketFields: data.ticket_custom_fields,
        customProfileFields: data.profile_custom_fields,
        customUserFields: data.user_custom_fields,
        customContactFields: data.contact_custom_fields,
        ticketResults: data.ticket_results,
        accessToken: data.access_token,
        localeCodesList: data.locale_codes,
        onboarding: data.onboarding,
        translations: data.translations,
        navigation: data.navigation,
        countries: data.countries,
        plugins: data.plugins,
        doNotDisturb: data.doNotDisturb,
        customViews: data.custom_views,
        legacyPlan:
          data.company_profile.subscription.trial_ends_at === null &&
          data.company_profile.subscription.plan_id === null &&
          !data.company_profile.subscription.graced,
        hasHelpcenter: data.channels.filter((c) => c.type === 'HELP_CENTER').length > 0,
        companyProfile: data.company_profile,
        agency: data.agency,
      };

      dispatch('customFields/loadCustomFields', {}, { root: true });

      Object.keys(mapped).forEach((k) => {
        commit('set', { key: k, value: mapped[k] });
      });
    },
    setFolders({ commit }, folders) {
      commit('setFoldersState', folders);
    },
    setIsMFAMandatory({ commit }, isMFAMandatory) {
      commit('setIsMFAMandatoryState', isMFAMandatory);
    },
    setPricingModel({ commit }, pricingModel) {
      commit('setPricingModelState', pricingModel);
    },
  },
  getters: {
    getTicketFolders(state) {
      return state.ticketFolders;
    },
    agency: (state) => state.agency,
    user: (state) => state.user,
    users: (state) => state.users,
    userHas2FAEnabled: (state) => {
      return state.user?.two_factor_authentication_enabled;
    },
    isBetaEnrolled: (state) => {
      return state.agency.beta_enrollment;
    },
    isMFAMandatory: (state) => {
      return state.agency.mfa_is_mandatory;
    },
    mFAMandatoryFrom: (state) => {
      return state.agency.mfa_is_mandatory_from;
    },
    shouldRedirectToMFASetup: (_state, getters) => {
      if (!getters.mFAMandatoryFrom || getters.user?.needs_new_pw) {
        return false;
      }
      const { isAfterHardDeadline } = checkIfAfterDeadline(getters.mFAMandatoryFrom, Date.now());
      return isAfterHardDeadline && getters.isMFAMandatory && !getters.userHas2FAEnabled;
    },
    getWaChannels: (state) => {
      return state.channels?.filter((channel) => channel.type === CHANNEL_TYPE.WA_BUSINESS);
    },
  },
};
