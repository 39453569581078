import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "t-text-h3 my-auto max-w-[90%] p-0" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "m-0 p-0" }
const _hoisted_4 = { class: "m-0 p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_linear = _resolveComponent("info-linear")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_spinner = _resolveComponent("t-spinner")!
  const _component_t_modal = _resolveComponent("t-modal")!

  return (_openBlock(), _createBlock(_component_t_modal, {
    "close-on-backdrop-click": false,
    variant: "narrow",
    "model-value": true,
    "is-closable": "",
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('integration_hub.uninstall_integration')), 1 /* TEXT */)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_t_button, {
        "btn-style": "secondary",
        disabled: _ctx.inProgress,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('general.cancel')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled"]),
      _createVNode(_component_t_button, {
        class: "flex flex-row items-center justify-center",
        disabled: _ctx.inProgress,
        onClick: _ctx.save
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('integration_hub.uninstall')), 1 /* TEXT */),
          (_ctx.inProgress)
            ? (_openBlock(), _createBlock(_component_t_spinner, {
                key: 0,
                size: "1rem",
                class: "ml-2"
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('integration_hub.uninstall_integration_description', { name: _ctx.integration.name })), 1 /* TEXT */),
        _createVNode(_component_t_inline_banner, { class: "mb-4" }, {
          icon: _withCtx(() => [
            _createVNode(_component_info_linear)
          ]),
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('integration_hub.uninstall_integration_information', { name: _ctx.integration.name })), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }))
}