import { PERMISSION } from '@/Configs/Constants';
import { ROUTE_NAME } from '@/routes/constants';

import type { RouteRecord } from '@/routes/types';

const pluginsRoutes: RouteRecord[] = [
  {
    path: '/admin/integrations/plugins',
    name: ROUTE_NAME.INTEGRATIONS_LEGACY_PLUGINS,
    component: () =>
      import(
        /* webpackChunkName; "IntegrationPlugins" */ '@/components/IntegrationHub/Pages/CustomIntegrations/Index.vue'
      ),
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
  },
  {
    path: '/admin/integrations/plugins/create',
    name: ROUTE_NAME.INTEGRATIONS_LEGACY_PLUGINS_CREATE,
    component: () =>
      import(/* webpackChunkName: "PluginsCreate" */ '@/components/IntegrationHub/Pages/CustomIntegrations/Edit.vue'),
    props: { action: 'create' },
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
  },
  {
    path: '/admin/integrations/plugins/create/:type',
    name: ROUTE_NAME.INTEGRATIONS_LEGACY_PLUGINS_CREATE_TYPE,
    component: () =>
      import(
        /* webpackChunkName: "PluginsCreateType" */ '@/components/IntegrationHub/Pages/CustomIntegrations/Edit.vue'
      ),
    props: { action: 'create' },
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
  },
  {
    path: '/admin/integrations/plugins/:id',
    name: ROUTE_NAME.INTEGRATIONS_LEGACY_PLUGINS_EDIT_ITEM,
    component: () =>
      import(/* webpackChunkName: "PluginsItem" */ '@/components/IntegrationHub/Pages/CustomIntegrations/Edit.vue'),
    props: { action: 'edit' },
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
  },
];

export default pluginsRoutes;
