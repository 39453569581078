<script type="text/babel">
import RouterWarningOnEdit from '../../mixins/RouterWarningOnEdit';

export default {
  data() {
    return {
      record: {},
      loaded: false,
      localeCodes: {},
    };
  },

  mounted() {
    this.loadHelpCenter();
  },

  mixins: [RouterWarningOnEdit],

  methods: {
    loadHelpCenter() {
      axios
        .get('/api/v2/help_center/' + this.$route.params.id)
        .then((res) => {
          this.record = res.data;
          this.initWarning();

          axios.get('/api/v2/locale_codes').then((languages) => {
            this.localeCodes = {};
            languages.data.forEach((l) => {
              Object.assign(this.localeCodes, {
                [l.id]: {
                  id: l.id,
                  text: l.name,
                },
              });
            });
          });
        })
        .catch(() => {
          router.push('/help_center');
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.onBeforeRouteLeaveGuard(to, from, next);
  },
  beforeRouteUpdate(to, from, next) {
    this.onBeforeRouteUpdateGuard(to, from, next);
  },
};
</script>

<template>
  <div class="row-col">
    <div id="settings-primary" class="col-xs modal fade aside aside-md b-r w-medium w-200px bg-white">
      <div class="nav-settings light left b-primary">
        <ul>
          <li class="text-md white header display-flex">
            <span class="text-truncate">{{ record.name }}</span>
            <a v-if="record.published" :href="record.url" target="_blank" class="m-l-xs text-sm">
              <i class="fa fa-external-link text-muted"></i>
            </a>
            <a data-dismiss="modal" class="hidden-md-up text-muted m-l-auto m-r-sm text-lg">×</a>
          </li>
          <li>
            <router-link
              data-dismiss="modal"
              class="nav-settings-link text-truncate"
              :to="'/help_center/' + record.id + '/categories'"
            >
              <i class="material-icons md-18">keyboard_arrow_right</i>
              {{ $t('help_center.categories') }}
            </router-link>
          </li>
          <li>
            <router-link
              data-dismiss="modal"
              class="nav-settings-link text-truncate"
              :to="'/help_center/' + record.id + '/articles'"
            >
              <i class="material-icons md-18">keyboard_arrow_right</i>
              {{ $t('help_center.articles') }}
            </router-link>
          </li>
          <li>
            <router-link
              data-dismiss="modal"
              class="nav-settings-link text-truncate"
              :to="'/help_center/' + record.id + '/blocks'"
            >
              <i class="material-icons md-18">keyboard_arrow_right</i>
              {{ $t('help_center.blocks') }}
            </router-link>
          </li>
          <li>
            <router-link
              data-dismiss="modal"
              class="nav-settings-link text-truncate"
              :to="'/help_center/' + record.id + '/settings'"
            >
              <i class="material-icons md-18">keyboard_arrow_right</i>
              {{ $t('help_center.settings') }}
            </router-link>
          </li>
          <li>
            <router-link
              data-dismiss="modal"
              class="nav-settings-link text-truncate"
              :to="'/help_center/' + record.id + '/authentication'"
            >
              <i class="material-icons md-18">keyboard_arrow_right</i>
              {{ $t('help_center.authentication') }}
            </router-link>
          </li>
          <li>
            <router-link
              data-dismiss="modal"
              class="nav-settings-link text-truncate"
              :to="'/help_center/' + record.id + '/theme'"
            >
              <i class="material-icons md-18">keyboard_arrow_right</i>
              {{ $t('help_center.theme') }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="row-col">
      <div class="row-row">
        <div class="row-body">
          <div class="row-inner">
            <router-view
              v-if="loaded"
              :locale-codes="localeCodes"
              :help-center="record"
              :record="record"
              @call-load-help-center-method="loadHelpCenter"
            ></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style type="text/scss">
.kb-nav .nav-link {
  padding: 15px 20px;
}
</style>
