import { defineStore } from 'pinia';
import { ref } from 'vue';

import { FEATURE_FLAG_SUBSCRIPTION, PERMISSION } from '@/Configs/Constants';
import { useAgencyStore, useCompanyProfileStore, useFeatureFlagStore, useUserStore } from '@/store/pinia';

import { fetchChannels, fetchNotifications, putDismissNotification } from './api';
import {
  IS_DISMISSED_KEY,
  MIGRATION_CUSTOM_NOTIFICATION,
  USAGE_NOTIFICATION_TYPES,
  WHITELISTED_AGENCY_IDS,
} from './constants';
import { checkMigrationBannerVisibility } from './utils';

import type { ServiceNotification } from './types';
import type { ChannelType } from '@/types';

export const useServiceNotificationsStore = defineStore('serviceNotifications', () => {
  const status = ref<'pending' | 'initialized' | 'error'>('pending');
  const channels = ref<ChannelType[]>([]);
  const notifications = ref<ServiceNotification[]>([]);
  const agencyStore = useAgencyStore();
  const companyProfileStore = useCompanyProfileStore();
  const featureFlagStore = useFeatureFlagStore();
  const userStore = useUserStore();

  const init = async () => {
    if (status.value === 'initialized') return;
    channels.value = await fetchChannels();
    await Promise.all([agencyStore.init(), companyProfileStore.init(), userStore.init()]);
    await loadNotifications();
  };

  const loadNotifications = async () => {
    status.value = 'pending';
    try {
      const hasAgencyMigrationBanner = checkMigrationBannerVisibility({
        pricingModel: companyProfileStore.pricingModel,
        alreadyDismissed: Boolean(localStorage.getItem(IS_DISMISSED_KEY)),
        isAdmin: userStore.hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE),
        hasFeatureFlag: featureFlagStore.isEnabled(FEATURE_FLAG_SUBSCRIPTION.TW_USAGE_BASED_MIGRATION_BANNER),
      });
      const shouldHideUnpaidInvoices = WHITELISTED_AGENCY_IDS.includes(Number(agencyStore.agency?.id));

      // FIXME: Doing this on the frontend due to urgency and the lack of a proper backend solution, needs to be moved to the backend
      // Details: https://linear.app/trengo/issue/WALL-528/do-not-show-in-app-banner-for-conversation-and-balance-usage
      const filterOutUsageNotifications = (items: ServiceNotification[]) =>
        items.filter((item) => !USAGE_NOTIFICATION_TYPES.includes(item?.notification_type));

      let response = filterOutUsageNotifications(await fetchNotifications(channels.value));
      if (shouldHideUnpaidInvoices) {
        response = response.filter((item) => item?.notification_type !== 'invoice_not_paid');
      }
      notifications.value = hasAgencyMigrationBanner ? [MIGRATION_CUSTOM_NOTIFICATION, ...response] : response;
      status.value = 'initialized';
    } catch (err) {
      console.error(err);
      status.value = 'error';
    }
  };

  const dismissNotification = async (ServiceNotification: ServiceNotification) => {
    notifications.value = notifications.value.filter((item) => item.id !== ServiceNotification.id);
    if (ServiceNotification.id === MIGRATION_CUSTOM_NOTIFICATION.id) {
      localStorage.setItem(IS_DISMISSED_KEY, 'true');
    } else {
      putDismissNotification(ServiceNotification.id);
    }
  };

  return {
    channels,
    init,
    notifications,
    status,
    dismissNotification,
    reload: loadNotifications,
  };
});
