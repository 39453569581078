<template>
  <div class="flex h-full w-full flex-col items-center justify-center bg-white text-center">
    <img alt="missing permission" :src="`${$root.assetsURL}img/permissions/lock-icon.svg`" />
    <span class="t-text-h3 mt-6">You don't have access to this page</span>
    <t-button size="md" btn-style="secondary" class="mt-4 flex items-center" @click="goBackToInbox">
      <arrow-left-linear :size="18" class="mr-1" />
      Back to inbox
    </t-button>
  </div>
</template>

<script>
import { ArrowLeftLinear } from '@trengo/trengo-icons';
export default {
  name: 'Unauthorized',
  components: { ArrowLeftLinear },
  methods: {
    goBackToInbox() {
      this.$router.push('/tickets');
    },
  },
};
</script>
