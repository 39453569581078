<template>
  <div class="flex w-full flex-col" id="comment-composer" data-test="commentComposerWrapper">
    <div class="comment-composer__tabs-wrapper">
      <div v-for="tab in tabs" :key="tab.index" v-show="tab.enabled">
        <div
          @click="changeTab(tab.index, true)"
          class="comment-composer__tab"
          :class="['tab-' + tab.slotName, selectedIndex === tab.index && 'comment-composer__selected-tab', cursorStyle]"
          :data-test="snakeKebabToCamel(tab.slotName) + 'TabButton'"
        >
          <component :is="tab.icon" class="icon" size="1.25rem" />
          {{ $t(tab.title) }}
        </div>
      </div>
    </div>

    <div v-for="tab in tabs" :key="tab.index">
      <div :ref="tab.slotName" class="comment-composer__content-wrapper" :class="'wrapper-' + tab.slotName">
        <slot :name="tab.slotName"></slot>
      </div>
    </div>
    <div ref="bottom"></div>
  </div>
</template>

<script lang="ts">
import { NoteTextLinear, ReplyLinear } from '@trengo/trengo-icons';
import anime from 'animejs';

import breakpoints from '@/util/breakpoints';
import { placeCaretAtStart } from '@/util/helpers';
import { snakeKebabToCamel } from '@/util/stringHelpers';

export default {
  name: 'CommentComposer',

  components: { NoteTextLinear, ReplyLinear },

  props: {
    showReply: {
      type: Boolean,
      default: true,
    },
    isTicketAssigned: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedIndex: 0,
      tabs: [
        {
          index: 0,
          enabled: this.showReply,
          title: 'tickets.composer_public_reply',
          slotName: 'public-reply',
          icon: 'reply-linear',
        },
        {
          index: 1,
          enabled: true,
          title: 'tickets.composer_add_note',
          slotName: 'note',
          icon: 'note-text-linear',
        },
      ],
    };
  },

  computed: {
    hasMultipleTabs() {
      return this.tabs.filter((t) => t.enabled).length > 1;
    },

    cursorStyle() {
      return this.hasMultipleTabs ? 'cursor-pointer' : 'cursor-default';
    },
    isMobile() {
      return !breakpoints.comparisons.gt_sm;
    },
  },

  created() {
    this.selectedIndex = this.tabs.find((t) => t.enabled)?.index || 0;
  },

  mounted() {
    this.changeTab(this.selectedIndex, false);
  },

  methods: {
    snakeKebabToCamel,

    animate(index, shouldFocus) {
      const publicReplyElement = this.$refs['public-reply'][0];
      const noteTabElement = this.$refs['note'][0];

      if (!publicReplyElement || !noteTabElement) {
        return;
      }

      const feedContainer = document.getElementById('feed-container');
      const messagesContainer = document.getElementById('MessagesContainer');
      let isFeedOverflowing = true;
      if (feedContainer && messagesContainer) {
        isFeedOverflowing = feedContainer?.offsetHeight > messagesContainer?.offsetHeight;
      }

      // we set height to auto just to get the height, then make it display: 'none' before it can render in DOM
      publicReplyElement.style.display = 'block';
      publicReplyElement.style.height = 'auto';
      const publicReplyHeight = publicReplyElement.getBoundingClientRect().height;
      publicReplyElement.style.display = 'none';

      publicReplyElement.style.overflow = 'visible';
      noteTabElement.style.overflow = 'visible';

      noteTabElement.style.display = 'block';
      noteTabElement.style.height = 'auto';
      const noteHeight = noteTabElement.getBoundingClientRect().height;
      noteTabElement.style.display = 'none';

      if (index === 0) {
        publicReplyElement.style.display = 'block';
        noteTabElement.style.display = 'none';

        if (this.selectedIndex === index) {
          shouldFocus && this.focus(index);
          return;
        }

        // hide overflow during animation
        publicReplyElement.style.overflow = 'hidden';
        noteTabElement.style.overflow = 'hidden';
        publicReplyElement.style.height = `${noteHeight}px`;

        this.inAnimation(publicReplyElement);
        this.outAnimation(noteTabElement);
        this.selectedIndex = index;

        let duration = 350;
        if (!this.isMobile && isFeedOverflowing) {
          duration = 100;
        }
        this.heightAnimation(publicReplyElement, `${publicReplyHeight}px`, duration, () => {
          publicReplyElement.style.height = '';
          if (publicReplyHeight <= 800) {
            this.$refs.bottom.scrollIntoView({ behavior: 'smooth' });
          }
          this.focus(index);
          publicReplyElement.style.overflow = 'visible';
          noteTabElement.style.overflow = 'visible';
        });
      }
      if (index === 1) {
        noteTabElement.style.display = 'block';
        publicReplyElement.style.display = 'none';
        if (this.selectedIndex === index) {
          shouldFocus && this.focus(index);
          return;
        }

        // hide overflow during animation
        publicReplyElement.style.overflow = 'hidden';
        noteTabElement.style.overflow = 'hidden';
        noteTabElement.style.height = `${publicReplyHeight}px`;

        this.inAnimation(noteTabElement);
        this.outAnimation(publicReplyElement);
        this.selectedIndex = index;
        this.heightAnimation(noteTabElement, `${noteHeight}px`, 350, () => {
          noteTabElement.style.height = '';
          this.focus(index);
          publicReplyElement.style.overflow = 'visible';
          noteTabElement.style.overflow = 'visible';
        });
      }
    },
    outAnimation(target) {
      anime({
        targets: target,
        opacity: [1, 0],
        duration: 300,
        easing: 'linear',
      });
    },
    inAnimation(target) {
      anime({
        targets: target,
        opacity: [0, 1],
        duration: 300,
        easing: 'linear',
      });
    },
    heightAnimation(target, newHeight, duration, callback) {
      anime({
        targets: target,
        height: newHeight,
        duration: duration,
        easing: 'easeInOutCubic',
        complete: callback,
      });
    },
    changeTab(index, shouldFocus) {
      if (!this.hasMultipleTabs) {
        // this means only comment tab should be visible
        const publicReplyElement = this.$refs['public-reply'][0];
        const noteTabElement = this.$refs['note'][0];
        if (publicReplyElement && noteTabElement) {
          publicReplyElement.style.display = 'none';
          noteTabElement.style.display = 'block';
          noteTabElement.style.opacity = 1;
          noteTabElement.style.overflow = 'visible';
          shouldFocus && this.focus(index);
        }
        return;
      }
      this.animate(index, shouldFocus);
    },
    focus(index) {
      if (window.is_mobile_device()) {
        return;
      }
      // nextTick resolves too soon for the composer to be properly mounted and interactable,
      // As such, setTimeout is the only alternative since it'll only execute once all the DOM rendering is finished,
      // at which point the composer elements will be interactable and focusable. Hopefully in the future this can
      // be handled better once we stop caring about keeping the legacy behavior intact
      let composer;

      if (index === 0 && this.isTicketAssigned) {
        composer = document.querySelector('.fr-wrapper > .fr-element') || document.getElementById('publicComposer');
      } else if (index === 1) {
        composer = document.getElementById('privateComposer');
      }

      if (composer) {
        composer.focus({ preventScroll: true });
        placeCaretAtStart(composer);
      }
    },
  },
  watch: {
    showReply(value) {
      this.selectedIndex = 1;
      this.tabs[0].enabled = value;
      this.changeTab(1, false);
    },
  },
};
</script>

<style scoped lang="scss" src="./CommentComposer.scss" />
