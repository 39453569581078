import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full px-6",
  "data-test": "settings-banner"
}
const _hoisted_2 = { class: "h-full px-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_thumbnail = _resolveComponent("t-thumbnail")!
  const _component_question_linear = _resolveComponent("question-linear")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_main_header = _resolveComponent("main-header")!
  const _component_admin_section_title = _resolveComponent("admin-section-title")!
  const _component_list_row_parent = _resolveComponent("list-row-parent")!
  const _component_settings_base_layout = _resolveComponent("settings-base-layout")!

  return (_openBlock(), _createBlock(_component_settings_base_layout, null, _createSlots({
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.showList)
          ? (_openBlock(), _createBlock(_component_list_row_parent, { key: 0 }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "list")
              ]),
              _: 3 /* FORWARDED */
            }))
          : _createCommentVNode("v-if", true),
        _renderSlot(_ctx.$slots, "content")
      ])
    ]),
    _: 2 /* DYNAMIC */
  }, [
    (_ctx.showList)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _createVNode(_component_main_header, {
              title: _ctx.headingTitle,
              class: "hidden-md-down"
            }, {
              left: _withCtx(() => [
                _createVNode(_component_t_thumbnail, { class: "bg-white" }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "title-icon")
                  ]),
                  _: 3 /* FORWARDED */
                })
              ]),
              right: _withCtx(() => [
                (_ctx.showHelpButton)
                  ? (_openBlock(), _createBlock(_component_t_button, {
                      key: 0,
                      class: "open-external",
                      "btn-style": "secondary",
                      "icon-only": "",
                      href: _ctx.helpLink,
                      rel: "noreferrer noopener",
                      target: "_blank"
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_question_linear, { size: "1.042rem" })
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["href"]))
                  : _createCommentVNode("v-if", true),
                _createVNode(_component_t_button, {
                  class: "ml-3",
                  onClick: _ctx.handleAddButtonPressed
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.addButtonText), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
              ]),
              _: 3 /* FORWARDED */
            }, 8 /* PROPS */, ["title"]),
            (_ctx.$slots.banner)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _renderSlot(_ctx.$slots, "banner")
                ]))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_admin_section_title, { title: _ctx.sectionTitle }, null, 8 /* PROPS */, ["title"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1024 /* DYNAMIC_SLOTS */))
}