<script type="text/babel">
import eventBus from '@/eventBus';

export default {
  name: 'ApiToken',
  data() {
    return {
      token: null,
      generating: false,
      generated: false,
      exists: false,
    };
  },

  mounted() {
    axios.get('/api/v2/api_tokens').then((res) => {
      this.exists = res.data.id != null;
    });
  },

  methods: {
    clickDocumentation() {
      eventBus.$emit('api-documentation-clicked');
      window.open('https://developers.trengo.com', '_blank');
    },

    save(force = false) {
      if (this.generating && !force) {
        return;
      }

      this.generating = true;

      axios
        .post('/api/v2/api_tokens')
        .then((res) => {
          this.generating = false;
          this.token = res.data.token;
          this.exists = true;
          $('#ApiTokenModal').modal('show');
          eventBus.$emit('api-token-generated');
        })
        .catch(() => {
          this.generating = false;
        });
    },

    regenerate() {
      if (
        this.generating ||
        !confirm(
          'Are you sure you want to re-generate your personal access token? This will invalidate the current token.'
        )
      ) {
        return;
      }

      this.generating = true;

      axios
        .delete('/api/v2/api_tokens/1')
        .then((res) => {
          this.save(true);
        })
        .catch(() => {
          this.generating = false;
        });
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <div class="nav-settings light left b-primary">
        <ul>
          <li class="text-md white header">
            <strong>{{ $t('apps_integration.rest_api') }}</strong>
          </li>
        </ul>
      </div>

      <div class="p-a p-b-0 container">
        <div class="p-a">
          <div class="box">
            <div class="box-header b-b">
              <h2>{{ $t('apps_integration.rest_api') }}</h2>
            </div>
            <div class="box-body">
              <p>
                {{ $t('apps_integration.rest_api_description') }}
              </p>
              <div>
                <button v-show="!exists" class="btn btn-default" :class="{ loader: generating }" @click="save()">
                  {{ $t('apps_integration.generate_api_token') }}
                </button>
                <button v-show="exists" class="btn btn-default" :class="{ loader: generating }" @click="regenerate()">
                  {{ $t('apps_integration.regenerate_api_token') }}
                </button>
                <button class="btn btn-default" @click="clickDocumentation()">
                  {{ $t('apps_integration.view_documentation') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="ApiTokenModal" class="modal" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('apps_integration.api_token') }}</h5>
          </div>
          <div class="modal-body">
            <div class="alert alert-warning">
              {{ $t('apps_integration.this_is_your_personal_access_token') }}
              <strong>{{ $t('apps_integration.only_time_token_will_ever_be_displayed') }}</strong>
              {{ $t('apps_integration.you_may_regenerate_the_token_at_any_time') }}
            </div>
            <textarea rows="10" class="form-control">{{ token }}</textarea>
          </div>
          <div class="modal-footer">
            <button data-dismiss="modal" class="btn btn-info">{{ $t('apps_integration.close') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
