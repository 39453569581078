<script lang="ts" setup>
import DangerZoneVerification from '@/components/common/DangerZoneVerification';

import ImportView from './ImportView.vue';
</script>

<template>
  <danger-zone-verification>
    <import-view />
  </danger-zone-verification>
</template>
