import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "list-none" }
const _hoisted_2 = { class: "filter-dropdown-second-layer absolute z-50 mt-2 min-h-[258px] w-92 rounded-xl border border-grey-300 bg-white shadow-500" }
const _hoisted_3 = { class: "flex justify-end px-3 py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_badge = _resolveComponent("t-badge")!
  const _component_caret_down_fill = _resolveComponent("caret-down-fill")!
  const _component_t_list_item = _resolveComponent("t-list-item")!
  const _component_t_input_search = _resolveComponent("t-input-search")!
  const _component_t_divider = _resolveComponent("t-divider")!
  const _component_t_list_item_checkbox = _resolveComponent("t-list-item-checkbox")!
  const _component_results_not_found = _resolveComponent("results-not-found")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_popover = _resolveComponent("t-popover")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_component_t_popover, {
      modelValue: _ctx.isOpen,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isOpen) = $event)),
      class: "relative",
      "click-outside-close": ""
    }, {
      trigger: _withCtx(() => [
        _createVNode(_component_t_list_item, {
          label: _ctx.$t('reports_v2.labels'),
          class: "relative",
          active: _ctx.isOpen
        }, {
          postfix: _withCtx(() => [
            (_ctx.labelsCount > 0)
              ? (_openBlock(), _createBlock(_component_t_badge, {
                  key: 0,
                  class: "flex items-center",
                  text: _ctx.labelsCount
                }, null, 8 /* PROPS */, ["text"]))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_caret_down_fill, {
              size: "1.25rem",
              class: "mt-1 -rotate-90 text-grey-600"
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label", "active"])
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_t_input_search, {
            key: "search-teams",
            modelValue: _ctx.search,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            variant: "primary-alt",
            class: "m-2",
            onClear: _cache[1] || (_cache[1] = ($event: any) => (_ctx.search = '')),
            onInput: _ctx.onSearch
          }, null, 8 /* PROPS */, ["modelValue", "onInput"]),
          _createVNode(_component_t_divider, { class: "mb-2" }),
          _createElementVNode("ul", {
            class: _normalizeClass([_ctx.containerHeight, "w-full overflow-x-hidden overflow-y-scroll px-2"])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLabels, (label) => {
              return (_openBlock(), _createBlock(_component_t_list_item_checkbox, {
                key: label.id,
                "model-value": _ctx.selectedItems.includes(label.id),
                label: label.name,
                "onUpdate:modelValue": ($event: any) => (_ctx.handleSelectItem(label))
              }, null, 8 /* PROPS */, ["model-value", "label", "onUpdate:modelValue"]))
            }), 128 /* KEYED_FRAGMENT */))
          ], 2 /* CLASS */),
          (!_ctx.getLabels.length)
            ? (_openBlock(), _createBlock(_component_results_not_found, {
                key: 0,
                text: _ctx.noResultsFound
              }, null, 8 /* PROPS */, ["text"]))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_t_divider),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_t_button, {
              size: "sm",
              "btn-style": "secondary",
              class: "mr-2",
              onClick: _ctx.clearFilters
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('reports_v2.clear')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"]),
            _createVNode(_component_t_button, {
              size: "sm",
              onClick: _ctx.applyFilters
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('reports_v2.confirm')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}