import { ENDPOINT } from '@/api/constants';
import { request } from '@/util/request';

import type { ConsolidatedComposerSendPayload } from '@/components/ReplyForm/types/composer';
import type Contact from '@/types/contact';
import type Ticket from '@/types/tickets';
import type { AxiosPromise } from 'axios';

export type ConsolidatedMessageReturnPayload = {
  ticket: Ticket;

  // TODO: https://linear.app/trengo/issue/TFA-1/add-consolidatedmessagereturnpayload-message-types We need to create a proper message type here
  message: {
    id: number | string;
    [key: string]: unknown;
  };
};

export type consolidatedSendResponseTicket = {
  readonly id: number;
  status: 'OPEN' | 'ASSIGNED' | 'CLOSED' | 'INVALID';
  subject?: string;
  closed_at?: string;
  created_at?: string;
  updated_at?: string;
  user_id?: string;
  team_id?: null;
  assigned_at?: string;
  contact_id?: string;
  contact?: Contact;
  channelMeta?: unknown;
  custom_data?: unknown;
  messages_count?: number;
  custom_field_values?: unknown[];
};

export function sendConsolidatedMessage(
  payload: ConsolidatedComposerSendPayload
): AxiosPromise<ConsolidatedMessageReturnPayload> {
  return request(ENDPOINT.CONSOLIDATED_MESSAGE_ENDPOINT, 'POST', payload);
}
