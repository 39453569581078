<template>
  <div class="my-16 flex flex-col items-center">
    <h2>{{ $t('csat.no_responses') }}</h2>
    <h4>{{ $t('csat.collect_csat') }}</h4>
  </div>
</template>

<style lang="scss" scoped>
h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}
h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  max-width: 600px;
  text-align: center;
}
</style>
