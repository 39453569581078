import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "t-text-lg-emphasize" }
const _hoisted_3 = { class: "t-text-md text-grey-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_divider = _resolveComponent("t-divider")!
  const _component_arrow_top_circle_linear = _resolveComponent("arrow-top-circle-linear")!
  const _component_arrow_right_linear = _resolveComponent("arrow-right-linear")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!
  const _component_t_switch = _resolveComponent("t-switch")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_t_divider, { class: "my-6" }),
    (!_ctx.isEntitledToManagePermissions)
      ? (_openBlock(), _createBlock(_component_t_inline_banner, {
          key: 0,
          type: "upgrade",
          class: "my-6"
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_arrow_top_circle_linear)
          ]),
          action: _withCtx(() => [
            (_ctx.hasUpgradePermissions)
              ? _renderSlot(_ctx.$slots, "default", { key: 0 }, () => [
                  _createVNode(_component_t_button, {
                    "btn-style": "secondary",
                    class: "flex items-center gap-1",
                    onClick: _ctx.handleUpgrade
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.labelCTA) + " ", 1 /* TEXT */),
                      _createVNode(_component_arrow_right_linear, { size: "1rem" })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"])
                ])
              : _createCommentVNode("v-if", true)
          ]),
          default: _withCtx(() => [
            _createElementVNode("span", { innerHTML: _ctx.bannerLabel }, null, 8 /* PROPS */, _hoisted_1)
          ]),
          _: 3 /* FORWARDED */
        }))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.permissionsGroupedByDomain, (permissions, domain) => {
      return (_openBlock(), _createElementBlock("div", {
        key: domain,
        class: "pb-8"
      }, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(`user_management.${domain}`)), 1 /* TEXT */),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(permissions, (permission) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `permission-${permission.id}-${_ctx.role.id}`,
            class: "mb-3 flex justify-between py-2"
          }, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(`user_management.${_ctx.getTranslatedPermissionName(permission.name)}`)), 1 /* TEXT */),
            _createVNode(_component_t_switch, {
              "model-value": permission.granted,
              disabled: !_ctx.isEntitledToManagePermissions,
              "onUpdate:modelValue": ($event: any) => (_ctx.handleToggleClick(permission.id, _ctx.role.id))
            }, null, 8 /* PROPS */, ["model-value", "disabled", "onUpdate:modelValue"])
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}