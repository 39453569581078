<template>
  <div class="h-full overflow-y-scroll">
    <mobile-preview-card>{{ $t('general.feature_only_available_on_desktop') }}</mobile-preview-card>
    <Overview v-if="!noCsats && isEntitled" :csats="csats" :average-csat="averageCsats" class="hidden-md-down" />
    <settings-landing v-if="noCsats" :title="page.title" :icon="page.icon" :list="listItems">
      <template v-if="featureNeedsUpgrade()" #entitlement-badge>
        <t-badge id="upgrade-badge" variant="upgrade" :text="entitlementBadgeText">
          <template #icon><arrow-top-circle-linear size="1.25rem" /></template>
        </t-badge>
      </template>

      <AdminIndexFooterForEntitlements :page="page" />
    </settings-landing>
  </div>
</template>

<script>
import { ArrowTopCircleLinear } from '@trengo/trengo-icons';
import { isEmpty } from 'lodash';
import { mapStores } from 'pinia';

import MobilePreviewCard from '@/components/common/MobilePreviewCard';
import SettingsLanding from '@/components/common/SettingsLanding';
import { FEATURE, ENTITLEMENT_STATUS, FEATURE_ICON } from '@/Configs/Constants';
import { useEntitlementsStore } from '@/store/pinia';

import Overview from './Overview';
import AdminIndexFooterForEntitlements from '../AdminIndexFooterForEntitlements.vue';
export default {
  data() {
    return {
      listItems: [
        this.$t('csat.measure_and_evaluate'),
        this.$t('csat.gain_valuable_insights'),
        this.$t('csat.identify_areas'),
      ],
      csats: null,
      averageCsats: 0,
      page: {
        name: 'csat',
        title: this.$t('csat.customer_satisfaction'),
        icon: FEATURE_ICON.CSAT,
        primary_button_text: this.$t('csat.add_first_survey'),
        primary_button_url: '/admin/csat/create',
        secondary_button_text: this.$t('csat.learn_more'),
        secondary_button_url: 'https://help.trengo.com/en/30068-customer-feedback',
        entitlement_upgrade_button_text: '',
        entitlement_feature_id: FEATURE.CUSTOMER_SERVICE__AUTOMATION__CSAT,
        implements_entitlement: true,
      },
    };
  },
  components: {
    ArrowTopCircleLinear,
    AdminIndexFooterForEntitlements,
    MobilePreviewCard,
    Overview,
    SettingsLanding,
  },
  mounted() {
    if (this.isEntitled) {
      this.loadCsats();
      this.loadCsatAverageValue();
    }
    this.page.entitlement_upgrade_button_text = this.entitlementUpgradeButtonText;
  },
  computed: {
    ...mapStores(useEntitlementsStore),
    noCsats() {
      return isEmpty(this.csats);
    },
    isEntitled() {
      return this.entitlementsStore?.isEntitledTo(FEATURE.CUSTOMER_SERVICE__AUTOMATION__CSAT);
    },
    minimumRequiredPlan() {
      return this.entitlementsStore?.getMinimumRequiredPlanFor(this.page.entitlement_feature_id);
    },
    entitlementBadgeText() {
      return this.$t('entitlement.upgrade_to_plan_long', { plan: this.minimumRequiredPlan });
    },
    entitlementUpgradeButtonText() {
      return this.$t('entitlement.upgrade_to_plan', { plan: this.minimumRequiredPlan });
    },
  },
  watch: {
    isEntitled(newVal) {
      if (newVal) {
        this.loadCsats();
        this.loadCsatAverageValue();
      }
    },
  },
  methods: {
    learnMore() {
      window.open('https://help.trengo.com/en/30068-customer-feedback', '', 'resizable,scrollbars,noopener');
    },
    goToSurvey() {
      this.$router.push('/admin/csat/create');
    },
    displayToast() {
      if (this.$route.query.newCsat && this.csats.filter((csat) => csat.is_activated).length === 1) {
        this.flashSuccess(this.$t('csat.csat_active_toast'));
      }
    },
    loadCsats() {
      axios.get('/api/v2/csat').then((res) => {
        this.csats = res.data;
        this.displayToast();
      });
    },
    loadCsatAverageValue() {
      axios
        .get('/api/v2/reports/reports/csat', {
          params: { ...this.firstCsatRequestParams() },
        })
        .then((res) => {
          this.averageCsats = Math.max(Math.round((res.data.buckets.total_average_rating - 1) * 25), 0);
        });
    },
    firstCsatRequestParams() {
      return {
        period_start: 1546124400,
        period_end: 1672527599,
        interval: 'month',
        timezone: this.$root.user.timezone,
      };
    },
    featureNeedsUpgrade() {
      const featureId = this.page.entitlement_feature_id;
      return featureId && this.getEntitlementStatusForUpgrade(featureId) && this.page.implements_entitlement;
    },
    getEntitlementStatusForUpgrade(featureId) {
      return this.entitlementsStore?.getEntitlementStatusFor(featureId) === ENTITLEMENT_STATUS.REQUIRES_PLAN_UPGRADE;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/sass/typography.scss';

#upgrade-badge {
  @apply t-text-sm-emphasize h-8 w-fit px-3 py-[6px] #{!important};
}
</style>
