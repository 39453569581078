<template>
  <div>
    <div v-if="!rounded" ref="cp" class="input-group colorpicker-component">
      <div ref="colorpicker" class="input-group">
        <input
          v-model="colorValue"
          :disabled="disabled"
          type="text"
          autocomplete="off"
          class="
            vc_border_color vc_input
            placeholder-gray-600
            focus:shadow-outline
            h-10
            w-full
            border
            pl-3
            pr-8
            text-base
          "
          @focus="showPicker()"
          @input="updateFromInput"
        />
        <button
          :disabled="disabled"
          class="
            vc_border_color
            absolute
            inset-y-0
            right-0
            flex
            items-center
            rounded-r-lg
            border-l-2
            px-4
            font-bold
            text-white
          "
          @click="handleButtonClick"
        >
          <span class="input-group-append">
            <span class="input-group-text">
              <span
                class="vc_current-color display-flex align-content-center inline-block self-center"
                :class="disabled ? '' : 'cursor-pointer'"
                :style="'background-color: ' + (colorValue || '#000000')"
                @click="disabled ? '' : togglePicker()"
              />
            </span>
            <chrome-picker
              v-if="displayPicker"
              :model-value="colors || '#000000'"
              @update:modelValue="updateFromPicker"
            />
          </span>
        </button>
      </div>
    </div>

    <div
      v-else
      ref="colorpicker"
      class="rounded-input-group colorpicker-component rounded border-2 pr-1"
      style="border-color: rgb(235, 237, 245)"
    >
      <div class="rounded_container">
        <span class="input-group-addon pointer rounded text-white">
          <i
            :style="{ backgroundColor: colorValue ? colorValue : '#000000' }"
            class="material-icons w-30 rounded p-2"
            @click="showPicker()"
          >
            colorize
          </i>
        </span>
        <input
          v-model="colorValue"
          :disabled="disabled"
          type="text"
          style="color: #b7b7b7"
          autocomplete="off"
          class="vc_border_color vc_input mt-1 rounded"
          @focus="showPicker()"
          @input="updateFromInput"
        />
      </div>
      <span>
        <chrome-picker v-if="displayPicker" :model-value="colors || '#000000'" @update:modelValue="updateFromPicker" />
      </span>
    </div>
  </div>
</template>

<script type="text/babel">
import { Chrome } from '@ckpack/vue-color';

export default {
  name: 'ColorPicker',
  emits: ['update:modelValue'],
  components: {
    'chrome-picker': Chrome,
  },
  props: {
    modelValue: {
      type: [String, null],
      default: '#000000',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    default: {
      type: [String, null],
      default: '#000000',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultColor: this.modelValue,
      colors: {
        hex: this.modelValue,
      },
      colorValue: '',
      displayPicker: false,
    };
  },
  watch: {
    colorValue(val) {
      this.updateColors(val);
      this.$emit('update:modelValue', val);
    },
    modelValue(color) {
      this.setColor(color);
    },
  },
  mounted() {
    this.setColor(this.defaultColor || this.default);
  },
  unmounted() {
    this.hidePicker();
  },
  methods: {
    handleButtonClick(e) {
      e.preventDefault();
    },
    setColor(color) {
      this.updateColors(color);
      this.colorValue = color;
    },
    updateColors(val) {
      this.colors = val;
    },
    showPicker() {
      document.addEventListener('click', this.documentClick);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick);
      this.displayPicker = false;
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateFromInput() {
      this.updateColors(this.colorValue);
    },
    updateFromPicker(color) {
      this.colors = color;
      if (color.rgba.a === 1) {
        this.colorValue = color.hex;
      } else {
        // eslint-disable-next-line
        this.colorValue =
          'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
      }
    },
    documentClick(e) {
      const el = this.$refs.colorpicker;
      const target = e.target;
      if (el !== target && !el.contains(target)) {
        this.hidePicker();
      }
    },
  },
};
</script>
