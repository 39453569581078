<script>
import Generic from './ECommerce/Generic';

export default {
  extends: Generic,

  data() {
    return {
      settings: {},
    };
  },

  methods: {
    loadData() {
      this.customer = {};
      this.orders = [];
      this.loading = true;

      axios
        .get('/api/v2/integrations/magento?plugin_id=' + this.plugin.id + '&ticket_id=' + this.ticket.id)
        .then((res) => {
          this.loading = false;
          if (!res.data.customer && !res.data.orders) {
            this.found = false;
            return;
          }
          this.found = true;
          this.customer = res.data.customer;
          this.orders = res.data.orders;

          if (this.orders.length == 0) {
            this.found = false;
          }
        })
        .catch((e) => {
          this.found = true;
          this.loading = false;
        });
    },

    getCustomerUrl(id) {
      return this.plugin.meta.url + '/admin/customers/' + id;
    },

    getOrderUrl(id) {
      return this.plugin.meta.admin_url + '/sales/order/view/order_id/' + id;
    },
  },
};
</script>
