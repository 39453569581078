<script>
export default {
  mounted() {},
  props: {
    color: {
      type: String,
      default: 'grey',
    },
    width: {
      type: String,
      default: '48px',
    },
    height: {
      type: String,
      default: '48px',
    },
    rounded: {
      type: String,
      default: '10px',
    },
  },
};
</script>

<template>
  <div
    class="flex items-center justify-center text-black"
    :style="{ background: color, 'border-radius': rounded, width: width, height: height }"
  >
    <i class="material-icons"><slot></slot></i>
  </div>
</template>
