<script type="text/babel">
import { mixin as VueClickAway } from 'vue3-click-away';

import eventBus from '@/eventBus';

import CallLog from './CallLog.vue';
import ChannelSelector from './ChannelSelector.vue';
import CurrentCall from './CurrentCall.vue';
import Dialpad from './Dialpad.vue';
import History from './History.vue';
import InboundCall from './InboundCall.vue';
import PhoneInput from './PhoneInput.vue';
import Settings from './Settings.vue';
import VoipAudio from './VoipAudio.vue';
import CallObject from '../CallObject';
import Voip from '../Voip';
import VoipEvents from '../VoipEvents';

export default {
  name: 'VoipPanel',
  emits: [
    'open',
    'close',
    'voip.reset_user_status',
    'outbound-call-started',
    'voip.call_ended',
    'voip.user_call_ended',
    'voip.user_call_started',
    'update-showMenu',
  ],
  data() {
    return {
      initialized: false,
      isOpen: false,
      user_status: this.$root.user.voip_status,
      ringing: false,
      voip: null,
      call: CallObject,
      tab: null,
      channels: [],
      channel: {},
      calls: [],
      voipSandboxRejectOutboundCall: false,
    };
  },

  watch: {
    isOpen() {
      this.$root.voipPanelOpen = this.isOpen;
      this.$emit('update-showMenu', false);
    },
    initialized() {
      this.$root.hasAcccessToVoipChannel = this.initialized;
    },
    '$root.user.voip_status'() {
      this.user_status = this.$root.user.voip_status;
    },
    ringing() {
      if (this.ringing) {
        this.$root.showMenu = false;
      }
    },
  },

  mixins: [VoipEvents, VueClickAway],

  components: {
    ChannelSelector,
    InboundCall,
    PhoneInput,
    CurrentCall,
    CallLog,
    Dialpad,
    VoipAudio,
    History,
    Settings,
  },

  mounted() {
    if (this.$root.voip_status === 'CALLING') {
      eventBus.$emit('voip.reset_user_status');
    }

    eventBus.$on('voip.call', (data) => {
      this.isOpen = true;
      this.phoneSelected(data.phone);
    });

    eventBus.$on('voip.toggle', () => {
      this.togglePanel();
    });

    eventBus.$on('voip.showPanel', () => {
      this.initialized = true;
    });

    eventBus.$on('voip.sandbox_reject_outbound_call', () => {
      this.voipSandboxRejectOutboundCall = true;
    });

    this.call.reset();
  },

  unmounted() {
    eventBus.$off('voip.call');
    eventBus.$off('voip.toggle');
  },

  methods: {
    initialize() {
      this.voip = new Voip(AGENCY.channel_prefix, this.$root.user, this.$root.mobileDevice);
      this.voip.initialize();
    },

    togglePanel() {
      this.isOpen ? this.$emit('close') : this.$emit('open');
      this.isOpen = !this.isOpen;
    },

    onChannelsLoaded(channels) {
      if (channels.length > 0) {
        this.initialize();
        this.registerEvents();
      }
    },

    selectTab(tab) {
      if (this.call.status === 'initializing') {
        return;
      }

      if (this.tab == tab) {
        this.tab = null;
        return;
      }
      this.tab = tab;
    },

    contactSelected() {},

    phoneSelected(phone) {
      this.tab = 'dialpad';
      this.voip.startCall(phone, this.channel.id, this.channel.voipChannel?.provider);

      eventBus.$emit('outbound-call-started');
    },

    onChannelSelected(channel) {
      this.channel = channel;
    },

    endCall() {
      if (this.call.intern == true) {
        this.voip.rejectInternCall(this.call.internCall.from.id);
      }

      if (this.call.status === 'ringing' || (this.$root.mobileDevice && this.call.status === 'initializing')) {
        $.post('/voip-api/cancelled-call', {
          token: this.call.token,
          mobile: this.$root.mobileDevice,
        });
      }

      eventBus.$emit('voip.call_ended', this.call);
      this.call.reset();

      this.voip.endCall();
      this.tab = null;
      eventBus.$emit('voip.user_call_ended');
    },

    transferSelected(data) {
      this.call.internCall.status = 'ringing';
      this.call.internCall.direction = 'outbound';
      this.call.internCall.type = data.type;

      if (data.type == 'user') {
        this.call.internCall.user = data.user;
        this.call.internCall.direction = 'outbound';
        this.voip.transferToUser(data.user, this.call);
      }

      if (data.type == 'team') {
        let teamUserIsAvailable = this.$root.users.some(
          (u) => u.teams.some((t) => t.id === data.team.id) && u.voip_status === 'ONLINE' && u.id !== this.$root.user.id
        );

        if (!teamUserIsAvailable) {
          this.flashWarning("The forwarded team doesn't have any online users");
          this.call.resetInternCall();
          return;
        }

        this.call.internCall.direction = 'outbound';
        this.call.internCall.team_id = data.team.id;
        this.call.internCall.user = {
          abbr: data.team.name.substr(0, 2).toUpperCase(),
          color: 'grey',
          full_name: data.team.name,
        };
        this.voip.transferToTeam(data.team, this.call);
      }

      if (data.type == 'extern') {
        this.call.internCall.direction = 'outbound';

        if (data.user != null) {
          this.call.internCall.user = data.user;
          this.call.internCall.direction = 'outbound';
          this.call.internCall.user.is_extern = true;
        } else {
          this.call.internCall.user = {
            abbr: data.phone.substr(0, 2),
            color: 'grey',
            full_name: data.phone,
            is_extern: true,
            user: data.user,
          };
        }

        this.voip.transferToPhone(data.phone, this.call);
      }
    },

    cancelTransfer() {
      this.voip.cancelTransfer(this.call);
      this.call.resetInternCall();
    },

    toggleMute() {
      this.call.muted = !this.call.muted;
      this.voip.setMute(this.call.muted);
    },

    toggleHold() {
      if (this.call.holdLoading) {
        return;
      }

      this.call.muted = !this.call.muted;
      this.voip.setMute(this.call.muted);

      this.call.holdLoading = true;
      this.call.hold = !this.call.hold;

      this.voip.setHold(this.call.hold, this.call.token).then(() => {
        this.call.holdLoading = false;
      });
    },

    userStatusChanged(status) {
      this.user_status = status;
    },

    acceptInboundCall() {
      eventBus.$emit('voip.user_call_started');

      this.voip.initToken(this.call.channel.id).then((data) => {
        this.call.accepting = true;
        if (this.call.intern) {
          this.voip.acceptInternCall(this.call.token);
        } else {
          this.voip.acceptInboundCall(this.call.token);
        }
      });

      this.voip.sendAcceptedCallEvent(this.call);
    },

    ignoreInboundCall() {
      if (this.call.intern) {
        this.voip.rejectInternCall(this.call.userId);
      }

      this.call.reset();
      this.tab = null;
    },

    showCurrentCallTab() {
      if (this.tab != null) {
        return false;
      }

      if (this.call.isInbound() && this.call.userId == this.$root.user.id && this.call.isInProgress()) {
        return true;
      }

      if (
        this.call.isOutbound() &&
        this.call.userId == this.$root.user.id &&
        (this.call.isInProgress() || this.call.isRinging())
      ) {
        return true;
      }

      return false;
    },

    deQueueCall(call) {
      this.voip.initToken(call.channel.id).then((data) => {
        eventBus.$emit('voip.user_call_started');
        this.call.accepting = true;
        this.call.direction = 'inbound';
        this.call.contact = call.contact;
        this.call.channel = call.channel;
        this.call.ticketId = call.ticket_id;
        this.call.token = call.token;
        this.call.status = 'initializing';
        this.tab = 'dialpad';
        this.voip.acceptInboundCall(call.token);
        this.voip.sendAcceptedCallEvent(call);
      });
    },

    transferCall() {
      this.call.internCall.status = 'transferring';
      this.voip
        .executeTransfer(this.call)
        .then((res) => {
          this.call.internCall.status = 'transferred';
          this.voip.endCall();
        })
        .catch((e) => {
          $.growl.error({ title: 'Oops.', message: 'Transfer failed. Try again, the customer is still on hold.' });
          this.call.internCall.status = 'in-progress';
        });
    },

    transferCompleted() {
      this.call.reset();
      this.tab = null;
      eventBus.$emit('voip.user_call_ended');
    },

    showSettingsTab() {
      if (this.call.status !== null) {
        return;
      }

      if (this.tab == 'settings') {
        this.tab = null;
        return;
      }

      this.tab = 'settings';
    },

    cancelOutboundCall() {
      console.log('Panel.vue cancelOutboundCall');
      this.endCall();
    },

    openPanel() {
      this.isOpen = true;
    },

    dialPadHornPressed() {},
  },
};
</script>
<template>
  <div v-show="initialized">
    <div
      id="TrengoVoip"
      v-tooltip="{ placement: 'right', content: 'Voice', popperClass: 'tooltip-vuetify' }"
      class="
        bounceIn
        icon-toggle
        selector-voip-panel
        flex
        h-full
        w-full
        cursor-pointer
        items-center
        justify-center
        text-grey-700
      "
      role="button"
      style="width: 46px; height: 46px"
      @click="togglePanel"
    >
      <slot name="icon-voice"></slot>
      <portal v-if="!call.isVoipSandbox" to="voip-portal">
        <div v-if="isOpen" class="voip-overlay nav-bg" @click="togglePanel"></div>
        <div
          v-show="isOpen"
          class="voip-panel pos-relative animated slideInUp flex flex-col bg-black"
          style="animation-duration: 0.2s"
        >
          <div class="relative flex items-center justify-center py-3">
            <div
              class="
                flex-inline flex-shrink-0
                select-none
                items-center
                rounded-lg
                bg-white
                px-2
                pt-1
                text-xs text-grey-500
              "
            >
              <span
                class="cursor-pointer hover:text-black"
                :class="{ 'text-black': tab === 'dialpad' }"
                @click="selectTab('dialpad')"
              >
                <i class="material-icons">dialpad</i>
              </span>
              <span
                class="mx-1 cursor-pointer hover:text-black"
                :class="{ 'text-black': tab === 'history' }"
                @click="selectTab('history')"
              >
                <i class="material-icons">history</i>
              </span>
              <span
                class="cursor-pointer hover:text-black"
                :class="{ 'text-black': tab === 'settings' }"
                @click="selectTab('settings')"
              >
                <i class="material-icons">settings</i>
              </span>
            </div>
            <div class="absolute" style="right: 15px; top: 15px">
              <i class="material-icons hidden-lg-up text-grey-500" @click="togglePanel">close</i>
            </div>
          </div>
          <inbound-call
            v-if="call.isRingingInbound() && !$root.mobileDevice"
            :call="call"
            @update:call="call = $event"
            @accept="acceptInboundCall"
            @ignore="ignoreInboundCall"
          ></inbound-call>
          <template v-else>
            <div
              v-if="voipSandboxRejectOutboundCall && channel.voipChannel.provider === 'TWILIO_SANDBOX'"
              class="absolute flex h-full w-full select-none items-center justify-center bg-black"
              style="margin-top: -50px; bottom: 0; z-index: 99999"
            >
              <div class="text-center font-bold text-white" style="font-size: 16px">
                <div class="px-4" style="font-size: 20px">Only incoming calls are allowed with the demo channel</div>
                <div
                  class="btn btn-lg mt-12 rounded bg-grey-800 text-white"
                  style="font-size: 16px; font-weight: bold; padding-left: 3rem; padding-right: 3rem"
                  data-toggle="tooltip"
                  data-placement="left"
                  @click="voipSandboxRejectOutboundCall = false"
                >
                  Go back
                </div>
              </div>
            </div>
            <div v-if="call.status == null && (!tab || tab === 'dialpad')">
              <channel-selector @loaded="onChannelsLoaded" @selected="onChannelSelected"></channel-selector>
              <phone-input v-if="isOpen && call.status == null" @selected="phoneSelected"></phone-input>
            </div>
            <settings v-if="tab === 'settings'"></settings>
            <current-call
              v-show="showCurrentCallTab()"
              :call="call"
              @update:call="call = $event"
              @end="endCall"
              @transferSelected="transferSelected"
              @transferCancelled="cancelTransfer"
              @transferCall="transferCall"
              @toggleMute="toggleMute"
              @toggleHold="toggleHold"
              @transferCompleted="transferCompleted"
              @openPanel="openPanel"
            ></current-call>
            <dialpad
              v-show="tab === 'dialpad'"
              :call="call"
              @cancel="cancelOutboundCall"
              @phoneSelected="phoneSelected"
            ></dialpad>
            <history v-if="tab === 'history'"></history>
            <call-log v-show="tab == null && call.status == null" @deQueueCall="deQueueCall"></call-log>
          </template>
        </div>
        <voip-audio :call="call"></voip-audio>
      </portal>
    </div>
  </div>
</template>
