import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { "data-test": "installation-item-row-wrapper" }
const _hoisted_2 = {
  style: {"min-width":"200px"},
  class: "py-4 text-base text-grey-600"
}
const _hoisted_3 = { class: "w-full text-base font-semibold text-grey-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("tr", _hoisted_1, [
      _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.$tc('integration_hub.installed')), 1 /* TEXT */),
      _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.formatDate(_ctx.installation.installed_at)), 1 /* TEXT */)
    ])
  ]))
}