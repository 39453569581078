<template>
  <div ref="modal" class="modal">
    <div class="modal-centered height-auto" style="max-width: 80%">
      <div class="modal-content">
        <div>
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('voice.voip_call_log') }}</h5>
            <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close-btn">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div style="max-height: 700px; overflow-y: auto">
            <table class="table">
              <thead>
                <tr>
                  <th>{{ $t('voice.voip_from') }}</th>
                  <th>{{ $t('voice.voip_to') }}</th>
                  <th>{{ $t('voice.voip_direction') }}</th>
                  <th>{{ $t('voice.voip_duration') }}</th>
                  <th>{{ $t('voice.voip_date') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="call in calls" :key="call.id">
                  <td>{{ call.from }}</td>
                  <td>{{ call.to }}</td>
                  <td>{{ call.direction }}</td>
                  <td>{{ call.duration }}s</td>
                  <td>{{ call.created_at }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="6" class="text-muted text-center">
                    <br />
                    <a v-show="hasMore" class="text-muted" @click="loadMore">{{ $t('voice.voip_load_more') }}</a>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  data() {
    return {
      page: 0,
      hasMore: false,
      calls: [],
    };
  },

  mounted() {
    this.loadMore();
  },

  methods: {
    loadMore() {
      this.page++;
      axios.get('/api/v2/voip/calls?channel_id=' + this.$route.params.id + '&page=' + this.page).then((res) => {
        this.calls.push(...res.data.data);
        this.hasMore = res.data.links.next !== null;
      });
    },
  },
};
</script>
