import { request } from '@/util/request';

import { ENDPOINT } from '../constants';

import type { Links, Meta, Team } from '@/types';
import type { AxiosPromise } from 'axios';

type TeamsResponse = {
  data: Team[];
  links: Links;
  meta: Meta;
};

export function fetchTeams(): AxiosPromise<TeamsResponse> {
  return request(ENDPOINT.TEAMS, 'GET');
}
