import type { AccordionItems as AccordionItemsType } from './types';
import type { TranslationFunction } from '@/types';

const AccordionItems = (
  t: TranslationFunction
): { email: AccordionItemsType[]; whatsapp: AccordionItemsType[]; other: AccordionItemsType[] } => {
  return {
    email: [
      {
        channel: 'gmail',
        title: t('onboarding.channels_items_gmail'),
        sub_title: t('onboarding.channels_items_gmail_subtitle'),
      },
      {
        channel: 'outlook',
        title: t('onboarding.channels_items_outlook'),
        sub_title: t('onboarding.channels_items_outlook_subtitle'),
      },
      {
        channel: 'another_provider',
        title: t('onboarding.channels_items_another_provider'),
        sub_title: t('onboarding.channels_items_another_provider_subtitle'),
      },
    ],
    whatsapp: [
      {
        channel: null,
        title: t('onboarding.channels_items_experience_whatsapp'),
        sub_title: t('onboarding.channels_items_experience_whatsapp_subtitle'),
        meta: 'whatsapp_sandbox',
      },
      {
        channel: null,
        title: t('onboarding.channels_items_connect_whatsapp'),
        sub_title: t('onboarding.channels_items_connect_whatsapp_subtitle'),
        meta: 'whatsapp_number',
      },
    ],
    other: [
      {
        channel: 'facebook',
        title: t('onboarding.channels_items_facebook'),
        sub_title: t('onboarding.channels_items_facebook_subtitle'),
      },
      {
        channel: 'instagram',
        title: t('onboarding.channels_items_instagram'),
        sub_title: t('onboarding.channels_items_instagram_subtitle'),
      },
      {
        channel: 'livechat',
        title: t('onboarding.channels_items_livechat'),
        sub_title: t('onboarding.channels_items_livechat_subtitle'),
      },
    ],
  };
};

export default AccordionItems;
