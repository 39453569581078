import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "relative flex h-fit flex-col rounded-lg bg-white p-4 shadow-md",
  "data-test": "sidebar-attach-profile-wrapper"
}
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "t-text-md-emphasize mx-auto" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiselect = _resolveComponent("multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$tc('tickets.attach_profile')), 1 /* TEXT */),
      _createVNode(_component_multiselect, {
        ref: "multiselect",
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
        label: "name",
        "select-label": "",
        "deselect-label": "",
        placeholder: _ctx.$t('tickets.search_profile'),
        "allow-empty": false,
        "track-by": "id",
        "open-direction": "bottom",
        "show-no-results": false,
        "show-no-options": false,
        options: _ctx.profiles,
        searchable: true,
        loading: _ctx.isLoading,
        "internal-search": false,
        "options-limit": 100,
        limit: 1,
        "clear-on-select": true,
        "close-on-select": true,
        "max-heigth": 400,
        "data-test": "profile-multiselect",
        onSearchChange: _ctx.findProfiles,
        onSelect: _ctx.select
      }, {
        option: _withCtx((props) => [
          (props.option.id > 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(props.option.name), 1 /* TEXT */))
            : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$tc('tickets.btn_create_profile')) + " \"" + _toDisplayString(props.option.name) + "\"", 1 /* TEXT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "placeholder", "options", "loading", "onSearchChange", "onSelect"])
    ])
  ]))
}