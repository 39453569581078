import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full w-full" }
const _hoisted_2 = { class: "ml-8" }
const _hoisted_3 = { class: "-mt-20 flex h-full w-full flex-col items-center justify-center" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "t-text-h3 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Lightning_linear = _resolveComponent("Lightning-linear")!
  const _component_t_thumbnail = _resolveComponent("t-thumbnail")!
  const _component_main_header = _resolveComponent("main-header")!
  const _component_arrow_left_linear = _resolveComponent("arrow-left-linear")!
  const _component_t_button = _resolveComponent("t-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_main_header, {
      "max-width": "1440px",
      title: _ctx.$t('reports.dashboard')
    }, {
      left: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_t_thumbnail, { class: "bg-white" }, {
            default: _withCtx(() => [
              _createVNode(_component_Lightning_linear, { size: "1rem" })
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title"]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        src: `${_ctx.assetsUrl}img/reporting/desktop.svg`,
        class: "mb-6"
      }, null, 8 /* PROPS */, _hoisted_4),
      _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('reports.dashboard_mobile')), 1 /* TEXT */),
      _createVNode(_component_t_button, {
        "btn-style": "secondary",
        class: "mt-2 flex items-center justify-center",
        onClick: _withModifiers(_ctx.goToPreviousRoute, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_arrow_left_linear, { class: "mr-1" }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('reports.back_overview')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ])
  ]))
}