// TODO Refactor, rename to Gamification
import eventBus from '@/eventBus';
export default {
  namespaced: true,
  state: {
    enabled: ((window.AGENCY || {}).data || {}).onboarding_steps_enabled || false, // agency wide
    stepsSeen: null, // refactor: stepsSeen should come from userConfig store
    steps: [],
    interests: [
      {
        name: 'whatsapp',
        icon: 'fa-whatsapp',
        background: 'linear-gradient(45deg, #c7db60 0%, #93cb8d 100%);',
        url: '/admin/channels2/wa_business',
        selected: false,
      },
      {
        name: 'chat',
        icon: 'fa-comments-o',
        background: 'linear-gradient(45deg, #02c2eb 0%, #4135c0 100%);',
        url: '/admin/channels2/chat',
        selected: false,
      },
      {
        name: 'email',
        icon: 'fa-envelope-o',
        background: 'linear-gradient(45deg, #f3155c 0%, #910024 100%);',
        url: '/admin/channels2/email',
        selected: false,
      },
      {
        name: 'voice',
        icon: 'fa-phone',
        background: 'linear-gradient(45deg, #1ed0e5 0%, #02829c 100%);',
        url: '/admin/channels2/voip',
        selected: false,
      },
      {
        name: 'messaging',
        icon: 'fa-instagram',
        background: 'linear-gradient(45deg, #fd841f 0%, #ba5902 100%);',
        url: '/admin/channels2/facebook',
        selected: false,
      },
      {
        name: 'help_center',
        icon: 'fa-question-circle-o',
        background: 'linear-gradient(45deg, #b615b4 0%, #710279 100%);',
        url: '/help_center',
        selected: false,
      },
      {
        name: 'chat_api',
        icon: 'fa-code',
        background: 'linear-gradient(45deg, #6be9b6 0%, #32c088 100%);',
        url: '/admin/developers/rest',
        selected: false,
      },
      {
        name: 'flowbot',
        icon: 'fa-sitemap',
        background: 'linear-gradient(45deg, #000000 0%, #4f4f4f 100%);',
        url: '/admin/flows',
        selected: false,
      },
    ],
    chosenInterests: [],
  },
  getters: {
    completedPercentage(state, getters) {
      const total = state.steps.length;
      const complete = state.steps.filter((step) => step.completed).length;
      return Math.floor((complete / total) * 100) || 0;
    },
    selectedInterests(state, getters) {
      return state.interests.filter((interest) => interest.selected);
    },
    selectedChosenInterestsLength(state) {
      return state.chosenInterests.length;
    },
    stepByType: (state, getters) => (type) => {
      return state.steps.find((step) => step.type === type);
    },
    unseenCompleted(state, getters) {
      if (!state.steps.length || !state.stepsSeen) {
        return [];
      }

      return state.steps.filter((step) => step.completed && !state.stepsSeen.includes(step.type));
    },
    interestByName: (state, getters) => (name) => {
      return state.interests.find((interest) => interest.name === name);
    },
  },
  mutations: {
    setSteps(state, data) {
      state.steps = data;
      eventBus.$emit('onboarding-steps.set');
    },
    setEnabled(state, data) {
      state.enabled = !!data;
    },
    setStepsSeen(state, data) {
      state.stepsSeen = data;
    },
    setInterestsSelected(state, data) {
      state.interests.forEach((interest) => {
        data.includes(interest.name) && state.chosenInterests.push(interest); // set selected interests
        interest.selected = data.includes(interest.name);
      });
    },
    completeStep(state, { type }) {
      const step = state.steps.find((step) => step.type === type);
      if (!step) {
        return;
      }

      step.completed = true;
      eventBus.$emit('onboarding-steps.set');
    },
  },
  actions: {
    fetchSteps({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v2/onboarding_steps')
          .then((r) => {
            commit('setSteps', r.data || []);
            resolve(r.data);
          })
          .catch((e) => reject(e));
      });
    },
    fetchInterestsSelected({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v2/onboarding_steps/interests')
          .then((r) => {
            commit('setInterestsSelected', r.data || []);
            resolve();
          })
          .catch((e) => reject(e));
      });
    },
    fetchStepsSeen({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v2/user_config/onboarding_steps.steps_seen')
          .then((r) => {
            commit('setStepsSeen', r.data || []);
            eventBus.$emit('onboarding-steps-seen.fetched');
            resolve(r.data);
          })
          .catch((e) => reject(e));
      });
    },
    /*fetchEnabled({ commit, state}) {
            return new Promise((resolve, reject) => {
                axios.get('/api/v2/user_config/onboarding_steps.enabled').then(r => {
                    commit('setEnabled', !!r.data);
                    resolve(r.data);
                }).catch(e => reject(e));
            })
        },*/
    putInterestsSelected({ commit, getters }, items) {
      return new Promise((resolve, reject) => {
        let prevItems = getters.selectedInterests.map((item) => item.name);
        // there is no need to send the same data and override the previous one
        // first interests are most important
        if (getters.selectedChosenInterestsLength === 0) {
          commit('setInterestsSelected', items || []);

          axios
            .put('/api/v2/onboarding_steps/interests', {
              interests: items,
              socket_id: window.PusherInstance.connection.socket_id,
            })
            .then((r) => {
              resolve();
            })
            .catch((e) => {
              commit('setInterestsSelected', prevItems);
              reject(e);
            });
        }
      });
    },
    async putStepsSeen({ commit, getters, state }) {
      if (!state.stepsSeen || !state.steps.length) {
        return;
      }

      let items = [
        ...new Set([...state.stepsSeen, ...state.steps.filter((item) => item.completed).map((item) => item.type)]),
      ];

      axios.put('/api/v2/user_config/onboarding_steps.steps_seen', { value: items }).catch(() => {});

      return items;
    },
    completeStep({ commit, getters }, { type, vm, user }) {
      let step = getters.stepByType(type);
      // only if step for current user && not yet complete
      if (!step || step.completed) {
        return;
      }

      return new Promise((resolve, reject) => {
        axios
          .put('/api/v2/onboarding_steps/complete/' + type)
          .then((r) => {
            commit('completeStep', { type: type, vm: vm, user: user });
            resolve();
          })
          .catch((e) => reject(e));
      });
    },
    // for debugging
    simulateCompleteAll({ commit, getters, state }, { vm = {}, user = {} }) {
      state.steps.forEach((step) => {
        commit('completeStep', { type: step.type, vm: vm, user: user });
      });
    },
  },
};
