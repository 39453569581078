<template>
  <div :class="wrapperClasses">
    <message-box
      v-for="(message, index) in messagesData"
      :key="index"
      :class="String(message.type) === messageTypes.OUTGOING ? 'outgoing' : 'incoming'"
      :message="message.message"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import { MessageTypes } from '../../constants';
import MessageBox from '../MessageBox';

import type { Message } from '../../constants';
import type { PropType } from 'vue';

type Messages = {
  messagesData: Message[];
  messageTypes: typeof MessageTypes;
};

export default defineComponent({
  name: 'Messages',
  components: { MessageBox },
  props: {
    messages: {
      type: [] as PropType<Message>,
      default: [],
    },
    wrapperClasses: {
      type: String,
      default: '',
    },
  },
  data(): Messages {
    return {
      messagesData: [],
      messageTypes: MessageTypes,
    };
  },
  mounted() {
    this.messagesData = this.messages;
  },
});
</script>
<style lang="scss" scoped>
.outgoing {
  background-color: #e1f7ca;
  @apply self-end;
}

.incoming {
  @apply bg-white xs2:ml-1 sm:ml-0;
}
</style>
