<template>
  <div
    class="
      flex flex-col
      rounded-xl
      border-1 border-grey-300
      bg-white
      xs:max-w-[150px]
      md:max-w-[217px]
      xl:max-w-[270px]
    "
  >
    <div class="flex h-max flex-col gap-3 p-4">
      <div class="flex justify-between">
        <span class="t-text-md font-semibold text-grey-800">{{ t(plan.title) }}</span>
        <div class="t-text-sm flex items-center font-semibold text-grey-600">
          5
          <user-fill class="ml-1" size="16px" />
        </div>
      </div>
      <span v-if="selectedFrequency === 'monthly'" class="text-4xl font-bold text-grey-800">
        €{{ selectedFrequencyPrice }}
      </span>
      <div v-else>
        <span class="text-4xl font-bold text-grey-800">€{{ selectedFrequencyPrice }}</span>
        <span class="text-sm font-semibold text-grey-500 line-through">€{{ monthlyPrice }}</span>
      </div>
      <div class="t-text-sm italic text-grey-600">{{ t('subscription.per_month') }}</div>
      <div class="t-text-sm text-grey-700">
        {{ plan.description && t(plan.description) }}
      </div>
    </div>
    <div class="t-text-sm h-max flex-grow border-t-1 border-grey-300 p-5 xs:leading-6 md:leading-7">
      <p class="mb-2 font-semibold text-grey-800">{{ t(plan.details.title) }}</p>
      <div v-for="(detail, i) in plan.details.detailList" :key="i" class="mb-2 flex items-center text-grey-600">
        <check-linear size="20px" class="mr-2 inline-block text-grey-900" />
        {{ typeof detail === 'string' ? t(detail) : t(detail.label) }}
        <info-fill
          v-if="typeof detail !== 'string' && detail.tooltip"
          v-tooltip="t(detail.tooltip)"
          size="19px"
          class="ml-2 inline-block shrink-0 text-grey-600"
        />
      </div>
    </div>
    <div class="align-center flex h-[80px] justify-center border-t-1 border-grey-300 p-5">
      <t-button
        type="submit"
        size="md"
        class="w-[220px]"
        :btn-style="plan.type === PLAN.TIER_2 ? 'primary' : 'secondary'"
        @click="$emit('click', plan.type, props.selectedFrequency)"
      >
        {{ t('subscription.choose_plan', { plan: plan.title }) }}
      </t-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CheckLinear, InfoFill, UserFill } from '@trengo/trengo-icons';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { PLAN } from '@/Configs/Constants';

import type { ChargebeePlan } from '@/types';
import type { PropType } from 'vue';

defineEmits(['click']);

const props = defineProps({
  plan: {
    type: Object as PropType<ChargebeePlan>,
    required: true,
  },
  selectedFrequency: {
    type: String as PropType<'monthly' | 'annually'>,
    required: true,
  },
});
const { t } = useI18n();

const selectPriceByFrequency = (frequency: 'monthly' | 'annually') => {
  return props.plan?.prices.find((item) => item.type === frequency)?.price ?? 0;
};

const monthlyPrice = computed(() => {
  return selectPriceByFrequency('monthly');
});

const selectedFrequencyPrice = computed(() => {
  return selectPriceByFrequency(props.selectedFrequency);
});
</script>
