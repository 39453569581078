<template>
  <gray-card>
    <template #default>
      <div class="mb-8 flex flex-row items-center">
        <div class="flex h-44px w-44px items-center justify-center rounded-xl bg-galaxy-600 p-2 sm:h-48px sm:w-48px">
          <img class="w-24" :src="`${$root.assetsURL}img/add-new-plugin.svg`" />
        </div>
        <div class="ml-4 flex flex-col">
          <div class="t-text-mobile-body-md sm:t-text-button-lg sm:mb-1">
            {{ $t('getting_started.title_add_an_integration') }}
          </div>
          <div class="t-text-desktop-body-xs sm:t-text-desktop-body-sm text-grey-800">
            {{ $t('getting_started.subtitle_add_an_integration') }}
          </div>
        </div>
      </div>
      <div class="mb-4 grid grid-cols-1 gap-2 sm:mb-3 lg:grid-cols-4">
        <add-integration-modal v-model="openAddIntegration" />
        <item-button @click="handleChooseIntegration('shopify')">
          <img :src="`${$root.assetsURL}img/integrations/shopify.svg`" class="h-24px w-24px" />
          <span class="t-text-button-sm">{{ $t('getting_started.title_shopify') }}</span>
        </item-button>
        <item-button @click="handleChooseIntegration('whoocommerce')">
          <img :src="`${$root.assetsURL}img/integrations/woo.svg`" class="h-24px w-24px" />
          <span class="t-text-button-sm">{{ $t('getting_started.title_woocommerce') }}</span>
        </item-button>
        <item-button @click="handleChooseIntegration('slack')">
          <img :src="`${$root.assetsURL}img/integrations/slack.svg`" class="h-24px w-24px" />
          <span class="t-text-button-sm">{{ $t('getting_started.title_slack') }}</span>
        </item-button>
        <item-button @click="handleChooseIntegration('hubspot')">
          <img :src="`${$root.assetsURL}img/integrations/hubspot.svg`" class="h-24px w-24px" />
          <span class="t-text-button-sm">{{ $t('getting_started.title_hubspot') }}</span>
        </item-button>
      </div>
      <div>
        <span class="t-text-button-md cursor-pointer text-leaf-500 hover:underline" @click="openAddIntegration = true">
          {{ $t('getting_started.button_see_all') }}
        </span>
      </div>
    </template>
  </gray-card>
</template>

<script>
import eventBus from '@/eventBus';

import AddIntegrationModal from './AddIntegrationModal';
import GrayCard from '../GrayCard';
import ItemButton from '../ModalItem/ItemButton';
export default {
  name: 'AddIntegration',
  components: { AddIntegrationModal, ItemButton, GrayCard },
  data() {
    return {
      openAddIntegration: false,
    };
  },
  mounted() {
    eventBus.$on('choose-integration', this.handleChooseIntegration);
  },
  methods: {
    handleChooseIntegration(type) {
      const pluginURL = this.generateRedirectURL(type);
      eventBus.$emit('close-overview-modal');

      eventBus.$off('choose-integration', this.handleChooseIntegration);
      setTimeout(() => {
        this.$router.push(pluginURL);
        eventBus.$off('close-overview-modal');
      }, 200);
    },
    generateRedirectURL(type) {
      switch (type) {
        case 'zapier':
          return '/admin/developers/zapier';
        case 'bol.com':
          return '/admin/developers/plugins/create/bol';
        case 'shopify':
          return '/admin/developers/plugins/create/shopify2';
        case 'twilio':
          return '/admin/channels2/voip/create?provider=TWILIO';
        default:
          return '/admin/developers/plugins/create/' + type;
      }
    },
    closeModal() {
      this.openAddIntegration = false;
    },
  },
};
</script>
