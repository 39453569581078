import vue from 'vue';

import { fetchUserById } from '@/api';
import UserModel from '@/components/InternalChat/Models/UserModel';
import { getBotUser, getDeletedUser } from '@/components/InternalChat/Util/Chat';

/**
 * @deprecated reserved for `InternalChat` only, for other cases use `users` module
 */
export default {
  namespaced: true,
  state: {
    users: [],
    currentUserId: null,
  },
  mutations: {
    setUsers(state, users) {
      state.users = users;
    },
    async updateUser(state, { id, user }) {
      let userObject = user;
      let index = state.users.findIndex((u) => u.id === parseInt(id));
      if (index < 0) {
        const fetchUser = await fetchUserById(id);
        const getUser = fetchUser.data;
        userObject = getUser;
      }
      const newUser = new UserModel(userObject);
      state.users[index] = newUser;
      return newUser;
    },
    addUser(state, user) {
      let newUser = new UserModel(user);
      state.users.push(newUser);

      return newUser;
    },
    removeUser(state, id) {
      let index = state.users.findIndex((u) => u.id === id);
      if (index < 0) {
        return false;
      }

      state.users.splice(index, 1);
    },
    setCurrentUserId(state, id) {
      state.currentUserId = id;
    },
  },
  getters: {
    userById: (state) => (id) => {
      id = parseInt(id);

      let user = state.users.find((u) => u.id === id);

      if (user) {
        return user;
      }

      if (id && !user) {
        return getDeletedUser(id);
      }

      if (!id && !user) {
        return getBotUser();
      }
    },
    currentUser: (state, getters) => {
      return getters.userById(state.currentUserId);
    },
  },
  actions: {
    async setCurrentUser({ getters, commit }, user) {
      await commit('updateUser', { id: user.id, user: user });
      commit('setCurrentUserId', user.id);
    },
    setUsers({ getters, commit }, users) {
      let usersMapped = (users || []).map((u) => new UserModel(u));
      commit('setUsers', usersMapped);
    },
  },
};
