import { request } from '@/util';

import type { OnlineStatusResponse } from './types';
import type { AxiosPromise } from 'axios';

const baseUrl = '/api/v2/reporting/';

const endpoints = {
  getReportsList: `${baseUrl}list`,
  pages: {
    overview: {
      aggregates: `${baseUrl}overview/aggregates`,
      users: `${baseUrl}users?is_online=1&take=8&random=1`,
    },
  },
} as const;

export type ReportsList = {
  NEW_TICKETS: string;
  CLOSED_TICKETS: string;
  CHANNEL_TYPE: string;
  FIRST_REPLY_TIME: string;
  RESOLUTION_TIME: string;
};

export type ReportsListResponse = {
  data: ReportsList;
};

export function getReportsList(): AxiosPromise<ReportsList> {
  return request(endpoints.getReportsList, 'GET');
}

export const fetchUsers = (): Promise<OnlineStatusResponse> => {
  return request(endpoints.pages.overview.users, 'GET');
};

export function callMockData<T>(data: T): Promise<T> {
  return Promise.resolve(data);
}

export function setVoipStatus(status: string, id: number) {
  request(`/auth/set-voip-status/${status.toUpperCase()}?user_id=${id}`, 'GET');
}

export function setChatStatus(status: string, id: number) {
  request(`/auth/set-status/${status.toUpperCase()}?user_id=${id}`, 'GET');
}
