import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-623c078f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 0,
  class: "ml-auto flex items-center justify-center p-0"
}
const _hoisted_3 = {
  key: 1,
  class: "ml-auto flex w-fit flex-shrink-0 items-center"
}
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filetype_icon = _resolveComponent("filetype-icon")!
  const _component_t_spinner = _resolveComponent("t-spinner")!
  const _component_cross_linear = _resolveComponent("cross-linear")!
  const _component_import_linear = _resolveComponent("import-linear")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["internal-comment-attachment__wrapper", { outbound: $props.isOutbound }]),
    "data-test": "messageAttachmentWrapper"
  }, [
    _createVNode(_component_filetype_icon, {
      extension: $props.attachment.extension,
      class: "mr-2 flex items-center"
    }, null, 8 /* PROPS */, ["extension"]),
    _createElementVNode("a", {
      href: _ctx.safeUrl($props.attachment.full_url),
      class: "my-0 cursor-pointer text-ellipsis hover:underline",
      target: "_blank",
      rel: "noopener",
      "data-test": "messageAttachmentFileUrl",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.openInPopup($props.attachment, $event)))
    }, _toDisplayString($options.fileName), 9 /* TEXT, PROPS */, _hoisted_1),
    ($props.isDeletable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createVNode(_component_t_spinner, { size: "1.25rem" }, null, 512 /* NEED_PATCH */), [
            [_vShow, $props.attachment.uploading]
          ]),
          _withDirectives(_createElementVNode("button", {
            "data-test": "deleteAttachmentButton",
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($options.sendDelete && $options.sendDelete(...args)), ["prevent"]))
          }, [
            _createVNode(_component_cross_linear, {
              class: "delete-icon",
              size: "1.5rem"
            })
          ], 512 /* NEED_PATCH */), [
            [_vShow, !$props.attachment.uploading]
          ])
        ]))
      : _createCommentVNode("v-if", true),
    ($props.hasDownloadButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("button", {
            "data-test": "downloadAttachmentButton",
            class: "ml-3 rounded-md p-2 transition-colors hover:bg-grey-200 disabled:bg-transparent",
            disabled: $data.isDownloading,
            onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => ($options.initiateDownload && $options.initiateDownload(...args)), ["prevent"]))
          }, [
            (!$data.isDownloading)
              ? (_openBlock(), _createBlock(_component_import_linear, {
                  key: 0,
                  size: "1.2rem"
                }))
              : (_openBlock(), _createBlock(_component_t_spinner, {
                  key: 1,
                  class: "m-auto",
                  size: "1.2rem"
                }))
          ], 8 /* PROPS */, _hoisted_4)
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}