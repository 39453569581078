<template>
  <div id="SendAsTicketModal" ref="modal" class="modal" role="dialog">
    <div class="modal-dialog" role="document">
      <div v-if="open" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('tickets.change_sender') }}</h5>
          <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <!--<p class="ml-1">{{ $t('tickets.change_sender_intro') }}</p>-->
          <div class="form-group">
            <label class="mb-2 ml-1">{{ $t('tickets.select_sender') }}</label>
            <dropdown v-if="filteredChannels" v-model="channel" :options="filteredChannels" :visible-items="5">
              <template #toggle="prop">
                <div
                  v-if="prop.label"
                  class="
                    flex
                    w-full
                    min-w-0
                    select-none
                    flex-nowrap
                    items-center
                    text-ellipsis
                    rounded-lg
                    border-2 border-grey-200
                    p-3
                    leading-none leading-none
                    text-grey-600
                    hover:text-grey-800
                  "
                >
                  <div class="flex items-center">
                    <i v-if="prop.selected.is_private" class="fa fa-lock mr-2 text-grey-500"></i>
                    <span class="text-ellipsis">{{ prop.label }}</span>
                  </div>
                </div>
              </template>
              <template #option="prop">
                <div class="flex items-center">
                  <i v-if="prop.option.is_private" class="fa fa-lock mr-2 text-grey-500"></i>
                  <span class="text-ellipsis">{{ prop.option.display_name }}</span>
                </div>
              </template>
            </dropdown>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn green" @click="setSender()">{{ $t('tickets.set_sender') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import eventBus from '@/eventBus';

import Dropdown from './ReplyForm/Dropdown.vue';

export default {
  name: 'SendAsTicketModal',
  components: {
    Dropdown,
  },
  data: function () {
    return {
      ticket: {},
      channel: null,
      open: false,
    };
  },
  computed: {
    filteredChannels() {
      return this.$root.channels.filter((c) => {
        return c.type === 'EMAIL' && c.is_private === this.ticket.channel.is_private;
      });
    },
  },

  mounted: function () {
    eventBus.$off('modals.send-as.open');
    eventBus.$on('modals.send-as.open', (data) => {
      this.ticket = data.ticket;

      if (this.ticket) {
        let c = this.$root.channels.filter((c) => {
          return c.id == this.ticket.channel.id;
        })[0];
        this.channel = {
          id: this.ticket.channel.id,
          type: this.ticket.channel.type,
          text: typeof c !== 'undefined' ? c.text : '',
        };

        this.openModal();
      }
    });
  },
  methods: {
    openModal() {
      this.open = true;
      $(this.$refs.modal).modal({
        keyboard: false,
      });
    },

    closeModal() {
      this.open = false;
      $(this.$refs.modal).modal('hide');
    },

    setSender() {
      axios.put('/api/v2/tickets/' + this.ticket.id, { channel_id: this.channel.id }).then((res) => {
        this.ticket.channel = this.channel;

        eventBus.$emit('ticket.current.reload');
        eventBus.$emit('updateTicketChannelInComposer', this.channel);

        this.closeModal();
      });
    },
  },
};
</script>
