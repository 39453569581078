<template>
  <t-modal :model-value="isOpen" variant="narrow" :title="$tc('settings.ticket_result')" @close="emit('close')">
    <label for="ticketResults">{{ $tc('tickets.select_ticket_result') }}</label>
    <select id="ticketResults" v-model="resultId" name="ticketResults" class="form-control">
      <option :value="-1" selected>{{ '-- ' + $tc('tickets.select_ticket_result') + ' --' }}</option>
      <option v-for="result in resultList" :key="result.value" :value="result.value">
        {{ result.text }}
      </option>
    </select>

    <template #footer>
      <t-button btn-style="secondary" @click.prevent="emit('close')">
        {{ $tc('general.cancel') }}
      </t-button>

      <t-button :disabled="resultId === -1" @click.prevent="emit('select-result', resultId)">
        {{ $tc('tickets.save_ticket_result') }}
      </t-button>
    </template>
  </t-modal>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';

import { vm } from '@/ApplicationHandler';

defineProps<{ isOpen: boolean }>();

const emit = defineEmits<{
  (e: 'select-result', resultId: number): void;
  (e: 'close'): void;
}>();

const resultId = ref(-1);
const resultList = computed(() => {
  return (
    vm?.$root?.ticketResults
      .sort((result) => result.sort_order)
      .map((result) => ({ text: result.name, value: result.id })) || []
  );
});
</script>
