<template>
  <div class="inline-block">
    <div v-if="!$slots.default">
      <div :class="indicatorColorClass" class="flex-no-shrink h-2 w-2 rounded-full" />
    </div>
    <div v-else :class="textColorsClasses" class="flex items-center rounded-full px-1">
      <div :class="indicatorColorClass" class="flex-no-shrink ml-2 mr-1 h-2 w-2 rounded-full" />
      <div class="pr-2">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusIndicator',
  props: {
    type: {
      type: String,
      default: 'warning',
    },
  },
  computed: {
    indicatorColorClass() {
      switch (this.type) {
        case 'success':
          return 'bg-success-500';
        case 'warning':
          return 'bg-sun-900';
        case 'danger':
          return 'bg-error-500';
      }
    },
    textColorsClasses() {
      switch (this.type) {
        case 'success':
          return 'text-success-500';
        case 'warning':
          return 'text-sun-900 bg-sun-50';
        case 'danger':
          return 'text-error-500 bg-error-100';
      }
    },
  },
};
</script>

<style scoped></style>
