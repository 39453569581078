import eventBus from '@/eventBus';
import store from '@/store';

import dnd from '../util/doNotDistburb';

let notificationAudios = {};
const notificationSoundPrefix = '/sound/channel_notification/'; // TODO: AWS

export default {
  methods: {
    notifyNew: function (contact, message, subject = null, channel_id = null, url = null) {
      eventBus.$emit('toast', {
        url: url,
        message: message,
        subject: subject,
        channel: this.$root.channels.find((c) => c.id === channel_id),
        from: contact,
      });
    },

    /**
     *
     * @param from
     * @param message
     * @param subject
     * @param channel_id
     * @param url
     * @param notification_id
     * @param ignoreMute True to ignore whether channel is muted (e.g. when user is a "Watcher" of the ticket.)
     */
    notify: function (
      from,
      message,
      subject = null,
      channel_id = null,
      url = null,
      notification_id = null,
      ignoreMute = false
    ) {
      if (dnd.isDnd(this.doNotDisturb)) {
        return;
      }

      if (this.$root?.onboarding) {
        return;
      }

      // if muted
      if (store.getters['userConfig/get']('channels.muted').includes(channel_id) && !ignoreMute) {
        return;
      }

      if (!document.hasFocus()) {
        if (window.isElectron && electronApiBridge) {
          electronApiBridge.notify(1);
        }
      }

      if (window.App.anonymousModule) {
        subject = 'Anoniem';
      }

      let channel = this.$root.channels.find((c) => c.id === channel_id);

      if ('Notification' in window) {
        if (Notification.permission === 'granted') {
          if (!document.hasFocus()) {
            let notification = new Notification(subject || 'Trengo', {
              body: message.length > 30 ? message.substr(0, 30) + '..' : message,
              icon: Assets_path + 'build/img/trengo.png',
            });
            setTimeout(() => {
              notification.close();
            }, 25000);
            notification.onclick = function (event) {
              if (notification_id != null) {
                eventBus.$emit('notification.clicked', notification_id);
              }
              if (url != null) {
                if (url.startsWith('event:')) {
                  var p = url.replace('event:', '').split(',');
                  eventBus.$emit(p[0], p[1]);
                } else {
                  vue_redirect(url);
                }
              }
              eventBus.$emit('notification-clicked', {});
              window.focus();
              notification.close();
              if (window.isElectron && electronApiBridge) {
                electronApiBridge.focus(1);
              }
              notification.close();
            };

            $(window).focus(() => {
              setTimeout(() => {
                notification.close();
              }, 1000);
            });
          }
        }
      }

      if (!window.isLoadedFromApp) {
        this.playSound(channel ? channel.notification_sound : null);
        eventBus.$emit('toast', {
          url: url,
          message: message,
          subject: subject,
          channel: channel,
          from: from,
        });
      }
    },

    playSound(notificationSound = null) {
      if (this.$root.user.voip_status === 'CALLING') {
        return;
      }

      if (!notificationSound) {
        return;
      }

      if (notificationAudios[notificationSound] === undefined) {
        notificationAudios[notificationSound] = new Audio(notificationSoundPrefix + notificationSound);
      }

      notificationAudios[notificationSound].play();
    },
  },
};
