import { PERMISSION, PRICING_MODEL } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';

import type { RouteRecord } from '../types';

const balanceSettingsRoutes: RouteRecord[] = [
  {
    path: '/admin/company_profile/balance',
    redirect: SETTINGS_URL.ADMIN__BALANCE_SETTINGS,
  },
  {
    path: SETTINGS_URL.ADMIN__BALANCE_SETTINGS,
    component: require('../../components/BalanceSettings/Index').default,
    meta: {
      permissionRequired: PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE,
      pricingModelRequired: PRICING_MODEL.SEAT_BASED,
    },
  },
];

export default balanceSettingsRoutes;
