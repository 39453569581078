import * as Sentry from '@sentry/vue';
import axios from 'axios';
import moment from 'moment';

import { STATUS_CODE } from '@/Configs/Constants/StatusCodes';

import { sleep } from './helpers';

import type { StatusCode } from '@/types';
import type { AxiosPromise, AxiosRequestConfig } from 'axios';

const cache: Record<string, unknown> = {};
let lastTimeCache: Date | string = '';
const invalidateCacheTimeByHours = 3;

/**
 * TODO: We should maybe discuss refactoring this to take a JSON as a param to make it more easy to extend in the future
 * for now a separate function is used (requestWithConfig) to handle this
 *
 * @param {('POST'|'GET'|'PUT'|'PATCH'|'DELETE')} method
 * @param {string} url
 * @param data
 * @param headers
 * @param params
 */
export function request<T>(
  url: string,
  method: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE',
  data: unknown = {},
  headers: unknown = {},
  params: unknown = {},
): AxiosPromise<T> {
  return axios({
    method,
    url,
    data,
    headers,
    params,
  });
}

export function requestWithConfig<T>(config: AxiosRequestConfig): AxiosPromise<T> {
  return axios(config);
}

export const retryRequestIfResponseMatchesStatusCode = async (
  config: AxiosRequestConfig,
  {
    statusCodes = [STATUS_CODE.NOT_FOUND],
    timeout = 1000,
    retries = 3,
  }: { statusCodes?: StatusCode[]; timeout?: number; retries?: number },
): Promise<unknown> => {
  try {
    const mergedConfig = {
      ...config,
      ...{
        meta: {
          // prevent error banner from popping up until the final retry
          bypassList: statusCodes,
          bypassAxiosInterceptors: retries > 0,
        },
      },
    };
    return await requestWithConfig(mergedConfig);
  } catch (error: Error | any) {
    if (error?.response?.status && statusCodes.includes(error.response.status) && retries > 0) {
      console.warn(
        `Retrying request in ${timeout} milliseconds due to matched response status code ${error.response.status}, retries left: ${retries}`,
      );

      Sentry.captureMessage(
        `Retrying request due to matched response status code ${error.response.status}, retries left: ${retries}`,
        'debug',
      );

      await sleep(timeout);
      return retryRequestIfResponseMatchesStatusCode(config, { statusCodes, timeout, retries: retries - 1 });
    }

    Sentry.captureException(error, {
      tags: {
        category: 'retryRequest',
        statusCode: error.response?.status,
      },
    });

    throw error;
  }
};

export async function cachedRequest(config: AxiosRequestConfig, cacheEnabled = true): Promise<any> {
  function generateCacheKey(config: AxiosRequestConfig) {
    return `${config.method}_${config.url}_${JSON.stringify(config.params)}`;
  }
  const cacheKey = generateCacheKey(config);
  const getCurrentTime = moment(new Date());
  const lastTimeCachedToMoment = moment(lastTimeCache);
  const duration = moment.duration(getCurrentTime.diff(lastTimeCachedToMoment));
  const lastTimeCachedDiffByHours = duration.asHours();

  if (cache[cacheKey] && cacheEnabled && lastTimeCachedDiffByHours <= invalidateCacheTimeByHours) {
    return Promise.resolve(cache[cacheKey]);
  }

  const response = await axios.request(config);
  lastTimeCache = new Date();
  cache[cacheKey] = response;
  return response;
}

export default request;
