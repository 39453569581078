<template>
  <div v-if="show">
    <web-chat-sandbox :show-buttons="false" @completed="handleCompleted" @close="handleClose" @open="handleOpen" />
    <div ref="backdrop" />
  </div>
</template>
<script lang="ts">
import WebChatSandbox from '@/components/Channels/WebChatSandbox.vue';

export default {
  name: 'ChannelWebChatSandboxModal',
  emits: ['completed', 'close'],
  components: { WebChatSandbox },
  props: {
    show: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    show(value) {
      if (!value) return;
      this.$nextTick(() => {
        $('.sandbox-modal').modal();
      });
    },
  },
  methods: {
    handleCompleted(link) {
      this.$emit('completed', { link });
    },
    handleClose() {
      this.$emit('close');
      const getBackdropElement = this.$refs.backdrop.children[0];
      getBackdropElement.remove();
    },
    handleOpen() {
      this.removeModalBackgroundClassFromBodyDOMTree();
    },
    createBackdropElement() {
      const div = document.createElement('div');
      div.classList.value = 'modal-backdrop in';
      this.$refs.backdrop.appendChild(div);
    },
    removeModalBackgroundClassFromBodyDOMTree() {
      const getBodyLastDiv = document.querySelector('body > div:last-of-type') as HTMLDivElement;
      const listBodyClasses = getBodyLastDiv.classList ? Array.from(getBodyLastDiv.classList) : [];
      if (listBodyClasses.includes('modal-backdrop')) {
        getBodyLastDiv?.remove();
        this.createBackdropElement();
      }
    },
  },
};
</script>
