export default {
  namespaced: true,
  state: {
    //loaded: false,
    config: {},
  },
  getters: {
    get:
      (state, getters) =>
      (key, defaultValue = undefined) => {
        return state.config[key] !== undefined ? state.config[key] : defaultValue;
      },
  },
  mutations: {
    set(state, { key, value }) {
      return (state.config[key] = value);
    },
    init(state, config) {
      return (state.config = config);
    },
  },
  actions: {
    async put({ commit, dispatch, getters }, { key, value }) {
      let oldConfig = getters['get'](key);
      if (oldConfig === value) {
        console.warn('userConfig value seems the same as before, not updating to server.');
        return;
      }

      let result = commit('set', { key, value });
      axios.put('/api/v2/user_config/' + key, { value: value }).catch((e) => {
        console.error('Something went wrong updating userConfig');
        result = commit('set', { key, oldConfig });
      });
      return result;
    },
  },
};
