const components = [
  {
    name: 'admin-section-title',
    path: require('../components/Admin/SectionTitle.vue').default,
  },
  {
    name: 'tel-input',
    path: require('../components/TelInput.vue').default,
  },
  {
    name: 'sidebar',
    path: require('../components/Sidebar.vue').default,
  },
  {
    name: 'date-range-picker',
    path: require('../UIKit/Molecules/DateRangePicker.vue').default,
  },
  {
    name: 'atomic-dropdown-selector',
    path: require('../UIKit/Molecules/DropdownSelector.vue').default,
  },
  {
    name: 'atomic-modal',
    path: require('../UIKit/Organisms/Modal').default,
  },
  {
    name: 'atomic-multiselect',
    path: require('../UIKit/Organisms/Multiselect').default,
  },
  {
    name: 'atomic-datepicker',
    path: require('../UIKit/Organisms/DatePicker').default,
  },
  {
    name: 'atomic-dropdown',
    path: require('../UIKit/Organisms/Dropdown').default,
  },
  {
    name: 'atomic-popup',
    path: require('../UIKit/Organisms/Popup.vue').default,
  },
];

export default components;
