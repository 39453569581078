import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { fetchUser } from '@/api';
import { ADMIN_ROLES } from '@/Configs/Constants/UserRoles';
import { isTestEnv } from '@/util/jest';

import type { Permission, User } from '@/types';

export const useUserStore = defineStore('user', () => {
  const isPending = ref(false);
  const isInitialized = ref(false);
  const user = ref<User | null>(null);
  const hasAdminRole = computed(() => user.value && ADMIN_ROLES.includes(user.value.role?.name));
  const isPrimaryUser = computed(() => user.value && Boolean(user.value.is_primary));
  const role = computed(() => user.value?.role?.name);

  const init = async () => {
    if (isInitialized.value) return;
    await loadUser();
    isInitialized.value = true;
  };

  const loadUser = async () => {
    isPending.value = true;
    try {
      const { data } = await fetchUser();
      user.value = data;
    } catch (err) {
      console.error(err);
    } finally {
      isPending.value = false;
    }
  };

  const hasPermission = (permission: Permission): boolean => {
    if (!isInitialized.value) {
      if (!isTestEnv()) {
        console.error('useUserStore().hasPermission called before store initialization.');
      }
      return false;
    }
    return user.value?.permissions?.includes(permission) ?? false;
  };

  const hasAnyPermission = (permissions: Permission[]): boolean => {
    if (!isInitialized.value) {
      if (!isTestEnv()) {
        console.error('useUserStore().hasPermission called before store initialization.');
      }
      return false;
    }
    return permissions?.some((permission) => hasPermission(permission));
  };

  const updateUser = async (newUserData: User) => {
    user.value = newUserData;
  };

  return {
    isInitialized,
    isPending,
    user,
    init,
    reload: loadUser,
    hasAdminRole,
    hasAnyPermission,
    hasPermission,
    isPrimaryUser,
    role,
    updateUser,
  };
});
