<template>
  <div ref="modal" data-test="connect-whatsapp" class="overflow-auto">
    <div v-if="!isMobile" data-test="qr_code">
      <p class="t-text-md mb-0 text-grey-700">{{ $t('whatsapp.scan_qr_code') }}</p>

      <div class="relative my-6 flex h-48 w-full items-center justify-center">
        <div v-if="qrCodeUrl" class="relative flex h-48 w-full items-center justify-center">
          <t-logo class="absolute h-16 w-16 bg-white px-2 py-3" :variant="'glyph'" />
          <canvas ref="qr" class="border-gray-400 rounded-lg border p-2" />
        </div>
      </div>
    </div>

    <div v-if="isMobile" data-test="open_whatsapp_button">
      <p class="t-text-md mb-0 text-grey-700" v-html="$t('whatsapp.click_whatsapp_button')"></p>

      <t-button
        :href="qrCodeUrl"
        btn-style="secondary"
        class="open-external my-4 flex w-full items-center justify-center"
      >
        <img :src="getWhatsAppLogoUrl()" class="mr-1" alt="trengo logo" />
        {{ $t('whatsapp.open_whatsapp_and_connect') }}
      </t-button>
    </div>

    <t-divider :text="$t('whatsapp.or_connect_phone')" />

    <t-phone-input
      v-model="phone"
      data-test="mobile-number"
      :placeholder="$t('general.custom_input_list_item_search_country')"
      :error-message="$t('onboarding.channels_step1_mobile_number_error')"
      default-country-code="+31"
      :error="!isPhoneNumberValid"
      class="mb-4 mt-6"
      @is-valid="isValid"
      @change="onPhoneChange"
    />
  </div>
</template>
<script lang="ts">
import QRCode from 'qrcode';
import { defineComponent } from 'vue';

import eventBus from '@/eventBus';
import breakpoints from '@/util/breakpoints';

import { postWhatsAppSandbox, getConnectionUrl } from '../../api';
import { Steps } from '../../constants';

import type { ComponentPublicInstance } from 'vue';

type ConnectWhatsapp = {
  phone: string;
  isPhoneNumberValid: boolean;
  root: ComponentPublicInstance;
  qrCodeUrl: string;
  verificationCode: string;
  messages: Message[];
  ticket_id: string;
};

type Message = {
  outbound: boolean;
  message: string;
};

export default defineComponent({
  name: 'ConnectWhatsapp',
  data(): ConnectWhatsapp {
    return {
      phone: '',
      isPhoneNumberValid: true,
      root: this.$root,
      qrCodeUrl: '',
      verificationCode: '',
      messages: [],
      ticket_id: '',
    };
  },
  computed: {
    isMobile() {
      return !breakpoints.comparisons.gt_sm;
    },
  },
  created() {
    eventBus.$on('submit-connect-whatsapp', this.submit);
  },
  mounted() {
    this.bindAgencyChannel();
    this.generateQrCode();
  },
  unmounted() {
    eventBus.$off('submit-connect-whatsapp', this.submit);
    this.$root.agencyChannel.unbind('SandboxMessage');
  },
  watch: {
    isMobile(newValue) {
      if (!newValue) {
        this.generateQrCode();
      }
    },
  },
  methods: {
    isValid(value, countryCode) {
      const getPhoneWithoutCountryCode = this.phone.replace(countryCode, '');
      this.isPhoneNumberValid = value;
      if (getPhoneWithoutCountryCode.length === 0) {
        this.isPhoneNumberValid = true;
        eventBus.$emit('validate-phone', false);
        return;
      }
      if (getPhoneWithoutCountryCode.length <= 5) return false;
      eventBus.$emit('validate-phone', value);
    },
    submit() {
      postWhatsAppSandbox({ phone: this.phone })
        .then((res) => {
          if (res.data.error) {
            this.isPhoneNumberValid = false;
          } else {
            eventBus.$emit('go-to-step', Steps.VALIDATING_CONNECTION);
            this.messages.push({
              outbound: true,
              message: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createQRCode() {
      if (!this.qrCodeUrl || !this.$refs.qr) return;
      QRCode.toCanvas(this.$refs.qr, this.qrCodeUrl, {
        width: 192,
        margin: 0,
        errorCorrectionLevel: 'H',
      });
    },
    getWhatsAppLogoUrl() {
      return `${this.$root.assetsURL}img/whatsapp.svg`;
    },
    onPhoneChange(value) {
      if (value.length === 0) {
        this.isPhoneNumberValid = true;
        eventBus.$emit('validate-phone', false);
      }
    },
    bindAgencyChannel() {
      this.$root.agencyChannel?.bind('SandboxMessage', (data) => this.onReply(data));
    },
    generateQrCode() {
      getConnectionUrl()
        .then((res) => {
          this.qrCodeUrl = res.data.url;
          this.verificationCode = res.data.verification_code;
        })
        .catch((err) => console.error(err))
        .finally(() => this.createQRCode());
    },
    onReply(data) {
      if (data.message.verification_code !== this.verificationCode) {
        return;
      }
      const replyData = {
        ticketId: data.message.ticket_id,
        nextStep: Steps.ESTABLISHING_CONNECTION,
      };

      eventBus.$emit('on-reply', replyData);
    },
  },
});
</script>
