<template>
  <t-modal
    v-model="computedValue"
    class="onboarding_step_three"
    :footer-border="false"
    :close-on-backdrop-click="false"
    :close-on-esc="false"
    :is-closable="false"
  >
    <template #default>
      <div class="step-three">
        <div class="step-three__top">
          <img :src="`${$root.assetsURL}img/trengo-logo.png`" class="step-three__top-logo" alt="" />
        </div>

        <div class="step-three__content-wrapper">
          <t-thumbnail class="step-three__content-wrapper-thumbnail" size="large">
            <email-linear />
          </t-thumbnail>
          <span class="step-three__content-wrapper-title" data-test="title">
            {{ $t('onboarding.verify_your_email_title') }}
          </span>
          <div class="step-three__content-wrapper-info-wrapper">
            <p class="step-three__content-wrapper-info-wrapper-character-message">
              {{ $t('onboarding.verify_your_email_subtitle') }}
              <strong class="step-three__content-wrapper-info-wrapper-character-message-email">
                &nbsp;{{ $root.user.email }}
              </strong>
            </p>

            <p class="step-three__content-wrapper-info-wrapper-expire-message">
              {{ $t('onboarding.verify_your_email_code_expiration') }}
            </p>
          </div>

          <t-pin-input
            ref="pinInputRef"
            v-model="code"
            :size="6"
            :error-message="errorMessage"
            :disabled="isVerifying"
            autofocus
            class="step-three__content-wrapper-pin-input"
            @complete="verify"
            @input="storeValue"
          />

          <t-button
            btn-style="secondary"
            class="step-three__content-wrapper-resend-button"
            :disabled="resendDisabled"
            @click="resend"
          >
            <rotate-ccw-linear size="16.5px" />
            <span class="step-three__content-wrapper-resend-button-counter">
              {{ $t('onboarding.verify_your_email_resend') }}
              <span v-if="resendDisabled">
                ({{ timerText }} {{ $t('onboarding.verify_your_email_resend_seconds') }})
              </span>
            </span>
          </t-button>

          <p v-if="resent" class="step-three__content-wrapper-verification-sent">
            <check-circle-linear size="15px" />
            {{ $t('onboarding.verify_your_email_resend_email_sent') }}
          </p>

          <span v-show="isVerifying" class="mt-4 flex flex-row items-center">
            <t-spinner />
            <p class="t-text-md-emphasize mb-0 ml-3 text-grey-600">{{ $t('onboarding.checking_code') }}</p>
          </span>
        </div>

        <div class="step-three__bottom">
          <div class="step-three__bottom-action-buttons">
            <t-button-text btn-style="secondary">
              <a target="_blank" href="https://mail.google.com" rel="noopener" class="open-external">
                <img :src="`${$root.assetsURL}img/settings-landing/gbm.svg`" />
                {{ $t('onboarding.verify_your_email_open_gmail') }}
              </a>
            </t-button-text>

            <t-button-text btn-style="secondary">
              <a target="_blank" href="https://outlook.office365.com/mail/inbox" rel="noopener" class="open-external">
                <img :src="`${$root.assetsURL}img/settings-landing/outlook.svg`" />
                {{ $t('onboarding.verify_your_email_open_outlook') }}
              </a>
            </t-button-text>
          </div>

          <span class="step-three__bottom-spam-message">{{ $t('onboarding.verify_your_email_cant_find_code') }}</span>
          <span class="step-three__bottom-not-there-message">
            {{ $t('onboarding.verify_your_email_not_there') }}
            <a target="_blank" href="https://trengo.com/en/contact" rel="noopener" class="open-external">
              {{ $t('onboarding.verify_your_email_open_contact_support') }}
            </a>
          </span>
        </div>
      </div>
    </template>
  </t-modal>
</template>

<script>
import { EmailLinear, CheckCircleLinear, RotateCcwLinear } from '@trengo/trengo-icons';

import { FEATURE_FLAG_ACTIVATION } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

export default {
  name: 'StepThree',
  emits: ['close-step-one'],
  components: {
    RotateCcwLinear,
    EmailLinear,
    CheckCircleLinear,
  },
  data() {
    return {
      code: '',
      prevCode: '',
      resent: false,
      throttled: false,
      hasVerificationError: false,
      errorMessage: '',
      openModal: true,
      resendDisabled: true,
      isVerifying: false,
      timer: 29,
    };
  },
  watch: {
    code(value) {
      if (!value && this.errorMessage) {
        this.errorMessage = '';
      }
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.openModal;
      },
      set(value) {
        this.openModal = value;
      },
    },
    timerText() {
      return this.timer < 0 ? 0 : this.timer + 1;
    },
  },
  inject: ['proceedStep', 'close'],
  mounted() {
    this.redirectToOnboardingChannel();
    this.startCountDown();
    if (this.$root.onboarding?.step < 6) {
      this.$router.push('/onboard-channels');
      return;
    }
  },
  methods: {
    storeValue(value) {
      this.code = value;
    },
    redirectToOnboardingChannel() {
      if (useFeatureFlagStore().isEnabled(FEATURE_FLAG_ACTIVATION.TA_ONBOARDING_CHANNEL_SELECTION)) {
        this.$router.push('/onboard-channels');
      } else {
        this.$router.push('/tickets');
      }
    },
    verify() {
      this.isVerifying = true;
      this.resent = false;
      this.errorMessage = '';
      this.hasVerificationError = false;
      if (this.code.length !== 6 || this.code === this.prevCode) {
        this.hasVerificationError = true;
        this.errorMessage = this.$t('onboarding.verify_your_email_incomplete_pin');
        this.isVerifying = false;
        return;
      }
      this.requestVerify();
    },
    requestVerify() {
      axios
        .post('/api/v2/signup/verify', { token: this.code })
        .then(() => {
          setTimeout(() => {
            this.completeVerification();
          }, 2400);
        })
        .catch((e) => {
          this.hasVerificationError = true;
          this.isVerifying = false;
          this.errorMessage = this.$t('onboarding.verify_your_email_incorrect_pin');
          if (e.response.status === 422) this.prevCode = this.code;
        });
    },
    completeVerification() {
      this.computedValue = false;
      this.isVerifying = false;
      this.$nextTick(() => {
        this.$emit('close-step-one');
      });
    },
    startCountDown() {
      this.resendDisabled = true;
      let intervalId;

      const countDown = () => {
        if (this.timer == -1) {
          clearInterval(intervalId);
          intervalId = null;
          this.timer = 29;
          this.resendDisabled = false;
          this.resent = false;
        } else {
          this.timer--;
        }
      };

      if (!intervalId) {
        intervalId = setInterval(countDown, 1000);
      }
    },
    resetPinInput() {
      this.$refs.pinInputRef.reset();
      this.code = '';
    },
    resend() {
      this.startCountDown();
      this.resetPinInput();
      this.errorMessage = '';
      this.hasVerificationError = false;
      this.resent = true;
      axios.post('/api/v2/signup/resend').catch((e) => {
        if (e.response.status === 429 && e.response.data.retry_after) {
          this.throttled = true;
          setTimeout(() => (this.throttled = false), e.response.data.retry_after * 1000);
        }
      });
    },
  },
};
</script>

<style lang="scss" src="./onboardingStepsGlobal.scss" />
<style scoped lang="scss" src="./onboardingStepThree.scss" />
