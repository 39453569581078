import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const usersRoutes: RouteRecord[] = [
  {
    path: '/admin/users',
    component: require('../../components/Users/pages/UsersPage').default,
    meta: { permissionRequired: PERMISSION.PERMISSIONS__USERS__MANAGE },
  },
];

export default usersRoutes;
