export const CUSTOM_FIELD = {
  CHECKBOX: 'Checkbox',
  CONTACT: 'CONTACT',
  DATE: 'Date',
  DROPDOWN: 'Dropdown',
  HYPERLINK: 'Hyperlink',
  NUMBER: 'Number',
  PROFILE: 'PROFILE',
  TEXT: 'Text',
  TICKET: 'TICKET',
  USER: 'USER',
} as const;
