<template>
  <div class="block h-screen overflow-y-scroll md:h-full">
    <div class="mx-4 flex flex-col items-start px-0 pb-40 pt-20 leading-none md:px-2 md:pb-20 lg:mx-20">
      <div class="mb-8 rounded-3xl bg-leaf-200 p-5">
        <img :src="reportingIconSrc" class="h-12 w-12" />
      </div>

      <div class="settings-landing-content">
        <h1 class="mb-3 max-w-full text-4xl font-bold text-grey-800 xl:max-w-md">
          {{ $t('reports_v2.navigation_header_reporting') }}
        </h1>
        <t-badge
          id="upgrade-badge"
          variant="upgrade"
          :text="$t('entitlement.upgrade_to_plan_long', { plan: minimumRequiredPlan })"
        >
          <template #icon><arrow-top-circle-linear size="1.25rem" /></template>
        </t-badge>
        <t-unordered-list :items="list" class="mt-8 max-w-full xl:max-w-md" size="lg"></t-unordered-list>
        <section class="mt-8 flex w-full flex-col md:w-auto xl:flex-row">
          <t-button
            btn-style="primary"
            size="md"
            class="mb-4 mr-0 xl:mb-0 xl:mr-4"
            @click="$router.push('/admin/subscription_settings')"
          >
            <div class="flex items-center justify-center">
              <arrow-top-circle-linear size="1.25rem" class="mr-1" />
              {{ $t('entitlement.upgrade_to_plan', { plan: minimumRequiredPlan }) }}
            </div>
          </t-button>

          <t-button
            size="md"
            btn-style="secondary"
            href="https://help.trengo.com/en/24850-trengo-analytics"
            rel="noreferrer noopener"
            target="_blank"
            class="open-external flex flex-row items-center"
          >
            {{ $t('reports_v2.learn_more') }}
            <external-link-linear class="ml-1" />
          </t-button>
        </section>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ExternalLinkLinear, ArrowTopCircleLinear } from '@trengo/trengo-icons';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import { FEATURE } from '@/Configs/Constants';
import { useEntitlementsStore } from '@/store/pinia';

import reportingIcon from '../assets/reportingIcon.svg';

export default defineComponent({
  name: 'ReportingEntitlementPage',
  components: { ArrowTopCircleLinear, ExternalLinkLinear },
  data() {
    return {
      list: [
        this.$t('reports_v2.upgrade_better_insight'),
        this.$t('reports_v2.upgrade_users_based_on_performance'),
        this.$t('reports_v2.upgrade_label_insights'),
      ],
      reportingIconSrc: reportingIcon,
    };
  },

  computed: {
    ...mapStores(useEntitlementsStore),
    minimumRequiredPlan() {
      return this.entitlementsStore?.getMinimumRequiredPlanFor(FEATURE.CUSTOMER_SERVICE__REPORTING);
    },
  },
});
</script>

<style scoped lang="scss">
#upgrade-badge {
  @apply t-text-sm-emphasize h-8 w-fit px-3 py-[6px] #{!important};
}
</style>
