<template>
  <get-started-card :is-done="isDone" data-test="website-widget">
    <template #title>{{ $t('getting_started.setup_website_widget') }}</template>
    <template #description>{{ $t('getting_started.offer_immediate_support_on_your_website') }}</template>
    <template #icon>
      <img class="w-48px rounded-2xl" :src="`${$root.assetsURL}img/get_started_page/web_widget.svg`" />
    </template>
    <div>
      <div class="flex h-32px items-center justify-between sm:px-2" data-test="item-web-widget">
        <div class="flex flex-row">
          <img :src="stateImage" class="mr-2" />
          <span :class="[stateTextColor]" class="t-text-desktop-label-sm mr-2 sm:mr-0">
            {{ $t('getting_started.create_and_set_up_your_web_widget') }}
          </span>
        </div>
        <atomic-button
          v-if="!setupWidget"
          btn-style="secondary"
          size="xs"
          class="ml-auto inline-flex h-32px min-w-max items-center px-4 text-sm leading-4"
          @click="openHelpCenterArticle"
        >
          <span class="t-text-desktop-label-sm mr-1 text-grey-900">{{ $t('getting_started.find_out_how') }}</span>
          <external-link-linear size="1rem" />
        </atomic-button>
      </div>
    </div>
  </get-started-card>
</template>

<script>
import GetStartedCard from '../GetStartedCard';
import { mapGetters } from 'vuex';
import { ExternalLinkLinear } from '@trengo/trengo-icons';

export default {
  name: 'SetupWebWidget',
  components: { GetStartedCard, ExternalLinkLinear },
  data() {
    return {
      setupWidget: false,
      isDone: false,
    };
  },
  computed: {
    ...mapGetters({
      getStepByType: 'gamification/getStepByType',
    }),
    stateImage() {
      return this.setupWidget
        ? this.$root.assetsURL + 'img/get_started_page/successful.svg'
        : this.$root.assetsURL + 'img/get_started_page/unsuccessful.svg';
    },
    stateTextColor() {
      return this.setupWidget ? 'state-text-grey-500' : 'state-text-grey-800';
    },
  },
  mounted() {
    this.setupWidget = this.getStepByType('user.setup_first_web_widget')?.completed;
    this.isDone = this.setupWidget;
  },
  methods: {
    openHelpCenterArticle() {
      window.open(
        'https://help.trengo.com/en/articles/68462-add-a-website-widget-to-your-trengo-inbox',
        '_blank',
        'noopener'
      );
    },
  },
};
</script>
<style scoped src="../styles.scss" lang="scss" />
