<script setup lang="ts">
import SettingItem from '../../Components/SettingItem';
import { computed } from 'vue';
import { vm } from '@/ApplicationHandler';
import { importMessages } from '@/api/modules/email';

const props = defineProps({
  action: {
    type: String,
    required: true,
  },
  record: {
    type: Object,
    required: true,
  },
  externalProviderSettings: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['input']);

const hasAvailableFolders = computed(() => {
  return Object.keys(availableFoldersWithoutSpam.value).length > 0;
});

const isImportDisabled = computed(() => {
  return props.externalProviderSettings.loadedAccountInfo && props.externalProviderSettings.accountError;
});

const availableFoldersWithoutSpam = computed(() => {
  const availableFolders = props?.externalProviderSettings?.availableFolders ?? {};
  const { Spam, ...foldersWithoutSpam } = availableFolders;
  return foldersWithoutSpam;
});

const computedRecord = computed({
  get() {
    return props.record;
  },
  set(newValue) {
    emit('input', newValue);
  },
});

const importUserMessages = () => {
  importMessages(computedRecord.value.id).then(() => {
    vm.flashSuccess(vm.$tc('email_settings.bulk_import_success'));
  });
};
</script>
<template>
  <div>
    <setting-item :custom-class="{ container: 'mb-4 sm:mb-1 mt-0 pt-5 sm:pt-4 pb-4 cursor-pointer' }">
      <template #title>{{ $t('email_settings.synchronize_conversation_status') }}</template>
      <template #formText>
        {{ $t('email_settings.nylas_synchronize_conversation_status_subtitle_one') }}
        <br />
        {{ $t('email_settings.synchronize_conversation_status_subtitle_two') }}
      </template>
      <template #toggle>
        <label class="ui-switch success m-t-xs">
          <input v-model="computedRecord.emailChannel.meta.syncStatus" type="checkbox" />
          <i></i>
        </label>
      </template>
    </setting-item>
    <setting-item
      v-if="hasAvailableFolders"
      :show-border="false"
      :custom-class="{ container: 'mb-4 mt-0 pt-0 sm:pt-5 pb-3' }"
    >
      <template #title>{{ $tc('email_settings.synchronise_email_labels') }}</template>
      <template #formText>
        {{ $tc('email_settings.synchronise_email_labels_subtitle_one') }}
      </template>
      <template #toggle>
        <label class="ui-switch success m-t-xs">
          <input v-model="computedRecord.emailChannel.meta.syncFolders" type="checkbox" />
          <i class="success"></i>
        </label>
      </template>
      <template #moreSettings>
        <div v-show="computedRecord.emailChannel.meta.syncFolders" class="scroll-box-container py-1">
          <div class="scroll-box mt-4">
            <div v-for="(folderValue, folderName) in availableFoldersWithoutSpam" :key="folderValue" class="my-1 flex">
              <div class="mt-1 cursor-pointer font-normal">
                <t-input-checkbox v-model="computedRecord.emailChannel.meta.selectedFolders" :value="folderValue">
                  {{ folderName }}
                </t-input-checkbox>
              </div>
            </div>
          </div>
        </div>
      </template>
    </setting-item>

    <setting-item :show-border="true" :custom-class="{ container: 'mb-4 mt-0 pt-0 sm:pt-5 pb-4' }">
      <template #title>{{ $tc('email_settings.bulk_import') }}</template>
      <template #formText>
        <div class="sm:mb-2">
          {{ $tc('email_settings.bulk_import_subtitle') }}
        </div>
      </template>
      <template #toggle>
        <atomic-button
          :disabled="isImportDisabled"
          btn-style="secondary flex flex-row items-center"
          size="sm"
          aria-label="Import"
          @click="importUserMessages"
        >
          <div :class="{ 'opacity-20': isImportDisabled }" class="mr-2 h-16px w-16px">
            <img :src="`${$root.assetsURL}img/import.svg`" alt="import" />
          </div>
          <span>Import</span>
        </atomic-button>
      </template>
    </setting-item>
  </div>
</template>

<style lang="scss" scoped src="./OutlookSettings.scss" />
