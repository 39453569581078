import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "t-text-h3 my-auto max-w-[90%] p-0" }
const _hoisted_2 = {
  class: "t-text-md text-grey-800",
  "data-test": "integration-description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_modal = _resolveComponent("t-modal")!

  return (_openBlock(), _createBlock(_component_t_modal, {
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isOpen) = $event)),
    "close-on-backdrop-click": true,
    "close-icon": true,
    variant: "narrow",
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('integration_hub.installation_failed_modal_title')), 1 /* TEXT */)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_t_button, {
        "data-test": "done-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('integration_hub.done_button')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('integration_hub.installation_failed_modal_description', { name: _ctx.integration.name })), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}