import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { fetchCompanyProfile } from '@/api';
import { PRICING_MODEL } from '@/Configs/Constants';

import type { CompanyProfile } from '@/types/companyProfile';

export const useCompanyProfileStore = defineStore('companyProfile', () => {
  const isPending = ref(false);
  const isInitialized = ref(false);
  const companyProfile = ref<CompanyProfile | null>(null);
  const pricingModel = computed(() => companyProfile.value?.pricing_model ?? PRICING_MODEL.SEAT_BASED);
  const isSeatBasedPricingModel = computed(() => pricingModel.value === PRICING_MODEL.SEAT_BASED);
  const isUsageBasedPricingModel = computed(() => pricingModel.value === PRICING_MODEL.USAGE_BASED);

  const init = async () => {
    if (isInitialized.value) return;
    await loadCompanyProfile();
    isInitialized.value = true;
  };

  const loadCompanyProfile = async () => {
    isPending.value = true;
    try {
      const { data } = await fetchCompanyProfile();
      companyProfile.value = data;
    } catch (err) {
      console.error(err);
    } finally {
      isPending.value = false;
    }
  };

  return {
    isInitialized,
    isPending,
    pricingModel,
    isSeatBasedPricingModel,
    isUsageBasedPricingModel,
    companyProfile,
    init,
    reload: loadCompanyProfile,
  };
});
