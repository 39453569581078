<template>
  <div>
    <div class="flex items-center">
      <h4 :class="headerColor" class="t-text-h4 mb-0">{{ headingText }}</h4>
      <div
        v-tooltip="
          tooltipText && {
            placement: 'top',
            content: tooltipText,
            popperClass: 'tooltip-vuetify tooltip-medium',
          }
        "
      >
        <t-switch
          v-if="shouldShowSwitch"
          v-model="computedValue"
          size="md"
          class="ml-2"
          :disabled="isToggleDisabled"
          @input="toggleSwitch"
        />
      </div>
      <t-badge v-if="shouldShowBadge" class="ml-auto" :text="badgeText" :variant="badgeVariant" />
    </div>
    <div class="mt-1">
      <p :class="subtitleColor" class="t-text-sm mb-3">{{ subtitle }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormTitle',
  emits: ['update:modelValue', 'handle-click'],
  props: {
    headingText: {
      type: String,
      default: '',
    },
    badgeText: {
      type: String,
      default: '',
    },
    badgeVariant: {
      type: String,
      default: 'default',
    },
    isToggleDisabled: {
      type: Boolean,
      default: false,
    },
    isTextDisabled: {
      type: Boolean,
      default: false,
    },
    shouldShowBadge: {
      type: Boolean,
      default: false,
    },
    shouldShowSwitch: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },

  methods: {
    toggleSwitch() {
      this.$emit('handle-click', this.computedValue);
    },
  },

  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },

    headerColor(): string {
      return this.isTextDisabled ? 'text-grey-500' : ' text-grey-800';
    },

    subtitleColor(): string {
      return this.isTextDisabled ? ' text-grey-400' : ' text-grey-600';
    },
  },
});
</script>
