<template>
  <div class="ml-16 flex flex-col">
    <h1 class="t-text-h4 m-0 mb-2 mt-8 p-0 text-grey-800">{{ $tc('apps_integration.zapier') }}</h1>

    <t-card class="mt-4 flex flex-col bg-white">
      <h2 class="t-text-md-emphasize m-0 mb-2 p-0 text-grey-800">{{ $tc('apps_integration.zapier') }}</h2>
      <p class="t-text-sm m-0 mb-6 p-0 text-grey-600">{{ $t('integrations.zapier_description') }}</p>

      <t-button class="w-fit" @click.prevent="navigateToZapier">
        {{ $t('integrations.zapier_button_start_building') }}
      </t-button>
    </t-card>
  </div>
</template>

<script setup lang="ts">
const navigateToZapier = () => window.open('https://zapier.com/apps/trengo/integrations', '_blank');
</script>
