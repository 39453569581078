<template>
  <div
    data-test="subscription-cancellation-scheduled-banner"
    class="mb-5 rounded-xl border-1 border-grey-300 bg-white px-6 py-5"
  >
    <div class="flex items-center gap-4">
      <t-thumbnail class="bg-white">
        <clock-linear size="1rem" />
      </t-thumbnail>
      <div class="flex-grow">
        <div class="t-text-md-emphasize font-bold text-grey-800">
          <slot name="heading"></slot>
        </div>
        <div class="t-text-sm text-grey-700">
          <slot name="subheading"></slot>
        </div>
      </div>
      <t-button
        v-if="withButton"
        size="md"
        btn-style="secondary"
        class="flex flex-nowrap items-center"
        href="https://trengo.com/contact"
        target="_blank"
      >
        <slot name="buttonLabel"></slot>
        <arrow-top-right-linear size="1.2rem" class="ml-1" />
      </t-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ArrowTopRightLinear, ClockLinear } from '@trengo/trengo-icons';
defineProps<{
  withButton?: boolean;
}>();
</script>
