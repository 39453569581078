<script lang="ts" setup>
import type { PropType } from 'vue';

const emit = defineEmits(['close']);
defineProps({
  type: String as PropType<'balance' | 'conversation'>,
});

const handleClose = () => {
  emit('close');
};

const MODAL_DETAILS = {
  balance: {
    title: 'usage_overview.add_carrier_costs_success_modal_title',
    description: 'usage_overview.add_carrier_costs_success_modal_description',
  },
  conversation: {
    title: 'usage_overview.add_conversation_blocks_success_modal_title',
    description: 'usage_overview.add_conversation_blocks_success_modal_description',
  },
};
</script>

<template>
  <t-modal
    v-if="type"
    :model-value="true"
    :title="$t(MODAL_DETAILS[type].title)"
    data-test="addBlocksSuccessModal"
    variant="narrow"
    @close="handleClose"
  >
    <p class="t-text-md m-0 text-grey-700" v-html="$t(MODAL_DETAILS[type].description)" />
    <template #footer>
      <div class="flex w-full justify-end">
        <t-button btn-style="primary" @click="handleClose">
          {{ $t('general.close') }}
        </t-button>
      </div>
    </template>
  </t-modal>
</template>
