<template>
  <ul class="mt-4 list-none rounded-lg border border-grey-300 bg-white px-0" :data-test="dataTestListRowItemParent">
    <slot></slot>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ListRowParent',
  props: {
    dataTestListRowItemParent: {
      type: String,
      default: 'list-row-item-parent',
    },
  },
});
</script>
