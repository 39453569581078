<script>
import Generic from './ECommerce/Generic';

export default {
  extends: Generic,

  data() {
    return {
      settings: {},
    };
  },

  methods: {
    loadData() {
      this.customer = {};
      this.orders = [];
      this.loading = true;
      axios
        .get('/api/v2/integrations/whoocommerce?plugin_id=' + this.plugin.id + '&ticket_id=' + this.ticket.id)
        .then((res) => {
          this.loading = false;
          if (!res.data.customer) {
            this.found = false;
            return;
          }
          this.found = true;
          this.customer = res.data.customer;
          this.orders = res.data.orders;
        })
        .catch((e) => {
          this.found = true;
          this.loading = false;
        });
    },
  },
};
</script>
