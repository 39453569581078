<template>
  <page-container>
    <hero class="my-6">
      <template #top>
        <page-title>{{ $t('reports.resolution_title') }}</page-title>
      </template>
      <template #body>
        {{ $t('reports.resolution_title_sub_text') }}
        <div class="mt-4">
          <atomic-link-external url="https://help.trengo.com">
            {{ $t('reports.resolution_title_learn_more') }}
          </atomic-link-external>
        </div>
      </template>
    </hero>
    <filters v-model="filters"></filters>
    <report
      mode="time"
      report="performance"
      :filters="filters"
      :metrics="['avg_first_reply_time', 'avg_resolution_time', 'avg_replies_until_resolution']"
    ></report>
  </page-container>
</template>

<script>
import AtomicLinkExternal from '../Components/ExternalLink';
import Filters from '../Components/Filters';
import PageContainer from '../Components/PageContainer';
import PageTitle from '../Components/PageTitle';
import Report from '../Components/Report';

export default {
  components: {
    Report,
    Filters,
    PageTitle,
    PageContainer,
    AtomicLinkExternal,
  },
  data() {
    return {
      filters: {},
    };
  },
};
</script>
