import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-35e4ffa5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-row items-center justify-between pt-2" }
const _hoisted_2 = { class: "flex flex-row items-center justify-start" }
const _hoisted_3 = { class: "flex flex-row items-center justify-center" }
const _hoisted_4 = { class: "flex flex-row items-center justify-center" }
const _hoisted_5 = { class: "flex flex-row items-center justify-center" }
const _hoisted_6 = { class: "flex flex-row items-center justify-end" }
const _hoisted_7 = { class: "w-18 flex h-8 items-center justify-between rounded-lg border border-grey-300 px-2 py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_table_list_cell = _resolveComponent("t-table-list-cell")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_t_table_list_cell, { class: "!pl-0" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.animationClass, "mr-2 h-8 w-8 rounded-full bg-grey-300"])
            }, null, 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.animationClass, "h-2 w-16 rounded-xl bg-grey-300"])
            }, null, 2 /* CLASS */)
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_t_table_list_cell, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.animationClass, "h-2 w-16 rounded-xl bg-grey-300"])
            }, null, 2 /* CLASS */)
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_t_table_list_cell, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.animationClass, "h-2 w-16 rounded-xl bg-grey-300"])
            }, null, 2 /* CLASS */)
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_t_table_list_cell, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.animationClass, "h-2 w-16 rounded-xl bg-grey-300"])
            }, null, 2 /* CLASS */)
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_t_table_list_cell, { class: "!pr-0" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", {
                class: _normalizeClass([_ctx.animationClass, "h-2 w-16 rounded-xl bg-grey-300"])
              }, null, 2 /* CLASS */)
            ])
          ])
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}