<template>
  <div class="mt-6 flex flex-col">
    <t-input-text
      id="url"
      v-model="$props.modelValue.url"
      size="sm"
      :label="$tc('integrations.shopify_label_webshop_domain')"
      :sub-label="$tc('integrations.shopify_subtitle_webshop_domain')"
      :class="{ 'mb-6': !errors.url }"
      @blur="checkUrl"
    />
    <t-error-item v-if="errors.url" :text="$tc('integration_hub.error_url_must_be_valid')" danger-icon class="mb-4" />

    <t-input-text
      id="apiKey"
      v-model="$props.modelValue.api_key"
      size="sm"
      :label="$tc('integrations.shopify_label_api_key')"
      :sub-label="$tc('integrations.shopify_subtitle_api_key')"
      :class="{ 'mb-6': !errors.apiKey }"
      @blur="checkApiKey"
    />
    <t-error-item v-if="errors.apiKey" :text="$tc('')" danger-icon class="mb-4" />

    <t-input-text
      id="apiSecretKey"
      v-model="$props.modelValue.api_secret_key"
      size="sm"
      :label="$tc('integrations.shopify_label_api_secret_key')"
      :sub-label="$tc('integrations.shopify_subtitle_your_secret_key')"
      :class="{ 'mb-6': !errors.apiSecretKey }"
      @blur="checkApiSecretKey"
    />
    <t-error-item v-if="errors.apiSecretKey" :text="$tc('')" danger-icon class="mb-4" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import type { CustomShopifyIntegration } from '@/store/types/integrations';

const props = defineProps<{ modelValue: CustomShopifyIntegration['meta'] }>();
const emit = defineEmits(['on-validated', 'on-invalidated']);

const errors = ref({ url: false, apiKey: false, apiSecretKey: false });

function validateFields() {
  checkUrl();
  checkApiKey();
  checkApiSecretKey();

  Object.values(errors.value).some((item) => item) ? emit('on-invalidated') : emit('on-validated');
}

defineExpose({ validateFields });

function checkUrl() {
  errors.value.url = !props.modelValue.url || props.modelValue.url === '';
}

function checkApiKey() {
  errors.value.apiKey = !props.modelValue.api_key || props.modelValue.api_key === '';
}

function checkApiSecretKey() {
  errors.value.apiSecretKey = !props.modelValue.api_secret_key || props.modelValue.api_secret_key === '';
}
</script>
