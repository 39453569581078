import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mobile_preview_card = _resolveComponent("mobile-preview-card")!
  const _component_wab_broadcasting_landing = _resolveComponent("wab-broadcasting-landing")!
  const _component_broadcasting_list = _resolveComponent("broadcasting-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_mobile_preview_card, { class: "max-h-screen w-full" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('general.feature_only_available_on_desktop')), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    (!_ctx.hasList && _ctx.loaded)
      ? (_openBlock(), _createBlock(_component_wab_broadcasting_landing, {
          key: 0,
          class: "hidden-md-down"
        }))
      : (_ctx.loaded)
        ? (_openBlock(), _createBlock(_component_broadcasting_list, {
            key: 1,
            list: _ctx.list,
            class: "hidden-md-down",
            loading: _ctx.loading,
            onInfiniteScroll: _ctx.onInfinite
          }, null, 8 /* PROPS */, ["list", "loading", "onInfiniteScroll"]))
        : _createCommentVNode("v-if", true)
  ]))
}