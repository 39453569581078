<template>
  <div class="relative h-88">
    <canvas ref="report" height="200" class="h-full"></canvas>

    <error-status
      v-if="showError"
      icon-name="ChartLineLinear"
      :title="$t('reports_v2.no_data_available')"
      :sub-title="$t('reports_v2.api_failed')"
      classes="h-[calc(100%-100px)] w-[calc(100%-64px)]
        min-[1500px]:h-[calc(100%-76px)]
        min-[990px]:h-[calc(100%-77px)]"
    />
  </div>
</template>

<script lang="ts">
import Chart from 'chart.js/auto';
import { map } from 'lodash';
import moment from 'moment';
import { defineComponent } from 'vue';

import { getWorkloadManagementChartConfiguration } from './utilities';
import ErrorStatus from '../../../ErrorStatus.vue';
import { getDayOfWeek } from '../utilities';

import type { WorkloadManagementBucket } from '@/components/Reporting/api/types';
import type { ChartConfiguration, ChartData } from 'chart.js/auto';

type Data = {
  chartInstance?: ChartConfiguration;
  data?: ChartData;
};

export default defineComponent({
  name: 'WorkloadManagementLoaded',
  components: { ErrorStatus },

  props: {
    createdConversations: {
      type: Array,
      default: () => [] as WorkloadManagementBucket[],
    },
    closedConversations: {
      type: Array,
      default: () => [] as WorkloadManagementBucket[],
    },
    showError: {
      type: Boolean,
      default: false,
    },
    interval: {
      type: String,
      default: 'day',
    },
  },

  chartInstance: undefined,
  data(): Data {
    return {
      data: undefined,
    };
  },

  mounted() {
    this.setChart();
  },

  watch: {
    createdConversations: {
      handler() {
        (this.chartInstance.data.labels = this.mapLabels()), (this.chartInstance.data.datasets = this.datasets);
        this.chartInstance.update();
      },
    },
  },

  computed: {
    chartConfiguration() {
      return getWorkloadManagementChartConfiguration((key, param) => this.$t(key, param), this.showError, false);
    },

    datasets() {
      return [
        {
          label: this.$t('reports_v2.created_converstions'),
          data: map(this.createdConversations, (bucket: WorkloadManagementBucket) => {
            return bucket.value;
          }),
          borderWidth: 4,
          backgroundColor: this.showError ? '#E1E3E5' : '#249888',
          borderColor: this.showError ? '#E1E3E5' : '#249888',
          pointBorderColor: 'white',
          pointRadius: this.showError ? 0 : 6,

          ...(!this.showError && {
            pointBorderWidth: 4,
            pointHoverBorderColor: '#249888',
            pointHoverRadius: 6,
            pointHoverBorderWidth: 4,
            pointHoverBackgroundColor: 'white',
          }),
        },
        {
          label: this.$t('reports_v2.closed_conversations'),
          data: map(this.closedConversations, (bucket: WorkloadManagementBucket) => {
            return bucket.value;
          }),
          borderWidth: 4,
          backgroundColor: this.showError ? '#E1E3E5' : '#ACB5D1',
          borderColor: this.showError ? '#E1E3E5' : '#ACB5D1',
          pointBorderColor: 'white',
          pointRadius: this.showError ? 0 : 6,

          ...(!this.showError && {
            pointBorderWidth: 4,
            pointHoverBorderColor: '#ACB5D1',
            pointHoverRadius: 6,
            pointHoverBorderWidth: 4,
            pointHoverBackgroundColor: 'white',
          }),
        },
      ];
    },
  },

  methods: {
    setChart() {
      this.mapData();
      this.drawChart();
    },

    mapData() {
      this.data = {
        labels: this.mapLabels(),

        datasets: this.datasets,
      };
    },
    hourRange(): string[] {
      const today = moment().tz(this.$root.user.timezone).startOf('day');
      const format = 'YYYY-MM-DDTHH:mm:ssZ';
      const hoursRange = [];

      for (let i = 0; i < 24; i++) {
        hoursRange.push(today.format(format));
        today.add(1, 'hours');
      }
      return hoursRange;
    },
    mapLabels() {
      if (this.interval === 'hour') {
        return this.hourRange().map((value: string) => {
          return moment(value).tz(this.$root.user.timezone).format('HH:mm');
        });
      } else {
        return this.createdConversations.map((value: WorkloadManagementBucket) => {
          if (this.interval === 'day') {
            return moment(value.datetime).tz(this.$root.user.timezone).format('DD MMM');
          } else if (this.interval === 'week') {
            return moment(value.datetime).tz(this.$root.user.timezone).format('DD/MM/YYYY');
          } else if (this.interval === 'month') {
            return moment(value.datetime).tz(this.$root.user.timezone).format('MMM');
          } else if (this.interval === 'year') {
            return moment(value.datetime).tz(this.$root.user.timezone).format('YYYY');
          }
        });
      }
    },

    drawChart() {
      const chart = this.$refs.report.getContext('2d');

      const options = {
        onclick: this.handleClickOnBar,
        responsive: true,
        maintainAspectRatio: false,
        scales: this.chartConfiguration.scales,
        plugins: this.chartConfiguration.plugins,
        legend: this.chartConfiguration.legend,
      };

      this.chartInstance = new Chart(chart, {
        type: 'line',
        data: this.data,
        options,
      });
    },

    dayOfWeek(timestamp: number) {
      return getDayOfWeek((key) => this.$t(key), timestamp);
    },
  },
});
</script>
