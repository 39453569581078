<script lang="ts" setup>
import { ArrowTopRightLinear } from '@trengo/trengo-icons';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { HELP_ARTICLE_LINK } from '@/Configs/Constants';
import { useSubscriptionStore } from '@/store/pinia';

import CarrierCostsEditor from './CarrierCostsEditor.vue';

const props = defineProps<{ carrierCosts: number }>();
const emit = defineEmits(['save', 'close']);
const { t } = useI18n();
const isLoading = ref(false);
const carrierCosts = ref(props.carrierCosts);

const handleSave = () => emit('save', carrierCosts.value);
</script>

<template>
  <t-modal
    :model-value="true"
    :title="t('edit_subscription.carrier_costs_title')"
    variant="default"
    @close="emit('close')"
  >
    <div class="h-[400px]">
      <div class="t-text-sm text-grey-600">
        <p>{{ t('edit_subscription.carrier_costs_description') }}</p>
        <p
          v-html="
            t('edit_subscription.carrier_costs_note', {
              cycleDate: useSubscriptionStore().subscription.subscriptionDueDate,
            })
          "
        ></p>
      </div>
      <form action="POST" @action="handleSave">
        <div>
          <div class="flex flex-col gap-3">
            <carrier-costs-editor
              :current-carrier-costs="props.carrierCosts"
              :carrier-costs="carrierCosts"
              @change="carrierCosts = $event"
            />
          </div>
        </div>
      </form>
    </div>
    <template #footer>
      <div class="flex w-full">
        <t-button btn-style="secondary" :href="HELP_ARTICLE_LINK.CARRIER_COSTS" target="_blank">
          {{ t('general.learn_more') }}
          <arrow-top-right-linear class="ml-1" size="1.25rem" />
        </t-button>
        <div class="flex flex-grow justify-end gap-2">
          <t-button btn-style="secondary" :disabled="isLoading" @click="emit('close')">
            {{ t('general.cancel') }}
          </t-button>
          <t-button class="flex items-center" btn-style="primary" :class="{ loader: isLoading }" @click="handleSave">
            {{ t('general.save_changes') }}
          </t-button>
        </div>
      </div>
    </template>
  </t-modal>
</template>
