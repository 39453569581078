<template>
  <div class="mt-6 flex flex-col">
    <t-input-text
      id="url"
      v-model="$props.modelValue.url"
      size="sm"
      :label="$tc('integrations.woocommerce_label_your_shop_url')"
      :sub-label="$tc('integrations.woocommerce_subtitle_your_shop_url')"
      :class="{ 'mb-6': !errors.url }"
      @blur="checkUrl"
    />
    <t-error-item v-if="errors.url" :text="$tc('integration_hub.error_url_must_be_valid')" danger-icon class="mb-4" />

    <t-input-text
      id="consumerKey"
      v-model="$props.modelValue.consumer_key"
      size="sm"
      :label="$tc('integrations.woocommerce_label_consumer_key')"
      :sub-label="$tc('integrations.woocommerce_subtitle_woocommerce_consumer_key')"
      :class="{ 'mb-6': !errors.consumerKey }"
      @blur="checkConsumerKey"
    />
    <t-error-item
      v-if="errors.consumerKey"
      :text="$tc('integration_hub.error_consumer_key_must_be_filled_out')"
      danger-icon
      class="mb-4"
    />

    <t-input-text
      id="consumerSecret"
      v-model="$props.modelValue.consumer_secret"
      size="sm"
      :label="$tc('integrations.woocommerce_label_consumer_secret')"
      :sub-label="$tc('integrations.woocommerce_subtitle_consumer_secret')"
      :class="{ 'mb-6': !errors.consumerSecret }"
      @blur="checkConsumerSecret"
    />
    <t-error-item
      v-if="errors.consumerSecret"
      :text="$tc('integration_hub.error_consumer_secret_must_be_filled_out')"
      danger-icon
      class="mb-4"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import type { CustomWhoocommerceIntegration } from '@/store/types/integrations';

const props = defineProps<{ modelValue: CustomWhoocommerceIntegration['meta'] }>();
const emit = defineEmits(['on-validated', 'on-invalidated']);

const errors = ref({ url: false, consumerKey: false, consumerSecret: false });

function validateFields() {
  checkUrl();
  checkConsumerKey();
  checkConsumerSecret();

  Object.values(errors.value).some((item) => item) ? emit('on-invalidated') : emit('on-validated');
}

defineExpose({ validateFields });

function checkUrl() {
  errors.value.url = !props.modelValue.url || props.modelValue.url === '';
}

function checkConsumerKey() {
  errors.value.consumerKey = !props.modelValue.consumer_key || props.modelValue.consumer_key === '';
}

function checkConsumerSecret() {
  errors.value.consumerSecret = !props.modelValue.consumer_secret || props.modelValue.consumer_secret === '';
}
</script>
