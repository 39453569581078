<template>
  <div>
    <div v-if="action === 'create'" class="box">
      <div class="box-header">
        <h2>{{ $t('telegram.telegram_settings') }}</h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">
            {{ $t('telegram.telegram_token') }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-10">
            <input
              v-model="record.password"
              type="text"
              class="form-control"
              :placeholder="$t('telegram.telegram_placeholder_example')"
              @paste="fetchUsername"
              @keyup="fetchUsername"
            />
            <span class="form-text" v-html="$t('telegram.telegram_learn_how_obtain_token')" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">
            {{ $t('telegram.telegram_username') }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-10">
            <input v-model="record.username" type="text" disabled class="form-control" />
            <span class="form-text">{{ $t('telegram.this_is_your_bots_username') }}</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="action === 'info'" class="box">
      <div class="box-header">
        <h2>Telegram</h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('telegram.telegram_connected_account') }}</label>
          <div class="col-sm-10">
            <p class="form-control-static">
              <a class="text-info" :href="'https://t.me/' + record.username" target="_blank">
                <i class="fa fa-external-link"></i>
                @{{ record.username }}
              </a>
              <span class="form-text">{{ $t('telegram.telegram_the_connected_telegram_account') }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  name: 'TelegramChannel',
  props: {
    action: {
      type: String,
      default: 'create',
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    fetchUsername() {
      this.record.username = '';
      this.$nextTick(() => {
        let token = this.record.password;
        $.post('https://api.telegram.org/bot' + token + '/getMe')
          .then((res) => {
            this.record.username = res.result.username;
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },
  },
};
</script>
