<template>
  <t-modal
    :model-value="isOpen"
    :is-closable="!isSubmitting"
    :title="$t('usage_overview.add_conversation_blocks_modal_title')"
    data-test="addConversationBlocksModal"
    variant="default"
    @close="handleClose"
  >
    <p class="t-text-md m-0 text-grey-700" v-html="$t('usage_overview.add_conversation_blocks_modal_description')" />
    <template v-if="isOpen">
      <div
        v-for="{ id, price } in items"
        :key="id"
        class="flex items-center gap-3 border-b-1 border-grey-300 py-4 last:border-0"
      >
        <t-input-stepper :id="id" v-model="formValues[id]" :size="'md'" :max="400" :disabled="isSubmitting" />
        <div>
          <label class="t-text-sm-emphasize m-0" :for="id">
            {{ $t(`usage_overview.add_conversation_blocks_modal_conversation_blocks_label_${snakeCase(id)}`) }}
          </label>
          <span class="t-text-sm block text-grey-600">
            {{
              $t('usage_overview.add_conversation_blocks_modal_price_breakdown_label', {
                price: formatPrice(price),
              })
            }}
          </span>
        </div>
        <span class="t-text-sm-emphasize ml-auto">{{ formatPrice(price * formValues[id]) }}</span>
      </div>
    </template>

    <template #footer>
      <div class="flex w-full justify-end gap-2">
        <t-button btn-style="secondary" @click="handleClose">
          {{ $t('general.cancel') }}
        </t-button>
        <t-button
          class="flex items-center"
          btn-style="primary"
          :disabled="!isChanged || isSubmitting"
          @click="handleSubmit"
        >
          {{ $t('usage_overview.add_conversation_blocks_modal_cta') }}
        </t-button>
      </div>
    </template>
  </t-modal>
</template>

<script lang="ts" setup>
import snakeCase from 'lodash/snakeCase';
import { computed, ref } from 'vue';

import { postOneTimePurchaseTopUpCommitment } from '@/api';
import { useQueryParameterOpenState } from '@/composables/useQueryParameterOpenState';
import { CONVERSATION_TYPE } from '@/Configs/Constants';
import { useSubscriptionStore } from '@/store/pinia';
import { segmentTrackEvent } from '@/util/analytics/segmentAnalytics';
import { formatPrice } from '@/util/currency';
import { flashError } from '@/util/flashNotification';

import type { ConversationType } from '@/types';

type FormValues = Record<ConversationType, number>;
const subscriptionStore = useSubscriptionStore();

const isSubmitting = ref(false);

const items = computed(() => [
  {
    id: CONVERSATION_TYPE.STANDARD,
    label: snakeCase(CONVERSATION_TYPE.STANDARD),
    price: subscriptionStore.subscription?.subscriptionFrequency === 'annually' ? 1500 : 1800,
  },
]);

const { isOpen, handleClose } = useQueryParameterOpenState('addConversationBlocksModal');
const emit = defineEmits(['success']);

const initializeForm = () => Object.fromEntries(items.value.map(({ id }) => [id, 0])) as FormValues;
const formValues = ref<FormValues>(initializeForm());

const isChanged = computed(() => {
  return Object.values(formValues.value).some((value) => value > 0);
});

const resetForm = () => {
  formValues.value = initializeForm();
};

const handleSegmentTracking = (amount: number) => {
  segmentTrackEvent({
    event_name: 'extra_usage',
    action: 'added',
    category: 'conversations',
    object_title: 'Add Conversations',
    subscription_id: subscriptionStore.subscription.subscriptionId,
    amount,
  });
};

const handleSubmit = async () => {
  isSubmitting.value = true;
  try {
    const conversationBlocks = formValues.value[CONVERSATION_TYPE.STANDARD];
    await postOneTimePurchaseTopUpCommitment({
      standard_conversation_blocks: conversationBlocks,
    });
    handleSegmentTracking(conversationBlocks);
    handleClose();
    emit('success');
    resetForm();
  } catch (error) {
    flashError('Something went wrong.');
    console.error(error);
  } finally {
    isSubmitting.value = false;
  }
};
</script>
