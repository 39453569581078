<template>
  <uninstall-modal
    :in-progress="inProgress"
    :installation="installation"
    :integration="integration"
    @close="closeModal"
    @uninstall="uninstall"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import UninstallModal from '@/components/IntegrationHub/Components/Modals/UninstallModal.vue';

import type { Installation } from '@/store/types/integrations';

export default defineComponent({
  name: 'IntegrationInstallationUninstall',

  components: {
    UninstallModal,
  },

  computed: {
    ...mapGetters({
      integration: 'integrations/getItem',
    }),

    installation() {
      return this.integration.installations.find((installation) => {
        return installation.id === parseInt(this.$route.params.installationId);
      });
    },
  },

  data() {
    return {
      inProgress: false,
    };
  },

  methods: {
    closeModal() {
      const params = {
        filter: this.$route.params.filter,
        integrationId: this.$route.params.integrationId,
      };

      const query = {
        page: this.integration.isInstalled ? 'installed' : 'description',
      };

      this.$router.replace({ name: 'integrationDetails', params, query });
    },

    async uninstall(installation: Installation) {
      this.inProgress = true;
      try {
        await this.$store.dispatch('integrations/removeInstallationItem', {
          integration_id: parseInt(this.$route.params.integrationId),
          installation_id: parseInt(this.$route.params.installationId),
        });
        this.closeModal();
        this.flashSuccess(this.$t('integration_hub.uninstall_success', { name: installation.name }));
      } catch (e) {
        this.flashError(e);
      } finally {
        this.inProgress = false;
      }
    },
  },
});
</script>
