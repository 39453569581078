<template>
  <div>
    <div v-if="page.name === 'channel.chat'">
      <web-chat-sandbox :title="page.title" :badge="page.badge" :icon="page.icon" :list="page.list"></web-chat-sandbox>
    </div>
    <div v-else-if="page.name === 'channel.voip' && !$root.mobileDevice">
      <voip-sandbox :title="page.title" :badge="page.badge" :icon="page.icon" :list="page.list"></voip-sandbox>
    </div>
    <settings-landing
      v-else
      :title="page.title"
      :intro="page.intro"
      :badge="computedBadge"
      :icon="page.icon"
      :list="page.list"
    >
      <template v-if="showUpgradeBadge" #entitlement-badge>
        <t-badge id="badge" variant="upgrade" :text="entitlementBadgeText">
          <template #icon><arrow-top-circle-linear size="1.25rem" /></template>
        </t-badge>
      </template>

      <template v-if="page.implements_entitlement">
        <AdminIndexFooterForEntitlements :page="page" />
      </template>

      <template v-if="showPrimaryButtonWithoutEntitlements">
        <channel-confirmation-modal
          :channel_type="page.channel_type"
          :open="openConfirmationModal"
          :channel="page.channel_item"
          @close="handleClose"
          @create="handleCreateChannel"
        />
        <t-button
          v-if="
            isInternalLink(page.primary_button_url) &&
            !isJsLink(page.primary_button_url) &&
            !page.open_channel_confirmation_modal
          "
          size="lg"
          :aria-label="page.primary_button_text"
          type="button"
          label="Button"
          class="mb-4 mr-0 xl:mb-0 xl:mr-4"
          @click="handleClickPrimaryButton()"
        >
          {{ page.primary_button_text }}
        </t-button>
        <t-button
          v-else-if="page.open_channel_confirmation_modal"
          size="lg"
          :aria-label="page.primary_button_text"
          type="button"
          label="Button"
          class="mb-4 mr-0 xl:mb-0 xl:mr-4"
          @click="handleOpenModal()"
        >
          {{ page.primary_button_text }}
        </t-button>
        <t-button
          v-else-if="page.primary_button_text"
          size="lg"
          :href="page.primary_button_url"
          :rel="!isJsLink(page.primary_button_url) ? 'noreferrer noopener' : ''"
          :target="!isJsLink(page.primary_button_url) ? '_blank' : ''"
          class="open-external mb-4 mr-0 xl:mb-0 xl:mr-4"
        >
          {{ page.primary_button_text }}
        </t-button>
      </template>
      <template v-if="showSecondaryButtonWithoutEntitlements">
        <t-button
          v-if="isInternalLink(page.secondary_button_url) && !isJsLink(page.secondary_button_url)"
          size="lg"
          :aria-label="page.secondary_button_text"
          type="button"
          label="Button"
          btn-style="secondary"
          @click="$router.push(page.secondary_button_url)"
        >
          {{ page.secondary_button_text }}
        </t-button>
        <t-button
          v-else
          size="lg"
          btn-style="secondary"
          :href="page.secondary_button_url"
          :rel="!isJsLink(page.secondary_button_url) ? 'noreferrer noopener' : ''"
          :target="!isJsLink(page.secondary_button_url) ? '_blank' : ''"
          class="open-external flex flex-row items-center"
        >
          {{ page.secondary_button_text }}
          <external-link-linear class="ml-1" />
        </t-button>
      </template>
    </settings-landing>
  </div>
</template>

<script>
import { ExternalLinkLinear, ArrowTopCircleLinear } from '@trengo/trengo-icons';
import { mapStores } from 'pinia';

import AdminIndexFooterForEntitlements from '@/components/AdminIndexFooterForEntitlements';
import SettingsLanding from '@/components/common/SettingsLanding';
import ChannelConfirmationModal from '@/components/OnboardingChannelLandingPage/components/ChannelConfirmationModal';
import channel_actions from '@/components/OnboardingChannelLandingPage/utils/channels_actions';
import { ENTITLEMENT_STATUS, FEATURE } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useEntitlementsStore } from '@/store/pinia';
import { request } from '@/util';

import VoipSandbox from './Channels/VoipSandbox';
import WebChatSandbox from './Channels/WebChatSandbox';

export default {
  name: 'AdminIndexBoxRich',
  emits: ['showModal', 'trigger-button-click', 'triggerShowModal'],
  props: {
    page: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ArrowTopCircleLinear,
    AdminIndexFooterForEntitlements,
    SettingsLanding,
    WebChatSandbox,
    VoipSandbox,
    ExternalLinkLinear,
    ChannelConfirmationModal,
  },
  data() {
    return {
      openConfirmationModal: false,
    };
  },
  computed: {
    ...mapStores(useEntitlementsStore),
    featureNeedsUpgrade() {
      const featureId = this.page.entitlement_feature_id;
      return (
        featureId &&
        this.getEntitlementStatus(featureId, ENTITLEMENT_STATUS.REQUIRES_PLAN_UPGRADE) &&
        this.entitlementBadgeText &&
        this.page.implements_entitlement
      );
    },
    featureRequiresAddOn() {
      const featureId = this.page.entitlement_feature_id;
      if (featureId !== FEATURE.CUSTOMER_SERVICE__AUTOMATION__FLOWBOT) {
        return false;
      }
      return (
        featureId &&
        this.getEntitlementStatus(featureId, ENTITLEMENT_STATUS.REQUIRES_ADDON) &&
        this.entitlementBadgeText &&
        this.page.implements_entitlement
      );
    },
    showUpgradeBadge() {
      return this.featureNeedsUpgrade || this.featureRequiresAddOn;
    },
    computedBadge() {
      return this.showUpgradeBadge ? '' : this.page.badge;
    },
    showPrimaryButtonWithoutEntitlements() {
      return this.showPrimaryButton() && !this.page.implements_entitlement;
    },
    showSecondaryButtonWithoutEntitlements() {
      return this.page.secondary_button_text && this.page.secondary_button_url && !this.page.implements_entitlement;
    },
    entitlementBadgeText() {
      if (this.featureRequiresAddOn) {
        return this.$t('entitlement.requires_add_on');
      }
      const minimumRequiredPlan = this.entitlementsStore?.getMinimumRequiredPlanFor(this.page.entitlement_feature_id);
      return this.$t('entitlement.upgrade_to_plan_long', { plan: minimumRequiredPlan });
    },
  },
  methods: {
    handleClickPrimaryButton() {
      if (this.page.name !== 'teams') {
        this.$router.push(this.page.primary_button_url);
      } else {
        eventBus.$emit('open_create_team_modal');
      }
    },
    isInternalLink(link = '') {
      return (
        link.startsWith(window.location.protocol + '//' + window.location.host) ||
        link.startsWith('//' + window.location.host) ||
        this.isJsLink(link) ||
        this.isRelativePath(link)
      );
    },
    isRelativePath(path = '') {
      return !!path.match(/^\/[a-z0-9-_%?#.]/i); // SHOULDDO: add some more special characters for valid url path
    },
    isJsLink(link = '') {
      return link.startsWith('javascript:');
    },
    init() {
      eventBus.$on('showModal', (link) => {
        if (link) {
          this.$emit('trigger-button-click', link, 'showModal', [], new Event('showModal'));
        } else {
          this.$emit('triggerShowModal');
        }
      });
      eventBus.$on('createNewChannel', (link, channel) => {
        this.$emit('trigger-button-click', link, 'createNewChannel', [channel], new Event('createNewChannel'));
      });
    },
    handleOpenModal() {
      this.openConfirmationModal = true;
    },
    handleClose() {
      this.openConfirmationModal = false;
    },
    handleCreateChannel(channel_type) {
      const { auth_link, inner_link } = channel_actions[channel_type];
      if (inner_link) {
        this.handleClose();
        this.$router.push(inner_link);
        return false;
      }
      this.redirectToAuthLinkAndFinalize(auth_link);
    },
    redirectToAuthLinkAndFinalize(auth_link) {
      request(auth_link, 'POST', {}).then((res) => {
        window.open(res.data.url, '_blank');
        this.handleClose();
      });
    },
    showPrimaryButton() {
      return (
        (this.page.primary_button_text && this.page.primary_button_url) ||
        (this.page.primary_button_text && this.page.open_channel_confirmation_modal)
      );
    },
    getEntitlementStatus(featureId, entitlementStatus) {
      return this.entitlementsStore?.getEntitlementStatusFor(featureId) === entitlementStatus;
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    $route(to, from) {
      this.init();
    },
  },
};
</script>

<style scoped lang="scss">
#badge {
  @apply h-8 w-fit px-3 py-[6px] text-sm font-semibold #{!important};
}
</style>
