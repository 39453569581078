<template>
  <t-button
    size="sm"
    :disabled="disabled"
    class="flex flex-row items-center justify-center"
    @click.prevent="$emit('addNote')"
  >
    <send2-fill class="mr-1.5" size="1.25rem" />
    {{ $t('tickets.composer_add_note_button') }}
  </t-button>
</template>

<script>
import { Send2Fill } from '@trengo/trengo-icons';

export default {
  name: 'AddNoteButton',
  emits: ['addNote'],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Send2Fill,
  },
};
</script>
