<template>
  <div class="h-full">
    <mobile-preview-card>{{ $t('csat.feature_only_available_on_desktop') }}</mobile-preview-card>
    <div class="hidden-md-down flex h-full w-full flex-row" :class="{ 'overflow-y-scroll': wasCompleteAtLoad }">
      <div class="left-column h-screen" :class="{ 'overflow-y-scroll': !wasCompleteAtLoad }">
        <div class="csat-survey flex w-full flex-col">
          <div class="z-10 bg-white pt-12">
            <div class="pl-20 pr-12">
              <a @click="goBackToOverview">
                <i class="far fa-arrow-left pr-2"></i>
                {{ $t('csat.back_to_overview') }}
              </a>
              <h2 class="pt-8">
                {{ survey.id ? $t('csat.edit_survey') : $t('csat.create_survey') }}
              </h2>
              <p class="title pt-12" style="color: #7d7d7d">
                <span id="survey-name" class="text-grey-900">
                  {{ $t('csat.survey_name') }}
                </span>
                {{ $t('csat.internal_use') }}
              </p>
              <atomic-input
                v-model="survey.name"
                size="lg"
                :placeholder="$t('csat.survey_name_placeholder')"
                :required="true"
              />
              <csat-survey-step-indicator :number="1" :caption="$t('csat.survey_text')" />
              <p class="title mt-8">
                {{ $t('csat.your_brand_name') }}
              </p>
              <atomic-input
                v-model="survey.company_name"
                size="lg"
                :placeholder="$t('csat.your_brand_name_placeholder')"
                :required="true"
                @focus="
                  companyNameFocus = true;
                  titleFocus = false;
                  questionFocus = false;
                  followUpQuestionFocus = false;
                  thankYouMessageFocus = false;
                  buttonUrlFocus = false;
                  buttonTextFocus = false;
                  mailButtonFocus = false;
                "
              />
              <p class="title mt-8">
                {{ $t('csat.survey_title') }}
              </p>
              <atomic-input
                v-model="survey.title"
                size="lg"
                :placeholder="$t('csat.survey_title_placeholder')"
                :required="true"
                @focus="
                  titleFocus = true;
                  companyNameFocus = false;
                  questionFocus = false;
                  followUpQuestionFocus = false;
                  thankYouMessageFocus = false;
                  buttonUrlFocus = false;
                  buttonTextFocus = false;
                  mailButtonFocus = false;
                "
              />
              <p class="title mt-8">
                {{ $t('csat.question') }}
              </p>
              <atomic-input
                v-model="survey.question"
                size="lg"
                :placeholder="$t('csat.question_placeholder')"
                :required="true"
                @focus="
                  questionFocus = true;
                  titleFocus = false;
                  companyNameFocus = false;
                  followUpQuestionFocus = false;
                  thankYouMessageFocus = false;
                  buttonUrlFocus = false;
                  buttonTextFocus = false;
                  mailButtonFocus = false;
                "
              />
              <p class="title mt-8">
                {{ $t('csat.email_button_text') }}
              </p>
              <atomic-input
                v-model="survey.button_text_email"
                size="lg"
                :placeholder="$t('csat.email_button_text_placeholder')"
                :required="true"
                @focus="
                  mailButtonFocus = true;
                  questionFocus = false;
                  titleFocus = false;
                  companyNameFocus = false;
                  followUpQuestionFocus = false;
                  thankYouMessageFocus = false;
                  buttonUrlFocus = false;
                  buttonTextFocus = false;
                "
              />
              <csat-survey-step-indicator :number="2" :caption="$t('csat.follow_up')" />
              <p class="title mt-8">
                {{ $t('csat.follow_up_question') }}
              </p>
              <atomic-input
                v-model="survey.follow_up_question"
                size="lg"
                :placeholder="$t('csat.follow_up_question_placeholder')"
                :required="true"
                @focus="
                  followUpQuestionFocus = true;
                  questionFocus = false;
                  titleFocus = false;
                  companyNameFocus = false;
                  thankYouMessageFocus = false;
                  buttonUrlFocus = false;
                  buttonTextFocus = false;
                  mailButtonFocus = false;
                "
              />
              <csat-survey-step-indicator :number="3" :caption="$t('csat.thank_you')" />
              <p class="title mt-8">
                {{ $t('csat.thank_you_message') }}
              </p>
              <t-textarea
                id="thankYouMessage"
                v-model="survey.thank_you_message"
                height="272px"
                :placeholder="$t('csat.thank_you_message_placeholder')"
                :required="true"
                @focus="
                  thankYouMessageFocus = true;
                  followUpQuestionFocus = false;
                  questionFocus = false;
                  titleFocus = false;
                  companyNameFocus = false;
                  buttonUrlFocus = false;
                  buttonTextFocus = false;
                  mailButtonFocus = false;
                "
              />
              <p class="title mt-8">
                {{ $t('csat.enable_button') }}
              </p>
              <atomic-toggle v-model="survey.enable_button"></atomic-toggle>
              <div v-if="survey.enable_button">
                <p class="title mt-8">
                  {{ $t('csat.button_text') }}
                </p>
                <atomic-input
                  v-model="survey.button_text_thank_you"
                  size="lg"
                  :placeholder="$t('csat.button_text_placeholder')"
                  :required="true"
                  @focus="
                    buttonTextFocus = true;
                    thankYouMessageFocus = false;
                    followUpQuestionFocus = false;
                    questionFocus = false;
                    titleFocus = false;
                    companyNameFocus = false;
                    buttonUrlFocus = false;
                    mailButtonFocus = false;
                  "
                />
                <p class="title mt-8">
                  {{ $t('csat.button_url') }}
                </p>
                <atomic-input
                  v-model="survey.button_url"
                  size="lg"
                  :placeholder="$t('csat.button_url_placeholder')"
                  :required="true"
                  @focus="
                    buttonUrlFocus = true;
                    buttonTextFocus = false;
                    thankYouMessageFocus = false;
                    followUpQuestionFocus = false;
                    questionFocus = false;
                    titleFocus = false;
                    companyNameFocus = false;
                    mailButtonFocus = false;
                  "
                />
              </div>
              <csat-survey-step-indicator ref="rules" :number="4" :caption="$t('csat.trigger_rules')" />
              <ul class="mb-0">
                <li>
                  {{ $t('csat.add_triggers') }}
                </li>
                <li>
                  {{ $t('csat.decide_channels') }}
                </li>
              </ul>
              <RuleCard
                v-for="rule in survey.rules"
                :key="rule.id"
                :rule="rule"
                :csat-id="survey.id"
                :amount-rules="survey.rules.length"
              ></RuleCard>
              <t-button
                size="md"
                class="mb-16 mt-12 block"
                :btn-style="survey.rules && survey.rules.length ? 'secondary' : 'primary'"
                @click="addNewRule"
              >
                <i class="far fa-plus mr-3"></i>
                {{ $t('csat.add_new_rule_set') }}
              </t-button>

              <div class="flex flex-col">
                <t-divider class="mb-3" />

                <div v-if="!isEntitled" class="mb-6 mt-6 flex flex-col">
                  <div class="flex items-center justify-between">
                    <p class="mb-0 text-base font-semibold text-grey-800">{{ $t('entitlement.trengo_branding') }}</p>
                    <div class="flex items-center">
                      <t-badge variant="upgrade" :text="minimumRequiredPlan" class="mr-3 w-fit">
                        <template #icon><arrow-top-circle-linear size="1rem" /></template>
                      </t-badge>

                      <label class="ui-switch m-t-xs">
                        <input v-model="survey.branding_enabled" type="checkbox" disabled />
                        <i></i>
                        <span class="label-style"></span>
                      </label>
                    </div>
                  </div>
                  <p
                    class="t-text-sm mt-1 text-grey-600"
                    v-html="$t('entitlement.customer_service__automation__csat__whitelabel')"
                  ></p>
                </div>

                <div v-else class="mb-6 mt-6 flex flex-col">
                  <div class="flex items-center justify-between">
                    <p class="mb-0 text-base font-semibold text-grey-800">{{ $t('entitlement.trengo_branding') }}</p>

                    <label class="ui-switch m-t-xs flex items-center">
                      <input v-model="survey.branding_enabled" type="checkbox" />
                      <i></i>
                      <span class="label-style"></span>
                    </label>
                  </div>
                  <p class="t-text-sm mt-1 text-grey-600">{{ $t('csat.include_powered_by_trengo') }}</p>
                </div>
              </div>
            </div>
            <div class="footer flex flex-row py-4 pl-20">
              <t-button size="md" class="mr-3" :disabled="formIncomplete" @click="publishCsat">
                {{ survey.id ? $t('csat.save_changes') : $t('csat.publish_csat') }}
              </t-button>
              <t-button size="md" btn-style="secondary" @click="saveAsDraft">
                {{ saveAsDraftLabel() }}
              </t-button>
              <t-button
                v-if="survey.id"
                size="md"
                btn-style="danger-alt"
                class="delete-button ml-3 flex text-error-500"
                @click="deleteCsat"
              >
                <trash-linear size="1rem" class="text-error-500" />
                <div class="pl-2">{{ $t('csat.delete_survey') }}</div>
              </t-button>
            </div>
          </div>
        </div>
      </div>
      <div class="right-column mx-10">
        <div v-if="computedTabs" class="mb-4 flex flex-row items-center justify-center pt-4">
          <t-tab-item
            v-for="(tab, i) in computedTabs"
            :key="tab.title"
            :is-active="i === activeTabKey"
            size="sm"
            :label="tab.title"
            @click="toggleActiveTab(i)"
          >
            <template #prefix>
              <component :is="tab.icon" size="1rem" />
            </template>
          </t-tab-item>
        </div>

        <div
          v-if="(!wasCompleteAtLoad && emailInputsFocus) || inputFocused"
          class="mb-4 text-center text-sm font-medium text-grey-800"
        >
          <template v-if="activeTabValue === 'email'">{{ $t('csat.what_your_email_will_look_like') }}</template>
          <template v-if="activeTabValue === 'messaging'">{{ $t('csat.what_your_message_will_look_like') }}</template>
        </div>
        <div
          :class="{
            'pt-4': wasCompleteAtLoad,
            'dynamic-content flex h-full flex-col justify-center': !wasCompleteAtLoad,
          }"
        >
          <email-header-preview-card
            v-if="showEmailPreview"
            :class="{
              animate: !wasCompleteAtLoad,
            }"
            :sender-and-date="activeTabValue === 'email'"
            class="preview-card"
          >
            <div class="survey-body">
              <div v-if="activeTabValue === 'email'" class="survey-company-name text-base font-bold">
                {{ survey.company_name || $t('csat.company_name_preview') }}
              </div>
              <div class="survey-question" :class="activeTabValue === 'email' ? 'email' : 'messaging'">
                {{ survey.question || $t('csat.question_preview') }}
              </div>
              <div v-if="activeTabValue === 'messaging'" class="survey-question">csat.trengo.com/example</div>
            </div>
          </email-header-preview-card>
          <email-content-preview-card
            v-show="activeTabValue === 'email' && emailInputFocused"
            :company_name="survey.company_name"
            :question="survey.question"
            :user="$root.user"
            :button_text="survey.button_text_email"
            :class="{
              animate: !wasCompleteAtLoad,
            }"
          />
          <survey-preview-card
            v-if="surveyInputFocused"
            :company-name="survey.company_name"
            :question="survey.question"
            :follow_up_question="survey.follow_up_question"
            :class="{
              animate: !wasCompleteAtLoad,
            }"
          />
          <thank-you-preview-card
            v-if="thankYouInputFocused"
            :thank_you_message="survey.thank_you_message"
            :enable_button="survey.enable_button"
            :button_text="survey.button_text_thank_you"
            :class="{
              animate: !wasCompleteAtLoad,
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommentLinear, EmailLinear, TrashLinear, ArrowTopCircleLinear } from '@trengo/trengo-icons';
import { mapStores } from 'pinia';

import MobilePreviewCard from '@/components/common/MobilePreviewCard';
import { FEATURE } from '@/Configs/Constants';
import { useEntitlementsStore } from '@/store/pinia';

import CsatSurveyStepIndicator from './CsatSurveyStepIndicator';
import EmailContentPreviewCard from './EmailContentPreviewCard';
import EmailHeaderPreviewCard from './EmailHeaderPreviewCard';
import RuleCard from './RuleCard';
import SurveyPreviewCard from './SurveyPreviewCard';
import ThankYouPreviewCard from './ThankYouPreviewCard';

export default {
  components: {
    ArrowTopCircleLinear,
    MobilePreviewCard,
    CsatSurveyStepIndicator,
    EmailHeaderPreviewCard,
    EmailContentPreviewCard,
    SurveyPreviewCard,
    ThankYouPreviewCard,
    RuleCard,
    TrashLinear,
    CommentLinear,
    EmailLinear,
  },

  data() {
    return {
      wasCompleteAtLoad: false,
      activeTabKey: 0,
      activeTabValue: 'email',
      companyNameFocus: false,
      titleFocus: false,
      questionFocus: false,
      followUpQuestionFocus: false,
      thankYouMessageFocus: false,
      buttonTextFocus: false,
      buttonUrlFocus: false,
      mailButtonFocus: false,
      survey: {
        name: '',
        enable_button: false,
        company_name: '',
        title: '',
        question: '',
        follow_up_question: '',
        thank_you_message: '',
        button_text_thank_you: '',
        button_url: '',
        button_text_email: '',
        branding_enabled: true,
      },
    };
  },

  methods: {
    focusChanged(event) {
      this.focusedElement = event.target;
    },
    goBackToOverview() {
      router.push('/admin/csat/');
    },
    isCompleteSurvey(survey) {
      return !(
        !survey.name ||
        !survey.company_name ||
        !survey.title ||
        !survey.question ||
        !survey.follow_up_question ||
        !survey.thank_you_message ||
        !survey.button_text_email ||
        (survey.enable_button === 1 && (!survey.button_text_thank_you || !survey.button_url))
      );
    },
    publishCsat() {
      if (this.survey.id) {
        axios
          .put('/api/v2/csat/' + this.survey.id, {
            ...this.survey,
            is_activated: this.survey.is_activated,
          })
          .then(() => {
            this.$router.push('/admin/csat/');
            this.flashSuccess(this.$t('csat.csat_updated_toast'));
          });
      } else {
        axios
          .post('/api/v2/csat', {
            ...this.survey,
            is_activated: true,
          })
          .then(() => {
            this.$router.push({
              name: 'csatLanding',
              query: { newCsat: true },
            });
          });
      }
    },
    saveAsDraft() {
      if (this.survey.id && this.wasCompleteAtLoad && this.isCompleteSurvey(this.survey)) {
        axios
          .put('/api/v2/csat/' + this.survey.id, {
            ...this.survey,
            is_activated: !this.survey.is_activated,
          })
          .then(() => {
            router.push('/admin/csat/');
            this.flashSuccess(this.$t('csat.csat_updated_toast'));
          });
      } else if (this.survey.id) {
        axios
          .put('/api/v2/csat/' + this.survey.id, {
            ...this.survey,
            is_activated: false,
          })
          .then(() => {
            router.push('/admin/csat/');
            this.flashSuccess(this.$t('csat.csat_updated_toast'));
          });
      } else {
        axios
          .post('/api/v2/csat', {
            ...this.survey,
            is_activated: false,
          })
          .then(() => {
            router.push('/admin/csat/');
          });
      }
    },
    saveAsDraftLabel() {
      if (this.survey.id && this.wasCompleteAtLoad && this.isCompleteSurvey(this.survey)) {
        if (this.survey.is_activated) {
          return this.$t('csat.disable_survey');
        } else {
          return this.$t('csat.enable_survey');
        }
      } else {
        return this.$t('csat.save_as_draft');
      }
    },
    addNewRule() {
      if (this.survey.id) {
        axios
          .put('/api/v2/csat/' + this.survey.id, {
            ...this.survey,
            is_activated: this.isCompleteSurvey(this.survey),
          })
          .then(() => {
            this.$router.push({
              name: 'ruleCreateFromCsat',
              params: {
                sentFromCsat: true,
                csatId: this.survey.id,
                csatName: this.survey.name,
              },
            });
          });
      } else {
        axios
          .post('/api/v2/csat', {
            ...this.survey,
            is_activated: this.isCompleteSurvey(this.survey),
          })
          .then((response) => {
            this.$router.push({
              name: 'ruleCreateFromCsat',
              params: {
                sentFromCsat: true,
                csatId: response.data.id,
                csatName: this.survey.name,
              },
            });
          });
      }
    },
    async deleteCsat() {
      const shouldDeleteSurvey = await this.$tConfirm(this.$t('csat.your_survey_will_delete_permanently'), {
        delete: true,
        title: this.$t('csat.delete_this_survey'),
      });
      if (shouldDeleteSurvey) {
        axios.delete('/api/v2/csat/' + this.survey.id).then(() => {
          this.flashSuccess(this.$t('csat.the_survey_deleted_successfully'));
          router.push('/admin/csat/');
        });
      }
    },
    toggleActiveTab(activeTabKey) {
      this.activeTabKey = activeTabKey;
      this.activeTabValue = this.computedTabs[activeTabKey].value;
    },
  },

  mounted() {
    if (this.$route.params.id) {
      axios.get('/api/v2/csat/' + this.$route.params.id).then((response) => {
        this.survey = response.data;
        this.wasCompleteAtLoad = this.isCompleteSurvey(this.survey);
        this.survey.branding_enabled = this.isEntitled ? response.data.branding_enabled : true;
      });
    }
  },

  created() {
    document.addEventListener('focusin', this.focusChanged);
  },

  beforeUnmount() {
    document.removeEventListener('focusin', this.focusChanged);
  },

  computed: {
    ...mapStores(useEntitlementsStore),
    formIncomplete() {
      return !this.isCompleteSurvey(this.survey);
    },
    showEmailPreview() {
      return (
        this.wasCompleteAtLoad || this.companyNameFocus || this.titleFocus || this.questionFocus || this.mailButtonFocus
      );
    },
    emailInputsFocus() {
      return this.companyNameFocus || this.titleFocus || this.questionFocus || this.mailButtonFocus;
    },

    inputFocused() {
      return (
        this.wasCompleteAtLoad ||
        this.companyNameFocus ||
        this.titleFocus ||
        this.questionFocus ||
        this.mailButtonFocus ||
        this.followUpQuestionFocus ||
        this.thankYouMessageFocus ||
        this.buttonTextFocus ||
        this.buttonUrlFocus
      );
    },
    emailInputFocused() {
      return (
        this.wasCompleteAtLoad || this.companyNameFocus || this.titleFocus || this.questionFocus || this.mailButtonFocus
      );
    },
    surveyInputFocused() {
      return this.wasCompleteAtLoad || this.followUpQuestionFocus;
    },
    thankYouInputFocused() {
      return this.wasCompleteAtLoad || this.thankYouMessageFocus || this.buttonTextFocus || this.buttonUrlFocus;
    },
    computedTabs() {
      return [
        { value: 'email', title: 'Email', icon: 'email-linear' },
        { value: 'messaging', title: 'Message', icon: 'comment-linear' },
      ];
    },
    minimumRequiredPlan() {
      return this.entitlementsStore?.getMinimumRequiredPlanFor(FEATURE.CUSTOMER_SERVICE__AUTOMATION__CSAT__WHITELABEL);
    },
    isEntitled() {
      return this.entitlementsStore?.isEntitledTo(FEATURE.CUSTOMER_SERVICE__AUTOMATION__CSAT__WHITELABEL);
    },
  },
};
</script>

<style lang="scss">
.csat-survey {
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    width: 607px;
  }

  #survey-name {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    width: 607px;
  }

  a {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }

  li {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }

  .footer {
    border-top: 1px solid var(--color-grey-200);
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
    position: sticky;
    left: 0px;
    bottom: 0px;
  }

  button.t-button.delete-button {
    @apply flex items-center;
  }
}

.right-column {
  max-width: 444px;
  width: 444px;

  .dynamic-content {
    margin-top: -81px; // vertically align preview cards in between end of starting content and end of page
  }

  .survey-question {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--color-black);
    font-weight: 500;
    font-size: 14px;

    &.email {
      width: 224px;
    }

    &.messaging {
      width: 400px;
    }
  }

  .survey-body {
    line-height: 1.4;
  }

  .survey-company-name {
    margin-bottom: 1px;
    line-height: 1.2;
  }
}

.left-column {
  width: 50%;
}

.preview-card {
  border-radius: 12px;
  width: 100%;
  background: var(--color-white);

  &:not(:last-child) {
    margin-bottom: 43px;
  }

  &.animate {
    animation: fadeIn 0.2s linear;
  }
}

.ui-switch .label-style {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: 30px;
  background: transparent;
}

.ui-switch input[type='checkbox']:disabled ~ .label-style {
  background: white;
  opacity: 0.6;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
