export const checkIfAfterDeadline = (
  deadlineUTCString: string,
  now: EpochTimeStamp
): { isAfterSoftDeadline: boolean; isAfterHardDeadline: boolean } => {
  if (!deadlineUTCString || !now) {
    throw new TypeError('unexpected argument(s)');
  }
  const deadline = new Date(deadlineUTCString);
  const deadlineTimestamp = deadline.getTime();
  const weekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  const weekBeforeDeadlineTimestamp = deadlineTimestamp - weekInMilliseconds;
  return {
    isAfterSoftDeadline: now > weekBeforeDeadlineTimestamp,
    isAfterHardDeadline: now > deadlineTimestamp,
  };
};

export const calculate24HoursFromNow = (now: EpochTimeStamp) => {
  const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
  return now + twentyFourHoursInMilliseconds;
};
