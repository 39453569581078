import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center gap-2" }
const _hoisted_2 = { class: "t-text-h3" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "text-md my-6 text-grey-700" }
const _hoisted_6 = { class: "text-sm text-grey-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_top_circle_linear = _resolveComponent("arrow-top-circle-linear")!
  const _component_t_badge = _resolveComponent("t-badge")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_modal = _resolveComponent("t-modal")!

  return (_openBlock(), _createBlock(_component_t_modal, {
    "close-on-backdrop-click": true,
    "close-icon": true,
    variant: "narrow",
    "is-open": _ctx.isOpen,
    "data-test": "integration-hub-upgrade-modal",
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('integration_hub.upgrade_modal_title')), 1 /* TEXT */),
        _createVNode(_component_t_badge, {
          variant: "upgrade",
          text: 'Boost'
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_arrow_top_circle_linear, { size: "1rem" })
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_t_button, {
        "btn-style": "secondary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.isFirstTime ? _ctx.$t('integration_hub.explore_integrations') : _ctx.$t('general.close')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      (_ctx.hasUpgradePermissions)
        ? (_openBlock(), _createBlock(_component_t_button, {
            key: 0,
            onClick: _ctx.handleUpgrade
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('entitlement.upgrade_plan_cta')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"]))
        : _createCommentVNode("v-if", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "",
          src: `${_ctx.$root.assetsURL}images/integration-hub/basic-plan-banner.png`,
          alt: ""
        }, null, 8 /* PROPS */, _hoisted_4),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('integration_hub.upgrade_modal_description')), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.upgradeModalInstructions), 1 /* TEXT */)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-open"]))
}