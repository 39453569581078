<template>
  <reporting-page :heading-title="$t('reports_v2.dashboard')" :updated-at="updatedAt" class="w-full">
    <div class="my-6 flex flex-col flex-nowrap justify-center gap-5 pb-0">
      <stats
        :new-tickets="newTickets"
        :assigned-tickets="assignedTickets"
        :resolution-time="resolutionTime"
        :first-response-time="firstResponseTime"
        :is-loaded="isLoaded"
      />
    </div>
    <div class="my-8">
      <average-tickets-created-chart />
    </div>
    <div class="w-full">
      <online-status />
    </div>
  </reporting-page>
</template>

<script lang="ts">
import moment from 'moment';
import { defineComponent } from 'vue';

import ReportingPage from '@/components/common/ReportingPage';
import { callMockData } from '@/components/Reporting/api';
import { CsatAggregateMockData } from '@/components/Reporting/api/mocks';
import AverageTicketsCreatedChart from '@/components/Reporting/Components/V2/Charts/Overview/AverageTicketCreated/Index.vue';
import OnlineStatus from '@/components/Reporting/Components/V2/OnlineStatus/OnlineStatus.vue';
import { useReportStore } from '@/components/Reporting/store';
import { convertSecondsToMinutesAndSeconds } from '@/components/Reporting/Util';
import eventBus from '@/eventBus';
import breakpoints from '@/util/breakpoints';

import { fetchTicketsAggregates, fetchCsatScore } from './api';
import Stats from './Stats.vue';

import type { CsatParams } from '@/components/Reporting/api/types';
import type { Filter } from '@/components/Reporting/Components/V2/FilterDropdown/FilterDropdown.vue';

type OverviewData = {
  newTickets: number;
  assignedTickets: number;
  resolutionTime: string;
  firstResponseTime: string;
  breakpoints: object;
  ticketsAggregatesApiParams: string[];
  isLoaded: boolean;
  reportStore: ReturnType<typeof useReportStore>;
  filters: string;
};

export default defineComponent({
  name: 'Overview',
  components: { Stats, ReportingPage, OnlineStatus, AverageTicketsCreatedChart },
  data(): OverviewData {
    return {
      newTickets: 0,
      assignedTickets: 0,
      resolutionTime: '0m 0s',
      firstResponseTime: '0m 0s',
      breakpoints,
      ticketsAggregatesApiParams: [
        'new_tickets',
        'assigned_tickets',
        'average_total_resolution_time',
        'average_first_response_time',
      ],
      isLoaded: false,
      reportStore: useReportStore(),
      filters: '',
    };
  },

  mounted() {
    this.filters = this.mapFilters(JSON.parse(this.$route.query.filters || '""'));
    this.getTicketsAggregates();
    this.reportStore.$patch({
      reportingFilters: {
        hasBusinessHours: true,
      },
    });
    eventBus.$on('reporting-filter', this.fetchTicketsByFilters);
  },

  unmounted() {
    this.reportStore.$patch({
      reportingFilters: {
        hasBusinessHours: false,
        hasAgent: false,
      },
    });
    eventBus.$off('reporting-filter', this.fetchTicketsByFilters);
  },
  computed: {
    updatedAt() {
      const now = this.getRoundedTime();

      return `${this.$t('reports_v2.updated')} ${now}`;
    },
  },
  methods: {
    getRoundedTime() {
      let currentTime = moment().tz(this.$root.user.timezone);

      let minutes = currentTime.minutes();
      let roundedMinutes = Math.floor(minutes / 3) * 3;
      currentTime = currentTime.minutes(roundedMinutes).seconds(0).milliseconds(0);

      return currentTime.format('HH:mm');
    },
    mapFilters(filtersObject: object) {
      let serializedFilters = '';

      for (const key in filtersObject) {
        const currentFilter = filtersObject[key];
        let filterString;
        if (typeof currentFilter === 'object') {
          filterString = currentFilter.map((filter: number | string) => `${key}[]=${filter}`).join('&');
        } else {
          filterString = `${key}=${currentFilter}`;
        }
        serializedFilters = `${serializedFilters ? serializedFilters + '&' : ''}${filterString}`;
      }

      return serializedFilters;
    },
    fetchTicketsByFilters(filters: Filter) {
      this.filters = this.mapFilters(filters);
      this.getTicketsAggregates();
    },
    async getTicketsAggregates() {
      fetchTicketsAggregates(this.ticketsAggregatesApiParams, this.filters)
        .then((data) => {
          this.newTickets = data.data.aggregates.new_tickets;
          this.assignedTickets = data.data.aggregates.assigned_tickets;
          this.resolutionTime = convertSecondsToMinutesAndSeconds(data.data.aggregates.average_total_resolution_time);
          this.firstResponseTime = convertSecondsToMinutesAndSeconds(data.data.aggregates.average_first_response_time);

          this.isLoaded = true;
        })
        .catch((error) => {
          console.error(error);
          this.newTickets = 0;
          this.assignedTickets = 0;
          this.resolutionTime = convertSecondsToMinutesAndSeconds(0);
          this.firstResponseTime = convertSecondsToMinutesAndSeconds(0);
          this.isLoaded = true;
        });
    },
    async getCsarAggregate() {
      const currentStart = moment().subtract(4, 'weeks').unix();
      const currentEnd = moment().unix();

      const params: CsatParams = {
        period_start: currentStart,
        period_end: currentEnd,
        interval: 'week',
        timezone: this.$root.user.timezone,
      };

      fetchCsatScore(params).then((data) => {
        console.log(data);
      });

      const response = await callMockData(CsatAggregateMockData);
      this.csatScore = response.data.csat_score_percentage;
    },
  },
});
</script>
