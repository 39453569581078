<template>
  <div v-if="loaded" class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis" v-text="record.name"></span>
        </li>
      </ul>
    </div>

    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <div class="p-a p-b-0 container">
            <div class="p-a">
              <form @submit.prevent="save">
                <div class="box">
                  <div class="box-header">
                    <h2>{{ $t('company_profile.subscription_page_business_hours') }}</h2>
                  </div>

                  <div class="box-divider m-a-0"></div>

                  <div class="box-body">
                    <first-step
                      v-if="recordExists"
                      :is-create="action === 'create'"
                      :timezones="timezones"
                      :record="record"
                      :channels="channelsList"
                      @change="handleChangeRecord"
                    />

                    <second-step
                      v-if="isLgorXl && recordExists"
                      :action="action"
                      :days="record.days"
                      @change="handleChangeDays"
                    />

                    <second-step-mobile
                      v-if="!isLgorXl && recordExists"
                      :days="record.days"
                      @change="handleChangeDays"
                    />

                    <component :is="record.type" v-model="record.meta" />
                  </div>
                </div>

                <div class="box">
                  <div class="box-body flow-root">
                    <button
                      type="submit"
                      :class="{ loader: saving }"
                      class="btn success text-white"
                      :disabled="saving || !ready"
                      v-text="saving ? 'Saving..' : $t('company_profile.subscription_page_save_changes')"
                    />

                    <button
                      v-if="action === 'edit'"
                      type="button"
                      class="btn white pull-right"
                      @click="deleteBusinessHours()"
                    >
                      {{ $t('company_profile.subscription_page_delete_business_hours') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { map } from 'lodash';
import { mapGetters } from 'vuex';

import Timezones from '@/components/Pages/Sections/Timezones';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import eventBus from '@/eventBus';
import RouterWarningOnEdit from '@/mixins/RouterWarningOnEdit';
import { request } from '@/util';
import breakpoints from '@/util/breakpoints';

import Steps from './components';
import { DEFAULT_DAYS } from './Utils';

import type { Channel } from '@/types';
import type { BusinessHours } from '@/types/businessHours';

export default {
  name: 'BusinessHoursEdit',

  components: {
    'second-step': Steps.SecondStep,
    'second-step-mobile': Steps.SecondStepMobile,
    'first-step': Steps.FirstStep,
  },

  mixins: [RouterWarningOnEdit],

  beforeRouteLeave(to, from, next) {
    this.onBeforeRouteLeaveGuard(to, from, next);
  },

  beforeRouteUpdate(to, from, next) {
    this.onBeforeRouteUpdateGuard(to, from, next);
  },

  props: {
    action: {
      type: String,
      default: 'create',
    },
  },

  data() {
    return {
      SETTINGS_URL,
      breakpoints,
      timezones: Timezones,
      loaded: false,
      record: {} as BusinessHours,
      saving: false,
      defaultDays: DEFAULT_DAYS,
      channels: [] as Channel[],
    };
  },

  computed: {
    ...mapGetters({
      channelsList: 'channels/businessHoursChannelsList',
      businessHour: 'business_hours/businessHour',
      unavailableChannels: 'business_hours/unavailableChannels',
    }),

    recordExists() {
      return Object.keys(this.record).length > 0;
    },

    ready() {
      return true;
    },

    isLgorXl() {
      const { comparisons } = this.breakpoints;
      return comparisons.eq_lg || comparisons.eq_xl;
    },
  },

  watch: {
    businessHour(value) {
      this.record = value;
      this.initWarning();
    },
  },

  mounted() {
    if (this.action === 'edit') {
      this.$store.dispatch({ type: 'business_hours/fetchBusinessHour', id: this.$route.params.id });
    } else {
      this.record = {
        name: 'General',
        timezone: this.$root.user.timezone,
        description: '',
        days: DEFAULT_DAYS,
      };
    }
    this.fetchInitialData();
  },

  methods: {
    handleChangeRecord(record: BusinessHours) {
      this.record = record;
    },

    handleChangeDays(days: BusinessHours['days']) {
      this.record.days = days;
    },

    fetchInitialData() {
      this.initWarning();
      this.$store.dispatch('channels/fetchChannels', true);
    },

    save() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      if (this.action === 'create') {
        return this.storeBusinessHours();
      } else {
        this.updateBusinessHours();
      }
    },

    storeBusinessHours() {
      const data = { ...{ channel_ids: this.mapChannelIds() }, ...this.record };
      request('/api/v2/business_hours', 'POST', data)
        .then((res) => {
          this.saving = false;
          this.initWarning();
          eventBus.$emit('load_business_hours');
          this.$nextTick(() => this.$router.push(`${SETTINGS_URL.ADMIN__BUSINESS_HOURS}/${res.data.id}`));
          this.flashSuccess(this.$t('company_profile.business_hours_created_successfully'));
          this.$store.dispatch({ type: 'business_hours/fetchBusinessHours', force_fetch: true });
        })
        .catch(() => {
          this.saving = false;
        });
    },

    updateBusinessHours() {
      const data = { ...{ channel_ids: this.mapChannelIds() }, ...this.record };
      request(`/api/v2/business_hours/${this.record.id}`, 'PUT', data)
        .then((_) => {
          this.saving = false;
          this.initWarning();
          eventBus.$emit('load_business_hours');
          this.flashSuccess(this.$t('company_profile.business_hours_updated_successfully'));
          this.$store.dispatch({ type: 'business_hours/fetchBusinessHours', force_fetch: true });
        })
        .catch(() => {
          this.saving = false;
        });
    },

    async deleteBusinessHours() {
      const shouldDeleteBusinessHours = await this.$tConfirm(
        this.$t('company_profile.are_you_sure_to_delete_business_hours'),
        {
          delete: true,
          title: this.$t('general.are_you_sure'),
        },
      );

      if (shouldDeleteBusinessHours) {
        this.initWarning();
        request(`/api/v2/business_hours/${this.record.id}`, 'DELETE').then(() => {
          eventBus.$emit('load_business_hours');
          this.$router.push(SETTINGS_URL.ADMIN__BUSINESS_HOURS);
          this.flashSuccess(this.$t('company_profile.business_hours_deleted_successfully'));
          this.$store.dispatch({ type: 'business_hours/fetchBusinessHours', force_fetch: true });
        });
      }
    },

    fetchChannels() {
      request(`/api/v2/channels`, 'GET').then((res) => {
        this.channels = res.data.data.filter((c) => {
          return c.type !== 'HELP_CENTER';
        });
      });
    },

    mapChannelIds() {
      return map(this.record.channels, 'id');
    },
  },
};
</script>
