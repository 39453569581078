import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-1 text-sm font-semibold text-grey-700" }
const _hoisted_2 = {
  key: 1,
  class: "row p-a"
}
const _hoisted_3 = { class: "mb-1 text-sm font-semibold text-grey-700" }
const _hoisted_4 = { class: "t-text-sm-emphasize m-0 p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_field_item = _resolveComponent("custom-field-item")!
  const _component_info_linear = _resolveComponent("info-linear")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.aligned && _ctx.fields.length > 0)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.fields, (field) => {
          return (_openBlock(), _createElementBlock("div", {
            key: field.id,
            class: "nav-item m-b-xs"
          }, [
            _createElementVNode("div", _hoisted_1, _toDisplayString(field.title), 1 /* TEXT */),
            _createVNode(_component_custom_field_item, {
              field: field,
              onSave: ($event: any) => (_ctx.save(field))
            }, null, 8 /* PROPS */, ["field", "onSave"])
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      : (_ctx.aligned && _ctx.fields.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
              return (_openBlock(), _createElementBlock("div", {
                key: field.id,
                class: "m-b-xs"
              }, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(field.title), 1 /* TEXT */),
                _createVNode(_component_custom_field_item, {
                  field: field,
                  onSave: ($event: any) => (_ctx.save(field))
                }, null, 8 /* PROPS */, ["field", "onSave"])
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createBlock(_component_t_inline_banner, {
            key: 2,
            type: "default"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_info_linear)
            ]),
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$tc('ticket_sidebar.no_profile_fields_setup')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
  ]))
}