<template>
  <div>
    <div v-if="mode === 'list'">
      <a class="btn btn-block btn-default mb-4" @click="mode = 'create'">Create deal</a>
      <div
        v-for="deal in deals"
        :key="deal.dealId"
        class="box"
        @mouseover="hoverIndex = deal.dealId"
        @mouseout="hoverIndex = null"
      >
        <div class="box-body">
          <div class="flex">
            <label class="text-muted mb-1">Deal</label>
            <span v-show="hoverIndex == deal.dealId" class="text-muted ml-auto">
              <a @click="editDeal(deal)"><i class="fa fa-pencil text-xs"></i></a>
              <a @click="deleteDeal(deal)"><i class="fa fa-remove text-xs"></i></a>
            </span>
          </div>
          <div>
            <a class="text-info" :href="getDealUrl(deal)" target="_blank">
              <strong>
                <span v-if="hasProperty(deal, 'amount')">
                  <template v-if="isInt(deal['amount'])">&euro;</template>
                  {{ deal.amount }}
                </span>
                <span v-if="hasProperty(deal, 'name')">{{ deal.name }}</span>
                <span v-else>-</span>
              </strong>
            </a>
          </div>

          <div v-if="settings.hasDealStatus">
            <label class="text-muted mb-1 mt-2">Status</label>
            <div v-if="hasProperty(deal, 'status_id')">
              <div v-if="!Number.isInteger(deal.status_id)" class="text-capitalize">{{ deal.status_id }}</div>
              <template v-else>{{ getDealStatus(deal.status_id) }}</template>
            </div>
            <div v-else>-</div>
          </div>

          <label class="text-muted mb-1 mt-2">Stage</label>
          <div v-if="hasProperty(deal, 'stage_id')">
            {{ getDealStage(deal.stage_id, deal.pipeline) }}
          </div>
          <div v-else>-</div>

          <label class="text-muted mb-1 mt-2">Create Date</label>
          <div v-if="hasProperty(deal, 'created')">{{ deal.created }}</div>
          <div v-else>-</div>
          <label class="text-muted mb-1 mt-2">Close Date</label>
          <div v-if="hasProperty(deal, 'close_date')">{{ deal.close_date }}</div>
          <div v-else>-</div>
          <label class="text-muted mb-1 mt-2">Owner</label>
          <div v-if="hasProperty(deal, 'owner_id')">{{ getOwner(deal.owner_id).full_name }}</div>
          <div v-else>-</div>
        </div>
      </div>
      <div v-if="loading">
        <loader></loader>
      </div>
    </div>

    <div v-if="mode === 'create' || mode === 'edit'">
      <div class="box">
        <div class="box-body">
          <form @submit.prevent="onSubmit">
            <div class="form-group">
              <label class="text-muted mb-1">Deal name</label>
              <input v-model="form.name" class="form-control" />
            </div>
            <div class="form-group">
              <label class="text-muted mb-1">Pipeline</label>
              <select v-model="form.pipeline" class="form-control">
                <option v-for="(v, k) in getDealPipelines()" :key="k" :value="k">{{ v }}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="text-muted mb-1">Stage</label>
              <select v-model="form.stage_id" class="form-control">
                <option v-for="(v, k) in getDealStages(form.pipeline)" :key="k" :value="k">{{ v }}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="text-muted mb-1">Amount</label>
              <input v-model="form.amount" class="form-control" />
            </div>
            <div class="form-group">
              <label class="text-muted mb-1">Close date</label>
              <datepicker v-model="form.close_date" format="d-m-Y" class="form-control"></datepicker>
            </div>
            <div class="form-group">
              <label class="text-muted mb-1">Owner</label>
              <select v-model="form.owner_id" class="form-control mb-2">
                <option value=""></option>
                <option v-for="owner in owners" :key="owner" :value="owner.owner_id">
                  {{ owner.full_name }} ({{ owner.email }})
                </option>
              </select>
            </div>

            <button
              class="btn success btn-block mt-2 text-white"
              type="submit"
              :disabled="saving"
              :class="{ loader: saving }"
            >
              Save
            </button>
          </form>
        </div>
      </div>
      <div class="text-center">
        <a class="text-muted text-xs" @click="cancel()">Cancel</a>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from '../../Elements/DatePicker';
import Loader from '../Loader';

export default {
  name: 'CrmDeals',
  components: {
    Datepicker,
    Loader,
  },

  props: {
    plugin: { type: Object, default: () => ({}) },
    ticket: { type: Object, default: () => ({}) },
    attributes: { type: Object, default: () => ({}) },
    settings: { type: Object, default: () => ({}) },
    hasProperty: { type: Function, default: () => ({}) },
    getOwner: { type: Function, default: () => ({}) },
    getDealStages: { type: Function, default: () => ({}) },
    getDealStatus: { type: Function, default: () => ({}) },
    getDealPipelines: { type: Function, default: () => ({}) },
    owners: { type: Array, default: () => [] },
  },

  data() {
    return {
      mode: 'list',
      deals: [],
      form: {},
      hoverIndex: null,
      dealProperties: {},
      dealPipelines: [],
      saving: false,
      loading: true,
    };
  },

  mounted() {
    this.resetForm();
    this.loadDeals();
  },

  methods: {
    getDealUrl(deal) {
      if (this.plugin.type === 'hubspot') {
        return (
          'https://app.hubspot.com/contacts/' + this.attributes.account_details.portalId + '/deals/' + deal.deal_id
        );
      } else if (this.plugin.type === 'pipedrive') {
        return this.attributes.company.company_domain + deal.path;
      }
      return deal.url;
    },

    getDealType(type) {
      return this.dealProperties.find((s) => s.name == 'dealtype').options.find((o) => o.value == type).label;
    },

    getDealSource(source) {
      return this.getDealSources().find((s) => s.value == source.value) || {};
    },

    getDealTypes() {
      return this.dealProperties.find((s) => s.name == 'dealtype').options;
    },

    getDealSources() {
      return this.dealProperties.find((s) => s.name == 'hs_analytics_source').options;
    },

    createEngagement() {},

    onTypeChange() {
      if (this.form.type === 'MEETING') {
        this.initDatepicker();
      }
    },

    loadDeals() {
      axios
        .get('/api/v2/integrations/crm/deals?plugin_id=' + this.plugin.id + '&ticket_id=' + this.ticket.id)
        .then((res) => {
          this.deals = res.data;
          this.loading = false;
        });
    },

    async deleteDeal(deal) {
      const shouldDeleteDeal = await this.$tConfirm('Are you sure you want to delete this deal?', {
        delete: true,
        title: this.$t('general.are_you_sure'),
      });
      if (shouldDeleteDeal) {
        axios.delete('/api/v2/integrations/crm/deals/' + deal.deal_id + '?plugin_id=' + this.plugin.id).then(() => {
          this.loadDeals();
        });
      }
    },

    editDeal(deal) {
      this.mode = 'edit';
      this.form = deal;
      this.form.id = deal.deal_id;
    },

    onSubmit() {
      if (this.saving) {
        return;
      }
      this.saving = true;
      let url =
        this.mode === 'edit'
          ? '/api/v2/integrations/crm/deals/' +
            this.form.id +
            '?plugin_id=' +
            this.plugin.id +
            '&ticket_id=' +
            this.ticket.id
          : '/api/v2/integrations/crm/deals?plugin_id=' + this.plugin.id + '&ticket_id=' + this.ticket.id;
      let method = this.mode === 'edit' ? 'put' : 'post';

      axios[method](url, this.form)
        .then((res) => {
          this.mode = 'list';
          this.resetForm();
          this.loadDeals();
          this.saving = false;
        })
        .catch(() => {
          this.saving = false;
        });
    },

    resetForm() {
      this.form = {
        name: '',
        amount: '',
        pipeline: 'default',
        created: moment().format('DD-MM-YYYY'),
        close_date: moment().add(7, 'd').format('DD-MM-YYYY'),
      };
    },

    cancel() {
      this.resetForm();
      this.mode = 'list';
    },

    // https://stackoverflow.com/a/14794066/3344029
    isInt(value) {
      if (isNaN(value)) {
        return false;
      }
      var x = parseFloat(value);
      return (x | 0) === x;
    },
  },
};
</script>

<style scoped>
.stage {
  border-radius: 12px;
  margin-right: 4px;
  height: 4px;
  transition: background-color 0.25s ease;
  background-color: #eaf0f6;
}

.stage--active {
  background-color: #fb9d95;
}
</style>
