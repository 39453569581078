<script type="text/babel">
import IconClass from './IconClass';
export default {
  computed: {
    iconClass: function () {
      return IconClass[this.channel];
    },
  },
  props: {
    channel: null,
  },
};
</script>

<template>
  <i :class="iconClass"></i>
</template>
