<template>
  <div class="flex flex-col items-center">
    <img :src="`${$root.assetsURL}img/trengo_logo.svg`" alt="trengo" class="logo-trengo mb-4" />
    <div class="inline-flex items-center">
      <t-spinner />
      <div class="t-text-desktop-paragraph-md ml-2.5 text-grey-600">
        {{ $t('whatsapp.hold_on_creating_your_whatsapp_channel') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepLoading',
  emits: ['stepLoading'],
  mounted() {
    this.$emit('stepLoading');
  },
};
</script>

<style scoped lang="scss">
.logo-trengo {
  width: 166px;
  height: 39px;
}
</style>
