import eventBus from '@/eventBus';

import store from './store';

export default {
  init(user) {
    $('body').on('click', 'a', function (e) {
      if ($(this).hasClass('open-external')) {
        e.preventDefault();
        parent.postMessage('app-browse-url:' + encodeURI($(this).attr('href')), '*');
      }
    });

    $('body').addClass('mobile-app-context');

    // Listen for event
    window.addEventListener('message', (e) => {
      if (!e || typeof e.data == 'object' || e.data == null) {
        return;
      }

      if (e.data.startsWith('app-show-ticket:chat:')) {
        let data = e.data.split('app-show-ticket:chat:')[1].split(':');
        let thread = store.getters['chat/threadById'](data[0]);

        vue_redirect('/chat/' + thread.getIdentifier() + '/' + data[1]);
      } else if (e.data.startsWith('app-show-ticket:')) {
        vue_redirect('/tickets/' + e.data.split('app-show-ticket:')[1]);
      }

      if (e.data == 'app-resume') {
        console.log('r');
        window.hasWindowFocus = true;
        window.lastWindowFocus = new Date().getTime();
        eventBus.$emit('interval-resume');
      }
      if (e.data == 'app-pause') {
        console.log('p');
        window.hasWindowFocus = false;
      }
      if (e.data == 'history.prev') {
        if (window.location.pathname === '/tickets') {
          // parent.postMessage("app.quit");
        } else {
          window.history.back();
        }
      }
      if (e.data === 'scroll.top') {
        eventBus.$emit('scroll.top');
      }
    });

    // Check if authenticated
    if (user && user.id) {
      // Send message to parent for push notifications
      setTimeout(() => {
        parent.postMessage('user:' + CHANNEL_PREFIX + '_' + user.id, '*');
      }, 1000);
    }
  },
};
