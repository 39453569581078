<template>
  <div class="mt-auto h-full rounded-b-md bg-sun-100 p-4" data-test="internalCommentComposerWrapper">
    <div class="pos-relative flex h-full flex-col">
      <form class="note flex flex-1 flex-col" @submit.prevent>
        <div class="flex-1 bg-sun-100">
          <content-editable
            id="privateComposer"
            ref="editor"
            v-model="message"
            :file-paste="true"
            class="message-editor flex-1 overflow-auto rounded-b-md"
            style="min-height: 72px"
            max-height="400px"
            :placeholder="$t('tickets.composer_add_note_placeholder')"
            data-test="internalCommentComposerContentEditable"
            @focus="focus = true"
            @blur="onBlur"
            @enter="onEnter"
            @file-paste="onFilePaste"
          />
        </div>

        <div v-if="attachments.length > 0" class="mt-4 overflow-auto pr-4" style="max-height: 12rem">
          <message-attachment
            v-for="attachment in attachments"
            :key="attachment.id"
            :attachment="attachment"
            @delete-attachment="deleteAttachment"
          />
        </div>

        <div class="mt-4 flex select-none justify-between">
          <GenerateSummaryCTA v-if="ticket?.id" :ticket-id="ticket.id" />
          <div class="flex ml-auto flex-row items-center gap-x-2">
            <div>
              <t-button
                ref="emojiButton"
                v-tooltip="{
                  placement: 'top',
                  content: $t('tickets.insert_emoji'),
                  delay: { show: 500, hide: 0 },
                }"
                size="sm"
                :icon-only="true"
                :btn-style="'secondary'"
                data-test="internalCommentComposerEmojiPicker"
                @click.prevent="toggleEmojiPicker"
              >
                <template #icon>
                  <emotion-smile-linear size="1.25rem" />
                </template>
              </t-button>
              <div
                v-if="showEmojiPicker"
                ref="emojiContainer"
                v-click-away="
                  () => {
                    showEmojiPicker = false;
                  }
                "
                class="absolute bottom-12 right-12"
                :style="computedEmojiContainerStyle"
              >
                <emoji-picker @insert-emoji="insertEmoji" />
              </div>
            </div>

            <attachment-selector
              :is-redesign="isInternalCommentAttachmentRedesignEnabled"
              color-class="text-black"
              data-test="internalCommentComposerAddAttachmentButton"
              @attachment="addAttachment"
            />

            <add-note-button :disabled="!isValid()" data-test="internalCommentAddNoteButton" @add-note="send" />
          </div>
        </div>
        <div ref="bottom"></div>
      </form>
    </div>

    <confirm-mention-unauthorized-users-modal @accept="confirmMentioningUserIdsWithoutAccess" />
  </div>
</template>

<script lang="ts">
import { EmotionSmileLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';
import { mixin as VueClickAway } from 'vue3-click-away';

import { sendConsolidatedMessage } from '@/api/modules/messages';
import ConfirmMentionUnauthorizedUsersModal from '@/components/ConfirmMentionUnauthorizedUsersModal.vue';
import ContentEditable from '@/components/ContentEditable.vue';
import EmojiPicker from '@/components/Elements/EmojiPicker.vue';
import MessageAttachment from '@/components/MessageFeed/MessageAttachment.vue';
import AddNoteButton from '@/components/ReplyForm/AddNoteButton.vue';
import AttachmentSelector from '@/components/ReplyForm/MultipleAttachmentSelector.vue';
import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useFeatureFlagStore } from '@/store/pinia';
import { getFileExtension, getByteFormat, MAX_FILE_SIZE, ALLOWED_FILE_EXTENSIONS } from '@/util/fileHelper';
import { flashError } from '@/util/flashNotification';
import { cachedRequest } from '@/util/request';
import { escapeHtml } from '@/util/stringHelpers';
import taggable from '@/util/Taggable';

import {
  createNote,
  createNoteDraft,
  deleteNoteDraft,
  deleteNoteDraftAttachment,
  fetchNoteDraft,
  postNoteDraftAttachment,
} from './api';
import GenerateSummaryCTA from './GenerateSummary/GenerateSummaryCTA.vue';

export default defineComponent({

  name: 'InternalCommentComposer',

  components: {
    AddNoteButton,
    AttachmentSelector,
    ConfirmMentionUnauthorizedUsersModal,
    ContentEditable,
    EmojiPicker,
    EmotionSmileLinear,
    GenerateSummaryCTA,
    MessageAttachment,
  },

  mixins: [VueClickAway],

  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },
    publicProvider: {
      type: String,
      default: '',
    },
    messageCount: {
      type: Number,
      default: 0,
    },
  },
  emits: ['generate-summary'],

  data() {
    return {
      attachments: [],
      pendingUploads: 0,
      focus: false,
      showEmojiPicker: false,
      message: '',
      dragenter: false,
      sending: false,
      draft: {},
      tribute: {
        isActive: false,
      },
      dirty: true,
      sent: false,
      users: [],
    };
  },

  computed: {
    isInternalCommentAttachmentRedesignEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.INTERNAL_COMMENT_ATTACHMENTS);
    },

    isConsolidatedMessageEndpointEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.CONSOLIDATED_MESSAGE_ENDPOINT_ENABLED);
    },

    totalAttachmentSize() {
      return this.attachments.reduce((accumulator, attachment) => accumulator + attachment.realSize, 0);
    },

    computedEmojiContainerStyle() {
      return this.messageCount < 2 ? 'height: 150px' : '';
    },

    isMessageEmpty() {
      return !this?.message || this.stripWhitespace(this?.message) === '';
    },
  },

  async created() {
    try {
      const response = await fetchNoteDraft(this.ticket.id);

      this.message = response.data.message || '';
      this.attachments = response.data.attachments;
    } catch (err) {
      console.error(err);
    } finally {
      this.onMessageUpdate();
    }
  },

  mounted() {
    this.$nextTick(() => {
      cachedRequest({ method: 'get', url: '/client-api/users/list' }, true).then((res) => {
        this.users = res.data.users;
        this.initMention();
      });
    });
  },

  methods: {
    onBlur() {
      this.focus = false;
      this.processDraft();
    },

    onEnter() {
      this.send();
    },

    /**
     * {@link https://linear.app/trengo/issue/IO-212 IO-212} breakage investigation
     *
     * This silently breaks if converted into a computed, I suspect due to the Taggable.js library.
     * It's hard to debug the true reason, but I suspect that `tribute.isActive` isn't reactive as
     * expected, since the library isn't Vue-specific and this object is a simple `data` one. There
     * are no errors, and stepping in with a debugger would make it seem like the values would
     * evaluate properly to `true | false`, but somewhere in the render chain this all breaks subtly
     */
    isValid() {
      if (this.sending || this.pendingUploads > 0 || this.tribute.isActive) {
        return false;
      }

      return !this.isMessageEmpty || this?.attachments.length > 0;
    },

    async processDraft() {
      if (this?.message?.length > 0) {
        await createNoteDraft(this.ticket.id, this.message);
      } else {
        if (this.attachments.length === 0) {
          await this.deleteDraft();
        }
      }
    },

    async deleteDraft() {
      this.message = '';
      this.attachments = [];
      this.onMessageUpdate();
      await deleteNoteDraft(this.ticket.id);
    },

    stripWhitespace(string) {
      if (!string) {
        return '';
      }

      return string
        .replace(/&nbsp;/g, ' ')
        .replace(/<br>/g, '\n')
        .trim();
    },

    async send() {
      if (this.sending || !this.isValid()) {
        return;
      }

      const mentionedUserIdsWithoutAccess = this.mentionedUserIdsWithoutAccess();

      if (mentionedUserIdsWithoutAccess.length) {
        eventBus.$emit('modals.confirm-mention.open', mentionedUserIdsWithoutAccess);
        return;
      }

      await this.$nextTick();
      await this.processMessage();
    },

    mentionedUserIdsWithoutAccess() {
      const mentionedUserIds = [];
      const mentionedUserIdsWithoutAccess: number[] = [];
      const matches = this?.message?.match(/@([\w-]+)/g);
      if (!matches) {
        return [];
      }
      matches.forEach((m) => {
        const userId = m.match(/[0-9]+/);
        if (userId && userId[0]) {
          mentionedUserIds.push(parseInt(userId[0]));

          if (!taggable.userIdsWithAccess.includes(parseInt(userId[0]))) {
            mentionedUserIdsWithoutAccess.push(parseInt(userId[0]));
          }
        }
      });
      return mentionedUserIdsWithoutAccess;
    },

    confirmMentioningUserIdsWithoutAccess() {
      this.tribute = taggable.reInit(this.$refs.editor.$el, this.ticket.id, this.$root.users);
      this.processMessage();
    },

    // TODO: This entire method was copied over from the old implementation; As such, we should make it a priority to rework this in the future and simplify things a bit.
    async processMessage() {
      this.sending = true;

      // Replace "<br>".
      let message = this.message?.replace(/<br>/gi, '\n');
      message = message.replace(/<br \/>/gi, '\n');
      message = message.replace(/<br\/>/gi, '\n');

      // remove html-tags
      message = window.stripHtml(message);

      // replace '<' with entities, (like htmlspecialchars)
      message = escapeHtml(message);

      if (this.isConsolidatedMessageEndpointEnabled) {
        this.handleConsolidatedMessage(message);
      } else {
        const formData = {
          message: message,
          attachment_ids: [],
          internal_note: true,
        };

        if (this?.attachments?.length > 0) {
          for (let i = 0, len = this.attachments.length; i < len; i++) {
            formData.attachment_ids.push(this.attachments[i]['id']);
          }
        }

        const originalMessage = this.message || '';
        this.message = '';
        this.onMessageUpdate();
        this.$refs.editor.$el.focus();

        try {
          const resp = await createNote(this.ticket.id, formData);
          eventBus.$emit('user-internal-comment-on-first-ticket');
          this.onMessageSent(resp.data.message);
          this.sent = true;
        } catch {
          this.message = originalMessage;
          this.sending = false;
          this.onMessageUpdate();
        }

        this.sending = false;
      }
    },

    async handleConsolidatedMessage(message) {
      try {
        const payload = {
          channel_id: this?.ticket?.channel?.id,
          ticket_id: this?.ticket?.id || null,
          subject: '',
          type: 'generic_message',

          messaging: {
            message,
            is_note: true,
            attachment_ids: this?.attachments?.map((attachment) => attachment.id) || [],

            // The majority of these are simply the default state; Should they even be included?
            recipients: [],
            cc: [],
            bcc: [],
            send_later: false,
            close_after_send: false,
            deliver_method: null,
            ticket_result_id: null,
            forwarded_message_id: null,
            forwarding_ticket_id: null,
            mark_as_read: false,
          },
        };

        sendConsolidatedMessage(payload)
          .then((response) => {
            eventBus.$emit('user-internal-comment-on-first-ticket');
            this.message = '';
            this.deleteDraft();
            this.sent = true;
            this.tribute.isActive = false;

            if (!this.publicProvider) {
              eventBus.$emit('ticket.note.added', response.data.message);
            }
          })
          .finally(() => {
            this.sending = false;
          });
      } catch (err) {
        console.error(err);

        this.message = message;
        this.sending = false;
        this.onMessageUpdate();
      }
    },

    onMessageSent(message) {
      this.sending = false;
      this.attachments = [];
      this.deleteDraft();

      if (!this.publicProvider) {
        eventBus.$emit('ticket.note.added', message);
      }
      this.tribute.isActive = false;
    },

    onMessageUpdate() {
      if (this?.$refs?.editor?.$el) {
        this.$refs.editor.$el.innerHTML = this.message || '';
      }
    },

    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },

    insertEmoji(emoji) {
      this.$nextTick(() => {
        const editor = this.$refs.editor.$el;

        this.showEmojiPicker = false;
        window.insertAtCursor(editor, emoji.emoji);
        this.message = editor.innerHTML;
        editor.focus({ preventScroll: true });
        window.placeCaretAtEnd(editor);
      });
    },

    async addAttachment(data) {
      if (
        data.realSize > MAX_FILE_SIZE ||
        this.totalAttachmentSize > MAX_FILE_SIZE ||
        this.totalAttachmentSize + data.realSize > MAX_FILE_SIZE
      ) {
        // TI-575: Not happy about having to use jQuery here, but I don't think we have a native popup notification like this built yet
        flashError(this.$t('attachment.total_upload_size_cant_exceed_bytes', { kilobytes: '20,000' }));
        return;
      }

      const formData = new FormData();
      formData.append('file', data.obj);

      data.uploading = true;
      data.client_name = data.name;
      data.id = null;
      this.attachments.push(data);
      this.pendingUploads++;
      this.scrollToBottom();

      postNoteDraftAttachment(this.ticket.id, formData)
        .then((response) => {
          data.client_name = response.data.client_name;
          data.uploading = false;
          data.full_url = response.data.full_url;
          data.id = response.data.id;
          this.pendingUploads--;
        })
        .catch(() => {
          this.attachments.splice(this.attachments.indexOf(data), 1);
          data.uploading = false;
          this.pendingUploads--;
        });
    },

    scrollToBottom() {
      // need to do inside setTimeout because the attachment list needs to be rendered before scrollIntoView().
      setTimeout(() => {
        this.$refs.bottom.scrollIntoView({ behavior: 'smooth' });
      });
    },

    async deleteAttachment(attachment) {
      this.attachments.splice(this.attachments.indexOf(attachment), 1);

      await deleteNoteDraftAttachment(this.ticket.id, attachment.id);
    },

    initMention() {
      this.tribute = taggable.initUsersTaggable(this.$refs.editor.$el, this.ticket.id, this.users);

      this.$refs.editor.$el.addEventListener('tribute-replaced', () => {
        this.message = this.$refs.editor.$el.innerHTML || '';
        this.$nextTick(() => {
          document
            .querySelector('.remove-prev')
            .addEventListener(
              'DOMNodeRemoved',
              (e) => e.target.previousElementSibling && e.target.previousElementSibling.remove(),
            );
        });
      });
    },

    onFilePaste(attachment) {
      const file = {
        name: attachment['name'],
        obj: attachment,
        extension: getFileExtension(attachment['name']),
        size: getByteFormat(attachment['size']),
        realSize: attachment['size'],
      };

      if (!file || !ALLOWED_FILE_EXTENSIONS.includes(file.extension.toLowerCase())) {
        flashError(this.$t('attachment.unsupported_file_type'));

        return;
      }

      this.addAttachment(file);
    },
  },
});
</script>

<style lang="scss" src="./InternalCommentComposer.scss" scoped />
