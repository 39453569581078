<template>
  <div class="cf-input-group rounded-lg bg-grey-100 px-6 py-4">
    <div class="cf-input-group-title pb-4 text-sm font-bold leading-6 text-black">{{ title }}</div>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'CustomFieldInputGroup',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
