import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "mb-4 flex flex-col rounded-lg",
  "data-test": "integration-image-carousel"
}
const _hoisted_2 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex flex-row gap-2" }
const _hoisted_5 = ["src", "onClick"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_card = _resolveComponent("t-card")!

  return (_openBlock(), _createBlock(_component_t_card, {
    class: "flex flex-col bg-white",
    "data-test": "integration-detail-wrapper"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.integration.screenshots.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("img", {
                class: "rounded-lg border-1 border-solid border-grey-300",
                src: `${_ctx.$root.assetsURL}${_ctx.integration.screenshots[_ctx.selectedScreenshotIndex]}`,
                alt: ""
              }, null, 8 /* PROPS */, _hoisted_3)
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.integration.screenshots, (screenshot, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createElementVNode("img", {
                class: _normalizeClass(["screenshot-thumbnail", { selected: _ctx.selectedScreenshotIndex === index }]),
                src: `${_ctx.$root.assetsURL}${screenshot}`,
                alt: "",
                onClick: ($event: any) => (_ctx.selectScreenshot(index))
              }, null, 10 /* CLASS, PROPS */, _hoisted_5)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _createElementVNode("p", {
        class: "text-base text-grey-800",
        "data-test": "integration-detail-long-description",
        innerHTML: _ctx.$tc(_ctx.integration.longDescription)
      }, null, 8 /* PROPS */, _hoisted_6)
    ]),
    _: 1 /* STABLE */
  }))
}