import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2ce5ccc8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-8 w-full rounded-xl border border-grey-300 bg-white p-6" }
const _hoisted_2 = { class: "flex flex-row items-center justify-between" }
const _hoisted_3 = { class: "flex flex-row items-center" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "t-text-h5 m-0 text-grey-800" }
const _hoisted_6 = { class: "label-test segment-wrapper w-32" }
const _hoisted_7 = {
  key: 1,
  class: "animation-loading h-8 w-32 rounded-xl bg-grey-300"
}
const _hoisted_8 = {
  "data-test": "workload-management-chart",
  class: "relative mt-6 h-88"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chart_column_linear = _resolveComponent("chart-column-linear")!
  const _component_t_thumbnail = _resolveComponent("t-thumbnail")!
  const _component_t_segmented_control = _resolveComponent("t-segmented-control")!
  const _component_hourly_average_conversations_created_loaded = _resolveComponent("hourly-average-conversations-created-loaded")!
  const _component_hourly_average_conversations_created_loading = _resolveComponent("hourly-average-conversations-created-loading")!
  const _component_daily_average_conversations_created_loaded = _resolveComponent("daily-average-conversations-created-loaded")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_t_thumbnail, { class: "mr-3 bg-white" }, {
          default: _withCtx(() => [
            _createVNode(_component_chart_column_linear, { size: "1.4rem" })
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('reports_v2.average_conversations_created')), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.isLoaded)
          ? (_openBlock(), _createBlock(_component_t_segmented_control, {
              key: 0,
              size: "sm",
              "active-index": 0,
              segments: _ctx.segments,
              onChange: _ctx.switchSegment
            }, null, 8 /* PROPS */, ["segments", "onChange"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7))
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.showHourlyAverageConversations)
        ? (_openBlock(), _createBlock(_component_hourly_average_conversations_created_loaded, {
            key: 0,
            "created-conversations": _ctx.hourlyCreatedConversations,
            "show-error": _ctx.showHourlyError
          }, null, 8 /* PROPS */, ["created-conversations", "show-error"]))
        : _createCommentVNode("v-if", true),
      (!_ctx.isLoaded)
        ? (_openBlock(), _createBlock(_component_hourly_average_conversations_created_loading, { key: 1 }))
        : _createCommentVNode("v-if", true),
      (_ctx.showDailyAverageConversations)
        ? (_openBlock(), _createBlock(_component_daily_average_conversations_created_loaded, {
            key: 2,
            "created-conversations": _ctx.dailyCreatedConversations,
            "show-error": _ctx.showDailyError
          }, null, 8 /* PROPS */, ["created-conversations", "show-error"]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}