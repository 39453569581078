/* eslint-disable @typescript-eslint/no-var-requires */
import eventBus from '@/eventBus';
import { duplicateObject } from '@/util/helpers';
import { randomString } from '@/util/stringHelpers';
class Voip {
  /**
   ** @param token
   * @param user
   * @param is_mobile
   */
  constructor(token, user, is_mobile = false) {
    this.user = user;
    this.token = token;
    this.twilio = null;
    this.events = {};
    this.connection = null;
    this.pusherChannel = null;
    this.isMobile = is_mobile;
    this.channel = null;
  }

  /**
   * @return void
   */
  initialize() {
    if (this.isMobile) {
      setTimeout(() => {
        this.initPusher();
        this.trigger('initialized');
      }, 200);
    } else {
      this.loadTwilio().then(() => {
        this.initToken().then(() => {
          this.initProvider();
          this.initPusher();
          this.trigger('initialized');
        });
      });
    }
  }

  loadTwilio() {
    return new Promise((resolve) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.onload = () => resolve();
      script.src = 'https://media.twiliocdn.com/sdk/js/client/releases/1.7.1/twilio.min.js';
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }

  initToken(channel_id = null) {
    return new Promise((resolve) => {
      if (this.isMobile) {
        resolve();
        return;
      }

      let args = {
        region: 'de1',
        debug: true,
        closeProtection: true,
      };
      if (channel_id) {
        axios.get('/client-api/voip/token?channel_id=' + channel_id).then((res) => {
          Twilio.Device.setup(res.data.token, args);
          setTimeout(resolve, 200);
        });
      } else {
        axios.get('/client-api/voip/token').then((res) => {
          Twilio.Device.setup(res.data.token, args);
          setTimeout(resolve, 200);
        });
      }
    });
  }

  /**
   * @return void
   */
  initProvider() {
    Twilio.Device.connect((connection) => {
      this.connection = connection;

      connection.on('warning', (warningName) => {
        if (warningName === 'low-mos') {
          this.trigger('poor-connection');
        }
      });

      connection.on('warning-cleared', (warningName) => {
        if (warningName === 'low-mos') {
          this.trigger('poor-connection-cleared');
        }
      });

      if (connection.type == 'outbound') {
        // noop
      }

      if (connection.message.type == 'OUTBOUND') {
        axios
          .get(
            '/client-api/voip/tickets/contact?phone=' +
              connection.message.PhoneNumber +
              '&channel_id=' +
              connection.message.ChannelId
          )
          .then((res) => {
            this.trigger('outbound_call_ringing', {
              contact: res.data.contact,
              ticket_id: res.data.ticket_id,
              user_id: res.data.user_id,
              channel: res.data.channel,
            });
          });
      }

      if (connection.message.type == 'INBOUND') {
        this.trigger('inbound_call_initialized');
      }

      if (connection.message.type == 'INTERN') {
        this.trigger('on_intern_call_initialized');
      }
    });

    Twilio.Device.error((error) => {
      console.log(error);

      // JWt expired
      if (
        error.code == 31205 ||
        error.code == 31204 ||
        error.code == 31207 ||
        error.code == 31202 ||
        error.code == 31000
      ) {
        $.getJSON('/client-api/voip/token?e=' + error.code).then((data) => {
          Twilio.Device.setup(data.token, {
            region: 'de1',
            debug: false,
            closeProtection: true,
          });
        });
      } else {
        this.trigger('error', error);
      }
    });
  }

  /**
   * @return void
   */
  initPusher() {
    this.pusherChannel = window.PusherInstance.subscribe('private-voip-' + CHANNEL_PREFIX);

    this.pusherChannel.bind('InboundCall', (data) => {
      this.trigger('on_inbound_call', data);
    });

    this.pusherChannel.bind('ConferenceStarted', (data) => {
      this.trigger('on_call_started', data);
    });

    this.pusherChannel.bind('OutboundMobileCallStarted', (data) => {
      this.trigger('outbound_call_ringing', data);
    });

    this.pusherChannel.bind('CallEnded', (data) => {
      this.trigger('on_call_ended', data);
    });

    this.pusherChannel.bind('client-' + this.user.id + '.intern-call', (data) => {
      this.trigger('on_intern_call_inbound', data);
    });

    this.pusherChannel.bind('client-' + this.user.id + '.intern-call-rejected', (data) => {
      this.trigger('on_intern_call_rejected', data);
    });

    this.pusherChannel.bind('client-call-transferred', (data) => {
      this.trigger('on_call_transferred', data);
    });

    this.pusherChannel.bind('client-' + this.user.id + '.intern-call-cancelled', (data) => {
      this.trigger('on_intern_call_cancelled', data);
    });

    this.pusherChannel.bind('client-team.intern-call', (data) => {
      this.trigger('on_intern_team_call', data);
    });

    this.pusherChannel.bind('client-team.intern-call-cancelled', (data) => {
      this.trigger('on_intern_team_call_cancelled', data);
    });

    this.pusherChannel.bind('ConferenceThirdPartyJoined', (data) => {
      this.trigger('on_intern_call_accepted', data);
    });

    this.pusherChannel.bind('ConferenceThirdPartyLeft', (data) => {
      this.trigger('on_intern_call_third_party_left', data);
    });

    this.pusherChannel.bind('CallAcceptedByExternalPhone', (data) => {
      this.trigger('on_external_phone_accepted_call', data);
    });

    this.pusherChannel.bind('client-accepted-call', (data) => {
      this.trigger('on_client_accepted_call', data);
    });
  }

  /**
   * @param mute
   */
  setMute(mute) {
    if (this.isMobile) {
      return;
    }

    if (Twilio.Device.activeConnection() == null) {
      console.log('No active connection to mute');
      return;
    }

    Twilio.Device.activeConnection().mute(mute);
  }

  /**
   * @param hold
   * @param token
   * @returns {*|i}
   */
  setHold(hold, token) {
    return new Promise((resolve) => {
      axios.post('/voip-api/hold/' + token, { hold: hold }).then(() => {
        resolve();
      });
    });
  }

  /**
   * @return void
   */
  endCall() {
    if (!this.isMobile) {
      Twilio.Device.disconnectAll();
    }

    this.connection = null;
  }

  /**
   * @param phone
   * @param channel_id
   * @param provider
   */
  startCall(phone, channel_id, provider) {
    this.initToken(channel_id).then(() => {
      if (provider === 'TWILIO_SANDBOX') {
        eventBus.$emit('voip.sandbox_reject_outbound_call');
        return;
      }

      try {
        var PNF = require('google-libphonenumber').PhoneNumberFormat;
        var phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
        var phoneNumber = phoneUtil.parse(phone, $('.multiselect__input').intlTelInput('getSelectedCountryData').iso2);

        let callToken = randomString(30);

        if (this.user.voip_device === 'MOBILE' || this.isMobile) {
          axios
            .post('/api/v2/voip/mobile_outbound', {
              token: callToken,
              phone_number: phoneUtil.format(phoneNumber, PNF.INTERNATIONAL),
              channel_id: channel_id,
            })
            .then(() => {
              this.trigger('outbound_mobile_call_initiated', {
                token: callToken,
              });
            });
        } else {
          Twilio.Device.connect({
            PhoneNumber: phoneUtil.format(phoneNumber, PNF.INTERNATIONAL),
            ChannelPrefix: CHANNEL_PREFIX,
            ChannelId: channel_id,
            UserId: this.user.id,
            type: 'OUTBOUND',
            token: callToken,
          });

          this.trigger('outbound_call_initiated', {
            token: callToken,
          });
        }
      } catch (e) {
        this.trigger('error', {
          message: 'The provided phone number is invalid.',
        });
      }
    });
  }

  /**
   * @param token
   */
  acceptInboundCall(token) {
    setTimeout(() => {
      Twilio.Device.connect({
        type: 'INBOUND',
        token: token,
      });
    }, Math.round(Math.random() * 1000)); // Random 1 to 1000ms to prevent duplicate answers
  }

  /**
   * @param token
   */
  acceptInternCall(token) {
    Twilio.Device.connect({
      type: 'INTERN',
      token: token,
    });
  }

  /**
   * @param event
   * @param data
   */
  trigger(event, data) {
    this.events[event](data);
  }

  /**
   * @param name
   * @param callback
   */
  on(name, callback) {
    this.events[name] = callback;
  }

  transferToUser(user, call) {
    call.intern = true;
    call.internCall.user = user;

    let from = duplicateObject(this.user);
    from.teams = null;

    this.pusherChannel.trigger('client-' + call.internCall.user.id + '.intern-call', {
      call: call,
      from: from,
    });
  }

  transferToTeam(team, call) {
    call.intern = true;

    let from = duplicateObject(this.user);
    from.teams = null;

    this.pusherChannel.trigger('client-team.intern-call', {
      call: call,
      target: team.id,
      from: from,
    });
  }

  /**
   * @param phone
   * @param call
   */
  transferToPhone(phone, call) {
    var PNF = require('google-libphonenumber').PhoneNumberFormat;
    var phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
    var phoneNumber = phoneUtil.parse(phone, $('.multiselect__input').intlTelInput('getSelectedCountryData').iso2);

    axios.post('/voip-api/participant/' + call.token, { phone: phoneUtil.format(phoneNumber, PNF.INTERNATIONAL) });
  }

  executeTransfer(call) {
    return new Promise((resolve, reject) => {
      $.post('/voip-api/transfer/' + call.token, {
        user_id: call.internCall.user.id,
        referer: call.userId,
      })
        .then(() => {
          this.pusherChannel.trigger('client-call-transferred', {
            call: call,
            user: call.internCall.user,
            user_id: call.internCall.user.id,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  cancelTransfer(call) {
    if (call.internCall.user.is_extern == true) {
      $.post('/voip-api/cancel-transfer/' + call.token);
    } else if (call.internCall.team_id != null) {
      this.pusherChannel.trigger('client-team.intern-call-cancelled', {
        target: call.internCall.team_id,
        token: call.token,
      });
    } else {
      this.pusherChannel.trigger('client-' + call.internCall.user.id + '.intern-call-cancelled', {});
    }
  }

  rejectInternCall(user_id) {
    this.pusherChannel.trigger('client-' + user_id + '.intern-call-rejected', {});
  }

  sendAcceptedCallEvent(call) {
    let user = duplicateObject(this.user);

    user.teams = null;

    this.pusherChannel.trigger('client-accepted-call', {
      call: call,
      user: user,
    });

    axios.post('/voip-api/cancel-mobile-forward/' + call.token);
  }

  dtmf(key) {
    if (this.isMobile) {
      return;
    }

    if (Twilio.Device.activeConnection() == null) {
      return;
    }

    Twilio.Device.activeConnection().sendDigits(key);
  }
}

export default Voip;
