import { mapValues } from 'lodash';

import { CHANNEL_TYPE } from '@/Configs/Constants';

/**
 *
 * @returns {{FACEBOOK: string, INSTAGRAM: string, CHAT: string,  VOIP: string, WHATSAPP: string, EMAIL: string, TELEGRAM: string, HELP_CENTER: string, GBM: string, SMS: string, WA_BUSINESS: string}}
 * @constructor
 */
export function ChannelsIconsMapper() {
  const mapped_channels = mapValues(CHANNEL_TYPE, function (value) {
    return `${Assets_path}img/channels_icons/${value.toLowerCase()}.svg`;
  });
  return mapped_channels;
}

export default { ChannelsIconsMapper };
