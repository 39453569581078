<template>
  <div class="block h-screen overflow-y-scroll md:h-full">
    <div class="mx-4 flex flex-col items-start px-0 pb-40 pt-20 leading-none md:px-2 md:pb-20 lg:mx-20">
      <t-feature-icon :icon="FEATURE_ICON.WIDGET" class="mb-8" />

      <div class="settings-landing-content">
        <h1 class="mb-3 max-w-full text-4xl font-bold text-grey-800 xl:max-w-md">
          {{ $t('admin_inboxes.web_widgets') }}
        </h1>
        <t-badge v-if="!isEntitled" id="upgrade-badge" variant="upgrade" :text="entitlementUpgradeBadgeText">
          <template #icon><arrow-top-circle-linear size="1.25rem" /></template>
        </t-badge>
        <p class="mt-3 text-base font-medium text-grey-700">
          {{ $t('admin_inboxes.web_widgets_intro') }}
        </p>
        <section class="mt-8 flex w-full flex-col md:w-auto xl:flex-row">
          <t-button
            v-if="isEntitled"
            size="md"
            class="open-external mb-4 mr-0 xl:mb-0 xl:mr-4"
            @click="$router.push('/admin/web_widgets/create')"
          >
            {{ $t('admin_inboxes.add_web_widget') }}
          </t-button>

          <t-button
            v-if="!isEntitled"
            btn-style="primary"
            size="md"
            class="mb-4 mr-0 xl:mb-0 xl:mr-4"
            @click="$router.push({ name: 'subscription' })"
          >
            <div class="flex items-center justify-center">
              <arrow-top-circle-linear size="1.25rem" class="mr-1" />
              {{ entitlementUpgradeButtonText }}
            </div>
          </t-button>

          <t-button
            size="md"
            btn-style="secondary"
            href="https://help.trengo.com/en/articles/68462-add-a-website-widget-to-your-trengo-inbox"
            rel="noreferrer noopener"
            target="_blank"
            class="open-external flex flex-row items-center"
          >
            {{ $t('admin_inboxes.learn_more') }}
            <external-link-linear class="ml-1" />
          </t-button>
        </section>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ExternalLinkLinear, ArrowTopCircleLinear } from '@trengo/trengo-icons';
import axios from 'axios';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import TFeatureIcon from '@/components/common/TFeatureIcon';
import { FEATURE, FEATURE_ICON } from '@/Configs/Constants';
import { useEntitlementsStore } from '@/store/pinia';
export default defineComponent({
  name: 'EntitlementRequiredPage',
  components: { ArrowTopCircleLinear, ExternalLinkLinear, TFeatureIcon },

  data() {
    return {
      widgetCount: 0,
      FEATURE_ICON,
    };
  },

  async beforeMount() {
    await this.loadList();
  },

  computed: {
    ...mapStores(useEntitlementsStore),
    user() {
      return this.$root.user;
    },
    isEntitled() {
      const featureId = FEATURE.CUSTOMER_SERVICE__AUTOMATION__WIDGET;
      return this.entitlementsStore?.isEntitledTo(featureId, this.widgetsMinimumQuantity);
    },
    minimumRequiredPlan() {
      return this.entitlementsStore?.getMinimumRequiredPlanFor(
        FEATURE.CUSTOMER_SERVICE__AUTOMATION__WIDGET,
        this.widgetsMinimumQuantity
      );
    },
    entitlementUpgradeButtonText() {
      return this.$t('entitlement.upgrade_to_plan', { plan: this.minimumRequiredPlan });
    },
    entitlementUpgradeBadgeText() {
      return this.$t('entitlement.upgrade_to_plan_long', { plan: this.minimumRequiredPlan });
    },
    widgetsMinimumQuantity() {
      return this.widgetCount + 1;
    },
  },

  methods: {
    async loadList(reload_root = true) {
      axios.get('/api/v2/web_widgets').then((result) => {
        this.widgetCount = result?.data?.meta?.total ?? 0;

        if (reload_root) {
          this.$root.web_widgets = this.web_widgets;
        }
      });
    },
  },
});
</script>

<style scoped lang="scss">
@import 'src/assets/sass/typography.scss';

#upgrade-badge {
  @apply t-text-sm-emphasize h-8 w-fit px-3 py-[6px];
}
</style>
