<script setup lang="ts">
import { ArrowCornerCwRTLinear, EmailLinear } from '@trengo/trengo-icons';
import { NYLAS_API_V3 } from '@/Configs/Constants/ChannelLandingPageTypes';
import { authenticateChannel } from '@/api/modules/email';
import router from '@/routes/routes';

const modalOpen = defineModel<boolean>();

const authenticate = async () => {
  try {
    const res = await authenticateChannel(NYLAS_API_V3);
    window.location.href = res.data.url;
  } catch (err) {
    console.log(err);
  }
};
const handleClose = () => {
  modalOpen.value = false;
};
const handleRedirect = () => {
  handleClose();
  router.push('/admin/channels2/email/create?type=TRENGO');
};
</script>

<template>
  <t-modal v-model="modalOpen" variant="narrow" @close="handleClose">
    <template #header>
      <h5 class="modal-title text-3xl font-bold">
        {{ $t('email_settings.email_landing_page_button_connect_email') }}
      </h5>
    </template>
    <p class="pr-16">
      {{ $t('email_settings.receive_and_send_emails_directly_from_your_trengo_inbox') }}
    </p>
    <div class="mt-6 flex flex-col space-y-3">
      <t-button
        data-test="email-settings-email-connect-modal-button-connect-email"
        btn-style="secondary"
        class="relative flex items-center"
        @click="authenticate"
      >
        <email-linear class="h-6 w-6" />
        <div class="w-full text-center">
          {{ $t('email_settings.email_landing_page_button_connect_email') }}
        </div>
        <t-badge text="Beta" class="absolute right-2" />
      </t-button>
      <t-button
        data-test="email-settings-email-connect-modal-create-forwarding-channel"
        btn-style="secondary"
        class="relative flex items-center"
        @click="handleRedirect"
      >
        <arrow-corner-cw-r-t-linear size="1.2rem" />
        <div class="w-full text-center">
          {{ $t('email_settings.email_connect_modal_create_forwarding_channel') }}
        </div>
      </t-button>
    </div>
  </t-modal>
</template>
