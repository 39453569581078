<template>
  <div class="row-body">
    <admin-section-title :title="$t('settings.quick_replies')"></admin-section-title>
    <div class="row-col">
      <div class="row-col">
        <settings-item-list
          v-if="showItems"
          :title="$t('settings.quick_replies')"
          type="quick_replies"
          :is-infinite="true"
          route-name="quickRepliesEdit"
          :load-more="fetchList"
          :refresh-counter="refreshCounter"
          :items="quick_replies"
        />
        <div v-if="showContent && loaded" class="row-col">
          <router-view :key="$route.params.id" @call-parent-loadlist-method="loadList"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import SettingsItemList from '@/components/common/SettingsItemList';

export default {
  components: {
    SettingsItemList,
  },

  data() {
    return {
      page: 0,
      quick_replies: [],
      items: [],
      refreshCounter: 0,
      loaded: false,
    };
  },
  computed: {
    showItems() {
      const hasQuickRepliesAndFeatureFlagEnabled = this.isFeatureFlagEnabled && this.quick_replies.length > 0;
      return (
        !this.isFeatureFlagEnabled ||
        (hasQuickRepliesAndFeatureFlagEnabled && !this.isCreateRoute() && !this.$route.params.id)
      );
    },
    showContent() {
      const isInEditPageAndFeatureFlagEnabled =
        this.isFeatureFlagEnabled && (Boolean(this.$route.params.id) || this.isCreateRoute());
      return !this.isFeatureFlagEnabled || isInEditPageAndFeatureFlagEnabled || this.quick_replies.length === 0;
    },
    isFeatureFlagEnabled() {
      return false; // @todo add feature flag for Quick replies
      // return useFeatureFlagStore().isEnabled(FEATURE_FLAG_SETTINGS.TA_SETTINGS_PAGE);
    },
  },
  watch: {
    $route(value) {
      if (value.fullPath === '/admin/quick_replies' && this.isFeatureFlagEnabled) {
        this.loaded = false;
        this.fetchList();
      }
    },
  },
  mounted() {
    if (this.isFeatureFlagEnabled) {
      this.fetchList();
    }
  },
  methods: {
    isCreateRoute() {
      return this.$route.fullPath === '/admin/quick_replies/create';
    },
    loadList() {
      this.page = 0;
      this.quick_replies = [];
      this.refreshCounter++; // resets the infinite-scroll
    },
    fetchList(scroll) {
      axios
        .get('/api/v2/quick_replies?page=' + (this.page + 1))
        .then((result) => {
          if (!result.data.data.length) {
            scroll?.complete();
          } else {
            const getItems = result.data.data?.map((item) => {
              return { name: item.title, id: item.id };
            });
            this.quick_replies.push(...getItems);
            this.page = parseInt(result.data.meta.current_page);
            scroll?.loaded();
            if (result.data.meta.current_page === result.data.meta.last_page) {
              scroll?.complete();
            }
          }
        })
        .catch(() => {
          scroll?.complete();
        })
        .finally(() => {
          this.loaded = true;
        });
    },
  },
};
</script>
