<template>
  <t-modal
    v-model="computedValue"
    class="add-entity-modal"
    variant="narrow"
    initial-min-height="35rem"
    is-closable
    @close="onCancel"
  >
    <template #header>
      <div data-test="add-entity-modal-header" class="flex w-full flex-col">
        <div class="mx-3 mb-4 flex flex-row items-center justify-center sm:mx-0 md:items-start md:justify-between">
          <div class="flex flex-col">
            <div class="t-text-h4 md:t-text-h3 text-grey-800">
              {{ computedTitle }}
            </div>
            <div v-if="typeIsChannel" class="t-text-md mt-1 hidden text-grey-700 md:block">
              {{ computedSubHeader }}
            </div>
            <div class="t-text-desktop-label-xs block text-center md:hidden">
              {{ $t('team.team_member_selected', { length: selectedEntitiesLength }) }}
            </div>
          </div>
        </div>
        <search-entities v-model="computedSearchKey" data-test="search-entity" />
      </div>
    </template>

    <div v-if="!noEntities" class="add-entity-modal-main">
      <list-entities
        v-model="computedEntities"
        data-test="list-entities"
        :label-by="labelBy"
        :entity-type="entityType"
        :has-workload="hasWorkload"
        :selected-entities="selectedEntities"
        @select-entity="handleSelectedEntities"
      />
    </div>
    <div v-else class="not-found-main h-full overflow-hidden">
      <no-entity-found data-test="entity-not-found" :entity-type="entityType" :message="computedMessage" />
    </div>

    <template #footer>
      <div class="hidden w-full items-center justify-start md:flex">
        <div v-show="selectedEntitiesLength" class="t-text-desktop-label-md text-grey-900" data-test="selected-number">
          {{ $t('team.team_member_selected', { length: selectedEntitiesLength }) }}
        </div>
      </div>
      <t-button size="md" btn-style="secondary" data-test="modal-cancel-btn" @click="onCancel">
        {{ $t('general.cancel') }}
      </t-button>
      <t-button :disabled="!selectedEntitiesLength" size="md" data-test="add-entity-modal-btn" @click="onAdd">
        {{ $t('general.add') }}
      </t-button>
    </template>
  </t-modal>
</template>

<script>
import { findIndex } from 'lodash';

import breakpoints from '@/util/breakpoints';

import ListEntities from './components/ListEntities';
import NoEntityFound from './components/NoEntityFound';
import SearchEntities from './components/SearchEntities';

export default {
  name: 'AddEntityModal',
  emits: ['cancel', 'update:modelValue', 'submit', 'entity'],
  components: {
    SearchEntities,
    ListEntities,
    NoEntityFound,
  },
  data() {
    return {
      breakpoints,
      selectedEntities: [],
      searchKey: '',
      entities: [],
      noEntities: false,
      noEntityMessage: '',
    };
  },
  props: {
    searchBy: {
      type: String,
      default: 'name',
    },
    labelBy: {
      type: String,
      default: 'name',
    },
    entityType: {
      type: String,
      default: 'channel',
      validate: (value) => ['channel', 'member'].includes(value),
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: Array,
      default: () => [],
    },
    hasWorkload: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    entity(val) {
      this.computedOldEntities = val;
      this.computedEntities = val;
    },
  },
  mounted() {
    this.computedOldEntities = this.entity;
    this.computedEntities = this.entity;
  },
  computed: {
    computedMessage: {
      get() {
        return this.noEntityMessage;
      },
      set(val) {
        this.noEntityMessage = val;
      },
    },
    selectedEntitiesLength() {
      return Object.keys(this.selectedEntities)?.length;
    },
    computedSearchKey: {
      get() {
        return this.searchKey;
      },
      set(val) {
        this.searchKey = val;
        this.computedEntities = this.computedOldEntities.filter((item) => {
          return item[this.searchBy]?.toLowerCase().includes(val?.toLowerCase());
        });
      },
    },
    typeIsChannel() {
      return this.entityType === 'channel';
    },
    computedTitle() {
      return this.typeIsChannel ? this.$t('team.add_channel_title') : this.$t('team.add_team_member_title');
    },
    computedSubHeader() {
      return this.typeIsChannel ? this.$t('team.add_a_channel_by_selecting_it_description') : '';
    },
    computedOldEntities: {
      get() {
        return this.entity;
      },
      set(value) {
        this.$emit('entity', value);
      },
    },
    computedEntities: {
      get() {
        return this.entities;
      },
      set(value) {
        this.entities = value;
        this.handleNoEntityMessage();
      },
    },
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    handleNoEntityMessage() {
      if (!this.computedEntities.length) {
        this.noEntities = true;
        const type = this.typeIsChannel ? 'channels' : 'team member';
        const isInSearch = !!this.searchKey;
        if (isInSearch) {
          this.computedMessage = `No ${type} found containing '${this.searchKey}'`;
          return false;
        }
        if (this.typeIsChannel) {
          this.computedMessage = this.$t('team.no_more_channels_available_to_add_message');
          return false;
        }
        this.computedMessage = this.$t('team.no_more_team_members_available_to_add_message');
        return false;
      }
      this.noEntities = false;
    },
    isSelected(entity) {
      if (!this.selectedEntities.length) {
        return false;
      }
      return (
        findIndex(this.selectedEntities, function (o) {
          return o.id === entity.id;
        }) !== -1
      );
    },
    handleSelectedEntities(entity) {
      if (!this.isSelected(entity)) {
        this.selectedEntities.push(entity);
        return false;
      }
      this.removeEntityFromSelectedList(entity);
    },
    removeEntityFromSelectedList(entity) {
      this.selectedEntities = this.selectedEntities.filter((item) => item.id !== entity.id);
    },
    onAdd() {
      this.$emit('submit', this.selectedEntities);
      this.onCancel();
    },
    resetStates() {
      this.selectedEntities = [];
      this.searchKey = '';
      this.computedValue = false;
      this.noEntities = false;
      this.noEntityMessage = '';
      this.computedEntities = this.computedOldEntities;
    },
    onCancel() {
      this.$emit('cancel');
      this.resetStates();
    },
  },
};
</script>

<style lang="scss" src="./AddEntityModal.scss" />
