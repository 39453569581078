import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-6 max-w-full rounded-xl border-1 border-grey-300 bg-white pt-6" }
const _hoisted_2 = { class: "mb-8 ml-6 flex items-center gap-3" }
const _hoisted_3 = { class: "t-text-h5 m-0 text-grey-800" }
const _hoisted_4 = { class: "text-sm text-grey-600" }
const _hoisted_5 = { class: "h-88 p-6 pt-0" }
const _hoisted_6 = { ref: "transactionsChart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chart_column_linear = _resolveComponent("chart-column-linear")!
  const _component_t_thumbnail = _resolveComponent("t-thumbnail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_t_thumbnail, null, {
        default: _withCtx(() => [
          _createVNode(_component_chart_column_linear)
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", null, [
        _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.$t('transactions.chart_heading')), 1 /* TEXT */),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('transactions.chart_subheading')), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("canvas", _hoisted_6, null, 512 /* NEED_PATCH */)
    ])
  ]))
}