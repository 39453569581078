<template>
  <div>
    <div v-if="!loading" class="box">
      <div class="box-header">
        <h2>{{ $t('google_business.brand_and_agent_settings') }}</h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <div v-if="record.gbmChannel.meta && record.gbmChannel.meta.gbmAgent">
          <div
            class="form-group row"
            :class="{
              'disabled-form-setting':
                verificationState.verificationState &&
                verificationState.verificationState !== 'VERIFICATION_STATE_UNVERIFIED',
            }"
          >
            <label class="col-sm-2 form-control-label">
              {{ $t('google_business.brand_name') }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-10">
              <input
                v-model="gbmBrandName"
                type="text"
                class="form-control"
                :placeholder="$t('google_business.your_brand_name')"
                required
              />
              <span class="form-text">{{ $t('google_business.brand_name_subtitle') }}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">
              {{ $t('google_business.brand_logo') }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-10">
              <div class="inline-block">
                <label
                  for="a"
                  class="btn btn-default mb-5 mt-2"
                  style="cursor: pointer; border-radius: 0.5rem; display: inherit; margin-bottom: 0"
                >
                  <div class="valign-center">
                    <i class="material-icons mr-2">image</i>
                    <template v-if="uploading">
                      <i class="fa fa-spin fa-spinner" style="font-size: 20px"></i>
                    </template>
                    <template v-else>
                      <template v-if="logoUrl">{{ $t('google_business.change_logo') }}</template>
                      <template v-else>{{ $t('google_business.add_logo') }}</template>
                    </template>
                  </div>
                  <input id="a" ref="input" type="file" accept="image/*" hidden @change="addFile($event)" />
                </label>
              </div>
              <div>
                <button
                  onclick="this.blur()"
                  type="button"
                  class="btn btn-secondary btn-md mt-2"
                  @click.prevent="testLogo = !testLogo"
                >
                  <i class="material-icons material-icons-xs">
                    {{ testLogo ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                  </i>
                  {{ $t('google_business.see_your_logo') }}
                </button>
                <div v-show="testLogo && logoUrl">
                  <div class="my-4">
                    <div class="flex">
                      <div class="flex-1">
                        <img alt="logo" :src="logoUrl" class="rounded-circle h-128px w-128px rounded" />
                      </div>
                      <div class="flex-1">
                        <div class="rounded-lg p-2" style="background-color: #202124">
                          <img alt="logo" :src="logoUrl" class="rounded-circle h-128px w-128px rounded" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="form-text">
                    {{ $t('google_business.brand_logo_description') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">
              {{ $t('google_business.brand_website_url') }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-10">
              <input
                v-model="brandWebsiteUrl"
                type="url"
                class="form-control"
                :placeholder="$t('google_business.httpsexamplecom')"
                required
              />
              <span class="form-text">{{ $t('google_business.brand_website_url_subtitle') }}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">
              {{ $t('google_business.brand_contact_name') }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-10">
              <input
                v-model="brandContactName"
                type="text"
                class="form-control"
                :placeholder="$t('google_business.john_doe')"
                required
              />
              <span class="form-text">{{ $t('google_business.brand_contact_name') }}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">
              {{ $t('google_business.brand_contact_email') }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-10">
              <input
                v-model="brandContactEmailAddress"
                type="email"
                class="form-control"
                :placeholder="$t('google_business.info_at_examplecom')"
                required
              />
              <span class="form-text">
                {{ $t('google_business.brand_contact_email_recommend_to_share_same_domain_as_brand_website') }}
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">
              {{ $t('google_business.agents_display_name') }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-10">
              <input
                v-model="gbmAgentDisplayName"
                type="text"
                class="form-control"
                :placeholder="$t('google_business.your_customer_facing_name')"
                required
              />
              <span class="form-text">
                {{ $t('google_business.agent_name_as_you_want_it_to_appear_in_conversations_with_users') }}
              </span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 form-control-label">
              {{ $t('google_business.agents_languages') }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-10">
              <label class="control-label">{{ $t('google_business.languages') }}</label>
              <multiselect
                v-model="selectedLanguages"
                label="text"
                track-by="id"
                :allow-empty="false"
                :options="languages"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                :multiple="true"
                :placeholder="$t('google_business.select_one_or_more_languages')"
              />
              <div v-for="language in selectedLanguages" class="form-control my-2">
                <p>{{ language.text }}</p>
                <div>
                  <label class="control-label">{{ $t('google_business.welcome_message') }}</label>
                  <input
                    :modelValue="getWelcomeMessageText(language.id)"
                    type="text"
                    class="form-control"
                    :placeholder="$t('google_business.hello_how_can_we_help_you')"
                    required
                    @input="setWelcomeMessageText($event, language.id)"
                  />
                </div>
                <div class="mt-2">
                  <label class="control-label">{{ $t('google_business.privacy_policy') }}</label>
                  <input
                    :modelValue="getPrivacyPolicyUrl(language.id)"
                    type="url"
                    class="form-control"
                    :placeholder="$t('google_business.httpsexamplecomenprivacy_statement')"
                    required
                    @input="setPrivacyPolicyUrl($event, language.id)"
                  />
                  <span class="form-text text-sm">
                    {{ $t('google_business.privacy_policy_as_a_publicly_available_url_starting_with_https') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="box">
        <div class="box-header">
          <h2>{{ $t('google_business.entry_points_where_conversations_start') }}</h2>
        </div>
        <div class="box-body b-b">
          {{ $t('google_business.entrypoints_description') }}
          <a
            href="https://help.trengo.com/en/articles/86040-google-business-messages"
            target="_blank"
            rel="noopener"
            style="text-decoration: underline"
          >
            {{ $t('google_business.here') }}
          </a>
        </div>
        <div class="box-header b-b">
          <h4>{{ $t('google_business.conversations_without_physical_locations') }}</h4>
        </div>
        <div class="box-body">
          <div
            class="form-group row"
            :class="{
              'disabled-form-setting':
                verificationState.verificationState &&
                verificationState.verificationState !== 'VERIFICATION_STATE_UNVERIFIED',
            }"
          >
            <label class="col-sm-2 form-control-label">{{ $t('google_business.non_local_conversations') }}</label>
            <div class="col-sm-10">
              <label class="ui-switch success m-t-xs m-r flex-1">
                <input v-model="hasNonLocalEntry" type="checkbox" :value="1" />
                <i></i>
              </label>
              <div class="my-2">
                {{ $t('google_business.non_local_conversations_subtitle') }}
              </div>
            </div>
          </div>
          <template v-if="hasNonLocalEntry">
            <div class="form-group row">
              <label class="col-sm-2 form-control-label">
                {{ $t('google_business.contact_website') }}
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-10">
                <input
                  v-model="record.gbmChannel.meta.gbmAgent.businessMessagesAgent.nonLocalConfig.contactOption.url"
                  type="text"
                  class="form-control"
                  :placeholder="$t('google_business.httpsexamplecom')"
                  required
                />
                <span class="form-text">
                  {{ $t('google_business.contact_information_for_the_agent_that_displays_with_the_messaging_button') }}
                </span>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 form-control-label">
                {{ $t('google_business.contact_options') }}
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-10">
                <multiselect
                  v-model="record.gbmChannel.meta.gbmAgent.businessMessagesAgent.nonLocalConfig.contactOption.options"
                  :options="nonLocalContactOptions.map((i) => i.id)"
                  :custom-label="(option) => nonLocalContactOptions.find((i) => i.id === option).text"
                  :allow-empty="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :multiple="true"
                  :placeholder="$t('general.select_one_or_more_options')"
                />
                <span class="form-text">
                  {{ $t('google_business.contact_information_for_the_agent_that_displays_with_the_messaging_button') }}
                </span>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 form-control-label">
                {{ $t('google_business.domains') }}
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-10">
                <multiselect
                  v-model="record.gbmChannel.meta.gbmAgent.businessMessagesAgent.nonLocalConfig.enabledDomains"
                  :tag-placeholder="$t('google_business.add_domain')"
                  :deselect-label="$t('google_business.press_enter_to_remove')"
                  :placeholder="$t('google_business.search_or_add_domain')"
                  :options="record.gbmChannel.meta.gbmAgent.businessMessagesAgent.nonLocalConfig.enabledDomains"
                  :allow-empty="false"
                  :multiple="true"
                  :taggable="true"
                  @tag="
                    (value) =>
                      record.gbmChannel.meta.gbmAgent.businessMessagesAgent.nonLocalConfig.enabledDomains.push(value)
                  "
                />
                <span
                  class="form-text"
                  v-html="markupSanitizer($t('google_business.domains_enabled_to_host_google_widget'))"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 form-control-label">
                {{ $t('google_business.phone_number') }}
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-10">
                <input
                  v-model="record.gbmChannel.meta.gbmAgent.businessMessagesAgent.nonLocalConfig.phoneNumber.number"
                  type="text"
                  class="form-control"
                  :placeholder="$t('google_business.phone_number_placeholder')"
                  required
                />
                <span class="form-text">{{ $t('google_business.phone_number_in_e164_format_eg_12223334444') }}</span>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 form-control-label">
                {{ $t('google_business.call_overflow_phone_number') }}
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-10">
                <multiselect
                  v-model="
                    record.gbmChannel.meta.gbmAgent.businessMessagesAgent.nonLocalConfig.callDeflectionPhoneNumbers
                  "
                  :options="
                    record.gbmChannel.meta.gbmAgent.businessMessagesAgent.nonLocalConfig.callDeflectionPhoneNumbers
                  "
                  :tag-placeholder="$t('google_business.press_enter_or_create_tag')"
                  :deselect-label="$t('google_business.press_enter_to_remove')"
                  :placeholder="$t('google_business.search_or_add_phone_number')"
                  label="number"
                  track-by="number"
                  :allow-empty="false"
                  :multiple="true"
                  :taggable="true"
                  @tag="
                    (value) =>
                      record.gbmChannel.meta.gbmAgent.businessMessagesAgent.nonLocalConfig.callDeflectionPhoneNumbers.push(
                        { number: value }
                      )
                  "
                />
                <span class="form-text">{{ $t('google_business.list_of_phone_numbers_for_call_deflection') }}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="box-header b-b b-t b-t-2x">
          <h4>{{ $t('google_business.conversations_through_physical_locations') }}</h4>
        </div>
        <div class="box-body">
          <div
            class="row"
            :class="{
              'disabled-form-setting':
                verificationState.verificationState &&
                verificationState.verificationState !== 'VERIFICATION_STATE_UNVERIFIED',
            }"
          >
            <label class="col-sm-2 form-control-label">{{ $t('google_business.physical_locations') }}</label>
            <div class="col-sm-10">
              <label class="ui-switch success m-t-xs m-r flex-1">
                <input v-model="hasLocationEntry" type="checkbox" :value="1" />
                <i></i>
              </label>
              <div class="my-2">
                {{
                  $t(
                    'google_business.enables_conversations_with_locations_in_google_maps_app_and_locations_on_google_search'
                  )
                }}
              </div>
            </div>
          </div>
          <div v-if="hasLocationEntry" class="form-group row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <button class="btn success" type="button" :disabled="action === 'create'" @click="openLocationModal">
                {{ $t('google_business.manage_locations') }}
              </button>
              <div v-if="action === 'create'" class="alert alert-info mt-3">
                {{ $t('google_business.you_can_create_and_manage_locations_after_creating_the_channel') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="box">
        <div class="box-header">
          <h2>{{ $t('google_business.test_verify_and_launch') }}</h2>
        </div>
        <div class="box-divider m-a-0"></div>
        <div class="box-body">
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('google_business.test_your_agent') }}</label>
            <div class="col-sm-10">
              <template v-if="agentTestUrl">
                <div v-show="!$root.isMobileDevice" class="my-2">
                  {{ $t('google_business.scan_the_qr_code_with_your_phone') }}
                  <div style="height: 276px; width: 276px">
                    <canvas ref="qrCanvas" />
                  </div>
                </div>
                <a v-show="$root.isMobileDevice" :href="agentTestUrl" target="_blank" class="btn btn-secondary btn-md">
                  {{ $t('google_business.test_your_agent') }}
                  <i class="fa fa-external-link text-muted"></i>
                </a>
                <div
                  class="alert alert-info mt-3"
                  v-html="$t('google_business.you_can_only_use_test_your_agent_on_mobile_device')"
                />
              </template>
              <template v-else-if="action === 'create'">
                <div class="form-text">
                  {{ $t('google_business.you_can_test_a_conversation_after_creating_the_channel') }}
                </div>
              </template>
            </div>
          </div>

          <!-- Agent verification state -->
          <div
            v-if="
              action !== 'create' &&
              (!launchState || !launchState.launchState || launchState.launchState !== 'LAUNCH_STATE_LAUNCHED') &&
              verificationState
            "
            class="form-group row"
          >
            <label class="col-sm-2 form-control-label">{{ $t('google_business.agent_verification') }}</label>
            <div class="col-sm-10">
              <template v-if="action === 'edit'">
                <template
                  v-if="
                    !verificationState.verificationState ||
                    verificationState.verificationState === 'VERIFICATION_STATE_UNVERIFIED'
                  "
                >
                  <button
                    class="btn success"
                    :class="{ loader: verificationLoading }"
                    type="button"
                    @click="requestVerification"
                  >
                    {{ $t('google_business.request_verification') }}
                  </button>
                  <div class="alert alert-warning mt-3">
                    {{ $t('google_business.before_request_verification_warning') }}
                  </div>
                  <div class="form-text">
                    {{ $t('google_business.before_request_verification_description_line_one') }}
                    <br />
                    {{ $t('google_business.before_request_verification_description_line_two') }}
                  </div>
                </template>
                <template v-else-if="verificationState.verificationState === 'VERIFICATION_STATE_PENDING'">
                  <div class="my-2">
                    {{ $t('google_business.verification_pending_the_approval_can_take_about_one_week') }}
                  </div>
                  <button
                    class="btn btn-secondary"
                    :class="{ loader: verificationLoading }"
                    type="button"
                    @click="cancelVerification"
                  >
                    {{ $t('google_business.cancel_verification') }}
                  </button>
                </template>
                <template v-else-if="verificationState.verificationState === 'VERIFICATION_STATE_VERIFIED'">
                  <div class="my-2" v-html="$t('google_business.youre_verified_one')" />
                </template>
              </template>
            </div>
          </div>

          <template
            v-if="
              verificationState && verificationState.verificationState === 'VERIFICATION_STATE_VERIFIED' && launchState
            "
          >
            <!-- Launch entry points -->
            <div v-if="launchState.businessMessages" class="form-group row">
              <label class="col-sm-2 form-control-label">{{ $t('google_business.launch_status') }}</label>
              <div class="col-sm-10">
                <template
                  v-if="
                    !launchState.businessMessages ||
                    !launchState.businessMessages.launchDetails ||
                    (launchState.businessMessages.launchDetails.LOCATION === 'LAUNCH_STATE_UNLAUNCHED' &&
                      launchState.businessMessages.launchDetails.NON_LOCAL === 'LAUNCH_STATE_UNLAUNCHED')
                  "
                >
                  <div class="my-2">
                    {{ $t('google_business.youre_ready_to_launch_your_agent_description') }}
                  </div>
                  <button
                    class="btn success"
                    :class="{ loader: launchLoading }"
                    type="button"
                    @click="launchAgent('all')"
                  >
                    {{ $t('google_business.launch_agent') }}
                  </button>
                </template>
                <template v-else>
                  <template
                    v-for="entryPoint in [
                      { title: this.$t('google_business.physical_locations'), type: 'LOCATION' },
                      { title: this.$t('google_business.non_local'), type: 'NON_LOCAL' },
                      { title: this.$t('google_business.url'), type: 'URL' },
                    ]"
                  >
                    <div v-if="launchState.businessMessages.launchDetails[entryPoint.type]">
                      <div
                        v-if="
                          launchState.businessMessages.launchDetails[entryPoint.type].launchState ===
                          'LAUNCH_STATE_UNLAUNCHED'
                        "
                      >
                        {{ $t('google_business.entry_point_is_not_launched', { title: entryPoint.title }) }}
                        <span class="text-md">🚀</span>
                        <button
                          class="btn btn-secondary btn-sm mb-2 ml-1"
                          :class="{ loader: launchLoading }"
                          type="button"
                          @click="launchAgent(entryPoint.type)"
                        >
                          {{ $t('google_business.launch') }}
                        </button>
                      </div>
                      <div
                        v-if="
                          launchState.businessMessages.launchDetails[entryPoint.type].launchState ===
                          'LAUNCH_STATE_PENDING'
                        "
                      >
                        {{ $t('google_business.entry_point_launch_pending', { title: entryPoint.title }) }}
                        <button
                          class="btn btn-secondary btn-sm mb-2 ml-1"
                          :class="{ loader: launchLoading }"
                          type="button"
                          @click="cancelLaunchAgent(entryPoint.type)"
                        >
                          {{ $t('google_business.cancel_launch') }}
                        </button>
                      </div>
                      <div
                        v-else-if="
                          launchState.businessMessages.launchDetails[entryPoint.type].launchState ===
                          'LAUNCH_STATE_LAUNCHED'
                        "
                      >
                        {{ $t('google_business.entry_point_is_launched', { title: entryPoint.title }) }}
                        <span class="text-md">🚀</span>
                        <button
                          class="btn btn-secondary btn-sm mb-2 ml-1"
                          :class="{ loader: launchLoading }"
                          type="button"
                          @click="cancelLaunchAgent(entryPoint.type)"
                        >
                          {{ $t('google_business.unlaunch') }}
                        </button>
                      </div>
                      <div
                        v-else-if="
                          launchState.businessMessages.launchDetails[entryPoint.type].launchState ===
                          'LAUNCH_STATE_REJECTED'
                        "
                      >
                        {{ $t('google_business.entry_point_is_rejected', { title: entryPoint.title }) }}
                      </div>
                      <div
                        v-else-if="
                          launchState.businessMessages.launchDetails[entryPoint.type].launchState ===
                          'LAUNCH_STATE_SUSPENDED'
                        "
                      >
                        {{ entryPoint.title }} entry point launch is suspended
                      </div>
                      <div v-else>
                        {{ entryPoint.title }} conversations entry point is
                        {{
                          mapLaunchVerificationState(
                            launchState.businessMessages.launchDetails[entryPoint.type]
                          ).toLowerCase()
                        }}
                      </div>
                    </div>
                    <div v-else>
                      <div
                        v-if="
                          entryPoint.type === 'LOCATION' &&
                          hasLocationEntry &&
                          !launchState.businessMessages.launchDetails[entryPoint.type]
                        "
                      >
                        {{ $t('google_business.entry_point_is_not_launched', { title: entryPoint.title }) }}
                        <span class="text-md">🚀</span>
                        <button
                          class="btn btn-secondary btn-sm mb-2 ml-1"
                          :class="{ loader: launchLoading }"
                          type="button"
                          @click="launchAgent(entryPoint.type)"
                        >
                          {{ $t('google_business.launch') }}
                        </button>
                      </div>
                      <!-- we might have to add it for non local entry point -->
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <gbm-locations ref="locationManager" :record="record" :languages="languages" />
  </div>
</template>

<script type="text/babel">
import _ from 'lodash';
import QRCode from 'qrcode';
import Multiselect from 'vue-multiselect';

import eventBus from '@/eventBus';
import markupSanitizer from '@/util/markupSanitizer';

import GbmLocations from './GbmLocations';

export default {
  name: 'GoogleBusinessMessagesChannel',
  emits: ['call-init-warning-method'],
  watch: {
    // Synchronize selected languages in dropdown with language settings in Gbm conversationalSettings
    selectedLanguages(val) {
      if (!this.record.gbmChannel || !this.record.gbmChannel.meta.gbmAgent) {
        return;
      }

      let selectedLanguages = val.map((l) => l.id);
      let languages = {};
      selectedLanguages.forEach((language) => {
        languages[language] = {
          welcomeMessage: {
            text: null, // 'Thanks for contacting Trengo. What can I help you with today?',
          },
          privacyPolicy: {
            url: null, // https://trengo.com/en/privacy-statement
          },
        };
      });
      this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.defaultLocale = selectedLanguages[0];

      _.merge(languages, this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.conversationalSettings);

      this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.conversationalSettings = languages;

      Object.keys(this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.conversationalSettings).forEach(
        (language) => {
          if (!selectedLanguages.includes(language)) {
            delete this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.conversationalSettings[language];
          }
        }
      );
    },
  },

  data() {
    return {
      loading: false,
      testLogo: this.action === 'create', // auto show testLogo if channel is being created

      languages: [],
      selectedLanguages: [],
      nonLocalContactOptions: [
        { id: 'WEB_CHAT', text: this.$t('google_business.web_chat') },
        { id: 'PHONE', text: this.$t('google_business.phone') },
        { id: 'EMAIL', text: this.$t('google_business.email') },
        { id: 'FAQ', text: this.$t('google_business.faq') },
        { id: 'WHATSAPP', text: this.$t('google_business.whatsapp') },
      ],

      /**
                 // After you verify an agent, you can only update the following fields:
                 customAgentId
                 conversationalSettings
                 primaryAgentInteraction
                 additionalAgentInteractions
                 phone
                 */

      logoAttachmentId: null,
      gbmAgent: {
        displayName: this.$root.companyProfile.profile.company_name, // Trengo // prefill with own company name
        businessMessagesAgent: {
          logoUrl: null, // https://trengo.com/blog/wp-content/uploads/2019/11/trengo-1200x1200.png
          entryPointConfigs: [
            {
              allowedEntryPoint: 'LOCATION',
            },
            // https://developers.google.com/business-communications/business-messages/guides/learn/entry-points#non-local
            {
              allowedEntryPoint: 'NON_LOCAL', // encompasses all entry points that don't rely on locations
            },
          ],
          // Configuration for Non local entry point https://developers.google.com/business-communications/business-messages/reference/business-communications/rest/v1/brands.agents#nonlocalconfig
          nonLocalConfig: {
            regionCodes: ['001'], // "To enable NON_LOCAL entry points in all available regions, use 001 for the World region code." https://developers.google.com/business-communications/business-messages/guides/set-up/agent?method=api#agent_entry_points
            contactOption: {
              // Contact information for the agent that displays with the messaging button
              url: null, // e.g. 'https://trengo.com' // The URL that contact options are available for
              options: [
                //     WEB_CHAT	Web Chat option.
                //     PHONE	Phone option.
                //     EMAIL	Email option.
                //     FAQS	FAQ option.
                //     TWITTER	Twitter option.
                //     WHATSAPP	Whatsapp option.
              ],
            },
            enabledDomains: [
              // Domains enabled to host the Business Messages widget // https://developers.google.com/business-communications/business-messages/reference/web-widget
              // 'https://trengo.com',
            ],
            phoneNumber: {
              number: null, // e.g. '31850013030'
            },
            callDeflectionPhoneNumbers: [], // Call overflow phone number(s)
          },
          conversationalSettings: {
            en: {
              // [locale] // 2 letter code ISO 639-1 language code // http://www.loc.gov/standards/iso639-2/php/code_list.php
              welcomeMessage: {
                text: null, // 'Thanks for contacting Trengo. What can I help you with today?',
              },
              privacyPolicy: {
                url: null, // https://trengo.com/en/privacy-statement
              },
            },
          },
          defaultLocale: 'en',
          primaryAgentInteraction: {
            interactionType: 'HUMAN',
            humanRepresentative: {
              humanMessagingAvailability: {
                hours: [
                  {
                    startTime: {
                      hours: 0,
                      minutes: 0,
                    },
                    endTime: {
                      hours: 23,
                      minutes: 59,
                    },
                    timeZone: window.APP_TIMEZONE,
                    startDay: 'MONDAY',
                    endDay: 'SUNDAY',
                  },
                ],
              },
            },
            // botRepresentative: {},
          },
        },
      },

      // https://developers.google.com/business-communications/business-messages/guides/deploy/verify#verify_an_agent
      agentVerificationContact: {
        partnerName: 'Trengo',
        partnerEmailAddress: 'team@trengo.com',
        brandContactName: null,
        brandContactEmailAddress: null,
        brandWebsiteUrl: null,
      },

      gbmBrand: {
        name: this.$root.companyProfile.profile.company_name, // brand name // prefill with own company name
      },

      gbmHours: [],

      // VERIFICATION_STATE_UNSPECIFIED	Unspecified state.
      // VERIFICATION_STATE_UNVERIFIED	Unverified state.
      // VERIFICATION_STATE_PENDING	    Verification in review.
      // VERIFICATION_STATE_VERIFIED	    Verification complete.
      verificationState: {},
      verificationLoading: false,

      // LAUNCH_STATE_UNSPECIFIED	Unspecified state.
      // LAUNCH_STATE_UNLAUNCHED	Entity is unlaunched.
      // LAUNCH_STATE_PENDING	    Launch in review.
      // LAUNCH_STATE_LAUNCHED	Launched.
      // LAUNCH_STATE_REJECTED	Launch is rejected.
      // LAUNCH_STATE_SUSPENDED	Launch is suspended.
      launchState: {
        businessMessages: {
          launchDetails: {},
        },
      },
      launchLoading: false,

      agentTestUrl: null,
      uploading: false,
      iconFile: null,
    };
  },

  computed: {
    isValidLogoUrl() {
      return (
        this.record.gbmChannel.meta &&
        this.record.gbmChannel.meta.gbmAgent &&
        this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent &&
        this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.logoUrl &&
        this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.logoUrl.startsWith('http') &&
        this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.logoUrl.includes('.')
      );
    },

    logoUrl() {
      if (this.iconFile) {
        return URL.createObjectURL(this.iconFile);
      }
      if (this.isValidLogoUrl) {
        return this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.logoUrl;
      }
      return null;
    },

    hasLocationEntry: {
      get() {
        return this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.entryPointConfigs.some(
          (i) => i.allowedEntryPoint === 'LOCATION'
        );
      },
      set(val) {
        if (val) {
          this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.entryPointConfigs.push({
            allowedEntryPoint: 'LOCATION',
          });
        } else {
          this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.entryPointConfigs =
            this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.entryPointConfigs.filter(
              (i) => i.allowedEntryPoint !== 'LOCATION'
            );
        }
      },
    },
    hasNonLocalEntry: {
      get() {
        return this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.entryPointConfigs.some(
          (i) => i.allowedEntryPoint === 'NON_LOCAL'
        );
      },
      set(val) {
        if (val) {
          this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.entryPointConfigs.push({
            allowedEntryPoint: 'NON_LOCAL',
          });
        } else {
          this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.entryPointConfigs =
            this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.entryPointConfigs.filter(
              (i) => i.allowedEntryPoint !== 'NON_LOCAL'
            );
        }
      },
    },
    gbmBrandName: {
      //write getter and setter functions for this
      get() {
        return this.record.gbmChannel.meta.gbmBrand.name;
      },
      set(val) {
        this.record.gbmChannel.meta.gbmBrand.name = val;
      },
    },
    brandWebsiteUrl: {
      get() {
        return this.record.gbmChannel.meta.agentVerificationContact.brandWebsiteUrl;
      },
      set(val) {
        this.record.gbmChannel.meta.agentVerificationContact.brandWebsiteUrl = val;
      },
    },
    brandContactName: {
      get() {
        return this.record.gbmChannel.meta.agentVerificationContact.brandContactName;
      },
      set(val) {
        this.record.gbmChannel.meta.agentVerificationContact.brandContactName = val;
      },
    },
    brandContactEmailAddress: {
      get() {
        return this.record.gbmChannel.meta.agentVerificationContact.brandContactEmailAddress;
      },
      set(val) {
        this.record.gbmChannel.meta.agentVerificationContact.brandContactEmailAddress = val;
      },
    },
    gbmAgentDisplayName: {
      get() {
        return this.record.gbmChannel.meta.gbmAgent.displayName;
      },
      set(val) {
        this.record.gbmChannel.meta.gbmAgent.displayName = val;
      },
    },
    conversationalSettings: {
      get() {
        return this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.conversationalSettings;
      },
      set(val) {
        this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.conversationalSettings = val;
      },
    },
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },

  props: {
    action: {
      type: String,
      default: 'create',
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    GbmLocations,
    Multiselect,
  },

  beforeMount() {
    if (this.action === 'create') {
      this.record.title = 'Google Business Messages';
      this.record.gbmChannel.meta = {};
      this.record.gbmChannel.meta.gbmAgent = this.gbmAgent;
      this.record.gbmChannel.meta.gbmBrand = this.gbmBrand;
      this.record.gbmChannel.meta.agentVerificationContact = this.agentVerificationContact;
      this.selectedLanguages = [
        {
          id: 'en',
          text: 'English',
        },
      ];
    }

    this.gbmAgent.businessMessagesAgent.nonLocalConfig =
      this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.nonLocalConfig;

    this.fetchLocaleCodes();

    if (this.action === 'edit') {
      this.$emit('call-init-warning-method', true);
      this.fetchGbmData();
    }
  },

  methods: {
    markupSanitizer: markupSanitizer,

    openLocationModal() {
      eventBus.$emit('show.gbm.locations.modal');
      // $(this.$refs.locationManager.$refs.modal).modal('show');
    },

    async beforeSave() {
      if (this.iconFile) {
        this.uploading = true;

        let formData = new FormData();
        formData.append('file', this.iconFile);
        await axios
          .post('/api/v2/attachments?type=true', formData)
          .then((res) => {
            this.uploading = false;

            this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.logoUrl = res.data.file_url;
            this.record.gbmChannel.meta.logoAttachmentId = res.data.attachment_id;
            this.iconFile = null;
          })
          .catch((e) => {
            this.uploading = false;
            this.flashError(this.$t('google_business.something_went_wrong_while_uploading_the_icon'));
          });
      }
    },

    fetchLocaleCodes() {
      axios.get('/client-api/locales/list').then((res) => {
        this.languages = _.toArray(res.data);

        if (this.action === 'edit') {
          this.selectedLanguages = this.languages.filter((l) =>
            Object.keys(this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.conversationalSettings).includes(
              l.id
            )
          );
        }
      });
    },

    fetchGbmData() {
      axios.get('/api/v2/gbm/pages/' + this.record.id).then((res) => {
        if (res.data.agent && res.data.agent.businessMessagesAgent.agentTestUrl) {
          this.agentTestUrl = res.data.agent.businessMessagesAgent.agentTestUrl;
          this.$nextTick(() => {
            if (this.$refs.qrCanvas) {
              QRCode.toCanvas(this.$refs.qrCanvas, res.data.agent.businessMessagesAgent.agentTestUrl);
            }
          });
        }
        this.verificationState = res.data.verificationState;
        this.launchState = res.data.launchState;
        this.$emit('call-init-warning-method', true);
      });
    },

    requestVerification() {
      this.verificationLoading = true;
      axios
        .put('/api/v2/gbm/pages/' + this.record.id, {
          type: 'agent_verification',
        })
        .then((res) => {
          this.verificationLoading = false;
          this.flashSuccess(this.$t('google_business.verification_requested'));
          this.verificationState = res.data;
        });
    },

    cancelVerification() {
      this.verificationLoading = true;
      axios
        .put('/api/v2/gbm/pages/' + this.record.id, {
          type: 'cancel_agent_verification',
        })
        .then((res) => {
          this.verificationLoading = false;
          this.flashSuccess(this.$t('google_business.verification_request_canceled'));
          this.verificationState = res.data;
        });
    },

    launchAgent(entryPointType) {
      this.launchLoading = true;
      axios
        .put('/api/v2/gbm/pages/' + this.record.id, {
          type: 'launch_agent',
          entryPointType: entryPointType,
        })
        .then((res) => {
          this.launchLoading = false;
          this.flashSuccess(this.$t('google_business.launch_requested_your_agent_will_be_live_in_a_few_hours'));
          this.launchState = res.data;
        });
    },

    cancelLaunchAgent(entryPointType) {
      this.launchLoading = true;
      axios
        .put('/api/v2/gbm/pages/' + this.record.id, {
          type: 'cancel_launch_agent',
          entryPointType: entryPointType,
        })
        .then((res) => {
          this.launchLoading = false;
          this.flashSuccess(this.$t('google_business.launch_canceled'));
          this.launchState = res.data;
        });
    },

    async addFile(event) {
      let file = event.target.files[0];

      if (file.size / 1024 / 1024 > 4) {
        this.flashWarning(this.$t('google_business.image_may_not_exceed_4mb'));
        return;
      }

      this.iconFile = file;
    },

    mapLaunchVerificationState(state) {
      if (!state || !state.launchState) {
        return 'Unspecified';
      }
      switch (state.launchState) {
        case 'LAUNCH_STATE_UNSPECIFIED':
          return 'Unspecified state';
        case 'LAUNCH_STATE_UNLAUNCHED':
          return 'Entity is unlaunched';
        case 'LAUNCH_STATE_PENDING':
          return 'Launch in review';
        case 'LAUNCH_STATE_LAUNCHED':
          return 'Launched';
        case 'LAUNCH_STATE_REJECTED':
          return 'Launch is rejected';
        case 'LAUNCH_STATE_SUSPENDED':
          return 'Launch is suspended';
        case 'LAUNCH_STATE_PENDING_UNLAUNCH':
          return 'Unlaunch in review';
        default:
          return state.launchState;
      }
    },
    getWelcomeMessageText(languageId) {
      return this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.conversationalSettings[languageId]
        .welcomeMessage.text;
    },
    setWelcomeMessageText(event, languageId) {
      this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.conversationalSettings[
        languageId
      ].welcomeMessage.text = event.target.value;
    },
    getPrivacyPolicyUrl(languageId) {
      return this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.conversationalSettings[languageId].privacyPolicy
        .url;
    },
    setPrivacyPolicyUrl(event, languageId) {
      this.record.gbmChannel.meta.gbmAgent.businessMessagesAgent.conversationalSettings[languageId].privacyPolicy.url =
        event.target.value;
    },
  },
};
</script>
