<template>
  <div :class="[showBorderClass, customClass.container]" class="border-grey-300">
    <div class="flex justify-between">
      <div class="">
        <div class="text-base font-semibold leading-tight text-grey-900">
          <slot name="title" />
        </div>
        <div v-if="$slots.formText && this.showFormText" class="mt-1 text-sm font-medium leading-6 text-grey-700">
          <slot name="formText" />
        </div>
      </div>
      <div v-if="$slots.toggle" class="ml-2 self-start">
        <slot name="toggle" />
      </div>
    </div>
    <div v-if="$slots.moreSettings" class="my-2">
      <slot name="moreSettings" />
    </div>
  </div>
</template>

<script>
import breakpoints from '@/util/breakpoints';

export default {
  name: 'Setting',
  data() {
    return {
      breakpoints,
    };
  },
  props: {
    hideFormTextOnMobile: {
      type: Boolean,
      default: false,
    },
    customClass: {
      container: {
        type: String,
        default: '',
      },
      default: () => ({ container: 'my-4 pt-5 pb-3' }),
    },
    showBorder: {
      type: Boolean,
      default: true,
    },
    borderOnMobile: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    isMdOrMore() {
      const { comparisons } = this.breakpoints;
      return comparisons.eq_lg || comparisons.eq_xl || comparisons.eq_md;
    },
  },
  computed: {
    showBorderClass() {
      const showBorderOnMobile = this.borderOnMobile && !this.isMdOrMore();
      return (!this.isMdOrMore() && showBorderOnMobile && this.showBorder) || this.showBorder ? 'border-b' : '';
    },
    showFormText() {
      return !this.hideFormTextOnMobile || this.isMdOrMore(); // show if not on mobile
    },
  },
};
</script>
