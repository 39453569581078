<template>
  <div class="rounded-xl border border-solid border-grey-300 bg-grey-200 px-3 py-3">
    <p v-if="headerSubType === TEMPLATE_HEADER_TYPE.TEXT.toUpperCase()" class="mb-2 text-sm font-bold text-grey-700">
      {{ headerText }}
    </p>
    <t-file-input
      v-if="headerSubType === TEMPLATE_HEADER_TYPE.IMAGE.toUpperCase()"
      class="mb-2"
      :value="selectedImage"
      :force-validation="forceFileValidation"
      :max-file-size="{
        size: TEMPLATE_HEADER_FILE_LIMIT_IN_BYTES,
        text: $t('whatsapp.image_sample_max_file_size', { fileSize: maxFileSize }),
      }"
      @update:value="$emit('handle-file-change', $event)"
      @reset="$emit('reset')"
    />
    <p class="t-text-sm text-grey-60 mb-0">{{ body }}</p>
    <p v-if="footer" class="t-text-xs mb-0 mt-2 text-grey-600">{{ footer }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { TEMPLATE_HEADER_TYPE, TEMPLATE_HEADER_FILE_LIMIT_IN_BYTES } from '@/Configs/Constants/whatsapp';
import { getFileSizeInBytes } from '@/util/fileHelper';

export default defineComponent({
  name: 'WaTemplateMessage',
  emits: ['handle-file-change', 'reset'],
  props: {
    body: {
      type: String,
      default: '',
    },
    header: {
      type: Object,
      default: () => {},
    },
    footer: {
      type: String,
      default: '',
    },
    selectedImage: {
      type: Array,
      default: () => [],
    },
    forceFileValidation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TEMPLATE_HEADER_TYPE,
      TEMPLATE_HEADER_FILE_LIMIT_IN_BYTES,
    };
  },
  computed: {
    headerSubType(): string {
      return this.header?.sub_type;
    },
    headerText(): string {
      return this.header.sub_type === TEMPLATE_HEADER_TYPE.TEXT.toUpperCase() ? this.header?.value : '';
    },
    maxFileSize(): number {
      return getFileSizeInBytes(TEMPLATE_HEADER_FILE_LIMIT_IN_BYTES);
    },
  },
});
</script>
