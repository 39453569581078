import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { API_INBOX_AI } from '@/components/ReplyForm/api/const';
import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

import type {
  GenerateSummaryResponse,
  SupportConversationSummaryPayload,
  SupportConversationSummaryResponse,
} from './types';
import type { FetchStatus } from '@/store/pinia/translation/types';

export const useGenerateSummaryStore = defineStore('generateSummary', () => {
  const status = ref<FetchStatus>('INITIAL');
  const activeTicketId = ref<number>();
  const isActive = ref(false);
  const isFeatureEnabled = computed(() => useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.TI_CONVERSATION_SUMMARY));

  async function getIsSummaryAvailable(ticketId?: number) {
    status.value = 'INITIAL';
    if (!isFeatureEnabled.value || !ticketId) return;
    activeTicketId.value = ticketId;

    try {
      const { data } = await axios.get<SupportConversationSummaryResponse>(
        `${API_INBOX_AI}/support_conversation_summary?ticket_id=${ticketId}`,
      );
      isActive.value = !!data?.is_conversation_summary_supported;
    } catch {
      return;
    }
  }

  async function generateSummary(payload: SupportConversationSummaryPayload['ticket_id']) {
    if (!payload) return;

    status.value = 'LOADING';

    try {
      await axios.post<GenerateSummaryResponse>(`${API_INBOX_AI}/conversation_summary`, {
        ticket_id: payload,
      });
      status.value = 'SUCCESS';
    } catch {
      status.value = 'FAILED';
      return;
    }
  }

  return {
    status,
    isActive,
    getIsSummaryAvailable,
    generateSummary,
  };
});
