<script type="text/babel">
import { mapStores } from 'pinia';

import { FEATURE } from '@/Configs/Constants';
import { useEntitlementsStore } from '@/store/pinia';
export default {
  name: 'WebWidgetsIndex',
  data() {
    return {
      loaded: false,
      web_widgets: {},
      widgetsCount: 0,
    };
  },

  mounted() {
    this.loadList(false);
  },

  computed: {
    ...mapStores(useEntitlementsStore),
    isEntitled() {
      const featureId = FEATURE.CUSTOMER_SERVICE__AUTOMATION__WIDGET;
      return this.entitlementsStore?.isEntitledTo(featureId, this.widgetsMinimumQuantity);
    },
    widgetsMinimumQuantity() {
      return this.widgetsCount + 1;
    },
  },

  methods: {
    loadList(reload_root = true) {
      axios.get('/api/v2/web_widgets').then((result) => {
        this.web_widgets = result.data.data;
        this.widgetsCount = result.data.meta.total;
        this.loaded = true;

        if (reload_root) {
          this.$root.web_widgets = this.web_widgets;
        }
      });
    },
  },
};
</script>

<template>
  <div class="row-body">
    <admin-section-title title="Web widgets"></admin-section-title>
    <div class="row-col">
      <div class="row-col">
        <div id="settings-primary" class="col-xs modal fade aside aside-md b-r w-medium w-200px">
          <div class="nav-settings light left b-primary row-col">
            <ul>
              <li class="text-md white header">
                <strong>{{ $t('web_widget.website_widgets') }}</strong>
                <a data-dismiss="modal" class="hidden-md-up text-muted m-l-auto m-r-sm m-l-1 text-lg">×</a>
                <a v-if="isEntitled" class="m-l-auto btn btn-icon btn-sm success rounded text-white">
                  <i class="material-icons md-18" @click="$router.push('/admin/web_widgets/create')">add</i>
                </a>
              </li>
            </ul>
            <div class="row-row">
              <div class="row-body scrollable hove">
                <div class="row-inner">
                  <ul>
                    <li v-for="item in web_widgets">
                      <router-link
                        class="nav-settings-link text-truncate"
                        data-dismiss="modal"
                        :to="'/admin/web_widgets/' + item.id + ''"
                        :class="{ active: $route.params.id == item.id }"
                      >
                        {{ item.title }}
                        <em v-if="item.title == null" class="text-muted">{{ $t('web_widget.untitled') }}</em>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-col">
          <router-view v-if="loaded" :key="$route.params.id" @call-parent-loadList-method="loadList"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
