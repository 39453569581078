<template>
  <t-multi-choice v-model="computedValue">
    <template #public="{ selected }">
      <t-multi-choice-button
        class="mb-4"
        title="For a team"
        desc="Collaborate on emails and respond faster as a team."
        :selected="selected"
      >
        <template #icon>
          <team-custom-icon :selected="selected" />
        </template>
      </t-multi-choice-button>
    </template>
    <template #private="{ selected }">
      <t-multi-choice-button
        title="For a user"
        icon="user"
        desc="Receive and send emails only visible to a specific user."
        :selected="selected"
      />
    </template>
  </t-multi-choice>
</template>

<script>
import TeamCustomIcon from './TeamCustomIcon';

export default {
  emits: ['update:modelValue'],
  components: {
    TeamCustomIcon,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
      default: '',
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>
