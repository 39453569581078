<script>
export default {
  data: function () {
    return {
      path: '',
    };
  },

  mounted() {},
};
</script>

<template>
  <div>saddassaddddadaadssasad</div>
</template>
