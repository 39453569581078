<template>
  <li class="list-none">
    <t-popover v-model="isOpen" class="relative" click-outside-close>
      <template #trigger>
        <t-list-item :label="$t('reports_v2.status')" class="relative" :active="isOpen">
          <template #postfix>
            <t-badge v-if="statusesCount > 0" class="flex items-center" :text="statusesCount" />
            <caret-down-fill size="1.25rem" class="mt-1 -rotate-90 text-grey-600" />
          </template>
        </t-list-item>
      </template>

      <template #content>
        <div
          class="
            filter-dropdown-second-layer
            absolute
            z-50
            mt-2
            w-64
            rounded-xl
            border border-grey-300
            bg-white
            pb-2
            pt-2
            shadow-500
          "
        >
          <ul class="max-h-[335px] w-full px-3 pt-2">
            <t-list-item-checkbox
              :model-value="activeStatus.includes('ASSIGNED')"
              :label="$t('reports_v2.assigned')"
              @update:modelValue="handleSelectItem('ASSIGNED')"
            />
            <t-list-item-checkbox
              :model-value="activeStatus.includes('CLOSED')"
              :label="$t('reports_v2.closed')"
              @update:modelValue="handleSelectItem('CLOSED')"
            />
            <t-list-item-checkbox
              :model-value="activeStatus.includes('OPEN')"
              :label="$t('reports_v2.open')"
              @update:modelValue="handleSelectItem('OPEN')"
            />
          </ul>

          <t-divider />
          <div class="flex justify-end px-3 py-3">
            <t-button size="sm" btn-style="secondary" class="mr-2" @click="clearFilters">
              {{ $t('reports_v2.clear') }}
            </t-button>
            <t-button size="sm" @click="applyFilter">{{ $t('reports_v2.confirm') }}</t-button>
          </div>
        </div>
      </template>
    </t-popover>
  </li>
</template>

<script lang="ts">
import { CaretDownFill } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Status',
  emits: ['filter-updated'],
  components: { CaretDownFill },
  data() {
    return {
      isOpen: false,
      selectedItems: [],
    };
  },
  props: {
    activeStatus: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.selectedItems = this.activeStatus;
  },
  computed: {
    statusesCount() {
      return this.selectedItems.length;
    },
  },
  methods: {
    clearFilters() {
      this.selectedItems = [];
      this.applyFilter();
    },
    applyFilter() {
      this.$emit('filter-updated', { statuses: this.selectedItems });
    },
    handleSelectItem(status: string) {
      if (this.selectedItems.includes(status)) {
        this.selectedItems = this.selectedItems.filter((s: string) => s !== status);
      } else {
        this.selectedItems.push(status);
      }
    },
  },
});
</script>
