import { defineStore } from 'pinia';
import { ref } from 'vue';

import { fetchAllChannels } from '@/api';

import type { ChannelsResponse } from './types';

export const useChannelsStore = defineStore('channels', () => {
  const isLoading = ref(false);
  const isError = ref(false);
  const isInitialized = ref(false);
  const channels = ref<ChannelsResponse['data']>();

  async function init() {
    if (isInitialized.value) return;
    try {
      await getChannels();
      isInitialized.value = true;
    } catch (error) {
      isError.value = true;
    }
  }

  async function getChannels() {
    isError.value = false;
    isLoading.value = true;

    try {
      const res = await fetchAllChannels();
      channels.value = res.data.data;
    } catch (error) {
      isError.value = true;
    } finally {
      isLoading.value = false;
    }

    return channels;
  }

  function findChannelTitle(channelId: number) {
    return channels.value?.find(({ id }) => id === channelId)?.type;
  }

  return {
    isLoading,
    isError,
    channels,
    getChannels,
    findChannelTitle,
    init,
  };
});
