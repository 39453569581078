<template>
  <reporting-page :heading-title="$t('reports_v2.workload_management')" class="w-full">
    <template #right>
      <div class="wrapper">
        <date-filter-dropdown
          :timezone="$root.user.timezone"
          :default-label="$t('reports_v2.last_seven_days')"
          :default-time="dateInterval"
          @change="setDateAndInterval"
        />
      </div>
    </template>
    <div class="mb-8 mt-6 flex flex-col flex-nowrap justify-center gap-5 pb-0">
      <stats-workload v-if="dateInterval.startDate" :filters="dateInterval" />
    </div>

    <div>
      <workload-management-chart v-if="dateInterval.startDate" :filters="dateInterval" />
    </div>

    <average-ticket-created-chart v-if="dateInterval.startDate" :filters="dateInterval" />
  </reporting-page>
</template>

<script lang="ts">
import moment from 'moment';
import { defineComponent } from 'vue';

import StatsWorkload from './StatsWorkload.vue';

import type { DatePickerDateAndInterval } from '@/components/Reporting/api/types';
import type { Filter } from '@/components/Reporting/Components/V2/FilterDropdown/FilterDropdown.vue';
import type { Moment } from 'moment';

import ReportingPage from '@/components/common/ReportingPage';
import AverageTicketCreatedChart from '@/components/Reporting/Components/V2/Charts/WorkloadManagement/AverageConversationsCreated/Index.vue';
import WorkloadManagementChart from '@/components/Reporting/Components/V2/Charts/WorkloadManagement/WorkloadManagementConversations/Index.vue';
import DateFilterDropdown from '@/components/Reporting/Components/V2/DateFilterDropdown/DateFilterDropdown.vue';
import { useReportStore } from '@/components/Reporting/store';

type Data = {
  reportStore: ReturnType<typeof useReportStore>;
  dateInterval: DatePickerDateAndInterval;
  filtersFromRouteQuery: string;
};

export type DateRange = {
  startDate: Moment;
  endDate: Moment;
};

export default defineComponent({
  name: 'Stats',
  components: {
    AverageTicketCreatedChart,
    DateFilterDropdown,
    ReportingPage,
    StatsWorkload,
    WorkloadManagementChart,
  },

  data(): Data {
    return {
      reportStore: useReportStore(),
      dateInterval: {
        startDate: '',
        endDate: '',
        interval: 'day',
      },
      filtersFromRouteQuery: '',
    };
  },
  created() {
    this.parseTimeInterval(
      this.$route.query.filters ? JSON.parse(this.$route.query.filters) : { startDate: null, endDate: null }
    );
  },
  mounted() {
    this.$router.replace({
      query: {
        filters: JSON.stringify({
          ...(this.$route.query.filters ? JSON.parse(this.$route.query.filters) : {}),
          ...this.dateInterval,
        }),
      },
    });
    this.reportStore.$patch({
      reportingFilters: {
        hasBusinessHours: true,
        hasDatePicker: true,
        hasConversation: true,
        hasAgent: false,
      },
    });
  },
  unmounted() {
    this.reportStore.$patch({
      reportingFilters: {
        hasBusinessHours: false,
        hasDatePicker: false,
        hasConversation: false,
      },
    });
  },

  methods: {
    parseTimeInterval(filters: { startDate: string; endDate: string }) {
      if (!filters.startDate && !filters.endDate) {
        this.dateInterval.startDate = moment()
          .tz(this.$root.user.timezone)
          .subtract(6, 'day')
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ssZ');
        this.dateInterval.endDate = moment().tz(this.$root.user.timezone).format('YYYY-MM-DDTHH:mm:ssZ');
        return;
      }
      this.dateInterval.startDate = moment(filters.startDate)
        .tz(this.$root.user.timezone)
        .format('YYYY-MM-DDTHH:mm:ssZ');
      this.dateInterval.endDate = moment(filters.endDate).tz(this.$root.user.timezone).format('YYYY-MM-DDTHH:mm:ssZ');
    },
    fetchFilters(filters: Filter) {
      this.filtersFromRouteQuery = JSON.stringify(filters);
    },

    setDateAndInterval(dateObj: DateRange) {
      const currentStart = dateObj.startDate.isValid()
        ? moment(dateObj.startDate).tz(this.$root.user.timezone).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
        : moment().tz(this.$root.user.timezone).subtract(6, 'day').startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
      const currentEnd = dateObj.endDate.isValid()
        ? moment(dateObj.endDate).tz(this.$root.user.timezone).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
        : moment().tz(this.$root.user.timezone).format('YYYY-MM-DDTHH:mm:ssZ');

      this.dateInterval.startDate = currentStart;
      this.dateInterval.endDate = currentEnd;
      this.dateInterval.interval = this.getInterval();

      this.$router.replace({
        query: {
          filters: JSON.stringify({
            ...(this.$route.query.filters ? JSON.parse(this.$route.query.filters) : {}),
            startDate: this.dateInterval.startDate,
            endDate: this.dateInterval.endDate,
            interval: this.dateInterval.interval,
          }),
        },
      });
    },

    getInterval() {
      let currentStart = moment.unix(moment(this.dateInterval.startDate).unix());
      let currentEnd = moment.unix(moment(this.dateInterval.endDate).unix());
      let diff = currentEnd.diff(currentStart, 'hours');

      if (diff < 24) {
        return 'hour';
      }
      if (diff < 744) {
        return 'day';
      }
      if (diff < 2720) {
        return 'week';
      }
      if (diff < 17531) {
        return 'month';
      }
      return 'year';
    },
  },
});
</script>
<style scoped>
.wrapper :deep(.t-select-input__input-container) {
  margin-top: 0 !important; /* Remove margin */
}
</style>
