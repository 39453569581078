<script type="text/babel">
import { mapGetters } from 'vuex';

export default {
  name: 'ChatStatus',
  data() {
    return {
      isAvailable: false,
    };
  },
  methods: {
    handleClick() {
      this.currentUser.toggleChatStatus();
    },
  },
  computed: {
    inActiveBorder() {
      return this.computedStatus ? 'border-grey-700' : 'border-grey-800';
    },
    ...mapGetters({
      currentUser: 'usersInternalChat/currentUser',
      chatStatus: 'chat/chatStatus',
    }),
    computedStatus: {
      get() {
        return this.chatStatus(this.currentUser) === 'online';
      },
      set(value) {
        this.isAvailable = value;
      },
    },
  },
};
</script>

<template>
  <div
    class="
      selector-toggle-status-team-chat
      mb-1
      mt-1
      flex
      cursor-pointer
      items-center
      rounded-lg
      border-1
      p-2
      px-3
      leading-none
      text-white
      hover:border-grey-600 hover:brightness-90
    "
    :class="inActiveBorder"
    role="button"
    data-test="profile-settings-item-chat-status"
    @click="handleClick"
  >
    <i
      class="material-icons text-md"
      :class="{ 'text-green': chatStatus(currentUser) === 'online', 'text-red': chatStatus(currentUser) === 'away' }"
    >
      people
    </i>
    <div class="ml-2">
      <span v-if="chatStatus(currentUser) === 'online'" v-html="$t('internal_chat.status_team_chat_online')"></span>
      <span
        v-if="chatStatus(currentUser) === 'away'"
        class="text-grey-500"
        v-html="$t('internal_chat.status_team_chat_away')"
      ></span>
    </div>
    <span class="ml-auto text-xs leading-none text-grey-500">
      <t-switch v-model="computedStatus" label off-color="red" size="sm" @input="handleClick" />
    </span>
  </div>
</template>
