<template>
  <main-header :title="title" max-width="1440px" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import MainHeader from '@/components/common/PageHeaders/MainHeader';

export default defineComponent({
  name: 'FilterHeader',

  components: {
    MainHeader,
  },

  computed: {
    title() {
      return this.$tc(`integration_hub.filter_${this.$route.params.filter}_title`);
    },
  },
});
</script>
