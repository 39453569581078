import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative h-88" }
const _hoisted_2 = {
  ref: "report",
  height: "200",
  class: "h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_status = _resolveComponent("error-status")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("canvas", _hoisted_2, null, 512 /* NEED_PATCH */),
    (_ctx.showError)
      ? (_openBlock(), _createBlock(_component_error_status, {
          key: 0,
          "icon-name": "ChartLineLinear",
          title: _ctx.$t('reports_v2.no_data_available'),
          "sub-title": _ctx.$t('reports_v2.api_failed'),
          classes: "h-[calc(100%-100px)] w-[calc(100%-64px)]\n        min-[1500px]:h-[calc(100%-76px)]\n        min-[990px]:h-[calc(100%-77px)]"
        }, null, 8 /* PROPS */, ["title", "sub-title"]))
      : _createCommentVNode("v-if", true)
  ]))
}