<template>
  <user-row
    :user-pending="isUserPending"
    :has-limit="hasLimit"
    :limit="user_limit"
    :show-more="removable"
    data-test="team-members-user-row"
    :user="user"
    @input-value-changed="handleChangedInputValue"
    @change-limit="handleChangeUserLimit"
    @remove="handleRemove"
  />
</template>
<script>
import { cloneDeep } from 'lodash';

import UserRow from '@/components/Teams/components/UserRow';
import UserStatus from '@/Configs/Constants/UserStatus';

export default {
  name: 'UserRowWorkloadBalance',
  emits: ['input-value-changed', 'change-limit', 'remove'],
  components: { UserRow },
  data() {
    return {
      user_limit: 0,
    };
  },
  props: {
    initLimit: {
      type: Number,
      default: undefined,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    hasLimit: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    user: {
      deep: true,
      handler: function (val) {
        this.user_limit = val.load_balancing_ticket_limit;
      },
    },
  },
  mounted() {
    const userBalanceLimit = this.initLimit !== undefined ? this.initLimit : this.user.load_balancing_ticket_limit;
    this.user_limit = this.isUserPending ? 0 : userBalanceLimit;
  },
  methods: {
    handleChangedInputValue(value) {
      this.$emit('input-value-changed', value);
    },
    handleChangeUserLimit(value) {
      this.user_limit = value;
      this.mutateUserLimit();
    },
    handleRemove() {
      if (!this.removable) {
        return false;
      }
      this.$emit('remove', this.user);
    },
    mutateUserLimit() {
      const clonedUser = cloneDeep(this.user);
      clonedUser.load_balancing_ticket_limit = this.user_limit;
      this.$emit('change-limit', clonedUser);
    },
  },
  computed: {
    isUserPending() {
      return this.user.status === UserStatus.UNVERIFIED;
    },
  },
};
</script>

<style lang="scss" src="./UserRowWorkloadBalance.scss" scoped />
