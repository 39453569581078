export const NAVIGATION_ITEM_ID = {
  GBM: 'gbm',
  INTEGRATION_HUB: 'integrationHub',
  LEGACY_INTEGRATIONS: 'legacyIntegrations',
  SETTINGS__ADMIN__ACCOUNT_SETTINGS: 'settings__admin__account_settings',
  SETTINGS__ADMIN__AUTO_REPLIES: 'settings__admin__auto_replies',
  SETTINGS__ADMIN__BALANCE_SETTINGS: 'settings__admin__balance_settings',
  SETTINGS__ADMIN__BUSINESS_HOURS: 'settings__admin__business_hours',
  SETTINGS__ADMIN__CHANNELS2__CHAT: 'settings__admin__channels2__chat',
  SETTINGS__ADMIN__CHANNELS2__CUSTOM: 'settings__admin__channels2__custom',
  SETTINGS__ADMIN__CHANNELS2__EMAIL: 'settings__admin__channels2__email',
  SETTINGS__ADMIN__CHANNELS2__FACEBOOK: 'settings__admin__channels2__facebook',
  SETTINGS__ADMIN__CHANNELS2__GBM: 'settings__admin__channels2__gbm',
  SETTINGS__ADMIN__CHANNELS2__INSTAGRAM: 'settings__admin__channels2__instagram',
  SETTINGS__ADMIN__CHANNELS2__SMS: 'settings__admin__channels2__sms',
  SETTINGS__ADMIN__CHANNELS2__TELEGRAM: 'settings__admin__channels2__telegram',
  SETTINGS__ADMIN__CHANNELS2__VOIP: 'settings__admin__channels2__voip',
  SETTINGS__ADMIN__CHANNELS2__WA_BUSINESS: 'settings__admin__channels2__wa_business',
  SETTINGS__ADMIN__CHATBOTS: 'settings__admin__chatbots',
  SETTINGS__ADMIN__CONTACT_GROUPS: 'settings__admin__contact_groups',
  SETTINGS__ADMIN__CSAT: 'settings__admin__csat',
  SETTINGS__ADMIN__CUSTOM_FIELDS: 'settings__admin__custom_fields',
  SETTINGS__ADMIN__CUSTOM_VIEWS: 'settings__admin__custom_views',
  SETTINGS__ADMIN__FLOWS: 'settings__admin__flows',
  SETTINGS__ADMIN__INBOX: 'settings__admin__inbox',
  SETTINGS__ADMIN__LABELS: 'settings__admin__labels',
  SETTINGS__ADMIN__LOCATIONS: 'settings__admin__locations',
  SETTINGS__ADMIN__QUICK_REPLIES: 'settings__admin__quick_replies',
  SETTINGS__ADMIN__RULES: 'settings__admin__rules',
  SETTINGS__ADMIN__SUBSCRIPTION_SETTINGS: 'settings__admin__subscription_settings',
  SETTINGS__ADMIN__TEAMS: 'settings__admin__teams',
  SETTINGS__ADMIN__TICKET_RESULTS: 'settings__admin__ticket_results',
  SETTINGS__ADMIN__TRANSACTIONS: 'settings__admin__transactions',
  SETTINGS__ADMIN__USAGE_OVERVIEW: 'settings__admin__usage_overview',
  SETTINGS__ADMIN__USERS: 'settings__admin__users',
  SETTINGS__ADMIN__WA_TEMPLATE: 'settings__admin__wa_template',
  SETTINGS__ADMIN__WEB_WIDGETS: 'settings__admin__web_widgets',
  SETTINGS__ADMIN__WIDGET_AUTOMATION: 'settings__admin__widget_automation',
} as const;
