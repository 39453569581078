<template>
  <t-modal
    v-model="isOpen"
    :title="translations.title"
    data-test="two-factor-authentication-setup-step-one"
    variant="narrow"
    @close="handleClose"
  >
    {{ translations.description }}
    <div class="mb-6 mt-6 flex justify-center">
      <img :src="qr" class="w-48 rounded-xl border border-solid p-2" />
    </div>
    <t-divider class="my-6" :text="translations.secretLabel" />
    <t-input-text id="twoFactorAuthenticationSecret" ref="secret" :model-value="secret" readonly>
      <template #end>
        <button
          v-tooltip="{
            placement: 'top',
            content: buttonText,
            popperClass: 'tooltip-vuetify',
            show: isTooltipVisible,
            trigger: 'manual',
          }"
          class="flex"
          @click="handleCopyToClipboard"
        >
          <copy-linear />
        </button>
      </template>
    </t-input-text>
    <template #footer>
      <t-button size="md" btn-style="secondary" @click="handleClose">
        {{ translations.cancel }}
      </t-button>
      <t-button size="md" @click="handleContinue">
        {{ translations.continue }}
      </t-button>
    </template>
  </t-modal>
</template>

<script lang="ts">
import { CopyLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import { copyToClipboard } from '@/util/helpers';

import { SETUP_STEP } from '../constants';

export default defineComponent({
  name: 'TwoFactorAuthSetupStepOne',
  emits: ['close', 'goToStep'],
  components: { CopyLinear },
  props: {
    isOpen: Boolean,
    isResetFlow: Boolean,
    isSaving: Boolean,
    qr: String,
    secret: String,
  },
  data() {
    return {
      buttonText: this.$t('general.copied'),
      isTooltipVisible: false,
    };
  },
  computed: {
    translations() {
      return {
        title: this.isResetFlow
          ? this.$t('auth.two_factor_authentication_reset_step_one_title')
          : this.$t('auth.two_factor_authentication_setup_step_one_title'),
        description: this.$t('auth.two_factor_authentication_setup_step_one_description'),
        secretLabel: this.$t('auth.two_factor_authentication_setup_step_one_secret_label'),
        cancel: this.$t('general.cancel'),
        continue: this.$t('general.continue'),
      };
    },
  },
  methods: {
    handleContinue() {
      this.$emit('goToStep', SETUP_STEP.TWO);
    },
    handleCopyToClipboard() {
      copyToClipboard(this.secret);
      this.$refs.secret.$refs.input.select();
      this.isTooltipVisible = true;
      setTimeout(() => {
        this.isTooltipVisible = false;
      }, 2000);
    },
    handleClose() {
      this.$emit('close');
    },
  },
});
</script>
