<script type="text/babel">
import _ from 'lodash';

export default {
  name: 'CreateContactModal',
  emits: ['call-reload-method'],
  props: {
    scope: {
      type: String,
      default: 'all',
    },
  },
  data() {
    return {
      saving: false,
      contact: {
        type: 'email',
        is_private: this.scope === 'private',
      },
      custom_field_data: [],
      selected: [],
    };
  },

  mounted() {
    this.custom_field_data = this.getCustomFields();
    this.setSelected();
  },

  methods: {
    createContact() {
      // console.log(this.contact);
      // if(this.contact)

      this.saving = true;
      axios
        .post('/api/v2/contacts', {
          ...{ custom_field_data: this.custom_field_data },
          ...{ contact_group_ids: this.selected },
          ...this.contact,
        })
        .then((res) => {
          this.saving = false;
          $('#CreateContactModal').modal('hide');

          this.custom_field_data = this.getCustomFields();
          this.setSelected();

          this.flashSuccess(this.$t('contacts.create_contact_the_contact_has_been_created_successfully'));
          this.$emit('call-reload-method');
          this.$router.push('/contacts/' + res.data.id);
        })
        .catch(() => {
          this.saving = false;
        });
    },

    getCustomFields() {
      return (this.$root.customContactFields || []).map((field) => {
        let value = '';
        return { title: field.title, id: field.id, value: value };
      });
    },

    setSelected() {
      this.selected = _.map(this.contact.groups, (group) => {
        return group.id;
      });
    },

    resetValue() {
      this.contact.phone = '';
      this.contact.email = '';
    },
  },
};
</script>

<template>
  <div id="CreateContactModal" class="modal">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <form @submit.prevent="createContact">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('contacts.create_contact') }}</h5>
            <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="form-group">
              <label class="control-label">{{ $t('contacts.full_name') }}</label>
              <input v-model="contact.name" type="text" :placeholder="$t('contacts.full_name')" class="form-control" />
            </div>
            <div class="form-group">
              <label class="control-label">{{ $t('contacts.type') }}</label>
              <select v-model="contact.type" class="form-control" @change="resetValue()">
                <option value="email">{{ $t('contacts.email') }}</option>
                <option value="phone">{{ $t('contacts.phone') }}</option>
              </select>
            </div>
            <div v-if="contact.type == 'email'" class="form-group">
              <label class="control-label">
                {{ $t('contacts.email') }}
                <span class="text-danger">*</span>
              </label>
              <input
                v-model="contact.email"
                type="email"
                :placeholder="$t('contacts.email')"
                class="form-control"
                required
              />
            </div>
            <div v-if="contact.type == 'phone'" class="form-group">
              <label class="control-label">
                {{ $t('contacts.phone') }}
                <span class="text-danger">*</span>
              </label>
              <tel-input v-model="contact.phone" :required="true" :placeholder="$t('contacts.phone')" />
            </div>
            <div v-for="field in custom_field_data" class="form-group">
              <label class="control-label">{{ field.title }}</label>
              <input
                v-model="field.value"
                type="text"
                :placeholder="field.title"
                autocomplete="off"
                class="form-control"
              />
            </div>
            <div v-if="Object.keys($root.contactGroups).length > 0" class="form-group">
              <label>{{ $t('contacts.groups') }}</label>
              <div v-for="contactGroup in $root.contactGroups">
                <label class="control-label">
                  <input v-model="selected" type="checkbox" :value="contactGroup.id" />
                  {{ contactGroup.name }}
                </label>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <label class="pull-left mb-0 mt-2 flex items-center text-grey-600">
              <input v-model="contact.is_private" type="checkbox" :value="1" />
              <i></i>
              <i class="fa fa-lock ml-2 mr-1"></i>
              {{ $t('contacts.create_contact_this_is_a_private_contact') }}
            </label>
            <button type="submit" :disabled="saving" class="btn green">
              <span v-if="saving"><i class="fa fa-spinner fa-spin"></i></span>
              <span v-if="!saving">{{ $t('general.save') }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
