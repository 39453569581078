<template>
  <div class="contacts-search">
    <atomic-input
      v-model="computedValue"
      class="contacts-search-input"
      v-bind="$attrs"
      size="sm"
      type="search"
      data-test="contact-search-field"
    />

    <button v-if="computedValue" class="clear fade-in" @click="clear">
      <cross-circle-fill />
    </button>

    <button class="button">
      <search-linear />
    </button>
  </div>
</template>

<script>
import { CrossCircleFill, SearchLinear } from '@trengo/trengo-icons';

export default {
  name: 'ContactsSearch',
  emits: ['update:modelValue'],
  components: {
    SearchLinear,
    CrossCircleFill,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    clear() {
      this.computedValue = '';
    },
  },
};
</script>

<style lang="scss" src="./ContactsSearchGlobal.scss" />
