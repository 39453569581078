<template>
  <div class="flex items-center items-stretch justify-center bg-grey-200" style="border-radius: 10px">
    <span
      :class="{ 'ticket-sub-filter--active': modelValue === 'last-week' }"
      class="ticket-sub-filter flex flex-1 flex-col justify-center text-center text-sm text-grey-600"
      @click="$emit('update:modelValue', 'last-week')"
    >
      {{ $t('tickets.filter_last_week') }}
    </span>
    <span
      :class="{ 'ticket-sub-filter--active': modelValue === 'last-month' }"
      class="ticket-sub-filter flex flex-1 flex-col justify-center text-center text-sm text-grey-600"
      @click="$emit('update:modelValue', 'last-month')"
    >
      {{ $t('tickets.filter_last_month') }}
    </span>
    <span
      :class="{ 'ticket-sub-filter--active': modelValue === 'all-time' }"
      class="ticket-sub-filter flex flex-1 flex-col justify-center text-center text-sm text-grey-600"
      @click="$emit('update:modelValue', 'all-time')"
    >
      {{ $t('tickets.filter_all_time') }}
    </span>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  name: 'SearchRange',
};
</script>

<style scoped>
.ticket-sub-filter {
  display: flex;
}
</style>
