<template>
  <div class="row-row overflow-y-scroll">
    <div v-if="isLgOrXl" class="row-body scrollable pb-6">
      <div class="row-inner settings-tab-content-scrollable-container" ref="scrollableContainer">
        <div class="p-a p-b-0 container">
          <div class="p-a xl:w-2/3">
            <slot />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row-body settings-tab-content-scrollable-container overflow-scroll bg-white px-4 pb-10 pt-6">
      <slot />
    </div>
  </div>
</template>

<script>
import breakpoints from '@/util/breakpoints';

export default {
  name: 'SettingsLayout',
  data() {
    return {
      breakpoints,
    };
  },
  computed: {
    isLgOrXl() {
      const { comparisons } = this.breakpoints;
      return comparisons.eq_lg || comparisons.eq_xl;
    },
  },
};
</script>
