<template>
  <div class="schedule-send-date" data-test="schedule-send-date">
    <div class="t-text-desktop-label-md mt-8">{{ $t('broadcast.schedule_send_date') }}</div>
    <div class="ml-3 mt-3">
      <label class="pointer my-1 flex items-center">
        <t-input-radio
          v-model="localDateRadio"
          type="radio"
          name="date"
          value="now"
          data-test="now-radio"
          class="mt-1"
        />
        {{ $t('broadcast.scheduled_date_now') }}
      </label>
    </div>
    <div class="ml-3 flex h-48px items-center">
      <label class="pointer flex items-center">
        <t-input-radio
          v-model="localDateRadio"
          type="radio"
          name="date"
          value="later"
          data-test="later-radio"
          class="mt-1"
        />
        {{ $t('broadcast.scheduled_date_later') }}
      </label>
      <div
        v-if="localDateRadio === 'later'"
        class="input-container ml-6 flex w-2/3 items-center rounded-xl border bg-white"
        :class="{ error: !validation.date }"
      >
        <div ref="datepicker" class="t-text-desktop-paragraph-md min-w-full">
          <atomic-datepicker
            v-model="localDate"
            :ranges="false"
            format-display="DD/MM/YYYY"
            full-width
            :as-unix="false"
            tabindex="0"
            :placeholder="dateToday"
            data-test="datepicker"
            :min-date="minDate"
          >
            <template #prependIcon>
              <calendar-dates-linear size="18px" class="ml-1 mr-2.5" data-test="datepicker-icon" />
            </template>
          </atomic-datepicker>
        </div>
      </div>
      <div class="ml-4 w-1/3">
        <search-bar
          v-if="localDateRadio === 'later'"
          :model-value="time"
          :options="timeOptions"
          size="lg"
          placeholder="12:00"
          class="b-1"
          :error="!validation.time || !validation.timeFormat"
          prepend-icon="wab-broadcasting/time"
          :prepend-icon-size="18"
          data-test="time-searchbar"
          :height="405"
          :scroll-height="scrollHeight"
          @update:modelValue="(e) => handleFilterTime(e.target.value)"
        >
          <template #item="slotProps">
            <div
              class="
                time-item
                pointer
                t-text-desktop-paragraph-sm
                mx-3
                my-1
                rounded-xl
                px-2
                py-1
                text-grey-800
                hover:bg-grey-300
              "
              @click="(e) => handleItemClick(e.currentTarget.innerText)"
            >
              {{ slotProps.item }}
            </div>
          </template>
        </search-bar>
      </div>
    </div>
    <t-error-item
      v-if="localDateRadio === 'later' && !validation.date && validation.time"
      data-test="date-error"
      class="my-1"
      :text="$t('broadcast.select_date_to_schedule_broadcast_placeholder')"
    />
    <t-error-item
      v-if="localDateRadio === 'later' && !validation.time && validation.date"
      data-test="time-error"
      class="my-1"
      :text="$t('broadcast.select_time_to_schedule_broadcast_placeholder')"
    />
    <t-error-item
      v-if="localDateRadio === 'later' && !validation.time && !validation.date"
      data-test="time-date-error"
      class="my-1"
      :text="$t('broadcast.select_date_and_time_to_schedule_broadcast_placeholder')"
    />
    <t-error-item
      v-if="localDateRadio === 'later' && !validation.timeFormat"
      data-test="time-invalid-error"
      class="my-1"
      :text="$t('broadcast.enter_valid_time_to_schedule_broadcast')"
    />
    <t-error-item
      v-if="timeBeforeNowError"
      data-test="time-before-error"
      class="my-1"
      :text="$t('broadcast.enter_future_time_to_schedule_broadcast')"
    />
  </div>
</template>

<script>
import { CalendarDatesLinear } from '@trengo/trengo-icons';

import SearchBar from '@/components/WabBroadcasting/components/SearchBar';

import chooseableTimes from './allTimes.js';

export default {
  name: 'ScheduleSendDate',
  emits: ['date', 'time', 'dateRadio'],
  components: { SearchBar, CalendarDatesLinear },
  props: {
    validation: {
      type: Object,
      default: () => ({}),
    },
    dateRadio: {
      type: String,
      default: 'now',
    },
    date: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filteredTimes: [],
      localDateRadio: 'now',
      localDate: '',
    };
  },
  watch: {
    localDate(value) {
      this.$emit('date', value);
    },
    time(value) {
      this.$emit('time', value);
    },
    localDateRadio(value) {
      this.$emit('dateRadio', value);
    },
    dateRadio(value) {
      this.localDateRadio = value;
    },
    date(value) {
      this.localDate = value;
    },
  },
  computed: {
    timeOptions() {
      if (this.filteredTimes.length) {
        return this.filteredTimes;
      } else return this.allTimes;
    },
    dateToday() {
      return moment().tz(this.$root.user.timezone).format('DD/MM/YYYY');
    },
    minDate() {
      return moment().tz(this.$root.user.timezone).subtract(1, 'days').format('YYYY-MM-DD');
    },
    allTimes() {
      const now = moment().tz(this.$root.user.timezone);
      if (
        this.localDate.split('-')[2] === now.format('DD') &&
        this.localDate.split('-')[1] === now.format('MM') &&
        this.localDate.split('-')[0] === now.format('YYYY')
      ) {
        return [...chooseableTimes].filter((time) => time >= now.format('HH:mm'));
      } else {
        return [...chooseableTimes];
      }
    },
    scrollHeight() {
      const today = moment().tz(this.$root.user.timezone);
      if (
        this.localDate.split('-')[2] === today.format('DD') &&
        this.localDate.split('-')[1] === today.format('MM') &&
        this.localDate.split('-')[0] === today.format('YYYY')
      ) {
        return 0;
      } else return 588;
    },
    timeBeforeNowError() {
      return (
        this.localDateRadio === 'later' && this.validation.date && this.validation.time && !this.validation.timeAfterNow
      );
    },
  },
  methods: {
    handleFilterTime(value) {
      this.filteredTimes = this.allTimes.filter((item) => item.toLowerCase().includes(value.toLowerCase()));
    },
    handleItemClick(value) {
      this.$emit('time', value);
    },
  },
};
</script>

<style lang="scss" src="./ScheduleSendDate.scss" />
