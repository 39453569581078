import { defineStore } from 'pinia';

type ReportingFilters = {
  hasTeam: boolean;
  hasChannel: boolean;
  hasSurvey: boolean;
  hasDatePicker: boolean;
  hasConversation: boolean;
  hasLabel: boolean;
  hasBusinessHours: boolean;
  hasStatus: boolean;
  hasCSAT: boolean;
  hasTicketAssignedAt: boolean;
  hasTicketClosedAt: boolean;
  hasTicketCreatedAt: boolean;
  hasAgent: boolean;
};

type State = {
  ticketsAmount: number;
  agentsAmount: number;
  reportingFilters: ReportingFilters;
};

export const useReportStore = defineStore('ReportingsStore', {
  state: (): State => {
    return {
      ticketsAmount: 0,
      agentsAmount: 0,
      reportingFilters: {
        hasTeam: true,
        hasChannel: true,
        hasSurvey: false,
        hasDatePicker: false,
        hasConversation: false,
        hasLabel: true,
        hasBusinessHours: false,
        hasStatus: false,
        hasCSAT: false,
        hasTicketAssignedAt: false,
        hasTicketClosedAt: false,
        hasTicketCreatedAt: false,
        hasAgent: false,
      },
    };
  },
});
