import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "relative border-t border-grey-300 border-opacity-30 p-3",
  "data-test": "customise-sidebar-items-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gear2_linear = _resolveComponent("gear2-linear")!
  const _component_sidebar_item_toggle_list = _resolveComponent("sidebar-item-toggle-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(["t-text-sm-emphasize flex w-full flex-row items-center rounded-md p-2 text-grey-800 transition-colors hover:bg-grey-200 active:bg-grey-300", { 'bg-grey-300': _ctx.isToggleOpened }]),
      "data-test": "customise-sidebar-toggle-button",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isToggleOpened = !_ctx.isToggleOpened), ["prevent"]))
    }, [
      _createVNode(_component_gear2_linear, {
        class: "mr-2 text-grey-800",
        size: "1.3rem"
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.itemTab === 'contact' ? _ctx.$tc('ticket_sidebar.customise_contacts') : _ctx.$tc('ticket_sidebar.customise_integrations')), 1 /* TEXT */)
    ], 2 /* CLASS */),
    (_ctx.isToggleOpened)
      ? (_openBlock(), _createBlock(_component_sidebar_item_toggle_list, {
          key: 0,
          label: _ctx.label,
          items: _ctx.items,
          onToggleItem: _ctx.emitToggle,
          onCloseList: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isToggleOpened = false))
        }, null, 8 /* PROPS */, ["label", "items", "onToggleItem"]))
      : _createCommentVNode("v-if", true)
  ]))
}