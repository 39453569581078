import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-6"
}
const _hoisted_2 = { class: "t-text-sm-emphasize text-grey-800" }
const _hoisted_3 = { class: "m-0 text-sm text-grey-700" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "t-text-sm-emphasize text-grey-800" }
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_external_link_linear = _resolveComponent("external-link-linear")!
  const _component_t_card = _resolveComponent("t-card")!

  return (_openBlock(), _createBlock(_component_t_card, {
    class: "break-words bg-white",
    "data-test": "integration-requirement-card-wrapper"
  }, {
    default: _withCtx(() => [
      (_ctx.metadata.requirements && _ctx.metadata.requirements.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$tc('integration_hub.requirement_header')), 1 /* TEXT */),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.metadata.requirements, (requirement) => {
              return (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$tc(requirement)), 1 /* TEXT */))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.metadata.support && _ctx.metadata.support.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$tc('integration_hub.support_header')), 1 /* TEXT */),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.metadata.support, (support) => {
              return (_openBlock(), _createElementBlock("a", {
                key: support.url,
                class: "flex items-center text-grey-700 underline hover:text-leaf-500 hover:underline",
                target: "_blank",
                rel: "noopener",
                href: support.url
              }, [
                _createTextVNode(_toDisplayString(support.text) + " ", 1 /* TEXT */),
                _createVNode(_component_external_link_linear, { class: "ml-1 font-bold" })
              ], 8 /* PROPS */, _hoisted_6))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}