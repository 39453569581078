<script lang="ts" setup>
import { FolderLinear, PlusLinear } from '@trengo/trengo-icons';

import { vm } from '@/ApplicationHandler';

function handleCreateGroups() {
  vm.$router.push('/admin/contact_groups/create');
}
</script>

<template>
  <div class="mt-40 flex h-full w-full flex-1 flex-col items-center justify-between">
    <div class="border-gray-900 rounded-xl border-1 border-opacity-10 bg-white p-2">
      <FolderLinear width="24px" height="24px" />
    </div>
    <div class="t-text-md-emphasize mb-2 mt-4 text-grey-800">{{ vm.$t('broadcast.you_dont_have_contact_groups') }}</div>
    <div class="t-text-sm mb-10 text-grey-700">{{ vm.$t('broadcast.get_started_by_creating_one') }}</div>
    <t-button class="flex flex-row items-center" @click="handleCreateGroups">
      {{ vm.$t('broadcast.create_group') }}
      <PlusLinear class="ml-2 text-white" width="20px" height="20px" />
    </t-button>
  </div>
</template>
