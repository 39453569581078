<template>
  <div class="relative inline-block w-full">
    <template v-if="icon !== null">
      <div class="pointer-events-none absolute mx-2 flex flex h-full items-center items-center px-2">
        <i :class="iconClass">{{ icon }}</i>
      </div>
    </template>
    <select
      v-model="selectValue"
      style="border-color: #ebedf5"
      class="block w-full appearance-none text-ellipsis rounded border-2 px-4 py-3 pr-8 leading-tight text-grey-800"
      :class="{ 'pl-12': icon !== null }"
    >
      <option v-for="option in options" :value="option.value">{{ option.name }}</option>
    </select>
    <div
      class="pointer-events-none absolute flex items-center px-2"
      style="top: 0; bottom: 0; right: 0; color: #dddddd"
    >
      <i class="material-icons">expand_more</i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoundSelect',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    iconClass: {
      type: String,
      default: 'material-icons',
    },
    icon: {
      type: String,
      default: null,
    },

    options: {
      type: Array,
      default: () => [
        { value: true, name: 'Yes' },
        { value: false, name: 'No' },
      ],
    },
  },

  computed: {
    selectValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
};
</script>

<style scoped></style>
