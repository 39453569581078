import eventBus from '@/eventBus';
import dnd from '@/util/doNotDistburb';
import { htmlToText, isServer } from '@/util/helpers';
import { escapeHtml } from '@/util/stringHelpers';

import store from '../../../store';
import UserModel from '../Models/UserModel';

/* eslint-disable */
import { vm } from '@/ApplicationHandler';

export const notifyThreadMessage = (thread, message, contact) => {
  if (!vm.chatEnabled) {
    //console.log('chat disabled');
    return;
  }

  // don't notify if dnd
  if (dnd.isDnd(store.state.misc.doNotDisturb)) {
    //console.log('dnd');
    return;
  }

  // don't notify if your own message
  if (store.getters['usersInternalChat/currentUser'].id === message.userId) {
    //console.log('own message');
    return;
  }

  // don't notify if current thread except if hidden or on other page
  if (
    store.state.chat.currentThread === thread.identifier &&
    window.hasWindowFocus &&
    window.location.pathname.startsWith('/chat/')
  ) {
    //console.log('current thread');
    return;
  }

  let mentioned = message.body.includes(store.getters['usersInternalChat/currentUser']).identifier;
  let messageBody = message.body;

  // video call behaves same as mention
  if (message.bodyType === 'VIDEO_CALL') {
    // don't notify about calls you started
    if (parseInt(message?.meta?.initiator_user_id) === store.getters['usersInternalChat/currentUser'].id) {
      return;
    }

    mentioned = true;
  }

  // if not mentioned, do some more checks
  if (!mentioned) {
    //console.log('not mentioned');

    // don't notify if muted
    if (thread.muted) {
      //console.log('muted');
      return;
    }

    // don't notify if status is set to 'away'
    if (!store.getters['usersInternalChat/currentUser'].chatStatus) {
      //console.log('away');
      return;
    }
  }

  // notify via browser if window is hidden
  if (!window.hasWindowFocus) {
    //console.log('no focus');
    notifyViaBrowser(
      htmlToText(thread.identifier),
      htmlToText(messageBody),
      '/chat/' + htmlToText(thread.identifier) /*+'/'+message.id*/
    );
  }

  // get UserModel for message sender
  const user = store.getters['usersInternalChat/userById'](message.userId);
  // notify via toast
  notifyViaTrengo(
    htmlToText(thread.identifier),
    htmlToText(messageBody),
    '/chat/' + htmlToText(thread.identifier) /*+'/'+message.id*/,
    user
  );
};

export const notifyViaTrengo = (title, message, url, user) => {
  eventBus.$emit('toast', {
    url: url,
    message: message,
    subject: title,
    from: {
      full_name: user.fullName,
      name: user.name,
      profile_image: user.profileImage,
      color: user.color,
      abbr: user.abbr,
    },
  });
};

export const notifyViaBrowser = (title, message, url) => {
  let notification = false;

  // Let's check if the browser supports notifications
  if (!('Notification' in window)) {
    console.log('notification not supported, fallback to growl');
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === 'granted') {
    // If it's okay let's create a notification
    notification = new Notification(title, { body: message, icon: Assets_path + 'build/img/trengo.png' });
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        notification = new Notification(title, { body: message, icon: Assets_path + 'build/img/trengo.png' });
      }
    });
  }

  // fallback
  if (!notification) {
    //console.log('notification permission denied, fallback to growl');
    // notifyViaTrengo(title, message, url, contact);
  } else {
    notification.onclick = (e) => {
      //console.log(e, url);
      vue_redirect(url);
      window.focus();
      notification.close();
      if (window.isElectron && electronApiBridge) {
        electronApiBridge.focus(1);
      }
    };
  }
};

export const isOnlyEmoji = (str = '') => {
  let ranges = [
    '\u00a9|\u00ae|\uFE0F|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]',
    ' ', // Also allow spaces,
    '\r\n', // Also allow newlines
    '\n',
    '<br>',
  ].join('|');

  let removeEmoji = (str) => str.replace(new RegExp(ranges, 'g'), '');
  return !window.stripHtml(removeEmoji(str)).length;
};

function getMessageHtmlReplacements(context = 'thread') {
  return [
    // bounce heart emoji and bigger font size for emoji only
    ...(context === 'thread'
      ? [
          (body) => {
            if (isOnlyEmoji(body) && window.stripHtml(body).length) {
              let emojiCodesHeart = [56473, 56474, 56475, 56476, 65039];

              if (
                (emojiCodesHeart.includes(window.stripHtml(body).charCodeAt(1)) && window.stripHtml(body).length < 3) ||
                (window.stripHtml(body).charCodeAt(1) === 65039 && window.stripHtml(body).length === 7)
              ) {
                return (
                  '<div class="heart-emoji flex items-center" style="font-size:32px;line-height:32px; ">' +
                  body +
                  '</div>'
                );
              } else {
                return '<span style="font-size:32px;line-height:32px;">' + body + '</span>';
              }
            }

            return body;
          },
        ]
      : []),

    // bigger font size for emoji
    ...(context === 'thread'
      ? [
          (body) => {
            if (!isOnlyEmoji(body)) {
              return body.replace(
                /((\u00a9|\u00ae|\uFE0F|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])+)/gi,
                '<span style="font-size:20px;vertical-align:middle; font-style: normal;">$1</span>'
              );
            } else {
              return body;
            }
          },
        ]
      : []),

    // urls // todo mentions and threads via linkify (and can we do tickets and/or colors with # ?)
    ...(context === 'thread'
      ? [
          (body) => {
            return window.linkifyHtml(body, { className: 'color-green-hover text-green open-external' });
          },
        ]
      : []),

    // username/thread mention (24fps first time, 33fps later)
    ...(context === 'thread'
      ? [
          (body) => {
            body.replace(new RegExp('(^|\\s)[@#][\\p{L}\\p{N}-]+', 'uig'), (match) => {
              if (match.trim().startsWith('@')) {
                let thread = store.state.chat.threads.find(
                  (thread) => thread.identifier === store.state.chat.currentThread
                );
                let user = store.state.usersInternalChat.users.find((user) => user.getIdentifier() === match.trim());

                // if user is in the current thread
                if (thread.participants.find((participant) => participant.id === user?.id)) {
                  body = user
                    ? body.replace(
                        match,
                        ' <a href="/chat/' +
                          user.getIdentifier() +
                          '" onclick="arguments[0].preventDefault();vue_redirect(\'/chat/' +
                          escapeHtml(user.getIdentifier()) +
                          '\')" class="mention color-green-hover">@' +
                          escapeHtml(user.getDisplayName()) +
                          '</a>'
                      )
                    : body;
                }
              } else if (match.trim().startsWith('#')) {
                let thread = store.state.chat.threads.find(
                  (thread) => window.stripHtmlAttribute(thread.identifier) === match.trim()
                );
                body = thread
                  ? body.replace(
                      match,
                      ' <a href="/chat/' +
                        thread.identifier +
                        '" onclick="arguments[0].preventDefault();vue_redirect(\'/chat/' +
                        window.stripHtmlAttribute(window.escapeRegex(thread.identifier)) +
                        '\')" class="mention color-green-hover">' +
                        escapeHtml(thread.identifier) +
                        '</a>'
                    )
                  : body;
              }
            });
            return body;
          },
        ]
      : []),
  ];
}

const audio = isServer
  ? { declined: {} }
  : {
      declined: new Audio('https://trengo.s3.eu-central-1.amazonaws.com/videocalling/audio/declined.mp3'),
    };
audio.declined.volume = 0.3;
export { audio };

export const getHtmlReplacements = (body, context = 'thread') => {
  getMessageHtmlReplacements(context).forEach((f) => {
    body = f(body);
  });
  return body;
};

// todo refactor to outside Chat folder (same goes for UserModel.js)
export const getBotUser = () => {
  return new UserModel({
    id: null,
    exists: false,
    first_name: 'Bot',
    full_name: 'Bot',
    profile_image: Assets_path + 'img/chatbot.png',
    abbr: 'B',
    color: '#ebedf6',
  });
};

export const getDeletedUser = (id) => {
  return new UserModel({
    id: id,
    exists: false,
    first_name: 'deleted-user',
    full_name: 'Deleted user #' + id,
    abbr: 'X',
    color: '#ebedf6',
  });
};

/**
 * TODO ferdi: refactor; rename to clearer name, e.g. 'trimHtml'.
 * TODO ferdi: refactor; make globally usable (start/util.js)
 * @param body
 * @returns {string}
 */
export const clean = (body) => {
  // max 2 br's in a row
  body = body.replace(/(<br>){3,}/g, '<br><br>');
  // not starting or ending with br's
  body = body.replace(/^(<br>)+|(<br>)+$/g, '');
  // replace nbsp. prevents multiple spaces
  body = body.replaceAll('&nbsp;', ' ');
  // trim spaces from start & end
  body = body.trim();

  return body;
};
