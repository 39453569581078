<template>
  <div>
    <div class="flex items-center justify-between">
      <div
        v-tooltip="
          tooltipText && {
            placement: 'bottom',
            content: tooltipText,
            popperClass: 'tooltip-vuetify tooltip-medium',
          }
        "
        class="w-100 w-full"
      >
        <t-input-text
          :id="inputId"
          ref="textInput"
          v-model.trim="computedValue"
          :placeholder="placeholder"
          :type="inputType"
          :maxlength="maxLength"
          :autofocus="autoFocusInput"
          :has-error="!isValidInput"
          :max="maxCharacters"
          :disabled="disabled"
          :label="label"
          :sub-label="subLabel"
          data-test="wa-templates-input"
          @input="$emit('handle-input-change', $event)"
          @keyup="$emit('keyup', $event)"
          @paste="$emit('paste', $event)"
          @blur="$emit('blur', $event)"
        >
          <template v-if="showTemplateEnd" #end>
            <t-badge :text="characterCount" />
          </template>
        </t-input-text>
      </div>
      <popup-menu
        v-if="showPopupMenu"
        v-model="popupmenuIsOpen"
        class="ml-3"
        action-text="Delete"
        @open="handleOpenPopup"
        @close="handleClosePopup"
        @handle-click="handleDeleteButton"
      ></popup-menu>
    </div>

    <t-error-item v-if="!isValidInput" class="mt-1" data-test="wa-input-error" :text="errorMsg" />
  </div>
</template>

<script lang="ts">
import PopupMenu from '../PopupMenu';

export default {
  name: 'WaTemplateInput',
  emits: ['handle-input-change', 'handle-delete-button', 'keyup', 'paste', 'blur', 'update:modelValue'],
  components: { PopupMenu },
  props: {
    isValidInput: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validateBtnText: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
    characterCount: {
      type: String,
      default: '',
    },
    maxCharacters: {
      type: Number,
      default: 25,
    },
    showPopupMenu: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: '',
    },
    autoFocusInput: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    inputId: {
      type: String,
      default: '',
    },
    showTemplateEnd: {
      type: Boolean,
      default: false,
    },
    subLabel: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    tooltipText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      popupmenuIsOpen: false,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    handleOpenPopup() {
      this.popupmenuIsOpen = true;
    },
    handleClosePopup() {
      this.popupmenuIsOpen = false;
    },
    handleDeleteButton() {
      this.$emit('handle-delete-button');
      this.handleClosePopup();
    },
  },
};
</script>
