import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "content",
  ref: "content",
  class: "h-full",
  "data-test": "settings-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "wrapper",
    class: "h-auto overflow-auto pb-8",
    onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleScroll && _ctx.handleScroll(...args)))
  }, [
    _createElementVNode("div", {
      id: "header",
      ref: "header",
      class: _normalizeClass(["top-0 z-50 w-full", _ctx.stickyHeaderClasses]),
      "data-test": "settings-header"
    }, [
      _renderSlot(_ctx.$slots, "header")
    ], 2 /* CLASS */),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ], 512 /* NEED_PATCH */)
  ], 32 /* NEED_HYDRATION */))
}