import { request } from '@/util';

import type { LoginParams, TokenParams } from './types';
import type { AxiosPromise } from 'axios';

// TWA-10 TODO merge endpoint constants from different places
// into a top-level data structure,
// which local-scoped api handlers would import
const endpoints = {
  login: '/auth/login',
  request2FAReset: '/request_2fa_reset/',
  setupPassword: '/client-api/me/setup/password',
  tfa: '/auth/tfa',
} as const;

type PostLogin = {
  '2fa': string;
};

type Reset2FA = {
  message: string;
};

export function postLogin(data: LoginParams): AxiosPromise<PostLogin> {
  return request(endpoints.login, 'POST', data);
}

export function post2FAToken(data: TokenParams) {
  return request(endpoints.tfa, 'POST', data);
}

export function reset2FA(secret: string): AxiosPromise<Reset2FA> {
  return request(`${endpoints.request2FAReset}${secret}`, 'GET');
}

export function setupPassword(form: { password: string; password_confirmation: string }) {
  return request(endpoints.setupPassword, 'POST', form);
}
