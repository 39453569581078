<template>
  <div
    class="internal-comment-attachment__wrapper"
    :class="{ outbound: isOutbound }"
    data-test="messageAttachmentWrapper"
  >
    <filetype-icon :extension="attachment.extension" class="mr-2 flex items-center" />

    <a
      :href="safeUrl(attachment.full_url)"
      class="my-0 cursor-pointer text-ellipsis hover:underline"
      target="_blank"
      rel="noopener"
      data-test="messageAttachmentFileUrl"
      @click="openInPopup(attachment, $event)"
    >
      {{ fileName }}
    </a>

    <div v-if="isDeletable" class="ml-auto flex items-center justify-center p-0">
      <t-spinner v-show="attachment.uploading" size="1.25rem" />

      <button v-show="!attachment.uploading" data-test="deleteAttachmentButton" @click.prevent="sendDelete">
        <cross-linear class="delete-icon" size="1.5rem" />
      </button>
    </div>

    <div v-if="hasDownloadButton" class="ml-auto flex w-fit flex-shrink-0 items-center">
      <button
        data-test="downloadAttachmentButton"
        class="ml-3 rounded-md p-2 transition-colors hover:bg-grey-200 disabled:bg-transparent"
        :disabled="isDownloading"
        @click.prevent="initiateDownload"
      >
        <import-linear v-if="!isDownloading" size="1.2rem" />
        <t-spinner v-else class="m-auto" size="1.2rem" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { CrossLinear, ImportLinear } from '@trengo/trengo-icons';

import FiletypeIcon from '@/components/MessageFeed/FiletypeIcon.vue';
import downloadFile from '@/util/downloadHelper';

export default {
  name: 'InternalCommentAttachment',
  emits: ['deleteAttachment'],
  components: {
    CrossLinear,
    ImportLinear,
    FiletypeIcon,
  },

  props: {
    attachment: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isDeletable: {
      type: Boolean,
      default: true,
    },
    isOutbound: {
      type: Boolean,
      default: false,
    },
    hasDownloadButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isDownloading: false,
    };
  },

  computed: {
    fileName() {
      return this.attachment.name || this.attachment.client_name;
    },
  },

  methods: {
    async initiateDownload() {
      this.isDownloading = true;

      await downloadFile(`/download/attachment/${this.attachment.id}`, this.fileName)
        .catch((err) => {
          console.error(err);
          this.flashError(err.message);
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },

    sendDelete() {
      this.$emit('deleteAttachment', this.attachment);
    },

    // TI-577: Not a fan of the reliance on the magical $root property, and also not a fan of the window objects being used in general.
    // This was copied over from EmailMessage.vue however, since we want to keep the current functionality for now.
    openInPopup(attachment, event) {
      if (
        attachment.full_url.match(/.(jpg|jpeg|png|gif|pdf)$/i) &&
        !this.$root.loadedFromApp &&
        !this.$root.isDesktopApp
      ) {
        // Prevents default behavior only if condition is true, otherwise acts as a normal <a> tag
        event.preventDefault();
        window.PopupCenter(this.safeUrl(attachment.full_url), attachment.client_name, 1020, 700);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.internal-comment-attachment__wrapper {
  @apply mb-1.5 flex flex-row items-center rounded-md border-1 border-black border-opacity-10 bg-sun-50 px-4 py-2;

  &.outbound {
    @apply bg-leaf-200;
  }

  &:hover .delete-icon {
    visibility: visible;
  }
}

.delete-icon {
  @apply ml-2;
  cursor: pointer;
  visibility: hidden;

  // Some tablets (for example some of the iPads) have this width
  @media (max-width: 810px) {
    visibility: visible;
  }
}
</style>
