import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const emailThemeRoutes: RouteRecord[] = [
  {
    path: '/admin/email_themes',
    component: require(/* : "ivr" */ '../../components/EmailTheme/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
    children: [
      {
        path: '/admin/email_themes/create',
        component: require('../../components/EmailTheme/Edit').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
      {
        path: '/admin/email_themes/:id',
        component: require('../../components/EmailTheme/Edit').default,
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
    ],
  },
];

export default emailThemeRoutes;
