<template>
  <div class="relative mt-6 h-88">
    <canvas ref="report" height="200" class="z-10 h-full"></canvas>
  </div>
</template>

<script lang="ts">
import Chart from 'chart.js/auto';
import { map } from 'lodash';
import moment from 'moment';
import { defineComponent } from 'vue';

import {
  ConversationsVolumeTodayLoadingStateMockData,
  ConversationsVolumeAverageLoadingStateMockData,
} from '@/components/Reporting/api/mocks';

import { getChartConfiguration, getPluralDayKey } from '../utilities';

import type { ConversationVolumeBucket } from '@/components/Reporting/api/types';
import type { ChartConfiguration, ChartData } from 'chart.js';

type AverageTicketCreatedData = {
  chartInstance?: ChartConfiguration;
  data?: ChartData;
  conversationsVolumeTodayLoadingState: ConversationVolumeBucket[];
  conversationsVolumeAvgLoadingState: ConversationVolumeBucket[];
  dayOfLast4Weeks?: string;
};

export default defineComponent({
  name: 'AverageTicketsCreatedChart',
  chartInstance: undefined,
  data(): AverageTicketCreatedData {
    return {
      data: undefined,
      conversationsVolumeTodayLoadingState: ConversationsVolumeTodayLoadingStateMockData,
      conversationsVolumeAvgLoadingState: ConversationsVolumeAverageLoadingStateMockData,
      dayOfLast4Weeks: undefined,
    };
  },

  mounted() {
    this.setCurrentDayOfWeek();
    this.setChart();
  },

  computed: {
    chartConfiguration() {
      return getChartConfiguration((key) => this.$t(key), false, true);
    },
  },

  methods: {
    setChart() {
      const chart = this.$refs.report.getContext('2d');

      this.data = {
        labels: map(this.conversationsVolumeAvgLoadingState, (value: ConversationVolumeBucket) => {
          const label = moment(value.timestamp).format('hhA');
          if (label[0] === '0') {
            return label.substring(1);
          }
          return label;
        }),
        datasets: [
          {
            label: this.$t('reports_v2.today'),
            data: map(this.conversationsVolumeTodayLoadingState, (value: ConversationVolumeBucket) => {
              return value.value;
            }),
            backgroundColor: '#E1E3E5',
            borderColor: '#E1E3E5',
            borderWidth: 1,
            borderRadius: 4, // Rounded corners,
          },
          {
            label: this.$t('reports_v2.average_of_last_days', { day: this.dayOfLast4Weeks }),
            data: map(this.conversationsVolumeAvgLoadingState, (value: ConversationVolumeBucket) => {
              return value.value;
            }),
            backgroundColor: '#E1E3E5',
            borderColor: '#E1E3E5',
            borderWidth: 1,
            borderRadius: 4, // Rounded corners,
          },
        ],
      };

      const data = this.data;

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: this.chartConfiguration.scales,
        plugins: this.chartConfiguration.plugins,
        categoryPercentage: 0.5,
        barPercentage: 0.8,
      };

      this.chartInstance = new Chart(chart, {
        type: 'bar',
        data,
        options,
      });
    },

    setCurrentDayOfWeek() {
      const currentDate = new Date();
      const currentDayKey = getPluralDayKey(currentDate.getDay());

      this.dayOfLast4Weeks = this.$t(`general.${currentDayKey}`);
    },
  },
});
</script>
