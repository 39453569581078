<script type="text/babel">
import { ArrowTopCircleLinear } from '@trengo/trengo-icons';
import { map, toArray } from 'lodash';
import { mapStores } from 'pinia';
import Multiselect from 'vue-multiselect';

import { FEATURE } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useEntitlementsStore } from '@/store/pinia';

export default {
  name: 'HelpCenterSetings',
  emits: ['call-load-help-center-method'],
  props: {
    helpCenter: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    localeCodes: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    ArrowTopCircleLinear,
    Multiselect,
  },

  data() {
    return {
      name: this.helpCenter.name,
      selectedLanguages: [],
      defaultLanguage: this.helpCenter.default_language,
      domain: this.helpCenter.domain,
      slug: this.helpCenter.slug,
      urlType: this.helpCenter.slug == '' || this.helpCenter.slug == null ? 'custom' : 'default',
      saving: false,
      homepage: {},
      title: {},
      analytics_id: this.helpCenter.google_analytics_id,
      custom_head_code: this.helpCenter.custom_head_code,
      disable_robot_index: this.helpCenter.disable_robot_index,
      published: this.helpCenter.published,
      deleting: false,
    };
  },

  computed: {
    ...mapStores(useEntitlementsStore),
    computedLocaleCodes() {
      return toArray(this.localeCodes);
    },
    isEntitled() {
      return this.entitlementsStore?.isEntitledTo(FEATURE.CUSTOMER_SERVICE__HELPCENTER__WHITELABEL);
    },
    minimumRequiredPlan() {
      return this.entitlementsStore?.getMinimumRequiredPlanFor(FEATURE.CUSTOMER_SERVICE__HELPCENTER__WHITELABEL);
    },
  },

  watch: {
    selectedLanguages(a) {
      if (
        this.defaultLanguage == null ||
        a.filter((lang) => {
          return lang.id == this.defaultLanguage;
        }).length == 0
      ) {
        this.defaultLanguage = a[0]['id'];
      }
    },
  },

  mounted() {
    this.helpCenter.languages.map((locale_code) => {
      // Fill selected languages
      if (this.localeCodes[locale_code]) {
        this.selectedLanguages.push(this.localeCodes[locale_code]);
      } else {
        setTimeout(() => this.selectedLanguages.push(this.localeCodes[locale_code]), 1000);
      }

      // Fill help center translations
      let trans = this.helpCenter.translations.filter((trans) => {
        return trans.locale_code == locale_code;
      })[0];
      if (trans == null) {
        trans = {
          title: '',
          content: '',
        };
      }

      this.title[locale_code] = trans['title'];
      this.homepage[locale_code] = trans['website'];
    });
  },

  methods: {
    save() {
      this.saving = true;

      let data = {
        name: this.name,
        published: this.published,
        languages: toArray(map(this.selectedLanguages, 'id')),
        default_language: this.defaultLanguage,
        slug: this.urlType == 'default' ? this.slug : null,
        domain: this.urlType == 'custom' ? this.domain : null,
        homepage: this.homepage,
        title: this.title,
        analytics_id: this.analytics_id,
        custom_head_code: this.custom_head_code,
        disable_robot_index: this.disable_robot_index,
        url_type: this.urlType,
      };

      axios
        .put('/api/v2/help_center/' + this.helpCenter.id, data)
        .then((res) => {
          this.saving = false;
          this.$emit('call-load-help-center-method');
          this.slug = res.data.slug;
          this.flashSuccess($t('help_center.you_have_successfully_updated_your_help_center_settings'));
          this.$tStorage.removeItem('hc.selected_locale');
          eventBus.$emit('help_center.list.reload');
        })
        .catch(() => {
          this.saving = false;
        });
    },

    async deleteHelpCenter() {
      const shouldDeleteHelpCenter = await this.$tConfirm(this.$t('help_center.delete_help_center_description'), {
        delete: true,
        title: this.$t('general.are_you_sure'),
      });
      if (shouldDeleteHelpCenter) {
        this.deleting = true;

        axios.delete('/api/v2/help_center/' + this.helpCenter.id).then(() => {
          router.push('/admin/help_center');
          eventBus.$emit('help_center.list.reload');
        });
      }
    },
  },
};
</script>

<template>
  <div class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis">{{ $t('help_center.settings') }}</span>
          <!--<div class="m-l-auto">-->
          <!--<button class="btn btn-success text-white btn-md" type="submit">Save</button>-->
          <!--</div>-->
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <div class="p-a container">
            <div class="box">
              <div class="box-header">
                <h2>{{ $t('help_center.general') }}</h2>
              </div>
              <div class="box-divider m-a-0"></div>
              <div class="box-body">
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">{{ $t('help_center.published') }}</label>
                  <div class="col-sm-8">
                    <select v-model="published" class="form-control">
                      <option value="0">{{ $t('help_center.no') }}</option>
                      <option value="1">{{ $t('help_center.yes') }}</option>
                    </select>
                    <span class="form-text">
                      {{ $t('help_center.your_help_center_wil_be_publicly_visible_when_published') }}
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">{{ $t('help_center.company_name') }}:</label>
                  <div class="col-sm-8">
                    <input v-model="name" type="text" class="form-control" />
                    <span class="form-text">{{ $t('help_center.the_company_name_for_this_help_center') }}</span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">{{ $t('help_center.languages') }}:</label>
                  <div class="col-sm-8">
                    <multiselect
                      v-model="selectedLanguages"
                      label="text"
                      track-by="id"
                      :allow-empty="false"
                      :options="computedLocaleCodes"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      :multiple="true"
                      :placeholder="$t('help_center.select_one_or_more_languages')"
                    ></multiselect>
                    <span class="form-text">
                      {{ $t('help_center.the_available_languages_description') }}
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">{{ $t('help_center.default_language') }}:</label>
                  <div class="col-sm-8">
                    <div v-for="lang in selectedLanguages" :key="lang.id">
                      <label>
                        <input v-model="defaultLanguage" type="radio" :value="lang.id" />
                        {{ lang.text }}
                      </label>
                    </div>
                    <span class="form-text">
                      {{ $t('help_center.default_language_description') }}
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">{{ $t('help_center.helpcenter_title') }}</label>
                  <div class="col-sm-8">
                    <div v-for="lang in selectedLanguages" :key="lang.id">
                      <span class="text-muted text-xs">{{ lang.text }} ({{ lang.id }})</span>
                      <input v-model="title[lang.id]" type="text" class="form-control" />
                    </div>
                    <span class="form-text">
                      {{ $t('help_center.name_your_help_center_this_will_be_visible_in_the_header') }}
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">{{ $t('help_center.homepage_url') }}</label>
                  <div class="col-sm-8">
                    <div v-for="lang in selectedLanguages" :key="lang.id">
                      <span class="text-muted text-xs">{{ lang.text }}</span>
                      <input v-model="homepage[lang.id]" type="text" class="form-control" />
                    </div>
                    <span class="form-text">
                      {{ $t('help_center.you_company_website_url_description') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="box-header">
                <h2>{{ $t('help_center.advanced') }}</h2>
              </div>
              <div class="box-divider m-a-0"></div>
              <div class="box-body">
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">{{ $t('help_center.help_center_url') }}</label>
                  <div class="col-sm-8">
                    <div class="flex">
                      <label class="m-r-xs">
                        <input v-model="urlType" type="radio" value="default" />
                        {{ $t('help_center.default') }}
                      </label>
                      <label class="flex items-center">
                        <div>
                          <input v-model="urlType" type="radio" value="custom" :disabled="!isEntitled" />
                        </div>
                        <div class="ml-1">
                          {{ $t('help_center.custom_domain') }}
                        </div>
                        <t-badge
                          v-if="!isEntitled && minimumRequiredPlan"
                          variant="upgrade"
                          :text="minimumRequiredPlan"
                          class="ml-2"
                        >
                          <template #icon><arrow-top-circle-linear size="1rem" /></template>
                        </t-badge>
                      </label>
                    </div>
                    <div v-if="urlType == 'default'">
                      <div class="input-group" style="max-width: 400px">
                        <input v-model="slug" type="text" class="form-control" />
                        <span class="input-group-addon">.trengohelp.com</span>
                      </div>
                      <br />
                    </div>
                    <div v-if="urlType == 'custom'">
                      <input
                        v-model="domain"
                        type="text"
                        class="form-control"
                        :placeholder="$t('help_center.url_type_placeholder')"
                      />
                      <span class="form-text">
                        {{ $t('help_center.custom_url_type_description') }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">{{ $t('help_center.analytics_tracking_id') }}</label>
                  <div class="col-sm-8">
                    <input
                      v-model="analytics_id"
                      type="text"
                      class="form-control"
                      :placeholder="$t('help_center.analytics_tracking_id_placeholder')"
                    />
                    <span class="form-text">
                      {{ $t('help_center.analytics_tracking_id_description') }}
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">{{ $t('help_center.custom_head_code') }}</label>
                  <div class="col-sm-8">
                    <textarea v-model="custom_head_code" class="form-control" rows="4"></textarea>
                    <span class="form-text">
                      {{ $t('help_center.custom_head_code_description') }}
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">{{ $t('help_center.disable_search_indexing') }}</label>
                  <div class="col-sm-8">
                    <select v-model="disable_robot_index" name="" class="form-control">
                      <option value="1">{{ $t('help_center.yes') }}</option>
                      <option value="0">{{ $t('help_center.no') }}</option>
                    </select>
                    <span class="form-text">
                      {{ $t('help_center.disable_search_indexing_description') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="box-body flow-root">
                <button
                  type="button"
                  class="btn btn-success pull-left text-white"
                  :disabled="saving"
                  @click="save()"
                  v-text="saving ? $t('help_center.saving') : $t('help_center.save_changes')"
                ></button>
                <button type="button" class="btn white pull-right" :disabled="deleting" @click="deleteHelpCenter()">
                  {{ $t('help_center.delete_help_center') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
