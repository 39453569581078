<template>
  <t-modal :close-on-backdrop-click="false" variant="narrow" :model-value="true" is-closable @close="$emit('close')">
    <template #header>
      <h3 class="t-text-h3 my-auto max-w-[90%] p-0">{{ $t('integration_hub.uninstall_integration') }}</h3>
    </template>

    <div class="flex flex-col">
      <p>{{ $t('integration_hub.uninstall_integration_description', { name: integration.name }) }}</p>
      <t-inline-banner class="mb-4">
        <template #icon>
          <info-linear />
        </template>

        <p class="m-0 p-0">{{ $t('integration_hub.uninstall_integration_information', { name: integration.name }) }}</p>
      </t-inline-banner>
    </div>

    <template #footer>
      <t-button btn-style="secondary" :disabled="inProgress" @click="$emit('close')">
        {{ $t('general.cancel') }}
      </t-button>

      <t-button class="flex flex-row items-center justify-center" :disabled="inProgress" @click="save">
        <p class="m-0 p-0">{{ $t('integration_hub.uninstall') }}</p>
        <t-spinner v-if="inProgress" size="1rem" class="ml-2" />
      </t-button>
    </template>
  </t-modal>
</template>

<script lang="ts">
import { InfoLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import type { Installation, Integration } from '@/store/types/integrations';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'IntegrationHubUninstallModal',
  components: { InfoLinear },
  emits: ['close', 'uninstall'],
  props: {
    installation: {
      type: Object as PropType<Installation>,
      default: () => ({}),
    },

    integration: {
      type: Object as PropType<Integration>,
      default: () => ({}),
    },

    inProgress: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    save() {
      this.$emit('uninstall', this.installation);
    },
  },
});
</script>
