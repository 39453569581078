<template>
  <div class="channels-list__wrapper">
    <div class="channels-list__wrapper__inner">
      <div class="channels-list-head">
        <div class="t-text-sm-emphasize text-grey-600">{{ $t('onboarding.channels_step2_of_2') }}</div>
        <div class="mt-2 text-grey-800">
          <h2 class="t-text-h2 mb-0 max-w-sm">{{ $t('onboarding.channels_bring_your_customer_conversations') }}</h2>
        </div>
        <div class="t-text-desktop-paragraph-md mt-2 text-grey-700">
          <p class="mb-0">{{ $t('onboarding.channels_select_channel_to_connect') }}</p>
        </div>
      </div>
      <div class="channels-list-items">
        <channels-accordion
          v-for="item in channels_groups"
          :key="item.channel"
          :open="open[item.channel]"
          :item="item"
          @item-click="handleItemClick"
          @change="handleAccordionChange"
        />
      </div>
      <channel-confirmation-modal
        :channel_type="channel_type"
        :open="open_confirmation_modal"
        :channel="channel_item"
        @close="handleClose"
        @create="handleCreateChannel"
      />
      <create-wa-bsp-modal v-model="showWaBspModal" @created="handleWhatsappChannelCreated"></create-wa-bsp-modal>
      <channel-web-chat-sand-box-modal
        :show="open_webchat_sandbox"
        :show-buttons="false"
        @close="handleCloseChannelWebChatSandBoxModal"
        @completed="handleWebChatSandBoxCompleted"
      />
      <t-button-text btn-style="secondary" class="channels-list__skipped-button" @click="handleModalOpen">
        {{ $t('onboarding.skip_popup_title') }}
        <arrow-right-linear size="1.5rem" />
      </t-button-text>
    </div>
    <channel-whatsapp-sandbox-modal
      v-model="show_whatsapp_sandbox"
      @completed="handleWhatsappSandBoxProcessCompleted"
      @open="show_whatsapp_sandbox = true"
      @close="show_whatsapp_sandbox = false"
    />
    <onboarding-skip-modal :is-open="isModalOpen" @close="handleModalClose" />
  </div>
</template>
<script lang="ts">
import { ArrowRightLinear } from '@trengo/trengo-icons';

import CreateWaBspModal from '@/components/Channels/CreateChannelModals/WhatsApp/CreateWaBspModal';
import { EMAIL, WA_BUSINESS, FACEBOOK, INSTAGRAM, CHAT } from '@/Configs/Constants/ChannelLandingPageTypes';
import eventBus from '@/eventBus';
import { request } from '@/util';

import { SegmentProviderTypes } from './types';
import channel_actions from '../../utils/channels_actions';
import updateOnboardingStep, { requestFinalize } from '../api';
import ChannelConfirmationModal from '../ChannelConfirmationModal';
import ChannelsAccordion from '../ChannelsAccordion';
import ChannelWebChatSandBoxModal from '../ChannelWebChatSandboxModal';
import OnboardingSkipModal from '../OnboardingSkipModal';
import ChannelWhatsappSandboxModal from '../WhatsappSandboxModal';

import type { AllowedAccordionChannelType, ChannelsGroup, WhatsappChannelItem, ChannelItem } from '../../types';
import type { AxiosPromise } from 'axios';
type FinalizeData = {
  url: string;
};
type FinalizeResponse = {
  data: FinalizeData;
};

export default {
  name: 'ChannelsList',
  emits: ['close'],
  components: {
    ChannelsAccordion,
    ChannelConfirmationModal,
    CreateWaBspModal,
    ChannelWhatsappSandboxModal,
    ChannelWebChatSandBoxModal,
    ArrowRightLinear,
    OnboardingSkipModal,
  },
  data() {
    return {
      open_confirmation_modal: false,
      open_webchat_sandbox: false,
      channel_item: {} as WhatsappChannelItem | ChannelItem,
      channel_type: '',
      show_whatsapp_sandbox: false,
      showWaBspModal: false,
      isModalOpen: false,
      open: {
        email: false,
        whatsapp: false,
        other: false,
      },
      opened_accordions: [],
      all_accordions: [],
      channels_groups: [
        {
          channel: 'email',
          title: this.$t('onboarding.channels_email'),
          sub_title: this.$t('onboarding.channels_email_subtitle'),
        },
        {
          channel: 'whatsapp',
          title: this.$t('onboarding.channels_whatsapp'),
          sub_title: this.$t('onboarding.channels_whatsapp_subtitle'),
        },
        {
          channel: 'other',
          title: this.$t('onboarding.channels_other'),
          sub_title: this.$t('onboarding.channels_other_subtitle'),
        },
      ] as ChannelsGroup[],
    };
  },
  created() {
    eventBus.$emit('channels.reload');
    document.addEventListener('keyup', this.closeSandBoxOnEscPressed);
  },
  unmounted() {
    document.removeEventListener('keyup', this.closeSandBoxOnEscPressed);
  },
  methods: {
    closeSandBoxOnEscPressed(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        this.show_whatsapp_sandbox = false;
      }
    },
    mapSegmentChannelTypesBasedOnAccordionTypes(
      channel_type: AllowedAccordionChannelType,
      provider: keyof SegmentProviderTypes
    ): { type: string; provider: keyof typeof SegmentProviderTypes } {
      let segmentType: { type: string; provider: keyof typeof SegmentProviderTypes } = {
        type: '',
        provider: SegmentProviderTypes.get(channel_type),
      };
      if (['gmail', 'outlook', 'another_provider'].includes(channel_type)) {
        segmentType.type = EMAIL;
      }
      if (['whatsapp_sandbox', 'whatsapp_number'].includes(channel_type)) {
        segmentType.type = WA_BUSINESS;
      }
      if (channel_type === 'facebook') {
        segmentType.type = FACEBOOK;
      }
      if (channel_type === 'instagram') {
        segmentType.type = INSTAGRAM;
      }
      if (channel_type === 'livechat') {
        segmentType.type = CHAT;
      }
      return segmentType;
    },
    finalizeOnboarding() {
      return new Promise((resolve, reject) => {
        updateOnboardingStep(8)
          .then(() => {
            this.$emit('close');
            this.$router.push('/tickets');
            this.$root.onboarding = null;
            requestFinalize().then((res) => {
              resolve(res);
            });
          })
          .catch(() => {
            setTimeout(() => {
              updateOnboardingStep(6);
              reject(false);
            }, 3000);
          });
      });
    },
    handleCloseChannelWebChatSandBoxModal() {
      this.open_webchat_sandbox = false;
    },
    handleWebChatSandBoxCompleted({ link }) {
      this.finalizeOnboarding().then(() => {
        this.$router.push(link);
      });
    },
    handleWhatsappSandBoxProcessCompleted({ ticket_link }) {
      this.finalizeOnboarding().then(() => {
        this.$router.push(ticket_link);
      });
    },
    finalizeAndPushToLink(inner_link) {
      this.closeConfirmationModal();
      this.finalizeOnboarding().then(() => {
        this.$router.push(inner_link);
      });
    },
    callFinalize(auth_link: string): AxiosPromise<FinalizeData> {
      return request(auth_link, 'POST', {});
    },
    redirectToAuthLinkAndFinalize(auth_link) {
      this.callFinalize(auth_link).then((res: FinalizeResponse) => {
        window.open(res.data.url, '_blank');
        this.closeConfirmationModal();
        this.finalizeOnboarding();
      });
    },
    handleOpenWebChatSandbox() {
      this.open_webchat_sandbox = true;
      this.$nextTick(() => {
        eventBus.$emit('chat_sandbox_init_channel');
      });
    },
    handleWhatsappChannelCreated() {
      this.finalizeOnboarding();
    },
    redirectToWhatsappBSPAndFinalize(channel_type: 'whatsapp_sandbox' | 'whatsapp_number') {
      this.finalizeOnboarding().then(() => {
        this.$router.push(`/admin/channels2/wa_business?open=${channel_type}`);
      });
    },
    handleCreateChannel(channel_type) {
      const { auth_link, inner_link } = channel_actions[channel_type];
      if (inner_link) {
        this.finalizeAndPushToLink(inner_link);
        return false;
      }
      this.redirectToAuthLinkAndFinalize(auth_link);
    },
    openWhatsappSandBox() {
      this.show_whatsapp_sandbox = true;
    },
    handleOpenWhatsappModal(channel_type) {
      this.showWaBspModal = true;
    },
    handleItemClick(item: WhatsappChannelItem | ChannelItem, channel_type: AllowedAccordionChannelType) {
      this.channel_item = item;
      this.channel_type = channel_type;

      if (channel_type === 'whatsapp_sandbox') {
        this.openWhatsappSandBox();
        return;
      }
      if (channel_type === 'whatsapp_number') {
        this.handleOpenWhatsappModal(channel_type);
        return;
      }
      if (channel_type === 'livechat') {
        this.handleOpenWebChatSandbox();
        return;
      }
      this.$nextTick(() => {
        this.open_confirmation_modal = true;
      });
    },
    closeConfirmationModal() {
      this.open_confirmation_modal = false;
    },
    closeAndRedirectBasedOnChannelType(channel_type: AllowedAccordionChannelType) {
      const { inner_skip_link } = channel_actions[channel_type];
      this.closeConfirmationModal();
      if (inner_skip_link) {
        this.finalizeOnboarding().then(() => {
          this.$router.push(inner_skip_link);
        });
      }
    },
    handleClose(channel_type) {
      channel_type ? this.closeAndRedirectBasedOnChannelType(channel_type) : this.closeConfirmationModal();
    },
    handleAddToElements(element) {
      this.all_accordions[element._uid] = element;
    },
    handleAccordionChange(state, channel) {
      Object.keys(this.open).forEach((key) => {
        if (key === channel) return false;
        this.open[key] = false;
      });
      this.$nextTick(() => {
        this.open[channel] = state;
      });
    },
    handleModalOpen() {
      this.isModalOpen = true;
    },
    handleModalClose() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style src="./ChannelsList.scss" lang="scss" scoped />
