<template>
  <t-modal v-model="isModalOpen" title="Summary" @close="$emit('cancel')">
    <div class="t-text-md mb-6 text-grey-700">
      You are about to change your subscription. Please review the plan and price differences before confirming the
      changes.
    </div>
    <div class="flex gap-6">
      <div class="t-text-xs-emphasize w-full text-grey-700">
        Current subscription
        <div class="mt-3 flex rounded-lg border-b-1 border-dashed border-grey-300 bg-grey-100 px-4 py-3 text-grey-600">
          <div class="flex flex-grow flex-col gap-4">
            {{ subscriptionInfo.old.plan }}
            <div>{{ subscriptionInfo.old.userSeats?.quantity }}x User Seats</div>
            <div v-if="subscriptionInfo.old.observerSeats?.quantity > 0">
              {{ subscriptionInfo.old.observerSeats?.quantity }}x Observer Seats
            </div>
          </div>
          <div class="flex flex-col gap-4 text-right">
            {{ subscriptionInfo.old.billingFrequency }}
            <div>€{{ subscriptionInfo.old.userSeats.amount }}</div>
            <div v-if="subscriptionInfo.old.observerSeats?.amount">
              €{{ subscriptionInfo.old.observerSeats?.amount }}
            </div>
          </div>
        </div>
        <div class="flex rounded-lg bg-grey-100 px-4 py-3 text-grey-600">
          <div class="flex-grow">Total</div>
          <div class="text-right">€{{ subscriptionInfo.old.total }}</div>
        </div>
      </div>
      <div class="t-text-xs-emphasize w-full text-grey-700">
        New subscription
        <div class="mt-3 flex rounded-lg border-b-1 border-dashed border-grey-300 bg-grey-200 px-4 py-3 text-grey-800">
          <div class="flex flex-grow flex-col gap-4">
            {{ subscriptionInfo.new.plan }}

            <div>{{ subscriptionInfo.new.userSeats?.quantity }}x User Seats</div>
            <div v-if="subscriptionInfo.new.observerSeats?.quantity > 0">
              {{ subscriptionInfo.new.observerSeats?.quantity }}x Observer Seats
            </div>
          </div>
          <div class="flex flex-col gap-4 text-right">
            {{ subscriptionInfo.new.billingFrequency }}

            <div>€{{ subscriptionInfo.new.userSeats.amount }}</div>
            <div v-if="subscriptionInfo.new.observerSeats?.amount">
              €{{ subscriptionInfo.new.observerSeats?.amount }}
            </div>
          </div>
        </div>
        <div class="flex rounded-lg bg-grey-200 px-4 py-3 text-grey-800">
          <div class="flex flex-grow flex-col gap-4">
            <div v-if="subscriptionInfo.new.showSubTotal">Sub total</div>
            <div>Total</div>
          </div>
          <div class="flex flex-col gap-4 text-right">
            <div v-if="subscriptionInfo.new.showSubTotal">€{{ subscriptionInfo.new.subTotal }}</div>
            <div>€{{ subscriptionInfo.new.total }}</div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <t-button btn-style="secondary" @click="$emit('cancel')">Cancel</t-button>
      <t-button btn-style="primary" @click="$emit('confirm')">Confirm changes</t-button>
    </template>
  </t-modal>
</template>

<script lang="ts" setup>
import { computed, ref, type PropType } from 'vue';

import { formatCurrency } from '@/util/currency';

import { getBillingFrequencyName, getPlanName } from '../utils';

import type { ChargebeeSubscriptionCheckoutSummary, } from '@/types';

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  summary: {
    type: Object as PropType<ChargebeeSubscriptionCheckoutSummary>,
    required: true,
  },

});

defineEmits(['cancel', 'confirm']);

const findByType = (type: string[]) => ({
  old: props.summary.old.items.find(item => type.includes(item.type)),
  new: props.summary.new.items.find(item => type.includes(item.type))
});


const subscriptionInfo = computed(() => {

  const { old: oldUserSeats, new: newUserSeats } = findByType(['plan']);
  const { old: oldObserverSeats, new: newObserverSeats } = findByType(['observer', 'addon']);

  const oldTotalAmount = props.summary.old.items.reduce((acc: any, currentItem: any) => acc + currentItem.amount
    , 0);


  return {
    old: {
      plan: getPlanName(props.summary.old.plan),
      billingFrequency: getBillingFrequencyName(props.summary.old.billing_frequency),
      userSeats: { quantity: oldUserSeats?.quantity, amount: formatCurrency(oldUserSeats?.amount) },
      observerSeats: { quantity: oldObserverSeats?.quantity, amount: formatCurrency(oldObserverSeats?.amount) },
      total: formatCurrency(oldTotalAmount),
      showSubTotal: props.summary.old.sub_total && props.summary.old.sub_total !== props.summary.old.total
    },
    new: {
      plan: getPlanName(props.summary.new.plan),
      billingFrequency: getBillingFrequencyName(props.summary.new.billing_frequency),
      userSeats: { quantity: newUserSeats?.quantity, amount: formatCurrency(newUserSeats?.amount) },
      observerSeats: { quantity: newObserverSeats?.quantity, amount: formatCurrency(newObserverSeats?.amount) },
      subTotal: formatCurrency(props.summary.new.sub_total),
      total: formatCurrency(props.summary.new.total),
      showSubTotal: props.summary.new.sub_total && props.summary.new.sub_total !== props.summary.new.total
    },
  }
})

const isModalOpen = computed(() => props.isOpen);
</script>
