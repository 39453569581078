<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

import { formatEpochDate } from '@/util/date';

defineProps<{ scheduledChangeDate: number }>();
const emit = defineEmits(['continue', 'close']);
const { t } = useI18n();
</script>

<template>
  <t-modal :model-value="true" :title="t('edit_subscription.title')" variant="default" @close="emit('close')">
    <div>
      {{
        t('edit_subscription.scheduled_change_warning', { scheduledChangeDate: formatEpochDate(scheduledChangeDate) })
      }}
    </div>
    <template #footer>
      <div class="flex w-full justify-end gap-2">
        <t-button btn-style="secondary" @click="emit('close')">
          {{ t('general.cancel') }}
        </t-button>
        <t-button class="flex items-center" btn-style="primary" @click="emit('continue')">
          {{ t('general.continue') }}
        </t-button>
      </div>
    </template>
  </t-modal>
</template>
