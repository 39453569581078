import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_title = _resolveComponent("form-title")!
  const _component_multi_choice_cards = _resolveComponent("multi-choice-cards")!
  const _component_info_linear = _resolveComponent("info-linear")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!
  const _component_wa_template_input = _resolveComponent("wa-template-input")!
  const _component_t_button = _resolveComponent("t-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showTitle)
      ? (_openBlock(), _createBlock(_component_form_title, {
          key: 0,
          modelValue: _ctx.toggleValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.toggleValue) = $event)),
          "badge-text": _ctx.badgeText,
          "badge-variant": _ctx.badgeVariant,
          "is-text-disabled": _ctx.isTitleTextDisabled,
          "heading-text": _ctx.headingText,
          "should-show-badge": _ctx.shouldShowBadge,
          "should-show-switch": _ctx.shouldShowSwitch,
          "is-toggle-disabled": _ctx.isToggleDisabled,
          subtitle: _ctx.subtitle,
          "tooltip-text": _ctx.tooltipText,
          onHandleClick: _ctx.handleClick
        }, null, 8 /* PROPS */, ["modelValue", "badge-text", "badge-variant", "is-text-disabled", "heading-text", "should-show-badge", "should-show-switch", "is-toggle-disabled", "subtitle", "tooltip-text", "onHandleClick"]))
      : _createCommentVNode("v-if", true),
    (_ctx.showMultiChoiceCards)
      ? (_openBlock(), _createBlock(_component_multi_choice_cards, {
          key: 1,
          disabled: _ctx.isMultiChoiceCardsDisabled,
          "selected-header-type": _ctx.headerType,
          onHandleSelectedHeader: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('handle-selected-header', $event)))
        }, null, 8 /* PROPS */, ["disabled", "selected-header-type"]))
      : _createCommentVNode("v-if", true),
    (_ctx.shouldShowBanner)
      ? (_openBlock(), _createBlock(_component_t_inline_banner, { key: 2 }, {
          icon: _withCtx(() => [
            _createVNode(_component_info_linear)
          ]),
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.bannerMessage), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    (_ctx.showInputField)
      ? (_openBlock(), _createBlock(_component_wa_template_input, {
          key: 3,
          modelValue: _ctx.inputValue,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.inputValue) = $event)),
          "auto-focus-input": _ctx.autoFocusInput,
          disabled: _ctx.disabled,
          "input-id": _ctx.inputId,
          "is-valid-input": _ctx.isValidInput,
          "show-template-end": _ctx.showTemplateEnd,
          "input-type": _ctx.inputType,
          label: _ctx.label,
          "max-length": _ctx.maxLength,
          placeholder: _ctx.placeholder,
          "character-count": _ctx.characterCount(_ctx.inputValue),
          "error-msg": _ctx.errorMsg,
          "sub-label": _ctx.subLabel,
          "tooltip-text": _ctx.inputTooltipText,
          onHandleInputChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('handle-input-change', $event))),
          onKeyup: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('keyup', $event))),
          onPaste: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('paste', $event))),
          onBlur: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('blur', $event)))
        }, null, 8 /* PROPS */, ["modelValue", "auto-focus-input", "disabled", "input-id", "is-valid-input", "show-template-end", "input-type", "label", "max-length", "placeholder", "character-count", "error-msg", "sub-label", "tooltip-text"]))
      : _createCommentVNode("v-if", true),
    (_ctx.showActionButton)
      ? (_openBlock(), _createBlock(_component_t_button, {
          key: 4,
          disabled: _ctx.disableMigrationButton,
          type: "button",
          size: "sm",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('go-to-migration')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.btnText), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"]))
      : _createCommentVNode("v-if", true)
  ]))
}