<template>
  <div class="mx-auto ml-16 flex flex-col">
    <div class="mb-4 mt-8 flex flex-row items-center">
      <button
        class="mr-6 rounded-full p-1.5 transition-colors hover:bg-grey-300"
        @click.prevent="$router.push({ name: 'plugins' })"
      >
        <arrow-left-linear size="1.3rem" />
      </button>

      <h1 v-if="action === 'edit'" class="t-text-h4 m-0 p-0 text-grey-800">{{ initialIntegration.name }}</h1>
      <h1 v-else class="t-text-h4 m-0 p-0 text-grey-800">{{ $tc('integration_hub.add_app') }}</h1>
    </div>

    <form v-if="isLoaded" @submit.prevent.stop>
      <t-card class="flex flex-col bg-white">
        <div :class="{ 'mb-6': !errors.name }">
          <t-input-text
            id="name"
            v-model="integration.name"
            size="sm"
            :label="$tc('integrations.label_name')"
            :sub-label="$tc('integrations.subtitle_name')"
            :placeholder="$tc('integration_hub.placeholder_my_plugin')"
            :has-error="errors.name"
            @blur="checkName"
          />
          <t-error-item
            v-if="errors.name"
            :text="$tc('integration_hub.error_name_must_be_filled_out')"
            danger-icon
            class="mb-4"
          />
        </div>

        <label for="appType" class="t-text-sm-emphasize text-grey-800">
          {{ $t('integrations.label_app_type') }}
        </label>
        <t-dropdown
          id="appType"
          v-model="integration.type"
          size="sm"
          :items="dropdownItems"
          value-index="value"
          text-index="title"
          :error="errors.type"
          :disabled="action === 'edit'"
          @input="checkType"
        />
        <t-error-item v-if="errors.type" :text="$tc('integration_hub.error_you_must_select_app_type')" danger-icon />

        <component
          :is="integration.type"
          v-if="integration.type !== 'slack'"
          ref="childForm"
          v-model="integration.meta"
          @on-validated="errors.meta = false"
          @on-invalidated="errors.meta = true"
        />

        <div class="mt-6 flex flex-row items-center">
          <t-button :class="{ loader: isSaving }" :disabled="isSaving || isDeleting" @click.prevent="saveIntegration">
            {{ $tc('general.save_changes') }}
          </t-button>

          <t-button
            v-if="action === 'edit'"
            :class="{ loader: isDeleting }"
            :disabled="isSaving || isDeleting"
            btn-style="danger-alt"
            class="ml-3"
            @click.prevent="deleteIntegration"
          >
            {{ $tc('integrations.uninstall_plugin') }}
          </t-button>
        </div>
      </t-card>
    </form>
  </div>
</template>

<script lang="ts">
import { ArrowLeftLinear } from '@trengo/trengo-icons';
import { cloneDeep, isEqual } from 'lodash';
import { defineComponent } from 'vue';

import {
  createCustomIntegration,
  deleteCustomIntegration,
  fetchPlugin,
  updateCustomIntegration,
} from '@/components/IntegrationHub/api';
import Bol from '@/components/IntegrationHub/Pages/CustomIntegrations/AppType/Bol.vue';
import Custom from '@/components/IntegrationHub/Pages/CustomIntegrations/AppType/Custom.vue';
import Lightspeed from '@/components/IntegrationHub/Pages/CustomIntegrations/AppType/Legacy/Lightspeed.vue';
import Magento from '@/components/IntegrationHub/Pages/CustomIntegrations/AppType/Legacy/Magento.vue';
import Picqer from '@/components/IntegrationHub/Pages/CustomIntegrations/AppType/Legacy/Picqer.vue';
import Shopify2 from '@/components/IntegrationHub/Pages/CustomIntegrations/AppType/Legacy/Shopify2.vue';
import Whoocommerce from '@/components/IntegrationHub/Pages/CustomIntegrations/AppType/Legacy/Whoocommerce.vue';
import Magento1 from '@/components/IntegrationHub/Pages/CustomIntegrations/AppType/Magento1.vue';

import type { BaseCustomIntegration } from '@/store/types/integrations';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'CustomIntegrationsEdit',

  components: { ArrowLeftLinear, Custom, Bol, Magento1, Shopify2, Lightspeed, Whoocommerce, Picqer, Magento },

  props: {
    action: {
      type: String as PropType<'create' | 'edit'>,
      default: 'edit',
    },
  },

  data() {
    return {
      integration: {} as BaseCustomIntegration,
      initialIntegration: {} as BaseCustomIntegration,
      isSaving: false,
      isDeleting: false,
      isLoaded: true,
      errors: {
        name: false,
        type: false,
        meta: false,
      },
    };
  },

  computed: {
    isDirty() {
      return !isEqual(this.integration, this.initialIntegration);
    },

    hasErrors() {
      return Object.values(this.errors).some((item) => item);
    },

    dropdownItems() {
      let items = [
        { title: this.$tc('integrations.option_custom_app'), value: 'custom' },
        { title: this.$tc('integrations.option_bol'), value: 'bol' },
        { title: this.$tc('integrations.option_magento_1'), value: 'magento1' },
        { title: this.$tc('integrations.option_slack'), value: 'slack' },
      ];

      if (this.action === 'edit') {
        const legacyItems = [
          { title: this.$tc('integrations.option_shopify'), value: 'shopify2' },
          { title: this.$tc('integrations.option_lightspeed'), value: 'lightspeed' },
          { title: this.$tc('integrations.option_woocommerce'), value: 'whoocommerce' },
          { title: this.$tc('integrations.option_magento_2'), value: 'magento' },
          { title: this.$tc('integrations.option_picquer'), value: 'picqer' },
          { title: this.$tc('integrations.option_hubspot'), value: 'hubspot' },
          { title: this.$tc('integrations.option_pipedrive'), value: 'pipedrive' },
        ];

        items = [...items, ...legacyItems];
      }

      return items;
    },
  },

  mounted: async function () {
    if (this.action === 'edit' && this.$route.params.id) {
      this.isLoaded = false;

      await fetchPlugin(this.$route.params.id)
        .then((res) => {
          this.integration = res.data;
          this.initialIntegration = cloneDeep(this.integration);
        })
        .finally(() => {
          this.isLoaded = true;
        });
    }
  },

  watch: {
    // Reset the meta fields when user switcher the plugin type
    'integration.type': function () {
      // Prevent initial fetch from overwriting the meta field
      if (this.isLoaded) {
        this.integration.meta = {};
        this.errors.meta = false;
      }
    },
  },

  methods: {
    async saveIntegration() {
      this.checkForErrors();

      if (this.isSaving || this.hasErrors) {
        return;
      }

      this.isSaving = true;

      this.action === 'create' ? await this.createIntegration() : await this.updateIntegration();
    },

    async createIntegration() {
      await createCustomIntegration(this.integration)
        .then(() => {
          this.initialIntegration = cloneDeep(this.integration);

          this.flashSuccess('App saved successfuly');
          this.$router.push({ name: 'plugins' });
        })
        .catch((err) => {
          console.error(err);
          this.flashError(err?.message);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },

    async updateIntegration() {
      await updateCustomIntegration(this.integration)
        .then(() => {
          this.initialIntegration = cloneDeep(this.integration);
          this.flashSuccess('App successfuly updated');
        })
        .catch((err) => {
          console.error(err);
          this.flashError(err?.message);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },

    async deleteIntegration() {
      this.isDeleting = true;

      await deleteCustomIntegration(this.integration.id)
        .then(() => {
          this.initialIntegration = cloneDeep(this.integration);

          this.flashSuccess('App successfuly uninstalled');
          this.$router.push({ name: 'plugins' });
        })
        .catch((err) => {
          console.error(err);
          this.flashError(err?.message);
        })
        .finally(() => {
          this.isDeleting = false;
        });
    },

    checkForErrors() {
      this.checkName();
      this.checkType();
      this.checkMeta();
    },

    checkName() {
      this.errors.name = !this.integration.name || this.integration.name === '';
    },

    checkType() {
      this.errors.type = this.integration.type === undefined || this.integration.type === '';
    },

    checkMeta() {
      // Slack has no form fields, so we don't have to verify anything
      if (this.integration.type === 'slack') {
        this.errors.meta = false;
        return;
      }

      this.$refs?.childForm?.validateFields?.();
    },
  },

  beforeRouteLeave(_to, _from, next) {
    if (this.isDirty) {
      if (window.confirm(this.$t('general.warning_text_leaving_page_unsaved_changes'))) {
        next();
      } else {
        return;
      }
    } else {
      next();
    }
  },
});
</script>
