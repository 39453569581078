<template>
  <div class="back-button-wrapper flex w-full flex-wrap items-center" :style="{ width: maxWidth }">
    <t-tooltip :text="$t('settings.back_button')" variant="solid" position="bottom">
      <t-icon-button class="mr-6" data-test="settings-item-back-button" @click="$emit('click')">
        <arrow-left-linear />
      </t-icon-button>
    </t-tooltip>
    <slot>
      <h5 class="t-text-h5 mb-0 flex-1 truncate text-ellipsis text-grey-900">{{ title }}</h5>
    </slot>
  </div>
</template>

<script lang="ts">
import { ArrowLeftLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BackHeader',
  emits: ['click'],
  components: { ArrowLeftLinear },
  props: {
    title: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '',
    },
  },
});
</script>

<style scoped lang="scss">
.back-button-wrapper {
  min-height: unset !important;
}
</style>
