import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-test"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", {
    class: "mt-4 list-none rounded-lg border border-grey-300 bg-white px-0",
    "data-test": _ctx.dataTestListRowItemParent
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8 /* PROPS */, _hoisted_1))
}