import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const webWidgetsRoutes: RouteRecord[] = [
  {
    path: '/admin/web_widgets',
    component: require('../../components/WebWidgets/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__WEBSITE_WIDGETS__MANAGE },
    children: [
      {
        path: '/admin/web_widgets/create',
        component: require('../../components/WebWidgets/Edit').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__WEBSITE_WIDGETS__MANAGE },
      },
      {
        path: '/admin/web_widgets/:id',
        component: require('../../components/WebWidgets/Edit').default,
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__WEBSITE_WIDGETS__MANAGE },
      },
      {
        path: '',
        component: require('../../components/WebWidgets/EntitlementRequiredPage').default,
        meta: { permissionRequired: PERMISSION.SETTINGS__WEBSITE_WIDGETS__MANAGE },
      },
    ],
  },
];

export default webWidgetsRoutes;
