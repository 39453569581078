<template>
  <div data-test="online-status-skeleton" class="relative mt-6 grid w-full grid-cols-1 flex-row gap-x-6 xl:grid-cols-2">
    <div>
      <online-status-user-item
        v-for="skeleton in [1, 2, 3, 4]"
        :key="skeleton"
        data-test="online-status-user-item"
        :skeleton="true"
        :animation="!showError"
        :has-border="skeleton !== 4"
      />
    </div>
    <div class="hidden xl:block">
      <online-status-user-item
        v-for="skeleton in [1, 2, 3, 4]"
        :key="skeleton"
        data-test="online-status-user-item"
        :skeleton="true"
        :animation="!showError"
        :has-border="skeleton !== 4"
      />
    </div>
    <div
      v-if="showError"
      data-test="no-agents-available"
      class="
        empty-state
        absolute
        bottom-full
        top-0
        flex
        h-full
        w-full
        flex-col
        items-center
        justify-center
        bg-transparent
      "
    >
      <div class="mb-5 rounded-xl border border-grey-500 p-2">
        <calendar-person-linear size="1.4rem" />
      </div>
      <div class="mb-2">
        <p class="t-text-md-emphasize m-0 text-grey-800">{{ $t('reports_v2.no_agents_available') }}</p>
      </div>
      <div>
        <p class="m-0 text-grey-700">{{ $t('reports_v2.no_agents_available_description') }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CalendarPersonLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import OnlineStatusUserItem from './OnlineStatusUserItem.vue';
export default defineComponent({
  name: 'OnlineStatusSkeleton',
  components: { OnlineStatusUserItem, CalendarPersonLinear },
  props: {
    showError: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.empty-state {
  background-image: linear-gradient(0deg, #fff 30%, transparent 100%);
}
</style>
