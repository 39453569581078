<script type="text/babel">
import Avatar from './Avatar.vue';

export default {
  name: 'AgAvatar',
  components: { Avatar },
  props: {
    params: {},
  },
};
</script>

<template>
  <div class="flex items-center">
    <avatar
      :width="$props.params.width"
      :color="$props.params.color"
      :abbr="$props.params.abbr"
      :image="$props.params.image"
    />
    <span class="ml-1 text-ellipsis">{{ $props.params.fullName }}</span>
  </div>
</template>
