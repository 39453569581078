import { PERMISSION } from '@/Configs/Constants';
import { ROUTE_NAME } from '@/routes/constants';

import type { RouteRecord } from '@/routes/types';

const zapierRoutes: RouteRecord[] = [
  {
    path: '/admin/integrations/zapier',
    component: () => import(/* webpackChunkName; "Zapier" */ '@/components/IntegrationHub/Pages/Zapier/Index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
    name: ROUTE_NAME.INTEGRATIONS_LEGACY_ZAPIER,
  },
];

export default zapierRoutes;
