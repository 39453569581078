<template>
  <label class="wab-recipients-item" :class="{ 'has-right': $slots['right-text'] }" data-test="recipe-item">
    <span class="left">
      <t-input-checkbox v-model="computedValue" :disabled="disable" data-test="recipe-checkbox" />
      <span v-if="$slots.name" class="display-name">
        <slot name="name"></slot>
      </span>
    </span>

    <span v-if="$slots['right-text']" class="right-text">
      <slot name="right-text"></slot>
    </span>
  </label>
</template>

<script>
export default {
  name: 'WabRecipientsItem',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style scoped lang="scss" src="./WabRecipientsItem.scss" />
