export default {
  adj: [
    'aged',
    'ancient',
    'amiable',
    'balmy',
    'beautiful',
    'billowing',
    'blessed',
    'bountiful',
    'breezy',
    'bubbling',
    'calm',
    'celestial',
    'clean',
    'colorful',
    'colossal',
    'curved',
    'deep',
    'divine',
    'exquisite',
    'fancy',
    'fathomless',
    'fragrant',
    'gorgeous',
    'graceful',
    'harmonious',
    'hidden',
    'hollow',
    'holy',
    'icy',
    'indigo',
    'jubilant',
    'limitless',
    'lively',
    'mellow',
    'merciful',
    'mirthful',
    'misty',
    'moonlit',
    'mythic',
    'quaint',
    'precious',
    'red',
    'resilient',
    'scenic',
    'silent',
    'snowy',
    'solitary',
    'sparkling',
    'stunning',
    'summer',
    'smooth',
    'tall',
    'unwavering',
    'wandering',
    'warm',
    'whispering',
    'zealous',
  ],

  nouns: [
    'abyss',
    'atoll',
    'aurora',
    'autumn',
    'badlands',
    'beach',
    'briars',
    'brook',
    'canopy',
    'canyon',
    'cavern',
    'chasm',
    'cliff',
    'cove',
    'crater',
    'creek',
    'darkness',
    'dawn',
    'desert',
    'dew',
    'dusk',
    'farm',
    'fern',
    'firefly',
    'flowers',
    'fog',
    'forest',
    'galaxy',
    'garden',
    'geyser',
    'grove',
    'hamlet',
    'hurricane',
    'iceberg',
    'lagoon',
    'lake',
    'meadow',
    'mist',
    'moss',
    'mountain',
    'oasis',
    'ocean',
    'peak',
    'pebble',
    'pine',
    'plateau',
    'pond',
    'reef',
    'reserve',
    'resonance',
    'sanctuary',
    'sands',
    'shelter',
    'silence',
    'snowflake',
    'spring',
    'storm',
    'stream',
    'summer',
    'summit',
    'sunrise',
    'surf',
    'temple',
    'tundra',
    'valley',
    'waterfall',
    'willow',
    'winds',
    'winter',
  ],

  get() {
    return this.getRandomCamelCasedItem(this.adj) + ' ' + this.getRandomCamelCasedItem(this.nouns).toLowerCase();
  },

  getRandomCamelCasedItem(items) {
    let string = items[Math.floor(Math.random() * items.length)];

    return string.charAt(0).toUpperCase() + string.slice(1);
  },
};
