<!--suppress TypeScriptCheckImport -->
<template>
  <div
    class="relative flex h-fit flex-col rounded-lg bg-white p-4 shadow-md"
    data-test="sidebar-attach-profile-wrapper"
  >
    <div class="flex flex-col">
      <h1 class="t-text-md-emphasize mx-auto">{{ $tc('tickets.attach_profile') }}</h1>
      <multiselect
        ref="multiselect"
        v-model="selected"
        label="name"
        select-label=""
        deselect-label=""
        :placeholder="$t('tickets.search_profile')"
        :allow-empty="false"
        track-by="id"
        open-direction="bottom"
        :show-no-results="false"
        :show-no-options="false"
        :options="profiles"
        :searchable="true"
        :loading="isLoading"
        :internal-search="false"
        :options-limit="100"
        :limit="1"
        :clear-on-select="true"
        :close-on-select="true"
        :max-heigth="400"
        data-test="profile-multiselect"
        @search-change="findProfiles"
        @select="select"
      >
        <template v-slot:option="props">
          <span v-if="props.option.id > 0">{{ props.option.name }}</span>
          <span v-else>{{ $tc('tickets.btn_create_profile') }} "{{ props.option.name }}"</span>
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script lang="ts">
import { debounce } from 'lodash';
import { defineComponent } from 'vue';
import Multiselect from 'vue-multiselect';

import { assignProfile, createNewProfile, getProfiles } from '@/components/TicketSidebar/Api/api';
import eventBus from '@/eventBus';

import type { Channel } from '@/types';
import type Contact from '@/types/contact';
import type Profile from '@/types/profile';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'AttachProfile',
  emits: ['close-profile-edit'],
  components: {
    Multiselect,
  },

  props: {
    contact: {
      type: Object as PropType<Contact>,
      default: () => ({}),
    },
    channel: {
      type: Object as PropType<Channel>,
      default: () => ({}),
    },
  },

  data() {
    return {
      profiles: [],
      isLoading: false,
      selected: null,
      profileName: '',
      newProfile: '',
    };
  },

  methods: {
    findProfiles: debounce(async function (query: string) {
      this.isLoading = true;

      if (!query) {
        this.isLoading = false;
        this.profiles = [];
        return;
      }

      this.profileName = query;

      try {
        const response = await getProfiles(query);
        this.profiles = [{ name: query }, ...response.data.data];
        if (response.data.data.length === 1) {
          if (response.data.data[0].name === query) {
            this.profiles.shift();
          }
        }
        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.flashError(err);
      }
    }, 250),

    async select(selected: Profile) {
      try {
        if (!selected.id) {
          const resp = await createNewProfile(selected.name);
          selected = resp.data;
        }
        await assignProfile(selected.id, this.contact.id, this.channel.type).finally(() => {
          eventBus.$emit('ticket.current.reload');
          this.$emit('close-profile-edit');
        });
      } catch (err) {
        console.error(err);
        this.flashError(err);
      }
    },
  },
  mounted() {
    const elm = this.$refs.multiselect.$el;
    window.placeCaretAtEnd(elm);
  },
});
</script>
