<template>
  <div class="flex w-full flex-row items-center justify-between">
    <div class="flex w-full flex-row">
      <div class="relative">
        <div class="mr-2 h-10 w-10">
          <img :src="getProfileImage" class="relative h-full w-full rounded-full" />
        </div>
        <div class="absolute bottom-0 right-0 mr-2 h-3 w-3 rounded-full border border-white bg-success-500" />
      </div>
      <div class="user-details flex flex-col justify-center">
        <div data-test="user-full-name">
          <p class="t-text-sm-emphasize m-0 truncate text-ellipsis text-grey-800">
            {{ user.name }}
          </p>
        </div>
        <div class="mt-1">
          <p class="t-text-sm m-0 truncate text-ellipsis text-grey-600">{{ user.email }}</p>
        </div>
      </div>
    </div>
    <div class="flex flex-row">
      <div
        class="
          flex
          h-10
          w-10
          cursor-pointer
          items-center
          justify-center
          rounded-lg
          shadow-[0_0px_0px_1px_rgba(0,0,0,0.1)_inset]
        "
        :class="voipStatusClass"
        @click="handleToggleVoipStatus"
      >
        <phone-linear size="1.4rem" :class="voipStatusIconClass" />
      </div>
      <div
        class="
          ml-2
          flex
          h-10
          w-10
          cursor-pointer
          items-center
          justify-center
          rounded-lg
          shadow-[0_0px_0px_1px_rgba(0,0,0,0.1)_inset]
        "
        :class="chatStatusClass"
        @click="handleToggleChatStatus"
      >
        <comment-linear size="1.4rem" :class="chatStatusIconClass" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PhoneLinear, CommentLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import { setVoipStatus, setChatStatus } from '@/components/Reporting/api';
import Unassigned from '@/components/Reporting/Pages/V2/TicketInsights/assets/unassigned.svg';

import type { User } from '@/types/common';
import type { PropType } from 'vue';

type VoipChatStatus = 'online' | 'offline';

export default defineComponent({
  name: 'OnlineStatusItem',
  components: { PhoneLinear, CommentLinear },
  props: {
    user: {
      type: Object as PropType<User>,
      default: () => ({} as User),
    },
  },
  data() {
    return {
      userData: this.user as User,
    };
  },
  methods: {
    newVoipStatus(): VoipChatStatus {
      if (!this.user.voip_status || this.user.voip_status === 'offline') {
        return 'online';
      }
      if (this.user.voip_status === 'online') {
        return 'offline';
      }
      return this.user.voip_status;
    },
    newChatStatus(): VoipChatStatus {
      if (!this.user.chat_status || this.user.chat_status === 'offline') {
        return 'online';
      }
      if (this.user.chat_status === 'online') {
        return 'offline';
      }
      return this.user.chat_status;
    },
    onVoipStatusChange() {
      this.userData.voip_status = this.newVoipStatus();
      setVoipStatus(this.userData.voip_status, this.userData.id);
    },
    onChatStatusChange() {
      this.userData.chat_status = this.newChatStatus();
      setChatStatus(this.userData.chat_status, this.userData.id);
    },
    handleToggleVoipStatus(): void {
      this.onVoipStatusChange();
    },
    handleToggleChatStatus(): void {
      this.onChatStatusChange();
    },
    isVoipEnabled() {
      return this.userData.voip_status === 'online';
    },
    isChatEnabled() {
      return this.userData.chat_status === 'online';
    },
    getStatusClass(isEnabled: boolean) {
      return !isEnabled ? 'bg-error-200' : 'bg-success-200';
    },
    getIconClass(isEnabled: boolean) {
      return !isEnabled ? 'text-error-800' : 'text-success-800';
    },
  },
  computed: {
    getProfileImage() {
      return this.user.profile_image || Unassigned;
    },
    voipStatusClass() {
      return this.getStatusClass(this.isVoipEnabled());
    },
    chatStatusClass() {
      return this.getStatusClass(this.isChatEnabled());
    },
    voipStatusIconClass() {
      return this.getIconClass(this.isVoipEnabled());
    },
    chatStatusIconClass() {
      return this.getIconClass(this.isChatEnabled());
    },
  },
});
</script>

<style lang="scss" scoped>
.user-details {
  width: 450px;
  @media (max-width: 1700px) {
    width: 350px;
  }
  @media (max-width: 1500px) {
    width: 300px;
  }
  @media (max-width: 1340px) {
    width: 200px;
  }
}
</style>
