<template>
  <span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21 2.25H20.945C18.991 2.24998 17.4326 2.24996 15.9908 2.5121C14.6356 2.7585 13.3992 3.23163 12 4.10704C10.6008 3.23163 9.36438 2.7585 8.00916 2.5121C6.56741 2.24996 5.00897 2.24998 3.05503 2.25H3.05502H3C2.80109 2.25 2.61032 2.32902 2.46967 2.46967C2.32902 2.61032 2.25 2.80109 2.25 3V15C2.25 15.4142 2.58579 15.75 3 15.75C5.02025 15.75 6.4461 15.7525 7.74084 15.9879C9.0029 16.2174 10.1582 16.6735 11.584 17.624C11.8359 17.792 12.1641 17.792 12.416 17.624C13.8418 16.6735 14.9971 16.2174 16.2592 15.9879C17.5539 15.7525 18.9798 15.75 21 15.75C21.4142 15.75 21.75 15.4142 21.75 15V3C21.75 2.58579 21.4142 2.25 21 2.25ZM7.74084 3.9879C8.90291 4.19919 9.97448 4.60267 11.25 5.40729V15.6677C10.1465 15.0668 9.11756 14.7136 8.00916 14.5121C6.74252 14.2818 5.38582 14.2538 3.75 14.2505V3.75061C5.38435 3.75449 6.6124 3.78273 7.74084 3.9879ZM15.9908 14.5121C14.8824 14.7136 13.8535 15.0668 12.75 15.6677V5.40729C14.0255 4.60267 15.0971 4.19919 16.2592 3.9879C17.3876 3.78273 18.6156 3.75448 20.25 3.75061V14.2505C18.6142 14.2538 17.2575 14.2818 15.9908 14.5121ZM3 17.75C2.58579 17.75 2.25 18.0858 2.25 18.5C2.25 18.9142 2.58579 19.25 3 19.25C4.97377 19.25 6.39687 19.3743 7.67766 19.7236C8.95056 20.0707 10.1262 20.6522 11.584 21.624C11.8359 21.792 12.1641 21.792 12.416 21.624C13.8738 20.6522 15.0494 20.0707 16.3223 19.7236C17.6031 19.3743 19.0262 19.25 21 19.25C21.4142 19.25 21.75 18.9142 21.75 18.5C21.75 18.0858 21.4142 17.75 21 17.75C18.9738 17.75 17.3969 17.8757 15.9277 18.2764C14.585 18.6426 13.3686 19.2283 12 20.1041C10.6314 19.2283 9.41499 18.6426 8.07234 18.2764C6.60313 17.8757 5.02623 17.75 3 17.75Z"
        class="svg-fill"
      />
    </svg>

    <span class="icon-active">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.0926 4.43835C11.1492 4.47056 11.2015 4.50029 11.25 4.52763V7C11.25 7.41421 11.5858 7.75 12 7.75C12.4142 7.75 12.75 7.41421 12.75 7V4.52763C12.7985 4.50029 12.8508 4.47056 12.9074 4.43835L12.9076 4.43824C15.0344 3.22941 16.7712 3.03659 19.3989 3.00583C19.9531 2.99935 20.2302 2.9961 20.4467 3.10417C20.6356 3.1985 20.7929 3.35393 20.8894 3.54175C21 3.75693 21 4.03795 21 4.6V13.4C21 13.958 21 14.237 20.8926 14.4496C20.7974 14.6381 20.6486 14.7886 20.4613 14.886C20.2499 14.9959 19.9662 14.9992 19.3989 15.0058H19.3989C16.7712 15.0366 15.0344 15.2294 12.9076 16.4382C12.5414 16.6464 12.3583 16.7505 12.2053 16.7826C12.0511 16.8149 11.9489 16.8149 11.7947 16.7826C11.6417 16.7505 11.4586 16.6464 11.0924 16.4382C8.96562 15.2294 7.22884 15.0366 4.60111 15.0058H4.60109C4.03376 14.9992 3.75009 14.9959 3.53872 14.886C3.35135 14.7886 3.20262 14.6381 3.1074 14.4496C3 14.237 3 13.958 3 13.4V4.6C3 4.03795 3 3.75693 3.11059 3.54175C3.20713 3.35393 3.36438 3.1985 3.55332 3.10417C3.76978 2.9961 4.04689 2.99935 4.60111 3.00583C7.22884 3.03659 8.96562 3.22941 11.0924 4.43824L11.0926 4.43835ZM3 17.75C2.58579 17.75 2.25 18.0858 2.25 18.5C2.25 18.9142 2.58579 19.25 3 19.25C4.97377 19.25 6.39687 19.3743 7.67766 19.7236C8.95056 20.0707 10.1262 20.6522 11.584 21.624C11.8359 21.792 12.1641 21.792 12.416 21.624C13.8738 20.6522 15.0494 20.0707 16.3223 19.7236C17.6031 19.3743 19.0262 19.25 21 19.25C21.4142 19.25 21.75 18.9142 21.75 18.5C21.75 18.0858 21.4142 17.75 21 17.75C18.9738 17.75 17.3969 17.8757 15.9277 18.2764C14.585 18.6426 13.3686 19.2283 12 20.1041C10.6314 19.2283 9.41499 18.6426 8.07234 18.2764C6.60313 17.8757 5.02623 17.75 3 17.75Z"
          fill="white"
        />
      </svg>
    </span>
  </span>
</template>
