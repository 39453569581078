<template>
  <t-modal
    v-model="computedIsOpen"
    initial-min-height="45rem"
    :title="$t('team.create_team_title')"
    @close="closeModal"
  >
    <div class="modal-container" data-test="create-team-modal-wrapper">
      <div class="input-wrapper">
        <div class="input-wrapper-label t-text-desktop-label-md sm:t-text-desktop-label-lg">
          {{ $t('team.team_name_title') }}
        </div>
        <atomic-input
          v-model="teamNameValue"
          data-test="create-team-name"
          :max="40"
          size="lg"
          :placeholder="$t('team.team_name_placeholder')"
          @keydown.space="handlePreventLeadingSpace"
        />
      </div>
      <div class="input-wrapper">
        <div class="input-wrapper-label t-text-desktop-label-md sm:t-text-desktop-label-lg">
          {{ $t('team.team_members_title') }}
        </div>
        <t-multi-select
          v-model="teamMembersValue"
          data-test="create-team-members"
          :placeholder="$t('team.select_one_or_more_users_placeholder')"
          is-closable
          size="lg"
          limited-height
          :data="users"
          container-size="lg"
        />
      </div>
      <div class="input-wrapper">
        <div class="input-wrapper-label">
          <span class="t-text-desktop-label-md sm:t-text-desktop-label-lg">{{ $t('team.channels_title') }}</span>
          <div class="t-text-desktop-paragraph-sm text-grey-600">
            {{ $t('team.select_channels_that_team_will_access_to_message') }}
          </div>
        </div>
        <t-multi-select
          v-model="channelsValue"
          data-test="create-team-channels"
          :placeholder="$t('team.select_one_or_more_channels_placeholder')"
          is-closable
          use-icon
          limited-height
          size="lg"
          variant="sky"
          :data="channels"
          container-size="lg"
        />
      </div>
    </div>
    <template #footer>
      <t-button data-test="create-team-modal-cancel" btn-style="secondary" @click="closeModal">Cancel</t-button>
      <t-button
        data-test="create-team-modal-submit"
        :disabled="!isDataValidate"
        btn-style="primary"
        @click="handleSubmit"
      >
        {{ $t('team.create_team_button') }}
      </t-button>
    </template>
  </t-modal>
</template>

<script>
import { ChannelsIconsMapper } from '@/util/ChannelsUtils';
import { cachedRequest } from '@/util/request';

export default {
  name: 'TeamModal',
  emits: ['close', 'submit', 'toggle', 'update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      channels: [],
      users: [],
    };
  },
  mounted() {
    this.mapChannels();
    this.fetchUsers();
  },
  methods: {
    fetchUsers() {
      cachedRequest({ method: 'get', url: '/client-api/users/list' }, true).then((res) => {
        this.users = res.data.users;
      });
    },
    handlePreventLeadingSpace(e) {
      const { value } = e.target;
      if (!value) {
        e.preventDefault();
      }
      const removeLeadingZero = value.replace(/^\s+|\s+$/gm, '');
      if (!removeLeadingZero) {
        e.preventDefault();
        return false;
      }
    },
    closeModal() {
      this.$emit('close');
    },
    handleSubmit() {
      this.$emit('submit');
    },
    mapChannels() {
      const channels_svg = ChannelsIconsMapper();
      this.channels = this.$root.channels
        .filter((item) => !item.is_private)
        .map((item) => ({ ...item, name: item.title, icon: channels_svg[item.type] }));
    },
  },
  computed: {
    isDataValidate() {
      return !!(this.computedValue && this.teamNameValue && this.teamMembersValue?.length);
    },
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    teamNameValue: {
      get() {
        return this.computedValue.name;
      },
      set(value) {
        this.computedValue.name = value;
        this.$emit('update:modelValue', this.computedValue);
      },
    },
    channelsValue: {
      get() {
        return this.computedValue.channels;
      },
      set(value) {
        this.computedValue.channels = value;
        this.$emit('update:modelValue', this.computedValue);
      },
    },
    teamMembersValue: {
      get() {
        return this.computedValue.team_members;
      },
      set(value) {
        this.computedValue.team_members = value;
        this.$emit('update:modelValue', this.computedValue);
      },
    },
    computedIsOpen: {
      get() {
        return this.isOpen;
      },
      set(value) {
        this.$emit('toggle', value);
      },
    },
  },
};
</script>

<style src="./TeamModal.scss" scoped lang="scss" />
