import { createStore } from 'vuex';

import business_hours from './modules/business_hours';
import channels from './modules/channels';
import customFields from './modules/customFields';
import gamification from './modules/gamification';
import initialData from './modules/initialData';
import integrations from './modules/integrations';
import misc from './modules/misc';
import onboardingSteps from './modules/onboardingSteps';
import teams from './modules/teams';
import userConfig from './modules/userConfig';
import users from './modules/users';
import usersInternalChat from './modules/usersInternalChat';
import chat from '../components/InternalChat/Store/Index';

export default createStore({
  modules: {
    business_hours,
    channels,
    chat,
    customFields,
    gamification,
    initialData,
    integrations,
    misc,
    onboardingSteps,
    teams,
    userConfig,
    usersInternalChat,
    users,
  },
});
