import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_connect_whatsapp = _resolveComponent("connect-whatsapp")!
  const _component_validating_connection = _resolveComponent("validating-connection")!
  const _component_establishing_connection = _resolveComponent("establishing-connection")!
  const _component_message_recieved = _resolveComponent("message-recieved")!
  const _component_trying_templates = _resolveComponent("trying-templates")!
  const _component_trying_templates_success = _resolveComponent("trying-templates-success")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`flex min-h-416px w-full flex-col items-center ${_ctx.getClassForStep()}`)
  }, [
    _createVNode(_TransitionGroup, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.step === _ctx.steps.CONNECT_WHATSAPP)
          ? (_openBlock(), _createBlock(_component_connect_whatsapp, {
              key: "connect-whatsapp",
              "data-test": "connect-whatsapp"
            }))
          : _createCommentVNode("v-if", true),
        (_ctx.step === _ctx.steps.VALIDATING_CONNECTION)
          ? (_openBlock(), _createBlock(_component_validating_connection, {
              key: "validating-connection",
              "data-test": "validating-connection"
            }))
          : _createCommentVNode("v-if", true),
        (_ctx.step === _ctx.steps.ESTABLISHING_CONNECTION)
          ? (_openBlock(), _createBlock(_component_establishing_connection, {
              key: "establishing-connection",
              "data-test": "establishing-connection"
            }))
          : _createCommentVNode("v-if", true),
        (_ctx.step === _ctx.steps.MESSAGE_RECIEVED)
          ? (_openBlock(), _createBlock(_component_message_recieved, {
              key: "message-recieved",
              "data-test": "message-recieved"
            }))
          : _createCommentVNode("v-if", true),
        (_ctx.step === _ctx.steps.TRYING_TEMPLATES)
          ? (_openBlock(), _createBlock(_component_trying_templates, {
              key: "trying-templates",
              "data-test": "trying-templates",
              "ticket-id": _ctx.ticketId
            }, null, 8 /* PROPS */, ["ticket-id"]))
          : _createCommentVNode("v-if", true),
        (_ctx.step === _ctx.steps.TRYING_TEMPLATES_SUCCESS)
          ? (_openBlock(), _createBlock(_component_trying_templates_success, {
              key: "trying-templates-success",
              "data-test": "trying-templates-success",
              "ticket-id": _ctx.ticketId,
              messages: _ctx.messages
            }, null, 8 /* PROPS */, ["ticket-id", "messages"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ], 2 /* CLASS */))
}