<template>
  <div>
    <div ref="modal" class="modal">
      <div class="modal-centered height-auto">
        <div class="modal-content" style="min-width: 1000px">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('google_business.messages_locations') }}</h5>
            <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close-btn">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body" style="max-height: 500px; overflow-y: auto">
            <div v-if="loading" class="text-center">
              <i class="fa fa-spin fa-circle-o-notch"></i>
            </div>
            <div v-else-if="locations.length === 0" class="w-full">
              {{ $t('google_business.create_verify_location') }}
            </div>
            <div v-else>
              <table class="w-full">
                <tr>
                  <th>{{ $t('google_business.place_id') }}</th>
                  <th>{{ $t('google_business.search') }}</th>
                  <th>{{ $t('google_business.maps') }}</th>
                  <th>{{ $t('google_business.verification_state') }}</th>
                  <th>{{ $t('google_business.launch_state') }}</th>
                  <th>&nbsp;</th>
                </tr>
                <tr v-for="location in locations">
                  <!--                                    <td>{{ location.title }}</td>-->
                  <td>
                    <a
                      :href="'https://www.google.com/maps/place/?q=place_id:' + location.placeId"
                      target="_blank"
                      rel="noopener"
                    >
                      {{ location.placeId }}
                      <i class="material-icons md-12">open_in_new</i>
                    </a>
                  </td>
                  <td>
                    <i v-if="locationEnabledEntry(location, 'PLACESHEET')" class="material-icons md-18 text-success">
                      check
                    </i>
                  </td>
                  <td>
                    <i v-if="locationEnabledEntry(location, 'MAPS_TACTILE')" class="material-icons md-18 text-success">
                      check
                    </i>
                  </td>
                  <td>{{ mapVerificationState(location.state) }}</td>
                  <td>
                    <span v-show="$t('google_business.state_verified') === mapVerificationState(location.state)">
                      {{ mapLaunchVerificationState(location.launchState) }}
                    </span>
                  </td>
                  <td>
                    <template
                      v-if="
                        [
                          $t('google_business.unspecified'),
                          $t('google_business.state_unspecified'),
                          $t('google_business.state_unverified'),
                        ].includes(mapVerificationState(location.state))
                      "
                    >
                      <button
                        type="button"
                        class="btn btn-sm btn-secondary"
                        @click="requestVerificationLocation(location)"
                      >
                        {{ $t('google_business.request_verification') }}
                      </button>
                    </template>
                    <template v-else-if="$t('google_business.state_verified') === mapVerificationState(location.state)">
                      <template
                        v-if="
                          [
                            $t('google_business.unspecified'),
                            $t('google_business.state_unspecified'),
                            $t('google_business.state_unlaunched'),
                            $t('google_business.state_rejected'),
                            $t('google_business.state_suspended'),
                          ].includes(mapLaunchVerificationState(location.launchState))
                        "
                      >
                        <button type="button" class="btn btn-sm btn-secondary" @click="launchLocation(location)">
                          {{ $t('google_business.launch_location') }}
                        </button>
                      </template>
                      <template
                        v-else-if="
                          [$t('google_business.state_pending_launch'), $t('google_business.state_launched')].includes(
                            mapLaunchVerificationState(location.launchState)
                          )
                        "
                      >
                        <button type="button" class="btn btn-sm btn-secondary" @click="unLaunchLocation(location)">
                          {{ $t('google_business.unlaunch_location') }}
                        </button>
                      </template>
                    </template>
                    <!--                                        For future reference, not possible yet -->
                    <!--                                        <button type="button" class="btn btn-sm btn-secondary" @click="editLocation(location)">-->
                    <!--                                            <i class="material-icons md-18">edit</i>-->
                    <!--                                        </button>-->
                    <button
                      type="button"
                      class="btn btn-sm btn-secondary"
                      @click="deleteLocation(location.location_id)"
                    >
                      <i class="material-icons md-12">delete</i>
                    </button>
                  </td>
                </tr>
              </table>
              <div class="form-text">
                {{ $t('google_business.after_verify_agent') }}
              </div>
            </div>
          </div>
          <div class="modal-footer text-left">
            <button type="button" class="btn btn-default" @click="addNewLocation">
              {{ $t('google_business.new_location') }}
            </button>
            <button type="button" class="btn btn-link text-muted" data-dismiss="modal">
              {{ $t('google_business.cancel') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div ref="newModal" class="modal">
      <div class="modal-centered height-auto">
        <div class="modal-content">
          <form class="form-horizontal" @submit.prevent="addOrEditLocation">
            <div>
              <div class="modal-header">
                <h5 class="modal-title">{{ $t('google_business.add_location') }}</h5>
                <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close-btn">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <p v-html="$t('google_business.you_can_add_and_verify_all_your_locations')" />
                <!--                                <div class="form-group row">-->
                <!--                                    <label class="col-sm-2 form-control-label">Location title <span class="text-danger">*</span></label>-->
                <!--                                    <div class="col-sm-10">-->
                <!--                                        <input type="text" v-model="gbmLocation.title" autocomplete="off" class="form-control" placeholder="Trengo HQ" />-->
                <!--                                        <span class="form-text">Internal display title.</span>-->
                <!--                                    </div>-->
                <!--                                </div>-->
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">
                    {{ $t('google_business.place_id') }}
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-sm-10">
                    <input
                      v-model="gbmLocation.placeId"
                      type="text"
                      autocomplete="off"
                      class="form-control"
                      placeholder="ChIJR5-TRABvxkcRFpbsdvCWpv8"
                    />
                    <span class="form-text" v-html="$t('google_business.go_to_the_place_id_finder')" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">
                    {{ $t('google_business.default_language') }}
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-sm-10">
                    <multiselect
                      v-model="selectedLanguage"
                      label="text"
                      track-by="id"
                      :allow-empty="false"
                      :options="languages"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      :multiple="false"
                      :placeholder="$t('google_business.select_one_language')"
                    />
                    <span class="form-text">{{ $t('google_business.language_of_location') }}</span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">{{ $t('google_business.location_entries') }}</label>
                  <div class="col-sm-10">
                    <label class="ui-switch success m-t-xs m-r flex-1">
                      <input v-model="gbmLocation.placesheet" type="checkbox" :value="1" />
                      <i></i>
                    </label>
                    <div class="flex-1">{{ $t('google_business.placesheet') }}</div>
                    <span class="form-text">
                      {{ $t('google_business.displays_when_users_search_for_location_on_search') }}
                    </span>
                    <br />
                    <label class="ui-switch success m-t-xs m-r flex-1">
                      <input v-model="gbmLocation.maps_tactile" type="checkbox" :value="1" />
                      <i></i>
                    </label>
                    <div class="flex-1">{{ $t('google_business.maps_tacticle') }}</div>
                    <span class="form-text">
                      {{ $t('google_business.displays_when_users_search_for_location_on_maps') }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="modal-footer text-left">
                <button
                  type="submit"
                  :class="{ loader: loading }"
                  class="btn success text-white"
                  v-text="
                    isEditingLocation ? $t('google_business.update_location') : $t('google_business.add_location')
                  "
                />
                <button type="button" class="btn btn-link text-muted" data-dismiss="modal">
                  {{ $t('general.cancel') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import Multiselect from 'vue-multiselect';

import { STATUS_CODE } from '@/Configs/Constants/StatusCodes';
import eventBus from '@/eventBus';
export default {
  name: 'GbmLocations',
  emits: ['changed'],
  props: {
    record: {
      type: Object,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
  },
  components: {
    Multiselect,
  },

  computed: {
    isEditingLocation() {
      return !!this.gbmLocation.id;
    },
  },

  watch: {
    selectedLanguage(val) {
      if (val && val.id) {
        this.gbmLocation.defaultLocale = val.id;
      } else {
        this.gbmLocation.defaultLocale = 'en';
      }
    },
  },

  data() {
    return {
      default_greetings: {},
      description_types: {},
      greeting_types: {},
      upload_method: 'record',
      recording: false,

      adding_greeting: false,

      loading: false,
      locations: [],
      selectedLanguage: { id: 'en', text: 'English' },
      gbmLocation: {
        location_id: null,
        defaultLocale: 'en',
        title: null,
        placeId: null,
        placesheet: true,
        maps_tactile: true,
      },
    };
  },

  mounted() {
    $(this.$refs.newModal).on('hidden.bs.modal', () => {
      $(this.$refs.modal).modal('show');
    });

    eventBus.$on('show.gbm.locations.modal', this.showModal);
  },

  beforeUnmount() {
    $(this.$refs.modal).modal('hide');
    eventBus.$off('show.gbm.locations.modal');
  },

  methods: {
    showModal() {
      this.fetchLocations();
      $(this.$refs.modal).modal('show');
    },

    mapVerificationState(state) {
      return this.mapVerificationStates(state, 'verificationState');
    },

    mapLaunchVerificationState(state) {
      return this.mapVerificationStates(state, 'launchState');
    },

    mapVerificationStates(state, stateType) {
      const verificationStates = Object.freeze({
        ['VERIFICATION_STATE_UNSPECIFIED']: this.$t('google_business.state_unspecified'),
        ['VERIFICATION_STATE_UNVERIFIED']: this.$t('google_business.state_unverified'),
        ['VERIFICATION_STATE_PENDING']: this.$t('google_business.state_pending_verification'),
        ['VERIFICATION_STATE_VERIFIED']: this.$t('google_business.state_verified'),
        ['LAUNCH_STATE_UNSPECIFIED']: this.$t('google_business.state_unspecified'),
        ['LAUNCH_STATE_UNLAUNCHED']: this.$t('google_business.state_unlaunched'),
        ['LAUNCH_STATE_PENDING']: this.$t('google_business.state_pending_launch'),
        ['LAUNCH_STATE_LAUNCHED']: this.$t('google_business.state_launched'),
        ['LAUNCH_STATE_REJECTED']: this.$t('google_business.state_rejected'),
        ['LAUNCH_STATE_SUSPENDED']: this.$t('google_business.state_suspended'),
        ['LAUNCH_STATE_PENDING_UNLAUNCH']: this.$t('google_business.state_pending_unlaunch'),
      });

      if (!state || !state[stateType]) {
        return this.$t('google_business.unspecified');
      }

      return verificationStates[state[stateType]];
    },

    locationEnabledEntry(location, entryPoint) {
      return location.locationEntryPointConfigs
        .map((l) => Object.values(l))
        .flat()
        .includes(entryPoint);
    },

    fetchLocations() {
      this.loading = true;
      axios.get('/api/v2/gbm/pages/' + this.record.id + '/locations').then((res) => {
        this.loading = false;
        this.locations = res.data;
      });
    },

    getLocationState(id) {
      axios.get('/api/v2/gbm/pages/' + this.record.id + '/locations/' + id).then((res) => {
        let location = this.locations.find((l) => l.id === id);
        if (location) {
          location.state = res.data.state;
          location.launchState = res.data.launchState;
        }
      });
    },

    requestVerificationLocation(location) {
      if (!confirm(this.$t('google_business.after_verify_location_you_cant_make_updates'))) {
        return;
      }
      axios
        .put('/api/v2/gbm/pages/' + this.record.id + '/locations/' + location.location_id, {
          type: 'REQUEST_LOCATION_VERIFICATION',
        })
        .then((res) => {
          location.state = res.data;
        });
    },

    launchLocation(location) {
      axios
        .put('/api/v2/gbm/pages/' + this.record.id + '/locations/' + location.location_id, {
          type: 'REQUEST_LAUNCH',
        })
        .then((res) => {
          if (
            res.data?.error?.code === STATUS_CODE.BAD_REQUEST &&
            res.data?.error?.status === 'INVALID_ARGUMENT' &&
            res.data?.error?.message?.includes('location entry point')
          ) {
            this.flashError(this.$t('google_business.after_verify_location_you_cant_make_updates'));
            return;
          }

          if (res.data?.error) {
            this.flashError(res.data?.error?.message);
            return;
          }

          location.launchState = res.data.launchState;
          this.flashSuccess(this.$t('google_business.launched_successfully'));
        });
    },

    unLaunchLocation(location) {
      axios
        .put('/api/v2/gbm/pages/' + this.record.id + '/locations/' + location.location_id, {
          type: 'UNLAUNCH_LOCATION',
        })
        .then((res) => {
          location.launchState = res.data.launchState;
        });
    },

    deleteLocation(id) {
      if (!confirm(this.$t('google_business.delete_confirmation'))) {
        return;
      }
      axios.delete('/api/v2/gbm/pages/' + this.record.id + '/locations/' + id).then((res) => {
        this.$emit('changed');
        this.fetchLocations();
      });
    },

    addNewLocation() {
      this.gbmLocation = {
        title: null,
        placeId: null,
        placesheet: true,
        maps_tactile: true,
        defaultLocale: 'en',
      };
      this.selectedLanguage = { id: 'en', text: 'English' };

      this.showAddingEditingModal();
    },

    showAddingEditingModal() {
      $(this.$refs.newModal).modal('show');
      $(this.$refs.modal).modal('hide');
    },

    // Future reference, not possible to update locations (yet)
    // editLocation(location) {
    //     this.selectedLanguage = this.languages.find(l => l.id === location.defaultLocale);
    //     this.gbmLocation = {
    //         id: location.location_id,
    //         title: location.title,
    //         placeId: location.placeId,
    //         placesheet: this.locationEnabledEntry(location, 'PLACESHEET'),
    //         maps_tactile: this.locationEnabledEntry(location, 'MAPS_TACTILE'),
    //         defaultLocale: location.defaultLocale,
    //     }
    //
    //     this.showAddingEditingModal();
    // },

    addOrEditLocation() {
      let method;
      if (this.gbmLocation.id) {
        method = 'put';
      } else {
        method = 'post';
      }

      this.loading = true;
      axios({
        method: method,
        url:
          '/api/v2/gbm/pages/' +
          this.record.id +
          '/locations' +
          (this.isEditingLocation ? '/' + this.gbmLocation.id : ''),
        data: this.gbmLocation,
      })
        .then((res) => {
          this.loading = false;
          this.fetchLocations();

          this.$emit('changed');
          $(this.$refs.newModal).modal('hide');
          this.gbmLocation = {
            title: null,
            placeId: null,
            placesheet: true,
            maps_tactile: true,
            defaultLocale: 'en',
          };
          this.selectedLanguage = { id: 'en', text: 'English' };
        })
        .catch((e) => {
          this.loading = false;
          this.flashError(this.$t('google_business.something_went_wrong') + e?.response?.data?.message);
        });
    },
  },
};
</script>
