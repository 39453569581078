import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "forwarding-email-edit-dns" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "text-lg font-semibold" }
const _hoisted_4 = { class: "leading-5" }
const _hoisted_5 = { class: "text-base font-normal" }
const _hoisted_6 = { class: "leading-6" }
const _hoisted_7 = {
  key: 0,
  class: "border-bottom mx-4 border-1 border-grey-300"
}
const _hoisted_8 = { class: "ml-4 mr-0" }
const _hoisted_9 = { class: "fal fa-spinner animate-spin" }
const _hoisted_10 = { class: "text-2xl font-extralight" }
const _hoisted_11 = { class: "text-base font-semibold" }
const _hoisted_12 = {
  key: 0,
  class: "mt-4 flex"
}
const _hoisted_13 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dns_item = _resolveComponent("dns-item")!
  const _component_search_dropdown = _resolveComponent("search-dropdown")!
  const _component_arrow_top_circle_linear = _resolveComponent("arrow-top-circle-linear")!
  const _component_t_badge = _resolveComponent("t-badge")!
  const _component_t_tooltip = _resolveComponent("t-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('email_settings.add_email')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('email_settings.select_an_existing_email_or_enter_a_new_one')), 1 /* TEXT */)
      ])
    ]),
    _createVNode(_component_search_dropdown, {
      modelValue: _ctx.computedSenderDomain,
      "onUpdate:modelValue": [
        _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedSenderDomain) = $event)),
        _cache[2] || (_cache[2] = (value) => _ctx.handleFilterDomains(value))
      ],
      options: _ctx.computedVerifiedSenderDomainsItems,
      placeholder: _ctx.placeholder,
      disabled: !_ctx.isEntitleToUpdateSenderDomain
    }, _createSlots({
      item: _withCtx((slotProps) => [
        _createVNode(_component_dns_item, {
          item: slotProps.item,
          onDelete: _ctx.handleDeleteItem,
          onClick: _ctx.handleItemClick
        }, null, 8 /* PROPS */, ["item", "onDelete", "onClick"])
      ]),
      _: 2 /* DYNAMIC */
    }, [
      (_ctx.showAddDomain)
        ? {
            name: "end",
            fn: _withCtx(() => [
              _createElementVNode("div", null, [
                (_ctx.computedVerifiedSenderDomainsItems.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("div", {
                  class: _normalizeClass(["m-3 mb-4 cursor-pointer items-center justify-center pb-1", _ctx.enableAddDomain ? 'text-grey-900' : 'text-grey-500']),
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleAddDomain && _ctx.handleAddDomain(...args)))
                }, [
                  _createElementVNode("span", _hoisted_8, [
                    _withDirectives(_createElementVNode("i", _hoisted_9, null, 512 /* NEED_PATCH */), [
                      [_vShow, _ctx.loading]
                    ]),
                    _withDirectives(_createElementVNode("span", _hoisted_10, "+", 512 /* NEED_PATCH */), [
                      [_vShow, !_ctx.loading]
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('email_settings.add_input_as_a_new_email')), 1 /* TEXT */)
                ], 2 /* CLASS */)
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "options", "placeholder", "disabled"]),
    (!_ctx.isEntitleToUpdateSenderDomain)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("p", {
            class: "t-text-sm mr-2 text-grey-600",
            innerHTML: _ctx.$t('entitlement.upgrade_sending_email', { plan: _ctx.requiredPlanEmailWhiteLabel })
          }, null, 8 /* PROPS */, _hoisted_13),
          (_ctx.badgeTooltipText.length > 0)
            ? (_openBlock(), _createBlock(_component_t_tooltip, {
                key: 0,
                position: "top",
                text: _ctx.badgeTooltipText
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_t_badge, {
                    id: "badge",
                    variant: "upgrade",
                    text: _ctx.requiredPlanEmailWhiteLabel
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_arrow_top_circle_linear, { size: "1rem" })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["text"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["text"]))
            : (_openBlock(), _createBlock(_component_t_badge, {
                key: 1,
                id: "badge",
                variant: "upgrade",
                text: _ctx.requiredPlanEmailWhiteLabel
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_arrow_top_circle_linear, { size: "1rem" })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["text"]))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}