import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6f4f3d47"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "main_wrapper",
  "data-test": "edit-team-page",
  class: "relative block h-screen overflow-y-auto pb-30"
}
const _hoisted_2 = { class: "nav-settings light left b-primary" }
const _hoisted_3 = { class: "text-md white header px-6 py-3 sm:p-6" }
const _hoisted_4 = ["textContent"]
const _hoisted_5 = { class: "px-3 py-8 pb-6 sm:mb-2 sm:px-8 sm:pb-8" }
const _hoisted_6 = { class: "flex flex-col justify-between sm:flex-row sm:items-end" }
const _hoisted_7 = { class: "mb-2 w-full sm:mb-0 sm:mr-14 sm:w-1/3" }
const _hoisted_8 = { class: "t-text-desktop-label-md mb-2 text-grey-900" }
const _hoisted_9 = { class: "px-3 sm:px-8" }
const _hoisted_10 = {
  "data-test": "user-availability",
  class: "mt-6 px-3 sm:mt-10 sm:px-8"
}
const _hoisted_11 = { class: "t-text-desktop-label-sm mb-1 text-grey-800" }
const _hoisted_12 = { class: "sm:t-text-desktop-paragraph-sm t-text-desktop-paragraph-xs mb-2 text-grey-600" }
const _hoisted_13 = { class: "flex flex-row" }
const _hoisted_14 = { class: "t-text-desktop-paragraph-md ml-2 text-grey-900" }
const _hoisted_15 = {
  "data-test": "ticket-notification",
  class: "mt-6 px-3 sm:mt-10 sm:px-8"
}
const _hoisted_16 = { class: "t-text-desktop-label-sm mb-1 text-grey-800" }
const _hoisted_17 = { class: "sm:t-text-desktop-paragraph-sm t-text-desktop-paragraph-xs mb-2 text-grey-600" }
const _hoisted_18 = { class: "w-full sm:w-1/3" }
const _hoisted_19 = { class: "mt-6 px-3 sm:mt-10 sm:px-8" }
const _hoisted_20 = { class: "t-text-desktop-label-sm mb-1 text-grey-800" }
const _hoisted_21 = { class: "sm:t-text-desktop-paragraph-sm t-text-desktop-paragraph-xs mb-3 text-grey-600 sm:mb-3" }
const _hoisted_22 = {
  key: 1,
  class: "grid h-screen place-items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_atomic_input = _resolveComponent("atomic-input")!
  const _component_t_error_item = _resolveComponent("t-error-item")!
  const _component_workload_balancing_toggle = _resolveComponent("workload-balancing-toggle")!
  const _component_upgrade_plan = _resolveComponent("upgrade-plan")!
  const _component_team_members = _resolveComponent("team-members")!
  const _component_team_channels = _resolveComponent("team-channels")!
  const _component_t_switch = _resolveComponent("t-switch")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_action_popup = _resolveComponent("t-action-popup")!
  const _component_t_spinner = _resolveComponent("t-spinner")!
  const _component_enable_work_load_modal = _resolveComponent("enable-work-load-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.is_loaded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", {
                class: "sm:t-text-desktop-h3 t-text-mobile-h3 text-ellipsis text-grey-800",
                textContent: _toDisplayString(_ctx.record.name)
              }, null, 8 /* PROPS */, _hoisted_4)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('team.team_name_title')), 1 /* TEXT */),
                _createVNode(_component_atomic_input, {
                  modelValue: _ctx.record.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.record.name) = $event)),
                  error: !_ctx.nameIsValid,
                  max: 40,
                  "data-test": "team_name",
                  placeholder: _ctx.$t('team.team_name_placeholder'),
                  onBlur: _ctx.validateName
                }, null, 8 /* PROPS */, ["modelValue", "error", "placeholder", "onBlur"]),
                (!_ctx.nameIsValid)
                  ? (_openBlock(), _createBlock(_component_t_error_item, {
                      key: 0,
                      "danger-icon": "",
                      "data-test": "error-name",
                      text: _ctx.$t('team.enter_team_name_error')
                    }, null, 8 /* PROPS */, ["text"]))
                  : _createCommentVNode("v-if", true)
              ]),
              (_ctx.isEntitled)
                ? (_openBlock(), _createBlock(_component_workload_balancing_toggle, {
                    key: 0,
                    "is-enabled": _ctx.workLoadIsEnabled,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleToggleWorkLoadBalancing()))
                  }, null, 8 /* PROPS */, ["is-enabled"]))
                : _createCommentVNode("v-if", true),
              (_ctx.showUpgradeWorkLoadBalancingForThisUser)
                ? (_openBlock(), _createBlock(_component_upgrade_plan, {
                    key: 1,
                    onCancel: _ctx.handleCancelShowWorkLoadBalancing
                  }, null, 8 /* PROPS */, ["onCancel"]))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_team_members, {
              "has-limit": _ctx.workLoadIsEnabled,
              "team-name": _ctx.record.name,
              "team-id": _ctx.record.id,
              users: _ctx.computedUsers,
              onChangeUsers: _ctx.handleUsersChanges,
              onInputValueChanged: _ctx.handleChangeInputValue,
              onRemoveUser: _ctx.handleRemoveUsers
            }, null, 8 /* PROPS */, ["has-limit", "team-name", "team-id", "users", "onChangeUsers", "onInputValueChanged", "onRemoveUser"]),
            _createVNode(_component_team_channels, {
              "has-workload": _ctx.workLoadIsEnabled,
              "team-name": _ctx.record.name,
              "team-id": _ctx.record.id,
              channels: _ctx.immediate_record.channels,
              onChange: _ctx.handleChannelsChanges
            }, null, 8 /* PROPS */, ["has-workload", "team-name", "team-id", "channels", "onChange"])
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('team.workload_balancing_availability_title')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('team.workload_balancing_detail')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_t_switch, {
                modelValue: _ctx.immediate_record.user_availability,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.immediate_record.user_availability) = $event)),
                "data-test": "user-availability-toggle"
              }, null, 8 /* PROPS */, ["modelValue"]),
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('team.consider_team_member_availability_title')), 1 /* TEXT */)
            ])
          ], 512 /* NEED_PATCH */), [
            [_vShow, _ctx.isConsiderToggleAvailable]
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('team.ticket_notification_title')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('team.ticket_notification_detail')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_atomic_input, {
                modelValue: _ctx.record.notification_setting.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.record.notification_setting.value) = $event)),
                error: !_ctx.emailIsValid,
                "data-test": "ticket-notification-input",
                placeholder: _ctx.$t('team.ticket_notification_example_email'),
                onBlur: _ctx.validateEmail
              }, null, 8 /* PROPS */, ["modelValue", "error", "placeholder", "onBlur"]),
              (!_ctx.emailIsValid)
                ? (_openBlock(), _createBlock(_component_t_error_item, {
                    key: 0,
                    "danger-icon": "",
                    "data-test": "error-email",
                    text: _ctx.$t('team.enter_valid_email_address_label_error')
                  }, null, 8 /* PROPS */, ["text"]))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('team.delete_team_title')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('team.delete_team_does_not_impact_assigned_conversation_hint')), 1 /* TEXT */),
            _createVNode(_component_t_button, {
              "data-test": "delete-team",
              "btn-style": "danger-alt",
              onClick: _ctx.deleteTeam
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('team.delete_team_button')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
          ]),
          (_ctx.recordIsDirty)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                "data-test": "action-pop-up",
                class: "fixed bottom-0",
                style: _normalizeStyle(_ctx.actionPopUpWidth)
              }, [
                _createVNode(_component_t_action_popup, {
                  saving: _ctx.saving,
                  text: _ctx.$t('general.action_popup_you_have_unsaved_changes'),
                  "discard-text": _ctx.$t('general.action_popup_discard'),
                  "save-text": _ctx.$t('general.action_popup_save_changes'),
                  onSave: _ctx.submitChanges,
                  onReset: _ctx.resetStates
                }, null, 8 /* PROPS */, ["saving", "text", "discard-text", "save-text", "onSave", "onReset"])
              ], 4 /* STYLE */))
            : _createCommentVNode("v-if", true)
        ], 64 /* STABLE_FRAGMENT */))
      : (_openBlock(), _createElementBlock("span", _hoisted_22, [
          _createVNode(_component_t_spinner)
        ])),
    (_ctx.openEnableWorkloadModal)
      ? (_openBlock(), _createBlock(_component_enable_work_load_modal, {
          key: 2,
          users: _ctx.computedUsers,
          team: _ctx.record.name,
          channels: _ctx.immediate_record.channels,
          "is-open": _ctx.openEnableWorkloadModal,
          onSubmit: _ctx.addAndEnableWorkLoadBalancing,
          onRemoveChannel: _ctx.handleRemoveChannel,
          onCancel: _ctx.handleCloseWorkloadBalancing
        }, null, 8 /* PROPS */, ["users", "team", "channels", "is-open", "onSubmit", "onRemoveChannel", "onCancel"]))
      : _createCommentVNode("v-if", true)
  ], 512 /* NEED_PATCH */))
}