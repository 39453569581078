<script>
import RatingCell from '../components/Reporting/Components/RatingCell.vue';

export default {
  components: { RatingCell },
  props: {
    params: {},
  },
};
</script>

<template>
  <div class="rating-cell-container">
    <RatingCell :rating="$props.params.rating" :assets-url="$props.params.assetsUrl" />
  </div>
</template>
