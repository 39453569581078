/**
 * @param after
 * @param before
 * @returns {*[]}
 */
export function getTimes(after = null, before = null) {
  after = after ? after : '00:00:00';

  let add = 0;
  if (!before) {
    before = '00:00:00';
    add = 1;
  }

  let slots = [];
  for (let i = 0; i <= 23; i++) {
    slots.push((i <= 9 ? `0${i}` : i) + ':00:00');
    slots.push((i <= 9 ? `0${i}` : i) + ':15:00');
    slots.push((i <= 9 ? `0${i}` : i) + ':30:00');
    slots.push((i <= 9 ? `0${i}` : i) + ':45:00');
  }
  slots.push('23:59:59');

  return slots.filter(
    (slot) =>
      moment(slot, 'HH:mm:ss').isSameOrAfter(moment(after, 'HH:mm:ss')) &&
      moment(slot, 'HH:mm:ss').isSameOrBefore(moment(before, 'HH:mm:ss').add(add, 'day'))
  );
}

/**
 * @param days
 */
export function addSlot(days) {
  const { slots } = days;
  const lastSlot = slots.length > 0 ? slots[slots.length - 1] : null;
  const start = lastSlot ? lastSlot.end : '09:00:00';
  const end = lastSlot ? moment(start, 'HH:mm').add(4, 'hours').format('HH:mm:00') : '17:30:00';
  const slotTimes = {
    start,
    end,
  };

  days.is_open = true;
  days.slots.push(slotTimes);
}

/**
 * @param days
 * @param slot
 */
export function deleteSlot(days, slot) {
  days.slots.splice(days.slots.indexOf(slot), 1);
}

/**
 * @param day
 * @returns {*}
 */
export function parseDayOfWeek(day) {
  const localeCode = this.$root.user.locale_code;
  const dayOfWeek = moment().locale(localeCode).day(day).format('dddd');
  return dayOfWeek[0].toUpperCase() + dayOfWeek.slice(1);
}

/**
 * @param time
 * @returns {*}
 */
export function parseTime(time) {
  return moment(time, 'HH:mm:ss', 'en-gb').format('LT');
}

/**
 * @param start
 * @param end
 * @param whole_times
 * @returns {*[]|*}
 */
export function getMomentDiff(start, end, whole_times = false) {
  const startDay = moment(start, 'H:mm:ss');
  const endDay = moment(end, 'H:mm:ss');
  const startIsLessThanEnd = moment.duration(startDay.diff(endDay)).asHours();
  const after = whole_times || startIsLessThanEnd > 0 ? null : start;
  const before = whole_times || startIsLessThanEnd > 0 ? null : end;
  return this.getTimes(after, before);
}

export const DEFAULT_DAYS = [
  {
    day_of_week: 1,
    is_open: true,
    slots: [
      {
        start: '09:00:00',
        end: '17:30:00',
      },
    ],
  },
  {
    day_of_week: 2,
    is_open: true,
    slots: [
      {
        start: '09:00:00',
        end: '17:30:00',
      },
    ],
  },
  {
    day_of_week: 3,
    is_open: true,
    slots: [
      {
        start: '09:00:00',
        end: '17:30:00',
      },
    ],
  },
  {
    day_of_week: 4,
    is_open: true,
    slots: [
      {
        start: '09:00:00',
        end: '17:30:00',
      },
    ],
  },
  {
    day_of_week: 5,
    is_open: true,
    slots: [
      {
        start: '09:00:00',
        end: '17:30:00',
      },
    ],
  },
  {
    day_of_week: 6,
    is_open: false,
    slots: [
      {
        start: '09:00:00',
        end: '17:30:00',
      },
    ],
  },
  {
    day_of_week: 0,
    is_open: false,
    slots: [
      {
        start: '09:00:00',
        end: '17:30:00',
      },
    ],
  },
];
