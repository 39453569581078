<template>
  <portal to="body-portal">
    <div v-show="show" class="overlay" @click.self="hidePopUp">
      <div
        id="popup-tooltip"
        ref="popover"
        class="absolute inline-block rounded-xl bg-white p-4"
        style="box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15)"
      >
        <div class="mb-2 flex items-end justify-between">
          <slot name="title" />
          <i class="material-icons pointer text-md ml-2 text-grey-500" @click="hidePopUp">clear</i>
        </div>

        <slot name="content" />
      </div>
    </div>
  </portal>
</template>

<script type="text/babel">
import eventBus from '@/eventBus';

export default {
  name: 'PopUpAtMousePosition',

  data() {
    return {
      show: false,
    };
  },

  mounted() {
    eventBus.$on('show.popup', (event) => this.showPopUp(event));
    eventBus.$on('hide.popup', () => this.hidePopUp());
  },

  methods: {
    showPopUp(event) {
      const offSet = 10;
      document.querySelector('#popup-tooltip').style.top = event.clientY + offSet + 'px';
      document.querySelector('#popup-tooltip').style.left = event.clientX + offSet + 'px';
      this.show = true;
    },
    hidePopUp() {
      this.show = false;
    },
  },

  beforeUnmount() {
    eventBus.$off('show.popup');
    eventBus.$off('hide.popup');
  },
};
</script>
<style scoped>
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
}
</style>
