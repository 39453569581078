const baseUrl = '/api/v2';

export function getUser(userId) {
  return axios.get(`${baseUrl}/users/` + userId);
}

export function editUser(userId, data) {
  return axios.put(`${baseUrl}/users/` + userId, data);
}

export function getAllTeams() {
  return axios.get(`${baseUrl}/teams`);
}

export function getSubscription() {
  return axios.get(`${baseUrl}/billing/subscriptions/0`);
}

export function sendVerification(userId) {
  return axios.post(`${baseUrl}/users/${userId}/verifications`);
}
