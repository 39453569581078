<template>
  <div class="roles-sidebar">
    <div v-if="isMobile" class="roles-sidebar__mobile" @click="handleMobileCollapse">
      <span>
        {{ $t(`user_management.${activeRole.name}`) }}
        <chevron-down-linear size="1rem" />
      </span>
    </div>

    <div v-if="!isMobile || mobileCollapsed">
      <ul class="roles-sidebar__list">
        <li
          v-for="role in sortedRoles"
          :key="role.id"
          class="roles-sidebar__list--item"
          :class="{ 'roles-sidebar__list--item-active': activeRole.id === role.id }"
          @click="setActiveRole(role)"
        >
          {{ $t(`user_management.${role.name}`) }}

          <div class="roles-sidebar__list--item-badge">
            <t-badge :text="role.metrics.number_of_users" />
          </div>
        </li>
      </ul>

      <t-button-text
        class="roles-sidebar__button open-external"
        href="https://help.trengo.com/en/articles/362659-user-roles"
        target="_blank"
      >
        {{ $t(`user_management.learn_more_about_user_roles`) }}
        <external-link-linear size="1rem" class="ml-2" />
      </t-button-text>
    </div>
  </div>
</template>

<script>
import { ChevronDownLinear, ExternalLinkLinear } from '@trengo/trengo-icons';

import breakpoints from '@/util/breakpoints';

export default {
  name: 'RolesSidebar',
  emits: ['setActiveRole'],
  components: {
    ChevronDownLinear,
    ExternalLinkLinear,
  },
  data() {
    return {
      mobileCollapsed: false,
    };
  },
  props: {
    roles: {
      type: Array,
      default: () => [],
    },
    activeRole: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isMobile() {
      return !breakpoints.comparisons.gt_sm;
    },
    sortedRoles() {
      return this.roles.slice().sort((a, b) => (a.permissions < b.permissions ? 1 : -1));
    },
  },
  methods: {
    handleMobileCollapse() {
      this.mobileCollapsed = !this.mobileCollapsed;
    },
    setActiveRole(role) {
      this.$emit('setActiveRole', role);
      this.handleMobileCollapse();
    },
  },
};
</script>

<style scoped lang="scss" src="./RolesSidebar.scss" />
