<template>
  <div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.shopify_label_webshop_domain') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.url" type="text" autocomplete="off" class="form-control" />
        <span class="form-text">{{ $t('integrations.shopify_subtitle_webshop_domain') }}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.shopify_label_api_key') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.api_key" type="text" autocomplete="off" class="form-control" />
        <span class="form-text" v-html="$t('integrations.shopify_subtitle_api_key')"></span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.shopify_label_api_secret_key') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.api_secret_key" type="text" autocomplete="off" class="form-control" />
        <span class="form-text" v-html="$t('integrations.shopify_subtitle_your_secret_key')"></span>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus';

export default {
  name: 'Shopify2',
  emits: ['update-record', 'update-saving', 'update:modelValue'],
  props: {
    action: {
      type: String,
      default: 'create',
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    eventBus.$on('plugin.saved', (plugin) => {
      this.redirectToShopify(plugin);
    });
    if (this.$route.query.code) {
      this.storeOauthCode(this.$route.query.state, this.$route.query.code);
    }
  },
  unmounted() {
    eventBus.$off('plugin.saved');
  },
  computed: {
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    redirectToShopify(plugin) {
      let queryString = new URLSearchParams({
        redirect_uri: 'https://' + window.location.host + '/admin/developers/plugins/create/shopify2',
        client_id: this.record.meta.api_key,
        client_secret: this.record.meta.api_secret_key,
        state: plugin.id,
        scope: 'read_orders read_customers',
      });
      window.location.href = 'https://' + this.record.meta.url + '/admin/oauth/authorize?' + queryString;
    },
    storeOauthCode(pluginId, code) {
      this.$emit('update-saving', true);
      this.record.meta.oauth_code = code;
      axios.get('/api/v2/plugins/' + pluginId).then((res) => {
        res.data.meta.oauth_code = code;
        axios
          .put('/api/v2/plugins/' + pluginId, res.data)
          .then((res) => {
            this.$emit('update-saving', false);
            this.flashSuccess('Shopify has been connected successfully.');
            router.push('/admin/developers/plugins/' + pluginId);
          })
          .catch(() => {
            this.$emit('update-saving', false);
          });
      });
    },
  },
};
</script>
