const url = '/api/v2/custom_fields/';

export function getChannels() {
  return axios.get('/api/v2/channels');
}

export function getCustomFields() {
  return axios.get(url);
}

export function getCustomField(id) {
  return axios.get(url + id);
}

export function storeCustomField(custom_field) {
  return axios.post(url, custom_field);
}

export function updateCustomField(custom_field) {
  return axios.put(url + custom_field.id, custom_field);
}

export function deleteCustomField(id) {
  return axios.delete(url + id);
}

/**
 * @param {number[]} ids
 * @param {string} type
 * @returns {*}
 */
export function sortCustomField({ ids, type }) {
  return axios.patch('/api/v2/custom_fields_order', { type, order: ids });
}
