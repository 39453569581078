<template>
  <t-modal
    v-model="computedValue"
    class="whatsapp-disclaimer-modal"
    variant="narrow"
    :title="$t('broadcast.whatsapp_disclaimer_title')"
    @close="onCancel"
  >
    <div class="t-text-desktop-paragraph-sm pb-4 text-grey-600">
      <span v-html="$t('broadcast.whatsapp_disclaimer_explenation')" />
      <br />
      <br />
      {{ $t('broadcast.whatsapp_disclaimer_by_sending') }}
    </div>
    <template #footer>
      <div class="flex flex-col-reverse justify-end sm:flex-row">
        <t-button btn-style="secondary" data-test="whatsapp-disclaimer-modal-decline" @click="onCancel">
          {{ $t('broadcast.whatsapp_disclaimer_decline') }}
        </t-button>
        <t-button class="mb-4 sm:mb-0 sm:ml-4" data-test="whatsapp-disclaimer-modal-agree" @click="$emit('submit')">
          {{ $t('broadcast.whatsapp_disclaimer_understand') }}
        </t-button>
      </div>
    </template>
  </t-modal>
</template>

<script>
export default {
  name: 'WhatsappDisclaimerModal',
  emits: ['submit', 'update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    async onCancel() {
      this.computedValue = false;
    },
  },
};
</script>
