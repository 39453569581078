import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "mb-3",
  "data-test": "upgrade-plan"
}
const _hoisted_2 = { class: "flex flex-row items-center" }
const _hoisted_3 = { class: "t-text-sm-emphasize mr-1" }
const _hoisted_4 = { class: "flex flex-row items-center" }
const _hoisted_5 = { class: "t-text-sm-emphasize mr-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_right_linear = _resolveComponent("arrow-right-linear")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_arrow_top_circle_linear = _resolveComponent("arrow-top-circle-linear")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (
        _ctx.entitlementStatus === _ctx.ENTITLEMENT_STATUS.REQUIRES_PLAN_UPGRADE ||
        _ctx.entitlementStatus === _ctx.ENTITLEMENT_STATUS.REQUIRES_ADDON_AND_PLAN_UPGRADE
      )
      ? (_openBlock(), _createBlock(_component_t_inline_banner, {
          key: 0,
          type: "upgrade"
        }, {
          action: _withCtx(() => [
            _createVNode(_component_t_button, {
              class: "twenty-four-hr-banner__btn",
              "btn-style": "secondary",
              size: "sm",
              target: "_blank",
              onClick: _ctx.handleRedirectToSubscriptionSettings
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('entitlement.upgrade_plan_cta')), 1 /* TEXT */),
                  _createVNode(_component_arrow_right_linear, {
                    class: "text-grey-700",
                    size: 20
                  })
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
          ]),
          icon: _withCtx(() => [
            _createVNode(_component_arrow_top_circle_linear, { size: "1.25rem" })
          ]),
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('entitlement.requires_addon_and_plan_upgrade', { featureName: _ctx.featureName })) + " ", 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }))
      : (_ctx.entitlementStatus === _ctx.ENTITLEMENT_STATUS.REQUIRES_ADDON)
        ? (_openBlock(), _createBlock(_component_t_inline_banner, {
            key: 1,
            type: "upgrade"
          }, {
            action: _withCtx(() => [
              _createVNode(_component_t_button, {
                class: "twenty-four-hr-banner__btn",
                "btn-style": "secondary",
                size: "sm",
                target: "_blank",
                onClick: _ctx.handleRedirectToSubscriptionSettings
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('entitlement.unlock_feature', { featureName: _ctx.featureName })), 1 /* TEXT */),
                    _createVNode(_component_arrow_right_linear, {
                      class: "text-grey-700",
                      size: 20
                    })
                  ])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["onClick"])
            ]),
            icon: _withCtx(() => [
              _createVNode(_component_arrow_top_circle_linear, { size: "1.25rem" })
            ]),
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('entitlement.unlock_feature_long', { featureName: _ctx.featureName })) + " ", 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
  ]))
}