<template>
  <div class="box">
    <div class="box-body flex justify-between">
      <t-button
        v-if="action === 'edit'"
        type="button"
        class="mr-4"
        btn-style="danger-alt"
        @click="$emit('delete-wa-template')"
      >
        {{ $t('whatsapp.delete') }}
      </t-button>
      <t-button
        v-if="action === 'create'"
        type="button"
        class="mr-4"
        btn-style="danger-alt"
        @click="$emit('go-to-overview-page')"
      >
        {{ $t('whatsapp.cancel') }}
      </t-button>
      <t-button
        class="mr-4"
        :class="{ loader: saving }"
        :disabled="disableCreateButton"
        @click="$emit('track-create-template-button')"
      >
        {{ submitButtonTitle }}
      </t-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { TEMPLATE_ACTION } from '@/Configs/Constants/whatsapp';

import type { TemplateAction } from '../types';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'TemplateActionButtons',
  emits: ['delete-wa-template', 'go-to-overview-page', 'track-create-template-button'],
  props: {
    action: {
      type: String as PropType<TemplateAction>,
      default: TEMPLATE_ACTION.CREATE,
    },
    disableCreateButton: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    submitButtonTitle() {
      if (this.saving) {
        return this.$t('whatsapp.saving_button');
      }
      if (this.action === TEMPLATE_ACTION.EDIT) {
        return this.$t('whatsapp.save_changes_button');
      }

      return this.$t('whatsapp.submit_for_whatsapp_approval');
    },
  },
});
</script>
