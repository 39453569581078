<template>
  <div>
    <t-accordion-new
      ref="accordion"
      :is-open="isOpen"
      class="channels_accordions"
      @change="(state) => (isOpen = state)"
      @open="getState"
    >
      <template #trigger>
        <div :class="computedTriggerClass" class="channel-accordion-item">
          <div><img class="w-12" :src="getIcon" /></div>
          <div class="w-full">
            <div class="t-text-sm-emphasize text-grey-800">{{ item.title }}</div>
            <div class="t-text-sm mt-1 text-grey-600">{{ item.sub_title }}</div>
          </div>
          <div class="flex flex-row items-center gap-3">
            <t-action-button>
              <chevron-down-linear v-if="!isOpen" size="1.3rem" class="h-20px w-20px text-grey-800" />
              <chevron-up-linear v-else size="1.3rem" class="h-20px w-20px text-grey-800" />
            </t-action-button>
          </div>
        </div>
      </template>
      <template #list>
        <channels-accordion-item
          v-for="item in channel_items"
          :key="item.channel"
          :item="item"
          @click="handleClick(item)"
        />
      </template>
    </t-accordion-new>
  </div>
</template>
<script lang="ts">
import { ChevronDownLinear, ChevronUpLinear } from '@trengo/trengo-icons';

import AllChannels from '@/components/OnboardingChannelLandingPage/utils/channels';

import AccordionItems from './AccordionItems';
import ChannelsAccordionItem from '../ChannelsAccordionItem';

import type { ChannelsAccordionItem as ChannelsAccordionItemType, ChannelsItemsMeta } from './types';
import type { ChannelsGroup } from '@/components/OnboardingChannelLandingPage/types';
import type { PropType } from 'vue';

export default {
  name: 'ChannelsAccordion',
  emits: ['change', 'item-click', 'mounted'],
  components: { ChevronUpLinear, ChevronDownLinear, ChannelsAccordionItem },
  data() {
    return {
      isOpen: false,
      channel_items: [] as ChannelsAccordionItemType[],
    };
  },
  props: {
    item: {
      type: Object as PropType<ChannelsGroup>,
      default: () => ({}),
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const getAccordionItems = AccordionItems;
    this.channel_items = getAccordionItems((key) => this.$t(key))[this.item.channel];
    this.$emit('mounted', this.$refs.accordion);
  },
  methods: {
    handleClick(item: ChannelsGroup & ChannelsItemsMeta) {
      const getChannels = AllChannels((key) => this.$t(key));
      const channel = item.meta || item.channel;
      this.$emit('item-click', getChannels[item.channel], channel);
    },
    handleClose() {
      this.$emit('change', false, this.item.channel);
    },
    getState(value) {
      this.$emit('change', value, this.item.channel);
    },
  },
  computed: {
    computedTriggerClass() {
      return this.isOpen ? 'accordion-open' : 'accordion-closed';
    },
    getIcon() {
      return `${this.$root.assetsURL}img/settings-landing/${this.item.channel}.svg`;
    },
  },
  watch: {
    open(value) {
      this.isOpen = value;
    },
  },
};
</script>

<style src="./ChannelsAccordion.scss" lang="scss" scoped />
