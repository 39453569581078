import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const waTemplatesRoutes: RouteRecord[] = [
  {
    path: '/admin/wa_templates',
    component: () => import('../../components/WaTemplates/Index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
    children: [
      {
        path: '/admin/wa_templates/create',
        component: () => import('../../components/WaTemplates/Edit.vue'),
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
      {
        path: '/admin/wa_templates/:id',
        component: () => import('../../components/WaTemplates/Edit.vue'),
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
      {
        path: '',
        component: () => import('../../components/AdminIndexBox.vue'),
        props: { boxName: 'wa_templates' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
    ],
  },
  {
    path: '/admin_popout/wa_templates',
    component: () => import('../../components/WaTemplates/Index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
    children: [
      {
        path: '/admin/admin_popout/create',
        component: () => import('../../components/WaTemplates/Edit.vue'),
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
      {
        path: '/admin/admin_popout/:id',
        component: () => import('../../components/WaTemplates/Edit.vue'),
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
    ],
  },
];

export default waTemplatesRoutes;
