<template>
  <div data-test="validating-connection" class="flex flex-col items-center justify-center text-center">
    <t-spinner />
    <p class="t-text-md mt-4 text-grey-600">{{ $t('whatsapp.check_your_phone') }}</p>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import eventBus from '@/eventBus';

import { Steps } from '../../constants';

export default defineComponent({
  name: 'MessageRecieved',
  mounted() {
    this.bindAgencyChannel();
  },
  unmounted() {
    this.$root.agencyChannel.unbind('SandboxMessage');
  },
  methods: {
    bindAgencyChannel() {
      this.$root.agencyChannel.bind('SandboxMessage', (data) => this.onReply(data));
    },
    onReply(data) {
      const onReplyData = {
        message: data.message.message,
        ticketId: data.message.ticket_id,
        nextStep: Steps.MESSAGE_RECIEVED,
      };
      eventBus.$emit('on-reply', onReplyData);
    },
  },
});
</script>
