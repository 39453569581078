<script lang="ts" setup>
import SettingsBaseLayout from '@/components/common/SettingsBaseLayout';

import ContactsProfilesSidebar from '../../Contacts/ContactsProfilesSidebar';
</script>
<template>
  <settings-base-layout :scroll="false" class="flex w-full flex-1 flex-col overflow-scroll !pb-10 pl-4 pr-6 pt-6">
    <template #header>
      <div class="flex flex-row items-center justify-between">
        <slot />
      </div>
    </template>

    <template #default>
      <portal to="sidebar">
        <contacts-profiles-sidebar />
      </portal>
    </template>
  </settings-base-layout>
</template>
