<template>
  <span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-inactive">
      <path
        d="M5.75005 9L5.00377 8.92537L5.00377 8.92537L5.75005 9ZM12 3L12 2.25L12 2.25L12 3ZM3.40053 17.8552L2.77822 17.4366L2.77822 17.4366L3.40053 17.8552ZM20.5995 17.8552L21.2218 17.4366L21.2218 17.4366L20.5995 17.8552ZM18.25 9L18.9963 8.92537L18.25 9ZM12 3L12.0001 2.25H12V3ZM6.49633 9.07463C6.67214 7.31647 7.60301 5.98909 8.74068 5.08795C9.89935 4.17017 11.2023 3.75002 12 3.75L12 2.25C10.7976 2.25003 9.17561 2.8299 7.80932 3.91212C6.42204 5.01098 5.22795 6.68358 5.00377 8.92537L6.49633 9.07463ZM4.02284 18.2738C5.68228 15.8068 6.1274 12.7639 6.49633 9.07463L5.00377 8.92537C4.63135 12.6496 4.19943 15.3238 2.77822 17.4366L4.02284 18.2738ZM4.06071 18.5C4.05766 18.5 4.06078 18.5009 4.05934 18.4999C4.0538 18.4959 4.03706 18.4808 4.02192 18.4478C3.98726 18.3723 4.00171 18.3052 4.02284 18.2738L2.77822 17.4366C2.4264 17.9596 2.43686 18.5899 2.65848 19.0732C2.88304 19.5628 3.37619 20 4.06071 20V18.5ZM12 18.5H4.06071V20H12V18.5ZM12 20H12V18.5H12V20ZM12 20H19.9393V18.5H12V20ZM19.9393 20C20.6238 20 21.117 19.5628 21.3415 19.0732C21.5632 18.5899 21.5736 17.9596 21.2218 17.4366L19.9772 18.2738C19.9983 18.3052 20.0128 18.3723 19.9781 18.4478C19.963 18.4808 19.9462 18.4959 19.9407 18.4999C19.9392 18.5009 19.9424 18.5 19.9393 18.5V20ZM21.2218 17.4366C19.8006 15.3238 19.3687 12.6496 18.9963 8.92537L17.5037 9.07463C17.8726 12.7639 18.3177 15.8068 19.9772 18.2738L21.2218 17.4366ZM18.9963 8.92537C18.7721 6.68358 17.578 5.01098 16.1907 3.91212C14.8244 2.8299 13.2024 2.25003 12.0001 2.25L12 3.75C12.7977 3.75002 14.1007 4.17017 15.2593 5.08795C16.397 5.98909 17.3279 7.31647 17.5037 9.07463L18.9963 8.92537ZM12 2.25H12V3.75H12V2.25Z"
        class="svg-fill"
      />
      <path
        d="M10.5 3L10.5 2.5C10.5 1.67157 11.1716 1 12 1V1C12.8284 1 13.5 1.67157 13.5 2.5L13.5 3L10.5 3Z"
        class="svg-fill"
      />
      <path
        d="M15 19.25L15 19.5C15 21.1569 13.6569 22.5 12 22.5V22.5C10.3431 22.5 9 21.1569 9 19.5L9 19.25L15 19.25Z"
        stroke="svg-stroke"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="svg-stroke"
      />
    </svg>

    <span class="icon-active">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 3C9.99995 3.00005 6.15004 5.00005 5.75005 9C5.37938 12.7067 4.94085 15.5653 3.40053 17.8552C3.02759 18.4096 3.39253 19.25 4.06071 19.25H19.9393C20.6075 19.25 20.9724 18.4096 20.5995 17.8552C19.0592 15.5653 18.6206 12.7067 18.25 9C17.85 5.00005 14 3.00005 12 3Z"
          fill="white"
          stroke="white"
          stroke-width="1.5"
          stroke-linejoin="round"
        />
        <path
          d="M10.5 3L10.5 2.5C10.5 1.67157 11.1716 1 12 1V1C12.8284 1 13.5 1.67157 13.5 2.5L13.5 3L10.5 3Z"
          fill="white"
        />
        <path
          d="M15 19.25L15 19.5C15 21.1569 13.6569 22.5 12 22.5V22.5C10.3431 22.5 9 21.1569 9 19.5L9 19.25L15 19.25Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  </span>
</template>
