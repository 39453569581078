<template>
  <div data-test="online-status-users" class="relative mt-6 grid w-full grid-cols-1 flex-row gap-x-6 xl:grid-cols-2">
    <online-status-user-item
      v-for="(user, index) in getUsers"
      :key="user.id"
      :user="user"
      :has-border="showBorderBottom(index)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import breakpoints from '@/util/breakpoints';

import OnlineStatusUserItem from './OnlineStatusUserItem.vue';

import type { User } from '@/types/common';
import type { PropType } from 'vue';

const USERS_COUNT = 8;
const SMALL_SCREEN_SIZE = 1200;

export default defineComponent({
  name: 'OnlineStatusItems',
  props: {
    users: {
      default: () => [],
      required: true,
      type: Array as PropType<User[]>,
    },
  },
  data() {
    return {
      USERS_COUNT,
      breakpoints,
    };
  },
  components: { OnlineStatusUserItem },
  computed: {
    getUsers(): User[] {
      const { w } = this.breakpoints;
      return w <= SMALL_SCREEN_SIZE ? this.users.slice(0, 4) : this.users.slice(0, USERS_COUNT);
    },
  },
  methods: {
    showBorderBottom(index: number): boolean {
      const { w } = this.breakpoints;
      const isSmallScreen = w <= SMALL_SCREEN_SIZE;
      return !((isSmallScreen && index === 3) || index === 6 || index === 7);
    },
  },
});
</script>
