import { request } from '@/util';

const base_url = '/api/v2/';
import type { Contact, ContactGroup } from '../types';
import type { AxiosPromise } from 'axios';

export const getContactGroups = (): AxiosPromise<ContactGroup[]> => {
  return request(`${base_url}contact_groups_with_count/1/100`, 'GET', {});
};

export const getContactGroup = (id: number): AxiosPromise<ContactGroup> => {
  return request(`${base_url}contact_groups/${id}`, 'GET', {});
};

export const getContactsInContactGroup = (
  groupId: number,
  page: number,
  { type, direction }: { type: string; direction: 'asc' | 'desc' }
): AxiosPromise<Contact[]> => {
  return request(
    `${base_url}contacts_in_group/${groupId}?page=${page}&limit=25&sort=${type}&direction=${direction}`,
    'GET',
    {}
  );
};

export const removeContactInContactGroup = (groupId: number, contactId: number): AxiosPromise<any> => {
  return request(`${base_url}remove_from_group/${groupId}/contact/${contactId}`, 'DELETE', {});
};

export const searchByContactGroup = (
  contactName: string,
  groupId: number,
  page: number,
  { type, direction }: { type: string; direction: 'asc' | 'desc' }
): AxiosPromise<Contact[]> => {
  return request(
    `${base_url}filter_contacts?term=${contactName}&page=${page}&contact_group_id=${groupId}&limit=25&sort=${type}&direction=${direction}`,
    'GET',
    {}
  );
};
