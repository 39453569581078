<template>
  <t-modal
    v-model="computedValue"
    :variant="variant"
    class="create-wa-bsp-modal"
    :title="
      this.step === 6
        ? $t('whatsapp.whatsapp_business_is_connected_to_trengo')
        : $t('whatsapp.getting_started_with_whatsapp_business_api')
    "
    @close="closeModal"
  >
    <mobile-preview-card :has-back="false" class="mobile-overlay absolute -ml-4 -mt-2 w-full md:hidden lg:flex">
      <div class="flex flex-col items-start">
        {{ $t('whatsapp.feature_only_available_on_desktop') }}
        <div class="flex w-full justify-center">
          <t-button size="md" btn-style="secondary" class="mt-8" @click="closeModal">
            <i class="far fa-arrow-left mr-2" />
            {{ $t('general.back') }}
          </t-button>
        </div>
      </div>
    </mobile-preview-card>

    <step-loading
      v-if="(step === 6 && !channel.id) || (step === 6 && stepLoader)"
      @stepLoading="stepLoaderSetMinimumWaitingTime"
    />
    <div v-else>
      <div v-if="step !== 1 && step !== 6" class="flex items-center">
        <t-step-indicator
          v-for="(stepIndicator, i) in [2, 3, 4]"
          :key="i"
          :step="stepIndicator"
          :active="stepIndicator === step"
          :completed="stepIndicator < step"
          class="cursor-default select-none pb-8"
        >
          <template #indicator>
            <span
              v-if="stepIndicator >= step"
              :class="stepIndicator !== step ? 'text-grey-400' : 'text-leaf-600'"
              class="text-xs font-semibold leading-4"
            >
              {{ i + 1 }}
            </span>
            <span v-else><img class="w-9px" :src="`${$root.assetsURL}img/checkmark_bold.svg`" /></span>
          </template>
          <template #title>
            <span
              class="mr-2 text-sm font-semibold leading-4"
              :class="
                stepIndicator < step ? 'text-leaf-600' : stepIndicator === step ? 'text-grey-600' : 'text-grey-500'
              "
            >
              {{ stepIndicatorTitles[i] }}
            </span>
          </template>
          <template v-if="stepIndicator !== 4" #separator>
            <span class="mr-2">
              <chevron-right-linear size="0.9rem" class="text-grey-500" />
            </span>
          </template>
        </t-step-indicator>
      </div>

      <step-one v-if="step === 1" />
      <step-two v-show="step === 2" v-model="confirmFacebookAccountValidation" @update:modelValue="allowNextStep" />
      <step-three
        v-show="step === 3"
        v-model="confirmWhatsappPhoneNumberValidation"
        @input="allowNextStep"
        @openWidget="openWidget"
      />
      <step-four
        v-show="step === 4"
        v-model="whatsAppSignupInProgress"
        class="pb-6"
        @facebookLogin="handleWhatsappSignup"
      />

      <step-five v-show="step === 5">
        <template #body-append>
          <div v-if="!wabAccountId || !phoneId">
            <skeleton-loader width="160px" height="16px" border-radius="4px"></skeleton-loader>
            <skeleton-loader width="448px" height="56px" class="mt-3"></skeleton-loader>
            <skeleton-loader width="160px" height="16px" border-radius="4px" class="mt-12"></skeleton-loader>
            <skeleton-loader width="448px" height="56px" class="mb-12 mt-3"></skeleton-loader>
          </div>
          <div v-else>
            <div class="mb-2 text-lg leading-7 text-grey-900">{{ $t('whatsapp.whatsapp_business_account') }}</div>
            <t-dropdown
              v-model="wabAccountId"
              :items="wabAccounts"
              value-index="id"
              text-index="name"
              size="lg"
              class="step5-dropdown mb-10"
            />

            <div class="mb-2 text-lg leading-7 text-grey-900">{{ $t('whatsapp.phone_number') }}</div>
            <t-dropdown
              v-model="phoneId"
              :items="phoneNumbers"
              value-index="id"
              text-index="display_phone_number"
              size="lg"
              class="step5-dropdown mb-10"
            />
          </div>
        </template>
      </step-five>
      <step-six v-if="step === 6 && channel.id && whatsappBusinessApprovedAt" />
      <step-not-verified v-if="step === 6 && channel.id && !whatsappBusinessApprovedAt" />
    </div>

    <template v-if="(step === 6 && channel.id && !stepLoader) || (step !== 6 && !channel.id)" #footer>
      <div :class="`flex ${footerClass}`">
        <div v-if="step === 1">
          <t-button
            :aria-label="$t('whatsapp.cancel')"
            btn-style="secondary"
            class="mr-2"
            data-testid="cancel-button"
            @click="closeModal"
          >
            {{ $t('whatsapp.cancel') }}
          </t-button>
          <t-button btn-style="primary" :aria-label="$t('whatsapp.get_started')" @click="goToNextStep">
            {{ $t('whatsapp.get_started') }}
          </t-button>
        </div>
        <t-button
          v-if="step !== 1 && step !== 6"
          btn-style="secondary"
          :aria-label="$t('whatsapp.back')"
          class="inline-flex items-center"
          @click="goToPreviousStep"
        >
          <arrow-left-linear :size="18" class="mr-2" />
          {{ $t('whatsapp.back') }}
        </t-button>
        <t-button
          v-if="step !== 1 && step !== 6"
          btn-style="primary"
          :aria-label="$t('whatsapp.next')"
          class="ml-auto"
          :disabled="computedStepBtnDisabled"
          @click="goToNextStep"
        >
          {{ $t('whatsapp.next') }}
        </t-button>
        <t-button
          v-if="step === 6"
          btn-style="secondary"
          :aria-label="$t('whatsapp.done')"
          class="ml-auto"
          @click="closeModal"
        >
          {{ $t('whatsapp.next') }}
        </t-button>
      </div>
    </template>
  </t-modal>
</template>

<script>
import { ArrowLeftLinear, ChevronRightLinear, CrossLinear } from '@trengo/trengo-icons';

import SkeletonLoader from '@/components/Channels/CreateChannelModals/WhatsApp/SkeletonLoader/SkeletonLoader';
import StepFive from '@/components/Channels/CreateChannelModals/WhatsApp/StepFive';
import StepFour from '@/components/Channels/CreateChannelModals/WhatsApp/StepFour';
import StepLoading from '@/components/Channels/CreateChannelModals/WhatsApp/StepLoading';
import StepNotVerified from '@/components/Channels/CreateChannelModals/WhatsApp/StepNotVerified';
import StepSix from '@/components/Channels/CreateChannelModals/WhatsApp/StepSix';
import StepThree from '@/components/Channels/CreateChannelModals/WhatsApp/StepThree';
import StepTwo from '@/components/Channels/CreateChannelModals/WhatsApp/StepTwo';
import MobilePreviewCard from '@/components/common/MobilePreviewCard';
import { WHATSAPP_GRAPH_API_VERSION } from '@/Configs/Constants/whatsapp';

import StepOne from '../../StepOne';
import TStepIndicator from '../TStepIndicator';

export default {
  name: 'CreateWaBspModal',
  emits: ['created', 'update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      step: 1,
      stepIndicatorTitles: [
        this.$t('whatsapp.provide_access'),
        this.$t('whatsapp.check_number'),
        this.$t('whatsapp.create_account'),
      ],
      confirmFacebookAccountValidation: false,
      confirmWhatsappPhoneNumberValidation: false,
      whatsAppSignupInProgress: false,
      wabAccounts: [],
      wabAccountId: '',
      phoneNumbers: [],
      phoneId: '',
      channel: {},
      businessHours: [],
      whatsappBusinessApprovedAt: false,
      stepLoader: true,
    };
  },
  components: {
    SkeletonLoader,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
    StepSix,
    StepNotVerified,
    StepLoading,
    TStepIndicator,
    ArrowLeftLinear,
    ChevronRightLinear,
    CrossLinear,
    MobilePreviewCard,
  },
  async mounted() {
    this.initFacebook();
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    computedStepBtnDisabled() {
      return (
        (this.step === 2 && !this.confirmFacebookAccountValidation) ||
        (this.step === 3 && !this.confirmWhatsappPhoneNumberValidation) ||
        (this.step === 4 && !this.wabAccountId) ||
        (this.step === 5 && !this.wabAccountId && !this.phoneId)
      );
    },
    variant() {
      return this.step === 1 ? 'narrow' : 'default';
    },
    footerClass() {
      return this.step === 1 ? '' : 'w-full';
    },
  },
  watch: {
    wabAccountId(value) {
      if (value) {
        this.getPhoneNumbers(value);
      }
    },
  },
  methods: {
    initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: window.MIX_FACEBOOK_BSP_APP_ID,
          cookie: true,
          xfbml: true,
          version: WHATSAPP_GRAPH_API_VERSION,
        });
      };
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = `https://connect.facebook.net/en_US/sdk.js`;
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    },
    handleWhatsappSignup() {
      this.whatsAppSignupInProgress = true;
      window.FB.login(
        (response) => {
          if (response.authResponse) {
            console.log('response', response);
            const code = response.authResponse.code;
            this.getWabAccounts(code);
            this.goToNextStep();
          }
          this.whatsAppSignupInProgress = false;
        },
        {
          config_id: window.MIX_FACEBOOK_BSP_SCOPE_CONFIG_ID,
          scope: 'whatsapp_business_management, whatsapp_business_messaging',
          response_type: 'code',
          override_default_response_type: true,
          extras: {
            feature: 'whatsapp_embedded_signup',
            version: 2,
            setup: {
              // Prefilled data can go here -> https://developers.facebook.com/docs/whatsapp/embedded-signup/pre-filled-data
            },
          },
        }
      );
    },
    async getWabAccounts(code) {
      const res = await axios.get('/api/v2/channels/bsp/waba_ids?code=' + code);
      const { data } = res;
      this.wabAccounts = data.map((account) => ({
        id: account?.id,
        name: account?.on_behalf_of_business_info?.name,
      }));
      this.wabAccountId = this.wabAccounts?.[0]?.id;
      await this.getPhoneNumbers(this.wabAccountId);
    },
    async getPhoneNumbers(wabAccountId) {
      const res = await axios.get('/api/v2/channels/bsp/waba_phone_numbers?waba_id=' + wabAccountId);
      this.phoneNumbers = res.data;
      this.phoneId = this.phoneNumbers?.[0]?.id;
    },
    getBusinessHours() {
      return axios.get('/api/v2/business_hours');
    },
    createChannel() {
      return axios.post('/api/v2/channels', this.getChannelRecord());
    },
    goToChannel() {
      this.$router.push(`/admin/channels2/wa_business/${this.channel.id}`);
    },
    getChannelRecord() {
      return {
        type: 'WA_BUSINESS',
        notification_email: null,
        notification_sound: 'chat.mp3',
        username: null,
        password: null,
        password_is_null: false,
        title: 'WhatsApp Business (' + this.phoneId + ')',
        color: null,
        team_ids: [this.getFirstTeam()?.id],
        teams: [this.getFirstTeam()],
        users: [],
        reopen_closed_ticket: 1,
        business_hour_id: this.businessHours[0]?.id || null,
        is_private: false,
        reassign_reopened_ticket: false,
        reopen_closed_ticket_time_window_days: 30,
        whatsappChannel: {
          meta: {
            phoneId: this.phoneId,
            wabaId: this.wabAccountId,
          },
          sms_channel_id: null,
          provider: 'trengo_bsp',
        },
        waSandboxNumbers: [],
      };
    },
    /**
     * @returns {{name: *, id: *}}
     */
    getFirstTeam() {
      const defaultTeam = this.$root.teams.reduce(
        (accumulator, team) => (team.id < accumulator.id ? team : accumulator),
        { id: Infinity }
      );
      return { id: defaultTeam?.id, name: defaultTeam?.name };
    },
    /**
     * @returns {number}
     */
    openWidget() {
      window?.Trengo?.Api?.Widget?.open('chat');
    },
    resetModalState() {
      this.step = 1;
      this.confirmFacebookAccountValidation = false;
      this.confirmWhatsappPhoneNumberValidation = false;
      this.whatsAppSignupInProgress = false;
      this.wabAccounts = [];
      this.wabAccountId = '';
      this.phoneNumbers = [];
      this.phoneId = '';
      this.channel = {};
      this.businessHours = [];
      this.whatsappBusinessApprovedAt = false;
      this.stepLoader = true;
    },
    closeModal() {
      this.computedValue = false;
      if (this.step === 6 && this.wabAccountId && this.phoneId && this.channel?.id) {
        this.goToChannel();
        this.resetModalState();
      }
    },
    allowNextStep(val) {
      if (this.step === 2) this.confirmFacebookAccountValidation = val;
      if (this.step === 3) this.confirmWhatsappPhoneNumberValidation = val;
    },
    goToPreviousStep() {
      this.step--;
    },
    async goToNextStep() {
      try {
        this.step++;
        if (this.step === 6 && this.wabAccountId && this.phoneId) {
          await this.getBusinessHours().then((res) => {
            this.businessHours = res.data?.data || [];
          });
          await this.createChannel().then((res) => {
            this.channel = res.data;
            this.whatsappBusinessApprovedAt = this.channel.whats_app_channel.approved_at;
            this.$root.loadAuthorizedChannels();
            this.$root.loadAllChannels();
            this.$emit('created');
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    stepLoaderSetMinimumWaitingTime() {
      setTimeout(() => {
        this.stepLoader = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped src="./CreateWaBspModal.scss" />
