import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message_box = _resolveComponent("message-box")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.wrapperClasses)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messagesData, (message, index) => {
      return (_openBlock(), _createBlock(_component_message_box, {
        key: index,
        class: _normalizeClass(String(message.type) === _ctx.messageTypes.OUTGOING ? 'outgoing' : 'incoming'),
        message: message.message
      }, null, 8 /* PROPS */, ["class", "message"]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 2 /* CLASS */))
}