<script lang="ts">
import { sanitize } from 'dompurify';
import { toArray } from 'lodash';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import NoteRedesign from '@/components/MessageFeed/Note.vue';
import { PERMISSION, FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import { NYLAS_V3 } from '@/Configs/Constants/ChannelLandingPageTypes';
import eventBus from '@/eventBus';
import { useUserStore, useFeatureFlagStore } from '@/store/pinia';
import { useInboundTranslationStore } from '@/store/pinia/translation/useInboundTranslationStore';
import breakpoints from '@/util/breakpoints';
import { formatDateTime } from '@/util/date';

import avatar from './Avatar';
import TranslateLink from './common/TranslateLink/TranslateLink.vue';
import EmailDropdown from './EmailDropdown';
import Note from './Note';
import QuotedEmailMessage from './QuotedEmailMessage';
import Dropdown from './ReplyForm/Dropdown.vue';

export default defineComponent({
  emits: ['deleteTicketMessage', 'move-message-to-new-ticket', 'mark-previous-tasks', 'translate-inbox-message'],
  components: {
    avatar,
    Dropdown,
    Note,
    NoteRedesign,
    QuotedEmailMessage,
    EmailDropdown,
    TranslateLink,
  },

  data() {
    return {
      isFull: false,
      originalMessage: '',
      downloadingZip: false,
      collapsed: true,
      messageElements: null,
      isChrome: window.isChrome(),
      sidebarState: null,
      showOpenExternalWindowIcon: null,
      PERMISSION,
    };
  },

  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
    ticket: {
      type: Object,
      default: () => ({}),
    },
    isLatest: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 0,
    },
    messages: {
      type: Array,
      default: () => [],
    },
  },

  async mounted() {
    this.messageElements = this.getAllHtmlElements(this.message.email_message?.html || this.message.message);
    if (
      (this.isLatest && !this.messageElementsLimit) ||
      (this.message.email_message && !this.messageElementsLimit && this.page === 1)
    ) {
      this.collapsed = this.message.email_message?.collapsed;
    }

    window.addEventListener('resize', this.toggleOpenExternalWindowButtonOrIcon);
    eventBus.$on('ticket.sidebar.toggle', (showSidebar) => {
      this.sidebarState = showSidebar;
      this.toggleOpenExternalWindowButtonOrIcon();
    });

    this.sidebarState = await this.getSidebarState();
    this.toggleOpenExternalWindowButtonOrIcon();
    this.getEmailMessageElements();
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.toggleOpenExternalWindowButtonOrIcon);
    eventBus.$off('ticket.sidebar.toggle');
  },

  computed: {
    ...mapStores(useUserStore, useInboundTranslationStore),
    cc() {
      return toArray(
        this.message.contacts?.filter((c) => {
          return c.pivot.type === 'CC';
        }),
      );
    },
    bcc() {
      return toArray(
        this.message.contacts?.filter((c) => {
          return c.pivot.type === 'BCC';
        }),
      );
    },
    recipients() {
      let contacts = toArray(
        this.message.contacts?.filter((c) => {
          return c.pivot.type === 'RECIPIENT';
        }),
      );
      if (this.message.contact && this.message.type === 'OUTBOUND') {
        contacts.unshift(this.message.contact);
      }
      return contacts;
    },
    showDownloadAttachmentZip() {
      return (
        this.message.attachments.length > 1 && !is_mobile_device() && !window.isLoadedFromApp && !window.isElectron
      );
    },
    showDownloadAttachmentFile() {
      return !is_mobile_device() && !window.isLoadedFromApp && !window.isElectron;
    },
    from() {
      if (this.message.type === 'INBOUND' && this.message.contact) {
        return {
          abbr: this.message.contact.abbr,
          color: this.message.contact.color,
          image_path: null,
        };
      } else if (this.message.type === 'OUTBOUND' && this.message.agent) {
        return {
          abbr: this.message.agent.abbr,
          color: this.message.agent.color,
          image_path: this.message.agent.profile_image,
          name: this.message.agent.name,
        };
      }

      const NylasChannelFallbackSenderName = this.ticket.channel?.emailChannel?.meta?.email_address;
      return {
        abbr: 'T',
        color: '#5bb130',
        image_path: null,
        name: this.emailChannelType === NYLAS_V3 ? NylasChannelFallbackSenderName : this.ticket.channel?.username,
      };
    },
    name() {
      if (this.message.type === 'INBOUND') {
        return this.message.contact?.full_name || this.message.identifier || this.message.email_message?.from;
      }

      return this.from.name;
    },
    trimmedMessage() {
      return (
        this.message.message
          ?.replace(/&nbsp;/g, ' ')
          .replace(/<[^>]*>/g, '')
          .substr(0, 400)
          .trim() ?? ''
      );
    },
    isMobile() {
      return !breakpoints.comparisons.gt_md;
    },
    messageElementsLimit() {
      return this.isChrome && this.messageElements?.length > 4000;
    },

    isFeedUpdateEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.COMMENT_FEED_REDESIGN);
    },
    emailChannelType() {
      return this.ticket.channel?.emailChannel?.type;
    },

    isConsolidatedMessageEndpointEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.CONSOLIDATED_MESSAGE_ENDPOINT_ENABLED);
    },
  },

  methods: {
    openLocalAttachment(attachment) {
      var reader = new FileReader();

      reader.addEventListener(
        'load',
        function () {
          var link = document.createElement('a');
          link.href = reader.result;
          link.download = attachment.client_name;
          document.body.appendChild(link);
          link.click();
        },
        false,
      );
      reader.readAsDataURL(attachment.file);
    },

    async popout(print = false) {
      if (this.messageElementsLimit) {
        await this.getEmailMessage().then((res) => {
          if (!res.data.email_message) this.message.email_message.html = this.message.message;
          this.message.email_message = res.data.email_message;
        });
      }
      var left = screen.width / 2 - 800 / 2;
      var top = screen.height / 2 - 600 / 2;

      var win = window.open(
        '',
        'Trengo',
        'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800, height=600, top=' +
          top +
          ', left=' +
          left,
      );
      win.document.body.innerHTML = 'Loading...';

      let html =
        '<p style="font-style: italic">From: ' +
        (this.message.contact.name || this.message.email_message.from) +
        '<br />Date: ' +
        formatDateTime(this.message.email_message.created_at) +
        '<br />' +
        (this.message.email_message.subject != null ? 'Subject: ' + this.message.email_message.subject : '') +
        '</p><hr />' +
        this.message.email_message?.html;
      var parser = new DOMParser();
      var doc = parser.parseFromString(html, 'text/html');
      var paragraphs = doc.querySelectorAll('img');
      paragraphs.forEach((img) => {
        img.src =
          'https://c4qmoqkz48.execute-api.eu-central-1.amazonaws.com/default/imageProxy?url=' +
          encodeURIComponent(img.src);
      });
      html = doc.querySelector('body').innerHTML;

      html = sanitize(html, {
        FORBID_TAGS: ['style', 'svg', 'audio', 'video', 'math', 'meta', 'script'],
        FORBID_ATTR: ['class'],
      });

      win.document.body.innerHTML = html;

      if (print) {
        setTimeout(() => {
          win.print();
          win.close();
        }, 250);
      }
    },

    openContact(id) {
      router.push('/contacts/' + id);
    },

    forward() {
      eventBus.$emit('modals.forward-ticket.open', {
        ticket: this.ticket,
        message_id: this.message.id,
      });
    },

    openFullMessage(e, force = false) {
      if (e.target.hasAttribute('data-no-toggle')) {
        e.preventDefault();
        return;
      }
      if (e.target.hasAttribute('data-no-close') && !this.collapsed) {
        return;
      }

      // Prevent click when text is selected text inside this EmailMessage component. But not sure if 100% cross browser so try/catch to be sure
      try {
        if (window.getSelection().toString() !== '' && !force) {
          const baseNode = window.getSelection()?.baseNode;
          const isSelectionInsideThisEmailMessage = this.$el.contains(baseNode);
          if (isSelectionInsideThisEmailMessage) {
            return;
          }
        }
      } catch (e) {}

      if (!this.collapsed) {
        this.collapsed = true;
        return;
      }

      if (this.message.email_message && this.message.email_message.html) {
        this.collapsed = false;
        return;
      }
      this.message.email_message.html = this.message.message;
      this.getEmailMessage().then((res) => {
        if (!res.data.email_message) this.message.email_message.html = this.message.message;
        this.message.email_message = res.data.email_message;
      });
      this.collapsed = false;
    },

    getEmailMessage() {
      return axios.get(`/api/v2/tickets/${this.ticket.id}/email_messages/${this.message.id}`);
    },

    getAttachmentsZip() {
      if (!this.downloadingZip) {
        this.downloadingZip = true;

        const callback = (data) => {
          axios({
            url: decodeURIComponent(data.data.url),
            method: 'GET',
            responseType: 'blob',
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'attachments_' + this.message.id + '.zip');
            document.body.appendChild(link);
            link.click();
            this.$nextTick(() => {
              this.downloadingZip = false;
              eventBus.$off('ticket.zip_ready.' + this.message.id, callback);
            });
          });
        };
        eventBus.$on('ticket.zip_ready.' + this.message.id, callback);

        axios.get('/api/v2/tickets/' + this.message.ticket_id + '/messages/' + this.message.id + '/attachments_zip');
      }
    },

    openInPopup(attachment, e) {
      if (
        attachment.full_url.match(/.(jpg|jpeg|png|gif|pdf)$/i) &&
        !this.$root.loadedFromApp &&
        !this.$root.isDesktopApp
      ) {
        e.preventDefault();
        window.PopupCenter(this.safeUrl(attachment.full_url), attachment.client_name, 1020, 700);
      }
    },

    getAllHtmlElements(string) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(string, 'text/html');
      return doc.querySelectorAll('*');
    },

    async getEmailMessageElements() {
      try {
        if (!this.isConsolidatedMessageEndpointEnabled) {
          const response = await this.getEmailMessage();
          this.message.email_message = response.data.email_message;
        }
        this.messageElements = this.getAllHtmlElements(this.message.email_message?.html);
        if (this.messageElementsLimit) {
          this.collapsed = true;
        }
      } catch (error) {
        console.error('Failed to get email message elements:', error);
      }
    },

    getSidebarState() {
      return this.$tStorage.getItem('sidebar_state');
    },

    toggleOpenExternalWindowButtonOrIcon() {
      this.showOpenExternalWindowIcon =
        (this.sidebarState &&
          ((window.innerWidth > 1299 && window.innerWidth < 1486) ||
            (window.innerWidth > 1595 && window.innerWidth < 1620))) ||
        (!this.sidebarState && window.innerWidth > 991 && window.innerWidth < 1190);
    },

    deleteTicketMessage(ticket_id) {
      this.$emit('deleteTicketMessage', ticket_id);
    },
    formatDateTime,
  },
  watch: {
    downloadingZip: function (val) {
      if (val) {
        this.$refs.attachmentsZipLoadIndicatorIcon.innerText = 'loop';
        this.$refs.attachmentsZipLoadIndicatorIcon.classList.add('spin');
        this.$refs.attachmentsZipLoadIndicator.classList.add('cursor-not-allowed');
      } else {
        this.$refs.attachmentsZipLoadIndicatorIcon.innerText = 'cloud_download';
        this.$refs.attachmentsZipLoadIndicatorIcon.classList.remove('spin');
        this.$refs.attachmentsZipLoadIndicator.classList.remove('cursor-not-allowed');
      }
    },
  },
});
</script>

<template>
  <div class="pb-0 lg:pb-6" style="margin-top: -2px" data-test="emailMessageWrapper">
    <div v-if="message.type !== 'NOTE'" class="flex">
      <div
        class="min-w-0 flex-1"
        :class="{ 'border-b-2 border-t-2 border-grey-200 lg:rounded-lg lg:border-2': !messageElementsLimit }"
      >
        <div
          class="mail-message bg white relative inline-block h-full w-full lg:rounded-lg"
          :class="{ 'is-auto-reply': message.is_auto_reply }"
        >
          <div
            class="cursor-pointer p-4"
            :class="{
              'lg:p-3': !messageElementsLimit,
              'wrapper border-b-2 border-t-2 border-grey-200 lg:rounded-lg lg:border-2 lg:px-3 lg:py-1':
                messageElementsLimit,
            }"
            :style="collapsed ? '' : ' border-bottom: 2px solid #eaedf5; padding-bottom:14px'"
            @click="!messageElementsLimit ? openFullMessage($event, true) : popout(false)"
          >
            <div class="flex min-w-0 flex-1 items-start" data-hj-suppress>
              <div
                class="hidden-md-down inline-block-40px mr-3 flex-shrink-0 overflow-hidden rounded-lg"
                :style="{
                  'margin-top': !messageElementsLimit ? '-14px' : '-6px',
                }"
                style="margin-top: -14px; margin-left: -14px"
              >
                <div class="overflow-hidden rounded-lg" data-hj-suppress>
                  <avatar
                    style="margin-top: -5px; margin-left: -5px"
                    width="45"
                    :color="from.color"
                    :abbr="from.abbr"
                    :image="from.image_path"
                  ></avatar>
                </div>
              </div>
              <div
                class="hidden-lg-up inline-block-40px mr-3 flex-shrink-0 overflow-hidden"
                style="margin-top: -18px; margin-left: -19px"
              >
                <div class="overflow-hidden">
                  <avatar
                    style="margin-top: -5px; margin-left: -5px"
                    width="45"
                    :color="from.color"
                    :abbr="from.abbr"
                    :image="from.image_path"
                  ></avatar>
                </div>
              </div>
              <div
                class="flex min-w-0 flex-1 flex-col"
                :style="{ 'margin-top': !messageElementsLimit ? '5px' : '6px' }"
              >
                <div class="flex min-w-0 flex-row items-center">
                  <div class="mb-1 flex min-w-0 flex-1 leading-none">
                    <div
                      data-hj-suppress
                      class="flex-shrink items-center text-ellipsis pb-1 pr-2 font-bold"
                      :class="{ 'mt-1.5': messageElementsLimit }"
                      :title="name"
                      :style="{ 'margin-top': !messageElementsLimit ? '' : '7px' }"
                    >
                      {{ name }}
                    </div>

                    <div v-if="collapsed" class="hidden-md-down select-none text-ellipsis text-grey-600">
                      <div class="text-ellipsis" :style="{ 'margin-top': !messageElementsLimit ? '' : '7px' }">
                        {{ trimmedMessage }}
                      </div>
                    </div>
                    <div
                      class="ml-auto flex flex-shrink-0 flex-nowrap items-center leading-none"
                      :style="{ 'margin-top': messageElementsLimit && showOpenExternalWindowIcon ? '7px' : '' }"
                    >
                      <datetime
                        v-if="(messageElementsLimit && !isMobile) || !messageElementsLimit"
                        class="pl-4 text-xs text-grey-500"
                        :time="message.created_at"
                        :pretty="$root.prettyDates"
                      ></datetime>
                      <div class="relative flex select-none items-center" style="margin-right: 8px">
                        <dropdown
                          :options="[]"
                          :static="true"
                          :search="false"
                          :scroll-on-hover="false"
                          :stop-propagation="true"
                          class="dropdown-more"
                        >
                          <template #toggle>
                            <a
                              class="ml-3 flex items-center leading-none"
                              data-no-close
                              :style="!messageElementsLimit ? 'margin-right: 25px' : 'margin-right: 17px'"
                            >
                              <i
                                class="material-icons leading-none text-grey-600"
                                style="line-height: 8px"
                                data-no-close
                              >
                                more_horiz
                              </i>
                            </a>
                          </template>
                          ]
                          <template #heading>
                            <div
                              class="_500 hidden-lg-up select-none border-grey-200 py-4 text-center text-base leading-none text-black"
                            >
                              {{ $t('general.nav_options') }}
                            </div>
                          </template>
                          <template #body>
                            <a
                              class="align-items m-0 flex flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                              data-no-toggle
                              @click="popout(false)"
                            >
                              <div class="container-icon md-18">
                                <i class="material-icons md-18 ticket-option-icon text-grey-600">open_in_new</i>
                              </div>
                              <div class="ml-2 flex items-center">
                                {{ $t('tickets.view_in_external_window') }}
                              </div>
                            </a>
                            <a
                              v-if="userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__CREATE)"
                              class="align-items m-0 flex flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                              data-no-toggle
                              @click="forward"
                            >
                              <div class="container-icon md-18">
                                <i class="material-icons md-18 ticket-option-icon text-grey-600">forward</i>
                              </div>
                              <div class="ml-2 flex items-center">
                                {{ $t('tickets.forward_message') }}
                              </div>
                            </a>
                            <a
                              v-if="ticket.channel.type === 'EMAIL' && messages.length > 1"
                              class="align-items m-0 flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                              data-no-toggle
                              @click="$emit('move-message-to-new-ticket', message.id)"
                            >
                              <div class="container-icon md-18">
                                <i class="material-icons md-18 ticket-option-icon text-grey-600">redo</i>
                              </div>
                              <div class="ml-2 flex items-center">
                                {{ $t('tickets.move_ticket_to_new_ticket') }}
                              </div>
                            </a>
                            <a
                              v-if="!$root.isDesktopApp"
                              class="align-items m-0 flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                              data-no-toggle
                              @click="popout(true)"
                            >
                              <div class="container-icon md-18">
                                <i class="material-icons md-18 ticket-option-icon text-grey-600">print</i>
                              </div>
                              <div class="ml-2 flex items-center">
                                {{ $t('tickets.print_message') }}
                              </div>
                            </a>
                            <a
                              v-if="userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__DELETE)"
                              class="align-items m-0 flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                              data-no-toggle
                              @click="this.$emit('deleteTicketMessage', message.id)"
                            >
                              <div class="container-icon md-18">
                                <i class="material-icons md-18 ticket-option-icon text-grey-600">delete</i>
                              </div>
                              <div class="ml-2 flex items-center">
                                {{ $t('tickets.delete_message') }}
                              </div>
                            </a>
                          </template>
                        </dropdown>
                        <div
                          v-if="!messageElementsLimit"
                          style="position: absolute !important; right: -8px !important; top: -8px !important"
                          @click.prevent.stop="openFullMessage($event, true)"
                        >
                          <i
                            class="material-icons cursor-pointer text-black"
                            v-text="!collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_left'"
                          ></i>
                        </div>
                        <atomic-button
                          v-else-if="messageElementsLimit && !showOpenExternalWindowIcon"
                          v-tooltip="{
                            placement: 'left',
                            content: $t('tickets.email_too_big'),
                            delay: { show: 500, hide: 0 },
                            popperClass: 'tooltip-open-window',
                          }"
                          size="sm"
                          btn-style="secondary"
                          aria-label="Open in external window"
                          class="flex items-center"
                          @click="popout(false)"
                        >
                          {{ $t('tickets.open_in_external_window') }}
                          <img
                            alt="icon-search"
                            style="width: 16px"
                            :src="`${$root.assetsURL}img/icon_external_arrow.svg`"
                            class="ml-1.5"
                          />
                        </atomic-button>
                        <img
                          v-else-if="messageElementsLimit && showOpenExternalWindowIcon"
                          v-tooltip="{
                            placement: 'left',
                            content: $t('tickets.email_too_big'),
                            delay: { show: 500, hide: 0 },
                            popperClass: 'tooltip-open-window',
                          }"
                          alt="icon-search"
                          style="width: 16px"
                          :src="`${$root.assetsURL}img/icon_external_arrow.svg`"
                          class="-mr-1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="collapsed" class="hidden-lg-up cursor-pointer text-ellipsis pt-1 text-grey-600">
                  <div class="text-ellipsis">{{ message.message?.substr(0, 400) }}</div>
                </div>
                <div class="flex justify-between">
                  <div>
                    <div v-if="!collapsed" class="flex flex-wrap text-grey-600">
                      <span class="text-500 mr-1">{{ $t('tickets.email_recipient') }}:</span>
                      <template v-for="contact in recipients">
                        <email-dropdown :icon="false" :contact="contact" data-no-toggle class="mr-2"></email-dropdown>
                      </template>
                      <div v-if="!recipients.length">{{ message.email_message.to }}</div>
                    </div>
                    <div v-if="!collapsed && cc.length > 0" class="flex flex-wrap text-grey-600">
                      <span class="mr-1 text-grey-500">Cc:</span>
                      <template v-for="contact in cc">
                        <email-dropdown :icon="false" :contact="contact" data-no-toggle class="mr-2"></email-dropdown>
                      </template>
                    </div>
                    <div v-if="!collapsed && bcc.length > 0" class="flex flex-wrap text-grey-600">
                      <span class="mr-1 text-grey-500">Bcc:</span>
                      <template v-for="contact in bcc">
                        <email-dropdown :icon="false" :contact="contact" data-no-toggle class="mr-2"></email-dropdown>
                      </template>
                    </div>
                  </div>
                  <TranslateLink
                    v-if="inboundTranslationStore.isActive"
                    :can-revert="
                      inboundTranslationStore?.getIsStorageTranslationAvailable({
                        messageId: message.id,
                        ticketId: message.ticket_id,
                        message: message.email_message?.html ?? message.message,
                        locale: userStore?.user?.locale_code,
                      })
                    "
                    :status="inboundTranslationStore.status"
                    @translate="(isReverting?: boolean) => $emit('translate-inbox-message', isReverting)"
                  />
                </div>
              </div>
            </div>
          </div>
          <quoted-email-message
            v-if="!collapsed && message.email_message?.html"
            data-hj-suppress
            class="email-body p-4"
            style="word-wrap: break-word; -webkit-nbsp-mode: space; -webkit-line-break: after-white-space"
            :html="message.email_message?.html"
            :message="message"
          ></quoted-email-message>
          <template v-if="message.attachments != null && message.attachments.length > 0 && !collapsed">
            <div style="border-top: 1px solid rgb(243, 243, 243); padding: 15px">
              <div class="mb-1">
                <strong>{{ $t('tickets.attachments') }}</strong>
                <div v-if="showDownloadAttachmentZip" class="inline pl-1 align-middle">
                  <a
                    ref="attachmentsZipLoadIndicator"
                    v-tooltip="{
                      placement: 'bottom',
                      content: $t('tickets.download_attachments_zip'),
                      delay: { show: 500, hide: 0 },
                    }"
                    class="text-success"
                    target="_blank"
                    @click="getAttachmentsZip()"
                  >
                    <i ref="attachmentsZipLoadIndicatorIcon" class="material-icons">cloud_download</i>
                  </a>
                </div>
              </div>
              <div v-for="attachment in message.attachments" class="text-ellipsis" style="">
                <div
                  class="text-success b-success inline border px-1 text-center"
                  style="border-radius: 0.25rem; min-width: 40px; font-size: 11px"
                >
                  {{ attachment.extension }}
                </div>
                <a v-if="showDownloadAttachmentFile" :href="'/download/attachment/' + attachment.id" target="_blank">
                  <i class="material-icons align-middle text-grey-600" style="font-size: 20px">file_download</i>
                </a>
                <a
                  style="color: inherit; text-decoration: none"
                  data-hj-suppress
                  :class="{ 'ml-1': !showDownloadAttachmentFile }"
                  class="open-external"
                  :href="attachment.full_url"
                  target="_blank"
                  @click="openInPopup(attachment, $event)"
                >
                  {{ attachment.client_name }}
                  <small v-if="attachment.size" class="mx-1">{{ attachment.size }}</small>
                </a>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <note
        v-if="!isFeedUpdateEnabled && message.type === 'NOTE'"
        :message="message"
        class="my-4 ml-3 lg:my-0 lg:ml-0"
        @mark-previous-tasks="$emit('mark-previous-tasks')"
        @deleteMessage="$emit('deleteMessage')"
      />
      <note-redesign
        v-else-if="message.type === 'NOTE'"
        :message="message"
        :messages="messages"
        class="my-4 ml-3 lg:my-0 lg:ml-0"
        @deleteTicketMessage="deleteTicketMessage"
      />
    </transition>
  </div>
</template>

<style lang="scss">
.text-dangerous {
  color: #d9534f;
}

.spin {
  transform-origin: center center;
  -webkit-backface-visibility: hidden;
  -webkit-animation: spin-animation 2s linear infinite;
  animation: spin-animation 2s linear infinite;
}

.dropdown-more {
  width: auto !important;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@screen lg {
  .tooltip-open-window {
    left: -9px !important;
    .tooltip-arrow {
      top: 11px;
      right: -7px;
      width: 0;
      height: 0;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 6px solid rgba(0, 0, 0, 0.75);
      backdrop-filter: blur(24px);
    }
    .tooltip-inner {
      max-width: none;
      border-radius: 8px;
      padding: 0.5rem 1rem;
      line-height: 1rem;
      background: rgba(0, 0, 0, 0.75);
      backdrop-filter: blur(24px);
      font-weight: 600;
      user-select: none;
      display: inline-block;
    }
  }
  .mail-message .wrapper {
    height: 56px;
  }
}
</style>
