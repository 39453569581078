<template>
  <edit-modal :installation="installation" @close="closeModal" @updated="updated" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import EditModal from '@/components/IntegrationHub/Components/Modals/EditModal.vue';

export default defineComponent({
  name: 'IntegrationInstallationEdit',

  components: {
    EditModal,
  },

  computed: {
    ...mapGetters({
      integration: 'integrations/getItem',
    }),
    installation() {
      return this.integration.installations.find((installation) => {
        return installation.id === parseInt(this.$route.params.installationId);
      });
    },
  },

  methods: {
    closeModal() {
      const params = {
        filter: this.$route.params.filter,
        integrationId: this.$route.params.integrationId,
      };

      this.$router.replace({ name: 'integrationDetails', params, query: { ...this.$route.query } });
    },

    updated(success: boolean) {
      if (success) {
        this.flashSuccess(this.$t('integration_hub.update_success', { name: this.integration.name }));
      }
      this.closeModal();
    },
  },
});
</script>
