<template>
  <div data-test="online-status" class="mb-6 h-full w-full rounded-xl border border-grey-300 bg-white p-6" width="100%">
    <div class="flex h-full flex-col items-start">
      <div class="flex flex-row items-center">
        <div class="mr-2 rounded-xl border border-grey-500 p-2">
          <calendar-person-linear size="1.4rem" />
        </div>
        <div class="flex flex-col">
          <p class="t-text-h5 m-0 text-grey-800">{{ $t('reports_v2.online_status') }}</p>
          <p
            v-if="showSkeleton"
            :class="{ 'animation-loading': showSkeleton }"
            class="m-0 mt-1 h-3 w-128px rounded-3xl bg-grey-300"
          />
          <p v-if="!showSkeleton && !showEmptyStateSkeleton" class="m-0 flex text-grey-600">
            {{ $t('reports_v2.available_agents_count', { available: aggregates.online, total: aggregates.total }) }}
          </p>
        </div>
      </div>
      <online-status-skeleton v-if="showSkeleton || showEmptyStateSkeleton" :show-error="showEmptyStateSkeleton" />
      <online-status-items v-if="!showSkeleton && !showEmptyStateSkeleton" :users="users" />
      <div
        v-if="showViewAllAgentsButton"
        data-test="view-all-agents"
        class="mt-6 flex w-full flex-row-reverse justify-items-end"
      >
        <router-link to="/reports/agents_availability">
          <t-button-text size="sm">
            {{ $t('reports_v2.view_all_agents') }}
            <arrow-right-linear size="1.2rem" />
          </t-button-text>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CalendarPersonLinear, ArrowRightLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import * as reportingAPI from '@/components/Reporting/api';

import OnlineStatusItems from './OnlineStatusItems.vue';
import OnlineStatusSkeleton from './OnlineStatusSkeleton.vue';

import type { User } from '@/types/common';

type Data = {
  loaded: boolean;
  users: User[];
  aggregates: {
    online: number;
    total: number;
  };
};

export default defineComponent({
  name: 'Availability',
  components: { CalendarPersonLinear, ArrowRightLinear, OnlineStatusSkeleton, OnlineStatusItems },
  data(): Data {
    return {
      loaded: false,
      users: [],
      aggregates: {
        online: 0,
        total: 0,
      },
    };
  },
  mounted() {
    reportingAPI.fetchUsers().then((res) => {
      this.users = res.data.data;
      const { aggregates } = res.data;
      this.aggregates = {
        online: aggregates.online_users,
        total: aggregates.offline_users + aggregates.online_users,
      };
      this.loaded = true;
    });
  },
  methods: {
    handleClickViewAllAgents(): void {
      console.log('View all agents clicked');
    },
  },
  computed: {
    showViewAllAgentsButton() {
      return this.loaded && !this.showEmptyStateSkeleton;
    },
    showSkeleton() {
      return !this.loaded;
    },
    showEmptyStateSkeleton() {
      return this.users.length === 0 && this.loaded;
    },
  },
});
</script>

<style scoped lang="scss" src="../assets/skeleton.scss" />
