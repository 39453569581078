<template>
  <div class="flex w-full flex-row justify-between">
    <div class="flex w-full flex-row">
      <div class="relative">
        <div :class="animationClass" class="mr-2 h-10 w-10 rounded-full bg-grey-300" />
        <div
          :class="animationClass"
          class="absolute bottom-0 right-0 mr-2 h-3 w-3 rounded-full border border-white bg-grey-300"
        />
      </div>
      <div class="flex flex-col justify-center">
        <div :class="animationClass" class="h-2 w-88px rounded-3xl bg-grey-300" />
        <div :class="animationClass" class="mt-1 h-2 w-128px rounded-3xl bg-grey-300" />
      </div>
    </div>
    <div class="flex flex-row">
      <div :class="animationClass" class="h-10 w-10 rounded-lg bg-grey-300" />
      <div :class="animationClass" class="ml-2 h-10 w-10 rounded-lg bg-grey-300" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OnlineStatusUserItemSkeleton',
  props: {
    animation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    animationClass() {
      return this.animation ? 'animation-loading' : '';
    },
  },
});
</script>

<style scoped lang="scss" src="../assets/skeleton.scss" />
