import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_title = _resolveComponent("page-title")!
  const _component_hero = _resolveComponent("hero")!
  const _component_filters = _resolveComponent("filters")!
  const _component_report = _resolveComponent("report")!
  const _component_page_container = _resolveComponent("page-container")!
  const _component_mobile_status = _resolveComponent("mobile-status")!

  return (!$options.isMobile)
    ? (_openBlock(), _createBlock(_component_page_container, {
        key: 0,
        class: "flex h-auto flex-col overflow-auto rounded-xl border-1 border-grey-200 bg-white"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_hero, { class: "mb-4 mt-8 xl2:mx-3" }, {
            top: _withCtx(() => [
              _createVNode(_component_page_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('reports.csat_title')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_filters, {
            modelValue: $data.filters,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.filters) = $event)),
            "show-rating": true,
            "show-survey": true,
            "compact-style": true
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createVNode(_component_report, {
            report: "csats",
            filters: $data.filters,
            metrics: ['average_rating', 'surveys_received']
          }, null, 8 /* PROPS */, ["filters"])
        ]),
        _: 1 /* STABLE */
      }))
    : (_openBlock(), _createBlock(_component_mobile_status, {
        key: 1,
        "data-test": "reporting-mobile"
      }))
}