import { cachedRequest } from '@/util/request';

function getTeams(cacheEnabled = false) {
  return cachedRequest({ method: 'get', url: '/api/v2/teams' }, cacheEnabled);
}
function reMapTeam(data) {
  return data?.map((team) => {
    return { ...team, isDisabled: team.load_balancing_enabled };
  });
}

export default {
  namespaced: true,
  state: {
    teams: [],
  },
  mutations: {
    setTeams(state, payload) {
      state.teams = payload;
    },
  },
  actions: {
    updateTeams({ commit }) {
      getTeams().then((res) => {
        const teams = reMapTeam(res.data?.data);
        commit('setTeams', teams);
      });
    },
    fetchTeams({ commit, state }) {
      if (state.teams.length === 0) {
        getTeams().then((res) => {
          const teams = reMapTeam(res.data?.data);
          commit('setTeams', teams);
        });
      } else {
        commit('setTeams', state.teams);
      }
    },
  },
  getters: {
    teamsList(state) {
      return state.teams;
    },
  },
};
