import { request } from '@/util';

import type {
  ConversationsVolumeResponse,
  WorkloadManagementAggregatesResponse,
} from '@/components/Reporting/api/types';
import type { AxiosPromise } from 'axios';
const baseURL = '/api/v2/reporting/';
export const fetchCreatedConversations = (
  startDate: string,
  endDate: string,
  interval: string,
  filter: string
): AxiosPromise<ConversationsVolumeResponse> => {
  const encodedStartDate = encodeURIComponent(startDate);
  const encodedEndDate = encodeURIComponent(endDate);

  const URL = `${baseURL}histograms?metric=created_tickets&interval=${interval}&start_date=${encodedStartDate}&end_date=${encodedEndDate}${
    filter ? '&' + filter : ''
  }`;

  return request(URL, 'GET');
};

export const fetchClosedConversations = (
  startDate: string,
  endDate: string,
  interval: string,
  filter: string
): AxiosPromise<ConversationsVolumeResponse> => {
  const encodedStartDate = encodeURIComponent(startDate);
  const encodedEndDate = encodeURIComponent(endDate);

  const URL = `${baseURL}histograms?metric=closed_tickets&interval=${interval}&start_date=${encodedStartDate}&end_date=${encodedEndDate}${
    filter ? '&' + filter : ''
  }`;

  return request(URL, 'GET');
};

export const fetchWorkloadAggregates = (
  startDate: string,
  endDate: string,
  filter: string
): AxiosPromise<WorkloadManagementAggregatesResponse> => {
  const encodedStartDate = encodeURIComponent(startDate);
  const encodedEndDate = encodeURIComponent(endDate);

  const URL = `${baseURL}metrics?metric[]=created_tickets&metric[]=closed_tickets&metric[]=reopened_tickets&metric[]=average_total_resolution_time&metric[]=average_first_response_time&start_date=${encodedStartDate}&end_date=${encodedEndDate}${
    filter ? '&' + filter : ''
  }`;

  return request(URL, 'GET');
};

export const fetchAverageCreatedConversations = (
  startDate: string,
  endDate: string,
  interval: string,
  filter: string
): AxiosPromise<ConversationsVolumeResponse> => {
  const encodedStartDate = encodeURIComponent(startDate);
  const encodedEndDate = encodeURIComponent(endDate);

  const URL = `${baseURL}histograms?metric=average_tickets_created&interval=${interval}&start_date=${encodedStartDate}&end_date=${encodedEndDate}${
    filter ? '&' + filter : ''
  }`;
  return request(URL, 'GET');
};
