import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { CHANNEL_TYPE } from '@/Configs/Constants';

type ChannelAsset = {
  title: string;
  icon: string;
};

export type ChannelAssets = {
  [key in keyof typeof CHANNEL_TYPE]: ChannelAsset;
};

export const useChannelTypes = () => {
  const { t } = useI18n();

  const channelAssets = computed(() => {
    return {
      [CHANNEL_TYPE.EMAIL]: {
        title: t('channels.channel_EMAIL'),
        icon: `${Assets_path}img/channels_icons/no-bg/email.svg`,
      },
      [CHANNEL_TYPE.SMS]: {
        title: t('channels.channel_SMS'),
        icon: `${Assets_path}img/channels_icons/no-bg/sms.svg`,
      },
      [CHANNEL_TYPE.CHAT]: {
        title: t('channels.channel_CHAT'),
        icon: `${Assets_path}img/channels_icons/no-bg/chat.svg`,
      },
      [CHANNEL_TYPE.WHATSAPP]: {
        title: t('channels.channel_WHATSAPP'),
        icon: `${Assets_path}img/channels_icons/no-bg/whatsapp.svg`,
      },
      [CHANNEL_TYPE.WA_BUSINESS]: {
        title: t('channels.channel_WHATSAPP'),
        icon: `${Assets_path}img/channels_icons/no-bg/whatsapp.svg`,
      },
      [CHANNEL_TYPE.TELEGRAM]: {
        title: t('channels.channel_TELEGRAM'),
        icon: `${Assets_path}img/channels_icons/no-bg/telegram.svg`,
      },
      [CHANNEL_TYPE.VOIP]: {
        title: t('channels.channel_VOIP'),
        icon: `${Assets_path}img/channels_icons/no-bg/voip.svg`,
      },
      [CHANNEL_TYPE.FACEBOOK]: {
        title: t('channels.channel_FACEBOOK'),
        icon: `${Assets_path}img/channels_icons/no-bg/facebook.svg`,
      },
      [CHANNEL_TYPE.HELP_CENTER]: {
        title: t('admin_inboxes.help_center_title'),
        icon: `${Assets_path}img/channels_icons/no-bg/help_center.svg`,
      },
      [CHANNEL_TYPE.CUSTOM]: {
        title: t('custom_channel.custom_channel'),
        icon: `${Assets_path}img/channels_icons/no-bg/help_center.svg`,
      },
      [CHANNEL_TYPE.INSTAGRAM]: {
        title: t('instagram.instagram'),
        icon: `${Assets_path}img/channels_icons/no-bg/instagram.svg`,
      },
      [CHANNEL_TYPE.GBM]: {
        title: t('admin_inboxes.navigation_google_business'),
        icon: `${Assets_path}img/channels_icons/no-bg/help_center.svg`,
      },
    };
  });

  return { channelAssets };
};
