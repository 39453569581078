<template>
  <div>
    <div class="t-text-desktop-paragraph-md mb-6 text-grey-700">
      {{ $t('team.workload_balancing_description') }}
    </div>
    <div v-if="channelIsConnectedToTeam" class="mb-6">
      <t-inline-banner type="warning">
        <template #icon>
          <alert-triangle-linear />
        </template>
        <span>{{ $t('team.each_channel_can_have_one_workload_balancing_team_hint') }}</span>
        <span v-html="textByTeamsCount" />
        <a
          target="_blank"
          href="https://help.trengo.com/en/articles/329579-workload-balancing-explained"
          class="open-external underline"
        >
          {{ $t('team.learn_more') }}
        </a>
      </t-inline-banner>
    </div>
    <team-table :is-empty="!channels.length">
      <template #head>
        <div class="t-text-desktop-label-xs m-0 p-0 text-grey-800">{{ $t('team.channels_title') }}</div>
      </template>

      <template #empty>
        <div data-test="team-members-empty" class="p-6 sm:my-1 sm:px-8 sm:py-6">
          <div class="flex flex-col items-center justify-center">
            <div class="t-text-desktop-h7 text-center text-grey-800">{{ $t('team.no_channels_added_title') }}</div>
            <div class="t-text-desktop-paragraph-md mt-1 text-center text-grey-700">
              {{ $t('team.no_access_to_channels_error') }}
            </div>
          </div>
        </div>
      </template>

      <template #rows>
        <div>
          <table-row
            v-for="channel in channels"
            :key="channel.id"
            data-test="team-channels-row"
            :is-last="isTheLastOne(channel)"
          >
            <channel-row
              team-is-enabled
              data-test="team-members-channel-row"
              remove-type="button"
              :channel="channel"
              @remove="handleRemoveChannel"
            />
          </table-row>
        </div>
      </template>
    </team-table>
  </div>
</template>

<script>
import { AlertTriangleLinear } from '@trengo/trengo-icons';
import { last } from 'lodash';

import { TableRow, TeamTable } from '@/components/Teams/components';
import ChannelRow from '@/components/Teams/components/ChannelRow';

export default {
  name: 'ChannelItemsList',
  emits: ['remove'],
  components: { AlertTriangleLinear, ChannelRow, TableRow, TeamTable },
  props: {
    channels: {
      type: Array,
      default: () => [],
    },
    team: {
      type: String,
      default: '',
    },
    channelIsConnectedToTeam: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textByTeamsCount() {
      const teamName = this.getChannelsWorkloadBalancingTeams[0];
      const textForOneTeam = this.$t('team.team_has_workload_balancing_enabled_description', {
        existingTeam: teamName,
        newTeam: this.team,
      });
      const textForMultipleTeam = this.$t('team.multiple_teams_have_workload_balancing_enabled_description', {
        newTeam: this.team,
      });
      return this.getChannelsWorkloadBalancingTeams?.length > 1 ? textForMultipleTeam : textForOneTeam;
    },
    getChannelsWorkloadBalancingTeams() {
      return this.channels
        .filter((item) => {
          return item.workloadBalancingTeams?.length > 0;
        })
        .map((item) => {
          return item.workloadBalancingTeams?.[0]?.name;
        });
    },
  },
  methods: {
    isTheLastOne(channel) {
      return last(this.channels)?.id === channel.id;
    },
    handleRemoveChannel(channel) {
      this.$emit('remove', channel);
    },
  },
};
</script>

<style lang="scss" src="./ChannelItemsList.scss" scoped />
