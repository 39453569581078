<template>
  <component :is="icon" v-bind="{ width: `${width}px`, height: `${width}px` }" data-test="integration-card-icon" />
</template>

<script lang="ts">
import {
  LogoAct,
  LogoActivecampaign,
  LogoBigCommerce,
  LogoBol,
  LogoClose,
  LogoCopper,
  LogoFlexmail,
  LogoGoogleContact,
  LogoHubspot,
  LogoLightspeed,
  LogoMagento,
  LogoMicrosoftDynamic,
  LogoMollie,
  LogoPicqer,
  LogoPipedrive,
  LogoPrestashop,
  LogoSalesflare,
  LogoSalesforce,
  LogoShopify,
  LogoShopware,
  LogoTeamlead,
  LogoWix,
  LogoWoo,
  LogoZendesk,
  LogoZoho,
} from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import type { Integration } from '@/store/types/integrations';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'IntegrationCardIcon',

  components: {
    LogoAct,
    LogoActivecampaign,
    LogoBigCommerce,
    LogoBol,
    LogoClose,
    LogoCopper,
    LogoFlexmail,
    LogoGoogleContact,
    LogoHubspot,
    LogoLightspeed,
    LogoMagento,
    LogoMicrosoftDynamic,
    LogoMollie,
    LogoPicqer,
    LogoPipedrive,
    LogoPrestashop,
    LogoSalesflare,
    LogoSalesforce,
    LogoShopify,
    LogoShopware,
    LogoTeamlead,
    LogoWix,
    LogoWoo,
    LogoZendesk,
    LogoZoho,
  },

  props: {
    icon: {
      type: String as PropType<Integration['icon']>,
      default: '',
    },
    width: {
      type: Number,
      default: 48,
    },
  },
});
</script>
