<template>
  <div class="mt-6 flex flex-col">
    <div :class="{ 'mb-6': !errors.url }">
      <t-input-text
        id="magentoUrl"
        v-model="$props.modelValue.url"
        size="sm"
        :label="$tc('integrations.magento1_label_your_shop_url')"
        :sub-label="$tc('integrations.magento1_subtitle_your_shop_url')"
        placeholder="https://example.com"
        @blur="checkUrl"
      />
      <t-error-item v-if="errors.url" class="mb-4" :text="$tc('integration_hub.error_url_must_be_valid')" danger-icon />
    </div>

    <div :class="{ 'mb-6': !errors.adminUrl }">
      <t-input-text
        id="magentoAdminUrl"
        v-model="$props.modelValue.admin_url"
        size="sm"
        :label="$tc('integrations.magento1_label_shop_admin_url')"
        :sub-label="$tc('integrations.magento1_subtitle_shop_admin_url')"
        placeholder="https://example.com/admin"
        @blur="checkAdminUrl"
      />
      <t-error-item
        v-if="errors.adminUrl"
        class="mb-4"
        :text="$tc('integration_hub.error_url_must_be_valid')"
        danger-icon
      />
    </div>
    <div :class="{ 'mb-6': !errors.apiKey }">
      <t-input-text
        id="magentoApiKey"
        v-model="$props.modelValue.api_key"
        size="sm"
        :label="$tc('integrations.magento1_label_soap_username')"
        :sub-label="$tc('integrations.magento1_subtitle_soap_username')"
        placeholder="JohnDoe"
        @blur="checkApiKey"
      />
      <t-error-item
        v-if="errors.apiKey"
        class="mb-4"
        :text="$tc('integration_hub.error_soap_username_must_be_filled_out')"
        danger-icon
      />
    </div>

    <t-input-text
      id="magentoApiPassword"
      v-model="$props.modelValue.api_password"
      type="password"
      size="sm"
      :label="$tc('integrations.magento1_label_soap_api_key')"
      :sub-label="$tc('integrations.magento1_subtitle_soap_api_key')"
      @blur="checkApiPassword"
    />
    <t-error-item
      v-if="errors.apiPassword"
      class="mb-4"
      :text="$tc('integration_hub.error_soap_password_must_be_filled_out')"
      danger-icon
    />
  </div>
</template>

<script setup lang="ts">
import validUrlLibrary from 'valid-url';
import { ref } from 'vue';

import type { CustomMagentoIntegration } from '@/store/types/integrations';

const props = defineProps<{ modelValue: CustomMagentoIntegration['meta'] }>();
const emit = defineEmits(['on-validated', 'on-invalidated']);

const errors = ref({ url: false, adminUrl: false, apiKey: false, apiPassword: false });

function validateFields() {
  checkUrl();
  checkAdminUrl();
  checkApiKey();
  checkApiPassword();

  Object.values(errors.value).some((item) => item) ? emit('on-invalidated') : emit('on-validated');
}

defineExpose({ validateFields });

function checkUrl() {
  errors.value.url = !props.modelValue.url || !validUrlLibrary.isUri(props.modelValue.url);
}

function checkAdminUrl() {
  errors.value.adminUrl = !props.modelValue.admin_url || !validUrlLibrary.isUri(props.modelValue.admin_url);
}

function checkApiKey() {
  errors.value.apiKey = !props.modelValue.api_key || props.modelValue.api_key === '';
}

function checkApiPassword() {
  errors.value.apiPassword = !props.modelValue.api_password || props.modelValue.api_password === '';
}
</script>
