import { defineStore } from 'pinia';

import { SETTINGS_TRACKING_NAVIGATION_ITEMS } from '@/util/analytics/settingsTrackingNavigationItems';

import type { RoutesStore } from './types';
import type { NavigationGroupItem, NavigationItem } from '@/types/navigation';

/* Utils */
const isValidPath = (path: string): boolean => {
  return typeof path === 'string' && path !== '' && path.includes('/');
};

const getSettingsBaseRoute = (currentRoutePath: string, activeSettingsUrl: string) => {
  if (!currentRoutePath || !activeSettingsUrl) return;
  return currentRoutePath.startsWith(activeSettingsUrl) ? activeSettingsUrl : '';
};

export const useRoutesStore = defineStore('routes', {
  state: (): RoutesStore => ({
    activeSettingsBaseRoutePath: '',
    activeSettingsFeatureName: '',
    activeSettingsNavigationGroupName: '',
    currentRoutePath: '',
    isAdminRoute: false,
    lastRoutePath: '',
  }),

  actions: {
    setLastRoutePath(path: string): void {
      if (!isValidPath(path)) {
        console.error('The given path is an Invalid path');
        return;
      }

      this.lastRoutePath = path;
    },

    setCurrentRoutePath(path: string): void {
      if (!isValidPath(path)) {
        console.error('The given path is an Invalid path');
        return;
      }
      this.currentRoutePath = path;
    },
    setActiveSettingsBaseRoutePath(): void {
      if (!isValidPath(this.currentRoutePath)) return;
      const navigationItem: NavigationItem | undefined = SETTINGS_TRACKING_NAVIGATION_ITEMS.map((group) => group.items)
        .flat()
        .find((menuItem) => menuItem.url === getSettingsBaseRoute(this.currentRoutePath, menuItem.url));

      this.activeSettingsBaseRoutePath = navigationItem?.url || '';
    },
    setActiveSettingsNavigationFeatureName(): void {
      if (!this.activeSettingsBaseRoutePath) return;
      const navigationItem: NavigationItem | undefined = SETTINGS_TRACKING_NAVIGATION_ITEMS.map((group) => group.items)
        .flat()
        .find((menuItem) => menuItem.url === this.activeSettingsBaseRoutePath);

      this.activeSettingsFeatureName = navigationItem?.trackingId || '';
    },

    setActiveSettingsNavigationGroupName(): void {
      if (!this.activeSettingsBaseRoutePath) return;
      const navigationGroup: NavigationGroupItem | undefined = SETTINGS_TRACKING_NAVIGATION_ITEMS.find((group) =>
        group.items.find((menuItem) => menuItem.url === this.activeSettingsBaseRoutePath)
      );

      this.activeSettingsNavigationGroupName = navigationGroup?.trackingGroupId || '';
    },
    setIsAdminRoute(isAdminRoute: boolean): void {
      if (!isAdminRoute) return;
      this.isAdminRoute = isAdminRoute;
    },
  },
  getters: {
    getLastRoutePath: (state): string | null => state.lastRoutePath,

    getCurrentRoutePath: (state): string | null => state.currentRoutePath,

    getActiveSettingsFeatureName: (state): string => state.activeSettingsFeatureName,

    getActiveSettingsNavigationGroupName: (state): string => state.activeSettingsNavigationGroupName,

    getIsAdminRoute: (state): boolean => state.isAdminRoute,
  },
});
