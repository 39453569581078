import { request } from '@/util';

import type { AxiosResponse } from 'axios';

const base_url = '/api/v2/';

export function putToggleSeen(mention_id: number, mention_seen: boolean): void {
  request(`${base_url}ticket_mentions/${mention_id}`, 'PUT', { seen: mention_seen });
}

export async function deleteTicketMessage(ticket_id: number, message_id: number): Promise<AxiosResponse<unknown>> {
  return request(`${base_url}tickets/${ticket_id}/messages/${message_id}`, 'DELETE');
}
