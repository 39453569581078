<template>
  <div class="rounded-xl p-3" style="background-color: rgb(247, 247, 247)">
    <div v-if="element.meta.client_name" class="mx-2 flex">
      <div class="w-1/6">
        <i class="material-icons text-success text-xxl">insert_drive_file</i>
      </div>
      <div class="ml-1 w-2/3">
        <div class="text-success text-ellipsis">{{ element.meta.client_name }}</div>
        <div class="text-grey-600">{{ element.meta.file_size_human }}</div>
      </div>
      <div class="w-1/6 text-right text-grey-600">
        <a :href="downloadPath" target="_blank"><i class="material-icons flow-file-download">file_download</i></a>
      </div>
    </div>

    <div v-else class="px-2 text-grey-600">
      {{ $t('flowbot.step_presenter_file_click') }}
      <i class="material-icons text-sm">create</i>
      {{ $t('flowbot.step_presenter_file_to_edit_this_file') }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['element'],

  computed: {
    downloadPath() {
      return CDN + this.element.meta.file_path;
    },
  },
};
</script>
<style>
.flow-file-download {
  line-height: 2.8rem;
  font-size: 2rem;
  text-align: end;
}
.text-xxl {
  font-size: 2.5rem !important;
}
</style>
