import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { "data-test": "sidebar-infobox-dropdown" }
const _hoisted_2 = {
  key: 0,
  "aria-hidden": "true",
  class: "my-1 w-full border-b border-grey-300 border-opacity-30"
}
const _hoisted_3 = {
  key: 1,
  "aria-hidden": "true",
  class: "my-1 w-full border-b border-grey-300 border-opacity-30"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_list_item = _resolveComponent("t-list-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userDataList, (item) => {
      return (_openBlock(), _createBlock(_component_t_list_item, {
        key: item.emit,
        label: item.label,
        size: "sm",
        "data-test": "sidebar-infobox-user-datalist",
        onClick: ($event: any) => (_ctx.$emit(item.emit))
      }, {
        prefix: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), { size: "1.25rem" }))
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "onClick"]))
    }), 128 /* KEYED_FRAGMENT */)),
    (_ctx.hasEmail)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emailDataList, (item) => {
      return (_openBlock(), _createBlock(_component_t_list_item, {
        key: item.emit,
        label: item.label,
        size: "sm",
        "data-test": "sidebar-infobox-email-datalist",
        onClick: ($event: any) => (_ctx.$emit(item.emit))
      }, {
        prefix: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), { size: "1.25rem" }))
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "onClick"]))
    }), 128 /* KEYED_FRAGMENT */)),
    (_ctx.hasPhone)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phoneDataList, (item) => {
      return (_openBlock(), _createBlock(_component_t_list_item, {
        key: item.emit,
        label: item.label,
        size: "sm",
        "data-test": "sidebar-infobox-phone-datalist",
        onClick: ($event: any) => (_ctx.$emit(item.emit))
      }, {
        prefix: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), { size: "1.25rem" }))
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "onClick"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}