import { mapStores } from 'pinia';

import { PERMISSION } from '@/Configs/Constants';
import { USER_ROLE, ADMIN_ROLES } from '@/Configs/Constants/UserRoles';
import { useUserStore } from '@/store/pinia';

const { TRENGO, OBSERVER } = USER_ROLE;

export default {
  computed: {
    ...mapStores(useUserStore),
    agencyOnlyHasOneAdministrator() {
      return this.$root.users.filter((user) => this.hasAdminRole(user)).length <= 1;
    },
    isOnlyAdministrator() {
      return this.hasAdminRole(this.user) && this.agencyOnlyHasOneAdministrator;
    },
    isOnlyPrimaryUser() {
      const allPrimaryUsers = this.$root.users.filter((user) => user.is_primary);

      return !!this.user.is_primary && allPrimaryUsers.length <= 1;
    },
    isAdministrator() {
      return ADMIN_ROLES.includes(this.currentRole);
    },
    currentRole() {
      return this.getRoleForUser(this.user);
    },
    canDeleteUser() {
      // You can not delete yourself
      if (this.user.id === this.$root.user.id) {
        return false;
      }

      // You can not delete a user with an "admin role" when you don't have permission to assign this role
      if (this.isAdministrator && !this.userStore.hasPermission(PERMISSION.PERMISSIONS__USERS__ASSIGN_ADMIN_ROLE)) {
        return false;
      }

      if (this.isOnlyAdministrator) {
        // You can not delete a user with an "admin role" when he's the only one
        return false;
      }

      // You can not delete the only primary user
      if (this.isOnlyPrimaryUser) {
        return false;
      }

      return true;
    },
  },
  methods: {
    roles(allowObserver) {
      const roles = Object.values(USER_ROLE);
      return roles.flatMap((role) => {
        // Hide "admin" roles when you're not authorized to assign it
        if (
          ADMIN_ROLES.includes(role) &&
          !this.userStore.hasPermission(PERMISSION.PERMISSIONS__USERS__ASSIGN_ADMIN_ROLE)
        ) {
          return [];
        }

        // Hide "trengo" roles for all agencies, except Trengo
        if (role === TRENGO && this.$root.user.agency_id !== 1) {
          return [];
        }

        // Hide Observer as you can not demote a user to this role, unless we're adding a new user
        const currentUser = this.duplicatedUserRecord || this.user;
        const currentRole = this.getRoleForUser(currentUser);

        if (!allowObserver && this.isObserver(role) && !this.isObserver(currentRole)) {
          return [];
        }

        // Hide Observer if you can not manage subscription since this is a paid add-on
        if (this.isObserver(role) && !this.userStore.hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)) {
          return [];
        }

        return [
          {
            value: role,
            title: this.translateRole(role),
            subtitle: this.translateRoleDescription(role),
            isDisabled: !this.isAssignable(role),
          },
        ];
      });
    },
    translateRole(role) {
      return this.$t(`user_management.${role.toLowerCase()}`);
    },
    translateRoleDescription(role) {
      return this.$t(`user_management.${role.toLowerCase()}_description`);
    },
    isObserver(role) {
      return role === OBSERVER;
    },
    isAssignable(role) {
      // You can not assign the same role
      if (role === this.currentRole) {
        return false;
      }

      return true;
    },
    getRoleForUser(user) {
      return user.role.name;
    },
    hasAdminRole(user) {
      return ADMIN_ROLES.includes(this.getRoleForUser(user));
    },
  },
};
