<template>
  <div class="row-col">
    <div>
      <span class="text-md p-y-1 white p-x-1 b-b hidden-md-up text-ellipsis">
        <a data-toggle="modal" data-target="#settings-third" class="pull-left">
          <span class="btn btn-sm btn-icon white m-r-1">
            <i class="fa fa-list"></i>
          </span>
        </a>
        {{ $t('company_profile.subscription_page_business_hours') }}
      </span>
    </div>
    <div class="row-col">
      <div class="row-col">
        <div id="settings-third" class="col-xs modal fade aside aside-md b-r w-medium w-200px">
          <div class="nav-settings light left b-primary row-col">
            <ul>
              <li class="text-md white header">
                <strong>{{ $t('company_profile.subscription_page_business_hours') }}</strong>
                <a data-dismiss="modal" class="hidden-md-up text-muted m-l-auto m-r-sm m-l-1 text-lg">×</a>
                <a class="m-l-auto btn btn-icon btn-sm success rounded text-white">
                  <i class="material-icons md-18" @click="$router.push(SETTINGS_URL.ADMIN__BUSINESS_HOURS__CREATE)">
                    add
                  </i>
                </a>
              </li>
            </ul>
            <div class="row-row">
              <div class="row-body scrollable hove">
                <div class="row-inner">
                  <ul>
                    <li v-for="item in businessHours" :key="item.id">
                      <router-link
                        class="nav-settings-link text-truncate"
                        data-dismiss="modal"
                        :to="`${SETTINGS_URL.ADMIN__BUSINESS_HOURS}/${item.id}`"
                        :class="{ active: $route.params.id === item.id }"
                      >
                        {{ item.name }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-col">
          <router-view v-if="loaded" :key="$route.params.id"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import eventBus from '@/eventBus';
export default {
  name: 'BusinessHoursIndex',

  data() {
    return {
      SETTINGS_URL,
      loaded: false,
    };
  },

  computed: {
    ...mapGetters({
      businessHours: 'business_hours/businessHours',
    }),
  },

  mounted() {
    this.loadList(false);
    eventBus.$on('load_business_hours', () => {
      this.loadList();
    });
  },

  methods: {
    async loadList(reloadRoot = true) {
      this.$store.dispatch('business_hours/fetchBusinessHours').then(() => {
        this.loaded = true;

        if (reloadRoot) {
          this.$root.business_hours = this.business_hours;
        }
      });
    },
  },
};
</script>
