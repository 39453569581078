<template>
  <div>
    <div class="mb-2 mt-4 grid grid-cols-1 gap-2 sm:mt-0 lg:grid-cols-3">
      <modal-item
        id="wa_business"
        :name="$t('getting_started.title_whatsapp')"
        type="whatsapp"
        :icon="getChannelIcon(CHANNEL_TYPE.WHATSAPP)"
      />
      <modal-item
        id="email"
        :name="$t('getting_started.title_email')"
        type="email"
        :icon="getChannelIcon(CHANNEL_TYPE.EMAIL)"
      />
      <modal-item
        id="chat"
        :name="$t('getting_started.title_live_chat')"
        type="chat"
        :icon="getChannelIcon(CHANNEL_TYPE.CHAT)"
      />
      <modal-item
        id="facebook"
        :name="$t('getting_started.title_facebook')"
        type="messaging"
        :icon="getChannelIcon(CHANNEL_TYPE.FACEBOOK)"
      />
      <modal-item
        id="instagram"
        :name="$t('getting_started.title_instagram')"
        type="messaging"
        :icon="getChannelIcon(CHANNEL_TYPE.INSTAGRAM)"
      />
      <modal-item
        id="voip"
        :name="$t('getting_started.title_voice')"
        type="voice"
        :icon="getChannelIcon(CHANNEL_TYPE.VOIP)"
      />
      <modal-item
        id="sms"
        :name="$t('getting_started.title_sms')"
        type="messaging"
        :icon="getChannelIcon(CHANNEL_TYPE.SMS)"
      />
      <modal-item
        id="custom"
        :name="$t('getting_started.title_custom')"
        type="messaging"
        :icon="getChannelIcon(CHANNEL_TYPE.CUSTOM)"
      />
      <modal-item
        id="telegram"
        :name="$t('getting_started.title_telegram')"
        type="messaging"
        :icon="getChannelIcon(CHANNEL_TYPE.TELEGRAM)"
      />
      <modal-item
        v-if="!isHideGbmEnabled"
        id="gbm"
        :name="$t('getting_started.title_google_business')"
        type="messaging"
        :icon="getChannelIcon(CHANNEL_TYPE.GBM)"
      />
      <modal-item
        v-if="userStore.hasPermission(PERMISSION.HELP_CENTER__GENERAL__MANAGE)"
        id="help_center"
        :name="$t('getting_started.title_help_center')"
        type="help_center"
        :icon="getChannelIcon(CHANNEL_TYPE.HELP_CENTER)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import { CHANNEL_TYPE, FEATURE_FLAG_CHANNELS, PERMISSION } from '@/Configs/Constants';
import { useFeatureFlagStore, useUserStore } from '@/store/pinia';

import ModalItem from '../../ModalItem';

export default defineComponent({
  name: 'ChannelsList',
  components: { ModalItem },
  data() {
    return {
      CHANNEL_TYPE,
      PERMISSION,
    };
  },
  methods: {
    getChannelIcon(channelType) {
      return `${this.$root.assetsURL}img/channels_icons/no-bg/${channelType.toLowerCase()}.svg`;
    },
  },
  computed: {
    ...mapStores(useUserStore),
    isHideGbmEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_CHANNELS.TC_HIDE_GBM);
    },
  },
});
</script>
