<template>
  <input ref="input" type="text" />
</template>

<script type="text/babel">
export default {
  name: 'DateTimePicker',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      default: 'Y-m-d H:i:s',
    },
  },
  mounted() {
    let t = this;
    $(this.$refs.input)
      .val(this.modelValue)
      .datetimepicker({ timepicker: true, format: this.format || 'Y-m-d H:i:s' })
      .on('change', function () {
        t.$emit('update:modelValue', $(this).val());
      });
  },
};
</script>
