<template>
  <div data-test="sidebar-watcher-display-wrapper">
    <h2 class="t-text-sm t-text-grey-800">{{ $tc('tickets.watchers') }}</h2>

    <t-button
      v-if="ticket.watchers.length < 1"
      class="flex w-fit flex-row items-center"
      btn-style="secondary"
      data-test="watcher-subscribe-button"
      @click="watchTicket"
    >
      <bell-linear class="mr-2" size="1.1rem" />
      <p class="t-text-sm m-0 p-0">
        {{ $t('ticket_sidebar.receive_notifications') }}
      </p>
    </t-button>

    <t-button v-else class="flex w-fit flex-row items-center active:!bg-grey-200" btn-style="secondary">
      <button
        class="-ml-1 mr-2 rounded-full p-1 transition-colors hover:bg-grey-300 active:bg-grey-400"
        data-test="unwatch-ticket-button"
        @click.prevent="unwatchTicket"
      >
        <bell-linear class="text-leaf-600" size="1.1rem" />
      </button>

      <button
        class="flex-inline flex flex-row rounded-full px-2 py-1 transition-colors hover:bg-grey-300 active:bg-grey-400"
        data-test="show-watchers-button"
        @click.prevent="showWatchers = !showWatchers"
      >
        <span
          v-for="(watcher, index) in ticket.watchers"
          :key="watcher.id"
          class="whitespace-nowrap"
          data-test="watcher-list-item"
        >
          <avatar
            v-if="index < 6"
            class="-ml-1 first:ml-0"
            width="25"
            :color="watcher.color"
            :abbr="watcher.abbr"
            :image="watcher.profile_image"
          />
        </span>

        <span v-if="ticket.watchers.length > 6">
          <avatar class="-ml-1.5" width="25" color="#000000" :abbr="`+${ticket.watchers.length - 6}`" />
        </span>
      </button>
    </t-button>

    <div v-if="showWatchers && ticket.watchers.length > 0" class="absolute flex flex-col">
      <ul
        class="
          relative
          flex
          max-h-48
          cursor-pointer
          list-none
          flex-col
          overflow-y-auto
          rounded-lg
          bg-white
          p-4
          shadow-md
          transition-colors
          hover:bg-grey-200
          active:bg-grey-300
        "
        style="bottom: 3rem; left: 7.5rem"
        data-test="hide-watchers-button"
        @click.prevent="showWatchers = false"
      >
        <li v-for="watcher in ticket.watchers" :key="watcher.id" class="mb-3 select-none whitespace-nowrap last:mb-0">
          <avatar width="25" class="mr-2" :color="watcher.color" :abbr="watcher.abbr" :image="watcher.profile_image" />
          {{ watcher.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { BellLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import Avatar from '@/components/Avatar.vue';
import { assignWatcher, unassignWatcher } from '@/components/TicketSidebar/Api/api';

import type Tickets from '@/types/tickets';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'WatcherDisplay',
  components: { BellLinear, Avatar },

  props: {
    ticket: {
      type: Object as PropType<Tickets>,
      default: () => ({}),
    },
  },

  data() {
    return {
      showWatchers: false,
    };
  },

  methods: {
    async watchTicket() {
      if (!this.ticket.agent.id) {
        this.flashError("Can't watch unassgined ticket");
      }

      try {
        await assignWatcher(this.ticket.id).then(() => {
          this.ticket.watchers.push(this.$root.user);
        });
      } catch (err: any) {
        console.error(err);
        this.flashError(err?.message);
      }
    },

    async unwatchTicket() {
      try {
        await unassignWatcher(this.ticket.id).then(() => {
          this.ticket.watchers = this.ticket.watchers.filter((watcher) => watcher.id !== this.$root.user.id);
        });
      } catch (err: any) {
        console.error(err);
        this.flashError(err?.message);
      }
    },
  },

  beforeUnmount() {
    this.showWatchers = false;
  },
});
</script>
