<template>
  <t-modal :model-value="true" :is-closable="false" :title="$t('user_management.reload_trengo_title')" variant="narrow">
    <p>
      {{ $t('user_management.reload_trengo_body') }}
    </p>
    <template #footer>
      <t-button btn-style="primary" @click="handleClick">
        {{ $t('user_management.reload_trengo_cta') }}
      </t-button>
    </template>
  </t-modal>
</template>

<script>
export default {
  name: 'PermissionsChangedModal',
  methods: {
    handleClick() {
      window.location.reload();
    },
  },
};
</script>
