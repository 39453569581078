<template>
  <Transition name="fade">
    <t-modal
      v-model="suggestedReplyStore.modal.isModalOpen"
      variant="narrow"
      @close="suggestedReplyStore.modal.closeModal"
    >
      <template #header>
        <div class="-m-8 mb-1 flex overflow-hidden rounded-t-3xl">
          <TransitionGroup name="fade" mode="out-in">
            <img
              key="img"
              loading="lazy"
              :src="imgPath"
              alt=""
              class="max-h-64 w-full object-cover"
              :close-on-backdrop-click="true"
              @load="isImgLoaded = true"
            />
            <div v-if="!isImgLoaded" key="loader" class="min-h-64 min-w-full rounded-t-3xl bg-grey-200">
              <span class="h-10 w-10 animate-pulse rounded-full bg-grey-800" />
            </div>
          </TransitionGroup>
        </div>
      </template>
      <section>
        <h3 class="t-text-h3">
          {{ $t('tickets.suggest_reply_modal_title') }}
        </h3>
        <p class="mt-2 text-grey-700">
          {{ $t('tickets.suggest_reply_modal_description') }}
          <br />
          <br />
          {{ $t('tickets.suggest_reply_modal_details') }}
        </p>
        <div class="mt-8 flex justify-end">
          <t-button btn-style="secondary" @click="suggestedReplyStore.modal.closeModal">
            {{ $t('tickets.suggest_reply_modal_button_dismiss') }}
          </t-button>
        </div>
      </section>
    </t-modal>
  </Transition>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { useSuggestedReplyStore } from './useSuggestedReplyStore';

const suggestedReplyStore = useSuggestedReplyStore();

const imgPath = ref(`${Assets_path}img/AI/suggested-reply.webp`);
const isImgLoaded = ref(false);
</script>
