<template>
  <div class="overflow-hack flex overflow-x-hidden">
    <t-avatar
      :width="40"
      :color="user.color"
      :abbr="user.abbr"
      :image="user.profile_image"
      :favourite="!!user.is_primary"
    />
    <div class="ml-4 flex flex-col" :class="{ 'mobile-margin': isMobile }">
      <div class="t-text-desktop-label-sm mb-1 text-grey-800">{{ user.first_name }}</div>
      <div class="t-text-sm text-grey-600">{{ user.email }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserInfo',
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped src="./UserInfo.scss" lang="scss" />
