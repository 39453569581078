import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-65666f3d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mr-4 flex flex-col" }
const _hoisted_2 = { class: "work-load-wrapper" }
const _hoisted_3 = { class: "t-text-desktop-label-xs sm:t-text-desktop-label-sm" }
const _hoisted_4 = { class: "sm:t-text-desktop-paragraph-xs t-text-desktop-paragraph-sm text-grey-600" }
const _hoisted_5 = {
  href: "https://help.trengo.com/en/articles/63517-select-or-update-your-trengo-plan",
  class: "underline"
}
const _hoisted_6 = { class: "flex flex-row items-center" }
const _hoisted_7 = { class: "t-text-sm-emphasize mr-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_top_circle_linear = _resolveComponent("arrow-top-circle-linear")!
  const _component_arrow_right_linear = _resolveComponent("arrow-right-linear")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_button_text = _resolveComponent("t-button-text")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_t_inline_banner, { type: "upgrade" }, {
      icon: _withCtx(() => [
        _createVNode(_component_arrow_top_circle_linear, { size: "1.25rem" })
      ]),
      action: _withCtx(() => [
        _createVNode(_component_t_button, {
          class: "twenty-four-hr-banner__btn",
          "btn-style": "secondary",
          size: "sm",
          target: "_blank",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.handleUpgrade()))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('entitlement.upgrade_plan_cta')), 1 /* TEXT */),
              _createVNode(_component_arrow_right_linear, {
                class: "text-grey-700",
                size: 20
              })
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_t_button_text, {
          "btn-style": "secondary",
          class: "ml-2 mt-2 sm:mt-0",
          "data-test": "load-balancing-enable-disable",
          size: "md",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.handleRemoveWorkload()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('team.no_thanks')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('team.balance_workload_effectively')), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createTextVNode(_toDisplayString($options.upgradeText) + " ", 1 /* TEXT */),
            _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.$tc('general.learn_more')), 1 /* TEXT */)
          ])
        ])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}