import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full rounded-xl border border-grey-300 bg-white p-6" }
const _hoisted_2 = { class: "flex flex-row items-center justify-between" }
const _hoisted_3 = { class: "flex flex-row items-center" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "t-text-h5 m-0 text-grey-800" }
const _hoisted_6 = { class: "t-text-sm m-0 flex text-grey-600" }
const _hoisted_7 = {
  "data-test": "overview-chart",
  class: "relative mt-6 h-88"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chart_column_linear = _resolveComponent("chart-column-linear")!
  const _component_t_thumbnail = _resolveComponent("t-thumbnail")!
  const _component_average_ticket_created_loaded = _resolveComponent("average-ticket-created-loaded")!
  const _component_average_ticket_created_loading = _resolveComponent("average-ticket-created-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_t_thumbnail, { class: "mr-3 bg-white" }, {
          default: _withCtx(() => [
            _createVNode(_component_chart_column_linear)
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('reports_v2.conversation_volume_title')), 1 /* TEXT */),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('reports_v2.conversation_volume_subtitle')), 1 /* TEXT */)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.isLoaded)
        ? (_openBlock(), _createBlock(_component_average_ticket_created_loaded, {
            key: 0,
            "conversations-volume-today": _ctx.conversationsVolumeToday,
            "conversations-volume-avg": _ctx.conversationsVolumeAvg,
            "show-error": _ctx.showError
          }, null, 8 /* PROPS */, ["conversations-volume-today", "conversations-volume-avg", "show-error"]))
        : (_openBlock(), _createBlock(_component_average_ticket_created_loading, { key: 1 }))
    ])
  ]))
}