<template>
  <div
    class="flex h-[500px] flex-col items-center justify-center gap-2 rounded-xl border-1 border-grey-300 bg-white px-4"
  >
    <t-thumbnail class="mb-3 bg-white">
      <credit-card-linear size="1rem" />
    </t-thumbnail>
    <div class="t-text-md-emphasize">{{ t(`subscription.${titleKey}`) }}</div>
    <div class="t-text-sm">{{ t(`subscription.${subtitleKey}`) }}</div>
  </div>
</template>

<script lang="ts" setup>
import { CreditCardLinear } from '@trengo/trengo-icons';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  hasQuerterlyBilling: {
    type: Boolean,
  },
});
const { t } = useI18n();

const titleKey = props.hasQuerterlyBilling
  ? 'service_is_down__quarterly_title'
  : 'service_is_down__payment_provider_down_title';
const subtitleKey = props.hasQuerterlyBilling
  ? 'service_is_down__quarterly_subtitle'
  : 'service_is_down__payment_provider_down_subtitle';
</script>
