import type { ChartItem } from '@/types';

export const TRANSACTION_COLORS = [
  '#177B6B',
  '#4FA1C8',
  '#DF694C',
  '#A965D3',
  '#FFD467',
  '#76CCBE',
  '#BBEAFF',
  '#EAC6FF',
];

export const TRANSACTION_BORDER_COLORS = ['#156F60', '#4791B4', '#C95F44', '#985BBE', '#E5BF5D'];

export const TRANSACTION_CHART_ITEM: ChartItem = {
  label: '',
  backgroundColor: '',
  data: [],
  borderColor: 'rgba(0, 0, 0, 0.1)',
  borderWidth: 1,
};
