<template>
  <div :class="[backgroundClass, checkSignColor]" class="color-items">
    <i v-if="checked" class="material-icons text-white">done</i>
  </div>
</template>

<script type="text/babel">
export default {
  name: 'ColorItem',
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    isLight: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return ['teal', 'red', 'blue', 'purple'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      backgroundClass: `color-item--${this.color}`,
      checkSignColor: !this.isLight ? 'text-dark' : 'text-white',
    };
  },
};
</script>

<style scoped lang="scss" src="./SelectableColorPicker.scss" />
