import { defineStore } from 'pinia';

type State = {
  isDownloadInProgress: boolean;
};

export const useTicketInsightsStore = defineStore('ReportingTicketInsightsStore', {
  state: (): State => {
    return {
      isDownloadInProgress: false,
    };
  },
});
