<template>
  <div
    class="
      mx-4
      flex
      cursor-pointer
      flex-row
      justify-between
      rounded-xl
      px-3
      py-3
      text-sm
      hover:bg-grey-100
      focus:bg-grey-100
    "
    @mouseover="showDelete = true"
    @mouseleave="showDelete = false"
    @click="() => handleClick({ value: item.id, text: item.domain })"
  >
    <t-dropdown-item
      classes="block text-base font-medium cursor-pointer hover:bg-grey-100 focus:bg-grey-100"
      :option="item"
      value="id"
      text="domain"
    />
    <div
      v-show="showDelete"
      class="flex flex-row items-center justify-center hover:text-error-500"
      @mouseover="svg_color = 'var(--color-error-500)'"
      @mouseleave="svg_color = 'black'"
      @click.stop="handleDelete(item.id)"
    >
      <span class="mr-1">
        <trash-icon :color="svg_color" />
      </span>
      <span class="text-base font-semibold leading-4">{{ $t('email_settings.delete_email') }}</span>
    </div>
  </div>
</template>

<script>
import TrashIcon from './icons/TrashIcon';

export default {
  name: 'DnsItem',
  emits: ['click', 'delete'],
  components: { TrashIcon },
  data() {
    return {
      showDelete: false,
      svg_color: 'black',
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleClick(value) {
      this.$emit('click', value);
    },
    handleDelete(id) {
      this.$emit('delete', id);
    },
  },
};
</script>
