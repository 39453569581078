<template>
  <div>
    <div v-if="action === 'create' && !loading" class="box">
      <div class="box-header">
        <h2>{{ $t('instagram.settings') }}</h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('instagram.connected_account') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">
                <strong>{{ user }}</strong>
                <span class="text-muted text-xs" style="cursor: pointer" @click="connect">
                  (
                  <i class="fa fa-refresh"></i>
                  )
                </span>
              </p>
              <span class="form-text">
                {{ $t('instagram.user_who_has_authorized_connection_between_instagram_and_trengo') }}
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">
              {{ $t('instagram.instagram_business_profile') }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-10">
              <select v-model="record.username" class="form-control">
                <option v-for="page in pages" :value="page.id">{{ page.name }} (#{{ page.id }})</option>
              </select>
              <span class="form-text">
                {{ $t('instagram.cant_see_your_instagram_connected_facebook_page') }}
                <a
                  href="https://help.trengo.com/en/articles/63932"
                  target="_blank"
                  class="underline"
                  style="color: inherit !important"
                >
                  https://help.trengo.com/en/articles/63932
                </a>
              </span>
              <span class="form-text">
                {{ $t('instagram.force_reselection_of_facebook_pages') }}
                <a
                  href="https://www.facebook.com/settings?tab=business_tools"
                  target="_blank"
                  class="underline"
                  style="color: inherit !important"
                >
                  {{ $t('instagram.here') }}
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="action === 'info'" class="box">
      <div class="box-header">
        <h2>{{ $t('instagram.instagram') }}</h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('instagram.instagram_business_profile') }}</label>
          <div class="col-sm-10">
            <p class="form-control-static">
              <span>
                <a target="_blank" class="text-info" :href="'https://www.instagram.com/' + record.name">
                  <i class="fa fa-external-link"></i>
                  {{ record.name }} (#{{ record.username }})
                </a>
              </span>
              <span class="form-text">{{ $t('instagram.connected_instagram') }}</span>
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('instagram.connection') }}</label>
          <div class="col-sm-10">
            <div class="form-control-static">
              <button
                class="btn btn-default mb-4"
                type="button"
                :class="{ loader: loading }"
                style="background-color: #4267b2; color: white"
                @click="reconnect"
              >
                {{ $t('instagram.reconnect_facebook_page') }}
              </button>
              <div v-if="grantedScopes.length" class="alert alert-success">
                {{ $t('instagram.granted_permissions') }}
                <ul style="margin: 0">
                  <li v-for="grantedScope in grantedScopes">{{ grantedScope }}</li>
                </ul>
              </div>
              <div v-if="missingScopes.length || record.password_is_null" class="alert alert-danger">
                <template v-if="missingScopes.length">
                  {{ $t('instagram.missing_permissions') }}
                  <ul style="margin: 0">
                    <li v-for="missingScope in missingScopes">{{ missingScope }}</li>
                  </ul>
                  <br />
                </template>
                <strong>{{ $t('instagram.please_reconnect_the_facebook_page') }}</strong>
                <br />
                {{ $t('instagram.new_permissions_or_page_selection_not_showing_up') }}
                <a href="https://www.facebook.com/settings?tab=business_tools" target="_blank" class="underline">
                  {{ $t('instagram.here') }}
                </a>
              </div>
              <span class="form-text">
                {{ $t('instagram.you_are_able_to_reconnect_instagram_to_trengo') }}
              </span>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('instagram.direct_messages') }}</label>
          <div class="col-sm-10">
            <div class="form-control-static">
              <label class="ui-switch success m-t-xs m-r" @change="checkScopes()">
                <input v-model="record.instagramChannel.private_messages" type="checkbox" />
                <i></i>
              </label>
              <span class="form-text">
                {{ $t('instagram.manage_direct_messages_via_trengo') }}
                <label class="label success mb-0 ml-2">{{ $t('instagram.new') }}</label>
              </span>
              <div v-if="record.instagramChannel.private_messages" class="alert alert-info mt-4">
                {{ $t('instagram.you_must_allow_connected_tools_to_access_messages') }}
                <a
                  target="_blank"
                  style="text-decoration: underline"
                  class="open-external"
                  href="https://trengo.s3.eu-central-1.amazonaws.com/assets/161856968_298941651650715_9153695413459699473_n.png"
                >
                  {{ $t('instagram.learn_how') }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('instagram.comments') }}</label>
          <div class="col-sm-10">
            <p class="form-control-static">
              <label class="ui-switch success m-t-xs m-r" @change="checkScopes()">
                <input v-model="record.instagramChannel.comments" :true-value="1" :false-value="0" type="checkbox" />
                <i></i>
              </label>
              <span class="form-text">{{ $t('instagram.receive_comments_on_your_instagram_posts_in_trengo') }}</span>
            </p>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('instagram.mentions') }}</label>
          <div class="col-sm-10">
            <p class="form-control-static">
              <label class="ui-switch success m-t-xs m-r" @change="checkScopes()">
                <input v-model="record.instagramChannel.mentions" type="checkbox" true-value="1" />
                <i></i>
              </label>
              <span class="form-text">{{ $t('instagram.receive_mentions_on_other_instagram_accounts') }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  name: 'InstagramChannel',
  emits: ['update-loaded', 'call-init-warning-method', 'call-update-channel-method', 'update:modelValue'],
  data() {
    return {
      loading: false,
      user: null,
      pages: [],
      grantedScopes: [],
      missingScopes: [],
    };
  },

  props: {
    action: {
      type: String,
      default: 'create',
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },

  async mounted() {
    if (this.action === 'create') {
      if (this.$route.query.access_token) {
        this.authenticate();
      } else {
        this.connect();
      }
    }

    if (this.action === 'edit') {
      if (this.$route.query.access_token) {
        this.reauthenticate();
      }

      // Because Insta DMs is not enabled by default (since it is still in beta), remember the setting from localStorage
      this.record.instagramChannel.private_messages = !!(await this.$tStorage.getItem('ig_dm_' + this.record.id));
    }

    if (this.action === 'info') {
      this.checkScopes();
    }
  },
  computed: {
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    connect() {
      this.$emit('update-loaded', false);

      this.loading = true;
      axios
        .post('/api/v2/instagram/authenticate')
        .then((res) => {
          window.location.href = res.data.url;
        })
        .catch(() => {
          this.$emit('update-loaded', true);
          this.loading = false;
        });
    },

    reconnect() {
      this.loading = true;
      axios
        .post(
          '/api/v2/instagram/authenticate?cid=' +
            this.record.id +
            '&private_messages=' +
            (this.record.instagramChannel.private_messages ? 1 : 0)
        )
        .then((res) => {
          window.location.href = res.data.url;
        })
        .catch(() => {
          this.$emit('update-loaded', true);
          this.loading = false;
          this.$router.push('/admin/channels2/instagram');
        });
    },

    authenticate() {
      let params = {
        access_token: this.$route.query.access_token,
      };

      this.record.password = params['access_token'];

      this.loading = true;

      axios
        .get('/api/v2/instagram/pages', { params: params })
        .then((res) => {
          this.pages = res.data.pages;
          this.user = res.data.user;
          this.record.title = this.user;
          this.loading = false;

          if (this.pages.length) {
            this.record.username = this.pages[0].id;
          }
        })
        .catch(() => {
          this.loading = false;
          this.$router.push('/admin/channels2/instagram');
        });
    },

    reauthenticate() {
      if (this.loading) {
        return;
      }

      this.record.password = this.$route.query.access_token;
      this.$emit('call-update-channel-method');
      this.$emit('call-init-warning-method', false);

      router.push('/admin/channels2/instagram/' + this.$route.params.id);
    },

    updated() {
      this.checkScopes();
    },

    checkScopes() {
      if (!this.record.id) {
        return;
      }

      // Because Insta DMs is not enabled by default (since it is still in beta), remember the setting from localStorage
      this.$tStorage.setItem('ig_dm_' + this.record.id, this.record.instagramChannel.private_messages);

      axios
        .get('/api/v2/instagram/pages/' + this.record.id, {
          params: {
            comments: this.record.instagramChannel.comments,
            mentions: this.record.instagramChannel.mentions,
            messages: this.record.instagramChannel.private_messages,
          },
        })
        .then((res) => {
          this.grantedScopes = res.data.grantedScopes;
          this.missingScopes = res.data.missingScopes;
          this.record.password_is_null = res.data.password_is_null;
          this.$emit('call-init-warning-method', false);
        })
        .catch((error) => {
          this.record.password_is_null = true;
        });
    },
  },
};
</script>
