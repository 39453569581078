<script lang="ts" setup>

import { vm } from '@/ApplicationHandler';
import ListRowItem from '@/components/common/ListRow/ListRowItem';

import { type ContactGroup } from '../types';

type Props = {
  items: ContactGroup[];
}

const props = defineProps<Props>();

function handleItemClicked(id: number) {
  vm.$router.push({ name: 'contact-groups-detail', params: { id: id.toString() } } );
}
</script>

<template>
  <div data-test="contact-groups-overview" class="row-body">
    <div class="h-full">
      <ul class="mt-4 list-none rounded-xl border border-grey-300 bg-white px-0">
        <list-row-item v-for="item in props.items" :key="item.id" @click="handleItemClicked(item.id)">
          <div class="flex w-full flex-wrap items-center justify-between" data-test="items-child">
            <p
              class="t-text-md-emphasize mb-0 mr-2.5 w-5 flex-1 truncate text-ellipsis text-left text-grey-800"
              data-test="item-name"
            >
              {{ item.name }}
            </p>
            <div class="flex items-center justify-between">
              <t-badge :text="item.contacts_count || 0" data-test="item-status" />
            </div>
          </div>
        </list-row-item>
      </ul>
    </div>
  </div>
</template>
