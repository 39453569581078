<template>
  <div v-if="!record.key_assigned" class="alert alert-warning">
    Your chatbot is not ready for use yet. You will be notified by email once we have activated your chatbot.
  </div>
</template>

<script>
export default {
  props: ['record'],
};
</script>
