<script type="text/babel">
import Status from './Status.vue';

export default {
  props: ['record'],

  components: {
    Status,
  },

  data() {
    return {
      query: '',
      messages: [],
      typing: false,
    };
  },

  mounted() {
    if (!this.record.key_assigned) {
      this.$router.push('/chatbots/' + this.record.id + '/questions');
    }

    this.$refs.input.focus();

    setTimeout(() => {
      this.messages.push({
        message: this.$t('chatbot.playground_message'),
        created_at: moment().format('HH:mm'),
        score: 100,
      });
    }, 500);
  },

  methods: {
    submit() {
      if (this.query == '') {
        return;
      }

      this.messages.push({
        me: true,
        message: this.query,
        created_at: moment().format('HH:mm'),
      });

      axios.get('/api/v2/chatbot/' + this.record.id + '/query?q=' + this.query).then((res) => {
        if (!res.data.success) {
          this.typing = false;
          return;
        }

        this.insertBotMessage(res.data);
      });

      this.query = '';
      this.scrollToBottom();
      this.$refs.input.focus();
    },

    scrollToBottom() {
      this.$nextTick(() => {
        var messageDisplay = this.$refs.container;
        messageDisplay.scrollTop = messageDisplay.scrollHeight;
      });
    },

    insertBotMessage(res) {
      setTimeout(() => {
        this.typing = true;
        setTimeout(() => {
          this.messages.push({
            me: false,
            message: res.message,
            score: res.score,
            created_at: moment().format('HH:mm'),
          });
          this.scrollToBottom();
          this.typing = false;
        }, 1000);
      }, 200);
    },
  },
};
</script>

<template>
  <div class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis">{{ $t('chatbot.playground') }}</span>
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body container">
        <div class="row-inner">
          <div class="p-a">
            <status :record="record"></status>
            <div class="box display-flex" style="height: 550px; flex-direction: column">
              <div class="box-header b-b">
                <h3>{{ $t('chatbot.chat') }}</h3>
                <small>{{ $t('chatbot.subtitle_playground') }}</small>
              </div>
              <div class="light dk p-a" style="flex: 1; overflow-y: scroll" ref="container">
                <div style="overflow-y: scroll">
                  <div v-for="message in messages">
                    <div class="m-b">
                      <div class="clear" :class="{ 'text-right': !message.me }">
                        <div>
                          <div
                            class="p-a p-y-sm dark-white r inline text-left"
                            :class="{ success: !message.me }"
                            style="max-width: 50%"
                          >
                            {{ message.message }}
                          </div>
                        </div>
                        <div class="text-muted">
                          <label
                            class="label label-default"
                            v-if="!message.me"
                            :class="{ danger: message.score < record.threshold }"
                          >
                            {{ message.score }}%
                          </label>
                          <span class="text-xs">
                            <i class="fa fa-ok text-success"></i>
                            {{ message.created_at }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box-footer b-t">
                <form @submit.prevent="submit">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control has-value"
                      ref="input"
                      :placeholder="$t('chatbot.say_something')"
                      v-model="query"
                    />
                    <span class="input-group-btn">
                      <button class="btn white b-a no-shadow" type="submit">{{ $t('chatbot.send') }}</button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
