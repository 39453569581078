<script type="text/babel">
import eventBus from '@/eventBus';

import ticketRepository from '../repositories/Ticket';

export default {
  name: 'TicketResultModal',
  data: function () {
    return {
      loaded: false,
      saving: false,
      ticket_id: null,
      ticket_result_id: null,
      saving_without_result: false,
      bulk_tickets_result: false,
    };
  },

  mounted: function () {
    eventBus.$on('modals.bulk-ticket-result.open', () => {
      this.bulk_tickets_result = true;
      $('#TicketResultModal').modal();
    });

    eventBus.$on('modals.ticket-result.open', (res) => {
      this.resetLoadingState();

      this.ticket_id = res.ticket_id;

      if (typeof res.currentResult[0] != 'undefined') {
        this.ticket_result_id = res.currentResult[0]['id'];
      }

      $('#TicketResultModal').modal();
    });
  },

  unmounted() {
    eventBus.$off('modals.ticket-result.open');
  },

  methods: {
    closeWithResult() {
      this.saving = true;
      if (this.bulk_tickets_result) {
        this.bulk_tickets_result = false;
        eventBus.$emit('modals.bulk-ticket-result.close', this.ticket_result_id);
        $('#TicketResultModal').modal('hide');
        this.resetLoadingState();
        return;
      }
      ticketRepository.closeTicket(this.ticket_id, this.ticket_result_id).then(() => {
        $('#TicketResultModal').modal('hide');
        this.resetLoadingState();
      });
    },

    resetLoadingState() {
      this.ticket_result_id = null;
      this.ticket_id = null;
      this.saving = false;
      this.saving_without_result = false;
    },
  },
};
</script>

<template>
  <div id="TicketResultModal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('tickets.apply_ticket_result') }}</h5>
          <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label class="control-label">{{ $t('tickets.select_ticket_result') }}</label>
            <select v-model="ticket_result_id" class="form-control">
              <option v-for="item in $root.ticketResults" :value="item.id">{{ item.name }}</option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn green" :disabled="ticket_result_id == null" @click="closeWithResult()">
            <span v-if="!saving">{{ $t('tickets.save_ticket_result') }}</span>
            <span v-if="saving"><i class="fa fa-spin fa-spinner"></i></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
