export const registerDragOverEvent = () => {
  return window.addEventListener(
    'dragover',
    (e) => {
      e = e || event;
      e.preventDefault();
    },
    false
  );
};

export const registerDropEvent = () => {
  return window.addEventListener(
    'drop',
    (e) => {
      e = e || event;
      e.preventDefault();
    },
    false
  );
};

export const registerFocusEvent = (hasWindowFocus: boolean) => window.addEventListener('focus', () => hasWindowFocus);

export const registerBlurEvent = (hasWindowFocus: boolean) => window.addEventListener('blur', () => hasWindowFocus);

export const unRegisterDragOverEvent = () => window.removeEventListener('dragover', () => {});

export const unRegisterDropEvent = () => window.removeEventListener('drop', () => {});

export const unRegisterFocusEvent = (hasWindowFocus: boolean) =>
  window.removeEventListener('focus', () => hasWindowFocus);

export const unRegisterBlurEvent = (hasWindowFocus: boolean) =>
  window.removeEventListener('blur', () => hasWindowFocus);
