<template>
  <page-container class="flex flex-col rounded-xl border-1 border-grey-200 bg-white" style="height: min-content">
    <hero class="mb-4 mt-8 xl2:mx-3">
      <template #top>
        <page-title>{{ $t('reports.csat_title') }}</page-title>
      </template>
    </hero>
    <filters v-model="filters" :show-rating="true" :show-survey="true" :compact-style="true"></filters>
    <report report="csats" :filters="filters" :metrics="['average_rating', 'surveys_received']"></report>
  </page-container>
</template>

<script>
import Filters from '../Components/Filters';
import PageContainer from '../Components/PageContainer';
import PageTitle from '../Components/PageTitle';
import Report from '../Components/Report';

export default {
  mounted() {},
  components: {
    Report,
    Filters,
    PageTitle,
    PageContainer,
  },
  data() {
    return {
      filters: {},
      surveys: {},
    };
  },
  methods: {},
};
</script>
