<template>
  <div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.woocommerce_label_consumer_key') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.consumer_key" type="text" autocomplete="off" class="form-control" />
        <span class="form-text" v-html="$t('integrations.woocommerce_subtitle_woocommerce_consumer_key')"></span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.woocommerce_label_consumer_secret') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.consumer_secret" type="text" autocomplete="off" class="form-control" />
        <span class="form-text" v-html="$t('integrations.woocommerce_subtitle_consumer_secret')"></span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.woocommerce_label_your_shop_url') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.url" type="text" autocomplete="off" class="form-control" />
        <span class="form-text">{{ $t('integrations.woocommerce_subtitle_your_shop_url') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
