import { NOTIFICATION_TYPE } from './constants';

import type { Notification } from './types';

export const getRouteFromType = (notification: Notification) => {
  switch (notification.type) {
    case NOTIFICATION_TYPE.ASSIGNED_TICKET:
    case NOTIFICATION_TYPE.EMAIL_RECIPIENT_FAILED:
    case NOTIFICATION_TYPE.NEW_TICKET_MESSAGE:
    case NOTIFICATION_TYPE.RULE_NOTIFY_USERS:
    case NOTIFICATION_TYPE.TICKET_MENTION:
    case NOTIFICATION_TYPE.TICKET_NOTE:
    case NOTIFICATION_TYPE.TICKET_REMINDER:
      return `/tickets/${notification.data?.ticket_id}`;
    case NOTIFICATION_TYPE.WABA_BANNED:
    case NOTIFICATION_TYPE.WABA_REVIEW_COMPLETED:
    case NOTIFICATION_TYPE.WABA_REVIEW_REMINDER:
      return `/admin/channels2/wa_business/${notification.data?.channel_id}`;
    case NOTIFICATION_TYPE.TWO_FACTOR_AUTH_IS_MANDATORY:
      return '/profile/2fa';
    case NOTIFICATION_TYPE.BETA_ENROLLMENT_STATUS:
      return '/admin/company_profile/settings';
    default:
      throw new TypeError(`unsupported notification type: ${notification.type}`);
  }
};

export const getTranslationParamsFromType = (
  notification: Notification
): [key: string, payload?: Record<string, unknown>] => {
  const concatDaysPhrase = (days = '') => {
    const daysPhrase = parseInt(days) > 1 ? 'days' : 'day';
    return `${days} ${daysPhrase}`;
  };

  switch (notification.type) {
    case NOTIFICATION_TYPE.ASSIGNED_TICKET:
      return [
        'notification.type_assigned_to_ticket',
        { user: notification.data?.user?.name, ticket_id: notification.data?.ticket_id },
      ];
    case NOTIFICATION_TYPE.NEW_TICKET_MESSAGE:
      return [
        'notification.type_ticket_message',
        { contact: notification.data?.user?.name, ticket_id: notification.data?.ticket_id },
      ];
    case NOTIFICATION_TYPE.TICKET_MENTION:
      return [
        'notification.type_ticket_mention',
        { user: notification.data?.user?.name, ticket_id: notification.data?.ticket_id },
      ];
    case NOTIFICATION_TYPE.TICKET_NOTE:
      return [
        'notification.type_ticket_note',
        { user: notification.data?.user?.name, ticket_id: notification.data?.ticket_id },
      ];
    case NOTIFICATION_TYPE.TICKET_REMINDER:
      return ['notification.type_ticket_reminder', { ticket_id: notification.data?.ticket_id }];
    case NOTIFICATION_TYPE.EMAIL_RECIPIENT_FAILED:
      return ['notification.type_email_recipient_failed', { ticket_id: notification.data?.ticket_id }];
    case NOTIFICATION_TYPE.RULE_NOTIFY_USERS:
      return [
        'notification.rule_notify_users',
        { ticket_id: notification.data?.ticket_id, rule: notification.data?.rule?.name },
      ];
    case NOTIFICATION_TYPE.WABA_REVIEW_REMINDER:
      return [
        'notification.waba_review_reminder',
        { channel_id: notification.data?.channel_id, days: concatDaysPhrase(notification.data?.days_remaining) },
      ];
    case NOTIFICATION_TYPE.WABA_BANNED:
      return ['notification.waba_banned', { channel_id: notification.data?.channel_id }];
    case NOTIFICATION_TYPE.MESSAGE_IMPORT_STARTED:
      return ['notification.type_message_import_started', { channel_name: notification.data?.channel_name }];
    case NOTIFICATION_TYPE.MESSAGE_IMPORT_FINISHED:
      return ['notification.type_message_import_finished', { channel_name: notification.data?.channel_name }];
    case NOTIFICATION_TYPE.MESSAGE_IMPORT_FAILED:
      return ['notification.type_message_import_failed'];
    case NOTIFICATION_TYPE.WABA_REVIEW_COMPLETED:
      return ['notification.waba_review_approved'];
    case NOTIFICATION_TYPE.RETROACTIVE_BALANCE_FINISHED:
      return ['notification.workload_balancing_retroactive_finished'];
    case NOTIFICATION_TYPE.CONTACT_IMPORT_FINISHED:
      return ['notification.contact_import_finished'];
    case NOTIFICATION_TYPE.CONTACT_IMPORT_FAILED:
      return ['notification.contact_import_failed'];
    case NOTIFICATION_TYPE.TWO_FACTOR_AUTH_IS_MANDATORY:
      return ['notification.mandatory_2fa_enabled'];
    case NOTIFICATION_TYPE.BETA_ENROLLMENT_STATUS:
      return notification.data?.beta_enrolled
        ? ['notification.beta_enrollment_enabled']
        : ['notification.beta_enrollment_disabled'];
    default:
      throw new TypeError(`unsupported notification type: ${notification.type}`);
  }
};
