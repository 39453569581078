<template>
  <div class="mb-4 pt-1">
    <h4 class="t-text-button-lg mb-4">{{ title }}</h4>
    <div class="grid grid-cols-1 gap-x-6 gap-y-2 sm:gap-y-3 md:grid-cols-2">
      <learn-more-item v-for="(item, index) in items" :key="index" v-bind="item" />
    </div>
  </div>
</template>

<script>
import LearnMoreItem from './LearnMoreItem';

export default {
  name: 'LearnMoreCategory',
  components: { LearnMoreItem },
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
