<script setup lang="ts">
import { computed, ref } from 'vue';

const props = defineProps<{
  value: number;
  indicatorText: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  error?: string;
}>();

const emit = defineEmits(['change']);
const valueRef = ref(props.value);

const computedValue = computed({
  get: () => valueRef.value,
  set: (val: number) => {
    let newVal;
    if (val < (props.min ?? 0)) {
      newVal = props.min ?? 0;
    } else if (props.max && val > props.max) {
      newVal = props.max;
    } else {
      newVal = val;
    }
    valueRef.value = Number(newVal); // trim leading zeros
    emit('change', valueRef.value);
  },
});
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}
</style>

<template>
  <div class="relative flex items-center text-grey-600">
    <input
      v-model="computedValue"
      type="number"
      :min="min ?? 0"
      :max="max"
      class="border-gray-400 t-text-sm h-9 w-48 rounded-md border px-2 py-1"
      :class="disabled ? 'bg-gray-100' : ''"
      :disabled="disabled"
    />
    <span class="z-100 t-text-sm absolute right-2">
      {{ indicatorText }}
    </span>
  </div>
</template>
