const MAGENTO = 'magento';
const SLACK = 'slack';
const WHOOCOMMERCE = 'whoocommerce';
const HUBSPOT = 'hubspot';
const LIGHTSPEED = 'lightspeed';
const CUSTOM = 'custom';
const SHOPIFY = 'shopify';
const EXACT = 'exact';
const MAGENTO_1 = 'magento1';
const PIPEDRIVE = 'pipedrive';
const PICQER = 'picqer';
const JIRA = 'jira';

export default {
  MAGENTO,
  SLACK,
  WHOOCOMMERCE,
  HUBSPOT,
  LIGHTSPEED,
  CUSTOM,
  SHOPIFY,
  EXACT,
  MAGENTO_1,
  PIPEDRIVE,
  PICQER,
  JIRA,
};
