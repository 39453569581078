<script>
export default {
  mounted() {},
};
</script>

<template>
  <div class="ui-page-container min-w-0" style="margin: 0 auto">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.ui-page-container {
  min-width: 1200px;
  max-width: 1200px;
  @media (max-width: 1500px) {
    min-width: 600px;
    max-width: calc(100vw - 345px);
  }
  @media (max-width: 992px) {
    min-width: 0px;
    max-width: 1000px;
  }
}
</style>
