<template>
  <div
    data-test="onboarding-slides-item"
    v-show="active"
    class="fade-in onboarding-steps-main select-none items-center justify-center"
  >
    <div class="mb-10"><img draggable="false" :src="item.banner" alt="first-slide" /></div>
    <div class="t-text-desktop-h5 mb-1">{{ item.title }}</div>
    <div class="t-text-desktop-paragraph-sm text-center">
      <span class="text-grey-600">
        {{ item.description }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnboardingSlides',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    item: {
      default: () => ({}),
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped src="./OnboardingSlides.scss" />
