<template>
  <tr data-test="installation-item-cell-wrapper">
    <td style="min-width: 200px" class="py-4 text-base text-grey-600">{{ $tc('integration_hub.installed') }}</td>
    <td class="w-full text-base font-semibold text-grey-800">{{ formatDate(installation.installed_at) }}</td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { formatDate } from '@/util/date';

import type { Installation } from '@/store/types/integrations';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'InstalledIntegrationCrm',
  props: {
    installation: {
      type: Object as PropType<Installation>,
      default: () => ({}),
    },
  },
  methods: {
    formatDate,
  },
});
</script>

<style lang="scss">
tr {
  @apply border-b-1 border-grey-300 last:border-b-0;
}
</style>
