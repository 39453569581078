import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0c1b5a45"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block h-screen overflow-y-scroll md:h-full" }
const _hoisted_2 = { class: "mx-4 flex flex-col items-start px-0 pb-40 pt-20 leading-none md:px-2 md:pb-20 lg:mx-20" }
const _hoisted_3 = { class: "mb-8 rounded-3xl bg-leaf-200 p-5" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "settings-landing-content" }
const _hoisted_6 = { class: "mb-3 max-w-full text-4xl font-bold text-grey-800 xl:max-w-md" }
const _hoisted_7 = { class: "mt-8 flex w-full flex-col md:w-auto xl:flex-row" }
const _hoisted_8 = { class: "flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_top_circle_linear = _resolveComponent("arrow-top-circle-linear")!
  const _component_t_badge = _resolveComponent("t-badge")!
  const _component_t_unordered_list = _resolveComponent("t-unordered-list")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_external_link_linear = _resolveComponent("external-link-linear")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.reportingIconSrc,
          class: "h-12 w-12"
        }, null, 8 /* PROPS */, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.$t('reports_v2.navigation_header_reporting')), 1 /* TEXT */),
        _createVNode(_component_t_badge, {
          id: "upgrade-badge",
          variant: "upgrade",
          text: _ctx.$t('entitlement.upgrade_to_plan_long', { plan: _ctx.minimumRequiredPlan })
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_arrow_top_circle_linear, { size: "1.25rem" })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["text"]),
        _createVNode(_component_t_unordered_list, {
          items: _ctx.list,
          class: "mt-8 max-w-full xl:max-w-md",
          size: "lg"
        }, null, 8 /* PROPS */, ["items"]),
        _createElementVNode("section", _hoisted_7, [
          _createVNode(_component_t_button, {
            "btn-style": "primary",
            size: "md",
            class: "mb-4 mr-0 xl:mb-0 xl:mr-4",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/admin/subscription_settings')))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_arrow_top_circle_linear, {
                  size: "1.25rem",
                  class: "mr-1"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('entitlement.upgrade_to_plan', { plan: _ctx.minimumRequiredPlan })), 1 /* TEXT */)
              ])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_t_button, {
            size: "md",
            "btn-style": "secondary",
            href: "https://help.trengo.com/en/24850-trengo-analytics",
            rel: "noreferrer noopener",
            target: "_blank",
            class: "open-external flex flex-row items-center"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('reports_v2.learn_more')) + " ", 1 /* TEXT */),
              _createVNode(_component_external_link_linear, { class: "ml-1" })
            ]),
            _: 1 /* STABLE */
          })
        ])
      ])
    ])
  ]))
}