import moment from 'moment';

import { getHoursMinutesSeconds } from './getHoursMinutesSeconds';

export function isUserRegisteredAfter(inputDate: string, createdAt: string) {
  const date = moment(inputDate, 'DD/MM/YYYY');
  const userCreatedAt = moment(createdAt, 'YYYY-MM-DD HH:mm:ss');

  return userCreatedAt.isAfter(date);
}

export function convertSecondsToMinutesAndSeconds(time: number): string {
  const { hours, minutes, seconds } = getHoursMinutesSeconds(time);
  return `${hours > 0 ? hours + 'h' : ''} ${minutes}m ${seconds > 0 ? seconds + 's' : ''}`;
}
