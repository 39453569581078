<template>
  <div class="w-96">
    <whatsapp-sandbox-modal
      v-model="computedValue"
      @open="handleOpen"
      @close="handleClose"
      @process-completed="handleProcessCompleted"
    ></whatsapp-sandbox-modal>
  </div>
</template>
<script lang="ts">
import WhatsappSandboxModal from '@/components/Channels/WhatsappSandboxModal.vue';

export default {
  name: 'ChannelWhatsappSandboxModal',
  emits: ['completed', 'close', 'open'],
  components: { WhatsappSandboxModal },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show_sandbox: false,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.show_sandbox;
      },
      set(value) {
        this.show_sandbox = value;
      },
    },
  },
  watch: {
    modelValue(val) {
      this.show_sandbox = val;
    },
    show_sandbox(value) {
      if (!value) return;
      this.openModal();
    },
  },
  methods: {
    handleProcessCompleted(ticket_link) {
      this.$emit('completed', { ticket_link });
    },
    handleClose() {
      this.$emit('close');
    },
    handleOpen() {
      this.$emit('open');
    },
    removeModalBackgroundClassFromBodyDOMTree() {
      const getBodyLastDiv = document.querySelector('body > div:last-of-type') as HTMLDivElement;
      const listBodyClasses = getBodyLastDiv.classList ? Array.from(getBodyLastDiv.classList) : [];
      if (listBodyClasses.includes('modal-backdrop')) {
        getBodyLastDiv?.remove();
      }
    },
    openModal() {
      (this as any).$nextTick(() => {
        (this as any).removeModalBackgroundClassFromBodyDOMTree();
        this.show_sandbox = true;
      });
    },
  },
};
</script>
