<template>
  <div class="settings-sidebar b-primary flex h-screen flex-col bg-white">
    <div class="header flex">
      <div class="title text-base font-bold">
        <span>{{ channelName }}</span>
        {{ $t('channels.channels_header_title') }}
      </div>
      <t-button type="button" btn-style="secondary" class="add-btn" size="sm" @click="create">
        <i class="material-icons">add</i>
      </t-button>
    </div>
    <div class="row-row">
      <div class="row-body scrollable hove">
        <div class="row-inner pb-20">
          <ul class="setting-sidebar-list">
            <setting-sidebar-item
              v-for="item in channels"
              :id="item.id"
              :key="item.id"
              :desc="item.text.replace(item.title + ' • ', '')"
              :status-title="getStatusIndicator(item) && getStatusIndicator(item).title"
              :status-type="getStatusIndicator(item) && getStatusIndicator(item).type"
              :title="item.title"
              :email-type="item.emailChannel ? item.emailChannel.type : ''"
              :is-private="item.is_private"
              :channel-type="channelType"
            />
          </ul>
        </div>
        <footer v-if="isWA_BUSINESS" class="footer absolute bottom-0 w-full bg-white py-4">
          <div v-if="getExtraSettings(channelType)" class="mx-auto">
            <t-button
              btn-style="secondary"
              size="sm"
              class="mx-auto flex items-center"
              @click="$router.push(getExtraSettings(channelType).path)"
            >
              <i class="far fa-cog mr-2" />
              {{ getExtraSettings(channelType).title }}
            </t-button>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { CHANNEL_TYPE } from '@/Configs/Constants';

import SettingSidebarItem from './SettingSidebarItem';
import { getExtraSettings, getStatusIndicator } from './utils';

export default {
  emits: ['create'],
  components: { SettingSidebarItem },
  props: {
    channels: {
      type: Array,
      default: () => [],
    },
    channelName: {
      type: String,
      default: '',
    },
  },
  computed: {
    channelType() {
      return this.$route.params.channel;
    },
    isWA_BUSINESS() {
      return this.channelType === CHANNEL_TYPE.WA_BUSINESS.toLowerCase();
    },
  },
  methods: {
    create() {
      this.$emit('create');
    },
    getStatusIndicator,
    getExtraSettings,
  },
};
</script>

<style scoped lang="scss" src="./SettingSidebar.scss" />
