<template>
  <t-modal
    v-model="computedValue"
    :title="$t('settings.users_delete_delete_name_question', { name })"
    variant="narrow"
    class="delete-user-modal"
    @close="close"
  >
    <div
      class="
        px-4
        pb-1
        text-sm
        font-medium
        leading-5
        text-grey-800
        md:px-8 md:text-base md:leading-6 md:text-grey-900
        lg:px-0
      "
    >
      {{ $t('settings.users_delete_warning_no_longer_have_access', { name: name }) }}
    </div>

    <slot name="body" />

    <template #footer>
      <t-button btn-style="danger" class="flex items-center justify-center" @click="confirm">
        <img :src="`${$root.assetsURL}img/action_icons/delete.svg`" alt="" class="mr-2" />
        {{ $t('settings.users_delete_delete_user') }}
      </t-button>
      <t-button btn-style="secondary" @click="cancel">
        {{ $t('settings.users_delete_cancel') }}
      </t-button>
    </template>
  </t-modal>
</template>

<script>
export default {
  name: 'DeleteTeamChatLastOwnerModal',
  emits: ['update:modelValue', 'close', 'cancel', 'confirm'],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'User',
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    close() {
      this.$emit('close');
      this.computedValue = false;
    },
    cancel() {
      this.$emit('cancel');
      this.close();
    },
    confirm() {
      this.$emit('confirm');
      this.close();
    },
  },
};
</script>
