<template>
  <div class="p-4" style="min-width: 1024px">
    <div class="automation-grid automation-grid-header pb-4">
      <span class="align-icon truncate">
        <i class="material-icons">format_size</i>
        <span>{{ $t('widget_automation.header_title') }}</span>
      </span>
      <span class="align-icon truncate">
        <i class="far fa-fw fa-sitemap"></i>
        <span>{{ $t('widget_automation.header_flowbot') }}</span>
      </span>
      <span class="align-icon truncate">
        <i class="material-icons">person_outline</i>
        <span>{{ $t('widget_automation.header_start_with_step') }}</span>
      </span>
      <span class="align-icon truncate">
        <i class="material-icons">person_outline</i>
        <span>{{ $t('widget_automation.header_started') }}</span>
      </span>
      <span class="align-icon truncate">
        <i class="material-icons">expand_more</i>
        <span>{{ $t('widget_automation.header_created') }}</span>
      </span>
    </div>

    <div
      v-for="automation in automations"
      class="pointer automation-grid py-4"
      @click="$router.push('/admin/widget_automation/' + automation.id)"
    >
      <div>
        <div class="flex">
          <h6 class="truncate">{{ automation.title }}</h6>
          <div class="ml-auto">
            <span class="label" :class="getAutomationStatus(automation)">{{ getAutomationStatus(automation) }}</span>
          </div>
        </div>
        <div v-if="getConditions(automation).length">
          <span v-for="condition in getConditions(automation).slice(0, 2)" class="condition align-icon pr-2">
            <i
              class="material-icons"
              v-text="condition.type in audienceConditionTypes ? 'person_outline' : 'language'"
            ></i>
            <span v-if="condition.value !== false">
              {{
                condition.type in audienceConditionTypes
                  ? audienceConditionTypes[condition.type].niceName
                  : globalConditionTypes[condition.type].niceName
              }}
              <template v-if="conditionTypeHasOperators(condition.type)">
                {{ conditionStrings.operators[condition.operator] }}
              </template>
              <template v-if="condition.value !== true">{{ condition.value }}</template>
            </span>
            <span v-else>Not {{ audienceConditionTypes[condition.type].niceName }}</span>
          </span>
          <span
            v-if="getAudienceConditions(automation).length + getPageUrlConditions(automation).length > 2"
            class="label more"
          >
            +{{ getConditions(automation).length - 2 }}
          </span>
        </div>
        <div v-else>
          <span class="condition align-icon pr-2">
            <i class="material-icons">person_outline</i>
            <span>Everyone</span>
          </span>
        </div>
      </div>
      <div>
        <span v-if="automation.flowbot" class="align-icon text-truncate" style="display: inline-block; max-width: 100%">
          <i class="far fa-fw fa-sitemap"></i>
          {{ automation.flowbot.title }}
          <span></span>
        </span>
      </div>
      <div>
        <span
          v-if="automation.flowbotStep"
          class="align-icon text-truncate"
          style="display: inline-block; max-width: 100%"
        >
          <i class="material-icons">person_outline</i>
          <span>{{ automation.flowbotStep.title }}</span>
        </span>
      </div>
      <div>
        <span class="label sent">{{ automation.metrics ? automation.metrics['flowbot.start'] || 0 : 0 }}</span>
      </div>
      <div class="truncate">
        {{ automation.created_at ? moment(automation.created_at * 1000).fromNow() : 'Not yet saved' }}
      </div>
    </div>
    <infinite-loading
      force-use-infinite-wrapper="#automation-scroll-container"
      direction="bottom"
      spinner="spiral"
      identifier="setFlowbot"
      @infinite="(event) => $emit('loadMore', event)"
    ></infinite-loading>
  </div>
</template>

<script>
import infiniteLoading from 'vue-infinite-loading';

import Automation from './Automation';

export default {
  name: 'FlowbotsList',
  emits: ['loadMore'],
  props: {
    automations: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: { infiniteLoading },

  data() {
    return {
      conditionStrings: Automation.conditionStrings(),
      audienceConditionTypes: Automation.audienceConditionTypes(),
      globalConditionTypes: Automation.globalConditionTypes(),
    };
  },

  methods: {
    moment(time) {
      return moment(time);
    },

    flowbotAction(automation) {
      // todo: cache this
      return automation.payload.actions.find((action) => action.type === 'setFlowbot');
    },

    conditionTypeHasOperators(conditionType) {
      return (
        (conditionType in this.audienceConditionTypes &&
          this.audienceConditionTypes[conditionType].operators &&
          this.audienceConditionTypes[conditionType].operators.length) ||
        (conditionType in this.globalConditionTypes &&
          this.globalConditionTypes[conditionType].operators &&
          this.globalConditionTypes[conditionType].operators.length)
      );
    },

    getAudienceConditions(automation) {
      return automation.payload.conditions.filter((item) => {
        return item.type in Automation.audienceConditionTypes();
      });
    },

    getPageUrlConditions(automation) {
      let subconditions =
        automation.payload.conditions.find((item) => {
          return item.type === 'page_url_subconditions';
        }) || [];

      return subconditions.subconditions || [];
    },

    getConditions(automation) {
      // TODO, cache this
      return [...this.getAudienceConditions(automation), ...this.getPageUrlConditions(automation)];
    },

    getAutomationStatus(automation) {
      if (this.automationIsPublished(automation)) {
        return 'published';
      }
      if (!automation.active || !automation.web_widget_id) {
        return 'unpublished';
      }
      if (this.automationIsScheduled(automation)) {
        return 'scheduled';
      }
    },

    automationIsScheduled(automation) {
      return (
        // is active
        automation.active &&
        // has a widget
        automation.web_widget_id &&
        // has start_date condition
        automation.payload.conditions.some((condition) => {
          return condition.type === 'start_date';
        }) &&
        // all start_date conditions are in the future (should always be only 0-1 start_date condition though)
        automation.payload.conditions.every((condition) => {
          return condition.type !== 'start_date' || moment().isBefore(condition.value);
        })
      );
    },

    automationIsPublished(automation) {
      return automation.active && automation.web_widget_id && !this.automationIsScheduled(automation);
    },
  },
};
</script>

<style lang="scss" scoped>
.automation-grid {
  display: grid;
  grid-template-columns: repeat(2, 2fr) repeat(3, 1fr);
  border-bottom: 1px solid #f0f0f0;

  & > * {
    overflow: hidden;
    padding: 0 0.5rem;
  }

  &.automation-grid-header {
    color: #6d6d6d;
    font-weight: bold;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

span.scheduled {
  background: none;
  border: 1px solid #107cd8;
  color: #107cd8;
}
span.published {
  background: none;
  border: 1px solid #14b29f;
  color: #14b29f;
}
span.unpublished {
  background: none;
  border: 1px solid #d9d9d9;
  color: #d9d9d9;
}

span.sent {
  color: #fff;
  background-color: #000;
}
span.opened {
  color: #fff;
  background-color: #14b29f;
}
span.replied {
  color: #fff;
  background-color: #df2c80;
}
span.bounced {
  color: #fff;
  background-color: #e5af1d;
}

span.more {
  background-color: #f2f2f2;
  color: #9e9e9e;
}

span .material-icons {
  vertical-align: bottom;
}

span.condition {
  span {
    color: #b8b8b8;
  }
  i.material-icons {
    color: #9e9e9e;
  }
}

span.align-icon {
  span {
    vertical-align: bottom;
  }
  i.material-icons {
    vertical-align: middle;
    font-size: 20px;
    padding-right: 0.125rem;
  }
}
</style>
