<template>
  <div class="wab-contact-status flex flex-col items-center justify-center">
    <slot name="icon">
      <user-linear size="1.5rem" />
    </slot>
    <div class="mt-2 w-96 flex-grow-0 truncate text-center">
      <slot />
    </div>
  </div>
</template>

<script>
import { UserLinear } from '@trengo/trengo-icons';

export default {
  name: 'WabContactStatus',
  components: {
    UserLinear,
  },
};
</script>
