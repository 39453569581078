<template>
  <div class="mt-6 flex flex-col">
    <div :class="{ 'mb-6': !errors.apiKey }">
      <t-input-text
        id="apiKey"
        v-model="$props.modelValue.api_key"
        size="sm"
        :label="$tc('integrations.bol_label_client_id')"
        :sub-label="$tc('integrations.bol_subtitle_client_id')"
        :has-error="errors.apiKey"
        @blur="checkApiKey"
      />
      <t-error-item
        v-if="errors.apiKey"
        :text="$tc('integration_hub.error_client_id_must_be_filled_out')"
        danger-icon
        class="mb-4"
      />
    </div>

    <t-input-text
      id="apiPassword"
      v-model="$props.modelValue.api_password"
      size="sm"
      :label="$tc('integrations.bol_label_client_secret')"
      :sub-label="$tc('integrations.bol_subtitle_client_secret')"
      :has-error="errors.apiPassword"
      @blur="checkApiPassword"
    />
    <t-error-item
      v-if="errors.apiPassword"
      :text="$tc('integration_hub.error_client_secret_must_be_filled_out')"
      danger-icon
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import type { CustomBolIntegration } from '@/store/types/integrations';

const props = defineProps<{ modelValue: CustomBolIntegration['meta'] }>();
const emit = defineEmits(['on-validated', 'on-invalidated']);

const errors = ref({ apiKey: false, apiPassword: false });

function validateFields() {
  checkApiKey();
  checkApiPassword();

  Object.values(errors.value).some((item) => item) ? emit('on-invalidated') : emit('on-validated');
}

defineExpose({ validateFields });

function checkApiKey() {
  errors.value.apiKey = !props.modelValue.api_key || props.modelValue.api_key === '';
}

function checkApiPassword() {
  errors.value.apiPassword = !props.modelValue.api_password || props.modelValue.api_password === '';
}
</script>
