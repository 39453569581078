<template>
  <settings-page
    v-if="isFeatureFlagEnabled"
    :class="fullHeightInEdit"
    help-link="https://help.trengo.com/en/articles/64117-the-anatomy-of-a-flowbot"
    :heading-title="$t('flowbot.list_heading_title_flowbots')"
    :add-button-text="primaryButtonText"
    :section-title="$t('flowbot.list_heading_title_flowbots')"
    :show-list="showItems"
    @add-button="handlePrimaryButtonClick"
  >
    <template #banner>
      <upgrade-flowbot-banner
        v-if="showUpgradeBanner"
        :entitlement-status="entitlementStatus"
        :feature-name="featureName"
      />
    </template>
    <template #title-icon>
      <flow-custom-linear size="1.042rem" />
    </template>
    <template #list>
      <list-row-item
        v-for="item in flows"
        :key="item.id"
        :class="{ hide: item.deleted }"
        @click="handleItemClick(item.id)"
      >
        <list-row-item-child
          :id="item.id"
          :title="item.title"
          :is-active="!!item.is_active"
          data-test-list-row-item-child="flowbot-list-row-item-child"
          @handle-delete-item="handleDeleteFlowbot(item.id)"
        />
      </list-row-item>

      <infinite-loading ref="infiniteScroller" :identifier="infiniteLoadingResetCounter" @infinite="fetchMoreData">
        <template #spinner>
          <list-row-item-skeleton />
        </template>
        <template #no-more>
          <div></div>
        </template>
        <template #no-results>
          <div></div>
        </template>
      </infinite-loading>
    </template>
    <template #content>
      <div
        v-if="showContent"
        class="flex flex-col items-start justify-start"
        :class="{ absolute: !isInEditPage, 'row-col-fullscreen': fullscreen }"
      >
        <router-view
          v-if="loaded"
          :key="$route.params.id"
          :flows-prop="flows"
          @update="update"
          @call-parent-handleDeleteFlowbot-method="handleDeleteFlowbot"
        ></router-view>
      </div>
    </template>
  </settings-page>
  <div v-else class="row-body">
    <admin-section-title :title="$t('flowbot.list_heading_title_flowbots')"></admin-section-title>
    <div class="row-col">
      <div class="row-col">
        <div id="settings-primary" class="col-xs modal fade aside aside-md b-r w-medium w-200px">
          <div class="nav-settings light left b-primary row-col">
            <ul>
              <li class="text-md white header">
                <strong>{{ $t('flowbot.list_heading_title_flowbots') }}</strong>
                <a data-dismiss="modal" class="hidden-md-up text-muted m-l-auto m-r-sm m-l-1 text-lg">×</a>
                <a class="m-l-auto btn btn-icon btn-sm success rounded text-white">
                  <i class="material-icons md-18" @click="$router.push('/admin/flows/create')">add</i>
                </a>
              </li>
            </ul>
            <div class="row-row">
              <div class="row-body scrollable hove">
                <div class="row-inner">
                  <ul>
                    <li v-for="item in flows" :key="item.id">
                      <router-link
                        class="nav-settings-link text-truncate"
                        :to="'/admin/flows/' + item.id + ''"
                        :class="{ active: $route.params.id == item.id }"
                      >
                        <span class="float-right">
                          <span v-if="item.is_active">
                            <label class="label success">{{ $t('flowbot.list_label_active') }}</label>
                          </span>
                        </span>
                        {{ item.title }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-col">
          <div class="row-row">
            <div class="row-body scrollable">
              <div class="row-inner" :class="{ 'row-col-fullscreen': fullscreen }">
                <router-view
                  v-if="loaded"
                  :key="$route.params.id"
                  :flows-prop="flows"
                  @update="update"
                  @call-parent-handleDeleteFlowbot-method="handleDeleteFlowbot"
                ></router-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { FlowCustomLinear } from '@trengo/trengo-icons';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import InfiniteLoading from 'vue-infinite-loading';

import ListRowItem from '@/components/common/ListRow/ListRowItem';
import ListRowItemSkeleton from '@/components/common/ListRowItemSkeleton';
import SettingsPage from '@/components/common/SettingsPage';
import { ENTITLEMENT_STATUS, FEATURE, FEATURE_FLAG_SETTINGS, PRICING_MODEL } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useCompanyProfileStore, useEntitlementsStore, useFeatureFlagStore, useSubscriptionStore } from '@/store/pinia';

import { deleteFlow, getFlows } from './api';
import UpgradeFlowbotBanner from './UpgradeFlowbotBanner.vue';
import ListRowItemChild from '../common/ListRowItemChild';

import type { StateChanger } from 'vue-infinite-loading';

const FLOW_LIST_PAGE_SIZE = 25;

export default defineComponent({
  name: 'FlowbotList',
  components: {
    SettingsPage,
    InfiniteLoading,
    ListRowItemChild,
    ListRowItemSkeleton,
    ListRowItem,
    FlowCustomLinear,
    UpgradeFlowbotBanner,
  },
  data() {
    return {
      loaded: false,
      flows: [],
      fullscreen: false,
      infiniteLoadingResetCounter: 0,
      page: 1,
      ENTITLEMENT_STATUS,
      featureName: 'Flowbots',
    };
  },
  watch: {
    $route(to) {
      if (to.fullPath === '/admin/flows') {
        this.loadList();
        eventBus.$on('loadList', this.loadList);
      }
    },
  },
  mounted() {
    this.loadList();
    eventBus.$on('loadList', this.loadList);
    eventBus.$on('flowbot.editor.fullscreen', () => (this.fullscreen = !this.fullscreen));
    eventBus.$on('flowbot.editor.closeFullscreen', this.closeFullscreen);
  },
  unmounted() {
    eventBus.$off('loadList', this.loadList);
    eventBus.$off('flowbot.editor.fullscreen', this.toggleFullscreen);
    eventBus.$off('flowbot.editor.closeFullscreen', this.closeFullscreen);
  },
  computed: {
    ...mapStores(useCompanyProfileStore, useEntitlementsStore),
    showUpgradeBanner() {
      return !this.isEntitled && this.hasUsageBasedSubscription;
    },
    hasUsageBasedSubscription() {
      return this.companyProfileStore.isUsageBasedPricingModel;
    },
    entitlementStatus() {
      const flowsQuantity = this.flows.length + 1;
      const entitlementStatus = this.entitlementsStore?.getEntitlementStatusFor(
        FEATURE.CUSTOMER_SERVICE__AUTOMATION__FLOWBOT,
        flowsQuantity
      );
      return entitlementStatus;
    },
    fullHeightInEdit() {
      const flowsAreMorThanFive = this.flows.length > 5;
      return this.isCreateRoute() || this.$route.params.id || flowsAreMorThanFive ? 'h-full' : '';
    },
    showItems() {
      const hasFlows = this.flows?.length > 0;
      return hasFlows && !this.isCreateRoute() && !this.$route.params.id;
    },
    isFeatureFlagEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_SETTINGS.TA_SETTINGS_FLOWBOT);
    },
    showContent() {
      return this.isInEditPage || (this.flows.length === 0 && this.loaded);
    },
    isInEditPage() {
      return Boolean(this.$route.params.id) || this.isCreateRoute();
    },
    isEntitled() {
      const featureId = FEATURE.CUSTOMER_SERVICE__AUTOMATION__FLOWBOT;
      const flowsQuantity = this.flows.length + 1;
      return this.entitlementsStore?.isEntitledTo(featureId, flowsQuantity);
    },
    primaryButtonText() {
      if (!this.isEntitled && !this.hasUsageBasedSubscription) {
        return this.$t('entitlement.unlock_feature', {
          featureName: 'Flowbots',
        });
      } else {
        return this.$t('admin_inboxes.add_flowbot');
      }
    },
  },

  methods: {
    closeFullscreen() {
      this.fullscreen = false;
    },
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    async handleDeleteFlowbot(id: number) {
      try {
        const shouldDelete = await this.$tConfirm(this.$t('flowbot.are_you_sure_you_want_to_delete_this_flowbot'), {
          delete: true,
          title: this.$t('general.are_you_sure'),
        });

        if (!shouldDelete) {
          return;
        }
        await deleteFlow(id);
        this.flashSuccess(this.$t('flowbot.the_flowbot_has_been_deleted_successfully'));
        this.fullscreen = false;

        const flow = this.flows.find((flow) => flow.id === id);
        if (flow) {
          this.flows.splice(this.flows.indexOf(flow), 1);
        }
        if (this.$refs.infinteScroller) {
          const { complete, loaded, reset, error } = this.$refs.infiniteScroller.stateChanger;
          this.fetchMoreData({ complete, loaded, reset, error });
        }
        this.$router.push('/admin/flows');
      } catch (e) {
        console.error('Error when deleting flowbot', e);
      }
    },
    async fetchMoreData(scroll: StateChanger) {
      if (this.flows?.length < FLOW_LIST_PAGE_SIZE) {
        scroll.complete();
        scroll.loaded();
        return;
      }
      if (this.page === 1) {
        this.page++;
      }
      try {
        const hasPagination = Boolean(this.isFeatureFlagEnabled);
        const result = await getFlows(this.page, hasPagination);
        const newFlows = 'data' in result.data ? result.data.data : result.data;

        if (newFlows?.length > 0) {
          this.flows = [...this.flows, ...newFlows];
          this.page++;
          scroll.loaded();
        } else {
          scroll.complete();
        }
      } catch (error) {
        console.error(error);
      }
    },
    handleItemClick(id: number) {
      this.$router.push(`/admin/flows/${id}`);
      this.page = 1;
    },
    handlePrimaryButtonClick(): void {
      this.primaryButtonText === this.$t('admin_inboxes.add_flowbot')
        ? this.$router.push('/admin/flows/create')
        : this.$router.push('/admin/subscription_settings');
    },
    isCreateRoute(): boolean {
      return this.$route.fullPath === '/admin/flows/create';
    },
    async loadList() {
      try {
        const hasPagination = Boolean(this.isFeatureFlagEnabled);
        this.page = 1;
        const result = await getFlows(this.page, hasPagination);
        this.flows = 'data' in result.data ? result.data.data : result.data;
        this.loaded = true;
      } catch (error) {
        console.error(error);
      }
    },
    update(data) {
      this.loadList();
      if (data && data.id) {
        this.$router.push('/admin/flows/' + data.id);
      }
    },
  },
});
</script>

<style>
.row-col-fullscreen {
  display: block;
  z-index: 9999;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed !important;
  top: 0;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  background: white;
}
</style>
