<template>
  <div class="block" data-test="get-started-card">
    <div :class="theTaskIsDone" class="relative overflow-hidden rounded-lg border-1 p-4 shadow-xs sm:px-6 sm:py-8">
      <div
        data-test="is-done"
        v-if="isDone"
        class="
          done-badge
          align-center
          absolute
          left-0
          top-0
          flex
          h-16px
          w-16px
          items-center
          justify-center
          rounded-full
          bg-leaf-400
          text-white
        "
      >
        <img :src="`${$root.assetsURL}img/get_started_page/tick.svg`" class="get-started-done-icon" />
      </div>
      <div class="flex flex-row items-start sm:items-center sm:px-2">
        <div class="mr-4" data-test="icon">
          <slot name="icon" />
        </div>
        <div class="get-started-card-right-column flex flex-col">
          <div class="sm:mb-0">
            <span data-test="title" class="t-text-desktop-paragraph-sm text-grey-600">
              <slot name="title" />
            </span>
          </div>
          <div>
            <span data-test="description" class="t-text-mobile-h6 sm:t-text-desktop-h6">
              <slot name="description" />
            </span>
          </div>
        </div>
      </div>
      <div v-if="$slots.default" data-test="items" class="mt-6 sm:mt-8">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import breakpoints from '@/util/breakpoints';

export default {
  name: 'GetStartedCard',
  data() {
    return {
      breakpoints,
    };
  },
  props: {
    isDone: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    theTaskIsDone() {
      return this.isDone ? 'is-done' : 'not-done';
    },
    isLgOrXl() {
      const { comparisons } = this.breakpoints;
      return comparisons.eq_lg || comparisons.eq_xl;
    },
    iconSize() {
      return this.isLgOrXl ? '1rem' : '.7rem';
    },
  },
};
</script>

<style src="./GetStartedCard.scss" lang="scss" scoped />
