import FaceeBookGif from '../../../assets/facebook.mp4';
import GmailGif from '../../../assets/google.mp4';
import OutlookGif from '../../../assets/outlook.mp4';

export const mapGifsBaseOnChannels = {
  gmail: GmailGif,
  outlook: OutlookGif,
  facebook: FaceeBookGif,
  instagram: FaceeBookGif,
};
