import type { ChannelItem } from '../types';
import type { TranslationFunction } from '@/types';

type Channels = {
  gmail: ChannelItem;
  outlook: ChannelItem;
  another_provider: ChannelItem;
  facebook: ChannelItem;
  instagram: ChannelItem;
  livechat: ChannelItem;
};

function channels(t: TranslationFunction): Channels {
  return {
    gmail: {
      title: t('onboarding.channel_connect_gmail_title'),
      body: t('onboarding.channel_connect_gmail_body'),
      info: t('onboarding.channel_connect_gmail_info_content'),
      success_btn: t('onboarding.channel_connect_gmail_success_button'),
      cancel_btn: t('onboarding.channel_connect_gmail_cancel_button'),
    },
    outlook: {
      title: t('onboarding.channel_connect_outlook_title'),
      body: t('onboarding.channel_connect_outlook_body'),
      info: t('onboarding.channel_connect_outlook_info_content'),
      success_btn: t('onboarding.channel_connect_outlook_success_button'),
      cancel_btn: t('onboarding.channel_connect_outlook_cancel_button'),
    },
    another_provider: {
      title: t('onboarding.channel_connect_other_provider_title'),
      body: t('onboarding.channel_connect_other_provider_body'),
      sub_content: t('onboarding.channel_connect_other_provider_sub_content'),
      info: t('onboarding.channel_connect_other_provider_info_content'),
      success_btn: t('onboarding.channel_connect_other_provider_success_button'),
      cancel_btn: t('onboarding.channel_connect_other_provider_cancel_button'),
    },
    facebook: {
      title: t('onboarding.channel_connect_facebook_title'),
      body: t('onboarding.channel_connect_facebook_body'),
      info: t('onboarding.channel_connect_facebook_info_content'),
      success_btn: t('onboarding.channel_connect_facebook_success_button'),
      cancel_btn: t('onboarding.channel_connect_facebook_cancel_button'),
    },
    instagram: {
      title: t('onboarding.channel_connect_instagram_title'),
      body: t('onboarding.channel_connect_instagram_body'),
      info: t('onboarding.channel_connect_instagram_info_content'),
      success_btn: t('onboarding.channel_connect_instagram_success_button'),
      cancel_btn: t('onboarding.channel_connect_instagram_cancel_button'),
    },
    livechat: {
      title: t('onboarding.channel_connect_instagram_title'),
      body: t('onboarding.channel_connect_instagram_body'),
      sub_content: t('onboarding.channel_connect_instagram_sub_content'),
      info: t('onboarding.channel_connect_instagram_info_content'),
      success_btn: t('onboarding.channel_connect_instagram_success_button'),
      cancel_btn: t('onboarding.channel_connect_instagram_cancel_button'),
    },
  };
}

export default channels;
