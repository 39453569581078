export const ADDON = {
  API_ACCESS: 'addon__api_access',
  BROADCASTING: 'addon__broadcasting',
  CHATBOT: 'addon__chatbot',
  DATA_RETENTION: 'addon__data_retention',
  FLOWBOT: 'addon__flowbot',
  VOICE: 'addon__voice',
  WHATSAPP_BUSINESS: 'addon__whatsapp_business',
} as const;

export const ADDON_NAME = {
  API_ACCESS: 'API Access',
  BROADCASTING: 'Broadcasting',
  CHATBOT: 'Chatbot',
  DATA_RETENTION: 'Data Retention',
  FLOWBOT: 'Flowbot',
  VOICE: 'Voice',
  WHATSAPP_BUSINESS: 'WhatsApp Business',
} as const;
