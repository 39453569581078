import dompurify from 'dompurify';

/**
 *
 * @param email
 * @returns {boolean}
 */
export const validateEmail = function (email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const openLink = (url) => {
  if (window.isLoadedFromApp) {
    parent.postMessage('app-browse-url:' + encodeURI(url), '*');
  } else {
    window.open(url, '_blank', 'noreferrer,noopener');
  }
};

export const copyToClipboard = (text) => {
  if (!navigator) return;
  navigator.clipboard.writeText(text);
};

export const isServer = () => typeof window === 'undefined';

export const placeCaretAtStart = (el) => {
  el.focus();
  if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
    const range = document.createRange();
    range.setStart(el, 0);
    range.collapse(false);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange !== 'undefined') {
    const textRange = document.body.createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(false);
    textRange.select();
  }
};

export const debug = (...val) => {
  const url = new URL(window.location.href);
  if (!url.searchParams.has('debug')) {
    return;
  }
  console.log(...val);
};
/**
 * removes html-tags (like strip_tags + html_entities_decode combined)
 *
 * @param html
 * @returns {string}
 */
export const stripHtml = (html) => {
  let dom = new DOMParser().parseFromString(html, 'text/html');
  return dom.documentElement.innerText;
};

/**
 * Removes all tags and returns the innerText without executing scripts.
 * @param html
 * @returns {string}
 */
export const htmlToText = (html) => stripHtml(dompurify.sanitize(html, { ALLOWED_TAGS: [] }));

export const duplicateObject = (v) => JSON.parse(JSON.stringify(v));

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
