<template>
  <div id="LinkTicketModal" ref="modal" class="modal" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('tickets.related_tickets_title') }}</h5>
          <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="p-3">
          <p>{{ $t('tickets.related_tickets_intro') }}</p>
          <div class="flex w-full items-center">
            <div class="input-group search-box">
              <input
                ref="input"
                v-model="term"
                type="text"
                :placeholder="$t('tickets.related_tickets_search')"
                @keyup.enter="search"
              />
              <div class="input-group-addon remove-addon">
                <i class="fa fa-search text-grey-600" @click="search"></i>
              </div>
            </div>
          </div>
        </div>
        <table v-if="searchedTerm != ''" class="mt-2 table">
          <thead>
            <tr>
              <th width="40%">{{ $t('tickets.related_tickets_ticket') }}</th>
              <th width="40%">{{ $t('tickets.related_tickets_date') }}</th>
              <th width="20%">#</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="searching">
              <td colspan="3" align="center">{{ $t('tickets.related_tickets_searching') }}...</td>
            </tr>
            <tr v-for="ticket in ticketsArray" :key="ticket.id">
              <td>
                #{{ ticket.id }}
                <br />
                {{ ticket.contact.full_name }}
                <br />
                {{ ticket.subject }}
              </td>
              <td><datetime :time="ticket.created_at" :pretty="$root.prettyDates"></datetime></td>
              <td valign="center">
                <button class="btn btn-xs btn-default" :disabled="busy" @click="addRelated(ticket.id)">
                  {{ $t('tickets.related_tickets_link') }}
                </button>
              </td>
            </tr>
            <tr v-if="tickets.length === 0 && !searching">
              <td colspan="3" align="center">{{ $t('tickets.related_tickets_no_results') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import eventBus from '@/eventBus';

export default {
  data: function () {
    return {
      ticket: {},
      searching: false,
      term: '',
      tickets: [],
      searchedTerm: '',
      busy: false,
      searched: false,
    };
  },
  computed: {
    ticketsArray() {
      return !this.searching ? this.tickets : [];
    },
  },
  mounted: function () {
    eventBus.$off('modals.add-related-ticket.open');
    eventBus.$on('modals.add-related-ticket.open', (data) => {
      this.ticket = data.ticket;
      this.openModal();
      this.$refs.input.focus();

      this.searching = false;
      this.term = this.searchedTerm = '';
      this.tickets = [];
    });
  },

  methods: {
    openModal() {
      $(this.$refs.modal).modal({
        keyboard: false,
      });
    },

    search() {
      if (this.searching || (this.term.length > 0 && this.term === this.searchedTerm)) {
        return;
      }

      if (this.term.length === 0) {
        this.tickets = [];
        this.searching = false;
        this.term = this.searchedTerm = '';
        return;
      }

      this.searchedTerm = this.term;

      this.searching = true;

      this.tickets = [];
      axios.get('/api/v2/tickets?page=1&sort=-date&term=' + this.term).then((res) => {
        this.tickets = res.data.data.filter((t) => {
          return t.id !== this.ticket.id;
        });
        this.searching = false;
      });
    },

    closeModal() {
      $(this.$refs.modal).modal('hide');
      this.tickets = [];
    },

    addRelated(related_ticket_id) {
      if (this.busy) {
        return;
      }
      this.busy = true;
      axios
        .post('/api/v2/tickets/' + this.ticket.id + '/relations', {
          related_ticket_id: related_ticket_id,
        })
        .then((res) => {
          this.busy = false;
          this.closeModal();
          eventBus.$emit('ticket.current.reload');
          eventBus.$emit('panels.collapseTicketSidebarRelations.open');
        })
        .catch(() => {
          this.busy = false;
        });
    },
  },
};
</script>
