import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3deaf812"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "mb-8 mt-6 flex flex-col flex-nowrap justify-center gap-5 pb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_filter_dropdown = _resolveComponent("date-filter-dropdown")!
  const _component_stats_workload = _resolveComponent("stats-workload")!
  const _component_workload_management_chart = _resolveComponent("workload-management-chart")!
  const _component_average_ticket_created_chart = _resolveComponent("average-ticket-created-chart")!
  const _component_reporting_page = _resolveComponent("reporting-page")!

  return (_openBlock(), _createBlock(_component_reporting_page, {
    "heading-title": _ctx.$t('reports_v2.workload_management'),
    class: "w-full"
  }, {
    right: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_date_filter_dropdown, {
          timezone: _ctx.$root.user.timezone,
          "default-label": _ctx.$t('reports_v2.last_seven_days'),
          "default-time": _ctx.dateInterval,
          onChange: _ctx.setDateAndInterval
        }, null, 8 /* PROPS */, ["timezone", "default-label", "default-time", "onChange"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.dateInterval.startDate)
          ? (_openBlock(), _createBlock(_component_stats_workload, {
              key: 0,
              filters: _ctx.dateInterval
            }, null, 8 /* PROPS */, ["filters"]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", null, [
        (_ctx.dateInterval.startDate)
          ? (_openBlock(), _createBlock(_component_workload_management_chart, {
              key: 0,
              filters: _ctx.dateInterval
            }, null, 8 /* PROPS */, ["filters"]))
          : _createCommentVNode("v-if", true)
      ]),
      (_ctx.dateInterval.startDate)
        ? (_openBlock(), _createBlock(_component_average_ticket_created_chart, {
            key: 0,
            filters: _ctx.dateInterval
          }, null, 8 /* PROPS */, ["filters"]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["heading-title"]))
}