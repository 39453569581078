<template>
  <p class="t-text-sm m-0 text-right text-grey-600">{{ getTime }}</p>
</template>

<script lang="ts">
import moment from 'moment';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'TicketInsightDateCell',
  props: {
    time: {
      type: String,
      default: '0',
    },
  },
  computed: {
    getTime() {
      return this.time ? moment(this.time).format('DD/MM/YY, HH:mm') : '--:--';
    },
  },
});
</script>
