<template>
  <modal-base
    v-model="computedValue"
    :title="$t('apps_integration.title_add_an_integration')"
    :sub-title="$t('apps_integration.subtitle_add_an_integration')"
  >
    <template #content>
      <div class="mb-6">
        <integrations-list />
      </div>
    </template>
  </modal-base>
</template>

<script>
import ModalBase from '../../ModalBase';
import IntegrationsList from '../IntegrationsList';
export default {
  name: 'AddIntegrationModal',
  emits: ['update:modelValue'],
  components: { IntegrationsList, ModalBase },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
