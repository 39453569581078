<template>
  <div v-if="loaded" class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis" v-text="record.name"></span>
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <div class="p-a p-b-0 container">
            <div class="p-a">
              <form @submit.prevent="save">
                <div class="box">
                  <div class="box-header">
                    <h2>{{ $t('company_profile.subscription_page_location') }}</h2>
                  </div>
                  <div class="box-divider m-a-0"></div>
                  <div class="box-body">
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('company_profile.subscription_page_name') }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-10">
                        <input v-model="record.name" type="text" autocomplete="off" class="form-control" />
                        <span class="form-text">
                          {{ $t('company_profile.subscription_page_the_name_of_the_location') }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('company_profile.subscription_page_team') }}
                      </label>
                      <div class="col-sm-10">
                        <multiselect
                          v-model="record.teams"
                          label="name"
                          track-by="id"
                          :allow-empty="false"
                          :options="$root.teams"
                          :searchable="true"
                          :close-on-select="true"
                          :show-labels="false"
                          :multiple="false"
                          :placeholder="$t('company_profile.subscription_page_placeholder_select_a_team')"
                        ></multiselect>
                        <span class="form-text">
                          {{ $t('company_profile.subscription_page_the_team_that_manages_this_location') }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('company_profile.subscription_page_identifiers') }}
                      </label>
                      <div class="col-sm-10">
                        <multiselect
                          ref="identifiers"
                          v-model="record.identifiers"
                          :options="record.identifiers"
                          :multiple="true"
                          :taggable="true"
                          :close-on-select="false"
                          tag-placeholder="Add this as new identifier"
                          :placeholder="$t('company_profile.subscription_page_placeholder_add_an_identifier')"
                          label="identifier"
                          track-by="identifier"
                          @tag="addIdentifier"
                          @search-change="checkAndOrAddIdentifierBySeparator()"
                        ></multiselect>
                        <span class="form-text">
                          {{ $t('company_profile.subscription_page_identifiers_can_also_be_entered_with_separators') }}
                        </span>
                        <span class="form-text">
                          {{
                            $t('company_profile.subscription_page_the_identifiers_to_identify_this_location_eg_utrecht')
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div class="box-body flow-root">
                    <button
                      type="submit"
                      :class="{ loader: saving }"
                      class="btn success text-white"
                      :disabled="saving || !ready"
                      v-text="saving ? 'Saving..' : this.$t('company_profile.subscription_page_save_changes')"
                    ></button>
                    <button
                      v-if="action == 'edit'"
                      type="button"
                      class="btn white pull-right"
                      @click="deleteLocation()"
                    >
                      {{ $t('company_profile.subscription_page_delete_location') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import Multiselect from 'vue-multiselect';

import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';

export default {
  name: 'EditLocation',
  emits: ['call-loadlist-method'],
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },

  components: {
    Multiselect,
  },

  computed: {
    ready() {
      return true;
    },
  },

  data() {
    return {
      loaded: false,
      record: {
        identifier: null,
        teams: [],
        identifiers: [],
      },
      saving: false,
    };
  },

  mounted() {
    if (this.action === 'edit') {
      axios.get('/api/v2/location/' + this.$route.params.id).then((result) => {
        this.record = result.data;

        this.loaded = true;
      });
    } else {
      this.loaded = true;
    }
  },

  methods: {
    checkAndOrAddIdentifierBySeparator() {
      [',', ';', '|'].forEach((separator) => {
        if (this.$refs.identifiers.search.includes(separator)) {
          this.$refs.identifiers.search
            .split(separator)
            .filter((identifier) => {
              return identifier.trim();
            })
            .forEach((identifier) => {
              this.addIdentifier(identifier);
            });
          this.$refs.identifiers.search = '';
        }
      });
    },

    addIdentifier(identifier) {
      if (
        this.record.identifiers.filter((id) => {
          return id.identifier === identifier;
        }).length > 0
      ) {
        return;
      }
      this.record.identifiers.push({
        identifier: identifier,
      });
    },

    save() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      if (this.action === 'create') {
        return this.storeLocation();
      } else {
        this.updateLocation();
      }
    },

    storeLocation() {
      axios
        .post('/api/v2/location', { ...this.record })
        .then((res) => {
          this.saving = false;
          this.$emit('call-loadlist-method');
          router.push(`${SETTINGS_URL.ADMIN__LOCATIONS}/${res.data.id}`);
          this.flashSuccess('The location has been created successfully.');
        })
        .catch(() => {
          this.saving = false;
        });
    },

    updateLocation() {
      axios
        .put('/api/v2/location/' + this.record.id, { ...this.record })
        .then((res) => {
          this.saving = false;
          this.$emit('call-loadlist-method');
          this.flashSuccess('The location has been updated successfully.');
        })
        .catch(() => {
          this.saving = false;
        });
    },

    async deleteLocation() {
      const shouldDeleteLocation = await this.$tConfirm('Are you sure you want to delete this location?', {
        delete: true,
        title: this.$t('general.are_you_sure'),
      });
      if (shouldDeleteLocation) {
        axios.delete('/api/v2/location/' + this.record.id).then(() => {
          this.$emit('call-loadlist-method');
          router.push(SETTINGS_URL.ADMIN__LOCATIONS);
          this.flashSuccess('The location has been deleted successfully.');
        });
      }
    },
  },
};
</script>
