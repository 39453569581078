import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "t-text-h3 my-auto max-w-[90%] p-0" }
const _hoisted_2 = {
  class: "t-text-md text-grey-800",
  "data-test": "integration-description"
}
const _hoisted_3 = { class: "m-0 p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_linear = _resolveComponent("info-linear")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_modal = _resolveComponent("t-modal")!

  return (_openBlock(), _createBlock(_component_t_modal, {
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isOpen) = $event)),
    "close-on-backdrop-click": true,
    "close-icon": true,
    variant: "narrow",
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('integration_hub.installed_modal_title', { name: _ctx.integration.name })), 1 /* TEXT */)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_t_button, {
        "data-test": "continue-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('integration_hub.start_using_button', { name: _ctx.integration.name })), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('integration_hub.installed_modal_description', { name: _ctx.integration.name })), 1 /* TEXT */),
      (_ctx.integration.filterType === 'crm')
        ? (_openBlock(), _createBlock(_component_t_inline_banner, {
            key: 0,
            class: "mb-4"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_info_linear)
            ]),
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('integration_hub.agents_must_sign_in', { name: _ctx.integration.name })), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}