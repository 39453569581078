<template>
  <span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-stroke">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.9031 18.761C20.7068 19.0202 20.5063 19.2796 20.305 19.5399C19.8961 20.069 19.4724 20.6165 19.0824 21.166C18.3375 22.2165 17.2252 22.4998 15.6809 22.0325C10.4927 20.4616 2.87994 10.9123 2.75159 5.81398C2.71699 4.42862 3.24786 3.58609 4.35011 3.16417C4.46506 3.12017 5.67105 2.51875 6.13292 2.31135C6.42469 2.18765 6.7002 2.07059 6.92532 1.96514C7.23763 1.81951 7.56326 1.75019 7.88131 1.75019C8.70923 1.75019 9.48926 2.21862 9.86983 3.02734L11.023 5.48121C11.2728 6.01213 11.2989 6.61055 11.0963 7.16691C10.8941 7.72327 10.4893 8.16665 9.95664 8.41494L8.76248 9.20474C8.51215 9.32143 8.36914 9.58874 8.41206 9.8612C8.61151 11.1352 12.5817 15.8719 13.4537 16.1928C13.7144 16.2889 14.0058 16.2011 14.1699 15.9784L15.2375 14.7956C15.2375 14.7956 15.8969 13.8206 16.5043 13.7281C17.2724 13.6111 17.6754 13.7805 18.1496 14.1274L20.3413 15.7314C20.8001 16.0671 21.1296 16.6474 21.223 17.2837C21.307 17.8535 21.193 18.3781 20.9031 18.761Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="svg-stroke"
      />
    </svg>

    <span class="icon-active">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.9031 18.761C20.7068 19.0202 20.5063 19.2796 20.305 19.5399C19.8961 20.069 19.4724 20.6165 19.0824 21.166C18.3375 22.2165 17.2252 22.4998 15.6809 22.0325C10.4927 20.4616 2.87994 10.9123 2.75159 5.81398C2.71699 4.42862 3.24786 3.58608 4.35011 3.16417C4.46506 3.12017 5.67105 2.51875 6.13292 2.31135C6.42469 2.18765 6.7002 2.07059 6.92532 1.96514C7.23763 1.81951 7.56326 1.75019 7.88131 1.75019C8.70923 1.75019 9.48926 2.21862 9.86983 3.02734L11.023 5.48121C11.2728 6.01213 11.2989 6.61054 11.0963 7.16691C10.8941 7.72327 10.4893 8.16665 9.95664 8.41494L8.76248 9.20474C8.51215 9.32143 8.36914 9.58873 8.41206 9.8612C8.61151 11.1352 12.5817 15.8719 13.4537 16.1928C13.7144 16.2889 14.0058 16.2011 14.1699 15.9784L15.2375 14.7956C15.2375 14.7956 15.8969 13.8206 16.5043 13.7281C17.2724 13.6111 17.6754 13.7805 18.1496 14.1274L20.3413 15.7314C20.8001 16.0671 21.1296 16.6474 21.223 17.2837C21.307 17.8535 21.193 18.3781 20.9031 18.761Z"
          fill="white"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  </span>
</template>
