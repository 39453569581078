import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { fetchScheduledChanges } from '@/api';
import { SUBSCRIPTION_ADDON_CHARGEBEE_TYPE } from '@/Configs/Constants';

import type { PlanPriceId, ScheduledChangeItem, SubscriptionAddonDetailType } from '@/types';

export const useSubscriptionChangesStore = defineStore('subscriptionChanges', () => {
  const scheduledChangesToUpdate = ref<ScheduledChangeItem[]>([]);
  const scheduledChangesToRemove = ref<PlanPriceId[]>([]);
  const scheduledChangeDate = ref<EpochTimeStamp>();

  const loadScheduledChanges = async () => {
    try {
      const {
        data: { scheduled_to_update = [], scheduled_to_remove = [], effective_from },
      } = await fetchScheduledChanges();
      scheduledChangesToUpdate.value = scheduled_to_update;
      scheduledChangesToRemove.value = scheduled_to_remove;
      scheduledChangeDate.value = effective_from;
    } catch (error) {
      console.error(error);
    }
  };

  const init = () => {
    loadScheduledChanges();
  };

  const getScheduledChangeForAddon = computed(() => {
    return (type: SubscriptionAddonDetailType) => {
      const scheduledChangeToUpdate = scheduledChangesToUpdate.value?.find((change) =>
        change.item_price_id.includes(type)
      );

      if (scheduledChangeToUpdate) {
        if (type === SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.CARRIER_COST_BALANCE) {
          return scheduledChangeToUpdate.quantity * scheduledChangeToUpdate.unit_price;
        }
        return scheduledChangeToUpdate.quantity;
      } else if (scheduledChangesToRemove.value?.find((change) => change.includes(type))) {
        return 0;
      }
    };
  });

  return {
    getScheduledChangeForAddon,
    scheduledChangeDate,
    init,
    reload: loadScheduledChanges,
  };
});
