<template>
  <list-row-item :data-test-list-row-item="dataTestListRowItem">
    <div class="flex w-full items-center justify-between" :data-test="dataTestListRowItemLoader">
      <div class="h-2 w-3/6 rounded-3xl bg-grey-300"></div>
      <div class="flex w-2/6 items-center justify-between">
        <div class="mr-4 h-2 w-3/6 rounded-3xl bg-grey-300"></div>
        <div class="h-2 w-3/6 rounded-3xl bg-grey-300"></div>
      </div>
    </div>
  </list-row-item>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import ListRowItem from '@/components/common/ListRow/ListRowItem';

export default defineComponent({
  name: 'ListRowItemLoader',
  components: { ListRowItem },
  props: {
    dataTestListRowItem: {
      type: String,
      default: 'list-row-item',
    },
    dataTestListRowItemLoader: {
      type: String,
      default: 'list-row-item-loader',
    },
  },
});
</script>
