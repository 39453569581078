<style lang="scss">
.panel-gif-browser {
  height: 333px;
  bottom: 92px;
  width: 350px;
  background: white;
  padding: 10px 10px 10px 10px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  box-shadow: 0px -5px 22px rgba(0, 0, 0, 0.1);

  .panel-gif-browser-top {
    display: flex;
    height: 40px;
    margin-bottom: 10px;
    min-height: 40px;
    .panel-gif-browser-search {
      position: relative;
      flex: 1;

      input {
        border-radius: 10px;
        background: #f7f7f7;
        width: 100%;
        border: none;
        outline: none;
        font-size: 14px;
        padding: 10px;
        padding-right: 80px;
      }
    }

    .panel-gif-browser-close {
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      .material-icons {
        font-size: 35px;
        cursor: pointer;
      }
    }
  }

  .panel-gifs {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;

    .panel-gif-col {
      flex: 1;

      &:first-child {
        margin-right: 2px;
      }
    }

    img {
      width: 100%;
      margin-bottom: 2px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .panel-gif-powered {
    img {
      position: absolute;
      right: 15px;
      top: 12px;
      width: 50px;
      z-index: 9999;
      opacity: 0.5;
      cursor: pointer;
    }
  }

  .panel-emojis {
    flex: 1;
    flex-direction: column;

    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .emoji-category-title {
      font-weight: bold;
      color: #999999;
      font-size: 14px;
      margin-bottom: 7px;
    }

    .emoji-category {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;

      .emoji {
        margin: 5px;
        font-size: 26px;
        cursor: pointer;
      }
    }
  }
}
</style>

<template>
  <div class="panel-gif-browser">
    <div class="panel-gif-browser-top">
      <div class="panel-gif-browser-search">
        <input ref="input" v-model="term" type="text" placeholder="Search GIPHY.." @keyup.esc="close" />
        <giphy-logo class="panel-gif-powered"></giphy-logo>
      </div>
      <div class="panel-gif-browser-close" @click="close">
        <i class="material-icons">keyboard_arrow_down</i>
      </div>
    </div>
    <div ref="container" class="panel-gifs">
      <div class="panel-gif-col">
        <img
          v-for="(gif, i) in filteredGifs"
          :key="i"
          :src="gif.images.preview_gif.url"
          alt=""
          @click="insertGif($event, gif)"
        />
      </div>
      <div class="panel-gif-col">
        <template v-for="(gif, i) in gifs">
          <img v-if="i % 2 !== 0" :key="i" :src="gif.images.preview_gif.url" alt="" @click="insertGif($event, gif)" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import GiphyLogo from './GiphyLogo';

export default {
  name: 'GifPicker',
  emits: ['insertGif', 'close'],
  components: {
    GiphyLogo,
  },

  data() {
    return {
      term: null,
      gifs: [],
      searchTimer: null,
      searching: true,
    };
  },
  computed: {
    filteredGifs() {
      return this.gifs.filter((gif, i) => i % 2 === 0);
    },
  },
  mounted() {
    this.term = '';
    if (window.innerWidth > 991) {
      this.$refs.input.focus();
    }
  },

  watch: {
    term() {
      this.$refs.container.scrollTop = 0;
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.searching = true;
        window.axios
          .get('/web-widget-api/gifs?term=' + this.term + '&fromWeb')
          .then((res) => {
            this.gifs = res.data;
            this.searching = false;
          })
          .catch(() => {
            this.gifs = [];
            this.searching = false;
          });
      }, 250);
    },
  },

  methods: {
    insertGif(e, gif) {
      this.$emit('insertGif', e, gif);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
