import getClassNameForExtension from 'font-awesome-filetypes';

import store from '../../../store';

export default class MessageAttachmentModel {
  constructor(messageAttachment) {
    this.id = parseInt(messageAttachment.id);
    this.name = messageAttachment.client_name;
    this.size = parseInt(messageAttachment.size);
    this.mime = messageAttachment.mime_type;
    this.url = messageAttachment.url || null;
    this.private = !!messageAttachment.private;
    this.userId = messageAttachment.user_id;
    this.createdAt = Math.floor(new Date(messageAttachment.created_at).getTime());
  }

  getName() {
    return this.name;
  }

  getExtension() {
    return (
      this.getName()
        .substring(this.name.lastIndexOf('.') + 1, this.name.length)
        .toLowerCase() || ''
    );
  }

  getSize() {
    if (this.size > 1000000) {
      return Math.round(this.size / 1000000) + 'MB';
    } else if (this.size > 1000) {
      return Math.round(this.size / 1000) + 'KB';
    } else {
      return this.size + 'bytes';
    }
  }

  getPreviewUrl() {
    // todo: get safe/supported formats ['image/gif', 'image/png', 'image/jpeg'].includes(this.mime) (or even better: generated previews in safe format)
    if (this.isImage() || this.isVideo() || this.isAudio() || this.isPdf()) {
      return this.getUrl();
    } else {
      // todo rl get preview url (saved in db when generating?). also thumbnail for images, possibly via imageproxy. e.g. https://github.com/prisma-archive/serverless-image-proxy
    }
  }

  isImage() {
    return this.mime.startsWith('image/');
  }

  isVideo() {
    return this.mime.startsWith('video/');
  }

  isAudio() {
    return this.mime.startsWith('audio/');
  }

  isPdf() {
    return this.mime.startsWith('application/pdf');
  }

  /*isDocument() {
        return this.mime.startsWith('document/');
    }*/

  getUrl() {
    if (this.private) {
      // todo do API call to get temporary url from AWS, cached locally when called already
    } else {
      // check domain to prevent loading external urls and javacript urls // todo get media domain whitelist from back-end
      return this.url.startsWith('https://trengo-sandbox.s3.eu-central-1.amazonaws.com/') ||
        this.url.startsWith('https://trengo.s3.eu-central-1.amazonaws.com/')
        ? this.url
        : '';
    }
  }

  getIconClass() {
    return getClassNameForExtension(this.getExtension());
  }
}
