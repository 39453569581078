export default {
  namespaced: true,
  state: {
    doNotDisturb: {},
  },
  mutations: {
    setDoNotDisturb(state, doNotDisturb) {
      state.doNotDisturb = doNotDisturb;
    },
  },
  actions: {},
  getters: {},
};
