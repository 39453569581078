import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = { class: "t-text-desktop-label-md mb-2 text-grey-800 sm:mt-4 md:mt-1" }
const _hoisted_3 = { class: "t-text-desktop-label-md mb-2 mt-6 text-grey-800" }
const _hoisted_4 = { class: "md:flex" }
const _hoisted_5 = { class: "md:mr-1 md:w-1/2" }
const _hoisted_6 = { class: "mt-2 md:ml-1 md:mt-0 md:w-1/2" }
const _hoisted_7 = { class: "t-text-desktop-label-md mt-6 text-grey-800" }
const _hoisted_8 = {
  key: 0,
  class: "t-text-sm mt-1 text-grey-600",
  "data-test": "subscription-user-limit-info"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "t-text-desktop-label-md mb-2 mt-6 text-grey-800" }
const _hoisted_11 = { class: "t-text-desktop-label-md mb-2 mt-6 text-grey-800" }
const _hoisted_12 = { class: "t-text-desktop-label-md mb-2 mt-6 inline-flex items-center text-grey-800" }
const _hoisted_13 = {
  key: "tooltipPlan",
  class: "tooltip-user-modal ml-1.5 flex h-16px w-16px cursor-default items-center justify-center rounded-full bg-grey-600 font-bold text-white hover:bg-grey-800"
}
const _hoisted_14 = { class: "t-text-desktop-label-md text-grey-800" }
const _hoisted_15 = { class: "t-text-desktop-label-md text-grey-600" }
const _hoisted_16 = { class: "text-error-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_atomic_input = _resolveComponent("atomic-input")!
  const _component_t_error_item = _resolveComponent("t-error-item")!
  const _component_t_dropdown = _resolveComponent("t-dropdown")!
  const _component_info_linear = _resolveComponent("info-linear")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!
  const _component_t_multi_select = _resolveComponent("t-multi-select")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_modal_base = _resolveComponent("modal-base")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_modal_base, {
    modelValue: $options.computedValue,
    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($options.computedValue) = $event)),
    "submit-button-text": $options.getSubmitButtonText,
    "cancel-button-text": _ctx.$t('user_management.cancel'),
    class: "edit-user-modal",
    title: _ctx.$t('user_management.edit_user_details'),
    onSubmit: $options.save,
    onClose: $options.closeModal
  }, _createSlots({
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('user_management.email')), 1 /* TEXT */),
          _createVNode(_component_atomic_input, {
            modelValue: $data.user.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.user.email) = $event)),
            size: "md",
            class: "cursor-not-allowed",
            "data-test": "email-input",
            disabled: ""
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('user_management.name')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_atomic_input, {
                modelValue: $data.user.first_name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.user.first_name) = $event)),
                placeholder: _ctx.$t('user_management.first_name'),
                size: "md",
                required: "",
                autofocus: "",
                error: !$data.validation.firstname,
                "data-test": "firstname-input",
                onKeydown: _cache[2] || (_cache[2] = ($event: any) => ($options.handleKeyboardEvent($event)))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "error"]),
              (!$data.validation.firstname)
                ? (_openBlock(), _createBlock(_component_t_error_item, {
                    key: 0,
                    text: _ctx.$t('user_management.validation_first_name_required'),
                    "data-test": "firstname-error"
                  }, null, 8 /* PROPS */, ["text"]))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_atomic_input, {
                modelValue: $data.user.last_name,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.user.last_name) = $event)),
                placeholder: _ctx.$t('user_management.last_name'),
                size: "md",
                error: !$data.validation.lastname,
                "data-test": "lastname-input",
                onKeydown: _cache[4] || (_cache[4] = ($event: any) => ($options.handleKeyboardEvent($event)))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "error"]),
              (!$data.validation.lastname)
                ? (_openBlock(), _createBlock(_component_t_error_item, {
                    key: 0,
                    text: _ctx.$t('user_management.validation_last_name_required'),
                    "data-test": "lastname-error"
                  }, null, 8 /* PROPS */, ["text"]))
                : _createCommentVNode("v-if", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('user_management.user_role')), 1 /* TEXT */),
        (!$options.isEditRoleDropdownEnabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t('user_management.you_have_reached_limit_message')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_openBlock(), _createBlock(_component_t_dropdown, {
          key: _ctx.currentRole,
          modelValue: $options.role,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($options.role) = $event)),
          items: _ctx.roles(),
          placeholder: _ctx.$t('user_management.pick_an_option'),
          size: "md",
          "value-index": "value",
          "text-index": "title",
          error: !$data.validation.role,
          disabled: !$options.isEditRoleDropdownEnabled || _ctx.isOnlyAdministrator,
          "container-text-ellipsis": "",
          class: "mt-2",
          "data-test": "authorization-input"
        }, null, 8 /* PROPS */, ["modelValue", "items", "placeholder", "error", "disabled"])),
        (!$data.validation.role)
          ? (_openBlock(), _createBlock(_component_t_error_item, {
              key: 1,
              text: _ctx.$t('user_management.please_select_a_user_role'),
              "data-test": "authorization-error"
            }, null, 8 /* PROPS */, ["text"]))
          : _createCommentVNode("v-if", true),
        (!$data.saving && _ctx.isOnlyAdministrator)
          ? (_openBlock(), _createBlock(_component_t_inline_banner, {
              key: 2,
              class: "mt-3",
              "data-test": "user-role-validation-info"
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_info_linear)
              ]),
              default: _withCtx(() => [
                (_ctx.isOnlyAdministrator)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('user_management.organisation_must_have_at_least_one_administrator')), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        (!$data.saving && _ctx.isObserver($data.duplicatedUserRecord.role.name) && !_ctx.isObserver($options.role))
          ? (_openBlock(), _createBlock(_component_t_inline_banner, {
              key: 3,
              class: "mt-3",
              "data-test": "user-role-upgrade-info"
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_info_linear)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('user_management.upgrading_this_user_to_another_role_can_not_be_reversed')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('user_management.teams')), 1 /* TEXT */),
        _createVNode(_component_t_multi_select, {
          modelValue: $data.user.teams,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.user.teams) = $event)),
          placeholder: _ctx.$t('user_management.select_one_or_more_teams'),
          "use-icon": false,
          "has-avatar": false,
          "is-closable": "",
          data: $data.teams,
          size: "md",
          variant: "leaf",
          error: !$data.validation.teams,
          "limited-height": "",
          "data-test": "teams-input"
        }, null, 8 /* PROPS */, ["modelValue", "placeholder", "data", "error"]),
        (!$data.validation.teams)
          ? (_openBlock(), _createBlock(_component_t_error_item, {
              key: 4,
              text: _ctx.$t('user_management.please_select_a_team'),
              "data-test": "teams-error"
            }, null, 8 /* PROPS */, ["text"]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('user_management.language')), 1 /* TEXT */),
        _createVNode(_component_t_dropdown, {
          modelValue: $data.user.locale_code,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($data.user.locale_code) = $event)),
          size: "md",
          placeholder: _ctx.$t('user_management.select_a_language'),
          items: $data.languages,
          "value-index": "id",
          "text-index": "title",
          "data-test": "language-input"
        }, null, 8 /* PROPS */, ["modelValue", "placeholder", "items"]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('user_management.account_owner')), 1 /* TEXT */),
          _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_13, [
            _createTextVNode(" i ")
          ])), [
            [_directive_tooltip, {
              placement: 'bottom',
              content: _ctx.$t(
                'user_management.the_account_owner_receives_important_emails_about_invoices_account_and_subscription_updates',
              ),
              popperClass: 'tooltip-vuetify tooltip-medium',
              trigger: $options.getTooltipEvent,
            }]
          ])
        ]),
        _createVNode(_component_t_dropdown, {
          modelValue: $options.isPrimary,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($options.isPrimary) = $event)),
          size: "md",
          items: $data.is_primary,
          "value-index": "id",
          "text-index": "title",
          disabled: _ctx.isOnlyPrimaryUser,
          "data-test": "is-primary-input"
        }, null, 8 /* PROPS */, ["modelValue", "items", "disabled"]),
        (!$data.saving && _ctx.isOnlyPrimaryUser)
          ? (_openBlock(), _createBlock(_component_t_inline_banner, {
              key: 5,
              class: "mt-3",
              "data-test": "account-owner-validation-info"
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_info_linear)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('user_management.you_need_to_make_another_user_the_primary_user_to')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        (!$data.customFieldsMapped)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 6 }, _renderList($data.customFieldsMapped, (customField) => {
              return (_openBlock(), _createElementBlock("div", {
                key: customField.id,
                class: "mt-6"
              }, [
                _createElementVNode("span", _hoisted_14, _toDisplayString(customField.title), 1 /* TEXT */),
                _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t('user_management.optional')), 1 /* TEXT */),
                _createVNode(_component_atomic_input, {
                  modelValue: customField.value,
                  "onUpdate:modelValue": ($event: any) => ((customField.value) = $event),
                  placeholder: customField.title,
                  size: "md",
                  class: "mt-2",
                  "data-test": "custom-field-input",
                  onKeydown: _cache[9] || (_cache[9] = ($event: any) => ($options.handleKeyboardEvent($event)))
                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "placeholder"])
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          : _createCommentVNode("v-if", true),
        (_ctx.canDeleteUser && $props.isMobile)
          ? (_openBlock(), _createBlock(_component_t_button, {
              key: 7,
              "btn-style": "secondary",
              type: "button",
              class: "t-text-button-md mt-8 w-full",
              "data-test": "delete-user-button",
              onClick: $options.deleteUser
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('user_management.delete_user')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 2 /* DYNAMIC */
  }, [
    ($options.userIsUnverified)
      ? {
          name: "footer-left",
          fn: _withCtx(() => [
            _createVNode(_component_t_button, {
              class: "t-text-desktop-button-md",
              "btn-style": "secondary",
              "data-test": "resend-invite-button",
              onClick: _cache[10] || (_cache[10] = ($event: any) => ($options.resendInvite()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('user_management.resend_invite')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          key: "0"
        }
      : undefined
  ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "submit-button-text", "cancel-button-text", "title", "onSubmit", "onClose"]))
}