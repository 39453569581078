import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex flex-col rounded-md border-2 border-grey-300 border-opacity-30 p-1",
  "data-test": "time-entry-card-wrapper"
}
const _hoisted_2 = { class: "flex flex-row items-center" }
const _hoisted_3 = {
  class: "t-text-sm-emphasize m-0 mr-2 p-0 text-grey-700",
  "data-test": "time-entry-card-time"
}
const _hoisted_4 = {
  class: "t-text-sm-emphasize m-0 p-0 text-grey-700",
  "data-test": "time-entry-parsed-date"
}
const _hoisted_5 = { class: "ml-auto flex flex-row" }
const _hoisted_6 = {
  key: 0,
  class: "pl-[24px]"
}
const _hoisted_7 = {
  class: "t-text-sm m-0 ml-1 mt-1 p-0 text-grey-700",
  "data-test": "time-entry-description"
}
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar = _resolveComponent("avatar")!
  const _component_edit3_linear = _resolveComponent("edit3-linear")!
  const _component_edit3_fill = _resolveComponent("edit3-fill")!
  const _component_trash_linear = _resolveComponent("trash-linear")!
  const _component_time_entry = _resolveComponent("time-entry")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.user)
        ? (_openBlock(), _createBlock(_component_avatar, {
            key: 0,
            class: "mr-2",
            width: "24",
            color: _ctx.user.color,
            abbr: _ctx.user.abbr,
            image: _ctx.user.profile_image
          }, null, 8 /* PROPS */, ["color", "abbr", "image"]))
        : (_openBlock(), _createBlock(_component_avatar, {
            key: 1,
            class: "mr-2",
            width: "24",
            color: "#000000",
            abbr: "U"
          })),
      _createElementVNode("p", _hoisted_3, _toDisplayString(`${_ctx.parsedTime.hours}H ${_ctx.parsedTime.minutes}M`), 1 /* TEXT */),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.parseDate(_ctx.entry.start_time)), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: _normalizeClass(["mr-1 text-grey-500 transition-colors hover:text-grey-700 active:text-grey-800", _ctx.isEditing ? 'text-grey-800' : '']),
          "data-test": "edit-entry-button",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isEditing = !_ctx.isEditing), ["prevent"]))
        }, [
          (!_ctx.isEditing)
            ? (_openBlock(), _createBlock(_component_edit3_linear, {
                key: 0,
                size: "1.2rem"
              }))
            : (_openBlock(), _createBlock(_component_edit3_fill, {
                key: 1,
                size: "1.2rem"
              }))
        ], 2 /* CLASS */),
        _createElementVNode("button", {
          class: "text-grey-500 transition-colors hover:text-grey-700 active:text-grey-800",
          "data-test": "time-entry-delete-button",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('delete-entry')), ["prevent"]))
        }, [
          _createVNode(_component_trash_linear, { size: "1.2rem" })
        ])
      ])
    ]),
    (_ctx.entry.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.entry.description), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.isEditing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_time_entry, {
            class: "mt-2",
            "is-editing": true,
            "ticket-id": _ctx.ticketId,
            "entry-id": _ctx.entry.id,
            "edit-date": _ctx.entry.start_time,
            "edit-hours": parseInt(_ctx.parsedTime.hours),
            "edit-minutes": parseInt(_ctx.parsedTime.minutes),
            "edit-description": _ctx.entry.description,
            onEditEntry: _ctx.editEntry
          }, null, 8 /* PROPS */, ["ticket-id", "entry-id", "edit-date", "edit-hours", "edit-minutes", "edit-description", "onEditEntry"])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}