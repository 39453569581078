<script>
export default {
  props: {
    totalUnreadChatMessagesCount: {
      type: Number,
      default: 0,
    },
    totalUnreadChatMentionsCount: {
      type: Number,
      default: 0,
    },
    messageIndicatorClasses: {
      type: String,
      default: 'lg:bg-white bg-grey-900',
    },
  },

  data() {
    return {
      countProxy: this.totalUnreadChatMessagesCount,
      mentionsCountProxy: this.totalUnreadChatMentionsCount,
    };
  },
  computed: {
    countMentions() {
      return this.totalUnreadChatMessagesCount < 100 ? this.totalUnreadChatMentionsCount : '99+';
    },
  },
};
</script>

<template>
  <span v-if="totalUnreadChatMentionsCount > 0 || totalUnreadChatMessagesCount > 0" class="absolute right-0 top-0">
    <t-badge v-if="totalUnreadChatMentionsCount" variant="notification" :text="countMentions.toString()" />
    <span
      v-else-if="totalUnreadChatMentionsCount < 1 && totalUnreadChatMessagesCount > 0"
      class="absolute right-1 top-1 block h-2 w-2 rounded-full"
      :class="messageIndicatorClasses"
    />
  </span>
</template>
