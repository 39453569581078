<template>
  <div class="h-full">
    <logo-header class="bg-grey-100" />
    <div class="justify-content flex h-full items-center bg-grey-100">
      <div style="max-width: 380px; margin: 0 auto; min-width: 350px">
        <div class="alert alert-danger" id="RequestErrors" v-if="alertType == 'error'">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
          {{ alertMessage }}
        </div>
        <div class="alert alert-success" id="LoginErrors" v-if="alertType == 'success'">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
          {{ alertMessage }}
        </div>
        <div
          class="box box-shadow-z2 animated fadeIn shadow"
          style="
            animation-duration: 0.2s;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09) !important;
            border-radius: 10px;
            padding: 2.25rem;
          "
        >
          <div class="text-center">
            <img :src="`${$root.assetsURL}img/trengo-logo.png`" class="mb-8" alt="" style="width: 90%" />
          </div>
          <div class="box-body">
            <form @submit.prevent="requestEmail" class="form-horizontal">
              <div class="form-group row">
                <div class="col-xs-12">
                  <strong>Email:</strong>
                  <input
                    type="email"
                    autofocus
                    required="required"
                    name="email"
                    v-model="email"
                    class="form-control mt-2"
                    placeholder="Email"
                  />
                </div>
              </div>
              <button type="submit" class="btn btn-block btn-lg success rounded">Request password</button>
            </form>
          </div>
          <div class="push pt-4 text-center">
            <router-link to="/auth/login">Login</router-link>
          </div>
        </div>
      </div>
    </div>
    <help-center-button />
  </div>
</template>

<script type="text/babel">
import HelpCenterButton from '@/components/common/HelpCenterButton';
import LogoHeader from '@/components/common/PageHeaders/LogoHeader';

import { useCredentialNotifications } from './useCredentialNotifications';

export default {
  components: { LogoHeader, HelpCenterButton },
  data() {
    return {
      email: null,
      alertMessage: null,
      alertType: null,
    };
  },

  methods: {
    requestEmail() {
      axios
        .post('/reset-password/request-email-submit', {
          email: this.email,
        })
        .then((res) => {
          this.alertMessage = res.data.message;
          this.email = null;
          this.alertType = 'success';
        })
        .catch((err) => {
          this.alertMessage = err.response.data.message;
          this.alertType = 'error';
        });
    },
  },

  mounted() {
    $('#TrengoLoader').hide();

    // Check if password has been reset, if true show notification

    if (useCredentialNotifications().hasNotification) {
      this.alertMessage = useCredentialNotifications().notification;
      this.alertType = 'error';
    }
  },
};
</script>
