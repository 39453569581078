import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 5,
  class: "flex items-center justify-center py-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aggregates = _resolveComponent("aggregates")!
  const _component_csat_cards = _resolveComponent("csat-cards")!
  const _component_graph_report = _resolveComponent("graph-report")!
  const _component_table_report = _resolveComponent("table-report")!
  const _component_no_csats_message = _resolveComponent("no-csats-message")!
  const _component_t_spinner = _resolveComponent("t-spinner")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["rounded-lg bg-white py-6", { 'px-10': $props.report !== 'csats', ' mx-6 xl2:mx-10': $props.report === 'csats' }])
  }, [
    ($options.showAggregates)
      ? (_openBlock(), _createBlock(_component_aggregates, {
          key: 0,
          metrics: $props.metrics,
          "current-data": _ctx.firstSetData.aggregates,
          "previous-data": _ctx.secondSetData.aggregates
        }, null, 8 /* PROPS */, ["metrics", "current-data", "previous-data"]))
      : _createCommentVNode("v-if", true),
    ($options.showCsatCards)
      ? (_openBlock(), _createBlock(_component_csat_cards, {
          key: 1,
          aggregates: _ctx.aggregates
        }, null, 8 /* PROPS */, ["aggregates"]))
      : _createCommentVNode("v-if", true),
    ($options.showGraphReport)
      ? (_openBlock(), _createBlock(_component_graph_report, {
          key: 2,
          metrics: $props.metrics,
          "first-set-data": (_ctx.firstSetData && _ctx.firstSetData.buckets) || _ctx.firstSetData,
          "second-set-data": (_ctx.secondSetData && _ctx.secondSetData.buckets) || _ctx.secondSetData,
          interval: $props.filters.interval,
          "current-metric": _ctx.currentMetric,
          report: $props.report
        }, null, 8 /* PROPS */, ["metrics", "first-set-data", "second-set-data", "interval", "current-metric", "report"]))
      : _createCommentVNode("v-if", true),
    ($options.showTableReport)
      ? (_openBlock(), _createBlock(_component_table_report, {
          key: 3,
          "time-columns": $props.timeColumns,
          "current-data": _ctx.firstSetData.buckets,
          "is-csat": $props.report === 'csats',
          "ticket-ids": _ctx.tickets
        }, null, 8 /* PROPS */, ["time-columns", "current-data", "is-csat", "ticket-ids"]))
      : _createCommentVNode("v-if", true),
    ($props.report === 'csats' && !_ctx.aggregates.received)
      ? (_openBlock(), _createBlock(_component_no_csats_message, { key: 4 }))
      : _createCommentVNode("v-if", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_t_spinner)
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}