import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "data-test": "message-recieved",
  class: "flex flex-col items-center justify-center"
}
const _hoisted_2 = { class: "t-text-md mt-4 text-grey-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox_circle_linear = _resolveComponent("checkbox-circle-linear")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_checkbox_circle_linear, {
      size: "1.75rem",
      class: "text-grey-600"
    }),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('whatsapp.we_recieved_your_reply')), 1 /* TEXT */)
  ]))
}