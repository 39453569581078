import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "contents"
}
const _hoisted_2 = { class: "t-text-sm flex w-full items-center justify-between overflow-visible py-3 pr-4" }
const _hoisted_3 = { class: "ml-2 flex-shrink-0" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = {
  key: 0,
  class: "mr-3"
}
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = { class: "t-text-sm text-grey-700" }
const _hoisted_9 = { class: "t-text-sm text-grey-700" }
const _hoisted_10 = { class: "t-text-sm text-grey-700" }
const _hoisted_11 = { class: "t-text-sm text-error-500" }
const _hoisted_12 = {
  key: 1,
  class: "contents"
}
const _hoisted_13 = { class: "t-text-sm flex w-full items-center justify-between overflow-visible py-3" }
const _hoisted_14 = { class: "ml-2 flex-shrink-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_info = _resolveComponent("user-info")!
  const _component_t_tag = _resolveComponent("t-tag")!
  const _component_t_table_list_cell = _resolveComponent("t-table-list-cell")!
  const _component_teams_info = _resolveComponent("teams-info")!
  const _component_chevron_down_linear = _resolveComponent("chevron-down-linear")!
  const _component_t_action_button = _resolveComponent("t-action-button")!
  const _component_t_action_menu = _resolveComponent("t-action-menu")!
  const _component_t_icon_button = _resolveComponent("t-icon-button")!
  const _component_edit3_linear = _resolveComponent("edit3-linear")!
  const _component_clipboard_linear = _resolveComponent("clipboard-linear")!
  const _component_send2_linear = _resolveComponent("send2-linear")!
  const _component_trash_linear = _resolveComponent("trash-linear")!
  const _component_t_table_list_row = _resolveComponent("t-table-list-row")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_t_table_list_row, _mergeProps({
    class: "users-list-item table-fixed py-1",
    "infinite-scrolling": ""
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      (!_ctx.isMobile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_t_table_list_cell, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_user_info, { user: _ctx.user }, null, 8 /* PROPS */, ["user"]),
                  _withDirectives(_createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_t_tag, {
                      class: "w-full select-none",
                      size: "sm"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.twoFAEnabled ? _ctx.$t('user_management.2fa') : _ctx.$t('user_management.invite_pending')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ], 512 /* NEED_PATCH */), [
                    [_vShow, _ctx.userIsUnverified || _ctx.twoFAEnabled]
                  ])
                ])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_t_table_list_cell, null, {
              default: _withCtx(() => [
                _createVNode(_component_teams_info, {
                  teams: _ctx.user.teams
                }, null, 8 /* PROPS */, ["teams"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_t_table_list_cell, null, {
              default: _withCtx(() => [
                _createVNode(_component_t_action_menu, {
                  modelValue: _ctx.userRoleDropdownIsOpen,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userRoleDropdownIsOpen) = $event)),
                  width: "max-content",
                  "trigger-on-click": false,
                  class: _normalizeClass(["inline-block", [
            {
              'cursor-pointer': !_ctx.isTheOnlyAdministrator,
              'cursor-not-allowed': !_ctx.isEditRoleDropdownEnabled,
            },
          ]]),
                  fade: ""
                }, {
                  trigger: _withCtx(() => [
                    _withDirectives((_openBlock(), _createElementBlock("span", {
                      class: _normalizeClass(["t-text-sm inline-flex select-none items-center rounded-lg py-1.5 pl-2 pr-1.5 text-grey-800", _ctx.roleDropdownClasses]),
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleUserRoleDropdown && _ctx.toggleUserRoleDropdown(...args)))
                    }, [
                      (_ctx.currentRole)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t(`user_management.${_ctx.currentRole.toLowerCase()}`)), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true),
                      (_ctx.isEditRoleDropdownEnabled)
                        ? (_openBlock(), _createBlock(_component_chevron_down_linear, {
                            key: 1,
                            size: "1rem",
                            class: "icon ml-1.5 text-grey-600"
                          }))
                        : _createCommentVNode("v-if", true)
                    ], 2 /* CLASS */)), [
                      [_directive_tooltip, _ctx.dropdownTooltip]
                    ])
                  ]),
                  content: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles, (role) => {
                        return (_openBlock(), _createBlock(_component_t_action_button, {
                          key: role.value,
                          class: "t-text-sm h-28px",
                          disabled: role.isDisabled,
                          "data-test": "user-role-dropdown-option",
                          onClick: _withModifiers(($event: any) => (_ctx.updateUserRole(role.value)), ["stop"])
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t(role.titleKey)), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["disabled", "onClick"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue", "class"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_t_table_list_cell, null, {
              default: _withCtx(() => [
                (_ctx.showOptionsDropdown)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_t_action_menu, {
                        modelValue: _ctx.optionsDropdownIsOpen,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.optionsDropdownIsOpen) = $event)),
                        position: "left",
                        fade: "",
                        width: "max-content"
                      }, {
                        trigger: _withCtx(() => [
                          _createVNode(_component_t_icon_button, {
                            size: "md",
                            class: _normalizeClass(["dropdown rounded-none", { 'dropdown-open': _ctx.optionsDropdownIsOpen }])
                          }, null, 8 /* PROPS */, ["class"])
                        ]),
                        content: _withCtx(() => [
                          _createElementVNode("div", _hoisted_7, [
                            (_ctx.canEditUser)
                              ? (_openBlock(), _createBlock(_component_t_action_button, {
                                  key: 0,
                                  "data-test": "edit-user",
                                  onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.editUser()), ["stop"]))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_edit3_linear, {
                                      size: "1.1rem",
                                      class: "mr-2 text-grey-700"
                                    }),
                                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('user_management.edit')), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                }))
                              : _createCommentVNode("v-if", true),
                            _withDirectives((_openBlock(), _createBlock(_component_t_action_button, {
                              "data-test": "copy-user-id",
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleCopyToClipboard(_ctx.user.id)))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_clipboard_linear, {
                                  size: "1.1rem",
                                  class: "mr-2 text-grey-700"
                                }),
                                _createElementVNode("span", _hoisted_9, "ID: " + _toDisplayString(_ctx.user.id), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            })), [
                              [_directive_tooltip, {
                    placement: 'top',
                    content: _ctx.buttonText,
                    classes: 'tooltip-vuetify',
                    show: _ctx.isTooltipVisible,
                    trigger: 'manual',
                  }]
                            ]),
                            (_ctx.userIsUnverified)
                              ? (_openBlock(), _createBlock(_component_t_action_button, {
                                  key: 1,
                                  "data-test": "resend-invite",
                                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.resendVerification(_ctx.user)))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_send2_linear, {
                                      size: "1.1rem",
                                      class: "mr-2 text-grey-700"
                                    }),
                                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('user_management.resend_invite')), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                }))
                              : _createCommentVNode("v-if", true),
                            (_ctx.userCanBeDeleted)
                              ? (_openBlock(), _createBlock(_component_t_action_button, {
                                  key: 2,
                                  "data-test": "delete-user",
                                  onClick: _cache[5] || (_cache[5] = ($event: any) => {
                    _ctx.toggleOptionsDropdown();
                    _ctx.deleteUser();
                  })
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_trash_linear, {
                                      size: "1.1rem",
                                      class: "mr-2 text-error-500"
                                    }),
                                    _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('user_management.delete')), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                }))
                              : _createCommentVNode("v-if", true)
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["modelValue"])
                    ]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(_component_t_table_list_cell, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_user_info, {
                    user: _ctx.user,
                    "is-mobile": true
                  }, null, 8 /* PROPS */, ["user"]),
                  _withDirectives(_createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_t_tag, {
                      class: "w-full select-none",
                      size: "sm"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.twoFAEnabled ? _ctx.$t('user_management.2fa') : _ctx.$t('user_management.invite_pending')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ], 512 /* NEED_PATCH */), [
                    [_vShow, _ctx.userIsUnverified || _ctx.twoFAEnabled]
                  ])
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]))
    ]),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */))
}