<template>
  <t-list-item-accordion @click="handleClick(item)">
    <template v-if="item.channel" #start-icon>
      <div class="channels-accordion-item__social-icon">
        <img :src="getIcon" />
      </div>
    </template>
    <template #default>
      <div class="channels-accordion-item__title">
        <p>{{ item.title }}</p>
      </div>
    </template>
    <template #subtitle>
      <div class="channels-accordion-item__subTitle__wrapper">
        <div class="channels-accordion-item__subTitle__content">
          <div class="channels-accordion-item__subTitle__content__time-fill-wrapper">
            <time-fill size="1.2rem" class="w-4 text-grey-600" />
          </div>
          <div>
            <p class="channels-accordion-item__subTitle__content__text">{{ item.sub_title }}</p>
          </div>
        </div>
      </div>
    </template>
  </t-list-item-accordion>
</template>
<script lang="ts">
import { TimeFill } from '@trengo/trengo-icons';

import type { ChannelsGroup } from '../../types';
import type { PropType } from 'vue';

export default {
  name: 'ChannelsAccordionItem',
  emits: ['click'],
  components: { TimeFill },
  props: {
    item: {
      type: Object as PropType<ChannelsGroup>,
      default: () => ({}),
      required: true,
    },
  },
  methods: {
    handleClick() {
      this.$emit('click', this.item);
    },
  },
  computed: {
    getIcon() {
      const getImage = this.item.channel === 'livechat' ? 'live-chat' : this.item.channel;
      return `${this.$root.assetsURL}img/settings-landing/${getImage}.svg`;
    },
  },
};
</script>

<style src="./ChannelsAccordionItem.scss" lang="scss" scoped />
