import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "ml-1 text-xs",
  "data-test": "attachmentCollapseStatusSymbol"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "mb-2 mt-1 flex items-center justify-center self-start font-semibold",
    "data-test": "attachmentCollapseToggleButton",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleAttachments && _ctx.toggleAttachments(...args)))
  }, [
    _createTextVNode(_toDisplayString(_ctx.$tc('attachment.attachment_count', _ctx.attachmentCount, { count: _ctx.attachmentCount })) + " ", 1 /* TEXT */),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.areAttachmentsVisible ? '▼' : '▲'), 1 /* TEXT */)
  ]))
}