import USER_PLAN_SLUG from '@/Configs/Constants/UserPlanSlugs';
import { app } from '@/main';

const UserPlan = {
  install() {
    app.mixin({
      methods: {
        $userPlan() {
          return {
            getSubscription: this.getSubscription,
            isGrow: this.isGrow,
            isStart: this.isStart,
            isScale: this.isScale,
            isEnterprise: this.isEnterprise,
            isTrial: this.isTrial,
          };
        },
      },
      computed: {
        /**
         * @returns {{plan_slug: string, plan_id: number}}
         */
        getSubscription() {
          return this.$root.companyProfile.subscription;
        },
        /**
         * @returns {string}
         */
        getPlanSlug() {
          return this.getSubscription?.plan_slug?.toLowerCase();
        },
        /**
         * @returns {boolean}
         */
        isGrow() {
          return this.getPlanSlug && this.getPlanSlug === USER_PLAN_SLUG.GROW;
        },
        /**
         * @return {boolean}
         */
        isStart() {
          return this.getPlanSlug && this.getPlanSlug === USER_PLAN_SLUG.START;
        },
        /**
         * @return {boolean}
         */
        isScale() {
          return this.getPlanSlug && this.getPlanSlug === USER_PLAN_SLUG.SCALE;
        },
        /**
         * @return {boolean}
         */
        isEnterprise() {
          return this.getPlanSlug && this.getPlanSlug === USER_PLAN_SLUG.ENTERPRISE;
        },
        /**
         * @return {boolean}
         */
        isTrial() {
          const trialEndsAt = moment(this.$root.companyProfile.subscription.trial_ends_at).format(
            'YYYY-MM-DD HH:mm:ss'
          );
          const today = moment().tz(window.APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss');

          return moment(trialEndsAt).isAfter(today) && this.getSubscription?.plan_id === null;
        },
      },
    });
  },
};

export default UserPlan;
