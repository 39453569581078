<template>
  <li class="settings-sidebar-item">
    <router-link
      class="settings-sidebar-link"
      :to="'/admin/channels2/' + channelType + '/' + id + ''"
      :class="{ active: $route.params.id == id }"
      data-dismiss="modal"
    >
      <div class="icon mr-2 flex w-4 flex-shrink-0 flex-col items-center justify-start md:hidden">
        <img :src="emailIcon" width="13px" height="13px" alt="icon" v-if="emailIcon" />
        <img
          :src="`${$root.assetsURL}img/channels_icons/email-lock-icon.svg`"
          width="13px"
          alt="lock"
          :class="{ 'mt-3': emailIcon, 'mobile-icon-margin': emailIcon }"
          v-if="isPrivate"
        />
      </div>
      <div class="content">
        <t-status-indicator :type="statusType" v-if="statusType" class="status-indic-mobile md:hidden">
          {{ statusTitle }}
        </t-status-indicator>
        <div class="text w-full flex-grow-0">
          <div class="title truncate pr-4 text-sm md:text-base" :class="{ 'font-bold': emailType !== '' }">
            {{ title }}
          </div>
          <div class="desc w-full truncate whitespace-nowrap text-sm text-grey-500" v-if="emailType">
            {{ desc }}
          </div>
        </div>
        <div class="desktop-icons">
          <img :src="`${$root.assetsURL}img/channels_icons/email-lock-icon.svg`" alt="lock" v-if="isPrivate" />
          <img :src="emailIcon" v-if="emailIcon" width="14px" alt="" class="email-icon ml-2" />

          <t-status-indicator :type="statusType" v-if="statusType" class="ml-2">
            {{ statusTitle }}
          </t-status-indicator>
        </div>
      </div>
    </router-link>
  </li>
</template>

<script>
import TStatusIndicator from '../../StatusIndicator';
export default {
  components: { TStatusIndicator },
  props: {
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    emailType: {
      type: String,
      default: '',
      validator: (value) => ['TRENGO', 'GOOGLE', 'OUTLOOK', 'NYLAS', ''].includes(value),
    },
    isPrivate: {
      type: Boolean,
      default: false,
    },
    statusTitle: {
      type: String,
      default: '',
    },
    statusType: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      default: -1,
    },
    channelType: {
      type: String,
      default: '',
    },
  },
  computed: {
    emailIcon() {
      if (this.emailType === 'GOOGLE') {
        return this.$root.assetsURL + 'img/gmail.svg';
      } else if (this.emailType === 'OUTLOOK') {
        return this.$root.assetsURL + 'img/outlook.svg';
      } else if (this.emailType === 'TRENGO') {
        return this.$root.assetsURL + 'img/channels_icons/mention.svg';
      }
    },
  },
};
</script>

<style scoped lang="scss" src="./SettingSidebarItem.scss" />
