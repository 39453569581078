import { fetchAllUsers } from '@/api';
import { USER_ROLE } from '@/Configs/Constants/UserRoles';

import type { User } from '@/types';
import type { Commit } from 'vuex';

type UsersState = {
  users: User[];
};

const MUTATION = {
  SET_USERS: 'setUsers',
};

const filterOutObservers = (users: User[] = []): User[] =>
  users.filter((user) => user.role.name !== USER_ROLE.OBSERVER);

export default {
  namespaced: true,
  state: {
    users: [],
  } as UsersState,
  getters: {
    getUsers: (state: UsersState) => (): User[] => {
      return state.users;
    },
    getBillableUsers: (state: UsersState) => (): User[] => {
      return filterOutObservers(state.users);
    },
    getAssignableUsers: (state: UsersState) => (): User[] => {
      return filterOutObservers(state.users);
    },
  },
  actions: {
    async fetchUsers({ commit }: { commit: Commit }) {
      try {
        const { data } = await fetchAllUsers({ cacheEnabled: false });
        commit(MUTATION.SET_USERS, data?.users);
      } catch (err) {
        console.error(err);
      }
    },
  },
  mutations: {
    [MUTATION.SET_USERS](state: UsersState, payload: User[]) {
      state.users = payload;
    },
  },
};
