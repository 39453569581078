<template>
  <label class="wab-recipients-manual" :class="{ focus }">
    <span class="wab-recipients-manual-label t-text-desktop-paragraph-sm mt-2">
      {{ $t('broadcast.please_include_the_country_code_for_each_number_eg_31') }}
    </span>
    <multiselect
      ref="multiselect"
      v-model="computedValue"
      :options="[]"
      :max-height="230"
      :multiple="true"
      :taggable="true"
      :close-on-select="false"
      :tag-placeholder="$t('broadcast.separate_phone_numbers_with_a_comma_or_hit_enter')"
      :placeholder="$t('broadcast.separate_phone_numbers_with_a_comma_or_hit_enter')"
      label="value"
      track-by="value"
      @tag="addTag"
      @focusin="onFocusIn"
      @focusout="onFocusOut"
      @search-change="checkAndOrAddIdentifierBySeparator"
    >
      <template #caret>
        <span></span>
      </template>
    </multiselect>
  </label>
</template>

<script>
import { isPossiblePhoneNumber } from 'libphonenumber-js/max';
import Multiselect from 'vue-multiselect';

export default {
  name: 'WabRecipientsManual',
  emits: ['update:modelValue'],
  components: {
    Multiselect,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    contacts: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    computedContacts() {
      return Object.values(this.contacts).map((o) => o.phone?.replace(/\D/g, ''));
    },
  },
  data() {
    return {
      focus: false,
    };
  },
  methods: {
    addTag(newTag) {
      const tag = {
        value: newTag,
      };

      if (this.validatePhoneNumber(newTag)) this.computedValue.push(tag);
    },
    validatePhoneNumber(number) {
      const phoneNumber = isPossiblePhoneNumber(number?.trim());
      const noDuplicateInManual = !this.computedValue
        .map((o) => o.value?.replace(/\D/g, ''))
        .includes(number?.replace(/\D/g, ''));
      const noDuplicateInContacts = !this.computedContacts.includes(number?.replace(/\D/g, ''));

      if (!phoneNumber) this.flashError(this.$t('broadcast.not_valid_phone', { number }));
      if (!noDuplicateInManual) this.flashError(this.$t('broadcast.phone_already_added', { number }));
      if (!noDuplicateInContacts) this.flashError(this.$t('broadcast.phone_already_added_contacts', { number }));

      return phoneNumber && noDuplicateInManual && noDuplicateInContacts;
    },
    checkAndOrAddIdentifierBySeparator() {
      const separators = [',', ';', '|', '\n'];
      separators.forEach((separator) => {
        if (this.$refs.multiselect.search.includes(separator)) {
          this.$refs.multiselect.search
            .split(separator)
            .filter((val) => {
              return val.trim();
            })
            .forEach((val) => {
              this.addPhone(val);
            });
          this.$refs.multiselect.search = '';
        }
      });
    },
    addPhone(val) {
      if (!this.validatePhoneNumber(val)) return;

      this.computedValue.push({
        value: val,
      });
    },
    onFocusIn() {
      this.focus = true;
    },
    onFocusOut() {
      this.focus = false;
    },
  },
};
</script>

<style lang="scss" src="./WabRecipientsManualGlobal.scss" />
