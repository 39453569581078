<template>
  <div class="broadcasting-list-main">
    <t-table-list class="table-fixed" alternate>
      <t-table-list-row head class="t-text-desktop-label-sm text-grey-600">
        <t-table-list-head width="160px">{{ $t('broadcast.broadcast_name') }}</t-table-list-head>
        <t-table-list-head>{{ $t('broadcast.broadcast_message') }}</t-table-list-head>
        <t-table-list-head width="120px">{{ $t('broadcast.broadcast_status') }}</t-table-list-head>
        <t-table-list-head width="170px">{{ $t('broadcast.broadcast_sender') }}</t-table-list-head>
        <t-table-list-head width="90px">
          <div class="flex items-center text-grey-900">
            {{ $t('broadcast.broadcast_date') }}
            <arrow-bottom-linear class="ml-1" size="1.125rem" />
          </div>
        </t-table-list-head>
        <t-table-list-head width="100px">{{ $t('broadcast.broadcast_recipients') }}</t-table-list-head>
        <t-table-list-head width="90px">
          {{ $t('broadcast.broadcast_remained_recipients_estimation') }}
        </t-table-list-head>
        <t-table-list-head width="60px"></t-table-list-head>
      </t-table-list-row>

      <broadcasting-list-item
        v-for="item in list"
        :id="item.id"
        :key="item.id"
        :message="getMessage(item)"
        :cost="item.price ? item.price : item.price_estimation"
        :status="item.status"
        :name="item.name"
        :date="item.send_at || item.start_sent_at"
        :recipients="item.recipients_count"
        :channel-name="item.channel && item.channel.title"
        :channel-type="item.channel && item.channel.type"
        :progress="item.progress"
        @delete="deleteBroadcast"
        @view="viewBroadcast"
      />

      <template v-if="loading">
        <broadcast-list-loader />
        <broadcast-list-loader />
      </template>

      <infinite-loading @infinite="fetchData">
        <template #spinner>
          <div></div>
        </template>
        <template #no-more><div></div></template>
        <template #no-results><div></div></template>
        <template #error>
          <div>
            <span @click="fetchData">{{ $t('broadcast.broadcast_try_again_button') }}</span>
          </div>
        </template>
      </infinite-loading>
    </t-table-list>

    <broadcast-details
      v-model="isDetailOpen"
      v-bind="currentBroadcast"
      :loading="detailsLoading"
      @delete="deleteBroadcast"
    />
    <router-link
      v-if="(getPlan === SCALE && isEnabled) || getPlan === ENTERPRISE"
      to="/sms"
      class="external-link t-text-button-md"
    >
      <span>{{ $t('broadcast.show_previous_sms_broadcasts') }}</span>
      <external-link-linear size="18px" />
    </router-link>
  </div>
</template>

<script>
import { ArrowBottomLinear, ExternalLinkLinear } from '@trengo/trengo-icons';
import InfiniteLoading from 'vue-infinite-loading';

import { deleteBroadcast, showBroadcast } from '@/components/WabBroadcasting/api';
import { CHANNEL_TYPE } from '@/Configs/Constants';
import Plans from '@/Configs/Constants/Plans';

import BroadcastDetails from '../BroadcastDetails';
import BroadcastingListItem from '../BroadcastingListItem';
import BroadcastListLoader from '../BroadcastListLoader';

export default {
  name: 'BroadcastingListMain',
  emits: ['infinite-scroll'],
  components: {
    BroadcastListLoader,
    BroadcastDetails,
    BroadcastingListItem,
    ArrowBottomLinear,
    ExternalLinkLinear,
    InfiniteLoading,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ENTERPRISE: Plans.ENTERPRISE,
      START: Plans.START,
      GROW: Plans.GROW,
      SCALE: Plans.SCALE,
      isDetailOpen: false,
      currentBroadcast: {
        name: '',
        message: '',
        sender: '',
        senderType: CHANNEL_TYPE.SMS,
        status: '',
        sendDate: '',
        recipients: [],
        cost: '',
        progress: {},
      },
      detailsLoading: false,
      listIdentifier: 0,
    };
  },
  computed: {
    getPlan() {
      return this.$root.companyProfile.subscription.plan_slug;
    },
    isEnabled() {
      return typeof window.AGENCY.enable_bulk_sms == 'undefined' || window.AGENCY.enable_bulk_sms == 1;
    },
  },
  methods: {
    getMessage(item) {
      if (item.channel?.type === CHANNEL_TYPE.WA_BUSINESS) {
        return item.template?.message;
      }

      return item.message;
    },
    async deleteBroadcast({ id, name }) {
      const shouldDeleteBroadcast = await this.$tConfirm(this.$t('general.delete_confirmation', { name }), {
        delete: true,
        hideDeleteIcon: true,
        title: this.$t('broadcast.delete_this_broadcast'),
      });
      if (shouldDeleteBroadcast) {
        deleteBroadcast(id).then(() => {
          this.list.splice(this.list.map((item) => item.id).indexOf(id), 1);
          this.flashSuccess(this.$t('broadcast.the_broadcast_has_been_successfully_deleted'));
        });
      }
    },
    viewBroadcast({ id, name }) {
      this.currentBroadcast.name = name;
      this.detailsLoading = true;
      this.isDetailOpen = true;

      showBroadcast(id)
        .then((res) => {
          const data = res?.data;
          const isWhatsApp = data?.channel?.type === CHANNEL_TYPE.WA_BUSINESS;
          this.currentBroadcast = {
            name,
            id,
            message: isWhatsApp ? data?.template?.message : data?.message,
            sender: data?.channel?.title,
            senderType: data?.channel?.type,
            status: data?.status,
            sendDate: data?.send_at || data?.start_sent_at,
            cost: data.price ? data.price : data.price_estimation,
            recipients: data.recipients,
            progress: data.progress,
          };
        })
        .finally(() => {
          this.detailsLoading = false;
        });
    },
    fetchData(scroll) {
      this.$emit('infinite-scroll', scroll);
    },
    closeModal() {
      this.isDetailOpen = false;
    },
  },
};
</script>

<style scoped lang="scss" src="./BroadcastingListMain.scss" />
