<template>
  <span v-if="timeText" :title="endDate">
    {{ timeText }}
    <template v-if="appendText">{{ $t('general.ago') }}</template>
  </span>
</template>
<script type="text/babel">
export default {
  /* Requires and uses Moment.js with user timezone set */
  props: ['date', 'seconds', 'shortText', 'unixtimestamp', 'autoUpdate', 'appendText'],
  data: function () {
    return {
      timeText: null,
      endDate: null,
      shortLocales: {
        nl: {
          relativeTime: {
            future: 'in %s',
            past: '%s',
            s: '1s',
            ss: '%ss',
            m: '1m',
            mm: '%dm',
            h: '1u',
            hh: '%du',
            d: '1d',
            dd: '%dd',
            M: '1M',
            MM: '%dM',
            y: '1Y',
            yy: '%dY',
          },
        },
        en: {
          relativeTime: {
            future: 'in %s',
            past: '%s',
            s: '1s',
            ss: '%ss',
            m: '1m',
            mm: '%dm',
            h: '1h',
            hh: '%dh',
            d: '1d',
            dd: '%dd',
            M: '1M',
            MM: '%dM',
            y: '1Y',
            yy: '%dY',
          },
        },
      },
    };
  },
  methods: {
    loadTime() {
      if (this.shortText) {
        if (this.shortLocales[moment.locale()]) {
          moment.updateLocale(moment.locale(), this.shortLocales[moment.locale()]);
        } else {
          moment.updateLocale(moment.locale(), this.shortLocales['en']);
        }
        this.endDate = moment(this.date).calendar();
      }

      if (this.unixtimestamp) {
        this.timeText = moment.unix(this.unixtimestamp).fromNow(true);
      } else if (!this.seconds) {
        this.timeText = moment(this.date).fromNow(true);
      } else {
        let calcDate = moment().unix() + this.seconds;
        this.timeText = moment(calcDate).fromNow(true);
      }
    },
    startUpdater() {
      // This causes major memory leaks
      // if (this.autoUpdate) {
      //     const autoUpdate = this.autoUpdate === true ? 60 : this.autoUpdate;
      //     this.updater = setInterval(() => {
      //         this.loadTime()
      //     }, autoUpdate * 1000);
      // }
    },
  },
  watch: {
    date: function () {
      this.loadTime();
    },
  },
  mounted() {
    this.loadTime();
    this.startUpdater();
  },

  /*
        methods: {
            update: function update() {
                var this$1 = this;

                var period = this.autoUpdate * 1000;
                this.interval = setInterval(function () {
                    this$1.now = new Date().getTime();
                }, period);
            },
            stopUpdate: function stopUpdate() {
                clearInterval(this.interval);
                this.interval = null;
            }
        },
        beforeUnmount: function beforeUnmount() {
            this.stopUpdate();
        }

        //*/
};
</script>
