<template>
  <t-modal
    :model-value="isModalOpen"
    :title="t('quote.quote_modal_title')"
    data-test="quote-overview-modal"
    variant="default"
    @close="handleClose"
  >
    <p class="t-text-md" v-html="bodyHtml" />
    <div class="flex gap-4">
      <div class="w-3/6 flex-col">
        <p class="t-text-xs-emphasize text-grey-700">{{ t('quote.quote_modal_current_subscription') }}</p>
        <ul class="m-0 rounded-lg border-b-1 border-dashed border-b-grey-300 bg-grey-100 p-0 pt-3">
          <li
            v-for="item in currentSubscriptionItems"
            :key="item.name"
            class="t-text-sm-emphasize mb-4 flex list-none justify-between px-4 text-grey-600"
          >
            <span>{{ item.name }}</span>
            <span v-if="item.price">{{ item.price }}</span>
          </li>
        </ul>
        <div class="flex justify-between rounded-lg bg-grey-100 p-4">
          <span class="t-text-sm-emphasize text-grey-800">Current price</span>
          <span class="t-text-sm-emphasize text-grey-800">{{ currentSubscriptionTotal }}</span>
        </div>
      </div>
      <div class="w-3/6 flex-col">
        <p class="t-text-xs-emphasize text-grey-700">{{ t('quote.quote_modal_after_migration') }}</p>
        <ul class="m-0 rounded-lg border-b-1 border-dashed border-b-grey-300 bg-grey-200 p-0 pt-3">
          <li
            v-for="item in newSubscriptionItems"
            :key="item.name"
            class="t-text-sm-emphasize mb-4 flex list-none items-center justify-between px-4 text-grey-800"
          >
            <span class="flex-grow">{{ item.name }}</span>
            <info-fill
              v-if="item.tooltip"
              v-tooltip="{ content: item.tooltip }"
              class="mr-2 text-grey-600"
              size="1rem"
            />

            <span v-if="item.price">{{ item.price }}</span>
          </li>
        </ul>
        <div class="flex flex-col gap-4 rounded-lg bg-grey-200 p-4">
          <div v-if="subscription.quote?.sub_total" class="flex justify-between">
            <span class="t-text-sm-emphasize text-grey-800">
              {{ t('quote.quote_modal_sub_total') }}
            </span>
            <span class="t-text-sm-emphasize text-grey-800">{{ formatPrice(subscription.quote?.sub_total) }}</span>
          </div>
          <div v-if="migrationDiscount" class="flex justify-between">
            <span class="t-text-sm-emphasize text-leaf-500">
              {{ t('quote.quote_modal_migration_discount') }}
            </span>
            <span class="t-text-sm-emphasize flex items-center text-leaf-500">
              <info-fill
                v-tooltip="{ content: 'Discount valid for 12 months' }"
                class="mr-2 text-grey-600"
                size="1rem"
              />
              <span>{{ `- ${formatPrice(migrationDiscount)} ` }}</span>
            </span>
          </div>
          <div v-if="subscription.quote?.total" class="flex justify-between">
            <span class="t-text-sm-emphasize text-grey-800">
              {{ t('quote.quote_modal_total_inc_vat') }}
            </span>
            <span class="t-text-sm-emphasize text-grey-800">{{ formatPrice(subscription.quote?.total) }}</span>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex w-full justify-between">
        <t-button
          class="flex items-center"
          btn-style="secondary"
          href="https://help.trengo.com/en/articles/417896-new-trengo-new-plan-pricing"
          target="__blank"
        >
          <span>{{ t('quote.quote_modal_logic_behind_the_quote') }}</span>
          <arrow-top-right-linear size="1.5rem" class="ml-1" />
        </t-button>
        <t-button @click="handleClose">
          {{ t('general.close') }}
        </t-button>
      </div>
    </template>
  </t-modal>
</template>

<script setup lang="ts">
import { ArrowTopRightLinear, InfoFill } from '@trengo/trengo-icons';
import { capitalize } from 'lodash';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useQueryParameterOpenState } from '@/composables/useQueryParameterOpenState';
import { useSubscriptionStore } from '@/store/pinia';
import { formatPrice } from '@/util/currency';

import { QUOTE_TOOLTIPS } from '../constants';

import type { SubscriptionAddonSeatBased } from '@/types/chargebee';

// UTILS

const formatSeatItems = (type: 'user_seats' | 'observer_seats', count: number) => {
  const seat = type === 'user_seats' ? 'Seat' : 'Observer seat';
  return count > 1 ? `${count}x ${seat}s` : `${count}x ${seat}`;
};

const formatUsageItems = (name: string, count?: number) => {
  return count && count > 1 ? `${count}x ${name}` : `${name}`;
};

// COMPONENT

const { t } = useI18n();

const { subscription } = useSubscriptionStore();

const { handleClose, isOpen } = useQueryParameterOpenState('quoteOverview');
const isModalOpen = computed(() => subscription.quote && isOpen.value);

const currentSubscriptionItems = computed(() => {
  return [
    {
      name: `${subscription.subscriptionName} (${capitalize(subscription.subscriptionFrequency)})`,
      price: null,
    },
    ...(subscription.addons as SubscriptionAddonSeatBased[]).map(({ type, count, totalAmount }) => ({
      name: formatSeatItems(type, count),
      price: formatPrice(totalAmount),
    })),
  ];
});

const currentSubscriptionTotal = computed(() =>
  formatPrice(
    (subscription.addons as SubscriptionAddonSeatBased[]).reduce((acc, { totalAmount }) => acc + totalAmount, 0)
  )
);

const getTooltip = (entityId: string) => QUOTE_TOOLTIPS.find(({ key }) => entityId.includes(key))?.tooltip;

const newSubscriptionItems = computed(() => {
  const plan = (subscription?.quote?.line_items ?? []).find(({ entity_type }) => entity_type === 'plan_item_price');
  const addons = (subscription?.quote?.line_items ?? []).filter(
    ({ entity_type }) => entity_type === 'addon_item_price'
  );
  return [
    {
      name: `${plan?.description} (${capitalize(subscription.subscriptionFrequency)})`,
      price: plan?.amount ? formatPrice(plan?.amount) : null,
      tooltip: plan?.entity_id && getTooltip(plan.entity_id),
    },
    ...addons.map((addon) => {
      const price = addon.item_level_discount_amount ? addon.amount - addon.item_level_discount_amount : addon.amount;
      return {
        name: formatUsageItems(addon.description, addon.quantity),
        price: formatPrice(price),
        tooltip: getTooltip(addon.entity_id),
      };
    }),
  ].filter(Boolean);
});

const migrationDiscount = computed(
  () => (subscription.quote?.discounts ?? []).find(({ entity_type }) => entity_type === 'document_level_coupon')?.amount
);

const bodyHtml = computed(() => {
  const dueDate = subscription.subscriptionDueDate;
  return t('quote.quote_modal_description', {
    dueDate,
    billingFrequency: '', // TODO add translations
    //      subscription.subscriptionFrequency === 'monthly'
    //        ? t('quote.billing_frequency_monthly')
    //        : t('quote.billing_frequency_annually'),
  });
});
</script>
