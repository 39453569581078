import AllChannels from '@/components/OnboardingChannelLandingPage/utils/channels';
import { FEATURE, FEATURE_ICON } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';

import type { TranslationFunction } from '@/types';
import type { FeatureIcon } from '@/types/featureIcon';

const path = Assets_path || '';

// FIXME: make this stricter and remove redundant fields
type AdminIndexBox = {
  name: string;
  title: string;
  intro?: string;
  body?: string;
  badge?: string;
  icon: FeatureIcon | string; // some features have older icons, we pass a direct path in those cases
  primary_button_text: string;
  primary_button_url?: string;
  secondary_button_text?: string;
  secondary_button_url?: string;
  list?: string[];
  open_channel_confirmation_modal?: boolean;
  channel_item?: unknown;
  channel_type?: string;
  showModal?: boolean;
  entitlement_badge_text?: string;
  add_on_badge_text?: string;
  entitlement_upgrade_button_text?: string;
  entitlement_add_on_button_text?: string;
  entitlement_feature_id?: string;
  implements_entitlement?: boolean;
};

export default function getAdminIndexBoxes(t: TranslationFunction): AdminIndexBox[] {
  const getChannels = AllChannels(t);

  return [
    {
      name: 'business_hours',
      title: t('admin_inboxes.business_hours'),
      badge: t('admin_inboxes.manage_your_customers_expectations'),
      icon: FEATURE_ICON.BUSINESS_HOURS,
      primary_button_text: t('admin_inboxes.add_business_hours'),
      list: [
        t('admin_inboxes.make_sure_your_customers_know_when_they_can_reach_you'),
        t('admin_inboxes.set_seperate_business_hours_for_different_channels_if_needed'),
        t('admin_inboxes.use_business_hours_in_conjunction_with_rules_for_extra_flexibility'),
      ],
      primary_button_url: SETTINGS_URL.ADMIN__BUSINESS_HOURS__CREATE,
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/64147-setting-up-your-business-hours',
    },
    {
      name: 'channel.facebook',
      title: 'Facebook',
      badge: t('admin_inboxes.includes_conversations_through_messenger'),
      icon: path + 'img/settings-landing/facebook.svg',
      primary_button_text: t('admin_inboxes.connect_facebook'),
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/63702-adding-facebook-to-your-trengo-inbox',
      list: [
        t('admin_inboxes.engage_on_the_worlds_biggest_social_media_platform'),
        t('admin_inboxes.work_together_on_conversations_in_one_shared_inbox'),
        t('admin_inboxes.reply_to_your_direct_messages_wall_posts_and_comments'),
      ],
      open_channel_confirmation_modal: true,
      channel_item: getChannels['facebook'],
      channel_type: 'facebook',
    },
    {
      name: 'channel.instagram',
      title: 'Instagram',
      badge: t('admin_inboxes.takes_less_than_1_minute_to_set_up'),
      icon: path + 'img/settings-landing/instagram.svg',
      primary_button_text: t('admin_inboxes.connect_instagram'),
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/63932',
      list: [
        t('admin_inboxes.connect_your_instagram_business_account_to_trengo'),
        t('admin_inboxes.work_together_on_conversations_in_one_shared_inbox'),
        t('admin_inboxes.reply_to_your_direct_messages_comments_and_mentions'),
      ],
      open_channel_confirmation_modal: true,
      channel_item: getChannels['instagram'],
      channel_type: 'instagram',
    },
    {
      name: 'channel.chat',
      title: t('admin_inboxes.live_chat'),
      badge: t('admin_inboxes.convenient_for_your_customers'),
      icon: path + 'img/settings-landing/live-chat.svg',
      primary_button_text: t('admin_inboxes.connect_live_chat'),
      primary_button_url: '/admin/channels2/chat/create',
      secondary_button_text: t('admin_inboxes.test_live_chat'),
      secondary_button_url: '/admin/web_widgets',
      list: [
        t('admin_inboxes.an_easy_way_to_generate_more_leads'),
        t('admin_inboxes.faster_replies_and_higher_customer_satisfaction'),
        t('admin_inboxes.be_there_for_customers_the_exact_moment_they_need_you'),
      ],
    },
    {
      name: 'channel.telegram',
      title: 'Telegram',
      badge: t('admin_inboxes.focused_on_speed_and_security'),
      icon: path + 'img/settings-landing/telegram.svg',
      primary_button_text: t('admin_inboxes.connect_telegram'),
      primary_button_url: '/admin/channels2/telegram/create',
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/63792-telegram',
      list: [
        t('admin_inboxes.telegram_messages_are_heavily_encrypted'),
        t('admin_inboxes.work_together_on_conversations_in_one_shared_inbox'),
        t('admin_inboxes.widely_used_in_eastern_europe_south_america_and_africa'),
      ],
    },
    {
      name: 'channel.voip',
      title: t('admin_inboxes.voice'),
      badge: t('admin_inboxes.the_modern_voice_solution'),
      icon: path + 'img/settings-landing/voice.svg',
      primary_button_text: t('admin_inboxes.connect_voice'),
      primary_button_url: '/admin/channels2/voip/create',
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/63812-adding-voice-in-your-trengo-inbox',
      list: [
        t('admin_inboxes.make_and_receive_calls_from_your_inbox'),
        t('admin_inboxes.record_calls_and_listen_back_to_them_with_colleagues'),
        t('admin_inboxes.carry_out_phone_calls_in_your_browser_or_via_sip_devices'),
      ],
    },
    {
      name: 'channel.sms',
      title: 'SMS',
      badge: t('admin_inboxes.send_text_messages_all_over_the_world'),
      icon: path + 'img/settings-landing/sms.svg',
      primary_button_text: t('admin_inboxes.connect_sms'),
      primary_button_url: 'javascript:$("#CreateSmsModal").modal()',
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/63657-adding-sms-to-your-trengo-inbox',
      list: [
        t('admin_inboxes.collect_all_your_text_messages_in_your_trengo_inbox'),
        t('admin_inboxes.manage_a_single_number_with_multiple_team_members'),
        t('admin_inboxes.use_broadcasting_to_proactively_engage_with_customers'),
      ],
    },
    {
      name: 'channel.custom',
      title: t('admin_inboxes.custom_channel'),
      badge: t('admin_inboxes.the_possibilites_are_endless'),
      icon: path + 'img/settings-landing/custom.svg',
      primary_button_text: t('admin_inboxes.connect_custom_channel'),
      primary_button_url: '/admin/channels2/custom/create',
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://developers.trengo.com/docs/introduction-2',
      list: [
        t('admin_inboxes.integrate_trengo_with_your_other_business_processes'),
        t('admin_inboxes.checkout_trengo_doc'),
        t('admin_inboxes.process_messages_from_external_systems_in_your_inbox'),
      ],
      entitlement_feature_id: FEATURE.CUSTOMER_SERVICE__CHANNELS__CUSTOM,
      implements_entitlement: true,
    },
    {
      name: 'channel.gbm',
      title: t('admin_inboxes.google_business'),
      badge: t('admin_inboxes.meet_your_customers_in_their_moment_of_need'),
      icon: path + 'img/settings-landing/gbm.svg',
      primary_button_text: t('admin_inboxes.connect_google_business'),
      primary_button_url: '/admin/channels2/gbm/create',
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/86040-google-business-messages',
      list: [
        t('admin_inboxes.meet_your_customers_in_their_moment_of_need'),
        t('admin_inboxes.provide_direct_support_through_google_search_and_maps'),
        t('admin_inboxes.work_together_on_conversations_in_one_shared_inbox'),
      ],
    },
    {
      name: 'help_center',
      title: t('admin_inboxes.help_center_title'),
      intro: t('admin_inboxes.help_center_intro'),
      icon: FEATURE_ICON.HELP_CENTRE,
      primary_button_text: t('admin_inboxes.add_help_center'),
      primary_button_url: '/admin/channels2/help_center/create',
      showModal: true,
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/63862-adding-a-help-center-to-your-trengo-account',
      entitlement_feature_id: FEATURE.CUSTOMER_SERVICE__HELPCENTER,
      implements_entitlement: true,
    },
    {
      name: 'rules',
      title: t('admin_inboxes.rules'),
      intro: t('admin_inboxes.automate_certain_parts_of_your_workflow'),
      icon: FEATURE_ICON.RULES,
      primary_button_text: t('admin_inboxes.add_rule'),
      primary_button_url: '/admin/rules/create',
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/63757-the-basic-configuration-of-rules',
    },
    {
      name: 'chatbots',
      title: 'Chatbots',
      intro: t('admin_inboxes.create_and_train_your_own_chatbot'),
      icon: path + 'img/settings-landing/chatbot.svg',
      primary_button_text: t('admin_inboxes.add_chatbot'),
      primary_button_url: '',
      showModal: true,
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/336919-setting-up-your-chatbot',
      entitlement_feature_id: FEATURE.CUSTOMER_SERVICE__AUTOMATION__CHATBOT,
      implements_entitlement: true,
    },
    {
      name: 'auto_replies',
      title: t('admin_inboxes.auto_replies'),
      intro: t('admin_inboxes.auto_replies_intro'),
      icon: FEATURE_ICON.AUTO_REPLIES,
      primary_button_text: t('admin_inboxes.add_auto_reply'),
      primary_button_url: '/admin/auto_replies/create',
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/63697-how-to-set-up-auto-replies',
    },
    {
      name: 'web_widgets',
      title: t('admin_inboxes.web_widgets'),
      intro: t('admin_inboxes.web_widgets_intro'),
      icon: FEATURE_ICON.WIDGET,
      primary_button_text: t('admin_inboxes.add_web_widget'),
      primary_button_url: '/admin/web_widgets/create',
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/68462-add-a-website-widget-to-your-trengo-inbox',
    },
    {
      name: 'company_profile_admin',
      title: t('admin_inboxes.company_profile'),
      intro: t('admin_inboxes.company_profile_admin_intro'),
      icon: path + 'img/settings-landing/company_profile.svg',
      primary_button_text: t('admin_inboxes.subscription_settings'),
      primary_button_url: SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS,
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/15442-trengo-account/12482-financials',
    },
    {
      name: 'company_profile_agent',
      title: t('admin_inboxes.company_profile'),
      intro: t('admin_inboxes.company_profile_agent_intro'),
      icon: path + 'img/settings-landing/company_profile.svg',
      primary_button_text: t('admin_inboxes.business_hours'),
      primary_button_url: SETTINGS_URL.ADMIN__BUSINESS_HOURS,
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/64147-setting-up-your-business-hours',
      entitlement_badge_text: t('admin_inboxes.upgrade_to_boost_access'),
      add_on_badge_text: t('admin_inboxes.requires_add_on'),
      entitlement_upgrade_button_text: t('admin_inboxes.upgrade_to_boost'),
      entitlement_add_on_button_text: t('entitlement.unlock_feature', { feature: 'Flowbots' }),
      entitlement_feature_id: FEATURE.CUSTOMER_SERVICE__AUTOMATION__FLOWBOT,
      implements_entitlement: true,
    },
    {
      name: 'teams',
      title: t('admin_inboxes.teams'),
      intro: t('admin_inboxes.teams_intro'),
      icon: FEATURE_ICON.TEAMS,
      primary_button_text: t('admin_inboxes.create_a_team'),
      primary_button_url: '/admin/teams/create',
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/63457-create-a-team',
    },
    {
      name: 'users',
      title: t('admin_inboxes.invite_your_colleagues'),
      intro: t('admin_inboxes.invite_your_colleagues_intro'),
      icon: FEATURE_ICON.TEAMS,
      primary_button_text: t('admin_inboxes.invite_a_colleague'),
      primary_button_url: '/admin/users/create',
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/63462-add-users-to-trengo',
    },
    {
      name: 'quick_replies',
      title: t('admin_inboxes.quick_replies'),
      intro: t('admin_inboxes.quick_replies_intro'),
      icon: FEATURE_ICON.QUICK_REPLY,
      primary_button_text: t('admin_inboxes.add_quick_reply'),
      primary_button_url: '/admin/quick_replies/create',
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/63727-adding-quick-replies-to-your-trengo-inbox',
    },
    {
      name: 'labels',
      title: t('admin_inboxes.labels'),
      intro: t('admin_inboxes.labels_intro'),
      icon: FEATURE_ICON.LABELS,
      primary_button_text: t('admin_inboxes.add_label'),
      primary_button_url: '/admin/labels/create',
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/63822-how-to-create-labels',
    },
    {
      name: 'locations',
      title: t('admin_inboxes.locations'),
      intro: t('admin_inboxes.locations_intro'),
      badge: t('admin_inboxes.manage_multiple_locations'),
      icon: FEATURE_ICON.LOCATIONS,
      primary_button_text: t('admin_inboxes.add_location'),
      primary_button_url: SETTINGS_URL.ADMIN__LOCATIONS__CREATE,
    },
    {
      name: 'views',
      title: t('admin_inboxes.views'),
      intro: t('admin_inboxes.views_intro'),
      icon: FEATURE_ICON.VIEWS,
      primary_button_text: t('admin_inboxes.add_view'),
      primary_button_url: '/admin/custom_views/create',
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/69762-views',
    },
    {
      name: 'contact_groups',
      title: t('admin_inboxes.contact_groups'),
      intro: t('admin_inboxes.contact_groups_intro'),
      icon: FEATURE_ICON.TEAMS,
      primary_button_text: t('admin_inboxes.add_contact_group'),
      primary_button_url: '/admin/contact_groups/create',
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/64392-contact-groups',
    },
    {
      name: 'ticket_results',
      title: t('admin_inboxes.ticket_results'),
      intro: t('admin_inboxes.ticket_results_intro'),
      icon: FEATURE_ICON.TICKET_RESULTS,
      primary_button_text: t('admin_inboxes.add_ticket_result'),
      primary_button_url: '/admin/ticket_results/create',
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/63777-ticket-results',
    },
    {
      name: 'developers',
      title: t('admin_inboxes.apps_integrations'),
      intro: t('admin_inboxes.apps_integrations_intro'),
      icon: path + 'img/settings-landing/connect-apps.svg',
      primary_button_text: t('admin_inboxes.connect_app'),
      primary_button_url: '/admin/developers/plugins/create',
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://developers.trengo.com',
    },
    {
      name: 'flows',
      title: 'Flowbots',
      intro: t('admin_inboxes.flowbot_intro'),
      icon: FEATURE_ICON.FLOWBOTS,
      primary_button_text: t('admin_inboxes.add_flowbot'),
      primary_button_url: '/admin/flows/create',
      secondary_button_text: t('admin_inboxes.learn_more'),
      secondary_button_url: 'https://help.trengo.com/en/articles/64117-the-anatomy-of-a-flowbot',
      entitlement_feature_id: FEATURE.CUSTOMER_SERVICE__AUTOMATION__FLOWBOT,
      entitlement_upgrade_button_text: t('entitlement.unlock_feature', { featureName: 'Flowbots' }),
      entitlement_add_on_button_text: t('entitlement.unlock_feature', { featureName: 'Flowbots' }),
      implements_entitlement: true,
    },
  ];
}
