import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "grid gap-4 pb-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl3:grid-cols-4" }
const _hoisted_2 = { class: "flex flex-row" }
const _hoisted_3 = {
  class: "t-text-lg-emphasize mb-0",
  "data-test": "integration-card-name"
}
const _hoisted_4 = {
  class: "mb-0 text-grey-600",
  "data-test": "integration-card-description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_integration_card_icon = _resolveComponent("integration-card-icon")!
  const _component_check_circle_linear = _resolveComponent("check-circle-linear")!
  const _component_t_badge = _resolveComponent("t-badge")!
  const _component_arrow_top_circle_linear = _resolveComponent("arrow-top-circle-linear")!
  const _component_t_card = _resolveComponent("t-card")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.integrations, (integration) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        key: integration.name,
        to: `/admin/integrations/${integration.filterType}/${integration.id}`,
        "data-test": "integration-card-link-to-details"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_t_card, {
            class: "h-full gap-3 bg-white",
            focusable: true
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_integration_card_icon, {
                  icon: integration.icon,
                  class: "mb-4 rounded-md"
                }, null, 8 /* PROPS */, ["icon"]),
                (integration.isInstalled)
                  ? (_openBlock(), _createBlock(_component_t_badge, {
                      key: 0,
                      class: "ml-auto flex flex-row items-center",
                      variant: "success",
                      text: _ctx.$t('integration_hub.badge_installed'),
                      "data-test": "integration-card-installed-badge"
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_check_circle_linear, {
                          size: "1rem",
                          class: "mr-0.5"
                        })
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["text"]))
                  : (!_ctx.isEntitledToPremium && integration.is_premium)
                    ? (_openBlock(), _createBlock(_component_t_badge, {
                        key: 1,
                        class: "ml-auto flex flex-row items-center",
                        variant: "upgrade",
                        text: _ctx.$t(_ctx.badgeText),
                        "data-test": "integration-card-enterprise-badge"
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_arrow_top_circle_linear, {
                            size: "1rem",
                            class: "mr-0.5"
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["text"]))
                    : _createCommentVNode("v-if", true)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("h1", _hoisted_3, _toDisplayString(integration.name), 1 /* TEXT */),
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$tc(integration.shortDescription)), 1 /* TEXT */)
              ])
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}