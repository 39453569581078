import { request } from '@/util/request';

import { ENDPOINT } from '../constants';

import type { Agency, CompanyProfile } from '@/types';

export function fetchCompanyProfile() {
  return request<CompanyProfile>(ENDPOINT.COMPANY_PROFILE, 'GET');
}

export function fetchAgency() {
  return request<Agency>(ENDPOINT.AGENCY, 'GET');
}

export function updateAgency(data: unknown) {
  return request<unknown>(ENDPOINT.AGENCY, 'PUT', data);
}

export function setCompanyProfileSettings(data: unknown) {
  return request<unknown>(ENDPOINT.COMPANY_PROFILE_SETTINGS, 'POST', data);
}

export function setCompanyProfileSupport(data: unknown) {
  return request<unknown>(ENDPOINT.COMPANY_PROFILE_SUPPORT, 'POST', data);
}
