import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "flex flex-col border-b border-grey-300",
  "data-test": "sidebar-dropdown-wrapper"
}
const _hoisted_2 = {
  key: 0,
  class: "mr-3"
}
const _hoisted_3 = {
  class: "t-text-sm-emphasize m-0 select-none text-ellipsis p-0 text-grey-800",
  "data-test": "sidebar-dropdown-title"
}
const _hoisted_4 = { class: "ml-auto flex flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sliders_vert2_linear = _resolveComponent("sliders-vert2-linear")!
  const _component_t_thumbnail = _resolveComponent("t-thumbnail")!
  const _component_external_link_linear = _resolveComponent("external-link-linear")!
  const _component_chevron_down_linear = _resolveComponent("chevron-down-linear")!
  const _component_chevron_up_linear = _resolveComponent("chevron-up-linear")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "flex cursor-pointer flex-row items-center p-3 hover:bg-grey-100",
      "data-test": "sidebar-dropdown-button",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleCard && _ctx.toggleCard(...args)))
    }, [
      (_ctx.hasIcon || _ctx.$slots.icon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "icon", {}, () => [
              _createVNode(_component_t_thumbnail, { size: "tiny" }, {
                default: _withCtx(() => [
                  _createVNode(_component_sliders_vert2_linear)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.externalLink.length > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "open-external mr-1.5 self-center",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openExternalLink()))
            }, [
              _createVNode(_component_external_link_linear, { size: "1.1rem" })
            ]))
          : _createCommentVNode("v-if", true),
        (!_ctx.isCardOpen)
          ? (_openBlock(), _createBlock(_component_chevron_down_linear, {
              key: 1,
              size: "1.5rem",
              "data-test": "sidebar-dropdown-icon-closed"
            }))
          : (_openBlock(), _createBlock(_component_chevron_up_linear, {
              key: 2,
              size: "1.5rem",
              "data-test": "sidebar-dropdown-icon-open"
            }))
      ])
    ]),
    (_ctx.isCardOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([
        'flex flex-col px-3 pb-3',
        { 'mt-2': !_ctx.isIntegrationDropdown, 'border-t border-grey-300 bg-grey-100 pt-2': _ctx.isIntegrationDropdown },
      ]),
          "data-test": "sidebar-dropdown-content-wrapper"
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ]))
}