import { request } from '@/util';

import type { Flowbot, FlowsResponse } from '@/api/types/flowbots';
import type { AxiosPromise, AxiosResponse } from 'axios';

const base_url = '/api/v2/';

export function getFlows(page: number, hasPagination: boolean): AxiosPromise<FlowsResponse | Flowbot[]> {
  return request(`${base_url}flowbot?pagination=${hasPagination}&page=${page}`, 'GET', {});
}

export function deleteFlow(id: number): AxiosPromise<AxiosResponse> {
  return request(`${base_url}flowbot/${id}`, 'DELETE');
}
