import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "flex flex-col",
  "data-test": "sidebar-infobox-wrapper"
}
const _hoisted_2 = { class: "flex flex-row items-center" }
const _hoisted_3 = {
  key: 0,
  class: "t-text-md-emphasize m-0 flex flex-row items-center text-ellipsis p-0 text-grey-800",
  "data-test": "sidebar-contact-name"
}
const _hoisted_4 = { class: "mt-2 flex flex-row items-center" }
const _hoisted_5 = {
  key: 0,
  class: "t-text-sm my-0 ml-6 text-ellipsis p-0 pr-2 text-grey-700",
  "data-test": "sidebar-contact-email"
}
const _hoisted_6 = {
  key: 3,
  class: "absolute"
}
const _hoisted_7 = {
  key: 0,
  class: "mt-2 flex flex-row items-center"
}
const _hoisted_8 = {
  class: "t-text-sm sm my-0 ml-6 text-ellipsis p-0 pr-2 text-grey-700",
  "data-test": "sidebar-contact-phone"
}
const _hoisted_9 = { class: "mt-2 flex flex-row items-center" }
const _hoisted_10 = {
  key: 2,
  class: "absolute"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar = _resolveComponent("avatar")!
  const _component_t_input_text = _resolveComponent("t-input-text")!
  const _component_more_vert_fill = _resolveComponent("more-vert-fill")!
  const _component_t_icon_button = _resolveComponent("t-icon-button")!
  const _component_infobox_dropdown = _resolveComponent("infobox-dropdown")!
  const _component_t_action_menu = _resolveComponent("t-action-menu")!
  const _component_email_linear = _resolveComponent("email-linear")!
  const _component_copy_linear = _resolveComponent("copy-linear")!
  const _component_t_button_text = _resolveComponent("t-button-text")!
  const _component_attach_email = _resolveComponent("attach-email")!
  const _component_edit_email_modal = _resolveComponent("edit-email-modal")!
  const _component_phone_linear = _resolveComponent("phone-linear")!
  const _component_user_square_linear = _resolveComponent("user-square-linear")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_attach_profile = _resolveComponent("attach-profile")!
  const _component_on_click_outside = _resolveComponent("on-click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isEditingName)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_3, [
            _createVNode(_component_avatar, {
              class: "mr-2",
              color: _ctx.contact.color,
              abbr: _ctx.contact.abbr,
              width: "32",
              image: _ctx.contact.profile_image
            }, null, 8 /* PROPS */, ["color", "abbr", "image"]),
            _createTextVNode(" " + _toDisplayString(_ctx.contact.full_name), 1 /* TEXT */)
          ]))
        : (_openBlock(), _createBlock(_component_t_input_text, {
            key: 1,
            id: "contactFullName",
            modelValue: _ctx.contact.full_name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contact.full_name) = $event)),
            class: "mr-2",
            placeholder: _ctx.contact.full_name,
            "data-test": "contact-edit-fullname",
            onKeydown: [
              _withKeys(_ctx.updateContactName, ["enter"]),
              _withKeys(_ctx.cancelName, ["esc"])
            ]
          }, null, 8 /* PROPS */, ["modelValue", "placeholder", "onKeydown"])),
      _createVNode(_component_t_action_menu, {
        id: "sidebar-action-menu",
        modelValue: _ctx.isModalOpen,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isModalOpen) = $event)),
        class: "ml-auto",
        position: "left",
        "trigger-on-click": true,
        width: "",
        "data-test": "sidebar-modal-action-menu"
      }, {
        trigger: _withCtx(() => [
          _createVNode(_component_t_icon_button, { class: "ml-auto" }, {
            default: _withCtx(() => [
              _createVNode(_component_more_vert_fill, { size: "1.5rem" })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        content: _withCtx(() => [
          _createVNode(_component_infobox_dropdown, {
            "has-email": !!_ctx.contact.email,
            "has-phone": _ctx.contact.is_phone,
            onEditName: _ctx.enableNameEdit,
            onStartConversation: _ctx.startConversation,
            onEditEmail: _ctx.enableEmailEdit,
            onShowContact: _ctx.showContact,
            onSearchEmail: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItem('email'))),
            onCopyEmail: _cache[2] || (_cache[2] = ($event: any) => (_ctx.copyItem('email'))),
            onCallContact: _ctx.callContact,
            onSearchPhone: _cache[3] || (_cache[3] = ($event: any) => (_ctx.searchItem('phone'))),
            onCopyPhone: _cache[4] || (_cache[4] = ($event: any) => (_ctx.copyItem('phone')))
          }, null, 8 /* PROPS */, ["has-email", "has-phone", "onEditName", "onStartConversation", "onEditEmail", "onShowContact", "onCallContact"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", null, [
        _createVNode(_component_email_linear, { size: "1.25rem" })
      ]),
      (_ctx.contact.email)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.contact.email), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      (_ctx.contact.email)
        ? (_openBlock(), _createBlock(_component_t_icon_button, {
            key: 1,
            class: "ml-auto",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.copyItem('email')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_copy_linear, { size: "1.1rem" })
            ]),
            _: 1 /* STABLE */
          }))
        : (!_ctx.isAddingEmail)
          ? (_openBlock(), _createBlock(_component_t_button_text, {
              key: 2,
              class: "my-0 ml-3",
              "data-test": "add-email-button",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isAddingEmail = true))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$tc('email_settings.add_email')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_attach_email, {
                class: "top-0",
                contact: _ctx.contact,
                onCloseEmailEdit: _cache[8] || (_cache[8] = ($event: any) => (_ctx.isAddingEmail = false))
              }, null, 8 /* PROPS */, ["contact"])
            ])),
      (_ctx.isEditingEmail)
        ? (_openBlock(), _createBlock(_component_edit_email_modal, {
            key: 4,
            email: _ctx.contact.email,
            "contact-id": _ctx.contact.id,
            onCloseModal: _cache[9] || (_cache[9] = ($event: any) => (_ctx.isEditingEmail = false))
          }, null, 8 /* PROPS */, ["email", "contact-id"]))
        : _createCommentVNode("v-if", true)
    ]),
    (_ctx.contact.is_phone)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("span", null, [
            _createVNode(_component_phone_linear, { size: "1.25rem" })
          ]),
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.contact.formatted_phone), 1 /* TEXT */),
          _createVNode(_component_t_icon_button, {
            class: "ml-auto",
            onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.copyItem('phone')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_copy_linear, { size: "1.1rem" })
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("span", null, [
        _createVNode(_component_user_square_linear, { size: "1.25rem" })
      ]),
      (_ctx.contact.profile.length > 0)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: `/profiles/${_ctx.contact.profile[0].id}`,
            class: "t-text-sm sm my-0 ml-6 text-ellipsis p-0 text-grey-700 underline hover:text-grey-900 hover:underline",
            "data-test": "sidebar-contact-profile-link"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.contact.profile[0].name), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["to"]))
        : (_openBlock(), _createBlock(_component_t_button_text, {
            key: 1,
            class: "my-0 ml-3",
            "data-test": "sidebar-add-contact-button",
            onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.isAddingProfile = true))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tc('settings.add_profile')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })),
      (_ctx.isAddingProfile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(_component_on_click_outside, {
              onTrigger: _cache[13] || (_cache[13] = ($event: any) => (_ctx.isAddingProfile = false))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_attach_profile, {
                  contact: _ctx.contact,
                  channel: _ctx.channel,
                  onCloseProfileEdit: _cache[12] || (_cache[12] = ($event: any) => (_ctx.isAddingProfile = false))
                }, null, 8 /* PROPS */, ["contact", "channel"])
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}