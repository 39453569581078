<script type="text/babel" lang="ts">
import { ChatbotLinear, QuestionLinear } from '@trengo/trengo-icons';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import infiniteLoading from 'vue-infinite-loading';

import ListRowItem from '@/components/common/ListRow/ListRowItem';
import ListRowParent from '@/components/common/ListRow/ListRowParent';
import MainHeader from '@/components/common/PageHeaders/MainHeader';
import SettingsBaseLayout from '@/components/common/SettingsBaseLayout';
import { FEATURE, FEATURE_FLAG_SETTINGS } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useEntitlementsStore, useFeatureFlagStore } from '@/store/pinia';

import { deleteChatbot, getChatbots } from './api';
import createChatbotModal from './CreateChatbotModal.vue';
import ListRowItemLoader from '../common/ListRow/ListRowItemLoader';
import ListRowItemChild from '../common/ListRowItemChild';

import type { Chatbot } from '@/types/chatbots';
import type { StateChanger } from 'vue-infinite-loading';


const CHATBOTS_LIST_PAGE_SIZE = 25;
export default defineComponent({
  name: 'ChatbotsList',
  components: {
    createChatbotModal,
    ChatbotLinear,
    ListRowItem,
    ListRowItemChild,
    ListRowItemLoader,
    ListRowParent,
    MainHeader,
    QuestionLinear,
    SettingsBaseLayout,
    infiniteLoading,
  },

  data() {
    return {
      chatbots: [] as Chatbot[],
      loaded: false,
      infiniteLoadingResetCounter: 0,
      page: 1,
    };
  },

  mounted() {
    this.loadList();

    eventBus.$on('chatbots.list.reload', this.loadList);
    eventBus.$on('showModal', this.showModal);
  },

  unmounted() {
    eventBus.$off('chatbots.list.reload', this.loadList);
    eventBus.$off('showModal', this.showModal);
  },

  computed: {
    ...mapStores(useEntitlementsStore),
     isFeatureFlagEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_SETTINGS.TA_CHATBOTS_PAGE);
    },

    showItems() {
      const hasChatbots = this.chatbots?.length > 0;
      return hasChatbots && !this.$route.params.id;
    },

    showContent() {
      const isInEditPage = Boolean(this.$route.params.id);
      return isInEditPage || this.chatbots.length === 0;
    },

    fullHeightInEdit() {
      const chatbotsAreMorThanFive = this.chatbots.length > 5;
      return (this.$route.params.id || chatbotsAreMorThanFive) && 'h-full';
    },

    paddingOnListPage() {
      const isInEditPage = Boolean(this.$route.params.id);
      return this.$route.params.id || isInEditPage ? '' : 'px-6';
    },

    isEntitled() {
      const featureId = FEATURE.CUSTOMER_SERVICE__AUTOMATION__CHATBOT;
      return this.entitlementsStore?.isEntitledTo(featureId);
    },

    primaryButtonText() {
      if (this.isEntitled) {
        return this.$t('admin_inboxes.add_chatbot');
      } else {
        return this.$t('entitlement.unlock_feature', { feature: 'Chatbots' });
      }
    },
  },

  methods: {
    showModal() {
      $('#CreateChatbotModal').modal();
    },

    hideModal() {
      $('#CreateChatbotModal').modal('hide');
    },

    async loadList() {
      try {
        const hasPagination = Boolean(this.isFeatureFlagEnabled);
        const result = await getChatbots(1, hasPagination);
        this.chatbots = 'data' in result.data ? result.data.data : result.data;
        this.loaded = true;
        this.infiniteLoadingResetCounter++;
      } catch (error) {
        console.error(error);
        this.loaded = true;
      }
    },

    isItemActive(id: number): boolean {
      return this.$route.params.id == id;
    },

    handleAddChatbot(): void {
      if (this.isEntitled) {
        this.showModal();
      } else {
        this.$router.push('/admin/subscription_settings');
      }
    },

    handleItemClick(id: number): void {
      this.$router.push(`/admin/chatbots/${id}/questions`);
      this.page = 1;
    },

    async handleDeleteChatbot(id: number) {
      try {
        const shouldDeleteChatbot = await this.$tConfirm(this.$t('chatbot.delete_chatbot_confirmation'), {
          delete: true,
          title: this.$t('general.are_you_sure'),
        });

        if (!shouldDeleteChatbot) {
          return;
        }
        await deleteChatbot(id);
        this.flashSuccess(this.$t('chatbot.delete_success'));
        const chatbot = this.chatbots.find((chatbot: Chatbot) => chatbot.id === id);

        if (chatbot) {
          this.chatbots.splice(this.chatbots.indexOf(chatbot), 1);
        }

        if (this.$refs.infinteScroller) {
          const { complete, loaded, reset, error } = this.$refs.infiniteScroller.stateChanger;
          this.fetchMoreData({ complete, loaded, reset, error });
        }
      } catch (e) {
        console.error(this.$t('chatbot.delete_error'), e);
      }
    },

    async fetchMoreData(scroll: StateChanger) {
      if (this.chatbots?.length < CHATBOTS_LIST_PAGE_SIZE) {
        scroll.complete();
        scroll.loaded();
        return;
      }
      if (this.page === 1) {
        this.page++;
      }
      try {
        const hasPagination = Boolean(this.isFeatureFlagEnabled);
        const result = await getChatbots(this.page, hasPagination);
        const newChatbots = 'data' in result.data ? result.data.data : result.data;
        if (newChatbots?.length > 0) {
          this.chatbots = [...this.chatbots, ...newChatbots];
          this.page++;
          scroll.loaded();
        } else {
          scroll.complete();
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
</script>

<template>
  <settings-base-layout v-if="isFeatureFlagEnabled" :class="fullHeightInEdit">
    <template v-if="showItems" #header>
      <main-header :title="$t('chatbot.chatbots')" class="hidden-md-down">
        <template #left>
          <t-thumbnail class="bg-white">
            <Chatbot-linear size="1rem" />
          </t-thumbnail>
        </template>

        <template #right>
          <t-button
            class="open-external"
            btn-style="secondary"
            icon-only
            href="https://help.trengo.com/en/articles/336919-setting-up-your-chatbot"
            rel="noreferrer noopener"
            target="_blank"
          >
            <template #icon>
              <question-linear size="1.042rem" />
            </template>
          </t-button>
          <t-button class="ml-3" @click="handleAddChatbot">
            {{ primaryButtonText }}
          </t-button>
        </template>
      </main-header>
      <admin-section-title :title="$t('chatbot.chatbots')"></admin-section-title>
    </template>

    <template #default>
      <div class="h-full" :class="paddingOnListPage">
        <list-row-parent v-if="showItems">
          <list-row-item
            v-for="item in chatbots"
            :key="item.id"
            :class="{ hide: item.deleted }"
            @click="handleItemClick(item.id)"
          >
            <list-row-item-child
              :id="item.id"
              :title="item.title"
              :is-active="!!item.is_active"
              data-test-list-row-item-child="chatbot-list-row-item-child"
              @handle-delete-item="handleDeleteChatbot(item.id)"
            />
          </list-row-item>

          <infinite-loading ref="infiniteScroller" :identifier="infiniteLoadingResetCounter" @infinite="fetchMoreData">
            <template #spinner>
              <list-row-item-loader
                data-test-list-row-item="chatbots-list-row-item"
                data-test-list-row-item-loader="chatbots-row-item-loader"
              />
              <list-row-item-loader
                data-test-list-row-item="chatbots-list-row-item"
                data-test-list-row-item-loader="chatbots-row-item-loader"
              />
            </template>
            <template #no-more>
              <div></div>
            </template>
            <template #no-results>
              <div></div>
            </template>
          </infinite-loading>
        </list-row-parent>
        <div v-if="showContent" class="row-col">
          <router-view v-if="loaded" :key="$route.params.id"></router-view>
        </div>
      </div>
      <create-chatbot-modal></create-chatbot-modal>
    </template>
  </settings-base-layout>

  <div v-else class="row-body">
    <admin-section-title :title="$t('chatbot.chatbots')"></admin-section-title>

    <div class="row-col">
      <div class="row-col">
        <div id="settings-primary" class="col-xs modal fade aside aside-md b-r w-medium w-200px">
          <div class="nav-settings light left b-primary row-col">
            <ul>
              <li class="text-md white header">
                <strong>{{ $t('chatbot.chatbots') }}</strong>
                <a data-dismiss="modal" class="hidden-md-up text-muted m-l-auto m-r-sm m-l-1 text-lg">×</a>
                <a
                  data-dismiss="modal"
                  class="m-l-auto btn btn-icon btn-sm success rounded text-white"
                  data-toggle="tooltip"
                  data-placement="left"
                  @click="showModal()"
                >
                  <i class="material-icons md-18">add</i>
                </a>
              </li>
            </ul>
            <div class="row-row">
              <div class="row-body scrollable hove">
                <div class="row-inner">
                  <ul>
                    <li v-for="(item, index) in chatbots" :key="index">
                      <router-link
                        class="nav-settings-link text-truncate"
                        :to="'/admin/chatbots/' + item.id + '/questions'"
                        data-dismiss="modal"
                        :class="{ active: isItemActive(item.id) }"
                      >
                        <span class="float-right">
                          <label v-if="!item.is_active" class="label m-l-xs m-b-0">Unpublished</label>
                        </span>
                        {{ item.title }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-col">
          <router-view :key="$route.params.id"></router-view>
        </div>
      </div>
    </div>

    <create-chatbot-modal></create-chatbot-modal>
  </div>
</template>
