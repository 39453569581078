<template>
  <div class="rounded-xl px-4 py-6" :class="bgColor">
    <div class="align-center flex flex-row items-center">
      <span class="mr-3">
        <img class="h-20px w-20px" :src="`${$root.assetsURL}img/info.svg`" alt="info" />
      </span>
      <span class="text-base font-bold">{{ title }}</span>
    </div>
    <div class="ml-8">
      <span v-html="markupSanitizer(body)"></span>
    </div>
  </div>
</template>

<script>
import markupSanitizer from '@/util/markupSanitizer';

export default {
  name: 'WarningCareful',
  emits: ['show-instruction'],
  props: {
    id: {
      type: Number,
      default: 0,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    bgColor: {
      type: String,
      default: 'bg-grey-300',
    },
  },
  methods: {
    handleEmailInstruction() {
      this.$emit('show-instruction', true);
    },
    markupSanitizer,
  },
};
</script>
