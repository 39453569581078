<script>
import { keys } from 'lodash';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
export default {
  name: 'TelInput',
  emits: ['update:modelValue', 'blur', 'enter'],
  components: {
    VueTelInput,
  },

  props: {
    modelValue: {
      type: String,
      default: '',
    },
    submitOnEnter: {
      type: Boolean,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      val: null,
      list: [],
    };
  },

  watch: {
    modelValue(v) {
      this.val = v;
    },
  },

  mounted() {
    this.val = this.modelValue;
    if (this.submitOnEnter === false) {
      this.registerOnEnter();
    }
    this.list = keys(this.$root.countries);
  },

  unmounted() {
    if (this.submitOnEnter === false) {
      this.$el.querySelector('input').removeEventListener('keydown', this.enterHandler);
    }
  },

  methods: {
    onInput(number) {
      this.$emit('update:modelValue', number.replace(/\s/g, ''));
    },

    clearInput() {
      this.val = null;
    },

    onBlur() {
      this.$emit('blur');
    },

    onEnter() {
      this.$emit('enter');
    },

    registerOnEnter() {
      this.$el.querySelector('input').addEventListener('keydown', this.enterHandler);
    },

    enterHandler(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        this.$emit('enter');
      }
    },
  },
};
</script>

<template>
  <vue-tel-input
    v-model="val"
    autocomplete="off"
    :preferred-countries="['NL', 'BE', 'DE', 'GB', 'US']"
    :only-countries="list"
    :required="required"
    @onInput="onInput"
    @onBlur="onBlur"
    @onEnter="onEnter"
  ></vue-tel-input>
</template>
