export default {
  methods: {
    formatText(message, removeLineBreaks = false) {
      if (!message) {
        return null;
      }

      message = window.linkifyString(message, {
        defaultProtocol: 'http',
        target: message.indexOf('/tickets/') == -1 ? '_blank' : '_self',
        className: window.isLoadedFromApp ? 'open-external' : '',
      });

      if (!removeLineBreaks) {
        message = message.replace(/\r?\n/g, '<br />');
      }

      message = message.replace(/{(.*?)}/g, function (x) {
        x = x.substring(1, x.length - 1);
        let fallbackMessage = '';
        if (x.indexOf('|') > -1) {
          fallbackMessage = 'Fallback: ' + x.split('|')[1];
        }
        x = x.split('|')[0];
        return '<label class="label rounded success" style="font-size:14px; margin:0">' + x + '</label>'; // { } // <label>
      });

      return message;
    },
  },
};
