<template>
  <div class="row-col">
    <div>
      <span class="text-md p-y-1 white p-x-1 b-b hidden-md-up text-ellipsis">
        <a data-toggle="modal" data-target="#settings-third" class="pull-left">
          <span class="btn btn-sm btn-icon white m-r-1">
            <i class="fa fa-list"></i>
          </span>
        </a>

        {{ $t('apps_integration.webhooks') }}
      </span>
    </div>
    <div class="row-col">
      <div class="row-col">
        <div id="settings-third" class="col-xs modal fade aside aside-md b-r w-medium w-200px">
          <div class="nav-settings light left b-primary row-col">
            <ul>
              <li class="text-md white header">
                <strong>{{ $t('apps_integration.webhooks') }}</strong>
                <a data-dismiss="modal" class="hidden-md-up text-muted m-l-auto m-r-sm m-l-1 text-lg">×</a>
                <a class="m-l-auto btn btn-icon btn-sm success rounded text-white">
                  <i class="material-icons md-18" @click="goToCreateRoute">add</i>
                </a>
              </li>
            </ul>
            <div class="row-row">
              <div class="row-body scrollable hove">
                <div class="row-inner">
                  <ul>
                    <li v-for="item in webhooks" :key="item.id">
                      <router-link
                        class="nav-settings-link text-truncate"
                        data-dismiss="modal"
                        :to="getItemRoute(item.id)"
                        :class="{ active: $route.params.id === item.id }"
                      >
                        {{ item.title }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-col">
          <router-view v-if="loaded" :key="$route.params.id" @call-loadlist-method="loadList"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

export default {
  data() {
    return {
      loaded: false,
      webhooks: {},
    };
  },

  mounted() {
    this.loadList(false);
  },

  methods: {
    loadList(reload_root = true) {
      axios.get('/api/v2/webhooks').then((result) => {
        this.webhooks = result.data.data;
        this.loaded = true;

        if (reload_root) {
          this.$root.webhooks = this.webhooks;
        }
      });
    },

    getItemRoute(itemId) {
      if (useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.INTEGRATION_HUB)) {
        return `/admin/integrations/webhooks/${itemId}`;
      }

      return `/admin/developers/webhooks/${itemId}`;
    },

    goToCreateRoute() {
      if (useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.INTEGRATION_HUB)) {
        this.$router.push('/admin/integrations/webhooks/create');
      } else {
        this.$router.push('/admin/developers/webhooks/create');
      }
    },
  },
};
</script>
