export default {
  secondsToString(seconds, auto_limit = true, noValueIndicator = '-') {
    if (isNaN(seconds)) {
      return noValueIndicator;
    }
    seconds = Math.abs(seconds);
    if (seconds > 172800 && auto_limit) {
      return '+2d';
    }
    let str = '';
    var numyears = Math.floor(seconds / 31536000);
    var numdays = Math.floor((seconds % 31536000) / 86400);
    var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    var numseconds = Math.floor(((seconds % 31536000) % 86400) % 3600) % 60;

    if (numyears > 0) {
      str += numyears + 'y ';
    }
    if (numdays > 0) {
      str += numdays + 'd ';
    }
    if (numhours > 0) {
      str += numhours + 'h ';
    }
    if (numminutes > 0 && numdays === 0) {
      str += numminutes + 'm ';
    }
    if (numseconds > 0 && numhours === 0 && numdays === 0) {
      str += numseconds + 's ';
    }
    if (!str) {
      return noValueIndicator;
    }
    return str;
  },
  numberFormat(text) {
    if (text < 999) {
      return text;
    }
    return String(text).replace(/(.)(?=(\d{3})+$)/g, '$1.');
  },
};
