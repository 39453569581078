<template>
  <div v-show="loaded" class="row-col scrollable">
    <div v-if="!isFeatureFlagEnabled" class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis" v-text="record.name"></span>
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <back-header
            v-if="isFeatureFlagEnabled"
            class="mb-4 mt-6 px-6 xs:pl-6 xs:pr-0"
            :title="record.name"
            @click="handleBackButtonClick"
          />
          <div class="p-b-0 container pb-4">
            <div class="pb-4">
              <form @submit.prevent="save">
                <div class="box">
                  <div class="box-header">
                    <h2>{{ $t('rules.setting_rule') }}</h2>
                  </div>
                  <div class="box-divider m-a-0"></div>
                  <div class="box-body">
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('rules.setting_name') }}</label>
                      <div class="col-sm-10">
                        <input v-model="record.name" type="text" autocomplete="off" class="form-control" />
                        <span class="form-text">{{ $t('rules.setting_the_internal_rule_name') }}</span>
                      </div>
                    </div>

                    <div v-if="false" class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('rules.setting_type') }}</label>
                      <div class="col-sm-10">
                        <select v-model="record.type" class="form-control">
                          <option value="AUTOMATIC">{{ $t('rules.setting_type_option_automatic') }}</option>
                          <option value="MANUAL">{{ $t('rules.setting_type_option_manual') }}</option>
                        </select>
                        <span class="form-text">{{ $t('rules.setting_type_automatic_or_manual') }}</span>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('rules.setting_active') }}</label>
                      <div class="col-sm-10">
                        <label class="ui-switch success m-t-xs m-r">
                          <input v-model="record.active" type="checkbox" />
                          <i></i>
                        </label>
                        <span class="form-text">{{ $t('rules.setting_activate_or_deactivate_this_rule') }}</span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('rules.setting_channels') }}</label>
                      <div class="col-sm-10">
                        <multiselect
                          v-model="record.channels"
                          label="display_name"
                          track-by="id"
                          :allow-empty="false"
                          :options="channels"
                          :searchable="true"
                          :close-on-select="true"
                          :show-labels="false"
                          :multiple="true"
                          :placeholder="$t('rules.setting_select_one_or_more_channels')"
                        />
                        <span class="form-text">
                          {{ $t('rules.setting_select_the_channels_for_which_this_rule_applies') }}
                        </span>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('rules.setting_when') }}</label>
                      <div class="col-sm-10">
                        <multiselect
                          v-model="record.triggers"
                          label="name"
                          track-by="trigger"
                          :options="baseTriggers"
                          :multiple="true"
                          :show-labels="false"
                          :allow-empty="false"
                          :placeholder="$t('rules.setting_select_one_or_more_triggers')"
                        />
                        <span v-if="record.triggers.find((t) => t.trigger === 'INBOUND_MESSAGE')" class="form-text">
                          {{
                            $t(
                              'rules.setting_rules_will_not_be_triggered_for_tickets_assigned_to_a_chatbot_tickets_assigned_to_a_flowbot_will_trigger_rules_like_normal'
                            )
                          }}
                        </span>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('rules.setting_sort_order') }}</label>
                      <div class="col-sm-10">
                        <input v-model="record.sort_order" type="number" autocomplete="off" class="form-control" />
                        <span class="form-text">
                          {{ $t('rules.setting_order_in_which_rules_are_being_validated_and_activated') }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="box">
                  <div class="box-header">
                    <h2>{{ $t('rules.condition_conditions') }}</h2>
                  </div>
                  <div class="box-divider m-a-0"></div>
                  <div class="box-body">
                    <div v-for="(statement, index) in record.statements" :key="index">
                      <div
                        v-show="index !== 0 && index !== record.statements.length"
                        class="text-center text-base"
                        style="margin-top: -5px; margin-bottom: 15px"
                      >
                        <a class="text-base text-grey-600" @click="addStatement">{{ $t('rules.condition_and') }}</a>
                      </div>
                      <div class="box pos-relative">
                        <a
                          v-if="record.statements.length > 1"
                          class="absolute"
                          style="right: 10px; top: 10px"
                          @click="deleteStatement(statement)"
                        >
                          <i class="material-icons text-grey-500">remove_circle_outline</i>
                        </a>
                        <div class="box-body">
                          <div v-for="(condition, index) in statement.conditions" :key="index" class="mb-4">
                            <div class="flex items-center">
                              <div class="row flex-1">
                                <div
                                  :class="{
                                    'col-md-3': selectedCustomFieldConditionField(condition.field),
                                    'col-md-6': !selectedCustomFieldConditionField(condition.field),
                                  }"
                                >
                                  <select
                                    v-model="condition.field"
                                    class="form-control"
                                    @change="onConditionFieldUpdated(condition)"
                                  >
                                    <template v-for="(baseConditionSet, baseConditionSetName) in baseConditionSets">
                                      <optgroup
                                        v-if="baseConditionSetName && baseConditionSetName !== 'null'"
                                        v-show="
                                          record.triggers
                                            .map((t) => triggerTypeToContext(t.trigger))
                                            .includes(baseConditionSetName.toLowerCase())
                                        "
                                        :key="baseConditionSetName"
                                        :label="baseConditionSetName"
                                      >
                                        <option
                                          v-for="(baseCondition, index) in baseConditionSet"
                                          :key="index"
                                          :value="baseCondition.name"
                                          v-text="baseCondition.label"
                                        />
                                      </optgroup>
                                      <template v-else>
                                        <option
                                          v-for="(baseCondition, baseConditionSetName) in baseConditionSet"
                                          :key="baseConditionSetName"
                                          :value="baseCondition.name"
                                          v-text="baseCondition.label"
                                        />
                                      </template>
                                    </template>
                                  </select>
                                </div>

                                <template
                                  v-if="
                                    ['ticket.custom_field', 'contact.custom_field', 'profile.custom_field'].includes(
                                      condition.field
                                    )
                                  "
                                >
                                  <div class="col-md-3">
                                    <select v-model="condition.meta" class="form-control flex-1">
                                      <option selected disabled :value="null">Choose field</option>
                                      <template v-if="condition.field === 'ticket.custom_field'">
                                        <option
                                          v-for="(customField, index) in $root.customTicketFields"
                                          :key="index"
                                          :value="customField.title"
                                        >
                                          {{ customField.title }}
                                        </option>
                                      </template>

                                      <template v-if="condition.field === 'contact.custom_field'">
                                        <option
                                          v-for="(customField, index) in $root.customContactFields"
                                          :key="index"
                                          :value="customField.title"
                                        >
                                          {{ customField.title }}
                                        </option>
                                      </template>
                                      <template v-if="condition.field === 'profile.custom_field'">
                                        <option
                                          v-for="(customField, index) in $root.customProfileFields"
                                          :key="index"
                                          :value="customField.title"
                                        >
                                          {{ customField.title }}
                                        </option>
                                      </template>
                                    </select>
                                  </div>
                                </template>

                                <div v-if="condition.operator !== 'TIME_AGO'" class="col-md-2">
                                  <select
                                    v-if="condition.field != null"
                                    v-model="condition.operator"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="(operator, index) in getConditionOperators(condition.field)"
                                      :key="index"
                                      :value="operator"
                                      v-text="getOperatorDisplayName(operator)"
                                    ></option>
                                  </select>
                                </div>
                                <div class="col-md-4">
                                  <template
                                    v-if="getValueTemplate(condition.field) == 'string' && condition.operator != null"
                                  >
                                    <input v-model="condition.payload" type="text" class="form-control" />
                                  </template>
                                  <template v-if="getValueTemplate(condition.field) == 'ticket_status'">
                                    <select v-model="condition.payload" class="form-control">
                                      <option
                                        v-for="ticketStatus in ticketStatuses"
                                        :key="ticketStatus.name"
                                        :value="ticketStatus.name"
                                        v-text="ticketStatus.name"
                                      ></option>
                                    </select>
                                  </template>
                                  <template v-if="getValueTemplate(condition.field) == 'team'">
                                    <select v-model="condition.payload" class="form-control">
                                      <option
                                        v-for="team in teams"
                                        :key="team.id"
                                        :value="team.id"
                                        v-text="team.name"
                                      ></option>
                                    </select>
                                  </template>
                                  <template v-if="getValueTemplate(condition.field) == 'labels'">
                                    <select v-model="condition.payload" class="form-control">
                                      <option
                                        v-for="label in $root.labels"
                                        :key="label.id"
                                        :value="label.id"
                                        v-text="label.archived ? label.name + ' (archived)' : label.name"
                                      ></option>
                                    </select>
                                  </template>
                                  <template v-if="getValueTemplate(condition.field) == 'business_hours'">
                                    <select v-model="condition.payload" class="form-control">
                                      <option
                                        v-for="business_hour in business_hours"
                                        :key="business_hour.id"
                                        :value="business_hour.id"
                                        v-text="business_hour.name + ' (' + business_hour.timezone + ')'"
                                      ></option>
                                    </select>
                                  </template>
                                  <template v-if="condition.operator === 'TIME_AGO'">
                                    <div class="flex">
                                      <input
                                        v-model="condition.payload.amount"
                                        type="number"
                                        class="form-control mr-2 flex-1"
                                      />
                                      <select v-model="condition.payload.interval" class="form-control flex-1">
                                        <option value="MINUTES">{{ $t('general.minutes') }}</option>
                                        <option value="HOURS">{{ $t('general.hours') }}</option>
                                        <option value="DAYS">{{ $t('general.days') }}</option>
                                      </select>
                                    </div>
                                  </template>
                                  <template v-if="getValueTemplate(condition.field) == 'contact_profiles'">
                                    <multiselect
                                      v-model="condition.payload"
                                      label="name"
                                      select-label=""
                                      :placeholder="$t('tickets.search_profile')"
                                      :allow-empty="false"
                                      deselect-label=""
                                      track-by="id"
                                      open-direction="bottom"
                                      :options="profiles"
                                      :searchable="true"
                                      :loading="isLoading"
                                      :internal-search="false"
                                      :options-limit="300"
                                      :limit="3"
                                      :clear-on-select="true"
                                      :close-on-select="true"
                                      :max-height="600"
                                      :multiple="true"
                                      @search-change="asyncFind"
                                    >
                                      <template #clear="props">
                                        <div
                                          v-if="profiles.length"
                                          class="multiselect__clear"
                                          @mousedown.prevent.stop="clearAll(props.search)"
                                        ></div>
                                      </template>
                                      <template #noResult>
                                        <span>{{ $t('general.query_no_results') }}</span>
                                      </template>
                                    </multiselect>
                                  </template>
                                  <template v-if="getValueTemplate(condition.field) == 'contact_group'">
                                    <multiselect
                                      v-model="condition.payload"
                                      label="name"
                                      track-by="id"
                                      :allow-empty="false"
                                      :options="$root.contactGroups"
                                      :searchable="true"
                                      :close-on-select="true"
                                      :show-labels="false"
                                      :multiple="true"
                                      :placeholder="$t('general.select_one_or_more_contact_groups')"
                                    >
                                      <template #clear="props">
                                        <div
                                          v-if="$root.contactGroups.length"
                                          class="multiselect__clear"
                                          @mousedown.prevent.stop="clearAll(props.search)"
                                        ></div>
                                      </template>
                                      <template #noResult>
                                        <span>{{ $t('general.query_no_results') }}</span>
                                      </template>
                                    </multiselect>
                                  </template>

                                  <!-- Todo: make dynamic -->
                                  <template v-if="getValueTemplate(condition.field) == 'boolean'">
                                    <select v-model="condition.payload" class="form-control">
                                      <option value="1">{{ $t('general.yes') }}</option>
                                      <option value="0">{{ $t('general.no') }}</option>
                                    </select>
                                  </template>

                                  <template v-if="getValueTemplate(condition.field) == 'datetime'">
                                    <DateTimePicker
                                      v-model="condition.payload"
                                      format="d-m-Y H:i"
                                      class="form-control"
                                    ></DateTimePicker>
                                  </template>

                                  <template v-if="getValueTemplate(condition.field) == 'causer_types'">
                                    <select v-model="condition.payload" class="form-control">
                                      <option value="user">{{ $t('rules.condition_causer_user') }}</option>
                                      <option value="rule">{{ $t('rules.condition_causer_rule') }}</option>
                                      <option value="chatbot">{{ $t('rules.condition_causer_chatbot') }}</option>
                                    </select>
                                  </template>

                                  <template v-if="getValueTemplate(condition.field) == 'direction'">
                                    <select v-model="condition.payload" class="form-control">
                                      <option value="INBOUND">{{ $t('rules.condition_direction_inbound') }}</option>
                                      <option value="OUTBOUND">{{ $t('rules.condition_direction_outbound') }}</option>
                                    </select>
                                  </template>
                                </div>
                              </div>
                              <div class="ml-3" style="flex-basis: 60px">
                                <a
                                  v-if="statement.conditions.length > 1"
                                  @click="deleteCondition(statement, condition)"
                                >
                                  <i class="material-icons text-grey-400">remove_circle_outline</i>
                                </a>
                              </div>
                            </div>
                            <div
                              v-show="
                                condition.context === 'Message' &&
                                record.triggers.filter(
                                  (t) => t.trigger === 'INBOUND_MESSAGE' || t.trigger === 'OUTBOUND_MESSAGE'
                                ).length !== record.triggers.length
                              "
                              class="form-text"
                            >
                              {{ $t('rules.condition_trigger_loop_info_text_triggered_by_assignment') }}
                            </div>
                            <div
                              v-show="
                                condition.context === 'Assignment' &&
                                record.triggers.filter((t) => t.trigger === 'ASSIGNMENT').length !==
                                  record.triggers.length
                              "
                              class="form-text"
                            >
                              {{ $t('rules.condition_trigger_loop_info_text_triggered_by_inbound_message') }}
                            </div>
                            <div v-show="condition.field === 'time.unreplied'" class="form-text">
                              {{ $t('rules.condition_trigger_delayed_validation_info') }}
                            </div>
                          </div>

                          <div
                            class="border border-b-0 border-l-0 border-r-0 border-t-1 border-grey-200 pt-2 text-center"
                          >
                            <a class="text-muted" @click="addCondition(statement)">
                              {{ $t('rules.condition_plus_or') }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--<div class="text-center text-grey-600 text-base" v-show="index != (record.statements.length-1)">AND</div>-->
                    <div class="text-center">
                      <a class="text-base text-grey-600" @click="addStatement">{{ $t('rules.condition_plus_and') }}</a>
                    </div>
                  </div>
                </div>

                <div class="box">
                  <div class="box-header">
                    <h2>{{ $t('rules.action_title_actions') }}</h2>
                  </div>
                  <div class="box-divider m-a-0"></div>
                  <div class="box-body">
                    <div v-for="(action, idx) in record.actions" :key="idx" class="box">
                      <div class="box-body">
                        <div class="flex items-center">
                          <div class="flex flex-1">
                            <div class="flex-1">
                              <select v-model="action.type" class="form-control" @change="setActionValueType(action)">
                                <option
                                  v-for="(baseAction, index) in availableAction"
                                  :key="index"
                                  :value="baseAction.name"
                                  v-text="baseAction.label"
                                ></option>
                              </select>
                              <div
                                v-if="getActionValueType(action.type) == 'HTML_RICH_TEXT'"
                                class="text-muted text-bold m-1"
                              >
                                {{ $t('rules.action_html_text_will_only_work_on_email_channels') }}
                              </div>
                              <div v-if="action.type === 'ASSIGN_ROUND_ROBIN_TO_TEAM'" class="mt-2 flex">
                                <atomic-toggle
                                  small-version
                                  :model-value="action.meta ? action.meta.consider_user_availability : false"
                                  @update:modelValue="
                                    (val) => {
                                      action.meta = {
                                        ...action.meta,
                                        consider_user_availability: val,
                                      };
                                    }
                                  "
                                />
                                <div class="-ml-2">{{ $t('rules.action_round_robin_consider_user_availability') }}</div>
                              </div>
                            </div>
                            <div v-if="getActionValueType(action.type) != null" class="flex-1 pl-4">
                              <div class="">
                                <template v-if="getActionValueType(action.type) == 'USER'">
                                  <multiselect
                                    v-model="action.payload"
                                    label="full_name"
                                    track-by="id"
                                    :allow-empty="false"
                                    :options="assignableUsers"
                                    :searchable="true"
                                    :close-on-select="true"
                                    :show-labels="false"
                                    :multiple="false"
                                    :placeholder="$t('general.select_a_user')"
                                  >
                                    <template #clear="props">
                                      <div
                                        v-if="assignableUsers.length"
                                        class="multiselect__clear"
                                        @mousedown.prevent.stop="clearAll(props.search)"
                                      ></div>
                                    </template>
                                    <template #noResult>
                                      <span>{{ $t('general.query_no_results') }}</span>
                                    </template>
                                  </multiselect>
                                </template>
                                <template v-if="getActionValueType(action.type) == 'USERS'">
                                  <multiselect
                                    v-model="action.payload"
                                    label="full_name"
                                    track-by="id"
                                    :allow-empty="false"
                                    :options="$root.users"
                                    :searchable="true"
                                    :close-on-select="true"
                                    :show-labels="false"
                                    :multiple="true"
                                    :placeholder="$t('general.select_one_or_more_users')"
                                  >
                                    <template #clear="props">
                                      <div
                                        v-if="$root.users.length"
                                        class="multiselect__clear"
                                        @mousedown.prevent.stop="clearAll(props.search)"
                                      ></div>
                                    </template>
                                    <template #noResult>
                                      <span>{{ $t('general.query_no_results') }}</span>
                                    </template>
                                  </multiselect>
                                </template>
                                <template v-if="getActionValueType(action.type) == 'TEAM'">
                                  <multiselect
                                    v-model="action.payload"
                                    label="name"
                                    track-by="id"
                                    :allow-empty="false"
                                    :options="teams"
                                    :searchable="true"
                                    :close-on-select="true"
                                    :show-labels="false"
                                    :multiple="false"
                                    :placeholder="$t('general.select_a_team')"
                                  >
                                    <template #clear="props">
                                      <div
                                        v-if="$root.teams.length"
                                        class="multiselect__clear"
                                        @mousedown.prevent.stop="clearAll(props.search)"
                                      ></div>
                                    </template>
                                    <template #noResult>
                                      <span>{{ $t('general.query_no_results') }}</span>
                                    </template>
                                  </multiselect>
                                </template>
                                <template v-if="getActionValueType(action.type) == 'LABELS'">
                                  <multiselect
                                    v-model="action.payload"
                                    label="name"
                                    track-by="id"
                                    :allow-empty="false"
                                    :options="$root.labels"
                                    :searchable="true"
                                    :close-on-select="true"
                                    :show-labels="false"
                                    :multiple="true"
                                    :placeholder="$t('general.select_one_or_more_labels')"
                                  >
                                    <template #clear="props">
                                      <div
                                        v-if="$root.labels.length"
                                        class="multiselect__clear"
                                        @mousedown.prevent.stop="clearAll(props.search)"
                                      ></div>
                                    </template>
                                    <template #noResult>
                                      <span>{{ $t('general.query_no_results') }}</span>
                                    </template>
                                  </multiselect>
                                </template>
                                <template v-if="getActionValueType(action.type) == 'CONTACT_GROUPS'">
                                  <multiselect
                                    v-model="action.payload"
                                    label="name"
                                    track-by="id"
                                    :allow-empty="false"
                                    :options="$root.contactGroups"
                                    :searchable="true"
                                    :close-on-select="true"
                                    :show-labels="false"
                                    :multiple="true"
                                    :placeholder="$t('general.select_one_or_more_contact_groups')"
                                  >
                                    <template #clear="props">
                                      <div
                                        v-if="$root.contactGroups.length"
                                        class="multiselect__clear"
                                        @mousedown.prevent.stop="clearAll(props.search)"
                                      ></div>
                                    </template>
                                    <template #noResult>
                                      <span>{{ $t('general.query_no_results') }}</span>
                                    </template>
                                  </multiselect>
                                </template>
                                <template v-if="getActionValueType(action.type) == 'EMAIL'">
                                  <input
                                    v-model="action.payload"
                                    class="form-control"
                                    :placeholder="$t('rules.action_forward_email_address')"
                                  />
                                </template>

                                <template v-if="getActionValueType(action.type) == 'SLACK_WEBHOOK'">
                                  <input
                                    v-model="action.payload"
                                    class="form-control"
                                    placeholder="https://hooks.slack.com/services/T00000000/B00000000/XXXXXXXXXXXXXXXXXXXXXXXX"
                                  />
                                  <div class="text-muted text-bold mt-1">
                                    {{ $t('rules.action_slack_paste_your_slack_webhook_url_here') }}
                                    <a href="https://api.slack.com/incoming-webhooks" target="_blank">Need help?</a>
                                  </div>
                                </template>

                                <template v-if="getActionValueType(action.type) == 'KLAVIYO_SITEID'">
                                  <input v-model="action.payload" class="form-control" placeholder="Enter site ID" />
                                  <div class="text-muted text-bold mt-1">
                                    {{ $t('rules.action_klaviyo_paste_your_klaviyo_site_id_here') }}
                                    <a
                                      href="https://developers.klaviyo.com/en/docs/retrieve_api_credentials"
                                      target="_blank"
                                    >
                                      Need help?
                                    </a>
                                  </div>
                                </template>

                                <template v-if="getActionValueType(action.type) == 'TEXT'">
                                  <textarea
                                    v-model="action.payload"
                                    class="form-control"
                                    rows="5"
                                    :placeholder="$t('rules.action_send_message_enter_text')"
                                  ></textarea>
                                </template>

                                <template v-if="getActionValueType(action.type) == 'HTML_RICH_TEXT'">
                                  <froala v-model="action.payload" :image="true"></froala>
                                  <span class="form-text">
                                    {{
                                      $t('rules.action_send_rich_text_the_signature_are_removed_if_no_agent_assigned')
                                    }}
                                  </span>
                                  <div class="list-group mt-2">
                                    <div class="list-group-item text-muted font-bold">
                                      {{ $t('rules.action_send_rich_text_available_tags') }}
                                    </div>
                                    <div class="list-group-item">
                                      [ticket.id]
                                      <span class="text-muted font-italic pl-2">
                                        {{ $t('rules.action_send_rich_text_tags_ticket_id') }}
                                      </span>
                                    </div>
                                    <div class="list-group-item">
                                      [profile.name]
                                      <span class="text-muted font-italic pl-2">
                                        {{ $t('rules.action_send_rich_text_tags_profile_name') }}
                                      </span>
                                    </div>
                                    <div class="list-group-item">
                                      [agent.first_name]
                                      <span class="text-muted font-italic pl-2">
                                        {{ $t('rules.action_send_rich_text_tags_agent_first_name') }}
                                      </span>
                                    </div>
                                    <div class="list-group-item">
                                      [agent.last_name]
                                      <span class="text-muted font-italic pl-2">
                                        {{ $t('rules.action_send_rich_text_tags_agent_last_name') }}
                                      </span>
                                    </div>
                                    <div class="list-group-item">
                                      [agent.image]
                                      <span class="text-muted font-italic pl-2">
                                        {{ $t('rules.action_send_rich_text_tags_agent_image') }}
                                      </span>
                                    </div>
                                    <div class="list-group-item">
                                      [agent.image width=200]
                                      <span class="text-muted font-italic pl-2">
                                        {{ $t('rules.action_send_rich_text_tags_agent_image_size') }}
                                      </span>
                                    </div>
                                    <div class="list-group-item">
                                      [agent.image rounded=false]
                                      <span class="text-muted font-italic pl-2">
                                        {{ $t('rules.action_send_rich_text_tags_agent_square_image') }}
                                      </span>
                                    </div>
                                    <div
                                      v-for="(field, index) in $root.customUserFields"
                                      :key="index"
                                      class="list-group-item"
                                    >
                                      [agent.custom_field.{{ field.id }}]
                                      <span class="text-muted font-italic pl-2">
                                        {{ field.title }}
                                      </span>
                                    </div>
                                  </div>
                                </template>

                                <template v-if="getActionValueType(action.type) == 'CSAT'">
                                  <multiselect
                                    v-model="action.payload"
                                    label="name"
                                    track-by="id"
                                    :allow-empty="false"
                                    :options="csats"
                                    :searchable="true"
                                    :close-on-select="true"
                                    :show-labels="false"
                                    :multiple="false"
                                    :placeholder="$t('general.select_an_active_csat')"
                                  >
                                    <template #clear="props">
                                      <div
                                        v-if="csats.length"
                                        class="multiselect__clear"
                                        @mousedown.prevent.stop="clearAll(props.search)"
                                      ></div>
                                    </template>
                                    <template #noResult>
                                      <span>{{ $t('general.query_no_results') }}</span>
                                    </template>
                                  </multiselect>
                                </template>

                                <template v-if="getActionValueType(action.type) == 'TICKET_RESULT'">
                                  <multiselect
                                    v-model="action.payload"
                                    label="name"
                                    track-by="id"
                                    :allow-empty="false"
                                    :options="$root.ticketResults"
                                    :searchable="true"
                                    :close-on-select="true"
                                    :show-labels="false"
                                    :multiple="false"
                                    :placeholder="$t('general.select_one_ticket_result')"
                                  >
                                    <template #clear="props">
                                      <div
                                        v-if="$root.ticketResults.length"
                                        class="multiselect__clear"
                                        @mousedown.prevent.stop="clearAll(props.search)"
                                      ></div>
                                    </template>
                                    <template #noResult>
                                      <span>{{ $t('general.query_no_results') }}</span>
                                    </template>
                                  </multiselect>
                                </template>

                                <template v-if="getActionValueType(action.type) == 'FLOWBOT'">
                                  <multiselect
                                    v-model="action.payload"
                                    label="title"
                                    track-by="id"
                                    :allow-empty="false"
                                    :options="flowbots"
                                    :searchable="true"
                                    :close-on-select="true"
                                    :show-labels="false"
                                    :multiple="false"
                                    :placeholder="$t('general.select_an_active_flowbot')"
                                  >
                                    <template #clear="props">
                                      <div
                                        v-if="flowbots.length"
                                        class="multiselect__clear"
                                        @mousedown.prevent.stop="clearAll(props.search)"
                                      ></div>
                                    </template>
                                    <template #noResult>
                                      <span>{{ $t('general.query_no_results') }}</span>
                                    </template>
                                  </multiselect>
                                </template>
                              </div>
                            </div>
                          </div>
                          <div v-if="record.actions.length > 1" class="ml-3">
                            <a @click="deleteAction(action)">
                              <i class="material-icons text-grey-500">remove_circle_outline</i>
                            </a>
                          </div>
                        </div>
                        <div v-if="getActionValueType(action.type) == 'CSAT'" class="form-text">
                          {{ $t('rules.action_csat_assigned_agent_info_text') }}
                        </div>
                      </div>
                    </div>
                    <div class="text-center text-center">
                      <a class="text-base text-grey-600" @click="addAction">{{ $t('rules.actions_plus_add') }}</a>
                    </div>
                  </div>
                </div>

                <div class="box">
                  <div class="box-body flow-root">
                    <button
                      v-if="!this.$route.params.sentFromCsat"
                      type="submit"
                      :class="{ loader: saving }"
                      class="btn success text-white"
                      :disabled="saving || !ready_to_save"
                      v-text="saving ? $t('general.saving') : $t('general.save_changes')"
                    ></button>
                    <button
                      v-if="actionType === 'edit'"
                      type="button"
                      :class="{ loader: saving }"
                      class="btn btn-white ml-2"
                      :disabled="saving || !ready_to_save"
                      @click="cloneCurrentRule"
                      v-text="saving ? $t('general.saving') : $t('rules.clone_clone_rule')"
                    ></button>
                    <button
                      v-if="actionType == 'edit'"
                      type="button"
                      class="btn btn-link text-muted pull-right"
                      @click="deleteRule"
                    >
                      {{ $t('rules.delete_button') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div v-if="this.$route.params.sentFromCsat" class="footer flex flex-row py-4 pl-16 pr-12">
            <atomic-button size="md" class="mr-6" @click="goBackToCsat()">
              <i class="far fa-arrow-left pr-2"></i>
              {{ $t('csat.back_to_csat') }}
            </atomic-button>
            <atomic-button size="md" btn-style="secondary" @click="saveAndRedirect()">
              {{ actionType === 'create' ? $t('csat.add_rules_to_csat') : $t('csat.edit_csat_rules') }}
            </atomic-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import _, { filter, map, toArray, groupBy, slice } from 'lodash';
import { mapStores } from 'pinia';
import Multiselect from 'vue-multiselect';

import { FEATURE, FEATURE_FLAG_SETTINGS } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useEntitlementsStore, useFeatureFlagStore } from '@/store/pinia';
import { duplicateObject } from '@/util/helpers';

import PrettyName from './PrettyName';
import RouterWarningOnEdit from '../../mixins/RouterWarningOnEdit';
import BackHeader from '../common/PageHeaders/BackHeader';
import DateTimePicker from '../Elements/DateTimePicker';
import Froala from '../ReplyForm/Froala.vue';

export default {
  name: 'RulesEdit',
  emits: ['call-parent-loadList-method'],
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },

  components: {
    DateTimePicker,
    Multiselect,
    Froala,
    BackHeader,
  },

  mixins: [RouterWarningOnEdit],

  computed: {
    ...mapStores(useEntitlementsStore),

    channels() {
      return toArray(
        filter(this.$root.channels, (c) => {
          return (
            c.type === 'EMAIL' ||
            c.type === 'FACEBOOK' ||
            c.type === 'TELEGRAM' ||
            c.type === 'WHATSAPP' ||
            c.type === 'CHAT' ||
            c.type === 'SMS' ||
            c.type === 'WA_BUSINESS' ||
            c.type === 'GBM' ||
            c.type === 'INSTAGRAM' ||
            c.type === 'CUSTOM'
          );
        })
      );
    },

    ready_to_save() {
      if (this.record.name == '') {
        return;
      }

      if (this.record.triggers.length == 0) {
        return false;
      }

      if (this.record.statements.length == 0) {
        return false;
      }

      if (this.record.actions.length == 0) {
        return false;
      }

      if (this.record.name.length == 0) {
        return false;
      }

      if (this.record.channels.length == 0) {
        return false;
      }

      return true;
    },

    baseConditionSets() {
      return groupBy(this.baseConditions, 'context');
    },

    availableAction() {
      return this.baseActions.filter((a) => {
        if (
          this.record.triggers.find((t) => t.trigger === 'ASSIGNMENT') &&
          (a.name === 'ASSIGN_TO_TEAM' || a.name === 'ASSIGN_TO_USER' || a.name === 'ASSIGN_ROUND_ROBIN_TO_TEAM')
        ) {
          return false;
        }
        return true;
      });
    },
    isFeatureFlagEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_SETTINGS.TA_SETTINGS_PAGE);
    },
  },

  data() {
    return {
      record: {
        name: '',
        type: 'AUTOMATIC',
        active: 1,
        triggers: [],
        actions: [{}],
        statements: [],
        channels: [],
      },

      isLoading: false,
      baseConditions: false,
      ticketStatuses: [],
      profiles: [],
      baseActions: [],
      baseTriggers: [],
      saving: false,
      teams: [],
      business_hours: [],
      flowbots: [],
      csats: [],
      assignableUsers: [],
      ruleCount: 0,
      actionType: this.action,
    };
  },

  mounted() {
    if (localStorage.getItem('ruleCount')) {
      this.ruleCount = parseInt(localStorage.getItem('ruleCount'));
    }

    if (this.$route.params.sentFromCsat && this.actionType === 'create') {
      const record = {
        type: 'AUTOMATIC',
        active: true,
        triggers: [
          {
            trigger: 'INBOUND_MESSAGE',
            name: 'Inbound message is received',
          },
        ],
        actions: [{ type: 'TRIGGER_CSAT', payload: null }],
        statements: [
          {
            conditions: [
              {
                field: 'time.unreplied',
                operator: 'TIME_AGO',
                payload: { amount: 5, interval: 'HOURS' },
                context: null,
              },
            ],
          },
        ],
        channels: [],
      };
      this.record = record;
    }
    axios.get('/api/v2/rules/conditions').then((result) => {
      this.baseConditions = result.data;

      if (this.actionType === 'edit') {
        axios.get('/api/v2/rules/' + this.$route.params.id).then((result) => {
          this.record = result.data;
          this.initWarning();
        });
      } else {
        if (this.$route.params.sentFromCsat) {
          this.record.name = this.$route.params.csatName;
        } else {
          this.record.name = PrettyName.get();
        }
        if (!this.$route.params.sentFromCsat) {
          this.addStatement();
        }
        this.initWarning();
      }
    });

    axios.get('/api/v2/rules/actions').then((result) => {
      this.baseActions = result.data;
    });

    axios.get('/api/v2/rules/triggers').then((result) => {
      this.baseTriggers = result.data;
    });

    axios.get('/api/v2/ticket_statuses').then((result) => {
      this.ticketStatuses = result.data;
    });

    axios.get('/api/v2/teams').then((result) => {
      this.teams = result.data.data;
    });

    axios.get('/api/v2/business_hours').then((result) => {
      this.business_hours = result.data.data;
    });

    axios.get('/api/v2/flowbot').then((result) => {
      if (result.data) {
        this.flowbots = result.data.filter((f) => f.is_active);
      }
    });

    axios.get('/api/v2/csat').then((result) => {
      if (result.data) {
        this.csats = result.data.filter((c) => c);
      }
    });

    this.assignableUsers = this.$root.getAssignableUsers();
  },

  methods: {
    handleBackButtonClick() {
      this.$router.push('/admin/rules/');
      eventBus.$emit('loadList');
    },
    addStatement() {
      let statement = {
        conditions: [],
      };
      this.addCondition(statement);
      this.record.statements.push(statement);
    },

    addCondition(statement) {
      statement.conditions.push({
        field: this.baseConditions[0].name,
        operator: this.baseConditions[0].operators[0],
      });
    },

    addAction() {
      this.record.actions.push({});
    },

    goBackToCsat() {
      router.push({
        name: 'editCsat',
        params: { id: this.$route.params.csatId, comesFromRules: true },
      });
    },

    save(comingFromFooter) {
      if (this.saving) {
        return;
      }

      this.saving = true;

      let data = duplicateObject(this.record);
      data.channels = toArray(map(this.record.channels, 'id'));
      data.statements.forEach((statement) => {
        statement.conditions.forEach((condition) => {
          if (condition.field == 'contact.profile') {
            condition.payload = toArray(map(condition.payload, 'id'));
          }
        });
      });

      data.actions.forEach((action) => {
        let actionType = this.getActionValueType(action.type);
        if (
          actionType == 'USER' ||
          actionType == 'TEAM' ||
          actionType == 'TICKET_RESULT' ||
          actionType == 'FLOWBOT' ||
          actionType == 'CSAT'
        ) {
          try {
            action.payload = action.payload.id;
          } catch (err) {}
        }

        if (actionType == 'USERS' || actionType == 'CONTACT_GROUPS' || actionType == 'LABELS') {
          action.payload = toArray(map(action.payload, 'id'));
        }
      });

      let url = this.actionType == 'create' ? '/api/v2/rules' : '/api/v2/rules/' + this.record.id;

      axios
        .post(url, data)
        .then((res) => {
          this.saving = false;

          if (this.actionType == 'create') {
            this.initWarning(false);
            if (comingFromFooter === true) {
              this.$emit('call-parent-loadList-method');
              this.goBackToCsat();
            } else {
              eventBus.$emit('loadList');
              router.push('/admin/rules/' + res.data.id);
            }
          }
          this.flashSuccess('The rule has been saved successfully.');
          localStorage.setItem('ruleCount', String(this.ruleCount + 1));

          this.initWarning();
          if (comingFromFooter === true && this.actionType == 'edit') {
            this.$emit('call-parent-loadList-method');
            this.goBackToCsat();
          } else if (this.actionType === 'edit') {
            eventBus.$emit('loadList');
          }
        })
        .catch((error) => {
          console.log('error', error);
          let errorMessage = 'Failed to save rule';
          if (error.response && error.response.data && typeof error.response.data.errors === 'object') {
            errorMessage += ': ' + Object.values(error.response.data.errors).flat(Infinity).join(', ');
          }
          this.flashError(errorMessage);
          this.saving = false;
        });
    },

    cloneCurrentRule() {
      if (this.entitlementsStore?.isEntitledTo(FEATURE.CUSTOMER_SERVICE__AUTOMATION__RULES, this.ruleCount + 1)) {
        this.record.name = this.record.name + ' ' + this.$t('rules.clone_copy_suffix');
        this.actionType = 'create';
        this.save();
      } else {
        const requiredPlan = this.entitlementsStore?.getMinimumRequiredPlanFor(
          FEATURE.CUSTOMER_SERVICE__AUTOMATION__RULES,
          this.ruleCount.length + 1
        );
        this.flashError(this.$t('user_management.must_upgrade_to_add_rules', { plan: requiredPlan }));

        this.$router.push('/admin/subscription_settings');
      }
    },

    getOperatorDisplayName(operator) {
      switch (operator) {
        case 'EQUALS':
          return this.$t('rules.operator_equals');
        case 'NOT_EQUALS':
          return this.$t('rules.operator_not_equals');
        case 'CONTAINS':
          return this.$t('rules.operator_contains');
        case 'NOT_CONTAINS':
          return this.$t('rules.operator_not_contains');
        case 'IN':
          return this.$t('rules.operator_in');
        case 'NOT_IN':
          return this.$t('rules.operator_not_in');
        case 'STARTS_WITH':
          return this.$t('rules.operator_starts_with');
        case 'NOT_STARTS_WITH':
          return this.$t('rules.operator_not_starts_with');
        case 'ENDS_WITH':
          return this.$t('rules.operator_ends_with');
        case 'NOT_ENDS_WITH':
          return this.$t('rules.operator_not_ends_with');
        case 'BEFORE':
          return this.$t('rules.operator_before');
        case 'AFTER':
          return this.$t('rules.operator_after');
        case 'CONTAINS_WORD':
          return this.$t('rules.operator_contains_word');
        case 'NOT_CONTAINS_WORD':
          return this.$t('rules.operator_not_contains_word');
      }
    },

    getValueTemplate(field) {
      if (field === undefined) {
        return 'string';
      }
      switch (field) {
        case 'contact.from':
          return 'string';

        case 'contact.profile':
          return 'contact_profiles';

        case 'contact.group':
          return 'contact_group';

        case 'has.attachment':
          return 'boolean';
        case 'message.first':
          return 'boolean';

        case 'ticket.status':
          return 'ticket_status';

        case 'ticket.team':
          return 'team';

        case 'time.unreplied':
          return 'time_unreplied';

        case 'business_hours.closed':
        case 'business_hours.open':
          return 'business_hours';

        case 'datetime':
          return 'datetime';

        case 'ticket.labels':
          return 'labels';

        case 'causer.type':
          return 'causer_types';

        case 'last_message.direction':
          return 'direction';
      }
      return 'string';
    },

    selectedCustomFieldConditionField(field) {
      return ['ticket.custom_field', 'contact.custom_field', 'profile.custom_field'].includes(
        slice(field.split('.'), 0, 2).join('.')
      );
    },

    setActionValueType(action) {
      for (let i = 0; i < this.baseActions.length; i++) {
        if (action.name == this.baseActions[i].name) {
          action.value_type = this.baseActions[i].value_type;
        }
      }

      if (action.type === 'SEND_HTML_RICH_MESSAGE') {
        action.payload = '';
      } else {
        action.payload = null;
      }
    },

    asyncFind(query) {
      this.isLoading = true;

      if (query == '') {
        this.isLoading = false;
        return;
      }

      this.profile_name = query;
      axios.get('/api/v2/profiles', { params: { term: query } }).then((response) => {
        this.profiles = response.data.data;
        this.isLoading = false;
      });
    },

    onConditionFieldUpdated(condition) {
      condition.payload = null;
      condition.operator = this.getConditionOperators(condition.field)[0];
      condition.context = this.baseConditions?.find((c) => c.name === condition.field)?.context;

      if (condition.operator === 'TIME_AGO') {
        condition.payload = {
          amount: 5,
          interval: 'MINUTES',
        };
      }
    },

    deleteStatement(statement) {
      this.record.statements.splice(this.record.statements.indexOf(statement), 1);
    },

    deleteAction(action) {
      this.record.actions.splice(this.record.actions.indexOf(action), 1);
    },

    deleteCondition(statement, condition) {
      statement.conditions.splice(statement.conditions.indexOf(condition), 1);
    },

    getConditionOperators(field) {
      if (this.baseConditions.length == 0) {
        return [];
      }
      let condition = this.baseConditions.filter((condition) => {
        return condition.name == field;
      })[0];
      return condition && condition.operators;
    },

    getActionValueType(field) {
      if (field == undefined) {
        return null;
      }

      if (this.baseActions.length == 0) {
        return null;
      }

      return this.baseActions.filter((action) => {
        return action.name == field;
      })[0].value_type;
    },

    async deleteRule() {
      const shouldDeleteRule = await this.$tConfirm(
        this.$t('rules.delete_this_rule_will_be_permanently_deleted_this_cannot_be_undone'),
        {
          delete: true,
          title: this.$t('rules.delete_delete_this_rule'),
        }
      );
      if (shouldDeleteRule) {
        let url = '/api/v2/rules/' + this.record.id;
        axios
          .delete(url)
          .then(() => {
            if (this.isFeatureFlagEnabled) {
              eventBus.$emit('loadList');
              router.push('/admin/rules');
            } else {
              this.$emit('call-parent-loadList-method');
              this.initWarning(false);
              router.push('/admin/rules/create');
            }
            this.flashSuccess(this.$t('rules.delete_the_rule_is_deleted'));
          })
          .catch(() => {});
      }
    },
    triggerTypeToContext(triggerType) {
      switch (triggerType) {
        case 'INBOUND_MESSAGE':
        case 'OUTBOUND_MESSAGE':
          return 'message';
        case 'ASSIGNMENT':
          return 'assignment';
      }
    },

    saveAndRedirect() {
      this.save(true);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.onBeforeRouteLeaveGuard(to, from, next);
  },
  beforeRouteUpdate(to, from, next) {
    this.onBeforeRouteUpdateGuard(to, from, next);
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: calc(100vh - 140px);
}

.footer {
  background: var(--color-grey-800);
  position: sticky;
  left: 0px;
  bottom: 0px;

  .ui-btn.primary {
    border: 1px solid var(--color-white);
  }
}
</style>
