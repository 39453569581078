<template>
  <div class="mx-auto w-full px-6 max-lg:mt-12 xl3:max-w-[1440px]" data-test="integration-filtered-cards">
    <div class="mt-2 flex w-full flex-row">
      <p class="text-md mt-1 text-grey-700" data-test="integration-filter-description">{{ filterDescription }}</p>
    </div>

    <t-input-search
      :model-value="searchText"
      class="mb-4 max-w-xs"
      :placeholder="$tc('integration_hub.search_for_integrations')"
      data-test="integration-search-bar"
      @input="searchIntegrations"
      @clear="clearSearch"
    />

    <div v-if="inProgress" class="grid gap-4 pb-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl3:grid-cols-4">
      <div v-for="index in 11" :key="index">
        <t-card class="h-full gap-3 bg-white" :focusable="false">
          <straight :type="'round'" class="mb-5" :width="48" :height="48" :border-radius="'100%'" />
          <straight class="mb-2" :width="128" :height="24" />
          <straight class="mb-2" :width="'100%'" :height="12" />
          <straight :width="157" :height="12" />
        </t-card>
      </div>
    </div>
    <integration-cards v-else :integrations="filteredIntegrations" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import Straight from '@/components/Channels/CreateChannelModals/WhatsApp/SkeletonLoader/Straight.vue';
import EcomPlaceholder from '@/components/IntegrationHub/Components/EcomPlaceholder.vue';
import IntegrationCards from '@/components/IntegrationHub/Components/IntegrationCards.vue';

import type { Integration } from '@/store/types/integrations';
import type { TranslateResult } from 'vue-i18n';

export default defineComponent({
  name: 'IntegrationFilter',

  components: {
    IntegrationCards,
    EcomPlaceholder,
    Straight,
  },

  computed: {
    ...mapGetters({
      integrations: 'integrations/getFiltered',
    }),

    filterDescription(): TranslateResult {
      return this.$t(`integration_hub.filter_${this.$route.params.filter}_description`);
    },
  },

  data() {
    return {
      filteredIntegrations: [] as Integration[],
      searchText: '',
      inProgress: true,
    };
  },

  async created() {
    try {
      this.inProgress = true;
      await this.$store.dispatch('integrations/fetchIntegrations');
      await this.$store.dispatch('integrations/filterIntegrations', this.$route.params.filter);
      this.searchIntegrations();
    } catch (e) {
      console.error(e);
    } finally {
      this.inProgress = false;
    }
  },

  methods: {
    searchIntegrations(value: string) {
      this.searchText = value;
      if (!this.searchText) {
        this.filteredIntegrations = this.integrations;
      } else {
        this.filteredIntegrations = this.integrations.filter((integration) => {
          return new RegExp(`${this.searchText}.*`, 'gi').test(integration.name);
        });
      }
    },

    clearSearch() {
      this.searchText = '';
      this.searchIntegrations();
    },
  },

  async beforeRouteUpdate(to, _from, next) {
    await this.$store.dispatch('integrations/filterIntegrations', to.params.filter).then(() => {
      this.searchIntegrations();
    });

    next();
  },
});
</script>
