import {
  ChartColumnLinear,
  InProgressLinear,
  RotateCcwLinear,
  RefreshCwAlertLinear,
  QuestionLinear,
  WalletMinusLinear,
  WalletMoveLinear,
} from '@trengo/trengo-icons';

import { CONVERSATION_TYPE } from '@/Configs/Constants';

import type { ChartItem, EmptyChartPanelItem } from '@/types';

export const PROGRESS_CARD_COLORS = {
  INITIAL_COLORS: {
    FINISHED: 'bg-sky-600',
    IN_PROGRESS: 'bg-sky-300',
  },
  OVER_90_TRESHOLD: {
    FINISHED: 'bg-sun-600',
    IN_PROGRESS: 'bg-sun-300',
  },
  OVER_USAGE: {
    FINISHED: 'bg-error-500',
    IN_PROGRESS: 'bg-error-300',
  },
} as const;

export const CHART_ITEM: ChartItem = {
  label: '',
  backgroundColor: '',
  data: [],
  borderColor: 'rgba(0, 0, 0, 0.1)',
  borderWidth: 1,
  borderRadius: 4,
  barPercentage: 0.85,
  categoryPercentage: 0.7,
  maxBarThickness: 18,
};

export const BALANCE_CHART_CATEGORIES: ChartItem[] = [
  { ...CHART_ITEM, name: 'quota', label: 'usage_overview.balance_chart_commitment', backgroundColor: '#E1E3E5' },
  {
    ...CHART_ITEM,
    name: 'used',
    label: 'usage_overview.balance_chart_current',
    backgroundColor: '#4FA1C8',
    borderColor: '#4791B4',
  },
];

export const CONVERSATION_CHART_CATEGORIES: ChartItem[] = [
  {
    ...CHART_ITEM,
    stack: 'Stack 0',
    groupName: 'standard',
    name: 'quota',
    label: 'usage_overview.conversations_chart_standard_commitment',
    backgroundColor: '#E3F6F3',
  },
  {
    ...CHART_ITEM,
    stack: 'Stack 0',
    groupName: CONVERSATION_TYPE.INTELLIGENT,
    name: 'quota',
    label: 'usage_overview.conversations_chart_automated_commitment',
    backgroundColor: '#FBF4FF',
  },
  {
    ...CHART_ITEM,
    stack: 'Stack 1',
    name: 'used',
    groupName: 'standard',
    label: 'usage_overview.conversations_chart_standard_usage',
    backgroundColor: '#177B6B',
    borderColor: '#156F60',
  },
  {
    ...CHART_ITEM,
    stack: 'Stack 1',
    groupName: CONVERSATION_TYPE.INTELLIGENT,
    name: 'finished',
    label: 'usage_overview.conversations_chart_automated_usage',
    backgroundColor: '#A965D3',
    borderColor: '#985BBE',
  },
];

export const DEFAULT_EMPTY_CHART_PANEL_ITEM: EmptyChartPanelItem = {
  chartName: 'empty',
  icon: WalletMoveLinear,
  panelTitle: 'usage_overview.usage_heading',
  title: 'usage_overview.no_usage',
  description: 'usage_overview.no_usage_subtext',
};

export const CHART_PROPERTIES: EmptyChartPanelItem[] = [
  DEFAULT_EMPTY_CHART_PANEL_ITEM,
  {
    chartName: 'conversations',
    icon: ChartColumnLinear,
    panelTitle: 'usage_overview.conversations_chart_heading',
    title: 'usage_overview.conversations_chart_trial_heading',
    description: 'usage_overview.conversations_chart_trial_subheading',
  },
  {
    chartName: 'balance',
    icon: WalletMinusLinear,
    panelTitle: 'usage_overview.balance_chart_heading',
    title: 'usage_overview.balance_chart_trial_heading',
    description: 'usage_overview.balance_chart_trial_subheading',
  },
];

export const OVERVIEW_INFORMATIONS = [
  {
    title: 'usage_overview.overview_section_info_conversation_blocks_heading',
    description: 'usage_overview.overview_section_info_conversation_blocks_description',
    icon: RefreshCwAlertLinear,
  },
  {
    title: 'usage_overview.overview_section_info_conversation_heading',
    description: 'usage_overview.overview_section_info_conversation_description',
    icon: QuestionLinear,
  },
  {
    title: 'usage_overview.overview_section_info_limitations_reset_heading',
    description: 'usage_overview.overview_section_info_limitations_reset_description',
    icon: RotateCcwLinear,
  },
  {
    title: 'usage_overview.overview_section_info_in_progress_heading',
    description: 'usage_overview.overview_section_info_in_progress_description',
    icon: InProgressLinear,
  },
];

export const PROGRESS_CARD_TOOLTIPS = {
  BALANCE: {
    IN_PROGRESS: '',
    FINISHED: 'usage_overview.overview_section_balance_spent',
  },
  DEFAULT: {
    IN_PROGRESS: 'usage_overview.overview_section_conversations_in_progress',
    FINISHED: 'usage_overview.overview_section_conversations_finished',
  },
  OVER_USAGE: {
    IN_PROGRESS: 'usage_overview.overview_section_conversations_over_usage_in_progress',
    FINISHED: 'usage_overview.overview_section_conversations_over_usage_finished',
  },
} as const;

export const FULL_BAR_LENGTH = 12;
