import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { PERMISSION, USER_ROLE } from '@/Configs/Constants';
import { ADMIN_ROLES } from '@/Configs/Constants/UserRoles';
import { useUserStore, useUsersStore } from '@/store/pinia';

import type { RoleItem } from './types';
import type { User, UserRole } from '@/types';

export function useRolesDropdown(selectedUser?: User) {
  const userStore = useUserStore();
  const usersStore = useUsersStore();
  const { hasPermission } = userStore;

  const { user } = storeToRefs(userStore);
  const { hasOnlyOneAdministrator, primaryUsers } = storeToRefs(usersStore);

  const selectedUserRole: UserRole | undefined = selectedUser?.role?.name;
  const isTheOnlyAdministrator = computed(() => hasOnlyOneAdministrator.value && hasAdminRole(selectedUserRole));
  const isTheOnlyPrimaryUser = computed(() => selectedUser?.is_primary === 1 && primaryUsers.value?.length === 1);

  const getRoles = ({ allowObserver }: { allowObserver: boolean }): RoleItem[] => {
    return Object.values(USER_ROLE)
      .filter((role) => isRoleAssignable(role, allowObserver))
      .map(
        (role) =>
          ({
            value: role,
            titleKey: `user_management.${role.toLowerCase()}`,
            subtitleKey: `user_management.${role.toLowerCase()}_description`,
            isDisabled: selectedUser && selectedUserRole && role === selectedUserRole,
          }) as RoleItem,
      );
  };

  const hasAdminRole = (role?: UserRole) => role && ADMIN_ROLES.includes(role);

  const userCanBeDeleted = computed(() => {
    if (!selectedUser) return false;

    const isSelectedUser = user.value?.id === selectedUser?.id;
    const isAdmin = selectedUserRole && hasAdminRole(selectedUserRole);
    const canDeleteAdmin = hasPermission(PERMISSION.PERMISSIONS__USERS__ASSIGN_ADMIN_ROLE);
    const isOnlyAdmin = isAdmin && hasOnlyOneAdministrator.value;

    switch (true) {
      case isSelectedUser:
      case isOnlyAdmin:
      case isTheOnlyPrimaryUser.value:
      case isAdmin && !canDeleteAdmin:
        return false;
      default:
        return true;
    }
  });

  const isRoleAssignable = (role: UserRole, allowObserver: boolean): boolean => {
    const hasAssignAdminPermission = hasPermission(PERMISSION.PERMISSIONS__USERS__ASSIGN_ADMIN_ROLE);
    const hasTrengoRoleOutsideTrengo = role === USER_ROLE.TRENGO && user.value?.agency_id !== 1;
    const isObserverRole = role === USER_ROLE.OBSERVER;
    const isSelectedUserObserver = selectedUserRole === USER_ROLE.OBSERVER;
    const canManageSubscription = hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE);

    switch (true) {
      case hasAdminRole(role) && !hasAssignAdminPermission:
      case hasTrengoRoleOutsideTrengo:
      case !allowObserver && isObserverRole && !isSelectedUserObserver:
      case isObserverRole && !canManageSubscription:
        return false;
      default:
        return true;
    }
  };

  return {
    isTheOnlyAdministrator,
    isTheOnlyPrimaryUser,
    userCanBeDeleted,
    currentRole: selectedUserRole,
    hasAdminRole,
    getRoles,
  };
}
