<script type="text/babel">
export default {
  name: 'Avatar',
  props: {
    color: null,
    abbr: '',
    image: '',
    width: {
      default: 40,
    },
    fontSize: {
      default: '14px',
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    capitalizedAbbr() {
      if (!this.abbr) {
        return;
      }
      return this.abbr.toUpperCase();
    },
  },
};
</script>

<template>
  <span
    v-if="image == null"
    class="circle inline-block-40px avatar flex-shrink-0 select-none"
    :class="{ 'green-lighter border-2': border }"
    :style="{
      'font-size': fontSize,
      width: width + 'px',
      height: width + 'px',
      'line-height': width + 'px',
      color: 'white',
      background: color,
    }"
  >
    {{ capitalizedAbbr }}
  </span>
  <span
    v-else
    class="circle avatar inline-block-40px flex-shrink-0 select-none"
    :class="{ 'border-2 border-green-lighter': border }"
    :style="{ width: width + 'px', height: width + 'px', 'line-height': 1 }"
  >
    <img :src="image" style="border-radius: 100%" />
  </span>
</template>
