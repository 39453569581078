<template>
  <t-button
    v-tooltip="{
      placement: 'top',
      content: $te('navigation.help_center') ? $t('navigation.help_center') : 'Help Center',
    }"
    class="fixed bottom-6 right-6"
    size="lg"
    btn-style="secondary"
    icon-only
    :href="link"
    target="_blank"
  >
    <template #icon>
      <question-linear size="1.5rem" />
    </template>
  </t-button>
</template>

<script lang="ts">
import { QuestionLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HelpCenterButton',
  components: { QuestionLinear },
  props: {
    locale: {
      type: String,
      default: '',
    },
  },
  computed: {
    link() {
      return `https://help.trengo.com/${this.locale === 'en' ? '' : this.locale}`;
    },
  },
});
</script>
