import { PRICING_MODEL, SUBSCRIPTION_ADDON_CHARGEBEE_TYPE } from '@/Configs/Constants';

import type { PricingModel } from '@/types';
import type {
  SubscriptionAddonDetailType,
  SubscriptionAddonSeatBased,
  SubscriptionAddonUsageBased,
  SubscriptionItem,
} from '@/types/chargebee';

export const getAddonsForSeatBased = (subscriptionItems: SubscriptionItem[]): SubscriptionAddonSeatBased[] =>
  subscriptionItems.map((item) => ({
    type: item.item_type === 'plan' ? 'user_seats' : 'observer_seats',
    count: item?.quantity ?? 0,
    totalAmount: item?.amount ?? 0,
  })) ?? [];

export const getAddonsForUsageBased = (subscriptionItems: SubscriptionItem[]): SubscriptionAddonUsageBased[] =>
  subscriptionItems.reduce<SubscriptionAddonUsageBased[]>((list, item) => {
    const type = Object.values(SUBSCRIPTION_ADDON_CHARGEBEE_TYPE).find((type) => item.item_price_id.includes(type));
    if (type) {
      list.push({
        type: type as SubscriptionAddonDetailType,
        quantity: item?.quantity ?? 0,
      });
    }
    return list;
  }, []);

const getAddonQuantity = (subscriptionItems: SubscriptionItem[], type: string) =>
  subscriptionItems.find((item) => item.item_price_id.includes(type))?.quantity ?? 0;

export const getUserQuantity = (pricingModel: PricingModel, subscriptionItems: SubscriptionItem[]) => {
  if (pricingModel === PRICING_MODEL.SEAT_BASED)
    return subscriptionItems.find((item) => item.item_type === 'plan')?.quantity ?? 0;

  return getAddonQuantity(subscriptionItems, SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.USER_SEATS) + 5;
};

export const getObserverQuantity = (subscriptionItems: SubscriptionItem[]) =>
  getAddonQuantity(subscriptionItems, SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.OBSERVER_SEATS);
