<template>
  <t-tooltip :text="tooltipText" data-test="feature-feedback-tooltip">
    <button
      data-test="feature-feedback-thumbs"
      :disabled="isDisabled"
      type="button"
      class="relative grid size-8 max-h-8 cursor-pointer place-content-center overflow-hidden rounded-lg hover:bg-grey-200"
      @click="handleClick"
    >
      <Transition :duration="{ enter: 500, leave: 100 }" name="transition" mode="out-in">
        <component :is="iconComponent" size="20px" />
      </Transition>
    </button>
  </t-tooltip>
</template>

<script setup lang="ts">
import { ThumbsDownFill, ThumbsDownLinear, ThumbsUpFill, ThumbsUpLinear } from '@trengo/trengo-icons';
import { computed } from 'vue';

const props = defineProps<{
  isDisabled: boolean;
  isPressed: boolean | null;
  tooltipText: string;
  isPositive: boolean;
}>();

const emit = defineEmits<{
  (e: 'feedback', isPositive: boolean): void;
}>();

const iconComponent = computed(() => {
  if (props.isPositive) {
    return props.isPressed ? ThumbsUpFill : ThumbsUpLinear;
  } else {
    return props.isPressed ? ThumbsDownFill : ThumbsDownLinear;
  }
});

const handleClick = () => {
  emit('feedback', props.isPositive);
};
</script>

<style src="./animation.css" scoped />
