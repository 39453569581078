import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "t-text-h3 my-auto max-w-[90%] p-0"
}
const _hoisted_2 = {
  key: 1,
  class: "t-text-h3 my-auto max-w-[90%] p-0"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  class: "t-text-md text-grey-800",
  "data-test": "integration-description"
}
const _hoisted_5 = { class: "t-text-md-emphasize" }
const _hoisted_6 = { class: "flex gap-4" }
const _hoisted_7 = { class: "grid h-10 w-10 flex-none place-items-center rounded-xl bg-sky-200" }
const _hoisted_8 = { class: "m-t-2" }
const _hoisted_9 = { class: "t-text-md-emphasize" }
const _hoisted_10 = { class: "flex gap-4" }
const _hoisted_11 = { class: "grid h-10 w-10 flex-none place-items-center rounded-xl bg-purple-200" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dynamic_form_fields = _resolveComponent("dynamic-form-fields")!
  const _component_lock_opened_linear = _resolveComponent("lock-opened-linear")!
  const _component_user_linear = _resolveComponent("user-linear")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_modal = _resolveComponent("t-modal")!

  return (_openBlock(), _createBlock(_component_t_modal, {
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isOpen) = $event)),
    "close-on-backdrop-click": false,
    "close-on-esc": false,
    variant: "narrow",
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close', { hasCancelled: true })))
  }, {
    header: _withCtx(() => [
      (_ctx.isReauthenticating)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_ctx.$tc('integration_hub.reauthenticate_integration')), 1 /* TEXT */))
        : (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.$t('integration_hub.install_modal_title', { name: _ctx.integration.name })), 1 /* TEXT */))
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_t_button, {
        "btn-style": "secondary",
        "data-test": "done-button",
        disabled: _ctx.inProgress,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close', { hasCancelled: true })))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('general.cancel')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled"]),
      _createVNode(_component_t_button, {
        "data-test": "continue-button",
        disabled: _ctx.inProgress,
        onClick: _ctx.install
      }, {
        default: _withCtx(() => [
          (_ctx.isOAuthType)
            ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t('integration_hub.install_continue_button', { name: _ctx.integration.name })), 1 /* TEXT */))
            : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t('integration_hub.install_integration')), 1 /* TEXT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "onClick"])
    ]),
    default: _withCtx(() => [
      (_ctx.integration.form_fields.length > 0)
        ? (_openBlock(), _createBlock(_component_dynamic_form_fields, {
            key: 0,
            "prefilled-settings": _ctx.prefilledData,
            onFormChanged: _ctx.onFormChanged
          }, null, 8 /* PROPS */, ["prefilled-settings", "onFormChanged"]))
        : _createCommentVNode("v-if", true),
      (_ctx.integration.form_fields.length === 0 && _ctx.integration.auth_type === 'oauth2')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('integration_hub.install_modal_description', { name: _ctx.integration.name })), 1 /* TEXT */),
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('integration_hub.install_step_1')), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_lock_opened_linear, {
                    width: "24px",
                    height: "24px"
                  })
                ]),
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('integration_hub.install_step_1_description', { name: _ctx.integration.name })), 1 /* TEXT */)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('integration_hub.install_step_2')), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_user_linear, {
                    width: "24px",
                    height: "24px"
                  })
                ]),
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('integration_hub.install_step_2_description', { name: _ctx.integration.name })), 1 /* TEXT */)
              ])
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}