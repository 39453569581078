<template>
  <div>
    <setting-item :show-border="false" :custom-class="{ container: 'mt-4 sm:mt-0 pt-3 pb-1' }">
      <template #title>
        <div class="mb-12px flex flex-row items-center">
          <div class="mr-2 rounded-xl bg-sun-100 p-3">
            <img class="h-6 w-6" :src="`${$root.assetsURL}img/inbox-in-filled.svg`" alt="inbox" />
          </div>
          <span>{{ $t('email_settings.receiving') }}</span>
        </div>
      </template>
    </setting-item>
    <div class="pb-2 text-grey-700">
      <div class="text-lg font-semibold">{{ $t('email_settings.forward_email') }}</div>
      <span class="font-medium">
        {{ $t('email_settings.forward_all_email_to_this_address_to_automatically_create_tickets') }}
      </span>
    </div>
    <div class="flex w-full flex-row">
      <div class="w-84">
        <atomic-input
          v-if="!isCopied"
          v-model="computedReceiverValue"
          :size="responsiveInputSize"
          readonly
          :required="false"
        />
        <span
          v-else
          :class="[responsiveInputSize]"
          class="
            copy-container
            input-container
            flex
            w-full
            items-center
            justify-center
            border-1 border-grey-200
            py-4
            font-semibold
          "
        >
          <i class="fas fa-check-circle mr-2 text-xl lg:text-xl" />
          {{ copiedText }}
        </span>
      </div>
      <div>
        <div
          role="button"
          aria-pressed="false"
          class="ml-3 cursor-pointer rounded-xl border-1 border-grey-300 px-6 py-2 hover:shadow-md lg:px-12 lg:py-4"
          @click="handleCopyClicked"
        >
          <span class="font-semibold">
            <i :class="{ 'mr-2': computedIsLgOrXl }" class="far fa-copy" />
            <span class="hidden lg:inline-block">{{ $t('email_settings.copy') }}</span>
          </span>
        </div>
      </div>
    </div>

    <div class="mt-12">
      <setting-item :show-border="false" :custom-class="{ container: 'mb-4' }">
        <template #title>
          <div class="flex flex-row items-center justify-center">
            <span class="mr-2 flex h-12 w-12 items-center justify-center self-center rounded-xl bg-sky-200 p-3">
              <i class="fas fa-paper-plane" />
            </span>
            {{ $t('email_settings.sending') }}
          </div>
        </template>
      </setting-item>

      <t-multi-choice v-if="featureFlagSendingThroughSmtpEnabled" v-model="sendingThroughSmtp" :options="[true, false]">
        <template #true="{ selected }">
          <t-multi-choice-button
            class="mb-4"
            :title="$t('email_settings.send_email_through_smtp_title')"
            :selected="selected"
          >
            <template #desc>
              {{ $t('email_settings.send_email_through_smtp_desc') }}
            </template>
          </t-multi-choice-button>
        </template>

        <template #false="{ selected }">
          <t-multi-choice-button
            class="mb-4"
            :title="$t('email_settings.send_email_through_dns_title')"
            :selected="selected"
          >
            <template #desc>
              {{ $t('email_settings.send_email_through_dns_desc') }}
            </template>
          </t-multi-choice-button>
        </template>
      </t-multi-choice>
      <div v-if="sendingThroughSmtp === true" class="my-3">
        <t-input-text
          id="smtp-host"
          v-model="computedRecord.emailChannel.meta.smtp_credentials.host"
          data-test="smtp-host"
          :label="$t('email_settings.smtp_host_label')"
          :placeholder="$t('email_settings.smtp_host_placeholder')"
          class="mt-3"
          :has-error="!validation.smtpHost.isValid"
          @input="validateField('smtpHost')"
        />
        <div v-if="!validation.smtpHost.isValid" class="error">{{ validation.smtpHost.message }}</div>
        <t-input-text
          id="smtp-sender"
          v-model="computedRecord.emailChannel.meta.smtp_from"
          data-test="smtp-sender"
          label="SMTP Sender"
          placeholder="e.g. smtp@gmail.com"
          class="mt-3"
          :has-error="!validation.smtpSender.isValid"
          @input="validateField('smtpSender')"
        />
        <div v-if="!validation.smtpSender.isValid" class="error">{{ validation.smtpSender.message }}</div>
        <t-input-text
          id="smtp-username"
          v-model="computedRecord.emailChannel.meta.smtp_credentials.username"
          data-test="smtp-username"
          :label="$t('email_settings.smtp_username_label')"
          :placeholder="$t('email_settings.smtp_username_placeholder')"
          class="mt-3"
          :has-error="!validation.smtpUsername.isValid"
          @input="validateField('smtpUsername')"
        />
        <div v-if="!validation.smtpUsername.isValid" class="error">{{ validation.smtpUsername.message }}</div>
        <t-input-text
          id="smtp-password"
          v-model="computedRecord.emailChannel.meta.smtp_credentials.password"
          data-test="smtp-password"
          :label="$t('email_settings.smtp_password_label')"
          :placeholder="$t('email_settings.smtp_password_placeholder')"
          class="mt-3"
          :has-error="!validation.smtpPassword.isValid"
          @input="validateField('smtpPassword')"
        />
        <div v-if="!validation.smtpPassword.isValid" class="error">{{ validation.smtpPassword.message }}</div>
        <t-input-text
          id="smtp-port"
          v-model="computedRecord.emailChannel.meta.smtp_credentials.port"
          data-test="smtp-port"
          :label="$t('email_settings.smtp_port_label')"
          :placeholder="$t('email_settings.smtp_port_placeholder')"
          class="mt-3"
          :has-error="!validation.smtpPort.isValid"
          @input="validateField('smtpPort')"
        />
        <div v-if="!validation.smtpPort.isValid" class="error">{{ validation.smtpPort.message }}</div>
      </div>

      <div v-if="sendingThroughSmtp === false">
        <forwarding-email-edit-dns
          v-if="!showRecordTable"
          v-model="computedSenderEmail"
          :loading="loading"
          :domains="verifiedSenderDomains"
          :placeholder="computedReceiverValue"
          @refresh="loadSenderDomains"
          @add-dns="handleAddDomain"
          @click="handleItemSelected"
        />
        <forwarding-email-record-table
          v-else
          :id="computedSenderEmail && computedSenderEmail.id ? computedSenderEmail.id : 0"
          @show="showEditOrRecord"
        />

        <div v-if="!showRecordTable" class="mt-6">
          <atomic-button :disabled="!computedShowEditOrNot" @click="showRecordTable = true">
            {{ $t('email_settings.next_step') }}
          </atomic-button>
        </div>
      </div>

      <sender-domains ref="domainsManager" :sender-domains="senderDomains" @changed="loadSenderDomains" />
    </div>
  </div>
</template>

<script>
import { FEATURE_FLAG_CHANNELS } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';
import breakpoints from '@/util/breakpoints';
import { validateEmail } from '@/util/helpers';

import { addSenderDomain, getSenderDomains } from './api';
import ForwardingEmailEditDns from './components/ForwardingEmailEditDns';
import { ForwardingEmailRecordTable } from './components/ForwardingEmailRecordTable';
import SenderDomains from '../../../Email/SenderDomains';
import SettingItem from '../../Components/SettingItem';

export default {
  name: 'DomainSettings',
  emits: ['input', 'smtpTabOpened'],
  components: {
    ForwardingEmailEditDns,
    ForwardingEmailRecordTable,
    SettingItem,
    SenderDomains,
  },
  data() {
    return {
      loading: false,
      breakpoints,
      showEditDns: false,
      copiedText: this.$t('email_settings.copied_to_clipboard'),
      isCopied: false,
      showRecordTable: false,
      copyButtonInterval: '',
      receiverValue: '',
      senderDomains: [],
      isMounted: false,
      validation: {
        smtpHost: {
          isValid: true,
          message: this.$t('email_settings.field_cannot_be_empty'),
        },
        smtpSender: {
          isValid: true,
          message: this.$t('email_settings.invalid_email_address'),
        },
        smtpUsername: {
          isValid: true,
          message: this.$t('email_settings.field_cannot_be_empty'),
        },
        smtpPassword: {
          isValid: true,
          message: this.$t('email_settings.field_cannot_be_empty'),
        },
        smtpPort: {
          isValid: true,
          message: this.$t('email_settings.smtp_port_must_be_number'),
        },
      },
    };
  },
  props: {
    action: {
      type: String,
      default: '',
    },
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isExternalProvider: {
      type: Boolean,
    },
  },
  computed: {
    featureFlagSendingThroughSmtpEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_CHANNELS.TC_EMAIL_OUTBOUND_SMTP);
    },
    sendingThroughSmtp: {
      get() {
        return !!this.computedRecord.emailChannel.meta?.use_smtp;
      },
      set(val) {
        this.computedRecord.emailChannel.meta = {
          ...this.computedRecord.emailChannel.meta,
          smtp_credentials: {
            host: this.computedRecord.emailChannel.meta?.smtp_credentials?.host || '',
            username: this.computedRecord.emailChannel.meta?.smtp_credentials?.username || '',
            password: this.computedRecord.emailChannel.meta?.smtp_credentials?.password || '',
            port: this.computedRecord.emailChannel.meta?.smtp_credentials?.port || null,
            encryption: this.computedRecord.emailChannel.meta?.smtp_credentials?.encryption || 'tls',
          },
          use_smtp: val,
          smtp_from: this.computedRecord.emailChannel.meta?.smtp_from || '',
        };
      },
    },
    computedIsLgOrXl() {
      return this.isLgOrXl();
    },
    responsiveInputSize() {
      return this.isLgOrXl() ? 'lg' : 'md';
    },
    computedReceiverValue() {
      const { username } = this.computedRecord;
      return username + '@trengomail.com';
    },
    computedRecord: {
      get() {
        return this.record;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    showEmbedAttachments() {
      return this.computedRecord.emailChannel.type === 'TRENGO';
    },
    prependTicketNumberOption() {
      return this.computedRecord.emailChannel.type === 'TRENGO';
    },
    verifiedSenderDomains() {
      return this.senderDomains.filter((n) => n.domain.includes('@'));
    },
    computedShowEditOrNot: {
      get() {
        return this.showEditDns;
      },
      set(value) {
        this.showEditDns = value;
      },
    },
    computedSenderEmail: {
      get() {
        const filterDomain = this.senderDomains.filter(
          (domain) => domain.id === this.computedRecord.emailChannel.sender_email_id
        );
        return filterDomain.length ? filterDomain[0] : {};
      },
      set(value) {
        this.computedRecord.emailChannel.sender_email_id = value ? value : null;
        this.handleShowEditOrNot();
        this.$emit('input', this.computedRecord);
      },
    },
    isFormValid() {
      const smtpCredentials = this.computedRecord.emailChannel.meta.smtp_credentials;

      return (
        this.isFieldValid('smtpHost', smtpCredentials.host) &&
        this.isFieldValid('smtpSender', this.computedRecord.emailChannel.meta.smtp_from) &&
        this.isFieldValid('smtpUsername', smtpCredentials.username) &&
        this.isFieldValid('smtpPassword', smtpCredentials.password) &&
        this.isFieldValid('smtpPort', smtpCredentials.port) &&
        this.isNum(smtpCredentials.port)
      );
    },
  },
  beforeUnmount() {
    clearTimeout(this.copyButtonInterval);
  },
  mounted() {
    this.handleShowEditOrNot();
    this.loadSenderDomains();
  },
  watch: {
    computedSenderEmail: function () {
      this.handleShowEditOrNot();
    },
    sendingThroughSmtp: {
      handler(newValue) {
        if (newValue) {
          this.$emit('smtpTabOpened', true);
        } else {
          this.$emit('smtpTabOpened', false);
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleItemSelected(value) {
      this.computedSenderEmail = value;
      this.showEditOrRecord(true); //Do not show record table
      this.computedShowEditOrNot =
        !!Object.keys(this.computedSenderEmail) && Object.keys(this.computedSenderEmail).length > 0;
    },
    showEditOrRecord(value) {
      this.showRecordTable = !value;
    },
    handleShowEditOrNot() {
      // only has to be called when component is mounted
      const senderEmailExists = Object.keys(this.computedSenderEmail).length > 0;

      if (!this.isMounted && senderEmailExists) {
        // if sender email is set, show edit dns
        this.showEditOrRecord(false); //show record table
        this.isMounted = true;
      }
      this.computedShowEditOrNot =
        !!Object.keys(this.computedSenderEmail) && Object.keys(this.computedSenderEmail).length > 0;
    },
    handleAddDomain(domain) {
      const splitDomain = domain.split('@');
      const getDomain = splitDomain[1];
      const mailbox = splitDomain[0];
      this.loading = true;
      addSenderDomain({ domain: getDomain, mailbox })
        .then((res) => {
          const object = {
            id: res.data.id,
            domain: getDomain,
            mailbox,
          };
          this.verifiedSenderDomains.push(object);
          this.loadSenderDomains();
          this.handleItemSelected(object.id);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    isLgOrXl() {
      const { comparisons } = this.breakpoints;
      return comparisons.eq_lg || comparisons.gt_lg;
    },
    loadSenderDomains() {
      getSenderDomains().then((r) => {
        this.senderDomains = r.data.data;
      });
    },
    openDomainsModal() {
      $(this.$refs.domainsManager.$refs.modal).modal('show');

      $(this.$refs.domainsManager.$refs.newModal).on('hidden.bs.modal', () => {
        element.modal('show');
      });
    },
    addEmail(v) {
      this.computedRecord.emailChannel.meta.email_addresses.push({ email: v });
    },
    handleCopyClicked() {
      this.isCopied = true;
      this.copyReceivingAddress();
      this.copyButtonInterval = setTimeout(
        function () {
          this.isCopied = false;
        }.bind(this),
        2000
      );
    },
    copyReceivingAddress() {
      copyToClipboard(this.computedReceiverValue);
    },
    isNum(value) {
      return /^\d+$/.test(value);
    },
    isFieldValid(fieldName, value) {
      return this.validation[fieldName].isValid && value !== '';
    },
    validateField(fieldName) {
      const validators = {
        smtpHost: () => this.computedRecord.emailChannel.meta.smtp_credentials.host.trim() !== '',
        smtpSender: () => validateEmail(this.computedRecord.emailChannel.meta.smtp_from),
        smtpUsername: () => this.computedRecord.emailChannel.meta.smtp_credentials.username.trim() !== '',
        smtpPassword: () => this.computedRecord.emailChannel.meta.smtp_credentials.password.trim() !== '',
        smtpPort: () => this.isNum(this.computedRecord.emailChannel.meta.smtp_credentials.port),
      };

      if (validators[fieldName]) {
        this.validation[fieldName].isValid = validators[fieldName]();
      }
    },
    resetFieldValidation(field) {
      field.isValid = true;
    },
    handleFieldsValidation(action) {
      for (let key in this.validation) {
        if (action === 'validate') {
          this.validateField(key);
        } else {
          this.resetFieldValidation(this.validation[key]);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped src="./DomainSettings.scss" />
