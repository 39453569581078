<template>
  <div class="mb-8 mt-12 flex flex-row items-center">
    <div class="flew-row number inline-flex items-center justify-center font-bold">
      {{ number }}
    </div>
    <h4 class="mb-0 ml-3">{{ caption }}</h4>
  </div>
</template>

<script>
export default {
  name: 'CsatSurveyStepIndicator',

  props: {
    number: {
      type: Number,
      default: '1',
    },
    caption: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.number {
  background: var(--color-galaxy-800);
  border-radius: 24px;
  width: 32px;
  height: 32px;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-white);
}
h4 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}
</style>
