import { capitalize } from 'lodash';

import { CUSTOM_FIELD } from '@/Configs/Constants';

import type { Channel } from '@/types/common';

/**
 * get customField name
 *
 * @param {string} type
 * @returns {string}
 */
export const getCustomFieldName = (type: string) => {
  if (!Object.keys(CUSTOM_FIELD).includes(type)) return 'Unknown';

  return capitalize(type);
};

/**
 * gets ID's
 *
 * @param id
 * @returns {`/admin/custom_fields/${string}`}
 */
export const getCustomFieldUrl = (id: string | number) => {
  return `/admin/custom_fields/${id}`;
};

/**
 *
 * @param item
 * @returns {string}
 */
export const getChannelIcon = (item: any) => {
  if (item.channels?.length !== 1) return '';

  const channelType = item.channels[0].type;
  return `${Assets_path}img/channels_icons/${channelType.toLowerCase()}.svg`;
};

/**
 * get list of items when there is more than one channel
 *
 * @param {object} item
 * @returns {string}
 */
export const getChannelTooltip = (item: any) => {
  if (item.channels?.length > 1) {
    const channelsList = item.channels
      .map((channel: Channel) => `<div class="truncate w-200px pr-4">${channel.display_name}</div>`)
      .join('\n');
    return `<div class="text-left">${channelsList}</div>`;
  }

  return '';
};
