export const USER_NOTIFICATION_TYPE = {
  ASSIGNED_TICKET: 'App\\Notifications\\AssignedTicket',
  EMAIL_RECIPIENT_FAILED: 'App\\Notifications\\EmailRecipientFailed',
  NEW_TICKET_MESSAGE: 'App\\Notifications\\NewTicketMessage',
  RULE_NOTIFY_USERS: 'App\\Notifications\\RuleNotifyUsers',
  TICKET_MENTION: 'App\\Notifications\\TicketMention',
  TICKET_NOTE: 'App\\Notifications\\TicketNote',
  TICKET_REMINDER: 'App\\Notifications\\TicketReminder',
} as const;

export const SYSTEM_NOTIFICATION_TYPE = {
  BETA_ENROLLMENT_STATUS: 'beta_enrollment_status_changed',
  CONTACT_IMPORT_FAILED: 'App\\Containers\\ContactImport\\Notifications\\ContactImportFailed',
  CONTACT_IMPORT_FINISHED: 'App\\Containers\\ContactImport\\Notifications\\ContactImportFinished',
  MESSAGE_IMPORT_FAILED: 'App\\Notifications\\MessageImportFailed',
  MESSAGE_IMPORT_FINISHED: 'App\\Notifications\\MessageImportFinished',
  MESSAGE_IMPORT_STARTED: 'App\\Notifications\\MessageImportStarted',
  RETROACTIVE_BALANCE_FINISHED: 'App\\Containers\\WorkloadBalancing\\Notifications\\RetroactiveBalanceFinished',
  TWO_FACTOR_AUTH_IS_MANDATORY: 'mandatory_2fa_was_enabled',
  WABA_BANNED: 'App\\Notifications\\WabaBanned',
  WABA_REVIEW_COMPLETED: 'App\\Notifications\\WabaReviewCompleted',
  WABA_REVIEW_REMINDER: 'App\\Notifications\\WabaReviewReminder',
} as const;

export const NOTIFICATION_TYPE = { ...USER_NOTIFICATION_TYPE, ...SYSTEM_NOTIFICATION_TYPE } as const;
