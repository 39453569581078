<template>
  <span class="my-auto pl-1 ml-1 mt-2 flex flex-shrink-0 flex-row flex-nowrap lg:m-0">
    <check-linear v-if="status === WHATSAPP_DELIVERY_STATUS.SENT" class="ml-1 text-black" />
    <double-check-linear v-if="status === WHATSAPP_DELIVERY_STATUS.DELIVERED" size="1.25rem" />
    <double-check-linear v-if="status === WHATSAPP_DELIVERY_STATUS.READ" class="text-leaf-500" size="1.25rem" />
    <p v-if="status === 'FAILED'" class="m-0 ml-1 p-0">- {{ $tc('tickets.failed_to_deliver') }}</p>
  </span>
</template>

<script setup lang="ts">
import { DoubleCheckLinear, CheckLinear } from '@trengo/trengo-icons';

import { WHATSAPP_DELIVERY_STATUS } from '@/Configs/Constants/whatsapp';

defineProps<{
  status:
    | typeof WHATSAPP_DELIVERY_STATUS.DELIVERED
    | typeof WHATSAPP_DELIVERY_STATUS.SENT
    | typeof WHATSAPP_DELIVERY_STATUS.READ
    | typeof WHATSAPP_DELIVERY_STATUS.FAILED;
}>();
</script>
