<template>
  <t-table-list-row class="table-fixed">
    <t-table-list-cell>
      <users-list-content-loader class="bg-grey-400" max-width="95%" />
    </t-table-list-cell>
    <t-table-list-cell v-if="!isMobile">
      <users-list-content-loader class="bg-grey-400" max-width="60%" />
    </t-table-list-cell>
    <t-table-list-cell v-if="!isMobile">
      <users-list-content-loader class="bg-grey-400" max-width="60%" />
    </t-table-list-cell>
    <t-table-list-cell v-if="!isMobile">
      <users-list-content-loader class="bg-grey-400" max-width="80%" />
    </t-table-list-cell>
  </t-table-list-row>
</template>

<script>
import UsersListContentLoader from '../UsersListContentLoader';

export default {
  name: 'UsersListLoader',
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  components: { UsersListContentLoader },
};
</script>
