<script>
export default {
  props: {
    rating: null,
    assetsUrl: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div class="flex items-center justify-between">
    <div
      class="indicator"
      :class="{
        very_dissatisfied: rating === 1 || rating === 0,
        dissatisfied: rating === 2,
        neutral: rating === 3,
        satisfied: rating === 4,
        very_satisfied: rating === 5,
      }"
    ></div>
    <span>{{ rating }}/5</span>
    <img v-if="rating === 1 || rating === 0" :src="`${assetsUrl}img/csat/very_dissatisfied_emoji.svg`" />
    <img v-if="rating === 2" :src="`${assetsUrl}img/csat/dissatisfied_emoji.svg`" />
    <img v-if="rating === 3" :src="`${assetsUrl}img/csat/neutral_emoji.svg`" />
    <img v-if="rating === 4" :src="`${assetsUrl}img/csat/satisfied_emoji.svg`" />
    <img v-if="rating === 5" :src="`${assetsUrl}img/csat/very_satisfied_emoji.svg`" />
  </div>
</template>
<style scoped lang="scss">
img {
  width: 16px;
}
.indicator {
  height: 56px;
  width: 4px;
  &.very_dissatisfied {
    background-color: var(--color-error-500);
  }
  &.dissatisfied {
    background-color: var(--color-error-500);
  }
  &.neutral {
    background-color: var(--color-sun-500);
  }
  &.satisfied {
    background-color: var(--color-success-500);
  }
  &.very_satisfied {
    background-color: var(--color-success-500);
  }
}
</style>
