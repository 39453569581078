import { ENDPOINT } from '@/api/constants';
import { request } from '@/util';

import type { FormField, InstallationStatus } from '@/store/types/integrations';
import type { PaginatedApiResponse } from '@/types';
import type { AxiosPromise } from 'axios';

export * from '@/api/modules/integrations/crm';
export * from '@/api/modules/integrations/commerce';

type IntegrationsResponse = {
  id: number;
  name: string;
  type: string;
  auth_type: 'oauth2' | 'basic' | 'api_key' | 'custom' | 'none';
  categories: string[];
  service_id: string;
  has_installations: boolean;
  is_premium: boolean;
  oauth_grant_type: 'authorization_code' | 'client_credentials' | 'password' | null;
};

type IntegrationInstallation = {
  id: number;
  installed_at: string;
  name: string;
  status: InstallationStatus;
};

export type IntegrationResponse = IntegrationsResponse & {
  form_fields: Array<FormField>;
  installations: Array<IntegrationInstallation>;
};

export type InstallIntegrationPayload = {
  settings: Array<unknown>;
  name: string;
};

export type UpdateIntegrationPayload = {
  settings: Array<unknown>;
};

type InstallResponse = {
  id: number;
};

type AuthorizationResponse = {
  authorize_url: string;
  revoke_url: string;
};

export type InstallationsResponse = {
  readonly id: number;
  name: string;
  integration_service_id: string;
  deeplink: string;
};

export function fetchIntegrations(): AxiosPromise<PaginatedApiResponse<Array<IntegrationsResponse>>> {
  return request(`${ENDPOINT.INTEGRATIONS}/admin/integrations`, 'GET');
}

export function fetchIntegration(id: number): AxiosPromise<IntegrationResponse> {
  return request(`${ENDPOINT.INTEGRATIONS}/admin/integrations/${id}`, 'GET');
}

export function installIntegration(id: number, payload: InstallIntegrationPayload): AxiosPromise<InstallResponse> {
  return request(`${ENDPOINT.INTEGRATIONS}/admin/integrations/${id}/installations`, 'POST', payload);
}

export function fetchAuthorization(
  integration_id: number,
  installation_id: number
): AxiosPromise<AuthorizationResponse> {
  return request(
    `${ENDPOINT.INTEGRATIONS}/admin/integrations/${integration_id}/installations/${installation_id}/authorization_details`,
    'GET'
  );
}

export function fetchInstallations(page: number): AxiosPromise<PaginatedApiResponse<Array<InstallationsResponse>>> {
  return request(`${ENDPOINT.INTEGRATIONS}/installations/callable?page=${page}`, 'GET');
}

export function deleteInstallation(integration_id: number, installation_id: number): AxiosPromise {
  return request(
    `${ENDPOINT.INTEGRATIONS}/admin/integrations/${integration_id}/installations/${installation_id}`,
    'DELETE'
  );
}

export type InstallationResponse = {
  readonly id: number;
  installed_at: string;
  name: string;
  status: string;
  settings: Record<string, unknown>;
};

export function fetchInstallation(integration_id: number, installation_id: number): AxiosPromise<InstallationResponse> {
  return request(
    `${ENDPOINT.INTEGRATIONS}/admin/integrations/${integration_id}/installations/${installation_id}`,
    'GET'
  );
}

export function updateInstallation(
  integration_id: number,
  installation_id: number,
  payload: UpdateIntegrationPayload
): AxiosPromise<InstallationResponse> {
  return request(
    `${ENDPOINT.INTEGRATIONS}/admin/integrations/${integration_id}/installations/${installation_id}`,
    'PATCH',
    payload
  );
}
