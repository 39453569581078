<template>
  <t-modal v-model="isModalOpen" :close-icon="true" variant="narrow" is-closable @close="emit('close')">
    <template #header>
      <h3 class="t-text-h3 my-auto max-w-[90%] p-0">
        {{ t('general.share_your_thoughts') }}
      </h3>
    </template>

    <div class="flex flex-col gap-6">
      <p class="m-0 font-medium text-grey-700">
        {{ t('tickets.feedback_modal__body') }}
      </p>
      <div v-if="!data.questions.length">
        <p class="t-text-md-emphasize mb-4">loading...</p>
        <div class="flex flex-col gap-6">
          <t-input-checkbox class="text-md font-medium text-grey-700">loading...</t-input-checkbox>
        </div>
      </div>
      <div v-for="question in data.questions" v-else :key="question.id">
        <p class="t-text-md-emphasize mb-4">{{ question.text }}</p>
        <div class="flex flex-col gap-6">
          <t-input-checkbox
            v-for="(optionText, optionId) in question.options"
            :key="optionId"
            v-model="activeOptions"
            class="text-md font-medium text-grey-700"
            :value="Number(optionId)"
          >
            {{ optionText }}
          </t-input-checkbox>
        </div>
      </div>

      <div>
        <p class="t-text-md-emphasize mb-2">
          {{ t('tickets.feedback_modal__feedback_text') }}
        </p>
        <textarea
          v-model="activeFeedbackText"
          type="text"
          class="form-control"
          rows="2"
          :placeholder="t('general.optional')"
        />
      </div>
    </div>

    <template #footer>
      <t-button btn-style="secondary" @click="emit('close')">
        {{ $t('general.cancel') }}
      </t-button>

      <t-button class="flex flex-row items-center justify-center" @click="onSubmit">
        <p class="m-0 p-0">{{ $t('general.send') }}</p>
      </t-button>
    </template>
  </t-modal>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { API_INBOX_AI } from '../api/const';

import type { FeedbackPayload, FeedbackResponse } from './types';

const { t, locale } = useI18n();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'send', data: FeedbackPayload): void;
}>();

const props = defineProps<{
  id: number;
}>();

const isModalOpen = defineModel<boolean>();

const data = ref<FeedbackResponse>({ questions: [] });
const activeOptions = ref<number[]>([]);
const activeFeedbackText = ref('');

onBeforeMount(async () => {
  try {
    const res = await axios.get<FeedbackResponse>(`${API_INBOX_AI}/feedback_questions_options?locale=${locale.value}`);
    data.value = res.data;
  } catch {
    throw new Error('Failed to fetch feedback questions');
  }
});

const onSubmit = () => {
  if (!activeOptions.value.length && !activeFeedbackText.value) {
    emit('close');
  } else {
    emit('send', {
      id: props.id,
      options: activeOptions.value,
      feedback: activeFeedbackText.value,
    });
  }
};
</script>
