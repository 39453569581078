import { ENDPOINT } from '@/api/constants';
import { request } from '@/util';

import type { ServiceNotification } from './types';
import type { ChannelType } from '@/types';

export const fetchChannels = async () => {
  const { data } = await request<ChannelType[]>(ENDPOINT.SERVICE_NOTIFICATIONS_CHANNELS, 'GET');
  return data;
};

export const fetchNotifications = async (channels: ChannelType[]) => {
  const queryString = channels.map((item) => 'channels[]=' + encodeURIComponent(item)).join('&');
  const { data } = await request<ServiceNotification[]>(`${ENDPOINT.SERVICE_NOTIFICATIONS}?${queryString}`, 'GET');
  return Array.isArray(data) ? data : [];
};

export const putDismissNotification = async (id: ServiceNotification['id']) => {
  await request<void>(`${ENDPOINT.SERVICE_NOTIFICATIONS}/${id}/dismiss`, 'PUT');
};
