<template>
  <div class="white bg b-b hidden-lg-up">
    <div v-if="mobileMenuBarPortalHasContent" class="h-14 flex w-full items-center p-4">
      <portal-target name="mobile-menu-bar" class="w-full" />
    </div>
    <div v-else class="navbar no-radius box-shadow-z1">
      <a
        v-if="!isFeatureFlagEnabled"
        data-toggle="modal"
        data-target="#settings-primary"
        data-ui-modal=""
        class="navbar-item pull-left"
      >
        <span class="btn btn-sm btn-icon white">
          <i class="fa fa-list"></i>
        </span>
      </a>
      <ul class="nav navbar-nav">
        <li class="nav-item">
          <span class="navbar-item text-md">{{ title }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { provideWormhole } from 'portal-vue';

import { FEATURE_FLAG_SETTINGS } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    const mobileMenuBarPortalHasContent = provideWormhole('mobile-menu-bar');
    const featureFlagStore = useFeatureFlagStore();
    const isFeatureFlagEnabled = () => featureFlagStore.isEnabled(FEATURE_FLAG_SETTINGS.TA_SETTINGS_PAGE);

    return {
      mobileMenuBarPortalHasContent,
      isFeatureFlagEnabled,
    };
  },
};
</script>
