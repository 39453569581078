<script setup lang="ts">
import { ArrowCornerCcwLTLinear, TranslationCharacter } from '@trengo/trengo-icons';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import TranslationLoader from '@/components/ReplyForm/TranslationOption/TranslationLoader.vue';

import type { FetchStatus } from '@/store/pinia/translation/types';

const { t } = useI18n();

const emit = defineEmits<{
  (e: 'translate', isReverting?: boolean): void;
}>();

const props = defineProps<{
  status: FetchStatus;
  canRevert?: boolean;
}>();

const fill = ref('#70767B');
const toggleFill = (isMouseOver: boolean) => {
  fill.value = isMouseOver ? '#000' : '#70767B';
};

const text = computed(() => {
  if (props?.canRevert) {
    return t('tickets.revert_to_original');
  }

  switch (props.status) {
    case 'INITIAL':
      return t('tickets.translate');
    case 'LOADING':
      return t('tickets.translating');
  }
});
</script>

<template>
  <button
    type="button"
    class="flex max-h-max items-center gap-1 text-grey-600 hover:text-black"
    @mouseover="toggleFill(true)"
    @mouseleave="toggleFill(false)"
    @click.stop="emit('translate', canRevert)"
  >
    <ArrowCornerCcwLTLinear v-if="canRevert" size="16px" :fill="fill" />
    <translation-character v-else-if="status === 'INITIAL' && !canRevert" size="16px" :fill="fill" />
    <translation-loader v-else-if="status === 'LOADING'" />
    <span class="text-sm">{{ text }}</span>
  </button>
</template>
