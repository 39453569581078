<template>
  <div v-if="loaded" class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis" v-text="record.name"></span>
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <div class="p-a p-b-0 container">
            <div class="p-a">
              <form @submit.prevent="save">
                <div class="box">
                  <div class="box-header">
                    <h2>{{ $t('integrations.title_app') }}</h2>
                  </div>
                  <div class="box-divider m-a-0"></div>
                  <div class="box-body">
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('integrations.label_name') }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-10">
                        <input v-model="record.name" type="text" autocomplete="off" class="form-control" />
                        <span class="form-text">{{ $t('integrations.subtitle_name') }}</span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('integrations.label_app_type') }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-10">
                        <select v-model="record.type" class="form-control">
                          <option value="custom">{{ $t('integrations.option_custom_app') }}</option>
                          <optgroup :label="$t('integrations.option_group_ecommerce')">
                            <option value="shopify2">{{ $t('integrations.option_shopify') }}</option>
                            <option value="lightspeed">{{ $t('integrations.option_lightspeed') }}</option>
                            <option value="whoocommerce">{{ $t('integrations.option_woocommerce') }}</option>
                            <option value="magento1">{{ $t('integrations.option_magento_1') }}</option>
                            <option value="magento">{{ $t('integrations.option_magento_2') }}</option>
                            <option value="picqer">{{ $t('integrations.option_picquer') }}</option>
                            <option value="bol">{{ $t('integrations.option_bol') }}</option>
                            <option v-if="action === 'edit'" value="shopify">
                              {{ $t('integrations.option_old_shopify') }}
                            </option>
                          </optgroup>
                          <optgroup :label="$t('integrations.option_group_crm')">
                            <option value="hubspot">{{ $t('integrations.option_hubspot') }}</option>
                            <option value="pipedrive">{{ $t('integrations.option_pipedrive') }}</option>
                          </optgroup>
                          <optgroup :label="$t('integrations.option_group_productivity')">
                            <option value="slack">{{ $t('integrations.option_slack') }}</option>
                          </optgroup>
                        </select>
                        <span class="form-text">{{ $t('integrations.subtitle_app_type') }}</span>
                      </div>
                    </div>
                    <component
                      :is="record.type"
                      v-model="record"
                      :action="action"
                      @update-saving="updateSaving"
                    ></component>
                  </div>
                </div>
                <div class="box">
                  <div class="box-body flow-root">
                    <button
                      type="submit"
                      :class="{ loader: saving }"
                      class="btn success"
                      :disabled="saving || !ready"
                      v-text="saving ? $t('integrations.button_saving') : $t('integrations.button_save_changes')"
                    ></button>

                    <button v-if="action === 'edit'" type="button" class="btn white pull-right" @click="deletePlugin()">
                      {{ $t('integrations.uninstall_plugin') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useFeatureFlagStore } from '@/store/pinia';

import Bol from './Integrations/Bol';
import Custom from './Integrations/Custom';
import Exact from './Integrations/Exact';
import Hubspot from './Integrations/Hubspot';
import Lightspeed from './Integrations/Lightspeed';
import Magento from './Integrations/Magento';
import Magento1 from './Integrations/Magento1';
import Picqer from './Integrations/Picqer';
import Pipedrive from './Integrations/Pipedrive';
import Shopify from './Integrations/Shopify';
import Shopify2 from './Integrations/Shopify2';
import Whoocommerce from './Integrations/WhooCommerce';
import RouterWarningOnEdit from '../../../mixins/RouterWarningOnEdit';

export default {
  name: 'EditPlugin',
  emits: ['call-loadlist-method'],
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },

  mixins: [RouterWarningOnEdit],

  components: {
    Bol,
    Custom,
    Exact,
    Hubspot,
    Lightspeed,
    Magento,
    Magento1,
    Picqer,
    Pipedrive,
    Shopify,
    Shopify2,
    Whoocommerce,
  },

  computed: {
    ready() {
      return true;
    },

    redirectRoute() {
      if (useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.INTEGRATION_HUB)) {
        return '/admin/integrations/plugins/';
      }

      return '/admin/developers/plugins/';
    },
  },

  data() {
    return {
      loaded: false,
      record: {
        name: '',
        type: 'custom',
        meta: {},
      },
      saving: false,
    };
  },

  mounted() {
    if (this.action === 'edit') {
      axios.get('/api/v2/plugins/' + this.$route.params.id).then((result) => {
        this.record = result.data;
        this.initWarning();
      });
    } else {
      this.initWarning();
    }

    // set type from param
    this.$data.record.type = this.$route.params.type;
    setTimeout(() => this.initWarning(), 100);
  },

  methods: {
    save() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      if (this.action === 'create') {
        return this.storePlugin();
      } else {
        this.updatePlugin();
      }
    },

    storePlugin() {
      axios
        .post('/api/v2/plugins', this.record)
        .then((res) => {
          this.saving = false;
          this.initWarning();
          this.$emit('call-loadlist-method');
          this.$nextTick(() => {
            this.$router.push(`${this.redirectRoute}${res.data.id}`);
          });

          this.flashSuccess(this.$t('integrations.alert_plugin_create_success'));
          eventBus.$emit('plugin.saved', {
            id: res.data.id,
          });
        })
        .catch(() => {
          this.saving = false;
        });
    },

    updatePlugin() {
      axios
        .put('/api/v2/plugins/' + this.record.id, this.record)
        .then(() => {
          this.saving = false;
          this.initWarning();
          this.$emit('call-loadlist-method');
          this.flashSuccess(this.$t('integrations.alert_plugin_save_success'));
          eventBus.$emit('plugin.saved', {
            id: this.record.id,
          });
        })
        .catch(() => {
          this.saving = false;
        });
    },

    async deletePlugin() {
      const shouldDeletePlugin = await this.$tConfirm(this.$t('integrations.confirmation_delete_plugin_message'), {
        delete: true,
        title: this.$t('general.are_you_sure'),
      });
      if (shouldDeletePlugin) {
        this.initWarning();

        axios.delete('/api/v2/plugins/' + this.record.id).then(() => {
          this.$emit('call-loadlist-method');
          this.$router.push(this.redirectRoute);
          this.flashSuccess(this.$t('integrations.plugin_delete_success'));
        });
      }
    },
    updateSaving(value) {
      this.saving = value;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.onBeforeRouteLeaveGuard(to, from, next);
  },
  beforeRouteUpdate(to, from, next) {
    this.onBeforeRouteUpdateGuard(to, from, next);
  },
};
</script>
