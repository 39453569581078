import { computed } from 'vue';

import { useSubscriptionStore } from '@/store/pinia';

import { CONVERSATION_DETAILS } from '../../../constants';

export const useConversationBlocks = () => {
  const subscriptionStore = useSubscriptionStore();

  const billingFrequency = computed(() => subscriptionStore.subscription.subscriptionFrequency);
  const plan = computed(() => subscriptionStore.subscription.tierId);
  const unitPrice = computed(() =>
    billingFrequency.value ? CONVERSATION_DETAILS.prices[billingFrequency.value] * 100 : 0,
  );

  const includedConversations = computed(() => {
    if (!plan.value || !billingFrequency.value) {
      return 0;
    }

    const conversations = CONVERSATION_DETAILS.includedConversations?.[plan.value] ?? 0;

    return billingFrequency.value === 'monthly' ? conversations : conversations * 12;
  });

  return { billingFrequency, includedConversations, unitPrice };
};
