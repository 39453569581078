<template>
  <t-alert
    v-model="computedValue"
    :title="$t('user_management.delete_this_user')"
    type="danger"
    confirm-text="Delete"
    show-close
    :delete-icon="false"
    class="t-text-sm sm:t-text-desktop-paragraph-md text-grey-800"
    @cancel="onCancel"
    @confirm="onConfirm"
  >
    <div class="flex flex-col">
      <span
        class="t-text-desktop-paragraph-md text-grey-650"
        v-html="$t('user_management.warning_delete_user', { name: computedNameValue })"
      ></span>
      <t-inline-banner class="mt-4">
        <template #icon>
          <info-linear />
        </template>
        <div>{{ $t('user_management.invite_user_update_subscription_charge_warning') }}</div>
      </t-inline-banner>
    </div>
  </t-alert>
</template>

<script>
import { InfoLinear } from '@trengo/trengo-icons';
import dompurify from 'dompurify';

export default {
  name: 'DeleteUserModal',
  emits: ['update:modelValue', 'cancel', 'confirm'],
  components: { InfoLinear },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedNameValue() {
      return dompurify.sanitize(this.name);
    },
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
      this.computedValue = false;
    },
    onConfirm() {
      this.$emit('confirm');
      this.computedValue = false;
    },
  },
};
</script>
