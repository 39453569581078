<template>
  <li
    class="
      mb-1
      flex
      w-full
      flex-row
      items-center
      rounded-md
      transition-colors
      last:mb-0
      hover:bg-grey-200
      active:bg-grey-300
    "
  >
    <label
      :for="item.name"
      class="t-text-sm m-0 w-full cursor-pointer select-none text-ellipsis px-3 py-2 text-grey-800"
      @click.prevent="item.visible = !item.visible"
    >
      {{ item.label || item.name }}
    </label>

    <t-switch :id="item.name" v-model="item.visible" class="ml-auto pr-2" size="md" @click.stop />
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import type { SidebarItem } from '@/components/TicketSidebar/Index.vue';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'SidebarItemToggle',
  emits: ['item-toggled'],
  props: {
    item: {
      type: Object as PropType<SidebarItem>,
      default: () => ({}),
    },
  },

  watch: {
    'item.visible': function (newValue) {
      this.$emit('item-toggled', this.item.name, newValue);
    },
  },
});
</script>
