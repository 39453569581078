<template>
  <div class="flex flex-col border-b border-grey-300" data-test="sidebar-dropdown-wrapper">
    <div
      class="flex cursor-pointer flex-row items-center p-3 hover:bg-grey-100"
      data-test="sidebar-dropdown-button"
      @click="toggleCard"
    >
      <div v-if="hasIcon || $slots.icon" class="mr-3">
        <slot name="icon">
          <t-thumbnail size="tiny">
            <sliders-vert2-linear />
          </t-thumbnail>
        </slot>
      </div>

      <h1
        class="t-text-sm-emphasize m-0 select-none text-ellipsis p-0 text-grey-800"
        data-test="sidebar-dropdown-title"
      >
        {{ title }}
      </h1>

      <div class="ml-auto flex flex-row">
        <div v-if="externalLink.length > 0" class="open-external mr-1.5 self-center" @click="openExternalLink()">
          <external-link-linear size="1.1rem" />
        </div>

        <chevron-down-linear v-if="!isCardOpen" size="1.5rem" data-test="sidebar-dropdown-icon-closed" />
        <chevron-up-linear v-else size="1.5rem" data-test="sidebar-dropdown-icon-open" />
      </div>
    </div>

    <div
      v-if="isCardOpen"
      :class="[
        'flex flex-col px-3 pb-3',
        { 'mt-2': !isIntegrationDropdown, 'border-t border-grey-300 bg-grey-100 pt-2': isIntegrationDropdown },
      ]"
      data-test="sidebar-dropdown-content-wrapper"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { SlidersVert2Linear, ChevronDownLinear, ChevronUpLinear, ExternalLinkLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SidebarDropdown',
  emits: ['toggled', 'mounted'],
  components: {
    ChevronDownLinear,
    ChevronUpLinear,
    SlidersVert2Linear,
    ExternalLinkLinear,
  },

  props: {
    cardId: {
      type: Number,
      default: 0,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    isExternal: {
      type: Boolean,
      default: false,
    },
    externalLink: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Card Title',
    },
    collapseKey: {
      type: String,
      default: '',
    },
    isIntegrationDropdown: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isCardOpen: false,
    };
  },

  methods: {
    toggleCard() {
      this.isCardOpen = !this.isCardOpen;

      if (this.collapseKey) {
        this.isCardOpen
          ? localStorage.setItem(this.collapseKey, 'open')
          : localStorage.setItem(this.collapseKey, 'closed');
      }

      this.$emit('toggled', this.isCardOpen, this.cardId || undefined);
    },
    openExternalLink() {
      window.open(this.externalLink, '_blank', 'noreferrer,noopener');
    },
  },

  mounted() {
    if (!this.collapseKey) {
      this.$emit('mounted', this.isCardOpen);
      return;
    }

    this.isCardOpen = localStorage.getItem(this.collapseKey) === 'open';
    this.$emit('mounted', this.isCardOpen);
  },
});
</script>
