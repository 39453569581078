<template>
  <div class="mobile-email-list hidden-lg-up">
    <portal to="mobile-menu-bar">
      <div class="flex h-6 w-full items-center justify-between"></div>
    </portal>
    <h2 class="my-6 px-4 text-2xl font-bold leading-6">Email channels</h2>
    <div class="list bg-white">
      <setting-sidebar-item
        v-for="item in channels"
        :id="item.id"
        :key="item.id"
        :desc="item.text.replace(item.title + ' • ', '')"
        :status-title="getStatusIndicator(item) && getStatusIndicator(item).title"
        :status-type="getStatusIndicator(item) && getStatusIndicator(item).type"
        :title="item.title"
        :email-type="item.emailChannel ? item.emailChannel.type : ''"
        :is-private="item.is_private"
        :channel-type="channelType"
      />
    </div>
    <atomic-button size="md" btn-style="secondary my-4 mx-4" @click="onCreate">
      <i class="fal fa-plus mr-2" />
      Add channel
    </atomic-button>
  </div>
</template>

<script>
import SettingSidebarItem from '@/components/Channels/components/SettingSidebar/SettingSidebarItem/SettingSidebarItem';
import { getExtraSettings, getStatusIndicator } from '@/components/Channels/components/SettingSidebar/utils';
import eventBus from '@/eventBus';
export default {
  components: { SettingSidebarItem },
  emits: ['modal-email-create'],
  props: {
    channels: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    channelType() {
      return this.$route.params.channel;
    },
  },
  methods: {
    getStatusIndicator,
    getExtraSettings,
    onCreate() {
      eventBus.$emit('modal-email-create');
    },
  },
};
</script>

<style scoped lang="scss" src="./MobileEmailList.scss" />
