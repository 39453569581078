<script type="text/babel">
import _ from 'lodash';

import eventBus from '@/eventBus';

export default {
  name: 'TextareaSmsCounter',
  emits: ['update:modelValue'],
  data: function () {
    return {
      channel_id: null,
      msg: '',
      chars: 0,
      sms_count: 0,
    };
  },

  props: {
    modelValue: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
  },

  watch: {
    modelValue: function (value) {
      this.msg = value;
    },

    to: _.debounce(function (newVal) {
      this.getCosts();
    }, 300),
  },

  mounted: function () {
    eventBus.$on('sms.reset-form', () => {
      this.msg = '';
      this.chars = 0;
      this.sms_count = 0;
    });
  },

  methods: {
    getCosts: _.debounce(function (newVal) {
      this.chars = this.msg.length;
      axios.post('/api/v2/sms_pricing', { message: this.msg, to: this.to }).then((res) => {
        this.sms_count = res.data.credits;
      });
    }, 300),

    handleInput() {
      this.$emit('update:modelValue', this.msg);
    },
  },

  unmounted: function () {
    eventBus.$off('sms.reset-form');
  },
};
</script>

<template>
  <div>
    <textarea
      v-model="msg"
      required="required"
      class="form-control"
      rows="4"
      @keyup="getCosts"
      @paste="getCosts"
      @change="getCosts"
      @input="handleInput"
    ></textarea>
    <div class="pull-right text-muted" style="margin-top: 5px">
      {{ chars }} {{ $t('sms.chars') }} (&euro; {{ sms_count }})
    </div>
  </div>
</template>
