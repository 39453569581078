<template>
  <div class="p-6">
    <div class="flex flex-row gap-3">
      <div
        v-for="plan in PLANS_USAGE_BASED"
        :key="plan.type"
        class="flex flex-col rounded-xl border-1 border-grey-300 bg-white"
      >
        <div class="flex h-max flex-col gap-2 p-4">
          <div class="t-text-sm-emphasize flex justify-between gap-1 text-grey-800">
            <span>{{ t(plan.title) }}</span>
            <template v-if="plan.type === currentPlanId">
              &middot;
              <span v-if="plan.type === currentPlanId" class="text-leaf-500">
                {{ t('edit_subscription.active_plan') }}
              </span>
            </template>
            <div class="t-text-sm flex flex-grow items-center justify-end font-semibold text-grey-600">
              5
              <user-fill class="ml-1" size="16px" />
            </div>
          </div>
          <span v-if="currentFrequency === 'monthly'" class="text-2xl font-bold text-grey-800">
            €{{ getPlanPrice(plan.prices, 'monthly') }}
          </span>
          <div v-else>
            <span class="text-2xl font-bold text-grey-800">€{{ getPlanPrice(plan.prices, 'annually') }}</span>
            <span class="ml-1 text-sm font-semibold text-grey-500 line-through">
              €{{ getPlanPrice(plan.prices, 'monthly') }}
            </span>
          </div>
          <div class="t-text-sm text-grey-700">
            {{ plan.description && t(plan.description) }}
          </div>

          <div>
            <t-badge
              class="w-fit"
              :text="
                t('edit_subscription.included_conversation_blocks_per_month', {
                  quantity: getIncludedConversations(plan.type),
                })
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { UserFill } from '@trengo/trengo-icons';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { PLANS_USAGE_BASED } from '@/api/mocks/chargebee';
import { useSubscriptionStore } from '@/store/pinia';

import { CONVERSATION_DETAILS } from '../../../constants';

import type { BillingFrequency, ChargebeePlanPrice, PlanId } from '@/types';

const { t } = useI18n();

const currentFrequency = computed(() => useSubscriptionStore().subscription.subscriptionFrequency);
const currentPlanId = computed(() => useSubscriptionStore().subscription.tierId);

const getIncludedConversations = (planId: PlanId) =>
  currentPlanId.value && CONVERSATION_DETAILS.includedConversations?.[planId];

const getPlanPrice = (prices: ChargebeePlanPrice[], frequency: BillingFrequency) => {
  return prices.find((price) => price.type === frequency)?.price ?? 0;
};
</script>
