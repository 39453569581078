import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row" }
const _hoisted_2 = { class: "max-w-[7rem] truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_badge = _resolveComponent("t-badge")!
  const _component_t_tooltip = _resolveComponent("t-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTwoItems, (label) => {
      return (_openBlock(), _createBlock(_component_t_tooltip, {
        key: label.id,
        text: label.name
      }, {
        default: _withCtx(() => [
          _createVNode(_component_t_badge, {
            style: {"max-width":"130px"},
            class: "mr-2 truncate text-ellipsis"
          }, {
            icon: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, _toDisplayString(label.name), 1 /* TEXT */)
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["text"]))
    }), 128 /* KEYED_FRAGMENT */)),
    (_ctx.getItemsAfterIndexTwo.length)
      ? (_openBlock(), _createBlock(_component_t_tooltip, {
          key: 0,
          text: _ctx.getMoreThanTwo,
          position: "right"
        }, {
          default: _withCtx(() => [
            (_ctx.hasMoreThanTwoItems)
              ? (_openBlock(), _createBlock(_component_t_badge, {
                  key: 0,
                  class: "cursor-default",
                  text: `+${_ctx.getItemsAfterIndexTwo.length}`
                }, null, 8 /* PROPS */, ["text"]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["text"]))
      : _createCommentVNode("v-if", true)
  ]))
}