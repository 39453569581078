<template>
  <header class="hidden justify-between md:flex">
    <h2 class="text-2xl font-bold">{{ $t('email_settings.create_email_channel') }}</h2>

    <div class="close-btn z-50 cursor-pointer" role="button" @click="$emit('close')">
      <i class="material-icons text-3xl">close</i>
    </div>
  </header>
</template>

<script>
export default {
  name: 'CreateEmailHeader',
  emits: ['close'],
};
</script>
