import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_input_checkbox = _resolveComponent("t-input-checkbox")!
  const _component_sidebar_dropdown = _resolveComponent("sidebar-dropdown")!

  return (_openBlock(), _createBlock(_component_sidebar_dropdown, {
    title: _ctx.$tc('ticket_sidebar.contact_groups'),
    "collapse-key": "contactGroupDropdown",
    "data-test": "contact-group-dropdown"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group) => {
          return (_openBlock(), _createBlock(_component_t_input_checkbox, {
            key: group.id,
            modelValue: group.selected,
            "onUpdate:modelValue": ($event: any) => ((group.selected) = $event),
            class: "mb-2 last:mb-0",
            "data-test": "contact-group-checkbox",
            onChange: ($event: any) => (_ctx.save(group.id, $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(group.name), 1 /* TEXT */)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "onChange"]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]))
}