<template>
  <div
    data-test="referral_button"
    class="mx-6 mb-3 mt-1 cursor-pointer rounded-lg text-white"
    style="background: #00029a"
  >
    <div class="justify-content-center relative flex min-h-56px w-full items-center px-4 py-2 sm:py-1">
      <div class="bubble-item-top" />
      <div class="bubble-item-bottom" />

      <a
        class="open-external justify-content-center flex items-center"
        href="https://trengo.com/referrals"
        target="_blank"
      >
        <div class="absolute h-24px w-24px">
          <gift-linear color="#1CA7FD" class="h-full w-full" />
        </div>
        <div class="ml-6 mr-3 pl-2 sm:ml-4 sm:pl-4">
          <span class="sm:t-text-desktop-paragraph-sm t-text-mobile-body-xs hover:underline">
            {{ $t('general.referral_bol_text') }}
          </span>
        </div>
      </a>

      <div class="absolute right-3 top-4" @click.stop="discardReferralButton">
        <svg
          class="close-icon"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.7729 2.28765C11.0658 1.99476 11.0658 1.51989 10.7729 1.22699C10.48 0.934101 10.0052 0.934101 9.71228 1.22699L5.99997 4.9393L2.28765 1.22699C1.99476 0.934101 1.51989 0.934101 1.22699 1.22699C0.934101 1.51989 0.934101 1.99476 1.22699 2.28765L4.93931 5.99997L1.22699 9.71228C0.934101 10.0052 0.934101 10.48 1.22699 10.7729C1.51989 11.0658 1.99476 11.0658 2.28765 10.7729L5.99997 7.06063L9.71228 10.7729C10.0052 11.0658 10.48 11.0658 10.7729 10.7729C11.0658 10.48 11.0658 10.0052 10.7729 9.71228L7.06063 5.99997L10.7729 2.28765Z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { GiftLinear } from '@trengo/trengo-icons';

import eventBus from '@/eventBus';
export default {
  name: 'ReferralBol',
  components: { GiftLinear },
  methods: {
    discardReferralButton() {
      eventBus.$emit('referral_button_closed');
      this.$store.dispatch('userConfig/put', { key: 'referral_button_bol_pressed', value: true });
    },
  },
};
</script>

<style src="./ReferralBol.scss" scoped lang="scss" />
