<template>
  <div>
    <div v-if="action === 'create' || change" class="box">
      <div class="box-header">
        <h2>Zipwhip</h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">
            Phone number:
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-10">
            <input v-model="record.phone" type="text" class="form-control" />
            <span class="form-text">Zipwhip phone number.</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">
            Username:
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-10">
            <input v-model="record.username" type="text" class="form-control" />
            <span class="form-text">Zipwhip username.</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">
            Password:
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-10">
            <input v-model="record.password" type="text" class="form-control" />
            <span class="form-text">Zipwhip password</span>
          </div>
        </div>

        <div v-if="action !== 'create' && change" class="form-group row">
          <label class="col-sm-2 form-control-label"></label>
          <div class="col-sm-10">
            <a @click="chancel">Cancel</a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="action === 'info' && !change" class="box">
      <div class="box-header">
        <h2>Zipwhip</h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">Phone number:</label>
          <div class="col-sm-10">
            <p class="form-control-static">
              <input v-model="record.phone" type="text" readonly class="form-control" />
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">Session ID:</label>
          <div class="col-sm-10">
            <p class="form-control-static">
              <input v-model="record.username" type="text" readonly class="form-control" />
              <span class="form-text">
                Your current Zipwhip session.
                <a @click="changeAccount()">Change account</a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  name: 'ZipwhipChannel',
  props: {
    action: {
      type: String,
      default: 'create',
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      change: false,
      session: null,
    };
  },
  computed: {
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    changeAccount() {
      this.session = this.record.username;
      this.record.username = '';
      this.record.password = '';
      this.change = true;
    },

    chancel() {
      this.record.username = this.session;
      this.record.password = '';
      this.change = false;
    },
  },
};
</script>
