import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-test": "toast-message",
  class: "absolute bottom-4 left-[88px] z-50 flex w-auto flex-row items-center justify-between rounded-lg bg-grey-800 px-4 py-2"
}
const _hoisted_2 = { class: "t-text-sm flex w-auto flex-row items-center justify-between text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert_triangle_linear = _resolveComponent("alert-triangle-linear")!
  const _component_checkbox_circle_linear = _resolveComponent("checkbox-circle-linear")!
  const _component_cross_linear = _resolveComponent("cross-linear")!
  const _component_t_icon_button = _resolveComponent("t-icon-button")!

  return (_ctx.isOpen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.hasIcon && _ctx.iconType === 'alert')
            ? (_openBlock(), _createBlock(_component_alert_triangle_linear, {
                key: 0,
                "data-test": "alert-icon",
                size: "1.25rem",
                class: "mr-2"
              }))
            : _createCommentVNode("v-if", true),
          (_ctx.hasIcon && _ctx.iconType === 'success')
            ? (_openBlock(), _createBlock(_component_checkbox_circle_linear, {
                key: 1,
                "data-test": "success-icon",
                size: "1.25rem",
                class: "mr-2"
              }))
            : _createCommentVNode("v-if", true),
          _renderSlot(_ctx.$slots, "default"),
          (_ctx.hasCloseIcon)
            ? (_openBlock(), _createBlock(_component_t_icon_button, {
                key: 2,
                variant: "dark",
                "data-test": "close-icon",
                class: "ml-3 cursor-pointer text-white",
                onClick: _ctx.handleCloseBar
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_cross_linear)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["onClick"]))
            : _createCommentVNode("v-if", true)
        ])
      ]))
    : _createCommentVNode("v-if", true)
}