import { ref } from 'vue';

import { CHANNEL_TYPE } from '@/Configs/Constants';
import { useChannelTypes } from '@/store/pinia/channels/hooks/useChannelTypes';
import { useChannelsStore } from '@/store/pinia/channels/useChannelsStore';

import { useInboxSettingsChannelsStore } from './useInboxSettingsChannelsStore';

import type { ChannelIdList, ChannelList, IndexedChannelAssets } from './types';
import type { CHANNEL_SETTING } from './useInboxSettingsChannelsStore';
import type { ChannelsResponse } from '@/store/pinia/channels/types';
import type { Channel } from '@/types';
import type { ComputedRef } from 'vue';

export const defineChannelList = (
  channels: ChannelsResponse['data'],
  channelAssets: ComputedRef<IndexedChannelAssets>,
  activeChannels: Channel['id'][]
) => {
  const channelList: ChannelList = {};
  const activeChannelsList: ChannelIdList = {};

  channels.forEach((channel) => {
    const { type, id, display_name } = channel;

    if (type === CHANNEL_TYPE.VOIP || type === CHANNEL_TYPE.VOICE || type === CHANNEL_TYPE.HELP_CENTER) {
      return;
    }

    if (!channelList[type]) channelList[type] = [];
    if (!activeChannelsList[type]) activeChannelsList[type] = [];

    if (activeChannels.includes(id)) {
      activeChannelsList[type]?.push(id);
    }

    channelList[type]?.push({
      name: display_name,
      id,
      title: channelAssets.value[type]?.title ?? '',
      icon: channelAssets.value[type]?.icon,
      isActive: activeChannels.includes(id),
    });
  });

  return { channelList, activeChannelsList };
};

export const useChannels = (setting: valueof<typeof CHANNEL_SETTING>) => {
  const channels = ref<ChannelList>();
  const activeChannelIds = ref<ChannelIdList>({});
  const channelsStore = useChannelsStore();
  const { channelAssets } = useChannelTypes();
  const inboxSettingsChannelsStore = useInboxSettingsChannelsStore();

  Promise.all([inboxSettingsChannelsStore.getActiveChannels(), channelsStore.getChannels()]).then(
    ([activeChannels, _allChannels]) => {
      if (!channelsStore?.channels) return;
      const { channelList, activeChannelsList } = defineChannelList(
        channelsStore?.channels,
        channelAssets,
        activeChannels?.[setting] ?? []
      );

      channels.value = channelList;
      activeChannelIds.value = activeChannelsList;
    }
  );

  return { channels, activeChannelIds, defineChannelList };
};
