import { defineStore } from 'pinia';
type State = {
  showToast: boolean;
  hasIcon: boolean;
  hasCloseIcon: boolean;
  iconType: 'alert' | 'success';
  text: string;
};

const TIMEOUT = 5000;
export const useToastMessageStore = defineStore('ToastMessage', {
  state: (): State => {
    return {
      showToast: false,
      hasCloseIcon: false,
      hasIcon: false,
      iconType: 'alert',
      text: '',
    };
  },
  actions: {
    closeToastMessage() {
      this.$reset();
    },
    hideToastMessage() {
      setTimeout(() => {
        this.closeToastMessage();
      }, TIMEOUT);
    },
  },
});
