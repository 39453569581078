<template>
  <div class="h-full">
    <logo-header />
    <two-factor-auth-setup-stepper :qr="qr" :secret="secret" />
    <help-center-button />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import HelpCenterButton from '@/components/common/HelpCenterButton';
import LogoHeader from '@/components/common/PageHeaders/LogoHeader';
import { TwoFactorAuthSetupStepper, getTwoFactorQRCode } from '@/components/common/TwoFactorAuthSetup';
import { waitForEvent } from '@/start/util';

import type { Data } from '@/components/common/TwoFactorAuthSetup';

export default defineComponent({
  name: 'SetupTwoFactorAuth',
  components: {
    LogoHeader,
    HelpCenterButton,
    TwoFactorAuthSetupStepper,
  },
  data(): Data {
    return {
      qr: '',
      secret: '',
    };
  },
  methods: {
    getQR() {
      getTwoFactorQRCode()
        .then((res) => {
          this.qr = res.data.image;
          this.secret = res.data.secret;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  async mounted() {
    await waitForEvent('initial-data.loaded', this.$store.state.initialData.user.id);
    this.getQR();
  },
});
</script>
