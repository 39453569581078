<template>
  <div class="flex w-full flex-1">
    <portal to="sidebar">
      <div class="flex h-screen flex-col justify-between">
        <settings-sidebar :list-items="sidebarListItems" />
        <t-list-item
          v-if="showSwitch"
          class="mx-3 mb-4 flex p-2"
          :label="$t('reports_v2.switch')"
          size="sm"
          @click="handleRedirectToOldReporting()"
        >
          <template #prefix>
            <chart-column-linear size="1.3rem" />
          </template>
        </t-list-item>
      </div>
    </portal>
    <router-view />
  </div>
</template>

<script type="text/babel">
import { ChartColumnLinear } from '@trengo/trengo-icons';

import SettingsSidebar from '@/components/common/SettingsSidebar';
import { FEATURE_FLAG_ACTIVATION } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

import { getReportsList } from '../api';
import { isUserRegisteredAfter } from '../Util';

export default {
  components: { SettingsSidebar, ChartColumnLinear },
  data: function () {
    return {
      list: {},
      showSwitch: false,
    };
  },
  mounted: function () {
    const NovemberFirst2023 = '01/11/2023';
    if (isUserRegisteredAfter(NovemberFirst2023, this.$root.agency.created_at)) {
      this.showSwitch = false;
    } else {
      this.showSwitch = true;
    }
    if (window.is_mobile_device()) {
      this.$router.push('/');

      return;
    }
    this.getList();
  },
  computed: {
    sidebarListItems() {
      const baseRoute = `/reports`;
      return [
        {
          title: this.$t('reports_v2.navigation_header_reporting'),
          badge: this.$t('reports_v2.beta'),
          children: [
            {
              label: this.$t('reports_v2.dashboard'),
              route: `${baseRoute}`,
              isActive: this.$route.matched.some((routeRecord) => {
                return routeRecord.path.includes(`${baseRoute}`) && routeRecord.name === 'reportingDashboard';
              }),
            },
            {
              label: this.$t('reports_v2.workload_management'),
              route: `${baseRoute}/workload_management`,
              isActive: this.$route.matched.some(({ path, name }) => {
                return (
                  (path.includes(`${baseRoute}/workload_management`) && name === 'reportingWorkloadManagement') ||
                  name === 'reportingWorkloadManagement'
                );
              }),
            },
            {
              label: this.$t('reports_v2.agents_performance'),
              route: `${baseRoute}/agents_performance`,
              isActive: this.$route.matched.some(({ path, name }) => {
                return (
                  (path.includes(`${baseRoute}/agents_performance`) && name === 'reportingAgentsPerformance') ||
                  name === 'reportingAgentsPerformance'
                );
              }),
            },
            {
              label: this.$t('reports_v2.csat'),
              route: `${baseRoute}/csat`,
              isActive: this.$route.matched.some(({ path, name }) => {
                return (path.includes(`${baseRoute}/csat`) && name === 'reportingCSAT') || name === 'reportingCSAT';
              }),
            },
            {
              label: this.$t('reports_v2.ticket_insights'),
              route: `${baseRoute}/ticket_insights`,
              isActive: this.$route.matched.some(({ path, name }) => {
                return (
                  (path.includes(`${baseRoute}/ticket_insights`) && name === 'reportingTicketInsights') ||
                  name === 'reportingTicketInsightsSpecificPage'
                );
              }),
            },
          ],
        },
      ];
    },
  },
  methods: {
    handleRedirectToOldReporting() {
      this.$router.push({ replace: true, name: 'reportingDashboardOld' });
    },
    getList() {
      getReportsList().then((res) => {
        this.list = res.data;
      });
    },
  },
};
</script>
