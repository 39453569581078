import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center" }
const _hoisted_2 = { class: "mt-2.5 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_top_circle_linear = _resolveComponent("arrow-top-circle-linear")!
  const _component_t_badge = _resolveComponent("t-badge")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_settings_landing = _resolveComponent("settings-landing")!
  const _component_whatsapp_sandbox_modal = _resolveComponent("whatsapp-sandbox-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_settings_landing, {
      title: _ctx.$t('whatsapp.whatsapp_business_api'),
      icon: `${_ctx.$root.assetsURL}img/settings-landing/whatsapp.svg`,
      badge: _ctx.badge,
      list: _ctx.listItems
    }, _createSlots({
      default: _withCtx(() => [
        (_ctx.isConnectButtonVisible)
          ? (_openBlock(), _createBlock(_component_t_button, {
              key: 0,
              size: "lg",
              "btn-style": "primary",
              role: "button",
              "aria-label": _ctx.$t('whatsapp.connect_your_number'),
              type: "submit",
              label: "Button",
              class: "mb-4 mr-0 xl:mb-0 xl:mr-4",
              onClick: _ctx.openWaBusinessModal
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('whatsapp.connect_your_number')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["aria-label", "onClick"]))
          : _createCommentVNode("v-if", true),
        (_ctx.isUpgradeButtonVisible)
          ? (_openBlock(), _createBlock(_component_t_button, {
              key: 1,
              size: "lg",
              "btn-style": "primary",
              role: "button",
              "aria-label": _ctx.upgradeToPlanText,
              type: "submit",
              label: "Button",
              class: "mb-4 mr-0 xl:mb-0 xl:mr-4",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(_ctx.SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS)))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_arrow_top_circle_linear, {
                    class: "mr-1",
                    size: "1.25rem"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.upgradeToPlanText), 1 /* TEXT */)
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["aria-label"]))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_t_button, {
          size: "lg",
          "btn-style": "secondary",
          role: "button",
          "aria-label": _ctx.$t('whatsapp.test_whatsapp_business'),
          type: "submit",
          label: "Button",
          onClick: _ctx.openSandboxModal
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('whatsapp.test_whatsapp_business')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["aria-label", "onClick"])
      ]),
      _: 2 /* DYNAMIC */
    }, [
      (!_ctx.isEntitledToWaBusiness)
        ? {
            name: "entitlement-badge",
            fn: _withCtx(() => [
              _createVNode(_component_t_badge, {
                text: _ctx.badgeText,
                variant: "upgrade",
                class: "w-fit cursor-default",
                "data-test": "entitlement-badge"
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_arrow_top_circle_linear, { size: "1rem" })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["text"])
            ]),
            key: "0"
          }
        : undefined,
      (_ctx.isHintVisible)
        ? {
            name: "hint",
            fn: _withCtx(() => [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('entitlement.admin_unlock_this_feature')), 1 /* TEXT */)
            ]),
            key: "1"
          }
        : undefined
    ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "icon", "badge", "list"]),
    _createVNode(_component_whatsapp_sandbox_modal, {
      modelValue: _ctx.computedValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedValue) = $event)),
      onOpen: _ctx.openSandboxModal,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showSandboxModal = false))
    }, null, 8 /* PROPS */, ["modelValue", "onOpen"])
  ]))
}