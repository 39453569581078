import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "t-text-h3 my-auto max-w-[90%] p-0" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "m-0 p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_integration_loading = _resolveComponent("integration-loading")!
  const _component_dynamic_form_fields = _resolveComponent("dynamic-form-fields")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_spinner = _resolveComponent("t-spinner")!
  const _component_t_modal = _resolveComponent("t-modal")!

  return (_openBlock(), _createBlock(_component_t_modal, {
    modelValue: _ctx.shouldOpenModal,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.shouldOpenModal) = $event)),
    "close-icon": true,
    variant: "narrow",
    "is-closable": "",
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('integration_hub.edit_integration')), 1 /* TEXT */)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_t_button, {
        "btn-style": "secondary",
        disabled: _ctx.inProgress,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('general.cancel')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled"]),
      _createVNode(_component_t_button, {
        class: "flex flex-row items-center justify-center",
        disabled: _ctx.isSaveDisabled,
        onClick: _ctx.save
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('general.save')), 1 /* TEXT */),
          (_ctx.isSaving)
            ? (_openBlock(), _createBlock(_component_t_spinner, {
                key: 0,
                size: "1rem",
                class: "ml-2"
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.inProgress)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.inProgress)
                ? (_openBlock(), _createBlock(_component_integration_loading, {
                    key: 0,
                    class: "mt-4"
                  }))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.integration.form_fields.length > 0)
                ? (_openBlock(), _createBlock(_component_dynamic_form_fields, {
                    key: 0,
                    "prefilled-settings": _ctx.oldSettings,
                    onFormChanged: _ctx.onFormChanged
                  }, null, 8 /* PROPS */, ["prefilled-settings", "onFormChanged"]))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}