<template>
  <rename-modal :installation="installation" @close="closeModal"></rename-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import RenameModal from '@/components/IntegrationHub/Components/Modals/RenameModal.vue';

export default defineComponent({
  name: 'IntegrationInstallationRename',

  components: {
    RenameModal,
  },

  computed: {
    ...mapGetters({
      integration: 'integrations/getItem',
    }),

    installation() {
      return this.integration.installations.find((installation) => {
        return installation.id === parseInt(this.$route.params.installationId);
      });
    },
  },

  methods: {
    closeModal() {
      const params = {
        filter: this.$route.params.filter,
        integrationId: this.$route.params.integrationId,
      };

      this.$router.replace({ name: 'integrationDetails', params, query: { ...this.$route.query } });
    },
  },
});
</script>
