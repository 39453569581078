import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative mt-6 h-88" }
const _hoisted_2 = {
  ref: "report",
  height: "200",
  class: "z-10 h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("canvas", _hoisted_2, null, 512 /* NEED_PATCH */)
  ]))
}