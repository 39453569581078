<template>
  <div class="grid grid-cols-2 gap-4 text-center xl:grid-cols-3">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'StatRow',
});
</script>
