<template>
  <t-popover v-model="open" data-test="table-sort" click-outside-close>
    <template #trigger>
      <div class="flex cursor-pointer flex-col justify-between">
        <chevron-up-linear size="0.75rem" />
        <chevron-down-linear class="-mt-[0.4rem]" size="0.75rem" />
      </div>
    </template>
    <template #content>
      <div class="relative">
        <div
          :class="positionClass"
          class="
            absolute
            -left-20
            z-10
            mt-2
            flex flex-col
            items-center
            rounded-xl
            border border-grey-300
            bg-white
            p-3
            shadow-500
          "
          data-test="table-sort-items"
        >
          <slot name="item" />
        </div>
      </div>
    </template>
  </t-popover>
</template>

<script lang="ts">
import { ChevronUpLinear, ChevronDownLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import type { PropType } from 'vue';
export default defineComponent({
  name: 'TableSort',
  components: { ChevronUpLinear, ChevronDownLinear },
  props: {
    position: {
      type: String as PropType<'right' | 'left'>,
      default: 'right',
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    positionClass() {
      return this.position === 'left' ? 'w-[187px] -left-[180px]' : '';
    },
  },
});
</script>
