<template>
  <div class="rounded-xl p-3" style="background-color: rgb(247, 247, 247); overflow: hidden">
    <div
      v-if="element.meta.file_path"
      class="image-message -mb-4 -ml-3 -mt-3"
      :class="{ '': !element.meta.message }"
      :style="{ backgroundImage: `url('${getCdnPath(element.meta.file_path)}')` }"
      style="
        background-color: rgb(204, 214, 221);
        width: 353px;
        height: 200px;
        background-position: center center;
        background-size: cover;
        line-height: 200px;
        text-align: center;
      "
    ></div>
    <div
      v-if="!element.meta.file_path || element.meta.message"
      :class="[!element.meta.file_path ? '' : 'mt-6']"
      class="px-2 text-grey-600"
    >
      <div v-if="!element.meta.file_path && !element.meta.message" class="font-italic">
        {{ $t('flowbot.step_presenter_image_click') }}
        <i class="material-icons text-sm">create</i>
        {{ $t('flowbot.step_presenter_image_to_edit_this_image_with_optional_caption') }}
      </div>
      <div v-else v-html="formatText(element.meta.message)"></div>
    </div>
  </div>
</template>

<script>
import textFormatter from '../mixins/TextFormatter';

export default {
  props: ['element'],

  mixins: [textFormatter],

  methods: {
    getCdnPath(file_path) {
      return CDN + file_path;
    },
  },
};
</script>
