import { request } from '@/util/request';

import { ENDPOINT } from '../constants';

import type { User } from '@/types';
import type { AxiosPromise } from 'axios';

export function fetchUser(): AxiosPromise<User> {
  return request(ENDPOINT.ME, 'GET');
}

export function fetchIsImpersonated(): AxiosPromise<{ is_impersonated: boolean }> {
  return request(ENDPOINT.IS_IMPERSONATED, 'GET');
}
