<script>
export default {
  props: {
    size: {
      type: String,
      default: '40px',
    },
  },
  mounted() {},
};
</script>

<template>
  <span class="leading-none text-black" :style="{ 'font-size': size }" style="font-weight: bold; line-height: 52px">
    <slot></slot>
  </span>
</template>
