import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filter-datepicker flex text-base" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "flex items-center justify-center" }
const _hoisted_4 = { class: "mb-4 ml-4 mr-4 mt-4 flex h-10 w-full items-center justify-center rounded-xl border border-grey-300" }
const _hoisted_5 = { class: "mb-4 ml-4 mr-4 mt-4 flex h-10 w-full items-center justify-center rounded-xl border border-grey-300" }
const _hoisted_6 = { class: "flex justify-end px-2 py-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_right_linear = _resolveComponent("arrow-right-linear")!
  const _component_t_divider = _resolveComponent("t-divider")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_date_range_picker = _resolveComponent("date-range-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_date_range_picker, {
        ref: "picker",
        modelValue: $data.date,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.date) = $event)),
        "date-range": $data.date,
        "single-date-picker": "range",
        "show-dropdowns": false,
        opens: "inline",
        ranges: false,
        "auto-apply": false,
        "control-container-class": '',
        "locale-data": {
          daysOfWeek: $options.getDaysOfWeek(),
          monthNames: $options.getMonthNames(),
        },
        onSelect: $options.setSelectedRange
      }, {
        input: _withCtx((picker) => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString($options.getStartDate()), 1 /* TEXT */),
            _createVNode(_component_arrow_right_linear, {
              size: "1.25rem",
              class: "shrink-0"
            }),
            _createElementVNode("span", _hoisted_5, _toDisplayString($options.getEndDate()), 1 /* TEXT */)
          ]),
          _createVNode(_component_t_divider)
        ]),
        footer: _withCtx((picker) => [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_t_button, {
              size: "sm",
              "btn-style": "secondary",
              class: "mr-2",
              onClick: $options.clearSelection
            }, {
              default: _withCtx(() => [
                _createTextVNode("Clear")
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"]),
            _createVNode(_component_t_button, {
              size: "sm",
              onClick: $options.applySelection
            }, {
              default: _withCtx(() => [
                _createTextVNode("Apply")
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "date-range", "locale-data", "onSelect"])
    ])
  ]))
}