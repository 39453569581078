import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_profile_custom_field_editor = _resolveComponent("profile-custom-field-editor")!
  const _component_sidebar_dropdown = _resolveComponent("sidebar-dropdown")!

  return (_openBlock(), _createBlock(_component_sidebar_dropdown, {
    title: _ctx.$tc('ticket_sidebar.profile_fields'),
    "collapse-key": "profileFieldsDropdown",
    "data-test": "profile-fields-dropdown"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_profile_custom_field_editor, { profile: _ctx.profile }, null, 8 /* PROPS */, ["profile"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]))
}