<script>
export default {
  props: {
    steps: Array,
    activeStepNumber: {
      type: Number,
      default: null,
    },
  },
};
</script>
<template>
  <div class="flex">
    <div class="relative mb-14 ml-8 mt-4 border-l-2 border-grey-100" style="z-index: 90" />
    <div class="relative -ml-8" style="z-index: 100">
      <div
        v-for="(step, index) in steps"
        class="mb-6 flex p-4 pr-8"
        :class="[{ 'rounded-lg border-1 border-grey-200 bg-white': activeStepNumber === index + 1 }]"
        :style="
          activeStepNumber === index + 1
            ? 'box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.02), 0px 13px 64px rgba(0, 0, 0, 0.05)'
            : ''
        "
      >
        <div class="h-8 w-8 text-center font-semibold leading-loose" style="margin-left: -1px">
          <slot :name="'step_indicator_' + (index + 1)">{{ index + 1 }}</slot>
        </div>

        <div class="ml-6 mt-1" :class="index + 1 > activeStepNumber ? 'text-grey-400' : 'text-grey-800'">
          <div class="mb-2 font-bold">{{ step.title }}</div>
          <slot :name="'step_' + (index + 1)" />
        </div>
      </div>
    </div>
  </div>
</template>
