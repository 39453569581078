<script>
import CustomFieldEditor from './CustomFieldEditor';
import select2 from '../../Select2';

export default {
  components: {
    select2,
    CustomFieldEditor,
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    channels: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      collapsed: true,
    };
  },
  computed: {
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<template>
  <div class="box">
    <div class="box-header b-b">
      <h3>{{ $t('web_widget.live_chat') }}</h3>
    </div>
    <div class="box-body">
      <div class="form-group row">
        <label class="col-md-2 form-control-label">{{ $t('web_widget.enabled') }}</label>
        <div class="col-md-10">
          <p class="form-control-static">
            <label class="ui-switch success m-t-xs m-r">
              <input v-model="record.chat.enabled" type="checkbox" />
              <i></i>
            </label>
          </p>
        </div>
      </div>
      <div v-if="record.chat.enabled">
        <div class="form-group row">
          <label class="col-md-2 form-control-label">
            {{ $t('web_widget.channel') }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-md-10">
            <select2
              v-model="record.chat.channel_id"
              :options="channels"
              :placeholder="$t('web_widget.select_a_channel')"
            ></select2>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 form-control-label">{{ $t('web_widget.show_always_as_available') }}</label>
          <div class="col-md-10">
            <label class="ui-switch success m-t-xs m-r">
              <input v-model="record.chat.always_available" type="checkbox" true-value="1" />
              <i></i>
            </label>
            <span class="form-text">
              {{ $t('web_widget.when_enabled_the_chat_description') }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 form-control-label">{{ $t('web_widget.pre_chat_form') }}</label>
          <div class="col-md-10">
            <label class="ui-switch success m-t-xs m-r">
              <input v-model="record.chat.pre_form" type="checkbox" true-value="1" />
              <i></i>
            </label>
            <span class="form-text">
              {{ $t('web_widget.pre_chat_form_description') }}
            </span>

            <div v-if="record.chat.pre_form == 1" class="mt-2">
              <custom-field-editor v-model="record"></custom-field-editor>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 form-control-label">{{ $t('web_widget.auto_close_chats') }}</label>
          <div class="col-md-10">
            <label class="ui-switch success m-t-xs m-r">
              <input v-model="record.chat.auto_close" type="checkbox" true-value="1" />
              <i></i>
            </label>
            <span class="form-text">
              {{ $t('web_widget.automatically_close_chats_after_x_minutes') }}
            </span>
          </div>
        </div>
        <div v-if="record.chat.auto_close == 1" class="form-group row">
          <label class="col-md-2 form-control-label">{{ $t('web_widget.auto_close_chat_in_seconds') }}</label>
          <div class="col-md-10">
            <input v-model="record.chat.auto_close_chat_seconds" type="text" class="form-control" />
            <span class="form-text">
              {{ $t('web_widget.auto_close_chat_in_seconds') }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 form-control-label">{{ $t('web_widget.show_online_status') }}</label>
          <div class="col-md-10">
            <label class="ui-switch success m-t-xs m-r">
              <input v-model="record.chat.show_online_status" type="checkbox" true-value="1" />
              <i></i>
            </label>
            <span class="form-text">
              {{ $t('web_widget.if_the_chat_is_online_a_message_will_be_displayed_on_every_page') }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 form-control-label">{{ $t('web_widget.offline_outside_business_hours') }}</label>
          <div class="col-md-10">
            <label class="ui-switch success m-t-xs m-r">
              <input v-model="record.chat.offline_outside_business_hours" type="checkbox" true-value="1" />
              <i></i>
            </label>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 form-control-label">{{ $t('web_widget.email_offline_chat') }}</label>
          <div class="col-md-10">
            <input v-model="record.chat.offline_email" type="email" class="form-control" />
            <span class="form-text">
              {{ $t('web_widget.when_the_chat_is_offline_messages_will_be_send_to_this_address') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
