import { includes } from 'lodash';

import eventBus from '@/eventBus';
import { flashError } from '@/util/flashNotification';

import store from '../../store';

export default {
  methods: {
    registerEvents() {
      this.voip.on('initialized', this.onInitialized);
      this.voip.on('outbound_call_initiated', (data) => this.onInitializedOutboundCall(data));
      this.voip.on('outbound_call_ringing', (data) => this.onRingingOutboundCall(data));
      this.voip.on('inbound_call_initialized', (data) => this.onInitializedInboundCall(data));

      this.voip.on('outbound_mobile_call_initiated', (data) => this.onInitiatedOutboundMobileCall(data));

      this.voip.on('error', (data) => this.onError(data));

      this.voip.on('on_inbound_call', (data) => this.onInboundCall(data));
      this.voip.on('on_call_started', (data) => this.onCallStarted(data));
      this.voip.on('on_call_ended', (data) => this.onCallEnded(data));
      this.voip.on('on_call_transferred', (data) => this.onCallTransferred(data));
      this.voip.on('on_inbound_transfer_call', null);

      this.voip.on('on_intern_call_inbound', (data) => this.onInternCallInbound(data));
      this.voip.on('on_intern_call_rejected', (data) => this.onInternCallRejected(data));
      this.voip.on('on_intern_call_accepted', (data) => this.onInternCallAccepted(data));
      this.voip.on('on_intern_call_cancelled', (data) => this.onInternCallCancelled(data));
      this.voip.on('on_intern_call_third_party_left', (data) => this.onInternCallThirdPartyLeft(data));
      this.voip.on('on_intern_call_initialized', this.onInternCallInitialized);

      this.voip.on('on_intern_team_call', (data) => this.onInternTeamCall(data));
      this.voip.on('on_intern_team_call_cancelled', (data) => this.onInternTeamCallCancelled(data));
      this.voip.on('on_client_accepted_call', (data) => this.onClientAcceptedCall(data));
      this.voip.on('on_external_phone_accepted_call', (data) => this.onExternalPhoneAcceptedCall(data));
    },

    onInitialized() {
      this.initialized = true;
      this.$root.voipReady = true;
      this.setDevices();
    },

    onInitializedOutboundCall(data) {
      this.call.direction = 'outbound';
      this.call.status = 'initializing';
      this.call.token = data.token;
      this.call.userId = this.$root.user.id;

      //  this.call.status = 'in_progress';

      eventBus.$emit('voip.user_call_started');
    },

    onRingingOutboundCall(data) {
      if (data.user_id !== this.$root.user.id) {
        return;
      }

      this.call.ticketId = data.ticket_id;
      this.call.contact = data.contact;
      this.call.status = 'ringing';
      this.call.channel = data.channel;
      this.tab = null;
    },

    onInitiatedOutboundMobileCall(data) {
      this.call.direction = 'outbound';
      this.call.status = 'initializing';
      this.call.token = data.token;
      this.call.userId = this.$root.user.id;
      this.call.mobile = true;

      eventBus.$emit('voip.user_call_started');
    },

    onCallStarted(call) {
      if (this.call.token === call.token) {
        this.call.status = 'in_progress';
        this.tab = null;

        if (call.user_id != this.$root.user.id) {
          this.call.reset();
          this.isOpen = false;
          this.tab = null;
        }
      }
      eventBus.$emit('voip.call_started', call);
    },

    onCallEnded(call) {
      if (this.call.token === call.token) {
        this.endCall();
      }

      if (!this.call.isVoipSandbox) {
        eventBus.$emit('voip.call_ended', call);
      } else if (this.call.isVoipSandbox) {
        eventBus.$emit('voip.sandbox_call_ended', call);
      }

      if (call.channel?.voip_channel?.provider === 'TWILIO_SANDBOX') {
        this.call.isVoipSandbox = false;
      }
    },

    onInboundCall(data) {
      // let panelTimeOut;
      // if (data.call.channel?.voip_channel?.provider === 'TWILIO_SANDBOX' && !this.call.isVoipSandboxModalOpen) {
      //     panelTimeOut = 5000;
      // } else {
      //     panelTimeOut = 0;
      // }

      if (!includes(data.targets, parseInt(this.$root.user.id))) {
        return;
      }

      if (data.call.channel?.voip_channel?.provider === 'TWILIO_SANDBOX' && this.call.isVoipSandboxModalOpen) {
        this.call.isVoipSandbox = true;
      }

      if (!this.call.isVoipSandbox) {
        // setTimeout(() => {
        eventBus.$emit('voip.call_started', data.call);
        // },panelTimeOut)
      } else if (this.call.isVoipSandbox) {
        eventBus.$emit('voip.sandbox_call_started', data.call);
      }

      if (this.user_status !== 'ONLINE' || this.call.status != null) {
        return;
      }

      if (!this.call.isVoipSandbox && !this.call.isVoipSandboxModalOpen) {
        // setTimeout(() => {
        this.isOpen = true;
        this.call.status = 'ringing';
        this.call.direction = 'inbound';
        this.call.contact = data.call.contact;
        this.call.channel = data.call.channel;
        this.call.ticketId = data.call.ticket_id;
        this.call.token = data.call.token;
        this.$root.notify(data.call.contact, 'Incoming call');
        // }, panelTimeOut)
      }
    },

    onCallAccepted() {
      // post accepted call
    },

    onInitializedInboundCall() {
      this.tab = null;
      this.call.status = 'in_progress';
      this.call.accepting = false;
      this.call.userId = this.$root.user.id;
    },

    onInternCallInbound(data) {
      if (this.user_status === 'CALLING') {
        return;
      }
      this.call.intern = true;
      this.call.contact = data.from;
      this.call.channel = {
        title: 'Intern',
      };
      this.call.userId = data.call.userId;
      this.call.direction = 'inbound';
      this.call.status = 'ringing';
      this.call.internCall.from = data.from;
      this.call.internCall.status = 'ringing';
      this.call.internCall.user.id = this.$root.user.id;
      this.call.token = data.call.token;
      this.call.channel = data.call.channel;
      this.isOpen = true;

      this.$root.notify(data.from, 'Incoming internal call');
    },

    onInternCallRejected() {
      this.call.internCall.status = 'rejected';
    },

    onInternCallAccepted(data) {
      if (this.$root.user.id == data.user_id && this.call.token === data.token) {
        this.call.internCall.status = 'in_progress';
        this.voip.setMute(false);
      }
    },

    onInternCallThirdPartyLeft(data) {
      if (this.$root.user.id == data.user_id && this.call.token === data.token) {
        this.call.internCall.status = 'rejected';
      }
    },

    onInternCallCancelled() {
      this.call.reset();
      this.voip.endCall();
      this.tab = null;
      eventBus.$emit('voip.user_call_ended');
    },

    onInternCallInitialized() {
      this.call.userId = this.$root.user.id;
      this.call.status = 'in_progress';
      this.call.internCall.direction = 'inbound';
    },

    onCallTransferred(data) {
      if (data.user_id != this.$root.user.id) {
        return;
      }

      this.call.resetInternCall();

      this.call.ticketId = data.call.ticketId;
      this.call.userId = this.$root.user.id;
      this.call.contact = data.call.contact;
      this.call.channel = data.call.channel;
      this.call.intern = false;
    },

    onInternTeamCall(data) {
      const isUserTeamSameAsTarget = this.$root.user.teams.find((item) => item.id === data.target);
      if (!isUserTeamSameAsTarget || this.$root.user.voip_status === 'OFFLINE') {
        return;
      }

      this.onInternCallInbound(data);
    },

    onInternTeamCallCancelled(data) {
      if (this.call.token === data.token && includes(this.$root.user.teams, data.target)) {
        this.onInternCallCancelled();
      }
    },

    async reCallNextInQueue(data) {
      let queue = (await axios.get('/client-api/voip/log')).data || [];
      queue = queue.filter((i) => {
        return (
          this.$root.channels.map((c) => c.id).includes(i.channel_id) &&
          i.status === 'QUEUED' &&
          i.token !== data.call.token
        );
      });

      // more calls in queue
      if (queue.length) {
        let call = queue[0];
        call.status = 'ringing';
        // emit as incoming call
        this.voip.trigger('on_inbound_call', { call, targets: [store.state.usersInternalChat.currentUserId] });
      }
    },

    onClientAcceptedCall(data) {
      if (this.call.isRinging() && this.call.token === data.call.token) {
        // reset panel
        this.call.reset();
        this.tab = null;
        this.isOpen = false;

        eventBus.$emit('voip.user_call_accepted', {
          call: data.call,
          user: data.user,
        });

        if (data.user.id !== store.state.usersInternalChat.currentUserId && this.$root.user.voip_device === 'WEB') {
          this.reCallNextInQueue(data);
        }
      }

      // internal call
      if (this.call.token === data.call.token && this.call.internCall.status === 'ringing') {
        this.call.internCall.status = 'connecting';
        this.call.internCall.user = data.user;
      }
    },

    onExternalPhoneAcceptedCall(data) {
      if (this.call.isRinging() && this.call.token === data.token) {
        this.call.reset();
        this.tab = null;
      }
    },

    onError(error) {
      flashError(error.message);
    },

    setDevices() {
      if (!window.isChrome() || this.$root.mobileDevice) {
        return;
      }

      setTimeout(async () => {
        if (await this.$tStorage.getItem('voip.op_device')) {
          Twilio.Device.audio.speakerDevices.set(await this.$tStorage.getItem('voip.op_device'));
        }

        if (await this.$tStorage.getItem('voip.ip_device')) {
          //Twilio.Device.audio.setInputDevice(this.$tStorage.getItem('voip.ip_device'));
        }
      }, 500);
    },
  },
};
