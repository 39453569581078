<template>
  <div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.magento2_label_shop_url') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.url" type="text" autocomplete="off" class="form-control" />
        <span class="form-text">{{ $t('integrations.magento2_subtitle_shop_url') }}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.magento2_label_admin_url') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.admin_url" type="text" autocomplete="off" class="form-control" />
        <span class="form-text">{{ $t('integrations.magento2_subtitle_admin_url') }}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.magento2_label_consumer_key') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.consumer_key" type="password" autocomplete="off" class="form-control" />
        <span class="form-text">{{ $t('integrations.magento2_subtitle_consumer_key') }}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.magento2_label_consumer_secret') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.customer_secret" type="password" autocomplete="off" class="form-control" />
        <span class="form-text">{{ $t('integrations.magento2_subtitle_consumer_secret') }}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.magento2_label_access_token') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.access_token" type="password" autocomplete="off" class="form-control" />
        <span class="form-text">{{ $t('integrations.magento2_subtitle_access_token') }}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.magento2_label_access_token_secret') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.access_token_secret" type="password" autocomplete="off" class="form-control" />
        <span class="form-text">{{ $t('integrations.magento2_subtitle_access_token_secret') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
