import { useEntitlementsStore } from '@/store/pinia';

import type { Middleware } from './types';

export const loadEntitlements: Middleware = async (to) => {
  if (to.meta?.allowUnauthenticated) {
    return true;
  }
  const entitlements = useEntitlementsStore();
  await entitlements.init();
};
