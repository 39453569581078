<template>
  <t-modal v-model="isModalOpen" variant="narrow" :title="channel.title" @close="handleClose">
    <p class="channel-confirmation-modal__body">{{ channel.body }}</p>

    <div v-if="gifImage" class="channel-confirmation-modal-image">
      <video ref="mp4_video" loop>
        <source :src="gifImage" type="video/mp4" />
      </video>
    </div>

    <div v-if="channel.sub_content" class="channel-confirmation-modal__sub-content">{{ channel.sub_content }}</div>

    <t-inline-banner class="items-start">
      <template #icon>
        <span>
          <info-linear class="channel-confirmation-modal__icon" />
        </span>
      </template>

      <p class="channel-confirmation-modal__info" v-html="channel.info" />
    </t-inline-banner>
    <template #footer>
      <t-button size="md" btn-style="secondary" @click="handleSkip">
        {{ channel.cancel_btn }}
      </t-button>
      <t-button size="md" @click="handleCreate">
        {{ channel.success_btn }}
      </t-button>
    </template>
  </t-modal>
</template>
<script lang="ts">
import { InfoLinear } from '@trengo/trengo-icons';

import { mapGifsBaseOnChannels } from './utils';

import type { ChannelItem, WhatsappChannelItem } from '@/components/OnboardingChannelLandingPage/types';
import type { PropType } from 'vue';

export default {
  name: 'ChannelConfirmationModal',
  emits: ['close', 'create'],
  components: { InfoLinear },
  data() {
    return {
      gifImage: undefined,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    channel: {
      type: Object as PropType<WhatsappChannelItem | ChannelItem>,
      default: () => ({}),
    },
    channel_type: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (this.channel_type) {
      this.gifImage = mapGifsBaseOnChannels[this.channel_type];
    }
  },
  computed: {
    isModalOpen() {
      return this.open;
    },
  },
  methods: {
    stopVideoAndSetToBegin() {
      if (!this.$refs.mp4_video) return;
      this.$refs.mp4_video.pause();
      this.$refs.mp4_video.currentTime = 0;
    },
    playVideoIfxists() {
      setTimeout(() => {
        if (!this.gifImage) return;
        if (!this.$refs.mp4_video) return;
        this.$refs.mp4_video.play();
      }, 100);
    },
    handleClose() {
      this.$emit('close');
      this.stopVideoAndSetToBegin();
    },
    handleSkip() {
      this.$emit('close', this.channel_type);
    },
    handleCreate() {
      this.$emit('create', this.channel_type);
    },
  },
  watch: {
    open(value) {
      if (!value) return;
      setTimeout(() => {
        this.playVideoIfxists();
      }, 100);
    },
    channel() {
      this.gifImage = mapGifsBaseOnChannels[this.channel_type];
    },
  },
};
</script>

<style src="./ChannelConfirmationModal.scss" lang="scss" scoped />
