<template>
  <div ref="target">
    <div class="flex flex-wrap gap-2 p-1">
      <!-- Selected Contacts -->
      <div
        v-for="(contact, index) in selectedContacts"
        :key="contact.identifier"
        class="select-none cursor-pointer py-1 px-2 text-ellipsis rounded-lg bg-grey-200 leading-none text-grey-600 hover:text-grey-800 items-center flex"
        :class="{
          'bg-grey-600 text-white': index === selectedIndex,
          'bg-grey-200 text-grey-600': index !== selectedIndex,
        }"
        @click="selectedContactsClicked(index)"
      >
        {{ contact[selectedLabelIndex] }}
      </div>
      <!-- Search Input -->
      <input
        v-model="search_keyword"
        type="text"
        class="flex-1"
        @click="selectedIndex = -1"
        @keydown="onKeyDownInput"
      />
    </div>
    <!-- Dropdown List -->
    <div
      v-show="isOpen && search_keyword?.length"
      ref="dropdownRef"
      class="absolute z-50 w-96 rounded-xl border border-grey-300 bg-white shadow-500 py-2"
    >
      <div class="mx-1 overflow-x-hidden overflow-y-auto max-h-[300px]">
        <!-- Loading State -->
        <div v-if="loading" class="flex flex-col gap-4 my-2 mx-1">
          <straight :width="300" :height="12" />
          <straight :width="150" :height="12" />
          <straight :width="250" :height="12" />
        </div>
        <!-- Items List -->
        <div v-else>
          <t-list-item
            ref="addItemRef"
            class="cursor-pointer"
            :label="`${$t('general.add')} '${search_keyword}'`"
            :value="search_keyword"
            :active="listIndex === 0"
            @click="select({ identifier: search_keyword })"
          >
            <template #prefix>
              <plus-linear size="1.5rem" />
            </template>
          </t-list-item>
          <t-list-item
            v-for="(item, index) in props.items"
            :key="item[itemLabelIndex]"
            :ref="refs.set"
            class="cursor-pointer"
            :label="item[itemLabelIndex]"
            :value="item"
            :active="showAddContact ? listIndex === index + 1 : listIndex === index"
            @click="select(item)"
          />
          <t-list-item
            v-if="props.items.length === 0"
            :label="$t('reports_v2.no_result_found')"
            :value="search_keyword"
            :disabled="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PlusLinear } from '@trengo/trengo-icons';
import { useTemplateRefsList, watchDebounced, onClickOutside } from '@vueuse/core';
import { ref, watch } from 'vue';

import Straight from '@/components/Channels/CreateChannelModals/WhatsApp/SkeletonLoader/Straight.vue';

import type Contact from '@/types/contact';

const refs = useTemplateRefsList();

const props = defineProps<{
  items: Contact[];
  itemLabelIndex: string;
  selectedLabelIndex: string;
  loading: boolean;
  allowMultiple: boolean;
  initialSelectedItems: Contact[];
}>();

const selectedContacts = ref<Partial<Contact>[]>(props.initialSelectedItems);
const emit = defineEmits(['onInputChange', 'onSelect']);
const isOpen = ref(false);
const selectedIndex = ref(-1);
const listIndex = ref(-1);
const search_keyword = ref('');
const showAddContact = ref(true);
const dropdownRef = ref<HTMLElement | null>(null);
const addItemRef = ref<Element | null>(null);
const target = ref(null);

// Select an item from the dropdown
const select = (item: Partial<Contact>) => {
  if (props.allowMultiple) {
    selectedContacts.value.push(item);
  } else {
    selectedContacts.value = [item];
  }
  emit('onSelect', selectedContacts.value);
  search_keyword.value = '';
  isOpen.value = false;
  listIndex.value = -1;
};

// Handle click on selected contacts
const selectedContactsClicked = (index: number) => {
  selectedIndex.value = selectedIndex.value === index ? -1 : index;
  if (selectedIndex.value > -1) {
    mountKeyboard();
  }
};

// Mount keyboard event listener
const mountKeyboard = () => {
  document.addEventListener('keydown', handleKeyboardEvent);
};

// Unmount keyboard event listener
const unmountKeyboard = () => {
  document.removeEventListener('keydown', handleKeyboardEvent);
};

// Handle keyboard events for selected contacts
const handleKeyboardEvent = ($event: KeyboardEvent) => {
  if (
    ($event.key === 'Backspace' || $event.key === 'Delete') &&
    selectedIndex.value > -1 &&
    search_keyword.value === '' &&
    selectedContacts.value.length > 0
  ) {
    selectedContacts.value.splice(selectedIndex.value, 1);
    selectedIndex.value = -1;
  }
  unmountKeyboard();
};

// Handle keyboard events for search input
const onKeyDownInput = ($event: KeyboardEvent) => {
  if ($event.key === 'ArrowDown' && listIndex.value < props.items.length) {
    $event.preventDefault();
    listIndex.value++;
    scrollListItemIntoView(false);
  }
  if ($event.key === 'ArrowUp' && listIndex.value > 0) {
    $event.preventDefault();
    listIndex.value--;
    scrollListItemIntoView(true);
  }
  if ($event.key === 'Enter') {
    $event.preventDefault();
    if (search_keyword.value === '' || props.loading) {
      return;
    }
    if (listIndex.value === 0) {
      select({ identifier: search_keyword.value });
    } else {
      const item = props.items[listIndex.value - 1];
      if (item) {
        select(item);
      }
    }
  }
  if (
    ($event.key === 'Backspace' || $event.key === 'Delete') &&
    search_keyword.value === '' &&
    selectedContacts.value.length > 0
  ) {
    selectedContacts.value.splice(selectedContacts.value.length - 1, 1);
  }
};

// Scroll the selected list item into view
const scrollListItemIntoView = (top: boolean) => {
  if (listIndex.value === 0) {
    if (addItemRef.value?.$el) {
      addItemRef.value.$el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'end' });
      return;
    }
  }
  if (refs.value.length > 0) {
    const elm = refs.value[listIndex.value - 1]?.$el;
    if (elm && !isDropdownOptionOverflowing(elm)) {
      elm.scrollIntoView(
        top
          ? { behavior: 'smooth', block: 'start', inline: 'end' }
          : { behavior: 'smooth', block: 'end', inline: 'end' },
      );
    }
  }
};

// Check if dropdown options are overflowing
const isDropdownOptionOverflowing = (optionElm: Element) => {
  const containerRect = dropdownRef.value?.getBoundingClientRect();
  const optionRect = optionElm.getBoundingClientRect();

  if (!containerRect) {
    return false;
  }

  return (
    optionRect.top >= containerRect.top &&
    optionRect.bottom <= containerRect.bottom &&
    optionRect.left >= containerRect.left &&
    optionRect.right <= containerRect.right
  );
};

// Watch for changes in initial selected items
watch(
  () => props.initialSelectedItems,
  (value) => {
    selectedContacts.value = value;
  },
);

// Watch for changes in search keyword with debounce
watchDebounced(
  search_keyword,
  async () => {
    emit('onInputChange', search_keyword.value);
    isOpen.value = search_keyword.value.length > 0;
    listIndex.value = -1;
  },
  { debounce: 500, maxWait: 5000 },
);

onClickOutside(target, () => (isOpen.value = false));
</script>

<style lang="scss" scoped></style>
