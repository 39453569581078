export function getStatusIndicator(item) {
  if (!['FACEBOOK', 'INSTAGRAM', 'EMAIL', 'WA_BUSINESS'].includes(item.type)) {
    return;
  }
  if (item.password_is_null) {
    return {
      title: 'Connection',
      type: 'danger',
    };
  }
  if (item.type === 'WA_BUSINESS') {
    if (item.status === 'PENDING') {
      return {
        title: 'In progress',
        type: 'warning',
      };
    }
    if (item.status === 'UNVERIFIED') {
      return {
        title: 'Unverified',
        type: 'warning',
      };
    }
    if (item.status === 'ACTIVE') {
      return {
        type: 'success',
      };
    }
  }
}

export function getExtraSettings(type) {
  switch (type) {
    case 'voip':
      return {
        title: this.$t('voice.ivr_menus'),
        path: '/admin/ivr',
      };
    case 'wa_business':
      return {
        title: this.$t('whatsapp.manage_templates'),
        path: '/admin/wa_templates',
      };
    case 'email':
      return {
        title: this.$t('channels.manage_styles'),
        path: '/admin/email_themes',
      };
  }
}
