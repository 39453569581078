<template>
  <div class="header-card px-3 py-4">
    <div class="flex flex-row justify-between">
      <div class="flex flex-row">
        <div class="circle">
          <i class="mr-3 inline-flex self-center rounded"></i>
        </div>
        <slot />
      </div>
      <div v-if="senderAndDate" class="sender-and-time">
        <span>info@*company*.com</span>
        <span class="ml-3">12:43</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmailHeaderPreviewCard',
  props: {
    senderAndDate: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.header-card {
  .circle {
    line-height: 1.4;
    i {
      width: 12px;
      height: 12px;
      background: var(--color-leaf-400);
    }
  }
  .sender-and-time {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.4;
    color: var(--color-grey-500);
  }
}
</style>
