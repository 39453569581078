/**
 * What is the problem?
 * Vue-i18n is the plugin that we are using to i18n the product
 * Before that, we were using our custom method which was synced with Laravel placeholder pattern
 * What is Laravel i18n placeholder pattern?
 * Imagine that you have a translation string which includes a variable as part of it
 * Example: 'settings.test' => 'This is a test, the username is :name'
 * In the Vue i18n, ':name' pattern is not working, so then, this method is a solution
 * for the problem
 * This method will find the ':key' pattern, and will replace it with '{key}' which
 * is recognized for Vue-i18n and Laravel as well.
 * @param languageObject
 * @returns {*}
 */

function reMapColonIntoCurlyBracketInTranslation(languageObject) {
  let messages = languageObject;

  Object.entries(messages).forEach(([key, values]) => {
    if (values) {
      Object.entries(values).forEach(([k, oldValue]) => {
        let value = oldValue;
        if (typeof value === 'string') {
          if (value.includes(':')) {
            const arr = value.match(/\B:\w+/gi);
            arr?.forEach((matchedStr) => {
              let cleanMatchedStr = matchedStr.replace(':', '');
              value = value.replace(matchedStr, `{${cleanMatchedStr}}`);
            });
            messages[key][k] = value;
          }
        }
        if (typeof value === 'object') {
          messages[key] = reMapColonIntoCurlyBracketInTranslation(values);
        }
      });
    }
  });
  return messages;
}

export default reMapColonIntoCurlyBracketInTranslation;
