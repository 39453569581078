<script type="text/babel">
import dompurify from 'dompurify';

import avatar from '../Avatar.vue';
import ContentEditable from '../ContentEditable';

export default {
  props: ['profile'],

  data() {
    return {
      note: '',
      loading: false,
    };
  },

  components: {
    avatar,
    ContentEditable,
  },

  computed: {
    notes() {
      return this.profile.notes.map((note) => {
        return { ...note, ...{ note: dompurify.sanitize(note.note, { ALLOWED_TAGS: ['br'], ALLOWED_ATTR: [] }) } };
      });
    },
  },

  methods: {
    async remove(id) {
      const shouldDeleteNote = await this.$tConfirm('Are you sure you want to delete this note?', {
        delete: true,
        title: this.$t('general.are_you_sure'),
      });
      if (shouldDeleteNote) {
        this.profile.notes.splice(this.profile.notes.map((item) => item.id).indexOf(id), 1);
        axios.delete('/api/v2/profiles/' + this.profile.id + '/notes/' + id);
      }
    },

    submit() {
      this.note = this.note.replace(/&nbsp;/gi, ' ');
      this.note = this.note.replace(/\s+/gi, ' ');

      if (!this.note) {
        return;
      }

      this.loading = false;
      axios.post('/api/v2/profiles/' + this.profile.id + '/notes', { note: this.note }).then((res) => {
        this.profile.notes.unshift(res.data);
        this.loading = false;
        this.note = '';
        this.$refs.noteBody.$el.innerText = ''; // Content editable should update model values..
      });
    },

    onContentEditableBlur(value) {
      this.note = value;
    },
  },
};
</script>

<template>
  <div class="mt-6">
    <h6 class="mb-4">{{ $t('profile.notes') }}</h6>
    <form @submit.prevent="submit">
      <div class="flex rounded-lg border-2 border-grey-200">
        <div class="overflow-hidden rounded-lg" style="margin-left: -2px; margin-top: -2px">
          <avatar
            width="45"
            class="-ml-1 -mt-1"
            :color="$root.user.color"
            :abbr="$root.user.abbr"
            :image="$root.user.profile_image"
          ></avatar>
        </div>
        <div class="flex-1 p-4">
          <content-editable
            :return-plain-text="false"
            ref="noteBody"
            @enter="submit"
            v-model="note"
            id="NoteTextArea"
            rows="2"
            :placeholder="$t('notes.create_note_placeholder')"
            @blur="onContentEditableBlur"
          ></content-editable>
        </div>
      </div>
    </form>

    <div v-if="notes && notes.length > 0">
      <div v-for="note in notes" class="w-full">
        <div class="mt-2 flex w-full min-w-0 rounded-lg border-2 border-grey-200">
          <div class="overflow-hidden rounded-lg" style="flex-shrink: 0; margin-left: -2px; margin-top: -2px">
            <avatar
              width="45"
              class="-ml-1 -mt-1"
              :color="profile.color"
              :abbr="profile.abbr"
              :image="profile.profile_image"
            ></avatar>
          </div>
          <div class="w-full p-4" style="word-break: break-all" v-html="note.note"></div>
        </div>
        <div class="flex items-center text-grey-600">
          <span class="text-xs">{{ note.user.first_name }}</span>
          <span class="mx-2 text-xs"><datetime :time="note.created_at" :pretty="$root.prettyDates"></datetime></span>
          <a @click="remove(note.id)" style="padding-top: 1px"><i class="material-icons text-xs">clear</i></a>
        </div>
      </div>
    </div>
  </div>
</template>
