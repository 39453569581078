import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  style: {"height":"calc(100% - 80px)"},
  class: "flex justify-center overflow-auto"
}
const _hoisted_2 = { class: "m-auto flex max-w-md flex-col items-center justify-center gap-3 px-5" }
const _hoisted_3 = { class: "t-text-h3 text-center" }
const _hoisted_4 = { class: "t-text-md text-center text-grey-800" }
const _hoisted_5 = { class: "mb-4 mt-auto flex w-full flex-col-reverse items-stretch justify-center gap-3 md:flex-row md:items-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_log_in_linear = _resolveComponent("log-in-linear")!
  const _component_t_thumbnail = _resolveComponent("t-thumbnail")!
  const _component_t_pin_input = _resolveComponent("t-pin-input")!
  const _component_arrow_left_linear = _resolveComponent("arrow-left-linear")!
  const _component_t_button = _resolveComponent("t-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_t_thumbnail, {
        size: "large",
        class: "mb-3 flex-shrink-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_log_in_linear)
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('auth.two_factor_authentication_setup_step_two_title')), 1 /* TEXT */),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('auth.two_factor_authentication_setup_step_two_description')), 1 /* TEXT */),
      _createVNode(_component_t_pin_input, {
        value: "",
        size: 6,
        "error-message": _ctx.validationMessage,
        disabled: _ctx.isVerifying,
        autofocus: "",
        class: "mb-6",
        onComplete: _ctx.handleSubmit,
        onInput: _ctx.storeValue
      }, null, 8 /* PROPS */, ["error-message", "disabled", "onComplete", "onInput"]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_t_button, {
          size: "md",
          "btn-style": "secondary",
          onClick: _ctx.handleBack
        }, {
          default: _withCtx(() => [
            _createVNode(_component_arrow_left_linear, { class: "inline" }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('general.back')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]),
        _createVNode(_component_t_button, {
          size: "md",
          disabled: _ctx.isVerifying || _ctx.isInvalid,
          onClick: _ctx.handleContinue
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.isVerifying
              ? _ctx.$t('auth.two_factor_authentication_setup_step_two_is_verifying_label')
              : _ctx.$t('auth.two_factor_authentication_setup_step_two_verify_label')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled", "onClick"])
      ])
    ])
  ]))
}