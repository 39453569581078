<template>
  <span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.5 10.75C3.5 6.99237 6.69009 3.5 11 3.5C14.6706 3.5 17.5299 6.02777 18.2966 9.10478C17.4445 8.71638 16.4975 8.5 15.5 8.5C11.7721 8.5 8.75 11.5221 8.75 15.25C8.75 16.2294 8.95858 17.16 9.33375 18H3.88925C4.09074 17.6735 4.23767 17.3968 4.32738 17.1326C4.40254 16.9113 4.46163 16.7165 4.49476 16.5024C4.5279 16.2883 4.53045 16.0848 4.52572 15.8511C4.5198 15.5585 4.44501 15.2069 4.34169 14.8032C4.23533 14.3876 4.07898 13.8458 3.87717 13.1464L3.87717 13.1464L3.8733 13.133C3.65617 12.3804 3.5 11.5557 3.5 10.75ZM10.2557 19.5H2.5C2.2234 19.5 1.96926 19.3478 1.83875 19.1039C1.70823 18.86 1.72254 18.5641 1.87596 18.334L2.00612 18.1387C2.61025 17.2325 2.8206 16.9049 2.90703 16.6503C2.97499 16.4502 2.99974 16.3549 3.01241 16.273C3.02509 16.1911 3.03031 16.0927 3.02603 15.8814C3.024 15.7808 2.99228 15.5805 2.88853 15.1751C2.78851 14.7843 2.63856 14.2644 2.4321 13.5488C2.18997 12.7096 2 11.7393 2 10.75C2 6.17107 5.85455 2 11 2C15.9406 2 19.6898 5.83773 19.9817 10.2024C21.3733 11.4389 22.25 13.2421 22.25 15.25C22.25 16.0196 22.0629 16.7633 21.8288 17.3978C21.6199 17.9637 21.47 18.3703 21.3694 18.6736C21.2624 18.9962 21.239 19.124 21.2369 19.1621C21.2281 19.324 21.2315 19.3772 21.2367 19.4124C21.2418 19.4475 21.2537 19.4995 21.3085 19.6521C21.3272 19.7043 21.3717 19.7949 21.4956 19.9769C21.6189 20.1582 21.7905 20.3873 22.04 20.72L22.1 20.8C22.2704 21.0273 22.2979 21.3313 22.1708 21.5854C22.0438 21.8395 21.7841 22 21.5 22H15.5C13.3828 22 11.4932 21.0252 10.2557 19.5ZM20.75 15.25C20.75 12.3505 18.3995 10 15.5 10C12.6005 10 10.25 12.3505 10.25 15.25C10.25 18.1495 12.6005 20.5 15.5 20.5H20.0545C19.9925 20.3889 19.9386 20.2759 19.8965 20.1586C19.8348 19.9863 19.7801 19.8186 19.7524 19.6291C19.7247 19.4396 19.7292 19.2632 19.7391 19.0805C19.7534 18.8178 19.8417 18.5147 19.9457 18.2013C20.0551 17.8714 20.2138 17.4415 20.4163 16.8927L20.4215 16.8785C20.6177 16.3469 20.75 15.7855 20.75 15.25Z"
        class="svg-fill"
      />
    </svg>

    <span class="icon-active">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.5 10.75C3.5 6.99237 6.69009 3.5 11 3.5C14.6706 3.5 17.5299 6.02777 18.2966 9.10478C17.4445 8.71638 16.4975 8.5 15.5 8.5C11.7721 8.5 8.75 11.5221 8.75 15.25C8.75 16.2294 8.95858 17.16 9.33375 18H3.88925C4.09074 17.6735 4.23767 17.3968 4.32738 17.1326C4.40254 16.9113 4.46163 16.7165 4.49476 16.5024C4.5279 16.2883 4.53045 16.0848 4.52572 15.8511C4.5198 15.5585 4.44501 15.2069 4.34169 14.8032C4.23533 14.3876 4.07898 13.8458 3.87717 13.1464L3.8733 13.133C3.65617 12.3804 3.5 11.5557 3.5 10.75ZM10.2557 19.5H2.5C2.2234 19.5 1.96926 19.3478 1.83875 19.1039C1.70823 18.86 1.72254 18.5641 1.87596 18.334L2.00612 18.1387C2.61025 17.2325 2.8206 16.9049 2.90703 16.6503C2.97499 16.4502 2.99974 16.3549 3.01241 16.273C3.02509 16.1911 3.03031 16.0927 3.02603 15.8814C3.024 15.7808 2.99228 15.5805 2.88853 15.1751C2.78851 14.7843 2.63856 14.2644 2.4321 13.5488C2.18997 12.7096 2 11.7393 2 10.75C2 6.17107 5.85455 2 11 2C15.9406 2 19.6898 5.83773 19.9817 10.2024C21.3733 11.4389 22.25 13.2421 22.25 15.25C22.25 16.0196 22.0629 16.7633 21.8288 17.3978C21.6199 17.9637 21.47 18.3703 21.3694 18.6736C21.2624 18.9962 21.239 19.124 21.2369 19.1621C21.2281 19.324 21.2315 19.3772 21.2367 19.4124C21.2418 19.4475 21.2537 19.4995 21.3085 19.6521C21.3272 19.7043 21.3717 19.7949 21.4956 19.9769C21.6189 20.1582 21.7905 20.3873 22.04 20.72L22.1 20.8C22.2704 21.0273 22.2979 21.3313 22.1708 21.5854C22.0438 21.8395 21.7841 22 21.5 22H15.5C13.3828 22 11.4932 21.0252 10.2557 19.5Z"
          fill="white"
        />
      </svg>
    </span>
  </span>
</template>
