<template>
  <div class="relative flex flex-col rounded-lg bg-white p-4 shadow-md" data-test="attach-email-wrapper">
    <h1 class="t-text-md-emphasize mx-auto">{{ $tc('email_settings.add_email') }}</h1>

    <t-input-text
      id="emailEdit"
      v-model="email"
      class="mb-3"
      size="sm"
      placeholder="johndoe@example.com"
      data-test="sidebar-attach-email-input"
      @keydown.enter.prevent="saveEmail"
      @keydown.esc.prevent="$emit('close-email-edit')"
    />

    <div class="flex flex-row items-center justify-between">
      <t-button
        btn-style="secondary"
        data-test="sidebar-cancel-email-button"
        @click.prevent="$emit('close-email-edit')"
      >
        {{ $tc('general.cancel') }}
      </t-button>
      <t-button data-test="sidebar-save-email-button" @click.prevent="saveEmail">{{ $tc('general.save') }}</t-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { updateContactEmail } from '@/components/TicketSidebar/Api/api';
import eventBus from '@/eventBus';

import type Contact from '@/types/contact';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'AttachEmail',
  emits: ['close-email-edit'],
  props: {
    contact: {
      type: Object as PropType<Contact>,
      default: '',
    },
  },

  data() {
    return {
      email: '',
    };
  },

  mounted() {
    document.getElementById('emailEdit')?.focus();

    if (this.contact.email) {
      this.email = this.contact.email;
    }
  },

  methods: {
    async saveEmail() {
      if (this.contact.email === this.email) {
        this.$emit('close-email-edit');
        return;
      }

      try {
        await updateContactEmail(this.contact.id, this.email).then(() => {
          eventBus.$emit('ticket.current.reload', { focus: true });
          this.$emit('close-email-edit');
        });
      } catch (err) {
        console.error(err);
        this.flashWarning(err);
      }
    },
  },
});
</script>
