import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative mb-6 overflow-hidden rounded-xl before:absolute before:h-full before:w-full before:rounded-xl before:border-1 before:border-[rgba(0,0,0,0.1)]" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "t-text-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_modal = _resolveComponent("t-modal")!

  return (_openBlock(), _createBlock(_component_t_modal, {
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isOpen) = $event)),
    title: _ctx.$t('auth.two_factor_authentication_grace_period_reminder_modal_title'),
    variant: "narrow",
    onClose: _ctx.handleClose
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_t_button, {
        "btn-style": "secondary",
        onClick: _ctx.handleClose
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.two_factor_authentication_grace_period_reminder_modal_skip')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"]),
      _createVNode(_component_t_button, { onClick: _ctx.handleRedirect }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.two_factor_authentication_grace_period_reminder_modal_cta_label')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: `${
          /* @ts-ignore */
          _ctx.$root.assetsURL
        }images/2fa_enabled.gif`,
          alt: "two factor illustration"
        }, null, 8 /* PROPS */, _hoisted_2)
      ]),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('auth.two_factor_authentication_grace_period_reminder_modal_description')), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title", "onClose"]))
}