<template>
  <settings-page
    v-if="isFeatureFlagEnabled"
    :class="fullHeightInEdit"
    help-link="https://help.trengo.com/en/articles/63697-adding-an-auto-reply-to-trengo"
    :heading-title="$t('auto_replies.auto_reply')"
    :add-button-text="$t('admin_inboxes.add_auto_reply')"
    :section-title="$t('admin_inboxes.auto_replies')"
    :show-list="showItems"
    @add-button="handleAddAutoReply"
  >
    <template #title-icon>
      <reply-all-linear size="1.042rem" />
    </template>
    <template #list>
      <list-row-item
        v-for="item in auto_replies"
        :key="item.id"
        :class="{ hide: item.deleted }"
        @click="handleItemClick(item.id)"
      >
        <list-row-item-child
          :id="item.id"
          :title="item.title"
          :is-active="item.archived === 0"
          data-test-list-row-item-child="auto-replies-list-row-item-child"
          @handle-delete-item="handleDeleteItem(item.id)"
        />
      </list-row-item>

      <infinite-loading ref="infiniteScroller" :identifier="infiniteLoadingResetCounter" @infinite="fetchMoreData">
        <template #spinner>
          <div>
            <list-row-item-skeleton />
          </div>
        </template>
        <template #no-more><div></div></template>
        <template #no-results><div></div></template>
      </infinite-loading>
    </template>
    <template #content>
      <div v-if="showContent" class="row-col">
        <router-view v-if="loaded" :key="$route.params.id"></router-view>
      </div>
    </template>
  </settings-page>

  <div v-else class="row-col">
    <div>
      <span class="text-md p-y-1 white p-x-1 b-b hidden-md-up text-ellipsis">
        <a data-toggle="modal" data-target="#settings-primary" class="pull-left ml-8 lg:ml-0">
          <span class="btn btn-sm btn-icon success m-r-1">
            <i class="material-icons md-18">add</i>
          </span>
        </a>

        {{ $t('auto_replies.auto_reply_manager') }}
      </span>
    </div>
    <div class="row-col">
      <div class="row-col">
        <div id="settings-primary" class="col-xs modal fade aside aside-md b-r w-medium w-200px">
          <div class="nav-settings light left b-primary row-col">
            <ul>
              <li class="text-md white header">
                <strong>{{ $t('auto_replies.auto_reply_manager') }}</strong>
                <a
                  class="m-l-auto btn btn-icon btn-sm success rounded text-white"
                  data-toggle="tooltip"
                  data-placement="left"
                >
                  <i class="material-icons md-18" @click="$router.push('/admin/auto_replies/create')">add</i>
                </a>
              </li>
            </ul>
            <div class="row-row">
              <div class="row-body scrollable hove">
                <div class="row-inner">
                  <ul>
                    <li v-for="item in auto_replies">
                      <router-link
                        class="nav-settings-link text-truncate"
                        data-dismiss="modal"
                        :to="'/admin/auto_replies/' + item.id + ''"
                        :class="{ active: $route.params.id == item.id }"
                      >
                        <span class="float-right">
                          <label v-if="item.archived" class="label m-l-xs m-b-0">
                            {{ $t('auto_replies.archived') }}
                          </label>
                        </span>
                        {{ item.title }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-col">
          <router-view v-if="loaded" :key="$route.params.id"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ReplyAllLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';
import InfiniteLoading from 'vue-infinite-loading';

import ListRowItem from '@/components/common/ListRow/ListRowItem';
import ListRowItemSkeleton from '@/components/common/ListRowItemSkeleton';
import SettingsPage from '@/components/common/SettingsPage';
import { FEATURE_FLAG_SETTINGS } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useFeatureFlagStore } from '@/store/pinia';

import { getAutoReplies, deleteAutoReplies } from './api';
import ListRowItemChild from '../common/ListRowItemChild';

import type { AutoReply } from './types';
import type { StateChanger } from 'vue-infinite-loading';

const ITEM_LIST_PAGE_SIZE = 25;

type Data = {
  loaded: boolean;
  auto_replies: AutoReply[];
  page: number;
  infiniteLoadingResetCounter: number;
};

export default defineComponent({
  name: 'AutoReplies',
  components: {
    SettingsPage,
    InfiniteLoading,
    ListRowItemChild,
    ListRowItemSkeleton,
    ListRowItem,
    ReplyAllLinear,
  },
  data(): Data {
    return {
      loaded: false,
      auto_replies: [],
      page: 1,
      infiniteLoadingResetCounter: 0,
    };
  },
  computed: {
    fullHeightInEdit() {
      const itemsAreMoreThanFive = this.auto_replies.length > 5;
      return this.isCreateRoute() || this.$route.params.id || itemsAreMoreThanFive ? 'h-full' : '';
    },
    showItems() {
      const hasItems = this.auto_replies?.length > 0;
      return hasItems && !this.isCreateRoute() && !this.$route.params.id;
    },
    isFeatureFlagEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_SETTINGS.TA_SETTINGS_AUTOREPLY);
    },
    showContent() {
      return this.isInEditPage || this.auto_replies.length === 0;
    },
    isInEditPage() {
      return Boolean(this.$route.params.id) || this.isCreateRoute();
    },
  },
  mounted() {
    this.loadList(false);
    eventBus.$on('reload-auto-replies', () => {
      this.loadList(false);
    });
  },
  unmounted() {
    eventBus.$off('reload-auto-replies', this.loadList(false));
  },
  methods: {
    loadList(reload_root = true) {
      const hasPagination = Boolean(this.isFeatureFlagEnabled);
      this.page = 1;
      getAutoReplies(this.page, hasPagination)
        .then((result) => {
          this.auto_replies = result.data.data;
          this.loaded = true;

          if (reload_root) {
            this.$root.auto_replies = this.auto_replies;
          }
        })
        .catch((e) => {
          console.error('Error on fetching auto replies', e);
        });
    },
    async handleDeleteItem(id: number) {
      try {
        const shouldDelete = await this.$tConfirm(
          this.$t('auto_replies.are_you_sure_you_want_to_delete_this_auto_reply'),
          {
            delete: true,
            title: this.$t('general.are_you_sure'),
          }
        );

        if (!shouldDelete) {
          return;
        }
        await deleteAutoReplies(id);
        this.flashSuccess(this.$t('auto_replies.the_message_has_been_deleted_successfully'));
        this.fullscreen = false;

        const item = this.auto_replies.find((item: AutoReply) => item.id === id);
        if (item) {
          this.auto_replies.splice(this.auto_replies.indexOf(item), 1, { ...item, deleted: true });
        }
        if (this.$refs.infinteScroller) {
          const { complete, loaded, reset, error } = this.$refs.infiniteScroller.stateChanger;
          this.fetchMoreData({ complete, loaded, reset, error });
        }
      } catch (e) {
        console.error('Error when deleting AutoReply item', e);
      }
    },
    async fetchMoreData(scroll: StateChanger) {
      if (this.auto_replies?.length < ITEM_LIST_PAGE_SIZE) {
        scroll.complete();
        scroll.loaded();
        return;
      }
      if (this.page === 1) {
        this.page++;
      }
      try {
        const hasPagination = Boolean(this.isFeatureFlagEnabled);
        const result = await getAutoReplies(this.page, hasPagination);
        const newItems = 'data' in result.data ? result.data.data : result.data;

        if (newItems?.length > 0) {
          this.auto_replies = [...this.auto_replies, ...newItems];
          this.page++;
          scroll.loaded();
        } else {
          scroll.complete();
        }
      } catch (error) {
        console.error(error);
      }
    },
    handleItemClick(id: number) {
      this.$router.push(`/admin/auto_replies/${id}`);
      this.page = 1;
    },
    handleAddAutoReply(): void {
      this.$router.push('/admin/auto_replies/create');
    },
    isCreateRoute(): boolean {
      return this.$route.fullPath === '/admin/auto_replies/create';
    },
  },
});
</script>
