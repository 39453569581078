import eventBus from '@/eventBus';
export default {
  // TODO Refactor rename to gamification
  listenOnboardingSteps() {
    eventBus.$on('user-created-account', () =>
      this.$store.dispatch('gamification/completeStep', { type: 'user.create_account' })
    );
    eventBus.$on('user-assigned-first-ticket', () => {
      this.$store.dispatch('gamification/completeStep', { type: 'user.assigned_first_ticket' });
    });
    eventBus.$on('user-internal-comment-on-first-ticket', () => {
      console.log(this.$store);

      this.$store.dispatch('gamification/completeStep', { type: 'user.internal_comment_on_first_ticket' });
    });
    eventBus.$on('user-reply-conversation', () =>
      this.$store.dispatch('gamification/completeStep', { type: 'user.reply_conversation' })
    );
    eventBus.$on('ticket-archived', () =>
      this.$store.dispatch('gamification/completeStep', { type: 'user.archived_ticket' })
    );
    eventBus.$on('user-add-first-channel', () =>
      this.$store.dispatch('gamification/completeStep', { type: 'user.add_first_channel' })
    );
    eventBus.$on('user-invite-first-team-member', () =>
      this.$store.dispatch('gamification/completeStep', { type: 'user.invite_first_team_member' })
    );
    eventBus.$on('user-setup-first-web-widget', () =>
      this.$store.dispatch('gamification/completeStep', { type: 'user.setup_first_web_widget' })
    );
    // bind to events for frontend steps
    eventBus.$on('ticket-archived', () =>
      this.$store.dispatch('onboardingSteps/completeStep', { type: 'user.archived_ticket' })
    );
    eventBus.$on('ticket-quick-reply-inserted', () =>
      this.$store.dispatch('onboardingSteps/completeStep', { type: 'user.quick_reply_used' })
    );
    eventBus.$on('ticket-mention-inserted', () =>
      this.$store.dispatch('onboardingSteps/completeStep', { type: 'user.team_mate_mentioned' })
    );
    eventBus.$on('ticket-label-added', () =>
      this.$store.dispatch('onboardingSteps/completeStep', { type: 'user.label_applied' })
    );
    eventBus.$on('ticket-reminder-set', () =>
      this.$store.dispatch('onboardingSteps/completeStep', { type: 'user.reminder_added' })
    );
    eventBus.$on('ticket-favourite-set', () =>
      this.$store.dispatch('onboardingSteps/completeStep', { type: 'user.favourite_added' })
    );
    eventBus.$on('user-profile-image-set', () =>
      this.$store.dispatch('onboardingSteps/completeStep', { type: 'user.profile_image_set' })
    );
    eventBus.$on('user-phone-number-set', () =>
      this.$store.dispatch('onboardingSteps/completeStep', { type: 'user.phone_number_set' })
    );
    eventBus.$on('ticket-message-sent', () =>
      this.$store.dispatch('onboardingSteps/completeStep', { type: 'user.message_sent' })
    );
    eventBus.$on('ticket-gif-sent', () =>
      this.$store.dispatch('onboardingSteps/completeStep', { type: 'chat.gif_sent' })
    );
    eventBus.$on('ticket-email-sent', () =>
      this.$store.dispatch('onboardingSteps/completeStep', { type: 'email.sent' })
    );
    eventBus.$on('outbound-call-started', () =>
      this.$store.dispatch('onboardingSteps/completeStep', { type: 'voice.outbound_call' })
    );
    eventBus.$on('api-token-generated', () =>
      this.$store.dispatch('onboardingSteps/completeStep', { type: 'chat_api.token_created' })
    );
    eventBus.$on('api-documentation-clicked', () =>
      this.$store.dispatch('onboardingSteps/completeStep', { type: 'chat_api.documentation_viewed' })
    );
  },

  initOnboardingSteps() {
    this.stepCompleteAudio.volume = 0.2;

    // fetch resources
    this.$store.dispatch('gamification/fetchSteps').then((steps) => {
      // onboarding-steps are disabled
      if (!steps.length) {
        return;
      }

      // fetch stepsSeen
      this.$store.dispatch('gamification/fetchStepsSeen');

      // fetch agency interests
      this.$store.dispatch('onboardingSteps/fetchInterestsSelected');

      // bind to step-completed event for agency wide steps
      this.$root.agencyChannel.bind('onboarding-step-completed', (data) => {
        if (data.type) {
          this.$store.commit('gamification/completeStep', { type: data.type });
          this.showStepCompleted(data);
        }
      });

      // bind to step-completed event for user steps
      this.$root.userChannel.bind('onboarding-step-completed', (data) => {
        if (data.type) {
          this.$store.commit('gamification/completeStep', {
            type: data.type,
          });

          this.showStepCompleted(data);
        }
      });

      // bind to reload-steps event
      this.agencyChannel.bind('onboarding-steps-reload', () => {
        this.$store.dispatch('gamification/fetchSteps');
      });

      // bind to interests-updated event
      this.$root.agencyChannel.bind('onboarding-steps-set-interests', (data) => {
        this.$store.commit('onboardingSteps/setInterestsSelected', data);
      });
    });
  },

  // for debugging
  simulateOnboardingStepsCompletion() {
    this.$store.dispatch('gamification/simulateCompleteAll', { user: this.user, vm: this });
  },

  showStepCompleted(data) {
    if (data.user.id && data.user.id === this.$root.user.id) {
      eventBus.$emit('onboarding-notification', data.type);
    } else if (data.user.first_name) {
      eventBus.$emit(
        'onboarding-notification',
        this.$t('onboarding.user_completed_step', {
          name: data.user.first_name,
          step: this.$tlateOnboardingStep(data.type, 'title'),
        })
      );
    } else {
      eventBus.$emit(
        'onboarding-notification',
        this.$t('onboarding.someone_completed_step', {
          step: this.$tlateOnboardingStep(data.type, 'title'),
        })
      );
    }
  },

  translateOnboardingStep(type, prop) {
    let step = this.$store.getters['gamification/getStepByType'](type) || {};
    return (
      (step.translations[this.$root.user.locale_code] || step.translations['en-GB'] || {})[prop] || step.type || ''
    );
  },
};
