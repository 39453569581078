import axios from 'axios';

import { cachedRequest } from '@/util/request';

const apiUrl = '/api/v2';

const getPaginatedUserList = (params) => axios.get(`${apiUrl}/users`, { params });
const getFullUserList = (cacheEnabled) => cachedRequest({ method: 'get', url: '/client-api/users/list' }, cacheEnabled);
const deleteUser = (id) => axios.delete(`${apiUrl}/users/${id}`);
const sendVerification = (id) => axios.post(`${apiUrl}/users/${id}/verifications`);
const getPaginatedThreads = (page, params) =>
  axios.get(`${apiUrl}/internal_chat/index_threads?page=${page}`, { params });
const updateThreadParticipant = (id, params) => axios.put(`${apiUrl}/internal_chat/update_thread/${id}`, { ...params });
const deleteThread = (id) => axios.delete(`${apiUrl}/internal_chat/delete_thread/${id}`);

export {
  getPaginatedUserList,
  getFullUserList,
  deleteUser,
  sendVerification,
  getPaginatedThreads,
  updateThreadParticipant,
  deleteThread,
};
