<template>
  <t-modal
    v-model="isOpen"
    :close-on-backdrop-click="false"
    :close-on-esc="false"
    variant="narrow"
    @close="$emit('close', { hasCancelled: true })"
  >
    <template #header>
      <h3 v-if="isReauthenticating" class="t-text-h3 my-auto max-w-[90%] p-0">
        {{ $tc('integration_hub.reauthenticate_integration') }}
      </h3>

      <h3 v-else class="t-text-h3 my-auto max-w-[90%] p-0">
        {{ $t('integration_hub.install_modal_title', { name: integration.name }) }}
      </h3>
    </template>

    <dynamic-form-fields
      v-if="integration.form_fields.length > 0"
      :prefilled-settings="prefilledData"
      @formChanged="onFormChanged"
    />

    <div v-if="integration.form_fields.length === 0 && integration.auth_type === 'oauth2'">
      <p class="t-text-md text-grey-800" data-test="integration-description">
        {{ $t('integration_hub.install_modal_description', { name: integration.name }) }}
      </p>

      <div>
        <p class="t-text-md-emphasize">{{ $t('integration_hub.install_step_1') }}</p>
        <div class="flex gap-4">
          <div class="grid h-10 w-10 flex-none place-items-center rounded-xl bg-sky-200">
            <lock-opened-linear width="24px" height="24px" />
          </div>
          <div>{{ $t('integration_hub.install_step_1_description', { name: integration.name }) }}</div>
        </div>
      </div>

      <div class="m-t-2">
        <p class="t-text-md-emphasize">{{ $t('integration_hub.install_step_2') }}</p>
        <div class="flex gap-4">
          <div class="grid h-10 w-10 flex-none place-items-center rounded-xl bg-purple-200">
            <user-linear width="24px" height="24px" />
          </div>
          <div>{{ $t('integration_hub.install_step_2_description', { name: integration.name }) }}</div>
        </div>
      </div>
    </div>

    <template #footer>
      <t-button
        btn-style="secondary"
        data-test="done-button"
        :disabled="inProgress"
        @click="$emit('close', { hasCancelled: true })"
      >
        {{ $t('general.cancel') }}
      </t-button>

      <t-button data-test="continue-button" :disabled="inProgress" @click="install">
        <span v-if="isOAuthType">
          {{ $t('integration_hub.install_continue_button', { name: integration.name }) }}
        </span>
        <span v-else>{{ $t('integration_hub.install_integration') }}</span>
      </t-button>
    </template>
  </t-modal>
</template>

<script lang="ts">
import { LockOpenedLinear, UserLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { installIntegration, fetchAuthorization, fetchInstallation } from '@/api';
import DynamicFormFields from '@/components/IntegrationHub/Components/DynamicFormFields.vue';

import type { FormFieldWithValue } from '@/store/types/integrations';

export default defineComponent({
  name: 'IntegrationHubInstallModal',
  emits: ['close'],
  components: { LockOpenedLinear, UserLinear, DynamicFormFields },

  computed: {
    ...mapGetters({
      integration: 'integrations/getItem',
    }),

    isOAuthType() {
      return (
        this.integration.auth_type === 'oauth2' &&
        (this.integration.oauth_grant_type === 'authorization_code' || this.integration.oauth_grant_type === null)
      );
    },

    isReauthenticating() {
      return this.installationId !== 0;
    },
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },

    installationId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      formData: [],
      inProgress: false,
      prefilledData: {},
    };
  },

  watch: {
    installationId(value) {
      this.installationId = value;
    },
  },

  async mounted() {
    if (this.isReauthenticating) {
      const response = await fetchInstallation(this.integration.id, this.installationId);
      this.formData = response.data.settings;
      this.prefilledData = response.data.settings;
    }
  },

  methods: {
    onFormChanged(formData: Array<FormFieldWithValue>) {
      this.formData = formData;
    },

    async install() {
      try {
        this.inProgress = true;
        let installation = null;
        const payload = {
          name: `${this.integration.name} ${this.integration.installations.length + 1}`,
          settings: this.formData,
        };

        if (this.installationId === 0) {
          installation = await installIntegration(this.integration.id, payload);
        }

        if (this.isOAuthType) {
          let authorization;

          if (installation) {
            authorization = await fetchAuthorization(this.integration.id, installation.data.id);
          } else {
            authorization = await fetchAuthorization(this.integration.id, this.installationId);
          }

          const redirectURL = new URL(window.location.href);
          redirectURL.searchParams.delete('page');
          redirectURL.searchParams.set('referrer', 'apideck');
          window.location.href = `${authorization.data.authorize_url}&redirect_uri=${redirectURL.toString()}`;
          return;
        }

        this.inProgress = false;
        this.$emit('close', { success: true });
      } catch (err) {
        this.inProgress = false;
        console.error(err);
      }
    },
  },
});
</script>
