<template>
  <img v-bind="$attrs" :src="`${$root.assetsURL}img/channels_icons/mono/${icon}.svg`" alt="" />
</template>

<script>
import { CHANNEL_TYPE } from '@/Configs/Constants';

export default {
  name: 'BroadcastingChannelIcon',
  props: {
    type: {
      type: String,
      default: CHANNEL_TYPE.WA_BUSINESS,
      validator: (value) => [CHANNEL_TYPE.SMS, CHANNEL_TYPE.WA_BUSINESS].includes(value),
    },
  },
  computed: {
    icon() {
      const iconConvert = {
        [CHANNEL_TYPE.WA_BUSINESS]: 'whatsapp',
        [CHANNEL_TYPE.SMS]: 'sms',
      };
      return iconConvert[this.type];
    },
  },
};
</script>
