<template>
  <div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.magento1_label_your_shop_url') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.url" type="text" autocomplete="off" class="form-control" />
        <span class="form-text">{{ $t('integrations.magento1_subtitle_your_shop_url') }}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.magento1_label_shop_admin_url') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.admin_url" type="text" autocomplete="off" class="form-control" />
        <span class="form-text">{{ $t('integrations.magento1_subtitle_shop_admin_url') }}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.magento1_label_soap_username') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.api_key" type="text" autocomplete="off" class="form-control" />
        <span class="form-text">
          {{ $t('integrations.magento1_subtitle_soap_username') }}
          <!-- <a href="#" target="_blank">Learn how</a>-->
        </span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.magento1_label_soap_api_key') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.api_password" type="text" autocomplete="off" class="form-control" />
        <span class="form-text">
          {{ $t('integrations.magento1_subtitle_soap_api_key') }}
          <!-- <a href="#" target="_blank">Learn how</a>-->
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
