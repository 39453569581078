<template>
  <t-action-menu
    v-model="isMenuOpen"
    position="left"
    fade
    width="max-content"
    :trigger-on-click="false"
    :data-test="dataTestListRowActionMenu"
    @click.prevent="handleClick"
    @keyup.enter="handleClick"
  >
    <template #trigger>
      <slot name="trigger">
        <t-icon-button
          size="md"
          class="rounded-none"
          :class="{ 'bg-grey-300': isMenuOpen }"
          :data-test="dataTestListRowActionMenuTrigger"
        />
      </slot>
    </template>
    <template #content>
      <div class="flex flex-col">
        <slot name="menu-content">
          <t-list-item is-delete size="sm" :label="$t('rules.delete_button')" @click="$emit('handle-delete-item')">
            <template #prefix>
              <trash-linear size="1.25rem" />
            </template>
          </t-list-item>
        </slot>
      </div>
    </template>
  </t-action-menu>
</template>

<script>
import { TrashLinear } from '@trengo/trengo-icons';

import eventBus from '@/eventBus';

export default {
  name: 'ListRowActionMenu',
  emits: ['handle-delete-item'],
  components: { TrashLinear },
  props: {
    id: {
      type: Number,
      required: true,
    },
    dataTestListRowActionMenu: {
      type: String,
      default: 'list-row-action-menu',
    },
    dataTestListRowActionMenuTrigger: {
      type: String,
      default: 'list-row-action-menu-trigger',
    },
    dataTestDeleteButton: {
      type: String,
      default: 'delete-button',
    },
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  mounted() {
    this.id && eventBus.$on('listRowActionMenuClickOutSide', this.clickOutSide);
  },
  unmounted() {
    this.id && eventBus.$off('listRowActionMenuClickOutSide', this.clickOutSide);
  },
  methods: {
    clickOutSide(id) {
      if (id !== this.id) {
        this.close();
      }
    },
    close() {
      this.isMenuOpen = false;
    },
    handleClick(e) {
      this.id && eventBus.$emit('listRowActionMenuClickOutSide', this.id);
      if (e) {
        e.stopPropagation();
      }
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>
