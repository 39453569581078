<template>
  <div class="flex flex-row justify-end text-right">
    <p class="t-text-sm m-0 text-grey-800">{{ score }}/5</p>
    <img class="ml-3 w-[20px]" :src="getEmoji" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import AngryEmojie from './assets/angry.svg';
import HappyEmojie from './assets/happy.svg';
import NeutralEmojie from './assets/neutral.svg';
import SadEmojie from './assets/sad.svg';
import SmileEmojie from './assets/smile.svg';

import type { CSATScore } from './utils';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'TicketInsightCSAT',
  props: {
    score: {
      type: Number as PropType<CSATScore>,
      default: 1 as CSATScore,
    },
  },
  computed: {
    getEmoji() {
      const score: CSATScore = this.score;
      if (score === 1) return AngryEmojie;
      if (score === 2) return SadEmojie;
      if (score === 3) return NeutralEmojie;
      if (score === 5) return SmileEmojie;
      return HappyEmojie;
    },
  },
});
</script>
