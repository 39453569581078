import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  style: {"height":"calc(100% - 80px)"},
  class: "flex justify-center overflow-auto"
}
const _hoisted_2 = { class: "m-auto flex max-w-md flex-col items-center justify-center gap-3 px-5" }
const _hoisted_3 = { class: "t-text-h3 mb-1 text-center" }
const _hoisted_4 = { class: "t-text-md text-center text-grey-800" }
const _hoisted_5 = { class: "mb-4 flex justify-center" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "w-full md:w-96" }
const _hoisted_8 = { class: "mb-4 mt-4 flex w-full flex-col-reverse items-stretch justify-center gap-3 md:flex-row md:items-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_scan_linear = _resolveComponent("scan-linear")!
  const _component_t_thumbnail = _resolveComponent("t-thumbnail")!
  const _component_copy_linear = _resolveComponent("copy-linear")!
  const _component_t_input_text = _resolveComponent("t-input-text")!
  const _component_arrow_left_linear = _resolveComponent("arrow-left-linear")!
  const _component_t_button = _resolveComponent("t-button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_t_thumbnail, {
        size: "large",
        class: "mb-3 flex-shrink-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_scan_linear)
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('auth.two_factor_authentication_setup_step_one_title')), 1 /* TEXT */),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('auth.two_factor_authentication_setup_step_one_description')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("img", {
          src: _ctx.qr,
          class: "max-w-[160px] rounded-xl border border-solid p-1 md:h-auto md:p-2"
        }, null, 8 /* PROPS */, _hoisted_6)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_t_input_text, {
          id: "twoFactorAuthenticationSecret",
          ref: "secret",
          "model-value": _ctx.secret,
          "sub-label": _ctx.$t('auth.two_factor_authentication_setup_step_one_secret_label'),
          readonly: ""
        }, {
          end: _withCtx(() => [
            _withDirectives((_openBlock(), _createElementBlock("button", {
              class: "flex",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCopyToClipboard && _ctx.handleCopyToClipboard(...args)))
            }, [
              _createVNode(_component_copy_linear)
            ])), [
              [_directive_tooltip, {
                placement: 'top',
                content: _ctx.buttonText,
                popperClass: 'tooltip-vuetify',
                show: _ctx.isTooltipVisible,
                trigger: 'manual',
              }]
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model-value", "sub-label"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_t_button, {
          size: "md",
          "btn-style": "secondary",
          onClick: _ctx.handleBack
        }, {
          default: _withCtx(() => [
            _createVNode(_component_arrow_left_linear, { class: "inline" }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('general.back')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]),
        _createVNode(_component_t_button, {
          size: "md",
          onClick: _ctx.handleContinue
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('general.continue')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ])
    ])
  ]))
}