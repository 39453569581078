<template>
  <li class="list-none">
    <t-popover v-model="isOpen" class="relative" click-outside-close>
      <template #trigger>
        <t-list-item :label="$t('reports_v2.labels')" class="relative" :active="isOpen">
          <template #postfix>
            <t-badge v-if="labelsCount > 0" class="flex items-center" :text="labelsCount" />
            <caret-down-fill size="1.25rem" class="mt-1 -rotate-90 text-grey-600" />
          </template>
        </t-list-item>
      </template>

      <template #content>
        <div
          class="
            filter-dropdown-second-layer
            absolute
            z-50
            mt-2
            min-h-[258px]
            w-92
            rounded-xl
            border border-grey-300
            bg-white
            shadow-500
          "
        >
          <t-input-search
            key="search-teams"
            v-model="search"
            variant="primary-alt"
            class="m-2"
            @clear="search = ''"
            @input="onSearch"
          />
          <t-divider class="mb-2" />
          <ul :class="containerHeight" class="w-full overflow-x-hidden overflow-y-scroll px-2">
            <t-list-item-checkbox
              v-for="label in getLabels"
              :key="label.id"
              :model-value="selectedItems.includes(label.id)"
              :label="label.name"
              @update:modelValue="handleSelectItem(label)"
            />
          </ul>

          <results-not-found v-if="!getLabels.length" :text="noResultsFound" />

          <t-divider />
          <div class="flex justify-end px-3 py-3">
            <t-button size="sm" btn-style="secondary" class="mr-2" @click="clearFilters">
              {{ $t('reports_v2.clear') }}
            </t-button>
            <t-button size="sm" @click="applyFilters">{{ $t('reports_v2.confirm') }}</t-button>
          </div>
        </div>
      </template>
    </t-popover>
  </li>
</template>

<script lang="ts">
import { CaretDownFill } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import breakpoints from '@/util/breakpoints';

import ResultsNotFound from './ResultsNotFound.vue';
import { sortActiveElementsFirst } from './utils';

export default defineComponent({
  name: 'Teams',
  components: { ResultsNotFound, CaretDownFill },
  emits: ['filter-updated'],
  props: {
    activeLabels: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isOpen: false,
      search: '',
      selectedItems: [],
      breakpoints,
    };
  },

  beforeMount() {
    this.selectedItems = [...this.activeLabels];
  },

  computed: {
    containerHeight() {
      const heightClass = breakpoints.h < 700 ? 'filter-items-wrapper-200' : 'filter-items-wrapper-335';
      return heightClass;
    },
    noResultsFound() {
      const searchPhrasePadding = 30;
      const searchPhrase =
        this.search.length > searchPhrasePadding ? this.search.substring(0, searchPhrasePadding) + '...' : this.search;
      return this.$t('reports_v2.no_label_found', { label: searchPhrase });
    },
    getLabels() {
      return this.getOrderedLabels();
    },

    labelsCount() {
      return this.selectedItems.length;
    },
  },
  methods: {
    handleSelectItem(label: object) {
      const id = label?.id;
      if (this.selectedItems.includes(id)) {
        this.selectedItems = this.selectedItems.filter((item) => item !== id);
      } else {
        this.selectedItems.push(id);
      }
    },
    clearFilters() {
      this.selectedItems = [];
      this.applyFilters();
    },
    applyFilters() {
      this.$emit('filter-updated', { label_ids: this.selectedItems });
    },
    getUnorderedLabels() {
      if (!this.search) {
        return this.$root.labels;
      }

      return this.$root.labels.filter((label) => label.name.toLowerCase().includes(this.search.toLowerCase()));
    },
    getOrderedLabels() {
      const labels = this.getUnorderedLabels();

      return sortActiveElementsFirst(labels, this.selectedItems);
    },
    onSearch(value: string) {
      this.search = value;
    },
  },
});
</script>
