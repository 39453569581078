import { toArray } from 'lodash';
import Tribute from 'tributejs';

import { escapeHtml } from './stringHelpers';

// if not cached, Tribute will send a new request when typing (onchange)
const cacheUsers = true;

let users = [];

export default {
  userIdsWithAccess: [],

  /**
   * @param user
   * @returns {string}
   */
  userToMention(user) {
    return '@' + escapeHtml(user.first_name.toLowerCase().replace(/[^a-zA-Z]+/g, '')) + user.id;
  },

  /**
   * @param el
   * @param ticketId
   * @param allUsers
   * @returns {Tribute<{}>}
   */
  initUsersTaggable(el, ticketId, allUsers) {
    this.tribute = new Tribute({
      selectTemplate: (item) => {
        if (typeof item === 'undefined' || item.original.loading) {
          return null;
        }
        return (
          ' <a class="text-info" contenteditable="false">' +
          this.userToMention(item.original) +
          '</a><span class="remove-prev"></span> '
        );
      },
      menuItemTemplate: (item) => {
        if (item.original.loading) {
          return '<span style="font-weight:normal;opacity:.7;font-style:italic"><i class="fa spin fa-circle-o-notch ml-3"></i></span>';
        }
        let user = item.original;
        let style = !this.userIdsWithAccess.includes(user.id) ? 'opacity:.5;font-style:italic' : '';
        if (user.profile_image == null) {
          return (
            '<span><span class="user-image" style="background:' +
            window.stripHtmlAttribute(user.color) +
            ';">' +
            escapeHtml(user.abbr) +
            '</span><span style="font-weight:normal;' +
            style +
            '">' +
            escapeHtml(user.full_name) +
            '</span></span>'
          );
        }
        return (
          '<span><span class="user-image" style="line-height: 1"><img style="width:30px;height:30px;" src="' +
          window.stripHtmlAttribute(user.profile_image) +
          '"></span><span style="font-weight:normal;' +
          style +
          '">' +
          escapeHtml(user.full_name) +
          '</span></span>'
        );
      },
      values: (text, cb) => {
        if (users[ticketId] != null && cacheUsers) {
          return cb(users[ticketId]);
        }
        cb([
          {
            loading: true,
          },
        ]);
        axios.get('/api/v2/users?ticket=' + ticketId).then((r) => {
          this.userIdsWithAccess = r.data.map((u) => u.id);
          users[ticketId] = _.sortBy(
            toArray(
              allUsers.map((user) => {
                return {
                  profile_image: user.profile_image,
                  color: user.color,
                  id: user.id,
                  first_name: user.first_name,
                  last_name: user.last_name,
                  full_name: user.name,
                  abbr: user.abbr,
                  key: user.full_name,
                  value: user.full_name,
                  has_access_to_ticket: this.userIdsWithAccess.includes(user.id),
                };
              })
            ),
            'has_access_to_ticket'
          ).reverse();

          return cb(users[ticketId]);
        });
      },
      lookup: 'full_name',
    });

    this.tribute.attach(el);

    return this.tribute;
  },

  /**
   * @param el
   * @param ticketId
   * @param allUsers
   * @returns {Tribute<{}>}
   */
  reInit(el, ticketId, allUsers) {
    this.tribute.detach(el);

    users[ticketId] = null;

    this.initUsersTaggable(el, ticketId, allUsers);

    return this.tribute;
  },
};
