<script type="text/babel">
import { includes, map, sortBy } from 'lodash';

import BackHeader from '@/components/common/PageHeaders/BackHeader';
import MobileStatus from '@/components/Reporting/Pages/MobileStatus.vue';
import breakpoints from '@/util/breakpoints';

import Grid from '../Components/Grid';
import GridItem from '../Components/GridItem';
import IconBlock from '../Components/IconBlock';
import PageContainer from '../Components/PageContainer';
import PageTitle from '../Components/PageTitle';
import Panel from '../Components/Panel';
import UserPanel from '../Components/UserPanel';

export default {
  data: function () {
    return {
      filters: {},
      activeUserOnlineStatusTab: 'users',
      workload: {},
      refreshTimer: null,
      activeEfficiencyTab: 'workload',
      users: [],
      usersCountbadge: 0,
      breakpoints,
    };
  },

  beforeMount() {
    this.activeEfficiencyTab = this.isNewReportingUrl ? 'availability' : 'workload';
  },

  components: { UserPanel, PageContainer, IconBlock, PageTitle, Panel, Grid, GridItem, BackHeader, MobileStatus },

  computed: {
    isMobile() {
      return this.breakpoints.w <= 991;
    },
    isNewReportingUrl() {
      return this.$route.matched.some(({ path, name }) => {
        return (
          (path.includes('/reports/agents_availability') && name === 'reportingAgentsAvailability') ||
          name === 'reportingAgentsAvailability'
        );
      });
    },

    onlineUsersList() {
      return sortBy(
        this.users.filter((u) => u.is_online),
        'full_name'
      );
    },
    offlineUsersList() {
      return sortBy(
        this.users.filter((u) => !u.is_online),
        'full_name'
      );
    },
    teamsList() {
      return sortBy(this.$root.teams, 'name');
    },
    usersCount() {
      return this.users.length;
    },
    teamsCount() {
      return this.$root.teams.length;
    },
    onlineChatUsersCount() {
      return this.users.filter((u) => u.user_status === 'ONLINE' && u.is_online).length;
    },
    onlineVoipUsersCount() {
      return this.users.filter((u) => u.voip_status === 'ONLINE' && u.is_online).length;
    },
    computedEfficiencyTabs() {
      const workloadTab = {
        title: this.$t('reports.availability_tab_title_workload'),
        type: 'workload',
      };

      const tabs = [
        {
          title: this.$t('reports.availability_tab_title_availability'),
          type: 'availability',
          show: this.$root.hasAcccessToChatChannel || this.$root.hasAcccessToVoipChannel,
        },
      ];

      if (!this.isNewReportingUrl) {
        tabs.push(workloadTab);
      }
      return tabs.filter((tab) => tab.show || !tab.hasOwnProperty('show'));
    },
    computedUserOnlineStatusTabs() {
      const teamsTab = {
        title: this.$t('reports.availability_tab_title_teams'),
        type: 'teams',
        badge: this.teamsCount,
        show: this.$root.hasAcccessToChatChannel || this.$root.hasAcccessToVoipChannel,
      };

      const tabs = [
        {
          title: this.$t('reports.availability_tab_title_users'),
          type: 'users',
          badge: this.usersCountbadge,
        },
      ];

      if (!this.isNewReportingUrl) {
        tabs.push(teamsTab);
      }
      return tabs.filter((tab) => tab.show || !tab.hasOwnProperty('show'));
    },
  },

  async mounted() {
    await this.fetchUsers();
    this.fetchWorkload();
    this.setRefreshTimer();
  },

  unmounted() {
    clearInterval(this.refreshTimer);
  },

  methods: {
    async fetchUsers() {
      let result = await axios.get('/client-api/users/list');
      this.users = result.data.users;
      this.usersCountbadge = this.users.length;
    },
    usersInTeam(team_id) {
      return sortBy(
        this.users.filter((u) => includes(map(u.teams, 'id'), team_id)),
        'full_name'
      );
    },
    fetchWorkload() {
      axios.get('/api/v2/reports/workload').then((res) => {
        this.workload = res.data;
      });
    },
    setRefreshTimer() {
      this.refreshTimer = setInterval(() => this.fetchWorkload(), 10000);
    },
    toggleActiveTab(tab) {
      const tabTitle = tab.type.toLowerCase();

      if (tabTitle === 'users' || tabTitle === 'teams') {
        this.activeUserOnlineStatusTab = tabTitle;
      } else {
        this.activeEfficiencyTab = tabTitle;
      }
    },

    handleBackButtonClick() {
      this.$router.push('/reports');
    },
  },
};
</script>

<template>
  <mobile-status v-if="isMobile" data-test="reporting-mobile" />
  <page-container v-else class="w-full overflow-auto">
    <hero v-if="!isNewReportingUrl" class="my-6">
      <template #top>
        <page-title>{{ $t('reports.availability_title') }}</page-title>
      </template>
      <template #body>{{ $t('reports.availability_title_sub_text') }}</template>
    </hero>
    <back-header
      v-else
      class="mb-4 mt-6 px-6 xs:pl-6 xs:pr-0"
      :title="$t('reports_v2.online_status')"
      @click="handleBackButtonClick"
    />

    <div class="mb-8 rounded-lg bg-white p-8">
      <div class="flex items-center border-b-2 border-grey-200 font-bold">
        <div v-if="computedEfficiencyTabs" class="mr-auto flex">
          <t-tab-item
            v-for="tab in computedEfficiencyTabs"
            :key="tab.title"
            size="sm"
            :is-active="tab.type.toLowerCase() === activeEfficiencyTab"
            :label="tab.title"
            @click="toggleActiveTab(tab)"
          />
        </div>
        <div v-if="activeEfficiencyTab === 'workload'" class="text-xs font-normal leading-none text-grey-500">
          {{ $t('reports.availability_refreshes_every_30_seconds') }}
        </div>
      </div>
      <grid v-if="activeEfficiencyTab === 'workload'" direction="row" class="-mx-2 flex-wrap">
        <grid-item class="mt-4 w-full px-2 lg:w-1/2 xl2:my-4 xl2:w-1/4">
          <panel>
            <template #left>
              <icon-block color="#F9F4DB">inbox</icon-block>
            </template>
            <template #body>
              <div class="">
                <div class="flex flex-col leading-none">
                  <div class="mb-2 font-bold text-black">{{ $t('reports.availability_new_conversations') }}</div>
                  <div class="text-black">{{ workload.new_tickets_count }}</div>
                </div>
              </div>
            </template>
          </panel>
        </grid-item>
        <grid-item class="mt-4 w-full px-2 lg:w-1/2 xl2:my-4 xl2:w-1/4">
          <panel>
            <template #left>
              <icon-block color="#C9F5FF">people</icon-block>
            </template>
            <template #body>
              <div class="">
                <div class="flex flex-col leading-none">
                  <div class="mb-2 font-bold text-black">{{ $t('reports.availability_assigned_conversations') }}</div>
                  <div class="text-black">{{ workload.assigned_tickets_count }}</div>
                </div>
              </div>
            </template>
          </panel>
        </grid-item>
        <grid-item v-if="$root.hasAcccessToVoipChannel" class="mt-4 w-full px-2 lg:w-1/2 xl2:my-4 xl2:w-1/4">
          <panel>
            <template #left>
              <icon-block color="#F9F4DB">call</icon-block>
            </template>
            <template #body>
              <div class="">
                <div class="flex flex-col leading-none">
                  <div class="mb-2 font-bold text-black">{{ $t('reports.availability_calls_in_queue') }}</div>
                  <div class="text-black">{{ workload.queued_phone_calls_count }}</div>
                </div>
              </div>
            </template>
          </panel>
        </grid-item>
        <grid-item v-if="$root.hasAcccessToVoipChannel" class="mt-4 w-full px-2 lg:w-1/2 xl2:w-1/4">
          <panel>
            <template #left>
              <icon-block color="#C9F5FF">call</icon-block>
            </template>
            <template #body>
              <div class="">
                <div class="flex flex-col leading-none">
                  <div class="mb-2 font-bold text-black">{{ $t('reports.availability_calls_in_progress') }}</div>
                  <div class="text-black">{{ workload.phone_calls_in_progress_count }}</div>
                </div>
              </div>
            </template>
          </panel>
        </grid-item>
      </grid>

      <grid v-if="activeEfficiencyTab === 'availability'" direction="row" class="-mx-2 flex-wrap">
        <grid-item class="mt-4 w-full px-2 xl:w-1/4 xl2:my-4">
          <panel>
            <template #left>
              <icon-block color="#C6FFF4">login</icon-block>
            </template>
            <template #body>
              <div class="">
                <div class="flex flex-col leading-none">
                  <div class="mb-2 font-bold text-black">{{ $t('reports.availability_users_logged_in') }}</div>
                  <div class="text-black">
                    {{
                      $t('reports.availability_number_users_logged_in_online', {
                        onlineUserNumber: onlineUsersList.length,
                      })
                    }}
                  </div>
                </div>
              </div>
            </template>
          </panel>
        </grid-item>
        <grid-item v-if="$root.hasAcccessToChatChannel" class="mt-4 w-full px-2 xl:w-1/4 xl2:my-4">
          <panel>
            <template #left>
              <icon-block color="#F9F4DB">chat_bubble</icon-block>
            </template>
            <template #body>
              <div class="">
                <div class="flex flex-col leading-none">
                  <div class="mb-2 font-bold text-black">{{ $t('reports.availability_available_for_chat') }}</div>
                  <div class="text-black">
                    {{
                      $tc('reports.availability_chat_users', onlineChatUsersCount, { userCount: onlineChatUsersCount })
                    }}
                  </div>
                </div>
              </div>
            </template>
          </panel>
        </grid-item>
        <grid-item v-if="$root.hasAcccessToVoipChannel" class="mt-4 w-full px-2 xl:w-1/4 xl2:my-4">
          <panel>
            <template #left>
              <icon-block color="#C9F5FF">call</icon-block>
            </template>
            <template #body>
              <div class="">
                <div class="flex flex-col leading-none">
                  <div class="mb-2 font-bold text-black">{{ $t('reports.availability_chat_available_for_voice') }}</div>
                  <div class="text-black">
                    {{
                      $tc('reports.availability_voip_users', onlineVoipUsersCount, { userCount: onlineVoipUsersCount })
                    }}
                  </div>
                </div>
              </div>
            </template>
          </panel>
        </grid-item>
      </grid>
      <!--      <grid direction="row" class="flex-wrap -mx-2" v-if="activeTab === 'voice'"></grid>-->
    </div>

    <div class="mb-8 rounded-lg bg-white p-8">
      <div class="mb-4 flex items-center border-b-2 border-grey-200 font-bold">
        <div v-if="computedUserOnlineStatusTabs && usersCountbadge" class="flex flex-row">
          <t-tab-item
            v-for="tab in computedUserOnlineStatusTabs"
            :key="tab.title"
            :is-active="tab.type.toLowerCase() === activeUserOnlineStatusTab"
            :label="tab.title"
            @click="toggleActiveTab(tab)"
          >
            <template v-if="tab.badge" #postfix>
              <t-badge :text="tab.badge" />
            </template>
          </t-tab-item>
        </div>
      </div>
      <template v-if="activeUserOnlineStatusTab === 'users'">
        <page-title size="14px">
          {{ $t('reports.availability_users_online_usercount', { userCount: onlineUsersList.length }) }}
        </page-title>
        <grid direction="row" class="-mx-2 mt-4 flex-wrap">
          <grid-item v-for="user in onlineUsersList" :key="user.id" class="mb-4 w-full px-2 lg:w-1/2 xl2:w-1/4">
            <user-panel :initial-user="user"></user-panel>
          </grid-item>
        </grid>
        <page-title size="14px">
          {{ $t('reports.availability_users_offline_usercount', { userCount: offlineUsersList.length }) }}
        </page-title>
        <grid direction="row" class="-mx-2 mt-4 flex-wrap">
          <grid-item v-for="user in offlineUsersList" :key="user.id" class="mb-4 w-full px-2 lg:w-1/2 xl2:w-1/4">
            <user-panel :initial-user="user"></user-panel>
          </grid-item>
        </grid>
      </template>
      <template v-if="activeUserOnlineStatusTab === 'teams'">
        <div v-for="team in teamsList">
          <template v-if="(usersInTeam(team.id) || []).length">
            <page-title size="14px">{{ team.name }} ({{ usersInTeam(team.id).length }})</page-title>
            <grid direction="row" class="-mx-2 mt-4 flex-wrap">
              <grid-item
                v-for="user in usersInTeam(team.id)"
                :key="user.id"
                class="mb-4 w-full px-2 lg:w-1/2 xl2:w-1/4"
              >
                <user-panel :initial-user="user"></user-panel>
              </grid-item>
            </grid>
          </template>
        </div>
      </template>
    </div>
  </page-container>
</template>
