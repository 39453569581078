<template>
  <div
    class="flex flex-col rounded-md border-2 border-grey-300 border-opacity-30 p-1"
    data-test="time-entry-card-wrapper"
  >
    <div class="flex flex-row items-center">
      <avatar v-if="user" class="mr-2" width="24" :color="user.color" :abbr="user.abbr" :image="user.profile_image" />
      <avatar v-else class="mr-2" width="24" color="#000000" abbr="U" />

      <p class="t-text-sm-emphasize m-0 mr-2 p-0 text-grey-700" data-test="time-entry-card-time">
        {{ `${parsedTime.hours}H ${parsedTime.minutes}M` }}
      </p>
      <p class="t-text-sm-emphasize m-0 p-0 text-grey-700" data-test="time-entry-parsed-date">
        {{ parseDate(entry.start_time) }}
      </p>

      <div class="ml-auto flex flex-row">
        <button
          class="mr-1 text-grey-500 transition-colors hover:text-grey-700 active:text-grey-800"
          :class="isEditing ? 'text-grey-800' : ''"
          data-test="edit-entry-button"
          @click.prevent="isEditing = !isEditing"
        >
          <edit3-linear v-if="!isEditing" size="1.2rem" />
          <edit3-fill v-else size="1.2rem" />
        </button>

        <button
          class="text-grey-500 transition-colors hover:text-grey-700 active:text-grey-800"
          data-test="time-entry-delete-button"
          @click.prevent="$emit('delete-entry')"
        >
          <trash-linear size="1.2rem" />
        </button>
      </div>
    </div>

    <div v-if="entry.description" class="pl-[24px]">
      <p class="t-text-sm m-0 ml-1 mt-1 p-0 text-grey-700" data-test="time-entry-description">
        {{ entry.description }}
      </p>
    </div>

    <div v-if="isEditing">
      <time-entry
        class="mt-2"
        :is-editing="true"
        :ticket-id="ticketId"
        :entry-id="entry.id"
        :edit-date="entry.start_time"
        :edit-hours="parseInt(parsedTime.hours)"
        :edit-minutes="parseInt(parsedTime.minutes)"
        :edit-description="entry.description"
        @edit-entry="editEntry"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { TrashLinear, Edit3Linear, Edit3Fill } from '@trengo/trengo-icons';
import moment from 'moment/moment';
import { defineComponent } from 'vue';

import Avatar from '@/components/Avatar.vue';
import TimeEntry from '@/components/TicketSidebar/TimeTracking/TimeEntry.vue';
import { formatDuration } from '@/util/stringHelpers';

import type { User } from '@/types';
import type Tickets from '@/types/tickets';
import type TimeEntryType from '@/types/timeEntry';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'TimeEntryCard',
  emits: ['delete-entry', 'edit-entry'],
  components: { TimeEntry, Avatar, TrashLinear, Edit3Linear, Edit3Fill },

  props: {
    entry: {
      type: Object as PropType<TimeEntryType>,
      default: () => ({}),
    },
    userId: {
      type: Number as PropType<User['id']>,
      default: 0,
    },
    ticketId: {
      type: Number as PropType<Tickets['id']>,
      default: 0,
    },
  },

  data() {
    return {
      isEditing: false,
    };
  },

  computed: {
    user(): User | null {
      if (!this.userId || !this.$root.users) {
        return null;
      }

      const user = this.$root.users.find((user) => user.id === this.userId);

      return user ? user : null;
    },

    parsedTime() {
      const diff = moment(this.entry?.end_time * 1000).diff(moment(this.entry?.start_time * 1000), 'milliseconds');

      return formatDuration(diff);
    },
  },

  methods: {
    parseDate(time: TimeEntryType['start_time']) {
      return moment(time * 1000).format('ddd, DD/MM/YYYY');
    },

    editEntry(entry: TimeEntryType) {
      this.$emit('edit-entry', entry);
      this.isEditing = false;
    },
  },
});
</script>
