import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-bb79e728"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative mt-6 h-88" }
const _hoisted_2 = {
  ref: "report",
  height: "200",
  class: "h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_status = _resolveComponent("error-status")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("canvas", _hoisted_2, null, 512 /* NEED_PATCH */),
    (_ctx.showError)
      ? (_openBlock(), _createBlock(_component_error_status, {
          key: 0,
          "icon-name": "WalletMoveLinear",
          title: _ctx.$t('reports_v2.no_data_available'),
          "sub-title": _ctx.$t('reports_v2.api_failed'),
          classes: " h-[calc(100%-100px)]\n      w-[calc(100%-56px)]\n      min-[900px]:h-[calc(100%-97px)]\n      min-[1100px]:h-[calc(100%-95px)]\n      min-[1200px]:h-[calc(100%-93px)]\n      min-[1300px]:h-[calc(100%-92px)]\n      min-[1400px]:h-[calc(100%-77px)]\n      min-[1500px]:h-[calc(100%-77px)]"
        }, null, 8 /* PROPS */, ["title", "sub-title"]))
      : _createCommentVNode("v-if", true)
  ]))
}