<template>
  <div
    id="emojiPicker"
    class="emoji-browser-chat"
    style="line-height: 1.5"
    :style="{ maxHeight: maxHeight, height: maxHeight, width: width }"
  >
    <div v-if="showEmojiPicker" class="dropdown-overlay" @click="close"></div>
    <div class="flex" style="padding-right: 10px; margin-top: 2px">
      <input
        ref="search"
        type="text"
        :value="keyword"
        class="form-control emoji-keyword"
        :placeholder="$t('tickets.type_comment_search_emoji')"
        @input="(e) => (keyword = e.target.value)"
      />
      <i class="material-icons emoji-picker-close" @click="close">keyboard_arrow_down</i>
    </div>
    <div class="panel-emojis">
      <div v-for="(list, cat, index) in filteredEmojis" :key="cat">
        <div v-if="!keyword && index === 0 && cat && currentUser && currentUser?.favouriteReactions?.length > 0">
          <div class="emoji-category-title">Favourite emojis</div>
          <span
            v-for="(emojiCode, i) in currentUser.favouriteReactions"
            :key="i"
            @click="insertEmoji(findEmoji(emojiCode), '', '')"
          >
            {{ findEmoji(emojiCode) }}
          </span>
        </div>
        <div class="emoji-category-title">{{ cat }}</div>
        <div>
          <span
            v-for="(emoji, title) in list"
            :key="title"
            class="emoji"
            :title="replaceUnderscores(title)"
            @click="insertEmoji(emoji, cat, title)"
          >
            <template v-if="Array.isArray(emoji)">
              {{ emoji[0] }}
            </template>
            <template v-else>
              {{ emoji }}
            </template>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapValues, pickBy } from 'lodash';

import emojiList from '../../../util/emoji';

export default {
  name: 'EmojiPicker',
  emits: ['insertEmoji', 'hideEmojiPicker'],
  props: {
    currentUser: {
      type: Object,
      default: () => ({}),
    },
    maxHeight: {
      type: String,
      default: '313px',
    },
    width: {
      type: String,
      default: '423px',
    },
    showEmojiPicker: {
      type: Boolean,
      default: false,
    },
    buttonRef: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      emojis: [],
      keyword: '',
    };
  },

  mounted() {
    if (document.body.clientWidth > 767) {
      window.addEventListener('resize', this.open);
    }
    if (document.body.clientWidth > 991) {
      this.$refs.search.focus();
    }

    setTimeout(() => {
      this.load();
    }, 100);
    if (this.showEmojiPicker) {
      this.open();
    }
  },

  computed: {
    filteredEmojis() {
      // emoji.json

      // let currentGroup = '';
      // let emojisObject = {};

      // this.emojis.forEach(e => {
      //     if (!e.name.includes('skin tone')) {
      //         if (e.group !== currentGroup) {
      //             emojisObject[e.group] = {};
      //             currentGroup = e.group;
      //         }
      //         emojisObject[e.group][e.name.replaceAll(' ', '_')] = e.char;
      //     }
      // });

      if (!this.keyword) {
        return this.emojis;
      } else {
        const titleIncludesKeyword = (list) => {
          return pickBy(list, (emoji, title) => title.includes(this.keyword.replace(/ /g, '_').toLowerCase()));
        };
        return pickBy(
          mapValues(this.emojis, (list) => {
            return titleIncludesKeyword(list);
          }),
          (list) => Object.keys(list).length
        );
      }
    },
  },

  methods: {
    findEmoji(emoji) {
      return String.fromCharCode(...emoji.split('.'));
    },

    insertEmoji(emoji, cat, title) {
      emoji = {
        category: cat,
        title: title,
        emoji: emoji,
      };

      this.$emit('insertEmoji', emoji);
      this.close();
    },

    load() {
      this.emojis = emojiList;
    },

    open() {
      this.inited = true;

      let dropdown = document.getElementById('emojiPicker');
      dropdown.style.display = 'block';

      let v = this.buttonRef;

      if (!v) {
        return;
      }

      let rect = v.getBoundingClientRect();

      let pos = {
        top: rect.top + document.body.scrollTop + v.clientHeight,
        bottom: document.body.clientHeight - (rect.top + document.body.scrollTop),
        left: rect.left + document.body.scrollLeft,
        right: document.body.clientWidth - (rect.left + document.body.scrollLeft),
      };

      let offsetEmojiPicker = 14;
      if (window.innerWidth <= 767) {
        offsetEmojiPicker = 0;
      }

      let offsetRight = document.body.clientWidth - pos.left - rect.width - this.width.replace('px', '');
      let offsetTop = rect.top + document.body.scrollTop - offsetEmojiPicker - this.maxHeight.replace('px', '');

      if (window.innerWidth <= 767) {
        dropdown.style.bottom = '0';
      } else if (offsetTop > 0) {
        dropdown.style.bottom = window.innerHeight - rect.top + offsetEmojiPicker + 'px';
      } else {
        dropdown.style.bottom =
          pos.bottom - v.clientHeight - this.maxHeight.replace('px', '') - offsetEmojiPicker + 'px';
      }

      if (offsetRight < 0) {
        dropdown.style.right = pos.right + 'px';
        dropdown.style.left = 'auto';
        if (window.innerWidth <= 767) {
          dropdown.style.right = '0';
        }
      } else {
        dropdown.style.left = pos.left + rect.width + 'px';
      }
    },

    close() {
      window.removeEventListener('resize', this.open);
      if (this.inited) {
        this.$emit('hideEmojiPicker');
      }
    },
    replaceUnderscores(v) {
      let text = v.replace(/_/g, ' ');
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
  },
};
</script>

<style lang="scss">
.emoji-browser-chat {
  background: var(--color-white);
  z-index: 9999999999;
  border-radius: 6px;
  color: #9b9b9b;
  border-top: 0;
  padding: 10px 0 10px 10px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 34%);

  .emoji-keyword {
    margin-bottom: 5px;
    border-radius: 500px;
    color: #000;
    background-color: var(--color-grey-100);
    border: none;
  }
  .emoji-picker-close {
    font-size: 35px;
    cursor: pointer;
    margin-left: 8px;
    transition: color 100ms ease-out;

    &:hover {
      color: #565656;
      transition: color 120ms ease-in;
    }
  }
  .panel-emojis {
    height: 260px;
    padding-top: 2px;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
  }
  .emoji-category-title {
    font-weight: bold;
    color: var(--color-grey-900);
    font-size: 15px;
    margin-top: 8px;
    margin-bottom: 2px;
    line-height: 1.5;
  }
  .emoji-category {
    flex: 1;
    margin-bottom: 10px;
  }
  .emoji,
  span {
    font-size: 22px;
    cursor: pointer;
    padding: 0 0px;
    line-height: 1.4;
  }
}
.dropdown-overlay {
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: fixed;
  z-index: -10;
}

@media (max-width: 767px) {
  .emoji-browser-chat {
    border-radius: 12px 12px 0 0;
    width: 100% !important;
    left: 0 !important;
  }
}
</style>
