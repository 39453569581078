export const getIsStorageTranslationAvailable = ({
  ticketId,
  messageId,
  message,
  locale,
}: {
  ticketId: number;
  messageId: number;
  message: string;
  locale?: string;
}) => {
  const item = sessionStorage.getItem('inbound_translations');
  if (item) {
    const translations = JSON.parse(item);
    const translation = translations.find(
      (translation: { id: string }) => translation?.id === `${ticketId}_${messageId}_${locale}_translated`
    );

    return message === translation?.translated;
  }
};
