<template>
  <div class="empty-template-msg">
    <h5><slot name="title" /></h5>
    <p><slot name="message" /></p>
    <t-button v-if="showButton" :type="buttonType" :size="size" @click="$emit('handle-click-action')">
      <slot name="action"></slot>
    </t-button>
  </div>
</template>

<script>
export default {
  emits: ['handle-click-action'],
  props: {
    buttonType: {
      type: String,
      default: 'button',
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'sm',
    },
  },
};
</script>

<style lang="scss" scoped src="./EmptyMsg.scss" />
