<template>
  <div>
    <div>
      <div class="mb-2 mt-2 font-bold">{{ $t('flowbot.step_delay_delay_in_seconds') }}</div>
      <input v-model="element.meta.delay_in_seconds" type="number" min="0" max="60" class="form-control" />
    </div>
    <div class="mt-4">
      <div class="mb-2 mt-2 font-bold">{{ $t('flowbot.step_delay_typing_event') }}</div>
      <label for="send_typing" class="valign-center">
        <label class="ui-switch success m-t-sm m-r">
          <input id="send_typing" v-model="element.meta.send_typing" type="checkbox" />
          <i></i>
        </label>
        <div class="mt-1 inline-block">{{ $t('flowbot.step_delay_send_is_typing_event') }}</div>
      </label>
      <span class="form-text">{{ $t('flowbot.step_delay_typing_event_not_supported_on_all_channels') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['element'],
};
</script>
