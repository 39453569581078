<template>
  <div
    role="button"
    aria-pressed="false"
    :class="[
      !disabled ? enableCopy : disableCopy,
      'ml-3 cursor-pointer rounded-full border-1 border-grey-200 px-6 py-2 lg:px-8 lg:py-4',
    ]"
    @click="handleCopyClicked"
  >
    <span class="font-semibold">
      <i :class="[!disabled ? enableCopyText : disableCopyText, computedMargin]" class="far fa-copy" />
      <span :class="copyBtnClass">{{ $t('email_settings.copy') }}</span>
    </span>
  </div>
</template>

<script>
import breakpoints from '@/util/breakpoints';

export default {
  name: 'CopyBtn',
  emits: ['click'],
  data() {
    return {
      breakpoints,
      enableCopy: 'bg-white hover:shadow-md ',
      disableCopy: 'bg-white',
      enableCopyText: 'text-grey-900',
      disableCopyText: 'text-grey-500',
    };
  },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    data: {
      type: String,
      default: '',
    },
  },
  computed: {
    copyBtnClass() {
      return [!this.disabled ? this.enableCopyText : this.disableCopyText, 'hidden lg:inline-block'];
    },
    computedMargin() {
      return { 'mr-2': this.isLgOrXl() };
    },
  },
  methods: {
    handleCopyClicked() {
      if (!this.disabled) {
        this.isCopied = true;
        this.copyReceivingAddress();
        this.copyButtonInterval = setTimeout(
          function () {
            this.isCopied = false;
          }.bind(this),
          2000
        );
      }
    },
    copyReceivingAddress() {
      this.$emit('click');
      copyToClipboard(this.data);
    },
    isLgOrXl() {
      const { comparisons } = this.breakpoints;
      return comparisons.eq_lg || comparisons.gt_lg;
    },
  },
};
</script>
