<template>
  <settings-base-layout :scroll="false" class="flex w-full flex-1 flex-col overflow-scroll bg-grey-100">
    <template #header>
      <router-view name="Header" />
    </template>

    <template #default>
      <portal to="sidebar">
        <integration-hub-sidebar :integrations="integrations" />
      </portal>
      <router-view />
    </template>
  </settings-base-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import SettingsBaseLayout from '@/components/common/SettingsBaseLayout/SettingsBaseLayout.vue';
import IntegrationHubSidebar from '@/components/IntegrationHub/Elements/Sidebar.vue';

export default defineComponent({
  name: 'IntegrationHubIndex',

  components: {
    SettingsBaseLayout,
    IntegrationHubSidebar,
  },

  computed: {
    ...mapGetters({
      integrations: 'integrations/getIntegrations',
    }),
  },

  async created() {
    await this.$store.dispatch('integrations/fetchIntegrations');
  },
});
</script>
