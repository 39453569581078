<template>
  <div class="w-full rounded-xl border border-grey-300 bg-white p-6">
    <div class="flex flex-row items-center justify-between">
      <div class="flex flex-row items-center">
        <t-thumbnail class="mr-3 bg-white">
          <chart-column-linear />
        </t-thumbnail>
        <div class="flex flex-col">
          <p class="t-text-h5 m-0 text-grey-800">{{ $t('reports_v2.conversation_volume_title') }}</p>
          <p class="t-text-sm m-0 flex text-grey-600">{{ $t('reports_v2.conversation_volume_subtitle') }}</p>
        </div>
      </div>
    </div>
    <div data-test="overview-chart" class="relative mt-6 h-88">
      <average-ticket-created-loaded
        v-if="isLoaded"
        :conversations-volume-today="conversationsVolumeToday"
        :conversations-volume-avg="conversationsVolumeAvg"
        :show-error="showError"
      />
      <average-ticket-created-loading v-else />
    </div>
  </div>
</template>

<script lang="ts">
import { ChartColumnLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import {
  fetcAverageConversationsVolumeLast4weeks,
  fetchConversationsVolumeToday,
} from '@/components/Reporting/Pages/V2/Overview/api';

import AverageTicketCreatedLoaded from './AverageTicketCreatedLoaded.vue';
import AverageTicketCreatedLoading from './AverageTicketCreatedLoading.vue';

import type { ConversationsVolumeResponse } from '@/components/Reporting/api/types';

type AverageTicketCreatedData = {
  isLoaded: boolean;
  conversationsVolumeToday: ConversationsVolumeResponse[];
  conversationsVolumeAvg: ConversationsVolumeResponse[];
  filters: string;
  showError: boolean;
};

export default defineComponent({
  name: 'AverageTicketsCreatedChart',
  components: { AverageTicketCreatedLoading, AverageTicketCreatedLoaded, ChartColumnLinear },
  data(): AverageTicketCreatedData {
    return {
      isLoaded: false,
      conversationsVolumeToday: [] as ConversationsVolumeResponse[],
      conversationsVolumeAvg: [] as ConversationsVolumeResponse[],
      filters: '',
      showError: false,
    };
  },

  mounted() {
    this.getConversationsVolume();
  },

  watch: {
    '$route.query.filters': {
      handler(newFilters, oldFilters) {
        if (newFilters !== oldFilters) {
          this.filters = this.$route.query.filters;
          this.getConversationsVolume();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    async getConversationsVolume() {
      try {
        this.isLoaded = false;
        const conversationsVolumeToday = await fetchConversationsVolumeToday(this.filters, this.$root.user.timezone);
        const conversationsVolumeAvg = await fetcAverageConversationsVolumeLast4weeks(
          this.filters,
          this.$root.user.timezone
        );

        this.conversationsVolumeToday = conversationsVolumeToday.data.buckets;
        this.conversationsVolumeAvg = conversationsVolumeAvg.data.buckets;
        this.isLoaded = true;
      } catch (error) {
        this.showError = true;
        this.isLoaded = true;
        console.error(error);
      }
    },
  },
});
</script>
