import SubscriptionEdit from '@/components/Subscription/Edit.vue';
import Subscription from '@/components/Subscription/Index.vue';
import { PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import { useSubscriptionStore } from '@/store/pinia';

import { ROUTE_NAME } from '../constants';

import type { RouteRecord } from '../types';

const hasQuote = async () => {
  await useSubscriptionStore().init();
  const isEditDisabled = useSubscriptionStore().subscription.quote;

  if (isEditDisabled) {
    return { name: 'subscription' };
  }

  return true;
};

const subscriptionSettingsRoutes: RouteRecord[] = [
  {
    path: '/admin/company_profile/subscription',
    redirect: SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS,
  },
  {
    path: SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS,
    name: ROUTE_NAME.SUBSCRIPTION,
    meta: { permissionRequired: PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE },
    component: Subscription,
    children: [
      {
        name: 'subscription_edit',
        path: `${SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS}/:id`,
        component: SubscriptionEdit,
        beforeEnter: hasQuote,
      },
    ],
  },
];

export default subscriptionSettingsRoutes;
