<template>
  <div v-tooltip.top-center="msg" class="leading-none">
    <i
      v-if="type !== 'success'"
      class="fa fa-exclamation-circle"
      :class="{ 'text-error-500': type === 'danger', 'text-sun-800': type === 'warning' }"
    />
  </div>
</template>

<script>
export default {
  name: 'StatusIndicator',
  props: {
    type: {
      type: String,
      default: 'warning',
      validator: (value) => {
        return ['warning', 'danger', 'success'].includes(value);
      },
    },
  },
  data() {
    return {
      msg: this.$slots.default()[0].children.replace(/\s+/g, ''),
    };
  },
};
</script>
