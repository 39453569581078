<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div class="grid-item min-w-0">
    <slot></slot>
  </div>
</template>
