import store from '../../../store';

//todo refactor outside of Chat folder
export default class UserModel {
  constructor(user) {
    this.id = parseInt(user.id) || null;
    this.agencyId = parseInt(user.agency_id) || null;
    this.firstName = user.first_name;
    this.lastName = user.last_name;
    this.name = user.name; // same
    this.fullName = user.full_name; // same
    this.text = user.text; // same
    this.color = user.color;
    this.abbr = user.abbr;
    this.profileImage = user.profile_image;
    this.locale = user.locale_code;
    this.timezone = user.timezone;
    this.authorization = user.authorization;
    this.username = this.getUsername();
    this.identifier = this.getIdentifier();
    this.isOnline = !!user.is_online; // actual online status
    this.chatStatus = !!user.chat_status; // internal chat status
    this.userStatus = user.user_status; // web-chat status
    this.voipStatus = user.voip_status; // voIp status
    this.favouriteReactions = user.favourite_reactions || [];
    this.exists = typeof user.exists === 'undefined' || !!user.exists; // deleted user thread etc.
    this.createdAt = Math.floor(new Date(user.created_at).getTime() / 1000);
  }

  isBot() {
    return !this.id && !this.exists;
  }

  isDeleted() {
    return this.id && !this.exists;
  }

  isNormal() {
    return this.id && this.exists;
  }

  getDisplayName() {
    return this.fullName;
  }

  getUsername() {
    // outdated electron cannot use unicode properties in regex, luckily we don't really need identifiers since they are mostly used in URLs
    if (window.isElectron) {
      return this.firstName.toLowerCase().replace(/ +/g, '-').trim() + '-' + this.id;
    } else {
      return (
        this.firstName.toLowerCase().replace(/ +/g, '-').replace(new RegExp('[^\\p{L}\\p{N}-]+', 'uig'), '').trim() +
        '-' +
        this.id
      );
    }
  }

  getIdentifier() {
    return '@' + this.getUsername();
  }

  getChatStatus() {
    return store.getters['chat/chatStatus'](this);
  }

  isCurrentUser() {
    return store.getters['usersInternalChat/currentUser'] === this;
  }

  toggleChatStatus() {
    // todo refactor: move to Util/Chat.js
    let toggled = !store.getters['userConfig/get']('internal_chat.online');
    return store.dispatch('userConfig/put', { key: 'internal_chat.online', value: toggled });
  }

  hasThread(threads) {
    if (!threads) {
      threads = store.state.chat.threads;
    }

    return !!this.findThread(threads);
  }

  findThread(threads) {
    if (!threads) {
      threads = store.state.chat.threads;
    }

    return threads.find((t) => {
      return t.isUser() && t.getUser().id === this.id;
    });
  }
}
