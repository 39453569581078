<template>
  <div class="flex w-full flex-col" data-test="legacy-integration-dropdowns-wrapper">
    <template v-for="integration in integrations">
      <sidebar-dropdown
        v-if="integration.visible"
        :key="integration.id"
        :title="integration.name"
        :has-icon="true"
        :collapse-key="`${integration.id}${integration.name.replace(/\s/gm, '')}DropdownItem`"
        data-test="legacy-integration-dropdown"
        is-integration-dropdown
      >
        <component
          :is="integration.type"
          v-if="isAuthenticated(integration)"
          :key="integration.id"
          :plugin="integration"
          :ticket="ticket"
        />

        <t-button
          v-else-if="requiresOAuth(integration) && !isAuthenticated(integration)"
          btn-style="secondary"
          class="mx-auto flex w-fit flex-row items-center"
          data-test="oauth-login-button"
          @click.prevent="authenticate(integration)"
        >
          {{ $tc('auth.login') }}
          <external-link2-linear class="ml-3" size="1.3rem" />
        </t-button>
      </sidebar-dropdown>
    </template>
  </div>
</template>

<script lang="ts">
import { ExternalLink2Linear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import Bol from '@/components/Integrations/Bol.vue';
import Custom from '@/components/Integrations/Custom.vue';
import Exact from '@/components/Integrations/Exact/Generic.vue';
import Hubspot from '@/components/Integrations/Hubspot/Generic.vue';
import Lightspeed from '@/components/Integrations/Lightspeed.vue';
import Magento from '@/components/Integrations/Magento.vue';
import Magento1 from '@/components/Integrations/Magento1.vue';
import Picqer from '@/components/Integrations/Picqer.vue';
import Pipedrive from '@/components/Integrations/Pipedrive/Generic.vue';
import Salesforce from '@/components/Integrations/Salesforce/Index.vue';
import Shopify from '@/components/Integrations/Shopify.vue';
import Shopify2 from '@/components/Integrations/Shopify2.vue';
import Slack from '@/components/Integrations/Slack.vue';
import Whoocommerce from '@/components/Integrations/Whoocommerce.vue';
import { saveLoggedinPlugin } from '@/components/TicketSidebar/Api/requests';
import SidebarDropdown from '@/components/TicketSidebar/SidebarDropdown.vue';
import PluginTypes from '@/Configs/Constants/PluginTypes';

export default defineComponent({
  name: 'LegacyIntegrationDropdowns',

  components: {
    SidebarDropdown,
    Bol,
    Custom,
    Exact,
    Hubspot,
    Lightspeed,
    Magento,
    Magento1,
    Picqer,
    Pipedrive,
    Salesforce,
    Shopify,
    Shopify2,
    Slack,
    Whoocommerce,
    ExternalLink2Linear,
  },

  props: {
    integrations: {
      type: Array,
      default: () => [],
    },
    ticket: {
      type: Object,
      default: () => ({}),
    },
  },

  async mounted() {
    if (this.$route.query.k) {
      const pluginId = await this.$tStorage.getItem('authenticating_plugin_id');

      try {
        await saveLoggedinPlugin(pluginId, this.$route.query.k).then((response) => {
          this.$root.user.plugins.push(response.data);
          this.$router.push(`/tickets/${this.ticket.id}`);
        });
      } catch (err) {
        console.error(err);
        this.flashError(err?.message);
      }
    }
  },

  methods: {
    isAuthenticated(integration): boolean {
      if (!integration || !this.requiresOAuth(integration)) {
        return true;
      }

      return !!this.$root.user.plugins.find((plugin) => {
        return plugin.id === integration.id;
      });
    },

    requiresOAuth(integration): boolean {
      if (!integration) {
        return false;
      }

      return [PluginTypes.SLACK, PluginTypes.HUBSPOT, PluginTypes.EXACT, PluginTypes.PIPEDRIVE].includes(
        integration.type
      );
    },

    authenticate(integration) {
      const path = `/api/v2/integrations/oauth/redirects?provider=${integration.type}`;
      this.$tStorage.setItem('authenticating_plugin_id', integration.id);

      if (window.isElectron) {
        shell.openExternal(`https://app.trengo.com${path}&return=${encodeURIComponent('https://app.trengo.com')}`);
        return;
      }

      if (window.isLoadedFromApp) {
        parent.postMessage(
          'app-browse-url:' +
            encodeURI(`https://app.trengo.com${path}&return=${encodeURIComponent('https://app.trengo.com')}`),
          '*'
        );
        return;
      }

      if ([PluginTypes.HUBSPOT, PluginTypes.PIPEDRIVE].includes(integration.type)) {
        if (['app.trengo.eu', 'web.trengo.eu'].includes(window.location.hostname)) {
          if (
            !confirm(
              'To enable connecting this plugin, you will be forwarded to a different domain (app.trengo.com), then restart authentication'
            )
          ) {
            return;
          }

          window.location.href = `https://app.trengo.com${window.location.pathname}`;
          return;
        }
      }

      window.location.href = `https://${window.location.hostname}${path}&return=${encodeURIComponent(
        window.location.href
      )}`;
    },
  },
});
</script>

<style scoped></style>
