<template>
  <div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">{{ $t('sms.sms_select_credentials') }}</label>
      <div class="col-sm-10">
        <div v-if="list.length > 0" class="input-group">
          <select v-if="list.length > 0" v-model="record_id" class="form-control">
            <option v-for="record in list" :value="record.id">{{ record.title }}</option>
          </select>
          <span class="input-group-addon cursor-pointer" @click="openAddModal">
            <a class="">{{ $t('sms.sms_add_new_account') }}</a>
          </span>
        </div>
        <div v-else class="">
          <span class="btn btn-default" @click="openAddModal">
            <a class="">{{ $t('sms.sms_add_your_credentials') }}</a>
          </span>
        </div>
      </div>
    </div>

    <div ref="modal" class="modal">
      <div class="modal-centered height-auto">
        <div class="modal-content">
          <div>
            <div class="modal-header">
              <h5 class="modal-title">{{ $t('sms.sms_add_account') }}</h5>
              <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close-btn">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div v-if="provider === 'TWILIO'">
                <div class="form-group row">
                  <label class="col-sm-3 form-control-label">
                    {{ $t('sms.sms_twilio_account_sid') }}
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-sm-9">
                    <input
                      v-model="auth_data.account_sid"
                      class="form-control"
                      placeholder="Example: AC3f84d59206412725a03114dfb5163e33"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 form-control-label">
                    {{ $t('sms.sms_twilio_auth_token') }}
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-sm-9">
                    <input
                      v-model="auth_data.auth_token"
                      class="form-control"
                      placeholder="Example: ae356b78c7ch1293h123n2afe6a9"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 form-control-label"></label>
                  <div class="col-sm-9">
                    <input
                      type="button"
                      class="btn success text-white"
                      :disabled="saving"
                      :value="$t('sms.add_account')"
                      @click="submit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  emits: ['update:modelValue'],
  props: {
    provider: {
      type: String,
      default: '',
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      list: [],
      create: false,
      saving: false,
      auth_data: {},
      record_id: null,
    };
  },

  mounted() {
    this.loadList();
  },

  watch: {
    record_id() {
      this.$emit('update:modelValue', this.record_id);
    },
  },

  methods: {
    loadList(select = null) {
      axios.get('/api/v2/auth_credentials').then((res) => {
        this.list = res.data.filter((c) => c.provider === this.provider);
        if (this.list.length !== 0) {
          this.$emit('input', this.list[0].id);
          this.record_id = this.list[0].id;
        }
        if (select) {
          this.$emit('input', select);
          this.record_id = select;
        }
      });
    },

    openAddModal() {
      $(this.$refs.modal).modal('show');
    },

    submit() {
      this.saving = true;
      axios
        .post('/api/v2/auth_credentials', this.getFields())
        .then((res) => {
          this.loadList(res.data.id);
          this.saving = false;
          this.create = false;
          this.auth_data = {};
          $(this.$refs.modal).modal('hide');
        })
        .catch(() => {
          this.saving = false;
        });
    },

    getFields() {
      this.auth_data.provider = this.provider;
      return this.auth_data;
    },
  },
};
</script>
