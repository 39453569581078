import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "filter-dropdown flex flex-row items-center",
  "data-test": "filter-reporting-dropdown"
}
const _hoisted_2 = { class: "flex flex-row items-center" }
const _hoisted_3 = { class: "absolute z-50 mt-2 w-80 rounded-xl border border-grey-300 bg-white p-2 shadow-500" }
const _hoisted_4 = { class: "mb-0 pl-0" }
const _hoisted_5 = {
  key: 0,
  class: "ml-4"
}
const _hoisted_6 = { class: "t-text-sm m-0 text-grey-600" }
const _hoisted_7 = {
  key: 1,
  class: "ml-4"
}
const _hoisted_8 = { class: "t-text-sm m-0 text-grey-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_linear = _resolveComponent("filter-linear")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_teams = _resolveComponent("teams")!
  const _component_agents = _resolveComponent("agents")!
  const _component_channels = _resolveComponent("channels")!
  const _component_conversation_direction = _resolveComponent("conversation-direction")!
  const _component_labels = _resolveComponent("labels")!
  const _component_status = _resolveComponent("status")!
  const _component_business_hours = _resolveComponent("business-hours")!
  const _component_date_filter = _resolveComponent("date-filter")!
  const _component_t_popover = _resolveComponent("t-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_t_popover, {
      modelValue: _ctx.isFilterOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isFilterOpen) = $event)),
      "click-outside-close": ""
    }, {
      trigger: _withCtx(() => [
        _createVNode(_component_t_button, {
          "btn-style": "secondary",
          size: "sm",
          "data-test": "filter-reporting-dropdown-button"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('reports_v2.filter_count', { count: _ctx.filtersCount })), 1 /* TEXT */),
              _createVNode(_component_filter_linear, {
                class: "ml-2 text-grey-400",
                size: "1.5rem"
              })
            ])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            (_ctx.reportStore.reportingFilters.hasTeam)
              ? (_openBlock(), _createBlock(_component_teams, {
                  key: 0,
                  "data-test": "teams",
                  "active-teams": _ctx.selectedFilters.team_ids,
                  onFilterUpdated: _ctx.applyFilters
                }, null, 8 /* PROPS */, ["active-teams", "onFilterUpdated"]))
              : _createCommentVNode("v-if", true),
            (_ctx.reportStore.reportingFilters.hasAgent)
              ? (_openBlock(), _createBlock(_component_agents, {
                  key: 1,
                  "data-test": "agents",
                  "active-agents": _ctx.selectedFilters.user_ids,
                  onFilterUpdated: _ctx.applyFilters
                }, null, 8 /* PROPS */, ["active-agents", "onFilterUpdated"]))
              : _createCommentVNode("v-if", true),
            (_ctx.reportStore.reportingFilters.hasChannel)
              ? (_openBlock(), _createBlock(_component_channels, {
                  key: 2,
                  "data-test": "channels",
                  "active-channels": _ctx.selectedFilters.channel_ids,
                  onFilterUpdated: _ctx.applyFilters
                }, null, 8 /* PROPS */, ["active-channels", "onFilterUpdated"]))
              : _createCommentVNode("v-if", true),
            (_ctx.reportStore.reportingFilters.hasConversation)
              ? (_openBlock(), _createBlock(_component_conversation_direction, {
                  key: 3,
                  "data-test": "conversation-direction",
                  "active-direction": _ctx.selectedFilters.direction,
                  onFilterUpdated: _ctx.applyFilters
                }, null, 8 /* PROPS */, ["active-direction", "onFilterUpdated"]))
              : _createCommentVNode("v-if", true),
            (_ctx.reportStore.reportingFilters.hasLabel)
              ? (_openBlock(), _createBlock(_component_labels, {
                  key: 4,
                  "data-test": "labels",
                  "active-labels": _ctx.selectedFilters.label_ids,
                  onFilterUpdated: _ctx.applyFilters
                }, null, 8 /* PROPS */, ["active-labels", "onFilterUpdated"]))
              : _createCommentVNode("v-if", true),
            (_ctx.reportStore.reportingFilters.hasStatus)
              ? (_openBlock(), _createBlock(_component_status, {
                  key: 5,
                  "active-status": _ctx.selectedFilters.statuses,
                  "data-test": "status",
                  onFilterUpdated: _ctx.applyFilters
                }, null, 8 /* PROPS */, ["active-status", "onFilterUpdated"]))
              : _createCommentVNode("v-if", true),
            (_ctx.reportStore.reportingFilters.hasBusinessHours)
              ? (_openBlock(), _createBlock(_component_business_hours, {
                  key: 6,
                  "active-item": _ctx.selectedFilters.with_business_hours,
                  "data-test": "business-hours",
                  onFilterUpdated: _ctx.applyFilters
                }, null, 8 /* PROPS */, ["active-item", "onFilterUpdated"]))
              : _createCommentVNode("v-if", true),
            (_ctx.reportStore.reportingFilters.hasTicketAssignedAt)
              ? (_openBlock(), _createBlock(_component_date_filter, {
                  key: 7,
                  "data-test": "assigned-at",
                  label: _ctx.$t('reports_v2.assigned_at'),
                  type: "assigned",
                  "active-dates": { start: _ctx.selectedFilters.assigned_start_date, end: _ctx.selectedFilters.assigned_end_date },
                  onFilterUpdated: _ctx.applyDateFilter
                }, null, 8 /* PROPS */, ["label", "active-dates", "onFilterUpdated"]))
              : _createCommentVNode("v-if", true),
            (_ctx.reportStore.reportingFilters.hasTicketClosedAt)
              ? (_openBlock(), _createBlock(_component_date_filter, {
                  key: 8,
                  "data-test": "closed-at",
                  label: _ctx.$t('reports_v2.closed_at'),
                  type: "closed",
                  "active-dates": { start: _ctx.selectedFilters.closed_start_date, end: _ctx.selectedFilters.closed_end_date },
                  onFilterUpdated: _ctx.applyDateFilter
                }, null, 8 /* PROPS */, ["label", "active-dates", "onFilterUpdated"]))
              : _createCommentVNode("v-if", true),
            (_ctx.reportStore.reportingFilters.hasTicketCreatedAt)
              ? (_openBlock(), _createBlock(_component_date_filter, {
                  key: 9,
                  "data-test": "created-at",
                  label: _ctx.$t('reports_v2.created_at'),
                  type: "created",
                  "active-dates": { start: _ctx.selectedFilters.created_start_date, end: _ctx.selectedFilters.created_end_date },
                  onFilterUpdated: _ctx.applyDateFilter
                }, null, 8 /* PROPS */, ["label", "active-dates", "onFilterUpdated"]))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]),
    (_ctx.ticketInsightsStore.ticketsAmount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$tc('reports_v2.tickets', _ctx.ticketInsightsStore.ticketsAmount, {
            amount: _ctx.ticketInsightsStore.ticketsAmount,
          })), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.ticketInsightsStore.agentsAmount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$tc('reports_v2.agents', _ctx.ticketInsightsStore.agentsAmount, {
            amount: _ctx.ticketInsightsStore.agentsAmount,
          })), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}