<template>
  <div class="relative flex flex-1">
    <iframe ref="iframe" class="absolute h-full w-full border-0" :src="iframeSrc" />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, onUnmounted, type Ref } from 'vue';

import { vm } from '@/ApplicationHandler';
import { USER_ROLE } from '@/Configs/Constants';
import { useAuthStore } from '@/store/pinia';

const iframe: Ref<HTMLIFrameElement | undefined> = ref(undefined);
const iframeSrc = ref('');

onMounted(() => {
  guardRole();
  loadIframe();
  listenForMessages();
  listenToRouteChange();
});

onUnmounted(() => {
  stopListeningForIframeMessages();
});

function guardRole() {
  const { TRENGO, ADMINISTRATOR, SUPERVISOR } = USER_ROLE;
  if (![TRENGO, ADMINISTRATOR, SUPERVISOR].includes(vm?.$root['user'].role.name)) {
    vm?.$router.push('/tickets');
    return false;
  }
}

function loadIframe() {
  const urlParams = new URLSearchParams({
    v: Math.random().toString(),
  }).toString();
  let url = window.WORKFLOWS_URL || process.env.WORKFLOWS_URL; // the base url to the Workflows project
  // url += vm.$route.path; // append the current route path
  url += '?' + urlParams; // append the query params
  url += '#' + vm.$route.path; // append the path as the hash
  if (!vm.$route.path.startsWith('/workflows')) {
    return;
  }
  iframeSrc.value = url as string;
}

function listenForMessages() {
  window.addEventListener('message', onIframeMessage);
}

function stopListeningForIframeMessages() {
  window.removeEventListener('message', onIframeMessage);
}

function listenToRouteChange() {
  vm.$router.afterEach((to) => {
    if (!to.path.startsWith('/workflows')) {
      return;
    }
    sendIframeToNewPath(to.path);
  });
}

function onIframeMessage(e: MessageEvent) {
  // If the workflow service frontend is loaded, we send the Bearer token to the iframe
  if (e.data === 'APP_LOADED') {
    sendBearerTokenToIframe();
  }

  /* if the workflow service throws an authentication error, it means that the provided Bearer
  token is not valid. In this case, the most pragmatic approach is to redirect the user
  to the logout page. A better approach in the future would be to re-generate a new token */
  if (e.data === 'AUTH_ERROR') {
    useAuthStore().logout();
    vm.$router.push('/auth/logout');
  }

  /* if the workflow service sends a ROUTE_CHANGED message, it means that the user has navigated
   in the workflow service. In this case, we should update the route in the main application
   to have the same route as the workflow service */
  if (e.data && e.data.message === 'ROUTE_PATH_CHANGED') {
    vm.$router.push('/workflows' + e.data.path.replace('/workflows', ''));
  }

  if (e.data?.message === 'CHANGE_ROUTE') {
    vm.$router.push(e.data.path);
  }
}

function sendBearerTokenToIframe() {
  iframe.value?.contentWindow?.postMessage(
    {
      type: 'BEARER_TOKEN',
      payload: {
        token: window.axios.defaults.headers.common['authorization'].split('Bearer ')[1],
      },
    },
    '*',
  );
}

function sendIframeToNewPath(toPath: string) {
  iframe.value?.contentWindow?.postMessage(
    {
      type: 'NAVIGATE',
      payload: {
        to: toPath,
      },
    },
    '*',
  );
}
</script>
