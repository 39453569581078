import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "t-text-sm t-text-grey-800 mt-3 first:mt-0",
  "data-test": "conversation-dropdown-channel-title"
}
const _hoisted_2 = { class: "flex flex-row items-center" }
const _hoisted_3 = {
  key: 1,
  class: "m-0 text-ellipsis p-0 capitalize text-grey-800",
  "data-test": "channel-title"
}
const _hoisted_4 = {
  class: "t-text-sm t-text-grey-800 mt-3",
  "data-test": "ticket-status-text"
}
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = {
  key: 1,
  class: "t-text-sm t-text-grey-800 mt-3",
  "data-test": "ticket-results"
}
const _hoisted_7 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_watcher_display = _resolveComponent("watcher-display")!
  const _component_channel_icon = _resolveComponent("channel-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_sidebar_dropdown = _resolveComponent("sidebar-dropdown")!

  return (_openBlock(), _createBlock(_component_sidebar_dropdown, {
    title: _ctx.$tc('ticket_sidebar.conversation'),
    "collapse-key": "conversationDropdown",
    "data-test": "conversation-dropdown-wrapper"
  }, {
    default: _withCtx(() => [
      (_ctx.hasWatchers || _ctx.hasAgent)
        ? (_openBlock(), _createBlock(_component_watcher_display, {
            key: 0,
            ticket: _ctx.ticket
          }, null, 8 /* PROPS */, ["ticket"]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$tc('ticket_sidebar.channel')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_channel_icon, {
          class: "mr-2",
          channel: _ctx.ticket.channel.type,
          style: _normalizeStyle({ color: _ctx.ticket.channel.color }),
          "data-test": "conversation-channel-icon"
        }, null, 8 /* PROPS */, ["channel", "style"]),
        (_ctx.hasChannelsManagePermission)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              class: "m-0 text-ellipsis p-0 capitalize text-grey-800 underline",
              to: `/admin/channels2/${_ctx.ticket.channel.type.toLowerCase()}/${_ctx.ticket.channel.id}`,
              "data-test": "channel-link"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.ticket.channel.text), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["to"]))
          : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.ticket.channel.text), 1 /* TEXT */))
      ]),
      _createElementVNode("h2", _hoisted_4, [
        _createTextVNode(_toDisplayString(_ctx.$tc('tickets.status')) + ": ", 1 /* TEXT */),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$tc(`general.status_${_ctx.ticket.status.toLowerCase()}`)), 1 /* TEXT */)
      ]),
      (_ctx.doTicketResultsExist)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_6, [
            _createTextVNode(_toDisplayString(_ctx.$tc('tickets.ticket_result')) + ": ", 1 /* TEXT */),
            (_ctx.ticket.results.length > 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.ticket.results[0].name), 1 /* TEXT */))
              : (_openBlock(), _createElementBlock("span", _hoisted_8, [
                  _createElementVNode("em", null, _toDisplayString(_ctx.$tc('general.no_results')), 1 /* TEXT */)
                ]))
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]))
}