<template>
  <mobile-preview-card v-if="isMobile" class="max-h-screen w-full">
    {{ t('general.feature_only_available_on_desktop') }}
  </mobile-preview-card>
  <plan-selector v-else-if="subscription.isInTrial || subscription.isCancelled" />
  <router-view v-else-if="$route.params.id && !subscription.isLoading" :key="$route.params.id" />
  <account-page-panel v-else :title="t('subscription.your_subscription')">
    <t-spinner v-if="subscription.isLoading" />
    <service-is-down
      v-else-if="subscription.isServiceDown || hasQuarterlyBilling"
      :has-querterly-billing="hasQuarterlyBilling"
    />
    <template v-else-if="showSubscriptionManager">
      <usage-based-subscription-manager v-if="companyProfileStore.isUsageBasedPricingModel" />
      <seat-based-subscription-manager v-else />
    </template>
  </account-page-panel>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import AccountPagePanel from '@/components/common/AccountPagePanel/Index.vue';
import MobilePreviewCard from '@/components/common/MobilePreviewCard/MobilePreviewCard.vue';
import { FEATURE_FLAG_SUBSCRIPTION } from '@/Configs/Constants';
import { useCompanyProfileStore, useFeatureFlagStore, useSubscriptionStore } from '@/store/pinia';
import { useMaxWidth } from '@/util/breakpointHelpers';

import SeatBasedSubscriptionManager from './SeatBasedSubscription/components/SubscriptionManager.vue';
import ServiceIsDown from './ServiceIsDown.vue';
import PlanSelector from './UsageBasedSubscription/components/PlanSelector.vue';
import UsageBasedSubscriptionManager from './UsageBasedSubscription/components/SubscriptionManager.vue';

const isMobile = useMaxWidth('lg');
const { t } = useI18n();
const { subscription } = storeToRefs(useSubscriptionStore());
const companyProfileStore = useCompanyProfileStore();

const hasQuarterlyBilling = computed(() => {
  return useFeatureFlagStore().isEnabled(FEATURE_FLAG_SUBSCRIPTION.TW_QUARTERLY_BILLING_CUSTOMERS);
});

const showSubscriptionManager = computed(() => {
  return subscription.value.hasPlan || subscription.value.isScheduledToCancel;
});
</script>
