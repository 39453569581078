<template>
  <span class="cursor-pointer select-none" @click="click">
    <t-timeago v-if="!pretty" :date-time="getLocalTimestamp(time)"></t-timeago>
    <span v-else data-no-toggle>{{ formatDateTime(time) }}</span>
  </span>
</template>

<script type="text/babel">
import { useTimeAgo } from '@vueuse/core';
import moment from 'moment';

import TTimeago from '@/components/common/TTimeago/TTimeago';
import { formatDateTime } from '@/util/date';

export default {
  data() {
    return {
      type: 'pretty',
    };
  },
  components: {
    TTimeago,
  },
  props: {
    time: {
      type: String,
      default: '',
    },
    /** Wether to show timeago format or not */
    pretty: {
      type: Boolean,
      default: true,
    },
    allowChange: {
      type: Boolean,
      default: true,
    },
    suffix: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    timeAgo() {
      return useTimeAgo(this.getLocalTimestamp(this.$props.time));
    },
  },
  methods: {
    click() {
      if (!this.allowChange) {
        return false;
      }
      this.$root.prettyDates = !this.$root.prettyDates;
      this.$tStorage.setItem('prettyDates', this.$root.prettyDates);
    },

    getLocalTimestamp(t) {
      const timeBasedOnServerTimeZone = moment.tz(t, window.APP_TIMEZONE);
      const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const currentTime = moment.tz(new Date(), systemTimezone);
      const timeDifferenceMinutes = timeBasedOnServerTimeZone.diff(currentTime, 'minutes');
      const diffOnDate = currentTime.clone().add(timeDifferenceMinutes, 'minutes').format('YYYY/MM/DD HH:mm:ss');
      return diffOnDate;
    },
    formatDateTime,
  },
};
</script>
