import { request } from '@/util/request';

import { ENDPOINT } from '../constants';

import type { Rule } from '@/types';
import type { AxiosPromise } from 'axios';

export type RulesResponse = {
  data: Rule[];
};

export function getRules(page: number, paginate: boolean): AxiosPromise<RulesResponse | Rule[]> {
  return request(`${ENDPOINT.RULES}?page=${page}`, 'GET', {}, { paginate });
}

export function deleteRule(id: number): AxiosPromise<unknown> {
  return request(`${ENDPOINT.RULES}/${id}`, 'DELETE');
}
