<template>
  <span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.3701 5.25118C15.2006 4.65429 15.7815 4.23764 16.2394 3.96808C16.7059 3.69348 16.9253 3.64693 17.0437 3.65108C17.4152 3.66409 17.7615 3.84169 17.9889 4.1357C18.0614 4.22947 18.1516 4.43477 18.2009 4.97383C18.2492 5.50298 18.25 6.2179 18.25 7.24062L18.25 7.74984L18.25 14.259C18.25 15.2817 18.2492 15.9966 18.2009 16.5258C18.1516 17.0648 18.0614 17.2701 17.9889 17.3639C17.7615 17.6579 17.4152 17.8355 17.0437 17.8485C16.9253 17.8527 16.7059 17.8061 16.2394 17.5315C15.7815 17.262 15.2006 16.8453 14.3701 16.2484L11.4377 14.1408C11.2998 14.0417 11.1397 13.9961 10.982 14L5.75 14C3.95507 14 2.5 12.5449 2.5 10.75C2.5 8.95506 3.95507 7.49998 5.75 7.49998L11 7.49998C11.1694 7.49998 11.3256 7.44385 11.4512 7.34917L14.3701 5.25118ZM19.75 7.03289C19.7498 6.13598 19.7468 5.40762 19.6947 4.83731C19.6395 4.2335 19.5208 3.66474 19.1755 3.21816C18.6754 2.57134 17.9134 2.18061 17.0962 2.152C16.5321 2.13225 16.001 2.36784 15.4785 2.67544C14.9543 2.98399 14.3177 3.44153 13.5262 4.01046L13.4946 4.03316L10.7582 5.99998L5.75 5.99998C3.12665 5.99998 0.999999 8.12663 0.999998 10.75C0.999998 13.1181 2.73301 15.0815 5 15.4411L5 18.7501C5 19.9927 6.00736 21.0001 7.25 21.0001C8.49264 21.0001 9.5 19.9927 9.5 18.7501L9.5 15.5L10.7587 15.5L13.4946 17.4665L13.5263 17.4892L13.5263 17.4892C14.3178 18.0581 14.9543 18.5156 15.4785 18.8242C16.001 19.1318 16.5321 19.3674 17.0962 19.3476C17.9134 19.319 18.6754 18.9283 19.1755 18.2815C19.5208 17.8349 19.6395 17.2661 19.6947 16.6623C19.7468 16.092 19.7498 15.3637 19.75 14.4668C21.5847 14.2224 23 12.6514 23 10.7498C23 8.8483 21.5847 7.27733 19.75 7.03289ZM19.75 8.5556L19.75 12.9441C20.752 12.7167 21.5 11.8206 21.5 10.7498C21.5 9.67905 20.752 8.78296 19.75 8.5556ZM6.5 15.5001L6.5 18.7501C6.5 19.1643 6.83579 19.5001 7.25 19.5001C7.66422 19.5001 8 19.1643 8 18.7501L8 15.5001L6.5 15.5001Z"
        class="svg-fill"
      />
    </svg>
    <span class="icon-active">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.7582 5.99999L13.5262 4.01046C14.3178 3.44154 14.9543 2.98399 15.4785 2.67544C16.001 2.36784 16.5321 2.13225 17.0963 2.152C17.9134 2.18061 18.6754 2.57134 19.1755 3.21816C19.5208 3.66474 19.6395 4.2335 19.6947 4.83731C19.7468 5.40763 19.7498 6.13599 19.75 7.03289C21.5847 7.27734 23 8.84831 23 10.7498C23 12.6514 21.5847 14.2223 19.75 14.4668C19.7498 15.3636 19.7468 16.092 19.6947 16.6623C19.6395 17.2661 19.5208 17.8349 19.1755 18.2815C18.6754 18.9283 17.9134 19.319 17.0963 19.3476C16.5321 19.3674 16.001 19.1318 15.4785 18.8242C14.9544 18.5157 14.3179 18.0582 13.5265 17.4894L13.5265 17.4893L13.5264 17.4893L10.7587 15.5L9.5 15.5L9.5 18.7501C9.5 19.9927 8.49264 21.0001 7.25 21.0001C6.00736 21.0001 5 19.9927 5 18.7501L5 15.4411C2.73302 15.0815 1.00002 13.1181 1.00002 10.75C1.00002 8.12663 3.12667 5.99999 5.75002 5.99999L10.7582 5.99999ZM19.75 12.9441C20.752 12.7167 21.5 11.8206 21.5 10.7498C21.5 9.67906 20.752 8.78298 19.75 8.5556L19.75 12.9441ZM6.5 15.5001L6.5 18.7501C6.5 19.1643 6.83579 19.5001 7.25 19.5001C7.66422 19.5001 8 19.1643 8 18.7501L8 15.5001L6.5 15.5001Z"
          fill="white"
        />
      </svg>
    </span>
  </span>
</template>
