<template>
  <div class="mb-3" data-test="upgrade-plan">
    <t-inline-banner
      v-if="
        entitlementStatus === ENTITLEMENT_STATUS.REQUIRES_PLAN_UPGRADE ||
        entitlementStatus === ENTITLEMENT_STATUS.REQUIRES_ADDON_AND_PLAN_UPGRADE
      "
      type="upgrade"
    >
      {{ $t('entitlement.requires_addon_and_plan_upgrade', { featureName }) }}
      <template #action>
        <t-button
          class="twenty-four-hr-banner__btn"
          btn-style="secondary"
          size="sm"
          target="_blank"
          @click="handleRedirectToSubscriptionSettings"
        >
          <div class="flex flex-row items-center">
            <span class="t-text-sm-emphasize mr-1">{{ $t('entitlement.upgrade_plan_cta') }}</span>
            <arrow-right-linear class="text-grey-700" :size="20" />
          </div>
        </t-button>
      </template>
      <template #icon><arrow-top-circle-linear size="1.25rem" /></template>
    </t-inline-banner>
    <t-inline-banner v-else-if="entitlementStatus === ENTITLEMENT_STATUS.REQUIRES_ADDON" type="upgrade">
      {{ $t('entitlement.unlock_feature_long', { featureName }) }}
      <template #action>
        <t-button
          class="twenty-four-hr-banner__btn"
          btn-style="secondary"
          size="sm"
          target="_blank"
          @click="handleRedirectToSubscriptionSettings"
        >
          <div class="flex flex-row items-center">
            <span class="t-text-sm-emphasize mr-1">
              {{ $t('entitlement.unlock_feature', { featureName }) }}
            </span>
            <arrow-right-linear class="text-grey-700" :size="20" />
          </div>
        </t-button>
      </template>
      <template #icon><arrow-top-circle-linear size="1.25rem" /></template>
    </t-inline-banner>
  </div>
</template>

<script lang="ts">
import { ArrowRightLinear, ArrowTopCircleLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import { ENTITLEMENT_STATUS } from '@/Configs/Constants';

export default defineComponent({
  name: 'UpgradeFlowbotBanner',
  data() {
    return {
      ENTITLEMENT_STATUS,
    };
  },
  components: {
    ArrowTopCircleLinear,
    ArrowRightLinear,
  },
  props: {
    featureName: {
      type: String,
      required: true,
    },
    entitlementStatus: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleRedirectToSubscriptionSettings() {
      this.$router.push('/admin/subscription_settings');
    },
  },
});
</script>
