<template>
  <p class="t-text-sm m-0 text-right text-grey-600">{{ getTime }}</p>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'TicketInsightTimeCell',
  props: {
    time: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getTime(): string {
      const hours = Math.floor(this.time / 3600);
      const minutes = Math.floor((this.time % 3600) / 60);
      const remainingSeconds = this.time % 60;

      if (!remainingSeconds) {
        return '0';
      }
      if (hours > 0) {
        return `${hours.toFixed(0)}h ${minutes.toFixed(0)}m`;
      } else if (minutes > 0) {
        return `${minutes.toFixed(0)}m`;
      }
      return `${remainingSeconds.toFixed(0)}s`;
    },
  },
});
</script>
