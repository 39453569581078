<template>
  <div class="pointer" @click="router.push('/tickets/' + ticket)">{{ ticket }}</div>
</template>

<script>
export default {
  props: {
    router: null,
    ticket: null,
  },
};
</script>
