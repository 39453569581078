<template>
  <div class="mr-2 w-full min-w-[200px]">
    <t-popover v-model="showPicker" class="relative" click-outside-close>
      <template #trigger>
        <div
          ref="dropdown"
          tabindex="0"
          class="
            t-text-sm
            dropdown
            relative
            flex
            h-8
            appearance-none
            items-center
            truncate
            rounded-lg
            border border-grey-400
            bg-white
            pl-2
            pr-8
            text-grey-700
          "
        >
          <div class="flex items-center">
            <div class="date-dropdown__input">{{ label }}</div>
          </div>
        </div>
        <div class="relative">
          <div class="absolute -top-[1.5rem] right-1 flex items-center"><chevron-down-linear size="1.25rem" /></div>
        </div>
      </template>

      <template #content>
        <div class="absolute right-0 top-10 w-[384px] rounded-2xl border-1 border-grey-300 bg-white pb-2">
          <date-picker v-model="date" @selection="setDate" />
        </div>
      </template>
    </t-popover>
  </div>
</template>

<script lang="ts" setup>
import { ChevronDownLinear } from '@trengo/trengo-icons';
import moment from 'moment';
import { onMounted, ref } from 'vue';

import DatePicker from '@/components/Reporting/Components/V2/FilterDropdown/components/DatePicker/DatePicker.vue';

import type { Moment } from 'moment';
import type { PropType, Ref } from 'vue';

type DateRange = {
  startDate: Moment | null | Date;
  endDate: Moment | null | Date;
};
const props = defineProps({
  defaultLabel: { type: String, required: true },
  timezone: { type: String, required: true },
  defaultTime: { type: Object as PropType<DateRange>, required: false },
});

const emit = defineEmits(['change']);
const seventhDayPadding = 6;
const lastSevenDaysTranslation = props.defaultLabel;
const defaultStartDate = props.defaultTime?.startDate
  ? moment(props.defaultTime.startDate).tz(props.timezone)
  : moment(new Date()).tz(props.timezone).subtract(seventhDayPadding, 'days');
const defaultendDate = moment(props.defaultTime?.endDate || new Date()).tz(props.timezone);
const defaultDate = {
  startDate: defaultStartDate,
  endDate: defaultendDate,
};
const date: Ref<DateRange> = ref({ ...defaultDate });
const showPicker: Ref<boolean> = ref(false);
const dropdown = ref(null);
const label: Ref<string> = ref(lastSevenDaysTranslation);

function setDate(dateObject: { startDate: Date; endDate: Date }) {
  if (
    !moment(dateObject.startDate).tz(props.timezone).isValid() &&
    !moment(dateObject.endDate).tz(props.timezone).isValid()
  ) {
    setDateValue(defaultDate);
    return;
  }
  setDateValue(dateObject);
}

function setDateValue(dateObject: DateRange) {
  date.value.startDate = moment(dateObject.startDate).tz(props.timezone);
  date.value.endDate = moment(dateObject.endDate).tz(props.timezone);
  setDateLabelValue();
  applyTheFilter();
}

function setDateLabelValue() {
  const { startDate, endDate } = date.value;
  if (!isLastSevenDays()) {
    label.value = `${formatDate(startDate)} -> ${formatDate(endDate)}`;
    return;
  }
  label.value = lastSevenDaysTranslation;
}

function applyTheFilter() {
  showPicker.value = false;
  emit('change', date.value);
}

function formatDate(date: Moment | null | Date) {
  return moment(date).tz(props.timezone).format('DD/MM/YYYY');
}

function isLastSevenDays(): boolean {
  const today = moment().tz(props.timezone);
  const sevenDaysAgo = today.clone().subtract(6, 'days');

  const selectedStartDate = moment(date.value.startDate).tz(props.timezone);
  const selectedEndDate = moment(date.value.endDate).tz(props.timezone);

  return selectedStartDate.isSame(sevenDaysAgo, 'day') && selectedEndDate.isSame(today, 'day');
}

onMounted(() => {
  setDateLabelValue();
});
</script>

<style lang="scss" scoped>
.dropdown {
  box-shadow: 0px 1px 4px 0px #53625f1f;

  &:focus,
  &:focus-within {
    @apply border-leaf-500;
    box-shadow: 0 0 0 4px rgba(36, 152, 136, 0.25);
  }
  &:hover {
    @apply cursor-pointer border-grey-500 bg-grey-100;
  }
}
</style>
