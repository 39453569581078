import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-2 mt-4 grid grid-cols-1 gap-2 sm:mt-0 lg:grid-cols-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_item = _resolveComponent("modal-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_modal_item, {
        id: "wa_business",
        name: _ctx.$t('getting_started.title_whatsapp'),
        type: "whatsapp",
        icon: _ctx.getChannelIcon(_ctx.CHANNEL_TYPE.WHATSAPP)
      }, null, 8 /* PROPS */, ["name", "icon"]),
      _createVNode(_component_modal_item, {
        id: "email",
        name: _ctx.$t('getting_started.title_email'),
        type: "email",
        icon: _ctx.getChannelIcon(_ctx.CHANNEL_TYPE.EMAIL)
      }, null, 8 /* PROPS */, ["name", "icon"]),
      _createVNode(_component_modal_item, {
        id: "chat",
        name: _ctx.$t('getting_started.title_live_chat'),
        type: "chat",
        icon: _ctx.getChannelIcon(_ctx.CHANNEL_TYPE.CHAT)
      }, null, 8 /* PROPS */, ["name", "icon"]),
      _createVNode(_component_modal_item, {
        id: "facebook",
        name: _ctx.$t('getting_started.title_facebook'),
        type: "messaging",
        icon: _ctx.getChannelIcon(_ctx.CHANNEL_TYPE.FACEBOOK)
      }, null, 8 /* PROPS */, ["name", "icon"]),
      _createVNode(_component_modal_item, {
        id: "instagram",
        name: _ctx.$t('getting_started.title_instagram'),
        type: "messaging",
        icon: _ctx.getChannelIcon(_ctx.CHANNEL_TYPE.INSTAGRAM)
      }, null, 8 /* PROPS */, ["name", "icon"]),
      _createVNode(_component_modal_item, {
        id: "voip",
        name: _ctx.$t('getting_started.title_voice'),
        type: "voice",
        icon: _ctx.getChannelIcon(_ctx.CHANNEL_TYPE.VOIP)
      }, null, 8 /* PROPS */, ["name", "icon"]),
      _createVNode(_component_modal_item, {
        id: "sms",
        name: _ctx.$t('getting_started.title_sms'),
        type: "messaging",
        icon: _ctx.getChannelIcon(_ctx.CHANNEL_TYPE.SMS)
      }, null, 8 /* PROPS */, ["name", "icon"]),
      _createVNode(_component_modal_item, {
        id: "custom",
        name: _ctx.$t('getting_started.title_custom'),
        type: "messaging",
        icon: _ctx.getChannelIcon(_ctx.CHANNEL_TYPE.CUSTOM)
      }, null, 8 /* PROPS */, ["name", "icon"]),
      _createVNode(_component_modal_item, {
        id: "telegram",
        name: _ctx.$t('getting_started.title_telegram'),
        type: "messaging",
        icon: _ctx.getChannelIcon(_ctx.CHANNEL_TYPE.TELEGRAM)
      }, null, 8 /* PROPS */, ["name", "icon"]),
      (!_ctx.isHideGbmEnabled)
        ? (_openBlock(), _createBlock(_component_modal_item, {
            key: 0,
            id: "gbm",
            name: _ctx.$t('getting_started.title_google_business'),
            type: "messaging",
            icon: _ctx.getChannelIcon(_ctx.CHANNEL_TYPE.GBM)
          }, null, 8 /* PROPS */, ["name", "icon"]))
        : _createCommentVNode("v-if", true),
      (_ctx.userStore.hasPermission(_ctx.PERMISSION.HELP_CENTER__GENERAL__MANAGE))
        ? (_openBlock(), _createBlock(_component_modal_item, {
            key: 1,
            id: "help_center",
            name: _ctx.$t('getting_started.title_help_center'),
            type: "help_center",
            icon: _ctx.getChannelIcon(_ctx.CHANNEL_TYPE.HELP_CENTER)
          }, null, 8 /* PROPS */, ["name", "icon"]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}