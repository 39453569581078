<script>
import eventBus from '@/eventBus';

export default {
  name: 'Collapse',
  emits: ['collapse'],
  props: {
    collapseKey: {
      type: String,
      default: '',
    },

    hideArrowIcon: {
      type: Boolean,
      default: false,
    },

    defaultOpen: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      showPanel: null,
    };
  },

  async mounted() {
    this.showPanel = (await this.$tStorage.getItem(this.collapseKey)) === 'true';

    if (!(await this.$tStorage.getItem(this.collapseKey)) && this.defaultOpen) {
      this.showPanel = true;
    }

    eventBus.$on('panels.' + this.collapseKey + '.open', () => {
      this.showPanel = true;
    });

    eventBus.$on('panels.' + this.collapseKey + '.close', () => {
      this.showPanel = false;
    });
  },

  unmounted() {
    eventBus.$off('panels.' + this.collapseKey + '.open');
    eventBus.$off('panels.' + this.collapseKey + '.close');
  },

  methods: {
    toggleClick(e) {
      if (e.target.tagName === 'I' && !e.target.classList.contains('collapse_icon') && this.showPanel) {
        return;
      }
      this.showPanel = !this.showPanel;
      this.$emit('collapse', this.showPanel);
    },
  },

  watch: {
    showPanel: function (val) {
      this.$tStorage.setItem(this.collapseKey, val + '');
    },
  },
};
</script>

<template>
  <div class="collapse-container">
    <div class="cursor-pointer" @click="toggleClick">
      <i
        v-if="!hideArrowIcon"
        class="fas md-18 pull-left fa-xs collapse_icon cursor-pointer select-none"
        :class="!showPanel ? 'fa-chevron-right' : 'fa-chevron-down'"
        style="padding-top: 3px"
      ></i>
      <slot name="collapse-title" class="" :open="showPanel"></slot>
    </div>
    <div v-show="showPanel">
      <div style="height: 8px; width: 8px"></div>
      <slot name="collapse-content" :open="showPanel"></slot>
    </div>
  </div>
</template>

<style>
.collapse_icon {
  color: theme('colors.grey-700');
  margin-top: 0px;
  margin-right: 5px;
  width: 15px !important;
}
</style>
