import { intersection } from 'lodash';

import { PRICING_MODEL } from '@/Configs/Constants';
import { SETTINGS_NAVIGATION } from '@/Configs/Constants/navigation/settingsNavigation';

import type { PricingModel } from '@/types';
import type { NavigationGroupItem } from '@/types/navigation';

const hasPermission = (userPermissions: string[], navigationPermissions: string[]): boolean =>
  intersection(userPermissions, navigationPermissions).length > 0;

export const getNavigationByUserPermissions = (
  userPermissions: string[],
  pricingModel: PricingModel
): NavigationGroupItem[] => {
  return SETTINGS_NAVIGATION.reduce((result: NavigationGroupItem[], navigationGroupItem: NavigationGroupItem) => {
    const filteredItems = navigationGroupItem.items.filter(({ permission, hidden, key }) => {
      if (key === 'balance_settings' && pricingModel === PRICING_MODEL.USAGE_BASED) {
        return false;
      }
      if (key === 'usage_overview' && pricingModel === PRICING_MODEL.SEAT_BASED) {
        return false;
      }

      return !hidden && hasPermission(userPermissions, permission);
    });

    return filteredItems.length > 0 ? [...result, { ...navigationGroupItem, items: filteredItems }] : result;
  }, []);
};
