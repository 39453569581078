<!--suppress ALL -->
<template>
  <section class="create-email">
    <create-email-mobile-header @close="goBack" />

    <div class="wrapper">
      <create-email-header @close="goBack" />

      <main class="create-email-main">
        <form action="" @submit.prevent="createChannel">
          <div class="form-group">
            <div class="form-group-title">{{ $t('email_settings.channel_name') }}</div>
            <label for="channel-name" class="form-group-label">
              {{
                $t(
                  'email_settings.the_channel_name_appears_in_your_settings_and_the_inbox_but_wont_be_visible_to_your_customers',
                )
              }}
            </label>
            <atomic-input
              id="channel-name"
              v-model="record.title"
              size="lg"
              required
              :placeholder="$t('email_settings.eg_customer_support_team_uk')"
            />
          </div>

          <div class="form-group mt-6 md:mt-12">
            <div class="form-group-title mb-4">{{ $t('email_settings.im_using_this_email_channel') }}</div>
            <choose-channel-owner-type v-model="channelOwnerType" />
          </div>

          <div v-if="channelOwnerType === 'public'" class="form-group select-user fade-in">
            <div class="form-group-title mb-4">{{ $t('email_settings.select_team') }}</div>
            <t-multi-select
              v-model="record.teams"
              :placeholder="$t('email_settings.select_one_or_more_teams')"
              :use-icon="false"
              :has-avatar="false"
              :is-closable="true"
              variant="leaf"
              size="md"
              container-size="md"
              :data="teamsList"
            />
          </div>

          <div v-if="channelOwnerType === 'private'" class="form-group select-user fade-in">
            <div class="form-group-title mb-4">{{ $t('email_settings.select_user') }}</div>
            <t-multi-select
              v-model="record.users"
              :placeholder="$t('email_settings.select_a_user')"
              :use-icon="false"
              :has-avatar="true"
              :is-closable="false"
              :close-on-select="true"
              :multiple="false"
              size="md"
              container-size="md"
              :data="$root.users"
            />
          </div>

          <div class="form-group">
            <div class="form-group-title">{{ $t('email_settings.email_sender_name') }}</div>
            <label for="company-name" class="form-group-label mb-2">
              {{ $t('email_settings.sender_name_subtitle') }}
            </label>
            <atomic-input
              id="company-name"
              v-model="record.emailChannel.sender_name_personal"
              size="lg"
              :placeholder="$t('email_settings.sender_name_example', { name })"
            />
          </div>

          <div v-if="hasBulkImport()" class="form-group mt-6 md:mt-12">
            <div class="form-group-title">{{ $t('email_settings.bulk_import') }}</div>
            <div class="flex">
              <label for="import-messages" class="form-group-label mb-2">
                {{ $t('email_settings.bulk_import_subtitle') }}
              </label>

              <div class="ml-4">
                <label class="ui-switch ui-switch-md success m-t-xs">
                  <input id="import-messages" v-model="record.importMessages" type="checkbox" />
                  <i class="success"></i>
                </label>
              </div>
            </div>
          </div>

          <div class="actions mb-8 mt-14 flex justify-end md:mb-8">
            <atomic-button
              btn-style="secondary"
              class="mr-2 hidden md:inline-block"
              size="lg"
              :disabled="loading"
              @click="goBack"
            >
              {{ $t('email_settings.cancel') }}
            </atomic-button>
            <atomic-button size="lg" class="block w-full md:inline-block md:w-auto" :disabled="loading">
              {{ $t('email_settings.create_channel') }}
            </atomic-button>
          </div>
        </form>
      </main>
    </div>
  </section>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

import { loadChannelSettings } from '@/api/modules/email';
import { CHANNEL_TYPE } from '@/Configs/Constants';
import { GOOGLE, OUTLOOK, NYLAS, NYLAS_V3 } from '@/Configs/Constants/ChannelLandingPageTypes';

import ChooseChannelOwnerType from './ChooseChannelOwnerType';
import CreateEmailHeader from './CreateEmailHeader';
import CreateEmailMobileHeader from './CreateEmailMobileHeader';
import BaseChannel from '../../../BaseChannel';

export default {
  components: {
    ChooseChannelOwnerType,
    CreateEmailHeader,
    CreateEmailMobileHeader,
  },
  extends: BaseChannel,
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      teams: [],
      channelOwnerType: 'public',
      loading: false,
      record: {
        notification_email: '',
        notification_sound: 'chat.mp3',
        username: '',
        password: '',
        password_is_null: false,
        text: '',
        color: null,
        teams: [],
        users: [],
        reopen_closed_ticket: 1,
        business_hour_id: null,
        is_private: false,
        reassign_reopened_ticket: false,
        reopen_closed_ticket_time_window_days: 30,
        title: '',
        type: CHANNEL_TYPE.EMAIL,
        importMessages: this.hasBulkImport(),
        emailChannel: {
          type: null,
          sender_name_personal: '',
          spam_setting: 1,
          embed_attachments: 1,
          auto_reply_enabled: false,
          split_by_subject: true,
          branding: true,
          auto_reply_body: '',
          signature: '',
          meta: {
            selectedFolders: [],
            syncStatus: false,
            syncFolders: false,
            zapier_consent: null,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      teamsList: 'teams/teamsList',
    }),
    name() {
      const firstName = this.$root.user.first_name;
      const lastName = this.$root.user.last_name;

      if (firstName || lastName) {
        return `${firstName ? firstName + ' ' : ''}${lastName}`;
      }

      return 'Company Name';
    },
    isExternalProvider() {
      return this.record.emailChannel.type !== 'TRENGO';
    },
  },
  watch: {
    channelOwnerType(val) {
      this.record.is_private = this.channelOwnerType === 'private';
      // Default disable auto replies on private channels
      this.record.emailChannel.auto_reply_enabled = this.channelOwnerType !== 'private';

      if (val === 'private') {
        const userID = this.$root.user?.id;
        this.record.user_ids = userID ? [userID] : [];
        this.record.users = [this.$root.user];
      }
    },
  },
  async mounted() {
    this.record.emailChannel.type = this.$route.query.type || NYLAS_V3;
    this.loadDefaults();
    this.$store.dispatch('teams/fetchTeams');
    await this.loadExternalProvider();
  },
  methods: {
    hasBulkImport() {
      return [GOOGLE, OUTLOOK, NYLAS_V3].includes(this.$route.query.type);
    },
    goBack() {
      this.$router.push('/admin/channels2/email');
    },
    async createChannel() {
      this.loading = true;
      const data = { ...this.record };

      data.team_ids = data.teams.map((item) => item.id);
      data.user_ids = this.mapUserIds();

      if (this.isNylasChannel()) {
        data.emailChannel.spam_setting = 0;
        data.emailChannel.meta.reply_all = true;

        if (!('provider' in data.emailChannel.meta) && !('email_address' in data.emailChannel.meta)) {
          try {
            const response = await this.handleNylasProvider();
            if (response) {
              data.username = response?.username || data.username;
              const meta = response.meta || {};
              data.emailChannel.meta = {
                ...data.emailChannel.meta,
                ...meta,
              };
            }
          } catch (e) {
            console.error('Failed to load provider and email address', e);
          }
        }
      }
      axios
        .post('/api/v2/channels', data)
        .then((res) => {
          this.$root.loadAuthorizedChannels();
          this.$root.loadAllChannels();
          router.push({
            path: '/admin/channels2/' + this.record.type.toLowerCase(),
            query: { createdId: res.data.id, isBulk: this.record.importMessages ? 'true' : 'false' },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadDefaults() {
      return axios
        .get('/api/v2/email/defaults')
        .then((result) => {
          this.record.emailChannel.auto_reply_body = result.data.auto_reply.body;
          this.record.emailChannel.signature = result.data.signature;
          this.record.emailChannel.sender_name = result.data.sender_name;
          this.record.emailChannel.sender_name_personal = result.data.sender_name_personal;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    async loadExternalProvider() {
      if (!this.isExternalProvider) {
        return;
      }

      let response = undefined;
      try {
        switch (this.record.emailChannel.type) {
          case GOOGLE:
            response = await this.handleGoogleProvider();
            break;
          case OUTLOOK:
            response = await this.handleOutlookProvider();
            break;
          case NYLAS:
          case NYLAS_V3:
            response = await this.handleNylasProvider();
            break;
          default:
            console.warn('Unhandled external provider type');
        }
        if (response) {
          this.record.username = response?.username || this.record.username;
          Object.assign(this.record.emailChannel.meta, response.meta || {});
        }
      } catch (e) {
        console.error('Failed to load external provider settings', e);
      }
    },
    async handleGoogleProvider() {
      const params = {
        access_token: this.$route.query.access_token,
        refresh_token: this.$route.query.refresh_token,
        created: this.$route.query.created,
        expires_in: this.$route.query.expires_in,
      };
      const res = await loadChannelSettings('gmail', params);
      return {
        username: res.data.email_address,
      };
    },
    async handleOutlookProvider() {
      const res = await loadChannelSettings('outlook', { outlook_id: this.$route.query.outlook_id });
      return {
        username: res.data.user.userPrincipalName,
        meta: {
          access_token: res.data.meta.access_token,
          expires: res.data.meta.expires,
          ext_expires_in: res.data.meta.ext_expires_in,
          id_token: res.data.meta.id_token,
          refresh_token: res.data.meta.refresh_token,
          scope: res.data.meta.scope,
          token_type: res.data.meta.token_type,
        },
      };
    },
    async handleNylasProvider() {
      const nylasId = this.$route.query.nylas_id;
      if (!nylasId) {
        console.error(`Missing nylas_id in query params: ${this.$route.query}`);
        return;
      }
      const res = await loadChannelSettings('nylas', { nylas_id: nylasId });

      return {
        username: res.data.sub_account_id,
        meta: {
          access_token: res.data.access_token,
          email_address: res.data.email_address,
          provider: res.data.provider,
        },
      };
    },
    isNylasChannel() {
      return this.$route.query.type === NYLAS_V3 || this.$route.query.type === NYLAS;
    },
  },
};
</script>

<style scoped lang="scss" src="./CreateEmail.scss" />
