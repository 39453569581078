import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "t-text-desktop-h5 m-0" }
const _hoisted_3 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_external_link2_linear = _resolveComponent("external-link2-linear")!
  const _component_atomic_button = _resolveComponent("atomic-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('broadcast.broadcast_overview_title')), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.userStore.hasPermission(_ctx.PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE))
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: _ctx.SETTINGS_URL.ADMIN__BALANCE_SETTINGS,
            "data-test": "balance-btn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_atomic_button, {
                "btn-style": "secondary",
                class: "mr-4 flex items-center"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('broadcast.balance')) + " €" + _toDisplayString(_ctx.loading ? '...' : _ctx.balance) + " ", 1 /* TEXT */),
                  _createVNode(_component_external_link2_linear, { class: "ml-2" })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["to"]))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_router_link, {
        to: "/broadcasting/new",
        "data-test": "new-broadcast-btn"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_atomic_button, { class: "new-btn" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('broadcast.new_broadcast')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}