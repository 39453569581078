export const SETTINGS_TRACKING_FEATURE_NAME = {
  ADMIN__ACCOUNT_SETTINGS: 'Account Settings',
  ADMIN__INBOX_SETTINGS: 'Inbox Settings',
  ADMIN__APPS_INTEGRATION_HUB: 'Integration Hub',
  ADMIN__APPS_INTEGRATIONS: 'App Integrations',
  ADMIN__AUTO_REPLIES: 'Auto Replies',
  ADMIN__BALANCE_SETTINGS: 'Balance Settings',
  ADMIN__BUSINESS_HOURS: 'Business Hours',
  ADMIN__CHANNELS2_CHAT: 'Live chat',
  ADMIN__CHANNELS2_CUSTOM: 'Custom Channel',
  ADMIN__CHANNELS2_EMAIL: 'Email',
  ADMIN__CHANNELS2_FACEBOOK: 'Facebook',
  ADMIN__CHANNELS2_GBM: 'Google Business',
  ADMIN__CHANNELS2_INSTAGRAM: 'Instagram',
  ADMIN__CHANNELS2_SMS: 'SMS',
  ADMIN__CHANNELS2_TELEGRAM: 'Telegram',
  ADMIN__CHANNELS2_VOIP: 'Voice',
  ADMIN__CHANNELS2_WA_BUSINESS: 'WhatsApp Business',
  ADMIN__CHATBOTS: 'Chatbots',
  ADMIN__CONTACT_GROUPS: 'Contact Groups',
  ADMIN__CSAT: 'Customer Satisfaction',
  ADMIN__CUSTOM_FIELDS: 'Custom Fields',
  ADMIN__CUSTOM_VIEWS: 'Custom Views',
  ADMIN__FLOWBOTS: 'Flowbots',
  ADMIN__LABELS: 'Labels',
  ADMIN__LOCATIONS: 'Locations',
  ADMIN__QUICK_REPLIES: 'Quick Replies',
  ADMIN__RULES: 'Rules',
  ADMIN__SUBSCRIPTION_SETTINGS: 'Subscription Settings',
  ADMIN__TEAMS: 'Teams',
  ADMIN__TICKET_RESULTS: 'Ticket Results',
  ADMIN__TRANSACTIONS: 'Transactions',
  ADMIN__USAGE_OVERVIEW: 'Usage Overview',
  ADMIN__USERS: 'Users',
  ADMIN__WA_TEMPLATE: 'WhatsApp Templates',
  ADMIN__WEB_WIDGETS: 'Web Widgets',
  ADMIN__WIDGET_AUTOMATION: 'Widget Automation',
} as const;

export const SETTINGS_TRACKING_GROUP_ID = {
  ADMIN__ACCOUNT: 'Account',
  ADMIN__AUTOMATION: 'Automation',
  ADMIN__CHANNELS: 'Channels',
  ADMIN__ORGANIZATION: 'Organization',
  ADMIN__SETTINGS: 'Settings',
} as const;
