export const PLAN_PRICE_ID = {
  SEAT_BASED__TIER1__MONTHLY: 'trengo-seat-tier1-EUR-Monthly',
  SEAT_BASED__TIER1__ANNUALLY: 'trengo-seat-tier1-EUR-Yearly',
  SEAT_BASED__TIER2__MONTHLY: 'trengo-seat-tier2-EUR-Monthly',
  SEAT_BASED__TIER2__ANNUALLY: 'trengo-seat-tier2-EUR-Yearly',
  SEAT_BASED__TIER3__MONTHLY: 'trengo-seat-tier3-EUR-Monthly',
  SEAT_BASED__TIER3__ANNUALLY: 'trengo-seat-tier3-EUR-Yearly',
  SEAT_BASED__TRIAL: 'trengo-seat-trial-EUR',
  USAGE_BASED__TIER1__MONTHLY: 'trengo-usage-tier1-EUR-Monthly',
  USAGE_BASED__TIER1__ANNUALLY: 'trengo-usage-tier1-EUR-Yearly',
  USAGE_BASED__TIER2__MONTHLY: 'trengo-usage-tier2-EUR-Monthly',
  USAGE_BASED__TIER2__ANNUALLY: 'trengo-usage-tier2-EUR-Yearly',
  USAGE_BASED__TIER3__MONTHLY: 'trengo-usage-tier3-EUR-Monthly',
  USAGE_BASED__TIER3__ANNUALLY: 'trengo-usage-tier3-EUR-Yearly',
  USAGE_BASED__TRIAL: 'trengo-usage-trial-EUR',
} as const;
