<script type="text/babel">
import { map, toArray } from 'lodash';
import Multiselect from 'vue-multiselect';

import eventBus from '@/eventBus';

export default {
  data() {
    return {
      languages: [],
      selectedLanguages: [],
      defaultLanguage: null,
      name: window.AGENCY.name,
    };
  },

  components: {
    Multiselect,
  },

  mounted() {
    axios.get('/api/v2/locale_codes').then((languages) => {
      this.localeCodes = {};
      languages.data.forEach((l) => {
        Object.assign(this.localeCodes, {
          [l.id]: {
            id: l.id,
            text: l.name,
          },
        });
      });
      this.languages = toArray(this.localeCodes);
      this.selectedLanguages.push(
        this.languages.filter((l) => {
          return l.id == LOCALE_CODE.substr(0, 2);
        })[0]
      ); // set default by current locale
      if (this.selectedLanguages.length > 0) {
        this.defaultLanguage = this.selectedLanguages[0]['id'];
      }
    });
  },

  watch: {
    selectedLanguages(a) {
      if (
        this.defaultLanguage == null ||
        a.filter((lang) => {
          return lang.id == this.defaultLanguage;
        }).length == 0
      ) {
        this.defaultLanguage = a[0]['id'];
      }
    },
  },

  methods: {
    save() {
      axios
        .post('/api/v2/help_center', {
          languages: toArray(map(this.selectedLanguages, 'id')),
          default_language: this.defaultLanguage,
          name: this.name,
        })
        .then((res) => {
          router.push('/help_center/' + res.data.id + '/categories');
          $('#CreateHelpCenterModal').modal('hide');
          eventBus.$emit('help_center.list.reload');
          this.$root.hasHelpcenter = true;
        });
    },
  },
};
</script>

<template>
  <div id="CreateHelpCenterModal" class="modal" role="dialog">
    <form @submit.prevent="save">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('help_center.create_new_help_center') }}</h5>
            <button
              type="button"
              class="close modal-close-btn"
              data-dismiss="modal"
              :aria-label="$t('help_center.close')"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label class="control-label">{{ $t('help_center.company_name') }}</label>
              <input v-model="name" type="text" required class="form-control" />
            </div>
            <div class="form-group">
              <label class="control-label">{{ $t('help_center.languages') }}</label>
              <multiselect
                v-model="selectedLanguages"
                label="text"
                track-by="id"
                :allow-empty="false"
                :options="languages"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                :multiple="true"
                :placeholder="$t('help_center.select_one_or_more_languages')"
              ></multiselect>
            </div>
            <div class="form-group">
              <label class="control-label">{{ $t('help_center.default_language') }}</label>
              <div v-for="lang in selectedLanguages">
                <label>
                  <input v-model="defaultLanguage" type="radio" :value="lang.id" />
                  {{ lang.text }}
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn white" data-dismiss="modal" :aria-label="$t('help_center.close')">
              {{ $t('help_center.cancel') }}
            </button>
            <button
              type="submit"
              class="btn btn-success ml-1 text-white"
              :disabled="defaultLanguage == null || name == '' || languages.length == 0"
            >
              {{ $t('help_center.create_help_center') }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
