import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f399accc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "channels-accordion-item__social-icon" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "channels-accordion-item__title" }
const _hoisted_4 = { class: "channels-accordion-item__subTitle__wrapper" }
const _hoisted_5 = { class: "channels-accordion-item__subTitle__content" }
const _hoisted_6 = { class: "channels-accordion-item__subTitle__content__time-fill-wrapper" }
const _hoisted_7 = { class: "channels-accordion-item__subTitle__content__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_time_fill = _resolveComponent("time-fill")!
  const _component_t_list_item_accordion = _resolveComponent("t-list-item-accordion")!

  return (_openBlock(), _createBlock(_component_t_list_item_accordion, {
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.handleClick($props.item)))
  }, _createSlots({
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, _toDisplayString($props.item.title), 1 /* TEXT */)
      ])
    ]),
    subtitle: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_time_fill, {
              size: "1.2rem",
              class: "w-4 text-grey-600"
            })
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_7, _toDisplayString($props.item.sub_title), 1 /* TEXT */)
          ])
        ])
      ])
    ]),
    _: 2 /* DYNAMIC */
  }, [
    ($props.item.channel)
      ? {
          name: "start-icon",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("img", { src: $options.getIcon }, null, 8 /* PROPS */, _hoisted_2)
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1024 /* DYNAMIC_SLOTS */))
}