<template>
  <canvas ref="report" height="200" class="h-full"></canvas>
</template>

<script lang="ts">
import Chart from 'chart.js/auto';
import { map } from 'lodash';
import moment from 'moment';
import { defineComponent } from 'vue';

import { WorkloadHourlyAverageConversationsCreatedMockData } from '@/components/Reporting/api/mocks';

import { getAverageConversationsChartConfiguration } from '../utilities';

import type { WorkloadManagementBucket } from '@/components/Reporting/api/types';
import type { ChartConfiguration, ChartData } from 'chart.js/auto';

type Data = {
  createdConversations: WorkloadManagementBucket[];
  chartInstance?: ChartConfiguration;
  data?: ChartData;
};

export default defineComponent({
  name: 'HourlyAverageConversationsCreatedLoaded',

  chartInstance: undefined,
  data(): Data {
    return {
      createdConversations: WorkloadHourlyAverageConversationsCreatedMockData.data.buckets,
      data: undefined,
    };
  },

  mounted() {
    this.setChart();
  },

  computed: {
    chartConfiguration() {
      return getAverageConversationsChartConfiguration((key, param) => this.$t(key, param), false, true, true);
    },

    datasets() {
      return [
        {
          label: this.$t('reports_v2.hourly'),
          data: map(this.createdConversations, (bucket: WorkloadManagementBucket) => {
            return bucket.value;
          }),
          backgroundColor: '#E1E3E5',
          borderWidth: 1,
          borderRadius: 4, // Rounded corners
        },
      ];
    },
  },

  methods: {
    setChart() {
      this.mapData();
      this.drawChart();
    },
    mapData() {
      const today = moment().tz(this.$root.user.timezone).startOf('day');
      const format = 'YYYY-MM-DDTHH:mm:ssZ';
      const hoursRange = [];

      for (let i = 0; i < 24; i++) {
        hoursRange.push(today.format(format));
        today.add(1, 'hours');
      }

      this.data = {
        labels: map(hoursRange, (value: string) => {
          return moment(value).tz(this.$root.user.timezone).format('HH:mm');
        }),
        datasets: this.datasets,
      };
    },
    drawChart() {
      const chart = this.$refs.report.getContext('2d');
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: this.chartConfiguration.scales,
        plugins: this.chartConfiguration.plugins,
        barThickness: 12,
      };
      this.chartInstance = new Chart(chart, {
        type: 'bar',
        data: this.data,
        options,
      });
    },
  },
});
</script>
