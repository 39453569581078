<script>
import Generic from './ECommerce/Generic';

export default {
  name: 'Shopify2',
  emits: ['update-external-url'],
  extends: Generic,
  data() {
    return {
      settings: {
        showCustomerUrl: true,
      },
    };
  },

  methods: {
    loadData() {
      this.customer = {};
      this.orders = [];
      this.loading = true;
      axios
        .get('/api/v2/integrations/shopify2?plugin_id=' + this.plugin.id + '&ticket_id=' + this.ticket.id)
        .then((res) => {
          this.loading = false;
          if (!res.data.customer) {
            this.found = false;
            return;
          }
          this.found = true;
          this.customer = res.data.customer;
          this.$emit('update-external-url', this.getCustomerUrl(this.customer.id));
          this.orders = res.data.orders;
        })
        .catch((e) => {
          this.found = true;
          this.loading = false;
        });
    },

    getCustomerUrl(id) {
      return 'https://' + this.plugin.meta.url + '/admin/customers/' + id;
    },

    getOrderUrl(id) {
      return 'https://' + this.plugin.meta.url + '/admin/orders/' + id;
    },
  },
};
</script>
