import { request } from '@/util';

import type { AxiosPromise } from 'axios';

const baseURL = `/api/v2/automation_ai`;

export type Tenant = { provider: string; provider_id: string };

export function registerTenant(): AxiosPromise<Tenant> {
  return request(`${baseURL}/register`, 'POST');
}

export function getTenant(): AxiosPromise<Tenant> {
  return request(`${baseURL}/providers`, 'GET');
}
