<template>
  <div>
    <div class="align-items-center flex">
      <color-item
        :is-light="isLight"
        color="red"
        :checked="paletteColor === 'red'"
        @click="() => updateFromPicker(all_colors['red'], 'red')"
      />
      <color-item
        :is-light="isLight"
        color="purple"
        :checked="paletteColor === 'purple'"
        @click="() => updateFromPicker(all_colors['purple'], 'purple')"
      />
      <color-item
        :is-light="isLight"
        color="blue"
        :checked="paletteColor === 'blue'"
        @click="() => updateFromPicker(all_colors['blue'], 'blue')"
      />
      <color-item
        :is-light="isLight"
        color="teal"
        :checked="paletteColor === 'teal'"
        @click="() => updateFromPicker(all_colors['teal'], 'teal')"
      />

      <div
        ref="colorpicker"
        class="color-items colorpicker-component relative"
        :style="{ backgroundColor: dropperColor }"
        @click="showPicker()"
      >
        <chrome-picker
          v-if="displayPicker"
          :model-value="colors"
          @update:modelValue="(color) => updateFromPicker(color, 'custom')"
        />
        <div :class="{ 'badge-dropper': isDropper || paletteColor === 'custom' }">
          <i class="fas fa-eye-dropper" />
        </div>
        <i
          v-if="paletteColor === 'custom' && isDropper"
          class="material-icons"
          :class="{ 'text-white': !isLight, 'text-black': isLight }"
        >
          done
        </i>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { Chrome } from '@ckpack/vue-color';

import ColorItem from './ColorItem';
import { isLight } from './utils';

export default {
  emits: ['update:modelValue'],
  components: {
    'chrome-picker': Chrome,
    'color-item': ColorItem,
  },
  props: {
    modelValue: {
      type: [String, Object],
      default: '',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      all_colors: {
        teal: '#41D1FF',
        red: '#FF0F57',
        blue: '#3A66FF',
        purple: '#B43EFD',
      },
      defaultColor: this.modelValue,
      colors: {
        hex: this.modelValue,
      },
      isDropper: false,
      dropperColor: '',
      colorValue: '',
      paletteColor: '',
      displayPicker: false,
      isLight: false,
    };
  },
  watch: {
    modelValue(val) {
      if (!val) {
        // reset color picker
        this.updateFromPicker(null, 'reset');
      } else {
        let type = 'custom';
        let color = { hex: val };
        if (this.isFromPalette(val)) {
          type = this.isFromPalette(val);
          color = val;
        }
        this.updateFromPicker(color, type);
      }
    },
    colorValue(val) {
      this.$emit('update:modelValue', val);
      const color = this.all_colors[val] ? this.all_colors[val] : this.colorValue;
      this.isLight = isLight(color);
    },
  },
  mounted() {
    this.setDefaultColor();
    this.setColor(this.defaultColor || '');
  },
  unmounted() {
    this.hidePicker();
  },
  methods: {
    isFromPalette(color) {
      return Object.keys(this.all_colors).find((key) => this.all_colors[key] === color);
    },
    setDefaultColor() {
      if (this.defaultColor !== '' && this.defaultColor) {
        let type = 'custom';
        let color = { hex: this.defaultColor };
        //if default color set

        if (this.isFromPalette(this.defaultColor)) {
          type = this.isFromPalette(this.defaultColor);
          color = this.defaultColor;
        }
        this.updateFromPicker(color, type);
      }
    },
    setColor(color) {
      this.updateColors(color);
      this.colorValue = color;
    },
    updateColors(val) {
      this.colors = val;
    },
    showPicker() {
      document.addEventListener('click', this.documentClick);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick);
      this.displayPicker = false;
    },
    updateFromPicker(color, type) {
      if (type === 'reset') {
        this.defaultColor = this.modelValue;
        this.dropperColor = '';
        this.paletteColor = '';
        this.isDropper = false;
        this.colors = {
          hex: this.modelValue,
        };
      } else if (type === 'custom') {
        this.colorValue = color.hex;
        this.dropperColor = color.hex;
        this.paletteColor = type;
        this.isDropper = true;
        this.colors = color;
      } else if (color && type !== 'custom') {
        this.colorValue = color;
        this.paletteColor = type;
        this.isDropper = false;
      } else {
        // eslint-disable-next-line
        this.colorValue =
          'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
      }
    },
    documentClick(e) {
      const el = this.$refs.colorpicker;
      const target = e.target;

      if (el !== target && !el.contains(target)) {
        this.hidePicker();
      }
    },
  },
};
</script>

<style scoped lang="scss" src="./SelectableColorPicker.scss" />
<style lang="scss" src="./SelectableColorPickerChromeHack.scss" />
