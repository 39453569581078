import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_users_list = _resolveComponent("users-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($data.loaded)
      ? (_openBlock(), _createBlock(_component_users_list, {
          key: 0,
          list: $data.list,
          loading: $data.loading,
          "infinite-loading-reset-counter": $data.infiniteLoadingResetCounter,
          onInfiniteScroll: $options.onInfinite,
          onUpdate: $options.loadCustomFieldList
        }, null, 8 /* PROPS */, ["list", "loading", "infinite-loading-reset-counter", "onInfiniteScroll", "onUpdate"]))
      : _createCommentVNode("v-if", true)
  ]))
}