<template>
  <t-table-list-row data-test="table-rows" class="custom-fields-row">
    <t-table-list-cell>
      <div class="table-detail balance-upgrades">
        <div class="t-text-desktop-paragraph-sm sort fade-in truncate text-grey-900">
          {{ item.type === 'CREDITS' ? $t('company_profile.balance_upgrade') : item.type }}
        </div>
      </div>
    </t-table-list-cell>
    <t-table-list-cell v-if="!viewable">
      <div class="t-text-desktop-paragraph-sm font-medium">
        <datetime :time="item.created_at" :pretty="false" />
      </div>
    </t-table-list-cell>
    <t-table-list-cell>
      <div class="tag-name t-text-desktop-paragraph-sm mr-2 text-right text-success-700 lg:mr-0">
        + €{{ item.amount_inc_vat }}
      </div>
    </t-table-list-cell>
    <t-table-list-cell>
      <div class="tag-name t-text-desktop-paragraph-sm text-right text-grey-600">
        <a class="underline" :href="'/client-api/balance/invoice/download/' + item.id" target="_blank">
          {{ !viewable ? $t('company_profile.download_invoice') : $t('company_profile.download') }}
        </a>
      </div>
    </t-table-list-cell>
  </t-table-list-row>
</template>

<script>
export default {
  name: 'BalanceRow',
  props: {
    item: {
      type: Object,
      required: true,
    },
    viewable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped src="../../Transactions.scss" />
