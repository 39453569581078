export const emptyChartData = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      label: '',
      backgroundColor: 'rgb(236,237,239)',
      data: [0, 4, 2, 3, 4, 5, 4, 3, 2, 6, 0],
    },
  ],
};
