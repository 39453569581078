<script setup lang="ts">
import { TextSummaryAlign, TranslationCharacter } from '@trengo/trengo-icons';
import { useI18n } from 'vue-i18n';

import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

import ChannelsList from './ChannelsList.vue';
import { CHANNEL_SETTING } from './useInboxSettingsChannelsStore';

const { t } = useI18n();
const isSummaryListEnabled = useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.SETTINGS_SUMMARY_LIST);
</script>

<template>
  <section class="mx-auto my-6 flex max-w-[820px] flex-col">
    <h1 data-test="settings-inbox-page-title" class="w-full max-w-[300px] truncate text-ellipsis text-xl font-bold">
      {{ t('settings.inbox_page__title') }}
    </h1>
    <div class="mb-8 flex flex-col rounded-xl border-1 border-solid border-[#E1E3E5] bg-white p-6">
      <ChannelsList :setting="CHANNEL_SETTING.TRANSLATION_CHANNELS" data-test="channel-list-translations">
        <template #subtitle>
          {{ t('settings.inbox_page__translation_channels_subtitle') }}
        </template>
        <template #description>
          {{ t('settings.inbox_page__translation_channels_description') }}
        </template>
        <template #icon>
          <TextSummaryAlign size="24px" />
        </template>
      </ChannelsList>
      <div class="w-x-[100% + 1.5rem] -mx-[1.5rem] my-8 h-[1px] bg-grey-300" />
      <ChannelsList
        v-if="isSummaryListEnabled"
        :setting="CHANNEL_SETTING.SUMMARY_CHANNELS"
        data-test="channel-list-summary"
      >
        <template #subtitle>
          {{ t('settings.inbox_page__summary_channels_subtitle') }}
        </template>
        <template #description>
          {{ t('settings.inbox_page__summary_channels_description') }}
        </template>
        <template #icon>
          <TranslationCharacter size="24px" />
        </template>
      </ChannelsList>
    </div>
  </section>
</template>
