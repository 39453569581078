<template>
  <div>
    <div v-if="typeIs(field.field_type, 'DROPDOWN')">
      <t-dropdown
        v-model="computedValue"
        :items="field.items"
        data-hj-suppress
        value-index="title"
        text-index="title"
        :placeholder="field.placeholder || 'Pick an option'"
        :light-version="true"
        size="sm"
        :error="error"
        @blur="onBlur"
      >
        <template #end>
          <div class="flex cursor-pointer flex-row items-center justify-start p-3 text-grey-800">
            <atomic-button size="sm" btn-style="secondary" type="button" @click="computedValue = null">
              Clear
            </atomic-button>
          </div>
        </template>
      </t-dropdown>
    </div>
    <div v-if="typeIs(field.field_type, 'TEXT')">
      <atomic-input
        v-model="computedValue"
        size="sm"
        data-hj-suppress
        :placeholder="field.placeholder"
        :required="field.required"
        :error="error"
        @blur="onBlur"
      />
    </div>
    <div v-if="typeIs(field.field_type, 'CHECKBOX')">
      <div class="flex items-center">
        <t-input-checkbox v-model="field.value" class="mb-0 mr-2" data-hj-suppress @change="save" @blur="onBlur" />
        <span data-hj-suppress class="text-sm font-medium text-grey-500">{{ field.placeholder }}</span>
      </div>
    </div>
    <div v-if="typeIs(field.field_type, 'HYPERLINK')">
      <atomic-input
        v-model="computedValue"
        size="sm"
        data-hj-suppress
        :placeholder="field.placeholder || 'http://www.'"
        :required="field.required"
        prepend-icon="custom-fields/link-icon"
        :error="error"
        @blur="onBlur"
      >
        <template #end>
          <div
            v-if="validUrl(field.value)"
            class="mr-8 flex cursor-pointer items-center text-sm font-semibold text-leaf-500"
            @click="openLink(field.value)"
          >
            <span>Open</span>
            <img :src="$root.assetsURL + 'img/custom-fields/link-open.svg'" class="ml-2 h-15px w-15px" />
          </div>
        </template>
      </atomic-input>
    </div>
    <div v-if="typeIs(field.field_type, 'NUMBER')">
      <atomic-input
        v-model="computedValue"
        size="sm"
        data-hj-suppress
        :placeholder="field.placeholder || '0'"
        :error="error"
        :required="field.required"
        @blur="onBlur"
      />
    </div>
    <div
      v-if="typeIs(field.field_type, 'DATE')"
      class="input-container flex w-full items-center rounded-lg border bg-white"
      :class="{ 'border-error-500 hover:border': error }"
    >
      <img :src="$root.assetsURL + 'img/custom-fields/calendar.svg'" class="ml-4 h-15px w-15px" />
      <datepicker
        v-model="formattedDate"
        data-hj-suppress
        format="d-m-Y"
        :placeholder="field.placeholder || 'Date'"
        class="w-full rounded border-none py-1.5 pl-3 text-sm text-grey-800"
        @blur="onBlur"
      />
    </div>
  </div>
</template>

<script type="text/babel">
import { debounce } from 'lodash';
import validUrlLibrary from 'valid-url';

import Datepicker from '@/components/Elements/DatePicker.vue';
import { CUSTOM_FIELD_TYPE } from '@/Configs/Constants';

export default {
  name: 'CustomFieldItem',
  emits: ['save', 'blur'],
  components: { Datepicker },
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
    error: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      debouncedSave: debounce((data) => {
        this.$emit('save', data);
      }, 200),
    };
  },

  methods: {
    save(data) {
      if (data || !this.field.is_required) {
        this.debouncedSave(data);
      } else {
        this.flashError(this.$t('custom_fields.required_error'));
      }
    },
    typeIs(value, type) {
      return CUSTOM_FIELD_TYPE[type].toUpperCase() === value;
    },
    openLink(url) {
      window.open(url, '', 'resizable,scrollbars,noopener');
    },
    onBlur(e) {
      this.$emit('blur', e);
    },
    validUrl(url) {
      return validUrlLibrary.isWebUri(url);
    },
  },

  computed: {
    formattedDate: {
      get() {
        return this.field.value ? moment(this.field.value).format('DD-MM-YYYY') : '';
      },
      set(val) {
        let formattedDate = '';
        if (val) {
          formattedDate = moment(val, 'DD-MM-YYYY').format();
        }
        this.field.value = formattedDate;
        this.save(formattedDate);
      },
    },
    computedValue: {
      get() {
        return this.field.value;
      },
      set(value) {
        this.field.value = value;
        this.save(value);
      },
    },
  },
};
</script>
