<template>
  <div class="w-full" data-test="prechatMessageContainer">
    <div class="prechat-message-wrapper">
      <div class="mr-4 self-start rounded-xl border-1 border-black border-opacity-10 p-2">
        <ClipboardTextLinear size="1.5rem" />
      </div>

      <div class="align-center flex flex-col flex-wrap justify-center">
        <div
          v-for="(attribute, index) in attributes"
          :key="index"
          class="attribute-wrapper"
          data-test="prechatAttributeWrapper"
        >
          <p class="m-0 p-0">
            <span class="font-bold">{{ attribute.key }}:</span>
            {{ attribute.value }}
          </p>
        </div>
      </div>
    </div>

    <div class="mt-2 flex flex-nowrap items-center text-sm text-grey-600">
      <span class="flex-shrink text-ellipsis" data-test="prechatFormCopyText">{{ $t('tickets.prechat_form') }}</span>

      <span class="mx-1">-</span>

      <span class="flex-shrink-0 text-ellipsis">
        <datetime :time="message.created_at" :pretty="$root.prettyDates" data-test="prechatFormCreatedatTimestamp" />
      </span>

      <span class="flex-shrink-0 pl-2">
        <ClipboardTextLinear size="1.1rem" />
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { ClipboardTextLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PrechatMessage',

  components: {
    ClipboardTextLinear,
  },

  props: {
    message: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    attributes() {
      type PrechatMessageAttributes = {
        key: string;
        value: string;
      };

      const target: PrechatMessageAttributes[] = [];

      // Right now prechat messages aren't treated any differently to normal messages in the backend, as such they come
      // in as a regular message delimited by <br> characters. As such, the only way to actually utilize these in a list
      // is to manually build the object that these message attributes should've been in the first place
      this.message.message.split('<br>').forEach((pair) => {
        if (pair) {
          const splitPair = pair.split(': ');

          if (splitPair[0] && splitPair[1]) {
            target.push({
              key: splitPair[0],
              value: splitPair[1],
            });
          }
        }
      });

      return target;
    },
  },
});
</script>

<style lang="scss" scoped>
.prechat-message-wrapper {
  @apply flex w-fit flex-row rounded-md border-1 border-black border-opacity-10 bg-white px-4 py-3;
  max-width: 80%;
}

.attribute-wrapper {
  @apply my-1;

  &:first-child {
    @apply mb-1 mt-0;
  }
}

@media screen and (min-width: 1920px) {
  .prechat-message-wrapper {
    max-width: 768px;
  }
}
</style>
