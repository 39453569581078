const ONBOARDING_STEPS = [
  'user.create_account',
  'user.assigned_first_ticket',
  'user.internal_comment_on_first_ticket',
  'user.reply_conversation',
  'user.archived_ticket',
  'user.add_first_channel',
  'user.invite_first_team_member',
];

export default ONBOARDING_STEPS;
