<script type="text/babel">
import { toArray } from 'lodash';
import Multiselect from 'vue-multiselect';

import Status from './Status.vue';

export default {
  props: ['record', 'editor'],

  components: {
    Status,
    Multiselect,
  },

  data() {
    return {
      questions: [],
      searchTerm: '',
      loading: false,
      saving: false,
      categories: [],
      searchTimer: null,
      searching: false,
    };
  },
  computed: {
    filteredCategories() {
      return this.categories.filter(
        (category) => this.searchTerm == '' || (this.searchTerm != '' && this.getQuestions(category).length != 0)
      );
    },
  },
  mounted() {
    if (this.record.key_assigned) {
      this.loadQuestions();
      this.loadCategories();
    }
  },

  unmounted() {
    $(this.$refs.formModal).off('hidden.bs.modal');
  },

  beforeRouteLeave(to, from, next) {
    if (this.saving) {
      const answer = window.confirm(this.$t('chatbot.confirmation_leave_unsaved_changes'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  methods: {
    loadQuestions() {
      this.loading = true;
      axios.get('/api/v2/chatbot/' + this.record.id + '/questions?term=' + this.searchTerm).then((res) => {
        this.questions = res.data;
        this.loading = false;

        if (this.searching) {
          this.onSearched();
        }
      });
    },

    onSearched() {
      this.categories.map((category) => {
        category.collapsed = this.getQuestions(category).length == 0 || this.searchTerm == '';
      });

      this.searching = false;
    },

    loadCategories() {
      this.loading = true;
      axios.get('/api/v2/chatbot/' + this.record.id + '/categories').then((res) => {
        this.categories = res.data;
      });
    },

    search() {
      this.questions = [];
      clearTimeout(this.searchTimer);
      this.searching = true;
      this.searchTimer = setTimeout(this.loadQuestions, 250);
    },

    createQuestion(category) {
      category.collapsed = false;

      let question = {
        answer: '',
        category: {
          id: category.id,
          name: category.name,
        },
        category_id: category.id,
        variants: [],
        editing: true,
        saving: false,
      };

      this.questions.unshift(question);
    },

    updateQuestion(question) {
      question.editing = true;
    },

    async deleteQuestion(question, visible = true) {
      if (question.id == null) {
        this.questions.splice(this.questions.indexOf(question), 1);
        return;
      }
      const shouldDeleteQuestion = await this.$tConfirm(this.$t('chatbot.delete_question_confirmation'), {
        delete: true,
        title: this.$t('chatbot.are_you_sure'),
      });
      if (shouldDeleteQuestion) {
        question.deleting = true;
        axios
          .delete('/api/v2/chatbot/' + this.record.id + '/questions/' + question.id)
          .then(() => {
            this.questions.splice(this.questions.map((item) => item.id).indexOf(question.id), 1);
          })
          .catch(() => {
            this.flashError(this.$t('chatbot.something_went_wrong_please_try_again'));
          })
          .finally(() => {
            question.deleting = false;
          });
      }
    },

    addVariant(question) {
      if (question.new_variant == '') {
        return;
      }

      question.variants.push({
        question: question.new_variant,
      });

      question.new_variant = '';
    },

    removeVariant(question, variant) {
      question.variants.splice(question.variants.indexOf(variant), 1);
    },

    persist(question) {
      if (question.new_variant != '' && question.new_variant != null) {
        this.addVariant(question);
      }

      if (question.answer == '' || question.variants.length == 0) {
        return;
      }

      question.saving = true;
      question.category_id = question.category.id;
      let url =
        question.id == null
          ? '/api/v2/chatbot/' + this.record.id + '/questions'
          : '/api/v2/chatbot/' + this.record.id + '/questions/' + question.id;

      axios({
        url: url,
        data: question,
        method: question.id == null ? 'POST' : 'PUT',
      })
        .then((res) => {
          question.id = res.data.id;
          question.saving = false;
          question.editing = false;
          question.category_id = question.category.id;
        })
        .catch(() => {
          this.flashError(this.$t('chatbot.failed_to_save_question'));
          question.saving = false;
        });
    },

    openCategory(category) {
      category.collapsed = !category.collapsed;
    },

    getQuestions(category) {
      return toArray(
        this.questions.filter((q) => {
          return q.category_id == category.id;
        })
      );
    },
  },
};
</script>

<template>
  <div class="pos-relative">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis">
            <span>{{ $t('chatbot.questions') }}</span>
            <small class="text-muted">({{ questions.length }})</small>
          </span>
          <div class="m-l-auto">
            <!--<button class="btn success text-white btn-md" @click="createQuestion()" :disabled="saving">Add question</button>-->
          </div>
        </li>
      </ul>
    </div>

    <div class="p-a p-b-0 container">
      <status :record="record"></status>

      <div>
        <div class="box">
          <div class="box-body">
            <div class="">
              <div>
                <div class="input-group">
                  <span class="input-group-addon" style="width: 42px">
                    <i v-if="!searching" class="fa fa-search"></i>
                    <i v-if="searching" class="fa fa-spin fa-spinner"></i>
                  </span>
                  <input
                    v-model="searchTerm"
                    type="text"
                    class="form-control"
                    :placeholder="$t('chatbot.find_by_question_or_answer')"
                    @keyup="search"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-for="category in filteredCategories" :key="category.name" class="box">
        <div class="box-header">
          <h2 style="cursor: pointer" @click="openCategory(category)">
            <span style="width: 25px; display: inline-block">
              <i
                class="fa m-r-xs"
                :class="{ 'fa-folder-open-o': !category.collapsed, 'fa-folder-o': category.collapsed }"
              ></i>
            </span>
            {{ category.name }}
          </h2>
          <div class="box-tool">
            <ul class="nav">
              <li class="nav-item inline">
                <a class="nav-link" @click="createQuestion(category)">
                  <a class="btn btn-xs btn-default"><i class="fa fa-fw fa-plus"></i></a>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="box-divider m-a-0"></div>
        <div
          v-for="(question, index) in getQuestions(category)"
          v-show="!category.collapsed"
          :key="index"
          class="b-b p-b p-a display-flex"
          :class="{ 'chatbot-editor-active-question': question.editing == true }"
          style="border-left: 4px solid #ffffff"
        >
          <div class="p-r-2" style="flex: 1">
            <div v-if="question.editing == true" class="pos-relative">
              <form class="" @submit.prevent>
                <div v-if="question.editing == true" class="row form-group">
                  <label class="col-md-2" style="padding-top: 6px">{{ $t('chatbot.category') }}</label>
                  <div class="col-md-10">
                    <multiselect
                      v-model="question.category"
                      label="name"
                      track-by="id"
                      :allow-empty="false"
                      :options="categories"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      :multiple="false"
                      :placeholder="$t('chatbot.select_a_category')"
                    ></multiselect>
                  </div>
                </div>

                <div class="row form-group">
                  <label class="col-md-2" style="padding-top: 6px">{{ $t('chatbot.question') }}</label>
                  <div class="col-md-10">
                    <input
                      ref="variantInput"
                      v-model="question.new_variant"
                      type="text"
                      class="form-control"
                      :disabled="saving"
                      :placeholder="$t('chatbot.type_what_the_user_might_say_and_press_enter')"
                      style="border-bottom-left-radius: 0; border-bottom-right-radius: 0"
                      @keyup.enter="addVariant(question)"
                    />
                    <div class="list-group">
                      <div v-for="(variant, i) in question.variants" :key="i" class="list-group-item">
                        <i class="fa fa-quote-left m-r-xs"></i>
                        <span v-text="variant.question"></span>
                        <span class="pull-right text-muted" style="opacity: 0.5">
                          <a @click="removeVariant(question, variant)"><i class="fa fa-trash"></i></a>
                        </span>
                      </div>
                    </div>

                    <div
                      v-show="question.variants.length > 0 && question.variants.length < 5"
                      style="
                        background: #e4e4e4;
                        padding: 3px 10px;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                        text-align: center;
                        color: #797979;
                      "
                    >
                      {{ $t('chatbot.type_about_5_examples_to_optimize_the_chatbot') }}
                    </div>
                  </div>
                </div>

                <div class="row form-group">
                  <label class="col-md-2" style="padding-top: 6px">{{ $t('chatbot.answer') }}</label>
                  <div class="col-md-10">
                    <textarea v-model="question.answer" type="text" class="form-control" rows="4"></textarea>
                  </div>
                </div>

                <!--<div class="form-group m-b-0">-->
                <!--<a class="btn btn-default" @click="close">Close</a>-->
                <!--<a class="btn " @click="editing = false">Cancel</a>-->
                <!--</div>-->
              </form>
            </div>
            <div v-else class="v-align">
              <span v-for="(variant, i) in question.variants" :key="i">
                <strong v-show="variant.question != ''" class="block" v-text="variant.question"></strong>
              </span>
              <div class="text-muted" v-text="question.answer"></div>
            </div>
          </div>
          <div class="m-l-auto">
            <div :class="{ loading: question.saving }">
              <a
                v-if="question.editing == true"
                class="btn btn-default btn-xs"
                :class="{ loader: question.saving == true }"
                @click="persist(question)"
              >
                <i class="fa fa-check"></i>
              </a>
              <a v-if="!question.editing" class="btn btn-default btn-xs" @click="updateQuestion(question)">
                <i class="fa fa-pencil"></i>
              </a>
              <a
                class="btn btn-default btn-xs"
                :class="{ loader: question.deleting }"
                @click="deleteQuestion(question)"
              >
                <i v-show="question.deleting" class="fa fa-spin fa-spinner"></i>
                <i v-show="!question.deleting" class="fa fa-trash"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.chatbot-editor-active-question {
  background: #f1f1f1;
  border-top: 1px solid #e7e7e7;
  border-left: 4px solid #14b29f;
}
</style>
