<template>
  <square-loader
    :width="width"
    :height="height"
    :animation="animation"
    :type="type"
    :color="color"
    :border-radius="borderRadius"
  />
</template>

<script>
import SquareLoader from './Straight.vue';
export default {
  components: {
    SquareLoader,
  },
  props: {
    type: {
      type: String,
      default: 'rect',
    },
    animation: {
      type: String,
      default: 'wave',
    },
    height: {
      type: [Number, String],
      default: '22px',
    },
    width: {
      type: [Number, String],
      default: '100%',
    },
    color: {
      type: String,
      default: '#EAEAEA',
    },
    waveColor: {
      type: String,
    },
    borderRadius: {
      type: [Number, String],
      default: '12px',
    },
  },
  mounted() {
    if (this.waveColor) {
      this.$el.style.setProperty('--gradient-color', this.waveColor);
    }
  },
};
</script>

<style lang="scss" scoped src="./SkeletonLoader.scss" />
