<template>
  <div>
    <div class="row action flex flex-col py-4 md:flex-row">
      <div class="col-md-3 mb-1 md:mb-0">
        <div class="row">
          <div class="col-xs-2 pt-2 text-center" style="padding-right: 0">
            <i class="far fa-fw fa-sitemap"></i>
          </div>

          <div class="col-xs-10 pt-2">
            <span class="pt-2">
              {{ $t('widget_automation.page_flowbot_select_which_flowbot_should_become_active') }}
            </span>
          </div>
        </div>
      </div>

      <div class="col-md-2 mb-1 md:mb-0">
        <select class="form-control" v-model="action.payload.flowbotId">
          <option v-for="option in bots" :value="option.id">
            {{ option.text }}
          </option>
        </select>
      </div>
    </div>

    <div class="row action flex flex-col py-4 md:flex-row">
      <div class="col-md-3 mb-1 md:mb-0">
        <div class="row">
          <div class="col-xs-2 pt-2 text-center" style="padding-right: 0">
            <i class="material-icons">directions</i>
          </div>

          <div class="col-xs-10 pt-2">
            <span class="pt-2">
              {{ $t('widget_automation.page_flowbot_select_at_which_step_the_flowbot_should_start') }}
            </span>
          </div>
        </div>
      </div>

      <div class="col-md-2 mb-1 md:mb-0">
        <select class="form-control" v-model="action.payload.flowbotStepId">
          <option v-for="(option, index) in steps" :value="option.id">
            {{ option.text }}{{ index === 0 ? ' (default)' : '' }}
          </option>
        </select>
      </div>
    </div>

    <!--<div class="row flex flex-col md:flex-row action py-4">
        <div class="col-md-3 mb-1 md:mb-0">
            <div class="row">
                <div class="col-xs-2 text-center pt-2" style="padding-right: 0">
                    <i class="material-icons">play_arrow</i>
                </div>

                <div class="col-xs-10 pt-2">
                    <label for="autoStartFlowbot">Automatically start the Flowbot?</label>
                </div>
            </div>
        </div>

        <div class="col-md-2 pt-1 mb-1 md:mb-0">
            <label class="ui-switch success m-t-xs m-r"><input id="autoStartFlowbot" v-model="action.payload.autoStart" type="checkbox" :value="true"><i></i></label>
        </div>
    </div>-->
  </div>
</template>

<script>
export default {
  name: 'SetFlowbot',

  props: {
    action: Object,
    actionType: Object,
  },

  data() {
    return {
      bots: [],
      steps: [],
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      // get bots
      axios.get('/api/v2/flowbot?onlyActive=1').then((r) => {
        this.bots = r.data.map((flow) => {
          return { id: flow.id, text: flow.title, steps: flow.steps };
        });

        if (!this.action.payload.flowbotId && this.bots.length) {
          this.action.payload.flowbotId = this.bots[0].id;
        }

        const currentBot = this.bots.find((bot) => bot.id === this.action.payload.flowbotId);
        if (!currentBot) {
          return;
        }

        this.steps =
          currentBot.steps.map((step) => {
            return { id: step.id, text: step.title };
          }) || [];

        if (!this.action.payload.flowbotStepId && this.steps.length) {
          this.action.payload.flowbotStepId = this.steps[0].id;
        }
      });
    },
  },

  watch: {
    'action.payload.flowbotId'(v) {
      if (!v) {
        return;
      }

      const currentBot = this.bots.find((bot) => bot.id === v);
      if (!currentBot) {
        return;
      }

      this.steps =
        currentBot.steps.map((step) => {
          return { id: step.id, text: step.title };
        }) || [];

      if (!this.action.payload.flowbotStepId && this.steps.length) {
        this.action.payload.flowbotStepId = this.steps[0].id;
      }

      this.action.payload.introText = currentBot.web_chat_start_message || '';
    },
  },

  computed: {},

  components: {},
};
</script>

<style scoped></style>
