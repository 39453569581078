<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import {
  TwoFactorAuthSetupModal,
  deleteTrustedDevice,
  deleteTwoFactorAuthentication,
  getTwoFactorAuthenticationSettings,
  getTwoFactorQRCode,
} from '@/components/common/TwoFactorAuthSetup';
import { formatDateTime } from '@/util/date';

import type { Device, Data } from './types';

export default defineComponent({
  name: 'TwoFactorAuth',
  components: { TwoFactorAuthSetupModal },
  data(): Data {
    return {
      isEnabled: false,
      isModalOpen: false,
      isSaving: false,
      qr: null,
      secret: null,
      trustedDevices: [],
    };
  },
  computed: {
    ...mapGetters({
      isMFAMandatory: 'initialData/isMFAMandatory',
    }),
    description() {
      return `${this.$t('user_profile.general_two_factor_authentication_with_google_authenticator')} ${
        this.isMFAMandatory ? this.$t('user_profile.general_two_factor_authentication_mandatory_description') : ''
      }`.trim();
    },
  },

  mounted() {
    this.updateState();
  },

  methods: {
    updateState() {
      getTwoFactorAuthenticationSettings().then((res) => {
        this.isEnabled = res.data.enabled;
        this.trustedDevices = res.data.trusted_devices;
      });
    },
    setup2FA() {
      this.isModalOpen = true;
      this.getQR();
    },

    getQR() {
      getTwoFactorQRCode().then((res) => {
        this.qr = res.data.image;
        this.secret = res.data.secret;
      });
    },

    handleClose() {
      this.isModalOpen = false;
    },

    disable2FA() {
      if (this.isMFAMandatory) return;
      deleteTwoFactorAuthentication();
      this.flashSuccess(this.$t('user_profile.2fa_disabled_successfully'));
      this.isEnabled = false;
    },

    deleteTrustedDevice(device: Device) {
      this.trustedDevices.splice(this.trustedDevices.indexOf(device), 1);
      this.flashSuccess(this.$t('user_profile.2fa_deleted_trusted_device_successfully'));
      deleteTrustedDevice(device.id);
    },
    formatDateTime,
  },
});
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <div class="box">
        <div class="box-header b-b">
          <h2>{{ $t('user_profile.general_two_factor_auth') }}</h2>
        </div>
        <div class="box-body">
          <p v-html="this.description" />
          <hr />
          <t-button v-if="!isEnabled" @click="setup2FA">{{ $t('user_profile.2fa_setup') }}</t-button>
          <t-button v-else-if="isEnabled && isMFAMandatory" btn-style="secondary" @click="setup2FA">
            {{ $t('user_profile.2fa_reset') }}
          </t-button>
          <t-button v-else-if="isEnabled && !isMFAMandatory" btn-style="danger" @click="disable2FA">
            {{ $t('user_profile.2fa_disable') }}
          </t-button>
        </div>
      </div>
    </div>

    <div v-if="isEnabled && trustedDevices.length > 0" class="col-md-12">
      <div class="box">
        <div class="box-header b-b">
          <h2>{{ $t('user_profile.2fa_trusted_devices') }}</h2>
        </div>
        <div class="box-body">
          <span>
            {{ $t('user_profile.2fa_usage_description') }}
          </span>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th width="70%">{{ $t('user_profile.2fa_device') }}</th>
              <th width="20%">{{ $t('user_profile.2fa_device_created_at') }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="device in trustedDevices" :key="device.id">
              <td>{{ device.device }}</td>
              <td>
                {{
                  /* @ts-ignore */
                  formatDateTime(device.created_at)
                }}
              </td>
              <td>
                <button class="btn btn-white btn-xs text-muted" @click="deleteTrustedDevice(device)">
                  {{ $t('user_profile.2fa_device_delete') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <two-factor-auth-setup-modal
      :is-open="isModalOpen"
      :is-reset-flow="isEnabled"
      :qr="qr"
      :secret="secret"
      @close="handleClose"
      @success="updateState"
    />
  </div>
</template>
