<template>
  <div class="flex w-full flex-row justify-between">
    <div class="flex w-full flex-row items-center justify-between">
      <div class="flex flex-row items-center sm:w-5/6">
        <span class="mr-2 w-24px">
          <img :src="channelImage" :alt="channel.text" width="24px" />
        </span>
        <span :class="computedTextClass" class="t-text-desktop-paragraph-sm m-0 truncate text-ellipsis p-0 sm:w-full">
          {{ channel.title }}
        </span>
      </div>
      <popover-menu
        v-if="removeType === 'icon'"
        v-model="optionsDropdownIsOpen"
        @open="handleOpenDropDown"
        @close="handleCloseDropDown"
      >
        <div
          data-test="remove-channel"
          class="flex cursor-pointer flex-row items-center justify-start rounded-lg px-2 py-1 hover:bg-grey-200"
          @click="deleteChannel()"
        >
          <trash-linear size="1.25rem" class="mr-1 text-error-500" />
          <div class="t-text-desktop-paragraph-sm text-error-500">{{ $t('team.remove_access_button') }}</div>
        </div>
      </popover-menu>
      <t-button
        v-if="removeType === 'button' && channelIsActivatedInOtherTeam"
        size="sm"
        btn-style="secondary"
        @click="deleteChannel()"
      >
        {{ $t('team.remove_channel') }}
      </t-button>
    </div>
  </div>
</template>

<script>
import { TrashLinear } from '@trengo/trengo-icons';

import { ChannelsIconsMapper } from '@/util/ChannelsUtils';

import PopoverMenu from '../PopoverMenu';

export default {
  name: 'ChannelRow',
  emits: ['remove'],
  components: { PopoverMenu, TrashLinear },
  data() {
    return {
      optionsDropdownIsOpen: false,
    };
  },
  props: {
    removeType: {
      type: String,
      default: 'icon',
      validate: (value) => ['icon', 'button'].includes(value),
    },
    channel: {
      type: Object,
      default: () => ({}),
    },
    teamIsEnabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleOpenDropDown() {
      this.optionsDropdownIsOpen = true;
    },
    handleCloseDropDown() {
      this.optionsDropdownIsOpen = false;
    },
    deleteChannel() {
      this.$emit('remove', this.channel);
    },
  },
  computed: {
    channelIsActivatedInOtherTeam() {
      return this.teamIsEnabled && this.channel.workloadBalancingTeams?.length > 0;
    },
    computedTextClass() {
      return this.channelIsActivatedInOtherTeam ? 'row-text-disabled' : 'row-text';
    },
    channelImage() {
      const channels_svg = ChannelsIconsMapper();
      return channels_svg[this.channel.type];
    },
  },
};
</script>

<style src="./ChannelRow.scss" lang="scss" scoped />
