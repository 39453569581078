<template>
  <div class="flex flex-wrap" style="gap: 0.5rem">
    <div
      v-for="file in message.attachments"
      class="message-attachment relative inline-block select-none overflow-hidden border border-grey-300"
      style="border-radius: 12px"
      :style="small ? 'width: 178px; height: 148px;' : 'width: 352px; height: 296px;'"
    >
      <!-- file previews -->
      <a
        @click="attachmentClicked"
        class="open-external block h-full w-full"
        :href="file.getPreviewUrl()"
        target="_blank"
        v-if="file.isImage()"
      >
        <img
          @load="attachmentImageLoaded(message)"
          class="h-full w-full"
          style="object-fit: cover"
          :src="file.getPreviewUrl()"
          alt="Shared image"
        />
      </a>
      <video
        @play="onPlay"
        @ended="onStop"
        @pause="onPause"
        ref="playable"
        class="h-full w-full"
        style="object-fit: cover"
        v-else-if="file.isVideo()"
      >
        <source :src="file.getPreviewUrl()" />
      </video>
      <audio
        @play="onPlay"
        @ended="onStop"
        @pause="onPause"
        ref="playable"
        class="h-full w-full"
        :src="file.getPreviewUrl()"
        v-else-if="file.isAudio()"
      ></audio>
      <!--            <iframe sandbox class="w-full h-full" :src="file.getPreviewUrl()" v-else-if="file.isPdf()"></iframe>-->
      <div class="h-full w-full bg-white" v-else>
        <i
          class="fa fa-8x block text-grey-200"
          :class="file.getIconClass()"
          style="left: 50%; position: absolute; top: 50%; transform: translate(-50%, -50%)"
        ></i>
      </div>

      <!-- play button for audio and video -->
      <button
        v-if="file.isAudio() || file.isVideo()"
        @click="playPause"
        :class="{ isPlaying: isPlaying }"
        class="absolute rounded bg-black text-white"
        style="left: 50%; top: 50%; transform: translate(-50%, -50%); z-index: 99"
        :style="small ? 'height: 44px; width: 44px;' : 'width: 72px; height: 72px; px-2 py-4'"
      >
        <i class="fa fa-fw" :class="{ 'fa-play': !isPlaying, 'fa-pause': isPlaying, 'fa-2x': !small }"></i>
      </button>

      <!-- file name, size, and link -->
      <a
        @click="attachmentClicked"
        v-if="!file.isImage()"
        class="open-external absolute flex flex-row border border-grey-200 bg-white"
        :class="{ 'mx-8 mb-2 px-4 py-3': !small, 'm-1 p-2': small }"
        :style="
          small ? 'width: calc(100% - .5rem); border-radius:8px;' : 'width: calc(100% - 4rem); border-radius:12px;'
        "
        style="bottom: 0; line-height: 1; box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05), 0px 13px 64px rgba(0, 0, 0, 0.1)"
        :href="file.getPreviewUrl()"
        target="_blank"
      >
        <i class="fa block text-grey-300" :class="{ 'fa-2x': !small, [file.getIconClass()]: true }"></i>
        <div class="ml-3 flex flex-col overflow-hidden">
          <strong class="text-ellipsis" :class="{ 'mb-1': !small, 'md-16': small }">{{ file.getName() }}</strong>
          <small class="text-grey-500" v-if="!small">{{ file.getSize() }}</small>
        </div>
      </a>

      <!-- hover menu top-right -->
      <div v-if="!isParentMessage" class="attachment-actions">
        <a
          class="attachment-actions-button open-external inline-block text-center"
          :href="file.getUrl()"
          target="_blank"
          v-tooltip="{ content: $t('internal_chat.download_file') }"
        >
          <i class="far fa-fw fa-cloud-download" style="vertical-align: bottom"></i>
        </a>
        <!--<button class="attachment-actions-button" v-tooltip="{content: $t('internal_chat.message_dropdown_share')}">
                    <i class="far fa-fw fa-share"></i>
                </button><button class="attachment-actions-button" v-tooltip="{content: $t('tickets.tooltip_more_options')}">
                    <i class="far fa-fw fa-ellipsis-v"></i>
                </button>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageAttachments',
  props: ['message', 'isParentMessage', 'small'],
  inject: ['attachmentImageLoaded'],
  data() {
    return {
      isPlaying: false,
    };
  },
  methods: {
    playPause() {
      if (this.isParentMessage) {
        return;
      }

      let el = this.$refs?.playable?.[0];
      if (!el) {
        return;
      }

      if (this.isPlaying) {
        el.pause();
      } else {
        el.play();
      }
    },
    onPlay() {
      this.isPlaying = true;
    },
    onStop() {
      this.isPlaying = false;
    },
    onPause() {
      this.isPlaying = false;
    },
    attachmentClicked() {
      // stop playing when opening link
      if (this.isPlaying) {
        this.playPause();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.message-attachment {
  .isPlaying {
    display: none;
  }

  &:hover .isPlaying {
    display: block;
  }

  &:hover .attachment-actions {
    display: block;
  }
  .attachment-actions {
    display: none;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 10;
    color: var(--color-leaf-900);
    position: absolute;
    background: var(--color-white);
    border: 1px solid var(--color-grey-200);
    padding: 8px 6px;
    height: 48px;
    border-radius: 12px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.02), 0px 4px 12px rgba(0, 0, 0, 0.1);

    .attachment-actions-button {
      vertical-align: bottom;
      color: var(--color-leaf-900);
      margin: 0 2px;
      padding: 0;
      background: var(--color-white);
      width: 32px;
      height: 32px;
      border-radius: 4px;

      &:hover,
      &:active,
      &:focus {
        background: var(--color-grey-100);
      }
    }
  }
}

@media (max-width: 350px) {
  .message-attachment {
    max-width: 200px !important;
    width: 200px !important;
  }
  .message-attachment-single {
    max-width: 100% !important;
    width: 100% !important;
  }
}
</style>
