import dompurify from 'dompurify';

/**
 * Passes an I18n string that might contain dangerous elements through a sanitizer, finally adding the items in the ADD_ATTR
 * array into the sanitized output
 * @param markup The markup received from the I18n service to be parsed and sanitized
 */
export default function markupSanitizer(markup: any): string | Node {
  return dompurify.sanitize(markup, { ADD_ATTR: ['target', 'rel', 'type', 'referrerpolicy', 'hreflang'] });
}
