<script type="text/babel">
export default {
  emits: ['articlesSelected'],
  props: {
    helpCenter: {
      type: Object,
      required: true,
    },
    localeCodes: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      articles: [],
      loading: false,
      selected: [],
    };
  },

  mounted() {
    $(this.$refs.modal).on('shown.bs.modal', () => {
      this.loading = true;
      this.articles = [];
      axios.get('/api/v2/help_center/' + this.helpCenter.id + '/articles/detached').then((res) => {
        this.articles = res.data;
        this.loading = false;
      });
    });
  },

  methods: {
    save() {
      this.$emit('articlesSelected', {
        articles: this.selected,
      });

      $(this.$refs.modal).modal('hide');
    },

    newArticle() {
      $(this.$refs.modal).modal('hide');
      router.push('/help_center/' + this.helpCenter.id + '/articles/create');
    },
  },
};
</script>

<template>
  <div id="ArticleSelectorModal" ref="modal" class="modal">
    <form @submit.prevent="save">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('help_center.insert_articles') }}</h5>
            <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="loading" class="text-muted text-center">{{ $t('help_center.loading_your_articles') }}</div>
            <div v-if="!loading && articles.length == 0" class="p-a-2 text-center">
              <span class="text-muted">{{ $t('help_center.write_new_articles_to_add_more_to_this_category') }}</span>
              <br />
              <a class="btn btn-success m-t-1 text-white" @click="newArticle">{{ $t('help_center.new_article') }}</a>
            </div>
            <div class="list-group m-b">
              <div v-for="article in articles" class="list-group-item">
                <label class="display-flex v-align">
                  <div class="m-r-1">
                    <input v-model="selected" type="checkbox" :value="article" />
                  </div>
                  <div>
                    <div v-for="lang in article.translations" style="padding-bottom: 10px">
                      <small class="text-muted">
                        {{ localeCodes[lang.locale_code].text }}:
                        <b v-if="lang.published" class="label label-xs success status-label rounded"></b>
                        <b v-if="!lang.published" class="label label-xs warning status-label rounded"></b>
                        <br />
                      </small>
                      <span v-if="lang.title == ''" class="text-muted">
                        <em>{{ $t('help_center.missing_translation') }}</em>
                      </span>
                      <span v-else>
                        {{ lang.title }}
                      </span>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div v-if="articles.length > 0" class="modal-footer">
            <button type="button" class="btn white" data-dismiss="modal" aria-label="Close">
              {{ $t('general.cancel') }}
            </button>
            <button type="submit" class="btn btn-success text-white" :disabled="selected.length == 0">
              {{ $t('help_center.insert_articles') }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
