<template>
  <li class="list-none">
    <t-popover v-model="isOpen" class="relative" click-outside-close>
      <template #trigger>
        <t-list-item :label="$t('reports_v2.conversation_direction')" class="relative" :active="isOpen">
          <template #postfix>
            <caret-down-fill size="1.25rem" class="mt-1 -rotate-90 text-grey-600" />
          </template>
        </t-list-item>
      </template>

      <template #content>
        <div
          class="
            filter-dropdown-second-layer
            absolute
            z-50
            mt-2
            w-64
            rounded-xl
            border border-grey-300
            bg-white
            pb-2
            pt-2
            shadow-500
          "
        >
          <ul class="max-h-[335px] w-full px-2">
            <t-input-radio v-model="value" name="conversation-direction" value="ALL" @change="handleSelectItem">
              {{ $t('reports_v2.all') }}
            </t-input-radio>
            <t-input-radio v-model="value" name="conversation-direction" value="OUTBOUND" @change="handleSelectItem">
              {{ $t('reports_v2.agent_initiated') }}
            </t-input-radio>
            <t-input-radio v-model="value" name="conversation-direction" value="INBOUND" @change="handleSelectItem">
              {{ $t('reports_v2.customer_initiated') }}
            </t-input-radio>
          </ul>
        </div>
      </template>
    </t-popover>
  </li>
</template>

<script lang="ts">
import { CaretDownFill } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConversationDirection',
  emits: ['filter-updated'],
  components: { CaretDownFill },
  data() {
    return {
      isOpen: false,
      value: '',
    };
  },
  props: {
    activeDirection: {
      type: String,
      default: 'ALL',
    },
  },
  mounted() {
    this.value = this.activeDirection || 'ALL';
  },
  methods: {
    handleSelectItem(event: Event) {
      if (event.target) {
        this.$emit('filter-updated', { direction: event.target.value });
      }
    },
  },
});
</script>
