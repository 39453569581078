import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3454654d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "channel-confirmation-modal__body" }
const _hoisted_2 = {
  key: 0,
  class: "channel-confirmation-modal-image"
}
const _hoisted_3 = {
  ref: "mp4_video",
  loop: ""
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "channel-confirmation-modal__sub-content"
}
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_linear = _resolveComponent("info-linear")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_modal = _resolveComponent("t-modal")!

  return (_openBlock(), _createBlock(_component_t_modal, {
    modelValue: $options.isModalOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.isModalOpen) = $event)),
    variant: "narrow",
    title: $props.channel.title,
    onClose: $options.handleClose
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_t_button, {
        size: "md",
        "btn-style": "secondary",
        onClick: $options.handleSkip
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.channel.cancel_btn), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"]),
      _createVNode(_component_t_button, {
        size: "md",
        onClick: $options.handleCreate
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.channel.success_btn), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString($props.channel.body), 1 /* TEXT */),
      ($data.gifImage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("video", _hoisted_3, [
              _createElementVNode("source", {
                src: $data.gifImage,
                type: "video/mp4"
              }, null, 8 /* PROPS */, _hoisted_4)
            ], 512 /* NEED_PATCH */)
          ]))
        : _createCommentVNode("v-if", true),
      ($props.channel.sub_content)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString($props.channel.sub_content), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_t_inline_banner, { class: "items-start" }, {
        icon: _withCtx(() => [
          _createElementVNode("span", null, [
            _createVNode(_component_info_linear, { class: "channel-confirmation-modal__icon" })
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("p", {
            class: "channel-confirmation-modal__info",
            innerHTML: $props.channel.info
          }, null, 8 /* PROPS */, _hoisted_6)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title", "onClose"]))
}