<template>
  <div>
    <div class="mb-6">
      <div class="flex flex-col content-end">
        <div class="mb-4 flex flex-col">
          <span class="mb-1 text-base font-semibold text-grey-900">
            {{ $t('company_profile.subscription_page_name') }}
          </span>

          <span class="text-sm font-medium text-grey-700">
            {{ $t('company_profile.subscription_page_name_this_set_of_business_hours') }}
          </span>
        </div>

        <div class="w-full">
          <input
            v-model="localRecord.name"
            class="h-12 w-full rounded-xl border border-grey-200 px-4 py-2"
            placeholder="e.g. Default opening hours"
            type="text"
            autocomplete="off"
          />
        </div>
      </div>
    </div>

    <div class="mb-6">
      <div class="flex flex-col content-end">
        <div class="mb-1 flex flex-col">
          <span class="mb-1 text-base font-semibold text-grey-900">
            {{ $t('company_profile.subscription_page_timezone') }}
          </span>
        </div>

        <div class="w-full">
          <t-dropdown
            v-model="localRecord.timezone"
            :items="timezones.map((item) => ({ value: item, text: item }))"
          ></t-dropdown>
        </div>
      </div>
    </div>

    <div class="mb-6">
      <div class="flex flex-col content-end">
        <div class="mb-4 flex flex-col">
          <span class="mb-1 text-base font-semibold text-grey-900">
            {{ $t('company_profile.subscription_page_channels') }}
          </span>

          <span class="text-sm font-medium text-grey-700">
            {{ $t('company_profile.subscription_page_select_the_applicable_channels_for_these_business_hours') }}
          </span>
        </div>

        <div class="w-full">
          <t-multi-select
            v-model="computedChannelModel"
            :use-icon="true"
            :placeholder="$t('company_profile.subscription_page_placeholder_search_for_channels')"
            size="md"
            container-size="md"
            variant="sky"
            :height="300"
            :has-avatar="false"
            :data="computedChannels"
          >
            <template #option="{ option }: { option: MappedListItem }">
              <div class="flex items-center">
                <span class="text-ellipsis mr-2">
                  {{ option.text }}
                </span>

                <span v-if="option.$isDisabled" class="ml-auto text-ellipsis max-w-[33%]">
                  {{ getUnavailableChannelName(option.id) }}
                </span>
              </div>
            </template>
          </t-multi-select>
        </div>
      </div>
    </div>

    <div class="mb-1">
      <div class="flex flex-col content-end">
        <div class="mb-4 flex flex-col">
          <span class="mb-1 text-base font-semibold text-grey-900">
            {{ $t('company_profile.subscription_page_description') }}
          </span>

          <span class="text-sm font-medium text-grey-700">
            {{ $t('company_profile.subscription_page_a_description_for_the_business_hours') }}
          </span>
        </div>

        <div class="w-full">
          <input
            v-model="localRecord.description"
            :placeholder="$t('company_profile.subscription_page_placeholder_eg_summer_schedule')"
            class="h-12 w-full rounded-xl border border-grey-200 px-4 py-2"
            type="text"
            autocomplete="off"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';

import { ChannelsIconsMapper } from '@/util/ChannelsUtils';

import type { Channel } from '@/types';
import type { BusinessHours, UnavailableChannel } from '@/types/businessHours';
import type { PropType } from 'vue';

type MappedListItem = Channel & {
  icon: string;
  $isDisabled: boolean;
  linkedBusinessHour: string;
};

export default {
  name: 'FirstStep',

  props: {
    timezones: {
      type: Array,
      default: () => [],
    },

    record: {
      type: Object as PropType<BusinessHours>,
      default: () => ({}),
    },

    channels: {
      type: Array as PropType<Channel[]>,
      default: () => [],
    },

    isCreate: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['change'],

  data() {
    return {
      localRecord: {},
    };
  },

  computed: {
    ...mapGetters({
      unavailableChannels: 'business_hours/unavailableChannels',
    }),

    computedChannelModel: {
      get() {
        return this.mapChannels(this.localRecord.channels);
      },

      set(value: unknown) {
        this.localRecord.channels = value;
        this.emitInput(this.localRecord);
      },
    },

    computedChannels() {
      return Object.keys(this.channels) ? this.mapChannels(this.channels) : false;
    },
  },

  watch: {
    localRecord: {
      handler(value) {
        this.emitInput(value);
      },
      deep: true,
    },
  },

  mounted() {
    this.localRecord = cloneDeep(this.record);
  },

  methods: {
    handleChangeChannels(value: unknown) {
      this.localRecord.channels = value;
      this.emitInput(this.localRecord);
    },

    getTimezone(v: string) {
      this.localRecord.timezone = v;
    },

    emitInput(record: unknown) {
      this.$emit('change', record);
    },

    getUnavailableChannelName(channelId: number) {
      let linkedHour: string;

      // If creating a new business hour, use global/Vuex unavailable channels list
      if (this.isCreate) {
        linkedHour = this.unavailableChannels?.find(
          (channel: UnavailableChannel) => channel.channel_id === channelId,
        )?.linked_to_business_hour;
      } else {
        linkedHour =
          this.record?.unavailable_channels?.find((channel: UnavailableChannel) => channel.channel_id === channelId)
            ?.linked_to_business_hour ?? '';
      }

      return linkedHour ? this.$t('settings.used_in_channel', { channel: linkedHour }) : '';
    },

    // Returns true if business hour belongs to a different channel to disable option selection
    checkBusinessId(businessHourId: BusinessHours['id'] | null) {
      if (!businessHourId) {
        return false;
      }

      return businessHourId !== this.record.id;
    },

    mapChannels(list: Channel[]): MappedListItem[] {
      if (!list) {
        return [];
      }

      const channels_svg = ChannelsIconsMapper();

      const dropdownList: MappedListItem[] = list.map((item) => ({
        ...item,
        name: item?.text ?? '',
        icon: channels_svg[item.type],
        // $isDisabled comes from the vue-multiselect library which trengo-ui utilizes
        // https://github.com/shentao/vue-multiselect/issues/176
        $isDisabled: this.checkBusinessId(item.business_hour_id),
        linkedBusinessHour: this.checkBusinessId(item.business_hour_id) ? this.record.name : '',
      }));

      // Sort the list items so that the disabled entries are on the bottom of the list
      return dropdownList.sort((a, b) => {
        if (a.$isDisabled === b.$isDisabled) return 0;

        return a.$isDisabled ? 1 : -1;
      });
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.multiselect__element:has(.multiselect__option--disabled)) {
  @apply cursor-not-allowed;
}

:deep(.multiselect__option--disabled) {
  // https://github.com/tailwindlabs/discuss/issues/224#issuecomment-463616957
  @apply bg-grey-100 #{!important};
}
</style>
