<template>
  <div v-show="loaded" class="row-col">
    <div id="settings-third" class="col-xs modal fade aside aside-md b-r w-medium w-200px">
      <div class="nav-settings light left b-primary row-col">
        <ul>
          <li class="text-md white header display-flex">
            <span class="text-truncate">{{ record.title }}</span>
            <a data-dismiss="modal" class="hidden-md-up text-muted m-l-auto m-r-sm text-lg">×</a>
          </li>
        </ul>
        <div class="row-row">
          <div class="row-body scrollable hove">
            <div class="row-inner">
              <ul>
                <li>
                  <a :class="{ active: tab == 'general' }" @click="tab = 'general'">
                    <i class="material-icons md-18">keyboard_arrow_right</i>
                    {{ $t('web_widget.settings') }}
                  </a>
                </li>
                <li>
                  <a
                    :class="{ active: tab == 'whatsapp' }"
                    @click="
                      tab = 'whatsapp';
                      this.filterChannels(channelType.WHATSAPP);
                    "
                  >
                    <i class="material-icons md-18">keyboard_arrow_right</i>
                    {{ $t('web_widget.whatsapp') }}
                    <label v-show="!record.whatsapp.enabled" class="label m-l-xs m-b-0">
                      {{ $t('web_widget.inactive') }}
                    </label>
                  </a>
                </li>
                <li>
                  <a
                    :class="{ active: tab == 'facebook' }"
                    @click="
                      tab = 'facebook';
                      this.filterChannels(channelType.FACEBOOK);
                    "
                  >
                    <i class="material-icons md-18">keyboard_arrow_right</i>
                    {{ $t('web_widget.facebook') }}
                    <label v-show="!record.facebook.enabled" class="label m-l-xs m-b-0">
                      {{ $t('web_widget.inactive') }}
                    </label>
                  </a>
                </li>
                <li>
                  <a
                    :class="{ active: tab == 'telegram' }"
                    @click="
                      tab = 'telegram';
                      this.filterChannels(channelType.TELEGRAM);
                    "
                  >
                    <i class="material-icons md-18">keyboard_arrow_right</i>
                    {{ $t('web_widget.telegram') }}
                    <label v-show="!record.telegram.enabled" class="label m-l-xs m-b-0">
                      {{ $t('web_widget.inactive') }}
                    </label>
                  </a>
                </li>
                <li>
                  <a :class="{ active: tab == 'help_center' }" @click="tab = 'help_center'">
                    <i class="material-icons md-18">keyboard_arrow_right</i>
                    {{ $t('web_widget.help_center') }}
                    <label v-show="!record.help_center.enabled" class="label m-l-xs m-b-0">
                      {{ $t('web_widget.inactive') }}
                    </label>
                  </a>
                </li>
                <li>
                  <a
                    :class="{ active: tab == 'chat' }"
                    @click="
                      tab = 'chat';
                      this.filterChannels(channelType.CHAT);
                    "
                  >
                    <i class="material-icons md-18">keyboard_arrow_right</i>
                    {{ $t('web_widget.live_chat') }}
                    <label v-show="!record.chat.enabled" class="label m-l-xs m-b-0">
                      {{ $t('web_widget.inactive') }}
                    </label>
                  </a>
                </li>
                <li>
                  <a
                    :class="{ active: tab == 'voice' }"
                    @click="
                      tab = 'voice';
                      this.filterChannels(channelType.VOIP);
                    "
                  >
                    <i class="material-icons md-18">keyboard_arrow_right</i>
                    {{ $t('web_widget.voice') }}
                    <label v-show="!record.voice.enabled" class="label m-l-xs m-b-0">
                      {{ $t('web_widget.inactive') }}
                    </label>
                  </a>
                </li>
                <li>
                  <a
                    :class="{ active: tab == 'sms' }"
                    @click="
                      tab = 'sms';
                      this.filterChannels(channelType.SMS);
                    "
                  >
                    <i class="material-icons md-18">keyboard_arrow_right</i>
                    {{ $t('web_widget.sms') }}
                    <label v-show="!record.sms.enabled" class="label m-l-xs m-b-0">
                      {{ $t('web_widget.inactive') }}
                    </label>
                  </a>
                </li>
                <li>
                  <a :class="{ active: tab == 'email' }" @click="tab = 'email'">
                    <i class="material-icons md-18">keyboard_arrow_right</i>
                    {{ $t('web_widget.email') }}
                    <label v-show="!record.email.enabled" class="label m-l-xs m-b-0">
                      {{ $t('web_widget.inactive') }}
                    </label>
                  </a>
                </li>
                <li>
                  <a :class="{ active: tab == 'translations' }" @click="tab = 'translations'">
                    <i class="material-icons md-18">keyboard_arrow_right</i>
                    {{ $t('web_widget.translations_sidebar') }}
                  </a>
                </li>
                <li>
                  <a :class="{ active: tab == 'installation' }" @click="tab = 'installation'">
                    <i class="material-icons md-18">keyboard_arrow_right</i>
                    {{ $t('web_widget.installation') }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <span class="text-md p-y-1 white p-x-1 b-b hidden-md-up text-ellipsis">
        <a data-toggle="modal" data-target="#settings-third" class="pull-left">
          <span class="btn btn-sm btn-icon white m-r-1">
            <i class="fa fa-list"></i>
          </span>
        </a>
        {{ $t('web_widget.widget') }}
      </span>
    </div>

    <div class="row-col">
      <div class="row-col">
        <div class="row-row">
          <div class="row-body scrollable">
            <div class="row-inner">
              <div class="p-b-0">
                <div class="p-a">
                  <div class="row-col">
                    <form ref="widgetForm" class="form-horizontal" @submit.prevent="save">
                      <div class="p-a">
                        <div v-show="tab == 'general'">
                          <div class="box">
                            <div class="box-header b-b">
                              <h3>{{ $t('web_widget.settings') }}</h3>
                            </div>
                            <div class="box-body">
                              <div class="form-group row">
                                <label class="col-md-2 form-control-label">{{ $t('web_widget.widget_title') }}</label>
                                <div class="col-md-10">
                                  <input v-model="record.title" type="text" class="form-control" />
                                  <span class="form-text">
                                    {{ $t('web_widget.for_internal_purposes') }}
                                  </span>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-2 form-control-label">
                                  {{ $t('web_widget.company_display_name') }}
                                </label>
                                <div class="col-md-10">
                                  <span class="form-text">
                                    {{ $t('web_widget.manage_your_company_name_and_more') }}
                                    <button
                                      class="btn btn-sm btn-secondary"
                                      @click.prevent="
                                        () => {
                                          tab = 'translations';
                                        }
                                      "
                                    >
                                      {{ $t('web_widget.here') }}
                                    </button>
                                  </span>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-2 form-control-label">
                                  {{ $t('web_widget.default_channel') }}
                                </label>
                                <div class="col-md-10">
                                  <select v-model="record.default_channel" class="form-control">
                                    <option value="facebook">{{ $t('web_widget.facebook') }}</option>
                                    <option value="telegram">{{ $t('web_widget.telegram') }}</option>
                                    <option v-if="$root.waEnabled" value="whatsapp">
                                      {{ $t('web_widget.whatsapp') }}
                                    </option>
                                    <option value="chat">{{ $t('web_widget.chat') }}</option>
                                    <option value="email">{{ $t('web_widget.email') }}</option>
                                    <option value="help_center">{{ $t('web_widget.help_center') }}</option>
                                    <option value="wa_business">{{ $t('web_widget.whatsapp_business') }}</option>
                                  </select>
                                  <div style="clear: both; margin-top: 10px">
                                    <span class="form-text">
                                      {{ $t('web_widget.the_default_selected_channel_when_the_widget_is_activated') }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-2 form-control-label">{{ $t('web_widget.widget_color') }}</label>
                                <div class="col-md-10">
                                  <color-picker v-model="record.color"></color-picker>
                                  <span class="form-text">{{ $t('web_widget.the_rgb_color_scheme') }}</span>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-2 form-control-label">{{ $t('web_widget.show_team') }}</label>
                                <div class="col-md-10">
                                  <label class="ui-switch success m-t-xs m-r">
                                    <input v-model="record.show_team" type="checkbox" true-value="1" />
                                    <i></i>
                                  </label>
                                  <span class="form-text">
                                    {{ $t('web_widget.display_the_support_team_in_the_website_widget') }}
                                  </span>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-2 form-control-label">
                                  {{ $t('web_widget.widget_position') }}
                                </label>
                                <div class="col-md-10">
                                  <select v-model="record.position" class="form-control">
                                    <option value="LEFT">{{ $t('web_widget.left') }}</option>
                                    <option value="RIGHT">{{ $t('web_widget.right') }}</option>
                                  </select>
                                  <span class="form-text">
                                    {{ $t('web_widget.the_position_of_the_widget_on_your_website') }}
                                  </span>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-2 form-control-label">{{ $t('web_widget.widget_icon') }}</label>
                                <div class="col-md-10">
                                  <div class="flex">
                                    <label class="flex items-center leading-none">
                                      <input
                                        v-model="icon.customize"
                                        type="radio"
                                        name="customize_icon"
                                        :value="false"
                                      />
                                      <i class="success ml-1"></i>
                                      <span>{{ $t('web_widget.default') }}</span>
                                    </label>
                                    <label class="ml-3 flex items-center leading-none">
                                      <input
                                        v-model="icon.customize"
                                        type="radio"
                                        name="customize_icon"
                                        :value="true"
                                      />
                                      <i class="success ml-1"></i>
                                      <span>{{ $t('web_widget.customize') }}</span>
                                    </label>
                                  </div>

                                  <template v-if="icon.customize">
                                    <template v-if="icon_preview_src">
                                      <div class="d-inline-block relative">
                                        <div
                                          class="launcher-container"
                                          :style="{ 'background-color': this.record.color }"
                                        >
                                          <span class="trengo_launcher">
                                            <img :src="icon_preview_src" />
                                          </span>
                                        </div>
                                        <span
                                          class="
                                            box-shadow-z3
                                            absolute
                                            rounded
                                            bg-white
                                            text-grey-600
                                            hover:text-grey-500
                                          "
                                          style="right: 0; bottom: 0; line-height: 1"
                                          @click="clearIcon"
                                        >
                                          <i class="material-icons md-12 pointer p-1">close</i>
                                        </span>
                                      </div>
                                      <span v-if="icon.blob && icon.blob.size > 2048000" class="form-text text-danger">
                                        {{ $t('web_widget.your_custom_icon_exceeds_the_maximum_file_size_of_2mb') }}
                                      </span>
                                      <span v-else class="form-text">
                                        {{ $t('web_widget.this_is_a_preview_of_your_widget_bubble') }}
                                      </span>
                                    </template>
                                    <template v-else>
                                      <input
                                        id="icon-upload-input"
                                        ref="icon-upload-input"
                                        type="file"
                                        accept="image/png,image/gif,image/jpeg"
                                        @change="previewIcon"
                                      />
                                      <label for="icon-upload-input" class="icon-upload-label" title="Click to upload">
                                        <span
                                          class="launcher-container"
                                          :style="{ 'background-color': this.record.color }"
                                        >
                                          <span class="icon-upload-inner">
                                            <i class="material-icons">cloud_upload</i>
                                          </span>
                                        </span>
                                      </label>
                                      <span class="form-text">
                                        {{ $t('web_widget.upload_an_image_to_preview_your_custom_widget_bubble') }}
                                      </span>
                                    </template>
                                  </template>
                                  <template v-else>
                                    <div class="d-inline-block">
                                      <div
                                        class="launcher-container"
                                        :style="{ 'background-color': this.record.color }"
                                      >
                                        <span class="trengo_launcher">
                                          <span></span>
                                          <span></span>
                                          <span></span>
                                        </span>
                                      </div>
                                    </div>
                                    <span class="form-text">
                                      {{ $t('web_widget.this_is_a_preview_of_your_widget_bubble') }}
                                    </span>
                                  </template>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-2 form-control-label">
                                  {{ $t('web_widget.hide_trengo_logo') }}
                                </label>
                                <div class="col-md-10 mt-2">
                                  <label
                                    class="ui-switch success"
                                    :class="{
                                      'cursor-not-allowed': !isEntitledToRemoveTrengoLogo,
                                      'opacity-50': !isEntitledToRemoveTrengoLogo,
                                    }"
                                  >
                                    <input
                                      v-model="record.no_branding"
                                      type="checkbox"
                                      true-value="1"
                                      :disabled="!isEntitledToRemoveTrengoLogo"
                                    />
                                    <i></i>
                                  </label>
                                  <div class="flex">
                                    <span class="form-text" v-html="brandingText"></span>
                                    <t-badge
                                      v-if="!isEntitledToRemoveTrengoLogo"
                                      :text="requiredPlan"
                                      variant="upgrade"
                                      class="ml-3 cursor-default p-2"
                                      data-test="entitlement-badge"
                                    >
                                      <template #icon><arrow-top-circle-linear size="1rem" /></template>
                                    </t-badge>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-2 form-control-label">
                                  {{ $t('web_widget.send_events_to_google_analytics') }}
                                </label>
                                <div class="col-md-10 mt-2">
                                  <label class="ui-switch success">
                                    <input v-model="record.launcher_styles.send_ga" type="checkbox" />
                                    <i></i>
                                  </label>
                                  <span class="form-text">
                                    {{
                                      $t('web_widget.when_enabled_widget_events_will_be_sent_to_your_google_analytics')
                                    }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <translations
                          v-show="tab == 'translations'"
                          v-model:locales="locales"
                          v-model:record="record"
                          :get-full-country-name="localeCodeName"
                          :enabled-channels="enabledChannels"
                          :default-translations="default_translations"
                        ></translations>
                        <facebook v-show="tab == 'facebook'" v-model="record" :channels="filteredChannels"></facebook>
                        <help-center v-show="tab == 'help_center'" v-model="record"></help-center>
                        <telegram v-show="tab == 'telegram'" v-model="record" :channels="filteredChannels"></telegram>
                        <chat v-show="tab == 'chat'" v-model="record" :channels="filteredChannels"></chat>
                        <whatsapp v-show="tab == 'whatsapp'" v-model="record" :channels="filteredChannels"></whatsapp>
                        <email v-show="tab == 'email'" v-model="record"></email>
                        <sms v-show="tab == 'sms'" v-model="record" :channels="filteredChannels"></sms>
                        <voice v-show="tab == 'voice'" v-model="record" :channels="filteredChannels"></voice>
                        <installation v-show="tab == 'installation'" v-model="record" :action="action"></installation>

                        <div class="box">
                          <div class="box-body flow-root">
                            <button
                              v-if="action === 'edit'"
                              type="submit"
                              :class="{ loader: saving }"
                              class="btn success text-white"
                              :disabled="saving"
                              v-text="saving ? $t('web_widget.updating') : $t('web_widget.update_widget')"
                            ></button>
                            <button
                              v-if="action === 'create'"
                              type="submit"
                              :class="{ loader: saving }"
                              class="btn success text-white"
                              :disabled="saving"
                              v-text="saving ? $t('web_widget.creating') : $t('web_widget.create_widget')"
                            ></button>

                            <button
                              v-if="action == 'edit'"
                              type="button"
                              class="btn btn-link text-muted pull-right"
                              @click="deleteWebWidget()"
                            >
                              {{ $t('web_widget.delete') }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.md-check {
  margin-right: 0.5rem;

  i {
    margin-right: 23px;
  }
}

#icon-upload-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  &:focus + .icon-upload-label,
  & + .icon-upload-label:hover {
    .launcher-container {
      .icon-upload-inner {
        /*width: 50px;
                    height: 50px;
                    margin: 5px;*/
        background-color: rgba(255, 255, 255, 0.1);

        i {
          font-size: 28px;
        }
      }
    }
  }
}

.icon-upload-label {
  margin: 0;
  cursor: pointer;

  .launcher-container {
    cursor: pointer;
  }

  .icon-upload-inner {
    display: block;
    width: 48px;
    height: 48px;
    margin: 6px;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0);
    border: 1px dashed rgba(255, 255, 255, 0.7);
    overflow: hidden;
    line-height: 100%;
    transition: background-color 100ms ease-in-out;

    i {
      transition: font-size 100ms ease-in-out;
      height: 100%;
      width: 100%;
      font-size: 24px;
      line-height: 48px;
    }
  }
}

.launcher-container {
  display: block;
  margin: 10px 0 0 0;
  width: 60px;
  height: 60px;
  background: #14b29f;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  font-size: 30px;
  line-height: 60px;
  overflow: hidden;
  transition: all 100ms ease-in-out;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .trengo_launcher {
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 60px;
    overflow: hidden;

    span {
      background: white;
      width: 20px;
      border-radius: 5px;
      margin-left: 17px;
      height: 5px;
      animation: slideIn 0.3s ease forwards;
      animation-delay: 0.1s;
      opacity: 0;
    }

    span:first-child {
      margin-left: 13px;
      margin-bottom: 3px;
      width: 30px;
      opacity: 0;
    }

    span:last-child {
      width: 22px;
      margin-left: 21px;
      margin-top: 3px;
      opacity: 0;
    }
  }
}
</style>

<script type="text/babel">
import { ArrowTopCircleLinear } from '@trengo/trengo-icons';
import _, { map } from 'lodash';
import { mapStores } from 'pinia';

import { FEATURE, ENTITLEMENT_STATUS, CHANNEL_TYPE } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useEntitlementsStore } from '@/store/pinia';
import { randomString } from '@/util/stringHelpers';

import Chat from './Sections/Chat';
import Email from './Sections/Email';
import Facebook from './Sections/Facebook';
import HelpCenter from './Sections/HelpCenter';
import Installation from './Sections/Installation';
import Sms from './Sections/Sms';
import Telegram from './Sections/Telegram';
import Translations from './Sections/Translations';
import Voice from './Sections/Voice';
import Whatsapp from './Sections/WhatsApp';
import RouterWarningOnEdit from '../../mixins/RouterWarningOnEdit';
import ColorPicker from '../Elements/ColorPicker';

export default {
  name: 'WebWidgetEdit',
  props: {
    action: {
      type: String,
      default: 'create',
      tab: 'general',
    },
  },

  mixins: [RouterWarningOnEdit],

  components: {
    HelpCenter,
    Facebook,
    Telegram,
    Chat,
    Email,
    Translations,
    Installation,
    ColorPicker,
    Whatsapp,
    Sms,
    Voice,
    ArrowTopCircleLinear,
  },

  computed: {
    ...mapStores(useEntitlementsStore),
    icon_preview_src() {
      if (this.icon.blob) {
        return URL.createObjectURL(this.icon.blob);
      } else if (this.record.launcher_styles.icon_url) {
        return this.record.launcher_styles.icon_url;
      } else {
        return null;
      }
    },

    enabledChannels() {
      let c = ['general'];
      if (this.record.facebook.enabled) {
        c.push('facebook');
      }
      if (this.record.telegram.enabled) {
        c.push('telegram');
      }
      if (this.record.email.enabled) {
        c.push('email');
      }
      if (this.record.chat.enabled) {
        c.push('chat');
      }
      if (this.record.whatsapp.enabled) {
        c.push('whatsapp');
      }
      if (this.record.sms.enabled) {
        c.push('sms');
      }
      if (this.record.voice.enabled) {
        c.push('voice');
      }
      if (this.record.help_center.enabled) {
        c.push('help_center');
      }
      return c;
    },
    isEntitledToRemoveTrengoLogo() {
      return this.entitlementsStore?.isEntitledTo(FEATURE.CUSTOMER_SERVICE__AUTOMATION__WIDGET__WHITELABEL);
    },
    requiredPlan() {
      return this.entitlementsStore?.getMinimumRequiredPlanFor(
        FEATURE.CUSTOMER_SERVICE__AUTOMATION__WIDGET__WHITELABEL
      );
    },
    brandingText() {
      return this.isEntitledToRemoveTrengoLogo
        ? this.$t('web_widget.include_the_trengo_logo_in_the_widget')
        : this.$t('entitlement.customer_service__automation__widget__whitelabel', {
            plan: this.requiredPlan,
          });
    },
    channelType() {
      return CHANNEL_TYPE;
    },
  },

  data() {
    return {
      icon: {
        customize: false,
        blob: null,
      },
      locales: [],
      loaded: false,
      channels: {},
      filteredChannels: [],
      saving: false,
      record: {
        token: null,
        title: 'Website widget',
        color: '#5bb130',
        position: 'RIGHT',
        show_team: true,
        languages: [],
        translations: {},
        default_channel: 'chat',
        default_lang: '',
        no_branding: 0,
        facebook: {
          enabled: false,
          channel_id: null,
        },
        chat: {
          enabled: false,
          channel_id: null,
          pre_form_custom_fields: [],
          pre_form: 0,
          offline_email: '',
          auto_close: 0,
          auto_close_chat_seconds: 60,
          offline_outside_business_hours: 0,
          show_online_status: 1,
          always_available: 1,
          show_team: 1,
        },
        whatsapp: {
          enabled: false,
          channel_id: null,
        },
        telegram: {
          enabled: false,
          channel_id: null,
        },
        help_center: {
          enabled: false,
          channel_id: null,
        },
        email: {
          enabled: false,
          custom_fields: [],
          email: '',
        },
        sms: {
          enabled: false,
          form: true,
        },
        voice: {
          enabled: false,
          allow_calls: true,
        },
        automation: [],
        launcher_styles: {
          icon_url: null,
          send_ga: false,
        },
      },
      default_translations: {},
      tab: 'general',
    };
  },

  watch: {
    'record.languages'(v) {
      let defaults = this.action === 'create' ? this.default_translations : this.record.translations;
      let t = '';
      _.each(v, (lang) => {
        _.each(this.default_translations['en'], (translations, group) => {
          _.each(translations, (value, key) => {
            t = _.get(defaults, lang + '.' + group + '.' + key);

            if (!t) {
              t = _.get(this.default_translations, lang + '.' + group + '.' + key);
              if (!t) {
                t = value; // fallback to en
              }
            }

            _.set(this.record.translations, lang + '.' + group + '.' + key, t);
          });
        });
      });

      if (this.record.languages.indexOf(this.record.default_lang) === -1 || this.record.default_lang === '') {
        this.record.default_lang = this.record.languages[0];
      }
    },

    locales(v) {
      if (v.length && v.length > 10) {
        v.splice(10);
        this.flashError(this.$t('web_widget.maximum_of_10_languages_reached'));
        return;
      }

      this.record.languages = map(v, 'id');
    },
  },

  mounted() {
    axios.get('/api/v2/web_widgets/defaults').then((res) => {
      this.default_translations = res.data.translations;

      if (this.action === 'create') {
        this.record.token = randomString(20);
        this.record.chat.offline_email = res.data.agency_email;
        this.record.email.email = res.data.agency_email;
        this.record.automation = res.data.automation || [];
        this.record.languages.push(res.data.language);
        this.locales.push({
          id: res.data.language,
          name: this.localeCodeName(res.data.language),
        });
        this.record.default_lang = res.data.language;
        setTimeout(() => this.initWarning(), 3000);
        this.loaded = true;
      } else {
        let defaults = res.data;
        axios.get('/api/v2/web_widgets/' + this.$route.params.id).then((result) => {
          this.setEditValues(result.data, defaults);
          setTimeout(() => this.initWarning(), 3000);
          this.loaded = true;
        });
      }
    });

    this.channels = this.$root.channels;
  },

  methods: {
    previewIcon(event) {
      try {
        if (!event.target.files.length) {
          return;
        }
        this.icon = Object.assign(this.icon, { blob: event.target.files[0] });
      } catch (error) {
        console.log(error);
      }
    },
    async handleIconUpload() {
      //custom icon
      if (this.icon.customize && this.icon.blob) {
        // upload new icon
        let formData = new FormData();
        formData.append('file', this.icon.blob);

        let res = await axios.post('/api/v2/web_widgets/custom_icon', formData);
        this.record.launcher_styles = Object.assign({}, this.record.launcher_styles, { icon_url: res.data.file_url });
      } else if (!this.icon.customize) {
        // unset custom icon
        this.clearIcon();
      }
    },
    clearIcon() {
      this.icon = Object.assign({}, this.icon, { blob: null });
      this.record.launcher_styles = Object.assign({}, this.record.launcher_styles, { icon_url: null });
    },

    localeCodeName(locale_code) {
      return (
        (
          this.$root.localeCodesList.filter((l) => {
            return l.id === locale_code;
          })[0] || {}
        ).name || '?'
      );
    },

    getChannels(type) {
      if (type === 'WHATSAPP') {
        return this.channels.filter((c) => {
          return c.type === 'WHATSAPP' || c.type === 'WA_BUSINESS';
        });
      }
      return this.channels.filter((c) => {
        return c.type === type;
      });
    },
    filterChannels(type) {
      if (type === CHANNEL_TYPE.WHATSAPP) {
        this.filteredChannels = this.channels.filter((c) => {
          return c.type === CHANNEL_TYPE.WHATSAPP || c.type === CHANNEL_TYPE.WA_BUSINESS;
        });
        return;
      }
      this.filteredChannels = this.channels.filter((c) => {
        return c.type === type;
      });
    },
    save() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      if (this.action === 'create') {
        return this.storeWebWidget();
      } else {
        this.updateWebWidget();
      }
    },

    async storeWebWidget() {
      try {
        await this.handleIconUpload();
      } catch (e) {
        this.saving = false;
        console.error(e);
        return false;
      }

      axios
        .post('/api/v2/web_widgets', this.record)
        .then((res) => {
          this.saving = false;
          this.$emit('call-parent-loadList-method');
          router.push('/admin/web_widgets/' + res.data.id);
          this.flashSuccess(this.$t('web_widget.the_website_widget_has_been_created_successfully'));
          this.initWarning();
          eventBus.$emit('user-setup-first-web-widget');
        })
        .catch(() => {
          this.saving = false;
        });
    },

    async updateWebWidget() {
      try {
        await this.handleIconUpload();
      } catch (e) {
        this.saving = false;
        console.error(e);
        return false;
      }

      let updateRecord = { ...this.record };
      delete updateRecord.token;

      //TODO: Check if this.record.id is not existent
      axios
        .put('/api/v2/web_widgets/' + this.record.id, updateRecord)
        .then((res) => {
          this.saving = false;
          this.$emit('call-parent-loadList-method');
          this.flashSuccess(this.$t('web_widget.the_website_widget_has_been_updated_successfully'));
          this.initWarning();
        })
        .catch(() => {
          this.saving = false;
        });
    },

    async deleteWebWidget() {
      const shouldDeleteWidget = await this.$tConfirm(
        this.$t('web_widget.are_you_sure_you_want_to_delete_this_widget'),
        {
          delete: true,
          title: this.$t('general.are_you_sure'),
        }
      );
      if (shouldDeleteWidget) {
        axios.delete('/api/v2/web_widgets/' + this.record.id).then(() => {
          eventBus.$emit('web_widget.deleted');
          this.$emit('call-parent-loadList-method');
          this.initWarning();
          router.push('/admin/web_widgets');
          this.flashSuccess(this.$t('web_widget.the_website_widget_has_been_deleted_successfully'));
        });
      }
    },

    setEditValues(record, defaults) {
      this.record.id = record['id'];
      this.record.token = record['token'];
      this.record.title = record['title'];
      this.record.position = record['position'];
      this.record.color = record['color'];
      this.record.default_channel = record['default_channel'];
      this.record.show_team = record['show_team'];
      this.record.no_branding = record['no_branding'];
      this.record.automation = record['automation'];
      this.record.translations = Object.assign(this.record.translations, record['translations']);
      this.record = Object.assign({}, this.record, { launcher_styles: record.launcher_styles || {} });
      this.icon = Object.assign({}, this.icon, { customize: !!(record.launcher_styles || {}).icon_url });

      this.locales = map(record['languages'], (k) => {
        return {
          id: k,
          name: this.localeCodeName(k),
        };
      });

      this.record.default_lang = record['default_lang'];

      if (record.email_channel != null) {
        Object.assign(this.record.email, record.email_channel);
        this.record.email.enabled = true;
      }

      if (record.chat_channel != null) {
        Object.assign(this.record.chat, record.chat_channel);
        this.record.chat.enabled = true;

        if (this.record.chat.pre_form_custom_fields == null) {
          this.record.chat.pre_form_custom_fields = [];
        }
      }

      if (record.sms_channel != null) {
        Object.assign(this.record.sms, record.sms_channel);
        this.record.sms.enabled = true;
      }

      if (record.voice_channel != null) {
        Object.assign(this.record.voice, record.voice_channel);
        this.record.voice.enabled = true;
      }

      if (record.helpCenterChannel != null) {
        this.record.help_center = {
          enabled: true,
          channel_id: record.helpCenterChannel.pivot.channel_id,
        };
      }

      if (record.telegramChannel != null) {
        this.record.telegram = {
          enabled: true,
          channel_id: record.telegramChannel.pivot.channel_id,
        };
      }

      if (record.whatsappChannel != null) {
        this.record.whatsapp = {
          enabled: true,
          channel_id: record.whatsappChannel.pivot.channel_id,
        };
      }

      if (record.facebookChannel != null) {
        this.record.facebook = {
          enabled: true,
          channel_id: record.facebookChannel.pivot.channel_id,
        };
      }

      if (this.record.email.email === null) {
        this.record.email.email = defaults.agency_email;
      }

      if (this.record.chat.offline_email === null) {
        this.record.chat.offline_email = defaults.agency_email;
      }
    },
    updateChannelRecord(value) {
      this.record = value;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.onBeforeRouteLeaveGuard(to, from, next);
  },
  beforeRouteUpdate(to, from, next) {
    this.onBeforeRouteUpdateGuard(to, from, next);
  },
};
</script>
