import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "t-text-h3 my-auto max-w-[90%] p-0" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "m-0 p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_input_text = _resolveComponent("t-input-text")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_spinner = _resolveComponent("t-spinner")!
  const _component_t_modal = _resolveComponent("t-modal")!

  return (_openBlock(), _createBlock(_component_t_modal, {
    "model-value": true,
    variant: "narrow",
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('integration_hub.rename')), 1 /* TEXT */)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_t_button, {
        "btn-style": "secondary",
        disabled: _ctx.isSaving,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('general.cancel')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled"]),
      _createVNode(_component_t_button, {
        class: "flex flex-row items-center justify-center",
        disabled: _ctx.isSaveDisabled,
        onClick: _ctx.save
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('general.save')), 1 /* TEXT */),
          (_ctx.isSaving)
            ? (_openBlock(), _createBlock(_component_t_spinner, {
                key: 0,
                size: "1rem",
                class: "ml-2"
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_t_input_text, {
          id: "title",
          modelValue: _ctx.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
          required: true,
          class: "mt-2"
        }, null, 8 /* PROPS */, ["modelValue"])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}