<template>
  <div class="rounded-xl border-1 border-galaxy-300 bg-galaxy-100 px-4 py-6 sm:px-8 sm:py-6">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'GrayCard',
};
</script>
