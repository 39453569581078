<template>
  <div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.light_speed_label_cluster') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <select v-model="record.meta.cluster" class="form-control">
          <option>{{ $t('integrations.light_speed_label_choose_an_option') }}</option>
          <option value="eu1">{{ $t('integrations.light_speed_option_eu1') }}</option>
          <option value="us1">{{ $t('integrations.light_speed_option_us1') }}</option>
        </select>
        <span class="form-text">{{ $t('integrations.light_speed_subtitle_cluster_location') }}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.light_speed_label_api_key') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.api_key" type="text" autocomplete="off" class="form-control" />
        <span class="form-text" v-html="$t('integrations.light_speed_subtitle_your_api_key')"></span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.light_speed_label_api_token') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.api_token" type="text" autocomplete="off" class="form-control" />
        <span class="form-text" v-html="$t('integrations.light_speed_subtitle_your_api_token')"></span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.light_speed_label_shop_url') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.shop_url" type="text" autocomplete="off" class="form-control" />
        <span class="form-text">{{ $t('integrations.light_speed_subtitle_shop_url') }}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.light_speed_label_shop_language') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <select v-model="record.meta.shop_language" class="form-control">
          <option value="nl">{{ $t('integrations.light_speed_language_option_dutch_nl') }}</option>
          <option value="en">{{ $t('integrations.light_speed_language_option_english_en') }}</option>
          <option value="us">{{ $t('integrations.light_speed_language_option_english_us') }}</option>
          <option value="fr">{{ $t('integrations.light_speed_language_option_french_fr') }}</option>
          <option value="fc">{{ $t('integrations.light_speed_language_option_french_fc') }}</option>
          <option value="es">{{ $t('integrations.light_speed_language_option_spanish_es') }}</option>
          <option value="de">{{ $t('integrations.light_speed_language_option_german_de') }}</option>
          <option value="da">{{ $t('integrations.light_speed_language_option_danish_da') }}</option>
          <option value="it">{{ $t('integrations.light_speed_language_option_italian_it') }}</option>
          <option value="no">{{ $t('integrations.light_speed_language_option_norwegian_no') }}</option>
          <option value="pl">{{ $t('integrations.light_speed_language_option_polish_po') }}</option>
          <option value="pt">{{ $t('integrations.light_speed_language_option_portugese_pt') }}</option>
          <option value="ru">{{ $t('integrations.light_speed_language_option_russian_ru') }}</option>
          <option value="sv">{{ $t('integrations.light_speed_language_option_swedish_sv') }}</option>
          <option value="tr">{{ $t('integrations.light_speed_language_option_turkish_tr') }}</option>
          <option value="el">{{ $t('integrations.light_speed_language_option_greek_el') }}</option>
          <option value="bg">{{ $t('integrations.light_speed_language_option_bulgarian_bg') }}</option>
        </select>
        <span class="form-text">{{ $t('integrations.light_speed_subtitle_shop_language') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
