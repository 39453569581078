<template>
  <sidebar-dropdown
    :title="$tc('ticket_sidebar.time_tracking')"
    collapse-key="timetrackingDropdown"
    data-test="timetracking-dropdown"
    @toggled="setToggleState"
    @mounted="setToggleState"
  >
    <div class="flex flex-row items-center">
      <clock-linear size="1.1rem" class="mr-2 text-grey-700" />
      <h4 class="t-text-sm-emphasize m-0 p-0 text-grey-700">{{ $tc('ticket_sidebar.time_tracking_working_time') }}</h4>
    </div>

    <time-entry class="mt-2 flex flex-col" :ticket-id="ticket.id" @add-log-entry="addLogEntry" />

    <span v-if="logs.length > 0" class="my-2 w-full border-b-2 border-grey-300 border-opacity-20" />

    <div v-if="logs.length > 0" class="flex flex-row items-center">
      <timer2-linear class="mr-2" size="1.3rem" />
      <h4 class="t-text-md-emphasize m-0 p-0 text-grey-800" data-test="timetracking-total-time">
        {{ $tc('ticket_sidebar.time_tracking_total_time') }}: {{ totalTrackedTime }}
      </h4>
    </div>

    <div v-if="!isLoading && logs.length > 0" class="mt-2">
      <time-entry-card
        v-for="entry in reversedLogs"
        :key="entry.id"
        class="mb-1"
        :entry="entry"
        :user-id="entry.user_id"
        :ticket-id="ticket.id"
        @delete-entry="deleteEntry(entry)"
        @edit-entry="editEntry"
      />
    </div>

    <t-spinner v-else-if="isLoading" class="m-auto" size="1.5rem" />
  </sidebar-dropdown>
</template>

<script lang="ts">
import { ClockLinear, Timer2Linear } from '@trengo/trengo-icons';
import moment from 'moment';
import { defineComponent } from 'vue';

import { deleteTimeEntry, getTimeTrackingEntries } from '@/components/TicketSidebar/Api/requests';
import SidebarDropdown from '@/components/TicketSidebar/SidebarDropdown.vue';
import TimeEntry from '@/components/TicketSidebar/TimeTracking/TimeEntry.vue';
import TimeEntryCard from '@/components/TicketSidebar/TimeTracking/TimeEntryCard.vue';
import { formatDuration } from '@/util/stringHelpers';

import type Tickets from '@/types/tickets';
import type TimeEntryType from '@/types/timeEntry';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'TimetrackingDropdown',

  components: { TimeEntry, TimeEntryCard, ClockLinear, SidebarDropdown, Timer2Linear },

  props: {
    ticket: {
      type: Object as PropType<Tickets>,
      default: () => ({}),
    },
  },

  data() {
    return {
      logs: [] as TimeEntryType[],
      isLoading: false,
      hasFetched: false,
      isDropdownToggled: false,
    };
  },

  async created() {
    await this.fetchEntries();
  },

  computed: {
    totalTrackedTime() {
      let totalTime = 0;

      const duration: number = this.logs
        .map((log: TimeEntryType) => {
          return moment(log.end_time * 1000).diff(log.start_time * 1000, 'milliseconds');
        })
        .reduce((accumulator: number, currentValue: number) => accumulator + currentValue, totalTime);

      const durationBits = formatDuration(duration);

      return `${durationBits.hours}H ${durationBits.minutes}M`;
    },

    reversedLogs() {
      const logCopy = [...this.logs];

      return logCopy.reverse();
    },
  },

  methods: {
    async fetchEntries() {
      this.isLoading = true;

      try {
        await getTimeTrackingEntries(this.ticket.id)
          .then((response) => {
            this.logs = response.data.data;
          })
          .finally(() => {
            this.hasFetched = true;
            this.isLoading = false;
          });
      } catch (err) {
        console.error(err);
        this.flashError(err);
        this.isLoading = false;
      }
    },

    addLogEntry(log: TimeEntryType) {
      this.logs.push(log);
    },

    editEntry(entry: TimeEntryType) {
      const localEntry = this.logs.find((log) => log.id === entry.id);
      const index = this.logs.indexOf(localEntry);
      if (index) {
        this.logs.splice(index, 1, entry);
      }
    },

    async deleteEntry(log: TimeEntryType) {
      const index = this.logs.indexOf(log);

      try {
        await deleteTimeEntry(log).then(() => {
          this.logs.splice(index, 1);
        });
      } catch (err) {
        console.error(err);
        this.flashError(err);
      }
    },

    setToggleState(state: boolean) {
      this.isDropdownToggled = state;

      if (state && !this.hasFetched) {
        this.fetchEntries();
      }
    },
  },
});
</script>
