import { request } from '@/util/request';

import { ENDPOINT } from '../constants';

import type { AxiosPromise, AxiosResponse } from 'axios';

export type FileResponse = {
  readonly id: number;
  name: string;
  url: string;
};

export function attachFile(options: string, payload: FormData): AxiosPromise<AxiosResponse> {
  return request(`${ENDPOINT.ATTACHMENTS}${options}`, 'POST', payload);
}
