<template>
  <div class="box" data-test="balance-upgrades">
    <div ref="main_box" class="box-header b-b mb-6">
      <h2>{{ $t('company_profile.subscription_page_balance_upgrades') }}</h2>
    </div>
    <div class="px-4 pb-1 sm:px-6" :class="{ 'pb-6': total <= 1 }">
      <t-table-list data-test="table" alternate>
        <t-table-list-row head>
          <t-table-list-head>Details</t-table-list-head>
          <t-table-list-head width="30%" v-if="!isViewable">
            {{ $t('company_profile.subscription_page_date') }}
          </t-table-list-head>
          <t-table-list-head
            class="pr-2 text-right lg:pr-0"
            :class="{ 'lg:pr-2': !records.length }"
            :width="isViewable ? '30%' : '15%'"
          >
            {{ $t('company_profile.subscription_page_price') }} {{ !isViewable ? 'inc. VAT' : '' }}
          </t-table-list-head>
          <t-table-list-head v-if="records.length" :width="isViewable ? 'auto' : '20%'" />
        </t-table-list-row>
        <balance-row v-for="item in records" :viewable="isViewable" :item="item" :key="item.id" />
      </t-table-list>
      <no-data-found v-if="!records.length" class="mb-6 mt-14" text="No transaction history" />
      <t-paginator v-if="total > 1" :total="total" :visible-page-count="6" @update="handlePaginateUpdate" />
    </div>
  </div>
</template>

<script type="text/babel">
import breakpoints from '@/util/breakpoints';
import BalanceRow from './components/BalanceRow';

export default {
  name: 'BalanceUpgrades',
  components: {
    BalanceRow,
  },
  data: function () {
    return {
      breakpoints,
      transactions: {},
      records: [],
      total: 0,
    };
  },
  mounted() {
    this.getBalanceUpgrades();
  },
  computed: {
    isMobile() {
      return !this.breakpoints.comparisons.gt_md;
    },
    isTablet() {
      const { eq_lg, eq_md, gt_sm } = this.breakpoints.comparisons;
      return !eq_lg && eq_md && gt_sm;
    },
    isViewable() {
      return this.isMobile && !this.isTablet;
    },
  },

  methods: {
    handlePaginateUpdate(data) {
      this.getBalanceUpgrades(data + 1);
    },
    request(url) {
      return axios.get(url);
    },
    getBalanceUpgrades(page = 1) {
      this.request(`/client-api/balance/transactions?page=${page}`).then((res) => {
        const data = res.data;
        this.records = data?.data;
        this.total = data?.last_page;
        this.followThePaginationCoordinates();
      });
    },
    followThePaginationCoordinates() {
      const main_box = this.$refs.main_box;
      main_box.scrollIntoView({ block: 'start', scrollBehavior: 'smooth' });
    },
  },
};
</script>
