<template>
  <div class="mt-2 w-full rounded-xl border border-grey-300 bg-white p-6">
    <div class="flex flex-row items-center justify-between">
      <div class="flex flex-row items-center">
        <t-thumbnail class="mr-3 bg-white">
          <chart-line-linear size="1.4rem" />
        </t-thumbnail>
        <div class="flex flex-col">
          <p class="t-text-h5 m-0 text-grey-800">{{ $t('reports_v2.workload_management') }}</p>
        </div>
      </div>
    </div>
    <div data-test="workload-management-chart" class="relative mt-6 h-88">
      <workload-management-loaded
        v-if="isLoaded"
        :created-conversations="createdConversations"
        :closed-conversations="closedConversations"
        :show-error="showError"
        :interval="filters.interval"
      />

      <workload-management-loading
        v-else
        :created-conversations="createdConversations"
        :closed-conversations="closedConversations"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { ChartLineLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import {
  WorkloadManagementClosedConversationsMockData,
  WorkloadManagementCreatedConversationsMockData,
} from '@/components/Reporting/api/mocks';
import {
  fetchClosedConversations,
  fetchCreatedConversations,
} from '@/components/Reporting/Pages/V2/WorkloadManagement/api';
import { mapFilters } from '@/components/Reporting/Pages/V2/WorkloadManagement/utils';
import eventBus from '@/eventBus';

import WorkloadManagementLoaded from './WorkloadManagementLoaded.vue';
import WorkloadManagementLoading from './WorkloadManagementLoading.vue';

import type { WorkloadManagementBucket, DatePickerDateAndInterval } from '@/components/Reporting/api/types';
import type { Filter } from '@/components/Reporting/Components/V2/FilterDropdown/FilterDropdown.vue';
import type { PropType } from 'vue';

type Data = {
  createdConversations: WorkloadManagementBucket[];
  closedConversations: WorkloadManagementBucket[];
  isLoaded: boolean;
  showError: boolean;
  filtersFromRouteQuery: string;
};

export default defineComponent({
  name: 'WorkloadManagementChartIndex',
  components: { ChartLineLinear, WorkloadManagementLoaded, WorkloadManagementLoading },

  data(): Data {
    return {
      createdConversations: [] as WorkloadManagementBucket[],
      closedConversations: [] as WorkloadManagementBucket[],
      isLoaded: false,
      showError: false,
      filtersFromRouteQuery: '',
    };
  },

  props: {
    filters: {
      type: Object as PropType<DatePickerDateAndInterval>,
      default: () => ({ interval: 'day' }),
    },
  },

  mounted() {
    const filtersExcludeDates = this.excludeDates(
      this.$route.query.filters ? JSON.parse(this.$route.query.filters) : {}
    );
    this.filtersFromRouteQuery = mapFilters(filtersExcludeDates);
    this.getWorkloadManagementVolume();
    eventBus.$on('reporting-filter', this.fetchFilters);
  },

  unmounted() {
    eventBus.$off('reporting-filter', this.fetchFilters);
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        const filtersExcludeDates = this.excludeDates(
          this.$route.query.filters ? JSON.parse(this.$route.query.filters) : {}
        );
        this.filtersFromRouteQuery = mapFilters(filtersExcludeDates);
        this.getWorkloadManagementVolume();
      },
    },
  },

  methods: {
    excludeDates(filters: object) {
      delete filters['startDate'];
      delete filters['endDate'];
      delete filters['interval'];
      return filters;
    },
    fetchFilters(filters: Filter) {
      this.filtersFromRouteQuery = mapFilters(this.excludeDates(filters));
      this.getWorkloadManagementVolume();
    },
    async getWorkloadManagementVolume() {
      try {
        const { startDate, endDate, interval } = this.filters;
        const createdConversations = await fetchCreatedConversations(
          startDate,
          endDate,
          interval,
          this.filtersFromRouteQuery
        );
        const closedConversations = await fetchClosedConversations(
          startDate,
          endDate,
          interval,
          this.filtersFromRouteQuery
        );

        this.setConversations(createdConversations.data.buckets, closedConversations.data.buckets);
        this.isLoaded = true;
      } catch (error) {
        this.showError = true;
        this.setConversations();
        this.isLoaded = true;
        console.error(error);
      }
    },

    setConversations(
      createdConversationsBuckets?: WorkloadManagementBucket[],
      closedConversationsBuckets?: WorkloadManagementBucket[]
    ) {
      if (
        createdConversationsBuckets &&
        createdConversationsBuckets.length > 0 &&
        closedConversationsBuckets &&
        closedConversationsBuckets.length > 0
      ) {
        this.createdConversations = createdConversationsBuckets;
        this.closedConversations = closedConversationsBuckets;
        this.showError = false;
      } else {
        this.createdConversations = WorkloadManagementCreatedConversationsMockData.data.buckets;
        this.closedConversations = WorkloadManagementClosedConversationsMockData.data.buckets;
        this.showError = true;
      }
    },
  },
});
</script>
