<template>
  <list-row-item :data-test-list-row-item="dataTestListRowItem">
    <list-row-item-loader :data-test-list-row-item-loader="dataTestListRowItemLoader" />
  </list-row-item>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import ListRowItem from '@/components/common/ListRow/ListRowItem';
import ListRowItemLoader from '@/components/common/ListRow/ListRowItemLoader';
export default defineComponent({
  name: 'ListRowItemSkeleton',
  props: {
    dataTestListRowItem: {
      type: String,
      default: 'list-row-item',
    },
    dataTestListRowItemLoader: {
      type: String,
      default: 'list-row-item-loader',
    },
  },
  components: { ListRowItem, ListRowItemLoader },
});
</script>
