import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3fe5721a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-6" }
const _hoisted_2 = { class: "flex flex-col content-end" }
const _hoisted_3 = { class: "mb-4 flex flex-col" }
const _hoisted_4 = { class: "mb-1 text-base font-semibold text-grey-900" }
const _hoisted_5 = { class: "text-sm font-medium text-grey-700" }
const _hoisted_6 = { class: "w-full" }
const _hoisted_7 = { class: "mb-6" }
const _hoisted_8 = { class: "flex flex-col content-end" }
const _hoisted_9 = { class: "mb-1 flex flex-col" }
const _hoisted_10 = { class: "mb-1 text-base font-semibold text-grey-900" }
const _hoisted_11 = { class: "w-full" }
const _hoisted_12 = { class: "mb-6" }
const _hoisted_13 = { class: "flex flex-col content-end" }
const _hoisted_14 = { class: "mb-4 flex flex-col" }
const _hoisted_15 = { class: "mb-1 text-base font-semibold text-grey-900" }
const _hoisted_16 = { class: "text-sm font-medium text-grey-700" }
const _hoisted_17 = { class: "w-full" }
const _hoisted_18 = { class: "flex items-center" }
const _hoisted_19 = { class: "text-ellipsis mr-2" }
const _hoisted_20 = {
  key: 0,
  class: "ml-auto text-ellipsis max-w-[33%]"
}
const _hoisted_21 = { class: "mb-1" }
const _hoisted_22 = { class: "flex flex-col content-end" }
const _hoisted_23 = { class: "mb-4 flex flex-col" }
const _hoisted_24 = { class: "mb-1 text-base font-semibold text-grey-900" }
const _hoisted_25 = { class: "text-sm font-medium text-grey-700" }
const _hoisted_26 = { class: "w-full" }
const _hoisted_27 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_dropdown = _resolveComponent("t-dropdown")!
  const _component_t_multi_select = _resolveComponent("t-multi-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('company_profile.subscription_page_name')), 1 /* TEXT */),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('company_profile.subscription_page_name_this_set_of_business_hours')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.localRecord.name) = $event)),
            class: "h-12 w-full rounded-xl border border-grey-200 px-4 py-2",
            placeholder: "e.g. Default opening hours",
            type: "text",
            autocomplete: "off"
          }, null, 512 /* NEED_PATCH */), [
            [_vModelText, $data.localRecord.name]
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('company_profile.subscription_page_timezone')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_t_dropdown, {
            modelValue: $data.localRecord.timezone,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.localRecord.timezone) = $event)),
            items: $props.timezones.map((item) => ({ value: item, text: item }))
          }, null, 8 /* PROPS */, ["modelValue", "items"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t('company_profile.subscription_page_channels')), 1 /* TEXT */),
          _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('company_profile.subscription_page_select_the_applicable_channels_for_these_business_hours')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createVNode(_component_t_multi_select, {
            modelValue: $options.computedChannelModel,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($options.computedChannelModel) = $event)),
            "use-icon": true,
            placeholder: _ctx.$t('company_profile.subscription_page_placeholder_search_for_channels'),
            size: "md",
            "container-size": "md",
            variant: "sky",
            height: 300,
            "has-avatar": false,
            data: $options.computedChannels
          }, {
            option: _withCtx(({ option }: { option: MappedListItem }) => [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("span", _hoisted_19, _toDisplayString(option.text), 1 /* TEXT */),
                (option.$isDisabled)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString($options.getUnavailableChannelName(option.id)), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "placeholder", "data"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t('company_profile.subscription_page_description')), 1 /* TEXT */),
          _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t('company_profile.subscription_page_a_description_for_the_business_hours')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_26, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.localRecord.description) = $event)),
            placeholder: _ctx.$t('company_profile.subscription_page_placeholder_eg_summer_schedule'),
            class: "h-12 w-full rounded-xl border border-grey-200 px-4 py-2",
            type: "text",
            autocomplete: "off"
          }, null, 8 /* PROPS */, _hoisted_27), [
            [_vModelText, $data.localRecord.description]
          ])
        ])
      ])
    ])
  ]))
}