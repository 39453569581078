export default {
  direction: null,
  status: null,
  isVoipSandbox: false,
  isVoipSandboxModalOpen: false,
  intern: false,
  hold: false,
  holdLoading: false,
  muted: false,
  internCall: {
    status: null,
    user: {},
    from: {},
    direction: null,
    type: null,
    is_extern: false,
    team_id: null,
  },
  token: null,
  accepting: false,
  ticketId: null,
  userId: null,

  isInbound() {
    return this.direction === 'inbound';
  },

  isOutbound() {
    return this.direction === 'outbound';
  },

  isRinging() {
    return this.status === 'ringing';
  },

  isRingingInbound() {
    return this.isRinging() && this.isInbound();
  },

  isInProgress() {
    return this.status === 'in_progress';
  },

  reset() {
    this.id = null;
    this.status = null;
    this.direction = null;

    this.contact = {
      id: null,
      name: null,
      color: null,
      abbr: null,
    };
    this.channel = {
      id: null,
      name: null,
    };
    this.hold = false;
    this.holdLoading = false;
    this.intern = false;
    this.muted = false;
    this.token = null;
    this.accepting = false;
    this.ticketId = null;
    this.userId = null;

    this.resetInternCall();
  },

  resetInternCall() {
    this.internCall = {
      status: null,
      user: {},
      from: {},
      direction: null,
      type: null,
      is_extern: false,
      team_id: null,
    };
    this.intern = false;
  },
};
