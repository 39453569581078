<template>
  <div class="mb-2 flex h-40px items-center px-3">
    <t-input-checkbox
      v-model="computedValue"
      :disabled="isChannelConnectedToAnyTeam"
      data-test="entity-checkbox"
      class="mb-1 h-24px"
    />
    <div class="relative ml-2 flex w-full flex-row" @click="handleAddItem">
      <div v-if="isEntityChannel" class="mr-2 h-24px w-24px">
        <img class="h-24px w-24px" :src="channelImage" :alt="entity.id" />
      </div>
      <div
        data-test="entity-label"
        class="t-text-desktop-paragraph-md flex h-24px w-5/6 items-center"
        :class="disabledOrEnabled"
      >
        <span
          v-tooltip="{
            popperClass: 'info-tooltip',
            content: isChannelConnectedToAnyTeam
              ? 'Another team is already using workload balancing for this channel'
              : '',
            position: 'top-start',
          }"
          class="text-truncate mr-1 w-max max-w-xs text-ellipsis"
        >
          {{ entity[labelBy] }}
        </span>
        <span class="w-16px">
          <load-balance-linear v-if="isChannelConnectedToAnyTeam" class="text-grey-400" size="1rem" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { LoadBalanceLinear } from '@trengo/trengo-icons';

import { ChannelsIconsMapper } from '@/util/ChannelsUtils';

export default {
  name: 'EntityItem',
  emits: ['select', 'update:modelValue'],
  components: { LoadBalanceLinear },
  props: {
    entityType: {
      type: String,
      default: 'channel',
      validate: (value) => ['channel', 'member'].includes(value),
    },
    labelBy: {
      type: String,
      default: 'name',
    },
    entity: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    hasWorkload: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleAddItem() {
      if (this.isChannelConnectedToAnyTeam) {
        return false;
      }
      this.computedValue = this.entity;
    },
  },
  computed: {
    disabledOrEnabled() {
      return this.isChannelConnectedToAnyTeam ? 'text-disable' : 'text-enable';
    },
    isChannelConnectedToAnyTeam() {
      if (!this.hasWorkload || !this.entityType === 'member') {
        return false;
      }
      return this.entity.workloadBalancingTeams?.length > 0;
    },
    isEntityChannel() {
      return this.entityType === 'channel';
    },
    channelImage() {
      const channels_svg = ChannelsIconsMapper();
      return channels_svg[this.entity.type];
    },
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        if (this.isChannelConnectedToAnyTeam) {
          return false;
        }
        this.$emit('select', this.entity);
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style src="./EntityItem.scss" scoped lang="scss" />
