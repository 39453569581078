import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-6 mt-6 flex justify-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_divider = _resolveComponent("t-divider")!
  const _component_copy_linear = _resolveComponent("copy-linear")!
  const _component_t_input_text = _resolveComponent("t-input-text")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_modal = _resolveComponent("t-modal")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_t_modal, {
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isOpen) = $event)),
    title: _ctx.translations.title,
    "data-test": "two-factor-authentication-setup-step-one",
    variant: "narrow",
    onClose: _ctx.handleClose
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_t_button, {
        size: "md",
        "btn-style": "secondary",
        onClick: _ctx.handleClose
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translations.cancel), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"]),
      _createVNode(_component_t_button, {
        size: "md",
        onClick: _ctx.handleContinue
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translations.continue), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.translations.description) + " ", 1 /* TEXT */),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _ctx.qr,
          class: "w-48 rounded-xl border border-solid p-2"
        }, null, 8 /* PROPS */, _hoisted_2)
      ]),
      _createVNode(_component_t_divider, {
        class: "my-6",
        text: _ctx.translations.secretLabel
      }, null, 8 /* PROPS */, ["text"]),
      _createVNode(_component_t_input_text, {
        id: "twoFactorAuthenticationSecret",
        ref: "secret",
        "model-value": _ctx.secret,
        readonly: ""
      }, {
        end: _withCtx(() => [
          _withDirectives((_openBlock(), _createElementBlock("button", {
            class: "flex",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCopyToClipboard && _ctx.handleCopyToClipboard(...args)))
          }, [
            _createVNode(_component_copy_linear)
          ])), [
            [_directive_tooltip, {
            placement: 'top',
            content: _ctx.buttonText,
            popperClass: 'tooltip-vuetify',
            show: _ctx.isTooltipVisible,
            trigger: 'manual',
          }]
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["model-value"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title", "onClose"]))
}