<template>
  <t-button class="flex flex-row items-center" @click="handleClick">
    <span class="mr-1">
      <plus-linear size="1rem" />
    </span>
    <span><slot /></span>
  </t-button>
</template>

<script>
import { PlusLinear } from '@trengo/trengo-icons';
export default {
  name: 'AddButton',
  emits: ['click'],
  components: { PlusLinear },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>
