<template>
  <div data-test="available-columns" @keypress.enter="isOpen = true">
    <t-popover v-model="isOpen" click-outside-close>
      <template #trigger>
        <div tabindex="0" class="dropdown-input" :class="{ active: isOpen }">
          <p class="t-text-sm m-0 mr-10 text-grey-700">{{ $t('reports_v2.selected_columns') }}</p>
          <chevron-down-linear size="1.25rem" />
        </div>
      </template>

      <template #content>
        <div class="relative">
          <div
            class="
              absolute
              -right-5
              -top-1
              z-50
              mt-2
              max-w-[296px]
              rounded-xl
              border border-grey-300
              bg-white
              p-2
              shadow-500
            "
          >
            <ul class="mb-0 max-h-[372px] w-full overflow-x-hidden overflow-y-scroll px-2">
              <t-list-item-checkbox
                v-for="item in Object.keys(dropdownItems)"
                :id="`${item}-${selectedItems[item]}`"
                :key="item"
                :label="dropdownItems[item]"
                :model-value="selectedItems[item]"
                @click.prevent="() => handleClickCheckbox(item)"
              />
            </ul>
          </div>
        </div>
      </template>
    </t-popover>
  </div>
</template>

<script lang="ts">
import { ChevronDownLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import { DefaultAvailableColumns } from '@/components/Reporting/Pages/V2/TicketInsights/utils';

import { fetchDropdownItems } from './utils';

import type { DropDownItems } from './utils';
import type { PropType } from 'vue';

type Data = {
  isOpen: boolean;
  dropdownItems: DropDownItems;
  selectedItems: typeof DefaultAvailableColumns;
};

export default defineComponent({
  name: 'AvailableColumnsDropdown',
  emits: ['change'],
  components: { ChevronDownLinear },
  data(): Data {
    return {
      isOpen: false,
      dropdownItems: {} as DropDownItems,
      selectedItems: this.selectedColumns,
    };
  },
  props: {
    selectedColumns: {
      type: Object as PropType<typeof DefaultAvailableColumns>,
      default: DefaultAvailableColumns,
    },
  },
  watch: {
    selectedItems: {
      deep: true,
      handler() {
        this.popUpSelectedItems();
      },
    },
    selectedColumns(value) {
      this.selectedItems = value;
    },
  },
  mounted() {
    this.dropdownItems = fetchDropdownItems((key) => this.$t(key));
  },
  methods: {
    popUpSelectedItems() {
      this.$emit('change', this.selectedItems);
    },
    handleClickCheckbox(item) {
      Object.assign(this.selectedItems, {
        [item]: !this.selectedItems[item],
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.dropdown-input {
  @apply flex h-8 items-center rounded-lg border border-grey-400 bg-white p-2 shadow-100;
  &:focus,
  &:focus-within {
    @apply border-leaf-500 shadow-focus;

    &:hover {
      @apply border-leaf-500;
    }
  }

  &.active {
    @apply border-leaf-500 shadow-focus;

    &:hover {
      @apply border-leaf-500;
    }
  }
}
</style>
