<script type="text/babel">
import { head, map, toArray } from 'lodash';
import Multiselect from 'vue-multiselect';

import eventBus from '@/eventBus';

import Status from './Status.vue';

export default {
  name: 'ChatbotSettings',
  emits: ['update-chatbot-record'],
  props: {
    record: {
      type: Object,
      required: true,
    },
  },

  components: {
    Multiselect,
    Status,
  },

  data() {
    return {
      title: this.record.title,
      is_active: this.record.is_active,
      threshold: this.record.threshold,
      intro_text: this.record.intro_text,
      no_answer_text: this.record.no_answer_text,
      fallback_text: this.record.fallback_text,
      fallback_accepted_text: this.record.fallback_accepted_text,
      fallback_declined_text: this.record.fallback_declined_text,
      chatbot_name: this.record.chatbot_name,
      agent_fallback: this.record.agent_fallback,
      agent_fallback_confirmation: this.record.agent_fallback_confirmation,
      failure_limit: this.record.failure_limit,
      partial_no_answer_text: this.record.partial_no_answer_text,
      threshold_partial_answer: this.record.threshold_partial_answer,
      partial_no_answer_limit: this.record.partial_no_answer_limit,
      channels: [],
      deleting: false,
      saving: false,
      compatableChannels: [],
      showAdvanced: false,
      client_access_token: this.record.client_access_token,
      client_access_secret: this.record.developer_access_token,
      google_key: this.record.google_key ? this.record.google_key.key : null,
    };
  },

  mounted() {
    this.loadChannels();
  },

  methods: {
    save() {
      this.saving = true;

      let data = {
        title: this.title,
        is_active: this.is_active,
        threshold: this.threshold,
        intro_text: this.intro_text,
        no_answer_text: this.no_answer_text,
        fallback_text: this.fallback_text,
        fallback_accepted_text: this.fallback_accepted_text,
        fallback_declined_text: this.fallback_declined_text,
        partial_no_answer_text: this.partial_no_answer_text,
        threshold_partial_answer: this.threshold_partial_answer,
        failure_limit: this.failure_limit,
        agent_fallback: this.agent_fallback,
        agent_fallback_confirmation: this.agent_fallback_confirmation,
        partial_no_answer_limit: this.partial_no_answer_limit,
        chatbot_name: this.chatbot_name,
        channels: toArray(map(this.channels, 'id')),
        client_access_token: this.client_access_token,
        client_access_secret: this.client_access_secret,
        google_key: this.google_key,
      };

      axios
        .put('/api/v2/chatbot/' + this.record.id, data)
        .then((res) => {
          this.$emit('update-chatbot-record', res.data);
          this.saving = false;
          this.flashSuccess('You have successfully updated your chatbot settings.');
          eventBus.$emit('chatbots.list.reload');
        })
        .catch(() => {
          this.saving = false;
        });
    },

    loadChannels() {
      axios.get('/api/v2/chatbot/channels?skip=' + this.record.id).then((res) => {
        this.compatableChannels = res.data;

        this.record.channels.map((channel) => {
          this.channels.push(
            head(
              res.data.filter((c) => {
                return c.id == channel.id;
              })
            )
          );
        });
      });
    },

    async deleteChatbot() {
      const shouldDeleteChatbot = await this.$tConfirm(this.$t('chatbot.delete_chatbot_confirmation'), {
        delete: true,
        title: this.$t('general.are_you_sure'),
      });
      if (shouldDeleteChatbot) {
        this.deleting = true;

        axios.delete('/api/v2/chatbot/' + this.record.id).then(() => {
          router.push('/chatbots');
          this.flashSuccess('The chatbot has been deleted successfully.');
          eventBus.$emit('chatbots.list.reload');
        });
      }
    },
  },
};
</script>

<template>
  <div class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis">{{ $t('chatbot.settings') }}</span>
          <!--<div class="m-l-auto">-->
          <!--<button class="btn info text-white btn-md" type="submit">Save</button>-->
          <!--</div>-->
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body container">
        <div class="row-inner">
          <div class="p-a">
            <form @submit.prevent="save">
              <!--<div class="box">-->
              <!--<div class="box-body flow-root">-->
              <!--<button type="submit" class="btn success text-white pull-left" :disabled="saving" :class="{loader:saving}">Save</button>-->
              <!--</div>-->
              <!--</div>-->
              <status :record="record"></status>
              <div class="box">
                <div class="box-header">
                  <h2>{{ $t('chatbot.general') }}</h2>
                </div>
                <div class="box-divider m-a-0"></div>
                <div class="box-body">
                  <div v-if="record.key_assigned" class="form-group row">
                    <label class="col-sm-2 form-control-label min-w-min">{{ $t('chatbot.published') }}</label>
                    <div class="col-sm-8">
                      <label class="ui-switch success m-t-xs m-r">
                        <input v-model="is_active" type="checkbox" true-value="1" />
                        <i></i>
                      </label>
                      <span class="form-text">{{ $t('chatbot.subtitle_published') }}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 form-control-label min-w-min">{{ $t('chatbot.title') }}</label>
                    <div class="col-sm-8">
                      <input v-model="title" type="text" required class="form-control" />
                      <span class="form-text">{{ $t('chatbot.the_internal_name_for_this_chatbot') }}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 form-control-label min-w-min">{{ $t('chatbot.channels') }}</label>
                    <div class="col-sm-8">
                      <multiselect
                        v-model="channels"
                        label="display_name"
                        track-by="id"
                        :allow-empty="true"
                        :options="compatableChannels"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        :multiple="true"
                        :placeholder="$t('chatbot.select_one_or_more_channels')"
                      ></multiselect>
                      <span class="form-text">{{ $t('chatbot.the_channels_on_which_the_chatbot_is_active') }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box">
                <div class="box-header">
                  <h2>{{ $t('chatbot.content') }}</h2>
                </div>
                <div class="box-divider m-a-0"></div>
                <div class="box-body">
                  <div class="form-group row">
                    <label class="col-sm-2 form-control-label min-w-min">{{ $t('chatbot.chatbot_name') }}</label>
                    <div class="col-sm-8">
                      <input v-model="chatbot_name" type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 form-control-label min-w-min">{{ $t('chatbot.introduction_text') }}</label>
                    <div class="col-sm-8">
                      <textarea v-model="intro_text" required class="form-control" rows="3"></textarea>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 form-control-label min-w-min">{{ $t('chatbot.no_answer_text') }}</label>
                    <div class="col-sm-8">
                      <textarea v-model="no_answer_text" required class="form-control" rows="3"></textarea>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 form-control-label min-w-min">{{ $t('chatbot.fallback_text') }}</label>
                    <div class="col-sm-8">
                      <textarea v-model="fallback_text" required class="form-control" rows="3"></textarea>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 form-control-label min-w-min">
                      {{ $t('chatbot.fallback_accepted_text') }}
                    </label>
                    <div class="col-sm-8">
                      <textarea v-model="fallback_accepted_text" required class="form-control" rows="3"></textarea>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 form-control-label min-w-min">
                      {{ $t('chatbot.fallback_declined_text') }}
                    </label>
                    <div class="col-sm-8">
                      <textarea v-model="fallback_declined_text" required class="form-control" rows="3"></textarea>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 form-control-label min-w-min">
                      {{ $t('chatbot.partial_no_answer_text') }}
                    </label>
                    <div class="col-sm-8">
                      <textarea v-model="partial_no_answer_text" required class="form-control" rows="3"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box">
                <div class="box-header">
                  <h2>{{ $t('chatbot.advanced') }}</h2>
                </div>
                <div class="box-divider m-a-0"></div>
                <div class="box-body">
                  <div class="form-group row">
                    <label class="col-sm-2 form-control-label min-w-min">{{ $t('chatbot.agent_fallback') }}</label>
                    <div class="col-sm-8">
                      <label class="ui-switch success m-t-xs m-r">
                        <input v-model="agent_fallback" type="checkbox" true-value="1" />
                        <i></i>
                      </label>
                      <span class="form-text">
                        {{ $t('chatbot.subtitle_agent_fallback') }}
                      </span>
                    </div>
                  </div>
                  <div v-show="agent_fallback == true" class="form-group row">
                    <label class="col-sm-2 form-control-label min-w-min">{{ $t('chatbot.failure_limit') }}</label>
                    <div class="col-sm-8">
                      <input v-model="failure_limit" type="number" class="form-control" />
                      <span class="form-text">
                        {{ $t('chatbot.subtitle_failure_limit') }}
                      </span>
                    </div>
                  </div>
                  <div v-show="agent_fallback == true" class="form-group row">
                    <label class="col-sm-2 form-control-label min-w-min">
                      {{ $t('chatbot.partial_answer_failure_limit') }}
                    </label>
                    <div class="col-sm-8">
                      <input v-model="partial_no_answer_limit" type="number" class="form-control" />
                      <span class="form-text">
                        {{ $t('chatbot.subtitle_partial_no_limit_answer') }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 form-control-label min-w-min">
                      {{ $t('chatbot.agent_fallback_confirmation') }}
                    </label>
                    <div class="col-sm-8">
                      <label class="ui-switch success m-t-xs m-r">
                        <input v-model="agent_fallback_confirmation" type="checkbox" true-value="1" />
                        <i></i>
                      </label>
                      <span class="form-text">
                        {{ $t('chatbot.subtitle_agent_fallback_confirmation') }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 form-control-label min-w-min">{{ $t('chatbot.answer_confidence') }}</label>
                    <div class="col-sm-8">
                      <input v-model="threshold" type="number" class="form-control" />
                      <span class="form-text">
                        {{ $t('chatbot.subtitle_answer_confidence') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="record.key_assigned == 2" class="box">
                <div class="box-header">
                  <h2>{{ $t('chatbot.google_dialogflow') }}</h2>
                </div>
                <div class="box-divider m-a-0"></div>
                <div class="box-body">
                  <!--<div class="form-group row">-->
                  <!--<label class="col-sm-2 form-control-label min-w-min">Client token</label>-->
                  <!--<div class="col-sm-8">-->
                  <!--<input type="text" v-model="client_access_token" class="form-control" />-->
                  <!--</div>-->
                  <!--</div>-->
                  <!--<div class="form-group row">-->
                  <!--<label class="col-sm-2 form-control-label min-w-min">Client secret</label>-->
                  <!--<div class="col-sm-8">-->
                  <!--<input type="text" v-model="client_access_secret" class="form-control" />-->
                  <!--</div>-->
                  <!--</div>-->

                  <div class="form-group row">
                    <label class="col-sm-2 form-control-label min-w-min">{{ $t('chatbot.google_cloud_key_v2') }}</label>
                    <div class="col-sm-8">
                      <textarea v-model="google_key" class="form-control" rows="6"></textarea>
                      <span class="form-text">{{ $t('chatbot.paste_your_google_cloud_json_key_here') }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box">
                <div class="box-body flow-root">
                  <button
                    type="submit"
                    class="btn success pull-left text-white"
                    :disabled="saving"
                    :class="{ loader: saving }"
                  >
                    {{ $t('chatbot.save') }}
                  </button>
                  <button type="button" class="btn white pull-right" :disabled="deleting" @click="deleteChatbot()">
                    {{ $t('chatbot.delete_chatbot') }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
