<template>
  <div style="height: calc(100% - 80px)" class="flex justify-center overflow-auto">
    <div class="m-auto flex max-w-md flex-col items-center justify-center gap-3 px-5">
      <t-thumbnail size="large" class="mb-3 flex-shrink-0">
        <log-in-linear />
      </t-thumbnail>
      <h3 class="t-text-h3 text-center">
        {{ $t('auth.two_factor_authentication_setup_step_two_title') }}
      </h3>
      <p class="t-text-md text-center text-grey-800">
        {{ $t('auth.two_factor_authentication_setup_step_two_description') }}
      </p>
      <t-pin-input
        value=""
        :size="6"
        :error-message="validationMessage"
        :disabled="isVerifying"
        autofocus
        class="mb-6"
        @complete="handleSubmit"
        @input="storeValue"
      />
      <div
        class="mb-4 mt-auto flex w-full flex-col-reverse items-stretch justify-center gap-3 md:flex-row md:items-start"
      >
        <t-button size="md" btn-style="secondary" @click="handleBack">
          <arrow-left-linear class="inline" />
          {{ $t('general.back') }}
        </t-button>
        <t-button size="md" :disabled="isVerifying || isInvalid" @click="handleContinue">
          {{
            isVerifying
              ? $t('auth.two_factor_authentication_setup_step_two_is_verifying_label')
              : $t('auth.two_factor_authentication_setup_step_two_verify_label')
          }}
        </t-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ArrowLeftLinear, LogInLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';

import { sendVerificationCode } from '../api';
import { SETUP_STEP } from '../constants';

import type { StepTwoData } from '../types';

export default defineComponent({
  name: 'TwoFactorAuthSetupStepTwo',
  emits: ['goToStep'],
  components: { ArrowLeftLinear, LogInLinear },
  props: {
    isOpen: Boolean,
  },
  data(): StepTwoData {
    return {
      otp: null,
      isVerifying: false,
      isInvalid: false,
      errorMessage: '',
    };
  },
  computed: {
    validationMessage() {
      return this.isInvalid ? this.errorMessage : '';
    },
  },
  watch: {
    otp(_value) {
      this.isInvalid = false;
    },
  },
  methods: {
    ...mapMutations({
      setUserHas2FAEnabled: 'initialData/setUserHas2FAEnabled',
    }),
    handleBack() {
      this.$emit('goToStep', SETUP_STEP.ONE);
    },
    storeValue(value: string) {
      this.otp = value;
    },
    handleContinue() {
      this.handleSubmit(this.otp);
    },
    async handleSubmit(value: string | null) {
      if (this.isInvalid) return;
      this.isVerifying = true;
      try {
        await sendVerificationCode(value);
        this.setUserHas2FAEnabled(true);
        this.$router.push('/');
        this.flashSuccess(this.$t('auth.two_factor_authentication_enabled'));
      } catch (err) {
        this.isInvalid = true;
        this.errorMessage = err?.response?.data?.message || '';
      } finally {
        this.isVerifying = false;
      }
    },
  },
});
</script>
