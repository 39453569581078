import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const aiRoutes: RouteRecord[] = [
  {
    path: '/ai/helpmate/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "HelpmateDashboard" */ '@/components/AI/Pages/Dashboard.vue'),
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
    name: 'helpmate',
  },
  {
    path: '/ai/terms/helpmate',
    component: () => import(/* webpackChunkName: "HelpmateTAndC" */ '@/components/AI/Pages/TermsAndCondition.vue'),
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
    name: 'helpmateTerms',
  },
];

export default aiRoutes;
