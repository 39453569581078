import { ref } from 'vue';

export default function useSidebarFetch() {
  const hasFetched = ref(false);
  const isDropdownToggled = ref(false);

  const setDropdownStatus = (status: boolean, callback: () => unknown) => {
    isDropdownToggled.value = status;

    if (status && !hasFetched.value) {
      callback();
    }
  };

  return {
    hasFetched,
    isDropdownToggled,
    setDropdownStatus,
  };
}
