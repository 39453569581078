<template>
  <on-click-outside class="absolute" @trigger="emit('close-modal')">
    <div class="flex flex-col rounded-md bg-white p-4 shadow-md">
      <p class="t-text-sm-emphasize m-0 mx-auto mb-2 p-0">{{ $tc('user_profile.email') }}</p>

      <t-input-text
        id="editEmail"
        v-model="emailCopy"
        class="mb-3"
        size="sm"
        :placeholder="email"
        @keydown.enter.prevent="saveEmail"
        @keydown.esc="emit('close-modal')"
      />

      <div class="flex flex-row justify-between">
        <t-button btn-style="secondary" @click.prevent="emit('close-modal')">{{ $tc('general.cancel') }}</t-button>
        <t-button @click.prevent="saveEmail">{{ $tc('general.save') }}</t-button>
      </div>
    </div>
  </on-click-outside>
</template>

<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components';
import { nextTick, onMounted, ref } from 'vue';

import { vm } from '@/ApplicationHandler';
import { updateContactEmail } from '@/components/TicketSidebar/Api/requests';
import eventBus from '@/eventBus';

import type Contact from '@/types/contact';

const props = defineProps<{ email: Contact['email']; contactId: Contact['id'] }>();
const emit = defineEmits<{ (e: 'close-modal'): void }>();

const emailCopy = ref(props.email);

onMounted(() => {
  nextTick(() => {
    document?.getElementById('editEmail')?.focus();
  });
});

async function saveEmail() {
  if (!emailCopy.value) {
    return;
  }

  if (emailCopy.value === props.email) {
    emit('close-modal');
    vm.flashSuccess(vm.$tc('general.successful_update'));
    return;
  }

  try {
    await updateContactEmail(props.contactId, emailCopy.value).then(() => {
      eventBus.$emit('ticket.current.reload', { focus: true });
      emit('close-modal');
      vm.flashSuccess(vm.$tc('general.successful_update'));
    });
  } catch (err) {
    console.error(err);
    vm.flashError(err.response.data.message);
  }
}
</script>
