import type {
  RouteLocationNormalizedGeneric,
  RouteLocationNormalizedLoadedGeneric,
  NavigationGuardNext,
} from 'vue-router';

function hasQueryParams(route: RouteLocationNormalizedGeneric) {
  return !!Object.keys(route.query).length;
}

export function queryParamsGuard(
  to: RouteLocationNormalizedGeneric,
  from: RouteLocationNormalizedLoadedGeneric,
  next: NavigationGuardNext,
) {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    if (from.name === 'reportingCSAT' && to.name === 'reportingTicketInsights') {
      next();
    } else {
      next({ name: to.name, query: from.query });
    }
  } else {
    next();
  }
}
