<template>
  <div>
    <div ref="modal" class="modal">
      <div class="modal-centered height-auto">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('voice.ivr_greetings') }}</h5>
            <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close-btn">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body" style="max-height: 500px; overflow-y: auto">
            <table id="general-table" class="table-striped table-vcenter table">
              <thead>
                <tr>
                  <th>{{ $t('voice.ivr_greeting') }}</th>
                  <th>{{ $t('voice.ivr_audio') }}</th>
                  <th class="text-center"><i class="fa fa-flash"></i></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(url, type) in default_greetings">
                  <td>{{ description_types[type]['description'] }}</td>
                  <td>
                    <audio controls>
                      <source :src="url" type="audio/mpeg" />
                      {{ $t('voice.ivr_your_browser_does_not_support_the_audio_element') }}
                    </audio>
                  </td>
                  <td></td>
                </tr>
                <tr v-for="greeting in greetings">
                  <td>{{ greeting['title'] }}</td>
                  <td><audio controls :src="greeting['file_url']"></audio></td>
                  <td class="text-center">
                    <a @click="deleteGreeting(greeting['id'])">
                      <i class="material-icons text-red">remove_circle_outline</i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer text-left">
            <button type="button" class="btn btn-default" @click="startAddingGreeting">
              {{ $t('voice.ivr_new_greeting') }}
            </button>
            <button type="button" class="btn btn-link text-muted" data-dismiss="modal">
              {{ $t('voice.ivr_cancel') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div ref="newModal" class="modal">
      <div class="modal-centered height-auto">
        <div class="modal-content">
          <form class="form-horizontal" @submit.prevent="addGreeting">
            <div>
              <div class="modal-header">
                <h5 class="modal-title">{{ $t('voice.ivr_add_greeting') }}</h5>
                <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close-btn">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">
                    {{ $t('voice.ivr_greeting_title') }}
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-sm-10">
                    <input v-model="greeting.title" type="text" autocomplete="off" class="form-control" />
                    <span class="form-text">{{ $t('voice.ivr_internal_display_title') }}</span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">
                    {{ $t('voice.ivr_audio') }}
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-sm-10">
                    <label class="radio-inline">
                      <input v-model="upload_method" type="radio" value="record" name="audio_type" checked="" />
                      {{ $t('voice.ivr_record_via_browser') }}
                    </label>
                    <label class="radio-inline">
                      &nbsp;
                      <input v-model="upload_method" type="radio" value="upload" name="audio_type" />
                      {{ $t('voice.ivr_upload_file') }}
                    </label>

                    <div v-if="upload_method == 'record'">
                      <br />
                      <audio ref="uploader_preview" controls autoplay class="mb-2"></audio>
                      <br />
                      <div class="flex">
                        <input
                          class="btn btn-default btn-xs mr-2"
                          type="button"
                          :value="$t('voice.ivr_start_recording')"
                          :class="{ disabled: recording }"
                          @click="startRecording()"
                        />
                        <input
                          class="btn btn-default btn-xs"
                          type="button"
                          :value="$t('voice.ivr_stop_recording')"
                          :class="{ disabled: !recording }"
                          @click="stopRecording()"
                        />
                        <span v-show="recording" class="ml-4 mr-2 flex items-center">
                          <label class="label label-xs danger mr-1 rounded" style="margin-bottom: 0"></label>
                          <span class="text-muted" style="line-height: 1">{{ $t('voice.ivr_recording') }}</span>
                        </span>
                      </div>
                    </div>
                    <div v-if="upload_method == 'upload'">
                      <input ref="fileInput" type="file" @change="selectFile($event)" />
                      <br />
                      <span class="text-muted">{{ $t('voice.ivr_allowed_mpeg_wav_mp3_aiff') }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer text-left">
                <button type="submit" class="btn success text-white">{{ $t('voice.ivr_add_greeting') }}</button>
                <button type="button" class="btn btn-link text-muted" data-dismiss="modal">
                  {{ $t('voice.ivr_cancel') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { MediaRecorder, register, deregister } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

export default {
  name: 'Greetings',
  emits: ['changed'],
  props: {
    record: {
      type: Object,
      default: () => ({}),
    },
    defaultGreetings: {
      type: Object,
      default: () => ({}),
    },
    descriptionTypes: {
      type: Object,
      default: () => ({}),
    },
    greetings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      default_greetings: {},
      description_types: {},
      greeting_types: {},
      adding_greeting: false,
      greeting: {},
      upload_method: 'record',
      recording: false,
      audioChunks: [],
      encoderPort: null,
    };
  },

  async mounted() {
    await this.setupRecorder();

    $(this.$refs.newModal).on('hidden.bs.modal', () => {
      $(this.$refs.modal).modal('show');
    });
  },
  unmounted() {
    if (this.encoderPort) {
      try {
        deregister(this.encoderPort);
      } catch (error) {
        console.error('Failed to deregister encoder: ', error);
      }
    }
  },
  methods: {
    async setupRecorder() {
      try {
        window.URL = window.URL || window.webkitURL;

        const { mediaDevices } = navigator;
        if (!mediaDevices || !mediaDevices.getUserMedia) {
          alert(this.$t('voice.ivr_your_browser_does_not_support_the_audio_element'));
          return;
        }
        this.encoderPort = await connect();
        await register(this.encoderPort);
      } catch (error) {
        console.error('Error setting up recorder:', error);
        alert(this.$t('voice.ivr_failed_to_setup_recorder'));
      }
    },

    async deleteGreeting(id) {
      const shouldDeleteGreeting = await this.$tConfirm(
        this.$t('voice.ivr_are_you_sure_you_want_to_delete_this_greeting'),
        {
          delete: true,
          title: this.$t('general.are_you_sure'),
        }
      );
      if (shouldDeleteGreeting) {
        axios.delete('/api/v2/voip/greetings/' + id).then(() => {
          this.$emit('changed');
        });
      }
    },

    startAddingGreeting() {
      $(this.$refs.newModal).modal('show');
      $(this.$refs.modal).modal('hide');
    },

    selectFile(event) {
      this.greeting.file = event.target.files[0];
    },

    addGreeting() {
      let data = new FormData();

      data.append('file', this.greeting.file);
      data.append('title', this.greeting.title ?? '');

      axios.post('/api/v2/voip/greetings', data).then((result) => {
        this.adding_greeting = false;
        this.$emit('changed');
        $(this.$refs.newModal).modal('hide');
        this.greeting = {};
        this.$refs.fileInput.value = '';
      });
    },

    onFail(e) {
      alert(this.$t('voice.ivr_recording_failed'));
    },

    onSuccess(stream) {
      this.greeting.recorder = new MediaRecorder(stream, { mimeType: 'audio/wav' });
      this.greeting.recorder.ondataavailable = (e) => this.audioChunks.push(e.data);

      this.greeting.recorder.onstop = (e) => {
        const blob = new Blob(this.audioChunks, { type: this.greeting.recorder.mimeType });
        this.greeting.file = blob;
        this.audioChunks = [];
        this.$refs.uploader_preview.src = window.URL.createObjectURL(blob);
      };
      this.greeting.recorder.start();
    },

    async startRecording() {
      if (this.recording) {
        return;
      }

      this.recording = true;
      this.$refs.uploader_preview.src = null;
      navigator.mediaDevices.getUserMedia({ audio: true }).then(this.onSuccess).catch(this.onFail);
    },

    stopRecording() {
      if (!this.recording) {
        return;
      }

      this.greeting.recorder.stop();
      this.recording = false;
    },
  },
};
</script>
