import { MiddlewareError } from '@/api/errors/middlewareError';
import { MIDDLEWARE_ERROR } from '@/Configs/Constants/middlewareError';
import { useAuthStore } from '@/store/pinia';

import type { Middleware } from './types';

export const checkAuthentication: Middleware = async (to) => {
  if (to.meta.allowUnauthenticated) {
    return true;
  }
  const auth = useAuthStore();
  await auth.init();
  if (auth.isAuthenticated || to.name === 'login') {
    return true;
  } else {
    throw new MiddlewareError(MIDDLEWARE_ERROR.AUTHENTICATION_REQUIRED);
  }
};
