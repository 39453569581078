<template>
  <integration-loading v-if="isLoading" class="mt-4" />

  <t-inline-banner v-else-if="this.hasLoaded && this.hasError" type="error" class="mt-4">
    <template #icon>
      <cross-octagon-linear />
    </template>

    <p class="t-text-sm-emphasize m-0 p-0">
      {{ $t('integration_hub.sidebar_error_title') }}
    </p>

    <p class="t-text-sm m-0 p-0">
      {{ $t('integration_hub.sidebar_error_description') }}
    </p>
  </t-inline-banner>

  <div v-else-if="this.hasLoaded && !leads?.length" class="mt-4">
    <t-inline-banner type="default">
      <template #icon>
        <info-linear />
      </template>

      <p class="t-text-sm-emphasize m-0 p-0">
        {{ $t('integration_hub.sidebar_no_data_found', { name: this.installation.name }) }}
      </p>
    </t-inline-banner>
  </div>

  <div v-else class="mt-4">
    <div
      v-for="(lead, i) in leads"
      :key="lead.lead_id"
      class="mb-3 rounded-lg border border-grey-300 bg-white px-2 pt-3"
    >
      <div v-if="lead.owner_name">
        <span class="mb-4 ml-2 text-xs text-grey-600">
          {{ $t('integration_hub.sidebar_crm_lead_owner_name') }}
        </span>
        <p class="mb-2 ml-2 text-sm font-semibold">{{ lead.owner_name }}</p>
      </div>

      <div v-if="lead.status">
        <span class="mb-4 ml-2 text-xs text-grey-600">{{ $t('integration_hub.sidebar_crm_lead_status') }}</span>
        <p class="mb-2 ml-2 text-sm font-semibold">{{ lead.status }}</p>
      </div>

      <div v-if="lead.created_at">
        <span class="mb-4 ml-2 text-xs text-grey-600">
          {{ $t('integration_hub.sidebar_crm_lead_created_at') }}
        </span>
        <p class="mb-2 ml-2 text-sm font-semibold">{{ formatDate(lead.created_at) }}</p>
      </div>

      <div v-if="lead.lead_custom_id">
        <span class="mb-4 ml-2 text-xs text-grey-600">{{ $t('integration_hub.sidebar_crm_lead_custom_id') }}</span>
        <p class="mb-2 ml-2 text-sm font-semibold">{{ lead.lead_custom_id }}</p>
      </div>

      <div v-if="lead.lead_id" class="pb-3">
        <span class="mb-4 ml-2 text-xs text-grey-600">{{ $t('integration_hub.sidebar_crm_lead_id') }}</span>
        <p v-if="lead.lead_url">
          <a
            class="mb-4 ml-2 cursor-pointer text-base font-semibold text-leaf-500"
            :href="lead.lead_url"
            target="_blank"
          >
            {{ lead.lead_id }}
          </a>
        </p>
        <p v-else class="mb-4 ml-2 text-base font-semibold">
          {{ lead.lead_id }}
        </p>
      </div>

      <t-divider v-if="i !== Object.keys(leads).length - 1" />
    </div>
  </div>
</template>

<script lang="ts">
import { InfoLinear, CrossOctagonLinear } from '@trengo/trengo-icons';
import moment from 'moment';
import { defineComponent } from 'vue';

import { fetchCrmLeads } from '@/api';

import IntegrationLoading from './IntegrationLoading.vue';

import type { CrmLead } from '@/api';
import type { InstalledIntegration } from '@/store/types/integrations';
import type Ticket from '@/types/tickets';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'IntegrationCrmLeads',

  components: {
    IntegrationLoading,
    InfoLinear,
    CrossOctagonLinear,
  },

  data() {
    return {
      isLoading: false,
      hasError: false,
      hasLoaded: false,
      leads: [] as Array<CrmLead>,
    };
  },

  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    installation: {
      type: Object as PropType<InstalledIntegration>,
      default: () => ({}),
    },
    ticket: {
      type: Object as PropType<Ticket>,
      default: () => ({}),
    },
  },

  methods: {
    async fetchLeads() {
      if (this.hasLoaded || this.isLoading) {
        return;
      }
      try {
        this.isLoading = true;
        const { data: leads } = await fetchCrmLeads(
          this.installation.installation_id,
          this.ticket.contact.email,
          this.ticket.contact.phone
        );
        this.leads = leads;
      } catch (e) {
        this.hasError = true;
      } finally {
        this.hasLoaded = true;
        this.isLoading = false;
      }
    },

    formatDate(date: string) {
      const tz = moment.tz(date, window.APP_TIMEZONE);
      return tz.clone().tz(this.$root.user.timezone).format('DD-MM-Y');
    },
  },

  watch: {
    selected: {
      immediate: true,
      handler(val) {
        if (val) {
          this.fetchLeads();
        }
      },
    },
  },
});
</script>
