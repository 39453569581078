import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const quickRepliesRoutes: RouteRecord[] = [
  {
    path: '/admin/quick_replies',
    component: require('../../components/QuickReplies/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__QUICK_REPLIES__MANAGE },
    children: [
      {
        path: '/admin/quick_replies/create',
        component: require('../../components/QuickReplies/Edit').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__QUICK_REPLIES__MANAGE },
      },
      {
        path: '/admin/quick_replies/:id',
        name: 'quickRepliesEdit',
        component: require('../../components/QuickReplies/Edit').default,
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__QUICK_REPLIES__MANAGE },
      },
      {
        path: '',
        component: require('../../components/AdminIndexBox').default,
        props: { boxName: 'quick_replies' },
        meta: { permissionRequired: PERMISSION.SETTINGS__QUICK_REPLIES__MANAGE },
      },
    ],
  },
];

export default quickRepliesRoutes;
