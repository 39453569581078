<template>
  <div>
    <div class="box">
      <div class="box-header">
        <h2>{{ $t('sms.sms_sms_settings') }}</h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <div v-if="action === 'create' && record.smsChannel.provider === 'TWILIO'">
          <auth-credentials
            v-model="record.smsChannel.auth_credentials_id"
            :help="$t('sms.select_or_add_twilio_account')"
            provider="TWILIO"
          ></auth-credentials>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">
            {{ $t('sms.sms_type') }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-10">
            <select v-model="record.smsChannel.type" class="form-control" :disabled="action === 'edit'">
              <option value="SEND_ONLY">{{ $t('sms.sms_one_way_send_only') }}</option>
              <option value="SEND_AND_RECEIVE">{{ $t('sms.sms_two_way_send_receive') }}</option>
            </select>
            <span v-show="record.smsChannel.type === 'SEND_ONLY'" class="form-text">
              {{
                $t(
                  'sms.sms_due_to_regulations_you_should_not_use_a_one_way_channel_if_you_are_planning_to_message_one_of_the'
                )
              }}
              <a
                style="text-decoration: underline"
                @click="showDisabledAlphaIdCountries = !showDisabledAlphaIdCountries"
              >
                {{ $t('sms.sms_following_countries') }}
              </a>
              .
            </span>
            <pre v-if="showDisabledAlphaIdCountries" class="mt-2 border p-2">{{
              disabledAlphaIdCountries.join('\n')
            }}</pre>
            <span v-show="record.smsChannel.type === 'SEND_ONLY'" class="form-text">
              {{ $t('sms.sms_the') }}
              <a
                style="text-decoration: underline"
                @click="showPreRegisterRequiredAlphaIdCountries = !showPreRegisterRequiredAlphaIdCountries"
              >
                {{ $t('sms.sms_following_countries') }}
              </a>
              {{
                $t(
                  'sms.sms_support_sending_one_way_messages_but_require_pre_registration_which_can_only_be_done_by_using_your_own_twilio_account'
                )
              }}
            </span>
            <pre v-if="showPreRegisterRequiredAlphaIdCountries" class="mt-2 border p-2">{{
              preRegisterRequiredAlphaIdCountries.join('\n')
            }}</pre>
          </div>
        </div>

        <div v-show="record.smsChannel.type === 'SEND_ONLY'" class="form-group row">
          <label class="col-sm-2 form-control-label">
            {{ $t('sms.sms_sender_name') }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-10">
            <input v-model="record.smsChannel.sender_name" class="form-control" />
          </div>
        </div>
        <div
          v-if="record.smsChannel.type === 'SEND_ONLY' && action === 'edit' && record.smsChannel.provider === 'TWILIO'"
          class="form-group row"
        >
          <label class="col-sm-2 form-control-label">{{ $t('sms.sms_connected_to') }}</label>
          <div class="col-sm-10">
            <p class="form-control-static">
              {{ record.smsChannel.auth_credentials[0]['auth_data']['account_sid'] }}
            </p>
          </div>
        </div>

        <div
          v-if="
            record.smsChannel.provider === 'TWILIO' &&
            twilio_numbers_loaded &&
            record.smsChannel.type === 'SEND_AND_RECEIVE' &&
            action == 'create'
          "
          class="form-group row"
        >
          <label class="col-sm-2 form-control-label">{{ $t('sms.sms_phone_number') }}v</label>
          <div v-if="twilio_phone_numbers.length > 0" class="col-sm-10">
            <select v-model="record.smsChannel.meta.twilio_phone_sid" class="form-control">
              <option v-for="record in twilio_phone_numbers" :value="record.id">{{ record.text }}</option>
            </select>
            <span class="form-text">
              {{
                $t(
                  'sms.sms_select_your_twilio_phone_number_from_the_list_note_we_will_overwrite_any_existing_twilio_configurations_for_this_number'
                )
              }}
              <i class="fa fa-refresh ml-2 text-xs" @click="loadNumbers"></i>
            </span>
          </div>
          <div v-else class="col-sm-10">
            <div class="alert alert-grey">
              {{
                $t('sms.sms_this_twilio_account_does_not_own_any_suitable_numbers_yet_please_purchase_a_number_via_the')
              }}
              <a
                style="text-decoration: underline"
                target="_blank"
                href="https://www.twilio.com/console/phone-numbers/incoming"
              >
                {{ $t('sms.sms_twilio_console') }}
              </a>
              .
              <button type="button" class="btn btn-default btn-xs ml-4" @click="loadNumbers()">
                {{ $t('sms.sms_try_again') }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="
            record.smsChannel.provider === 'TWILIO' && record.smsChannel.type === 'SEND_AND_RECEIVE' && action == 'edit'
          "
        >
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('sms.sms_phone_number') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">
                {{ record.phone }}
                <a
                  class="btn btn-xs btn-default ml-2"
                  :href="'https://www.twilio.com/console/phone-numbers/' + record.smsChannel.meta.twilio_phone_sid"
                  target="_blank"
                >
                  {{ $t('sms.sms_view_in_twilio') }}
                </a>
              </p>
            </div>
          </div>
        </div>

        <div
          v-if="
            record.smsChannel.provider === 'TWILIO_TRENGO' &&
            record.smsChannel.type === 'SEND_AND_RECEIVE' &&
            action == 'edit'
          "
        >
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('sms.sms_phone_number') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">{{ record.phone }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import _ from 'lodash';

import AuthCredentials from './AuthCredentials';

export default {
  name: 'SmsChannel',
  emits: ['update-ready', 'call-init-warning-method'],
  components: {
    AuthCredentials,
  },

  props: {
    action: {
      type: String,
      default: 'create',
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      available_phone_numbers: [],
      phone_numbers: [],
      searching: false,
      phone_number: {
        country: 'NL',
        prefix: '',
        provider: 'twilio',
        sms: 1,
        tollFree: 0,
      },
      showDisabledAlphaIdCountries: false,
      showPreRegisterRequiredAlphaIdCountries: false,

      twilio_phone_numbers: [],
      twilio_numbers_loaded: false,
      loading_twilio_numbers: false,
    };
  },

  watch: {
    'phone_number.country'(v) {
      this.phone_number.prefix = '';
      this.record.smsChannel.country = v;
    },
    'record.smsChannel.auth_credentials_id'(v) {
      this.twilio_numbers_loaded = false;
      this.record.smsChannel.meta.twilio_phone_sid = null;
      this.twilio_phone_numbers = [];
      this.$emit('update-ready', false);
      if (v !== null) {
        this.loadNumbers();
      }
    },
    'record.smsChannel.type'() {
      this.$emit('update-ready', true);
    },
    '$route.query.provider'() {
      window.location.reload();
    },
  },

  computed: {
    // https://support.twilio.com/hc/en-us/articles/223133767-International-support-for-Alphanumeric-Sender-ID
    disabledAlphaIdCountries() {
      return [
        'Argentina',
        'Bahamas',
        'Belgium',
        'Brazil',
        'Canada',
        'Cayman Islands (UK)',
        'Chile',
        'China',
        'Colombia',
        'Costa Rica',
        'Dominican Republic',
        'Ecuador',
        'El Salvador',
        'French Guiana',
        'Guam',
        'Guatemala',
        'Hungary',
        'India',
        'Kyrgyzstan',
        'Malaysia',
        'Monaco',
        'Nauru',
        'New Zealand',
        'Nicaragua',
        'Panama',
        'Paraguay',
        'Peru',
        'Puerto Rico',
        'South Africa',
        'South Korea',
        'Syria',
        'Taiwan',
        'United States',
        'Uruguay',
      ];
    },
    preRegisterRequiredAlphaIdCountries() {
      return [
        'Afghanistan',
        'Armenia',
        'Belarus',
        'Benin',
        'Cameroon',
        'Chad',
        'Congo (Republic of the Congo)',
        'Cuba',
        'Czech Republic',
        'Egypt',
        'Ethiopia',
        'Ghana',
        'Guinea',
        'Guinea-Bissau',
        'Indonesia',
        'Iran',
        'Israel',
        "Ivory Coast (Côte d'Ivoire)",
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kuwait',
        'Lebanon',
        'Liberia',
        'Malawi',
        'Mexico',
        'Morocco',
        'Myanmar',
        'Nepal',
        'Nigeria',
        'Oman',
        'Pakistan',
        'Palestine',
        'Philippines',
        'Qatar',
        'Russia',
        'Rwanda',
        'Samoa',
        'Saudi Arabia',
        'South Sudan',
        'Sri Lanka',
        'Sudan',
        'Tanzania',
        'Thailand',
        'Tunisia',
        'Turkey',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'Vietnam',
        'Yemen',
        'Zambia',
        'Zimbabwe',
      ];
    },
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  mounted() {
    if (this.action === 'create') {
      this.record.smsChannel.provider = this.$route.query.provider;

      if (this.record.smsChannel.provider !== 'TWILIO') {
        router.push('/admin/channels2/sms');
      }

      if (this.record.smsChannel.provider === 'TWILIO') {
        this.$emit('update-ready', false);
      }
    }
  },

  methods: {
    loadNumbers() {
      this.twilio_numbers_loaded = false;
      this.record.smsChannel.meta.twilio_phone_sid = null;
      this.twilio_phone_numbers = [];
      this.$emit('update-ready', false);

      this.loading_twilio_numbers = true;

      axios
        .post('/api/v2/twilio/incoming_phone_numbers', {
          type: 'sms',
          auth_credentials_id: this.record.smsChannel.auth_credentials_id,
        })
        .then((res) => {
          if (!this.record.smsChannel.auth_credentials_id) {
            return;
          }

          this.twilio_phone_numbers = res.data;
          this.twilio_numbers_loaded = true;
          this.loading_twilio_numbers = false;

          if (res.data.length > 0) {
            this.record.smsChannel.meta.twilio_phone_sid = res.data[0].id;
            this.$emit('update-ready', true);
          }
        })
        .catch((res) => {
          this.loading_twilio_numbers = false;
        });
    },

    findPhoneNumbers: _.debounce(function (init = false) {
      this.searching = true;
      this.record.smsChannel.phone_number = null;
      this.$emit('call-init-warning-method');
      this.phone_numbers = [];
      axios
        .get('/api/v2/available_phone_numbers', { params: this.phone_number })
        .then((result) => {
          if (result.data.type !== 'error') {
            this.phone_numbers = result.data;
            this.record.smsChannel.phone_number = result.data[0];
            this.$emit('call-init-warning-method');
          }
          this.searching = false;
        })
        .catch(() => {})
        .finally(() => {
          this.searching = false;
          if (init) {
            this.$emit('call-init-warning-method');
          }
        });
    }, 200),
  },
};
</script>
