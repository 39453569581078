import { app } from '@/main';
import tStorage from '@/util/tStorage';
/**
 * @description LocalStorage Wrapper Vue Plugin
 * @type {{install(*): void}}
 */
const storage = {
  install() {
    app.config.globalProperties.$tStorage = tStorage;
  },
};

export default storage;
