<template>
  <div class="select-none pl-3 pt-4">
    <collapse collapse-key="collapseContacts" :default-open="true" :hide-arrow-icon="true">
      <template #collapse-title>
        <div>
          <h6>{{ $t('contacts.title') }}</h6>
        </div>
      </template>

      <template #collapse-content>
        <div>
          <router-link
            to="/contacts/all"
            class="collapse-menu-item selector-filter-all-contacts t-text-sm flex cursor-pointer items-center rounded"
            active-class="bg-grey-200 text-grey-900"
          >
            <div style="width: 20px">
              <FolderLinear />
            </div>
            {{ $t('contacts.all_contacts') }}
          </router-link>
          <router-link
            class="collapse-menu-item t-text-sm flex items-center rounded text-grey-600"
            active-class="bg-grey-200 text-grey-900"
            to="/sms/import"
          >
            <div style="width: 20px">
              <FolderLinear />
            </div>
            {{ $t('sms.import_contacts') }}
          </router-link>
          <router-link
            v-if="hasPrivateChannels"
            to="/contacts/mine"
            class="collapse-menu-item selector-filter-my-contacts t-text-sm flex cursor-pointer items-center rounded"
            active-class="bg-grey-200 text-grey-900"
          >
            <div style="width: 20px">
              <FolderLinear />
            </div>
            {{ $t('contacts.my_contacts') }}
          </router-link>
        </div>
      </template>
    </collapse>
    <collapse collapse-key="collapseContacts" class="mt-6" :default-open="true" :hide-arrow-icon="true">
      <template #collapse-title>
        <div>
          <h6>{{ $t('profile.filter_profiles') }}</h6>
        </div>
      </template>
      <template #collapse-content>
        <div>
          <router-link
            to="/profiles"
            class="collapse-menu-item selector-filter-all-profiles t-text-sm flex cursor-pointer items-center rounded"
            active-class="bg-grey-200 text-grey-900"
          >
            <div style="width: 20px">
              <FolderLinear />
            </div>
            {{ $t('profile.all_profiles') }}
          </router-link>
        </div>
      </template>
    </collapse>

    <collapse
      v-if="contactGroupsFeatureFlagEnabled"
      collapse-key="collapseContacts"
      class="mt-6"
      :default-open="true"
      :hide-arrow-icon="true"
    >
      <template #collapse-title>
        <div>
          <h6>
            {{ $t('broadcast.contact_group') }}
          </h6>
        </div>
      </template>
      <template #collapse-content>
        <div>
          <router-link
            to="/contact_groups"
            class="collapse-menu-item selector-filter-all-profiles t-text-sm flex cursor-pointer items-center rounded"
            active-class="bg-grey-200 text-grey-900"
          >
            <div style="width: 20px">
              <FolderLinear />
            </div>
            {{ $t('broadcast.all_contact_groups') }}
          </router-link>
        </div>
      </template>
    </collapse>
  </div>
</template>

<script>
import { FolderLinear } from '@trengo/trengo-icons';

import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

import Collapse from '../Collapse';
export default {
  components: { Collapse, FolderLinear },
  computed: {
    hasPrivateChannels() {
      return this.$root.channels.filter((c) => c.is_private).length > 0;
    },
    contactGroupsFeatureFlagEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.TI_CG_FRONTEND);
    },
  },
};
</script>

<style lang="scss">
@media only screen and (min-width: 991px) and (max-width: 1599px) {
  .main-app-sidebar-contacts {
    @apply w-66 #{!important};
  }
}
</style>
