<template>
  <div>
    <FileEditor
      v-bind="$props"
      :accept="'video/x-ms-asf,video/x-ms-wmv,video/x-ms-wmx,video/x-ms-wm,video/avi,video/divx,video/x-flv,video/quicktime,video/mpeg,video/mp4,video/ogg,video/webm,video/x-matroska'"
    ></FileEditor>
    <TextEditor
      v-bind="$props"
      :title="$t('flowbot.step_video_caption')"
      :placeholder="$t('flowbot.step_video_optional_video_caption')"
    />
  </div>
</template>
<script>
import FileEditor from './File';
import TextEditor from './Text';

export default {
  props: ['element', 'customFields'],

  components: {
    FileEditor,
    TextEditor,
  },

  methods: {},
};
</script>
