<template>
  <div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.custom_label_app_url') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.url" type="text" autocomplete="off" class="form-control" />
        <span class="form-text">{{ $t('integrations.custom_subtitle_app_url') }}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.custom_label_auth_token') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input
          v-model="record.meta.auth_token"
          type="text"
          disabled="disabled"
          :placeholder="$t('integrations.custom_placeholder_auth_token')"
          autocomplete="off"
          class="form-control"
        />
        <span class="form-text">
          {{ $t('integrations.custom_subtitle_auth_token') }}
        </span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">{{ $t('integrations.custom_label_height') }}</label>
      <div class="col-sm-10">
        <input
          v-model="record.meta.height"
          text="text"
          class="form-control"
          :placeholder="$t('integrations.custom_placeholder_height')"
        />
        <span class="form-text">
          {{ $t('integrations.custom_subtitle_height') }}
        </span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label"></label>
      <div class="col-sm-10">
        <div class="alert alert-info">
          <p v-html="$t('integrations.custom_app_alert_information')"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
