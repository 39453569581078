import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_integration_hub_sidebar = _resolveComponent("integration-hub-sidebar")!
  const _component_portal = _resolveComponent("portal")!
  const _component_settings_base_layout = _resolveComponent("settings-base-layout")!

  return (_openBlock(), _createBlock(_component_settings_base_layout, {
    scroll: false,
    class: "flex w-full flex-1 flex-col overflow-scroll bg-grey-100"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_router_view, { name: "Header" })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_portal, { to: "sidebar" }, {
        default: _withCtx(() => [
          _createVNode(_component_integration_hub_sidebar, { integrations: _ctx.integrations }, null, 8 /* PROPS */, ["integrations"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_router_view)
    ]),
    _: 1 /* STABLE */
  }))
}