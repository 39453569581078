<template>
  <div class="pt-4">
    <div
      v-if="error"
      class="pointer break-words border-l-4 border-orange-light bg-orange-lightest p-4 text-orange-darker"
      role="alert"
      @click="$router.push('/admin/developers/plugins/' + plugin.id)"
    >
      <p>{{ error }}</p>
    </div>
    <loader v-else-if="loading"></loader>
    <div v-else>
      <div v-if="found">
        <template v-if="customer.length">
          <div class="flex items-center">
            <h6 v-if="settings.showCustomerUrl" class="w-100">
              <a class="text-info" target="_blank" :href="getCustomerUrl(customer.id)">
                {{ customer.first_name }} {{ customer.last_name }}
              </a>
              <div v-if="customers && customers.length > 1" class="float-right -mt-4">
                <span
                  :disabled="!customers[index - 1]"
                  :class="{ disabled: !customers[index - 1] }"
                  class="btn btn-sm white valign-center inline-block rounded"
                  style="padding: 0.25rem 0.5rem"
                  @click="() => customers[index - 1] && cycleCustomer(-1)"
                >
                  <i class="material-icons">navigate_before</i>
                </span>
                <span
                  :disabled="!customers[index + 1]"
                  :class="{ disabled: !customers[index + 1] }"
                  class="btn btn-sm white valign-center inline-block rounded"
                  style="padding: 0.25rem 0.5rem"
                  @click="() => customers[index + 1] && cycleCustomer(1)"
                >
                  <i class="material-icons">navigate_next</i>
                </span>
              </div>
            </h6>
            <span v-else>{{ customer.first_name }} {{ customer.last_name }}</span>
          </div>
          <div class="text-muted">
            <span v-if="customer.email != ''">
              {{ customer.email }}
              <br />
            </span>
            <span v-if="customer.phone">
              {{ customer.phone }}
              <br />
            </span>
            <span v-if="customer.total_spent">
              {{ $t('ticket_sidebar.total_spent') }} {{ customer.total_spent }}
              <br />
            </span>
          </div>
        </template>

        <div v-if="returns && returns.length > 0" class="mt-6">
          <h6 class="b-b mb-4 pb-2">{{ $t('ticket_sidebar.latest_returns') }}</h6>
          <div v-for="returnModel in returns" class="b-b mb-4 pb-2">
            <div>
              <span class="text-info">
                <a class="text-info" target="_blank" :href="getReturnUrl(returnModel.id)">
                  {{ $t('ticket_sidebar.return') }} {{ returnModel.number }}
                </a>
              </span>
              <label class="label label-default mx-2 mb-0" :class="getPaymentClass(returnModel.status)">
                {{ returnModel.status }}
              </label>
            </div>
            <div class="text-muted my-2">{{ returnModel.created_at }}</div>
            <div v-for="item in returnModel.items">
              <span class="mb-4">
                {{ item.quantity }}x {{ item.name }} (
                <template v-if="item.currency">{{ item.currency }}</template>
                {{ item.price }} each)
              </span>
            </div>
          </div>
        </div>

        <div v-if="orders && orders.length > 0" class="mt-6">
          <h6 class="b-b mb-4 pb-2">{{ $t('ticket_sidebar.latest_orders') }}</h6>
          <div v-for="order in orders" class="b-b mb-4 pb-2">
            <div>
              <span class="text-info">
                <a class="text-info" target="_blank" :href="getOrderUrl(order.id)">Order {{ order.number }}</a>
              </span>

              <label class="label label-default mx-2 mb-0" :class="getPaymentClass(order.status)">
                {{ order.status }}
              </label>
              <!--<label class="label mb-0 label-default" :class="getFullfillmentClass(order.fulfillment_status)">{{ order.fulfillment_status || 'Unfulfilled' }}</label>-->
            </div>
            <div class="text-muted my-2">{{ formatDate(order.created_at) }}</div>
            <div v-for="item in order.items">
              <span class="mb-4">
                {{ item.quantity }}x {{ item.name }} (
                <template v-if="item.currency">{{ item.currency }}</template>
                {{ item.price }} each)
              </span>
            </div>
            <div class="mt-4">
              <div>
                <strong class="mb-2">{{ $t('ticket_sidebar.shipping') }}</strong>
              </div>
              {{ order.shipping.first_name }} {{ order.shipping.last_name }}
              <br />
              <template v-if="order.shipping.telephone">
                {{ order.shipping.telephone }}
                <br />
              </template>
              {{ order.shipping.address_1 }} {{ order.shipping.address_2 }}
              <br />
              {{ order.shipping.postcode }} {{ order.shipping.city }}
              <br />
              {{ order.shipping.country }}
            </div>
            <div v-if="order.shipping_info" class="mt-4">
              <div>
                <strong class="mb-2">{{ $t('ticket_sidebar.shipping_type') }}</strong>
              </div>
              {{ order.shipping_info.title }} (EUR {{ order.shipping_info.price }})
            </div>
            <div class="mt-4">
              <div><strong class="mb-2">Billing</strong></div>
              {{ order.billing.first_name }} {{ order.billing.last_name }}
              <br />
              <template v-if="order.billing.telephone">
                {{ order.billing.telephone }}
                <br />
              </template>
              {{ order.billing.address_1 }} {{ order.billing.address_2 }}
              <br />
              {{ order.billing.postcode }} {{ order.billing.city }}
              <br />
              {{ order.billing.country }}
            </div>
            <div v-if="order.payment_method_title || order.payment_status" class="mt-4">
              <div>
                <strong class="mb-2">{{ $t('ticket_sidebar.payment_method') }}</strong>
              </div>
              {{ order.payment_method_title }}
              <span v-if="order.payment_status">
                <label class="label label-default">{{ order.payment_status }}</label>
              </span>
            </div>

            <div v-if="order.total" class="mt-4">
              <div>
                <strong class="mb-2">{{ $t('ticket_sidebar.total_amount') }}</strong>
              </div>
              {{ order.currency }} {{ order.total }}
            </div>
          </div>
        </div>
        <div v-else>
          <div class="text-muted text-center">
            <i>{{ $t('ticket_sidebar.no_orders_found') }}</i>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="text-muted text-center">
          <i>{{ $t('ticket_sidebar.no_results_found_for_this_contact') }}</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import eventBus from '@/eventBus';

import Loader from '../Loader';

export default {
  name: 'ECommerceGeneric',
  props: {
    ticket: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    plugin: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    settings: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },

  data() {
    return {
      index: 0,
      customers: [],
      customer: {},
      orders: [],
      returns: [],
      found: false,
      loading: false,
      error: null,
    };
  },

  watch: {
    ticket() {
      this.loadData();
    },
  },

  components: {
    Loader,
  },

  mounted() {
    this.loadData();

    eventBus.$off('integrations.error.' + this.plugin.id);
    eventBus.$on('integrations.error.' + this.plugin.id, (message) => {
      this.error = message;
    });
  },

  methods: {
    getPaymentClass(status) {
      switch (status.toLowerCase()) {
        case 'received':
        case 'processing':
          return 'info';
        case 'paid':
        case 'complete':
        case 'completed':
          return 'success';
        case 'pending':
        case 'concept':
        case 'waiting for bank transfer':
          return 'warning';
        case 'in repair':
          return 'danger';
      }
    },

    getFullfillmentClass(status) {
      switch (status.toLowerCase()) {
        case null:
          return 'warning';
        case 'fulfilled':
          return 'success';
      }
    },

    formatDate(date) {
      return moment.tz(date, window.APP_TIMEZONE).tz(this.$root.user.timezone).format('YYYY-MM-DD HH:mm:ss');
    },

    getCustomerUrl(id) {
      return this.plugin.meta.url + '/admin/customers/' + id;
    },

    getOrderUrl(id) {
      return this.plugin.meta.url + '/wp-admin/post.php?post=' + id + '&action=edit';
    },

    getReturnUrl(id) {
      return this.plugin.meta.url + '/admin/return/' + id;
    },
  },
};
</script>
