<template>
  <div class="row condition flex flex-col md:flex-row">
    <div class="col-md-3 mb-1 md:mb-0">
      <div class="row">
        <div class="col-xs-2 pt-2 text-center" style="padding-right: 0">
          <i class="material-icons">{{ icon || 'perm_identity' }}</i>
        </div>

        <div v-if="Object.keys(allowedTypes).length === 1" class="col-xs-10 pt-2">
          <span v-for="type in allowedTypes" class="pt-2">{{ type.niceName }}</span>
        </div>
        <div v-else class="col-xs-10">
          <select v-model="condition.type" class="form-control">
            <option v-for="type in allowedTypes" :value="type.type">
              {{ type.niceName }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div v-if="allowedTypes[condition.type].operators.length" class="col-md-2 mb-1 md:mb-0">
      <select v-model="condition.operator" class="form-control">
        <option v-for="operator in allowedTypes[condition.type].operators" :value="operator.value">
          {{ operator.name }}
        </option>
      </select>
    </div>

    <div class="col-md-2 mb-1 md:mb-0">
      <textarea
        v-if="allowedTypes[condition.type].valueType === 'textarea'"
        v-model="condition.value"
        class="form-control"
      ></textarea>

      <select
        v-else-if="allowedTypes[condition.type].valueType === 'select'"
        v-model="condition.value"
        class="form-control"
      >
        <option v-for="option in allowedTypes[condition.type].options" :value="option.value">
          {{ option.name }}
        </option>
      </select>

      <datetimepicker
        v-else-if="allowedTypes[condition.type].valueType === 'datetime'"
        class="form-control"
        :model-value="datetime"
        @update:modelValue="datetimeInput"
      ></datetimepicker>

      <input
        v-else
        v-model="condition.value"
        class="form-control"
        :type="allowedTypes[condition.type].valueType"
        :placeholder="allowedTypes[condition.type].placeholder || allowedTypes[condition.type].append"
      />
    </div>

    <div class="col-md-3 text-muted mb-1 pt-2 md:mb-0">{{ computedAppend }}</div>

    <div v-if="hasButtons" class="col-md-2 mb-1 ml-auto text-right md:mb-0">
      <button class="btn btn-outline btn-outline-secondary" type="button" @click.prevent="$emit('remove')">-</button>
      <button class="btn btn-outline btn-outline-secondary" type="button" @click.prevent="$emit('add')">+</button>
    </div>
  </div>
</template>

<script>
import Datetimepicker from '../../Elements/DateTimePicker';

export default {
  name: 'Condition',
  emits: ['remove', 'add'],
  props: {
    condition: {
      type: Object,
      default: () => ({}),
    },
    icon: {
      type: String,
      default: '',
    },
    hasButtons: {
      type: Boolean,
      default: false,
    },
    allowedTypes: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    datetimeInput(val) {
      this.condition.value = moment(val).valueOf();
    },
  },

  computed: {
    computedAppend() {
      return typeof this.allowedTypes[this.condition.type].append !== 'undefined'
        ? this.allowedTypes[this.condition.type].append
        : this.allowedTypes[this.condition.type].placeholder;
    },
    datetime() {
      return this.condition.value ? moment(this.condition.value).format('YYYY-MM-DD HH:mm') : null;
    },
  },

  components: {
    Datetimepicker,
  },
};
</script>

<style scoped></style>
