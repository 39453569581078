<template>
  <div>
    <div class="box">
      <div class="box-header">
        <h2>{{ $t('custom_channel.custom_channel') }}</h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <div class="alert alert-grey mb-4" v-html="markupSanitizer($t('custom_channel.check_our_documentation'))" />

        <div
          v-if="modelValue.username"
          v-html="markupSanitizer($t('custom_channel.your_unique_channel_identifier', { id: modelValue.username }))"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import markupSanitizer from '@/util/markupSanitizer';

export default {
  name: 'CustomChannel',
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    markupSanitizer: markupSanitizer,
  },
};
</script>
