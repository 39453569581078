type FiltersObject = {
  [key: string]: number | string | (number | string)[];
};

export function mapFilters(filtersObject: FiltersObject) {
  let serializedFilters = '';

  for (const key in filtersObject) {
    const currentFilter = filtersObject[key];
    let filterString;
    if (typeof currentFilter === 'object') {
      filterString = currentFilter.map((filter: number | string) => `${key}[]=${filter}`).join('&');
    } else {
      filterString = `${key}=${currentFilter}`;
    }
    serializedFilters = `${serializedFilters ? serializedFilters + '&' : ''}${filterString}`;
  }

  return serializedFilters;
}

export function removeDuplicatesAndInterval(filtersObject: FiltersObject) {
  const removeDuplicates = { ...filtersObject };
  delete removeDuplicates.interval;
  return removeDuplicates;
}
