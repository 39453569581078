<template>
  <div class="py-4">
    <div class="box">
      <div class="box-body">
        <form @submit.prevent="send">
          <div class="form-group">
            <strong class="text-muted mb-1">Channel:</strong>
            <div class="mt-2">
              <multiselect
                v-model="form.channel"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                :multiple="false"
                :options="targets"
                group-values="options"
                group-label="type"
                :group-select="true"
                placeholder="Select a channel"
                track-by="id"
                label="name"
              >
                <template #noResult>
                  <span>Oops! No elements found. Consider changing the search query.</span>
                </template>
              </multiselect>
            </div>
          </div>
          <div class="form-group">
            <strong class="text-muted mb-1">Message:</strong>
            <div class="mt-2">
              <textarea v-model="form.message" class="form-control" rows="5"></textarea>
            </div>
          </div>
          <div class="form-group">
            <div>
              <button v-show="!form.channel.id" disabled class="btn btn-default btn-block">Send</button>
              <button
                v-if="form.channel.id"
                type="submit"
                class="btn btn-block success text-ellipsis text-white"
                :disabled="sending"
                :class="{ loader: sending }"
                v-text="sending ? 'Sending..' : 'Send to ' + form.channel.name"
              ></button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

import Request from './Mixins/Request';

export default {
  components: {
    Multiselect,
  },

  mixins: [Request],

  props: ['plugin', 'ticket'],

  data() {
    return {
      targets: [],
      message: [],
      form: {
        message: '',
        channel: {},
        ticket_id: this.ticket.id,
      },
      sending: false,
    };
  },

  mounted() {
    this.fetchChannels();
  },

  methods: {
    fetchChannels() {
      this.get(this.plugin, '/api/v2/integrations/slack/channels').then((res) => {
        this.targets = [
          {
            type: 'Channels',
            options: res.data.channels.map((channel) => {
              return {
                type: 'channel',
                name: '#' + channel.name,
                id: channel.id,
              };
            }),
          },
          {
            type: 'Members',
            options: res.data.users.map((user) => {
              return {
                type: 'member',
                name: user.real_name,
                id: user.id,
              };
            }),
          },
        ];
      });
    },

    send() {
      if (this.sending) return;
      this.sending = true;

      this.post(this.plugin, '/api/v2/integrations/slack/messages', this.form)
        .then((res) => {
          this.sending = false;
          this.form.message = '';
          this.form.channel = {};
          this.flashSuccess('Your message has been sent successfully.');
        })
        .catch((e) => {
          this.sending = false;
        });
    },
  },
};
</script>
