import { vm } from '@/ApplicationHandler';
import {
  deleteCustomField as apiDeleteCustomField,
  getCustomFields as apiGetCustomFields,
} from '@/components/CustomFields/Api';
import { CUSTOM_FIELD } from '@/Configs/Constants';

/**
 *
 * @param {array} collection
 * @param {string} type
 * @returns {array}
 */
const filterAndSort = (collection, type) => {
  return collection
    ?.filter((field) => field?.type === type)
    .sort((itemA, itemB) => itemB.sort_order - itemA.sort_order);
};

export default {
  namespaced: true,
  state: {
    customFields: [],
    customUserFields: [],
    customProfileFields: [],
    customContactFields: [],
    customTicketFields: [],
    ticketCustomFields: [],
    ticketFields: {
      profile: [],
      user: [],
      contact: [],
      ticket: [],
    },
    loading: false,
    loaded: false,
    ticketHasProfile: false,
    ticketHasError: false,
  },
  mutations: {
    setCustomFields(state, payload) {
      if (!Array.isArray(payload)) return console.error(`Payload of setCustomFields should be an Array`);

      state.customFields = payload;

      state.customUserFields = filterAndSort(payload, CUSTOM_FIELD.USER);
      state.customProfileFields = filterAndSort(payload, CUSTOM_FIELD.PROFILE);
      state.customTicketFields = filterAndSort(payload, CUSTOM_FIELD.TICKET);
      state.customContactFields = filterAndSort(payload, CUSTOM_FIELD.CONTACT);
    },
    setCustomUserFields(state, payload) {
      state.customUsersFields = payload;
    },
    setCustomProfileFields(state, payload) {
      state.customProfileFields = payload;
    },
    setCustomTicketFields(state, payload) {
      state.customTicketFields = payload;
    },
    setCustomContactFields(state, payload) {
      state.customContactsFields = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setLoaded(state, payload) {
      state.loaded = payload;
    },
    setTicketCustomFields(state, payload) {
      state.ticketCustomFields = payload;
    },
    setTicketProfileFields(state, payload) {
      state.ticketFields.profile = payload;
    },
    setTicketUserFields(state, payload) {
      state.ticketFields.user = payload;
    },
    setTicketContactFields(state, payload) {
      state.ticketFields.contact = payload;
    },
    setTicketTicketFields(state, payload) {
      state.ticketFields.ticket = payload;
    },
    setTicketHasProfile(state, payload) {
      state.ticketHasProfile = payload;
    },
    setTicketHasError(state, payload) {
      state.ticketHasError = payload;
    },
  },
  actions: {
    async loadCustomFields({ commit }) {
      try {
        commit('setLoading', true);
        const { data: resData } = await apiGetCustomFields();
        commit('setCustomFields', resData.data);
        commit('setLoading', false);
        commit('setLoaded', true);
        return resData;
      } catch (e) {
        console.error(e);
        commit('setLoading', false);
        commit('setLoaded', true);
      } finally {
        console.log('Tickets loaded!');
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async deleteCustomField({}, { id, i18n }) {
      try {
        if (
          await vm.$tConfirm(i18n?.message, {
            delete: true,
            title: i18n?.confirm,
          })
        ) {
          await apiDeleteCustomField(id);
          vm.flashSuccess(i18n?.success);
          return id;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  getters: {
    hasEmptyRequiredFields: (state, getters) => {
      let hasEmptyRequiredFields = false;

      Object.keys(getters.requiredEmptyFields).forEach((key) => {
        hasEmptyRequiredFields = !!getters.requiredEmptyFields[key]?.length;
      });

      return hasEmptyRequiredFields;
    },
    requiredEmptyFields: (state) => {
      const requiredFields = {};

      Object.keys(state.ticketFields).forEach((key) => {
        if (!state.ticketHasProfile && key === 'profile') return;

        const filteredFields = state.ticketFields[key].filter((field) => {
          return field.is_required && (!field.value || !!field.errors?.length);
        });
        if (filteredFields.length) requiredFields[key] = filteredFields;
      });

      return requiredFields;
    },
  },
};
