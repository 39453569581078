<template>
  <div>
    <transition name="fade">
      <modal v-if="modal" :modal="modal"></modal>
    </transition>
  </div>
</template>

<script>
import eventBus from '@/eventBus';

import Modal from './Modal';

export default {
  name: 'ModalContainer',
  components: { Modal },
  data() {
    return {
      modal: null,
    };
  },
  mounted() {
    eventBus.$on('modal', (modal) => (this.modal = modal));
  },

  computed: {},

  methods: {},
};
</script>

<style scoped lang="scss">
body {
  overflow-y: auto;
}

.trengo-modal {
  left: 0;
  top: 0;
  position: absolute;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  min-height: 100vh;

  .box {
    box-shadow: rgba(0, 0, 0, 0.11) 0 0 50px 0;
    max-width: 1337px;
    border-radius: 10px;
    border: none;

    h2 {
      font-size: 300%;
      font-weight: 600;
    }
    h3 {
      font-size: 200%;
      font-weight: 600;
    }
  }
}
</style>
