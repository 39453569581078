import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "t-text-paragraph-sm m-0 max-w-[320px] text-grey-600" }
const _hoisted_2 = { class: "flex flex-row items-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "t-text-desktop-paragraph-sm m-0 ml-2 w-full truncate text-grey-800" }
const _hoisted_5 = { class: "t-text-sm m-0 text-right text-grey-600" }
const _hoisted_6 = { class: "t-text-sm m-0 text-right text-grey-600" }
const _hoisted_7 = { class: "t-text-sm m-0 text-right text-grey-600" }
const _hoisted_8 = { class: "t-text-sm m-0 text-right text-grey-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_avatar = _resolveComponent("t-avatar")!
  const _component_t_table_list_cell = _resolveComponent("t-table-list-cell")!
  const _component_ticket_insight_time_cell = _resolveComponent("ticket-insight-time-cell")!
  const _component_t_table_list_row = _resolveComponent("t-table-list-row")!

  return (_openBlock(), _createBlock(_component_t_table_list_row, { "data-test": "ticket-inisight-table-row" }, {
    default: _withCtx(() => [
      _createVNode(_component_t_table_list_cell, { class: "max-w-[320px] break-words pr-4" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.availableData.agent.profile_image)
                ? (_openBlock(), _createBlock(_component_t_avatar, {
                    key: 0,
                    image: _ctx.availableData.agent.profile_image,
                    width: "32"
                  }, null, 8 /* PROPS */, ["image"]))
                : (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    src: _ctx.getUnassignedImage,
                    width: "32px"
                  }, null, 8 /* PROPS */, _hoisted_3)),
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.availableData.agent.full_name || _ctx.$t('reports_v2.unassigned')), 1 /* TEXT */)
            ])
          ])
        ]),
        _: 1 /* STABLE */
      }),
      (_ctx.withinBusinessHours)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 0,
            class: "max-w-[320px] break-words pr-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ticket_insight_time_cell, {
                time: _ctx.availableData?.average_first_resolution
              }, null, 8 /* PROPS */, ["time"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (!_ctx.withinBusinessHours)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 1,
            class: "max-w-[320px] break-words pr-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ticket_insight_time_cell, {
                time: _ctx.availableData?.average_first_resolution_without_business_hours
              }, null, 8 /* PROPS */, ["time"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.withinBusinessHours)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 2,
            class: "max-w-[320px] pr-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ticket_insight_time_cell, {
                time: _ctx.availableData?.average_total_resolution
              }, null, 8 /* PROPS */, ["time"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (!_ctx.withinBusinessHours)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 3,
            class: "max-w-[320px] pr-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ticket_insight_time_cell, {
                time: _ctx.availableData?.average_total_resolution_without_business_hours
              }, null, 8 /* PROPS */, ["time"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.withinBusinessHours)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 4,
            class: "max-w-[320px] pr-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ticket_insight_time_cell, {
                time: _ctx.availableData?.average_first_response
              }, null, 8 /* PROPS */, ["time"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (!_ctx.withinBusinessHours)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 5,
            class: "max-w-[320px] pr-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ticket_insight_time_cell, {
                time: _ctx.availableData?.average_first_response_without_business_hours
              }, null, 8 /* PROPS */, ["time"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_t_table_list_cell, { class: "max-w-[320px] pr-4" }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.availableData.total_assigned || 0), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_t_table_list_cell, { class: "max-w-[320px] break-words pl-4" }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.availableData.total_outbound || 0), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_t_table_list_cell, { class: "max-w-[320px] break-words pl-4" }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.availableData.total_closed || 0), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_t_table_list_cell, { class: "max-w-[320px] break-words pl-4" }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.availableData.total_note || 0), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}