import { vm } from '@/ApplicationHandler';

import DefaultAutomation from './Templates/DefaultAutomation';
import GreetingTemplate from './Templates/Greeting';
import OpenHelpCenterTemplate from './Templates/OpenHelpCenter';
import SetFlowbotTemplate from './Templates/SetFlowbot';

let Automation = {
  DefaultAutomation(template = null) {
    switch (template) {
      case 'setFlowbot':
        return new SetFlowbotTemplate();
      case 'greeting':
        return new GreetingTemplate();
      case 'openHelpCenter':
        return new OpenHelpCenterTemplate();
      default:
        return new DefaultAutomation();
    }
  },

  Condition(condition_type, setValue = true) {
    let type, operator, value, subconditions;
    type = condition_type.type;
    if (condition_type.subconditionType) {
      subconditions = []; // setValue ? [new Automation.Condition(Automation.globalConditionTypes[type.subconditionType])] : []
    } else {
      operator = condition_type.operators.length ? condition_type.operators[0].value : null;
      value = setValue ? condition_type.valueDefault || null : null;
    }

    return { type, operator, value, subconditions };
  },

  Action(action_type) {
    let type, payload;
    type = action_type.type;
    payload = action_type.defaultPayload ? Object.assign({}, action_type.defaultPayload) : null;
    return {
      type,
      payload,
    };
  },

  /* CONDITIONS */

  conditionStrings() {
    return {
      operators: {
        '=': vm.$t('widget_automation.condition_string_is'),
        '!=': vm.$t('widget_automation.condition_string_is_not'),
        '>': vm.$t('widget_automation.condition_string_is_greater_than'),
        '<': vm.$t('widget_automation.condition_string_is_less_than'),
        is_after: vm.$t('widget_automation.condition_string_is_after'),
        is_before: vm.$t('widget_automation.condition_string_is_before'),
        contains: vm.$t('widget_automation.condition_string_contains'),
        starts_with: vm.$t('widget_automation.condition_string_starts_with'),
        ends_with: vm.$t('widget_automation.condition_string_ends_with'),
      },
    };
  },

  audienceConditionTypes() {
    return {
      last_seen: {
        type: 'last_seen',
        niceName: vm.$t('widget_automation.audience_condition_last_seen'),
        operators: [
          { name: vm.$t('widget_automation.audience_condition_less_than'), value: '<' },
          { name: vm.$t('widget_automation.audience_condition_more_than'), value: '>' },
        ],
        append: vm.$t('widget_automation.audience_condition_days_ago'),
        valueType: 'number',
        valueDefault: 1,
      },
      last_contact: {
        type: 'last_contact',
        niceName: vm.$t('widget_automation.audience_condition_last_contact'),
        operators: [
          { name: vm.$t('widget_automation.audience_condition_less_than'), value: '<' },
          { name: vm.$t('widget_automation.audience_condition_more_than'), value: '>' },
        ],
        append: vm.$t('widget_automation.audience_condition_days_ago'),
        valueType: 'number',
        valueDefault: 1,
      },
      total_visits: {
        type: 'total_visits',
        niceName: vm.$t('widget_automation.audience_condition_total_visits'),
        operators: [
          { name: vm.$t('widget_automation.audience_condition_equals'), value: '=' },
          { name: vm.$t('widget_automation.audience_condition_less_than'), value: '<' },
          { name: vm.$t('widget_automation.audience_condition_more_than'), value: '>' },
        ],
        append: vm.$t('widget_automation.audience_condition_visits'),
        valueType: 'number',
        valueDefault: 10,
      },
      total_page_views: {
        type: 'total_page_views',
        niceName: vm.$t('widget_automation.audience_condition_total_page_views'),
        operators: [
          { name: vm.$t('widget_automation.audience_condition_equals'), value: '=' },
          { name: vm.$t('widget_automation.audience_condition_less_than'), value: '<' },
          { name: vm.$t('widget_automation.audience_condition_more_than'), value: '>' },
        ],
        append: vm.$t('widget_automation.audience_condition_views'),
        valueType: 'number',
        valueDefault: 10,
      },
      utm_source: {
        type: 'utm_source',
        niceName: vm.$t('widget_automation.audience_condition_utm_source'),
        operators: [
          { name: vm.$t('widget_automation.audience_condition_equals'), value: '=' },
          { name: vm.$t('widget_automation.audience_condition_starts_with'), value: 'starts_with' },
          {
            name: vm.$t('widget_automation.audience_condition_ends_with'),
            value: 'ends_with',
          },
          { name: vm.$t('widget_automation.audience_condition_contains'), value: 'contains' },
        ],
        valueType: 'text',
        valueDefault: '',
      },
      utm_campaign: {
        type: 'utm_campaign',
        niceName: vm.$t('widget_automation.audience_condition_utm_campaign'),
        operators: [
          { name: vm.$t('widget_automation.audience_condition_equals'), value: '=' },
          { name: vm.$t('widget_automation.audience_condition_starts_with'), value: 'starts_with' },
          {
            name: vm.$t('widget_automation.audience_condition_ends_with'),
            value: 'ends_with',
          },
          { name: vm.$t('widget_automation.audience_condition_contains'), value: 'contains' },
        ],
        valueType: 'text',
        valueDefault: '',
      },
      utm_medium: {
        type: 'utm_medium',
        niceName: vm.$t('widget_automation.audience_condition_utm_medium'),
        operators: [
          { name: vm.$t('widget_automation.audience_condition_equals'), value: '=' },
          { name: vm.$t('widget_automation.audience_condition_starts_with'), value: 'starts_with' },
          {
            name: vm.$t('widget_automation.audience_condition_ends_with'),
            value: 'ends_with',
          },
          { name: vm.$t('widget_automation.audience_condition_contains'), value: 'contains' },
        ],
        valueType: 'text',
        valueDefault: '',
      },
      utm_term: {
        type: 'utm_term',
        niceName: vm.$t('widget_automation.audience_condition_utm_term'),
        operators: [
          { name: vm.$t('widget_automation.audience_condition_equals'), value: '=' },
          { name: vm.$t('widget_automation.audience_condition_starts_with'), value: 'starts_with' },
          {
            name: vm.$t('widget_automation.audience_condition_ends_with'),
            value: 'ends_with',
          },
          { name: vm.$t('widget_automation.audience_condition_contains'), value: 'contains' },
        ],
        valueType: 'text',
        valueDefault: '',
      },
      utm_content: {
        type: 'utm_content',
        niceName: vm.$t('widget_automation.audience_condition_utm_content'),
        operators: [
          { name: vm.$t('widget_automation.audience_condition_equals'), value: '=' },
          { name: vm.$t('widget_automation.audience_condition_starts_with'), value: 'starts_with' },
          {
            name: vm.$t('widget_automation.audience_condition_ends_with'),
            value: 'ends_with',
          },
          { name: vm.$t('widget_automation.audience_condition_contains'), value: 'contains' },
        ],
        valueType: 'text',
        valueDefault: '',
      },
      referrer_url: {
        type: 'referrer_url',
        niceName: vm.$t('widget_automation.audience_condition_referrer_url'),
        operators: [
          { name: vm.$t('widget_automation.audience_condition_equals'), value: '=' },
          { name: vm.$t('widget_automation.audience_condition_starts_with'), value: 'starts_with' },
          {
            name: vm.$t('widget_automation.audience_condition_ends_with'),
            value: 'ends_with',
          },
          { name: vm.$t('widget_automation.audience_condition_contains'), value: 'contains' },
        ],
        valueType: 'text',
        valueDefault: '',
      },
      mobile_device: {
        type: 'mobile_device',
        niceName: vm.$t('widget_automation.audience_condition_mobile_device'),
        operators: [],
        valueType: 'select',
        valueDefault: true,
        options: [
          { name: vm.$t('widget_automation.audience_condition_true'), value: true },
          { name: vm.$t('widget_automation.audience_condition_false'), value: false },
        ],
      },
      browser_lang: {
        type: 'browser_lang',
        niceName: vm.$t('widget_automation.audience_condition_browser_lang'),
        operators: [],
        valueType: 'select',
        valueDefault: 'en',
        options: [
          { name: vm.$t('widget_automation.audience_condition_browser_lang_english'), value: 'en' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_dutch'), value: 'nl' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_spanish'), value: 'es' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_portuguese'), value: 'pt' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_french'), value: 'fr' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_german'), value: 'de' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_greek'), value: 'el' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_italian'), value: 'it' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_finnish'), value: 'fi' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_polish'), value: 'pl' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_hungarian'), value: 'hu' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_czech'), value: 'cs' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_arabic'), value: 'ar' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_russian'), value: 'ru' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_chinese'), value: 'zh' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_turkish'), value: 'tr' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_hebrew'), value: 'he' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_japanese'), value: 'ja' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_malay'), value: 'ms' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_indonesian'), value: 'id' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_vietnamese'), value: 'vi' },
          { name: vm.$t('widget_automation.audience_condition_browser_lang_thai'), value: 'th' },
        ],
      },
      returning_visitor: {
        type: 'returning_visitor',
        niceName: vm.$t('widget_automation.audience_condition_returning_visitor'),
        operators: [],
        valueType: 'select',
        valueDefault: true,
        options: [
          { name: vm.$t('widget_automation.audience_condition_true'), value: true },
          { name: vm.$t('widget_automation.audience_condition_false'), value: false },
        ],
      },
    };
  },

  globalConditionTypes() {
    return {
      seconds_on_page: {
        type: 'seconds_on_page',
        niceName: vm.$t('widget_automation.global_condition_seconds_on_page'),
        operators: [],
        append: 'seconds',
        valueType: 'number',
        valueDefault: 5,
      },
      page_url: {
        type: 'page_url',
        niceName: vm.$t('widget_automation.global_condition_page_url'),
        operators: [
          { name: vm.$t('widget_automation.audience_condition_equals'), value: '=' },
          { name: vm.$t('widget_automation.audience_condition_starts_with'), value: 'starts_with' },
          { name: vm.$t('widget_automation.audience_condition_ends_with'), value: 'ends_with' },
        ],
        valueType: 'text',
        valueDefault: '',
        placeholder: vm.$t('widget_automation.global_condition_page_url_placeholder'),
        append: vm.$t('widget_automation.global_condition_page_url_example'),
      },
      start_date: {
        type: 'start_date',
        niceName: vm.$t('widget_automation.global_condition_set_start_date'),
        operators: [],
        valueType: 'datetime',
        valueDefault: '',
      },
      end_date: {
        type: 'end_date',
        niceName: vm.$t('widget_automation.global_condition_set_end_date'),
        operators: [],
        valueType: 'datetime',
        valueDefault: '',
      },
      page_url_subconditions: {
        type: 'page_url_subconditions',
        subconditionType: 'page_url',
      },
    };
  },

  globalConditionEnablers() {
    return {
      deliveryMoment: {
        niceName: vm.$t('widget_automation.page_greeting_delivery_method'),
        options: [
          { value: null, text: vm.$t('widget_automation.page_greeting_delivery_method_immediately') },
          { value: 'seconds_on_page', text: vm.$t('widget_automation.page_greeting_delivery_method_after_seconds') },
        ],
        value: null,
        conditionType: 'seconds_on_page',
        templateBlacklist: [
          'setFlowbot', // hide for setFlowbot automations
        ],
      },
      pageSelection: {
        niceName: vm.$t('widget_automation.page_greeting_page_selection'),
        options: [
          { value: null, text: vm.$t('widget_automation.page_greeting_page_selection_all') },
          { value: 'page_url_subconditions', text: vm.$t('widget_automation.page_greeting_page_selection_select') },
        ],
        value: null,
        conditionType: 'page_url_subconditions',
      },
      startDate: {
        niceName: vm.$t('widget_automation.page_greeting_start_date'),
        options: [
          { value: null, text: vm.$t('widget_automation.page_greeting_start_date_now') },
          { value: 'start_date', text: vm.$t('widget_automation.page_greeting_start_date_set') },
        ],
        value: null,
        conditionType: 'start_date',
      },
      endDate: {
        niceName: vm.$t('widget_automation.page_greeting_end_date'),
        options: [
          { value: null, text: vm.$t('widget_automation.page_greeting_end_date_never') },
          { value: 'end_date', text: vm.$t('widget_automation.page_greeting_end_date_set') },
        ],
        value: null,
        conditionType: 'end_date',
      },
      audienceConditions: {
        niceName: vm.$t('widget_automation.page_greeting_choose_audience'),
        options: [
          { value: null, text: vm.$t('widget_automation.page_greeting_choose_audience_everyone') },
          { value: 'audience_conditions', text: vm.$t('widget_automation.page_greeting_choose_audience') },
        ],
        value: null,
        conditionType: 'audience_conditions',
      },
    };
  },

  /* ACTIONS */

  actionTypes: {
    greeting: {
      type: 'greeting',
      defaultPayload: {
        from: { name: '', avatars: [], fromType: 'team', id: null },
        message: [{ language: 'en', message: '', default: true }],
      },
    },
    setFlowbot: {
      type: 'setFlowbot',
      defaultPayload: { flowbotId: null, autoStart: false, introText: '', flowbotStepId: null },
    },
    openHelpCenter: {
      type: 'openHelpCenter',
      defaultPayload: { helpCenterName: null, articleName: null, articleId: null },
    },
  },
};

export default Automation;
