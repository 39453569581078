import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "LoginSessionLimitModal",
  class: "modal",
  tabindex: "-1",
  role: "dialog"
}
const _hoisted_2 = {
  class: "modal-dialog",
  role: "document"
}
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.$t('auth.login_session_limit_modal_title')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", {
          class: "modal-body",
          innerHTML: _ctx.$t('auth.login_session_limit_modal_body', { email: _ctx.$root.user.email })
        }, null, 8 /* PROPS */, _hoisted_6),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("a", {
            class: "btn dark-white",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.logout && $options.logout(...args)))
          }, _toDisplayString(_ctx.$t('auth.login_session_limit_modal_logout')), 1 /* TEXT */),
          _createElementVNode("button", {
            type: "button",
            class: "btn info",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.useHere && $options.useHere(...args)))
          }, _toDisplayString(_ctx.$t('auth.login_session_limit_modal_use_here')), 1 /* TEXT */)
        ])
      ])
    ])
  ]))
}