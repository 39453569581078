<script type="text/babel">
import { mapStores } from 'pinia';

import { FEATURE } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useEntitlementsStore } from '@/store/pinia';

import createHelpCenterModal from './CreateHelpCenterModal.vue';
import Collapse from '../Collapse';

export default {
  name: 'HelpCenterIndex',
  components: {
    createHelpCenterModal,
    Collapse,
  },

  data() {
    return {
      list: [],
      path: '/help_center',
    };
  },

  mounted() {
    this.loadList();
    eventBus.$on('help_center.list.reload', this.loadList);
  },

  computed: {
    ...mapStores(useEntitlementsStore),
    isEntitled() {
      return this.entitlementsStore?.isEntitledTo(FEATURE.CUSTOMER_SERVICE__HELPCENTER);
    },
  },

  methods: {
    showModal() {
      $('#CreateHelpCenterModal').modal();
    },

    loadList() {
      axios.get('/api/v2/help_center').then((res) => {
        this.list = res.data.data;
      });
    },
  },
};
</script>

<template>
  <div class="row-body">
    <portal to="sidebar">
      <div class="pl-4 pr-4 pt-2 lg:pr-0">
        <collapse collapse-key="hc" :default-open="true">
          <template #collapse-title>
            <div class="flex">
              <h6>{{ $t('help_center.help_center') }}</h6>
              <a
                v-if="isEntitled"
                data-dismiss="modal"
                class="ml-auto h-3 w-3"
                data-toggle="tooltip"
                data-placement="left"
                @click="showModal()"
              >
                <i class="material-icons md-18 text-grey-500">add</i>
              </a>
            </div>
          </template>
          <template #collapse-content>
            <div>
              <div v-for="item in list">
                <router-link
                  class="collapse-menu-item flex items-center rounded text-grey-700"
                  :to="'/help_center/' + item.id + '/categories'"
                  data-dismiss="modal"
                  :class="{ 'bg-grey-200 text-grey-900': $route.params.id == item.id }"
                >
                  <div style="width: 28px"><i class="far fa-folder md-18"></i></div>
                  {{ item.name }}
                  <i v-if="item.has_password" class="fa fa-lock ml-2 text-xs text-yellow"></i>
                  <label v-if="!item.published" class="label m-l-xs m-b-0">{{ $t('help_center.unpublished') }}</label>
                </router-link>
              </div>
            </div>
          </template>
        </collapse>
      </div>
    </portal>

    <div class="white bg b-b hidden-lg-up">
      <div class="navbar no-radius box-shadow-z1">
        <a
          v-show="$route.path !== '/help_center'"
          data-toggle="modal"
          data-target="#settings-primary"
          data-ui-modal=""
          class="navbar-item pull-left"
        >
          <span class="btn btn-sm btn-icon white">
            <i class="fa fa-list"></i>
          </span>
        </a>
        <ul class="nav navbar-nav">
          <li class="nav-item">
            <span class="navbar-item text-md">{{ $t('help_center.help_center') }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="row-col">
      <div class="row-col">
        <div class="row-col dark-light">
          <router-view :key="$route.params.id" @showModal="showModal"></router-view>
        </div>
      </div>
    </div>

    <create-help-center-modal></create-help-center-modal>
  </div>
</template>
