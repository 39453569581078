<script>
import ContactsProfilesSidebar from '../Contacts/ContactsProfilesSidebar';
import IconClass from '../IconClass';
export default {
  name: 'Profiles',
  components: {
    ContactsProfilesSidebar,
  },

  data() {
    return {
      searchTerm: '',
    };
  },

  mounted() {
    this.searchTerm = '';
  },

  methods: {
    getIconClass(type) {
      return IconClass[type];
    },
    updateSearchTerm(searchTerm) {
      this.searchTerm = searchTerm;
    },
  },
};
</script>
<template>
  <div class="h-full w-full flex-1 bg-white pl-4">
    <portal to="sidebar">
      <contacts-profiles-sidebar></contacts-profiles-sidebar>
    </portal>

    <div class="scroll-on-hover h-full w-full flex-1 pl-4">
      <router-view :search-term="searchTerm" @update-search-term="updateSearchTerm"></router-view>
    </div>
  </div>
</template>
