import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "t-text-sm-emphasize m-0 p-0" }
const _hoisted_2 = { class: "flex max-h-80 w-full flex-col overflow-y-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_segmented_control = _resolveComponent("t-segmented-control")!
  const _component_info_linear = _resolveComponent("info-linear")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!
  const _component_integration_crm_contacts = _resolveComponent("integration-crm-contacts")!
  const _component_integration_crm_opportunities = _resolveComponent("integration-crm-opportunities")!
  const _component_integration_crm_leads = _resolveComponent("integration-crm-leads")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.shouldShowSegmentedControl)
      ? (_openBlock(), _createBlock(_component_t_segmented_control, {
          key: 0,
          size: "sm",
          "active-index": _ctx.activeIndex,
          segments: _ctx.segments,
          onChange: _ctx.switchSegment
        }, null, 8 /* PROPS */, ["active-index", "segments", "onChange"]))
      : (_ctx.tabsHaveErrors)
        ? (_openBlock(), _createBlock(_component_t_inline_banner, {
            key: 1,
            type: "default"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_info_linear)
            ]),
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('integration_hub.company_required_for_opportunities', { name: _ctx.installation.name })), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createVNode(_component_integration_crm_contacts, {
        installation: _ctx.installation,
        ticket: _ctx.ticket,
        selected: _ctx.activeSegment === 'Contacts',
        onSetCompanyId: _ctx.setCompanyId,
        onSetError: _ctx.setTabError
      }, null, 8 /* PROPS */, ["installation", "ticket", "selected", "onSetCompanyId", "onSetError"]), [
        [_vShow, _ctx.activeSegment === 'Contacts']
      ]),
      _withDirectives(_createVNode(_component_integration_crm_opportunities, {
        selected: _ctx.activeSegment === 'Opportunities',
        installation: _ctx.installation,
        ticket: _ctx.ticket,
        "company-id": _ctx.companyId
      }, null, 8 /* PROPS */, ["selected", "installation", "ticket", "company-id"]), [
        [_vShow, _ctx.shouldShowOpportunities && _ctx.activeSegment === 'Opportunities']
      ]),
      _withDirectives(_createVNode(_component_integration_crm_leads, {
        selected: _ctx.activeSegment === 'Leads',
        installation: _ctx.installation,
        ticket: _ctx.ticket
      }, null, 8 /* PROPS */, ["selected", "installation", "ticket"]), [
        [_vShow, _ctx.shouldShowLeads && _ctx.activeSegment === 'Leads']
      ])
    ])
  ]))
}