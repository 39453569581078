<script type="text/babel">
import eventBus from '@/eventBus';
import { fetchWaTemplates } from '@/api/modules/waTemplates';
import CircleIndicator from '@/components/common/CircleIndicator/CircleIndicator.vue';
export default {
  name: 'WaTemplatesIndex',
  components: { CircleIndicator },
  data() {
    return {
      loaded: false,
      wa_templates: [],
    };
  },

  mounted() {
    this.loadList(false);
    eventBus.$on('trigger-loadlist', () => {
      this.loadList();
    });
  },

  methods: {
    async loadList() {
      try {
        const { data } = await fetchWaTemplates();
        this.wa_templates = data?.data || [];
        this.loaded = true;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<template>
  <div class="row-body">
    <admin-section-title title="Whatsapp Templates"></admin-section-title>
    <div class="row-col">
      <div class="row-col">
        <div id="settings-primary" class="col-xs modal fade aside aside-md b-r w-medium w-200px">
          <div class="nav-settings light left b-primary row-col">
            <ul>
              <li class="text-md white header">
                <strong>{{ $t('whatsapp.whatsapp_templates') }}</strong>
                <a data-dismiss="modal" class="hidden-md-up text-muted m-l-auto m-r-sm m-l-1 text-lg">×</a>
                <a class="m-l-auto btn btn-icon btn-sm success rounded text-white">
                  <i class="material-icons md-18" @click="$router.push('/admin/wa_templates/create')">add</i>
                </a>
              </li>
            </ul>
            <div class="row-row">
              <div class="row-body scrollable hove">
                <div class="row-inner">
                  <ul>
                    <li v-for="item in wa_templates" :key="item.id" class="flex flex-grow items-center space-x-1.5">
                      <circle-indicator
                        :indicator="item.status"
                        :color-classes="{
                          ACCEPTED: 'bg-success-500',
                          DECLINED: 'bg-error-500',
                          PENDING: 'bg-grey-500',
                        }"
                      />
                      <router-link
                        class="nav-settings-link text-truncate"
                        :to="'/admin/wa_templates/' + item.id + ''"
                        :class="{ active: $route.params.id == item.id }"
                      >
                        {{ item.title }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-col">
          <router-view v-if="loaded" :key="$route.params.id"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
