<template>
  <sidebar-dropdown
    :title="$tc('ticket_sidebar.chat_tech')"
    collapse-key="visitorDetailsDropdown"
    data-test="visitor-details-dropdown-wrapper"
  >
    <div class="flex flex-col">
      <h4 class="header-text">{{ $t('ticket_sidebar.chatter_status') }}</h4>
      <div class="flex flex-row items-center" data-test="chatter-status-wrapper">
        <span
          class="mr-2 mt-0.5 h-2 w-2 select-none rounded-full"
          :class="online ? 'bg-green-dark' : 'bg-red-dark-matte'"
          data-test="chatter-status-icon"
        />
        <p v-if="online" class="m-0 p-0 capitalize" data-test="chatter-status-text">
          {{ $t('ticket_sidebar.online') }}
        </p>
        <p v-else class="m-0 p-0 capitalize" data-test="chatter-status-text">{{ $t('ticket_sidebar.offline') }}</p>
      </div>

      <div v-if="hasChatInfo && !isAnonymousModuleEnabled" class="mt-3">
        <h4 v-if="chatInfo.country" class="header-text" data-test="chatter-country">
          {{ $tc('ticket_sidebar.chat_tech_country') }}
        </h4>
        <div v-if="chatInfo.country" class="flex flex-row">
          <img class="mr-2" :src="`https://flagsapi.com/${chatInfo.country}/flat/64.png`" width="24" alt="" />
          {{ countries[chatInfo.country] }}
        </div>
      </div>

      <h4 v-if="chatInfo.browser" class="header-text">{{ $tc('ticket_sidebar.chat_tech_browser') }}</h4>
      <p v-if="chatInfo.browser" class="m-0 p-0" data-test="chatter-browser">{{ chatInfo.browser }}</p>

      <h4 v-if="chatInfo.os" class="header-text">{{ $tc('ticket_sidebar.chat_tech_os') }}</h4>
      <p v-if="chatInfo.os" class="m-0 p-0" data-test="chatter-os">{{ chatInfo.os }}</p>

      <h4 v-if="chatInfo.resolution" class="header-text">{{ $tc('ticket_sidebar.chat_tech_screen_resolution') }}</h4>
      <p v-if="chatInfo.resolution" class="m-0 p-0" data-test="chatter-resolution">{{ chatInfo.resolution }}</p>

      <h4 v-if="chatInfo.remote_address" class="header-text">{{ $tc('ticket_sidebar.ip_address') }}</h4>
      <p v-if="chatInfo.remote_address" class="m-0 p-0" data-test="chatter-remote-address">
        {{ chatInfo.remote_address }}
      </p>

      <h4 v-if="chatInfo.domain" class="header-text">{{ $tc('ticket_sidebar.website') }}</h4>
      <a
        v-if="chatInfo.domain"
        :href="sanitizeUrl(chatInfo.domain)"
        target="_blank"
        rel="noreferrer"
        class="w-fit underline"
        data-test="chatter-website"
      >
        {{ chatInfo.domain.replace('https://', '').replace('http://', '') }}
      </a>
    </div>
  </sidebar-dropdown>
</template>

<script lang="ts">
import { sanitizeUrl } from '@braintree/sanitize-url';
import { defineComponent } from 'vue';

import { getVisitorDetails } from '@/components/TicketSidebar/Api/requests';
import SidebarDropdown from '@/components/TicketSidebar/SidebarDropdown.vue';
import countries from '@/util/countries.json';

import type ChatInfo from '@/types/chatInfo';
import type Tickets from '@/types/tickets';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'VisitorDetailsDropdown',

  components: { SidebarDropdown },

  props: {
    ticket: {
      type: Object as PropType<Tickets>,
      default: () => ({}),
    },
    online: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chatInfo: {} as Partial<ChatInfo>,
      countries: countries,
      channelName: '',
    };
  },

  computed: {
    isAnonymousModuleEnabled() {
      return this.$root.anonymousModule;
    },

    hasChatInfo() {
      return this?.chatInfo !== null && Object.keys(this?.chatInfo)?.length > 0;
    },
  },

  watch: {
    async online(value) {
      if (value) {
        await this.loadVisitorDetails();
      } else {
        this.chatInfo = {};
      }
    },
  },

  async mounted() {
    await this.loadVisitorDetails();
  },

  methods: {
    sanitizeUrl,

    async loadVisitorDetails() {
      try {
        const response = await getVisitorDetails(this.ticket.id);

        this.chatInfo = response.data;
      } catch (err) {
        console.error(err);
        this.flashError(err);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/typography.scss';

.header-text {
  @apply t-text-sm mx-0 mb-0 mt-3 p-0 text-grey-700 first:mt-0;
}
</style>
