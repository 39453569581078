<template>
  <get-started-card :is-done="isDone" data-test="add-team-member">
    <template #title>{{ $t('getting_started.add_team_members') }}</template>
    <template #description>{{ $t('getting_started.learn_how_to_collaborate') }}</template>
    <template #icon>
      <img class="w-48px rounded-2xl" :src="`${$root.assetsURL}img/get_started_page/users.svg`" />
    </template>
    <div>
      <div class="flex h-32px items-center justify-between" data-test="item-invite-first-team-member">
        <div class="flex flex-row">
          <img :src="stateImage" class="mr-2" />
          <span :class="[stateTextColor]" class="t-text-desktop-label-sm mr-2 sm:mr-0">
            {{ $t('getting_started.invite_your_first_team_member') }}
          </span>
        </div>
        <atomic-button
          v-if="!addTeamMember"
          btn-style="secondary"
          size="xs"
          class="ml-auto inline-flex h-32px min-w-max items-center px-4 text-sm leading-4"
          @click="openHelpCenterArticle"
        >
          <span class="t-text-desktop-label-sm mr-1 text-grey-900">{{ $t('getting_started.find_out_how') }}</span>
          <external-link-linear size="1rem" />
        </atomic-button>
      </div>
    </div>
  </get-started-card>
</template>

<script>
import GetStartedCard from '../GetStartedCard';
import { mapGetters } from 'vuex';
import { ExternalLinkLinear } from '@trengo/trengo-icons';

export default {
  name: 'AddTeamMembers',
  components: { GetStartedCard, ExternalLinkLinear },
  data() {
    return {
      addTeamMember: false,
      isDone: false,
    };
  },
  computed: {
    ...mapGetters({
      getStepByType: 'gamification/getStepByType',
    }),
    stateImage() {
      return this.addTeamMember
        ? this.$root.assetsURL + 'img/get_started_page/successful.svg'
        : this.$root.assetsURL + 'img/get_started_page/unsuccessful.svg';
    },
    stateTextColor() {
      return this.addTeamMember ? 'state-text-grey-500' : 'state-text-grey-800';
    },
  },
  mounted() {
    this.addTeamMember = this.getStepByType('user.invite_first_team_member')?.completed;
    this.isDone = this.addTeamMember;
  },
  methods: {
    openHelpCenterArticle() {
      window.open('https://help.trengo.com/en/articles/63462-add-users-to-trengo', '_blank', 'noopener');
    },
  },
};
</script>
<style scoped src="../styles.scss" lang="scss" />
