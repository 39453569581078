import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { ref: "backdrop" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_web_chat_sandbox = _resolveComponent("web-chat-sandbox")!

  return ($props.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_web_chat_sandbox, {
          "show-buttons": false,
          onCompleted: $options.handleCompleted,
          onClose: $options.handleClose,
          onOpen: $options.handleOpen
        }, null, 8 /* PROPS */, ["onCompleted", "onClose", "onOpen"]),
        _createElementVNode("div", _hoisted_2, null, 512 /* NEED_PATCH */)
      ]))
    : _createCommentVNode("v-if", true)
}