import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_whatsapp_business_sandbox = _resolveComponent("whatsapp-business-sandbox")!
  const _component_t_spinner = _resolveComponent("t-spinner")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_modal = _resolveComponent("t-modal")!

  return (_openBlock(), _createBlock(_component_t_modal, {
    modelValue: $data.openSandboxModal,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.openSandboxModal) = $event)),
    "data-test": "whatsapp-sandbox-modal",
    title: $options.getModalTitle(),
    subheader: $options.getSubheaderText(),
    variant: $options.getModalVariant(),
    onClose: _cache[1] || (_cache[1] = () => $options.close())
  }, {
    footer: _withCtx(() => [
      (String($data.step) === $data.steps.CONNECT_WHATSAPP)
        ? (_openBlock(), _createBlock(_component_t_button, {
            key: 0,
            "data-test": "send-button",
            disabled: $data.isSendDisabled,
            class: "flex items-center justify-center",
            onClick: $options.submit
          }, {
            default: _withCtx(() => [
              ($data.isLoading)
                ? (_openBlock(), _createBlock(_component_t_spinner, {
                    key: 0,
                    class: "mr-2"
                  }))
                : _createCommentVNode("v-if", true),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('whatsapp.send_message')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled", "onClick"]))
        : _createCommentVNode("v-if", true),
      (String($data.step) === $data.steps.TRYING_TEMPLATES_SUCCESS)
        ? (_openBlock(), _createBlock(_component_t_button, {
            key: 1,
            "data-test": "continue-inbox",
            class: "flex items-center justify-center",
            onClick: $options.completeTemplatesSandbox
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('whatsapp.continue_to_inbox')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"]))
        : _createCommentVNode("v-if", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_whatsapp_business_sandbox, {
        step: $data.step,
        "ticket-id": $data.ticketId,
        messages: $data.messages
      }, null, 8 /* PROPS */, ["step", "ticket-id", "messages"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title", "subheader", "variant"]))
}