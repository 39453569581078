<template>
  <div class="preview-card survey-card">
    <h6 class="company_name_title_preview">
      {{ companyName ? companyName : $t('csat.company_name_preview') }}
    </h6>
    <h6 class="question_preview mb-5">
      {{ question ? question : $t('csat.question_preview') }}
    </h6>
    <div class="flex flex-row justify-between">
      <div class="relative">
        <i class="far fa-check"></i>
        <div class="emoji-box red-box">
          <img :src="`${$root.assetsURL}img/csat/very_dissatisfied_emoji.svg`" width="32px;" height="44px;" />
        </div>
      </div>
      <div class="emoji-box grey-box">
        <img :src="`${$root.assetsURL}img/csat/dissatisfied_emoji.svg`" width="32px;" height="44px;" />
      </div>
      <div class="emoji-box grey-box">
        <img :src="`${$root.assetsURL}img/csat/neutral_emoji.svg`" width="32px;" height="44px;" />
      </div>
      <div class="emoji-box grey-box">
        <img :src="`${$root.assetsURL}img/csat/satisfied_emoji.svg`" width="32px;" height="44px;" />
      </div>
      <div class="emoji-box grey-box">
        <img :src="`${$root.assetsURL}img/csat/very_satisfied_emoji.svg`" width="32px;" height="44px;" />
      </div>
    </div>
    <h6>
      {{ follow_up_question ? follow_up_question : $t('csat.follow_up_question_preview') }}
    </h6>
    <t-textarea id="csatReview" height="88px" :required="true" />
    <atomic-button size="md" class="mb-16 mt-12 block">
      {{ $t('csat.submit_review') }}
    </atomic-button>
  </div>
</template>

<script>
export default {
  name: 'SurveyPreviewCard',

  props: {
    follow_up_question: {
      type: String,
      default: '',
    },
    question: {
      type: String,
      default: '',
    },
    companyName: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.survey-card {
  padding: 81px 48px 81px 48px;

  .fa-check {
    color: var(--color-error-700);
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.9);
    left: 15px;
    top: -18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .emoji-box {
    width: 60px;
    height: 60px;
    border-radius: 12px;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .red-box {
    border: 1px solid var(--color-error-500);
    background: var(--color-error-100);
  }

  .grey-box {
    border: 1px solid #cbcbd1;
  }

  h6 {
    font-weight: bold;
    font-size: 17px;
    line-height: 24px;
  }

  .company_name_title_preview {
    color: var(--color-grey-600);
  }

  .t-textarea {
    margin: 12px 0 32px 0;
  }

  button.ui-btn {
    cursor: default;
    margin: 0px;

    &:hover {
      box-shadow: none;
    }
  }
}
</style>
