<template>
  <admin-index-box-rich
    v-if="!!page && needApi"
    :page="page"
    @trigger-button-click="triggerButtonClick"
    @trigger-show-modal="triggerShowModal"
  ></admin-index-box-rich>
  <whatsapp-sandbox v-else-if="channel === 'wa_business'" />
  <wa-template-guide v-else-if="isPathWaTemplates" />
  <div v-else-if="channel === 'email'">
    <email-landing-page v-if="!isGtMd || !channels.length" />
    <mobile-email-list :channels="channels" />
    <channel-created-modal />
  </div>
</template>

<script>
import { mapStores } from 'pinia';

import ChannelCreatedModal from '@/components/Channels/ChannelCreatedModal';
import MobileEmailList from '@/components/Channels/Email/MobileEmailList';
import { useEntitlementsStore } from '@/store/pinia';
import breakpoints from '@/util/breakpoints';

import getAdminIndexBoxes from './AdminIndexBoxes';
import AdminIndexBoxRich from './AdminIndexBoxRich';
import EmailLandingPage from './Channels/EmailLandingPage';
import WhatsappSandbox from './Channels/WhatsappSandbox';
import WaTemplateGuide from './WaTemplates/WaTemplateGuide.vue';
export default {
  name: 'AdminIndexBox',
  emits: ['showModal'],
  components: {
    ChannelCreatedModal,
    MobileEmailList,
    EmailLandingPage,
    WhatsappSandbox,
    AdminIndexBoxRich,
    WaTemplateGuide,
  },
  props: {
    boxName: {
      type: String,
      default: '',
    },
    channels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      page: null,
      breakpoints,
    };
  },
  computed: {
    ...mapStores(useEntitlementsStore),
    AdminIndexBoxes() {
      return getAdminIndexBoxes((key, options) => this.$t(key, options));
    },
    articleLink() {
      let lang = 'en';
      if (this.$root.user.locale_code === 'nl-NL') {
        lang = 'nl';
      }
      return 'https://help.trengo.com/' + lang + this.box.helpArticle;
    },
    box() {
      if (this.boxName === 'channel') {
        return this.AdminIndexBoxes[this.$route.params.channel];
      }
      return this.AdminIndexBoxes[this.boxName];
    },
    channel() {
      return this.$route.params.channel;
    },
    needApi() {
      return this.channel !== 'email' && this.channel !== 'wa_business';
    },
    isGtMd() {
      const { comparisons } = this.breakpoints;
      return !comparisons.gt_md;
    },
    isPathWaTemplates() {
      return this.$route.path === '/admin/wa_templates';
    },
    entitlementUpgradeButtonText() {
      const minimumRequiredPlan = this.entitlementsStore?.getMinimumRequiredPlanFor(this.page.entitlement_feature_id);
      return this.$t('entitlement.upgrade_to_plan', { plan: minimumRequiredPlan });
    },
  },
  beforeMount() {
    if (this.needApi) this.loadRichPage();
  },
  watch: {
    $route(to, from) {
      this.loadRichPage();
    },
  },
  methods: {
    loadRichPage() {
      let name = this.boxName;
      if (this.boxName === 'channel') {
        name = 'channel.' + this.$route.params.channel;
      }
      this.page = this.getAdminInboxJsByName(name);
      if (this.page && this.page.entitlement_feature_id) {
        this.page.entitlement_upgrade_button_text = this.entitlementUpgradeButtonText;
      }
    },
    getAdminInboxJsByName(name) {
      return this.AdminIndexBoxes.find((b) => b.name === name);
    },
    triggerButtonClick(link, functionName, functionParameters, event) {
      this.buttonClick(link, functionName, functionParameters, event);
    },
    triggerShowModal() {
      this.$emit('showModal');
    },
    buttonClick(link, functionName, functionParameters, event) {
      if (functionName) {
        if (functionParameters) {
          this.$emit(functionName, ...functionParameters);
        } else {
          this.$emit[functionName]();
        }
        event.preventDefault();
      } else {
        router.push(link);
      }
    },
  },
};
</script>
