<script type="text/babel">
import { filter, map } from 'lodash';

import PusherHelper from '../../../util/PusherHelper';
import Timezones from '../../Pages/Sections/Timezones';
import PageContainer from '../Components/PageContainer';
import PageTitle from '../Components/PageTitle';

export default {
  data: function () {
    return {
      csats: {},
      filters: {
        date: {
          start: moment().subtract(6, 'days').unix(),
          end: moment().unix(),
        },
        channels: [],
        labels: [],
        csat: [],
        users: [],
      },
      columns: [],
      selected: 0,
      list: [],
      selectedColumns: [],
      timezone: this.$root.user.timezone,
      exporting: false,
    };
  },

  components: { PageTitle, PageContainer },

  computed: {
    timezoneList() {
      let list = map(Timezones, (t) => {
        return { id: t, text: t };
      });
      this.timezone = filter(list, (t) => t.id === this.$root.user.timezone)[0];
      return list;
    },
  },

  mounted: function () {
    this.fetchColumns();
    this.fetchList();
    this.fetchCsats();

    PusherHelper.bindPusherEvent(this.$root.agencyChannel, 'TicketExportFinished', (data) => {
      this.fetchList();
      if (data.id && this.exporting) {
        this.downloadItem(data.id);
      }
    });
    PusherHelper.bindPusherEvent(this.$root.agencyChannel, 'TicketExportProgress', (data) => {
      let e = this.list.find((i) => i.id === data.id);
      Object.assign(e, { progress: data.progress });
    });
  },

  methods: {
    fetchCsats() {
      $.getJSON('/client-api/reports/filters/csats').then((csats) => {
        this.csats = csats;
      });
    },

    fetchColumns() {
      $.getJSON('/client-api/reports/export/columns').then((res) => {
        this.columns = map(res, (c) => {
          return { id: c.key, name: c.label };
        });
      });
    },

    fetchList() {
      $.getJSON('/client-api/reports/export/list').then((list) => {
        this.list = list;
      });
    },

    startExport() {
      this.exporting = true;
      axios
        .post('/client-api/reports/export', {
          data: JSON.stringify({
            labels: this.filters.labels,
            users: this.filters.users,
            channels: this.filters.channels,
            csat: this.filters.csat,
            start: moment.unix(this.filters.date.start).format('YYYY-MM-DD HH:mm:ss'),
            end: moment.unix(this.filters.date.end).format('YYYY-MM-DD HH:mm:ss'),
            timezone: this.timezone.id,
            columns: this.selectedColumns,
          }),
        })
        .then(() => {
          this.fetchList();
        })
        .catch((res) => {
          alert(res.response.data.error);
        });

      this.selectedColumns = [];
    },

    downloadItem(id) {
      window.location.href = '/client-api/reports/export/' + id;
    },
    removeSelectedColumn(col) {
      this.selectedColumns.splice(this.selectedColumns.indexOf(col), 1);
    },
    getCol(col) {
      return filter(this.columns, (c) => c.id === col)[0];
    },
  },
};
</script>

<template>
  <div class="w-full">
    <page-container>
      <hero class="my-6">
        <template #top>
          <page-title>{{ $t('reports.export_title') }}</page-title>
        </template>
        <template #body>{{ $t('reports.export_title_sub_text') }}</template>
      </hero>

      <div class="mb-8 flex select-none flex-row items-center rounded-lg bg-white px-10 pb-6 pt-4">
        <div class="flex w-full items-center">
          <div>
            <atomic-multiselect
              v-model="filters.channels"
              :title="$t('reports.export_filter_channels')"
              label="text"
              :options="$root.channels"
            ></atomic-multiselect>
          </div>
          <div class="ml-4">
            <atomic-multiselect
              v-model="filters.users"
              :title="$t('reports.export_filter_users')"
              label="text"
              :options="$root.users"
            ></atomic-multiselect>
          </div>
          <div class="ml-4">
            <atomic-multiselect
              v-model="filters.labels"
              :title="$t('reports.export_filter_labels')"
              label="name"
              :options="$root.labels"
            ></atomic-multiselect>
          </div>
          <div class="ml-4">
            <atomic-multiselect
              v-model="filters.csat"
              :title="$t('reports.export_filter_csats')"
              label="text"
              :options="csats"
            ></atomic-multiselect>
          </div>
          <div class="ml-auto">
            <div class="flex rounded-lg border border-grey-200 font-bold">
              <atomic-datepicker v-model="filters.date" mode="range"></atomic-datepicker>
            </div>
          </div>
          <div class="ml-4">
            <atomic-dropdown v-model="timezone" max-height="500px" :options="timezoneList">
              <template #toggle>
                <div class="flex cursor-pointer items-center">
                  <span class="text-base font-bold">
                    {{ $t('reports.export_filter_timezone') }} {{ timezone.text }}
                  </span>
                  <i class="far fa-chevron-down pl-1 text-sm text-grey-600"></i>
                </div>
              </template>
            </atomic-dropdown>
          </div>
        </div>
      </div>

      <div class="mb-8 select-none items-center rounded-lg bg-white p-6 px-10">
        <div class="">
          <div :class="{ ' ': !selectedColumns.length }">
            <p>{{ $t('reports.export_select_one_or_more_fields_that_you_want_to_export') }}</p>

            <atomic-multiselect
              v-model="selectedColumns"
              title="Select columns to export"
              :show-selected-count="false"
              :options="columns"
              :select-all="true"
            >
              <template #toggle>
                <atomic-button size="md" btn-style="secondary">
                  <i class="far fa-plus"></i>
                  {{ $t('reports.export_add_field') }}
                </atomic-button>
              </template>
            </atomic-multiselect>
          </div>
        </div>
        <div v-if="selectedColumns.length" class="mt-4 flex flex-col" style="max-height: 500px; overflow: auto">
          <div v-for="col in selectedColumns" class="mb-2 flex flex-1 rounded-lg border border-grey-100 px-4 py-5">
            <div class="flex-1" style="font-size: 14px; font-weight: 500">
              <span class="bg-white px-3 py-2 text-grey-800" style="border-radius: 12px">
                {{ getCol(col).name }}
              </span>
            </div>
            <div class="ml-auto">
              <span class="cursor-pointer font-semibold" @click="removeSelectedColumn(col)">
                {{ $t('reports.export_remove') }}
              </span>
            </div>
          </div>
        </div>
        <div v-show="selectedColumns.length > 0" class="mt-4 flex items-center justify-center">
          <div>
            <atomic-button
              size="md"
              btn-style="secondary"
              :disabled="!selectedColumns.length"
              @click="selectedColumns = []"
            >
              {{ $t('reports.export_clear_fields') }}
            </atomic-button>
            <atomic-button
              size="md"
              btn-style="primary"
              :disabled="!selectedColumns.length"
              class="ml-2"
              @click="startExport()"
            >
              <i class="far fa-file pr-2"></i>
              {{ $t('reports.export_start_export') }}
            </atomic-button>
          </div>
        </div>
      </div>

      <div v-if="list.length" class="mb-8 select-none items-center rounded-lg bg-white px-10 py-6">
        <div class="my-4 text-2xl font-bold">{{ $t('reports.export_export_history') }}</div>
        <div>
          <div v-for="item in list" class="mb-2 flex items-center border-b border-grey-300 pb-2">
            <div class="flex-1 text-base">
              <div v-if="item.status === 'PENDING'">
                <i class="fa fa-spinner fa-spin"></i>
                ({{ item.progress || 0 }}%)
              </div>
              <div v-if="item.status === 'COMPLETED'" class="flex items-center">
                <i class="far fa-file mr-2 text-grey-600"></i>
                <datetime :time="item.created_at" :pretty="$root.prettyDates"></datetime>
              </div>
            </div>
            <div v-if="item.status === 'COMPLETED'" class="ml-auto">
              <atomic-button size="sm" btn-style="secondary" @click="downloadItem(item.id)">
                <i class="far fa-cloud-download-alt fa-sm mr-2"></i>
                {{ $t('reports.export_download_file') }}
              </atomic-button>
            </div>
          </div>
        </div>
      </div>
    </page-container>
  </div>
</template>
