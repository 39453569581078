import eventBus from '@/eventBus';
import { app } from '@/main';
const confirmAlert = {
  install() {
    /**
     * You can use this method to confirm an action.
     * @example
     * this.$tConfirm('Are you sure?', {
     *     title: 'Confirm',
     *     type: 'regular',
     *     showCancel: true,
     *     showConfirm: true,
     *     confirmText: 'Confirm',
     *     cancelText: 'Cancel',
     *     deleteIcon: false,
     *     delete: false,
     * }).then((val) => {
     *     if (val) {
     *       console.log('Confirmed');
     *     }else {
     *       console.log('Canceled');
     *     }
     * })
     *
     *
     * @example
     * if (await this.$tConfirm('Are you sure?')) {
     *     console.log('Confirmed');
     * } else {
     *     console.log('Canceled');
     * }
     *
     *
     * @param {string} message
     * @param {object=} options
     * @param {string=} options.title
     * @param {'regular','danger'=} options.type
     * @param {boolean=} options.showCancel
     * @param {boolean=} options.showClose
     * @param {string=} options.cancelText
     * @param {string=} options.confirmText
     * @param {boolean=} options.deleteIcon
     * @param {boolean=} options.delete
     * @param {boolean=} options
     * @returns {Promise<boolean>}
     */
    app.config.globalProperties.$tConfirm = (message, options) => {
      // Making a easy way to use the confirm for delete
      if (options.delete) {
        options.deleteIcon = true;
        options.type = options.type || 'danger';
      }
      if (options.hideDeleteIcon) {
        options.deleteIcon = false;
      }

      eventBus.$emit('t-alert-confirm', {
        message,
        ...options,
      });

      return new Promise((resolve, reject) => {
        eventBus.$on('t-alert-confirm-resolve', () => {
          resolve(true);
        });
        eventBus.$on('t-alert-confirm-reject', () => {
          resolve(false);
        });
      });
    };
  },
};

export default confirmAlert;
