<template>
  <li class="icon-text">
    <div :class="['icon', bgColor]" aria-hidden="true">
      <slot name="icon" />
    </div>
    <div class="text">
      <slot name="text" />
    </div>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'IconTextListItem',
  props: {
    bgColor: {
      type: String,
      default: 'purple',
    },
  },
});
</script>

<style lang="scss" scoped src="./IconTextListItem.scss" />
