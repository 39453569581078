import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_2 = { class: "mx-auto flex w-2/3 flex-row pt-5" }
const _hoisted_3 = { class: "space-between flex items-center" }
const _hoisted_4 = { class: "grow" }
const _hoisted_5 = {
  key: 0,
  class: "mx-auto flex w-2/3 flex-row py-5",
  "data-test": "integration-details"
}
const _hoisted_6 = { class: "w-2/3" }
const _hoisted_7 = { class: "w-1/3" }
const _hoisted_8 = {
  key: 1,
  class: "mx-auto flex w-2/3 flex-row py-5",
  "data-test": "integration-installed-details"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_top_circle_linear = _resolveComponent("arrow-top-circle-linear")!
  const _component_arrow_right_linear = _resolveComponent("arrow-right-linear")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!
  const _component_detail_card = _resolveComponent("detail-card")!
  const _component_metadata_card = _resolveComponent("metadata-card")!
  const _component_installed_table = _resolveComponent("installed-table")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.integration)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.showUpgradeBar)
            ? (_openBlock(), _createBlock(_component_t_inline_banner, {
                key: 0,
                type: "upgrade"
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_arrow_top_circle_linear, { size: "1rem" })
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.bannerText, { plan: _ctx.planName })), 1 /* TEXT */),
                    _createVNode(_component_t_button, {
                      "btn-style": "secondary",
                      size: "sm",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(_ctx.SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('email_settings.upgrade_plan')) + " ", 1 /* TEXT */),
                        _createVNode(_component_arrow_right_linear, {
                          size: "1.125rem",
                          class: "ml-1 inline"
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ])
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        (_ctx.page === 'description')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_detail_card, {
                  class: "mr-4",
                  integration: _ctx.integration
                }, null, 8 /* PROPS */, ["integration"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_metadata_card, {
                  metadata: _ctx.integration.metadata
                }, null, 8 /* PROPS */, ["metadata"])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_installed_table, {
                class: "mt-8 w-full",
                integration: _ctx.integration
              }, null, 8 /* PROPS */, ["integration"])
            ])),
        _createVNode(_component_router_view)
      ]))
    : _createCommentVNode("v-if", true)
}