<template>
  <div class="mt-8 w-full rounded-xl border border-grey-300 bg-white p-6">
    <div class="flex flex-row items-center justify-between">
      <div class="flex flex-row items-center">
        <t-thumbnail class="mr-3 bg-white">
          <chart-column-linear size="1.4rem" />
        </t-thumbnail>
        <div class="flex flex-col">
          <p class="t-text-h5 m-0 text-grey-800">{{ $t('reports_v2.average_conversations_created') }}</p>
        </div>
      </div>

      <div class="label-test segment-wrapper w-32">
        <t-segmented-control v-if="isLoaded" size="sm" :active-index="0" :segments="segments" @change="switchSegment" />

        <div v-else class="animation-loading h-8 w-32 rounded-xl bg-grey-300"></div>
      </div>
    </div>
    <div data-test="workload-management-chart" class="relative mt-6 h-88">
      <hourly-average-conversations-created-loaded
        v-if="showHourlyAverageConversations"
        :created-conversations="hourlyCreatedConversations"
        :show-error="showHourlyError"
      />

      <hourly-average-conversations-created-loading v-if="!isLoaded" />

      <daily-average-conversations-created-loaded
        v-if="showDailyAverageConversations"
        :created-conversations="dailyCreatedConversations"
        :show-error="showDailyError"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { ChartColumnLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import {
  WorkloadHourlyAverageConversationsCreatedMockData,
  WorkloadDailyAverageConversationsCreatedMockData,
} from '@/components/Reporting/api/mocks';
import { fetchAverageCreatedConversations } from '@/components/Reporting/Pages/V2/WorkloadManagement/api';
import { mapFilters, removeDuplicatesAndInterval } from '@/components/Reporting/Pages/V2/WorkloadManagement/utils';
import eventBus from '@/eventBus';

import DailyAverageConversationsCreatedLoaded from './DailyAverageConversationsCreated/DailyAverageConversationsCreatedLoaded.vue';
import HourlyAverageConversationsCreatedLoaded from './HourlyAverageConversationsCreated/HourlyAverageConversationsCreatedLoaded.vue';
import HourlyAverageConversationsCreatedLoading from './HourlyAverageConversationsCreated/HourlyAverageConversationsCreatedLoading.vue';

import type { DatePickerDateAndInterval, WorkloadManagementBucket } from '@/components/Reporting/api/types';
import type { Filter } from '@/components/Reporting/Components/V2/FilterDropdown/FilterDropdown.vue';
import type { PropType } from 'vue';

type Data = {
  hourlyCreatedConversations: WorkloadManagementBucket[];
  dailyCreatedConversations: WorkloadManagementBucket[];
  isHourlyConversationsLoaded: boolean;
  isDailyConversationsLoaded: boolean;
  showHourlyError: boolean;
  showDailyError: boolean;
  segments: Segment[];
  isLoaded: boolean;
  filtersFromRouteQuery: string;
};

type Segment = {
  label: string;
  value: string;
};

type ActiveSegment = {
  index: number;
  segment: Segment;
};

export default defineComponent({
  name: 'WorkloadManagementChartIndex',
  components: {
    ChartColumnLinear,
    DailyAverageConversationsCreatedLoaded,
    HourlyAverageConversationsCreatedLoaded,
    HourlyAverageConversationsCreatedLoading,
  },

  data(): Data {
    return {
      hourlyCreatedConversations: [] as WorkloadManagementBucket[],
      dailyCreatedConversations: [] as WorkloadManagementBucket[],
      isHourlyConversationsLoaded: true,
      isDailyConversationsLoaded: false,
      segments: [
        { label: 'Hourly', value: 'Hourly' },
        { label: 'Daily', value: 'Daily' },
      ],
      showHourlyError: false,
      showDailyError: false,
      isLoaded: false,
      filtersFromRouteQuery: '',
    };
  },

  props: {
    filters: {
      type: Object as PropType<DatePickerDateAndInterval>,
      default: () => ({}),
    },
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        const filtersExcludeDates = this.excludeDates(
          this.$route.query.filters ? JSON.parse(this.$route.query.filters) : {}
        );
        this.filtersFromRouteQuery = mapFilters(filtersExcludeDates);
        this.getWorkloadManagementVolume(this.filtersFromRouteQuery);
      },
    },
  },

  mounted() {
    const filtersExcludeDates = this.excludeDates(
      this.$route.query.filters ? JSON.parse(this.$route.query.filters) : {}
    );
    this.filtersFromRouteQuery = mapFilters(filtersExcludeDates);
    this.getWorkloadManagementVolume(this.filtersFromRouteQuery);
    eventBus.$on('reporting-filter', this.fetchFilters);
  },

  unmounted() {
    eventBus.$off('reporting-filter', this.fetchFilters);
  },

  computed: {
    showDailyAverageConversations() {
      return this.isLoaded && this.isDailyConversationsLoaded && !this.isHourlyConversationsLoaded;
    },

    showHourlyAverageConversations() {
      return this.isLoaded && this.isHourlyConversationsLoaded && !this.isDailyConversationsLoaded;
    },
  },

  methods: {
    excludeDates(filters: object) {
      delete filters['startDate'];
      delete filters['endDate'];
      delete filters['interval'];
      return filters;
    },
    fetchFilters(filters: Filter) {
      this.filtersFromRouteQuery = mapFilters(this.excludeDates(filters));
      const mapedFilters = mapFilters(removeDuplicatesAndInterval(filters));
      this.getHourlyWorkloadManagementVolume(mapedFilters);
      this.getDailyWorkloadManagementVolume(mapedFilters);
    },
    async getHourlyWorkloadManagementVolume(filters: string) {
      try {
        const createdConversations = await fetchAverageCreatedConversations(
          this.filters.startDate,
          this.filters.endDate,
          'hour',
          filters
        );

        this.setCreatedConversations(createdConversations.data.buckets, true);
      } catch (error) {
        this.showHourlyError = true;
        this.hourlyCreatedConversations = WorkloadHourlyAverageConversationsCreatedMockData.data.buckets;
        console.error(error);
      }
    },

    async getDailyWorkloadManagementVolume(filters: string) {
      try {
        const createdConversations = await fetchAverageCreatedConversations(
          this.filters.startDate,
          this.filters.endDate,
          'day',
          filters
        );

        this.setCreatedConversations(createdConversations.data.buckets, false);
      } catch (error) {
        this.showDailyError = true;
        this.dailyCreatedConversations = WorkloadDailyAverageConversationsCreatedMockData.data.buckets;
        console.error(error);
      }
    },

    setCreatedConversations(createdConversationsBuckets: WorkloadManagementBucket[], isHourly: boolean) {
      const createdConversationType = isHourly ? 'hourlyCreatedConversations' : 'dailyCreatedConversations';
      const errorType = isHourly ? 'showHourlyError' : 'showDailyError';
      const mockData = isHourly
        ? WorkloadHourlyAverageConversationsCreatedMockData
        : WorkloadDailyAverageConversationsCreatedMockData;

      if (createdConversationsBuckets.length > 0) {
        this[createdConversationType] = createdConversationsBuckets;
      } else {
        this[createdConversationType] = mockData.data.buckets;
        this[errorType] = true;
      }
    },

    switchSegment(activeSegment: ActiveSegment) {
      if (activeSegment.index === 0) {
        this.isHourlyConversationsLoaded = true;
        this.isDailyConversationsLoaded = false;
      } else {
        this.isHourlyConversationsLoaded = false;
        this.isDailyConversationsLoaded = true;
      }
    },

    getWorkloadManagementVolume(filters: string) {
      this.getHourlyWorkloadManagementVolume(filters);
      this.getDailyWorkloadManagementVolume(filters);
      this.isLoaded = true;
    },
  },
});
</script>

<style scoped lang="scss" src="../../../assets/skeleton.scss" />
