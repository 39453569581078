import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "t-text-paragraph-sm m-0 text-grey-600 hover:cursor-pointer hover:text-leaf-500 hover:underline hover:underline-offset-2" }
const _hoisted_2 = { class: "flex flex-row items-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "t-text-desktop-paragraph-sm m-0 ml-2 w-full truncate text-grey-800" }
const _hoisted_5 = { class: "flex flex-row items-center" }
const _hoisted_6 = { class: "t-text-sm m-0 text-right text-grey-600" }
const _hoisted_7 = { class: "t-text-sm m-0 text-grey-600" }
const _hoisted_8 = { class: "t-text-sm m-0 text-grey-600" }
const _hoisted_9 = { class: "t-text-sm m-0 text-grey-600" }
const _hoisted_10 = { class: "t-text-sm m-0 text-grey-600" }
const _hoisted_11 = { class: "t-text-sm m-0 text-grey-600" }
const _hoisted_12 = { class: "t-text-sm m-0 text-grey-600" }
const _hoisted_13 = { class: "t-text-sm m-0 text-grey-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_t_table_list_cell = _resolveComponent("t-table-list-cell")!
  const _component_t_avatar = _resolveComponent("t-avatar")!
  const _component_t_badge = _resolveComponent("t-badge")!
  const _component_ticket_insight_labels = _resolveComponent("ticket-insight-labels")!
  const _component_t_tag = _resolveComponent("t-tag")!
  const _component_ticket_insight_csat = _resolveComponent("ticket-insight-csat")!
  const _component_ticket_insight_time_cell = _resolveComponent("ticket-insight-time-cell")!
  const _component_ticket_insight_date_cell = _resolveComponent("ticket-insight-date-cell")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_table_list_row = _resolveComponent("t-table-list-row")!

  return (_openBlock(), _createBlock(_component_t_table_list_row, { "data-test": "ticket-inisight-table-row" }, {
    default: _withCtx(() => [
      _createVNode(_component_t_table_list_cell, { class: "w-[120px] break-words pr-4" }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, [
            _createVNode(_component_router_link, {
              to: `/tickets/${_ctx.availableData.ticket_id}`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.availableData.ticket_id), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["to"])
          ])
        ]),
        _: 1 /* STABLE */
      }),
      (_ctx.availableColumns.Assignee)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 0,
            class: "max-w-[320px] break-words pr-4"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.availableData.assignee.profile_image)
                  ? (_openBlock(), _createBlock(_component_t_avatar, {
                      key: 0,
                      image: _ctx.availableData.assignee.profile_image,
                      width: "32"
                    }, null, 8 /* PROPS */, ["image"]))
                  : (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      src: _ctx.getUnassignedImage,
                      width: "32px"
                    }, null, 8 /* PROPS */, _hoisted_3)),
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.availableData.assignee.full_name || _ctx.getUnassignedDisplayName(_ctx.availableData.ticket_status)), 1 /* TEXT */)
              ])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.ConversationStatus)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 1,
            class: "flex w-[85px] items-center pr-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_t_badge, {
                class: "w-fit",
                variant: _ctx.getStatusVariant,
                text: _ctx.$t(`reports_v2.${_ctx.availableData.ticket_status.toLowerCase()}`)
              }, null, 8 /* PROPS */, ["variant", "text"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.AttachedLabels)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 2,
            class: "max-w-[320px] pr-4"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_ticket_insight_labels, {
                  labels: this.availableData.attached_labels
                }, null, 8 /* PROPS */, ["labels"])
              ])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.Channel)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 3,
            class: "max-w-[320px] pr-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_t_tag, {
                variant: "channel",
                size: "sm",
                avatar: _ctx.getChannelIconSrc(_ctx.availableData.channel.type),
                class: "max-w-[300px]"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.availableData.channel.title), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["avatar"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.CSATScore)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 4,
            "data-test": "csat",
            class: "max-w-[320px] break-words pl-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ticket_insight_csat, {
                score: _ctx.availableData.csat
              }, null, 8 /* PROPS */, ["score"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.FirstResolutionTimeWithoutBusinessHours)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 5,
            class: "max-w-[320px] break-words pl-4",
            "data-test": "first_resolution_time_without_business_hours"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ticket_insight_time_cell, {
                time: _ctx.availableData.first_resolution_time_without_business_hours
              }, null, 8 /* PROPS */, ["time"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.FirstResolutionTime)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 6,
            class: "max-w-[320px] break-words pl-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ticket_insight_time_cell, {
                time: _ctx.availableData.first_resolution_time_with_business_hours
              }, null, 8 /* PROPS */, ["time"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.TotalResolutionTimeWithoutBusinessHours)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 7,
            class: "max-w-[320px] break-words pl-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ticket_insight_time_cell, {
                time: _ctx.availableData.total_resolution_time_without_business_hours
              }, null, 8 /* PROPS */, ["time"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.TotalResolutionTime)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 8,
            class: "max-w-[320px] break-words pl-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ticket_insight_time_cell, {
                time: _ctx.availableData.total_resolution_time_with_business_hours
              }, null, 8 /* PROPS */, ["time"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.FirstResponseTimeWithoutBusinessHours)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 9,
            class: "max-w-[320px] break-words pl-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ticket_insight_time_cell, {
                time: _ctx.availableData.first_reply_time_without_business_hours
              }, null, 8 /* PROPS */, ["time"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.FirstResponseTime)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 10,
            class: "max-w-[320px] break-words pl-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ticket_insight_time_cell, {
                time: _ctx.availableData.first_reply_time_with_business_hours
              }, null, 8 /* PROPS */, ["time"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.CreatedAt)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 11,
            class: "break-words pl-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ticket_insight_date_cell, {
                time: _ctx.availableData.created_at
              }, null, 8 /* PROPS */, ["time"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.AssignedAt)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 12,
            class: "break-words pl-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ticket_insight_date_cell, {
                time: _ctx.availableData.assigned_at
              }, null, 8 /* PROPS */, ["time"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.ClosedAt)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 13,
            class: "break-words pl-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ticket_insight_date_cell, {
                time: _ctx.availableData.closed_at
              }, null, 8 /* PROPS */, ["time"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.MessagesSent)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 14,
            class: "break-words pl-4"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.availableData.messages_sent), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.ChannelType && _ctx.availableData.channel)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 15,
            class: "break-words pl-4"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.availableData.channel.type), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.ContactEmail)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 16,
            class: "break-words pl-4"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.availableData.contact_email), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.ContactPhone)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 17,
            class: "break-words pl-4"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.availableData.contact_phone), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.ContactCustomFields)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 18,
            class: "break-words pl-4"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.availableData.contact_custom_fields), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.ProfileCustomFields)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 19,
            class: "break-words pl-4"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.availableData.profile_custom_fields), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.TicketCustomFields)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 20,
            class: "break-words pl-4"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.availableData.ticket_custom_fields), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.availableColumns.TicketResult)
        ? (_openBlock(), _createBlock(_component_t_table_list_cell, {
            key: 21,
            class: "break-words pl-4"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.availableData.ticket_result), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_t_table_list_cell, { class: "w-[6rem] break-words pl-4 text-right" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: `/tickets/${_ctx.availableData.ticket_id}`
          }, {
            default: _withCtx(() => [
              _createVNode(_component_t_button, {
                "btn-style": "secondary",
                class: "!rounded-lg",
                size: "sm"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('reports_v2.view')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["to"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}