<template>
  <div class="ml-2 rounded-full p-1 transition-colors" :style="{ background: statusColor }"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import PusherHelper from '@/util/PusherHelper';

export default defineComponent({
  name: 'VisitorOnlineStatus',
  emits: ['online-status-changed', 'client-typing'],
  props: {
    contactPhone: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      inProgress: true,
      isOnline: false,
      offlineTimer: null,
      pusherChannel: null,
    };
  },

  computed: {
    statusColor() {
      if (this.inProgress) {
        return 'lightgray';
      }
      return this.isOnline ? '#14b29f' : 'red';
    },
  },

  beforeUnmount() {
    this.unbindAllEvents();
  },

  methods: {
    unbindAllEvents() {
      if (this.pusherChannel !== null) {
        PusherHelper.unbindPusherEvent(this.pusherChannel, 'pusher:subscription_succeeded');
        PusherHelper.unbindPusherEvent(this.pusherChannel, 'pusher:member_added');
        PusherHelper.unbindPusherEvent(this.pusherChannel, 'pusher:member_removed');
        PusherHelper.unbindPusherEvent(this.pusherChannel, 'client-typing');
        PusherHelper.unsubscribePusherChannel(this.pusherChannel);
        this.pusherChannel = null;
      }
      window.PusherInstance.connection.unbind('state_change');
    },
    subscribeToStatus() {
      const channelName = `presence-ticket@${this.contactPhone}`;
      const channel = window?.PusherInstance?.subscribe(channelName);
      this.pusherChannel = channel;
      this.inProgress = true;
      channel.bind('pusher:subscription_succeeded', (members: any) => {
        this.inProgress = false;
        if (members.count >= 2) {
          for (const [key] of Object.entries(members.members)) {
            if (key === this.contactPhone) {
              clearTimeout(this.offlineTimer);
              this.isOnline = true;
            }
          }
        }
      });

      channel.bind('pusher:member_added', (member: any) => {
        if (member.id === this.contactPhone) {
          clearTimeout(this.offlineTimer);
          this.isOnline = true;
        }
      });

      channel.bind('pusher:member_removed', (member: any) => {
        if (member.id === this.contactPhone) {
          this.offlineTimer = setTimeout(() => {
            this.isOnline = false;
          }, 100);
        }
      });

      channel.bind('client-typing', (data: any) => {
        this.$emit('client-typing', data.message);
      });
    },
  },

  watch: {
    isOnline(val) {
      this.$emit('online-status-changed', val);
    },
    contactPhone: {
      immediate: true,
      handler(val) {
        if (val) {
          this.unbindAllEvents();
          this.subscribeToStatus();
          window.PusherInstance.connection.bind('state_change', () => {
            const state = window.PusherInstance.connection.state;
            if (state === 'connected') {
              this.unbindAllEvents();
              this.subscribeToStatus();
            } else {
              this.inProgress = true;
            }
          });
        }
      },
    },
  },
});
</script>
