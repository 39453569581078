<script type="text/babel">
import { PaperclipLinear } from '@trengo/trengo-icons';

import { ALLOWED_FILE_EXTENSIONS, getFileExtension } from '@/util/fileHelper';
import { flashError } from '@/util/flashNotification';

export default {
  components: { PaperclipLinear },

  props: {
    colorClass: {
      default: function () {
        return ['text-grey-600', 'hover:text-grey-600'];
      },
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    isRedesign: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    allowedFileExtensions() {
      return ALLOWED_FILE_EXTENSIONS.map((item) => '.' + item);
    },
  },

  methods: {
    /**
     * @return void
     */
    onChange: function (e) {
      const files = e.target.files;
      const filesArr = Array.prototype.slice.call(files);
      filesArr.forEach((f) => {
        this.addFile(f);
      });
      e.target.value = '';
    },

    /**
     * @param f
     */
    addFile: function (f) {
      const extension = getFileExtension(f['name']);

      if (!extension || !ALLOWED_FILE_EXTENSIONS.includes(extension.toLowerCase())) {
        flashError(this.$t('attachment.unsupported_file_type'));

        return;
      }

      this.$emit('attachment', {
        name: f['name'],
        obj: f,
        extension,
        size: formatBytes(f['size']),
        realSize: f['size'],
      });
    },
  },
};
</script>

<template>
  <div v-tooltip="{ placement: 'top', content: $t('tickets.insert_attachment'), delay: { show: 500, hide: 0 } }">
    <i
      v-if="!isRedesign"
      class="material-icons mb-0 mt-2 cursor-pointer"
      :class="colorClass"
      style="font-size: 23px"
      @click="$refs.file.click()"
    >
      attach_file
    </i>

    <t-button v-else size="sm" :icon-only="true" :btn-style="'secondary'" @click="$refs.file.click()">
      <template #icon>
        <paperclip-linear size="1.25rem" :class="colorClass" />
      </template>
    </t-button>

    <input ref="file" type="file" :multiple="multiple" :accept="allowedFileExtensions" hidden @change="onChange" />
  </div>
</template>

<style lang="scss" scoped>
.rounded-icon {
  @apply cursor-pointer rounded-full border-1 border-grey-400 p-2 text-grey-800 hover:bg-grey-200;
}
</style>
