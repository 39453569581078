import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "mx-auto w-full px-6 max-lg:mt-12 xl3:max-w-[1440px]",
  "data-test": "integration-filtered-cards"
}
const _hoisted_2 = { class: "mt-2 flex w-full flex-row" }
const _hoisted_3 = {
  class: "text-md mt-1 text-grey-700",
  "data-test": "integration-filter-description"
}
const _hoisted_4 = {
  key: 0,
  class: "grid gap-4 pb-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl3:grid-cols-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_input_search = _resolveComponent("t-input-search")!
  const _component_straight = _resolveComponent("straight")!
  const _component_t_card = _resolveComponent("t-card")!
  const _component_integration_cards = _resolveComponent("integration-cards")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.filterDescription), 1 /* TEXT */)
    ]),
    _createVNode(_component_t_input_search, {
      "model-value": _ctx.searchText,
      class: "mb-4 max-w-xs",
      placeholder: _ctx.$tc('integration_hub.search_for_integrations'),
      "data-test": "integration-search-bar",
      onInput: _ctx.searchIntegrations,
      onClear: _ctx.clearSearch
    }, null, 8 /* PROPS */, ["model-value", "placeholder", "onInput", "onClear"]),
    (_ctx.inProgress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(11, (index) => {
            return _createElementVNode("div", { key: index }, [
              _createVNode(_component_t_card, {
                class: "h-full gap-3 bg-white",
                focusable: false
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_straight, {
                    type: 'round',
                    class: "mb-5",
                    width: 48,
                    height: 48,
                    "border-radius": '100%'
                  }),
                  _createVNode(_component_straight, {
                    class: "mb-2",
                    width: 128,
                    height: 24
                  }),
                  _createVNode(_component_straight, {
                    class: "mb-2",
                    width: '100%',
                    height: 12
                  }),
                  _createVNode(_component_straight, {
                    width: 157,
                    height: 12
                  })
                ]),
                _: 1 /* STABLE */
              })
            ])
          }), 64 /* STABLE_FRAGMENT */))
        ]))
      : (_openBlock(), _createBlock(_component_integration_cards, {
          key: 1,
          integrations: _ctx.filteredIntegrations
        }, null, 8 /* PROPS */, ["integrations"]))
  ]))
}