import { DATA_TRACKING_DEVICE_TYPE } from './constants';

/* This is specifically used for data team tracking purposes to know the device type the user is accessing Trengo App with.
 * It is not an exhaustive way to detect the device type, but it is sufficient for the data team's needs.
 * Mobile and tablet devices are categorized together as mobile.
 */
export const getDeviceTypeForTracking = (): string => {
  const mobileRegex = /Mobi|Android|iPad|Tablet/i;

  const userAgent = navigator.userAgent;

  if (mobileRegex.test(userAgent)) {
    return DATA_TRACKING_DEVICE_TYPE.MOBILE;
  } else {
    return DATA_TRACKING_DEVICE_TYPE.DESKTOP;
  }
};
