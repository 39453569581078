export const LOCALE_CODE = {
  AR_AR: 'ar-AR',
  DE_DE: 'de-DE',
  EN_GB: 'en-GB',
  ES_ES: 'es-ES',
  FR_FR: 'fr-FR',
  ID_ID: 'id-ID',
  IT_IT: 'it-IT',
  NL_NL: 'nl-NL',
  PT_BR: 'pt-BR',
  RU_RU: 'ru-RU',
} as const;

export const LOCALE = {
  [LOCALE_CODE.AR_AR]: { dir: 'rtl', name: 'العربية' },
  [LOCALE_CODE.DE_DE]: { dir: 'ltr', name: 'Deutsch' },
  [LOCALE_CODE.EN_GB]: { dir: 'ltr', name: 'English' },
  [LOCALE_CODE.ES_ES]: { dir: 'ltr', name: 'Español' },
  [LOCALE_CODE.FR_FR]: { dir: 'ltr', name: 'Français' },
  [LOCALE_CODE.ID_ID]: { dir: 'ltr', name: 'Bahasa Indonesia' },
  [LOCALE_CODE.IT_IT]: { dir: 'ltr', name: 'Italiano' },
  [LOCALE_CODE.NL_NL]: { dir: 'ltr', name: 'Nederlands' },
  [LOCALE_CODE.PT_BR]: { dir: 'ltr', name: 'Português' },
  [LOCALE_CODE.RU_RU]: { dir: 'ltr', name: 'Русский' },
} as const;
