<template>
  <div class="mt-6 flex flex-col">
    <label for="cluster" class="t-text-sm-emphasize text-grey-800">
      {{ $tc('integrations.light_speed_label_cluster') }}
    </label>
    <t-dropdown
      id="cluster"
      v-model="$props.modelValue.cluster"
      size="sm"
      :items="clusterItems"
      value-index="value"
      text-index="title"
      :error="errors.cluster"
      :class="{ 'mb-6': !errors.cluster }"
      @input="checkCluster"
    />
    <t-error-item
      v-if="errors.cluster"
      :text="$tc('integration_hub.error_cluster_must_be_chosen')"
      danger-icon
      class="mt1.5 mb-4"
    />

    <t-input-text
      id="apiKey"
      v-model="$props.modelValue.api_key"
      size="sm"
      :label="$tc('integrations.light_speed_label_api_key')"
      :sub-label="$tc('integrations.light_speed_subtitle_your_api_key')"
      :class="{ 'mb-6': !errors.apiKey }"
      @blur="checkApiKey"
    />
    <t-error-item
      v-if="errors.apiKey"
      :text="$tc('integration_hub.error_api_key_must_be_filled_out')"
      danger-icon
      class="mb-4"
    />

    <t-input-text
      id="apiToken"
      v-model="$props.modelValue.api_token"
      size="sm"
      :label="$tc('integrations.light_speed_label_api_token')"
      :sub-label="$tc('integrations.light_speed_subtitle_your_api_token')"
      :class="{ 'mb-6': !errors.apiToken }"
      @blur="checkApiToken"
    />
    <t-error-item
      v-if="errors.apiToken"
      :text="$tc('integration_hub.error_api_token_must_be_filled_out')"
      danger-icon
      class="mb-4"
    />

    <label for="shopLanguage" class="t-text-sm-emphasize text-grey-800">
      {{ $tc('integrations.light_speed_label_shop_language') }}
    </label>
    <t-dropdown
      id="shopLanguage"
      v-model="$props.modelValue.shop_language"
      size="sm"
      :items="languageItems"
      value-index="value"
      text-index="title"
      :error="errors.shopLanguage"
      :class="{ 'mb-6': !errors.shopLanguage }"
      @input="checkShopLanguage"
    />
    <t-error-item
      v-if="errors.shopLanguage"
      :text="$tc('integration_hub.error_language_must_be_selected')"
      danger-icon
      class="mb-4"
    />

    <t-input-text
      id="shopUrl"
      v-model="$props.modelValue.shop_url"
      size="sm"
      :label="$tc('integrations.light_speed_label_shop_url')"
      :sub-label="$tc('integrations.light_speed_subtitle_shop_url')"
      :class="{ 'mb-6': !errors.shopUrl }"
      @blur="checkShopUrl"
    />
    <t-error-item
      v-if="errors.shopUrl"
      :text="$tc('integration_hub.error_url_must_be_valid')"
      danger-icon
      class="mb-4"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { vm } from '@/ApplicationHandler';

import type { CustomLightspeedIntegration } from '@/store/types/integrations';

const clusterItems = [
  { title: vm.$tc('integrations.light_speed_option_eu1'), value: 'eu1' },
  { title: vm.$tc('integrations.light_speed_option_us1'), value: 'us1' },
];

const languageItems = [
  { title: vm.$tc('integrations.light_speed_language_option_dutch_nl'), value: 'nl' },
  { title: vm.$tc('integrations.light_speed_language_option_english_en'), value: 'en' },
  { title: vm.$tc('integrations.light_speed_language_option_english_us'), value: 'us' },
  { title: vm.$tc('integrations.light_speed_language_option_french_fr'), value: 'fr' },
  { title: vm.$tc('integrations.light_speed_language_option_french_fc'), value: 'fc' },
  { title: vm.$tc('integrations.light_speed_language_option_spanish_es'), value: 'es' },
  { title: vm.$tc('integrations.light_speed_language_option_german_de'), value: 'de' },
  { title: vm.$tc('integrations.light_speed_language_option_danish_da'), value: 'da' },
  { title: vm.$tc('integrations.light_speed_language_option_italian_it'), value: 'it' },
  { title: vm.$tc('integrations.light_speed_language_option_norwegian_no'), value: 'no' },
  { title: vm.$tc('integrations.light_speed_language_option_polish_po'), value: 'po' },
  { title: vm.$tc('integrations.light_speed_language_option_portugese_pt'), value: 'pt' },
  { title: vm.$tc('integrations.light_speed_language_option_russian_ru'), value: 'ru' },
  { title: vm.$tc('integrations.light_speed_language_option_swedish_sv'), value: 'sv' },
  { title: vm.$tc('integrations.light_speed_language_option_turkish_tr'), value: 'tr' },
  { title: vm.$tc('integrations.light_speed_language_option_greek_el'), value: 'el' },
  { title: vm.$tc('integrations.light_speed_language_option_bulgarian_bg'), value: 'bg' },
];

const props = defineProps<{ modelValue: CustomLightspeedIntegration['meta'] }>();
const emit = defineEmits(['on-validated', 'on-invalidated']);

const errors = ref({ cluster: false, apiKey: false, apiToken: false, shopUrl: false, shopLanguage: false });

function validateFields() {
  checkCluster();
  checkApiKey();
  checkApiToken();
  checkShopUrl();
  checkShopLanguage();

  Object.values(errors.value).some((item) => item) ? emit('on-invalidated') : emit('on-validated');
}

defineExpose({ validateFields });

function checkCluster() {
  errors.value.cluster = !props.modelValue.cluster;
}

function checkApiKey() {
  errors.value.apiKey = !props.modelValue.api_key || props.modelValue.api_key === '';
}

function checkApiToken() {
  errors.value.apiToken = !props.modelValue.api_token || props.modelValue.api_token === '';
}

function checkShopUrl() {
  errors.value.shopUrl = !props.modelValue.shop_url || props.modelValue.shop_url === '';
}

function checkShopLanguage() {
  errors.value.shopLanguage = !props.modelValue.shop_language;
}
</script>
