import { MiddlewareError } from '@/api/errors/middlewareError';
import { MIDDLEWARE_ERROR } from '@/Configs/Constants/middlewareError';
import { ROUTE_NAME } from '@/routes/constants';

import { checkAuthentication } from './auth';
import { loadEntitlements } from './entitlements';
import { loadFeatureFlags } from './featureFlags';
import { checkPermission } from './permission';
import { checkPricingModel } from './pricingModel';

import type { Middleware } from './types';
import type { RouteLocationNormalizedGeneric } from 'vue-router';

const runSimultaneously = (middlewares: Middleware[]) => (to: RouteLocationNormalizedGeneric) =>
  Promise.all(middlewares.map((middleware) => middleware(to)));

export const handleMiddleware = async (to: RouteLocationNormalizedGeneric) => {
  // FIXME: set a global flag in a separate Pinia module to indicate whether the data is still loading,
  // show a spinner while the middlewares are being resolved
  // https://linear.app/trengo/issue/ENG-1/[fe]-implement-isapploading-store-to-signal-to-the-users-that
  const middlewares = [
    checkAuthentication,
    loadFeatureFlags,
    loadEntitlements,
    runSimultaneously([checkPermission, checkPricingModel]),
  ]; // order matters! (middlewares may have dependencies on one another)
  for (const middleware of middlewares) {
    try {
      await middleware(to);
    } catch (error) {
      if (error instanceof MiddlewareError) {
        switch (error?.message) {
          case MIDDLEWARE_ERROR.PERMISSION_REQUIRED:
          case MIDDLEWARE_ERROR.PRICING_MODEL_MISMATCH:
            return { name: ROUTE_NAME.UNAUTHORIZED };
          case MIDDLEWARE_ERROR.AUTHENTICATION_REQUIRED:
          default:
            return { name: ROUTE_NAME.LOGIN };
        }
      } else {
        throw error;
      }
    }
  }
};
