<template>
  <div data-test="team-channels" class="flex flex-col items-start">
    <div v-if="!isChannelEmpty" class="mt-10 flex w-full flex-col items-start justify-between sm:flex-row sm:items-end">
      <div class="flex flex-row flex-col items-start justify-start">
        <div class="t-text-mobile-h5 sm:t-text-desktop-h5 mb-1 flex text-grey-800 sm:flex-row">
          {{ $t('team.connected_channels_title') }}
        </div>
        <div class="t-text-desktop-paragraph-sm text-grey-600">{{ $t('team.connected_channels_detail') }}</div>
      </div>
      <div class="mt-4 sm:mt-0">
        <add-button @click="showAddEntityModal">{{ $t('team.add_channel_title') }}</add-button>
      </div>
    </div>
    <div class="w-full" :class="classIfChannelIsEmpty">
      <team-table :is-empty="isChannelEmpty">
        <template #head>
          <div class="t-text-desktop-label-xs m-0 p-0 text-grey-800">{{ $t('team.channel_name_title') }}</div>
        </template>

        <template #empty>
          <div class="p-6 sm:p-10">
            <div class="flex flex-col items-center justify-center">
              <div class="t-text-desktop-h7 text-center text-grey-800">
                {{ $t('team.team_doesnt_have_access_to_any_channels_error') }}
              </div>
              <div class="t-text-desktop-paragraph-md mb-4 mt-1 text-center text-grey-600">
                {{ $t('team.add_channels_by_clicking_the_button_message') }}
              </div>
              <add-button @click="showAddEntityModal">{{ $t('team.add_channel_button') }}</add-button>
            </div>
          </div>
        </template>

        <template #rows>
          <div>
            <table-row
              v-for="channel in computedChannels"
              :key="channel.id"
              data-test="team-channels-row"
              :is-last="isTheLastOne(channel)"
            >
              <channel-row data-test="team-members-channel-row" :channel="channel" @remove="handleRemoveChannel" />
            </table-row>
          </div>
        </template>
      </team-table>
      <add-entity-modal
        v-model="addEntityModalIsOpen"
        label-by="title"
        :has-workload="hasWorkload"
        entity-type="channel"
        search-by="title"
        :entity="validChannels"
        @submit="handleSubmitAddChannel"
      />
    </div>
  </div>
</template>

<script>
import { differenceBy, last } from 'lodash';

import { fetchChannels } from '@/api';
import { deleteTeamChannelRequest, updateTeamRequestNewAPI } from '@/components/Teams/api';
import { CHANNEL_TYPE } from '@/Configs/Constants';
import { STATUS_CODE } from '@/Configs/Constants/StatusCodes';
import eventBus from '@/eventBus';

import AddButton from '../AddButton';
import AddEntityModal from '../AddEntityModal';
import ChannelRow from '../ChannelRow';
import TableRow from '../TableRow';
import TeamTable from '../TeamTable';
export default {
  name: 'TeamChannels',
  emits: ['change'],
  components: { AddEntityModal, AddButton, ChannelRow, TableRow, TeamTable },
  data() {
    return {
      addEntityModalIsOpen: false,
      validChannels: [],
      allChannels: [],
    };
  },
  props: {
    channels: {
      type: Array,
      default: () => [],
    },
    teamName: {
      type: String,
      default: '',
    },
    teamId: {
      type: Number,
      default: 0,
    },
    hasWorkload: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    channels(val) {
      this.collectChannels(val);
    },
  },
  mounted() {
    this.fetchAllChannels();
  },
  methods: {
    fetchAllChannels() {
      fetchChannels().then((res) => {
        const data = res.data.data;
        this.allChannels = data.filter((c) => c.type !== CHANNEL_TYPE.HELP_CENTER);
        this.collectChannels(this.channels);
      });
    },
    collectChannels(channels) {
      const allChannels = differenceBy(this.allChannels, channels, 'id');
      this.validChannels = allChannels.filter((channel) => !channel.is_private);
    },
    isTheLastOne(channel) {
      return last(this.computedChannels)?.id === channel.id;
    },
    handleSubmitAddChannel(channels) {
      if (!channels.length) {
        return false;
      }
      const data = {
        name: this.teamName,
        channels: channels.map((channel) => channel.id),
      };
      updateTeamRequestNewAPI(this.teamId, data).then(() => {
        this.computedChannels = [...this.computedChannels, ...channels];
        const flashMessage =
          channels.length > 1
            ? this.$t('team.channels_were_added_successfully')
            : this.$t('team.channel_was_added_successfully');
        this.flashSuccess(flashMessage);
      });
    },
    showAddEntityModal() {
      this.addEntityModalIsOpen = true;
    },
    async handleRemoveChannel(channel) {
      const shouldDeleteChannel = await this.$tConfirm(
        this.$t('team.are_you_sure_to_remove_team_from_channel', {
          teamName: this.teamName,
          channelTitle: channel.title,
        }),
        {
          delete: true,
          hideDeleteIcon: true,
          title: this.$t('general.are_you_sure'),
          confirmText: this.$t('general.remove'),
        }
      );
      if (!shouldDeleteChannel) {
        return false;
      }
      deleteTeamChannelRequest(channel.id, this.teamId)
        .then(() => {
          this.computedChannels = this.computedChannels.filter((u) => u.id !== channel.id);
          eventBus.$emit('update-team');
          this.fetchAllChannels();
          this.flashSuccess(this.$t('team.access_to_channel_removed_successfully_message'));
        })
        .catch((e) => {
          if (e?.response?.status === STATUS_CODE.UNPROCESSABLE_ENTITY) {
            this.flashError(this.$t('team.channel_must_have_a_team_error'));
          }
        });
    },
  },
  computed: {
    computedChannels: {
      get() {
        return this.channels;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
    isChannelEmpty() {
      return !this.computedChannels?.length;
    },
    classIfChannelIsEmpty() {
      return this.isChannelEmpty ? 'table-heading-channel-is-empty' : 'table-heading';
    },
  },
};
</script>

<style src="./TeamChannels.scss" scoped lang="scss" />
