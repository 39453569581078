export default class ContactRepository {
  /**
   * @param contact_id
   * @param channel_ids
   * @param page
   * @returns {Promise}
   */
  static fetchTickets(contact_id, channel_ids = [], page = 1) {
    let p = {
      page: page,
      contact_id: contact_id,
    };

    if (channel_ids) {
      p.channels = channel_ids;
    }

    return new Promise((resolve, reject) => {
      axios.get('/api/v2/tickets', { params: p }).then((res) => {
        resolve(res.data);
      });
    });
  }

  /**
   *
   * @param channel_id
   * @param term
   * @param channel_type
   * @returns {Promise<any>}
   */
  static fetchByChannel(channel_id, term, channel_type = null) {
    return new Promise((resolve, reject) => {
      window
        .singleRequest('get', '/api/v2/contacts', {
          params: { channel_id: channel_id, term: term, channel_type: channel_type },
        })
        .then((result) => {
          resolve(result.data.data);
        })
        .catch(() => {});
    });
  }
}
