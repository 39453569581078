<template>
  <div class="preview flex flex-col items-center justify-center rounded-lg bg-contain bg-center p-12">
    <div
      v-if="shouldShowMessage"
      class="message-width preview__card relative min-w-[94px] break-words rounded-md bg-white px-2 pb-2 pt-3"
    >
      <p
        v-if="!!templateHeader && selectedHeaderType === TEMPLATE_HEADER_TYPE.TEXT"
        class="mb-0 text-lg font-bold leading-5"
        v-html="formattedText(templateHeader)"
      />
      <img v-if="selectedHeaderType === TEMPLATE_HEADER_TYPE.IMAGE" :src="imageSrc" class="preview__image" />
      <div class="mt-2 pb-3">
        <p class="mb-0 text-base font-medium leading-5" v-html="formattedText(templateBody)" />
      </div>

      <div class="preview__footer flex items-center justify-between">
        <p
          v-if="!!templateFooter"
          class="footer mb-0 mr-2 w-4/6 break-words text-sm font-normal leading-4"
          v-html="formattedText(templateFooter)"
        />
        <p class="time mb-0 ml-auto text-sm leading-4">{{ currentTime }}</p>
      </div>
    </div>
    <div
      v-for="btn in filteredButtons"
      :key="btn.id"
      class="
        preview__buttons
        message-width
        mt-0.5
        flex
        w-full
        items-center
        justify-center
        rounded-sm
        bg-white
        px-3
        py-2
      "
    >
      <p class="mb-0 break-words text-center text-base font-normal leading-5">{{ btn.text }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import { defineComponent } from 'vue';

import { TEMPLATE_HEADER_TYPE } from '@/Configs/Constants/whatsapp';
import { formatWhatsAppText } from '@/util/GlobalHelpers';

import type { TemplateButton } from '../types';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'TemplatePreview',
  props: {
    buttons: {
      type: Array as PropType<TemplateButton[]>,
      default: [],
    },
    selectedHeaderType: {
      type: String,
      default: 'text',
    },
    templateBody: {
      type: String,
      default: '',
    },
    templateHeader: {
      type: String,
      default: '',
    },
    templateFooter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentTime: moment().format('HH:mm'),
      timeIntervalId: null,
      TEMPLATE_HEADER_TYPE,
    };
  },
  mounted() {
    this.setCurrentTime();
  },
  computed: {
    imageSrc() {
      return this.templateHeader ? this.templateHeader : `${this.$root.assetsURL}img/wabusiness/template-image.svg`;
    },
    shouldShowMessage(): boolean {
      return Boolean(
        this.templateBody ||
          this.templateHeader ||
          this.templateFooter ||
          this.selectedHeaderType === TEMPLATE_HEADER_TYPE.IMAGE
      );
    },
    filteredButtons(): TemplateButton[] {
      return this.buttons.filter((btn: TemplateButton) => btn.text);
    },
  },
  methods: {
    formattedText(text: string): string {
      return formatWhatsAppText(text);
    },
    setCurrentTime(): void {
      this.timeIntervalId = setInterval(() => {
        this.currentTime = moment().format('HH:mm');
      }, 1000);
    },
  },
  beforeUnmount() {
    clearInterval(this.timeIntervalId);
  },
});
</script>

<style lang="scss" scoped src="./TemplatePreview.scss" />
