<template>
  <div class="font-italic valign-center w-full rounded border p-2 text-grey-600">
    <i class="material-icons text-md mx-2 text-base">timelapse</i>
    <template v-if="element.meta.send_typing">
      {{ $t('flowbot.step_presenter_typing_for_seconds', { delay_in_seconds: element.meta.delay_in_seconds }) }}
    </template>
    <template v-else>
      {{ $t('flowbot.step_presenter_waiting_for_seconds', { delay_in_seconds: element.meta.delay_in_seconds }) }}
    </template>
  </div>
</template>

<script>
export default {
  props: ['element'],
};
</script>
