import type { Tenant } from './api';
import type { EventPayload, TrialPayload } from './types';
import type { CompanyProfile } from '@/types/companyProfile';

type ProvideTokenToIframe = {
  companyProfile: CompanyProfile['subscription'];
  isTrial: boolean;
  tenant: Tenant['provider_id'];
  provider: Tenant['provider'];
};

export function provideToken(
  isTrial: ProvideTokenToIframe['isTrial'],
  tenantID: ProvideTokenToIframe['tenant'],
  provider: ProvideTokenToIframe['provider'],
): EventPayload {
  const trialPayload: TrialPayload = isTrial;
  const payload: EventPayload = {
    type: 'SEND_ORIGIN',
    payload: { token: tenantID, isTrial: trialPayload, provider },
  };
  return payload;
}
