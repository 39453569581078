<script type="text/babel">
import { map, toArray } from 'lodash';
import draggable from 'vuedraggable';

import articleSelector from './ArticleSelectorModal.vue';
import iconPicker from '../IconPicker.vue';

export default {
  name: 'HelpCenterCategory',
  components: {
    draggable,
    iconPicker,
    articleSelector,
  },

  props: {
    helpCenter: {
      type: Object,
      default: () => ({}),
    },
    localeCodes: {
      type: Object,
      default: () => ({}),
    },
    record: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      category: {},
      loaded: false,
      localeCode: this.helpCenter.default_language,
      title: '',
      dragging: false,
    };
  },

  async mounted() {
    this.loadCategory();

    if (await this.$tStorage.getItem('hc.selected_locale')) {
      this.localeCode = await this.$tStorage.getItem('hc.selected_locale');
    }
  },

  methods: {
    loadCategory() {
      axios
        .get('/api/v2/help_center/' + this.helpCenter.id + '/categories/' + this.$route.params.category_id)
        .then((res) => {
          this.category = res.data;
          this.loaded = true;

          this.category.sections.unshift({
            id: 0,
            articles: this.category.orphan_articles,
          });
        });
    },

    getTranslation(record) {
      var trans = record.translations?.filter((trans) => {
        return trans.locale_code == this.localeCode;
      })[0];

      if (trans == null) {
        trans = {
          locale_code: this.localeCode,
          title: '',
          description: '',
        };

        if (!record.translations) {
          record.translations = [];
        }

        record.translations.push(trans);
      }

      return trans;
    },

    getArticleTranslation(article) {
      var trans = article.translations?.filter((trans) => {
        return trans.locale_code == this.localeCode;
      })[0];

      if (trans == null) {
        trans = {
          locale_code: this.localeCode,
          title: '',
        };
      }

      return trans;
    },

    addSection() {
      axios
        .post(
          '/api/v2/help_center/' + this.helpCenter.id + '/categories/' + this.$route.params.category_id + '/sections'
        )
        .then((section) => {
          section.articles = [];
          this.category.sections.push(section);
          this.loadCategory();
        });
    },

    saveSectionTitle(section) {
      axios
        .put(
          '/api/v2/help_center/' +
            this.helpCenter.id +
            '/categories/' +
            this.$route.params.category_id +
            '/sections/' +
            section.id,
          {
            title: this.getTranslation(section).title,
            locale_code: this.localeCode,
          }
        )
        .then((res) => {
          this.loadCategory();
        });
    },

    getArticles(section_id) {
      return this.collection.articles?.filter((obj) => {
        return obj.id == section_id;
      });
    },

    setLanguage(lang) {
      this.localeCode = lang;
      this.$tStorage.setItem('hc.selected_locale', lang);
    },

    saveTitle() {
      this.save('title', this.getTranslation(this.category).title);
    },

    saveDescription() {
      this.save('description', this.getTranslation(this.category).description);
    },

    saveIcon() {
      this.save('icon', this.category.icon);
    },

    save(field, value) {
      axios.put('/api/v2/help_center/' + this.helpCenter.id + '/categories/' + this.$route.params.category_id, {
        field: field,
        value: value,
        locale_code: this.localeCode,
      });
    },

    saveSectionOrder() {
      axios.post(
        '/api/v2/help_center/' +
          this.helpCenter.id +
          '/categories/' +
          this.$route.params.category_id +
          '/sections/sort-order',
        {
          ids: toArray(
            map(
              this.category.sections.filter((s) => {
                return s.id != 0;
              }),
              'id'
            )
          ),
        }
      );
    },

    saveArticleOrder(e) {
      let section = this.getSectionByArticleId(e.item.getAttribute('data-article-id'));

      axios.post(
        '/api/v2/help_center/' +
          this.helpCenter.id +
          '/categories/' +
          this.$route.params.category_id +
          '/sections/' +
          section.id +
          '/sort-order',
        {
          ids: toArray(map(section.articles, 'id')),
          section_id: section.id,
        }
      );
    },

    deleteSection(id) {
      if (!confirm(this.$t('help_center.delete_section_confirmation'))) {
        return;
      }

      axios
        .delete(
          '/api/v2/help_center/' +
            this.helpCenter.id +
            '/categories/' +
            this.$route.params.category_id +
            '/sections/' +
            id,
          {}
        )
        .then(this.category.sections.splice(this.category.sections.map((item) => item.id).indexOf(id), 1));
    },

    detachArticle(id) {
      let section = this.getSectionByArticleId(id);
      if (confirm(this.$t('help_center.are_you_sure_you_want_to_detach_this_article'))) {
        axios.post('/api/v2/help_center/' + this.helpCenter.id + '/articles/' + id + '/detach');
        section.articles.splice(section.articles.map((item) => item.id).indexOf(id), 1);
      }
    },

    getSectionByArticleId(article_id) {
      return this.category.sections.filter((section) => {
        return (
          section.articles.filter((article) => {
            return article.id == article_id;
          }).length == 1
        );
      })[0];
    },

    showArticleSelector() {
      $('#ArticleSelectorModal').modal('show');
    },

    onArticlesSelected(e) {
      e.articles.map((article) => {
        this.category.sections[0].articles.push(article);
      });
      axios.post(
        '/api/v2/help_center/' +
          this.helpCenter.id +
          '/categories/' +
          this.$route.params.category_id +
          '/articles/attach',
        {
          article_ids: toArray(map(e.articles, 'id')),
        }
      );
    },

    async remove() {
      const shouldDeleteCategory = await this.$tConfirm(
        this.$t('help_center.are_you_sure_you_want_to_delete_this_category'),
        {
          delete: true,
          title: this.$t('help_center.are_you_sure'),
        }
      );
      if (shouldDeleteCategory) {
        axios
          .delete('/api/v2/help_center/' + this.helpCenter.id + '/categories/' + this.$route.params.category_id, {})
          .then(setTimeout(() => router.push('/help_center/' + this.helpCenter.id + '/categories'), 0));
      }
    },

    editArticle(article_id) {
      router.push('/help_center/' + this.helpCenter.id + '/articles/' + article_id);
    },

    sectionUrl(section) {
      return (
        this.record.url +
        '/' +
        this.localeCode +
        '/' +
        this.getTranslation(this.category).slug +
        '/' +
        this.getTranslation(section).slug
      );
    },
  },
};
</script>

<template>
  <div v-if="loaded">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis">
            {{ getTranslation(category).title }}
          </span>
          <div class="m-l-auto">
            <a class="btn white btn-md m-r-xs" @click="remove()">
              {{ $t('help_center.remove_category') }}
            </a>
            <a class="btn white btn-md m-r-xs" @click="addSection()">
              {{ $t('help_center.add_section') }}
            </a>
            <a class="btn btn-success btn-md text-white" @click="showArticleSelector()">
              {{ $t('help_center.insert_articles') }}
            </a>
          </div>
        </li>
      </ul>
    </div>
    <div class="p-t" style="margin: 0 auto; max-width: 900px">
      <div class="col-md-12">
        <div class="p-b-1 display-flex">
          <div style="width: 75px">
            <icon-picker v-model="category.icon" @change="saveIcon"></icon-picker>
          </div>
          <div style="width: calc(100% - 150px)">
            <input
              v-model="getTranslation(category).title"
              class="input-transparant display-block"
              :placeholder="$t('help_center.the_category_title')"
              style="width: 100%; font-size: 20px"
              @change="saveTitle"
            />
            <span class="text-muted">
              <input
                v-model="getTranslation(category).description"
                class="input-transparant display-block"
                :placeholder="$t('help_center.the_category_description')"
                style="width: 100%"
                @change="saveDescription"
              />
            </span>
          </div>
          <div class="dropdown m-l-auto m-t-sm">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">
              {{ localeCodes[localeCode].text }}
            </button>
            <div class="dropdown-menu">
              <a v-for="lang in helpCenter.languages" class="dropdown-item" @click="setLanguage(lang)">
                {{ localeCodes[lang].text }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <draggable
          v-model="category.sections"
          item-key="id"
          handle=".handle"
          :group="{ name: 'sections' }"
          @end="saveSectionOrder"
        >
          <template #item="{ element: section, index: sectionIndex }">
            <div
              :key="section.id"
              class="box section"
              :class="{ 'ghost-section': section.id == 0 }"
              :data-section-id="section.id"
            >
              <div v-if="section.id != 0" class="box-header b-b">
                <h3>
                  <i class="fa fa-reorder handle text-muted mr-2 cursor-grab"></i>
                  <strong>
                    <input
                      v-model="getTranslation(section).title"
                      type="text"
                      class="input-transparant"
                      :placeholder="$t('help_center.section_title')"
                      style="width: 80%"
                      @change="saveSectionTitle(section)"
                    />
                  </strong>
                </h3>
              </div>

              <div v-if="section.id != 0" class="box-tool">
                <ul class="nav">
                  <li v-if="getTranslation(section).slug != null" class="nav-item mr-1 inline">
                    <a :href="sectionUrl(section, sectionIndex)" target="_blank">
                      <i class="fa fa-external-link text-muted"></i>
                    </a>
                  </li>
                  <li class="nav-item inline">
                    <a @click="deleteSection(section.id)"><i class="fa fa-remove text-muted"></i></a>
                  </li>
                </ul>
              </div>

              <div class="box-body">
                <draggable
                  v-model="section.articles"
                  item-key="id"
                  handle=".handle"
                  :group="{ name: 'articles', put: true, pull: true }"
                  @end="saveArticleOrder"
                >
                  <template #item="{ element: article }">
                    <div :data-article-id="article.id">
                      <div
                        class="box article p-a-md m-b-1 display-flex align-center v-align list-item"
                        style="padding: 15px; min-height: 51px"
                      >
                        <i class="fa fa-reorder m-r-2 m-l-1 handle text-muted cursor-grab"></i>
                        <span class="cursor-pointer" @click="editArticle(article.id)">
                          <span v-if="getArticleTranslation(article).title != ''">
                            {{ getArticleTranslation(article).title }}
                          </span>
                          <span v-else class="text-muted">
                            {{ $t('help_center.untranslated_article') }} (#{{ article.id }}) -
                            <a class="text-info" @click="editArticle(article.id)">{{ $t('help_center.edit') }}</a>
                          </span>
                        </span>
                        <span class="m-l-auto">
                          <a @click="detachArticle(article.id)"><i class="fa fa-remove text-muted"></i></a>
                        </span>
                      </div>
                    </div>
                  </template>
                </draggable>
                <div
                  v-show="section.articles.length == 0"
                  class="article text-muted text-center"
                  style="border: 1px dashed #818a91; height: 51px; line-height: 51px"
                >
                  {{ $t('help_center.drag_here_to_add_articles_to_this_section') }}
                </div>
              </div>
            </div>
          </template>
        </draggable>
      </div>
    </div>

    <article-selector
      :help-center="helpCenter"
      :locale-codes="localeCodes"
      @articlesSelected="onArticlesSelected"
    ></article-selector>
  </div>
</template>
