<template>
  <div class="flex w-full flex-wrap items-center justify-between" :data-test="dataTestListRowItemChild">
    <p
      class="t-text-md-emphasize mb-0 mr-2.5 w-5 flex-1 truncate text-ellipsis text-left text-grey-800"
      data-test="item-name"
    >
      {{ title }}
    </p>
    <div class="flex items-center justify-between">
      <t-badge v-if="!isActive" :text="$t('general.list_label_inactive')" data-test="item-status" />

      <list-row-action-menu
        :id="id"
        class="pl-4"
        data-test-list-row-action-menu="item-action-menu"
        data-test-delete-button="item-delete-button"
        @handle-delete-item="$emit('handle-delete-item')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import ListRowActionMenu from '@/components/common/ListRow/ListRowActionMenu';

export default defineComponent({
  name: 'ListRowItemChild',
  emits: ['handle-delete-item'],
  components: { ListRowActionMenu },
  props: {
    id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    dataTestListRowItemChild: {
      type: String,
      default: 'list-row-item-child',
    },
  },
});
</script>
