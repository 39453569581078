import { defineStore } from 'pinia';
import { ref } from 'vue';

import { fetchAgency } from '@/api';

import type { Agency } from '@/types';

export const useAgencyStore = defineStore('agency', () => {
  const isPending = ref(false);
  const isInitialized = ref(false);
  const agency = ref<Agency | null>(null);

  const init = async () => {
    if (isInitialized.value) return;
    await loadAgency();
    isInitialized.value = true;
  };

  const loadAgency = async () => {
    isPending.value = true;
    try {
      const { data } = await fetchAgency();
      agency.value = data;
    } catch (err) {
      console.error(err);
    } finally {
      isPending.value = false;
    }
  };

  return {
    isInitialized,
    isPending,
    agency,
    init,
    reload: loadAgency,
  };
});
