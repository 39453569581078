import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-2 w-full rounded-xl border border-grey-300 bg-white p-6" }
const _hoisted_2 = { class: "flex flex-row items-center justify-between" }
const _hoisted_3 = { class: "flex flex-row items-center" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "t-text-h5 m-0 text-grey-800" }
const _hoisted_6 = {
  "data-test": "workload-management-chart",
  class: "relative mt-6 h-88"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chart_line_linear = _resolveComponent("chart-line-linear")!
  const _component_t_thumbnail = _resolveComponent("t-thumbnail")!
  const _component_workload_management_loaded = _resolveComponent("workload-management-loaded")!
  const _component_workload_management_loading = _resolveComponent("workload-management-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_t_thumbnail, { class: "mr-3 bg-white" }, {
          default: _withCtx(() => [
            _createVNode(_component_chart_line_linear, { size: "1.4rem" })
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('reports_v2.workload_management')), 1 /* TEXT */)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.isLoaded)
        ? (_openBlock(), _createBlock(_component_workload_management_loaded, {
            key: 0,
            "created-conversations": _ctx.createdConversations,
            "closed-conversations": _ctx.closedConversations,
            "show-error": _ctx.showError,
            interval: _ctx.filters.interval
          }, null, 8 /* PROPS */, ["created-conversations", "closed-conversations", "show-error", "interval"]))
        : (_openBlock(), _createBlock(_component_workload_management_loading, {
            key: 1,
            "created-conversations": _ctx.createdConversations,
            "closed-conversations": _ctx.closedConversations
          }, null, 8 /* PROPS */, ["created-conversations", "closed-conversations"]))
    ])
  ]))
}