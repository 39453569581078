<template>
  <div>
    <t-inline-banner v-if="isCurrentCycle && hasConversationInvoice" class="mt-2">
      <template #icon>
        <info-linear />
      </template>
      {{ $t('usage_overview.overview_section_conversation_quota_update_pending') }}
    </t-inline-banner>
    <usage-progress-card
      :spent-amount="standardConversations?.finished ?? 0"
      :quota="standardConversations?.quota ?? 0"
      :in-progress-amount="standardConversations?.inProgress ?? 0"
      format="number"
      :type="'standard_conversations'"
      :show-button="isCurrentCycle"
    >
      <template #icon>
        <t-thumbnail size="large">
          <comments-linear size="m" />
        </t-thumbnail>
      </template>
      <template #title>{{ $t('usage_overview.overview_section_standard_conversations_heading') }}</template>
      <template #info>
        <info-fill v-tooltip="{ content: $t('usage_overview.overview_section_conversations_tooltip') }" size="1rem" />
      </template>
      <template #button>
        <t-button
          size="sm"
          data-test="addConversationBlocksCTA"
          :disabled="hasConversationInvoice"
          btn-style="secondary"
          @click="openConversationBlocksModal"
        >
          {{ $t('usage_overview.overview_section_conversations_cta') }}
        </t-button>
      </template>
    </usage-progress-card>
    <separator />

    <template v-if="isAutomedConversationsEnabled">
      <usage-progress-card
        :spent-amount="intelligentConversations?.finished ?? 0"
        :quota="intelligentConversations?.quota ?? 0"
        :in-progress-amount="intelligentConversations?.inProgress ?? 0"
        format="number"
        :type="'intelligent_conversations'"
        :show-button="!isNearingTheEndOfBillingCycle && isCurrentCycle"
      >
        <template #icon>
          <t-thumbnail size="large">
            <comments-linear size="m" />
          </t-thumbnail>
        </template>
        <template #title>{{ $t('usage_overview.overview_section_intelligent_conversations_heading') }}</template>
        <template #info>
          <info-fill v-tooltip="{ content: $t('usage_overview.overview_section_conversations_tooltip') }" size="1rem" />
        </template>
        <template #button>
          <t-button-text @click="() => $router.push({ name: 'subscription' })">
            {{ $t('usage_overview.overview_section_conversations_cta') }}
            <arrow-right-linear size="1.5rem" />
          </t-button-text>
        </template>
      </usage-progress-card>
      <separator />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { ArrowRightLinear, CommentsLinear, InfoFill } from '@trengo/trengo-icons';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { useQueryParameterOpenState } from '@/composables/useQueryParameterOpenState';
import { CONVERSATION_TYPE } from '@/Configs/Constants';
import { useBillingStore } from '@/store/pinia';

import Separator from './Separator.vue';
import UsageProgressCard from './UsageProgressCard.vue';
import { useUsageOverviewStore } from '../store';

import type { ConversationType } from '@/types';

const { handleOpen: openConversationBlocksModal } = useQueryParameterOpenState('addConversationBlocksModal');
const { isTrial, isCurrentCycle, selectedBreakdown } = storeToRefs(useUsageOverviewStore());
const { hasConversationInvoice } = storeToRefs(useBillingStore());

const isAutomedConversationsEnabled = false; // intelligent conversations will be enabled when it's calculated

const computeConversations = (type: ConversationType) =>
  computed(() => {
    const conversation = (selectedBreakdown.value?.conversations || []).find(
      (conversation) => conversation.type === type
    );

    if (!isTrial.value) {
      return conversation;
    }

    return { ...conversation, quota: conversation?.finished || 0 };
  });

const standardConversations = computeConversations(CONVERSATION_TYPE.STANDARD);
const intelligentConversations = computeConversations(CONVERSATION_TYPE.INTELLIGENT);

const isNearingTheEndOfBillingCycle = computed(() => {
  const currentTermTotal = selectedBreakdown.value?.endDate - selectedBreakdown.value?.startDate;
  const currentTermUsed = Date.now() - selectedBreakdown.value?.startDate;
  const percentageUsed = (currentTermUsed / currentTermTotal) * 100;
  return percentageUsed > 80;
});
</script>
