<template>
  <div class="w-full">
    <users-list
      v-if="loaded"
      :list="list"
      :loading="loading"
      :infinite-loading-reset-counter="infiniteLoadingResetCounter"
      @infinite-scroll="onInfinite"
      @update="loadCustomFieldList"
    />
  </div>
</template>

<script lang="ts">
import { getPaginatedUserList } from '@/components/Users/api';
import { useSubscriptionStore } from '@/store/pinia';

import UsersList from '../UsersList';

const USER_LIST_PAGE_SIZE = 25;

export default {
  name: 'UsersPage',
  components: { UsersList },
  data() {
    return {
      list: [],
      loaded: false,
      page: 1,
      loading: false,
      infiniteLoadingResetCounter: 0,
    };
  },
  mounted() {
    useSubscriptionStore().init();
    this.getPaginatedData();
  },
  methods: {
    getPaginatedData(reset = false) {
      getPaginatedUserList({ sort: 'first_name' })
        .then(({ data }) => {
          if (reset) {
            this.page = 1;
            this.infiniteLoadingResetCounter++;
          }
          this.list = data?.data;
          this.loaded = true;
        })
        .catch((e) => {
          console.error('Error while fetching users: ', e);
        });
    },
    loadCustomFieldList() {
      this.getPaginatedData(true);
    },
    onInfinite(scroll) {
      if (this.list.length < USER_LIST_PAGE_SIZE) {
        return;
      }
      if (this.page === 1) {
        this.page++;
      }
      this.loading = true;
      getPaginatedUserList({ sort: 'first_name', page: this.page })
        .then(({ data }) => {
          if (data?.data?.length > 0) {
            this.list = [...this.list, ...data.data];
            this.page++;
            scroll.loaded();
          } else {
            scroll.complete();
          }
        })
        .catch((e) => {
          console.error('Error while fetching users: ', e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
