<template>
  <t-card class="break-words bg-white" data-test="integration-requirement-card-wrapper">
    <div v-if="metadata.requirements && metadata.requirements.length > 0" class="mb-6">
      <h3 class="t-text-sm-emphasize text-grey-800">{{ $tc('integration_hub.requirement_header') }}</h3>

      <p v-for="requirement in metadata.requirements" class="m-0 text-sm text-grey-700">
        {{ $tc(requirement) }}
      </p>
    </div>

    <div v-if="metadata.support && metadata.support.length > 0">
      <h3 class="t-text-sm-emphasize text-grey-800">{{ $tc('integration_hub.support_header') }}</h3>
      <a
        v-for="support in metadata.support"
        :key="support.url"
        class="flex items-center text-grey-700 underline hover:text-leaf-500 hover:underline"
        target="_blank"
        rel="noopener"
        :href="support.url"
      >
        {{ support.text }}
        <external-link-linear class="ml-1 font-bold" />
      </a>
    </div>
  </t-card>
</template>

<script lang="ts">
import { ExternalLinkLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import type { IntegrationMetadata } from '@/store/types/integrations';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'MetadataCard',

  components: { ExternalLinkLinear },

  props: {
    metadata: {
      type: Object as PropType<IntegrationMetadata>,
      default: () => ({}),
    },
  },
});
</script>
