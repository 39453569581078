import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "t-text-md mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_pin_input = _resolveComponent("t-pin-input")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_modal = _resolveComponent("t-modal")!

  return (_openBlock(), _createBlock(_component_t_modal, {
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isOpen) = $event)),
    title: _ctx.$t('auth.two_factor_authentication_setup_step_two_title'),
    "data-test": "two-factor-authentication-setup-step-two",
    variant: "narrow",
    onClose: _ctx.handleClose
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_t_button, {
        size: "md",
        "btn-style": "secondary",
        class: "md:mr-auto",
        onClick: _ctx.handleBack
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('general.back')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"]),
      _createVNode(_component_t_button, {
        size: "md",
        "btn-style": "secondary",
        onClick: _ctx.handleClose
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('general.cancel')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"]),
      _createVNode(_component_t_button, {
        size: "md",
        disabled: _ctx.isVerifying || _ctx.isInvalid,
        onClick: _ctx.handleContinue
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.isVerifying
            ? _ctx.$t('auth.two_factor_authentication_setup_step_two_is_verifying_label')
            : _ctx.$t('auth.two_factor_authentication_setup_step_two_verify_label')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('auth.two_factor_authentication_setup_step_two_description')), 1 /* TEXT */),
      _createVNode(_component_t_pin_input, {
        value: "",
        size: 6,
        "error-message": _ctx.validationMessage,
        disabled: _ctx.isVerifying,
        autofocus: "",
        class: "mb-6",
        onComplete: _ctx.handleSubmit,
        onInput: _ctx.storeValue
      }, null, 8 /* PROPS */, ["error-message", "disabled", "onComplete", "onInput"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title", "onClose"]))
}