<script type="text/babel">
import { map, toArray } from 'lodash';
import { VueDraggableNext } from 'vue-draggable-next';

export default {
  components: {
    draggable: VueDraggableNext,
  },
  props: {
    helpCenter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      blocks: [],
      loading: false,
      saving: false,
      localeCode: this.helpCenter.default_language,
    };
  },
  mounted() {
    this.loading = true;
    axios.get('/api/v2/help_center/' + this.$route.params.id + '/blocks').then((r) => {
      this.blocks = r.data;
      this.loading = false;
    });
  },
  methods: {
    addBlock() {
      this.saving = true;
      axios.post('/api/v2/help_center/' + this.$route.params.id + '/blocks', {}).then((r) => {
        this.saving = false;
        router.push('/help_center/' + this.$route.params.id + '/blocks/' + r.data.id);
      });
    },
    getTranslation(record) {
      let trans = record.translations.filter((trans) => {
        return trans.locale_code === this.localeCode;
      })[0];
      if (trans == null) {
        trans = {
          title: this.$t('help_center.untitled'),
          description: '',
          external_url: '',
        };
      }
      return trans;
    },
    saveOrder() {
      axios.put('/api/v2/help_center/' + this.$route.params.id + '/blocks/sort-order', {
        ids: toArray(map(this.blocks, 'id')),
      });
    },
  },
};
</script>

<template>
  <div v-if="!loading">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis">
            {{ $t('help_center.blocks') }}
            <small class="text-muted">({{ blocks.length }})</small>
          </span>
          <div class="m-l-auto">
            <a class="btn btn-success btn-md text-white" @click="addBlock()">
              <span v-show="saving"><i class="fa fa-spinner fa-spin"></i></span>
              <span v-show="!saving">{{ $t('help_center.new_block') }}</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
    <div class="p-a" style="margin: 0 auto; max-width: 900px">
      <div v-if="!blocks.length" class="m-t-3 text-center">
        <div>
          <i class="fa fa-file-text-o text-muted" style="font-size: 60px"></i>
          <h6 class="m-t-1 m-b-1">{{ $t('help_center.add_your_first_block_to_this_help_center') }}</h6>
          <button class="btn btn-success text-white" @click="addBlock()">
            <span v-show="saving"><i class="fa fa-spinner fa-spin"></i></span>
            <span v-show="!saving">{{ $t('help_center.new_block') }}</span>
          </button>
        </div>
      </div>
      <draggable
        v-model="blocks"
        handle=".handle"
        :options="{ draggable: '.list-item' }"
        item-key="id"
        @end="saveOrder"
      >
        <div
          v-for="block in blocks"
          :key="block.id"
          class="box p-a-md m-b-1 display-flex align-center v-align list-item"
          style="padding: 15px"
        >
          <i class="fa fa-reorder m-r-2 m-l-1 handle text-muted cursor-grab"></i>
          <i class="material-icons m-r-2 text-lg" style="font-size: 30px" v-text="block.icon"></i>
          <span class="text-truncate p-r-1">
            <strong>{{ getTranslation(block).title }}</strong>
            <br />
            {{ getTranslation(block).description }}
          </span>
          <span class="m-l-auto" style="min-width: 100px">
            <router-link class="text-info" :to="'/help_center/' + helpCenter.id + '/blocks/' + block.id">
              {{ $t('help_center.edit') }}
            </router-link>
          </span>
        </div>
      </draggable>
    </div>
  </div>
</template>
