import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "list-none" }
const _hoisted_2 = { class: "filter-dropdown-second-layer absolute z-50 mt-2 w-64 rounded-xl border border-grey-300 bg-white pb-2 pt-2 shadow-500" }
const _hoisted_3 = { class: "max-h-[335px] w-full px-3 pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_caret_down_fill = _resolveComponent("caret-down-fill")!
  const _component_t_list_item = _resolveComponent("t-list-item")!
  const _component_t_input_radio = _resolveComponent("t-input-radio")!
  const _component_t_popover = _resolveComponent("t-popover")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_component_t_popover, {
      modelValue: _ctx.isOpen,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isOpen) = $event)),
      class: "relative",
      "click-outside-close": ""
    }, {
      trigger: _withCtx(() => [
        _createVNode(_component_t_list_item, {
          label: _ctx.$t('reports_v2.business_hours'),
          class: "relative",
          active: _ctx.isOpen
        }, {
          postfix: _withCtx(() => [
            _createVNode(_component_caret_down_fill, {
              size: "1.25rem",
              class: "mt-1 -rotate-90 text-grey-600"
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label", "active"])
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            _createVNode(_component_t_input_radio, {
              modelValue: _ctx.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
              name: "business-hours",
              value: true,
              onChange: _ctx.handleSelectItem
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('reports_v2.including_business_hours')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "onChange"]),
            _createVNode(_component_t_input_radio, {
              modelValue: _ctx.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
              name: "business-hours",
              value: false,
              onChange: _ctx.handleSelectItem
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('reports_v2.excluding_business_hours')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "onChange"])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}