<template>
  <div class="mb-8 flex flex-row flex-wrap justify-between gap-y-4">
    <div class="mr-4 flex flex-row">
      <div class="report-card flex flex-col justify-center" :class="csatResults">
        <p class="report-card-title mb-1">
          {{ $t('csat.csat') }}
        </p>
        <p class="report-card-value mb-1">{{ aggregates.csats }}%</p>
      </div>
      <div class="report-card ml-4 flex flex-col justify-center">
        <p class="report-card-title mb-1">
          {{ $t('csat.response_rate') }}
        </p>
        <p class="report-card-value mb-1">{{ aggregates.response_rate }}%</p>
      </div>
    </div>
    <div class="flex flex-row">
      <div class="report-card report-card-clickable small flex flex-col justify-center" @click="toggleAmountResponses">
        <p class="report-card-title mb-1">
          {{ $t('csat.positive_responses') }}
        </p>
        <div class="flex flex-row content-center">
          <img :src="`${$root.assetsURL}img/csat/positive_emoji.svg`" class="report-image" />
          <p v-if="showAmountResponses" class="report-card-value mb-1 ml-2">
            {{ aggregates.positive_responses }}
          </p>
          <p v-if="!showAmountResponses" class="report-card-value mb-1 ml-2">
            {{ percentageResponses(aggregates.positive_responses) }}%
          </p>
        </div>
      </div>
      <div
        class="report-card report-card-clickable small ml-4 flex flex-col justify-center"
        @click="toggleAmountResponses"
      >
        <p class="report-card-title mb-1">
          {{ $t('csat.neutral_responses') }}
        </p>
        <div class="flex flex-row content-center">
          <img :src="`${$root.assetsURL}img/csat/neutral_emoji.svg`" class="report-image" />
          <p v-if="showAmountResponses" class="report-card-value mb-1 ml-2">
            {{ aggregates.neutral_responses }}
          </p>
          <p v-if="!showAmountResponses" class="report-card-value mb-1 ml-2">
            {{ percentageResponses(aggregates.neutral_responses) }}%
          </p>
        </div>
      </div>
      <div
        class="report-card report-card-clickable small ml-4 flex flex-col justify-center"
        @click="toggleAmountResponses"
      >
        <p class="report-card-title mb-1">
          {{ $t('csat.negative_responses') }}
        </p>
        <div class="flex flex-row content-center">
          <img :src="`${$root.assetsURL}img/csat/negative_emoji.svg`" class="report-image" />
          <p v-if="showAmountResponses" class="report-card-value mb-1 ml-2">
            {{ aggregates.negative_responses }}
          </p>
          <p v-if="!showAmountResponses" class="report-card-value mb-1 ml-2">
            {{ percentageResponses(aggregates.negative_responses) }}%
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    aggregates: {},
  },
  data() {
    return {
      showAmountResponses: true,
    };
  },
  computed: {
    totalResponses() {
      return (
        this.aggregates.positive_responses + this.aggregates.neutral_responses + this.aggregates.negative_responses
      );
    },
    csatResults() {
      if (this.aggregates.csats <= 40) {
        return 'dissatisfied';
      } else if (this.aggregates.csats > 40 && this.aggregates.csats < 60) {
        return 'neutral';
      } else return 'satisfied';
    },
  },
  methods: {
    percentageResponses(responses) {
      return Math.round(((responses + Number.EPSILON) * 10000) / this.totalResponses) / 100;
    },
    toggleAmountResponses() {
      this.showAmountResponses = !this.showAmountResponses;
    },
  },
};
</script>

<style scoped lang="scss">
.report-card {
  width: 192px;
  height: 99px;
  border: 1px solid var(--color-grey-300);
  border-radius: 12px;
  padding: 10px 0 0 12px;
  background-color: var(--color-white);
  &.small {
    width: 157px;
  }
  &.report-card-clickable {
    transition: box-shadow 0.2s ease-in-out;
    &:hover {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03), 0 2px 5px rgba(0, 0, 0, 0.11);
    }
    cursor: pointer;
  }
  .report-card-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--color-grey-500);
  }
  .report-card-value {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: var(--color-grey-800);
  }
  .report-image {
    width: 16px;
    height: 30px;
  }
  &.dissatisfied {
    border-color: var(--color-error-500);
  }
  &.neutral {
    border-color: var(--color-sun-500);
  }
  &.satisfied {
    border-color: var(--color-success-500);
  }
}
</style>
