export default {
  WHATSAPP: 'fab fa-whatsapp',
  WA_BUSINESS: 'fab fa-whatsapp',
  EMAIL: 'far fa-envelope',
  FACEBOOK: 'fab fa-facebook',
  TELEGRAM: 'fab fa-telegram',
  CHAT: 'far fa-comments',
  VOIP: 'far fa-phone',
  TWITTER: 'fab fa-twitter',
  SMS: 'far fa-sms',
  ZIPWHIP: 'far fa-comments',
  INSTAGRAM: 'fab fa-instagram',
  CUSTOM: 'far fa-external-link',
  PLAYGROUND: 'far fa-play-circle',
  GBM: 'fab fa-google',
};
