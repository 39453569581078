import { request } from '@/util';

const baseurl = '/api/v2/email/sender_domains/';
/**
 *
 * @param id
 * @param test
 * @returns {*}
 */
export function getSenderDomains(id, test = false) {
  let url = baseurl;
  if (id) {
    url += id;
  }
  if (test) {
    url += '?test=true';
  }
  return request(url, 'GET');
}

/**
 *
 * @param id
 * @returns {*}
 */
export function removeSenderDomain(id) {
  const url = baseurl + id;
  return request(url, 'DELETE');
}

/**
 *
 * @param data
 * @returns {*}
 */
export function addSenderDomain(data) {
  return request(baseurl, 'POST', data);
}

/**
 *
 * @param data
 * @param id
 * @returns {*}
 */
export function sendSenderDomainEmail(data, id) {
  return request(`${baseurl}${id}/email`, 'POST', data);
}
