import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full w-full" }
const _hoisted_2 = { class: "-mt-20 flex h-full w-full flex-col items-center justify-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "t-text-h3 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_left_linear = _resolveComponent("arrow-left-linear")!
  const _component_t_button = _resolveComponent("t-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: `${_ctx.assetsUrl}img/reporting/desktop.svg`,
        class: "mb-6"
      }, null, 8 /* PROPS */, _hoisted_3),
      _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t('reports.dashboard_mobile')), 1 /* TEXT */),
      _createVNode(_component_t_button, {
        "btn-style": "secondary",
        class: "mt-2 flex items-center justify-center",
        onClick: _withModifiers(_ctx.goToPreviousRoute, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_arrow_left_linear, { class: "mr-1" }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('reports.back_overview')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ])
  ]))
}