<template>
  <div
    v-click-outside="hide"
    class="focus:ring-offset-gray-100 focus:ring-indigo-500 relative inline-block w-full text-left sm:w-132"
  >
    <div class="relative mb-3 flex w-full flex-wrap items-stretch">
      <atomic-input
        ref="dropdown"
        v-model="computedValue"
        type="email"
        :class="{ 'shadow-md': show }"
        :value="computedValue ? computedValue : null"
        :placeholder="computedPlaceholder"
        :disabled="disabled"
        class="
          t-search-dropdown
          relative
          flex flex-1 flex-shrink flex-grow
          cursor-pointer
          select-none
          items-center
          justify-between
          self-center
          rounded-xl
          border border-grey-200
          bg-white
          py-1
          text-sm
          font-medium
          leading-normal
          outline-none
          transition-all
          hover:border-grey-400
          focus:outline-none
        "
        @click="showDropdown"
      />
      <span
        class="
          absolute
          right-0
          z-10
          mr-2
          flex
          h-full
          w-8
          items-center
          justify-center
          rounded
          bg-transparent
          pr-4
          text-center text-base
          font-normal
          leading-snug
        "
        :class="{ 'cursor-pointer': !disabled }"
        @click="toggleDropdown"
      >
        <span class="material-icons" :class="[disabled ? 'text-grey-500' : 'text-grey-800']">expand_more</span>
      </span>
    </div>

    <transition
      enter-active-class="duration-100 ease-out"
      enter-class="-translate-x-full opacity-0"
      enter-to-class="translate-x-0 opacity-100"
      leave-active-class="duration-100 ease-in"
      leave-class="translate-x-0 opacity-100"
      leave-to-class="-translate-x-full opacity-0"
    >
      <div v-if="show" class="overflow-x-auto">
        <div
          class="
            absolute
            right-0
            z-50
            mt-0
            w-full
            origin-top-right
            select-none
            rounded-xl
            bg-white
            shadow-lg
            ring-1 ring-black ring-opacity-5
            focus:outline-none
          "
        >
          <slot name="start" />
          <div v-if="options.length" :style="{ 'max-height': this.height + 'px', overflow: 'auto' }" class="my-3 py-1">
            <div v-for="item in options" :key="item.id">
              <div @click="hide">
                <slot :item="item" name="item" />
              </div>
            </div>
          </div>
          <div v-if="!searchStarts && !options.length" class="m-4 cursor-pointer text-grey-500">
            <span class="text-base font-medium">
              {{ $t('email_settings.there_are_no_existing_domains_start_by_adding_a_new_email') }}
            </span>
          </div>
          <slot name="end" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SearchDropdown',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String,
      required: true,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchStarts: false,
      show: false,
      height: 170,
    };
  },
  watch: {
    modelValue(newValue) {
      this.computedValue = newValue;
    },
  },
  methods: {
    startSearch(value) {
      this.searchStarts = !!value && !this.options.length;
    },
    callback(value) {
      this.computedValue = value.value;
    },
    showDropdown() {
      this.show = true;
    },
    toggleDropdown() {
      this.show = this.disabled ? false : !this.show;
    },
    hide() {
      this.show = false;
    },
  },
  computed: {
    computedPlaceholder() {
      return this.placeholder.length > 0 ? this.placeholder : this.$t('email_settings.no_email');
    },
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.startSearch(value);

        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
