<template>
  <t-table-list-row data-test="ticket-inisight-table-row">
    <t-table-list-cell class="max-w-[320px] break-words pr-4">
      <div class="t-text-paragraph-sm m-0 max-w-[320px] text-grey-600">
        <div class="flex flex-row items-center">
          <t-avatar v-if="availableData.agent.profile_image" :image="availableData.agent.profile_image" width="32" />
          <img v-else :src="getUnassignedImage" width="32px" />
          <p class="t-text-desktop-paragraph-sm m-0 ml-2 w-full truncate text-grey-800">
            {{ availableData.agent.full_name || $t('reports_v2.unassigned') }}
          </p>
        </div>
      </div>
    </t-table-list-cell>
    <t-table-list-cell v-if="withinBusinessHours" class="max-w-[320px] break-words pr-4">
      <ticket-insight-time-cell :time="availableData?.average_first_resolution" />
    </t-table-list-cell>
    <t-table-list-cell v-if="!withinBusinessHours" class="max-w-[320px] break-words pr-4">
      <ticket-insight-time-cell :time="availableData?.average_first_resolution_without_business_hours" />
    </t-table-list-cell>
    <t-table-list-cell v-if="withinBusinessHours" class="max-w-[320px] pr-4">
      <ticket-insight-time-cell :time="availableData?.average_total_resolution" />
    </t-table-list-cell>
    <t-table-list-cell v-if="!withinBusinessHours" class="max-w-[320px] pr-4">
      <ticket-insight-time-cell :time="availableData?.average_total_resolution_without_business_hours" />
    </t-table-list-cell>
    <t-table-list-cell v-if="withinBusinessHours" class="max-w-[320px] pr-4">
      <ticket-insight-time-cell :time="availableData?.average_first_response" />
    </t-table-list-cell>
    <t-table-list-cell v-if="!withinBusinessHours" class="max-w-[320px] pr-4">
      <ticket-insight-time-cell :time="availableData?.average_first_response_without_business_hours" />
    </t-table-list-cell>
    <t-table-list-cell class="max-w-[320px] pr-4">
      <p class="t-text-sm m-0 text-right text-grey-600">{{ availableData.total_assigned || 0 }}</p>
    </t-table-list-cell>
    <t-table-list-cell class="max-w-[320px] break-words pl-4">
      <p class="t-text-sm m-0 text-right text-grey-600">{{ availableData.total_outbound || 0 }}</p>
    </t-table-list-cell>
    <t-table-list-cell class="max-w-[320px] break-words pl-4">
      <p class="t-text-sm m-0 text-right text-grey-600">{{ availableData.total_closed || 0 }}</p>
    </t-table-list-cell>
    <t-table-list-cell class="max-w-[320px] break-words pl-4">
      <p class="t-text-sm m-0 text-right text-grey-600">{{ availableData.total_note || 0 }}</p>
    </t-table-list-cell>
  </t-table-list-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Unassigned from './assets/unassigned.svg';
import TicketInsightTimeCell from './TicketInsightTimeCell.vue';

import type { Agent } from './utils';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'TicketInsightRow',
  components: { TicketInsightTimeCell },
  props: {
    availableData: {
      type: Object as PropType<Agent>,
      default: {} as Agent,
    },
    withinBusinessHours: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getUnassignedImage() {
      return Unassigned;
    },
    getStatusVariant() {
      if (this.availableData.ticket_status === 'ASSIGNED') return 'info';
      if (this.availableData.ticket_status === 'OPEN') return 'warning';
      return 'default';
    },
  },
});
</script>
