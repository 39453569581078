import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3730e4a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "channels-list__wrapper" }
const _hoisted_2 = { class: "channels-list__wrapper__inner" }
const _hoisted_3 = { class: "channels-list-head" }
const _hoisted_4 = { class: "t-text-sm-emphasize text-grey-600" }
const _hoisted_5 = { class: "mt-2 text-grey-800" }
const _hoisted_6 = { class: "t-text-h2 mb-0 max-w-sm" }
const _hoisted_7 = { class: "t-text-desktop-paragraph-md mt-2 text-grey-700" }
const _hoisted_8 = { class: "mb-0" }
const _hoisted_9 = { class: "channels-list-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_channels_accordion = _resolveComponent("channels-accordion")!
  const _component_channel_confirmation_modal = _resolveComponent("channel-confirmation-modal")!
  const _component_create_wa_bsp_modal = _resolveComponent("create-wa-bsp-modal")!
  const _component_channel_web_chat_sand_box_modal = _resolveComponent("channel-web-chat-sand-box-modal")!
  const _component_arrow_right_linear = _resolveComponent("arrow-right-linear")!
  const _component_t_button_text = _resolveComponent("t-button-text")!
  const _component_channel_whatsapp_sandbox_modal = _resolveComponent("channel-whatsapp-sandbox-modal")!
  const _component_onboarding_skip_modal = _resolveComponent("onboarding-skip-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('onboarding.channels_step2_of_2')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('onboarding.channels_bring_your_customer_conversations')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('onboarding.channels_select_channel_to_connect')), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.channels_groups, (item) => {
          return (_openBlock(), _createBlock(_component_channels_accordion, {
            key: item.channel,
            open: $data.open[item.channel],
            item: item,
            onItemClick: $options.handleItemClick,
            onChange: $options.handleAccordionChange
          }, null, 8 /* PROPS */, ["open", "item", "onItemClick", "onChange"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createVNode(_component_channel_confirmation_modal, {
        channel_type: $data.channel_type,
        open: $data.open_confirmation_modal,
        channel: $data.channel_item,
        onClose: $options.handleClose,
        onCreate: $options.handleCreateChannel
      }, null, 8 /* PROPS */, ["channel_type", "open", "channel", "onClose", "onCreate"]),
      _createVNode(_component_create_wa_bsp_modal, {
        modelValue: $data.showWaBspModal,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.showWaBspModal) = $event)),
        onCreated: $options.handleWhatsappChannelCreated
      }, null, 8 /* PROPS */, ["modelValue", "onCreated"]),
      _createVNode(_component_channel_web_chat_sand_box_modal, {
        show: $data.open_webchat_sandbox,
        "show-buttons": false,
        onClose: $options.handleCloseChannelWebChatSandBoxModal,
        onCompleted: $options.handleWebChatSandBoxCompleted
      }, null, 8 /* PROPS */, ["show", "onClose", "onCompleted"]),
      _createVNode(_component_t_button_text, {
        "btn-style": "secondary",
        class: "channels-list__skipped-button",
        onClick: $options.handleModalOpen
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('onboarding.skip_popup_title')) + " ", 1 /* TEXT */),
          _createVNode(_component_arrow_right_linear, { size: "1.5rem" })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ]),
    _createVNode(_component_channel_whatsapp_sandbox_modal, {
      modelValue: $data.show_whatsapp_sandbox,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.show_whatsapp_sandbox) = $event)),
      onCompleted: $options.handleWhatsappSandBoxProcessCompleted,
      onOpen: _cache[2] || (_cache[2] = ($event: any) => ($data.show_whatsapp_sandbox = true)),
      onClose: _cache[3] || (_cache[3] = ($event: any) => ($data.show_whatsapp_sandbox = false))
    }, null, 8 /* PROPS */, ["modelValue", "onCompleted"]),
    _createVNode(_component_onboarding_skip_modal, {
      "is-open": $data.isModalOpen,
      onClose: $options.handleModalClose
    }, null, 8 /* PROPS */, ["is-open", "onClose"])
  ]))
}