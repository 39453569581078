<template>
  <div
    class="
      filter-dropdown-not-found
      mb-5
      ml-auto
      mr-auto
      mt-5
      flex
      min-h-[6.25rem]
      flex-col
      items-center
      justify-center
      text-center
    "
  >
    <t-thumbnail>
      <slash-linear size="1.25rem" />
    </t-thumbnail>
    <p class="t-text-sm mb-0 mt-2">{{ text }}</p>
  </div>
</template>

<script lang="ts">
import { SlashLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ResultsNotFound',
  components: { SlashLinear },
  props: {
    text: {
      type: String,
      default: 'No results found',
    },
  },
});
</script>

<style lang="scss" scoped>
.filter-dropdown-not-found {
  max-width: 16.125rem;
}
</style>
