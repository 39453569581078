<template>
  <div class="relative border-t border-grey-300 border-opacity-30 p-3" data-test="customise-sidebar-items-wrapper">
    <button
      class="
        t-text-sm-emphasize
        flex
        w-full
        flex-row
        items-center
        rounded-md
        p-2
        text-grey-800
        transition-colors
        hover:bg-grey-200
        active:bg-grey-300
      "
      :class="{ 'bg-grey-300': isToggleOpened }"
      data-test="customise-sidebar-toggle-button"
      @click.prevent="isToggleOpened = !isToggleOpened"
    >
      <gear2-linear class="mr-2 text-grey-800" size="1.3rem" />
      {{
        itemTab === 'contact' ? $tc('ticket_sidebar.customise_contacts') : $tc('ticket_sidebar.customise_integrations')
      }}
    </button>

    <sidebar-item-toggle-list
      v-if="isToggleOpened"
      :label="label"
      :items="items"
      @toggle-item="emitToggle"
      @close-list="isToggleOpened = false"
    />
  </div>
</template>

<script lang="ts">
import { Gear2Linear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import SidebarItemToggleList from '@/components/TicketSidebar/SidebarItemToggleList.vue';

import type { SidebarItem } from '@/components/TicketSidebar/Index.vue';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'CustomiseSidebarItems',
  emits: ['item-toggled'],
  components: { SidebarItemToggleList, Gear2Linear },

  props: {
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemTab: {
      type: String as PropType<'contact' | 'integrations'>,
      default: 'contact',
    },
  },

  data() {
    return {
      isToggleOpened: false,
    };
  },

  methods: {
    closeToggleOverlay(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        this.isToggleOpened = false;
      }
    },

    emitToggle(itemName: SidebarItem['name'], itemVisibility: SidebarItem['visible']) {
      this.$emit('item-toggled', itemName, itemVisibility);
    },
  },

  mounted() {
    document.addEventListener('keydown', this.closeToggleOverlay);
  },

  beforeUnmount() {
    this.isToggleOpened = false;
    document.removeEventListener('keydown', this.closeToggleOverlay);
  },
});
</script>
