<template>
  <get-started-card is-done>
    <template #title>{{ $t('getting_started.the_first_steps') }}</template>
    <template #description>{{ $t('getting_started.create_your_trengo_account') }}</template>
    <template #icon>
      <img class="w-48px rounded-xl" :src="`${$root.assetsURL}img/get_started_page/trengo.svg`" />
    </template>
  </get-started-card>
</template>

<script>
import GetStartedCard from '../GetStartedCard';

export default {
  name: 'FirstSteps',
  components: { GetStartedCard },
};
</script>
