<template>
  <li class="list-none">
    <t-popover v-model="isOpen" class="relative" click-outside-close>
      <template #trigger>
        <t-list-item :label="label" class="relative" :active="isOpen">
          <template #postfix>
            <t-badge v-if="hasBadge" class="flex items-center" text="1" />
            <caret-down-fill size="1.25rem" class="mt-1 -rotate-90 text-grey-600" />
          </template>
        </t-list-item>
      </template>

      <template #content>
        <div
          :class="containerHeight"
          class="
            filter-dropdown-second-layer-calendar
            absolute
            z-50
            mt-2
            w-96
            overflow-hidden
            rounded-xl
            border border-grey-300
            bg-white
            shadow-500
          "
        >
          <date-picker v-model="date" @selection="setDate"></date-picker>
        </div>
      </template>
    </t-popover>
  </li>
</template>

<script lang="ts">
import { CaretDownFill } from '@trengo/trengo-icons';
import moment from 'moment';
import { defineComponent } from 'vue';

import breakpoints from '@/util/breakpoints';

import DatePicker from './DatePicker/DatePicker.vue';
import { normalizeDate } from './utils';

import type { PropType } from 'vue';
import type { TranslateResult } from 'vue-i18n';

type Data = {
  isOpen: boolean;
  date: {
    startDate: Date | null;
    endDate: Date | null;
  };
};

export default defineComponent({
  name: 'DateFilter',
  emits: ['filter-updated'],
  components: { DatePicker, CaretDownFill },
  data(): Data {
    return {
      breakpoints,
      isOpen: false,
      date: {
        startDate: null,
        endDate: null,
      },
    };
  },
  props: {
    label: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
    type: {
      type: String,
      default: 'assigned',
    },
    activeDates: {
      type: Object,
      default: () => ({ start: '', end: '' }),
    },
  },
  mounted() {
    this.date.startDate = this.activeDates.start ? normalizeDate(this.activeDates.start) : null;
    this.date.endDate = this.activeDates.end ? normalizeDate(this.activeDates.end) : null;
  },
  computed: {
    containerHeight() {
      const heightClass = breakpoints.h < 750 ? 'filter-date-items-wrapper-350' : 'filter-date-items-wrapper-auto';
      return heightClass;
    },
    hasBadge() {
      return this.date.startDate != null && this.date.endDate != null;
    },
  },
  methods: {
    setDate(date: Data['date']) {
      if (!moment(date.startDate).isValid() && !moment(date.endDate).isValid()) {
        this.$emit('filter-updated', { type: this.type, startDate: '', endDate: '' });
        return;
      }
      this.date.startDate = date.startDate;
      this.date.endDate = date.endDate;
      this.$emit('filter-updated', { type: this.type, startDate: this.date.startDate, endDate: this.date.endDate });
    },
  },
});
</script>
