import { MiddlewareError } from '@/api/errors/middlewareError';
import { MIDDLEWARE_ERROR } from '@/Configs/Constants/middlewareError';
import { useCompanyProfileStore } from '@/store/pinia';

import type { Middleware } from './types';
import type { PricingModel } from '@/types';

export const checkPricingModel: Middleware = async (to) => {
  if (to.meta?.allowUnauthenticated) {
    return true;
  }
  const store = useCompanyProfileStore();
  await store.init();

  const isPricingModelRequired = (pricingModel?: PricingModel): pricingModel is PricingModel => Boolean(pricingModel);
  if (!isPricingModelRequired(to.meta.pricingModelRequired)) {
    return true;
  }
  if (store.pricingModel !== to.meta.pricingModelRequired) {
    throw new MiddlewareError(MIDDLEWARE_ERROR.PRICING_MODEL_MISMATCH);
  }
};
