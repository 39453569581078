<template>
  <div class="broadcast-details-recipients">
    <div class="flex items-start justify-between">
      <div>
        <recipients-badge
          v-for="item in list"
          :key="item.id"
          :name="item.name"
          :phone="item.phone"
          :show-delete="false"
          :variant="recipientsBadgeClass"
          class="fade-in"
          data-hj-suppress
        />

        <recipients-badge
          class="plus-more cursor-pointer"
          v-if="!open && recipients.length > 2"
          :show-delete="false"
          :variant="recipientsBadgeClass"
          @click="toggle"
        >
          +{{ recipients.length - 2 }}
        </recipients-badge>
      </div>
      <button @click="toggle" v-if="recipients.length > 2">
        <chevron-down-linear size="1.5rem" v-if="!open" />
        <chevron-up-linear size="1.5rem" v-else />
      </button>
    </div>
  </div>
</template>

<script>
import RecipientsBadge from '@/components/WabBroadcasting/components/RecipientsBadge';
import { ChevronDownLinear, ChevronUpLinear } from '@trengo/trengo-icons';
export default {
  name: 'BroadcastDetailsRecipients',
  components: { RecipientsBadge, ChevronDownLinear, ChevronUpLinear },
  props: {
    recipients: {
      type: Array,
      default: () => [],
    },
    isSuccessfulRecipients: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    firstTwo() {
      return this.recipients.slice(0, 2);
    },
    list() {
      return this.open ? this.recipients : this.firstTwo;
    },
    recipientsBadgeClass() {
      return this.isSuccessfulRecipients ? 'success' : 'failed ';
    },
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
  },
};
</script>
