import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_question_linear = _resolveComponent("question-linear")!
  const _component_t_button = _resolveComponent("t-button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createBlock(_component_t_button, {
    class: "fixed bottom-6 right-6",
    size: "lg",
    "btn-style": "secondary",
    "icon-only": "",
    href: _ctx.link,
    target: "_blank"
  }, {
    icon: _withCtx(() => [
      _createVNode(_component_question_linear, { size: "1.5rem" })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["href"])), [
    [_directive_tooltip, {
      placement: 'top',
      content: _ctx.$te('navigation.help_center') ? _ctx.$t('navigation.help_center') : 'Help Center',
    }]
  ])
}