import { BROADCAST_STATUS, BROADCAST_STATUS_DISPLAY_TEXT } from '@/Configs/Constants';

const currency = (amount) => {
  const amt = Number(amount);
  return (amt && amt.toLocaleString('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 })) || '0,00';
};

const replaceTags = (message) => {
  let newMessage = message.replace(/{{/g, '<span class="template-tag text-leaf-500">[');
  newMessage = newMessage.replace(/}}/g, ']</span>');
  return newMessage;
};
const replaceSmsTags = (message) => {
  let newMessage = message.replace(/\[/g, '<span class="template-tag text-leaf-500">[');
  newMessage = newMessage.replace(/]/g, ']</span>');
  return newMessage;
};

const statusTypeConvert = (status) => {
  const typeConvert = {
    SCHEDULED: 'default',
    CREATED: 'default',
    SENT: 'success',
    FAILED: 'error',
    SENDING: 'warning',
  };

  return typeConvert[status];
};

const statusTooltip = (status) => {
  const typeConvert = {
    SCHEDULED: '',
    SENT: '',
    PARTLY_SENT: 'Some recipients did not receive this broadcast',
    LIST_PARTLY_SENT: 'Some recipients did not receive this broadcast, view the broadcast for details',
    CREATED: '',
    FAILED: '',
    LIST_FAILED: 'Broadcast has failed to send, view the broadcast for more information',
    SENDING: 'Your Broadcast is being sent.',
  };

  return typeConvert[status];
};

const broadcastStatusDisplayText = (status) => {
  switch (status) {
    case BROADCAST_STATUS.SCHEDULED:
      return BROADCAST_STATUS_DISPLAY_TEXT.SCHEDULED;
    case BROADCAST_STATUS.PARTLY_SENT:
      return BROADCAST_STATUS_DISPLAY_TEXT.PARTLY_SENT;
    case BROADCAST_STATUS.CREATED:
      return BROADCAST_STATUS_DISPLAY_TEXT.CREATED;
    case BROADCAST_STATUS.FAILED:
      return BROADCAST_STATUS_DISPLAY_TEXT.FAILED;
    case BROADCAST_STATUS.SENDING:
      return BROADCAST_STATUS_DISPLAY_TEXT.SENDING;
    default:
      return BROADCAST_STATUS_DISPLAY_TEXT.SENT;
  }
};

export { currency, replaceTags, replaceSmsTags, statusTypeConvert, statusTooltip, broadcastStatusDisplayText };
