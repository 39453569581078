<template>
  <div class="emoji-browser">
    <div class="flex">
      <input
        ref="search"
        v-model="keyword"
        type="text"
        class="form-control emoji-keyword"
        :placeholder="$t('tickets.type_comment_search_emoji')"
      />
      <i class="material-icons emoji-picker-close" @click="$emit('hideEmojiPicker')">keyboard_arrow_down</i>
    </div>
    <div ref="container" class="panel-emojis">
      <div v-for="(list, cat, index) in filteredEmojis" :key="cat">
        <div v-if="index === 0 && cat && currentUser && currentUser?.favouriteReactions?.length > 0">
          <strong>Favourite emojis</strong>
          <br />
          <button
            v-for="(emojiCode, i) in currentUserFavouriteReactions"
            :key="i"
            @click="
              insertEmoji(
                emojis[emojiCode.split('.')[0]][emojiCode.split('.')[1]],
                emojiCode.split('.')[0],
                emojiCode.split('.')[1]
              )
            "
          >
            {{ emojis[emojiCode.split('.')[0]][emojiCode.split('.')[1]] }}
          </button>
        </div>
        <div class="emoji-category-title">{{ cat }}</div>
        <div>
          <span
            v-for="(emoji, title) in list"
            :key="title"
            class="emoji"
            :title="replaceUnderscores(title)"
            @click="insertEmoji(emoji, cat, title)"
          >
            {{ emoji }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapValues, pickBy } from 'lodash';

import emojiList from '../../util/emoji';

export default {
  emits: ['insertEmoji', 'hideEmojiPicker'],
  props: {
    currentUser: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      emojis: [],
      keyword: '',
    };
  },

  mounted() {
    this.$refs.search.focus();
    setTimeout(() => {
      this.load();
    }, 100);
  },

  computed: {
    currentUserFavouriteReactions() {
      return this.currentUser?.favouriteReactions?.filter(
        (emojiCode) => emojiCode.split('.')[0] && emojiCode.split('.')[1]
      );
    },
    filteredEmojis() {
      if (!this.keyword) {
        return this.emojis;
      } else {
        const titleIncludesKeyword = (list) => {
          return pickBy(list, (emoji, title) => title.includes(this.keyword.replace(/ /g, '_').toLowerCase()));
        };
        return pickBy(
          mapValues(this.emojis, (list) => {
            return titleIncludesKeyword(list);
          }),
          (list) => Object.keys(list).length
        );
      }
    },
  },

  methods: {
    insertEmoji(emoji, cat, title) {
      emoji = {
        category: cat,
        title: title,
        emoji: emoji,
      };

      this.$emit('insertEmoji', emoji);
      this.$emit('hideEmojiPicker');
    },

    load() {
      this.emojis = emojiList;
    },
    replaceUnderscores(v) {
      let text = v.replace(/_/g, ' ');
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
  },
};
</script>

<style lang="scss">
.emoji-browser {
  height: 230px;
  width: 333px;
  background: white;
  padding: 10px 10px 10px 10px;
  border-top: 2px solid #f7f7f7;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  box-shadow: 0px -5px 22px rgba(0, 0, 0, 0.1);

  .emoji-keyword {
    margin-bottom: 10px;
    border-radius: 500px;
    color: #000;
    background-color: rgb(247, 247, 247);
    border: none;
  }
  .emoji-picker-close {
    font-size: 35px;
    cursor: pointer;
    margin-left: 10px;
    transition: color 100ms ease-out;

    &:hover {
      color: #565656;
      transition: color 120ms ease-in;
    }
  }
}

@media (max-width: 991px) {
  .emoji-container {
    left: 15px !important;
    right: 15px !important;
    width: auto;
  }
}

@media (max-width: 543px) {
  .emoji-browser {
    width: 100%;
  }
}

.panel-emojis {
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}
.emoji-category-title {
  font-weight: bold;
  color: #999999;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 7px;
}
.emoji-category {
  flex: 1;
  margin-bottom: 10px;
}
.emoji {
  font-size: 21px;
  cursor: pointer;
}
</style>
