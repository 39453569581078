<template>
  <div class="relative h-full w-full pb-10 pt-30">
    <div class="flex items-center justify-center">
      <img :src="`${$root.assetsURL}img/whatsapp_logo.svg`" alt="whatsapp" />
      <div class="relative ml-2 mr-2.5 flex items-center">
        <img :src="`${$root.assetsURL}img/dashed_line.svg`" alt="dashed line" class="absolute" />
        <div class="h-2 w-2 rounded-full bg-success-300" />
        <span class="z-10 ml-4 mr-3 rounded-full bg-grey-100">
          <img :src="`${$root.assetsURL}img/checkmark_dot.svg`" alt="whatsapp" />
        </span>
        <div class="h-2 w-2 rounded-full bg-success-300" />
      </div>
      <img :src="`${$root.assetsURL}img/trengo_logo.svg`" alt="trengo" />
    </div>
    <div class="m-0 m-auto mt-10 flex w-1/2 flex-col items-center justify-center">
      <div class="t-text-desktop-h6 mb-1 text-grey-900">Great stuff! But...</div>
      <div class="description t-text-desktop-paragraph-md text-center text-grey-800">
        Remember to verify your business in the Meta Business Manager. Without verification your WhatsApp Business
        account will be frozen after the 30-day trial period.
      </div>
      <div class="pt-10">
        <a href="https://www.facebook.com/business/help/2058515294227817?id=180505742745347" target="_blank">
          <atomic-button btn-style="primary">Verify your business</atomic-button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepNotVerified',
};
</script>

<style scoped lang="scss">
.description {
  width: 441px;
}
</style>
