<script type="text/babel">
import eventBus from '@/eventBus';

export default {
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    },
    publicProvider: {
      type: String,
      required: true,
    },
    replyTo: {
      type: Object,
      default: null,
    },
    isLatestInbound: {
      type: Boolean,
      default: false,
    },
    fromParent: {
      type: Object,
      default: null,
    },
    from: {
      type: Object,
      default: null,
    },
  },
  components: {},

  data() {
    return {};
  },

  computed: {
    currentReactionType: function () {
      if (this.publicProvider === 'FACEBOOK' && this.message.meta && this.message.meta.page_reaction_type) {
        return this.message.meta.page_reaction_type;
      }
      /* // For future reference. When agents are able to like other objects as well
                else if (this.message.agentReactions) {
                    ...
                }
                */
      return;
    },
    isReplyingToCurrent: function () {
      return this.replyTo && this.replyTo.frontend_message_id === this.message.id;
    },
    canReplyTo: function () {
      return (
        this.message.meta === null ||
        typeof this.message.meta.can_comment === 'undefined' ||
        this.message.meta.can_comment === true
      );
    },
  },

  mounted() {
    if (this.isLatestInbound && this.canReplyTo) {
      this.setReplyTo();
    }
  },

  methods: {
    react(action, reaction_type) {
      if (this.publicProvider === 'FACEBOOK' && reaction_type !== 'like') {
        alert('Unfortunately the Facebook API does not allow interactions with emotions other than likes');
        return;
      }
      eventBus.$emit('reactTo', {
        ticket_id: this.ticket.id,
        message_id: this.message.id,
        request_data: {
          type: 'reaction',
          reaction_type: reaction_type,
          action: action,
        },
      });
    },
    setReplyTo() {
      if (this.publicProvider === 'FACEBOOK' && this.message.meta && this.message.meta.can_nest === false) {
        eventBus.$emit('appendToComposer', {
          message: '<span contenteditable="false">@[' + this.message.contact.full_name + ']</span>&nbsp;',
        });
        eventBus.$emit('replyTo', {
          type: this.message.parent?.meta.meta.object_type,
          message_id: this.message.parent?.id,
          full_name: this.fromParent.full_name,
          frontend_message_id: this.message.id,
        });
      } else {
        if (this.publicProvider === 'INSTAGRAM') {
          eventBus.$emit('appendToComposer', {
            message: '@' + this.message.contact.full_name + '&nbsp;',
          });
        }
        eventBus.$emit('replyTo', {
          type: this.message.meta.object_type,
          message_id: this.message.id,
          full_name: this.from.full_name,
          frontend_message_id: this.message.id,
        });
      }
    },
  },
};
</script>

<template>
  <span v-show="ticket.agent && ticket.agent.id === $root.user.id && ticket.status === 'ASSIGNED' && canReplyTo">
    <span class="mx-1">-</span>
    <span v-if="isReplyingToCurrent" class="_800">{{ $t('tickets.reply') }}</span>
    <a v-else @click="setReplyTo()">{{ $t('tickets.reply') }}</a>

    <template v-if="publicProvider === 'FACEBOOK'">
      -
      <span v-if="currentReactionType && currentReactionType !== 'like'" class="_800">
        {{ $t('tickets.reaction_' + currentReactionType) }}
      </span>
      <a
        v-else
        :class="{ _800: currentReactionType }"
        @click="react(currentReactionType === 'like' ? 'delete' : 'add', 'like')"
      >
        {{ $t('tickets.reaction_like') }}
      </a>
    </template>
  </span>
</template>
