<template>
  <t-modal v-model="isOpen" :close-on-backdrop-click="true" :close-icon="true" variant="narrow" @close="$emit('close')">
    <template #header>
      <h3 class="t-text-h3 my-auto max-w-[90%] p-0">
        {{ $t('integration_hub.installed_modal_title', { name: integration.name }) }}
      </h3>
    </template>

    <p class="t-text-md text-grey-800" data-test="integration-description">
      {{ $t('integration_hub.installed_modal_description', { name: integration.name }) }}
    </p>

    <t-inline-banner v-if="integration.filterType === 'crm'" class="mb-4">
      <template #icon>
        <info-linear />
      </template>

      <p class="m-0 p-0">{{ $t('integration_hub.agents_must_sign_in', { name: integration.name }) }}</p>
    </t-inline-banner>

    <template #footer>
      <t-button data-test="continue-button" @click="$emit('close')">
        {{ $t('integration_hub.start_using_button', { name: integration.name }) }}
      </t-button>
    </template>
  </t-modal>
</template>

<script lang="ts">
import { InfoLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import type { Integration } from '@/store/types/integrations';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'IntegrationHubInstalledModal',
  emits: ['close'],
  components: { InfoLinear },

  props: {
    integration: {
      type: Object as PropType<Integration>,
      default: () => ({}),
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
