<template>
  <page-container>
    <hero class="my-6">
      <template #top>
        <page-title>{{ $t('reports.conversations_title') }}</page-title>
      </template>
      <template #body>
        {{ $t('reports.conversations_title_sub_text') }}
        <div class="mt-4">
          <atomic-link-external url="https://help.trengo.com">
            {{ $t('reports.conversations_title_learn_more') }}
          </atomic-link-external>
        </div>
      </template>
    </hero>
    <filters v-model="filters"></filters>
    <report
      report="conversations"
      :filters="filters"
      :metrics="['new_conversations', 'assigned_conversations', 'closed_conversations']"
    ></report>
  </page-container>
</template>

<script>
import AtomicLinkExternal from '../Components/ExternalLink';
import Filters from '../Components/Filters';
import PageContainer from '../Components/PageContainer';
import PageTitle from '../Components/PageTitle';
import Report from '../Components/Report';

export default {
  mounted() {},
  components: {
    Report,
    Filters,
    PageTitle,
    PageContainer,
    AtomicLinkExternal,
  },
  data() {
    return {
      filters: {},
    };
  },
  methods: {},
};
</script>
