export const FEATURE = {
  ACCOUNT__AUTHENTICATION__API_ACCESS: 'account__authentication__api_access',
  ACCOUNT__AUTHENTICATION__MANDATORY_MFA: 'account__authentication__mandatory_mfa',
  ACCOUNT__AUTHENTICATION__MFA: 'account__authentication__mfa',
  ACCOUNT__AUTHENTICATION__USER_SEATS: 'account__authentication__user_seats',
  ACCOUNT__AUTHORIZATION__MANAGE_ROLES: 'account__authorization__manage_roles',
  ACCOUNT__AUTHORIZATION__MANAGE_ROLE_PERMISSIONS: 'account__authorization__manage_role_permissions',
  ACCOUNT__PRIVACY__DATA_RETENTION: 'account__privacy__data_retention',
  ACCOUNT__SUPPORT__EMAIL: 'account__support__email',
  ACCOUNT__SUPPORT__LIVECHAT: 'account__support__livechat',
  ACCOUNT__SUPPORT__MESSAGING: 'account__support__messaging',
  ACCOUNT__SUPPORT__PHONE: 'account__support__phone',
  CUSTOMER_SERVICE_TEAMCHAT: 'customer_service_teamchat',
  CUSTOMER_SERVICE__AUTOMATION__CHATBOT: 'customer_service__automation__chatbot',
  CUSTOMER_SERVICE__AUTOMATION__CSAT: 'customer_service__automation__csat',
  CUSTOMER_SERVICE__AUTOMATION__CSAT__WHITELABEL: 'customer_service__automation__csat__whitelabel',
  CUSTOMER_SERVICE__AUTOMATION__FLOWBOT: 'customer_service__automation__flowbot',
  CUSTOMER_SERVICE__AUTOMATION__RULES: 'customer_service__automation__rules',
  CUSTOMER_SERVICE__AUTOMATION__WEBHOOKS: 'customer_service__automation__webhooks',
  CUSTOMER_SERVICE__AUTOMATION__WIDGET: 'customer_service__automation__widget',
  CUSTOMER_SERVICE__AUTOMATION__WIDGET__WHITELABEL: 'customer_service__automation__widget__whitelabel',
  CUSTOMER_SERVICE__AUTOMATION__WORKLOAD_BALANCING: 'customer_service__automation__workload_balancing',
  CUSTOMER_SERVICE__BROADCASTING__SMS: 'customer_service__broadcasting__sms',
  CUSTOMER_SERVICE__BROADCASTING__WHATSAPP: 'customer_service__broadcasting__whatsapp',
  CUSTOMER_SERVICE__CHANNELS__CUSTOM: 'customer_service__channels__custom',
  CUSTOMER_SERVICE__CHANNELS__EMAIL__WHITELABEL: 'customer_service__channels__email__whitelabel',
  CUSTOMER_SERVICE__CHANNELS__VOICE: 'customer_service__channels__voice',
  CUSTOMER_SERVICE__CHANNELS__WHATSAPP: 'customer_service__channels__whatsapp',
  CUSTOMER_SERVICE__CHANNELS__WHATSAPP_BUSINESS__WHITELABEL:
    'customer_service__channels__whatsapp_business__whitelabel',
  CUSTOMER_SERVICE__HELPCENTER: 'customer_service__helpcenter',
  CUSTOMER_SERVICE__HELPCENTER__WHITELABEL: 'customer_service__helpcenter__whitelabel',
  CUSTOMER_SERVICE__INBOX__ASSIGN_CONVERSATIONS: 'customer_service__inbox__assign_conversations',
  CUSTOMER_SERVICE__INBOX__FORWARD_CONVERSATIONS: 'customer_service__inbox__forward_conversations',
  CUSTOMER_SERVICE__INBOX__INTEGRATIONS__BASIC: 'customer_service__inbox__integrations__basic',
  CUSTOMER_SERVICE__INBOX__INTEGRATIONS__PREMIUM: 'customer_service__inbox__integrations__premium',
  CUSTOMER_SERVICE__INBOX__MANAGE_LABELS: 'customer_service__inbox__manage_labels',
  CUSTOMER_SERVICE__INBOX__MANAGE_TEAMS: 'customer_service__inbox__manage_teams',
  CUSTOMER_SERVICE__REPORTING: 'customer_service__reporting',
} as const;
