<template>
  <iframe
    :src="getPluginUrl()"
    style="width: 100%; border: none"
    :style="{ height: plugin.meta.height || '250px' }"
  ></iframe>
</template>

<script>
export default {
  props: ['plugin', 'ticket'],

  methods: {
    getPluginUrl() {
      let url = this.plugin.meta.url;

      url = window.updateQueryStringParameter(url, 'auth_token', this.plugin.meta.auth_token);
      return window.updateQueryStringParameter(url, 'ticket_id', this.ticket.id);
    },
  },
};
</script>
