<script type="text/babel">
import { fetchCompanyProfile } from '@/api';
import { FEATURE_FLAG_SUBSCRIPTION } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

import AutoRecharge from './components/AutoRecharge.vue';
import BalanceAlert from './components/BalanceAlert.vue';
import UpdateBalance from './components/UpdateBalance.vue';
import BalanceUpgrades from './Transactions/BalanceUpgrades';
import MigrationFreeze from '../MigrationFreeze/Index.vue';

export default {
  components: {
    AutoRecharge,
    BalanceAlert,
    UpdateBalance,
    BalanceUpgrades,
    MigrationFreeze,
  },

  mounted() {
    this.isFreeze = useFeatureFlagStore().isEnabled(FEATURE_FLAG_SUBSCRIPTION.TW_FREEZE_REGISTRATION);
    if (!this.isFreeze) {
      this.loadBalanceSettings();
    }
    this.return_url = window.location.href;
  },

  data() {
    return {
      isFreeze: false,
      return_url: '',
      record: {},
    };
  },

  methods: {
    loadBalanceSettings() {
      fetchCompanyProfile()
        .then((company_profile) => {
          this.record = company_profile.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleReloadBalanceSettings() {
      this.loadBalanceSettings();
    },
  },
};
</script>

<template>
  <migration-freeze v-if="isFreeze" />
  <div v-else class="row-col" style="display: block">
    <div class="row-body">
      <div class="row-inner">
        <div class="p-a container">
          <div class="box">
            <div class="box-header b-b">
              <h2>{{ $t('company_profile.subscription_page_update_balance_smsvoice') }}</h2>
            </div>
            <div class="box-body">
              <update-balance :return_url="return_url" :record="record"></update-balance>
            </div>
          </div>
          <auto-recharge :record="record" @reload-balance-settings="handleReloadBalanceSettings"></auto-recharge>
          <balance-alert :record="record" @reload-balance-settings="handleReloadBalanceSettings"></balance-alert>
          <balance-upgrades />
        </div>
      </div>
    </div>
  </div>
</template>
