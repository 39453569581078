import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4efa1900"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "t-text-h5 mb-0 flex-1 truncate text-ellipsis text-grey-900" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_left_linear = _resolveComponent("arrow-left-linear")!
  const _component_t_icon_button = _resolveComponent("t-icon-button")!
  const _component_t_tooltip = _resolveComponent("t-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: "back-button-wrapper flex w-full flex-wrap items-center",
    style: _normalizeStyle({ width: _ctx.maxWidth })
  }, [
    _createVNode(_component_t_tooltip, {
      text: _ctx.$t('settings.back_button'),
      variant: "solid",
      position: "bottom"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_t_icon_button, {
          class: "mr-6",
          "data-test": "settings-item-back-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_arrow_left_linear)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["text"]),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.title), 1 /* TEXT */)
    ], true)
  ], 4 /* STYLE */))
}