export const GOOGLE = 'GOOGLE';
export const OUTLOOK = 'OUTLOOK';
export const TRENGO = 'TRENGO';
export const NYLAS = 'NYLAS';
export const NYLAS_V3 = 'NYLASV3';
export const NYLAS_API_V3 = 'nylas-v3';
export const SANDBOX = 'sandbox';
export const TRENGO_BSP = 'trengo_bsp';
export const GMAIL = 'GMAIL';
export const SKIPPED = 'skipped';
export const CHAT = 'CHAT';
export const FACEBOOK = 'FACEBOOK';
export const EMAIL = 'EMAIL';
export const WA_BUSINESS = 'WA_BUSINESS';
export const INSTAGRAM = 'INSTAGRAM';
