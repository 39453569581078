<script lang="ts" setup>
import { AlertCircleLinear } from '@trengo/trengo-icons';
</script>

<template>
  <div
    class="m-8 flex h-[500px] flex-col items-center justify-center gap-2 rounded-xl border-1 border-grey-300 bg-white"
  >
    <t-thumbnail class="mb-3 bg-white">
      <alert-circle-linear size="1rem" />
    </t-thumbnail>
    <div class="t-text-md-emphasize">{{ $t('settings.migration_freeze') }}</div>
    <div class="t-text-sm">{{ $t('settings.migration_freeze_detail') }}</div>
  </div>
</template>
