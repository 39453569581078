<template>
  <div class="form-group m-0 flex flex-col p-0">
    <div v-for="(day, i) in localDays" :key="i" :class="{ 'opacity-50': !day.is_open }">
      <t-accordion
        :item-index="parseDayOfWeek(day.day_of_week)"
        :is-open="openedItem === parseDayOfWeek(day.day_of_week)"
        @eventSelect="(selected) => handleSelectedAccordion(selected, day)"
      >
        <template #title>
          <span class="flex items-center justify-between">
            <span class="flex items-center justify-center">
              <label class="ui-switch success m-t-xs m-r" @click="(event) => toggleSwitcher(event, day)">
                <input v-model="day.is_open" type="checkbox" />
                <i></i>
              </label>
              <span class="font-600 text-sm">{{ parseDayOfWeek(day.day_of_week) }}</span>
            </span>
            <span class="mr-4 items-center"><i class="fal fa-chevron-down"></i></span>
          </span>
        </template>

        <template #body>
          <div class="px-2">
            <div class="flex flex-col items-start">
              <div
                v-for="(slot, i) in day.slots"
                class="flex w-full items-center justify-between rounded-xl bg-beta-100 p-3"
                :class="{ 'mt-1': i > 0 }"
              >
                <div class="w-5/6">
                  <select
                    v-model="slot.start"
                    :disabled="!day.is_open"
                    class="mr-1 h-12 w-2/5 rounded-xl border border-grey-500 px-1 py-2"
                  >
                    <option v-for="time in getMomentDiff(slot.start, slot.end, true)" :value="time">
                      {{ parseTime(time) }}
                    </option>
                  </select>
                  <span class="text-muted mx-0">-</span>
                  <select
                    v-model="slot.end"
                    :disabled="!day.is_open"
                    class="ml-1 h-12 w-2/5 rounded-xl border border-grey-500 px-1 py-2"
                  >
                    <option v-for="time in getMomentDiff(slot.start, slot.end, true)" :value="time">
                      {{ parseTime(time) }}
                    </option>
                  </select>
                </div>
                <div class="w-1/6">
                  <div
                    v-if="i > 0"
                    class="
                      text-muted
                      ml-2
                      flex
                      h-38px
                      w-38px
                      flex-shrink-0
                      cursor-pointer
                      items-center
                      justify-center
                      rounded-xl
                      border-1 border-grey-500
                      bg-white
                    "
                    @click="deleteSlot(day, slot)"
                  >
                    <i class="far fa-trash"></i>
                  </div>
                </div>
              </div>

              <div
                class="
                  my-4
                  ml-2
                  flex
                  h-12
                  w-200px
                  flex-shrink-0
                  cursor-pointer
                  items-center
                  justify-center
                  rounded-full
                  border
                  font-bold
                  text-grey-900
                "
                @click="day.is_open && addSlot(day)"
              >
                <i class="material-icons">add</i>
                Add time slot
              </div>
            </div>
          </div>
        </template>
      </t-accordion>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

import { addSlot, deleteSlot, getTimes, parseDayOfWeek, parseTime } from '../Utils';

export default {
  name: 'SecondStepMobile',
  emits: ['change'],
  props: {
    days: {
      type: Array,
      default: () => ({}),
    },
  },
  data() {
    return {
      openedItem: '',
      localDays: {},
    };
  },
  mounted() {
    this.localDays = cloneDeep(this.days);
  },
  watch: {
    localDays: {
      handler(value) {
        this.emitInput(value);
      },
      deep: true,
    },
  },
  methods: {
    getTimes,
    deleteSlot,
    addSlot,
    parseDayOfWeek,
    parseTime,

    getMomentDiff(start, end) {
      const startDay = moment(start, 'H:mm:ss');
      const endDay = moment(end, 'H:mm:ss');
      const startIsLessThanEnd = moment.duration(startDay.diff(endDay)).asHours();
      return this.getTimes(startIsLessThanEnd > 0 ? null : start, startIsLessThanEnd > 0 ? null : end);
    },

    emitInput(days) {
      this.$emit('change', days);
    },
    toggleSwitcher(event, day) {
      const dayName = parseDayOfWeek(day.day_of_week);

      if (!day.is_open || dayName === this.openedItem) {
        this.openedItem = '';
      }
      event.stopPropagation();
    },
    handleSelectedAccordion(selected, day) {
      if (!day.is_open) {
        return false;
      }
      this.openedItem = this.openedItem === selected ? '' : selected;
    },
  },
};
</script>
