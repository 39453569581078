<template>
  <div>
    <p class="t-text-md text-grey-800" data-test="integration-description">
      {{ $t('integration_hub.install_modal_form_description', { name: integration.name }) }}
    </p>
    <div v-for="field in fields">
      <div v-if="!field.hidden && field.type === 'select'" class="mb-2">
        <div class="t-text-md-emphasize mb-2 text-grey-800">
          {{ field.label }}
        </div>
        <t-dropdown
          v-model="field.value"
          text-index="label"
          :disabled="field.disabled"
          :error="field.hasError"
          :placeholder="field.placeholder"
          :items="field.options"
          value-index="value"
          size="lg"
        />
      </div>

      <div v-else-if="!field.hidden" class="pb-6">
        <t-input-text
          :id="field.id"
          v-model="field.value"
          :has-error="field.hasError"
          :type="field.sensitive ? 'password' : 'text'"
          :required="field.required"
          :label="field.label"
          :sub-label="field.description"
          :placeholder="field.placeholder"
        >
          <template v-if="field.prefix" #prefix>{{ field.prefix }}</template>
          <template v-if="field.suffix" #suffix>{{ field.suffix }}</template>
        </t-input-text>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(> label > span) {
  word-break: normal !important;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import type { FormField, FormFieldWithValue } from '@/store/types/integrations';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'IntegrationHubDynamicFormFields',
  emits: ['formChanged'],
  computed: {
    ...mapGetters({
      integration: 'integrations/getItem',
    }),
  },

  props: {
    prefilledSettings: {
      type: Object as PropType<Record<string, unknown>>,
      default: [],
    },
  },

  watch: {
    integration: {
      handler() {
        this.fields = [];
        this.integration.form_fields.forEach((field: FormField) => {
          let value = '';
          if (field.id === 'environment' && this.integration.service_id === 'crm+salesforce') {
            value = 'login';
          }
          this.fields.push({
            ...field,
            value,
            hasError: false,
          });
        });
      },
      deep: true,
      immediate: true,
    },

    fields: {
      handler() {
        const formValues = this.fields.reduce((accumulator: FormFieldWithValue[], field: FormFieldWithValue) => {
          return { ...accumulator, [field.id]: field.value };
        }, {});
        this.$emit('formChanged', formValues);
      },
      deep: true,
      immediate: true,
    },

    prefilledSettings: {
      handler() {
        this.fields.forEach((field: FormField, index: number) => {
          const val = this.prefilledSettings[field.id];
          if (val) {
            this.fields[index].value = val;
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },

  data() {
    return {
      fields: [] as FormFieldWithValue[],
    };
  },
});
</script>
