<template>
  <div class="flex w-full flex-row justify-between px-4 py-3" :class="rowBorderClass">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TableRow',
  props: {
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rowBorderClass() {
      return this.isLast ? 'none-border' : 'show-border';
    },
  },
};
</script>

<style src="./TableRow.scss" scoped lang="scss" />
