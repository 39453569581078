<template>
  <div v-if="!shouldBypassVerification && status === 'loading'" class="flex h-full w-full items-center justify-center">
    <t-spinner />
  </div>
  <danger-zone-verification-required
    v-else-if="!shouldBypassVerification && status === 'needsVerification'"
    :is-email-sent="isEmailSent"
    @submit="handleSubmit"
    @refresh="checkAccess"
  />
  <div v-else class="h-full w-full overflow-scroll">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

import { checkDangerZoneAccess } from '@/api';
import { FEATURE_FLAG_SETTINGS } from '@/Configs/Constants/featureFlag';
import { LOCAL_STORAGE } from '@/Configs/Constants/localStorage';
import { useFeatureFlagStore } from '@/store/pinia';

import DangerZoneVerificationRequired from './DangerZoneVerificationRequired.vue';

type Status = 'loading' | 'needsVerification' | 'verified';
const status = ref<Status>('loading');
const isEmailSent = ref(false);
const shouldBypassVerification = useFeatureFlagStore().isEnabled(
  FEATURE_FLAG_SETTINGS.TA_BYPASS_DANGER_ZONE_VERIFICATION
);

const handleSubmit = () => {
  isEmailSent.value = true;
};

const checkAccess = async () => {
  if (shouldBypassVerification) {
    status.value = 'verified';
    return;
  }
  status.value = 'loading';
  const token = localStorage.getItem(LOCAL_STORAGE.DANGER_ZONE_TOKEN);
  if (!token) {
    status.value = 'needsVerification';
    return;
  }
  const { data } = await checkDangerZoneAccess(token);
  status.value = data.is_valid_token ? 'verified' : 'needsVerification';
};
checkAccess();
</script>
