<template>
  <step-zero v-if="currentStep === 'disclaimer'" @goToStep="handleTransition" />
  <step-one v-else-if="currentStep === 'scanQRCode'" :qr="qr" :secret="secret" @goToStep="handleTransition" />
  <step-two v-else-if="currentStep === 'enterVerificationCode'" @goToStep="handleTransition" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import StepOne from './StepOne.vue';
import StepTwo from './StepTwo.vue';
import StepZero from './StepZero.vue';
import { SETUP_STEP } from '../constants';

import type { ModalData, SetupStep } from '../types';

const defaultCurrentStep = SETUP_STEP.ZERO;

export default defineComponent({
  name: 'TwoFactorAuthSetupStepper',
  components: {
    StepZero,
    StepOne,
    StepTwo,
  },
  data(): ModalData {
    return {
      currentStep: defaultCurrentStep,
    };
  },
  props: {
    qr: String,
    secret: String,
  },
  methods: {
    handleTransition(step: SetupStep) {
      this.currentStep = step;
    },
  },
});
</script>
