import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "t-text-desktop-h4 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_learn_more_category = _resolveComponent("learn-more-category")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('getting_started.learn_more')), 1 /* TEXT */),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, index) => {
      return (_openBlock(), _createBlock(_component_learn_more_category, {
        key: index,
        items: category.items,
        title: category.title
      }, null, 8 /* PROPS */, ["items", "title"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}