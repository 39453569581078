type NotificationType = 'error' | 'warning' | 'notice';

const showGrowlNotification = (type: NotificationType, title: string, message: string): void => {
  $.growl[type]({
    size: 'large',
    duration: 3000,
    namespace: 'growl',
    title,
    location: 'tc',
    message,
  });
};

export const flashError = (message: string, title = '') => {
  $('.growl').hide();
  showGrowlNotification('error', title, message);
};

export const flashWarning = (message: string, title = '') => {
  $('.growl').hide();
  showGrowlNotification('warning', title, message);
};

export const flashSuccess = (message: string, title = '') => {
  $('.growl').hide();
  showGrowlNotification('notice', title, message);
};
