import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "max-h-[350px] overflow-y-auto pr-1"
}
const _hoisted_3 = {
  key: 0,
  class: "t-text-lg-emphasize text-center text-grey-700",
  "data-test": "no-data-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_input_text = _resolveComponent("t-input-text")!
  const _component_contact_moment_card = _resolveComponent("contact-moment-card")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_spinner = _resolveComponent("t-spinner")!
  const _component_sidebar_dropdown = _resolveComponent("sidebar-dropdown")!

  return (_openBlock(), _createBlock(_component_sidebar_dropdown, {
    title: 
      _ctx.profiles.length > 0 ? this.$tc('ticket_sidebar.profile_moments') : this.$tc('ticket_sidebar.contact_moments')
    ,
    "collapse-key": `${_ctx.momentType}MomentsDropdown`,
    "data-test": `${_ctx.momentType}-moments-dropdown`,
    onToggled: _ctx.setToggleState,
    onMounted: _ctx.setToggleState
  }, {
    default: _withCtx(() => [
      (_ctx.hasFetched)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_t_input_text, {
              id: "contactMomentSearch",
              modelValue: _ctx.searchTerm,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
              class: "mb-2",
              placeholder: _ctx.$tc('general.searching'),
              size: "sm",
              "allow-clear": true,
              "data-test": "contact-moments-searchbar",
              onClear: _ctx.clearSearch,
              onKeydown: [
                _withKeys(_withModifiers(_ctx.clearSearch, ["prevent"]), ["esc"]),
                _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.searchTicket(false, false, true)), ["prevent"]), ["enter"]))
              ]
            }, null, 8 /* PROPS */, ["modelValue", "placeholder", "onClear", "onKeydown"]),
            (!_ctx.isLoading || !_ctx.isTicketListEmpty)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tickets.data, (ticket) => {
                    return (_openBlock(), _createBlock(_component_contact_moment_card, {
                      key: ticket.id,
                      ticket: ticket,
                      "current-ticket": _ctx.currentTicket,
                      profiles: _ctx.profiles
                    }, null, 8 /* PROPS */, ["ticket", "current-ticket", "profiles"]))
                  }), 128 /* KEYED_FRAGMENT */)),
                  (_ctx.isTicketListEmpty)
                    ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.$tc('ticket_sidebar.no_contact_moments')), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  (_ctx.tickets.links && _ctx.tickets.links.next !== null)
                    ? (_openBlock(), _createBlock(_component_t_button, {
                        key: 1,
                        class: "mx-auto mt-3 w-fit",
                        "data-test": "load-more-button",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.searchTicket(true)))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$tc('tickets.load_more')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true)
                ]))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.isLoading || !_ctx.hasFetched)
        ? (_openBlock(), _createBlock(_component_t_spinner, {
            key: 1,
            class: "m-auto",
            size: "1.5rem"
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "collapse-key", "data-test", "onToggled", "onMounted"]))
}