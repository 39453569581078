<template>
  <div class="form-group flex flex-col">
    <div
      v-for="(day, i) in localDays"
      :key="i"
      class="b-b mb-2 flex flex-row items-center items-baseline justify-start pb-2"
      :class="{ 'opacity-50': !day.is_open }"
    >
      <div class="col-3 w-200px">
        <label>{{ parseDayOfWeek(day.day_of_week) }}</label>
      </div>
      <div class="col-3 flex w-200px items-center">
        <label class="ui-switch success m-t-xs m-r">
          <input v-model="day.is_open" type="checkbox" :true-value="openDayValue" />
          <i></i>
        </label>
        <span class="font-500 text-sm">{{ day.is_open ? $t('general.open_day') : $t('general.status_closed') }}</span>
      </div>
      <div class="col-6 flex w-full flex-col items-center">
        <div v-for="(slot, i) in day.slots" class="flex w-full items-center justify-between" :class="{ 'mt-1': i > 0 }">
          <select
            v-model="slot.start"
            :disabled="!day.is_open"
            class="mr-1 h-12 w-80px rounded-xl border border-grey-200 px-1 py-2"
          >
            <option v-for="time in getMomentDiff(slot.start, slot.end, true)" :value="time">
              {{ parseTime(time) }}
            </option>
          </select>
          <span class="text-muted mx-0">To</span>
          <select
            v-model="slot.end"
            :disabled="!day.is_open"
            class="mr-1 h-12 w-80px rounded-xl border border-grey-200 px-1 py-2"
          >
            <option v-for="time in getMomentDiff(slot.start, slot.end, true)" :value="time">
              {{ parseTime(time) }}
            </option>
          </select>
          <div
            v-if="i === 0"
            class="
              text-muted
              ml-2
              flex
              h-38px
              w-38px
              flex-shrink-0
              cursor-pointer
              items-center
              justify-center
              rounded-full
              border
            "
            @click="day.is_open && addSlot(day)"
          >
            <i class="material-icons">add</i>
          </div>
          <div
            v-else
            class="
              text-muted
              ml-2
              flex
              h-38px
              w-38px
              flex-shrink-0
              cursor-pointer
              items-center
              justify-center
              rounded-full
              border
            "
            @click="deleteSlot(day, slot)"
          >
            <i class="material-icons">remove</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

import { addSlot, deleteSlot, getMomentDiff, getTimes, parseDayOfWeek, parseTime } from '../Utils';

export default {
  name: 'SecondStep',
  emits: ['change'],
  props: {
    days: {
      type: Array,
      default: () => ({}),
    },
    action: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      localDays: {},
    };
  },
  mounted() {
    this.localDays = cloneDeep(this.days);
  },
  watch: {
    localDays: {
      handler(value) {
        this.emitInput(value);
      },
      deep: true,
    },
  },
  computed: {
    openDayValue() {
      return this.action === 'create' ? true : 1;
    },
  },
  methods: {
    getTimes,
    deleteSlot,
    addSlot,
    parseDayOfWeek,
    parseTime,
    getMomentDiff,

    // getMomentDiff(start, end) {
    //     const startDay = moment(start, "H:mm:ss")
    //     const endDay = moment(end, "H:mm:ss")
    //     const startIsLessThanEnd = moment.duration(startDay.diff(endDay)).asHours();
    //     return this.getTimes(startIsLessThanEnd > 0 ? null : start, startIsLessThanEnd > 0 ? null : end)
    // },

    emitInput(days) {
      this.$emit('change', days);
    },
  },
};
</script>
