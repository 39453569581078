<template>
  <div>
    <div class="mb-2 text-xl font-bold leading-7 text-grey-900">{{ $t('whatsapp.facebook_business_manager') }}</div>
    <div class="mb-8">
      {{ $t('whatsapp.access_to_the_facebook_business_manager') }}
    </div>

    <div
      class="
        mb-8
        flex
        w-full
        cursor-pointer
        select-none
        items-center
        rounded-xl
        bg-grey-200
        p-8
        leading-normal
        text-grey-900
      "
      @click.prevent="checkboxToggle"
    >
      <t-input-checkbox v-model="computedValue" class="checkbox-validation mb-0 mr-3 cursor-pointer" />
      {{ $t('whatsapp.i_have_a_facebook_account_and_understand_the_above') }}
    </div>

    <div>
      <p v-html="markupSanitizer($t('whatsapp.dont_have_a_facebook_account'))" />
    </div>
  </div>
</template>

<script>
import markupSanitizer from '@/util/markupSanitizer';

export default {
  name: 'StepTwo',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
  methods: {
    markupSanitizer: markupSanitizer,

    checkboxToggle() {
      this.computedValue = !this.computedValue;
    },
  },
};
</script>

<style scoped lang="scss">
.checkbox-validation {
  margin-top: -3px;
}
</style>
