<script lang="ts">
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import { PERMISSION } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useUserStore } from '@/store/pinia';

import Avatar from './Avatar';
import Dropdown from './ReplyForm/Dropdown';
import ticketRepository from '../repositories/Ticket';

export default defineComponent({
  name: 'BulkActionSelector',
  emits: ['update-selected'],
  components: { Dropdown, Avatar },
  props: {
    selection: {
      type: Array,
      default: () => [],
    },
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      timer: null,
      assignType: 'user',
      ticket_result_id: null,
      PERMISSION,
    };
  },

  computed: {
    ...mapStores(useUserStore),
    assignOptions() {
      if (this.assignType === 'team') {
        return this.$root.teams;
      }
      if (this.assignType === 'user') {
        const getAssignableUsersByUserId = this.$root.getAssignableUsers().filter((u) => u.id !== this.$root.user.id);
        return [this.$root.user, ...getAssignableUsersByUserId];
      } else return {};
    },
  },

  mounted() {
    PusherInstance.subscribe(this.getChannel()).bind('progress', (data) => {
      if (data.status === 'completed') {
        eventBus.$emit('bulk.end');
        clearTimeout(this.timer);
        this.$emit('update-selected', []);
        eventBus.$emit('tickets.statuses.fetch');
      }
    });

    eventBus.$on('bulk.start', (data) => {
      this.$root.pendingBulk = true;
    });

    eventBus.$on('bulk.end', (data) => {
      this.$root.pendingBulk = false;
    });

    eventBus.$on('modals.bulk-ticket-result.close', (ticketResultId) => {
      this.ticket_result_id = ticketResultId;
      this.run('close_tickets');
    });

    $(this.$refs.dropdown).on('click', function (event) {
      event.stopPropagation();
    });
  },

  unmounted() {
    eventBus.$off('bulk.start');
    eventBus.$off('bulk.end');
    eventBus.$off('modals.bulk-ticket-result.open');
    eventBus.$off('modals.bulk-ticket-result.close');
    PusherInstance.unsubscribe(this.getChannel());
  },

  methods: {
    getChannel() {
      return CHANNEL_PREFIX + '@bulk-' + this.$root.user.id;
    },

    async run(action, e) {
      if (this.$root.pendingBulk) {
        return;
      }

      if (this.selection.length > 50) {
        alert('Please select no more than 50 tickets.');
        if (action !== 'close_tickets') {
          e.preventDefault();
        }
        return;
      }

      if (this.shouldBeConfirmed(action)) {
        const shouldConfirm = await this.$tConfirm(this.$t('general.are_you_sure'), {
          delete: true,
          title: this.$t('general.are_you_sure'),
        });
        if (!shouldConfirm) {
          return;
        }
      }

      if (action === 'assign_tickets') {
        return false;
      }

      this.setTimeoutTimer();
      this.startBulk();

      let ticketsWithResult = {
        ticket_ids: this.selection,
        ticket_result_id: this.ticket_result_id,
      };

      axios
        .post('/api/v2/bulk', {
          action: action,
          payload: action === 'close_tickets' ? ticketsWithResult : this.resolvePayload(action),
        })
        .catch(() => {
          eventBus.$emit('bulk.end');
        });
    },

    onAssign(opt) {
      this.setTimeoutTimer();
      this.startBulk();
      eventBus.$emit('bulk.start');
      if (this.assignType === 'user') {
        ticketRepository.assignTickets(this.selection, opt.id);
      }
      if (this.assignType === 'team') {
        ticketRepository.assignTicketsToTeam(this.selection, opt.id);
      }
    },

    resolvePayload(action) {
      switch (action) {
        case 'close_tickets':
          if (Object.keys(this.$root.ticketResults).length > 0) {
            return;
          }
          return {
            ticket_ids: this.selection,
          };
        case 'mark_as_spam':
          return {
            ticket_ids: this.selection,
          };
        case 'unmark_as_spam':
          return {
            ticket_ids: this.selection,
          };
        case 'delete_tickets':
          return {
            ticket_ids: this.selection,
          };
      }
    },

    shouldBeConfirmed(action) {
      const actionsToConfirm = ['delete_tickets'];
      return actionsToConfirm.includes(action);
    },

    setTimeoutTimer() {
      this.timer = setTimeout(() => window.location.reload(), 5000 * 60);
    },

    startBulk() {
      eventBus.$emit('bulk.start');
    },

    closeTickets() {
      if (Object.keys(this.$root.ticketResults).length > 0) {
        eventBus.$emit('modals.bulk-ticket-result.open');
        return;
      }
      this.run('close_tickets');
    },
  },
});
</script>

<template>
  <div class="dropdown">
    <a data-toggle="dropdown" class="_500 dropdown-toggle no-border text-base" :class="{ disabled: $root.pendingBulk }">
      <span>{{ $t('tickets.with_selected') }} ({{ selection.length }})</span>
    </a>
    <span v-show="$root.pendingBulk" class="ml-2">
      <i class="fa fa-spinner fa-spin"></i>
    </span>
    <div ref="dropdown" class="dropdown-menu text-color">
      <a
        v-show="status !== 'INVALID' && this.userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__ASSIGN)"
        class="dropdown-item"
        @click="run('assign_tickets', $event)"
      >
        <dropdown :options="assignOptions" @update:modelValue="onAssign">
          <template #heading>
            <div class="_500 pt-4 text-center text-base leading-none text-black">
              {{ $t('tickets.assign_type') }}
            </div>
            <div class="p-4 pb-0">
              <div class="flex items-center justify-center bg-grey-200" style="border-radius: 10px">
                <span
                  class="ticket-sub-filter flex-1 text-center text-sm text-grey-600"
                  :class="{ 'ticket-sub-filter--active': assignType === 'user' }"
                  @click="assignType = 'user'"
                >
                  {{ $t('tickets.assign_type_user') }}
                </span>
                <span
                  class="ticket-sub-filter flex-1 text-center text-sm text-grey-600"
                  :class="{ 'ticket-sub-filter--active': assignType === 'team' }"
                  @click="assignType = 'team'"
                >
                  {{ $t('tickets.assign_type_team') }}
                </span>
              </div>
            </div>
          </template>
          <template #option="user">
            <div v-if="assignType === 'user'" class="flex items-center">
              <div class="relative">
                <avatar
                  width="32"
                  :color="user.option.color"
                  :abbr="user.option.abbr"
                  :image="user.option.profile_image"
                ></avatar>
                <strong
                  class="label absolute rounded border border-white"
                  style="width: 10px; height: 10px; padding: 0; bottom: 0; right: 0"
                  :class="{
                    success: user.option.is_online && user.option.user_status == 'ONLINE',
                    warning: user.option.is_online && user.option.user_status == 'AWAY',
                    danger: !user.option.is_online,
                  }"
                ></strong>
              </div>
              <div class="ml-2 leading-none">
                {{ user.option.name }}
              </div>
            </div>
            <div v-if="assignType === 'team'" class="flex items-center">
              <div class="relative">
                <span
                  class="circle inline-block-40px avatar flex-shrink-0 bg-grey-200"
                  style="width: 32px; height: 32px; line-height: 32px"
                >
                  <i class="material-icons text-md leading-none" style="padding-top: 6px">people</i>
                </span>
                <b
                  class="label absolute rounded bg-grey-500"
                  style="width: 10px; height: 10px; padding: 0; bottom: 0; right: 0"
                  :class="{ success: user.option.online_users_count > 0, danger: user.option.online_users_count == 0 }"
                ></b>
              </div>
              <div class="ml-2 leading-none">
                {{ user.option.name }}
                <span v-if="user.option.users_count > 0" class="text-muted p-l-xs text-xs">
                  ({{ user.option.online_users_count }})
                </span>
              </div>
            </div>
          </template>
          <template #toggle>
            {{ $t('tickets.bulk_action_assign') }}
          </template>
        </dropdown>
      </a>
      <a
        v-show="
          status !== 'CLOSED' &&
          status !== 'INVALID' &&
          this.userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__CLOSE)
        "
        class="dropdown-item"
        @click="closeTickets()"
      >
        {{ $t('tickets.bulk_action_close') }}
      </a>
      <a
        v-show="this.userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__DELETE)"
        class="dropdown-item"
        @click="run('delete_tickets')"
      >
        {{ $t('tickets.bulk_action_delete') }}
      </a>
      <a
        v-show="status !== 'INVALID' && this.userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__CREATE)"
        class="dropdown-item"
        @click="run('mark_as_spam')"
      >
        {{ $t('tickets.bulk_action_mark_as_spam') }}
      </a>
      <a
        v-show="status === 'INVALID' && this.userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__CREATE)"
        class="dropdown-item"
        @click="run('unmark_as_spam')"
      >
        {{ $t('tickets.bulk_action_unmark_as_spam') }}
      </a>
    </div>
  </div>
</template>
