import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { request } from '@/util';
import { flashError } from '@/util/flashNotification';

import type { ChannelIdList } from './types';
import type { Channel } from '@/types';

export const CHANNEL_SETTING = {
  SUMMARY_CHANNELS: 'summary_channels',
  TRANSLATION_CHANNELS: 'translation_channels',
} as const;

type InboxSettingsResponse = {
  response: {
    agency_id: number;
    settings: {
      [CHANNEL_SETTING.SUMMARY_CHANNELS]: Channel['id'][];
      [CHANNEL_SETTING.TRANSLATION_CHANNELS]: Channel['id'][];
    };
  };
};

export type InboxSettingsPatch = Omit<InboxSettingsResponse['response'], 'agency_id'>;

const API_URL = '/api/v2/inbox_settings';

export const useInboxSettingsChannelsStore = defineStore('inbox-settings-channels-store', () => {
  const { t } = useI18n();

  const isLoading = ref(false);
  const activeChannels = ref<InboxSettingsPatch['settings']>({
    [CHANNEL_SETTING.SUMMARY_CHANNELS]: [],
    [CHANNEL_SETTING.TRANSLATION_CHANNELS]: [],
  });

  const getActiveChannels = async () => {
    isLoading.value = true;
    try {
      const res = await request<InboxSettingsResponse>(`${API_URL}/all`, 'GET');
      activeChannels.value = res.data.response.settings;
    } catch {
      flashError(t('general.error_something_went_wrong'));
      return;
    } finally {
      isLoading.value = false;
    }

    return activeChannels.value;
  };

  const updateChannels = async (payload: InboxSettingsPatch) => {
    try {
      const res = await request<{ response: boolean }>(`${API_URL}/update`, 'PUT', payload);
      activeChannels.value = payload.settings;
      return res.data.response;
    } catch (error) {
      flashError(t('general.error_something_went_wrong'));
      return false;
    }
  };

  const updateActiveChannels = async (channelList: ChannelIdList, key: valueof<typeof CHANNEL_SETTING>) => {
    const newActiveChannels = Object.values(channelList).flat();

    const payload: InboxSettingsPatch = {
      settings: {
        ...activeChannels.value,
        [key]: newActiveChannels,
      },
    };
    updateChannels(payload);
  };

  return {
    isLoading,
    activeChannels,
    getActiveChannels,
    updateActiveChannels,
  };
});
