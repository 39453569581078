import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { fetchAllUsers } from '@/api';
import { USER_ROLE, ADMIN_ROLES } from '@/Configs/Constants/UserRoles';

import type { User } from '@/types';

const filterOutObservers = (usersList: User[]): User[] =>
  usersList.filter((user) => user.role.name !== USER_ROLE.OBSERVER);

export const useUsersStore = defineStore('users', () => {
  const isLoading = ref(true);
  const users = ref<User[]>([]);
  const billableUsers = computed(() => filterOutObservers(users.value)); // FIXME: Should be renamed, with the new p&p observer is billable
  const observers = computed(() => users.value.filter(({ role }) => role.name === USER_ROLE.OBSERVER));
  const assignableUsers = computed(() => filterOutObservers(users.value));
  const primaryUsers = computed(() => (users.value ?? []).filter(({ is_primary }) => is_primary));

  const hasOnlyOneAdministrator = computed(() => {
    const adminUsers = users.value.filter((user) => Boolean(user?.role?.name) && ADMIN_ROLES.includes(user.role.name));
    return adminUsers.length <= 1;
  });

  const init = async (payload?: { data: { users: User[] } }) => {
    if (users.value.length > 0) {
      return;
    }

    try {
      if (payload?.data?.users?.length) {
        users.value = payload?.data.users;
      } else {
        reload();
      }
    } catch (err) {
      console.error(err);
    } finally {
      isLoading.value = false;
    }
  };

  const reload = async () => {
    try {
      isLoading.value = true;
      const { data } = await fetchAllUsers({ cacheEnabled: false });
      users.value = data?.users || [];
    } catch (err) {
      console.error(err);
    } finally {
      isLoading.value = false;
    }
  };

  const updateUser = async (user: User) => {
    const index = users.value.findIndex((userItem) => userItem.id === user.id);
    if (index !== -1) {
      users.value.splice(index, 1, { ...users.value[index], ...user });
    }
  };

  return {
    assignableUsers,
    billableUsers,
    primaryUsers,
    observers,
    init,
    reload,
    updateUser,
    isLoading,
    hasOnlyOneAdministrator,
    users,
  };
});
