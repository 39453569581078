import { request } from '@/util';
const apiUrl = '/api/v2';

const postNoteDraftAttachment = (ticket_id, data) => {
  return request(`${apiUrl}/tickets/${ticket_id}/me/note_draft_attachments`, 'POST', data);
};

const deleteNoteDraftAttachment = (ticket_id, attachment_id) => {
  request(`${apiUrl}/tickets/${ticket_id}/me/note_draft_attachments/${attachment_id}`, 'DELETE');
};

const fetchNoteDraft = (ticket_id) => {
  return request(`${apiUrl}/tickets/${ticket_id}/me/note_draft`, 'GET');
};

const createNoteDraft = (ticket_id, message) => {
  return request(`${apiUrl}/tickets/${ticket_id}/me/note_draft`, 'POST', { message });
};

const deleteNoteDraft = (ticket_id) => {
  return request(`${apiUrl}/tickets/${ticket_id}/me/note_draft/0`, 'DELETE');
};

const createNote = (ticket_id, data) => {
  return request(`${apiUrl}/tickets/${ticket_id}/messages`, 'POST', data);
};

const uploadFile = (data) => {
  return request(`${apiUrl}/upload?type=message`, 'POST', data);
};

const sendWaTemplateMessage = (data) => {
  return request(`${apiUrl}/wa_sessions`, 'POST', data);
};

export {
  postNoteDraftAttachment,
  deleteNoteDraftAttachment,
  fetchNoteDraft,
  createNoteDraft,
  deleteNoteDraft,
  createNote,
  uploadFile,
  sendWaTemplateMessage,
};
