import { PERMISSION } from '@/Configs/Constants';
import { NAVIGATION_ITEM_ID } from '@/Configs/Constants/navigation/navigationItemId';
import { SETTINGS_NAVIGATION } from '@/Configs/Constants/navigation/settingsNavigation';
import { SETTINGS_TRACKING_FEATURE_NAME } from '@/Configs/Constants/navigation/settingsNavigationTrackingId';

import type { NavigationGroupItem, NavigationItem } from '@/types/navigation';

const channelsGroupTitle = 'settings.navigation_channels';
const WA_TEMPLATE_URL = '/admin/wa_template';

const waTemplateItem: NavigationItem = {
  id: NAVIGATION_ITEM_ID.SETTINGS__ADMIN__WA_TEMPLATE,
  title: 'WhatsApp Template',
  url: WA_TEMPLATE_URL,
  permission: [PERMISSION.SETTINGS__CHANNELS__MANAGE],
  trackingId: SETTINGS_TRACKING_FEATURE_NAME.ADMIN__WA_TEMPLATE,
};

export const SETTINGS_TRACKING_NAVIGATION_ITEMS: readonly NavigationGroupItem[] = SETTINGS_NAVIGATION.map((group) =>
  group.title === channelsGroupTitle ? { ...group, items: [...group.items, waTemplateItem] } : group
);
