import { request } from '@/util/request';

import type {
  WhatsAppSandboxPayload,
  WhatsAppSandboxRequest,
  WhatsAppConnectionRequest,
  WhatsappTemplateMessage,
} from './types/whatsAppSandbox';
import type { AxiosPromise } from 'axios';

const baseUrl = '/api/v2/';
const endpoints = {
  waSandbox: `${baseUrl}wa/sandbox`,
  waConnection: `${baseUrl}whatsapp_sandbox/generate_url`,
  waTemplates: `${baseUrl}tickets`,
} as const;

export function postWhatsAppSandbox(payload: WhatsAppSandboxPayload): AxiosPromise<WhatsAppSandboxRequest> {
  const endpoint = endpoints.waSandbox;
  return request(endpoint, 'POST', payload);
}

export function getConnectionUrl(): AxiosPromise<WhatsAppConnectionRequest> {
  return request(endpoints.waConnection, 'GET');
}

export function postTemplateMessage(ticketId: string, payload: WhatsappTemplateMessage): AxiosPromise {
  const url = `${endpoints.waTemplates}/${ticketId}/messages`;
  return request(url, 'POST', payload);
}
