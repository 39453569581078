<template>
  <div>
    <div
      v-if="record.whatsappChannel?.provider === '360dialog' && action === 'edit'"
      class="mb-8 rounded-lg bg-white px-2 py-4 md:px-12"
    >
      <h1 class="my-4 font-bold">{{ $t('whatsapp.settings') }}</h1>
      <atomic-button btn-style="secondary" type="button" class="my-4" @click="goTo360dashboard">
        {{ $t('whatsapp.go_to_partner_dashboard') }}
      </atomic-button>
      <div v-if="record.whatsappChannel?.meta?.client_channel_id" class="mt-6">
        <h3 class="font-bold">{{ $t('whatsapp.status') }}</h3>
        <hr class="my-8 border-grey-300" />
        <whats-app360-dialog-status
          :channel="record"
          :phone-number="record.username"
          @reloadChannelIndexList="$emit('reloadChannelIndexList')"
        />
      </div>
    </div>
    <div
      v-if="
        record.whatsappChannel?.provider !== 'trengo_bsp' &&
        (action === 'create' ||
          (record.whatsappChannel?.provider !== 'trengo' &&
            record.whatsappChannel?.provider !== 'sandbox' &&
            action === 'edit'))
      "
      class="box"
    >
      <div class="box-header">
        <h2>{{ $t('whatsapp.settings') }}</h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <div v-if="action === 'create'" class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('whatsapp.provider') }}</label>
          <div class="col-sm-10">
            <label class="block">
              <input v-model="record.whatsappChannel.provider" type="radio" value="sandbox" />
              {{ $t('whatsapp.sandbox') }}
              <span class="text-muted"></span>
              <span class="text-muted">
                {{ $t('whatsapp.select_this_option_if_you_just_want_to_test_the_wa_business_integration') }}
              </span>
            </label>
            <label class="mt-2 block">
              <input id="360Option" v-model="record.whatsappChannel.provider" type="radio" value="360dialog" />
              {{ $t('whatsapp.360_dialog') }}
              <!--<label for="360Option" class="label success mb-0">Recommended</label>-->
              <span class="text-muted">
                {{ $t('whatsapp.select_this_option_if_you_already_have_an_active_whatsapp_number_via_360_dialog') }}
              </span>
            </label>
            <label v-show="showTrengoProvider" class="mt-2 block">
              <input id="TrengoOption" v-model="record.whatsappChannel.provider" type="radio" value="trengo" />
              {{ $t('whatsapp.trengo_via_business_partner') }}
              <span class="text-muted">
                {{ $t('whatsapp.select_this_option_if_you_are_ready_to_activate_whatsapp_on_your_own_number') }}
              </span>
            </label>
            <label class="mt-2 block">
              <input v-model="record.whatsappChannel.provider" type="radio" value="twilio" />
              {{ $t('whatsapp.twilio') }}
              <span class="text-muted">
                {{ $t('whatsapp.select_this_option_if_you_already_have_an_active_whatsapp_number_via_twilio') }}
              </span>
            </label>
            <label class="mt-2 block">
              <input v-model="record.whatsappChannel.provider" type="radio" value="messagebird" />
              {{ $t('whatsapp.messagebird') }}
              <span class="text-muted">
                {{ $t('whatsapp.select_this_option_if_you_already_have_an_active_whatsapp_number_via_messagebird') }}
              </span>
            </label>
          </div>
        </div>

        <div v-if="record.whatsappChannel?.provider === '360dialog'">
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.api_key') }}</label>
            <div class="col-sm-10">
              <input v-model="record.whatsappChannel.meta.api_key" type="text" class="form-control" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.address') }}</label>
            <div class="col-sm-10">
              <input
                v-model="record.whatsappChannel.meta.api_address"
                type="text"
                class="form-control"
                :placeholder="$t('whatsapp.httpswaba360dialogio')"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.namespace') }}</label>
            <div class="col-sm-10">
              <input v-model="record.whatsappChannel.meta.namespace" type="text" class="form-control" />
              <span class="form-text">
                {{ $t('whatsapp.only_required_when_sending_whatsapp_templates_360dialog') }}
                <router-link to="/admin/wa_templates" class="btn btn-xs btn-default">
                  {{ $t('whatsapp.manage_templates') }}
                </router-link>
              </span>
            </div>
          </div>
        </div>

        <div v-if="record.whatsappChannel?.provider === 'messagebird'">
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.api_key') }}</label>
            <div class="col-sm-10">
              <input v-model="record.whatsappChannel.meta.api_key" type="text" class="form-control" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.channel_id') }}</label>
            <div class="col-sm-10">
              <input v-model="record.whatsappChannel.meta.channel_id" type="text" class="form-control" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.phone_number') }}</label>
            <div class="col-sm-10">
              <input v-model="record.username" type="number" class="form-control" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.namespace') }}</label>
            <div class="col-sm-10">
              <input v-model="record.whatsappChannel.meta.namespace" type="text" class="form-control" />
              <span class="form-text">
                {{ $t('whatsapp.only_required_when_sending_whatsapp_templates_messagebird') }}
                <router-link to="/admin/wa_templates" class="btn btn-xs btn-default">
                  {{ $t('whatsapp.manage_templates') }}
                </router-link>
              </span>
            </div>
          </div>
        </div>

        <div v-if="record.whatsappChannel?.provider === 'twilio'">
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.twilio_account_sid') }}</label>
            <div class="col-sm-10">
              <input v-model="record.whatsappChannel.meta.twilio_account_sid" type="text" class="form-control" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.twilio_auth_token') }}</label>
            <div class="col-sm-10">
              <input v-model="record.whatsappChannel.meta.twilio_auth_token" type="text" class="form-control" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.phone_number') }}</label>
            <div class="col-sm-10">
              <input v-model="record.username" type="text" class="form-control" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.template_messages') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">
                <router-link to="/admin/wa_templates" class="btn btn-xs btn-default">
                  {{ $t('whatsapp.manage_templates') }}
                </router-link>
              </p>
            </div>
          </div>
        </div>

        <div v-if="record.whatsappChannel?.provider === 'cm'">
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.product_token') }}</label>
            <div class="col-sm-10">
              <input v-model="record.whatsappChannel.meta.product_token" type="text" class="form-control" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.phone_number') }}</label>
            <div class="col-sm-10">
              <input v-model="record.username" type="number" class="form-control" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="record.whatsappChannel?.provider === 'trengo' && action === 'create'" class="box">
      <div class="box-header b-b">
        <h2>{{ $t('whatsapp.request_access') }}</h2>
      </div>
      <div class="box-body b-b">
        <p v-html="markupSanitizer($t('whatsapp.check_before_requesting_access'))" />
      </div>
      <div class="box-body">
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('whatsapp.company_legal_entity_name') }}</label>
          <div class="col-sm-10">
            <input
              v-model="record.whatsappChannel.meta.profile_company_legal_name"
              type="text"
              class="form-control"
              required
            />
            <span class="form-text">
              {{ $t('whatsapp.the_legal_entity_name_of_your_company_for_example_trengo_bv') }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('whatsapp.business_description_and_use_case') }}</label>
          <div class="col-sm-10">
            <input v-model="record.whatsappChannel.meta.profile_use_case" type="text" class="form-control" required />
            <span class="form-text">
              {{ $t('whatsapp.this_will_be_used_for_the_compliance_and_approval_process') }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('whatsapp.business_category') }}</label>
          <div class="col-sm-10">
            <select v-model="record.whatsappChannel.meta.profile_vertical" class="form-control" required>
              <option value="Automotive">{{ $t('whatsapp.automotive') }}</option>
              <option value="Beauty, Spa and Salon">{{ $t('whatsapp.beauty_spa_and_salon') }}</option>
              <option value="Clothing and Apparel">{{ $t('whatsapp.clothing_and_apparel') }}</option>
              <option value="Education">{{ $t('whatsapp.education') }}</option>
              <option value="Event Planning and Service">{{ $t('whatsapp.event_planning_and_service') }}</option>
              <option value="Finance and Banking">{{ $t('whatsapp.finance_and_banking') }}</option>
              <option value="Food and Grocery">{{ $t('whatsapp.food_and_grocery') }}</option>
              <option value="Public Service">{{ $t('whatsapp.public_service') }}</option>
              <option value="Hotel and Lodging">{{ $t('whatsapp.hotel_and_lodging') }}</option>
              <option value="Medical and Health">{{ $t('whatsapp.medical_and_health') }}</option>
              <option value="Non-profit">{{ $t('whatsapp.non_profit') }}</option>
              <option value="Professional Services">{{ $t('whatsapp.professional_services') }}</option>
              <option value="Shopping and Retail">{{ $t('whatsapp.shopping_and_retail') }}</option>
              <option value="Travel and Transportation">{{ $t('whatsapp.travel_and_transportation') }}</option>
              <option value="Restaurant">{{ $t('whatsapp.restaurant') }}</option>
            </select>
            <span class="form-text">
              {{ $t('whatsapp.this_is_the_industryservice_category_that_your_business_is_in') }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('whatsapp.facebook_business_manager_id') }}</label>
          <div class="col-sm-10">
            <input
              v-model="record.whatsappChannel.meta.profile_business_manager_id"
              type="number"
              maxlength="16"
              minlength="16"
              class="form-control"
              required
            />
            <span class="text-muted">
              {{ $t('whatsapp.tie_your_whatsapp_business_profile_to_your_business_manager') }}
              <a
                style="text-decoration: underline"
                href="https://www.facebook.com/business/help/1181250022022158"
                target="_blank"
              >
                {{ $t('whatsapp.learn_how') }}
              </a>
              .
            </span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('whatsapp.whatsapp_business_phone_number') }}</label>
          <div class="col-sm-10">
            <tel-input v-model="record.whatsappChannel.meta.profile_number"></tel-input>
            <span class="form-text">
              {{ $t('whatsapp.it_will_be_the_number_that_your_customers_use_to_contact_your_business') }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('whatsapp.business_display_name') }}</label>
          <div class="col-sm-10">
            <input v-model="record.whatsappChannel.meta.profile_name" type="text" class="form-control" required />
            <span class="form-text">
              {{ $t('whatsapp.this_will_be_the_display_name_of_your_business_on_your_whatsapp_business_profile') }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('whatsapp.business_address') }}</label>
          <div class="col-sm-10">
            <input v-model="record.whatsappChannel.meta.profile_address" type="text" class="form-control" required />
            <span class="form-text">
              {{ $t('whatsapp.this_will_be_the_address_of_your_business_on_your_whatsapp_business_profile') }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('whatsapp.business_website') }}</label>
          <div class="col-sm-10">
            <input v-model="record.whatsappChannel.meta.profile_website" type="text" class="form-control" required />
            <span class="form-text">
              {{ $t('whatsapp.this_will_be_the_website_of_your_business_on_your_whatsapp_business_profile') }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">&nbsp;</label>
          <div class="col-sm-10">
            <label>
              <input v-model="record.whatsappChannel.meta.profile_terms" type="checkbox" required />
              <span v-html="markupSanitizer($t('whatsapp.i_accept_a_one_time_setup_fee', { cost: '45,00' }))" />
            </label>
            <br />
            <label>
              <input type="checkbox" required />
              <span
                v-html="
                  markupSanitizer(
                    $t('whatsapp.i_accept_the_recurring_whatsapp_fee', { monthly: '45,00', yearly: '540,00' })
                  )
                "
              />
            </label>
            <br />
            <label>
              <input type="checkbox" required />
              <span v-html="markupSanitizer($t('whatsapp.i_accept_the_policies_and_terms'))" />
            </label>
            <br />
            <label>
              <input type="checkbox" required />
              <span v-html="markupSanitizer($t('whatsapp.i_am_aware_of_the_limitations'))" />
            </label>
            <br />
          </div>
        </div>
      </div>
    </div>

    <div v-if="record.whatsappChannel?.provider === 'trengo' && !isSubscribed && action === 'create'">
      <div class="box">
        <div class="box-body">
          <div
            class="border-l-4 bg-yellow-light p-4"
            style="border-color: #e3a008; background: #fdfdea; color: #8e4b10"
          >
            <div class="flex">
              <div class="flex-shrink-0">
                <i class="fa fa-exclamation-circle" style="color: #e3a008; font-size: 18px; margin-top: 3px"></i>
              </div>
              <div class="ml-3">
                <span style="font-size: 16px; font-weight: 500">{{ $t('whatsapp.upgrade_your_subscription') }}</span>
                <p class="mt-2 text-sm">
                  {{ $t('whatsapp.in_order_to_use_whatsapp_business_you_need_a_subscription_for_scale_or_enterprise') }}
                  <a
                    v-if="userStore.hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)"
                    class="
                      text-yellow-700
                      hover:text-yellow-600
                      font-medium
                      underline
                      transition
                      duration-150
                      ease-in-out
                    "
                    style="text-decoration: underline"
                    @click="goToSubscription"
                  >
                    {{ $t('whatsapp.upgrade_your_account') }}
                  </a>
                  .
                </p>
                <div class="mt-2" style="opacity: 0.8; font-size: 11px; font-style: italic">
                  {{ $t('whatsapp.note_your_form_submission_will_be_remembered') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="sandbox && action !== 'info'" class="box">
      <div class="box-header">
        <h2>{{ $t('whatsapp.sandbox_settings') }}</h2>
      </div>
      <div class="box-body">
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('whatsapp.your_demo_number') }}</label>
          <div class="col-sm-10">
            <p class="form-control-static font-weight-bold">
              <a
                style="text-decoration: underline"
                href="https://api.whatsapp.com/send?phone=31850014188"
                target="_blank"
              >
                +31 850 014 188
              </a>
            </p>
            <span class="text-muted">
              {{ $t('whatsapp.use_this_number_to_test_the_whatsapp_business') }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('whatsapp.phone_numbers') }}</label>
          <div class="col-sm-10">
            <div class="flex">
              <tel-input
                v-model="sandbox_phone"
                type="text"
                style="width: 300px"
                :submit-on-enter="false"
                :placeholder="$t('whatsapp.for_example_31612345678')"
                class=""
                @blur="addNumberToSandbox"
                @enter="addNumberToSandbox"
              />
              <button
                type="button"
                class="bnt success rounded"
                style="border-top-left-radius: 0; border-bottom-left-radius: 0; margin-left: -2px; width: 50px"
                @click="addNumberToSandbox"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
            <span class="form-text">
              {{ $t('whatsapp.add_numbers_for_testing_purposes_to_receive_and_send_whatsapp_messages') }}
            </span>
            <ul v-if="record.waSandboxNumbers?.length" class="list-group mt-2">
              <li v-for="item in record.waSandboxNumbers" class="list-group-item mr-2" style="display: inline-block">
                {{ item.phone }}
                <i class="text-muted fa fa-remove" @click="deleteFromSandbox(item)"></i>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!sandbox && action === 'edit'" class="box">
      <div class="box-header">
        <h2>{{ $t('whatsapp.sms_fallback') }}</h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('whatsapp.sms_fallback_channel') }}</label>
          <div class="col-sm-10">
            <select v-model="record.whatsappChannel.sms_channel_id" class="form-control">
              <option :value="null">{{ $t('whatsapp.none_messages_will_not_be_delivered_after_24_hours') }}</option>
              <option v-for="channel in smsChannels" :value="channel.id">{{ channel.title }}</option>
            </select>
            <span class="form-text">
              {{ $t('whatsapp.the_sms_fallback_channel_for_sending_messages_outside_the_24_hour') }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="action === 'info' && record.whatsappChannel?.provider === 'trengo'" class="box">
      <div class="box-header">
        <h2>{{ $t('whatsapp.whatsapp_business') }}</h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <div v-if="record.whatsappChannel?.meta?.provisioned && !record.whatsappChannel?.meta?.verified">
          <div>
            <div class="alert alert-success">
              {{ $t('whatsapp.you_request_has_been_approved_you_can_may_now_activate_your_number') }}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.activate') }}</label>
            <div class="col-sm-10">
              <button type="button" class="btn btn-default" @click="registerNumber">
                {{ $t('whatsapp.activate_number') }}
              </button>
            </div>
          </div>
          <div></div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('whatsapp.phone_number') }}</label>
          <div class="col-sm-10">
            <p class="form-control-static">
              {{ record.username }}
              <label
                v-if="record.whatsappChannel?.meta?.provisioned && record.whatsappChannel?.meta?.verified"
                class="label success"
              >
                {{ $t('whatsapp.active') }}
              </label>
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('whatsapp.namespace') }}</label>
          <div class="col-sm-10">
            <input v-model="record.whatsappChannel.meta.namespace" type="text" class="form-control" />
            <span class="form-text">
              {{ $t('whatsapp.only_required_when_sending_whatsapp_templates_to_initiate_conversations') }}
              <router-link to="/admin/wa_templates/create" class="btn btn-xs btn-default">
                {{ $t('whatsapp.manage_templates') }}
              </router-link>
            </span>
          </div>
        </div>
        <div v-if="!record.whatsappChannel?.meta?.provisioned && !record.whatsappChannel?.meta?.deleted">
          <div
            class="border-l-4 bg-yellow-light p-4"
            style="border-color: #1a56db; background: #ebf5ff; color: #1a56db"
          >
            <div class="flex">
              <div class="flex-shrink-0">
                <i class="fa fa-exclamation-circle" style="color: #1a56db; font-size: 18px; margin-top: 3px"></i>
              </div>
              <div class="ml-3">
                <span style="font-size: 16px; font-weight: 500">{{ $t('whatsapp.thank_you_for_your_request') }}</span>
                <p class="mt-2 text-sm">
                  {{
                    $t('whatsapp.in_order_to_proceed_please_read_sign_the_whatsapp_agreement_we_have_sent_to', {
                      email: $root.user.email,
                    })
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="record.whatsappChannel?.meta?.provisioned && record.whatsappChannel?.meta?.verified">
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.profile') }}</label>
            <div class="col-sm-10">
              <button type="button" class="btn btn-default" @click="manageProfile">
                {{ $t('whatsapp.manage_business_profile') }}
              </button>
            </div>
          </div>
          <wa-profile-modal
            v-if="showManageBusinessProfileModal"
            ref="profileModal"
            :channel="record.whatsappChannel"
          ></wa-profile-modal>
        </div>
        <div v-if="record.whatsappChannel?.meta?.deleted">
          <div class="alert alert-warning">
            {{ $t('whatsapp.this_channel_has_been_deactivated_you_may_delete_this_channel') }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="action === 'info' && !sandbox && record.whatsappChannel?.provider !== 'trengo'" class="box">
      <div class="box-header">
        <h2>{{ $t('whatsapp.whatsapp_business') }}</h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('whatsapp.phone_number') }}</label>
          <div class="col-sm-10">
            <p class="form-control-static">
              {{ record.username || record.phone }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('whatsapp.provider') }}</label>
          <div class="col-sm-10">
            <p class="form-control-static">
              {{ record.whatsappChannel?.provider }}
            </p>
          </div>
        </div>

        <div v-if="record.whatsappChannel?.provider === 'facebook'">
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.api_key') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">
                {{ record.whatsappChannel?.meta?.api_key }}
              </p>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.channel') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">
                <a
                  class="text-info"
                  target="_blank"
                  :href="
                    'https://dashboard.messagebird.com/en/channels/whatsapp/' + record.whatsappChannel?.meta?.channel_id
                  "
                >
                  <i class="fa fa-external-link"></i>
                  {{ record.whatsappChannel?.meta?.channel_id }}
                </a>
              </p>
            </div>
          </div>
        </div>

        <div v-if="record.whatsappChannel?.provider === 'twilio'">
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.twilio_account_sid') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">
                {{ record.whatsappChannel?.meta?.twilio_account_sid }}
              </p>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.twilio_auth_token') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">
                {{ record.whatsappChannel?.meta?.twilio_auth_token }}
              </p>
            </div>
          </div>
        </div>

        <div v-if="record.whatsappChannel?.provider === 'cm'">
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.product_token') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">
                {{ record.whatsappChannel?.meta?.product_token }}
              </p>
            </div>
          </div>
        </div>

        <div v-if="record.whatsappChannel?.provider === 'trengo_bsp'">
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.waba_id') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">
                {{ record.whatsappChannel?.meta?.wabaId }}
              </p>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.business_id') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">
                {{ record.whatsappChannel?.meta?.businessId }}
              </p>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.phone_id') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">
                {{ record.whatsappChannel?.meta?.phoneId }}
              </p>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('whatsapp.business_verified') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">
                {{ record.whatsappChannel?.approved_at || $t('whatsapp.check_your_meta_suite') }}
              </p>
            </div>
          </div>
        </div>

        <div
          v-if="
            record.whatsappChannel?.provider !== 'trengo' &&
            record.whatsappChannel?.provider !== '360dialog' &&
            record.whatsappChannel?.provider !== 'trengo_bsp'
          "
          class="form-group row"
        >
          <label class="col-sm-2 form-control-label">{{ $t('whatsapp.webhook') }}</label>
          <div class="col-sm-10">
            <p class="form-control-static">https://app.trengo.com/hook/wa_business?channel={{ record.password }}</p>
          </div>
        </div>
      </div>
    </div>

    <wa-registration-modal
      ref="registerModal"
      :phone="record.phone"
      :channel="record.whatsappChannel"
    ></wa-registration-modal>
  </div>
</template>

<script lang="ts">
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import { fetchCustomerSubscription } from '@/api/modules/subscriptionChargebee';
import { PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import eventBus from '@/eventBus';
import { useCompanyProfileStore, useUserStore } from '@/store/pinia';
import markupSanitizer from '@/util/markupSanitizer';

import WaProfileModal from './WaProfileModal';
import WaRegistrationModal from './WaRegistrationModal';
import WhatsApp360DialogStatus from './WhatsApp/WhatsApp360DialogStatus';
export default defineComponent({
  name: 'WhatsAppBusiness',
  emits: [
    'reloadChannelIndexList',
    'update-ready',
    'update-show-footer',
    'set-create-text',
    'call-init-warning-method',
  ],
  props: {
    action: {
      type: String,
      default: 'create',
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    ready: {
      type: Boolean,
      default: true,
    },
    scrollableContainerRef: {
      type: Element,
      default: null,
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    WhatsApp360DialogStatus,
    WaRegistrationModal,
    WaProfileModal,
  },

  watch: {
    'record.whatsappChannel.provider'(v) {
      if (v === 'trengo') {
        this.checkApplicableForWhatsApp();
        this.restorePendingSubmission();
      } else {
        eventBus.emit('show-channel-edit-footer');
      }
      this.$nextTick(() => {
        this.scrollableContainerRef.scrollTop = this.scrollableContainerRef.scrollHeight;
      });
    },
  },

  data() {
    return {
      smsChannels: [],
      sandbox_phone: '',
      showManageBusinessProfileModal: false,
      isSubscribed: false,
      PERMISSION,
    };
  },

  computed: {
    ...mapStores(useCompanyProfileStore, useUserStore),
    sandbox() {
      return this.record.whatsappChannel?.provider === 'sandbox';
    },
    showCmProvider() {
      return this.$route.query.showCm;
    },
    showTrengoProvider() {
      return this.$route.query.showTrengo;
    },
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },

  mounted() {
    if (this.action !== 'info') {
      this.getSmsChannels();
    }

    if (this.$route.query.provider) {
      this.record.whatsappChannel.provider = this.$route.query.provider;
    }

    if (this.action === 'create') {
      this.record.title = 'WhatsApp Business';
      if (this.record.whatsappChannel?.provider !== 'trengo_bsp') {
        this.$emit('set-create-text', 'Submit application');
        this.createText = 'Submit application';
      }
    }

    if (this.$route.query.profile) {
      this.manageProfile();
    }
  },

  methods: {
    markupSanitizer: markupSanitizer,

    checkApplicableForWhatsApp() {
      fetchCustomerSubscription().then((data) => {
        this.isSubscribed = data.plan.level >= 3;
        if (!this.isSubscribed && this.companyProfileStore.isSeatBasedPricingModel) {
          this.$emit('update-ready', false);
          this.$emit('update-show-footer', false);
        }
      });
    },
    getSmsChannels() {
      axios.get('/api/v2/channels?type=sms').then((res) => {
        this.smsChannels = res.data.data;
      });
    },
    addNumberToSandbox(e) {
      if (this.sandbox_phone.length < 10) {
        return;
      }
      this.record.waSandboxNumbers.push({
        phone: this.sandbox_phone,
      });
      this.sandbox_phone = '';
    },
    deleteFromSandbox(item) {
      this.record.waSandboxNumbers.splice(this.record.waSandboxNumbers.indexOf(item), 1);
    },
    registerNumber() {
      $(this.$refs.registerModal.$refs.modal).modal('show');
    },
    manageProfile() {
      this.showManageBusinessProfileModal = true;
      this.$nextTick(() => {
        $(this.$refs.profileModal.$refs.modal).modal('show');
      });
    },
    goToSubscription() {
      this.$emit('call-init-warning-method', false);
      this.$tStorage.setItem('wa_submission', this.record.whatsappChannel.meta);
      this.$tStorage.setItem('return_path', '/admin/channels2/wa_business/create?provider=trengo');
      router.push(SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS);
    },
    async restorePendingSubmission() {
      if (await this.$tStorage.getItem('wa_submission')) {
        this.record.whatsappChannel['meta'] = await this.$tStorage.getItem('wa_submission');
        this.$tStorage.removeItem('wa_submission');
      }
    },
    goTo360dashboard() {
      window.open('https://hub.360dialog.com/auth/login', '_blank', 'noopener');
    },
  },
});
</script>
