<template>
  <gray-card>
    <div class="mb-8 flex flex-row items-center">
      <div
        class="flex h-[44px] w-[44px] items-center justify-center rounded-xl bg-galaxy-600 p-2 sm:h-[48px] sm:w-[48px]"
      >
        <img class="w-24" :src="`${$root.assetsURL}img/add-new-plugin.svg`" aria-hidden="true" alt="" />
      </div>

      <div class="ml-4 flex flex-col">
        <div class="t-text-mobile-body-md sm:t-text-button-lg sm:mb-1">
          {{ $t('getting_started.title_add_an_integration') }}
        </div>
        <div class="t-text-desktop-body-xs sm:t-text-desktop-body-sm text-grey-800">
          {{ $t('getting_started.subtitle_add_an_integration') }}
        </div>
      </div>
    </div>

    <div class="mb-4 grid grid-cols-1 gap-2 sm:mb-3 lg:grid-cols-4">
      <item-button
        v-for="integration in shownIntegrations"
        :key="integration.id"
        @click="navigateToIntegration(integration)"
      >
        <integration-card-icon class="mb-1" :icon="integration.icon" />
        <span class="t-text-button-sm">{{ integration.name }}</span>
      </item-button>
    </div>

    <router-link
      class="t-text-button-md text-leaf-500 hover:text-leaf-500 hover:underline"
      to="/admin/integrations/all"
    >
      {{ $t('getting_started.button_see_all') }}
    </router-link>
  </gray-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import IntegrationCardIcon from '@/components/IntegrationHub/Components/IntegrationCardIcon.vue';
import GrayCard from '@/components/OverviewPage/components/GrayCard';
import ItemButton from '@/components/OverviewPage/components/ModalItem/ItemButton';

import type { Integration } from '@/store/types/integrations';

export default defineComponent({
  name: 'IntegrationHubCards',

  components: {
    GrayCard,
    ItemButton,
    IntegrationCardIcon,
  },

  computed: {
    ...mapGetters({
      integrations: 'integrations/getIntegrations',
    }),

    shownIntegrations(): Integration[] {
      if (this.integrations.length === 0) {
        return [];
      }
      // TODO: Not sure how we'll handle what we consider to be the "top" integrations yet, for now this is just random
      const numbers = new Set<number>();
      while (numbers.size < 4) {
        numbers.add(Math.floor(Math.random() * this.integrations.length));
      }

      const integrations: Integration[] = [];

      numbers.forEach((number) => {
        integrations.push(this.integrations[number]);
      });

      return integrations;
    },
  },

  async created() {
    await this.$store.dispatch('integrations/fetchIntegrations');
  },

  methods: {
    navigateToIntegration(integration: Integration) {
      this.$router.push(`/admin/integrations/${integration.filterType}/${integration.id}`);
    },
  },
});
</script>
