<template>
  <span class="flex items-center" data-test="feature-feedback">
    <ThumbsFeedback
      :is-disabled="isDisabled"
      :is-pressed="isPositiveFeedback === true"
      :tooltip-text="t('tickets.response_good')"
      :is-positive="true"
      @feedback="handleFeedback"
    />
    <ThumbsFeedback
      :is-disabled="isDisabled"
      :is-pressed="isPositiveFeedback === false"
      :tooltip-text="t('tickets.response_bad')"
      :is-positive="false"
      @feedback="handleFeedback"
    />
    <Transition name="fade">
      <FeedbackModal
        v-if="feedbackId"
        :id="feedbackId"
        v-model="modal.isModalOpen.value"
        @close="modal.closeModal"
        @send="sendFeedback"
      />
    </Transition>
    <Transition name="transition" mode="out-in">
      <span :key="text.length">{{ text }}</span>
    </Transition>
  </span>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { FEEDBACK_TYPE } from '@/api/constants';
import { postInboxSuggestedReplyFeedback } from '@/api/modules/suggestedReply';
import { useModal } from '@/composables/useModal';

import FeedbackModal from './FeedbackModal.vue';
import ThumbsFeedback from './ThumbsFeedback.vue';
import { API_INBOX_AI } from '../api/const';

import type { FeedbackPayload } from './types';

const { t } = useI18n();

const props = defineProps<{
  ticketId: number;
}>();

const isPositiveFeedback = ref<boolean | null>(null);
const status = ref<'ERROR' | 'PENDING' | 'INITIAL' | 'SUCCESS'>('INITIAL');
const feedbackId = ref<number>();

const modal = useModal();

const text = computed(() => {
  if (status.value === 'ERROR') return t('general.error_something_went_wrong');

  return isPositiveFeedback.value === null
    ? t('tickets.suggest_reply_feedback_prompt')
    : t('tickets.suggest_reply_feedback_result');
});

const handleFeedback = async (isPositive: boolean) => {
  if (!isPositive) {
    modal.isModalOpen.value = true;
  }

  isPositiveFeedback.value = isPositive;
  status.value = 'PENDING';

  const payload = {
    ticket_id: props.ticketId,
    approved: isPositive,
    type: FEEDBACK_TYPE.SUGGEST_REPLIES,
  };

  try {
    const res = await postInboxSuggestedReplyFeedback(payload);
    feedbackId.value = res.data.identifier;
    status.value = 'SUCCESS';
  } catch {
    status.value = 'ERROR';
  } finally {
    status.value = 'SUCCESS';
  }
};

const sendFeedback = (data: FeedbackPayload) => {
  try {
    axios.post<FeedbackPayload>(`${API_INBOX_AI}/update_feedback`, data);
  } catch {
    throw new Error('Failed to send feedback');
  } finally {
    modal.closeModal();
  }
};

const isDisabled = computed(() => status.value === 'SUCCESS' || status.value === 'PENDING' || status.value === 'ERROR');
</script>

<style src="./animation.css" scoped />
