<script type="text/babel">
import _ from 'lodash';

export default {
  props: {
    contact: {},
    title: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      selected: [],
    };
  },

  mounted: function () {
    this.setSelected();
  },

  methods: {
    setSelected() {
      this.selected = _.map(this.contact.groups, (group) => {
        return group.id;
      });
    },

    save() {
      axios.put('/api/v2/contacts/' + this.contact.id, { contact_group_ids: this.selected });
    },
  },
};
</script>

<template>
  <div v-if="$root.contactGroups.length > 0" class="text-left">
    <h6 v-if="title" class="mb-4">{{ $t('contacts.groups') }}</h6>
    <div v-for="contactGroup in $root.contactGroups">
      <label style="margin-bottom: 3px">
        <input v-model="selected" type="checkbox" :value="contactGroup.id" @change="save" />
        {{ contactGroup.name }}
      </label>
    </div>
  </div>
</template>
