<template>
  <li class="list-none">
    <t-popover v-model="isOpen" class="relative" click-outside-close>
      <template #trigger>
        <t-list-item :label="$t('reports_v2.agents_title')" class="relative" :active="isOpen">
          <template #postfix>
            <t-badge v-if="agentsCount > 0" class="flex items-center" :text="agentsCount" />
            <caret-down-fill size="1.25rem" class="mt-1 -rotate-90 text-grey-600" />
          </template>
        </t-list-item>
      </template>

      <template #content>
        <div
          class="
            filter-dropdown-second-layer
            absolute
            z-50
            mt-2
            min-h-[258px]
            w-92
            rounded-xl
            border border-grey-300
            bg-white
            shadow-500
          "
        >
          <t-input-search
            key="search-teams"
            v-model="search"
            variant="primary-alt"
            class="m-2"
            @clear="search = ''"
            @input="onSearch"
          />
          <t-divider class="mb-2" />
          <ul
            class="w-full overflow-x-hidden overflow-y-scroll px-2"
            :class="[containerHeight, { 'min-h-[8.25rem]': agentsLengthLessThanEight }]"
          >
            <t-list-item-checkbox
              v-for="agent in filteredAgents"
              :key="agent.id"
              :model-value="selectedItems.includes(agent.id)"
              :label="agent.full_name"
              @update:modelValue="handleSelectItem(agent)"
            />

            <infinite-loading v-if="!search" @infinite="fetchMoreData">
              <template #spinner></template>
              <template #no-more><div></div></template>
              <template #no-results><div></div></template>
            </infinite-loading>
          </ul>

          <results-not-found v-if="!agents.length" text="No agents found" />

          <t-divider />
          <div class="flex justify-end px-3 py-3">
            <t-button size="sm" btn-style="secondary" class="mr-2" @click="clearFilters">
              {{ $t('reports_v2.clear') }}
            </t-button>
            <t-button size="sm" @click="applyFilters">{{ $t('reports_v2.confirm') }}</t-button>
          </div>
        </div>
      </template>
    </t-popover>
  </li>
</template>

<script lang="ts">
import { CaretDownFill } from '@trengo/trengo-icons';
import { debounce } from 'lodash';
import { defineComponent } from 'vue';
import infiniteLoading from 'vue-infinite-loading';

import { getPaginatedUserList } from '@/components/Users/api';
import breakpoints from '@/util/breakpoints';

import ResultsNotFound from './ResultsNotFound.vue';

import type { StateChanger } from 'vue-infinite-loading';

const ITEM_LIST_PAGE_SIZE = 15;
const MAX_HEIGHT = 600;

type Agent = {
  id: number;
  first_name: string;
  last_name: string;
  name: string;
  full_name: string;
};

type Data = {
  isOpen: boolean;
  search: string;
  selectedItems: number[];
  breakpoints: typeof breakpoints;
  agents: Agent[];
  page: number;
  allDataLoaded: boolean;
};

export default defineComponent({
  name: 'Agents',
  emits: ['filter-updated'],
  components: { CaretDownFill, ResultsNotFound, infiniteLoading },
  data(): Data {
    return {
      isOpen: false,
      search: '',
      selectedItems: [],
      breakpoints,
      agents: [],
      page: 1,
      allDataLoaded: false,
    };
  },

  props: {
    activeAgents: {
      type: Array,
      default: () => [],
    },
  },

  beforeMount() {
    this.selectedItems = [...this.activeAgents];
    this.getAgentsList();
  },

  computed: {
    agentsCount() {
      return this.selectedItems.length;
    },
    containerHeight() {
      const heightClass = breakpoints.h < MAX_HEIGHT ? 'filter-items-wrapper-200' : 'filter-items-wrapper-335';
      return heightClass;
    },
    filteredAgents() {
      return this.agents;
    },
    agentsLengthLessThanEight() {
      return this.agents.length > 0 && this.agents.length <= 8;
    },
  },

  methods: {
    getAgentsList() {
      getPaginatedUserList({ sort: 'first_name', page: this.page })
        .then(({ data }) => {
          this.agents = data?.data;
        })
        .catch((e) => {
          console.error('Error while fetching users: ', e);
        });
    },
    findUsers: debounce(function (query: string, callback: (agents?: Agent[], error?: Error) => void) {
      getPaginatedUserList({ sort: 'first_name', term: query })
        .then(({ data }) => {
          callback(data?.data);
        })
        .catch((e) => {
          console.error('Error while fetching users: ', e);
          callback([], e);
        });
    }),
    handleSelectItem(agent: Agent) {
      const id = agent?.id;
      if (this.selectedItems.includes(id)) {
        this.selectedItems = this.selectedItems.filter((item: number) => item !== id);
      } else {
        this.selectedItems.push(id);
      }
    },
    applyFilters() {
      this.$emit('filter-updated', { user_ids: this.selectedItems });
    },
    clearFilters() {
      this.selectedItems = [];
      this.applyFilters();
    },
    getAgents() {
      this.page = 1;
      if (this.search) {
        this.findUsers(this.search, (agents: Agent[], error: Error) => {
          if (error) {
            console.error('Error while fetching agents: ', error);
          } else {
            this.agents = agents;
          }
        });
      } else {
        this.getAgentsList();
      }
    },
    async fetchMoreData(scroll: StateChanger) {
      if (this.allDataLoaded) {
        scroll.complete();
        return;
      }

      if (this.page === 1) {
        this.page++;
      }

      try {
        const newAgents = await getPaginatedUserList({ sort: 'first_name', page: this.page });

        if (newAgents.data.data.length < ITEM_LIST_PAGE_SIZE) {
          this.allDataLoaded = true;
          scroll.complete();
        } else {
          this.page++;
          scroll.loaded();
        }

        this.agents = [...this.agents, ...newAgents.data.data];
      } catch (error) {
        console.log(error);
        scroll.complete();
      }
    },
    onSearch(value: string) {
      this.search = value;
    },
  },
  watch: {
    search() {
      this.getAgents();
    },
  },
});
</script>
