<template>
  <div
    class="flex flex-col absolute bg-white text-black border border-grey-400 rounded-lg shadow-full z-[99999999] p-3 min-w-max"
    @click.stop
  >
    <div class="flex flex-col mb-2">
      <p class="font-semibold mb-2 p-0">{{ t('tickets.filter_by_ticket_creation_date') }}</p>

      <label class="mr-1 my-0 p-0 text-grey-800 font-semibold" for="start">{{ t('general.start') }}</label>
      <input
        id="start"
        class="border border-grey-400 rounded-md"
        type="date"
        :value="start"
        autofocus
        @change="(e) => handleDateChange('start', e)"
      />
    </div>

    <div class="flex flex-col mb-2">
      <label class="mr-1 my-0 p-0 text-grey-800 font-semibold" for="end">{{ t('general.end') }}</label>
      <input
        id="end"
        class="border border-grey-400 rounded-md"
        type="date"
        :value="end"
        @change="(e) => handleDateChange('end', e)"
      />
    </div>

    <div class="flex flex-col">
      <p class="mr-1 my-0 p-0 cursor-pointer text-grey-800 font-semibold" for="labels">
        {{ t('tickets.label_status') }}
      </p>

      <div class="flex items-center">
        <label for="defaultLabels" class="mr-3 my-0 p-0 cursor-pomter text-grey-800 whitespace-nowrap cursor-pointer">
          {{ t('tickets.default_labels') }}
        </label>
        <input id="defaultLabels" v-model="labelStatus" class="ml-auto" type="radio" value="default" />
      </div>

      <div class="flex items-center">
        <label for="unlabelled" class="mr-3 my-0 p-0 cursor-pomter text-grey-800 whitespace-nowrap cursor-pointer">
          {{ t('tickets.unlabelled') }}
        </label>
        <input id="unlabelled" v-model="labelStatus" class="ml-auto" type="radio" value="unlabelled" />
      </div>
    </div>

    <t-button size="sm" class="my-2" @click="handleApply">{{ t('tickets.apply_filters') }}</t-button>
    <t-button size="sm" btn-style="secondary" @click="handleClear">{{ t('tickets.clear_filters') }}</t-button>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  dates: [string, string];
  labels: 'unlabelled' | 'default';
}>();

const start = ref<string>(props.dates?.[0] ?? '');
const end = ref<string>(props.dates?.[1] ?? '');
const labelStatus = ref<'unlabelled' | 'default'>('default');

const emit = defineEmits<{
  selected: [dates: [string, string], labelStatus: 'unlabelled' | 'default'];
  clear: [];
  close: [];
}>();

const handleDateChange = (direction: 'start' | 'end', event: Event) => {
  const date = (event.target as HTMLInputElement).value;

  if (direction === 'start') {
    start.value = date;
  } else {
    end.value = date;
  }
};

function handleApply() {
  emit('selected', [start.value, end.value], labelStatus.value);
  emit('close');
}

function handleClear() {
  emit('clear');
  emit('close');
}
</script>
