<template>
  <t-multi-choice
    v-model="headerType"
    :disabled="disabled"
    :stretch="true"
    class="my-5 flex w-full justify-between gap-x-4"
    child-class="w-1/2"
    @update:modelValue="handleSelectedHeader"
  >
    <template #text="{ selected }">
      <t-multi-choice-button
        title="Text"
        desc="Title header"
        :selected="selected"
        vertical
        :show-check-icon="false"
        :disabled="disabled"
      >
        <template #icon>
          <text-linear selected size="1.5rem" />
        </template>
      </t-multi-choice-button>
    </template>
    <template #image="{ selected }">
      <t-multi-choice-button
        title="Image"
        desc="JPG or PNG"
        vertical
        :selected="selected"
        :show-check-icon="false"
        :disabled="disabled"
      >
        <template #icon>
          <image-3-linear selected size="1.5rem" />
        </template>
      </t-multi-choice-button>
    </template>
  </t-multi-choice>
</template>

<script lang="ts">
import { Image3Linear, TextLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import { TEMPLATE_HEADER_TYPE } from '@/Configs/Constants/whatsapp';

import type { TemplateHeaderType } from '../types';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'MultiChoiceCards',
  emits: ['handle-selected-header'],
  components: { Image3Linear, TextLinear },
  props: {
    selectedHeaderType: {
      type: String as PropType<TemplateHeaderType>,
      default: TEMPLATE_HEADER_TYPE.TEXT,
      validator: (value: string) => {
        return Object.values(TEMPLATE_HEADER_TYPE).includes(value as TemplateHeaderType);
      },
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data(): { headerType: TemplateHeaderType } {
    return {
      headerType: this.selectedHeaderType as TemplateHeaderType,
    };
  },
  methods: {
    handleSelectedHeader(item: TemplateHeaderType) {
      this.$emit('handle-selected-header', item);
    },
  },
  watch: {
    selectedHeaderType(val: TemplateHeaderType) {
      this.headerType = val;
    },
  },
});
</script>
