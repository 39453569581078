<template>
  <div class="h-full w-full pt-4" :class="wrapperClass">
    <div v-if="showBack" class="mb-1 flex items-center">
      <button
        class="
          flex
          w-full
          flex-row
          items-center
          rounded-md
          p-2
          text-grey-700
          transition-colors
          hover:bg-grey-200 hover:text-grey-900
        "
        @click.prevent="$router.push('/admin')"
      >
        <arrow-left-linear size="1.3rem" class="mr-2" />

        <p class="t-text-sm m-0 p-0">{{ $tc('general.back_to_settings') }}</p>
      </button>
    </div>

    <t-divider v-if="showBack" class="mb-2 mt-1" />

    <div v-if="hasHeaderListItem" data-test="header-wrapper">
      <ul class="m-0 p-0">
        <t-list-item
          :label="headerListItem.label"
          :active="headerListItem.isActive"
          size="sm"
          @click="goToRoute(headerListItem.route)"
        >
          <template #prefix>
            <arrow-left-linear size="0.938rem" />
          </template>
        </t-list-item>
      </ul>
      <t-divider class="py-1" />
    </div>

    <div v-for="(item, index) in listItems" :key="index" data-test="items-wrapper">
      <div class="flex flex-row items-center justify-between">
        <p
          v-if="item.title"
          class="t-text-md-emphasize mb-1 truncate text-ellipsis px-2 py-1"
          data-test="list-item-title"
        >
          {{ item.title }}
        </p>
      </div>

      <ul class="p-0">
        <t-list-item
          v-for="(child, childIndex) in item.children"
          :key="childIndex"
          size="sm"
          class="mb-1"
          :class="{ firstItem: childIndex === 0 && !item.title }"
          :label="child.label"
          :active="child.isActive"
          tabindex="0"
          @click="goToRoute(child.route)"
        >
          <template v-if="child.badgeCount" #postfix>
            <t-badge class="flex items-center" :text="child.badgeCount" />
          </template>
        </t-list-item>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { ArrowLeftLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import type { ListItem, ListItemChildren } from './types';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'SettingsSidebar',

  components: { ArrowLeftLinear },

  props: {
    alt: {
      type: Boolean,
      default: false,
    },

    showBack: {
      type: Boolean,
      default: false,
    },

    listItems: {
      type: Array as PropType<ListItem[]>,
      default: () => [] as ListItem[],
    },

    headerListItem: {
      type: Object as PropType<ListItemChildren>,
      default: () => ({} as ListItemChildren),
    },
  },

  computed: {
    wrapperClass() {
      return this.alt ? 'pl-5' : 'pl-3 bg-white';
    },

    hasHeaderListItem() {
      return Object.keys(this.headerListItem).length > 0;
    },
  },

  methods: {
    goToRoute(route: string) {
      this.$router.push(route);
    },
  },
});
</script>

<style scoped lang="scss">
.firstItem {
  @apply mt-4;
}
</style>
