import { request } from '@/util/request';

import { ENDPOINT } from '../constants';
import { mockedUsageOverview } from '../mocks/usage';

import type { ConversationType, PeriodType } from '@/types';
import type { AxiosPromise } from 'axios';

export type ConversationBreakdownItem = {
  conversation_type: ConversationType;
  finished_conversations_count: number;
  in_progress_conversations_count: number;
  commitment_in_bundles: number;
};

export type CarrierCostsItem = {
  used: number;
  commitment_in_bundles: number;
  carried_over_bundles?: number;
};

export type PeriodBreakdownItem = {
  period_type: PeriodType;
  start_date: EpochTimeStamp;
  end_date: EpochTimeStamp;
  conversations: ConversationBreakdownItem[];
  carrierCosts: CarrierCostsItem;
};

export type UpcomingPeriodBreakdown = {
  upcoming_standard_conversations_count: number;
  upcoming_ai_conversations_count: number;
};

export type AgencyUsageResponse = {
  upcoming_period_breakdown: UpcomingPeriodBreakdown | null;
  current_period_breakdown: PeriodBreakdownItem | null;
  previous_periods_break_down: PeriodBreakdownItem[] | null;
};

export const fetchUsageOverview = (): AxiosPromise<AgencyUsageResponse> => request(ENDPOINT.USAGE_BREAKDOWN, 'GET');

export const fetchMockedUsageOverview = (): AxiosPromise<AgencyUsageResponse> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: mockedUsageOverview,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {},
      });
    }, 1000);
  });
};
