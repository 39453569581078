<script>
export default {
  name: 'DateRangePicker',
  emits: ['update:modelValue'],
  props: {
    format: {
      type: String,
      default: 'YYYY-MM-DD HH:mm',
    },
    displayFormat: {
      type: String,
      default: 'MMM D, YYYY',
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    startDate: {
      type: String,
      default: 'MMM D, YYYY',
    },
  },
  data() {
    return {
      start: '',
      end: '',
    };
  },
  watch: {
    modelValue(v) {
      this.start = v.start;
      this.end = v.end;
      this.render();
    },
  },
  mounted() {
    if (!this.start || !this.end) {
      this.start = moment().subtract(14, 'days').unix();
      this.end = moment().unix();
    }
    this.render();
  },
  methods: {
    getRange() {
      let range = {};
      range[this.$t('general.today')] = [moment(), moment()];
      range[this.$t('general.yesterday')] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
      range[this.$t('general.last_7_days')] = [moment().subtract(6, 'days'), moment()];
      range[this.$t('general.last_30_days')] = [moment().subtract(29, 'days'), moment()];
      range[this.$t('general.this_month')] = [moment().startOf('month'), moment().endOf('month')];
      range[this.$t('general.last_month')] = [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ];
      return range;
    },
    render() {
      try {
        $(this.$el).data('daterangepicker').remove();
      } catch (e) {}
      $(this.$el).daterangepicker(
        {
          opens: 'left',
          startDate: moment(this.start * 1000),
          endDate: moment(this.end * 1000),
          ranges: this.getRange(),
        },
        this.onDateChange
      );
    },
    onDateChange(start, end) {
      this.$emit('update:modelValue', {
        start: start.startOf('day').unix(),
        end: end.endOf('day').unix(),
      });
    },
    parseFormat(d) {
      return moment(d * 1000).format(this.displayFormat);
    },
  },
};
</script>

<template>
  <div class="flex">
    <i class="glyphicon glyphicon-calendar fa fa-calendar mr-2"></i>
    &nbsp;
    <span>{{ parseFormat(start) }} - {{ parseFormat(end) }}</span>
    <b class="caret"></b>
  </div>
</template>
