<template>
  <div class="teams-info flex">
    <t-tag
      v-if="teams.length"
      v-tooltip="{ content: teams[0].name, placement: 'bottom', popperClass: 'tooltip-vuetify tooltip-medium' }"
      class="mr-2 text-ellipsis"
      variant="team"
      size="sm"
    >
      {{ teams[0].name }}
    </t-tag>
    <t-tag
      v-if="teams.length > 1"
      v-tooltip="{
        content: teams[1].name,
        placement: 'bottom',
        popperClass: 'tooltip-vuetify tooltip-medium',
      }"
      class="mr-2 text-ellipsis"
      variant="team"
      size="sm"
    >
      {{ teams[1].name }}
    </t-tag>
    <t-tag
      v-if="teams.length > 2"
      v-tooltip="{
        content: restOfTeamsTooltip(teams),
        placement: 'bottom',
        html: true,
        popperClass: 'tooltip-vuetify tooltip-medium',
      }"
      class="mr-2"
      variant="team"
      size="sm"
    >
      +{{ teams.length - 2 }}
    </t-tag>
  </div>
</template>

<script>
export default {
  name: 'TeamsInfo',
  props: {
    teams: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    restOfTeamsTooltip(teams) {
      const restOfTeams = [...teams].splice(2, teams.length - 2);
      return restOfTeams.map((team) => {
        return ' ' + team.name;
      });
    },
  },
};
</script>

<style lang="scss">
.teams-info .t-tag {
  @apply select-none;
}
</style>
