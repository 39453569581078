import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "wa-template-btn" }
const _hoisted_2 = {
  key: 0,
  class: "wa-template-btn__input-wrapper"
}
const _hoisted_3 = { class: "wa-template-btn__create-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_title = _resolveComponent("form-title")!
  const _component_wa_template_input = _resolveComponent("wa-template-input")!
  const _component_plus_linear = _resolveComponent("plus-linear")!
  const _component_t_button = _resolveComponent("t-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_form_title, {
      modelValue: _ctx.computedValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValue) = $event)),
      "badge-text": _ctx.badgeText,
      "is-toggle-disabled": _ctx.isToggleDisabled,
      "is-text-disabled": _ctx.isTitleTextDisabled,
      "heading-text": _ctx.headingText,
      "should-show-badge": _ctx.shouldShowBadge,
      "should-show-switch": _ctx.shouldShowSwitch,
      subtitle: _ctx.subtitle,
      "tooltip-text": _ctx.tooltipText
    }, null, 8 /* PROPS */, ["modelValue", "badge-text", "is-toggle-disabled", "is-text-disabled", "heading-text", "should-show-badge", "should-show-switch", "subtitle", "tooltip-text"]),
    (_ctx.computedValue)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttonsData, (button, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "wa-template-btn__input-inner-wrapper"
            }, [
              _createVNode(_component_wa_template_input, {
                modelValue: button.text,
                "onUpdate:modelValue": ($event: any) => ((button.text) = $event),
                "is-valid-input": button.isValid,
                "character-count": _ctx.characterCount(button.text),
                "show-popup-menu": _ctx.showPopupMenu && !_ctx.disabled,
                "error-msg": button.error,
                "auto-focus-input": _ctx.autoFocusInput,
                disabled: _ctx.disabled,
                label: _ctx.label,
                placeholder: _ctx.placeholder,
                "max-length": _ctx.maxLength,
                "show-template-end": _ctx.showTemplateEnd,
                "sub-label": _ctx.label,
                onHandleInputChange: ($event: any) => (_ctx.$emit('handle-change-value', $event, button, index)),
                onHandleDeleteButton: ($event: any) => (_ctx.$emit('handle-delete-button', button.id, index))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "is-valid-input", "character-count", "show-popup-menu", "error-msg", "auto-focus-input", "disabled", "label", "placeholder", "max-length", "show-template-end", "sub-label", "onHandleInputChange", "onHandleDeleteButton"])
            ]))
          }), 128 /* KEYED_FRAGMENT */)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_t_button, {
              class: "banner__buttons-first",
              "btn-style": "secondary",
              type: "button",
              disabled: _ctx.disableAddNewBtn || _ctx.disabled,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('add-new-button')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_plus_linear, { class: "inline" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('whatsapp.whatsapp_template_quick_reply_add_button')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled"])
          ])
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.showActionButton)
      ? (_openBlock(), _createBlock(_component_t_button, {
          key: 1,
          disabled: _ctx.disableMigrationButton,
          type: "button",
          size: "sm",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('go-to-migration')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.btnText), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"]))
      : _createCommentVNode("v-if", true)
  ]))
}