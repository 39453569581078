<script>
export default {
  props: {
    direction: {
      type: String,
      default: 'column',
    },
  },
};
</script>

<template>
  <div class="grid-container min-w-0" :style="{ 'flex-direction': direction }">
    <slot></slot>
  </div>
</template>

<style lang="scss">
.grid-container {
  display: flex;
}
</style>
