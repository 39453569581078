<template>
  <div>
    <settings-landing v-if="showButtons" :title="title" :icon="icon" :badge="badge" :list="list">
      <t-button
        size="lg"
        aria-label="Connect live chat"
        type="button"
        label="Button"
        class="mb-4 mr-0 xl:mb-0 xl:mr-4"
        @click.prevent="showSandboxModal(true)"
      >
        {{ $t('admin_inboxes.connect_live_chat') }}
      </t-button>
      <t-button
        size="lg"
        btn-style="secondary"
        aria-label="Test live chat"
        type="button"
        label="Button"
        @click.prevent="showSandboxModal()"
      >
        {{ $t('admin_inboxes.test_live_chat') }}
      </t-button>
    </settings-landing>
    <div class="container flex flex-col items-center justify-center pt-8 leading-none lg:pt-32">
      <div ref="modal" class="modal animated fadeIn sandbox-modal" role="dialog" style="animation-duration: 0.3s">
        <div
          class="modal-centered modal-dialog"
          style="
            height: 90vh;
            overflow-y: auto;
            transform: translate(-50%, -50%);
            margin: 0;
            max-width: 1125px;
            width: 100vw;
            max-height: 750px;
          "
        >
          <div class="flex h-full flex-shrink-0 select-none overflow-hidden rounded-xl bg-white">
            <button
              type="button"
              class="modal-close-btn leading-none"
              style="z-index: 99999"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="material-icons text-grey-500" style="margin-top: -3px">close</i>
            </button>
            <div
              class="hidden-lg-down flex min-w-0 flex-shrink-0 flex-col items-center"
              :class="showCreateWizard ? 'justify-center' : 'justify-start'"
              style="width: 500px; transition: background-color 1s"
              :style="{ backgroundColor: showCreateWizard ? '#F1F1F1' : '#14b29f' }"
            >
              <div
                class="h-full w-4/5 overflow-hidden bg-white"
                style="box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.04); transition: border-radius, max-height 1s"
                :class="showCreateWizard ? 'rounded-xl pt-6' : 'rounded-b-xl'"
                :style="{ maxHeight: showCreateWizard ? '600px' : '640px' }"
              >
                <div class="h-full overflow-hidden">
                  <div class="flex h-full w-full flex-col overflow-hidden rounded-xl">
                    <div
                      class="widget-header relative bg-white pb-8"
                      style="box-shadow: 0 10px 25px rgba(0, 0, 0, 0.04)"
                    >
                      <transition name="fade">
                        <div v-show="showCreateWizard" class="mx-6 text-2xl font-bold">
                          <div class="flex justify-between">
                            <div class="my-2">
                              {{ options.translationTitle }}
                              <div
                                v-show="options.showOnlineStatus"
                                class="
                                  relative
                                  ml-1
                                  inline-block
                                  rounded-full
                                  px-3
                                  py-2
                                  text-sm
                                  font-medium
                                  text-grey-500
                                "
                                style="box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08); top: -5px"
                              >
                                Online
                              </div>
                            </div>
                            <i class="material-icons -mt-1 text-5xl text-grey-500">keyboard_arrow_down</i>
                          </div>
                          <div class="text-md my-2">
                            {{ options.translationHeading }}
                          </div>
                        </div>
                      </transition>
                      <transition name="fade">
                        <div
                          v-show="options.showTeam"
                          class="mt-3 flex w-full items-center justify-around px-4 text-sm text-grey-500"
                        >
                          <div>
                            <div
                              class="hero-avatar"
                              :style="`background-image: url('${$root.assetsURL}img/heroes/naomi.jpg')`"
                            ></div>
                            <div v-show="showCreateWizard" class="mt-2 text-center">Sarah</div>
                          </div>
                          <div>
                            <div
                              class="hero-avatar"
                              :style="`background-image: url('${$root.assetsURL}img/heroes/niels.jpg')`"
                            ></div>
                            <div v-show="showCreateWizard" class="mt-2 text-center">Thomas</div>
                          </div>
                          <div>
                            <div
                              class="hero-avatar"
                              :style="`background-image: url('${$root.assetsURL}img/heroes/danique.jpg')`"
                            ></div>
                            <div v-show="showCreateWizard" class="mt-2 text-center">Deborah</div>
                          </div>
                          <div>
                            <div
                              class="hero-avatar"
                              :style="`background-image: url('${$root.assetsURL}img/heroes/igo.jpg')`"
                            ></div>
                            <div v-show="showCreateWizard" class="mt-2 text-center">Robert</div>
                          </div>
                          <div>
                            <div
                              class="hero-avatar"
                              :style="`background-image: url('${$root.assetsURL}img/heroes/efe.jpg')`"
                            ></div>
                            <div v-show="showCreateWizard" class="mt-2 text-center">Nick</div>
                          </div>
                        </div>
                      </transition>
                    </div>
                    <template v-if="!showCreateWizard">
                      <div
                        ref="msgContainer"
                        style="overflow-y: auto; padding-right: 9px; margin-bottom: -78px; padding-bottom: 78px"
                        class="-mb-6 -mt-8 pb-6 pl-6 pt-10"
                      >
                        <template v-for="message in messages">
                          <div v-if="message.outbound" class="my-4 mr-2 flex justify-end">
                            <div
                              class="success max-w-sm rounded-lg p-4 leading-normal"
                              style="box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1)"
                            >
                              {{ message.message }}
                            </div>
                          </div>
                          <div v-else class="my-4 flex">
                            <div
                              class="small-hero-avatar mr-4 flex-shrink-0"
                              :style="`background-image: url('${$root.assetsURL}img/heroes/naomi.jpg')`"
                            ></div>
                            <div
                              class="w-2/3 rounded-lg p-4 leading-normal"
                              style="box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1)"
                            >
                              {{ message.message }}
                            </div>
                          </div>
                        </template>
                      </div>
                      <div class="mt-auto" style="z-index: 10">
                        <div
                          class="mx-4 mb-4 mt-auto rounded-xl bg-white"
                          style="z-index: 10"
                          :style="{
                            animation: !showCreateWizard ? 'pulsate 2s ease-out infinite' : '',
                            boxShadow: showCreateWizard ? '0 3px 20px rgba(206, 206, 206, 0.3)' : '',
                          }"
                        >
                          <form class="flex items-center pl-4" @submit.prevent="submitMessage">
                            <input
                              ref="messageInput"
                              v-model="message"
                              class="mr-3 w-full appearance-none border-none px-2 py-4"
                              type="text"
                              :placeholder="$t('livechat.type_your_test_message')"
                              autofocus
                              @enter="submitMessage"
                            />
                            <button
                              class="hero-avatar flex-shrink-0 rounded bg-green px-2 py-1 text-white"
                              type="button"
                              @click="submitMessage"
                            >
                              <i class="material-icons">send</i>
                            </button>
                          </form>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <template v-if="!options.preChatForm">
                        <div
                          ref="msgContainer"
                          style="overflow-y: auto; padding-right: 9px; margin-bottom: -78px; padding-bottom: 78px"
                          class="-mb-6 -mt-8 pb-6 pl-6 pt-10"
                        >
                          <div class="my-4 flex">
                            <div
                              class="small-hero-avatar mr-4 flex-shrink-0"
                              :style="`background-image: url('${$root.assetsURL}img/heroes/naomi.jpg')`"
                            ></div>
                            <div
                              class="w-2/3 rounded-lg p-4 leading-normal"
                              style="box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1)"
                            >
                              <div
                                class="mb-3"
                                style="background-color: #f1f1f1; border-radius: 10px; width: 220px; height: 18px"
                              ></div>
                              <div
                                style="background-color: #f1f1f1; border-radius: 10px; width: 140px; height: 18px"
                              ></div>
                            </div>
                          </div>
                          <div>
                            <div class="my-4 flex justify-end">
                              <div
                                class="mr-3 mt-2 max-w-sm rounded-lg p-4 leading-normal"
                                style="box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1); transition: background-color 1s"
                                :style="{ backgroundColor: options.primaryColor }"
                              >
                                <div
                                  class="mb-3"
                                  style="
                                    background-color: rgb(255 255 255);
                                    opacity: 0.5;
                                    border-radius: 10px;
                                    width: 220px;
                                    height: 18px;
                                  "
                                ></div>
                                <div
                                  style="
                                    background-color: rgb(255 255 255);
                                    opacity: 0.5;
                                    border-radius: 10px;
                                    width: 140px;
                                    height: 18px;
                                  "
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-auto" style="z-index: 10">
                          <div
                            class="mx-4 mb-4 mt-auto rounded-xl bg-white"
                            style="z-index: 10"
                            :style="{
                              animation: !showCreateWizard ? 'pulsate 1s ease-out infinite' : '',
                              boxShadow: showCreateWizard ? '0 3px 20px rgba(206, 206, 206, 0.3)' : '',
                            }"
                          >
                            <form
                              class="flex items-center pl-4"
                              style="cursor: not-allowed !important"
                              @submit.prevent="submitMessage"
                            >
                              <input
                                ref="messageInput"
                                v-model="message"
                                class="disabled mr-3 w-full appearance-none border-none bg-white px-2 py-4"
                                style="cursor: not-allowed !important"
                                type="text"
                                disabled
                                @enter="submitMessage"
                              />
                              <i class="material-icons mx-2 cursor-not-allowed text-grey-400">gif</i>
                              <i class="material-icons ml-2 mr-4 cursor-not-allowed text-grey-400">insert_emoticon</i>
                              <button
                                class="
                                  hero-avatar
                                  flex-shrink-0
                                  cursor-not-allowed
                                  rounded
                                  bg-green
                                  px-2
                                  py-1
                                  text-white
                                "
                                type="button"
                                style="transition: background-color 1s; cursor: not-allowed !important"
                                :style="{ backgroundColor: options.primaryColor }"
                                disabled
                                @click="submitMessage"
                              >
                                <i class="material-icons">send</i>
                              </button>
                            </form>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="mx-2 flex h-full flex-col justify-around">
                          <div class="mt-4">
                            <div
                              class="mx-4 mb-8 rounded-xl bg-white"
                              style="z-index: 10; box-shadow: 0 3px 20px rgba(206, 206, 206, 0.3)"
                            >
                              <input
                                class="mr-3 w-full appearance-none rounded-full border-none"
                                type="text"
                                placeholder="Name..."
                                style="padding: 1.25rem"
                              />
                            </div>
                            <div
                              class="mx-4 mb-8 rounded-xl bg-white"
                              style="z-index: 10; box-shadow: 0 3px 20px rgba(206, 206, 206, 0.3)"
                            >
                              <input
                                class="mr-3 w-full appearance-none rounded-full border-none"
                                type="text"
                                placeholder="Message..."
                                style="padding: 1.25rem"
                              />
                            </div>
                          </div>
                          <div class="mb-4">
                            <button
                              class="mx-4 rounded font-bold text-white"
                              type="button"
                              style="padding: 1.25rem; transition: background-color 1s"
                              :style="{ backgroundColor: options.primaryColor }"
                            >
                              Start chat
                            </button>
                          </div>
                        </div>
                      </template>
                      <div v-if="showChannels" class="flex justify-center">
                        <div v-if="options.channelChat" class="border-b-3 cursor-not-allowed border-black px-3 pb-2">
                          <i class="material-icons">chat_bubble</i>
                        </div>
                        <div
                          v-if="options.channelWaBusiness"
                          class="border-b-3 cursor-not-allowed border-white px-3 pb-2 text-grey-400"
                        >
                          <i class="fa fa-whatsapp material-icons md-24"></i>
                        </div>
                        <div
                          v-if="options.channelEmail"
                          class="border-b-3 cursor-not-allowed border-white px-3 pb-2 text-grey-400"
                        >
                          <i class="material-icons">email</i>
                        </div>
                        <div
                          v-if="options.channelVoice"
                          class="border-b-3 cursor-not-allowed border-white px-3 pb-2 text-grey-400"
                        >
                          <i class="material-icons">phone</i>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="mt-4 flex w-4/5 justify-end">
                <transition name="fade">
                  <div
                    v-show="showCreateWizard && options.showOnlineStatus"
                    class="mb-4 mr-4 flex flex-col justify-center"
                  >
                    <div
                      class="message-container inline-block rounded-xl bg-green p-4 font-bold text-white"
                      style="transition: background-color 1s"
                      :style="{ backgroundColor: options.primaryColor }"
                    >
                      <span class="dot pull-left mr-2 inline-block bg-white"></span>
                      {{ options.translationAlwaysOnline }}
                    </div>
                  </div>
                </transition>

                <div class="" style="filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2))">
                  <div
                    class="flex items-center justify-center rounded-xl"
                    style="width: 67px; height: 67px; border-bottom-right-radius: 0; transition: background-color 1s"
                    :style="{ backgroundColor: showCreateWizard ? options.primaryColor : 'white' }"
                  >
                    <svg
                      style="width: 75px"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 112 87"
                      xml:space="preserve"
                    >
                      <path
                        :fill="showCreateWizard ? 'white' : '#14b29f'"
                        d="M35.47,42.9c0-2.77,2.27-5.04,5.04-5.04h25.33c2.77,0,5.04,2.27,5.04,5.04s-2.27,5.04-5.04,5.04H40.51
                                            C37.74,47.94,35.47,45.68,35.47,42.9z M81.69,62.35h-34.1c-2.77,0-5.04-2.27-5.04-5.04s2.27-5.04,5.04-5.04h34.1
                                            c2.77,0,5.04,2.27,5.04,5.04S84.46,62.35,81.69,62.35z M81.69,33.54H32.23c-2.77,0-5.04-2.27-5.04-5.04s2.27-5.04,5.04-5.04h49.46
                                            c2.77,0,5.04,2.27,5.04,5.04S84.46,33.54,81.69,33.54z"
                      />
                    </svg>
                  </div>
                  <div
                    class="pull-right widget-triangle relative"
                    style="filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.04)); transition: border-right-color 1s"
                    :style="{ borderRightColor: showCreateWizard ? options.primaryColor : 'white' }"
                  ></div>
                </div>
              </div>
            </div>
            <div class="relative flex w-full justify-center">
              <div v-if="showCreateWizard" class="scroll-box w-full px-16 pt-16">
                <div style="font-size: 42px" class="mb-2 font-bold text-black">
                  {{ $t('livechat.create_your_own_live_chat') }}
                </div>
                <step-wizard
                  :steps="4"
                  :active-step-wizard-index="activeStepWizardIndex"
                  @update:activeStepWizardIndex="activeStepWizardIndex = $event"
                >
                  <template #step_1>
                    <div>
                      <div class="font-bold text-green" style="font-size: 32px">
                        <span>{{ $t('livechat.first_step_the_customization_part') }}</span>
                      </div>
                      <div class="my-8 leading-normal">
                        <p>{{ $t('livechat.you_are_about_to_create_live_chat') }}</p>
                        <div>
                          <strong>{{ $t('livechat.show_avatars_from_your_employees') }}</strong>
                        </div>
                        <div class="my-2 inline-block">
                          <round-select v-model="options.showTeam" />
                        </div>
                        <div>
                          <strong>{{ $t('livechat.show_online_status') }}</strong>
                          {{ $t('livechat.at_least_one_employee_has_to_be_online') }}
                        </div>
                        <div class="my-2 inline-block">
                          <round-select v-model="options.showOnlineStatus" />
                        </div>
                        <div>
                          <strong>{{ $t('livechat.require_name_and_email_before_chatting') }}</strong>
                        </div>
                        <div class="my-2 inline-block">
                          <round-select v-model="options.preChatForm" />
                        </div>
                      </div>
                      <button type="submit" class="btn btn-round primary-button mb-4" @click="activeStepWizardIndex++">
                        {{ $t('livechat.create_livechat_step_one_next_button') }}
                      </button>
                    </div>
                  </template>
                  <template #step_2>
                    <div>
                      <div class="font-bold text-green" style="font-size: 32px">
                        <span>{{ $t('livechat.second_step_personalize_the_copy') }}</span>
                      </div>
                      <div class="my-8 leading-normal">
                        <div>
                          <strong>{{ $t('livechat.select_default_language') }}</strong>
                          {{ $t('livechat.you_can_add_more_languages_after_creating') }}
                        </div>
                        <div class="my-2 inline-block">
                          <round-select
                            v-model="options.defaultLanguage"
                            :options="$root.localeCodesList.map((lc) => ({ value: lc.id, name: lc.name }))"
                          />
                        </div>
                        <div class="mb-1 mt-3">
                          <strong>{{ $t('livechat.personalize_the_copy') }}</strong>
                          {{ $t('livechat.you_can_change_more_text_after_creating') }}
                        </div>
                        <div class="my-1 inline-block w-full">
                          <atomic-input
                            v-model="options.translationTitle"
                            :placeholder="$t('livechat.livechat_title')"
                          />
                        </div>
                        <div class="my-1 inline-block w-full">
                          <atomic-input
                            v-model="options.translationHeading"
                            :placeholder="$t('livechat.livechat_heading')"
                          />
                        </div>
                        <div v-if="options.showOnlineStatus" class="my-1 inline-block w-full">
                          <atomic-input
                            v-model="options.translationAlwaysOnline"
                            :placeholder="$t('livechat.livechat_always_online_text')"
                          />
                        </div>
                      </div>
                      <button type="submit" class="btn btn-round primary-button mb-4" @click="activeStepWizardIndex++">
                        {{ $t('livechat.create_livechat_step_two_next_button') }}
                      </button>
                    </div>
                  </template>
                  <template #step_3>
                    <div>
                      <div class="font-bold text-green" style="font-size: 32px">
                        <span>{{ $t('livechat.step_three_select_your_color_and_channels') }}</span>
                      </div>
                      <div class="my-8 leading-normal">
                        <div>
                          <strong>{{ $t('livechat.primary_color') }}</strong>
                        </div>
                        <div class="relative my-2 inline-block">
                          <color-picker v-model="options.primaryColor" :rounded="true" />
                        </div>
                        <div>
                          <strong>{{ $t('livechat.add_channels_to_livechat') }}</strong>
                          {{ $t('livechat.add_more_channels_later') }}
                        </div>
                        <div class="my-2 flex w-2/3">
                          <div class="mr-2 w-1/2 pr-2">
                            <round-select
                              v-model="options.channelChat"
                              :options="chatChannelOptions"
                              icon="chat_bubble"
                            />
                          </div>
                          <div class="w-1/2">
                            <round-select
                              v-model="options.channelWaBusiness"
                              :options="waBusinessChannelOptions"
                              icon-class="fa fa-whatsapp material-icons md-24"
                              icon=""
                            ></round-select>
                          </div>
                        </div>
                        <div class="my-2 flex w-2/3">
                          <div class="mr-2 w-1/2 pr-2">
                            <round-select v-model="options.channelEmail" :options="emailChannelOptions" icon="email" />
                          </div>
                          <div class="w-1/2">
                            <round-select v-model="options.channelVoice" :options="voiceChannelOptions" icon="phone" />
                          </div>
                        </div>
                      </div>
                      <button type="submit" class="btn btn-round primary-button mb-4" @click="activeStepWizardIndex++">
                        {{ $t('livechat.create_livechat_step_three_next_button') }}
                      </button>
                    </div>
                  </template>

                  <template #step_4>
                    <div>
                      <div class="font-bold text-green" style="font-size: 32px">
                        <span>{{ $t('livechat.place_the_code_on_your_website') }}</span>
                      </div>
                      <div class="my-8">
                        <p>
                          <strong>{{ $t('livechat.place_the_code_before_body_tag') }}</strong>
                        </p>
                        <pre
                          class="code-scroll mt-2 select-text rounded-xl border-2 bg-white p-4 text-grey-500"
                          @click="selectCode"
                          v-text="embed_code"
                        />
                        <div class="pointer my-3 flex items-center" @click="copyWidgetJavaScriptCodeToClipboard">
                          <i class="material-icons text-green">content_copy</i>
                          <span ref="copyCodeText" class="pl-2 text-grey-600">{{ $t('livechat.copy_code') }}</span>
                        </div>
                        <div class="mb-2 mt-6">
                          <p>
                            <strong>{{ $t('livechat.need_help_to_place_code') }}</strong>
                          </p>
                          <div class="m-4">
                            <div class="my-2">
                              <a href="https://help.trengo.com/en/articles/53241" target="_blank">
                                {{ $t('livechat.read_how_to_install_on_your_wordpress') }}
                              </a>
                            </div>
                            <div class="my-2">
                              <a href="https://help.trengo.com/en/articles/53236" target="_blank">
                                {{ $t('livechat.read_how_to_install_via_google_tag_manager') }}
                              </a>
                            </div>
                            <div class="pointer my-2" @click="openSendWidgetCodeToDeveloper">
                              {{ $t('livechat.send_code_to_your_developer') }}
                            </div>
                          </div>
                          <pop-up-at-mouse-position>
                            <template #title>
                              <div>
                                <strong>Mail the code</strong>
                                including install info
                              </div>
                            </template>
                            <template #content>
                              <div>
                                <atomic-input
                                  v-model="developerEmailAddress"
                                  type="email"
                                  placeholder="Email address.."
                                  submit-icon="send"
                                  @submit="emailWidgetCodeInstructions"
                                />
                              </div>
                            </template>
                          </pop-up-at-mouse-position>
                        </div>
                        <div
                          role="button"
                          :class="{ loader: loading }"
                          class="btn btn-round primary-button my-4"
                          @click="handleClickTheCodeIsPlaced"
                        >
                          <p class="mb-0">{{ $t('livechat.the_code_is_placed_on_my_website') }}</p>
                        </div>
                      </div>
                    </div>
                  </template>
                </step-wizard>
              </div>
              <div v-else class="mx-16 mt-32">
                <div style="font-size: 42px" class="mb-2 font-bold text-black">
                  {{ $t('livechat.test_livechat_for_your_website') }}
                </div>
                <div class="font-bold text-green" style="font-size: 32px">
                  <span>{{ $t('livechat.send_a_chat_like_your_customer_on_website') }}</span>
                </div>
                <div class="my-8 leading-normal">
                  <template v-if="messages.filter((m) => m.outbound).length < 1">
                    <p
                      v-html="
                        sanitizedListItem(
                          $t('livechat.benefits_of_using_trengo_livechat', {
                            companyName: $root.companyProfile.profile.company_name,
                          })
                        )
                      "
                    />
                    <div class="my-4">
                      <div class="flex">
                        <i class="material-icons md-18 mr-4 text-green">done</i>
                        {{ $t('livechat.faster_replies') }}
                      </div>
                      <div class="flex">
                        <i class="material-icons md-18 mr-4 text-green">done</i>
                        {{ $t('livechat.higher_customer_satisfaction') }}
                      </div>
                      <div class="flex">
                        <i class="material-icons md-18 mr-4 text-green">done</i>
                        {{ $t('livechat.personal_contact_with_upsell_potential') }}
                      </div>
                      <div class="flex">
                        <i class="material-icons md-18 mr-4 text-green">done</i>
                        {{ $t('livechat.generate_more_leads') }}
                      </div>
                    </div>
                    <p>
                      <strong>
                        <span class="animate-slide-loop pr-4 text-2xl">👈🏻</span>
                        {{ $t('livechat.now_send_first_message_to_see_how_it_works') }}
                      </strong>
                    </p>
                  </template>
                  <template v-else>
                    <p>
                      <strong>{{ $t('livechat.congratulations') }}</strong>
                      {{ $t('livechat.you_have_tested_livechat_continue_choosing_options') }}
                    </p>
                    <button
                      v-if="ticketId"
                      type="submit"
                      class="mt-6 rounded border-2 border-green p-4 font-bold leading-none text-green"
                      @click="handleClickGoToInbox"
                    >
                      {{ $t('livechat.see_chat_message_in_inbox') }}
                    </button>
                    <button
                      type="submit"
                      class="mt-4 rounded bg-green p-4 font-bold leading-none text-white"
                      @click="showCreateWizard = true"
                    >
                      {{ $t('livechat.create_your_own_livechat_widget') }}
                    </button>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sanitize } from 'dompurify';

import SettingsLanding from '@/components/common/SettingsLanding';
import eventBus from '@/eventBus';
import { randomString } from '@/util/stringHelpers';

import ColorPicker from '../Elements/ColorPicker';
import PopUpAtMousePosition from '../Elements/PopUpAtMousePosition';
import RoundSelect from '../Elements/RoundSelect';
import StepWizard from '../StepWizard';

export default {
  emits: ['open', 'close', 'completed'],
  name: 'WebChatSandbox',
  props: {
    showButtons: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    badge: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    PopUpAtMousePosition,
    RoundSelect,
    StepWizard,
    ColorPicker,
    SettingsLanding,
  },
  data() {
    return {
      messages: [
        {
          message: this.$t('livechat.this_is_first_message_to_show_you_how_livechat_works', {
            name: this.$root.user.name,
          }),
        },
        {
          message: this.$t('livechat.reply_to_this_message_to_get_this_conversation_started'),
        },
      ],
      message: null,
      showCreateWizard: false,
      options: {
        showTeam: true,
        showOnlineStatus: true,
        preChatForm: false,
        defaultLanguage: 'en',
        translationTitle: 'Team Trengo',
        translationHeading: 'Ask us anything. We will reply as soon as possible.',
        translationAlwaysOnline: 'How can we help you?',
        primaryColor: '#14b29f',
        channelChat: null,
        channelWaBusiness: null,
        channelEmail: null,
        channelVoice: null,
      },
      activeStepWizardIndex: 1,
      selectedChannelType: 'CHAT',
      contactIdentifier: null,
      ticketId: null,
      widgetId: null,
      embed_code: null,
      developerEmailAddress: null,
      loading: false,
      lastGeneratedWidgetCode: null,
    };
  },
  computed: {
    chatChannelOptions() {
      return this.$root.channels.filter((c) => c.type === 'CHAT').map((c) => ({ value: c.id, name: c.title }));
    },
    waBusinessChannelOptions() {
      const channelOptions = this.$root.channels
        .filter((c) => c.type === 'WA_BUSINESS')
        .map((c) => ({ value: c.id, name: c.title }));
      channelOptions.unshift({ value: null, name: 'None' });
      return channelOptions;
    },
    voiceChannelOptions() {
      const channelOptions = this.$root.channels
        .filter((c) => c.type === 'VOIP')
        .map((c) => ({ value: c.id, name: c.title }));
      channelOptions.unshift({ value: null, name: 'None' });
      return channelOptions;
    },
    emailChannelOptions() {
      const channelOptions = this.$root.channels
        .filter((c) => c.type === 'EMAIL')
        .map((c) => ({ value: c.id, name: c.title }));
      channelOptions.unshift({ value: null, name: 'None' });
      return channelOptions;
    },
    showChannels() {
      // Cast all selected channels to boolean, then to Numbers, then sum them
      return (
        Number(Boolean(this.options.channelChat)) +
          Number(Boolean(this.options.channelWaBusiness)) +
          Number(Boolean(this.options.channelEmail)) +
          Number(Boolean(this.options.channelVoice)) >=
        2
      );
    },
    selectedEmailChannelRecipient() {
      if (!this.options.channelEmail) {
        return null;
      }
      const emailAddress = this.$root.channels.find((channel) => channel.id === this.options.channelEmail).username;
      if (emailAddress.includes('@')) {
        // Outlook or Gmail address
        return emailAddress;
      }
      // Trengo address
      return emailAddress + '@trengomail.com';
    },
  },
  watch: {
    activeStepWizardIndex(newIndex) {
      if (newIndex === 4) {
        this.createOrUpdateWidget();
      }
    },
  },
  mounted() {
    $(this.$refs.modal).on('shown.bs.modal', () => {
      this.$emit('open');
    });
    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close');
    });
    eventBus.$on('chat_sandbox_init_channel', this.selectFirstOrCreateChatChannel);
  },
  beforeUnmount() {
    this.$emit('close');
    $(this.$refs.modal).modal('hide');
  },
  methods: {
    handleClickGoToInbox() {
      this.$emit('completed', '/tickets/' + this.ticketId);
      this.$router.push('/tickets/' + this.ticketId);
    },
    handleClickTheCodeIsPlaced() {
      this.$emit('completed', '/admin/web_widgets/' + this.widgetId);
      this.$router.push('/admin/web_widgets/' + this.widgetId);
    },
    sanitizedListItem(item) {
      return sanitize(item);
    },
    selectFirstOrCreateChatChannel() {
      const chatChannelIndex = this.$root.channels?.findIndex((c) => c.type === 'CHAT');
      if (chatChannelIndex && chatChannelIndex > -1) {
        this.options.channelChat = this.$root.channels[chatChannelIndex].id;
        return;
      }
      // No chat channels, let's create one
      // Use lowest business_hours_id (default one) and team (default)
      const defaultBusinessHoursId = this.$root.channels.reduce(
        (accumulator, channel) =>
          channel.business_hour_id && channel.business_hour_id < accumulator ? channel.business_hour_id : accumulator,
        Infinity
      );
      const defaultTeam = this.$root.teams.reduce(
        (accumulator, team) => (team.id < accumulator.id ? team : accumulator),
        { id: Infinity }
      );
      const chatChannelRecord = {
        name: 'Chat',
        title: 'Chat',
        phone: '',
        type: 'CHAT',
        auto_reply: 'ENABLED',
        color: null,
        notification_email: null,
        business_hour_id: defaultBusinessHoursId,
        notification_sound: 'chat.mp3',
        status: 'ACTIVE',
        display_name: 'Chat (Web-chat)',
        text: 'Chat (Web-chat)',
        show_ticket_fields: 1,
        show_contact_fields: 1,
        team_ids: [defaultTeam.id],
        teams: [
          {
            id: defaultTeam.id,
            name: defaultTeam.name,
          },
        ],
        voipIdentity: null,
        username: null,
        password_is_null: false,
        reopen_closed_ticket: 1,
      };
      axios.post('/api/v2/channels', chatChannelRecord).then((res) => {
        eventBus.$emit('channels.reload');
        this.options.channelChat = res.data.id;
      });
    },
    showSandboxModal(showCreateWizard = false) {
      this.showCreateWizard = showCreateWizard;
      this.selectFirstOrCreateChatChannel();
      $(this.$refs.modal).modal();

      this.$nextTick(() => {
        this.jumpToBottom();
        this.$refs.messageInput.focus();
      });
    },
    submitMessage() {
      if (!this.message) {
        return;
      }

      const params = {
        vt: this.contactIdentifier,
        message: this.message,
        channel_id: this.options.channelChat,
      };
      axios.post('/api/v2/web_widgets/sandbox', params).then((res) => {
        this.ticketId = res.data.ticket.id;
        this.contactIdentifier = res.data.contact_identifier;
        this.messages.push({
          outbound: true,
          message: res.data.message.message,
        });
        this.jumpToBottom();
      });
      this.message = null;
    },
    jumpToBottom() {
      this.$nextTick(() => {
        this.$refs.msgContainer.scrollTop = this.$refs.msgContainer.scrollHeight;
      });
    },
    createOrUpdateWidget() {
      let widgetRecord = this.generateWidgetRecord();

      if (this.lastGeneratedWidgetCode !== null && JSON.stringify(widgetRecord) === this.lastGeneratedWidgetCode) {
        // No options changed
        return;
      }
      this.loading = true;
      this.lastGeneratedWidgetCode = JSON.stringify(widgetRecord);
      if (this.widgetId) {
        // update widget
        axios
          .put('/api/v2/web_widgets/' + this.widgetId, widgetRecord)
          .then((res) => {
            this.loading = false;
            this.embed_code = res.data.embed_code;
            this.flashSuccess(this.$t('livechat.website_widget_updated_successfully'));
          })
          .catch(() => {
            this.loading = false;
            this.flashError(this.$t('livechat.something_went_wrong_updating_the_widget'));
          });
      } else {
        // create widget
        widgetRecord.token = randomString(20);
        axios
          .post('/api/v2/web_widgets', widgetRecord)
          .then((res) => {
            this.loading = false;
            this.embed_code = res.data.embed_code;
            this.widgetId = res.data.id;
            this.flashSuccess(this.$t('livechat.website_widget_created_successfully'));
          })
          .catch(() => {
            this.loading = false;
            this.flashError(this.$t('livechat.something_went_wrong_creating_the_widget'));
          });
      }
    },
    generateWidgetRecord() {
      return {
        title: 'Website widget',
        color: this.options.primaryColor,
        position: 'RIGHT',
        show_team: this.options.showTeam,
        languages: [this.options.defaultLanguage],
        translations: {
          [this.options.defaultLanguage]: {
            general: {
              title: this.options.translationTitle === 'Team Trengo' ? null : this.options.translationTitle,
              heading:
                this.options.translationHeading === 'Ask us anything. We will reply as soon as possible.'
                  ? null
                  : this.options.translationHeading,
            },
            chat: {
              online_status:
                this.options.translationAlwaysOnline === 'How can we help you?'
                  ? null
                  : this.options.translationAlwaysOnline,
            },
          },
        },
        default_channel: 'chat',
        default_lang: this.options.defaultLanguage,
        no_branding: 0,
        facebook: {
          enabled: false,
          channel_id: null,
        },
        chat: {
          enabled: true,
          channel_id: this.options.channelChat,
          pre_form_custom_fields: [
            // ask Patrick
            // {
            //     "type": "text_field",
            //     "title": {
            //         "en": "E-mail",
            //         "nl": "E-mailadres"
            //     },
            //     "options": [],
            //     "required": true
            // }
          ],
          pre_form: this.options.preChatForm,
          offline_email: '',
          auto_close: 0,
          auto_close_chat_seconds: 60,
          offline_outside_business_hours: 0,
          show_online_status: this.options.showOnlineStatus, // ask Patrick
          always_available: this.options.showOnlineStatus, // ask Patrick
          show_team: this.options.showTeam,
        },
        whatsapp: {
          enabled: this.options.channelWaBusiness !== null,
          channel_id: this.options.channelWaBusiness,
        },
        telegram: {
          enabled: false,
          channel_id: null,
        },
        help_center: {
          enabled: false,
          channel_id: null,
        },
        email: {
          enabled: this.options.channelEmail !== null,
          custom_fields: [],
          email: this.selectedEmailChannelRecipient,
        },
        sms: {
          enabled: false,
          form: true,
        },
        voice: {
          enabled: this.options.channelVoice !== null,
          channel_id: this.options.channelVoice,
        },
        automation: [],
        launcher_styles: {
          icon_url: null,
        },
      };
    },
    copyWidgetJavaScriptCodeToClipboard() {
      copyToClipboard(this.embed_code);
      this.$refs.copyCodeText.textContent = 'Copied!';
      setTimeout(() => {
        this.$refs.copyCodeText.textContent = 'Copy code';
      }, 3000);
    },
    openSendWidgetCodeToDeveloper(event) {
      eventBus.$emit('show.popup', event);
    },
    emailWidgetCodeInstructions() {
      axios
        .post('/api/v2/web_widgets/sandbox/email', {
          email_address: this.developerEmailAddress,
          widget_id: this.widgetId,
        })
        .then((res) => {
          this.flashSuccess('Email sent to ' + this.developerEmailAddress);
          eventBus.$emit('hide.popup');
          this.developerEmailAddress = null;
        });
    },
    selectCode(e) {
      let range = document.createRange();
      range.selectNodeContents(e.target);
      let sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    },
  },
};
</script>

<style lang="scss">
.widget-header {
  z-index: 10;
  border-bottom-right-radius: 100%;
  border-bottom-left-radius: 100%;
  margin-left: -120px;
  margin-right: -120px;
  padding-left: 120px;
  padding-right: 120px;
}
.widget-triangle {
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 27px solid white;
  top: -13px;
}
.hero-avatar {
  background-repeat: round;
  height: 62px;
  width: 62px;
  border-radius: 20px;
}
.small-hero-avatar {
  background-repeat: round;
  height: 51px;
  width: 51px;
  border-radius: 20px;
}
.dot {
  width: 14px;
  height: 14px;
  border-radius: 9999px;
}
.colorpicker {
  position: absolute;
  z-index: 99999999;
}
@keyframes pulsate {
  0%,
  100% {
    box-shadow: 0 3px 20px rgba(206, 206, 206, 0.3);
  }
  50% {
    box-shadow: 0 3px 30px rgba(20, 178, 159, 0.6);
  }
}
.animate-slide-loop {
  position: relative;
  animation: slide 2s infinite;
}
@keyframes slide {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: -30px;
  }
}
.scroll-box {
  overflow: auto;
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}
.rounded-b-xl {
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.border-b-3 {
  border-bottom-width: 3px;
}
.sandbox-modal {
  .modal-centered {
    z-index: 9999999;
  }
  @media only screen and (min-width: 1200px) {
    .modal-centered {
      border-radius: 25px !important;
    }
  }
  @media only screen and (max-width: 1199px) {
    border-radius: 0 !important;
  }
}
.code-scroll {
  max-width: 28rem;
  line-height: 1rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.code-scroll::-webkit-scrollbar {
  display: none; /* Chrome */
}
</style>
