import { renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-test"]
const _hoisted_2 = ["data-test"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    class: "flex cursor-pointer list-none flex-col justify-center border-b border-grey-300 text-center last:border-none",
    "data-test": _ctx.dataTestListRowItem
  }, [
    _createElementVNode("div", _mergeProps({
      ref: "row_item",
      class: ["m-1 box-border flex items-center justify-between rounded-lg p-4 ring-inset ring-grey-300 transition-all duration-200 ease-linear hover:bg-grey-200 active:bg-grey-300", {
        'bg-grey-200 ring-2 ring-grey-300': _ctx.isFocused,
      }],
      "data-test": _ctx.dataTestListRowItemChild,
      tabindex: "0"
    }, _ctx.$attrs, {
      onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isFocused = true)),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFocused = false))
    }), [
      _renderSlot(_ctx.$slots, "default")
    ], 16 /* FULL_PROPS */, _hoisted_2)
  ], 8 /* PROPS */, _hoisted_1))
}