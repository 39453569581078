<template>
  <t-action-menu v-model="computedValue" class="popup" width="max-content" :position="position" fade>
    <template #trigger>
      <slot name="button">
        <t-icon-button />
      </slot>
    </template>
    <template #content>
      <div class="popup__content">
        <slot name="content">
          <div class="popup__content--inner-wrapper">
            <t-button-text :type="btnType" btn-style="secondary" @click="$emit('handle-click')">
              <trash-linear size="1.25rem" class="popup__content--icon" />
              <span class="popup__content--text">{{ actionText }}</span>
            </t-button-text>
          </div>
        </slot>
      </div>
    </template>
  </t-action-menu>
</template>
<script>
import { TrashLinear } from '@trengo/trengo-icons';

export default {
  components: { TrashLinear },
  emits: ['open', 'close', 'update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'left',
    },
    actionText: {
      type: String,
      default: 'Remove',
    },
    btnType: {
      type: String,
      default: 'button',
    },
  },
  methods: {
    handleOpen() {
      this.$emit('open');
    },
    handleClose() {
      this.$emit('close');
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style src="./PopupMenu.scss" scoped lang="scss" />
