<template>
  <li class="list-none">
    <t-popover v-model="isOpen" class="relative" click-outside-close>
      <template #trigger>
        <t-list-item :label="$t('reports_v2.business_hours')" class="relative" :active="isOpen">
          <template #postfix>
            <caret-down-fill size="1.25rem" class="mt-1 -rotate-90 text-grey-600" />
          </template>
        </t-list-item>
      </template>

      <template #content>
        <div
          class="
            filter-dropdown-second-layer
            absolute
            z-50
            mt-2
            w-64
            rounded-xl
            border border-grey-300
            bg-white
            pb-2
            pt-2
            shadow-500
          "
        >
          <ul class="max-h-[335px] w-full px-3 pt-2">
            <t-input-radio v-model="value" name="business-hours" :value="true" @change="handleSelectItem">
              {{ $t('reports_v2.including_business_hours') }}
            </t-input-radio>
            <t-input-radio v-model="value" name="business-hours" :value="false" @change="handleSelectItem">
              {{ $t('reports_v2.excluding_business_hours') }}
            </t-input-radio>
          </ul>
        </div>
      </template>
    </t-popover>
  </li>
</template>

<script lang="ts">
import { CaretDownFill } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Teams',
  emits: ['filter-updated'],
  components: { CaretDownFill },
  data() {
    return {
      isOpen: false,
      value: null,
    };
  },
  props: {
    activeItem: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.value = this.activeItem;
  },
  methods: {
    handleSelectItem(event: Event) {
      this.$emit('filter-updated', { with_business_hours: event.target?.value });
    },
  },
});
</script>
