<template>
  <div>
    <settings-sidebar :list-items="sidebarItems" :show-back="true" />
    <portal v-if="showUpgradeBar" to="sidebar/bottom">
      <div class="w-full border-t-1 border-grey-300">
        <div class="m-3">
          <t-list-item
            size="sm"
            :label="$tc('integration_hub.basic_integrations')"
            data-test="edit-installed-integration"
            @click="isModalOpen = true"
          >
            <template #postfix>
              <t-badge :variant="'upgrade'" :text="'Boost'">
                <template #icon>
                  <arrow-top-circle-linear size="1rem" />
                </template>
              </t-badge>
            </template>
          </t-list-item>
        </div>
      </div>
    </portal>
    <upgrade-modal :is-open="isModalOpen" :is-first-time="isFirstTime" @close="modalClosed()"></upgrade-modal>
  </div>
</template>

<script lang="ts">
import { ArrowTopCircleLinear } from '@trengo/trengo-icons';
import { mapState, mapStores } from 'pinia';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import SettingsSidebar from '@/components/common/SettingsSidebar';
import UpgradeModal from '@/components/IntegrationHub/Components/Modals/UpgradeModal.vue';
import { FEATURE } from '@/Configs/Constants/Feature';
import { useEntitlementsStore } from '@/store/pinia';

import type { ListItem } from '@/components/common/SettingsSidebar/types';
import type { Integration } from '@/store/types/integrations';
import type { ClientSubscriptionEntitlement } from '@/types';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'IntegrationHubSidebar',

  components: { SettingsSidebar, UpgradeModal, ArrowTopCircleLinear },
  props: {
    integrations: {
      type: Array as PropType<Integration[]>,
      default: () => [],
    },
  },

  data() {
    return {
      FEATURE,
      isModalOpen: false,
      isFirstTime: false,
      showUpgradeBar: false,
    };
  },

  methods: {
    modalClosed() {
      this.isFirstTime = false;
      this.isModalOpen = false;
    },
    onLoad() {
      try {
        const isEntitled = this.entitlementsStore?.isEntitledToEnforce(
          FEATURE.CUSTOMER_SERVICE__INBOX__INTEGRATIONS__BASIC
        );
        const configHideBanner = this.getUserConfig('integration_hub');
        if (!isEntitled) {
          this.showUpgradeBar = true;
        }
        if (
          !isEntitled &&
          (configHideBanner['hide_upgrade_banner'] === undefined || configHideBanner['hide_upgrade_banner'] === false)
        ) {
          this.isFirstTime = true;
          this.isModalOpen = true;
          this.$store.dispatch('userConfig/put', { key: 'integration_hub', value: { hide_upgrade_banner: true } });
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  watch: {
    isEntitlementsInitialized(val: boolean) {
      if (val) {
        this.onLoad();
      }
    },
  },
  computed: {
    ...mapStores(useEntitlementsStore),
    ...mapState(useEntitlementsStore, {
      isEntitlementsInitialized: 'isInitialized',
    }),
    ...mapGetters({
      getUserConfig: 'userConfig/get',
    }),

    currentFilter() {
      return this.$route.params.filter;
    },

    // This information will most likely come from the backend in the future, or otherwise this would be fetched from a Vuex store
    integrationCounts() {
      return {
        all: this.integrations.length ?? 0,
        installed: this.integrations.filter((integration) => integration.isInstalled).length ?? 0,
        crm: this.integrations.filter((integration) => integration.filterType === 'crm').length ?? 0,
        commerce: this.integrations.filter((integration) => integration.filterType === 'commerce').length ?? 0,
      };
    },

    installedIntegrationsSideBarItem() {
      return {
        title: this.$t('integration_hub.manage'),
        children: [
          {
            label: this.$t('integration_hub.filter_installed_title'),
            route: '/admin/integrations/installed',
            badgeCount: String(this.integrationCounts.installed),
            isActive: this.currentFilter === 'installed',
          },
        ],
      } as ListItem;
    },

    sidebarItems(): ListItem[] {
      const defaultListItems: ListItem[] = [
        {
          title: this.$t('integration_hub.categories'),
          children: [
            {
              label: this.$t('integration_hub.filter_all_title'),
              route: '/admin/integrations/all',
              badgeCount: String(this.integrationCounts.all),
              isActive: this.currentFilter === 'all',
            },
            {
              label: this.$t('integration_hub.filter_crm_title'),
              route: '/admin/integrations/crm',
              badgeCount: String(this.integrationCounts.crm),
              isActive: this.currentFilter === 'crm',
            },
            {
              label: this.$t('integration_hub.filter_commerce_title'),
              route: '/admin/integrations/commerce',
              badgeCount: String(this.integrationCounts.commerce),
              isActive: this.currentFilter === 'commerce',
            },
          ],
        },
        {
          title: this.$t('integration_hub.developer'),
          children: [
            {
              label: this.$t('apps_integration.webhooks'),
              route: '/admin/integrations/webhooks',
              isActive: this.$route.matched.some(({ name }) => /webhook.+/.test(name)),
            },
            {
              label: this.$t('settings.rest_api'),
              route: '/admin/integrations/rest',
              isActive: this.$route.matched.some(({ name }) => name === 'rest'),
            },
            {
              label: this.$t('settings.zapier'),
              route: '/admin/integrations/zapier',
              isActive: this.$route.matched.some(({ name }) => name === 'zapier'),
            },
            {
              label: this.$t('apps_integration.custom_integrations'),
              route: '/admin/integrations/plugins',
              isActive: this.$route.matched.some(({ name }) => /plugin.+/.test(name)),
            },
          ],
        },
      ];

      return this.integrationCounts.installed > 0
        ? [this.installedIntegrationsSideBarItem, ...defaultListItems]
        : defaultListItems;
    },
  },
});
</script>
