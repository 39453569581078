import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_switch = _resolveComponent("t-switch")!
  const _component_t_badge = _resolveComponent("t-badge")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h4", {
        class: _normalizeClass([_ctx.headerColor, "t-text-h4 mb-0"])
      }, _toDisplayString(_ctx.headingText), 3 /* TEXT, CLASS */),
      _withDirectives((_openBlock(), _createElementBlock("div", null, [
        (_ctx.shouldShowSwitch)
          ? (_openBlock(), _createBlock(_component_t_switch, {
              key: 0,
              modelValue: _ctx.computedValue,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValue) = $event)),
              size: "md",
              class: "ml-2",
              disabled: _ctx.isToggleDisabled,
              onInput: _ctx.toggleSwitch
            }, null, 8 /* PROPS */, ["modelValue", "disabled", "onInput"]))
          : _createCommentVNode("v-if", true)
      ])), [
        [_directive_tooltip, 
          _ctx.tooltipText && {
            placement: 'top',
            content: _ctx.tooltipText,
            popperClass: 'tooltip-vuetify tooltip-medium',
          }
        ]
      ]),
      (_ctx.shouldShowBadge)
        ? (_openBlock(), _createBlock(_component_t_badge, {
            key: 0,
            class: "ml-auto",
            text: _ctx.badgeText,
            variant: _ctx.badgeVariant
          }, null, 8 /* PROPS */, ["text", "variant"]))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", {
        class: _normalizeClass([_ctx.subtitleColor, "t-text-sm mb-3"])
      }, _toDisplayString(_ctx.subtitle), 3 /* TEXT, CLASS */)
    ])
  ]))
}