<script lang="ts" setup>
import { CommentsLinear } from '@trengo/trengo-icons';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { formatPrice } from '@/util/currency';

import BlockStepper from './BlockStepper.vue';
import { useConversationBlocks } from './useConversationBlocks';

import type { BillingFrequency } from '@/types';

type ConversationBlocksEditorProps = {
  blocks: number;
  error?: string;
  disabled?: boolean;
  isSubmitted?: boolean;
  scheduledBlocks?: number;
};
const { t } = useI18n();
const props = defineProps<ConversationBlocksEditorProps>();
const emit = defineEmits(['change', 'edit']);

const CONVERSATION_QUANTITY: Record<BillingFrequency, number> = {
  monthly: 100,
  annually: 1200,
};

const conversationBlocks = ref(props.blocks);

const { billingFrequency, includedConversations, unitPrice } = useConversationBlocks();

const priceBreakdown = computed(() => {
  const totalPrice = formatPrice(unitPrice.value * conversationBlocks.value, 2);

  return `${formatPrice(unitPrice.value, 2)} x ${conversationBlocks.value} = ${totalPrice}`;
});

const totalConversations = computed(() =>
  billingFrequency.value
    ? conversationBlocks.value * CONVERSATION_QUANTITY[billingFrequency.value] + includedConversations.value
    : 0
);
const hasScheduledChanges = computed(() => props.scheduledBlocks !== undefined);

const handleChange = (blocks: number) => {
  conversationBlocks.value = blocks;
  emit('change', blocks);
};

const handleEdit = () => {
  emit('edit');
};
</script>

<template>
  <div>
    <div class="flex w-full items-center gap-3 rounded-xl bg-grey-200 p-2 text-grey-800">
      <t-thumbnail class="bg-white">
        <comments-linear size="1rem" />
      </t-thumbnail>
      <div class="flex-grow">
        <div class="t-text-sm flex items-center gap-2 font-semibold">
          {{ t('edit_subscription.conversation_block_input_standard_title') }}
        </div>
        <div class="t-text-sm text-grey-600">
          {{ priceBreakdown }} /{{ t(`edit_subscription.billing_frequency_${billingFrequency}_title`) }}
        </div>
      </div>
      <block-stepper
        v-if="!disabled"
        :value="conversationBlocks"
        :min="0"
        :max="2000"
        error="error"
        :indicator-text="
          t('edit_subscription.conversation_block_input_placeholder_monthly', {
            blocks: CONVERSATION_QUANTITY.monthly,
          }).toString()
        "
        @change="handleChange"
      />
      <div
        v-if="disabled"
        class="border-gray-400 t-text-sm flex h-9 w-[220px] items-center justify-between gap-6 rounded-md border px-2"
      >
        <div>
          <span v-if="!isSubmitted" class="mr-1 text-sky-600">{{ scheduledBlocks }}</span>
          <span class="text-grey-500" :class="{ 'line-through': hasScheduledChanges || isSubmitted }">
            {{ blocks }}
          </span>
        </div>
        <div class="text-grey-600">
          {{
            t('edit_subscription.conversation_block_input_placeholder_monthly', {
              blocks: CONVERSATION_QUANTITY.monthly,
            })
          }}
        </div>
      </div>
      <t-button v-if="disabled" :disabled="isSubmitted" btn-style="secondary" @click="handleEdit">
        {{ t('general.edit') }}
      </t-button>
    </div>
    <div v-if="!disabled" class="mt-2 w-full text-right">
      <strong class="t-text-sm-emphasize">{{ t('edit_subscription.total') }}</strong>
      <span class="t-text-sm text-grey-600">
        {{
          `${totalConversations} ${t(`edit_subscription.conversation_blocks_standard`)} ${t(
            `edit_subscription.conversation_blocks_total_per_${billingFrequency}`
          )}`
        }}
      </span>
    </div>
    <t-error-item v-if="error" class="ml-4" danger-icon :text="error" />
  </div>
</template>
