<template>
  <div class="w-full">
    <mobile-preview-card class="max-h-screen w-full">
      {{ $t('general.feature_only_available_on_desktop') }}
    </mobile-preview-card>
    <wab-broadcasting-landing v-if="!hasList && loaded" class="hidden-md-down" />
    <broadcasting-list
      v-else-if="loaded"
      :list="list"
      class="hidden-md-down"
      :loading="loading"
      @infinite-scroll="onInfinite"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import MobilePreviewCard from '@/components/common/MobilePreviewCard';
import { getBroadcastList } from '@/components/WabBroadcasting/api';
import BroadcastingList from '@/components/WabBroadcasting/pages/BroadcastingList';
import WabBroadcastingLanding from '@/components/WabBroadcasting/pages/WabBroadcastingLanding';

export default defineComponent({
  name: 'BroadcastingView',
  components: { BroadcastingList, WabBroadcastingLanding, MobilePreviewCard },
  data() {
    return {
      list: [],
      loaded: false,
      interval: null,
      page: 1,
      loading: false,
    };
  },
  computed: {
    hasList() {
      return this.list.length > 0;
    },
  },
  mounted() {
    this.getData();
    this.refreshData();
  },
  unmounted() {
    this.clearRefreshInterval();
  },
  methods: {
    getData() {
      getBroadcastList().then(({ data }) => {
        this.list = data?.data;
        this.loaded = true;
      });
    },
    refreshData() {
      if (this.page === 1) this.interval = setInterval(this.getData, 10000);
    },
    clearRefreshInterval() {
      clearInterval(this.interval);
    },
    onInfinite(scroll) {
      if (this.page === 1) {
        this.page++;
      }
      this.loading = true;
      getBroadcastList({ page: this.page })
        .then(({ data }) => {
          if (data?.data?.length > 0) {
            this.list = [...this.list, ...data.data];
            this.page++;
            scroll.loaded();
            this.clearRefreshInterval();
          } else {
            scroll.complete();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
</script>
