<template>
  <div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.picqer_label_subdomain') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <div class="input-group">
          <div class="input-group-btn">
            <span class="btn disabled bg-grey-600 text-white">{{ $t('integrations.picqer_button_https') }}</span>
          </div>
          <input
            v-model="record.meta.url"
            type="text"
            class="form-control"
            :placeholder="$t('integrations.picqer_placeholder_subdomain')"
            aria-describedby="inputGroupPrepend"
            required
          />
          <div class="input-group-btn">
            <span class="btn disabled bg-grey-600 text-white">
              {{ $t('integrations.picqer_button_subdomain_url') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.picqer_label_api_key') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.api_key" type="text" autocomplete="off" class="form-control" />
        <!--                TODO L link to help center article -->
        <!--                <span class="form-text">Your API key. <a href="https://help.shopify.com/en/api/getting-started/authentication/private-authentication" target="_blank">Learn how</a>.</span>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
