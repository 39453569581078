<script setup lang="ts">
import { onMounted, ref } from 'vue';

import { useOutboundTranslationStore } from './useOutboundTranslationStore';

import type { Locales } from './useLocales';

const emit = defineEmits<{
  (e: 'select-locale', locale: Locales): void;
}>();

const outboundTranslationStore = useOutboundTranslationStore();
const modal = ref(document.createElement('div'));
onMounted(() => modal.value.focus());
</script>

<template>
  <div
    ref="modal"
    data-test="modal-translation"
    class="
      absolute
      bottom-[125%]
      left-0
      z-10
      flex
      w-64
      transform-gpu
      flex-col
      gap-1
      rounded-xl
      border-1 border-solid
      bg-white
      p-2
      shadow-sm
      md:right-0
      xl:left-[initial]
    "
  >
    <button
      v-for="[key, locale] in Object.entries(outboundTranslationStore.locales)"
      :key="locale"
      type="button"
      class="
        block
        w-full
        rounded-lg
        p-2
        text-left
        transition-transform
        hover:bg-grey-200
        focus:bg-grey-200
        active:scale-95
      "
      :class="{ 'bg-grey-200': outboundTranslationStore.locale === key }"
      @click="emit('select-locale', key as Locales)"
    >
      {{ locale }}
    </button>
  </div>
</template>
