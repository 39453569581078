<template>
  <div>
    <div class="flex w-full flex-col md:w-auto xl:flex-row">
      <t-button
        v-if="isEntitled"
        size="md"
        class="open-external mb-4 mr-0 xl:mb-0 xl:mr-4"
        @click="handleClickPrimaryButton"
      >
        {{ page.primary_button_text }}
      </t-button>

      <t-button
        v-if="isAdministrator && requiresPlanUpgrade"
        btn-style="primary"
        size="md"
        type="button"
        label="Button"
        class="mb-4 mr-0 xl:mb-0 xl:mr-4"
        @click="redirectToSubscription"
      >
        <div class="flex items-center justify-center">
          <arrow-top-circle-linear size="1.25rem" class="mr-1" />
          {{ page.entitlement_upgrade_button_text }}
        </div>
      </t-button>

      <t-button
        v-if="isAdministrator && requiresAddOn"
        btn-style="primary"
        size="md"
        type="button"
        label="Button"
        class="mb-4 mr-0 xl:mb-0 xl:mr-4"
        @click="redirectToSubscription"
      >
        <div class="flex items-center justify-center">
          <arrow-top-circle-linear size="1.25rem" class="mr-1" />
          {{ page.entitlement_add_on_button_text }}
        </div>
      </t-button>

      <t-button
        size="md"
        btn-style="secondary"
        :href="page.secondary_button_url"
        rel="noreferrer noopener"
        target="_blank"
        class="open-external flex flex-row items-center"
      >
        {{ page.secondary_button_text }}
        <external-link-linear class="ml-1" />
      </t-button>
    </div>
    <p v-if="!isAdministrator && requiresPlanUpgrade" class="t-text-sm mt-3 text-grey-700">
      {{ $t('admin_inboxes.ask_your_administrator_to_upgrade') }}
    </p>
  </div>
</template>

<script lang="ts">
import { ArrowTopCircleLinear, ExternalLinkLinear } from '@trengo/trengo-icons';
import { mapStores, mapState } from 'pinia';
import { defineComponent } from 'vue';

import { ENTITLEMENT_STATUS } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useEntitlementsStore, useUserStore } from '@/store/pinia';

import type { FEATURE } from '@/Configs/Constants';
import type { PropType } from 'vue';

type Page = {
  name: string;
  title: string;
  intro: string;
  icon: string;
  primary_button_text: string;
  primary_button_url: string;
  showModal: boolean;
  secondary_button_text: string;
  secondary_button_url: string;
  entitlement_upgrade_button_text: string;
  entitlement_add_on_button_text: string;
  entitlement_feature_id: typeof FEATURE;
  implements_entitlement: boolean;
};
export default defineComponent({
  name: 'AdminIndexFooterForEntitlements',
  components: { ArrowTopCircleLinear, ExternalLinkLinear },
  props: {
    page: {
      type: Object as PropType<Page>,
      default: {} as Page,
    },
  },
  computed: {
    ...mapStores(useEntitlementsStore),
    ...mapState(useUserStore, {
      isAdministrator: 'hasAdminRole',
    }),
    requiresPlanUpgrade() {
      return (
        this.entitlementsStore?.getEntitlementStatusFor(this.page.entitlement_feature_id) ===
          ENTITLEMENT_STATUS.REQUIRES_PLAN_UPGRADE ||
        this.entitlementsStore?.getEntitlementStatusFor(this.page.entitlement_feature_id) ===
          ENTITLEMENT_STATUS.REQUIRES_ADDON_AND_PLAN_UPGRADE
      );
    },
    requiresAddOn() {
      return (
        this.entitlementsStore?.getEntitlementStatusFor(this.page.entitlement_feature_id) ===
        ENTITLEMENT_STATUS.REQUIRES_ADDON
      );
    },
    isEntitled() {
      return (
        this.entitlementsStore?.getEntitlementStatusFor(this.page.entitlement_feature_id) ===
        ENTITLEMENT_STATUS.ENTITLED
      );
    },
  },
  methods: {
    redirectToSubscription() {
      this.$router.push('/admin/subscription_settings');
    },
    handleClickPrimaryButton() {
      if (this.page.showModal) {
        eventBus.$emit('showModal', this.page.primary_button_url);
      } else {
        this.$router.push(this.page.primary_button_url);
      }
    },
  },
});
</script>
