<template>
  <div class="more-option-icon-button" @click="handleClick">
    <more-horiz-fill size="1.5rem" />
  </div>
</template>

<script>
import { MoreHorizFill } from '@trengo/trengo-icons';

export default {
  name: 'MoreOptionIconButton',
  emits: ['click'],
  components: { MoreHorizFill },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style src="./MoreOptionIconButton.scss" scoped lang="scss" />
