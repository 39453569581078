<script type="text/babel">
import { mapStores } from 'pinia';

import { FEATURE } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useEntitlementsStore } from '@/store/pinia';

export default {
  name: 'HelpCenterAuthentication',
  emits: ['call-load-help-center-method'],
  props: {
    helpCenter: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      name: this.helpCenter.name,
      password: '',
      saving: false,
    };
  },

  computed: {
    ...mapStores(useEntitlementsStore),
    isEntitled() {
      return this.entitlementsStore?.isEntitledTo(FEATURE.CUSTOMER_SERVICE__HELPCENTER__WHITELABEL);
    },
  },

  methods: {
    save() {
      this.saving = true;

      let data = {
        name: this.name,
        password: this.password,
      };

      axios
        .post('/api/v2/help_center/' + this.helpCenter.id + '/authentication', data)
        .then((res) => {
          this.saving = false;
          this.helpCenter.has_password = true;
          this.$emit('call-load-help-center-method');
          eventBus.$emit('help_center.list.reload');
          this.flashSuccess(this.$t('help_center.you_have_successfully_updated_your_help_center_authentication'));
        })
        .catch(() => {
          this.saving = false;
        });
    },

    deletePassword() {
      axios
        .delete('/api/v2/help_center/' + this.helpCenter.id + '/authentication')
        .then((res) => {
          this.helpCenter.has_password = false;
          this.$emit('call-load-help-center-method');
          eventBus.$emit('help_center.list.reload');
          this.flashSuccess(this.$t('help_center.you_have_successfully_updated_your_help_center_authentication'));
        })
        .catch(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<template>
  <div class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis">{{ $t('help_center.authentication') }}</span>
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <div class="p-a container">
            <form @submit.prevent="save">
              <div class="box">
                <div class="box-header">
                  <h2>{{ $t('help_center.password_protection') }}</h2>
                </div>
                <div class="box-divider m-a-0"></div>
                <div class="box-body">
                  <div v-if="!helpCenter.has_password" class="form-group row">
                    <label class="col-sm-2 form-control-label">{{ $t('help_center.password') }}</label>
                    <div class="col-sm-8">
                      <input v-model="password" type="password" autocomplete="off" class="form-control" />
                      <span class="form-text">{{ $t('help_center.authentication_password_description') }}</span>
                    </div>
                  </div>
                  <div v-else>
                    <div class="alert alert-info">
                      {{ $t('help_center.your_help_center_is_protected_by_a_password') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="box">
                <div class="box-body flow-root">
                  <button
                    v-if="!helpCenter.has_password"
                    type="submit"
                    class="btn btn-success text-white"
                    :disabled="saving || !isEntitled"
                    v-text="saving ? $t('help_center.saving') : $t('help_center.save_changes')"
                  ></button>
                  <a v-else class="btn danger text-white" @click="deletePassword()">
                    {{ $t('help_center.delete_password') }}
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
