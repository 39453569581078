<template>
  <div v-if="isEntitled" data-test="reporting-is-entitled" class="flex flex-grow">
    <NewReporting v-if="renderNewReporting" />
    <OldReporting v-else />
  </div>
  <div v-else data-test="reporting-is-not-entitled">
    <reporting-entitlement-page />
  </div>
</template>

<script lang="ts">
import { mapStores } from 'pinia';

import NewReporting from '@/components/Reporting/Pages/Index.vue';
import OldReporting from '@/components/Reporting/Pages/OldIndex.vue';
import { FEATURE } from '@/Configs/Constants/Feature';
import { useEntitlementsStore } from '@/store/pinia';

import ReportingEntitlementPage from './ReportingEntitlementPage.vue';
import { isUserRegisteredAfter } from '../Util';

export default {
  components: {
    NewReporting,
    OldReporting,
    ReportingEntitlementPage,
  },
  data() {
    return {
      renderNewReporting: false,
    };
  },
  methods: {
    showTheReportingBasedOnFeatureFlagAndDate() {
      const NovemberFirst2023 = '01/11/2023';
      const isRegisteredAfterNovember = isUserRegisteredAfter(NovemberFirst2023, this.$root.agency.created_at);
      const isOldRoute = this.$route?.path.includes('/old');

      if (isRegisteredAfterNovember) {
        this.handleUserRegisteredAfterNovember(isOldRoute);
      } else {
        this.handleUserRegisteredBeforeNovember(isOldRoute);
      }
    },

    handleUserRegisteredAfterNovember(isOldRoute: boolean) {
      this.renderNewReporting = true;
      if (isOldRoute) {
        this.$router.push({ name: 'reportingDashboard' });
      }
    },

    handleUserRegisteredBeforeNovember(isOldRoute: boolean) {
      this.renderNewReporting = !isOldRoute;
      if (isOldRoute) {
        this.$router.push({ name: 'reportingDashboard' });
      }
    },
  },
  mounted() {
    if (this.isEntitled) {
      this.showTheReportingBasedOnFeatureFlagAndDate();
    }
  },
  computed: {
    ...mapStores(useEntitlementsStore),
    isEntitled() {
      return this.entitlementsStore?.isEntitledTo(FEATURE.CUSTOMER_SERVICE__REPORTING);
    },
  },
  watch: {
    $route: function (to, from) {
      if (to.path.includes('/reports/old') && from.path.includes('/reports')) {
        this.renderNewReporting = false;
      } else if (to.path.includes('/reports') && from.path.includes('/reports/old')) {
        this.renderNewReporting = true;
      }
    },
    isEntitled(newVal, oldVal) {
      if (newVal) {
        this.showTheReportingBasedOnFeatureFlagAndDate();
      }
    },
  },
};
</script>
