<template>
  <t-modal v-model="shouldOpenModal" :close-icon="true" variant="narrow" is-closable @close="$emit('close')">
    <template #header>
      <h3 class="t-text-h3 my-auto max-w-[90%] p-0">{{ $t('integration_hub.edit_integration') }}</h3>
    </template>

    <div class="flex flex-col">
      <template v-if="inProgress">
        <integration-loading v-if="inProgress" class="mt-4" />
      </template>
      <template v-else>
        <dynamic-form-fields
          v-if="integration.form_fields.length > 0"
          :prefilled-settings="oldSettings"
          @formChanged="onFormChanged"
        />
      </template>
    </div>

    <template #footer>
      <t-button btn-style="secondary" :disabled="inProgress" @click="$emit('close')">
        {{ $t('general.cancel') }}
      </t-button>

      <t-button class="flex flex-row items-center justify-center" :disabled="isSaveDisabled" @click="save">
        <p class="m-0 p-0">{{ $t('general.save') }}</p>
        <t-spinner v-if="isSaving" size="1rem" class="ml-2" />
      </t-button>
    </template>
  </t-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { fetchInstallation, updateInstallation } from '@/api';
import DynamicFormFields from '@/components/IntegrationHub/Components/DynamicFormFields.vue';
import IntegrationLoading from '@/components/TicketSidebar/Integrations/IntegrationLoading.vue';

import type { FormFieldWithValue, Installation } from '@/store/types/integrations';
import type { PropType } from 'vue';
export default defineComponent({
  name: 'IntegrationHubEditModal',
  emits: ['close', 'save'],
  components: {
    IntegrationLoading,
    DynamicFormFields,
  },
  props: {
    installation: {
      type: Object as PropType<Installation>,
      default: () => ({}),
    },
  },
  data() {
    return {
      oldSettings: {} as Record<string, unknown>,
      inProgress: false,
      isSaving: false,
      newSettings: {} as Record<string, unknown>,
      shouldOpenModal: true,
    };
  },
  computed: {
    ...mapGetters({
      integration: 'integrations/getItem',
    }),
    isSaveDisabled() {
      const isAnyFieldEmpty = this.integration.form_fields.some(
        (field: FormFieldWithValue) => field.required && !this.newSettings[field.id]
      );
      return this.isSaving || this.inProgress || isAnyFieldEmpty;
    },
  },

  methods: {
    async save() {
      this.isSaving = true;
      try {
        await updateInstallation(parseInt(this.$route.params.integrationId), this.installation.id, {
          settings: this.newSettings,
        });
        this.$emit('updated', true);
      } catch (e) {
        console.error(e);
      } finally {
        this.isSaving = false;
      }
    },
    onFormChanged(formData: Array<FormFieldWithValue>) {
      this.newSettings = formData;
    },
  },

  async mounted() {
    this.inProgress = true;
    try {
      const { data: resp } = await fetchInstallation(parseInt(this.$route.params.integrationId), this.installation.id);
      this.oldSettings = resp.settings;
    } catch (e) {
      console.error(e);
    } finally {
      this.inProgress = false;
    }
  },
});
</script>
