<template>
  <div class="mx-auto mt-32 flex flex-col items-center">
    <t-thumbnail size="large" class="mb-6 bg-white">
      <shopping-cart-linear />
    </t-thumbnail>

    <h1 class="t-text-h4 m-0 mb-2 p-0">
      {{ $tc('integration_hub.ecom_coming_soon') }}
    </h1>

    <p class="t-text-md m-0 mb-6 p-0 text-grey-700">
      {{ $tc('integration_hub.ecom_is_available') }}
    </p>

    <t-button
      btn-style="secondary"
      class="flex flex-row items-center justify-center"
      @click.prevent="$router.push('/admin/integrations/plugins')"
    >
      <p class="t-text-sm-emphasize m-0 p-0 text-grey-900">{{ $tc('apps_integration.custom_integrations') }}</p>

      <arrow-right-linear class="ml-1" size="1.25rem" />
    </t-button>
  </div>
</template>

<script setup lang="ts">
import { ArrowRightLinear, ShoppingCartLinear } from '@trengo/trengo-icons';
</script>
