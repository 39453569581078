import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex w-full flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_integration_crm = _resolveComponent("integration-crm")!
  const _component_integration_commerce = _resolveComponent("integration-commerce")!
  const _component_integration_card_icon = _resolveComponent("integration-card-icon")!
  const _component_sidebar_dropdown = _resolveComponent("sidebar-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.installations, (integration) => {
      return (_openBlock(), _createBlock(_component_sidebar_dropdown, {
        key: integration.id,
        title: integration.name,
        "has-icon": true,
        "collapse-key": `${integration.installation_id}${integration.name.replace(/\s/gm, '')}DropdownItem`,
        "card-id": integration.installation_id,
        "data-test": "legacy-integration-dropdown",
        "external-link": integration.deeplink,
        "is-integration-dropdown": "",
        onToggled: _ctx.onDropdownToggle
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_integration_card_icon, {
            class: "mr-2",
            width: 24,
            icon: integration.icon
          }, null, 8 /* PROPS */, ["icon"])
        ]),
        default: _withCtx(() => [
          (integration.filterType === 'crm')
            ? (_openBlock(), _createBlock(_component_integration_crm, {
                key: 0,
                installation: integration,
                ticket: _ctx.ticket
              }, null, 8 /* PROPS */, ["installation", "ticket"]))
            : _createCommentVNode("v-if", true),
          (integration.filterType === 'commerce')
            ? (_openBlock(), _createBlock(_component_integration_commerce, {
                key: 1,
                installation: integration,
                ticket: _ctx.ticket
              }, null, 8 /* PROPS */, ["installation", "ticket"]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "collapse-key", "card-id", "external-link", "onToggled"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}