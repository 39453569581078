<template>
  <div v-bind="$attrs" class="collapse-permissions-button">
    <span>
      {{
        isPermissionsCollapsed
          ? $t('user_management.collapse_default_permissions')
          : $t('user_management.show_default_permissions')
      }}
    </span>
    <arrow-bottom-linear size="1rem" :class="{ 'rotate-180 transform': isPermissionsCollapsed }" />
  </div>
</template>

<script>
import { ArrowBottomLinear } from '@trengo/trengo-icons';

export default {
  name: 'CollapsePermissionsButton',
  components: { ArrowBottomLinear },
  props: {
    isPermissionsCollapsed: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" src="./CollapsePermissionsButton.scss" />
