<script>
export default {
  emits: ['clicked'],
  props: {
    cardTitle: {
      type: String,
      default: '',
    },
    cardOrder: {
      type: String,
      default: '',
    },
    collapsed: {
      type: Boolean,
    },
    ariaExpanded: {
      type: String,
      default: 'false',
    },
    dataParent: {
      type: String,
      default: '',
    },
  },
};
</script>
<template>
  <div class="panel card configuration-card custom-border-radius border-0">
    <div
      :id="cardTitle + '_heading'"
      class="card-header flex"
      :class="{ collapsed: !collapsed }"
      role="tab"
      :data-parent="dataParent"
      :aria-expanded="null"
      :aria-controls="cardTitle + '_section'"
      data-toggle="collapse"
      :data-target="'#' + cardTitle + '_section'"
      @click="$emit('clicked')"
    >
      <h5 class="mb-0">
        <slot name="card-title"></slot>
      </h5>
      <span class="ml-auto">
        <i class="material-icons rotate-arrow md-18">keyboard_arrow_left</i>
      </span>
    </div>

    <div
      :id="cardTitle + '_section'"
      role="tabpanel"
      :aria-labelledby="cardTitle + '_heading'"
      class="collapse"
      :class="{ in: collapsed }"
    >
      <div class="panel-body">
        <slot name="card-content"></slot>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.configuration-card {
  //overflow: hidden;

  .card-header {
    cursor: pointer;
    transition: all 300ms ease;
    background-color: #14b29f;
    color: #fff;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
    .step {
      vertical-align: top;
      background-color: #fff;
      color: #14b29f;
      border-radius: 500px;
      padding: 0;
      line-height: 22px;
      width: 22px;
      height: 22px;
      text-align: center;
      margin: 0 0.5rem 0 0;
      font-size: 0.75rem;
      display: inline-block;
    }
    .material-icons.rotate-arrow {
      transition: transform 300ms ease;
      transform-origin: 50% 50%;
      transform: rotateZ(-90deg);
    }
    &.collapsed {
      background-color: #fff;
      color: #808080;
      border-radius: 10px;
      &.done {
        color: #000;
      }

      .step {
        background-color: #cacaca;
        color: #fff;

        &.done {
          background-color: #14b29f;
        }

        &.warning {
          background-color: #f6993f;
        }
      }

      .material-icons.rotate-arrow {
        transform: rotateZ(0);
      }
    }
  }

  .panel-body {
    border: 1px solid #14b29f;
    border-top: 0;
    border-radius: 0 0 10px 10px;
  }
}

.custom-shadow-outline {
  box-shadow: rgba(0, 0, 0, 0.11) 0 0 9px 0;
}

.custom-border-radius {
  border-radius: 10px;
}
</style>
