<template>
  <t-inline-banner class="wa-btn-info">
    <template #icon>
      <info-linear class="info-icon" size="22" />
    </template>
    <template #default>
      <p class="wa-btn-info__content">
        {{ $t('whatsapp.whatsapp_template_only_being_used_for_trengo_provider_info') }}
      </p>
    </template>
  </t-inline-banner>
</template>

<script>
import { InfoLinear } from '@trengo/trengo-icons';
export default {
  name: 'WaButtonsInfo',
  components: { InfoLinear },
};
</script>

<style lang="scss" scoped src="./WaButtonsInfo.scss" />
