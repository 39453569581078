<script type="text/babel">
import { FEATURE_FLAG_SETTINGS } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

import SettingsSidebar from '../common/SettingsSidebar';

const headerListItem = {
  label: 'Chatbots',
  route: '/admin/chatbots/',
};

export default {
  name: 'ChatbotEdit',
  components: {
    SettingsSidebar,
  },

  data() {
    return {
      record: {},
      loaded: false,
      sidebarHeaderListItem: headerListItem,
    };
  },

  mounted() {
    this.loadChatbot();
  },

  computed: {
    isFeatureFlagEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_SETTINGS.TA_SETTINGS_SIDEBAR);
    },
    sidebarListItems() {
      const baseRoute = `/admin/chatbots/${this.$route.params.id}`;
      return [
        {
          title: this.record.title,
          children: [
            {
              label: this.$t('chatbot.questions'),
              route: `${baseRoute}/questions`,
              isActive: this.$router.resolve(`${baseRoute}/questions`).path === this.$route.path,
            },
            {
              label: this.$t('chatbot.categories'),
              route: `${baseRoute}/categories`,
              isActive: this.$router.resolve(`${baseRoute}/categories`).path === this.$route.path,
            },
            {
              label: this.$t('chatbot.playground'),
              route: `${baseRoute}/playground`,
              isActive: this.$router.resolve(`${baseRoute}/playground`).path === this.$route.path,
            },
            {
              label: this.$t('chatbot.mismatches'),
              route: `${baseRoute}/mismatches`,
              isActive: this.$router.resolve(`${baseRoute}/mismatches`).path === this.$route.path,
            },
            {
              label: this.$t('chatbot.settings'),
              route: `${baseRoute}/settings`,
              isActive: this.$router.resolve(`${baseRoute}/settings`).path === this.$route.path,
            },
          ],
        },
      ];
    },
  },

  //mixins: [RouterWarningOnEdit], TODO warning: but only on settings page, or show a save button on all chatbot pages

  methods: {
    loadChatbot() {
      axios
        .get('/api/v2/chatbot/' + this.$route.params.id)
        .then((res) => {
          this.record = res.data;
          this.loaded = true;
        })
        .catch(() => {
          this.$router.push('/chatbots');
        });
    },
    updateChatbotRecord(record) {
      this.record = record;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.onBeforeRouteLeaveGuard(to, from, next);
  },
  beforeRouteUpdate(to, from, next) {
    this.onBeforeRouteUpdateGuard(to, from, next);
  },
};
</script>

<template>
  <div class="row-col">
    <div id="settings-secondary" class="col-xs modal fade aside aside-md w-medium w-200px">
      <settings-sidebar
        v-if="isFeatureFlagEnabled"
        :header-list-item="sidebarHeaderListItem"
        :list-items="sidebarListItems"
        alt
      />

      <div v-else class="nav-settings light left b-primary">
        <ul>
          <li class="text-md white header display-flex">
            <span class="text-truncate">{{ record.title }}</span>
            <a v-if="record.published" :href="record.url" target="_blank" class="m-l-xs text-sm">
              <i class="fa fa-external-link text-muted"></i>
            </a>
            <a data-dismiss="modal" class="hidden-md-up text-muted m-l-auto m-r-sm text-lg">×</a>
          </li>
          <li>
            <router-link
              data-dismiss="modal"
              class="nav-settings-link text-truncate"
              :to="'/admin/chatbots/' + record.id + '/questions'"
            >
              <i class="material-icons md-18">keyboard_arrow_right</i>
              {{ $t('chatbot.questions') }}
            </router-link>
          </li>
          <li>
            <router-link
              data-dismiss="modal"
              class="nav-settings-link text-truncate"
              :to="'/admin/chatbots/' + record.id + '/categories'"
            >
              <i class="material-icons md-18">keyboard_arrow_right</i>
              {{ $t('chatbot.categories') }}
            </router-link>
          </li>
          <li>
            <router-link
              data-dismiss="modal"
              class="nav-settings-link text-truncate"
              :to="'/admin/chatbots/' + record.id + '/playground'"
            >
              <i class="material-icons md-18">keyboard_arrow_right</i>
              {{ $t('chatbot.playground') }}
            </router-link>
          </li>
          <li>
            <router-link
              data-dismiss="modal"
              class="nav-settings-link text-truncate"
              :to="'/admin/chatbots/' + record.id + '/mismatches'"
            >
              <i class="material-icons md-18">keyboard_arrow_right</i>
              {{ $t('chatbot.mismatches') }}
            </router-link>
          </li>
          <li>
            <router-link
              data-dismiss="modal"
              class="nav-settings-link text-truncate"
              :to="'/admin/chatbots/' + record.id + '/settings'"
            >
              <i class="material-icons md-18">keyboard_arrow_right</i>
              {{ $t('chatbot.settings') }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="row-col">
      <div class="row-row">
        <div class="row-body scrollable">
          <div class="row-inner">
            <router-view
              v-if="loaded"
              :key="$route.fullPath"
              :record="record"
              @update-chatbot-record="updateChatbotRecord"
            ></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style type="text/scss">
.kb-nav .nav-link {
  padding: 15px 20px;
}
</style>
