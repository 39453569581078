<script lang="ts" setup>
import { computed, ref } from 'vue';

import { sendDangerZoneVerificationEmail } from '@/api';
import { useUserStore } from '@/store/pinia';
import { obscureEmail } from '@/util/stringHelpers';

const props = defineProps({ isEmailSent: Boolean });
const emit = defineEmits(['refresh', 'submit']);

const isSending = ref(false);
const userStore = useUserStore();
const email = computed(() => obscureEmail(userStore.user?.email || ''));
const sendEmail = async () => {
  try {
    isSending.value = true;
    await sendDangerZoneVerificationEmail();
    emit('submit');
  } catch (err) {
    console.error(err);
    throw err;
  } finally {
    isSending.value = false;
  }
};
const btnTextKey = computed(() =>
  isSending.value
    ? 'general.sending'
    : props.isEmailSent
    ? 'danger_zone.resend_email_btn'
    : 'danger_zone.send_email_btn'
);
</script>

<template>
  <div class="flex h-full w-full bg-grey-100">
    <div class="m-auto flex w-[670px] flex-col items-center justify-center">
      <img
        :src="`${
          /* @ts-ignore */
          $root.assetsURL
        }images/verification-required.svg`"
        alt="verification required illustration"
        class="mb-8"
      />
      <span class="t-text-h3 mb-2">
        {{ $t(props.isEmailSent ? 'danger_zone.email_sent_title' : 'danger_zone.verification_required_title') }}
      </span>
      <span class="t-text-md mb-8 text-center text-grey-700">
        {{
          $t(
            props.isEmailSent ? 'danger_zone.email_sent_description' : 'danger_zone.verification_required_description',
            {
              email,
            }
          )
        }}
      </span>
      <span class="t-text-sm mb-8 text-grey-600">{{ $t('danger_zone.verification_required_info') }}</span>
      <div class="flex gap-2">
        <t-button :disabled="isSending" :btn-style="props.isEmailSent ? 'secondary' : 'primary'" @click="sendEmail">
          {{ $t(btnTextKey) }}
        </t-button>
        <t-button v-if="props.isEmailSent" :disabled="isSending" :btn-style="'primary'" @click="emit('refresh')">
          {{ $t('general.refresh') }}
        </t-button>
      </div>
    </div>
  </div>
</template>
