import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center p-2" }
const _hoisted_2 = { class: "pr-2" }
const _hoisted_3 = { class: "text-xs" }
const _hoisted_4 = {
  key: 0,
  class: "font-weight-normal flex min-w-0 cursor-pointer flex-nowrap items-center hover:text-grey-800",
  "data-no-toggle": "",
  "data-hj-suppress": ""
}
const _hoisted_5 = {
  key: 0,
  class: "material-icons material-icon-mail pr-1"
}
const _hoisted_6 = {
  "data-hj-suppress": "",
  class: "text-ellipsis",
  "data-no-toggle": ""
}
const _hoisted_7 = {
  key: 1,
  class: "material-icons text-md"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar = _resolveComponent("avatar")!
  const _component_dropdown = _resolveComponent("dropdown")!

  return (_openBlock(), _createBlock(_component_dropdown, {
    search: false,
    "toggle-element-inline": true,
    options: _ctx.dropdownOptionObjects,
    width: "auto",
    "max-width": "300px",
    "onUpdate:modelValue": _ctx.onInput
  }, {
    heading: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openContact(_ctx.contact.id)))
          }, [
            _createVNode(_component_avatar, {
              color: _ctx.contact.color,
              abbr: _ctx.contact.abbr,
              width: "30",
              "font-size": "12px",
              image: _ctx.contact.profile_image
            }, null, 8 /* PROPS */, ["color", "abbr", "image"])
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("strong", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openContact(_ctx.contact.id)))
          }, _toDisplayString(_ctx.contact.full_name), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.contact.email), 1 /* TEXT */)
        ])
      ])
    ]),
    toggle: _withCtx(() => [
      (_ctx.contact.email)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
            (_ctx.icon)
              ? (_openBlock(), _createElementBlock("i", _hoisted_5, "email"))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.contact.email), 1 /* TEXT */),
            (_ctx.icon)
              ? (_openBlock(), _createElementBlock("i", _hoisted_7, "expand_more"))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["options", "onUpdate:modelValue"]))
}