import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "box-body flex justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_button = _resolveComponent("t-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.action === 'edit')
        ? (_openBlock(), _createBlock(_component_t_button, {
            key: 0,
            type: "button",
            class: "mr-4",
            "btn-style": "danger-alt",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('delete-wa-template')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('whatsapp.delete')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.action === 'create')
        ? (_openBlock(), _createBlock(_component_t_button, {
            key: 1,
            type: "button",
            class: "mr-4",
            "btn-style": "danger-alt",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('go-to-overview-page')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('whatsapp.cancel')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_t_button, {
        class: _normalizeClass(["mr-4", { loader: _ctx.saving }]),
        disabled: _ctx.disableCreateButton,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('track-create-template-button')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.submitButtonTitle), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class", "disabled"])
    ])
  ]))
}