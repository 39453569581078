<template>
  <div class="rounded-xl bg-grey-200 p-6">
    <h4 class="t-text-h5 text-grey-800">{{ $t('whatsapp.samples_for_body_content') }}</h4>
    <p class="t-text-md mb-0 text-grey-600">
      {{ $t('whatsapp.whatsapp_requires_you_to_prefill') }}
    </p>
    <div class="mt-6">
      <label
        v-show="selectedHeaderType === TEMPLATE_HEADER_TYPE.IMAGE"
        for="fileInput"
        class="mb-2 text-base font-semibold text-grey-800"
      >
        {{ $t('whatsapp.template_header_img_label') }}
      </label>
      <t-file-input
        v-show="selectedHeaderType === TEMPLATE_HEADER_TYPE.IMAGE"
        class="mb-2"
        :max-file-size="{
          size: TEMPLATE_HEADER_FILE_LIMIT_IN_BYTES,
          text: $t('whatsapp.image_sample_max_file_size', { fileSize: TEMPLATE_HEADER_FILE_LIMIT_IN_BYTES / 1000000 }),
        }"
        @update:value="$emit('update:image', $event)"
        @reset="$emit('update:image', [])"
      />
      <div v-for="(variable, index) in templateVariablesCopy" :key="variable.key" class="mb-2">
        <t-input-text
          :id="`variableSample-${variable.key}`"
          v-model.trim="variable.value"
          :placeholder="
            $t('whatsapp.type_something_to_replace', {
              variable: variable.key,
            })
          "
          type="text"
          :label="
            $t('whatsapp.samples_for_body_variable', {
              variable: variable.key,
            })
          "
          :disabled="disabled"
          data-test="variable-sample-input"
          @input="updateTemplateVariable($event, index)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { TEMPLATE_HEADER_FILE_LIMIT_IN_BYTES, TEMPLATE_HEADER_TYPE } from '@/Configs/Constants/whatsapp';

import type { TemplateVariable, TemplateHeaderType } from '../types';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'VariableSample',
  emits: ['update:image', 'update-template-variables'],
  props: {
    selectedHeaderType: {
      type: String as PropType<TemplateHeaderType>,
      default: 'text',
    },
    templateVariables: {
      type: Array as PropType<TemplateVariable[]>,
      default: [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      templateVariablesCopy: [] as TemplateVariable[],
      TEMPLATE_HEADER_FILE_LIMIT_IN_BYTES,
      TEMPLATE_HEADER_TYPE,
    };
  },
  mounted() {
    this.templateVariablesCopy = this.templateVariables.map((variable) => ({
      ...variable,
    }));
  },
  methods: {
    updateTemplateVariable(event: Event, index: number) {
      this.templateVariablesCopy[index].value = (event.target as HTMLInputElement).value;
      this.$emit('update-template-variables', this.templateVariablesCopy);
    },
  },
  watch: {
    templateVariables(newVariables) {
      this.templateVariablesCopy = newVariables;
    },
  },
});
</script>
