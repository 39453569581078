<template>
  <div class="w-full rounded-lg border-1 border-grey-300 bg-white" role="table">
    <div v-if="$slots.head" class="h-10 border-b-1 border-grey-300 px-4 py-3">
      <slot v-if="!isEmpty" name="head"></slot>
    </div>
    <div role="list">
      <slot v-if="!isEmpty" name="rows"></slot>
      <slot v-else name="empty"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamTable',
  props: {
    isEmpty: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
