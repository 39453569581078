<template>
  <div class="flex w-full flex-col">
    <sidebar-dropdown
      v-for="integration in installations"
      :key="integration.id"
      :title="integration.name"
      :has-icon="true"
      :collapse-key="`${integration.installation_id}${integration.name.replace(/\s/gm, '')}DropdownItem`"
      :card-id="integration.installation_id"
      data-test="legacy-integration-dropdown"
      :external-link="integration.deeplink"
      is-integration-dropdown
      @toggled="onDropdownToggle"
    >
      <integration-crm v-if="integration.filterType === 'crm'" :installation="integration" :ticket="ticket" />
      <integration-commerce v-if="integration.filterType === 'commerce'" :installation="integration" :ticket="ticket" />

      <template #icon>
        <integration-card-icon class="mr-2" :width="24" :icon="integration.icon" />
      </template>
    </sidebar-dropdown>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import IntegrationCardIcon from '@/components/IntegrationHub/Components/IntegrationCardIcon.vue';
import IntegrationCommerce from '@/components/TicketSidebar/Integrations/Commerce/IntegrationCommerce.vue';
import IntegrationCrm from '@/components/TicketSidebar/Integrations/IntegrationCrm.vue';
import SidebarDropdown from '@/components/TicketSidebar/SidebarDropdown.vue';

import type { InstalledIntegration } from '@/store/types/integrations';
import type Ticket from '@/types/tickets';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'IntegrationDropdowns',

  components: { IntegrationCardIcon, SidebarDropdown, IntegrationCrm, IntegrationCommerce },

  data() {
    return {
      openIntegrationIds: [],
      itemsMounted: 0,
    };
  },

  computed: {
    ...mapGetters({
      integration: 'integrations/getIntegrationByServiceId',
    }),
  },

  props: {
    installations: {
      type: Array as PropType<InstalledIntegration[]>,
      default: () => [],
    },
    ticket: {
      type: Object as PropType<Ticket>,
      default: () => ({}),
    },
  },

  mounted() {
    const installationIds: number[] = [];

    this.installations.forEach((installation: InstalledIntegration) => {
      const key = localStorage.getItem(
        `${installation.installation_id}${installation.name.replace(/\s/gm, '')}DropdownItem`,
      );

      if (key === 'open') {
        installationIds.push(installation.installation_id);
      }
    });

    this.openIntegrationIds = installationIds;
  },

  methods: {
    onDropdownToggle(isOpen: boolean, id: number) {
      this.setOpenIntegration(id, isOpen);
    },

    setOpenIntegration(id: InstalledIntegration['installation_id'], isOpen: boolean) {
      if (isOpen) {
        this.openIntegrationIds.push(id);
      } else {
        this.openIntegrationIds.splice(
          this.openIntegrationIds.findIndex((integrationId: number) => id === integrationId),
          1,
        );
      }
    },
  },
});
</script>
