import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center" }
const _hoisted_2 = { class: "flex flex-row items-center" }
const _hoisted_3 = { class: "w-4/5" }
const _hoisted_4 = { class: "flex flex-wrap items-center" }
const _hoisted_5 = { class: "text-sm font-semibold" }
const _hoisted_6 = { class: "text-sm font-semibold" }
const _hoisted_7 = { class: "text-sm font-semibold" }
const _hoisted_8 = { class: "w-4/5" }
const _hoisted_9 = { class: "text-sm font-semibold" }
const _hoisted_10 = {
  key: 2,
  class: "my-6 mb-1 border-b border-grey-300"
}
const _hoisted_11 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_12 = { class: "mt-4" }
const _hoisted_13 = { class: "flex flex-row" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "flex flex-row" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_switch = _resolveComponent("t-switch")!
  const _component_setting_item = _resolveComponent("setting-item")!
  const _component_multiselect = _resolveComponent("multiselect")!
  const _component_t_multi_choice_button = _resolveComponent("t-multi-choice-button")!
  const _component_t_multi_choice = _resolveComponent("t-multi-choice")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_setting_item, {
      "custom-class": { container: 'mb-1 mt-0 pb-6 cursor-pointer p-4 pl-0' },
      onClick: _cache[2] || (_cache[2] = () => $options.handleToggleSwitch('emailChannel.add_conversation_to_reply'))
    }, {
      title: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('email_settings.attach_full_conversation_history_to_reply')), 1 /* TEXT */)
      ]),
      formText: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('email_settings.when_enabled_well_include_the_full_conversation_history_to_your_conversations')), 1 /* TEXT */)
      ]),
      toggle: _withCtx(() => [
        _createVNode(_component_t_switch, {
          modelValue: $options.computedRecord.emailChannel.add_conversation_to_reply,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.computedRecord.emailChannel.add_conversation_to_reply) = $event)),
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }),
    (!$options.isNylasChannel)
      ? (_openBlock(), _createBlock(_component_setting_item, {
          key: 0,
          "show-border": !$options.computedRecord.emailChannel.split_by_sender,
          "custom-class": { container: $options.splitBySenderContainerStyle },
          onClick: _cache[5] || (_cache[5] = () => $options.handleToggleSwitch('emailChannel.split_by_sender'))
        }, {
          title: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('email_settings.create_a_new_conversation_when_a_customer_sends_a_reply')), 1 /* TEXT */)
          ]),
          formText: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('email_settings.instead_of_merging_a_reply_into_the_existing_thread_a_new_conversation_is_created')), 1 /* TEXT */)
          ]),
          toggle: _withCtx(() => [
            _createVNode(_component_t_switch, {
              modelValue: $options.computedRecord.emailChannel.split_by_sender,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($options.computedRecord.emailChannel.split_by_sender) = $event)),
              onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["show-border", "custom-class"]))
      : _createCommentVNode("v-if", true),
    ($options.shouldShowTemplate)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          ($props.hasReopenClosedTickets)
            ? (_openBlock(), _createBlock(_component_setting_item, {
                key: 0,
                "show-border": !$options.computedRecord.reopen_closed_ticket,
                "custom-class": { container: $options.reOpenClosedTicketsContainerStyle },
                onClick: _cache[8] || (_cache[8] = () => $options.handleToggleSwitch('reopen_closed_ticket'))
              }, {
                title: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('email_settings.reopen_closed_conversations')), 1 /* TEXT */)
                ]),
                formText: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('email_settings.reopen_closed_conversations_subtitle')), 1 /* TEXT */)
                ]),
                toggle: _withCtx(() => [
                  _createVNode(_component_t_switch, {
                    modelValue: $options.computedRecord.reopen_closed_ticket,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($options.computedRecord.reopen_closed_ticket) = $event)),
                    onClick: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["stop"]))
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["show-border", "custom-class"]))
            : _createCommentVNode("v-if", true),
          ($options.computedRecord.reopen_closed_ticket)
            ? (_openBlock(), _createBlock(_component_setting_item, {
                key: 1,
                "show-border": false,
                "custom-class": { container: ' mb-2 bg-grey-100 rounded-xl p-4' }
              }, {
                title: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('email_settings.do_not_reopen_conversations_after')), 1 /* TEXT */),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($options.computedRecord.reopen_closed_ticket_time_window_days) = $event)),
                      type: "number",
                      autocomplete: "off",
                      class: "spin-button-none mx-1 inline-block rounded-lg border-1 px-2 py-1 text-center text-sm font-medium hover:border-grey-400",
                      min: "1",
                      max: "999",
                      style: {"width":"46px","height":"32px"}
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, $options.computedRecord.reopen_closed_ticket_time_window_days]
                    ]),
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('email_settings.days_of_no_message')), 1 /* TEXT */)
                  ])
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          ($options.computedRecord.type === 'EMAIL' && $options.computedRecord.reopen_closed_ticket && !$options.isNylasChannel)
            ? (_openBlock(), _createBlock(_component_setting_item, {
                key: 2,
                "custom-class": {
          container: 'mb-2 pb-3 border-none cursor-pointer bg-grey-100 rounded-xl p-4',
        },
                onClick: _cache[12] || (_cache[12] = () => $options.handleToggleSwitch('emailChannel.split_by_subject'))
              }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('email_settings.create_a_new_conversation_when_subject_line_differs')), 1 /* TEXT */)
                ]),
                formText: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('email_settings.create_new_conversations_subtitle')), 1 /* TEXT */)
                ]),
                toggle: _withCtx(() => [
                  _createVNode(_component_t_switch, {
                    modelValue: $options.computedRecord.emailChannel.split_by_subject,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($options.computedRecord.emailChannel.split_by_subject) = $event)),
                    onClick: _cache[11] || (_cache[11] = _withModifiers(() => {}, ["stop"]))
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          ($options.computedRecord.reopen_closed_ticket)
            ? (_openBlock(), _createBlock(_component_setting_item, {
                key: 3,
                "custom-class": {
          container: 'mb-2 bg-grey-100 border-none rounded-xl p-4 cursor-pointer',
        },
                onClick: _cache[15] || (_cache[15] = ($event: any) => (!$options.isNylasV2Channel && $options.handleToggleSwitch('reassign_reopened_ticket')))
              }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('email_settings.assign_reopened_conversations_to_the_previous_user')), 1 /* TEXT */)
                ]),
                toggle: _withCtx(() => [
                  _createVNode(_component_t_switch, {
                    modelValue: $options.computedRecord.reassign_reopened_ticket,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($options.computedRecord.reassign_reopened_ticket) = $event)),
                    disabled: $options.isNylasV2Channel,
                    onClick: _cache[14] || (_cache[14] = _withModifiers(() => {}, ["stop"]))
                  }, null, 8 /* PROPS */, ["modelValue", "disabled"])
                ]),
                formText: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('email_settings.reopen_and_assign_the_message_to_user_that_closed_the_conversation')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true),
    ($options.computedRecord.reopen_closed_ticket)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10))
      : _createCommentVNode("v-if", true),
    ($options.showEmbedAttachments)
      ? (_openBlock(), _createBlock(_component_setting_item, {
          key: 3,
          "custom-class": {
        container: 'mb-1 mt-0 pt-4 sm:pt-5 pb-6 cursor-pointer p-4 pl-0',
      },
          onClick: _cache[18] || (_cache[18] = () => $options.handleToggleSwitch('emailChannel.embed_attachments'))
        }, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('email_settings.embed_attachments')), 1 /* TEXT */)
          ]),
          formText: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('email_settings.embed_attachments_subtitle')), 1 /* TEXT */)
          ]),
          toggle: _withCtx(() => [
            _createVNode(_component_t_switch, {
              modelValue: $options.computedRecord.emailChannel.embed_attachments,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (($options.computedRecord.emailChannel.embed_attachments) = $event)),
              disabled: $options.isNylasChannel,
              onClick: _cache[17] || (_cache[17] = _withModifiers(() => {}, ["stop"]))
            }, null, 8 /* PROPS */, ["modelValue", "disabled"])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    (!$options.isNylasChannel)
      ? (_openBlock(), _createBlock(_component_setting_item, {
          key: 4,
          "custom-class": {
        container: 'mb-1 mt-0 pt-4 sm:pt-5 pb-6 cursor-pointer p-4 pl-0',
      },
          onClick: _cache[21] || (_cache[21] = () => $options.handleToggleSwitch('emailChannel.prepend_ticket_number_to_subject'))
        }, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('email_settings.attach_conversation_number_to_subject_as_a_prefix')), 1 /* TEXT */)
          ]),
          formText: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('email_settings.if_enabled_the_ticket_number_will_be_attached_before_the_email_subject')), 1 /* TEXT */)
          ]),
          toggle: _withCtx(() => [
            _createVNode(_component_t_switch, {
              modelValue: $options.computedRecord.emailChannel.prepend_ticket_number_to_subject,
              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (($options.computedRecord.emailChannel.prepend_ticket_number_to_subject) = $event)),
              onClick: _cache[20] || (_cache[20] = _withModifiers(() => {}, ["stop"]))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_setting_item, {
      "custom-class": {
        container: 'mb-1 mt-0 pt-4 sm:pt-5 pb-6 cursor-pointer p-4 pl-0',
      },
      onClick: _cache[25] || (_cache[25] = () => $options.handleToggleSwitch('emailChannel.meta.reply_all'))
    }, {
      title: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('email_settings.reply_to_all_recipients')), 1 /* TEXT */)
      ]),
      formText: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('email_settings.by_default_youll_respond_to_all_recipients_including_everyone_in_the_cc')), 1 /* TEXT */)
      ]),
      moreSettings: _withCtx(() => [
        ($options.computedRecord.emailChannel.meta.reply_all)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createTextVNode(_toDisplayString(_ctx.$t(
              'email_settings.please_specify_your_own_email_addresses_and_aliases_to_prevent_sending_replies_back_to_yourself',
            )) + " ", 1 /* TEXT */),
              _createVNode(_component_multiselect, {
                modelValue: $options.computedRecord.emailChannel.meta.email_addresses,
                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => (($options.computedRecord.emailChannel.meta.email_addresses) = $event)),
                class: "mt-2",
                "tag-placeholder": "Add email address",
                placeholder: _ctx.$t('email_settings.search_or_add_email'),
                label: "email",
                "track-by": "email",
                options: $options.computedRecord.emailChannel.meta.email_addresses,
                multiple: true,
                taggable: true,
                disabled: $options.isNylasV2Channel,
                onClick: _withModifiers($options.stopCallingToggleSwitch, ["stop"]),
                onTag: $options.addEmail
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "options", "disabled", "onClick", "onTag"])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      toggle: _withCtx(() => [
        _createVNode(_component_t_switch, {
          modelValue: $options.computedRecord.emailChannel.meta.reply_all,
          "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => (($options.computedRecord.emailChannel.meta.reply_all) = $event)),
          disabled: $options.isNylasV2Channel,
          onClick: _cache[24] || (_cache[24] = _withModifiers(() => {}, ["stop"]))
        }, null, 8 /* PROPS */, ["modelValue", "disabled"])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_setting_item, {
      class: "w-full",
      "show-border": false,
      "custom-class": { container: 'mb-1 mt-0 pt-4 sm:pt-5 pb-6 p-4 pl-0 pr-0' }
    }, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('email_settings.spam_filter')), 1 /* TEXT */)
      ]),
      moreSettings: _withCtx(() => [
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_t_multi_choice, {
            modelValue: $options.computedRecord.emailChannel.spam_setting,
            "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => (($options.computedRecord.emailChannel.spam_setting) = $event)),
            disabled: $options.isNylasV2Channel
          }, _createSlots({
            "0": _withCtx(() => [
              _createVNode(_component_t_multi_choice_button, {
                class: "mb-4",
                title: $options.isNylasChannel ? _ctx.$t('email_settings.basic') : _ctx.$t('email_settings.none'),
                selected: $options.selectedSpamSetting === 0,
                disabled: $options.isNylasV2Channel
              }, {
                desc: _withCtx(() => [
                  _createTextVNode(_toDisplayString($options.isNylasChannel
                      ? _ctx.$t('email_settings.default_spam_protection_from_your_email_provider')
                      : _ctx.$t('email_settings.receive_all_emails_in_one_place_even_your_private_ones')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["title", "selected", "disabled"])
            ]),
            "1": _withCtx(() => [
              _createVNode(_component_t_multi_choice_button, {
                class: "mb-4",
                title: $options.isNylasChannel ? _ctx.$t('email_settings.advanced') : _ctx.$t('email_settings.basic'),
                selected: $options.selectedSpamSetting === 1,
                disabled: $options.isNylasV2Channel
              }, _createSlots({ _: 2 /* DYNAMIC */ }, [
                ($options.isNylasChannel)
                  ? {
                      name: "desc",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('email_settings.default_spam_protection_from_your_email_provider_plus_extra_blacklist_check')), 1 /* TEXT */)
                      ]),
                      key: "0"
                    }
                  : {
                      name: "desc",
                      fn: _withCtx(() => [
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("span", {
                            innerHTML: _ctx.$t('email_settings.checks_suspicious_content')
                          }, null, 8 /* PROPS */, _hoisted_14),
                          _withDirectives(_createElementVNode("img", {
                            src: `${_ctx.$root.assetsURL}img/info.svg`,
                            alt: "info",
                            class: "ml-2 h-20px w-20px"
                          }, null, 8 /* PROPS */, _hoisted_15), [
                            [_directive_tooltip, {
                        content: _ctx.$t('email_settings.tooltip_dmarc'),
                      }]
                          ])
                        ])
                      ]),
                      key: "1"
                    }
              ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "selected", "disabled"])
            ]),
            _: 2 /* DYNAMIC */
          }, [
            (!$options.isNylasChannel)
              ? {
                  name: "2",
                  fn: _withCtx(() => [
                    _createVNode(_component_t_multi_choice_button, {
                      title: _ctx.$t('email_settings.advanced'),
                      selected: $options.selectedSpamSetting === 2
                    }, {
                      desc: _withCtx(() => [
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("span", {
                            innerHTML: _ctx.$t('email_settings.checks_for_basic_spam_settings')
                          }, null, 8 /* PROPS */, _hoisted_17),
                          _withDirectives(_createElementVNode("img", {
                            src: `${_ctx.$root.assetsURL}img/info.svg`,
                            alt: "info",
                            class: "ml-2 h-20px w-20px"
                          }, null, 8 /* PROPS */, _hoisted_18), [
                            [_directive_tooltip, {
                        content: _ctx.$t('email_settings.tooltip_basic_spam_and_spf'),
                      }]
                          ])
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["title", "selected"])
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "disabled"])
        ])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}