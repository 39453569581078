<template>
  <div class="csat-overview mx-8 my-16 bg-white px-6 py-12">
    <div class="flex flex-row">
      <div class="ml-8 max-w-sm">
        <h3>{{ $t('csat.csat_overview') }}</h3>
        <p class="subtitle">
          {{ $t('csat.csat_overview_subtitle') }}
        </p>
        <p class="surveys mb-6 mt-12">
          {{ $t('csat.surveys') }}
        </p>
      </div>
      <div v-if="userStore.hasPermission(PERMISSION.REPORTING__GENERAL__MANAGE)" class="average-csat ml-14">
        <p class="average-csat-label mb-1">
          {{ $t('csat.average_csat') }}
        </p>
        <p class="average-csat-value mb-1">{{ averageCsat }}%</p>
        <p class="view-full-report" @click="seeReport()">
          {{ $t('csat.view_full_report') }}
        </p>
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>{{ $t('csat.name') }}</th>
          <th>{{ $t('csat.status') }}</th>
          <th>{{ $t('csat.surveys_sent') }}</th>
          <th>{{ $t('csat.last_modified') }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="csat in csats"
          :key="csat.id"
          :class="{
            inactive: !csat.is_activated,
          }"
        >
          <td v-tooltip="{ content: csat.name, placement: 'bottom' }" class="csat-name">{{ csat.name }}</td>
          <td>
            <ActiveIndicator :is_active="csat.is_activated"></ActiveIndicator>
          </td>
          <td>{{ csat.csat_tickets_count }}</td>
          <td>
            {{ moment(csat.updated_at).format('DD/MM/YYYY') }}
          </td>
          <td class="buttons-container flex justify-end">
            <atomic-button
              v-if="userStore.hasPermission(PERMISSION.REPORTING__GENERAL__MANAGE)"
              size="sm"
              btn-style="secondary"
              @click="seeReport(csat.id)"
            >
              <i class="far fa-chart-bar mr-2"></i>
              {{ $t('csat.see_report') }}
            </atomic-button>
            <atomic-button size="sm" class="ml-2" btn-style="secondary" @click="editCsat(csat.id)">
              {{ $t('csat.edit') }}
            </atomic-button>
          </td>
        </tr>
      </tbody>
    </table>
    <atomic-button size="md" class="ml-8 mt-12 block" @click="goToSurvey">
      <i class="fal fa-plus mr-2"></i>
      {{ $t('csat.add_new_survey') }}
    </atomic-button>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import { PERMISSION, FEATURE_FLAG_ACTIVATION } from '@/Configs/Constants';
import { useFeatureFlagStore, useUserStore } from '@/store/pinia';

import ActiveIndicator from './ActiveIndicator';

export default defineComponent({
  name: 'Overview',
  data: () => ({
    moment: moment,
    PERMISSION,
    showNewReporting: false,
  }),
  components: {
    ActiveIndicator,
  },
  props: {
    averageCsat: {
      type: Number,
      default: 0,
    },
    csats: {
      type: Array,
      default: null,
    },
  },
  computed: {
    ...mapStores(useUserStore),
  },
  mounted() {
    this.showNewReporting = useFeatureFlagStore()?.isEnabled(FEATURE_FLAG_ACTIVATION.TA_FRONTEND_REPORTING);
  },
  methods: {
    goToSurvey() {
      this.$router.push('/admin/csat/create');
    },
    editCsat(id) {
      this.$router.push('/admin/csat/' + id);
    },
    seeReport() {
      this.$router.push(
        `/reports/csat?filters=%7B"currentPeriod"%3A%7B"start"%3A1293836400,"end"%3A` +
          moment().unix() +
          '%7D,"previousPeriod"%3A%7B"start"%3A1293836400,"end"%3A' +
          this.$root.user.timezone
      );
    },
  },
});
</script>

<style scoped lang="scss">
.csat-overview {
  border: 1px solid var(--color-grey-300);
  border-radius: 12px;
  h3 {
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    color: var(--color-grey-900);
  }
  .subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-grey-800);
  }
  .surveys {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-grey-800);
  }
  .average-csat {
    width: 192px;
    height: 99px;
    border: 1px solid var(--color-grey-300);
    border-radius: 12px;
    padding: 10px 0 0 12px;
    transition: box-shadow 0.2s ease-in-out;
    &:hover {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03), 0 2px 5px rgba(0, 0, 0, 0.11);
    }
    .average-csat-label {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: var(--color-grey-500);
    }
    .average-csat-value {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      color: var(--color-grey-800);
    }
    .view-full-report {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: var(--color-grey-600);
      cursor: pointer;
    }
  }

  button {
    font-weight: 600;
    font-size: 14px;
  }
  table {
    border-radius: 8px 8px 0px 0px;
  }
  th {
    background: var(--color-grey-100);
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    height: 40px;
    padding-top: 13px;
    padding-bottom: 13px;
  }
  th:first-of-type {
    border-radius: 8px 0px 0px 0px;
    padding-left: 32px;
  }
  th:last-of-type {
    border-radius: 0px 8px 0px 0px;
    min-width: 256px;
  }
  .csat-name {
    @apply max-w-xs truncate align-middle text-base font-semibold;
  }
  .inactive td {
    &:not(.buttons-container) {
      color: var(--color-grey-600);
    }
  }
  tbody tr {
    border-bottom: 1px solid var(--color-grey-300);
  }
  td:first-of-type {
    padding-left: 32px;
  }
  td {
    font-weight: 600;
    font-size: 14px;
    line-height: 64px;
    height: 64px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom: 1px solid var(--color-grey-300);
    &.buttons-container {
      padding: 0.75rem;
      border-bottom: 0px;
    }
  }
}
</style>
