<template>
  <div class="mt-4">
    <t-table-list class="bg-white p-0">
      <t-table-list-row v-for="item in [1, 2, 3, 4]" :key="item">
        <t-table-list-cell>
          <div class="flex flex-row items-center justify-start">
            <div class="animation-loading h-4 w-200px rounded-xl bg-grey-300" />
          </div>
        </t-table-list-cell>
      </t-table-list-row>
    </t-table-list>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
@keyframes loading {
  to {
    background-position: 227px 0, 0 0, 0 157px, 23px 135px;
  }
}

.animation-loading {
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(var(--color-grey-300), var(--color-grey-300)),
    radial-gradient(38px circle at 19px 19px, var(--color-grey-300) 50%, transparent 51%),
    linear-gradient(var(--color-grey-300), var(--color-grey-300));
  background-repeat: no-repeat;
  animation: loading 1.5s linear infinite;
}

.empty-state {
  background-image: linear-gradient(0deg, #fff 30%, transparent 100%);
}
</style>
