import { h } from 'vue';
import { RouterView } from 'vue-router';

import { PERMISSION } from '@/Configs/Constants';

import { ROUTE_NAME } from '../constants';

import type { RouteRecord } from '../types';

const csatRoutes: RouteRecord[] = [
  {
    path: '/admin/csat',
    name: ROUTE_NAME.CSAT_LANDING,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: '/admin/csat',
        name: 'csatLanding',
        component: require('../../components/CustomerSatisfaction/Landing').default,
        props: (route) => ({ ...route.query }),
        meta: { permissionRequired: PERMISSION.SETTINGS__CSAT__MANAGE },
      },
      {
        path: 'create',
        component: require('../../components/CustomerSatisfaction/Survey').default,
        meta: { permissionRequired: PERMISSION.SETTINGS__CSAT__MANAGE },
      },
      {
        path: ':id',
        name: 'editCsat',
        component: require('../../components/CustomerSatisfaction/Survey').default,
        meta: { permissionRequired: PERMISSION.SETTINGS__CSAT__MANAGE },
      },
    ],
  },
];

export default csatRoutes;
