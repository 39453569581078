<template>
  <div class="p-6">
    <h3 class="t-text-md-emphasize mb-2 text-grey-800">
      {{ t('edit_subscription.carrier_costs_title') }}
    </h3>
    <div class="t-text-sm text-grey-600">
      <p>{{ t('edit_subscription.carrier_costs_description') }}</p>
      <p v-html="t('edit_subscription.carrier_costs_section_note')"></p>
    </div>
    <div>
      <div class="flex flex-col gap-3">
        <carrier-costs-editor
          :carrier-costs="carrierCosts"
          :scheduled-carrier-costs="scheduledChange"
          disabled
          :is-submitted="isSubmitted"
          @edit="handleEditModalOpen"
        />
      </div>
      <scheduled-change-panel
        :is-submitted="isSubmitted"
        :has-scheduled-change="!!scheduledChange"
        :scheduled-change-date="scheduledChangeDate"
      />
    </div>
    <edit-carrier-costs-modal
      v-if="modalStatus === 'editModal'"
      :carrier-costs="carrierCosts"
      @save="handleSave"
      @close="closeModal"
    />
    <scheduled-change-warning-modal
      v-if="scheduledChangeDate && modalStatus === 'warningModal'"
      :scheduled-change-date="scheduledChangeDate"
      @continue="handleSubmit"
      @close="closeModal"
    />
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { updateCarrierCost } from '@/api';
import { SEGMENT_TRACKING_DETAILS } from '@/components/Subscription/UsageBasedSubscription/constants';
import { SUBSCRIPTION_ADDON_CHARGEBEE_TYPE } from '@/Configs/Constants';
import { useSubscriptionChangesStore, useSubscriptionStore } from '@/store/pinia';
import { useToastMessageStore } from '@/store/pinia/toastMessage';
import { segmentTrackEvent } from '@/util/analytics/segmentAnalytics';

import CarrierCostsEditor from './CarrierCostsEditor.vue';
import EditCarrierCostsModal from './EditCarrierCostsModal.vue';
import ScheduledChangePanel from '../ScheduledChangePanel.vue';
import ScheduledChangeWarningModal from '../ScheduledChangeWarningModal.vue';

const modalStatus = ref<'editModal' | 'warningModal' | 'none'>('none');
const newCarrierCosts = ref();
const isSubmitted = ref(false);

const { t } = useI18n();

const { scheduledChangeDate, getScheduledChangeForAddon } = storeToRefs(useSubscriptionChangesStore());
const subscriptionStore = useSubscriptionStore();
const toastMessageStore = useToastMessageStore();

const scheduledChange = computed(() =>
  getScheduledChangeForAddon.value(SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.CARRIER_COST_BALANCE),
);

const carrierCosts = computed(() =>
  !subscriptionStore.subscription.isLoading
    ? subscriptionStore.getUsageBasedAddonQuantity(SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.CARRIER_COST_BALANCE)
    : 0,
);

const closeModal = () => (modalStatus.value = 'none');

const handleEditModalOpen = () => {
  modalStatus.value = 'editModal';
  handleSegmentTracking('BALANCE_COMMITMENT_EDIT');
};

const updateType = computed(() => {
  switch (true) {
    case carrierCosts.value === 0 && newCarrierCosts.value > 0:
      return 'addon_added';
    case newCarrierCosts.value > carrierCosts.value:
      return 'addon_scheduled_to_be_added';
    case newCarrierCosts.value < carrierCosts.value:
      return 'addon_scheduled_to_be_removed';
    case newCarrierCosts.value === carrierCosts.value:
      return 'addon_scheduled_change_removed';
    default:
      return undefined;
  }
});

const showToast = () => {
  if (!updateType.value) {
    return;
  }

  toastMessageStore.$patch({
    hasCloseIcon: true,
    text: t(`edit_subscription.${updateType.value}`, { addon: t('edit_subscription.carrier_costs_balance') }),
    showToast: true,
  });
  toastMessageStore.hideToastMessage();
};

const handleSave = (newValue: number) => {
  newCarrierCosts.value = newValue;
  if (scheduledChange.value) {
    modalStatus.value = 'warningModal';
  } else {
    handleSubmit();
  }
};

const handleSubmit = async () => {
  modalStatus.value = 'none';

  await updateCarrierCost({ quantity: newCarrierCosts.value });
  isSubmitted.value = true;

  handleSegmentTracking('BALANCE_COMMITMENT_EDIT_COMPLETED');
  showToast();
  closeModal();

  if (carrierCosts.value === 0 && newCarrierCosts.value > 0) {
    subscriptionStore.reload();
  }
};

const handleSegmentTracking = (event: keyof typeof SEGMENT_TRACKING_DETAILS) => {
  segmentTrackEvent({
    ...SEGMENT_TRACKING_DETAILS[event],
    subscription_id: subscriptionStore.subscription.subscriptionId,
    amount: newCarrierCosts.value,
  });
};
</script>
