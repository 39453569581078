import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full border-t-1 border-grey-300" }
const _hoisted_2 = { class: "m-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_settings_sidebar = _resolveComponent("settings-sidebar")!
  const _component_arrow_top_circle_linear = _resolveComponent("arrow-top-circle-linear")!
  const _component_t_badge = _resolveComponent("t-badge")!
  const _component_t_list_item = _resolveComponent("t-list-item")!
  const _component_portal = _resolveComponent("portal")!
  const _component_upgrade_modal = _resolveComponent("upgrade-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_settings_sidebar, {
      "list-items": _ctx.sidebarItems,
      "show-back": true
    }, null, 8 /* PROPS */, ["list-items"]),
    (_ctx.showUpgradeBar)
      ? (_openBlock(), _createBlock(_component_portal, {
          key: 0,
          to: "sidebar/bottom"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_t_list_item, {
                  size: "sm",
                  label: _ctx.$tc('integration_hub.basic_integrations'),
                  "data-test": "edit-installed-integration",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isModalOpen = true))
                }, {
                  postfix: _withCtx(() => [
                    _createVNode(_component_t_badge, {
                      variant: 'upgrade',
                      text: 'Boost'
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_arrow_top_circle_linear, { size: "1rem" })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ])
            ])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_upgrade_modal, {
      "is-open": _ctx.isModalOpen,
      "is-first-time": _ctx.isFirstTime,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modalClosed()))
    }, null, 8 /* PROPS */, ["is-open", "is-first-time"])
  ]))
}