import { PRICING_MODEL } from '@/Configs/Constants';

import { POLLING_INTERVAL } from './constants';

import type { PricingModel } from '@/types';

export const checkMigrationBannerVisibility = ({
  pricingModel,
  alreadyDismissed,
  isAdmin,
  hasFeatureFlag,
}: {
  pricingModel: PricingModel;
  alreadyDismissed: boolean;
  isAdmin: boolean;
  hasFeatureFlag: boolean;
}) => {
  if (pricingModel !== PRICING_MODEL.SEAT_BASED || alreadyDismissed) {
    return false;
  }

  return Boolean(hasFeatureFlag && isAdmin);
};

export const getPollingInterval = () => {
  return POLLING_INTERVAL;
};
