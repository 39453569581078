<template>
  <div
    ref="el"
    :contenteditable="true"
    data-hj-suppress
    class="break-words"
    :style="{ maxHeight: maxHeight }"
    style="word-break: break-word"
    @keyup.delete.prevent="$emit('delete')"
    @focus="onFocus"
    @blur="onBlur"
    @keydown.enter="onEnter"
    @keyup.prevent="onChange($event)"
    @paste="onChange"
    @input="onChange"
  />
</template>

<script type="text/babel">
export default {
  emits: ['change', 'enter', 'delete', 'focus', 'blur', 'keyup', 'filePaste', 'update:modelValue'],
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    filePaste: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: '400px',
    },
    returnPlainText: {
      type: Boolean,
      default: false,
    },
    insertPlainText: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      changed: false,
      changeTimer: null,
      selRange: null,
      removePrev: document.querySelector('.remove-prev'),
    };
  },

  mounted() {
    if (this.insertPlainText) {
      this.$refs.el.innerText = this.modelValue;
    } else {
      this.$refs.el.innerHTML = this.modelValue;
    }

    this.$nextTick(() => {
      if (!this.$refs.el) {
        return;
      }

      this.$refs.el.addEventListener('paste', (e) => {
        if (window.isLoadedFromApp) {
          // fix for IOS bug
          setTimeout(() => {
            this.$refs.el[this.returnPlainText ? 'innerText' : 'innerHTML'] = stripHtml(
              this.$refs.el[this.returnPlainText ? 'innerText' : 'innerHTML']
            );
            this.$emit('update:modelValue', this.$refs.el[this.returnPlainText ? 'innerText' : 'innerHTML']);
            this.$emit('change');
          }, 250);
        } else {
          if (e.clipboardData.files.length > 0) {
            e.clipboardData.items.forEach((file) => {
              if (file.kind === 'file') {
                e.preventDefault();
                const blob = file.getAsFile();

                if (blob) {
                  this.$emit('filePaste', blob);
                }
              }
            });

            return;
          }

          if (e.clipboardData) {
            e.preventDefault();

            // execCommand is deprecated - however it still works and Firefox doesn't support the Clipboard API yet
            // So, we just have to keep it in for now. Hopefully a better solution presents itself in the future
            document.execCommand('insertText', false, (e.originalEvent || e).clipboardData.getData('text/plain'));
          }

          // Trigger change event
          this.$emit('update:modelValue', this.$refs.el.innerText);
        }
      });
    });

    document.addEventListener('selectionchange', this.selectionChanged);
  },

  destroy() {
    document.removeEventListener('selectionchange', this.selectionChanged);
    this.removePrev.removeEventListener('DOMNodeRemoved', this.removePreviousElements);
  },

  methods: {
    onEnter(e) {
      if (!e.shiftKey && !this.multiline) {
        this.$emit('enter', e);
        e.preventDefault();
      }
    },

    onChange(e) {
      this.changed = true;
      this.$emit('keyup', e);
      this.$emit('update:modelValue', this.$refs.el[this.returnPlainText ? 'innerText' : 'innerHTML']);
      clearTimeout(this.changeTimer);
      this.changeTimer = setTimeout(() => {
        this.$emit('change');
        this.changed = false;
      }, 2000);
    },

    onFocus() {
      this.$emit('focus');
    },

    onBlur() {
      this.$emit('blur', this.$refs?.el?.innerHTML);
      if (this.changed) {
        this.$emit('change');
        this.changed = false;
        clearTimeout(this.changeTimer);
      }
    },

    selectionChanged() {
      if (
        !window.getSelection() ||
        !window.getSelection().baseNode ||
        !this.$refs.el?.contains(window.getSelection().baseNode.parentNode)
      ) {
        return;
      }
      this.selRange = this.saveSelection();
    },

    saveSelection() {
      if (window.getSelection) {
        let sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
          return sel.getRangeAt(0);
        }
      } else if (document.selection && document.selection.createRange) {
        return document.selection.createRange();
      }
      return null;
    },

    restoreSelection(range) {
      if (range) {
        if (window.getSelection) {
          let sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        } else if (document.selection && range.select) {
          range.select();
        }
      }
    },

    insertTextAtCursor(text, element = null, removeOnBackspace = false) {
      let sel, range;
      if (window.getSelection) {
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0);
          range.deleteContents();
          let textNode;

          if (!element) {
            textNode = document.createTextNode(text);
          } else {
            textNode = document.createElement(element);
            if (removeOnBackspace) {
              textNode.className = 'sms-tag';
              textNode.contentEditable = false;
            }
            textNode.innerHTML = text;
          }
          if (removeOnBackspace) {
            //required for erasing in firefox
            const newTextNode = document.createElement('span');
            newTextNode.className = 'remove-prev';
            range.insertNode(newTextNode);
          }
          range.insertNode(textNode);
          sel.removeAllRanges();
          range = range.cloneRange();
          range.selectNode(textNode);
          range.collapse(false);
          sel.addRange(range);

          if (removeOnBackspace) {
            this.$nextTick(() => {
              this.removePrev.addEventListener('DOMNodeRemoved', this.removePreviousElements);
            });
          }
        }
      } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange();
        range.pasteHTML(text);
        range.select();
      }
    },

    insertText(text) {
      this.restoreSelection(this.selRange);
      this.$refs.el.focus();
      this.insertTextAtCursor(text);
      if (this.returnPlainText) {
        this.$emit('update:modelValue', this.$el.innerText.replace(/\u00A0/g, ` `));
      } else {
        this.$emit('update:modelValue', this.$el.innerHTML);
      }
    },

    removePreviousElements(e) {
      e.target.previousElementSibling && e.target.previousElementSibling.remove();
    },
  },
};
</script>
