import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "data-test": "establishing-connection",
  class: "flex flex-col items-center justify-center"
}
const _hoisted_2 = { class: "t-text-md mt-4 text-grey-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_spinner = _resolveComponent("t-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_t_spinner),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('whatsapp.establishing_connection')), 1 /* TEXT */)
  ]))
}