import { computed } from 'vue';

import { vm } from '@/ApplicationHandler';

type QueryParameterId = 'addConversationBlocksModal' | 'addCarrierCostsModal' | 'quoteOverview';

export const useQueryParameterOpenState = (id: QueryParameterId) => {
  const isOpen = computed(() => vm.$route.query[id] === 'open');

  const handleOpen = () => {
    const currentQuery = { ...vm.$route.query };
    currentQuery[id] = 'open';
    vm.$router.push({ query: currentQuery });
  };

  const handleClose = () => {
    const currentQuery = { ...vm.$route.query };
    delete currentQuery[id];
    vm.$router.push({ query: currentQuery });
  };

  return { isOpen, handleOpen, handleClose };
};
