<script type="text/babel">
import { LoadBalanceLinear } from '@trengo/trengo-icons';
import { map } from 'lodash';

import { storeTeamRequest } from '@/components/Teams/api';
import { TeamModal } from '@/components/Teams/components';
import eventBus from '@/eventBus';

export default {
  name: 'Teams',
  components: { TeamModal, LoadBalanceLinear },
  data() {
    return {
      loaded: false,
      show_create_modal: false,
      record: {
        name: '',
        team_members: [],
        channels: [],
      },
      teams: {},
    };
  },
  mounted() {
    this.loadList(false);
    eventBus.$on('open_create_team_modal', this.openModal);
    eventBus.$on('update-team', this.loadList);
  },
  unmounted() {
    eventBus.$off('open_create_team_modal', this.openModal);
    eventBus.$off('update-team', this.loadList);
  },
  computed: {
    computedValue: {
      get() {
        return this.record;
      },
      set(val) {
        this.record = val;
      },
    },
  },

  methods: {
    handleSubmit() {
      const data = {
        ...{ channel_ids: this.mapChannelIds(), user_ids: this.mapUserIds() },
        ...this.record,
      };
      storeTeamRequest(data).then((res) => {
        this.loadList();
        this.closeModal();
        this.$nextTick(() => this.$router.push('/admin/teams/' + res.data.id));
        this.flashSuccess(this.$t('settings.teams_team_was_created_successfully'));
        this.resetStates();
      });
    },
    resetStates() {
      this.record = {
        team_members: [],
        name: '',
        channels: [],
      };
    },
    toggleModal() {
      if (this.show_create_modal) {
        this.closeModal();
        return false;
      }
      this.openModal();
    },
    handleClick() {
      this.openModal();
    },
    openModal() {
      this.show_create_modal = true;
    },
    closeModal() {
      this.show_create_modal = false;
    },
    mapChannelIds() {
      return map(this.record.channels, 'id');
    },

    mapUserIds() {
      return map(this.record.team_members, 'id');
    },
    loadList(reload_root = true) {
      axios.get('/api/v2/teams').then((result) => {
        this.teams = result.data.data;
        this.loaded = true;

        if (reload_root) {
          this.$root.teams = this.teams;
        }
      });
    },
  },
};
</script>

<template>
  <div class="row-body">
    <team-modal
      v-model="computedValue"
      :is-open="show_create_modal"
      @submit="handleSubmit"
      @toggle="toggleModal"
      @close="closeModal"
    />
    <admin-section-title title="Teams"></admin-section-title>
    <div class="row-col">
      <div class="row-col">
        <div id="settings-primary" class="col-xs modal fade aside aside-md b-r w-medium w-200px">
          <div class="nav-settings light left b-primary row-col">
            <ul>
              <li class="text-md white header">
                <strong>{{ $t('settings.teams_teams') }}</strong>
                <a data-dismiss="modal" class="hidden-md-up text-muted m-l-auto m-r-sm m-l-1 text-lg">×</a>
                <a class="m-l-auto btn btn-icon btn-sm success rounded text-white">
                  <i class="material-icons md-18" @click="handleClick">add</i>
                </a>
              </li>
            </ul>
            <div class="row-row">
              <div class="row-body scrollable hove">
                <div class="row-inner">
                  <ul>
                    <li v-for="item in teams">
                      <router-link
                        class="nav-settings-link text-truncate team-item-link"
                        data-dismiss="modal"
                        :to="'/admin/teams/' + item.id + ''"
                        :class="{ active: $route.params.id == item.id }"
                      >
                        <span>{{ item.name }}</span>
                        <span
                          v-if="item.load_balancing_enabled"
                          v-tooltip="{
                            popperClass: 'info-tooltip',
                            content: 'Workload balancing active',
                            placement: 'top',
                          }"
                        >
                          <load-balance-linear class="text-grey-400" size="1rem" />
                        </span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-col">
          <router-view v-if="loaded" :key="$route.params.id" @call-loadlist-method="loadList"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.team-item-link {
  @apply flex #{!important};
  @apply flex-row items-center justify-between;
}
</style>
