<template>
  <div data-test="team-members" class="flex flex-col items-start">
    <div class="w-full">
      <team-table>
        <template #head>
          <div class="grid w-full grid-cols-4 gap-0">
            <div class="col-span-4 flex flex-row items-center sm:justify-between">
              <div class="t-text-desktop-label-xs m-0 p-0 text-grey-800">Name</div>
            </div>
            <div class="col-end-6 flex flex-row items-center justify-between">
              <div class="t-text-desktop-label-xs m-0 w-max p-0 text-left text-grey-800">Limit</div>
              <div class="w-64px" />
            </div>
          </div>
        </template>

        <template #rows>
          <div>
            <table-row
              v-for="user in computedUsers"
              :key="user.id"
              :is-last="isTheLast(user)"
              data-test="team-members-row"
            >
              <user-row-workload-balance :init-limit="0" has-limit :user="user" @change-limit="handleChangeUserLimit" />
            </table-row>
          </div>
        </template>
      </team-table>
    </div>
  </div>
</template>

<script>
import { cloneDeep, last } from 'lodash';

import TableRow from '@/components/Teams/components/TableRow';
import TeamTable from '@/components/Teams/components/TeamTable';

import UserRowWorkloadBalance from '../UserRowWorkloadBalance';

export default {
  name: 'UsersTable',
  emits: ['users', 'change'],
  components: { UserRowWorkloadBalance, TableRow, TeamTable },
  data() {
    return {
      clonedUsers: {},
    };
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.clonedUsers = cloneDeep(this.users);
  },
  methods: {
    isTheLast(user) {
      return last(this.computedUsers)?.id === user.id;
    },
    getUserById(userId) {
      const getUserById = this.clonedUsers.filter((u) => userId === u.id)?.[0];
      if (!getUserById) {
        return false;
      }
      return getUserById;
    },
    handleChangeUserLimit(user) {
      const getUserById = this.getUserById(user.id);
      const updatedUser = this.updateUserLimitByUserId(getUserById, user);
      this.reMapUsersByUserId(updatedUser);
      this.$emit('change', this.clonedUsers);
    },
    updateUserLimitByUserId(getUserById, user) {
      getUserById.load_balancing_ticket_limit = user.load_balancing_ticket_limit;
      return getUserById;
    },
    reMapUsersByUserId(user) {
      this.clonedUsers.map((u) => {
        if (u.id === user.id) {
          u = user;
        }
        return u;
      });
    },
  },
  computed: {
    computedUsers: {
      get() {
        return this.users;
      },
      set(val) {
        this.$emit('users', val);
      },
    },
  },
};
</script>

<style lang="scss" src="./UsersTable.scss" scoped />
