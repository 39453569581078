<script type="text/babel">
export default {
  name: 'BalanceAlert',
  emits: ['reload-balance-settings'],
  props: {
    record: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      enabled: false,
      threshold: 0,
      notification_email: '',
      saving: false,
    };
  },

  watch: {
    record() {
      this.setBalanceAlert(this.record);
    },
  },

  computed: {
    thresholdOptions() {
      let res = [];
      let list = [10, 20, 30, 40, 50, 100, 200, 300, 400, 500, 750, 1000];
      for (var key in list) {
        res.push({
          value: list[key],
          text: '&euro; ' + list[key],
        });
      }
      return res;
    },
  },

  methods: {
    setBalanceAlert(record) {
      if (record?.balance_alert) {
        this.enabled = record.balance_alert.enabled;
        this.threshold = record.balance_alert.threshold;
        this.notification_email = record.balance_alert.notification_email;
      }
    },
    save() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      let data = {
        enabled: this.enabled,
        threshold: this.threshold,
        notification_email: this.notification_email,
      };

      axios
        .post('/client-api/company_profile/balance_alert', data)
        .then((res) => {
          this.saving = false;
          this.flashSuccess('You have successfully updated the balance alert settings.');
          this.$emit('reload-balance-settings');
        })
        .catch(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<template>
  <form class="form-horizontal form-bordered" @submit.prevent="save">
    <div class="box">
      <div class="box-header b-b">
        <h2>{{ $t('company_profile.subscription_page_low_balance_alert') }}</h2>
      </div>
      <div class="box-body">
        <div class="form-group row">
          <label for="lowBalanceAlertSwitch" class="col-md-3 form-control-label">
            {{ $t('company_profile.subscription_page_enabled') }}
          </label>
          <div class="col-md-9">
            <t-switch id="lowBalanceAlertSwitch" v-model="enabled" size="md" class="m-t-xs m-r !justify-start" />
          </div>
        </div>
        <div v-show="enabled == 1">
          <div class="form-group row">
            <label class="col-md-3 form-control-label">
              {{ $t('company_profile.subscription_page_if_the_balance_falls_below') }}
            </label>
            <div class="col-md-9">
              <select v-model="threshold" class="form-control">
                <option
                  v-for="item in thresholdOptions"
                  :key="item.value"
                  :value="item.value"
                  v-html="item.text"
                ></option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="notificationEmailInput" class="col-md-3 form-control-label">
              {{ $t('company_profile.subscription_page_send_notification_to') }}
            </label>
            <div class="col-md-9">
              <input id="notificationEmailInput" v-model="notification_email" type="email" class="form-control" />
            </div>
          </div>
        </div>
        <button type="submit" class="btn success text-white" :class="{ loader: saving }">
          {{ $t('company_profile.subscription_page_save') }}
        </button>
      </div>
    </div>
  </form>
</template>
