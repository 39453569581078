export const BASE_URL = '/api/v2/';
const CLIENT_API_URL = '/client-api/';

export const ENDPOINT = {
  AGENCY: `${BASE_URL}agency`,
  ATTACHMENTS: `${BASE_URL}attachments?`,
  AUTHORISE_DEBIT_TRANSACTION: `${BASE_URL}transactions/authorise`,
  AUTH_ACTIVITY: `/auth/activity`,
  BILLING_ONE_TIME_PURCHASE_TOP_UP_COMMITMENT: `${BASE_URL}billing/one-time-purchase/top-up-commitment`,
  BILLING_ONE_TIME_PURCHASE_INVOICES: `${BASE_URL}billing/one-time-purchase/invoices`,
  BILLING_SUBSCRIPTION_SCHEDULED_CHANGES: `${BASE_URL}billing/subscription/scheduled-changes`,
  BILLING_SUBSCRIPTION_UPDATE_CARRIER_COST: `${BASE_URL}billing/subscription-update/carrier-cost`,
  BILLING_SUBSCRIPTION_ADD_ADDONS_WITH_QUANTITY: `${BASE_URL}billing/subscription-update/add-addons-with-quantity`,
  BILLING_SUBSCRIPTION_UPDATE_ADDONS_WITH_QUANTITY: `${BASE_URL}billing/subscription-update/update-addons-with-quantity`,
  CHANNELS: `${BASE_URL}channels`,
  CHARGEBEE_GENERATE_CHECKOUT: `${BASE_URL}chargebee/generate-checkout-url`,
  CHARGEBEE_PORTAL_SESSION: `${BASE_URL}chargebee/portal-session`,
  CHARGEBEE_SUBSCRIPTION: `${BASE_URL}chargebee/subscription`,
  CHARGEBEE_SUBSCRIPTION_CHECKOUT: `${BASE_URL}chargebee/subscription/checkout`,
  CHARGEBEE_SUBSCRIPTION_CHECKOUT_UPDATE: `${BASE_URL}chargebee/subscription/checkout/update`,
  CHARGEBEE_SUBSCRIPTION_CHECKOUT_UPDATE_PREVIEW: `${BASE_URL}chargebee/subscription/checkout/update-preview`,
  CHARGEBEE_SUBSCRIPTION_NEXT_PAYMENT_ESTIMATE: `${BASE_URL}chargebee/subscription/next_payment_estimate`,
  CHARGEBEE_TOP_UP_INVOICE: `${BASE_URL}chargebee/top-up/invoice`,
  COMPANY_PROFILE: `${CLIENT_API_URL}company_profile`,
  COMPANY_PROFILE_SETTINGS: `${CLIENT_API_URL}company_profile/settings`,
  COMPANY_PROFILE_SUPPORT: `${CLIENT_API_URL}company_profile/support`,
  CONSOLIDATED_MESSAGE_ENDPOINT: `${BASE_URL}messaging/send`,
  CONTACTS: `${BASE_URL}contacts`,
  DIRECT_LOGIN_URL: `${CLIENT_API_URL}me/direct-login-url`,
  INBOX_AI: `${BASE_URL}inbox_ai`,
  INBOX_AI_FEEDBACK: `${BASE_URL}inbox_ai/feedback`,
  INTEGRATIONS: `${BASE_URL}integration_hub`,
  IS_IMPERSONATED: `${BASE_URL}impersonation/user/is_impersonated`,
  ITEM_ENTITLEMENTS: `${BASE_URL}entitlements/item-entitlements`,
  LOGIN_TOKEN: `${CLIENT_API_URL}me/token`,
  ME: `${BASE_URL}me`,
  ME_CHANNELS: `${BASE_URL}me/channels`,
  PLANS: `${BASE_URL}plans`,
  RULES: `${BASE_URL}rules`,
  SERVICE_NOTIFICATIONS: `${BASE_URL}service_notifications`,
  SERVICE_NOTIFICATIONS_CHANNELS: `${BASE_URL}service_notifications/channels`,
  SUBSCRIPTION: `${BASE_URL}billing/subscriptions/0`,
  SUBSCRIPTION_ENTITLEMENTS: `${BASE_URL}entitlements/subscription-entitlements`,
  TEAMS: `${BASE_URL}teams`,
  TICKETS: `${BASE_URL}tickets`,
  TICKET_DRAFTS: `${BASE_URL}ticket_drafts`,
  TRANSACTIONS: `${BASE_URL}transactions`,
  TRANSACTIONS_DOWNLOAD: `${BASE_URL}transactions/download`,
  USAGE_BREAKDOWN: `${BASE_URL}usage/breakdown`,
  USERS: `${BASE_URL}users`,
  USERS_LIST: `${CLIENT_API_URL}users/list`,
  USER_VERIFICATION_GENERATE_TOKEN: `${BASE_URL}user_verification/generate_token`,
  USER_VERIFICATION_SEND_EMAIL: `${BASE_URL}user_verification/send_email`,
  USER_VERIFICATION_VERIFY_TOKEN: `${BASE_URL}user_verification/verify_token`,
  WA_TEMPLATES: `${BASE_URL}wa_templates`,
} as const;

export const FEEDBACK_TYPE = {
  SUGGEST_REPLIES: 'SUGGESTED_REPLIES',
  TRANSLATION: 'TRANSLATION',
  CONVERSATION_SUMMARY: 'CONVERSATION_SUMMARY',
};
