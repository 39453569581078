<template>
  <div class="flex h-full flex-col items-center justify-center">
    <div class="mb-3">
      <slash-linear v-if="entityType === 'channel'" size="1.25rem" />
      <user-cross-linear v-else size="1.25rem" />
    </div>
    <div class="t-text-desktop-paragraph-sm text-center">
      {{ message }}
    </div>
  </div>
</template>

<script>
import { SlashLinear, UserCrossLinear } from '@trengo/trengo-icons';

export default {
  name: 'NoEntityFound',
  components: {
    SlashLinear,
    UserCrossLinear,
  },
  props: {
    entityType: {
      type: String,
      default: 'channel',
      validate: (value) => ['channel', 'member'].includes(value),
    },
    message: {
      type: String,
      default: '',
    },
  },
};
</script>
