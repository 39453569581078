<script lang="ts" setup>
import { UsersLinear } from '@trengo/trengo-icons';

import { vm } from '@/ApplicationHandler';

const props = defineProps<{
  searchKey: string;
}>();
</script>

<template>
  <t-table-list data-test="table" class="mb-2 bg-white">
    <t-table-list-row head>
      <t-table-list-head />
    </t-table-list-row>
    <t-table-list-row data-test="table-rows">
      <div class="flex h-72 flex-col items-center justify-center">
        <div class="border-gray-900 mb-4 rounded-xl border-1 border-opacity-10 bg-white p-2">
          <UsersLinear width="24px" height="24px" />
        </div>
        <p class="t-text-md-emphasize mb-0 text-grey-800">
          {{ vm.$t('contacts.no_contacts_find_for') }} ‘{{ props.searchKey }}’
        </p>
      </div>
    </t-table-list-row>
  </t-table-list>
</template>
