<template>
  <get-started-card :is-done="isDone" data-test="explore-shared-inbox">
    <template #title>{{ $t('getting_started.explore_shared_inbox') }}</template>
    <template #description>{{ $t('getting_started.learn_about_conversations') }}</template>
    <template #icon>
      <img class="w-48px rounded-2xl" :src="`${$root.assetsURL}img/get_started_page/email.svg`" />
    </template>
    <div class="pt-1">
      <div
        v-if="!isDone"
        class="
          mb-6
          flex
          min-h-48px
          flex-col
          items-end
          justify-between
          rounded-lg
          bg-grey-300
          p-2
          sm:flex-row sm:items-center
        "
      >
        <div class="flex items-start sm:items-center">
          <div class="icon-card">
            <img :src="`${$root.assetsURL}img/get_started_page/whatsapp.svg`" />
          </div>
          <div class="t-text-desktop-label-sm tet-grey-900">
            {{ $t('getting_started.try_it_now_in_our_trengo_sandbox') }}
          </div>
        </div>
        <div class="mt-2 min-w-max sm:mt-0">
          <t-button
            btn-style="primary-alt"
            type="primary-alt"
            size="xs"
            class="ml-auto inline-flex h-32px min-w-max items-center px-4 text-sm leading-4"
            @click="openSandboxModal"
          >
            <span class="t-text-desktop-label-sm mr-1 text-grey-900">{{ $t('getting_started.try_now') }}</span>
            <img :src="`${$root.assetsURL}img/arrow_right.svg`" class="w-16px" />
          </t-button>
        </div>
      </div>
      <div class="flex h-32px items-center justify-between sm:px-2" data-test="item-assign-first-ticket">
        <div class="flex flex-row">
          <img :src="stateImage(assignFirstTicketIsDone)" class="mr-2" />
          <span :class="[stateTextColor(assignFirstTicketIsDone)]" class="t-text-desktop-label-sm mr-2 sm:mr-0">
            {{ $t('getting_started.assign_your_first_conversation') }}
          </span>
        </div>

        <atomic-button
          v-if="!assignFirstTicketIsDone"
          btn-style="secondary"
          size="xs"
          class="ml-auto inline-flex h-32px items-center px-4 text-sm leading-4"
          @click="redirectToInbox"
        >
          <span class="t-text-desktop-label-sm mr-1 text-grey-900">{{ $t('getting_started.start') }}</span>
          <img :src="`${$root.assetsURL}img/arrow_right.svg`" class="w-16px" />
        </atomic-button>
      </div>
      <div class="mt-3 flex h-32px items-center justify-between sm:px-2" data-test="item-internal-comment">
        <div class="flex flex-row">
          <img :src="stateImage(commentedInternally)" class="mr-2" />
          <span :class="[stateTextColor(commentedInternally)]" class="t-text-desktop-label-sm mr-2 sm:mr-0">
            {{ $t('getting_started.leave_an_internal_comment') }}
          </span>
        </div>
        <atomic-button
          v-if="!commentedInternally"
          btn-style="secondary"
          size="xs"
          class="ml-auto inline-flex h-32px items-center px-4 text-sm leading-4"
          @click="redirectToInbox"
        >
          <span class="t-text-desktop-label-sm mr-1 text-grey-900">{{ $t('getting_started.start') }}</span>
          <img :src="`${$root.assetsURL}img/arrow_right.svg`" class="w-16px" />
        </atomic-button>
      </div>
      <div class="mt-3 flex h-32px items-center justify-between sm:px-2" data-test="reply-a-conversation">
        <div class="flex flex-row">
          <img :src="stateImage(repliedConversation)" class="mr-2" />
          <span :class="[stateTextColor(repliedConversation)]" class="t-text-desktop-label-sm mr-2 sm:mr-0">
            {{ $t('getting_started.reply_a_conversation') }}
          </span>
        </div>

        <atomic-button
          v-if="!repliedConversation"
          btn-style="secondary"
          size="xs"
          class="ml-auto inline-flex h-32px items-center px-4 text-sm leading-4"
          @click="redirectToInbox"
        >
          <span class="t-text-desktop-label-sm mr-1 text-grey-900">{{ $t('getting_started.start') }}</span>
          <img :src="`${$root.assetsURL}img/arrow_right.svg`" class="w-16px" />
        </atomic-button>
      </div>
      <div class="mt-3 flex h-32px items-center justify-between sm:px-2" data-test="item-closed-ticket">
        <div class="flex flex-row">
          <img :src="stateImage(closeFirstTicket)" class="mr-2" />
          <span :class="[stateTextColor(closeFirstTicket)]" class="t-text-desktop-label-sm mr-2 sm:mr-0">
            {{ $t('getting_started.close_your_first_conversation') }}
          </span>
        </div>
        <atomic-button
          v-if="!closeFirstTicket"
          btn-style="secondary"
          size="xs"
          class="ml-auto inline-flex h-32px items-center px-4 text-sm leading-4"
          @click="redirectToInbox"
        >
          <span class="t-text-desktop-label-sm mr-1 text-grey-900">{{ $t('getting_started.start') }}</span>
          <img :src="`${$root.assetsURL}img/arrow_right.svg`" class="w-16px" />
        </atomic-button>
      </div>
    </div>
    <whatsapp-sandbox-modal
      v-if="showSandboxModal"
      v-model="showSandboxModal"
      @open="showSandboxModal"
      @close="showSandboxModal = false"
    ></whatsapp-sandbox-modal>
  </get-started-card>
</template>

<script>
import { mapGetters } from 'vuex';

import WhatsappSandboxModal from '@/components/Channels/WhatsappSandboxModal';

import GetStartedCard from '../GetStartedCard';

export default {
  name: 'SharedInbox',
  emits: ['open'],
  components: { WhatsappSandboxModal, GetStartedCard },
  data() {
    return {
      showSandboxModal: false,
      isDone: false,
      assignFirstTicketIsDone: false,
      commentedInternally: false,
      repliedConversation: false,
      closeFirstTicket: false,
    };
  },
  mounted() {
    this.assignFirstTicketIsDone = this.getStepByType('user.assigned_first_ticket')?.completed;
    this.commentedInternally = this.getStepByType('user.internal_comment_on_first_ticket')?.completed;
    this.repliedConversation = this.getStepByType('user.reply_conversation')?.completed;
    this.closeFirstTicket = this.getStepByType('user.archived_ticket')?.completed;

    if (this.assignFirstTicketIsDone && this.commentedInternally && this.closeFirstTicket && this.repliedConversation) {
      this.isDone = true;
    }
  },
  computed: {
    ...mapGetters({
      getStepByType: 'gamification/getStepByType',
    }),
  },
  methods: {
    openSandboxModal() {
      if (this.$store.state.onboardingSteps?.enabled && this.$root.onboarding?.type === 'whatsapp') {
        this.$emit('open');
        return;
      }
      this.showSandboxModal = true;
      $('#WaBusinessSandboxModal').modal();
    },
    stateImage(step) {
      return step
        ? this.$root.assetsURL + 'img/get_started_page/successful.svg'
        : this.$root.assetsURL + 'img/get_started_page/unsuccessful.svg';
    },
    stateTextColor(step) {
      return step ? 'state-text-grey-500' : 'state-text-grey-800';
    },
    redirectToInbox() {
      this.$router.push('/tickets');
    },
  },
};
</script>

<style scoped src="../styles.scss" lang="scss" />
