<template>
  <sidebar-dropdown
    :title="$tc('ticket_sidebar.linked_conversations')"
    collapse-key="relatedTicketsDropdown"
    data-test="related-tickets-dropdown"
  >
    <div
      v-for="ticket in relatedTickets"
      :key="ticket.id"
      class="
        border-opacity-300
        flex flex-col
        border-b border-grey-300
        py-1
        last:border-none last:pb-0
        only:border-none only:py-0
      "
      data-test="related-ticket-item-wrapper"
    >
      <div class="flex flex-row items-center">
        <p class="t-text-md-emphasize m-0 max-w-[33%] text-ellipsis p-0 text-grey-700" data-test="related-ticket-name">
          {{ displayedName(ticket) }}
        </p>

        <router-link
          class="t-text-md ml-3 text-ellipsis underline"
          :to="`/tickets/${ticket.id}`"
          data-test="related-ticket-number"
        >
          #{{ ticket.id }}
        </router-link>

        <button
          class="ml-auto p-1 text-grey-500 transition-colors hover:text-grey-700 active:text-grey-800"
          data-test="remove-related-ticket-button"
          @click.prevent="removeRelation(ticket.id)"
        >
          <trash-linear size="1.1rem" />
        </button>
      </div>

      <p
        v-if="ticket.subject"
        class="t-text-md m-0 mt-1 text-ellipsis p-0 text-grey-700"
        data-test="related-ticket-subject"
      >
        <span class="t-text-md-emphasize">{{ $t('tickets.subject') }}:</span>
        {{ ticket.subject }}
      </p>
    </div>
  </sidebar-dropdown>
</template>

<script lang="ts">
import { TrashLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import { removeRelatedTicket } from '@/components/TicketSidebar/Api/requests';
import SidebarDropdown from '@/components/TicketSidebar/SidebarDropdown.vue';

import type Tickets from '@/types/tickets';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'RelatedTicketsDropdown',
  emits: ['remove-related'],
  components: { SidebarDropdown, TrashLinear },
  props: {
    relatedTickets: {
      type: Array as PropType<Tickets[]>,
      default: () => [],
    },
    mainTicketId: {
      type: Number as PropType<Tickets['id']>,
      default: 0,
    },
  },

  methods: {
    displayedName(ticket: Tickets) {
      return ticket.contact?.name ? ticket.contact?.name : ticket.contact?.email;
    },

    async removeRelation(relatedId: Tickets['id']) {
      try {
        await removeRelatedTicket(this.mainTicketId, relatedId).then(() => {
          this.$emit('remove-related', relatedId);
          this.flashSuccess('Ticket successfuly unlinked');
        });
      } catch (err) {
        console.error(err);
        this.flashError(err);
      }
    },
  },
});
</script>
