import { h } from 'vue';
import { RouterView } from 'vue-router';

import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const wabBroadcastingRoutes: RouteRecord[] = [
  {
    path: 'broadcasting',
    component: { render: () => h(RouterView) },
    meta: { title: 'Broadcasting' },
    children: [
      {
        path: '',
        component: require('../../components/WabBroadcasting/pages/BroadcastingPage').default,
        meta: { permissionRequired: PERMISSION.BROADCASTING__GENERAL__MANAGE },
      },
      {
        path: 'new',
        component: require('../../components/WabBroadcasting/pages/CreateBroadcast/CreateBroadcast.vue').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.BROADCASTING__GENERAL__MANAGE },
      },
      {
        path: ':id',
        component: require('../../components/WabBroadcasting/pages/CreateBroadcast/CreateBroadcast.vue').default,
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.BROADCASTING__GENERAL__MANAGE },
      },
    ],
  },
];

export default wabBroadcastingRoutes;
