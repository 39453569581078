<template>
  <div
    ref="v"
    class="rounded-xl border-1 border-grey-300"
    :class="{
      'mr-2 px-4 py-2': !newMultiselect,
      'color-on-open': open,
      'new-multiselect': newMultiselect,
    }"
  >
    <div v-if="!newMultiselect" class="cursor-pointer flex-nowrap" @click="toggle">
      <slot name="toggle">
        <span
          class="text-sm font-bold text-grey-600"
          :class="{
            'text-leaf-500': open,
            'text-grey-800': !open,
          }"
        >
          {{ title }}:
          {{ selection.length ? selection.length : $t('general.multiselect_all') }}
        </span>
        <i class="far fa-chevron-down pl-1 text-sm text-grey-600"></i>
      </slot>
    </div>
    <div v-if="newMultiselect" class="cursor-pointer" @click="toggle">
      <slot name="toggle">
        <div
          class="multiselect__tags w-full items-center justify-between text-base"
          :class="{
            'text-grey-500': !selection.length,
            'text-grey-800': selection.length,
          }"
        >
          <div class="multipleSelect__tags">
            <div class="multipleSelect__tags-wrapper">
              <div v-for="item in selectedOptions" class="multipleSelect__tag">
                {{ item[label] }}
                <i class="far fa-close multipleSelect__tag-icon" @click.stop="deselectOption(item)"></i>
              </div>
            </div>
            {{ selection.length ? '' : placeholder }}
          </div>
          <i class="far fa-chevron-down mr-1 flex flex-col justify-center text-sm text-grey-600"></i>
        </div>
      </slot>
    </div>
    <div v-if="open" class="multipleSelect__overlay" @click="close()"></div>
    <transition name="scale-fade">
      <div
        v-if="open"
        ref="container"
        class="multipleSelect__container"
        :class="{
          'multipleSelect__container--remote': remote,
          'filters-multiselect-container': !newMultiselect,
        }"
        :style="{ top: top, left: left }"
      >
        <div v-if="!showCheckboxes && !newMultiselect" class="multipleSelect__tags">
          <div class="multipleSelect__tags-wrapper">
            <div v-for="item in selectedOptions" :key="item.label" class="multipleSelect__tag">
              {{ item[label] }}
              <i class="far fa-close multipleSelect__tag-icon" @click="deselectOption(item, newMultiselect)"></i>
            </div>
            <div class="multipleSelect__search">
              <label v-if="!newMultiselect" for="searchInput"></label>
              <input
                id="searchInput"
                ref="input"
                @input="onSearch"
                @keydown="handleKeyboardEvent($event)"
                v-model="query"
                type="text"
                :placeholder="
                  remote
                    ? $t('general.multiselect_search_placeholder_type_something_to_search')
                    : $t('general.multiselect_search_placeholder_search')
                "
              />
            </div>
          </div>
        </div>
        <div
          class="multipleSelect__options"
          :class="{
            'px-3': !newMultiselect,
          }"
        >
          <div
            v-for="option in visibleOptions"
            :key="option.id"
            class="multipleSelect__option flex items-center"
            :class="{
              'multipleSelect__option--selected': visibleOptions.indexOf(option) === selectedIndex,
            }"
            @mouseover="selectedIndex = visibleOptions.indexOf(option)"
            @click="clickOption($event, option)"
          >
            <atomic-checkbox v-if="showCheckboxes" v-model="selection" class="mb-0 mr-2" :value="option.id" />
            <img v-if="showCheckboxes" class="mr-2" :src="option.url" width="16px;" height="16px;" />
            <avatar
              v-if="showAvatars"
              width="20"
              class="mr-1"
              :color="option.color"
              :abbr="option.abbr"
              :image="option.profile_image"
            ></avatar>
            {{ option[label] }}
          </div>
        </div>
        <div
          v-show="!remote || (remote && visibleOptions.length > 0)"
          class="footer mt-auto flex pt-4"
          :class="{
            'mx-3': !newMultiselect,
          }"
        >
          <div v-if="selectAll && this.visibleOptions.length">
            <atomic-button
              size="sm"
              btn-style="secondary"
              :type="newMultiselect ? 'button' : undefined"
              @click="selectAllOptions()"
            >
              {{ $t('general.multiselect_select_all') }}
            </atomic-button>
          </div>
          <div v-if="!newMultiselect" class="ml-auto flex">
            <atomic-button size="sm" btn-style="secondary" @click="clear()">
              {{ $t('general.multiselect_clear') }}
            </atomic-button>
            <atomic-button size="sm" class="ml-2" @click="close()">{{ $t('general.multiselect_done') }}</atomic-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style></style>

<script>
import { filter, includes } from 'lodash';

import Avatar from '../../components/Avatar';

export default {
  name: 'Multiselect',
  emits: ['update:modelValue', 'search'],
  data() {
    return {
      selection: [],
      open: false,
      top: 0,
      left: 0,
      query: '',
      active: null,
      selectedIndex: 0,
      selectedOptions: [],
    };
  },
  components: { Avatar },
  props: {
    label: {
      default: 'name',
    },
    remote: {
      default: false,
    },
    selectAll: {
      default: false,
    },
    title: {},
    options: {},
    modelValue: {},
    newMultiselect: {
      default: false,
    },
    placeholder: '',
    showAvatars: {
      default: false,
    },
    showCheckboxes: {
      default: false,
    },
  },

  watch: {
    modelValue() {
      this.determineSelection();
    },
    selection() {
      if (this.showCheckboxes) {
        // this.modelValue = this.selection;
        this.selectedOptions = filter(this.options, (option) => includes(this.selection, option.id));
        this.$emit('update:modelValue', this.selection);
      }
    },
  },
  computed: {
    visibleOptions() {
      if (this.showCheckboxes) {
        return this.options;
      }
      let term = this.query.toLowerCase();
      let results = this.options;
      if (this.query && this.query.length && !this.remote) {
        this.selectedIndex = 0;
        results = results.filter((row) => row[this.label].toLowerCase().indexOf(term) > -1);
      }
      return results.filter((option) => !includes(this.selection, option.id));
    },
  },
  methods: {
    determineSelection() {
      if (this.newMultiselect) {
        this.selection = this.modelValue.map((i) => i.id);
        this.selectedOptions = filter(this.options, (option) => includes(this.selection, option.id));
        return;
      }
      this.selection = this.modelValue;
      this.selectedOptions = filter(this.options, (option) => includes(this.selection, option.id));
    },
    selectAllOptions() {
      this.visibleOptions.forEach((option) => {
        this.selectedOptions.push(option);
        this.selection.push(option.id);
      });
      this.$emit('update:modelValue', this.selection);
      this.close();
    },
    onSearch() {
      this.$emit('search', this.query);
    },
    adjustDropdown() {
      this.$nextTick(() => {
        const v = this.$refs.v;
        let rect = v.getBoundingClientRect();
        //if it fits on the screen
        if (window.innerHeight > 480 + rect.top + document.body.scrollTop + v.clientHeight) {
          this.top = rect.top + document.body.scrollTop + v.clientHeight + 10 + 'px';
        } else {
          this.top = window.innerHeight - 490 + 'px';
        }
        this.left = rect.left + document.body.scrollLeft + 'px';
        if (this.$refs.input) {
          this.$refs.input.focus();
        }
      });
    },
    toggle() {
      this.open = !this.open;
      if (this.open) {
        this.adjustDropdown();
      }
    },
    close() {
      if (this.open) {
        this.open = false;
      }
    },
    selectOption(option) {
      this.selectedOptions.push(option);
      this.selection.push(option.id);
      if (!this.newMultiselect) {
        this.$emit('update:modelValue', this.selection);
      } else {
        this.$emit('update:modelValue', this.selectedOptions);
      }
      this.$refs.input?.focus();
      this.selectedIndex = 0;
      this.query = '';
      this.adjustDropdown();
      if (!this.visibleOptions.length) {
        this.close();
      }
    },
    deselectOption(option) {
      this.selectedOptions.splice(this.selectedOptions.indexOf(option), 1);
      this.selection.splice(this.selection.indexOf(option.id), 1);
      if (!this.newMultiselect) {
        this.$emit('update:modelValue', this.selection);
      } else {
        this.$emit('update:modelValue', this.selectedOptions);
      }
      this.$refs.input?.focus();
      this.selectedIndex = 0;
      this.adjustDropdown();
    },
    displayOption(id) {
      return (this.display.find((option) => option.id === id) || {})[this.label];
    },
    handleKeyboardEvent(event) {
      if (event.key === 'ArrowDown' || (event.key === 'Tab' && !event.shiftKey)) {
        this.increaseSelectIndex(event);
      } else if (event.key === 'ArrowUp' || (event.key === 'Tab' && event.shiftKey)) {
        this.decreaseSelectIndex(event);
      } else if (event.key === 'Enter' && this.visibleOptions[this.selectedIndex]) {
        this.selectOption(this.visibleOptions[this.selectedIndex]);
      } else if (event.key === 'Backspace' && this.selectedOptions.length && this.query.length === 0) {
        this.deselectOption(this.selectedOptions[this.selectedOptions.length - 1]);
      } else if (event.key === 'Escape' && this.open) {
        this.open = false;
      }
    },
    increaseSelectIndex(event) {
      if (this.selectedIndex === null || this.selectedIndex >= this.visibleOptions.length - 1) {
        this.selectedIndex = 0;
      } else {
        this.selectedIndex++;
      }
    },
    decreaseSelectIndex(event) {
      if (this.selectedIndex <= 0) {
        this.selectedIndex = this.visibleOptions.length - 1;
      } else {
        this.selectedIndex--;
      }
    },
    clear() {
      this.selection = [];
      if (this.newMultiselect) {
        this.selectedOptions = [];
      }
      this.$emit('update:modelValue', this.selection);
      this.close();
    },
    toggleValueIfClickingOption(event, values, target_id) {
      // so clicking on the checkbox doesn't trigger this
      if (event.target.className.includes('multipleSelect__option')) {
        if (values.includes(target_id)) {
          values = values.filter((item) => item !== target_id);
          this.$emit('update:modelValue', values);
        } else {
          values.push(target_id);
        }
      }
    },
    clickOption(event, option) {
      !this.showCheckboxes
        ? this.selectOption(option, this.newMultiselect)
        : this.toggleValueIfClickingOption(event, this.selection, option.id);
    },
  },

  mounted() {
    this.determineSelection();
  },
};
</script>

<style lang="scss" scoped>
.multipleSelect__container {
  width: 400px;
  position: fixed;
  z-index: 9999999;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.02), 0px 13px 28px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 16px;
  max-height: 480px;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.multipleSelect__container--remote {
  height: auto;
  min-height: 0;
  max-height: 470px;
}

.multipleSelect__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}

.multipleSelect__tags {
  background: #ffffff;
  border: 1px solid #249888;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px 4px rgba(36, 152, 136, 0.25);
  border-radius: 12px;
  padding: 8px 12px;
  max-height: 298px;
  overflow-y: auto;
  flex: 1;

  .multipleSelect__tags-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
  }

  .multipleSelect__tag {
    background: #f1f1f1;
    border-radius: 4px;
    padding: 0px 25px 0px 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    margin: 4px;
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    max-width: 220px;
  }

  .multipleSelect__tag-icon {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 13px;
    height: 32px;
    line-height: 34px;
    cursor: pointer;
  }

  .multipleSelect__search {
    margin: 4px;

    input {
      width: 210px;
      height: 32px;
      line-height: 32px;
    }
  }
}

.multipleSelect__options {
  flex: 0 999 328px;
  overflow-y: auto;
  margin-top: 10px;
  min-height: 88px;

  .multipleSelect__option {
    border-radius: 12px;
    line-height: 48px;
    height: 48px;
    padding: 0 16px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .multipleSelect__option--selected {
    background: #f1f1f1;
  }
}

.filters-multiselect-container {
  .multipleSelect__tags {
    background: var(--color-grey-100);
    border: none;
    box-shadow: none;
    padding: 0px 14px;

    .multipleSelect__tags-wrapper {
      background: var(--color-grey-100);

      input {
        background: var(--color-grey-100);
        font-weight: 500;
        font-size: 16px;
        position: relative;
        padding-left: 0.75rem;
        width: 300px;

        ::placeholder {
          color: var(--color-grey-700);
          opacity: 1;
        }
      }

      label {
        margin: 0;

        &:before {
          font-family: FontAwesome;
          font-size: 18px;
          content: '\f002';
          color: var(--color-grey-500);
          -webkit-text-stroke: 1px var(--color-grey-100);
        }
      }
    }

    .multipleSelect__tag {
      background: var(--color-galaxy-900);
      color: var(--color-white);
      border-radius: 12px;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      padding: 0px 28px 0px 8px;
      margin-top: 6px;
    }
  }

  .multipleSelect__options {
    .multipleSelect__option {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-grey-800);
      padding: 0;
    }

    .multipleSelect__option--selected {
      background: var(--color-white);
    }
  }

  .footer {
    padding-top: 0.75rem;
    border-top: 1px solid var(--color-grey-300);
  }
}

.color-on-open {
  border: 1px solid #249888;
  box-shadow: 0px 0px 0px 4px rgba(36, 152, 136, 0.25);
}

@media (max-width: 768px) {
  .multipleSelect__container {
    position: fixed;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    bottom: 0 !important;
    top: 100px !important;
    height: auto;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.new-multiselect {
  .multiselect__tags {
    border-radius: 12px;
    border: 0;
    min-height: 48px;
    padding: 0 1rem;
    display: flex;

    @media only screen and (min-width: 1024px) {
      min-height: 64px;
    }

    div {
      font-size: 16px;

      @media only screen and (min-width: 1024px) {
        font-size: 18px;
      }
    }

    .multipleSelect__tags {
      padding: 8px 0;
      border: 0;
      box-shadow: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 58px;

      .multipleSelect__tag {
        background: var(--color-galaxy-100);
        border-radius: 12px;
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
      }
    }
  }

  .multipleSelect__options {
    margin: 0;
  }
}
</style>
