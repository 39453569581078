<template>
  <div>
    <template v-if="!aligned && fields.length > 0">
      <div v-for="field in fields" :key="field.id" class="nav-item m-b-xs">
        <div class="mb-1 text-sm font-semibold text-grey-700">{{ field.title }}</div>
        <custom-field-item :field="field" @save="save(field)" />
      </div>
    </template>

    <template v-else-if="aligned && fields.length > 0">
      <div class="row p-a">
        <div v-for="field in fields" :key="field.id" class="m-b-xs">
          <div class="mb-1 text-sm font-semibold text-grey-700">{{ field.title }}</div>
          <custom-field-item :field="field" @save="save(field)" />
        </div>
      </div>
    </template>

    <template v-else>
      <t-inline-banner type="default">
        <template #icon>
          <info-linear />
        </template>

        <p class="t-text-sm-emphasize m-0 p-0">{{ $tc('ticket_sidebar.no_profile_fields_setup') }}</p>
      </t-inline-banner>
    </template>
  </div>
</template>

<script lang="ts">
import { InfoLinear } from '@trengo/trengo-icons';
import { cloneDeep, find } from 'lodash';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import { storeProfileCustomField as apiStoreProfileCustomField } from './Api';
import CustomFieldItem from '../TicketSidebar/CustomFieldItem';

import type Profile from '@/types/profile';
import type { PropType } from 'vue';

export default defineComponent({
  props: {
    profile: {
      type: Object as PropType<Profile>,
      default: () => ({}),
    },
    aligned: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    InfoLinear,
    CustomFieldItem,
  },

  mounted() {
    this.loadList();
  },

  data: function () {
    return {
      loaded: false,
      fields: [],
    };
  },

  computed: {
    ...mapState({
      storedFields: (state: any) => state.customFields.customProfileFields,
      customFieldsLoading: (state: any) => state.customFields.loading,
      customFieldsLoaded: (state: any) => state.customFields.loaded,
    }),
  },
  watch: {
    customFieldsLoaded(loaded) {
      if (loaded) {
        this.loadList();
      }
    },
  },
  methods: {
    save(fieldToSave: unknown) {
      let field = find(this.fields, function (field: unknown) {
        return field?.id === fieldToSave?.id;
      });
      const fieldValue = field.value ? field.value : null;
      apiStoreProfileCustomField(this.profile.id, field.id, fieldValue);
    },

    loadList() {
      this.fields = this.storedFields;

      this.fields.map((field: any) => {
        let value = this.profile.custom_fields.filter((f: { id: any }) => f.id == field.id);
        Object.assign(field, {
          value: value.length ? value[0].pivot.value : null,
          profileId: this.profile.id,
        });
      });

      this.$store.commit('customFields/setTicketProfileFields', cloneDeep(this.fields));

      this.loaded = true;
    },
  },
});
</script>
