import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mx-4 max-h-48 overflow-x-hidden rounded-lg bg-orange p-4 text-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.channelsWithConnectionIssues.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.$t('general.channels_with_connection_issues')) + " ", 1 /* TEXT */),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.channelsWithConnectionIssues, (channel, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "inline-block break-words"
          }, [
            _createVNode(_component_router_link, {
              to: _ctx.manageChannelsRoute(channel),
              class: "pointer font-bold hover:text-grey-300"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(channel.display_name), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]),
            (index !== _ctx.channelsWithConnectionIssues.length - 1)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(",")
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
    : _createCommentVNode("v-if", true)
}