<template>
  <div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.shopify_label_webshop_domain') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.url" type="text" autocomplete="off" class="form-control" />
        <span class="form-text">Your shop domain (without https://).</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        {{ $t('integrations.shopify_label_api_key') }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.api_key" type="text" autocomplete="off" class="form-control" />
        <span class="form-text" v-html="$t('integrations.shopify_subtitle_your_api_key')"></span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">
        API password:
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-10">
        <input v-model="record.meta.api_password" type="text" autocomplete="off" class="form-control" />
        <span class="form-text">
          Your app password.
          <a
            href="https://help.shopify.com/en/api/getting-started/authentication/private-authentication"
            target="_blank"
          >
            Learn how
          </a>
          .
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShopifyPlugin',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    action: {
      type: String,
      default: 'create',
    },
  },
  mounted() {
    // Redirect to the new Shopify integration if users land on the legacy shopify version
    if (this.action === 'create') {
      setTimeout(() => {
        window.location.href = 'https://app.trengo.com/admin/developers/plugins/create/shopify2';
      }, 500);
    }
  },
  computed: {
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
