<template>
  <div
    class="
      flex
      h-full
      flex-col
      items-center
      justify-center
      border-1 border-grey-300
      bg-white
      p-12
      text-center
      lg:hidden
    "
  >
    <img alt="desktop" :src="`${$root.assetsURL}img/desktop.svg`" width="36px;" height="44px;" />
    <h2 class="mt-8">
      <slot />
    </h2>

    <atomic-button v-if="hasBack" size="md" btn-style="secondary" class="mt-8" @click="goBack">
      <i class="far fa-arrow-left mr-2" />
      Back
    </atomic-button>
  </div>
</template>

<style scoped lang="scss">
h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}
</style>

<script>
export default {
  name: 'MobilePreviewCard',
  props: {
    hasBack: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
};
</script>
