<template>
  <div class="flex flex-row">
    <t-tooltip v-for="label in getTwoItems" :key="label.id" :text="label.name">
      <t-badge style="max-width: 130px" class="mr-2 truncate text-ellipsis">
        <template #icon>
          <div class="max-w-[7rem] truncate">{{ label.name }}</div>
        </template>
      </t-badge>
    </t-tooltip>
    <t-tooltip v-if="getItemsAfterIndexTwo.length" :text="getMoreThanTwo" position="right">
      <t-badge v-if="hasMoreThanTwoItems" class="cursor-default" :text="`+${getItemsAfterIndexTwo.length}`" />
    </t-tooltip>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import type { AttachedLabel } from './utils';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'TicketInsightLabels',
  props: {
    labels: {
      type: Array as PropType<AttachedLabel[]>,
      default: () => [],
    },
  },
  computed: {
    hasMoreThanTwoItems(): boolean {
      return this.labels.length > 2;
    },
    getTwoItems(): AttachedLabel[] {
      return this.labels.slice(0, 2);
    },
    getItemsAfterIndexTwo(): AttachedLabel[] {
      return this.labels.slice(2, this.labels.length);
    },
    getMoreThanTwo() {
      const labels: string[] = [];
      this.getItemsAfterIndexTwo.forEach((label: AttachedLabel) => {
        labels.push(label.name);
      });
      return labels.join(',<br/>');
    },
  },
});
</script>
