import { PERMISSION } from '@/Configs/Constants';
import { ROUTE_NAME } from '@/routes/constants';

import type { RouteRecord } from '@/routes/types';

const webhooksRoutes: RouteRecord[] = [
  {
    path: '/admin/integrations/webhooks',
    name: ROUTE_NAME.INTEGRATIONS_LEGACY_WEBHOOKS,
    component: () =>
      import(/* webpackChunkName: "WebhooksIndex" */ '@/components/IntegrationHub/Pages/Webhooks/Index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
  },
  {
    path: '/admin/integrations/webhooks/create',
    name: ROUTE_NAME.INTEGRATIONS_LEGACY_WEBHOOK_CREATE,
    component: () =>
      import(/* webpackChunkName: "WebhookCreate" */ '@/components/IntegrationHub/Pages/Webhooks/Edit.vue'),
    props: { action: 'create' },
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
  },
  {
    path: '/admin/integrations/webhooks/:id',
    name: ROUTE_NAME.INTEGRATIONS_LEGACY_WEBHOOK_ITEM,
    component: () =>
      import(/* webpackChunkName: "WebhookItem" */ '@/components/IntegrationHub/Pages/Webhooks/Edit.vue'),
    props: { action: 'edit' },
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
  },
];

export default webhooksRoutes;
