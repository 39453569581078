<script>
export default {
  emits: ['update:modelValue', 'search'],
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'dark',
    },
    modelValue: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      term: '',
      timer: null,
      dirty: false,
    };
  },

  watch: {
    modelValue(v) {
      this.term = this.modelValue || '';
    },
    term() {
      this.dirty = true;
    },
  },

  methods: {
    search() {
      if (!this.dirty) {
        return;
      }
      this.$emit('update:modelValue', this.term);
      this.$emit('search');
      clearTimeout(this.timer);
      this.dirty = false;
    },
    clear() {
      this.term = '';
      this.$emit('update:modelValue', '');
      this.$emit('search');
    },
    onPaste() {
      this.search();
    },
    onKeyup(e) {
      if (e.key === 'Enter' || !this.dirty) {
        return;
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.search(), 1000);
    },
  },
};
</script>

<template>
  <div
    class="flex w-full items-center overflow-hidden rounded-lg bg-grey-200 px-3 leading-none"
    style="height: 46px; line-height: 46px"
  >
    <i class="material-icons mr-3 rounded-lg text-grey-600" style="font-size: 23px">search</i>
    <input
      ref="search"
      v-model="term"
      data-test="tickets-search-text-field"
      type="text"
      class="selector-conversation-search flex-1 bg-grey-200 leading-none text-grey-600"
      style="height: 40px; line-height: 40px"
      :placeholder="placeholder"
      @keyup.enter="search"
      @keyup="onKeyup($event)"
      @paste="onPaste"
    />
    <i
      v-show="term.length > 0"
      class="material-icons cursor-pointer rounded-full bg-grey-500 text-xs text-white hover:brightness-90"
      style="padding: 2px"
      @click="clear"
    >
      clear
    </i>
  </div>
</template>

<style scoped>
input::placeholder {
  color: theme('colors.grey-600') !important;
  opacity: 1;
}
</style>
