import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9ed6e88a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "users-list-header" }
const _hoisted_2 = { class: "title t-text-desktop-h3" }
const _hoisted_3 = { class: "actions" }
const _hoisted_4 = { class: "button-text" }
const _hoisted_5 = { class: "button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gear_linear = _resolveComponent("gear-linear")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_user_plus_linear = _resolveComponent("user-plus-linear")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('user_management.users')), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.userStore.hasPermission(_ctx.PERMISSION.PERMISSIONS__ROLES__MANAGE))
        ? (_openBlock(), _createBlock(_component_t_button, {
            key: 0,
            "btn-style": "secondary",
            "data-test": "manage-roles-btn",
            class: "manage-roles-btn",
            "icon-only": _ctx.isMobile,
            onClick: _ctx.handleOpenRolesModal
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_gear_linear, {
                class: "icon",
                size: "1rem"
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_gear_linear, {
                class: "icon",
                size: "1rem"
              }),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('user_management.manage_roles')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["icon-only", "onClick"]))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_t_button, {
        "data-test": "invite-user-btn",
        class: "invite-user-btn",
        "icon-only": _ctx.isMobile,
        onClick: _ctx.handleOpenModal
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_user_plus_linear, {
            class: "icon",
            size: "1rem"
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_user_plus_linear, {
            class: "icon",
            size: "1rem"
          }),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('user_management.invite_user')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["icon-only", "onClick"])
    ])
  ]))
}