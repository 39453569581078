<template>
  <div class="widget_preview"></div>
</template>

<script type="text/babel">
import { mapState } from 'pinia';

import { FEATURE_FLAG_ACTIVATION, PLAN } from '@/Configs/Constants';
import { useEntitlementsStore, useFeatureFlagStore } from '@/store/pinia';
import breakpoints from '@/util/breakpoints';

export default {
  data() {
    return {
      ready: false,
      showText: false,
      widgetIsLoaded: false,
      breakpoints,
    };
  },

  computed: {
    ...mapState(useEntitlementsStore, ['subscriptionPlan']),
  },

  mounted() {
    if (this.shouldShowWidget() && this.subscriptionPlan) {
      this.loadWidget();
      this.checkWidgetIframeLoaded();
    }
  },

  watch: {
    $route: {
      deep: true,
      handler() {
        if (!this.widgetIsLoaded && this.shouldShowWidget()) {
          this.loadWidget();
          this.checkWidgetIframeLoaded();
        }
        this.toggleWidget();
      },
    },
    subscriptionPlan() {
      if (this.shouldShowWidget() && this.subscriptionPlan) {
        this.loadWidget();
      }
    },
  },

  methods: {
    checkWidgetIframeLoaded() {
      const iframe = document.querySelector('.trengo-vue-iframe');
      if (iframe) {
        this.hideInMobile();
        return;
      }

      setTimeout(this.checkWidgetIframeLoaded, 100);
    },
    loadWidget() {
      // Insert widget
      window.Trengo = window.Trengo || {};
      const widgetKeyBeforeFeatureFlag = 'Y9wHe9diqK7zjWlRzKWm';
      const isFeatureFlagEnabled = useFeatureFlagStore().isEnabled(FEATURE_FLAG_ACTIVATION.TA_TIERED_SUPPORT_WIDGET);
      window.Trengo.key = !isFeatureFlagEnabled ? widgetKeyBeforeFeatureFlag : this.getWidgetKey();
      (function (d, script) {
        script = d.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://cdn.widget.trengo.eu/embed.js';
        d.getElementsByTagName('head')[0].appendChild(script);
      })(document);

      window.Trengo.contact_data = {
        email: this.$root.user.email,
        name: this.$root.user.first_name + ' ' + this.$root.user.last_name,
        custom_fields: [
          {
            field_id: 209,
            value: window.AGENCY.name,
          },
          {
            field_id: 101575,
            value: this.$root.user.locale_code,
          },
          {
            field_id: 3919,
            value: (window.AGENCY.data || {}).team_size || '',
          },
          {
            field_id: 5656,
            value: ((window.AGENCY.data || {}).interests || []).join(', '),
          },
        ],
      };
      this.widgetIsLoaded = true;
    },
    getWidgetKey() {
      const widgetKeyPerTier = Object.freeze({
        [PLAN.TIER_0]: 'xwnP6xCSmuZIBXa6R8Vr',
        [PLAN.TIER_1]: 'ItjyhhTIJVGsXZENWKWg',
        [PLAN.TIER_2]: '3zU7IjbNp25TMBV0lxY9',
        [PLAN.TIER_3]: 'mt01sTp7JbbuudzYxPOb',
      });

      return widgetKeyPerTier[this.subscriptionPlan];
    },
    shouldShowWidget() {
      return (
        (this.$route.path === '/admin/get-started' ||
          this.$route.path === '/admin' ||
          this.$route.path === '/admin/channels2/wa_business') &&
        !this.$route.path.startsWith('/chat')
      );
    },
    isMobile() {
      return !this.breakpoints.comparisons.gt_md;
    },
    hideInMobile() {
      if (this.isMobile()) {
        const widgetContainerShadow = document.querySelector('.TrengoWidgetLauncher__iframe');
        if (widgetContainerShadow) {
          widgetContainerShadow.style.display = 'none';
        }
      }
    },
    toggleWidget() {
      const webWidget = document.querySelector('#trengo-web-widget');

      if (!webWidget) {
        return;
      }

      // show widget
      if (this.shouldShowWidget()) {
        if (webWidget.classList.contains('hide')) {
          webWidget.classList.remove('hide');
        }
        return;
      }

      // hide widget
      if (!webWidget.classList.contains('hide')) {
        webWidget.classList.add('hide');
      }
    },
  },
};
</script>

<style>
.hide {
  display: none !important;
}

@media (max-width: 768px) {
  #trengo-web-widget .TrengoWidgetLauncher__iframe {
    position: absolute;
    bottom: 56px;
    right: 12px;
  }
}
</style>
