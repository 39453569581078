import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "my-6 flex flex-col flex-nowrap justify-center gap-5 pb-0" }
const _hoisted_2 = { class: "my-8" }
const _hoisted_3 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_stats = _resolveComponent("stats")!
  const _component_average_tickets_created_chart = _resolveComponent("average-tickets-created-chart")!
  const _component_online_status = _resolveComponent("online-status")!
  const _component_reporting_page = _resolveComponent("reporting-page")!

  return (_openBlock(), _createBlock(_component_reporting_page, {
    "heading-title": _ctx.$t('reports_v2.dashboard'),
    "updated-at": _ctx.updatedAt,
    class: "w-full"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_stats, {
          "new-tickets": _ctx.newTickets,
          "assigned-tickets": _ctx.assignedTickets,
          "resolution-time": _ctx.resolutionTime,
          "first-response-time": _ctx.firstResponseTime,
          "is-loaded": _ctx.isLoaded
        }, null, 8 /* PROPS */, ["new-tickets", "assigned-tickets", "resolution-time", "first-response-time", "is-loaded"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_average_tickets_created_chart)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_online_status)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["heading-title", "updated-at"]))
}