<template>
  <div>
    <quote-banner v-if="hasQuote" @open-quote-modal="openQuoteModal" />
    <quote-overview-modal v-if="hasQuote" />
    <scheduled-change-banner v-if="subscription.isScheduledToDowngrade">
      <template #heading>Downgrade scheduled</template>
      <template #subheading>
        Downgrade will take effect from the next billing cycle on {{ subscription.subscriptionDueDate }}
      </template>
    </scheduled-change-banner>
    <scheduled-change-banner v-if="subscription.isScheduledToCancel" with-button>
      <template #heading>Your subscription is cancelled</template>
      <template #subheading>In order to re-activate your account, please contact Trengo support</template>
      <template #buttonLabel>Contact Support</template>
    </scheduled-change-banner>
    <div class="rounded-xl border-1 border-grey-300 bg-white">
      <div class="p-6">
        <div class="flex items-center gap-4 pb-6">
          <t-thumbnail class="bg-white">
            <newspaper-linear size="1rem" />
          </t-thumbnail>
          <div class="flex-grow">
            <div class="t-text-md font-bold text-grey-800">
              You are on the {{ subscription.subscriptionName }} plan
              <span class="t-text-xs font-semibold text-grey-600">
                ({{ capitalize(subscription.subscriptionFrequency) }})
              </span>
            </div>
            <div class="t-text-sm text-grey-700">Thank you for using Trengo</div>
          </div>
        </div>
        <div class="xs:t-text-sm xl:t-text-md grid grid-cols-2 gap-4">
          <div
            v-for="(item, index) in addons"
            :key="index"
            class="flex items-center gap-3 rounded-xl bg-grey-200 p-3 text-grey-800"
          >
            <t-thumbnail class="bg-white" size="small">
              <user-plus-linear v-if="item.type === 'user_seats'" size="1rem" />
              <eye-linear v-else size="1rem" />
            </t-thumbnail>
            {{ item.count }} {{ item.type === 'user_seats' ? 'user' : 'observer' }} seats
          </div>
        </div>
      </div>
      <div class="border-t-1 border-grey-300" />
      <div class="flex flex-col gap-4 p-6">
        <div class="t-text-md font-semibold text-grey-800">
          Billing
          <div v-if="!subscription.isScheduledToCancel" class="t-text-sm font-normal text-grey-600">
            Your next {{ subscription.subscriptionFrequency }} payment of
            <span class="text-grey-800">
              {{ subscriptionDuePrice }} is due
              <span v-if="subscriptionDueDate">on {{ subscriptionDueDate }}.</span>
            </span>
          </div>
        </div>
        <div class="flex gap-4 text-grey-800">
          <button
            class="t-text-sm flex w-[400px] items-center gap-2 rounded-xl border-1 border-grey-300 bg-grey-100 p-3 hover:bg-grey-200 active:bg-grey-300"
            @click="editPaymentMethods"
          >
            <t-thumbnail class="bg-white" size="tiny">
              <credit-card-linear size="1rem" />
            </t-thumbnail>

            <div>Payment details</div>
            <div class="ml-auto">
              <external-link-linear size="20px" />
            </div>
          </button>
          <button
            class="t-text-sm flex w-[400px] items-center gap-2 rounded-xl border-1 border-grey-300 bg-grey-100 p-3 hover:bg-grey-200 active:bg-grey-300"
            @click="editBillingAddress"
          >
            <t-thumbnail class="bg-white" size="tiny">
              <pin-linear />
            </t-thumbnail>
            <span>Billing address</span>
            <div class="ml-auto">
              <external-link-linear size="20px" />
            </div>
          </button>
        </div>
      </div>
      <div class="border-t-1 border-grey-300" />
      <div class="flex justify-between gap-2 p-6">
        <t-button
          v-if="!subscription.isScheduledToCancel"
          id="bb-cancel"
          size="md"
          btn-style="danger-alt"
          @click="cancelSubscription"
        >
          Cancel subscription
        </t-button>
        <div class="flex justify-end gap-3">
          <t-button size="md" btn-style="secondary" @click="showBillingHistory">Billing history</t-button>
          <t-button
            v-if="!subscription.isScheduledToCancel"
            size="md"
            btn-style="primary"
            class="flex items-center"
            @click="handleEdit"
          >
            Edit subscription
            <chevron-right-linear size="1.125rem" class="ml-1 inline" />
          </t-button>
        </div>
      </div>
    </div>
    <quote-interrupt-edit-modal
      :is-open="isInterruptEditModalOpen"
      :due-date="subscription.subscriptionDueDate"
      @view-quote="openQuoteModal"
      @close="closeInterruptEditModal"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  NewspaperLinear,
  EyeLinear,
  CreditCardLinear,
  UserPlusLinear,
  PinLinear,
  ExternalLinkLinear,
  ChevronRightLinear,
} from '@trengo/trengo-icons';
import { capitalize } from 'lodash';
import { computed, onMounted, ref } from 'vue';

import { openChargebeePortal } from '@/api/modules/subscriptionChargebee';
import { useChargebeeDuePayment } from '@/composables/useChargebeeDuePayment';
import { useQueryParameterOpenState } from '@/composables/useQueryParameterOpenState';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import { useSubscriptionStore, useUserStore } from '@/store/pinia';
import { segmentTrackEvent } from '@/util/analytics/segmentAnalytics';

import QuoteBanner from './QuoteBanner.vue';
import QuoteInterruptEditModal from './QuoteInterruptEditModal.vue';
import QuoteOverviewModal from './QuoteOverviewModal.vue';
import ScheduledChangeBanner from './ScheduledChangeBanner.vue';
import { SEGMENT_TRACKING_DETAILS } from '../constants';

import type { SubscriptionAddonSeatBased } from '@/types';

const { subscription } = useSubscriptionStore();
const userStore = useUserStore();
const { subscriptionDuePrice, subscriptionDueDate } = useChargebeeDuePayment();

const brightAppId = window.BRIGHT_APP_ID;

const cbInstance = window.Chargebee.init({
  site: window.CHARGEBEE_SITE,
  publishableKey: window.CHARGEBEE_PUBLISHABLE_KEY,
});
const cbPortal = cbInstance.createChargebeePortal();
const isInterruptEditModalOpen = ref(false);

const hasQuote = computed(() => Boolean(subscription.quote));
const addons = computed(() => subscription.addons as SubscriptionAddonSeatBased[]);

const initPortal = () => {
  cbInstance.setPortalSession(() => {
    return openChargebeePortal().then((response) => response.data);
  });
};

const editPaymentMethods = () => {
  cbPortal.openSection({
    sectionType: window.Chargebee.getPortalSections().PAYMENT_SOURCES,
  });
};

const handleSegmentTracking = (event: keyof typeof SEGMENT_TRACKING_DETAILS) => {
  segmentTrackEvent({
    subscription_id: subscription.subscriptionId,
    ...SEGMENT_TRACKING_DETAILS[event],
  });
};

const showBillingHistory = () => {
  cbPortal.openSection({
    sectionType: window.Chargebee.getPortalSections().BILLING_HISTORY,
  });

  handleSegmentTracking('BILLING_HISTORY');
};

const editBillingAddress = () => {
  cbPortal.openSection({
    sectionType: window.Chargebee.getPortalSections().ADDRESS,
  });
};

const cancelSubscription = () => {
  if (!window.Brightback) {
    return false;
  }
  // FIXME: Refactor this with routes store
  const currentPage = window.location.origin + window.location.pathname;

  window.Brightback.handleDataPromise({
    app_id: brightAppId,
    email: userStore.user?.email,
    subscription_id: subscription.subscriptionId,
    save_return_url: currentPage,
    cancel_confirmation_url: currentPage + '?cancelled',
  }).then((result: any) => {
    if (result.valid) {
      handleSegmentTracking('CANCEL_BEGIN');
      window.location = result.url;
    }
  });
};

const { handleOpen } = useQueryParameterOpenState('quoteOverview');
const openQuoteModal = () => {
  closeInterruptEditModal();
  handleOpen();
};

const closeInterruptEditModal = () => (isInterruptEditModalOpen.value = false);

const handleEdit = () => {
  if (hasQuote.value) {
    isInterruptEditModalOpen.value = true;
  } else {
    handleSegmentTracking('EDIT');
    router.push(`${SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS}/${subscription.subscriptionId}`);
  }
};

onMounted(() => {
  initPortal();

  // FIXME: Refactor this with routes store
  if (window.location.search.includes('cancelled')) {
    handleSegmentTracking('CANCELLED');
  }
});
</script>
