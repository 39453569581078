<template>
  <div class="users-list-main" :class="{ desktop: !isMobile, mobile: isMobile }">
    <t-table-list class="table-fixed" :has-border="false">
      <t-table-list-row v-if="!isMobile" head class="table-headers t-text-desktop-label-sm text-grey-650">
        <t-table-list-head type="round">{{ $t('user_management.name') }}</t-table-list-head>
        <t-table-list-head>{{ $t('user_management.teams') }}</t-table-list-head>
        <t-table-list-head class="pl-2">{{ $t('user_management.user_role') }}</t-table-list-head>
        <t-table-list-head type="round"></t-table-list-head>
      </t-table-list-row>
      <t-table-list-row v-else head class="t-text-desktop-label-sm text-grey-700">
        <t-table-list-head>
          <div class="t-text-desktop-label-sm flex items-center text-grey-700">{{ $t('user_management.name') }}</div>
        </t-table-list-head>
      </t-table-list-row>
      <users-list-item
        v-for="item in sortedList"
        :key="item.id"
        :user="item"
        :is-mobile="isMobile"
        @click="clickListItem(item)"
        @openModal="openEditUserModal"
        @resendInvite="resendInvite"
        @delete="deleteUser"
      />

      <template v-if="loading">
        <users-list-loader :is-mobile="isMobile" />
        <users-list-loader :is-mobile="isMobile" />
      </template>
      <infinite-loading :identifier="infiniteLoadingResetCounter" @infinite="fetchData">
        <template #spinner>
          <div></div>
        </template>
        <template #no-more>
          <div></div>
        </template>
        <template #no-results>
          <div></div>
        </template>
        <template #error>
          <div>
            <span @click="fetchData">{{ $t('user_management.try_again') }}</span>
          </div>
        </template>
      </infinite-loading>
    </t-table-list>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

import UsersListItem from '../UsersListItem';
import UsersListLoader from '../UsersListLoader';

export default {
  name: 'UsersListMain',
  emits: ['delete', 'infinite-scroll', 'resendInvite', 'openModal', 'clickListItem'],
  components: {
    UsersListItem,
    InfiniteLoading,
    UsersListLoader,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    infiniteLoadingResetCounter: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    sortedList() {
      const list = [...this.list];
      return list.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    deleteUser(user) {
      this.$emit('delete', user);
    },
    fetchData(scroll) {
      this.$emit('infinite-scroll', scroll);
    },
    resendInvite(userId) {
      this.$emit('resendInvite', userId);
    },
    openEditUserModal(user) {
      this.$emit('openModal', user);
    },
    clickListItem(item) {
      if (this.isMobile) {
        this.$emit('clickListItem', item);
      }
    },
  },
};
</script>

<style scoped lang="scss" src="./UsersListMain.scss" />
