import { request } from '@/util/request';

import type { User, Channel, Team } from '@/types/common';
import type { AxiosPromise } from 'axios';

const base_url = '/api/v2/teams/';
const team_url = '/api/v2/team/';
const user_config_url = '/api/v2/user_config/show_upgrade_tip_for_workload_balancing';
const all_conversations_url = '/api/v2/team/retroactive_balance';

type TeamResponse = {
  readonly id: number;
  name: string;
  channels: Channel[];
  users: User[];
  load_balancing_enabled?: boolean;
  consider_user_availability?: boolean;
};

export function getTeamRequest(id: string | number): AxiosPromise<TeamResponse> {
  return request(`${base_url}${id}`, 'GET');
}

export function deleteTeamRequest(id: string | number) {
  return request(`${base_url}${id}`, 'DELETE');
}

export function deleteTeamMemberRequest(id: string | number, team_id: string | number) {
  return request(`${team_url}${team_id}/users/${id}`, 'DELETE');
}

export function deleteTeamChannelRequest(id: string | number, team_id: string | number) {
  return request(`${team_url}${team_id}/channels/${id}`, 'DELETE');
}

export function storeTeamRequest(data: Team) {
  return request(base_url, 'POST', data);
}

export function updateTeamRequestNewAPI(id: string | number, data: Team) {
  return request(`${team_url}${id}`, 'PUT', data);
}

export function setUserConfigUpgradeTipWLB(data: { value: unknown[] }) {
  return request(user_config_url, 'PUT', data);
}

export function getUserConfigUpgradeTipWLB() {
  return request(user_config_url, 'GET');
}

export function setAllConversationsEnable() {
  return request(all_conversations_url, 'POST');
}
