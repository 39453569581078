import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_image3_linear = _resolveComponent("image3-linear")!
  const _component_file_linear = _resolveComponent("file-linear")!
  const _component_video_camera_linear = _resolveComponent("video-camera-linear")!
  const _component_volume_high_linear = _resolveComponent("volume-high-linear")!
  const _component_paperclip_linear = _resolveComponent("paperclip-linear")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.fileType === 'image')
      ? (_openBlock(), _createBlock(_component_image3_linear, {
          key: 0,
          size: "1.2rem",
          "data-test": "fileTypeImage"
        }))
      : (_ctx.fileType === 'document')
        ? (_openBlock(), _createBlock(_component_file_linear, {
            key: 1,
            size: "1.2rem",
            "data-test": "fileTypeDocument"
          }))
        : (_ctx.fileType === 'video')
          ? (_openBlock(), _createBlock(_component_video_camera_linear, {
              key: 2,
              size: "1.2rem",
              "data-test": "fileTypeVideo"
            }))
          : (_ctx.fileType === 'audio')
            ? (_openBlock(), _createBlock(_component_volume_high_linear, {
                key: 3,
                size: "1.2rem",
                "data-test": "fileTypeAudio"
              }))
            : (_openBlock(), _createBlock(_component_paperclip_linear, {
                key: 4,
                size: "1.2rem",
                "data-test": "fileTypeOther"
              }))
  ]))
}