<template>
  <div class="relative h-88">
    <canvas ref="report" height="200" class="h-full"></canvas>

    <error-status
      v-if="showError"
      icon-name="ChartColumnLinear"
      :title="$t('reports_v2.no_data_available')"
      :sub-title="$t('reports_v2.api_failed')"
      classes="h-[calc(100%-100px)] w-[calc(100%-64px)]
      min-[1500px]:h-[calc(100%-30.66px)]
      min-[1400px]:h-[calc(100%-30.66px)]
      min-[1378px]:h-[calc(100%-31px)]
      min-[1350px]:h-[calc(100%-45px)]
      min-[1250px]:h-[calc(100%-46px)]
      min-[1200px]:h-[calc(100%-47px)]
      min-[1100px]:h-[calc(100%-48px)]
      min-[900px]:h-[calc(100%-45px)]"
    />
  </div>
</template>

<script lang="ts">
import Chart from 'chart.js/auto';
import { map } from 'lodash';
import { defineComponent } from 'vue';

import ErrorStatus from '@/components/Reporting/Components/V2/ErrorStatus.vue';

import { getDayOfWeek } from '../../utilities';
import { getAverageConversationsChartConfiguration } from '../utilities';

import type { WorkloadManagementBucket } from '@/components/Reporting/api/types';
import type { ChartConfiguration, ChartData } from 'chart.js/auto';

type Data = {
  chartInstance?: ChartConfiguration;
  data?: ChartData;
};

export default defineComponent({
  name: 'DailyAverageConversationsCreatedLoaded',
  components: { ErrorStatus },

  props: {
    createdConversations: {
      type: Array,
      default: () => [] as WorkloadManagementBucket[],
    },
    showError: {
      type: Boolean,
      default: false,
    },
  },

  chartInstance: undefined,
  data(): Data {
    return {
      data: undefined,
    };
  },

  mounted() {
    this.setChart();
  },

  computed: {
    chartConfiguration() {
      return getAverageConversationsChartConfiguration(
        (key, param) => this.$t(key, param),
        this.showError,
        false,
        false
      );
    },

    datasets() {
      return [
        {
          label: this.$t('reports_v2.daily'),
          data: map(this.createdConversations, (bucket: WorkloadManagementBucket) => {
            return bucket.value;
          }),
          backgroundColor: this.showError ? '#E1E3E5' : '#249888',
          borderColor: this.showError ? '#E1E3E5' : '#20897A',
          hoverBackgroundColor: '#0C5D51',
          hoverBorderColor: '#0B5449',
          borderWidth: 1,
          borderRadius: 4, // Rounded corners
        },
      ];
    },
    mapLabels() {
      const currentDataset = this.createdConversations;
      return map(currentDataset, (value: WorkloadManagementBucket) => {
        return this.dayOfWeek(value.datetime);
      });
    },
  },

  watch: {
    createdConversations: {
      handler() {
        (this.chartInstance.data.labels = this.mapLabels), (this.chartInstance.data.datasets = this.datasets);
        this.chartInstance.update();
      },
    },
  },

  methods: {
    setChart() {
      this.mapData();
      this.drawChart();
    },

    mapData() {
      this.data = {
        labels: this.mapLabels,
        datasets: this.datasets,
      };
    },

    drawChart() {
      const chart = this.$refs.report.getContext('2d');

      const options = {
        onclick: this.handleClickOnBar,
        responsive: true,
        maintainAspectRatio: false,
        scales: this.chartConfiguration.scales,
        plugins: this.chartConfiguration.plugins,
        barThickness: 24,
      };

      this.chartInstance = new Chart(chart, {
        type: 'bar',
        data: this.data,
        options,
      });
    },

    dayOfWeek(timestamp: number) {
      return getDayOfWeek((key) => this.$t(key), timestamp);
    },
  },
});
</script>
