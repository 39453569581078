import { randBoolean, randFutureDate, randNumber, randRecentDate, randSequence } from '@ngneat/falso';
import { addDays, addMonths, addYears } from 'date-fns';
import { capitalize } from 'lodash';

import { PLAN, PLAN_PRICE_ID, SUBSCRIPTION_STATUS } from '@/Configs/Constants';
import { toEpochDate } from '@/util/date';

import type {
  BillingFrequency,
  ChargebeePlan,
  ChargebeeSubscription,
  PlanId,
  PricingModel,
  Quote,
  SubscriptionAddonDetailType,
  SubscriptionItem,
} from '@/types';

export const PLANS_SEAT_BASED: ChargebeePlan[] = [
  {
    type: PLAN.TIER_1,
    title: 'Grow',
    prices: [
      {
        type: 'monthly',
        price: 20,
      },
      {
        type: 'annually',
        price: 16,
      },
    ],
    minimumUsers: 5,
    maximumUsers: 5,
    details: {
      title: 'Includes',
      detailList: ['Unlimited messages', 'Unlimited contacts', '180 days archive', '5 users', '5 channels'],
    },
  },
  {
    type: PLAN.TIER_2,
    title: 'Scale',
    prices: [
      {
        type: 'monthly',
        price: 33,
      },
      {
        type: 'annually',
        price: 27,
      },
    ],
    minimumUsers: 5,
    maximumUsers: 100,
    details: {
      title: 'Everything in Grow, plus',
      detailList: [
        'Unlimited messages',
        'Unlimited contacts',
        'Unlimited days archive',
        '5 - 100 users',
        '50 channels',
      ],
    },
  },
  {
    type: PLAN.TIER_3,
    title: 'Enterprise',
    prices: [
      {
        type: 'monthly',
        price: 45,
      },
      {
        type: 'annually',
        price: 38,
      },
    ],
    minimumUsers: 5,
    details: {
      title: 'Everything in Scale, plus',
      detailList: ['Unlimited users', 'Unlimited channels'],
    },
  },
];

export const PLANS_USAGE_BASED: ChargebeePlan[] = [
  {
    type: PLAN.TIER_1,
    title: 'Essentials',
    description: 'subscription.plan_selector__essentials_description',
    prices: [
      {
        type: 'monthly',
        price: 125,
      },
      {
        type: 'annually',
        price: 99,
      },
    ],
    details: {
      title: 'subscription.plan_selector__essentials_includes',
      detailList: [
        {
          label: 'subscription.plan_selector__essentials_detail_users',
        },
        {
          label: 'subscription.plan_selector__essentials_omnichannel_inbox',
        },
        {
          label: 'subscription.plan_selector__essentials_all_channels',
        },
        {
          label: 'subscription.plan_selector__essentials_web_widget',
        },
        {
          label: 'subscription.plan_selector__essentials_email_support',
        },
      ],
    },
  },
  {
    type: PLAN.TIER_2,
    title: 'Boost',
    description: 'subscription.plan_selector__boost_description',
    prices: [
      {
        type: 'monthly',
        price: 185,
      },
      {
        type: 'annually',
        price: 149,
      },
    ],
    details: {
      title: 'subscription.plan_selector__boost_includes',
      detailList: [
        {
          label: 'subscription.plan_selector__boost_white_labelling',
          tooltip: 'subscription.plan_selector__boost_white_labelling_tooltip',
        },
        {
          label: 'subscription.plan_selector__boost_user_roles',
          tooltip: 'subscription.plan_selector__boost_user_roles_tooltip',
        },
        {
          label: 'subscription.plan_selector__boost_basic_integrations',
        },
        {
          label: 'subscription.plan_selector__boost_reporting',
          tooltip: 'subscription.plan_selector__boost_reporting_tooltip',
        },
        {
          label: 'subscription.plan_selector__boost_rules_and_automations',
          tooltip: 'subscription.plan_selector__boost_rules_and_automations_tooltip',
        },
        {
          label: 'subscription.plan_selector__boost_messaging_support',
        },
      ],
    },
  },
  {
    type: PLAN.TIER_3,
    title: 'Pro',
    description: 'subscription.plan_selector__pro_description',
    prices: [
      {
        type: 'monthly',
        price: 310,
      },
      {
        type: 'annually',
        price: 249,
      },
    ],
    details: {
      title: 'subscription.plan_selector__pro_includes',
      detailList: [
        {
          label: 'subscription.plan_selector__pro_all_integrations',
        },
        {
          label: 'subscription.plan_selector__pro_workload_balancing',
        },
        {
          label: 'subscription.plan_selector__pro_mandatory_2fa',
          tooltip: 'subscription.plan_selector__pro_mandatory_2fa_tooltip',
        },
        {
          label: 'subscription.plan_selector__pro_live_chat_and_phone_support',
        },
      ],
    },
  },
];

export const mockSubscriptionItem = (
  pricingModel: PricingModel,
  billingFrequency: BillingFrequency,
  quantity = 1,
  details:
    | {
        itemType: 'plan';
        planId: PlanId;
      }
    | {
        itemType: 'addon';
        addonType: SubscriptionAddonDetailType;
      },
): SubscriptionItem => {
  let itemPriceId = '';

  const pricingModelPart = pricingModel === 'usage' ? 'usage' : 'seat';
  if (details.itemType === 'plan') {
    const frequencyPart = billingFrequency.toUpperCase() as Uppercase<typeof billingFrequency>;
    const planIdPart = details.planId.toUpperCase() as Uppercase<PlanId>;
    itemPriceId =
      PLAN_PRICE_ID[
        `${pricingModelPart.toUpperCase()}_BASED__${planIdPart}__${frequencyPart}` as keyof typeof PLAN_PRICE_ID
      ];
  } else {
    itemPriceId = `trengo-${pricingModelPart}-${details.addonType}-EUR-${capitalize(billingFrequency)}`;
  }

  const unit_price = randNumber({ min: 20, max: 10000 });
  return {
    item_price_id: itemPriceId,
    item_type: details.itemType,
    quantity,
    unit_price,
    amount: unit_price * quantity,
  };
};

const BASE_SUBSCRIPTION: ChargebeeSubscription = {
  chargebee_subscription_id: randSequence(),
  status: SUBSCRIPTION_STATUS.ACTIVE,
  trial_end: 0,
  trial_start: 0,
  billing_frequency: 'monthly',
  plan: {
    tier: PLAN.TIER_3,
    level: 4,
  },
  next_billing_at: 0,
  current_term_start: 0,
  current_term_end: 0,
  cancelled_at: 0,
  subscription_items: [],
  pricing_model: 'usage',
  quote: undefined,
};

export const mockTrialSubscription = (): ChargebeeSubscription => {
  const trialStart = new Date();

  return {
    ...BASE_SUBSCRIPTION,
    status: SUBSCRIPTION_STATUS.IN_TRIAL,
    trial_start: toEpochDate(trialStart),
    trial_end: toEpochDate(addDays(trialStart, 7)),
    plan: { tier: PLAN.TIER_0, level: 0 },
    subscription_items: [
      {
        item_price_id: PLAN_PRICE_ID.USAGE_BASED__TRIAL,
        item_type: 'plan',
        quantity: 1,
        unit_price: 0,
        amount: 0,
      },
    ],
  };
};

export const mockSubscription = (
  type: PricingModel,
  billingFrequency: BillingFrequency,
  tier: PlanId,
): ChargebeeSubscription => {
  const termStart = randRecentDate();
  const current_term_start = toEpochDate(termStart);
  const current_term_end = toEpochDate(
    billingFrequency === 'monthly' ? addMonths(termStart, 1) : addYears(termStart, 1),
  );

  const subscriptionItem = mockSubscriptionItem(type, billingFrequency, 1, { planId: tier, itemType: 'plan' });

  return {
    ...BASE_SUBSCRIPTION,
    pricing_model: type,
    billing_frequency: billingFrequency,
    current_term_start,
    current_term_end,
    next_billing_at: current_term_end,
    subscription_items: [subscriptionItem],
    plan: { tier, level: 4 },
  };
};

export const mockQuote = (): Quote => {
  const customer_id = randNumber().toString();
  const dueDate = toEpochDate(randFutureDate());
  const pastDate = toEpochDate(randRecentDate());
  const amount = randNumber({ min: 0 });
  const quantity = randNumber({ min: 1, max: 10 });

  return {
    customer_id,
    id: randSequence(),
    name: randSequence(),
    status: 'open',
    valid_till: dueDate,
    date: pastDate,
    sub_total: randNumber(),
    total: randNumber(),
    amount_paid: amount,
    amount_due: amount,
    updated_at: pastDate,
    line_items: [
      {
        id: randSequence(),
        date_from: pastDate,
        date_to: dueDate,
        unit_amount: amount,
        quantity: quantity,
        amount: amount * quantity,
        is_taxed: randBoolean(),
        tax_amount: randNumber(),
        customer_id,
        description: 'Pro',
        entity_type: 'plan_item_price',
        entity_id: 'trengo-usage-tier3-EUR-Yearly',
        metered: false,
        discount_amount: amount * 0.1,
        item_level_discount_amount: amount * 0.1,
      },
    ],
    discounts: [
      {
        entity_type: 'document_level_coupon',
        discount_percentage: 0,
        description: '450,00 € off',
        amount,
        entity_id: randSequence(),
        discount_type: 'fixed_amount',
      },
    ],
    line_item_discounts: [
      {
        object: 'line_item_discount',
        line_item_id: randSequence(),
        discount_type: 'document_level_coupon',
        discount_amount: amount * 0.1,
        coupon_id: randSequence(),
        entity_id: randSequence(),
      },
    ],
    taxes: [
      {
        object: 'tax',
        name: 'VAT',
        description: 'VAT @ 21 %',
        amount: amount * 0.21,
      },
    ],
    line_item_taxes: [
      {
        tax_name: 'VAT',
        tax_rate: 21,
        tax_juris_type: 'country',
        tax_juris_name: 'Netherlands',
        tax_juris_code: 'NL',
        object: 'line_item_tax',
        line_item_id: '198QBuUABpMxFxdF',
        tax_amount: amount * 0.21,
        is_partial_tax_applied: randBoolean(),
        taxable_amount: amount * 0.21,
        is_non_compliance_tax: false,
      },
    ],
    currency_code: 'EUR',
  };
};
