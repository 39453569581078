import { USER_ROLE } from '@/Configs/Constants/UserRoles';
import eventBus from '@/eventBus';
import VuexStore from '@/store';
import PusherHelper from '@/util/PusherHelper';
export default {
  // subscribe to update our store on user updates
  bindInternalChatUserEvents() {
    // user updated
    PusherHelper.bindPusherEvent(this.agencyChannel, 'user-updated', (user) => {
      let userModel = VuexStore.commit('usersInternalChat/updateUser', { id: user.id, user: user });
      eventBus.$emit('user-updated', userModel);
    });

    // user created
    PusherHelper.bindPusherEvent(this.agencyChannel, 'user-created', (user) => {
      let userModel = VuexStore.commit('usersInternalChat/addUser', user);
      eventBus.$emit('user-created', userModel);
    });

    // user deleted
    PusherHelper.bindPusherEvent(this.agencyChannel, 'user-deleted', (id) => {
      VuexStore.commit('usersInternalChat/removeUser', id);
      eventBus.$emit('user-deleted', id);
    });
  },
  bindUserEvents() {
    // user permissions changed
    PusherHelper.bindPusherEvent(this.agencyChannel, 'role-permissions-updated', (data) => {
      eventBus.$emit('role-permissions-updated', data);
    });

    // user role changed
    PusherHelper.bindPusherEvent(this.userChannel, 'user-role-updated', (data) => {
      eventBus.$emit('user-role-updated', data);
    });
  },

  async initUserStore() {
    VuexStore.dispatch('usersInternalChat/setUsers', this.users);
    VuexStore.dispatch('usersInternalChat/setCurrentUser', this.user);
    this.bindInternalChatUserEvents();
    this.bindUserEvents();
    eventBus.$emit('user-loaded', this.user);
  },
  /**
   * @deprecated
   */
  filterOutObservers(users = []) {
    const usersDataSet = users.length ? users : this.$root.users;
    return usersDataSet.filter((user) => user.role.name !== USER_ROLE.OBSERVER);
  },
  /**
   * @deprecated use Vuex getter `users/getBillableUsers`
   */
  getBillableUsers() {
    return this.filterOutObservers();
  },

  /**
   * @deprecated use Vuex getter `users/getAssignableUsers`
   */
  getAssignableUsers(users = []) {
    return this.filterOutObservers(users);
  },
};
