import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e642c39e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "header-text" }
const _hoisted_3 = {
  class: "flex flex-row items-center",
  "data-test": "chatter-status-wrapper"
}
const _hoisted_4 = {
  key: 0,
  class: "m-0 p-0 capitalize",
  "data-test": "chatter-status-text"
}
const _hoisted_5 = {
  key: 1,
  class: "m-0 p-0 capitalize",
  "data-test": "chatter-status-text"
}
const _hoisted_6 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_7 = {
  key: 0,
  class: "header-text",
  "data-test": "chatter-country"
}
const _hoisted_8 = {
  key: 1,
  class: "flex flex-row"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 1,
  class: "header-text"
}
const _hoisted_11 = {
  key: 2,
  class: "m-0 p-0",
  "data-test": "chatter-browser"
}
const _hoisted_12 = {
  key: 3,
  class: "header-text"
}
const _hoisted_13 = {
  key: 4,
  class: "m-0 p-0",
  "data-test": "chatter-os"
}
const _hoisted_14 = {
  key: 5,
  class: "header-text"
}
const _hoisted_15 = {
  key: 6,
  class: "m-0 p-0",
  "data-test": "chatter-resolution"
}
const _hoisted_16 = {
  key: 7,
  class: "header-text"
}
const _hoisted_17 = {
  key: 8,
  class: "m-0 p-0",
  "data-test": "chatter-remote-address"
}
const _hoisted_18 = {
  key: 9,
  class: "header-text"
}
const _hoisted_19 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar_dropdown = _resolveComponent("sidebar-dropdown")!

  return (_openBlock(), _createBlock(_component_sidebar_dropdown, {
    title: _ctx.$tc('ticket_sidebar.chat_tech'),
    "collapse-key": "visitorDetailsDropdown",
    "data-test": "visitor-details-dropdown-wrapper"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('ticket_sidebar.chatter_status')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", {
            class: _normalizeClass(["mr-2 mt-0.5 h-2 w-2 select-none rounded-full", _ctx.online ? 'bg-green-dark' : 'bg-red-dark-matte']),
            "data-test": "chatter-status-icon"
          }, null, 2 /* CLASS */),
          (_ctx.online)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('ticket_sidebar.online')), 1 /* TEXT */))
            : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('ticket_sidebar.offline')), 1 /* TEXT */))
        ]),
        (_ctx.hasChatInfo && !_ctx.isAnonymousModuleEnabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_ctx.chatInfo.country)
                ? (_openBlock(), _createElementBlock("h4", _hoisted_7, _toDisplayString(_ctx.$tc('ticket_sidebar.chat_tech_country')), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              (_ctx.chatInfo.country)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("img", {
                      class: "mr-2",
                      src: `https://flagsapi.com/${_ctx.chatInfo.country}/flat/64.png`,
                      width: "24",
                      alt: ""
                    }, null, 8 /* PROPS */, _hoisted_9),
                    _createTextVNode(" " + _toDisplayString(_ctx.countries[_ctx.chatInfo.country]), 1 /* TEXT */)
                  ]))
                : _createCommentVNode("v-if", true)
            ]))
          : _createCommentVNode("v-if", true),
        (_ctx.chatInfo.browser)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_10, _toDisplayString(_ctx.$tc('ticket_sidebar.chat_tech_browser')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.chatInfo.browser)
          ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.chatInfo.browser), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.chatInfo.os)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_12, _toDisplayString(_ctx.$tc('ticket_sidebar.chat_tech_os')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.chatInfo.os)
          ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.chatInfo.os), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.chatInfo.resolution)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_14, _toDisplayString(_ctx.$tc('ticket_sidebar.chat_tech_screen_resolution')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.chatInfo.resolution)
          ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.chatInfo.resolution), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.chatInfo.remote_address)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_16, _toDisplayString(_ctx.$tc('ticket_sidebar.ip_address')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.chatInfo.remote_address)
          ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(_ctx.chatInfo.remote_address), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.chatInfo.domain)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_18, _toDisplayString(_ctx.$tc('ticket_sidebar.website')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.chatInfo.domain)
          ? (_openBlock(), _createElementBlock("a", {
              key: 10,
              href: _ctx.sanitizeUrl(_ctx.chatInfo.domain),
              target: "_blank",
              rel: "noreferrer",
              class: "w-fit underline",
              "data-test": "chatter-website"
            }, _toDisplayString(_ctx.chatInfo.domain.replace('https://', '').replace('http://', '')), 9 /* TEXT, PROPS */, _hoisted_19))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]))
}