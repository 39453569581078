<template>
  <t-modal
    v-model="computedValue"
    :title="title"
    :subheader="subTitle"
    :initial-min-height="initialMinHeight"
    v-bind="$attrs"
    @close="handleClose"
  >
    <slot name="content"></slot>

    <template #footer>
      <slot name="footer-left"></slot>
      <t-button btn-style="secondary" data-test="cancel-button" @click="handleClose">
        {{ cancelButtonText }}
      </t-button>
      <t-button
        v-if="submitButtonText"
        :disabled="disabledSubmitButton"
        data-test="submit-button"
        @click="$emit('submit')"
      >
        {{ submitButtonText }}
        <slot name="button-spinner" />
      </t-button>
    </template>
  </t-modal>
</template>

<script>
import eventBus from '@/eventBus';
export default {
  name: 'ModalBase',
  emits: ['update:modelValue', 'close', 'submit', 'close'],
  props: {
    submitButtonText: {
      type: String,
      default: '',
    },
    cancelButtonText: {
      type: String,
      default: 'Close',
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabledSubmitButton: {
      type: Boolean,
      default: false,
    },
    isClosable: {
      type: Boolean,
      default: true,
    },
    initialMinHeight: {
      type: [String, Number],
      default: 0,
    },
  },
  mounted() {
    eventBus.$on('close-overview-modal', () => {
      this.computedValue = false;
      this.handleClose();
    });
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
        this.$emit('close');
      },
    },
  },
  methods: {
    handleClose() {
      if (this.isClosable) {
        this.computedValue = false;
      }
      this.$emit('close');
    },
  },
};
</script>
