<template>
  <span ref="v" class="flex w-full select-none flex-row flex-wrap">
    <span
      v-if="!remote"
      class="w-100 flex min-w-0"
      style="pointer-events: all"
      :class="{ 'rounded-lg border': beautify }"
      @click="open($event)"
    >
      <slot name="toggle" :label="selectedLabel" :selected="current">
        <div
          v-if="current"
          class="
            flex
            min-w-0
            select-none
            flex-nowrap
            items-center
            text-ellipsis
            rounded-lg
            bg-grey-200
            leading-none leading-none
            text-grey-600
            hover:text-grey-800
          "
          style="display: inline-flex; vertical-align: middle; padding: 5px 10px"
        >
          <span>{{ selectedLabel }}</span>
          <i v-if="caret" class="material-icons text-md pl-1" style="line-height: 0">expand_more</i>
        </div>
      </slot>
      <i v-if="beautify" class="material-icons text-md pl-1" style="line-height: 0">expand_more</i>
    </span>
    <div
      v-for="(current, i) in computedCurrents"
      :key="i"
      v-click-away="deselectValue"
      class="mr-1 cursor-pointer rounded-lg leading-none"
      style="margin: 5px; padding: 5px 10px"
      :class="{
        'bg-grey-600 text-white': current === highlightedValue,
        'bg-grey-200 text-grey-600': current !== highlightedValue,
      }"
      @click="highlightValue(current, $event)"
    >
      <span class="select-text selection:bg-none" data-hj-suppress>{{ current[selectedLabelField] }}</span>
      <!--<span class="pl-1">×</span>-->
    </div>
    <input
      v-if="remote"
      ref="search"
      v-model="term"
      data-hj-suppress
      class="p-0"
      style="padding: 0; padding-left: 5px; width: calc(100% - 5px) !important"
      type="text"
      @focus="onSearchFocus()"
      @keydown="onRemoteSearch"
      @blur="onRemoteQueryBlur"
    />

    <portal to="body-portal">
      <div v-if="isOpen" v-show="!remote" class="dropdown-overlay nav-bg" @click="close()"></div>
      <transition name="scale-fade">
        <div
          v-if="isOpen"
          :id="id"
          ref="dropdown"
          class="dropdown-container align-items min-w-0 select-none flex-col"
          :class="{ 'dropdown-container-mobile-fullscreen': !remote, 'dropdown-auto-top': autoHeight }"
          style="pointer-events: all"
          :style="{ width: width, maxWidth: maxWidth, minWidth: minWidth }"
        >
          <div v-show="!remote" class="close-dropdown" @click="close">
            <i class="material-icons text-grey-500">close</i>
          </div>
          <slot name="heading"></slot>
          <div v-if="!remote && search" class="m-4 rounded-lg border-2 border-grey-200 bg-white p-2 leading-none">
            <input
              ref="search"
              v-model="term"
              type="text"
              :placeholder="$t('general.search')"
              @keydown="handleKeyboardEvent($event)"
            />
          </div>

          <div
            v-if="static"
            ref="staticBody"
            class="dropdown-options flex flex-col"
            :style="autoHeight ? '' : { maxHeight: maxHeight, minHeight: minHeight }"
            :class="{ 'scroll-on-hover': scrollOnHover }"
            @click="onStaticClick"
          >
            <slot name="body"></slot>
          </div>
          <ul
            v-if="filteredResults(options).length"
            class="dropdown-options m-0 flex-1 select-none p-0"
            :class="{ '-mt-3': search }"
            :style="{ maxHeight: maxHeight, overflowY: overflowY }"
          >
            <slot name="options">
              <li
                v-for="option in filteredResults(options)"
                :key="option.id"
                class="align-items b-b m-0 flex border-grey-500 p-3 px-3"
                :class="[option.itemClass, { selected: selected == option && hover, active: option.id == current.id }]"
                @click="click(option, $event)"
                @mouseover="selected = option"
              >
                <slot name="option" :option="option">
                  <span class="text">
                    <i v-if="option.icon" class="material-icons option-icon" style="margin-top: -3px">
                      {{ option.icon }}
                    </i>
                    {{ option[label] }}
                  </span>
                </slot>
              </li>
              <template v-if="!!$slots.afterList">
                <li class="align-items b-b m-0 flex border-grey-500 p-3 px-3">
                  <slot name="afterList"></slot>
                </li>
              </template>
            </slot>
          </ul>
          <slot v-if="!remote && term != '' && !filteredResults(options).length" name="no-results">
            <div class="border-t-2 border-grey-200 p-4 text-grey-600">No results found for "{{ term }}"</div>
          </slot>
        </div>
      </transition>
    </portal>
  </span>
</template>

<script type="text/babel">
import { mixin as VueClickAway } from 'vue3-click-away';

import { randomString } from '@/util/stringHelpers';

export default {
  name: 'Dropdown',
  emits: ['update:modelValue', 'selected', 'open', 'search', 'count', 'inputEnter'],
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    autoHeight: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
      default: () => {
        return {};
      },
    },
    minHeight: {
      type: String,
      default: '100px',
    },
    maxHeight: {
      type: String,
      default: '231px',
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    remote: {
      type: Boolean,
      default: false,
    },
    caret: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: 'text',
    },
    overflowY: {
      type: String,
      default: 'scroll',
    },
    selectedLabelField: {
      type: String,
      default: 'text',
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    'active-state': {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '350px',
    },
    maxWidth: {
      type: String,
      default: 'auto',
    },
    minWidth: {
      type: String,
      default: 'auto',
    },
    visibleItems: {
      type: Number,
      default: 4,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    emailFormat: {
      type: Boolean,
      default: false,
    },
    closeOnClick: {
      type: Boolean,
      default: false,
    },
    maxItems: {
      type: Number,
      default: 20,
    },
    static: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: true,
    },
    beautify: {
      type: Boolean,
      default: false,
    },
    scrollOnHover: {
      type: Boolean,
      default: true,
    },
    hover: {
      type: Boolean,
      default: true,
    },
    createOnNoResults: {
      type: Boolean,
      default: false,
    },
    asMultiSelect: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [VueClickAway],

  data() {
    return {
      isOpen: false,
      term: '',
      current: this.modelValue,
      resultSelected: false,
      selected: null,
      inited: false,
      currents: [],
      highlightedValue: null,
      id: '',
      dropdown: null,
    };
  },

  computed: {
    selectedLabel() {
      if (this.current[this.selectedLabelField]) {
        return this.current[this.selectedLabelField];
      }
    },
    computedCurrents() {
      return this.remote ? this.currents : [];
    },
  },

  watch: {
    highlightedValue() {
      if (this.highlightedValue) {
        this.mountKeyboard();
      } else {
        this.unmountKeyboard();
      }
    },

    modelValue() {
      this.current = this.modelValue;
      this.currents = this.modelValue;
    },

    term() {
      this.doSearch();
    },

    options() {
      if (this.remote) {
        this.inited = true;
      }
      this.$nextTick(() => {
        if (this.remote) {
          if (this.dropdown && this.dropdown.querySelector('ul')) {
            this.dropdown.querySelector('ul').scrollTop = 0;
          }
          this.selected = this.options[0];
        }
      });

      if (this.options.length > 0) {
        this.mountKeyboard();
      } else {
        this.unmountKeyboard();
      }
    },
  },

  mounted() {
    this.id = randomString(20);
    this.selected = this.options[0];
  },

  beforeUnmount() {
    this.unmountKeyboard();
  },

  methods: {
    onStaticClick() {
      if (this.closeOnSelect) {
        this.close();
      }
    },
    doSearch() {
      this.$emit('search', this.term);

      if (this.term !== '' && !this.remote) {
        this.$emit('count', this.filteredResults().length);
      }

      if (this.term == '' && this.remote) {
        this.close();
        return;
      }

      if (this.remote && !this.isOpen) {
        this.open({ target: this.$refs.search });
      }

      this.$nextTick(() => {
        if (!this.remote) {
          // this.dropdown.querySelector('ul').scrollTop = 0;
          this.selected = this.filteredResults()[0];
        }
      });
    },

    onSearchFocus() {
      if (this.term == '') {
        return;
      }

      this.doSearch();
    },

    mountKeyboard() {
      this.unmountKeyboard();
      document.addEventListener('keydown', this.handleKeyboardEvent);
    },

    unmountKeyboard() {
      document.removeEventListener('keydown', this.handleKeyboardEvent);
    },

    handleKeyboardEvent(e) {
      if (!this.dropdown) {
        this.dropdown = document.getElementById(this.id);
      }

      if (
        e.key == 'Backspace' ||
        e.key == 'Delete' ||
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 65 && e.keyCode <= 90)
      ) {
        if (e.metaKey || e.ctrlKey || e.shiftKey) {
          return;
        }
        if (this.highlightedValue != null) {
          if (this.currents.length > 1) {
            this.currents.splice(this.currents.indexOf(this.highlightedValue), 1);
          } else {
            this.currents = [];
          }
          this.highlightedValue = null;
          this.$refs.search.focus();
          this.$emit('update:modelValue', this.currents);
        }
      }

      if (e.key == 'ArrowDown') {
        if (this.options.length > 0 && this.selected != null) {
          let current = this.options.indexOf(this.selected);
          if (this.options[current + 1]) {
            this.selected = this.options[current + 1];
            let pointerPosition = this.options.indexOf(this.selected) * this.itemHeight();

            if (
              this.dropdown &&
              this.dropdown.querySelector('ul').scrollTop <=
                pointerPosition - (this.visibleItems - 1) * this.itemHeight()
            ) {
              this.dropdown.querySelector('ul').scrollTop =
                pointerPosition - (this.visibleItems - 1) * this.itemHeight();
            }
          }
        }
      }

      if (e.key == 'ArrowUp') {
        if (this.options.length > 0 && this.selected != null) {
          let current = this.options.indexOf(this.selected);
          if (this.options[current - 1]) {
            this.selected = this.options[current - 1];
            let pointerPosition = this.options.indexOf(this.selected) * this.itemHeight();
            if (this.dropdown && this.dropdown.querySelector('ul').scrollTop >= pointerPosition) {
              this.dropdown.querySelector('ul').scrollTop = pointerPosition;
            }
          }
        }
      }

      if (e.key == 'Enter') {
        if (this.isOpen) {
          if (this.selected != null) {
            this.click(this.selected);
          } else if (!this.selected && this.term !== '' && !this.remote && this.createOnNoResults) {
            this.$emit('inputEnter', this.term);
            this.term = '';
          }
        }
      }

      if (e.key == 'Escape') {
        if (this.isOpen) {
          this.close();
        }
      }
    },

    highlightValue(item, e) {
      this.selectedNode = e.target;

      this.$nextTick(() => {
        this.highlightedValue = item;
        window.getSelection().selectAllChildren(e.target);
      });
    },

    deselectValue(e) {
      // clickaway doesn't trigger correctly because vue-portal is used.
      // See: https://github.com/simplesmiler/vue-clickaway/issues/9
      if (e.target === this.selectedNode) {
        return;
      }
      this.highlightedValue = null;
      this.selectedNode = null;
    },

    onRemoteSearch(e) {
      if (e.key == ',' || e.key == ' ' || e.key == ';' || e.key == 'Enter') {
        if (this.term != '' && this.multiple && this.validateEmail(this.term)) {
          e.preventDefault();
          this.click({
            text: this.term,
            identifier: this.term,
            //id: this.term
          });
        }
      }

      if (e.key == 'Backspace') {
        if (this.remote && this.term == '') {
          this.highlightedValue = this.currents[this.currents.length - 1];
          //this.$refs.search.blur();
          return;
        }
      }
    },

    onRemoteQueryBlur() {
      if (this.multiple && this.options.length == 0) {
        if (this.term != '' && this.validateEmail(this.term)) {
          this.click({
            text: this.term,
            identifier: this.term,
          });
          this.close();
        }
        return;
      }

      if (this.term != '' && this.options.length == 0) {
        this.click({
          text: this.term,
          identifier: this.term,
          //id: this.term
        });
      }

      if (this.term != '' && !this.validateEmail(this.term)) {
        setTimeout(this.close, 200); // Quick fix for closing when using tab while still make the item's clickable
      }
    },

    itemHeight() {
      if (!this.dropdown || !this.dropdown.querySelector('li')) {
        return;
      }
      return this.dropdown.querySelector('li').offsetHeight;
    },

    open(e) {
      this.$emit('open');

      if (!this.remote) {
        this.$emit('count', this.filteredResults().length);
      }

      if (!this.isOpen) {
        setTimeout(() => {
          this.inited = true;
        }, 0);
      }

      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        // Duplicate nextTick because Vue Portal
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.dropdown = document.getElementById(this.id);
            this.dropdown.style.display = 'flex';

            let width = this.width;

            if (width === 'auto') {
              width = this.dropdown.clientWidth;
            } else {
              width = this.width.replace('px', '') - 10;
            }

            //let pos = cumulativeElOffset(e.target); // Creates variable position depending where you click..
            // Solution below, might require further testing
            let v = this.$refs.v;
            let rect = v.getBoundingClientRect();
            let pos = {
              top: rect.top + document.body.scrollTop + v.clientHeight - 10,
              left: rect.left + document.body.scrollLeft,
            };

            let offsetRight = document.body.clientWidth - pos.left - width;
            let offsetBottom = document.body.clientHeight - pos.top - (this.maxHeight.replace('px', '') - 10);

            if (offsetRight < 0) {
              this.dropdown.style.right = '10px';
              this.dropdown.style.left = 'auto';
            } else {
              this.dropdown.style.left = pos.left + 'px';
            }

            if (!this.remote && this.search) {
              this.$nextTick(() => {
                this.$refs.search.focus();
              });
            }

            if (offsetBottom > 0) {
              this.dropdown.style.top = pos.top + 'px';
            } else {
              let height = this.maxHeight.replace('px', '');
              if (parseInt(height) > pos.top && this.dropdown.querySelector('ul')) {
                this.dropdown.style.top = 0;
                this.dropdown.querySelector('ul').style.maxHeight = pos.top - 30 + 'px';
              }
              this.dropdown.style.bottom = window.innerHeight - pos.top + 30 + 'px';
            }

            if (this.placement === 'right') {
              this.dropdown.style.left = pos.left + v.offsetWidth + 'px';
              this.dropdown.style.bottom = window.innerHeight - (pos.top + 10 - v.offsetHeight / 2) + 'px';
            }
          });
        });
      } else {
        this.close();
      }
    },

    close() {
      if (this.inited) {
        this.isOpen = false;
        this.inited = false;
      }
    },

    filteredResults() {
      if (this.remote) {
        return this.options;
      }
      return this.options.filter((record) => {
        var found = false;
        for (var key in record) {
          if (typeof record[key] == 'string' && record[key].toLowerCase().indexOf(this.term.toLowerCase()) != -1) {
            found = true;
          }
        }
        return found;
      });
    },

    click(item, e) {
      if (e && e.target.tagName !== 'LI' && e.target.tagName !== 'SPAN') {
        // return;
      }

      this.highlightedValue = null;

      if (this.remote) {
        if (!this.multiple) {
          this.currents = [];
        }
        this.term = '';

        if (this.currents.length >= this.maxItems) {
          return;
        }

        this.currents.push(item);
        this.$emit('update:modelValue', this.currents);

        if (this.multiple) {
          this.$refs.search.focus();
        }

        return;
      } else {
        this.term = '';
        if (this.activateState) {
          this.current = item;
        }
        if (item) {
          if (this.closeOnSelect) {
            this.close();
          }
          this.$emit('update:modelValue', item);
          this.$emit('selected', item);
        }
      }
    },

    validateEmail: function (email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
};
</script>

<style scoped>
.dropdown-container {
  width: 300px;
  position: fixed;
  z-index: 9999999999;
  background: white;
  border-radius: 10px;
  font-size: 16px;
  margin-top: 20px;
  overflow: hidden;
  border: 1px solid #eaeaea;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.02), 0px 13px 28px rgba(0, 0, 0, 0.04);
}

.dropdown-container ul {
  color: #9b9b9b;
}

.dropdown-overlay {
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: fixed;
  z-index: 9999999900;
}

.close-dropdown {
  display: none;
}

@media only screen and (max-width: 991px) {
  .close-dropdown {
    display: block;
    position: absolute;
    right: 12px;
    top: 13px;
    color: #9b9b9b;
    z-index: 999999;
  }

  .close-dropdown i {
  }

  .dropdown-container {
    width: auto !important;
    left: 15px !important;
    right: 15px !important;
    max-width: none !important;
    min-width: 0 !important;
  }

  .dropdown-container-mobile-fullscreen {
    width: auto !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    max-width: none !important;
    min-width: 0 !important;
    top: 150px !important;
    margin-top: 0;
    border-radius: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: none;
    animation-duration: 0.3s;
  }

  .dropdown-container-mobile-fullscreen.dropdown-auto-top {
    top: auto !important;
  }

  .dropdown-container-mobile-fullscreen .dropdown-options {
    max-height: none !important;
    height: 100%;
  }
}

.input-search {
  position: relative;
  height: 33px;
  margin: 12px 12px 12px;
  border-radius: 6px;
  display: flex;
}

.input-search .fa {
  padding-top: 10px;
  padding-left: 9px;
}

/*.input-search:before {*/
/*content: "";*/
/*height: 26px;*/
/*width: 13px;*/
/*background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zd…UgMCAxIDEgMC05IDQuNSA0LjUgMCAwIDEgMCA5eiIgZmlsbD0iIzlCOUM5RSIvPjwvc3ZnPg==);*/
/*background-repeat: no-repeat;*/
/*position: absolute;*/
/*top: 0;*/
/*left: 7px;*/
/*}*/

input {
  /*display: inline-block;*/
  /*width: 100%;*/
  /*height: 100%;*/
  /*z-index: 1;*/
  /*position: relative;*/
  /*background: transparent;*/
  /*color: #303133;*/
  /*border: 0;*/
  /*border-radius: 6px;*/
  /*font-size: 13px;*/
  /*padding: 0 15px 1px 15px;*/
  /*border: 1px solid rgba(0,0,0,.08);*/
  flex: 1;
  background: none;
  border: none;
  padding-left: 8px;
  font-weight: normal;
}

ul {
  overflow: hidden;
  padding: 12px;
}

li {
  cursor: pointer;
  border-bottom: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  color: #3f3f41 !important;
  font-size: 16px;
  border-radius: 12px;
  padding: 12px 16px;
}

li.selected,
li.active {
  background: #f1f1f1 !important;
  color: #070707;
}

.highlight-value {
  font-weight: 600;
  color: rgb(77, 79, 82);
  white-space: nowrap;
  background: #cfe4fa;
  /* color: white; */
  border-radius: 4px;
  pointer-events: none;
}

.remote-search-selection {
  /*background: #cfe4fa;*/
  /*margin-right: 5px;*/
  /*padding: 0 3px;*/
  /*border-radius: 4px;*/
}

.option-icon {
  width: 1.2rem;
  margin-right: 0.2rem !important;
  text-align: center;
  opacity: 0.7;
  margin-left: 0;
  font-size: 18px;
  vertical-align: middle !important;
}

.selection\:bg-none::selection {
  background-color: transparent;
}
</style>
