import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_modal = _resolveComponent("edit-modal")!

  return (_openBlock(), _createBlock(_component_edit_modal, {
    installation: _ctx.installation,
    onClose: _ctx.closeModal,
    onUpdated: _ctx.updated
  }, null, 8 /* PROPS */, ["installation", "onClose", "onUpdated"]))
}