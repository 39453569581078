/**
 * Converts a time value in seconds to an object containing hours, minutes, and seconds.
 *
 * @param time - The time in seconds to be converted.
 * @returns An object with properties `hours`, `minutes`, and `seconds` representing
 *          the equivalent time in hours, minutes, and seconds, respectively.
 */
export function getHoursMinutesSeconds(time: number) {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  return {
    hours,
    minutes,
    seconds,
  };
}
