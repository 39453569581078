import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-8 flex flex-row items-center" }
const _hoisted_2 = { class: "flex h-44px w-44px items-center justify-center rounded-xl bg-galaxy-600 p-2 sm:h-48px sm:w-48px" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "ml-4 flex flex-col" }
const _hoisted_5 = { class: "t-text-mobile-body-md sm:t-text-button-lg sm:mb-1" }
const _hoisted_6 = { class: "t-text-desktop-body-xs sm:t-text-desktop-body-sm text-grey-800" }
const _hoisted_7 = { class: "mb-4 grid grid-cols-1 gap-4 sm:mb-3 lg:grid-cols-3" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "t-text-button-sm" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "t-text-button-sm" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "t-text-button-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_channel_modal = _resolveComponent("add-channel-modal")!
  const _component_item_button = _resolveComponent("item-button")!
  const _component_gray_card = _resolveComponent("gray-card")!

  return (_openBlock(), _createBlock(_component_gray_card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "w-24",
            src: `${_ctx.$root.assetsURL}img/comment_filled_white.svg`
          }, null, 8 /* PROPS */, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('getting_started.title_add_a_channel')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('getting_started.connect_with_your_customers')), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_add_channel_modal, {
          modelValue: _ctx.openAddChannel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openAddChannel) = $event))
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode(_component_item_button, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleChooseChannel({ type: 'whatsapp', id: 'wa_business' })))
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: `${_ctx.$root.assetsURL}img/whatsapp.svg`,
              class: "w-24px"
            }, null, 8 /* PROPS */, _hoisted_8),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('getting_started.title_whatsapp')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_item_button, {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleChooseChannel({ type: 'email', id: 'email' })))
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: `${_ctx.$root.assetsURL}img/channels_icons/no-bg/email.svg`,
              class: "w-24px"
            }, null, 8 /* PROPS */, _hoisted_10),
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('getting_started.title_email')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_item_button, {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleChooseChannel({ type: 'chat', id: 'chat' })))
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: `${_ctx.$root.assetsURL}img/channels_icons/no-bg/chat.svg`,
              class: "w-24px"
            }, null, 8 /* PROPS */, _hoisted_12),
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('getting_started.title_live_chat')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", {
          class: "t-text-button-md cursor-pointer text-leaf-500 hover:underline",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openAddChannel = true))
        }, _toDisplayString(_ctx.$t('getting_started.button_see_all')), 1 /* TEXT */)
      ])
    ]),
    _: 1 /* STABLE */
  }))
}