<template>
  <div ref="modal" class="modal">
    <div class="modal-centered height-auto">
      <div class="modal-content">
        <div>
          <div class="modal-header">
            <h5 class="modal-title">Profile</h5>
            <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close-btn">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="loading">Loading..</div>

            <div v-else>
              <form @submit.prevent="submit">
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">About:</label>
                  <div class="col-sm-10">
                    <textarea v-model="record.about" class="form-control" required rows="5"></textarea>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">Photo:</label>
                  <div v-if="record.photo && !changePhoto" class="col-sm-10">
                    <img ref="image" width="96" :src="record.photo" />
                    <a class="text-info ml-2" @click="changePhoto = true">Update photo</a>
                  </div>
                  <div v-else class="col-sm-10">
                    <input type="file" class="form-control mb-2" accept="image/*" @change="onFileChange" />
                    <a v-if="record.photo" class="text-info" @click="changePhoto = false">Cancel</a>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">Address:</label>
                  <div class="col-sm-10">
                    <input v-model="record.profile.address" type="text" required class="form-control" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">Business Description:</label>
                  <div class="col-sm-10">
                    <input v-model="record.profile.description" type="text" required class="form-control" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">Vertical:</label>
                  <div class="col-sm-10">
                    <select v-model="record.profile.vertical" required class="form-control">
                      <option v-for="v in verticals" :value="v" v-text="v"></option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">Email:</label>
                  <div class="col-sm-10">
                    <input v-model="record.profile.email" type="text" required class="form-control" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">Website:</label>
                  <div class="col-sm-10">
                    <input v-model="record.profile.website" type="text" required class="form-control" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-10 offset-md-2">
                    <button type="button" class="btn success" :disabled="saving" @click="submit">
                      <span v-if="!saving">Save profile</span>
                      <span v-if="saving"><i class="fa fa-spin fa-spinner"></i></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  props: {
    channel: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      loading: false,
      saving: false,
      record: {
        about: '',
        photo: null,
        profile: {
          address: '',
          email: '',
          vertical: '',
          description: '',
          website: '',
        },
      },
      changePhoto: false,
      profile_image: null,
      verticals: [
        'Automotive',
        'Beauty, Spa and Salon',
        'Clothing and Apparel',
        'Education',
        'Entertainment',
        'Event Planning and Service',
        'Finance and Banking',
        'Food and Grocery',
        'Public Service',
        'Hotel and Lodging',
        'Medical and Health',
        'Non-profit',
        'Professional Services',
        'Shopping and Retail',
        'Travel and Transportation',
        'Restaurant',
        'Other',
      ],
    };
  },

  mounted() {
    this.loadProfile();
  },

  methods: {
    loadProfile() {
      this.loading = true;
      axios.get('/api/v2/wa/business_profile/' + this.channel.channel_id).then((res) => {
        this.record = res.data;
        this.loading = false;

        if (res.data.profile.websites) {
          this.record.profile.website = res.data.profile.websites[0];
        }
      });
    },

    submit() {
      if (this.saving) return;
      this.saving = true;

      let data = new FormData();
      if (this.record.about) data.append('about', this.record.about);
      if (this.record.profile.address) data.append('address', this.record.profile.address);
      if (this.record.profile.email) data.append('email', this.record.profile.email);
      if (this.record.profile.description) data.append('description', this.record.profile.description);
      if (this.record.profile.website) data.append('website', this.record.profile.website);
      if (this.record.profile.vertical) data.append('vertical', this.record.profile.vertical);
      if (this.profile_image) data.append('photo', this.profile_image);

      axios
        .post('/api/v2/wa/business_profile/' + this.channel.channel_id, data)
        .then((res) => {
          this.saving = false;
          this.flashSuccess('Profile updated successfully');
        })
        .catch((e) => {
          this.saving = false;
        });
    },

    onFileChange(event) {
      this.profile_image = event.target.files[0];
      let reader = new FileReader();
      this.changePhoto = false;
      this.$nextTick(() => {
        reader.addEventListener(
          'load',
          () => {
            this.$refs.image.src = reader.result;
          },
          false
        );
        reader.readAsDataURL(this.profile_image);
      });
    },
  },
};
</script>
