<template>
  <div class="custom-fields-list min-h-screen w-full bg-white">
    <header class="border-b border-grey-300 bg-white">
      <div class="cf-container">
        <h1 class="title">{{ $t('custom_fields.custom_fields') }}</h1>
        <div class="flex flex-row" v-if="computedTabs">
          <t-tab-item v-for="tab in computedTabs" :key="tab.title" :to="tab.to" :label="tab.title">
            <template v-if="tab.badge" #postfix>
              <t-badge :text="tab.badge" />
            </template>
          </t-tab-item>
        </div>
      </div>
    </header>

    <main class="main">
      <div class="cf-container mr-4">
        <slot />
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'CustomFieldsList',
  props: {
    customUserFields: {
      type: Array,
      default: () => [],
    },
    customProfileFields: {
      type: Array,
      default: () => [],
    },
    customTicketFields: {
      type: Array,
      default: () => [],
    },
    customContactFields: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    computedTabs() {
      return [
        {
          title: this.$t('custom_fields.ticket'),
          badge: this.customTicketFields.length,
          to: '/admin/custom_fields',
        },
        {
          title: this.$t('custom_fields.contacts'),
          badge: this.customContactFields.length,
          to: '/admin/custom_fields/contacts',
        },
        {
          title: this.$t('custom_fields.profiles'),
          badge: this.customProfileFields.length,
          to: '/admin/custom_fields/profiles',
        },
        {
          title: this.$t('custom_fields.users'),
          badge: this.customUserFields.length,
          to: '/admin/custom_fields/users',
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss" src="./CustomFieldsList.scss" />
