<template>
  <div class="onboarding-steps w-full">
    <div class="container text-center">
      <div>
        <div class="box">
          <!-- interests -->
          <div
            v-if="onboardingStepsEnabled && userStore.hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)"
            class="px-4 pt-4"
          >
            <h4 class="pt-4">{{ $t('onboarding.choose_interests') }}</h4>

            <ul class="m-a-0 list-unstyled flex w-full flex-wrap p-0">
              <li v-for="interest in interests" class="w-full sm:w-1/3 lg:w-1/5">
                <div
                  class="interest flex-col justify-between text-center text-white"
                  :style="
                    interest.selected || putInterestsQueue.includes(interest.name)
                      ? 'background: ' + interest.background
                      : ''
                  "
                  :class="{ selected: interest.selected && !putInterestsQueue.includes(interest.name) }"
                  @click="putInterestsDebounced(interest.name)"
                >
                  <i
                    class="fa fa-fw interest-icon mb-2"
                    :class="interest.icon || 'fa-comment-o'"
                    aria-hidden="true"
                  ></i>
                  <span class="text-ellipsis">
                    {{ $t('onboarding.interests_title_' + interest.name) }}
                  </span>
                </div>
              </li>
            </ul>
          </div>

          <!--<div class="box-divider m-a-0"></div>-->

          <!-- steps -->
          <div v-if="steps.length" class="m-4 px-4 pt-4">
            <h4 class="mb-4 pt-4">{{ $t('onboarding.steps_title') }}</h4>

            <div class="progress" :class="{ 'bounce-once': animatedPercentage === 100 }">
              <div
                class="progress-bar"
                role="progressbar"
                :aria-valuenow="percentage"
                aria-valuemin="0"
                aria-valuemax="100"
                :style="'width: ' + percentage + '%;'"
              >
                {{
                  animatedPercentage < 100
                    ? $t('onboarding.steps_percentage_completed', { percentage: animatedPercentage })
                    : $t('onboarding.steps_percentage_done')
                }}
              </div>
              <span v-if="animatedPercentage < 100" class="goal">
                {{ $t('onboarding.steps_percentage_goal') }}
              </span>
            </div>
          </div>

          <div class="box-body relative flex">
            <transition-group name="slide-fade" tag="ul" class="items m-a-0 flex w-full flex-wrap">
              <li
                v-for="step in steps"
                :key="step.type"
                :ref="'step_' + step.type"
                class="w-full overflow-visible sm:w-1/2 lg:w-1/3"
              >
                <span
                  class="pointer step-link flex"
                  :class="{ done: step.completed && !stepIsNew(step.type) }"
                  @click="showDescriptionModal(step)"
                >
                  <div
                    class="m-l-1 m-r-2 icons absolute"
                    :class="{ 'bounce-once': step.completed && stepIsNew(step.type) && stepsShowing[step.type] }"
                  >
                    <i
                      class="fa fa-fw icon-default"
                      :class="$tlateOnboardingStep(step.type, 'icon') || 'fa-circle-o'"
                      aria-hidden="true"
                    ></i>
                    <i class="fa fa-fw icon-done fa-check-circle" aria-hidden="true"></i>
                  </div>
                  <span class="w-full text-ellipsis text-left">
                    {{ $tlateOnboardingStep(step.type, 'title') || step.type }}
                  </span>
                </span>
              </li>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { TweenLite } from 'gsap';
import _ from 'lodash';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import { PERMISSION } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useUserStore } from '@/store/pinia';

export default defineComponent({
  name: 'OnboardingSteps',
  data() {
    return {
      tweenedPercentage: this.$store.getters['onboardingSteps/completedPercentage'],
      putInterestsQueue: [],
      putInterestsTimeout: null,
      stepsShowing: {},
      currentStepDelay: 300,
      addStepDelay: 300, // time between steps animation
      scrollDebounced: null,
      initialLoad: true,
      PERMISSION,
    };
  },
  mounted() {
    this.$store.dispatch('userConfig/put', { key: 'onboarding_steps.popover', value: false });

    this.scrollDebounced = _.debounce(
      function (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      },
      3000,
      {
        leading: true,
        trailing: false,
      }
    );

    // steps already loaded
    if (this.$store.state.onboardingSteps.steps.length && this.$store.state.onboardingSteps.stepsSeen) {
      this.setStepsSeenAndAnimate();
    } else {
      // if steps not yet loaded, animate on load
      eventBus.$on('onboarding-steps-seen.fetched', this.setStepsSeenAndAnimate);
    }

    // when steps update, animate again
    eventBus.$on('onboarding-steps.set', this.setStepsSeenAndAnimate);

    if (this.percentage === 100 && this.$store.getters['onboardingSteps/unseenCompleted'].length) {
      eventBus.$emit('confetti');
      this.$store.dispatch('userConfig/put', { key: 'onboarding_steps.enabled', value: false });
    }
  },
  unmounted() {
    try {
      eventBus.$off('onboarding-steps-seen.fetched');
      eventBus.$off('onboarding-steps.set');
    } catch (e) {
      console.log(e);
    }
  },
  computed: {
    ...mapStores(useUserStore),
    unseenCompletedCount() {
      return this.$store.getters['onboardingSteps/unseenCompleted'].length;
    },
    steps() {
      return this.$store.state.onboardingSteps.steps;
    },
    onboardingStepsEnabled() {
      return this.$store.state.onboardingSteps.enabled;
    },
    interests() {
      return this.$store.state.onboardingSteps.interests;
    },
    selectedInterests() {
      return this.$store.getters['onboardingSteps/selectedInterests'];
    },
    percentage() {
      return this.$store.getters['onboardingSteps/completedPercentage'];
    },
    isDone() {
      const percentage = this.$store.getters['onboardingSteps/completedPercentage'];
      return percentage === 100;
    },
    animatedPercentage() {
      return parseInt(this.tweenedPercentage.toFixed(0));
    },
  },
  watch: {
    percentage(percentage) {
      TweenLite.to(this.$data, 0.5, { tweenedPercentage: percentage });
    },
    animatedPercentage(percentage) {
      // when 100% completed and not yet seen
      if (percentage === 100 && this.$store.getters['onboardingSteps/unseenCompleted'].length) {
        eventBus.$emit('confetti');
      }
    },
  },
  methods: {
    showDescriptionModal(step) {
      if (this.$tlateOnboardingStep(step.type, 'description')) {
        eventBus.$emit('modal', {
          htmlBody: this.$tlateOnboardingStep(step.type, 'description'),
          title: this.$tlateOnboardingStep(step.type, 'title'),
          buttons: [
            {
              class: 'btn-success',
              text: this.$tlateOnboardingStep(step.type, 'button_primary_text'),
              url: this.$tlateOnboardingStep(step.type, 'button_primary_url'),
            },
            {
              class: 'btn-default',
              text: this.$tlateOnboardingStep(step.type, 'button_secondary_text'),
              url: this.$tlateOnboardingStep(step.type, 'button_secondary_url'),
            },
          ],
        });
      }
    },

    async setStepsSeenAndAnimate() {
      // Steps not yet loaded or agency has onboarding-steps disabled
      if (!this.$store.state.onboardingSteps.steps.length || !this.$store.state.onboardingSteps.stepsSeen) {
        return;
      }

      // update stepsSeen server-side
      const newStepsSeen = await this.$store.dispatch('onboardingSteps/putStepsSeen');

      this.animateSteps().finally(() => {
        // after animating we can update stepsSeen in the store (otherwise bounce-once class would be removed too early)
        this.$store.commit('onboardingSteps/setStepsSeen', newStepsSeen);
      });
    },

    animateSteps() {
      return new Promise((resolve, reject) => {
        // not yet loaded
        if (!this.$store.state.onboardingSteps.steps.length || !this.$store.state.onboardingSteps.stepsSeen) {
          console.log('not yet loaded');
          reject();
          return;
        }

        // get unseen steps
        let filteredSteps = this.$store.state.onboardingSteps.steps.filter((step) => this.stepIsNew(step.type));
        let totalFilteredSteps = filteredSteps.length;

        if (filteredSteps.length) {
          const el = (this.$refs['step_' + filteredSteps[0].type] || [])[0] || null;
          if (el) {
            this.scrollDebounced(el);
          }
        }

        // animate unseen steps
        filteredSteps.forEach((step, i) => {
          let delay = Number((this.currentStepDelay += this.addStepDelay));
          setTimeout(() => {
            this.stepsShowing = Object.assign({}, this.stepsShowing, { [step.type]: true });
            this.$nextTick(() => {
              const el = (this.$refs['step_' + step.type] || [])[0] || null;
              if (!el) {
                return;
              }
              // play sound and animate
              setTimeout(() => {
                el.querySelector('.step-link').classList.add('done');
              }, 310);
            });

            // if last
            if (i + 1 === totalFilteredSteps) {
              this.initialLoad = false;
              setTimeout(() => {
                resolve();
              }, 2000); // bounce transition is 2seconds, after that animateSteps is done.
            }
          }, delay);
        });
        this.currentStepDelay = 0;
      });
    },

    stepIsNew(type) {
      return (
        !(this.$store.state.onboardingSteps.stepsSeen || []).includes(type) &&
        ((this.$store.getters['onboardingSteps/stepByType'](type) || {}).completed || false)
      );
    },

    putInterests(itemNames = []) {
      if (this.$root.onboarding) {
        this.$root.onboarding = null;
        axios.post('/client-api/onboarding/finalize').catch(() => {});
      }

      let items = [...this.$store.getters['onboardingSteps/selectedInterests'].map((item) => item.name)];

      itemNames.forEach((itemName) => {
        let index = items.findIndex((item) => item === itemName);
        if (index > -1) {
          items.splice(index, 1);
        } else {
          items.push(itemName);
        }
      });

      this.$store.dispatch('onboardingSteps/putInterestsSelected', items);
    },

    putInterestsDebounced(item) {
      this.putInterestsQueue.push(item);

      if (this.putInterestsTimeout) {
        clearTimeout(this.putInterestsTimeout);
      }

      this.putInterestsTimeout = setTimeout(() => {
        this.putInterests(this.putInterestsQueue);
        this.putInterestsQueue = [];
      }, 666);
    },
  },
});
</script>

<style scoped lang="scss">
h2 {
  font-size: 300%;
  font-weight: 600;
}
h3 {
  font-size: 200%;
  font-weight: 600;
}

.onboarding-steps {
  height: 100%;
}

.interest {
  cursor: pointer;
  opacity: 0.5;
  border-radius: 12px;
  margin: 1rem !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  transition: all 200ms ease-in-out;
  font-weight: bold;
  background: #ccc linear-gradient(#ccc, #ccc);

  .interest-icon {
    font-size: 42pt !important;
  }
}
.selected {
  opacity: 1;
}
.progress {
  height: 2.5rem;
  border-radius: 12px;
  position: relative;

  .goal {
    opacity: 0.5;
    font-size: 112%;
    font-weight: bold;
    position: absolute;
    right: 1rem;
    line-height: 2.5rem;
  }
}
.progress-bar {
  background-color: #14b29f;
  color: #fff;
  border-radius: 12px;
  font-weight: bold;
  font-size: 112%;
  line-height: 2.5rem;
}

ul.items {
  list-style: none;
  padding: 0;
}
.items li {
  font-size: 1.125em;
  margin: 0.5rem 0;

  a {
    transition: color 0.3s, font-weight 0.3s;
  }
}
i.fa {
  font-size: 1.25em;
}
li:hover {
  opacity: 0.8;
}
i.fa.small {
  font-size: 0.75em;
}
.circle {
  border-radius: 50%;
  width: 3.5em;
  height: 3.5em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.circle i {
  position: absolute;
  font-size: 1.5em;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.circle-big {
  opacity: 0.5;
  border-radius: 50%;
  width: 8.5em;
  height: 8.5em;
  position: absolute;
  bottom: -33%;
  right: -33%;
  transform: translateY(33%) translateX(33%);
}
.circle-big i {
  position: absolute;
  font-size: 2.75em;
  top: 33%;
  transform: translateY(-33%) translateX(-33%);
  left: 33%;
}
h2 .title {
  margin-left: 5rem;
}
.box-header,
.box-body {
  overflow: hidden;
  position: relative;
}
div.box {
  margin-bottom: 3rem;
}

.description-box {
  position: absolute;
  height: 100%;
  width: 100%;
  //opacity: 1;
  z-index: 999;
  left: 0;
  top: 0;
  margin: 0;
}

.custom-shadow-outline {
  box-shadow: rgba(0, 0, 0, 0.11) 0 0 9px 0;
}

.custom-border-radius {
  border-radius: 10px;
}

/* icon transition */

.icons {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  /*margin: 0 2.3rem 0 0;*/
  width: 1.25rem;
  height: 2.6rem;

  i {
    position: absolute;
    top: 0;
    left: 0;

    display: block;
  }

  .icon-default {
    transition: opacity 0.3s, transform 0.3s;
  }

  .icon-done {
    transition: opacity 0.3s, transform 0.3s;
    transform: rotate(-180deg) scale(0.5);
    opacity: 0;
    color: #14b29f;
  }
}

.done {
  color: #14b29f;
  font-weight: bold;

  .icon-done {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }

  .icon-default {
    transform: rotate(180deg) scale(0.5);
    opacity: 0;
  }
}
</style>
<style>
.text-green-light {
  color: #14b29f;
}
</style>
