<script type="text/babel">
import eventBus from '@/eventBus';

import textareaSmsCounter from './TextareaSmsCounter';
export default {
  name: 'QuickSend',
  emits: ['message'],
  props: {
    default_channel: {
      type: Number,
      default: null,
    },
    channels: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      channel_id: null,
      to: '',
      message: '',
      loading: false,
      result: {},
      valid: false,
      list: [],
      quick_replies: [],
      nextLink: null,
      page: 0,
    };
  },

  components: {
    textareaSmsCounter,
  },

  mounted() {
    this.loadHistory();
    this.channel_id = this.default_channel;

    if (this.channel_id != null) {
      this.loadQuickReplies();
    }
  },

  watch: {
    default_channel: function (val) {
      this.channel_id = val;
    },

    message: function (val) {
      this.validate();
    },

    to: function (val) {
      this.validate();
    },
  },

  methods: {
    setCount() {
      this.chars = this.message.length;
    },

    loadQuickReplies() {
      axios.get('/api/v2/quick_replies?type=SMS&channel_id=' + this.channel_id).then((res) => {
        this.quick_replies = res.data.data;
      });
    },

    insertQuickReply(reply) {
      this.message = reply.message + '' + this.message;
    },
    sendSms() {
      if (this.shouldShowChannels.broadcasting) return;
      this.loading = true;
      axios
        .post('/api/v2/sms_messages', {
          to: this.to,
          channel_id: this.channel_id,
          message: this.message,
        })
        .then((result) => {
          this.clearForm();
          this.loadHistory();
          this.loading = false;
          this.result = {};
          eventBus.$emit('sms.calculate-balance');
        })
        .catch((result) => {
          // this.result = result.response.data.er;
          this.loading = false;
        });
    },
    handlePhoneInput() {
      this.to = this.to.replace(/[^0-9,]/g, '');
    },

    validate() {
      this.valid = this.to != '' && this.message != '';
    },

    clearForm() {
      this.to = '';
      this.message = '';
      this.$emit('message', '');
      this.validate();
      eventBus.$emit('sms.reset-form');
      this.$refs.phoneInput.clearInput();
    },

    loadHistory(reset = true) {
      if (reset) {
        this.page = 1;
      } else {
        this.page++;
      }

      axios.get('/api/v2/sms_messages?page=' + this.page).then((res) => {
        if (reset) {
          this.list = [];
        }

        res.data.data.forEach((m) => this.list.push(m));
        this.nextLink = res.data.links.next;
      });
    },

    getStatusLabel: function (status) {
      if (status == 'SENT') {
        return '<label class="label success">' + this.$t('sms.status_sent') + '</label>';
      }

      if (status == 'FAILED') {
        return '<label class="label danger">' + this.$t('sms.status_failed') + '</label>';
      }

      return status;
    },
  },
};
</script>

<template>
  <div class="w-full">
    <div class="box relative">
      <div v-if="shouldShowChannels.broadcasting" class="absolute inset-0 z-40 bg-white opacity-80"></div>

      <div class="box-header b-b">
        <h3>{{ $t('sms.quick_send') }}</h3>
      </div>
      <div class="box-body">
        <form data-pjax="false" @submit.prevent="sendSms">
          <div class="form-group">
            <label>
              <strong>{{ $t('sms.receiver') }}</strong>
            </label>
            <tel-input ref="phoneInput" v-model="to" :placeholder="$t('sms.receiver')"></tel-input>
          </div>
          <div class="form-group">
            <label>
              <strong>{{ $t('sms.sender') }}</strong>
            </label>
            <select v-model="channel_id" class="form-control" @change="loadQuickReplies()">
              <option v-for="channel in channels" :value="channel.id">{{ channel.title }}</option>
            </select>
          </div>
          <div class="form-group">
            <label>
              <strong>{{ $t('sms.content') }}</strong>
            </label>
            <textarea-sms-counter v-model="message" :to="to"></textarea-sms-counter>
            <div class="btn-group" role="group">
              <button
                type="button"
                class="btn btn-default btn-xs dropdown-toggle"
                style="margin-right: 5px; margin-top: 6px"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ $t('sms.insert_quick_reply') }}
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li v-for="quick_reply in quick_replies">
                  <a @click="insertQuickReply(quick_reply)">{{ quick_reply.title }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group">
            <button type="submit" style="margin-top: 4px" class="btn success text-white" :disabled="!valid">
              <i v-show="loading" class="fa fa-spin fa-spinner"></i>
              <span v-show="!loading">{{ $t('sms.send') }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div
      v-if="result.message != null"
      class="m-t-10 alert"
      :class="{ warning: result.type == 'error', success: result.type == 'success' }"
    >
      {{ result.message }}
      <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
    </div>

    <div class="box">
      <div class="box-header b-b">
        <h3>{{ $t('sms.history') }}</h3>
      </div>
      <div class="box-body">
        <table class="table">
          <thead>
            <tr>
              <th width="17%">{{ $t('sms.to') }}</th>
              <th width="17%">{{ $t('sms.sender') }}</th>
              <th width="37%">{{ $t('sms.message') }}</th>
              <th width="9%">{{ $t('sms.credits') }}</th>
              <th width="12%">{{ $t('sms.date') }}</th>
              <th width="8%">{{ $t('sms.status') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="message in list">
              <td>{{ message.formatted_to }}</td>
              <td>{{ message.from }}</td>
              <td class="lg:break-all">{{ message.message }}</td>
              <td>{{ message.credits === 0 ? '-' : message.credits }}</td>
              <td><datetime :time="message.created_at" :pretty="$root.prettyDates"></datetime></td>
              <td v-html="getStatusLabel(message.status)"></td>
            </tr>
          </tbody>
          <tfoot v-show="nextLink !== null">
            <tr>
              <td>
                <a class="text-info" @click="loadHistory(false)">More..</a>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
