<template>
  <div data-test="online-status-user-item" class="flex flex-row p-3" :class="borderClass">
    <template v-if="skeleton">
      <online-status-user-item-skeleton :animation="animation" />
    </template>
    <template v-else>
      <online-status-item :user="user" />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import OnlineStatusItem from './OnlineStatusItem.vue';
import OnlineStatusUserItemSkeleton from './OnlineStatusUserItemSkeleton.vue';

import type { User } from '@/types/common';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'OnlineStatusUserItem',
  props: {
    skeleton: {
      type: Boolean,
      default: false,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
    animation: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object as PropType<User>,
      default: () => ({} as User),
    },
  },
  components: { OnlineStatusUserItemSkeleton, OnlineStatusItem },
  computed: {
    borderClass() {
      return this.hasBorder ? 'border-b border-grey-300' : '';
    },
  },
});
</script>
