<template>
  <div class="z-9999 relative top-0">
    <transition-group name="slide">
      <div
        v-for="serviceNotification of store.notifications"
        :key="serviceNotification.id"
        class="service-notification flex items-center justify-between gap-2 px-4"
        :class="serviceNotification.status"
        :data-test="`service-notification-${serviceNotification.id}`"
      >
        <div class="pointer" @click="alert(serviceNotification.message)">
          <alert-circle-linear class="material-icons text-2x align-middle" size="1.3rem" />
        </div>

        <div class="flex-grow text-ellipsis">
          <span class="t-text-sm">{{ serviceNotification.message }}</span>
        </div>

        <div class="mr-1">
          <t-button
            v-if="serviceNotification.link_url"
            class="align-baseline"
            :href="serviceNotification.link_url"
            btn-style="secondary"
            size="sm"
            target="_blank"
            rel="noreferrer noopener"
          >
            {{ serviceNotification.link_text }}
          </t-button>
        </div>

        <div v-if="serviceNotification.is_dismissible" class="flex-shrink-0 overflow-hidden">
          <t-action-button
            :data-test="`dismiss-service-notification-${serviceNotification.id}`"
            class="close-button !rounded-md !p-1"
            @click="store.dismissNotification(serviceNotification)"
          >
            <cross-linear size="1.3rem" color="white" />
          </t-action-button>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import { AlertCircleLinear, CrossLinear } from '@trengo/trengo-icons';
import { onBeforeUnmount, onMounted, ref } from 'vue';

import { useServiceNotificationsStore } from './useServiceNotifications';
import { getPollingInterval } from './utils';

const store = useServiceNotificationsStore();
const pollingIntervalId = ref<ReturnType<typeof setInterval> | null>(null);

onMounted(async () => {
  store.init();
  startPolling();
});

onBeforeUnmount(() => {
  stopPolling();
});

function startPolling() {
  pollingIntervalId.value = setInterval(() => {
    store.reload();
  }, getPollingInterval());
}

function stopPolling() {
  if (!pollingIntervalId.value) return;
  clearInterval(pollingIntervalId.value);
}

function alert(msg: string) {
  window.alert(msg);
}
</script>

<style scoped lang="scss">
.service-notification {
  height: 3.5rem;
  line-height: 3.5rem;
  color: #fff;
  transition: all 200ms ease-out;

  .content {
    margin: 0 6rem 0 0.5rem;
  }
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}

.info {
  background-color: rgba(0, 111, 202, 1);

  .btn-outline-white:hover {
    color: #20a6b6;
  }
}
.danger {
  background-color: #ef183c;

  .btn-outline-white:hover {
    color: #ef183c;
  }
}
.warning {
  background-color: #f2c012;

  .btn-outline-white:hover {
    color: #f2c012;
  }
}
.success {
  background-color: #23b66d;

  .btn-outline-white:hover {
    color: #23b66d;
  }
}

.btn-outline-white {
  border: 1px solid #fff;
  border-radius: 500px;
  padding: 0.25rem 1rem;

  color: #fff;
  background-color: rgba(255, 255, 255, 0);
  transition: all 200ms ease;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
}

.close-button:hover {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
</style>
