<template>
  <div class="flex w-full flex-1">
    <mobile-status v-show="isMobile" />

    <div v-show="!isMobile && showIframe" class="relative flex flex-1">
      <div class="relative flex flex-1 bg-grey-200">
        <iframe ref="aiIframe" class="absolute h-full w-full border-0" :src="AIURL" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { isEmpty } from 'lodash';
import { type Ref, ref, onMounted, onUnmounted, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { vm } from '@/ApplicationHandler';
import { USER_ROLE } from '@/Configs/Constants/UserRoles';
import { ROUTE_NAME } from '@/routes/constants';
import { useUserStore } from '@/store/pinia';
import breakpoints from '@/util/breakpoints';

import { getTenant, type Tenant } from './api';
import MobileStatus from './MobileStatus.vue';
import { provideToken } from './utils';

const router = useRouter();
const route = useRoute();
const userStore = useUserStore();

const { TRENGO, ADMINISTRATOR, SUPERVISOR } = USER_ROLE;

const showIframe = ref(false);
const AIURL = `${window.AI_MFE_URL || process.env.AI_LOCAL_URL}?v=${Math.random()}`;

const aiIframe: Ref<HTMLIFrameElement | null> = ref(null);
const tenant: Ref<Tenant> = ref({ provider: '', provider_id: '' });
const eventInterval: Ref<number | undefined> = ref();

const isMobile = computed(() => breakpoints.w <= 991);
watch(isMobile, () => checkTenant());

onMounted(() => {
  // @ts-expect-error TS2345 - https://github.com/microsoft/TypeScript/issues/26255
  // Typescript is a bit too strict when it comes to the Array#includes method definition and goes
  // against what the intent of this kind of method call would be in most cases.
  if (![TRENGO, ADMINISTRATOR, SUPERVISOR].includes(userStore?.role)) {
    router.push('/tickets');
    return;
  }

  checkTenant();
});

const messageTargetOrigin = computed(() => {
  if (window.location.hostname === 'app-stg.intrengo.com') {
    return 'https://ai-stg.intrengo.com';
  } else if (window.APP_ENV === 'production') {
    return 'https://ai.trengo.com';
  } else {
    return process.env?.AI_LOCAL_URL || 'http://localhost:5173';
  }
});

onUnmounted(() => {
  showIframe.value = false;
  window.removeEventListener('message', handleIframeMessage);

  if (eventInterval.value !== undefined) {
    clearInterval(eventInterval.value);
  }
});

function checkTenant() {
  getTenant().then((response) => {
    if (!response.data?.provider_id) {
      router.push({ name: ROUTE_NAME.HELPMATE_TERMS });
      return;
    }

    showIframe.value = true;
    tenant.value = response.data;

    window.addEventListener('message', handleIframeMessage);
    provideTokenToIframe();
  });
}

function provideTokenToIframe() {
  if (eventInterval.value !== undefined) {
    clearInterval(eventInterval.value);
  }

  const userSubscriptionSlug = vm?.$userPlan()?.getSubscription as { plan_slug: string };
  const isTrial = isEmpty(userSubscriptionSlug.plan_slug);
  const payload = provideToken(isTrial, tenant.value.provider_id, tenant.value.provider);

  eventInterval.value = window.setInterval(() => {
    aiIframe.value?.contentWindow?.postMessage(payload, messageTargetOrigin.value);
  }, 100);
}

async function handleIframeMessage(e: MessageEvent) {
  switch (e?.data?.type) {
    case 'DATA_REQUEST':
      handleDataRequest(e);

      break;
    case 'AUTHORIZATION':
      if (e?.data?.payload === 'token') {
        aiIframe.value?.contentWindow?.postMessage(
          { type: 'AUTHORIZATION', payload: { token: localStorage.getItem('token') } },
          messageTargetOrigin.value,
        );
      }

      break;
    case 'CHANGE_ROUTE':
      router.push(e.data.path);

      break;
    case 'INTERVAL_STOP':
      window.clearInterval(eventInterval.value);
      break;

    // The user initialized a navigation from inside the helpmate iframe, so we sync the monolith
    case 'ROUTE_PATH_CHANGED':
      router.push(`/ai/helpmate${e.data.payload}`);

      break;
    default:
      return;
  }
}

function pushRouteToIframe() {
  if (route.path.startsWith('/ai/helpmate')) {
    aiIframe.value?.contentWindow?.postMessage(
      { type: 'NAVIGATE', payload: route.path.replace('/ai/helpmate', '') },
      messageTargetOrigin.value,
    );
  }
}

function handleDataRequest(e: MessageEvent) {
  const provideDataRequest = (payload: unknown) =>
    aiIframe.value?.contentWindow?.postMessage({ type: 'DATA_REQUEST', payload }, messageTargetOrigin.value);

  switch (e?.data?.payload) {
    case 'agencyId':
      provideDataRequest({ agencyId: vm?.$store.getters['initialData/agency']?.id });
      break;
    case 'clientLocale':
      provideDataRequest({ clientLocale: userStore.user?.locale_code });
      break;
    case 'routeSync':
      pushRouteToIframe();
      break;
  }
}
</script>
