<script>
import Generic from './ECommerce/Generic';

export default {
  extends: Generic,

  methods: {
    loadData() {
      // this.customer = {};
      this.orders = [];
      this.loading = true;
      axios
        .get('/api/v2/integrations/bol?plugin_id=' + this.plugin.id + '&ticket_id=' + this.ticket.id)
        .then((res) => {
          this.loading = false;
          this.found = true;
          this.parseData(res.data);
        })
        .catch((e) => {
          this.found = true;
          this.loading = false;
        });
    },

    getOrderUrl(id) {
      return 'https://partner.bol.com/sdd/outbound-orders/open/FBR';
    },
    // getReturnUrl(id) {
    //     return 'https://partner.bol.com/sdd/outbound-orders/open/FBR';
    // },
    parseData(data) {
      this.orders = data.orders;
      this.returns = data.returns;
    },
  },
};
</script>
