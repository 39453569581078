<template>
  <div>
    <div
      :class="computedClasses"
      class="
        modal-multi-item
        flex
        h-48px
        cursor-pointer
        select-none
        items-center
        justify-center
        rounded-xl
        border border-grey-400
        p-6
        sm:h-auto
      "
    >
      <span class="t-text-desktop-label-sm sm:t-text-desktop-label-lg">
        {{ title }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalMultiItem',
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    selected: {
      type: Boolean,
      required: true,
      default: false,
    },
    size: {
      type: String,
      default: 'fixed',
      validate: (value) => ['fixed', 'auto'].includes(value),
    },
  },
  computed: {
    computedClasses() {
      return [this.selected && 'selected', this.computedSize];
    },
    computedSize() {
      return this.size === 'fixed' ? 'width-28rem' : 'auto-width';
    },
  },
};
</script>
<style src="./ModalMultiItem.scss" scoped lang="scss" />
