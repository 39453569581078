<template>
  <span></span>
  <!--    <a :href="url" target="_blank" class="open-external font-semibold flex items-center hover:text-black"><slot></slot>-->
  <!--        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-2">-->
  <!--            <path d="M0.75 1.5L5.25 6L0.75 10.5" stroke="#838389" stroke-width="2"/>-->
  <!--        </svg>-->
  <!--    </a>-->
</template>

<script>
export default {
  props: ['url'],
};
</script>

<style lang="scss" scoped>
a {
  svg {
    margin-top: 2px;
    transition: margin 0.1s ease-in-out;
  }
}
a:hover {
  svg {
    margin-left: 15px;
  }
}
</style>
