import dompurify from 'dompurify';
import marked from 'marked';

import { randomString } from '@/util/stringHelpers';

import MessageAttachmentModel from './MessageAttachmentModel';
import store from '../../../store';
import PusherHelper from '../../../util/PusherHelper';
import { messages } from '../Api';
import Message from '../Components/Message';
import { getHtmlReplacements } from '../Util/Chat';

const ALLOWED_TAGS = ['br', 'strong', 'em', 'u', 's', 'ul', 'ol', 'li', 'div', 'pre'];
const ALLOWED_ATTR = ['class'];

export default class MessageModel {
  constructor(message) {
    this.id = parseInt(message.id) || randomString(16);
    this.userId = parseInt(message.userId) || null;
    this.threadId = parseInt(message.threadId) || null;
    this.body = message.body;
    this.attachments = (message.attachments || []).map((a) => new MessageAttachmentModel(a));
    this.reactions = (message.reactions || []).map((r) => {
      return { emoji: r.emoji, users: (r.users || []).map(Number) };
    }); // parse user ids as int
    this.pinned = !!message.pinned;
    this.parent = message.parent ? new MessageModel(message.parent) : null; // this does not get updated on new message
    this.createdAt = parseInt(message.createdAt) || null;
    this.bodyType = message.bodyType;
    this.meta = message.meta;

    // for sending only
    this.toUserId = parseInt(message.toUserId) || null;
    this.attachmentIds = (message.attachmentIds || []).map(Number);
    this.parentId = parseInt(message.parentId) || null;
  }

  getUser() {
    return store.getters['usersInternalChat/userById'](this.userId);
  }

  getThread() {
    return store.getters['chat/threadById'](this.threadId);
  }

  isIncoming() {
    return !this.isOutgoing();
  }

  isOutgoing() {
    return store.state.usersInternalChat.currentUserId === this.userId;
  }

  /**
   * @param context
   */
  getBodyHtmlSanitized(context = 'thread') {
    if (context === 'edit') {
      return dompurify.sanitize(this.body, { ALLOWED_TAGS: ALLOWED_TAGS, ALLOWED_ATTR: ALLOWED_ATTR });
    }

    return getHtmlReplacements(
      dompurify.sanitize(this.body, { ALLOWED_TAGS: ALLOWED_TAGS, ALLOWED_ATTR: ALLOWED_ATTR }),
      context
    );
  }

  /**
   * returns message from store if loaded, otherwise returns the message from attached resource.
   * Notice: This method can return messages that are not actually available in the thread.messages. These are also not updated on update message (yet)
   * @returns {*|MessageModel}
   */
  getParentMessage() {
    if (!this.parent) {
      return;
    }

    return store.getters['chat/messageById'](this.getThread(), this.parent.id) || this.parent;
  }

  isMyMessage() {
    return store.getters['usersInternalChat/currentUser'].id === this.userId;
  }

  deleteMessage() {
    let thread = this.getThread();
    let isThreadOwner = thread.participants.filter(
      (p) => p.id === store.getters['usersInternalChat/currentUser'].id && p.owner === true
    );

    if (!this.isMyMessage() && !isThreadOwner) {
      return;
    }

    store
      .dispatch('chat/deleteMessage', { thread: thread, message: this })
      .then((messageId) => {
        if (messageId === thread.lastMessageId) {
          thread.lastMessageId = null;
        }
        thread.pusherChannel.trigger('client-delete-message', { messageId: messageId });
        thread.messages.forEach((m) => {
          if (m.parent && m.parent.id === messageId) {
            m.parent = null;
          }
        });
      })
      .catch(() => {});
  }

  togglePinned() {
    return store.dispatch('chat/togglePinned');
  }

  toggleReaction(emojiCode) {
    if (this.myReactions().find((r) => r.emoji === emojiCode)) {
      store
        .dispatch('chat/deleteReaction', {
          reactionResource: {
            emoji: emojiCode,
            user_id: store.getters['usersInternalChat/currentUser'].id,
            message_id: this.id,
          },
          messageModel: this,
        })
        .then((data) => {
          this.getThread().pusherChannel.trigger('client-delete-reaction', data);
        })
        .catch(() => {});
    } else {
      store
        .dispatch('chat/postReaction', {
          reactionResource: {
            emoji: emojiCode,
            user_id: store.getters['usersInternalChat/currentUser'].id,
            message_id: this.id,
          },
          messageModel: this,
        })
        .then((data) => {
          this.getThread().pusherChannel.trigger('client-new-reaction', data);
        })
        .catch(() => {});
    }
  }

  myReactions() {
    return this.reactions.filter((r) => r.users.indexOf(store.state.usersInternalChat.currentUserId) > -1) || [];
  }

  getReaction(emojiCode) {
    let index = this.reactions.findIndex((r) => r.emoji === emojiCode);
    if (index > -1) {
      return this.reactions[index];
    } else {
      return false;
    }
  }

  isMyReaction(emojiCode) {
    return !!this.myReactions().find((r) => r.emoji === emojiCode);
  }
}
