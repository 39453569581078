import { request, cachedRequest } from '@/util/request';

import { ENDPOINT } from '../constants';

import type { Links, Meta, User } from '@/types';
import type { AxiosPromise } from 'axios';

type UsersResponse = {
  data: User[];
  links: Links;
  meta: Meta;
};

export function fetchUsers(parameters = null): AxiosPromise<UsersResponse> {
  const endpoint = parameters ? `${ENDPOINT.USERS}?${new URLSearchParams(parameters)?.toString()}` : ENDPOINT.USERS;
  return request(endpoint, 'GET');
}

export function createUser(payload: unknown) {
  return request(ENDPOINT.USERS, 'POST', payload);
}

export function fetchAllUsers({ cacheEnabled } = { cacheEnabled: true }) {
  return cachedRequest({ method: 'GET', url: ENDPOINT.USERS_LIST }, cacheEnabled);
}

export function fetchUserById(id: number) {
  return request(`${ENDPOINT.USERS}/${id}`, 'GET');
}
