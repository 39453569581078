<template>
  <div class="w-full">
    <page-container>
      <hero class="my-6">
        <template #top>
          <page-title>{{ $t('reports.time_tracking_title') }}</page-title>
        </template>
        <template #body>
          {{ $t('reports.time_tracking_title_sub_text') }}
        </template>
      </hero>
      <div class="mb-8 flex select-none flex-row items-center rounded-lg bg-white px-10 pb-6 pt-4 pt-4">
        <div class="flex flex-nowrap items-start">
          <div class="flex flex-wrap items-center gap-y-1">
            <atomic-multiselect
              v-model="filters.user_id"
              :remote="true"
              title="Users"
              label="name"
              :options="users"
              @search="findUsers"
            ></atomic-multiselect>
            <atomic-multiselect
              v-model="filters.contact_id"
              :remote="true"
              title="Contacts"
              label="display_name"
              :options="contacts"
              @search="findContacts"
            ></atomic-multiselect>
            <atomic-multiselect v-model="filters.label_id" title="Labels" :options="labels"></atomic-multiselect>
            <atomic-multiselect
              v-model="filters.profile_id"
              :remote="true"
              title="Profiles"
              :options="profiles"
              @search="findProfiles"
            ></atomic-multiselect>
            <atomic-multiselect
              v-model="filters.channel_id"
              title="Channels"
              label="title"
              :options="channels"
            ></atomic-multiselect>
            <atomic-multiselect
              v-model="filters.ticket_id"
              :remote="true"
              title="Tickets"
              label="id"
              :options="tickets"
              @search="findTickets"
            ></atomic-multiselect>
          </div>
          <div class="ml-auto flex min-w-305p">
            <div class="flex rounded-lg border border-grey-200 font-bold">
              <atomic-datepicker v-model="filters.date" mode="range"></atomic-datepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-8 select-none items-center rounded-lg bg-white px-4 pt-6">
        <div v-if="payload.data.data.rows.length">
          <div class="w-full flex-1 leading-none">
            <div
              v-for="(row, rowIndex) in payload.data.data.rows"
              :key="rowIndex"
              style="transition: box-shadow 0.3s"
              class="p-4"
              :class="{
                'rounded-lg px-10 shadow-xl': openRow === rowIndex,
                'border-b border-grey-300': openRow !== rowIndex && openRow - 1 !== rowIndex,
                'mx-6': openRow !== rowIndex,
              }"
            >
              <div class="flex items-center">
                <div class="flex-1 leading-none">
                  <div class="mb-2 flex text-sm font-semibold text-grey-700">
                    <div v-for="(col, index) in filteredPayloadDataHead" :key="index" class="" style="width: 200px">
                      {{ col.text }}:
                    </div>
                  </div>
                  <div class="mb-1 flex text-base font-medium text-grey-900">
                    <div
                      v-for="(col, idx) in filteredPayloadDataHead"
                      :key="idx"
                      class=""
                      :class="{ 'font-bold': col.name === 'ticket_id' }"
                      style="width: 200px"
                    >
                      <span v-if="col.name === 'ticket_id'">#</span>
                      {{ col.filter ? format[col.filter](row[col.name]) : row[col.name] }}
                    </div>
                  </div>
                </div>
                <div
                  v-show="openRow !== rowIndex"
                  class="ml-auto flex cursor-pointer items-center text-sm font-semibold leading-none text-grey-800"
                  @click="openRow = rowIndex"
                >
                  {{ $t('reports.time_tracking_see_details') }}
                  <i class="far fa-chevron-down ml-2 text-grey-600"></i>
                </div>
                <div
                  v-show="openRow === rowIndex"
                  class="ml-auto cursor-pointer text-2xl text-grey-600"
                  @click="openRow = null"
                >
                  <i class="far fa-times"></i>
                </div>
              </div>

              <div v-show="openRow === rowIndex" class="mt-4">
                <div
                  v-for="(child, i) in row.children"
                  :key="i"
                  class="-mx-4 flex-1 rounded-lg p-4"
                  :class="{ 'bg-grey-100': i % 2 === 0 }"
                >
                  <div class="mb-2 flex text-sm font-semibold text-grey-700">
                    <div v-for="col in payload.data.data.childHead" :key="col.text" class="flex-1">
                      <span>{{ col.text }}</span>
                    </div>
                  </div>
                  <div class="flex font-medium text-grey-900">
                    <div v-for="(col, idx) in payload.data.data.childHead" :key="idx" class="flex-1">
                      {{ col.filter ? format[col.filter](child[col.name]) : child[col.name] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-900 mt-8 flex items-center px-6 pb-6 text-base font-medium leading-none">
            <div class="w-1/3 text-left text-grey-900">
              <span class="pl-4 text-grey-800">{{ $t('reports.time_tracking_total_time') }}</span>
              {{ formattedSeconds }}
            </div>
            <div class="flex w-1/3 cursor-pointer items-center justify-center text-center text-sm text-grey-600">
              <div class="cursor-pointer text-grey-400" @click="paginate(payload.meta.current_page - 1)">
                <i class="far fa-chevron-left mr-4"></i>
              </div>
              <div
                v-for="page in pagesToShow"
                :key="page"
                :class="{ 'text-base font-bold text-black': payload.meta.current_page === page }"
                class="mx-4"
                @click="paginate(page)"
              >
                {{ page }}
              </div>
              <div class="cursor-pointer text-grey-400" @click="paginate(payload.meta.current_page + 1)">
                <i class="far fa-chevron-right ml-4"></i>
              </div>
            </div>
            <div class="w-1/3 text-right">
              <atomic-button :disabled="waitingForExport" size="md" btn-style="primary" @click="exportData">
                <i class="far fa-file fa-sm mr-2"></i>
                {{ $t('reports.time_tracking_export') }}
              </atomic-button>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex flex-col items-center justify-center pb-16 pt-4 leading-none">
            <div class="my-4 text-2xl font-bold">{{ $t('reports.time_tracking_no_data_available') }}</div>
            <atomic-button href="https://help.trengo.com/en/articles/103180-time-tracking" class="mt-2">
              {{ $t('reports.time_tracking_start_tracking') }}
            </atomic-button>
          </div>
        </div>
      </div>
      <div v-if="exports.length" class="mb-8 select-none items-center rounded-lg bg-white px-10 py-6">
        <div class="my-4 text-2xl font-bold">{{ $t('reports.time_tracking_export_history') }}</div>
        <div>
          <div v-for="(ex, index) in exports" :key="index" class="mb-2 flex items-center border-b border-grey-300 pb-2">
            <div class="flex-1 text-base">
              <div v-if="ex.status === 'PENDING'">
                <i class="fa fa-circle-o-notch fa-spin fa-fw align-middle"></i>
                <template v-if="currentPercentage !== null">{{ currentPercentage }}%</template>
                <template v-else>{{ $t('statistics.export_pending') }}</template>
              </div>
              <div v-if="ex.status === 'COMPLETED'" class="flex items-center">
                <i class="far fa-file mr-2 text-grey-600"></i>
                <datetime :time="ex.created_at" :pretty="$root.prettyDates"></datetime>
              </div>
            </div>
            <div v-if="ex.status === 'COMPLETED'" class="ml-auto">
              <atomic-button size="sm" btn-style="secondary" @click="getDownloadUrl(ex.id)">
                <i class="far fa-cloud-download-alt fa-sm mr-2"></i>
                {{ $t('reports.time_tracking_download_file') }}
              </atomic-button>
            </div>
          </div>
        </div>
      </div>
    </page-container>
  </div>
</template>

<script>
import _ from 'lodash';

import PageContainer from '../Components/PageContainer';
import PageTitle from '../Components/PageTitle';

/*
 * TODO: make this reusable: e.g. Rename this file to Table.vue, if reportType === 'table' -> load Table.vue.
 */
export default {
  name: 'TimeTracking',
  components: { PageTitle, PageContainer },
  data() {
    return {
      openRow: null,
      waitingForExport: false,
      exportChannel: null,
      exports: [],

      payload: {
        meta: {
          current_page: 1,
          last_page: 1,
          total: 0,
        },
        data: {
          data: {
            totalUsers: 0,
            totalTickets: 0,
            totalTime: 0,
            head: [],
            childHead: [],
            rows: [],
          },
        },
      },

      selectedUsers: [],
      users: [],
      usersLoading: false,

      selectedContacts: [],
      contacts: [],
      contactsLoading: false,

      selectedProfiles: [],
      profiles: [],
      profilesLoading: false,

      selectedTickets: [],
      tickets: [],
      ticketsLoading: false,

      selectedChannels: [],
      channels: [],

      selectedLabels: [],
      labels: [],

      filters: {
        date: {
          start: moment().subtract(6, 'days').unix(),
          end: moment().unix(),
        },
        user_id: [],
        profile_id: [],
        contact_id: [],
        ticket_id: [],
        label_id: [],
        channel_id: [],
      },

      loading: false,

      format: {
        formatSeconds(seconds) {
          const hours = Math.floor(seconds / 60 / 60 || 0);
          const minutes = Math.floor((seconds / 60) % 60 || 0);

          if (hours > 0) {
            return `${hours}h ${minutes}m`;
          } else {
            return `${minutes}m`;
          }
        },
        formatDateTime(unixSeconds) {
          return moment(unixSeconds * 1000).format('lll');
        },
        diff(time) {
          return moment(time).fromNow();
        },
      },
    };
  },

  computed: {
    filteredPayloadDataHead() {
      return this.payload.data.data.head.filter((col) => !col.children);
    },
    currentPercentage() {
      if (!this.waitingForExport) {
        return null;
      }
      return Math.round((this.waitingForExport.progress / this.waitingForExport.total || 0) * 100);
    },
    pagesToShow() {
      let maxButtonCount = 5; // should be uneven integer

      let pages = [];
      let currentPage = this.payload.meta.current_page;
      let lastPage = this.payload.meta.last_page;
      let page = 0; // first button to show

      // if not enough pages for maxButtonCount
      if (lastPage < maxButtonCount) {
        maxButtonCount = lastPage;
      }

      // if current page is near start
      if (currentPage - Math.ceil(maxButtonCount / 2) < 1) {
        page = 1;
      }
      // if current page is near end
      else if (currentPage + Math.floor(maxButtonCount / 2) > lastPage) {
        page = lastPage - (maxButtonCount - 1);
      }
      // if first page nor last page are visible
      else {
        page = currentPage - Math.floor(maxButtonCount / 2);
      }

      for (let i = 0; i < maxButtonCount; i++) {
        pages.push(page++);
      }

      return pages;
    },
    formattedSeconds() {
      const seconds = this.payload.data.data.totalTime || 0;
      const hours = Math.floor(seconds / 60 / 60 || 0);
      const minutes = Math.floor((seconds / 60) % 60 || 0);

      if (hours > 0) {
        return `${hours}h ${minutes}m`;
      } else {
        return `${minutes}m`;
      }
    },
  },

  mounted() {
    this.initDropdowns();

    this.loadData();

    this.exportChannel = PusherInstance.subscribe(
      'private-agency-' + this.$root.companyProfile.profile.agency_id + '@dataExport'
    );
    this.exportChannel.bind('progress', (data) => {
      let ex = this.exports.find((ex) => ex.id === data.id);
      if (ex) {
        this.exports.splice(this.exports.indexOf(ex), 1, { ...ex, progress: data.progress });
      }
    });
    this.exportChannel.bind('status', (data) => {
      let ex = this.exports.find((ex) => ex.id === data.id);
      if (ex) {
        this.exports.splice(this.exports.indexOf(ex), 1, { ...ex, status: data.status });
      }
      if (this.waitingForExport && this.waitingForExport.id === data.id) {
        // todo rl: download url / redirect
        this.waitingForExport = null;
      }
    });
  },

  beforeUnmount() {
    this.exportChannel.unsubscribe();
  },

  methods: {
    paginate(page) {
      if (page <= this.payload.meta.last_page && page > 0) {
        this.payload.meta.current_page = page;
        this.loadData();
      }
    },
    findUsers: _.debounce(function (query) {
      this.usersLoading = true;
      axios
        .get('/api/v2/users?minimal=1&term=' + query)
        .then((r) => {
          this.users = r.data.data;
        })
        .finally(() => (this.usersLoading = false));
    }),
    findContacts: _.debounce(function (query) {
      this.contactsLoading = true;
      axios
        .get('/api/v2/contacts?term=' + query)
        .then((r) => {
          this.contacts = r.data.data;
        })
        .finally(() => (this.contactsLoading = false));
    }),
    findProfiles: _.debounce(function (query) {
      this.profilesLoading = true;
      axios
        .get('/api/v2/profiles?term=' + query)
        .then((r) => {
          this.profiles = r.data.data;
        })
        .finally(() => (this.profilesLoading = false));
    }),
    findTickets: _.debounce(function (query) {
      this.ticketsLoading = true;
      axios
        .get('/api/v2/tickets?term=' + query)
        .then((r) => {
          this.tickets = r.data.data;
        })
        .finally(() => (this.ticketsLoading = false));
    }),

    initDropdowns() {
      this.channels = this.$root.channels;
      this.labels = this.$root.labels;
    },

    loadData() {
      this.openRow = null;
      this.loading = true;
      this.filters.start = moment.unix(this.filters.date.start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
      this.filters.end = moment.unix(this.filters.date.end).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      $.getJSON('/client-api/reports/data/TIME_TRACKING?page=' + this.payload.meta.current_page, {
        filters: this.filters,
      })
        .then((data) => {
          this.payload = data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
        });

      axios
        .get('/api/v2/data_export?exportType=TimeTracking')
        .then((r) => {
          this.exports = r.data.data;
        })
        .catch(() => {});
    },

    exportData() {
      if (this.waitingForExport) {
        return;
      }
      axios
        .post('/api/v2/data_export/', { exportType: 'TimeTracking', exportTo: 'csv', filters: this.filters })
        .then((r) => {
          if (typeof r.data !== 'object') {
            return;
          }

          this.waitingForExport = { ...r.data, progress: 0, total: this.payload.meta.total };

          this.exports.unshift(this.waitingForExport);
        })
        .catch(() => {});
    },

    getDownloadUrl(id) {
      axios
        .get('/api/v2/data_export/' + id)
        .then((r) => {
          window.location.href = r.data;
        })
        .catch(() => {});
    },
  },

  watch: {
    filters: {
      deep: true,
      handler(v) {
        this.loadData();
      },
    },
  },
};
</script>
