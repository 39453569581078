<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    action: {
      type: String,
      default: 'create',
    },
  },
  computed: {
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    selectCode(e) {
      var text = e.target,
        range,
        selection;

      if (document.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(text);
        range.select();
      } else if (window.getSelection) {
        selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(text);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    },
  },
};
</script>

<template>
  <div class="box">
    <div class="box-header b-b">
      <h3>{{ $t('web_widget.installation') }}</h3>
    </div>
    <div class="box-body">
      <p v-html="$t('web_widget.copy_the_following_script_description')" />

      <pre class="p-a-1" @click="selectCode">
&lt;script type="text/javascript"&gt;
window.Trengo = window.Trengo || {};
window.Trengo.key = '{{ record.token }}';
(function(d, script, t) {
    script = d.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://static.widget.trengo.eu/embed.js';
    d.getElementsByTagName('head')[0].appendChild(script);
}(document));
&lt;/script&gt;</pre
      >
      <em v-show="action === 'create'" class="text-muted">
        {{ $t('web_widget.embed_code_description') }}
      </em>
    </div>
  </div>
</template>
