<template>
  <div class="row-body">
    <admin-section-title title="Developers"></admin-section-title>
    <div class="row-col">
      <div class="row-col">
        <div id="settings-primary" class="col-xs modal fade aside aside-md b-r w-medium w-200px">
          <div class="nav-settings light left b-primary row-col">
            <ul>
              <li class="text-md white header">
                <strong>{{ $t('apps_integration.apps_integrations') }}</strong>
                <a data-dismiss="modal" class="hidden-md-up text-muted m-l-auto m-r-sm m-l-1 text-lg">×</a>
              </li>
            </ul>
            <div class="row-row">
              <div class="row-body scrollable hove">
                <div class="row-inner">
                  <ul>
                    <li>
                      <router-link
                        class="nav-settings-link text-truncate"
                        data-dismiss="modal"
                        :to="'/admin/developers/rest'"
                      >
                        {{ $t('apps_integration.rest_api') }}
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="nav-settings-link text-truncate"
                        data-dismiss="modal"
                        :to="'/admin/developers/webhooks'"
                      >
                        {{ $t('apps_integration.webhooks') }}
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="nav-settings-link text-truncate"
                        data-dismiss="modal"
                        :to="'/admin/developers/plugins'"
                      >
                        {{ $t('apps_integration.apps') }}
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="nav-settings-link text-truncate"
                        data-dismiss="modal"
                        :to="'/admin/developers/zapier'"
                      >
                        <span class="float-right">
                          <label class="label label-success success">{{ $t('apps_integration.new') }}</label>
                        </span>
                        {{ $t('apps_integration.zapier') }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="loaded" class="row-col">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  data() {
    return {
      loaded: true,
    };
  },
};
</script>
