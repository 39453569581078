<template>
  <div class="mb-4 flex flex-col rounded-md border border-grey-300 bg-white p-2">
    <div class="mb-2 grid grid-cols-2 gap-2">
      <div v-if="order.order_id" class="m-0 flex flex-col p-0">
        <h2 class="t-text-xs m-0 p-0 text-grey-600">{{ $t('integration_hub.sidebar_crm_order_id') }}</h2>
        <a
          v-if="order.order_url"
          class="t-text-sm-emphasize m-0 cursor-pointer truncate p-0 text-leaf-500"
          :href="order.order_url"
          target="_blank"
        >
          {{ order.order_id }}
        </a>
        <p v-else class="t-text-sm-emphasize m-0 truncate p-0 text-grey-900">
          {{ order.order_id }}
        </p>
      </div>

      <div v-if="order.order_number" class="m-0 flex flex-col p-0">
        <h2 class="t-text-xs m-0 p-0 text-grey-600">{{ $t('integration_hub.sidebar_crm_order_number') }}</h2>
        <p class="t-text-sm-emphasize m-0 truncate p-0 text-grey-900">
          {{ order.order_number }}
        </p>
      </div>

      <div v-if="order.order_status" class="m-0 flex flex-col p-0">
        <h2 class="t-text-xs m-0 p-0 text-grey-600">{{ $t('integration_hub.sidebar_crm_status') }}</h2>
        <p class="t-text-sm-emphasize m-0 truncate p-0 text-grey-900">{{ order.order_status }}</p>
      </div>

      <div v-if="order.payment_status" class="m-0 flex flex-col p-0">
        <h2 class="t-text-xs m-0 p-0 text-grey-600">{{ $t('integration_hub.sidebar_crm_payment_status') }}</h2>
        <p class="t-text-sm-emphasize m-0 truncate p-0 text-grey-900">
          {{ order.payment_status }}
        </p>
      </div>

      <div v-if="order.total_amount" class="m-0 flex flex-col p-0">
        <h2 class="t-text-xs m-0 p-0 text-grey-600">{{ $t('integration_hub.sidebar_crm_total_amount') }}</h2>
        <p class="t-text-sm-emphasize m-0 w-fit max-w-full truncate p-0 text-grey-900">
          {{ amountToCurrency(order.total_amount) }}
        </p>
      </div>

      <div v-if="order.fulfillment_status" class="m-0 flex flex-col p-0">
        <h2 class="t-text-xs m-0 p-0 text-grey-600">{{ $t('integration_hub.sidebar_crm_fulfillment_status') }}</h2>
        <p class="t-text-sm-emphasize m-0 w-fit max-w-full truncate p-0 text-grey-900">
          {{ order.fulfillment_status }}
        </p>
      </div>

      <div v-if="order.tracking.length > 0" class="m-0 flex flex-col p-0">
        <h2 class="t-text-xs m-0 p-0 text-grey-600">{{ $t('integration_hub.sidebar_crm_tracking') }}</h2>
        <a
          v-for="tracking in order.tracking"
          :key="tracking.url"
          class="t-text-sm-emphasize m-0 cursor-pointer truncate p-0 text-leaf-500"
          :href="tracking.url"
          target="_blank"
        >
          {{ tracking.number }}
        </a>
      </div>
    </div>
    <div v-if="order.created_at" class="m-0 mb-2 flex flex-col p-0">
      <h2 class="t-text-xs m-0 p-0 text-grey-600">{{ $t('integration_hub.sidebar_crm_order_date') }}</h2>
      <p class="t-text-sm-emphasize m-0 w-fit max-w-full truncate p-0 text-grey-900">
        {{ formatDate(order.created_at) }}
      </p>
    </div>
    <div v-if="hasShippingAddress" class="m-0 mb-2 flex flex-col p-0">
      <h2 class="t-text-xs m-0 p-0 text-grey-600">{{ $t('integration_hub.sidebar_crm_shipping_address') }}</h2>
      <p
        v-if="order.shipping_address.line1"
        class="t-text-sm-emphasize m-0 w-fit max-w-full truncate p-0 text-grey-900"
      >
        {{ order.shipping_address.line1 }}
      </p>
      <p
        v-if="order.shipping_address.line2"
        class="t-text-sm-emphasize m-0 w-fit max-w-full truncate p-0 text-grey-900"
      >
        {{ order.shipping_address.line2 }}
      </p>
      <p
        v-if="order.shipping_address.postal_code || order.shipping_address.city || order.shipping_address.country"
        class="t-text-sm-emphasize m-0 w-fit max-w-full truncate p-0 text-grey-900"
      >
        {{ order.shipping_address.postal_code }} {{ order.shipping_address.city }} {{ order.shipping_address.country }}
      </p>
    </div>

    <div v-if="hasBillingAddress" class="m-0 mb-2 flex flex-col p-0">
      <h2 class="t-text-xs m-0 p-0 text-grey-600">{{ $t('integration_hub.sidebar_crm_billing_address') }}</h2>
      <p v-if="order.billing_address.line1" class="t-text-sm-emphasize m-0 w-fit max-w-full truncate p-0 text-grey-900">
        {{ order.billing_address.line1 }}
      </p>
      <p v-if="order.billing_address.line2" class="t-text-sm-emphasize m-0 w-fit max-w-full truncate p-0 text-grey-900">
        {{ order.billing_address.line2 }}
      </p>
      <p
        v-if="order.billing_address.postal_code || order.billing_address.city || order.billing_address.country"
        class="t-text-sm-emphasize m-0 w-fit max-w-full truncate p-0 text-grey-900"
      >
        {{ order.billing_address.postal_code }} {{ order.billing_address.city }} {{ order.billing_address.country }}
      </p>
    </div>

    <t-divider class="mb-2" />

    <div class="grid grid-cols-2 gap-2">
      <div class="m-0 flex flex-col p-0">
        <h2 class="t-text-xs m-0 p-0 text-grey-600">{{ $t('integration_hub.sidebar_crm_discounts') }}</h2>
        <p
          v-for="discount in order.discounts"
          :key="discount.amount"
          class="t-text-sm-emphasize m-0 w-fit max-w-full truncate p-0 text-grey-900"
        >
          {{ amountToCurrency(discount.amount) }}
        </p>
        <p
          v-if="order.discounts.length === 0"
          class="t-text-sm-emphasize m-0 w-fit max-w-full truncate p-0 text-grey-900"
        >
          {{ $tc(`integration_hub.sidebar_crm_not_available`) }}
        </p>
      </div>

      <div v-if="order.shipping_cost" class="m-0 flex flex-col p-0">
        <h2 class="t-text-xs m-0 p-0 text-grey-600">{{ $t('integration_hub.sidebar_crm_shipping_cost') }}</h2>
        <p class="t-text-sm-emphasize m-0 w-fit max-w-full truncate p-0 text-grey-900">
          {{ amountToCurrency(order.shipping_cost) }}
        </p>
      </div>

      <div v-if="order.sub_total" class="m-0 flex flex-col p-0">
        <h2 class="t-text-xs m-0 p-0 text-grey-600">{{ $t('integration_hub.sidebar_crm_sub_total') }}</h2>
        <p class="t-text-sm-emphasize m-0 w-fit max-w-full truncate p-0 text-grey-900">
          {{ amountToCurrency(order.sub_total) }}
        </p>
      </div>

      <div v-if="order.total_tax" class="m-0 flex flex-col p-0">
        <h2 class="t-text-xs m-0 p-0 text-grey-600">{{ $t('integration_hub.sidebar_crm_vat') }}</h2>
        <p class="t-text-sm-emphasize m-0 w-fit max-w-full truncate p-0 text-grey-900">
          {{ amountToCurrency(order.total_tax) }}
        </p>
      </div>
    </div>

    <t-divider v-if="order.note" class="my-2" />

    <h2 v-if="order.note" class="t-text-xs m-0 p-0 text-grey-600">{{ $t('integration_hub.sidebar_crm_note') }}</h2>
    <p v-if="order.note" class="t-text-sm m-0 p-0 text-grey-900">{{ order.note }}</p>
  </div>
</template>

<script setup lang="ts">
import moment from 'moment';

import { vm } from '@/ApplicationHandler';

import type { ECommerceOrder } from '@/api';
const props = defineProps<{ order: ECommerceOrder }>();

const amountToCurrency = (amount: string): string => {
  if (!amount) {
    return vm.$tc(`integration_hub.sidebar_crm_not_available`);
  }
  if (!props.order.currency) {
    return amount;
  }
  return new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: props.order.currency,
    currencyDisplay: 'narrowSymbol',
  }).format(parseInt(amount));
};

const hasShippingAddress = Object.values(props.order.shipping_address).filter((val) => val).length > 0;
const hasBillingAddress = Object.values(props.order.billing_address).filter((val) => val).length > 0;

const formatDate = (str: string): string => (str ? moment.tz(str, window.APP_TIMEZONE).format('YYYY-MM-DD') : '-');
</script>
