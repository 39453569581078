import { request } from '@/util/request';

import { ENDPOINT } from '../constants';

import type { ServerItemEntitlement, PlanId, ServerSubscriptionEntitlement } from '@/types';
import type { AxiosPromise } from 'axios';

export function fetchItemEntitlements(): AxiosPromise<ServerItemEntitlement[]> {
  return request(`${ENDPOINT.ITEM_ENTITLEMENTS}`, 'GET');
}
export function fetchSubscriptionEntitlements(): AxiosPromise<{
  plan: PlanId;
  entitlements: ServerSubscriptionEntitlement[];
}> {
  return request(`${ENDPOINT.SUBSCRIPTION_ENTITLEMENTS}`, 'GET');
}
