<template>
  <div>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 14C20.625 14 22 12.6562 22 11C22 9.375 20.625 8 19 8C17.3438 8 16 9.375 16 11C16 12.6562 17.3438 14 19 14ZM20.5 15H20.375C19.9375 15.1562 19.4688 15.25 19 15.25C18.5 15.25 18.0312 15.1562 17.5938 15H17.5C16.8438 15 16.25 15.1875 15.75 15.5C16.5 16.3125 17 17.4062 17 18.625V19.8125C17 19.875 16.9688 19.9375 16.9688 20H22.5C23.3125 20 24 19.3438 24 18.5C24 16.5938 22.4062 15 20.5 15Z"
        :fill="selected ? '#76CCBE' : '#838389'"
      />
      <path
        d="M10 14C11.9062 14 13.5 12.4375 13.5 10.5C13.5 8.59375 11.9062 7 10 7C8.0625 7 6.5 8.59375 6.5 10.5V10.5312C6.5 12.4375 8.0625 14.0312 9.96875 14.0312C9.96875 14.0312 9.96875 14 10 14ZM12.375 15H12.125C11.4688 15.3438 10.75 15.5 10 15.5C9.21875 15.5 8.5 15.3125 7.84375 15H7.59375C5.59375 15.0312 4 16.625 4 18.625V19.5C4 20.3438 4.65625 21 5.5 21H14.5C15.3125 21 16 20.3438 16 19.5V18.625C15.9688 16.625 14.375 15.0312 12.375 15Z"
        :fill="selected ? '#177B6B' : '#3f3f41'"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    selected: { type: Boolean, default: false },
  },
};
</script>
