<template>
  <div class="row-col">
    <div class="row-col px-7 pb-2 pt-7">
      <div class="">
        <router-link to="/admin/channels2/wa_business">
          <t-button-text btn-style="secondary" class="channel--link-back bg-transparent">
            <arrow-left-linear size="1.1rem" class="mr-1.5" />
            <span>{{ $t('whatsapp.back_to_whatsapp_business_channels') }}</span>
          </t-button-text>
        </router-link>
      </div>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <div class="pb-0 md:px-4">
            <div class="p-a">
              <form @submit.prevent="save">
                <div class="box">
                  <div class="border-b border-grey-300 px-8 py-4">
                    <div class="flex flex-wrap items-center">
                      <p class="t-text-md-emphasize mb-0 pr-2 text-grey-800">
                        {{ templateTitle }}
                      </p>
                      <t-badge
                        v-if="action === TEMPLATE_ACTION.EDIT && record.status"
                        :variant="statusVariant(record.status)"
                        :text="capitalizeStatus"
                      />
                    </div>
                  </div>
                  <div class="box-divider m-a-0"></div>
                  <div class="p-4 md:p-8 md:pt-6">
                    <div class="mb-6">
                      <h4 class="t-text-h4 mb-1 text-grey-800">{{ $t('whatsapp.template_settings') }}</h4>
                      <p class="t-text-sm mb-0 text-grey-600">
                        {{ $t('whatsapp.on_this_screen_you_are_able_to_fill_out_information') }}
                      </p>
                    </div>
                    <div class="mb-6">
                      <div
                        :class="{
                          'grid grid-cols-1 space-y-6 sm:grid-cols-[55%_45%] sm:space-x-6 sm:space-y-0  xl:grid-cols-[70%_30%]':
                            shouldDisplayRecordId,
                        }"
                      >
                        <div>
                          <form-content
                            v-model="record.title"
                            :auto-focus-input="isCreateMode"
                            :error-msg="inputErrors['name'].errorMsg"
                            input-type="text"
                            input-id="template-name"
                            :is-valid-input="inputErrors['name'].isValidInput"
                            :label="$t('whatsapp.template_name')"
                            :placeholder="$t('whatsapp.template_name_placeholder')"
                            show-input-field
                            @handle-input-change="handleInputChange($event, 'name')"
                            @keyup="setSlug"
                            @paste="setSlug"
                            @blur="setSlug"
                          />
                          <p v-if="record.slug" class="t-text-sm mb-0 mt-1 pl-1 text-grey-500">
                            {{ $t('whatsapp.meta_identifier') }}: {{ record.slug }}
                          </p>
                        </div>
                        <div v-if="shouldDisplayRecordId">
                          <t-input-text
                            id="templateId"
                            v-model="record.id"
                            :label="$t('whatsapp.template_id')"
                            :icon-label="$t('whatsapp.template_id_tooltip_message')"
                            disabled
                          >
                            <template #end>
                              <t-icon-button
                                data-test="copy-button"
                                class="relative cursor-pointer"
                                @click="copyIdToClipboard"
                              >
                                <copy-linear size="1.1rem" :class="{ 'text-black': isCopyCompleted }" />
                              </t-icon-button>
                            </template>
                          </t-input-text>
                        </div>
                      </div>
                    </div>
                    <div class="mb-6">
                      <div class="mb-2">
                        <p class="t-text-md-emphasize mb-1 text-grey-800">{{ $t('whatsapp.template_channel') }}</p>
                        <p class="t-text-sm mb-0 text-grey-600">
                          {{ $t('whatsapp.select_the_channel_your_template_will_be_used') }}
                        </p>
                      </div>

                      <t-dropdown
                        v-model="record.channel_id"
                        :items="channels"
                        :disabled="shouldDisableEverything"
                        :placeholder="$t('whatsapp.select_channel')"
                        size="md"
                        value-index="id"
                        text-index="text"
                        :error="!inputErrors.channel.isValidInput"
                        container-text-ellipsis
                        data-test="template-channel-dropdown"
                      />
                      <t-error-item
                        v-if="!inputErrors.channel.isValidInput"
                        :text="$t('whatsapp.please_select_a_channel_to_enable')"
                        data-test="template-channel-error"
                      />
                      <div v-if="is360dialogWaChannel" class="alert alert-info mt-2">
                        {{ $t('whatsapp.you_can_request_new_whatsapp_template_messages_in_360_dialogs_dashboard') }}
                        <a
                          href="https://hub.360dialog.com/"
                          target="_blank"
                          rel="noopener"
                          class="font-bold hover:underline"
                        >
                          {{ $t('whatsapp.here') }}
                        </a>
                        {{
                          $t(
                            'whatsapp.after_approval_you_can_create_the_approved_template_into_trengo_by_copying_it_into_this_form'
                          )
                        }}
                      </div>
                    </div>
                    <div class="mb-6">
                      <div class="mb-2">
                        <p class="t-text-md-emphasize mb-1 text-grey-800">{{ $t('whatsapp.category') }}</p>
                        <p class="t-text-sm mb-0 text-grey-600">
                          {{ $t('whatsapp.select_the_category_your_template_fits_in') }}
                        </p>
                      </div>
                      <t-dropdown
                        v-model="record.category"
                        :items="categories"
                        :placeholder="$t('whatsapp.select_category')"
                        :disabled="shouldDisableEverything"
                        size="md"
                        value-index="value"
                        text-index="title"
                        :error="!inputErrors.category.isValidInput"
                        container-text-ellipsis
                        data-test="template-categories"
                      />
                      <t-error-item
                        v-if="!inputErrors.category.isValidInput"
                        :text="$t('whatsapp.please_select_a_category')"
                        data-test="template-category-error"
                      />

                      <t-inline-banner v-if="isOldTemplate" class="mt-2">
                        <template #icon>
                          <info-linear />
                        </template>
                        <p class="mb-0">
                          {{ $t('whatsapp.whatsapp_has_update_their_pricing') }}
                        </p>
                        <template #action>
                          <t-button
                            href="https://help.trengo.com/en/articles/375574-whatsapp-template-categories"
                            btn-style="secondary"
                            size="sm"
                            target="_blank"
                          >
                            {{ $t('whatsapp.learn_more') }}
                            <arrow-right-linear color="grey-700" size="1.25rem" class="ml-1 text-grey-700" />
                          </t-button>
                        </template>
                      </t-inline-banner>
                    </div>
                    <div class="mb-6">
                      <div class="mb-2">
                        <p class="t-text-md-emphasize mb-1 text-grey-800">{{ $t('whatsapp.language') }}</p>
                        <p class="t-text-sm mb-0 text-grey-600">
                          {{ $t('whatsapp.select_a_language_your_template_will_be_typed_in') }}
                        </p>
                      </div>
                      <t-dropdown
                        v-model="record.language"
                        :items="$root.localeCodesList"
                        :disabled="shouldDisableEverything"
                        :placeholder="$t('whatsapp.select_channel')"
                        size="md"
                        value-index="id"
                        text-index="name"
                        :error="!inputErrors.language.isValidInput"
                        container-text-ellipsis
                        data-test="template-language"
                      />
                    </div>
                    <div class="mb-6">
                      <form-content
                        v-model="templateHeader"
                        :activate-toggle="activateHeader"
                        :auto-focus-input="isCreateMode"
                        :badge-text="$t('whatsapp.optional')"
                        :banner-message="$t('whatsapp.template_header_img_info')"
                        :btn-text="$t('whatsapp.migrate_number_for_access')"
                        :disabled="shouldDisableEverything"
                        :is-multi-choice-cards-disabled="action === 'edit'"
                        :is-title-text-disabled="isFormTitleTextDisabled"
                        :is-toggle-disabled="isToggleDisabled"
                        :disable-migration-button="false"
                        :error-msg="inputErrors['header'].errorMsg"
                        :heading-text="$t('whatsapp.header')"
                        :input-type="'text'"
                        input-id="template-header"
                        :is-valid-input="inputErrors['header'].isValidInput"
                        :label="$t('whatsapp.header_text')"
                        :max-length="60"
                        :placeholder="$t('whatsapp.header_title')"
                        :selected-header-type="selectedHeaderType"
                        :show-action-button="showActionButton"
                        :show-multi-choice-cards="showFormContents('header-cards')"
                        show-template-end
                        show-title
                        :show-input-field="showFormContents('header')"
                        :should-show-badge="action === TEMPLATE_ACTION.CREATE"
                        :should-show-banner="showFormContents('header-banner')"
                        should-show-switch
                        :subtitle="$t('whatsapp.by_including_a_header')"
                        :tooltip-text="tooltipText"
                        @go-to-migration="goToMigration"
                        @handle-input-change="handleInputChange($event, 'header')"
                        @handle-selected-header="handleSelectedHeader"
                        @toggle-switch="toggleFieldSwitch($event, 'header')"
                      />
                    </div>
                    <div class="mb-6">
                      <form-title
                        :heading-text="$t('whatsapp.body')"
                        :is-toggle-disabled="isToggleDisabled"
                        :subtitle="$t('whatsapp.enter_the_text_of_your_message')"
                      />
                      <div class="gap-6 xl:flex">
                        <div class="body min-h-[224px] xl:w-1/2">
                          <t-textarea
                            id="template-body"
                            v-model="record.message"
                            class="h-full"
                            :auto-focus="isCreateMode"
                            :disabled="disableMessage || shouldDisableEverything"
                            :has-error="!inputErrors['body'].isValidInput"
                            :placeholder="$t('whatsapp.example_placeholder_for_whatsapp_template_your_order_is_ready')"
                            :rows="9"
                            :maxlength="1024"
                            @input="handleInputChange($event, 'body')"
                          />
                          <t-error-item
                            v-if="!inputErrors['body'].isValidInput"
                            class="wa-template-input__error mb-3"
                            data-test="wa-input-error"
                            :text="inputErrors['body'].errorMsg"
                          />
                        </div>
                        <div class="min-h-[224px] xl:w-1/2">
                          <template-preview
                            :buttons="buttonsData"
                            :selected-header-type="selectedHeaderType"
                            :template-body="parsedPreviewText"
                            :template-header="templateHeader"
                            :template-footer="templateFooter"
                          />
                        </div>
                      </div>
                      <div
                        v-if="selectedChannel.whatsappChannel && selectedChannel.whatsappChannel.provider === 'trengo'"
                        class="mt-4"
                      >
                        <div v-if="action === TEMPLATE_ACTION.CREATE" class="alert alert-warning">
                          {{ $t('whatsapp.you_may_submit_10_templates_for_approval_without_any_extra_charges') }}
                        </div>
                        <div
                          v-if="action === TEMPLATE_ACTION.EDIT && record.status === 'PENDING'"
                          class="alert alert-warning"
                        >
                          {{
                            $t(
                              'whatsapp.your_template_has_been_submitted_for_approval_you_will_receive_a_notification_once_the_template_has_approved'
                            )
                          }}
                        </div>
                        <div
                          v-if="action === TEMPLATE_ACTION.EDIT && record.status === 'UNDER_REVIEW'"
                          class="alert alert-warning"
                        >
                          {{
                            $t(
                              'whatsapp.your_template_is_currently_under_review_you_will_receive_a_notification_once_the_template_has_been_approved'
                            )
                          }}
                        </div>
                      </div>
                    </div>
                    <variable-sample
                      v-if="showSample"
                      :disabled="shouldDisableEverything"
                      :template-variables="templateVariables"
                      :selected-header-type="selectedHeaderType"
                      class="mb-6"
                      @update-template-variables="updateTemplateVariables"
                      @update:image="headerImageHandler"
                    />
                    <div class="mb-6">
                      <form-content
                        v-model="templateFooter"
                        :activate-toggle="activateFooter"
                        :auto-focus-input="isCreateMode"
                        :badge-text="badgeText"
                        :badge-variant="badgeVariant"
                        :btn-text="$t('whatsapp.migrate_number_for_access')"
                        :disabled="shouldDisableEverything || shouldEnableFooterWatermark"
                        :is-toggle-disabled="isToggleDisabled || shouldEnableFooterWatermark"
                        :is-title-text-disabled="isFormTitleTextDisabled"
                        :disable-migration-button="false"
                        :error-msg="inputErrors['footer'].errorMsg"
                        :heading-text="$t('whatsapp.footer')"
                        :input-type="'text'"
                        input-id="template-footer"
                        :input-tooltip-text="tooltipText || footerEnterpriseTooltipText"
                        :is-valid-input="inputErrors['footer'].isValidInput"
                        :label="$t('whatsapp.footer_text')"
                        :max-length="60"
                        :placeholder="$t('whatsapp.footer_title')"
                        :show-action-button="showActionButton"
                        show-template-end
                        show-title
                        :show-input-field="showFormContents('footer')"
                        :should-show-badge="action === TEMPLATE_ACTION.CREATE"
                        should-show-switch
                        :subtitle="$t('whatsapp.by_including_a_footer')"
                        :tooltip-text="tooltipText || footerEnterpriseTooltipText"
                        @go-to-migration="goToMigration"
                        @handle-input-change="handleInputChange($event, 'footer')"
                        @toggle-switch="toggleFieldSwitch($event, 'footer')"
                      />
                      <t-button
                        v-if="isCreateMode && shouldEnableFooterWatermark"
                        btn-style="secondary"
                        type="button"
                        class="mt-4"
                        @click="$router.push(SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS)"
                      >
                        {{ $t('whatsapp.upgrade_to_enterprise') }}
                      </t-button>
                    </div>
                    <wa-template-buttons
                      v-model="activateTemplateButtons"
                      :auto-focus-input="isCreateMode"
                      :badge-text="$t('whatsapp.optional')"
                      :buttons-data="buttonsData"
                      :btn-text="$t('whatsapp.migrate_number_for_access')"
                      :disabled="shouldDisableEverything"
                      :disable-add-new-btn="disableAddNewBtn"
                      :is-title-text-disabled="isFormTitleTextDisabled"
                      :is-toggle-disabled="disableButtonsToggle"
                      :heading-text="$t('whatsapp.buttons')"
                      :max-length="25"
                      :placeholder="$t('whatsapp.template_enter_button_text')"
                      :should-show-badge="action === TEMPLATE_ACTION.CREATE"
                      should-show-switch
                      :show-popup-menu="showPopupMenu"
                      :show-action-button="showActionButton"
                      show-template-end
                      :subtitle="$t('whatsapp.create_up_to_three_quick_reply_button')"
                      :tooltip-text="tooltipText"
                      @add-new-button="addNewButton"
                      @go-to-migration="goToMigration"
                      @handle-delete-button="handleDeleteButton"
                      @handle-change-value="handleChangeValue"
                    />
                  </div>
                </div>
                <action-buttons
                  :action="action"
                  :disable-create-button="disableCreateButton"
                  :saving="saving"
                  @delete-wa-template="deleteWaTemplate"
                  @go-to-overview-page="goToOverviewPage"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { ArrowLeftLinear, InfoLinear, ArrowRightLinear, CrossLinear, CopyLinear } from '@trengo/trengo-icons';
import { filter, head, capitalize, uniq } from 'lodash';

import Constants, { FEATURE_FLAG_CHANNELS } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import { useFeatureFlagStore } from '@/store/pinia';
import { copyToClipboard, duplicateObject } from '@/util/helpers';

import ActionButtons from './ActionButtons';
import FormContent from './FormContent/FormContent';
import FormTitle from './FormTitle/FormTitle.vue';
import TemplatePreview from './TemplatePreview/TemplatePreview.vue';
import VariableSample from './VariableSample';
import WaTemplateButtons from './WaTemplateButtons';

const {
  WhatsappProviders,
  WA_TEMPLATE_COMPONENT,
  TEMPLATE_FIELDS,
  TEMPLATE_STATUS,
  TEMPLATE_ACTION,
  TEMPLATE_FORM_CONTENTS,
  TEMPLATE_HEADER_TYPE,
} = Constants;

export default {
  name: 'WaTemplateEdit',
  components: {
    CrossLinear,
    ArrowLeftLinear,
    FormContent,
    WaTemplateButtons,
    FormTitle,
    InfoLinear,
    ArrowRightLinear,
    ActionButtons,
    TemplatePreview,
    VariableSample,
    CopyLinear,
  },
  props: {
    action: {
      type: String,
      default: TEMPLATE_ACTION.CREATE,
    },
  },
  created() {
    this.TEMPLATE_ACTION = TEMPLATE_ACTION;
  },
  data() {
    return {
      loaded: false,
      isCopyCompleted: false,
      record: {
        title: '',
        slug: '',
        message: '',
        category: '',
        language: 'nl',
        channel_id: null,
        components: [
          {
            type: WA_TEMPLATE_COMPONENT.header,
            sub_type: TEMPLATE_HEADER_TYPE.TEXT.toUpperCase(),
            value: '',
          },
          {
            type: WA_TEMPLATE_COMPONENT.footer,
            value: '',
          },
        ],
        created_at: '',
      },
      previewHeaderImg: {
        file: null,
        url: null,
      },
      initialRecord: null,
      saving: false,
      buttonsData: [{ id: 1, text: '', error: '', isValid: true }],
      activateTemplateButtons: false,
      activateHeader: false,
      activateFooter: false,
      inputErrors: {
        header: { isValidInput: true, errorMsg: '' },
        footer: { isValidInput: true, errorMsg: '' },
        body: { isValidInput: true, errorMsg: '' },
        category: { isValidInput: true, errorMsg: '' },
        name: { isValidInput: true, errorMsg: '' },
        channel: { isValidInput: true, errorMsg: '' },
        language: { isValidInput: true, errorMsg: '' },
      },
      categories: [
        {
          value: 'MARKETING',
          title: this.$t('whatsapp.marketing_conversation'),
          subtitle: this.$t('whatsapp.business_initated_conversations_marketing'),
        },
        {
          value: 'UTILITY',
          title: this.$t('whatsapp.utility_conversations'),
          subtitle: this.$t('whatsapp.business_initated_conversations_utility'),
        },
        {
          value: 'AUTHENTICATION',
          title: this.$t('whatsapp.authentication_conversations'),
          subtitle: this.$t('whatsapp.business_initated_conversations_authentication'),
        },
      ],
      selectedHeaderType: TEMPLATE_HEADER_TYPE.TEXT,
      previewText: '',
      rawVariables: [],
      templateVariables: [],
      SETTINGS_URL,
    };
  },
  computed: {
    channels() {
      return filter(this.$root.channels, (c) => {
        return (
          c.type === 'WA_BUSINESS' &&
          ['messagebird', 'twilio', 'trengo', 'trengo_bsp', '360dialog'].includes(c.whatsappChannel.provider)
        );
      });
    },
    selectedChannel() {
      const selectedChannels = filter(this.$root.channels, (c) => c.id === this.record.channel_id);
      if (selectedChannels.length) {
        return head(selectedChannels);
      } else {
        return selectedChannels;
      }
    },
    shouldDisableEverything() {
      return this.action === TEMPLATE_ACTION.EDIT;
    },
    disableMessage() {
      if (!this.selectedChannel || !this.selectedChannel.whatsappChannel) {
        return false;
      }

      return this.selectedChannel.whatsappChannel.provider === 'trengo' && this.action === TEMPLATE_ACTION.EDIT;
    },
    is360dialogWaChannel() {
      return this.selectedChannel?.whatsappChannel?.provider === '360dialog';
    },
    isDirty() {
      return this.initialRecord !== null && !window.isEqualObject(this.initialRecord, this.record);
    },
    disableCreateButton() {
      return this.saving || !this.ready_to_save || (this.action === TEMPLATE_ACTION.EDIT && !this.isDirty);
    },
    saveButtonTitle() {
      if (this.saving) {
        return this.$t('whatsapp.saving_button');
      } else if (this.action === TEMPLATE_ACTION.EDIT) {
        return this.$t('whatsapp.save_changes_button');
      }

      return this.$t('whatsapp.create_template_button');
    },
    showPopupMenu() {
      return this.buttonsData.length > 1;
    },
    disableAddNewBtn() {
      return this.buttonsData.length === 3 || this.shouldDisableEverything;
    },
    showActionButton() {
      return (
        this.isCreateMode &&
        !Array.isArray(this.selectedChannel) &&
        this.selectedChannel?.whatsappChannel?.provider !== WhatsappProviders.TRENGO_BSP.id
      );
    },
    noChannelSelected() {
      return this.selectedChannel?.length === 0;
    },
    disableButtonsToggle() {
      return this.noChannelSelected || this.shouldDisableEverything || !this.isTrengoChannel;
    },
    hasEmptyButtonInput() {
      return this.activateTemplateButtons ? this.buttonsData.some((item) => item.text === '') : false;
    },
    isToggleDisabled() {
      return !this.isTrengoChannel || this.noChannelSelected || this.shouldDisableEverything;
    },
    isCreateMode() {
      return this.action === TEMPLATE_ACTION.CREATE;
    },
    isTrengoChannel() {
      return !this.noChannelSelected && this.selectedChannel.whatsappChannel?.provider === 'trengo_bsp';
    },
    footer() {
      return this.record.components?.find((item) => item.type === WA_TEMPLATE_COMPONENT.footer);
    },
    header() {
      return this.record.components?.find((item) => item.type === WA_TEMPLATE_COMPONENT.header);
    },
    validateInput() {
      let isValid = true;

      const inputErrorArr = Object.values(this.inputErrors);
      const hasInvalidInput = inputErrorArr.some((item) => !item.isValidInput);

      if (this.hasEmptyButtonInput) {
        isValid = false;
      }
      if (this.hasDuplicateBtnText()) {
        isValid = false;
      }
      if (!this.record.message) {
        isValid = this.isValidBodyInput();
      }
      if (!this.record.category) {
        isValid = this.validateCategory();
      }
      if (hasInvalidInput) {
        isValid = false;
      }
      return isValid;
    },
    templateHeader: {
      get() {
        return this.selectedHeaderType !== 'image' ? this.header?.value : this.previewHeaderImg.url;
      },
      set(value) {
        const headerIndex = this.record.components?.indexOf(this.header);
        this.record.components.splice(headerIndex, 1, {
          type: WA_TEMPLATE_COMPONENT.header,
          value: value,
          sub_type: this.selectedHeaderType.toUpperCase(),
        });
      },
    },
    templateFooter: {
      get() {
        if (!this.isCreateMode) {
          return this.shouldEnableFooterWatermark && !this.footer?.value
            ? this.$t('whatsapp.delivered_by_trengo')
            : this.footer?.value;
        }
        return this.shouldEnableFooterWatermark ? this.$t('whatsapp.delivered_by_trengo') : this.footer?.value;
      },
      set(value) {
        const footerIndex = this.record.components?.indexOf(this.footer);
        this.record.components.splice(footerIndex, 1, { type: WA_TEMPLATE_COMPONENT.footer, value: value });
      },
    },
    templateTitle() {
      if (this.action === TEMPLATE_ACTION.CREATE) {
        return this.record.title ? this.record.title : this.$t('whatsapp.new_whatsapp_message_template');
      }

      return this.record.title;
    },
    isOldTemplate() {
      const { created_at } = this.record;
      if (!created_at) {
        return false;
      }
      const cutoffDate = moment('2023-04-01');
      const recordDate = moment(created_at);
      return recordDate.isBefore(cutoffDate);
    },
    capitalizeStatus() {
      return capitalize(this.record.status);
    },
    hasEmptyVariableSample() {
      return this.templateVariables.some((item) => !item.value);
    },
    parsedPreviewText() {
      let msg = this.previewText;
      for (const { key, value } of this.templateVariables) {
        if (value?.length) {
          msg = msg.replace(key, value);
        }
      }
      return msg;
    },
    isDeliveredByTrengoEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_CHANNELS.POWERED_BY_TRENGO);
    },
    shouldEnableFooterWatermark() {
      return this.isDeliveredByTrengoEnabled && this.isTrengoChannel && !this.$userPlan().isEnterprise;
    },
    badgeText() {
      return this.shouldEnableFooterWatermark ? this.$t('whatsapp.enterprise_only') : this.$t('whatsapp.optional');
    },
    badgeVariant() {
      return this.shouldEnableFooterWatermark ? 'warning' : 'default';
    },
    isFormTitleTextDisabled() {
      return this.isCreateMode && !this.noChannelSelected && !this.isTrengoChannel;
    },
    tooltipText() {
      if (this.noChannelSelected) {
        return this.$t('whatsapp.please_select_a_channel_to_enable');
      }
      if (!this.isTrengoChannel) {
        return this.$t('whatsapp.migrate_your_whatsapp_number');
      }
      return '';
    },
    footerEnterpriseTooltipText() {
      return this.shouldEnableFooterWatermark && this.isCreateMode
        ? this.$t('whatsapp.you_are_only_able_to_edit_or_remove_the_footer')
        : '';
    },
    showSample() {
      return this.isCreateMode && (this.templateVariables.length > 0 || this.selectedHeaderType === 'image');
    },
    ready_to_save() {
      const { title, slug, message, channel_id, category } = this.record;
      let validHeaderValue = true;

      if (this.activateHeader && this.isCreateMode) {
        validHeaderValue =
          this.selectedHeaderType === TEMPLATE_HEADER_TYPE.TEXT
            ? this.activateHeader && this.header.value && this.header.value.length > 0
            : this.activateHeader && this.previewHeaderImg.file && this.previewHeaderImg.url;
      }

      return !!(
        title &&
        slug &&
        message &&
        channel_id !== null &&
        category &&
        !this.hasEmptyVariableSample &&
        validHeaderValue &&
        this.validateInput
      );
    },
    shouldDisplayRecordId() {
      return this.action === TEMPLATE_ACTION.EDIT && this.record.id;
    },
  },

  mounted() {
    if (this.action === TEMPLATE_ACTION.EDIT) {
      axios.get('/api/v2/wa_templates/' + this.$route.params.id).then(async (result) => {
        let data = result.data;
        this.buttonsData =
          data.components
            ?.filter((item) => item.type === WA_TEMPLATE_COMPONENT.buttons)
            .map((button) => {
              return { id: button.id, text: button.value, error: '', isValid: true };
            }) || [];

        this.record = data;
        // When header component available and of type image set selected header type
        const headerComponent = data.components.find((item) => item.type === WA_TEMPLATE_COMPONENT.header);
        if (headerComponent) {
          this.selectedHeaderType =
            headerComponent.sub_type === TEMPLATE_HEADER_TYPE.IMAGE.toUpperCase()
              ? TEMPLATE_HEADER_TYPE.IMAGE
              : TEMPLATE_HEADER_TYPE.TEXT;
        }

        this.initialRecord = duplicateObject(this.record);
      });
    }

    if (this.$route.query.channel_id) {
      this.record.channel_id = this.$route.query.channel_id;
    }
  },
  methods: {
    save() {
      if (this.saving) {
        return;
      }
      if (!this.validateInput) return;

      this.saving = true;

      if (this.action === TEMPLATE_ACTION.CREATE) {
        return this.storeWaTemplate();
      } else {
        this.updateWaTemplate();
      }
    },
    /**
     * @param {object} file
     * @returns {number} mediaId
     */
    async saveMedia(file) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        const { data } = await axios.post('api/v2/upload?type=whatsapp_hsm', formData);
        return data.id;
      } catch (error) {
        console.error(error);
      }
    },

    async storeWaTemplate() {
      if (this.selectedHeaderType === TEMPLATE_HEADER_TYPE.IMAGE && this.previewHeaderImg.file) {
        const mediaId = await this.saveMedia(this.previewHeaderImg.file);
        const headerIndex = this.record.components?.indexOf(this.header);
        this.record.components.splice(headerIndex, 1, {
          type: WA_TEMPLATE_COMPONENT.header,
          value: mediaId,
          sub_type: this.selectedHeaderType.toUpperCase(),
        });
      }

      let payload = { ...this.record };
      let buttons;
      if (this.buttonsData[0].text) {
        buttons = this.buttonsData.map((button) => {
          return {
            type: WA_TEMPLATE_COMPONENT.buttons,
            sub_type: 'QUICK_REPLY',
            value: button.text,
          };
        });

        payload.components = payload.components.filter((item) => item.type !== WA_TEMPLATE_COMPONENT.buttons);
        payload.components = [...payload.components, ...buttons];
      }

      if (!this.templateHeader) {
        payload.components = payload.components.filter((item) => item.type !== WA_TEMPLATE_COMPONENT.header);
      }

      if (!this.templateFooter) {
        payload.components = payload.components.filter((item) => item.type !== WA_TEMPLATE_COMPONENT.footer);
      }

      if (this.templateVariables.length > 0) {
        payload.variable_examples = this.templateVariables.map((item) => item.value);
      }

      axios
        .post('/api/v2/wa_templates', payload)
        .then((res) => {
          this.saving = false;
          eventBus.$emit('trigger-loadlist');
          this.$router.push('/admin/wa_templates/' + res.data.id);
          this.flashSuccess(this.$t('whatsapp.the_template_has_been_created_successfully'));
        })
        .catch(() => {
          this.saving = false;
        });
    },

    updateWaTemplate() {
      axios
        .put('/api/v2/wa_templates/' + this.record.id, this.record)
        .then((res) => {
          this.saving = false;
          eventBus.$emit('trigger-loadlist');
          this.flashSuccess(this.$t('whatsapp.the_template_has_been_updated_successfully'));
          this.updateInitialRecord();
        })
        .catch(() => {
          this.saving = false;
        });
    },

    async deleteWaTemplate() {
      try {
        const shouldDeleteTemplate = await this.$tConfirm(
          this.$t('whatsapp.are_you_sure_you_want_to_delete_this_template'),
          {
            delete: true,
            title: this.$t('whatsapp.are_you_sure'),
          }
        );
        if (shouldDeleteTemplate) {
          axios.delete('/api/v2/wa_templates/' + this.record.id).then(() => {
            eventBus.$emit('trigger-loadlist');
            this.$router.push('/admin/wa_templates');
            this.flashSuccess(this.$t('whatsapp.the_template_has_been_deleted_successfully'));
          });
        }
      } catch (error) {
        console.error(error);
        this.flashError(this.$t('whatsapp.the_template_has_been_deleted_successfully'));
      }
    },

    setSlug() {
      if (this.action !== TEMPLATE_ACTION.CREATE) {
        return;
      }
      this.record.slug = this.record.title.replace(/\s+/g, '_').toLowerCase();
    },
    updateInitialRecord() {
      this.initialRecord = duplicateObject(this.record);
    },
    addNewButton() {
      const btnCount = this.buttonsData.length;
      const id = btnCount + 1;
      const canAddBtn = btnCount < 3;

      if (!canAddBtn) {
        return;
      }

      const btnObj = { id, text: '', error: '', isValid: true };
      this.buttonsData.push(btnObj);
    },
    handleDeleteButton(id, currIndex) {
      this.buttonsData = this.buttonsData.filter((item, index) => index !== currIndex);
      this.hasDuplicateBtnText();
    },
    validateEmptyButtonInput() {
      this.buttonsData = this.buttonsData.map((item) => {
        if (item.text.trim() === '') {
          return {
            ...item,
            isValid: false,
            error: this.$t('whatsapp.please_enter_button_text_or_delete_before_create_template'),
          };
        }
        return item;
      });
    },
    hasDuplicateBtnText() {
      let mapObj = {};
      let duplicatesFound = false;
      this.buttonsData.forEach((item) => {
        const trimmedText = item.text.trim().toLowerCase();
        if (trimmedText !== '' && !(trimmedText in mapObj)) {
          mapObj[trimmedText] = false;
          item.error = '';
          item.isValid = true;
        } else if (trimmedText !== '' && trimmedText in mapObj) {
          mapObj[trimmedText] = true;
          duplicatesFound = true;
        }
      });

      this.buttonsData.forEach((item) => {
        const trimmedText = item.text.trim().toLowerCase();
        if (mapObj[trimmedText]) {
          item.error = this.$t('whatsapp.buttons_can_not_contain_same_text_as_another');
          item.isValid = false;
        }
      });

      return duplicatesFound;
    },
    handleSelectedHeader(val) {
      this.selectedHeaderType = val;
      // Clearing header value
      const headerIndex = this.record.components?.indexOf(this.header);
      this.record.components.splice(headerIndex, 1, {
        type: WA_TEMPLATE_COMPONENT.header,
        value: '',
        sub_type: this.selectedHeaderType.toUpperCase(),
      });

      // Clear preview
      this.previewHeaderImg.url = null;
      this.previewHeaderImg.file = null;
    },
    handleChangeValue(e, btn, currIndex) {
      const value = btn.text;
      const trimmedText = this.buttonsData[currIndex].text.trim();
      if (trimmedText !== '') {
        this.buttonsData.splice(currIndex, 1, {
          id: btn.id,
          text: value,
          error: '',
          isValid: true,
        });
        this.hasDuplicateBtnText();
      } else if (trimmedText === '') {
        this.buttonsData.splice(currIndex, 1, {
          id: btn.id,
          text: btn.text,
          error: this.$t('whatsapp.please_enter_button_text_or_delete_before_create_template'),
          isValid: false,
        });
      }
    },
    toggleFieldSwitch(val, field) {
      switch (field) {
        case TEMPLATE_FIELDS.header:
          this.activateHeader = val;
          break;
        case TEMPLATE_FIELDS.footer:
          this.activateFooter = val;
          break;
        default:
          return;
      }
    },
    setInputErrors(isValidInput, field, msg = '') {
      if (!isValidInput) {
        this.inputErrors = Object.assign({}, this.inputErrors, {
          [field]: {
            isValidInput: false,
            errorMsg: this.$t(msg),
          },
        });
      } else if (isValidInput) {
        this.inputErrors = Object.assign({}, this.inputErrors, {
          [field]: { isValidInput: true, errorMsg: '' },
        });
      }
    },
    handleInputOnChangeValidation(val = '', field = '') {
      const unsupportedCharacters = /[{}]/;
      const hasUnsupportedCharacters = unsupportedCharacters.test(val);
      if (field === TEMPLATE_FIELDS.header) {
        if (hasUnsupportedCharacters) {
          this.setInputErrors(false, TEMPLATE_FIELDS.header, this.$t('whatsapp.it_is_currently_not_possible_header'));
        } else if (val.trim() === '') {
          this.setInputErrors(
            false,
            TEMPLATE_FIELDS.header,
            this.$t('whatsapp.please_enter_header_text_before_creating_template')
          );
        } else {
          this.setInputErrors(true, TEMPLATE_FIELDS.header);
        }
      }

      if (field === TEMPLATE_FIELDS.footer) {
        if (hasUnsupportedCharacters) {
          this.setInputErrors(false, TEMPLATE_FIELDS.footer, this.$t('whatsapp.it_is_not_possible_footer'));
        } else if (val.trim() === '') {
          this.setInputErrors(
            false,
            TEMPLATE_FIELDS.footer,
            this.$t('whatsapp.please_enter_footer_text_before_creating_template')
          );
        } else {
          this.setInputErrors(true, TEMPLATE_FIELDS.footer);
        }
      }

      if (field === TEMPLATE_FIELDS.body) {
        this.isValidBodyInput(val);
      }
    },
    isValidBodyInput(val) {
      let isValid = true;
      let errorMsg = '';
      if (!this.record.message || !this.record.message.trim()) {
        isValid = false;
        errorMsg = 'whatsapp.template_must_have_a_message_body';
      } else if (this.rawVariables.length !== uniq(this.rawVariables).length) {
        errorMsg = 'whatsapp.template_message_cannot_have_duplicate_variables';
        isValid = false;
      } else if (
        (val.match(/(\{\{\}\})/g) && val.match(/(\{\{\}\})/g).length > 0) || // can't be {{}}
        (val.match(/(\{\})/g) && val.match(/(\{\})/g).length > 0) || // can't be {}
        (val.match(/(\{\{0\}\})/g) && val.match(/(\{\{0\}\})/g).length > 0) || // can't be {{0}}
        (val.match(/(\{\{ \}\})/g) && val.match(/(\{\{ \}\})/g).length > 0) // can't be {{ }}
      ) {
        errorMsg = 'whatsapp.template_message_invalid_var';
        isValid = false;
      } else {
        isValid = true;
      }

      this.setInputErrors(isValid, TEMPLATE_FIELDS.body, this.$t(errorMsg));
      return isValid;
    },
    validateCategory() {
      if (!this.record.category) {
        this.setInputErrors(false, TEMPLATE_FIELDS.category, this.$t('whatsapp.select_category'));
        return false;
      }
      this.setInputErrors(true, TEMPLATE_FIELDS.category);
      return true;
    },
    handleInputChange(event, field) {
      this.handleInputOnChangeValidation(event.target.value, field);
    },
    goToMigration() {
      this.$router.push('/admin/channels2/wa_business/' + this.record.channel_id);
    },
    showFormContents(field) {
      const isActiveHeader = this.activateHeader && this.isTrengoChannel;
      const isActiveFooter = this.activateFooter && this.isTrengoChannel;

      if (this.isCreateMode) {
        switch (field) {
          case TEMPLATE_FIELDS.header:
            return isActiveHeader && this.selectedHeaderType === TEMPLATE_HEADER_TYPE.TEXT;
          case TEMPLATE_FIELDS.footer:
            return isActiveFooter;
          case TEMPLATE_FORM_CONTENTS.HEADER_MULTI_CHOICE_CARDS:
            return isActiveHeader;
          case TEMPLATE_FORM_CONTENTS.HEADER_BANNER:
            return isActiveHeader && this.selectedHeaderType === TEMPLATE_HEADER_TYPE.IMAGE;
          default:
            return false;
        }
      }
      if (!this.isCreateMode) {
        switch (field) {
          case TEMPLATE_FIELDS.header:
            return !!this.templateHeader && this.selectedHeaderType === TEMPLATE_HEADER_TYPE.TEXT;
          case TEMPLATE_FIELDS.footer:
            return !!this.templateFooter;
          case TEMPLATE_FORM_CONTENTS.HEADER_MULTI_CHOICE_CARDS:
            return !!this.templateHeader;
          case TEMPLATE_FORM_CONTENTS.HEADER_BANNER:
            return !!this.templateHeader && this.selectedHeaderType === TEMPLATE_HEADER_TYPE.IMAGE;
          default:
            return false;
        }
      }

      return false;
    },
    statusVariant(status) {
      switch (status) {
        case TEMPLATE_STATUS.ACCEPTED:
          return 'success';
        case TEMPLATE_STATUS.DECLINED:
          return 'notification';
        default:
          return 'default';
      }
    },
    goToOverviewPage() {
      this.$router.push('/admin/wa_templates');
    },
    setTemplateVariables(message) {
      // get array of variables from the message string
      this.rawVariables = message.match(/(\{\{[1-9]+)\}\}/g) || [];

      // check for existing keys in this.templateVariables.
      const existingKeys = new Set(this.templateVariables.map((obj) => obj.key));

      // get the newVariables that don't exist in this.templateVariables
      const newVariables = this.rawVariables.filter((key) => !existingKeys.has(key)).map((key) => ({ key, value: '' }));

      //filter out items item in the templateVariables array whose key does not match any item in the rawVariables array.
      const uniqueVariables = this.templateVariables.filter((obj) => this.rawVariables.includes(obj.key));

      // concat the newVariables array, and the uniqueVariables array
      // sort the returned array in ascending order.
      const variables = [...uniqueVariables, ...newVariables].sort((a, b) => {
        const numA = Number(a.key.match(/\d+/)[0]);
        const numB = Number(b.key.match(/\d+/)[0]);
        return numA - numB;
      });
      this.templateVariables = variables;
    },
    updateTemplateVariables(templateVariables) {
      this.templateVariables = templateVariables;
    },
    headerImageHandler(imageFiles) {
      let imgURL;
      if (imageFiles[0] instanceof File) {
        imgURL = URL.createObjectURL(imageFiles[0]);
      }
      this.previewHeaderImg.url = imgURL;
      this.previewHeaderImg.file = imageFiles[0];
    },
    copyIdToClipboard() {
      window.copyToClipboard(this.record.id);
      this.isCopyCompleted = true;

      setTimeout(() => {
        this.isCopyCompleted = false;
      }, 2000);
    },
  },
  watch: {
    activateTemplateButtons(val) {
      if (val === false) {
        this.buttonsData = [{ id: 1, text: '', error: '', isValid: true }];
      }
    },
    isTrengoChannel(val) {
      if (val === false) {
        this.activateTemplateButtons = false;
        this.activateFooter = false;
        this.activateHeader = false;
      }
      if (this.shouldEnableFooterWatermark) {
        this.activateFooter = true;
      }
    },
    record(val) {
      val.components?.map((item) => {
        if (item.type === WA_TEMPLATE_COMPONENT.buttons) {
          this.activateTemplateButtons = true;
        }
        if (item.type === WA_TEMPLATE_COMPONENT.header) {
          this.activateHeader = true;
        }
        if (item.type === WA_TEMPLATE_COMPONENT.footer) {
          this.activateFooter = true;
        }
      });
    },
    activateFooter(val) {
      if (val === false) {
        const footerIndex = this.record.components?.indexOf(this.footer);
        this.record.components.splice(footerIndex, 1, {
          type: WA_TEMPLATE_COMPONENT.footer,
          value: '',
        });
        this.setInputErrors(true, TEMPLATE_FIELDS.footer);
      }
    },
    activateHeader(val) {
      if (val === false) {
        const headerIndex = this.record.components?.indexOf(this.header);
        const components = [...this.record.components];
        components[headerIndex] = {
          type: WA_TEMPLATE_COMPONENT.header,
          value: '',
        };

        this.record.components.splice(headerIndex, 1, {
          type: WA_TEMPLATE_COMPONENT.header,
          value: '',
        });

        this.setInputErrors(true, TEMPLATE_FIELDS.header);
      }
    },
    'record.category': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setInputErrors(true, TEMPLATE_FIELDS.category);
      }
    },
    'record.message': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.previewText = newValue;
        if (this.isCreateMode) {
          this.setTemplateVariables(newValue);
        }
      }
    },
    shouldEnableFooterWatermark(val) {
      if (val === true) {
        const footerIndex = this.record.components?.indexOf(this.footer);
        this.record.components.splice(footerIndex, 1, {
          type: WA_TEMPLATE_COMPONENT.footer,
          value: this.$t('whatsapp.delivered_by_trengo'),
        });
      }
    },
    hasEmptyButtonInput(value) {
      if (value === true) {
        this.validateEmptyButtonInput();
      }
    },
  },
};
</script>
