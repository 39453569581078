<template>
  <div>
    <div class="mb-2 text-xl font-bold leading-7 text-grey-900">
      {{ $t('whatsapp.phone_number_for_whatsapp_business_api') }}
    </div>
    <div class="mb-4">
      {{ $t('whatsapp.your_whatsapp_channel_needs_a_phone_number_with_the_following_specifications') }}
    </div>
    <div>
      <ul class="my-4 list-disc">
        <li>{{ $t('whatsapp.this_number_must_be_your_companys_business_number_dont_use_your_private_number') }}</li>
        <li>{{ $t('whatsapp.this_number_must_be_able_to_receive_phone_calls_and_text_messages') }}</li>
      </ul>
    </div>

    <div class="mb-12 mt-6 leading-6" v-html="$t('whatsapp.disclaimer_after_connecting_to_whatsapp_business_api')" />

    <div
      class="
        mb-8
        flex
        w-full
        cursor-pointer
        select-none
        items-center
        rounded-xl
        bg-grey-200
        p-8
        leading-normal
        text-grey-900
      "
      @click.prevent="checkboxToggle"
    >
      <t-input-checkbox v-model="computedValue" class="checkbox-validation mb-0 mr-3 cursor-pointer" />
      {{ $t('whatsapp.i_have_a_phone_number_that_meets_all_the_above_conditions') }}
    </div>

    <div class="mb-6">
      {{ $t('whatsapp.dont_have_a_phone_number_that_meets_all_the_above_conditions') }}
      <span class="cursor-pointer text-leaf-500 hover:text-leaf-500" @click="openWidget">
        {{ $t('whatsapp.contact_customer_service') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepThree',
  emits: ['update:modelValue', 'openWidget'],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
  methods: {
    checkboxToggle() {
      this.computedValue = !this.computedValue;
    },
    openWidget() {
      this.$emit('openWidget');
    },
  },
};
</script>

<style scoped lang="scss">
.checkbox-validation {
  margin-top: -3px;
}
</style>
