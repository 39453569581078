<script type="text/babel">
import TicketCell from '../components/Reporting/Components/TicketCell.vue';

export default {
  name: 'AgTicketCell',
  components: { TicketCell },
  props: {
    params: {},
  },
};
</script>

<template>
  <span>
    <ticket-cell :router="$props.params.router" :ticket="$props.params.ticket" />
  </span>
</template>
