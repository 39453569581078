<template>
  <t-button
    v-if="generateSummaryStore.isActive"
    :disabled="generateSummaryStore.status === 'LOADING' || generateSummaryStore.status === 'SUCCESS'"
    type="button"
    size="sm"
    btn-style="secondary"
    class="flex items-center"
    data-test="suggest-reply-cta"
    @click="handleClick"
  >
    <t-spinner v-if="generateSummaryStore.status === 'LOADING'" class="me-1" size="1rem" />
    <TaskAutomationFill v-else class="me-1 size-5" />
    <span class="text-nowrap">
      {{
        generateSummaryStore.status === 'LOADING'
          ? $t('tickets.generate_summary__loading')
          : $t('tickets.generate_summary')
      }}
    </span>
  </t-button>
  <div v-if="generateSummaryStore.status === 'FAILED'" class="flex flex-nowrap items-center gap-1 text-red-dark">
    <AlertTriangleFill class="me-1 size-5" />
    <span>{{ $t('tickets.suggest_reply_error') }}</span>
  </div>
</template>

<script setup lang="ts">
import { AlertTriangleFill, TaskAutomationFill } from '@trengo/trengo-icons';

import { useGenerateSummaryStore } from './useGenerateSummaryStore';

const props = defineProps<{
  ticketId: number;
}>();

const emit = defineEmits<{
  (e: 'generate-summary'): void;
}>();

const generateSummaryStore = useGenerateSummaryStore();
generateSummaryStore.getIsSummaryAvailable(props.ticketId);

const handleClick = () => {
  generateSummaryStore.generateSummary(props.ticketId);
  emit('generate-summary');
};
</script>
