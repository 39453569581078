<script type="text/babel">
import { map, toArray } from 'lodash';
import Multiselect from 'vue-multiselect';

import eventBus from '@/eventBus';

export default {
  name: 'CreateChatbotModal',
  data() {
    return {
      channels: [],
      languages: [],
      loading: false,
      form: {
        title: 'Chatbot',
        v2_key: null,
      },
    };
  },
  components: {
    Multiselect,
  },

  mounted() {
    this.resetForm();
    axios.get('/api/v2/chatbot/languages').then((res) => (this.languages = res.data));
    eventBus.$on('chatbots.list.reload', this.loadChannels);
  },

  computed: {
    sanitizedTextPasteYourGoogleCloud() {
      return window.lokaliseHTMLSanitizer(this.$t('chatbot.paste_your_google_cloud_json_key_here_learn_how'));
    },
  },

  methods: {
    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios
        .post('/api/v2/chatbot', {
          channels: toArray(map(this.form.channels, 'id')),
          title: this.form.title,
          culture: this.form.culture.code,
          v2_key: this.form.v2_key,
        })
        .then((res) => {
          this.resetForm();
          router.push('/chatbots/' + res.data.id + '/questions');
          $('#CreateChatbotModal').modal('hide');
          eventBus.$emit('chatbots.list.reload');
          this.loading = false;
        })
        .catch((e) => {
          alert('Please try again');
          this.loading = false;
        });
    },

    loadChannels() {
      axios.get('/api/v2/chatbot/channels').then((res) => (this.channels = res.data));
    },

    resetForm() {
      this.loadChannels();

      this.form = {
        title: 'Chatbot',
        v2_key: null,
      };
    },
  },
};
</script>

<template>
  <div id="CreateChatbotModal" class="modal" role="dialog">
    <form @submit.prevent="save">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('chatbot.create_new_chatbot') }}</h5>
            <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label class="control-label">{{ $t('chatbot.internal_title') }}</label>
              <input v-model="form.title" type="text" required class="form-control" />
            </div>
            <div class="form-group">
              <label class="control-label">{{ $t('chatbot.chatbot_language') }}</label>
              <multiselect
                v-model="form.culture"
                label="name"
                track-by="code"
                :allow-empty="false"
                :options="languages"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                :multiple="false"
                :placeholder="$t('chatbot.select_a_language')"
              ></multiselect>
            </div>
            <div class="form-group">
              <label class="control-label">{{ $t('chatbot.channels') }}</label>
              <multiselect
                v-model="form.channels"
                label="display_name"
                track-by="id"
                :allow-empty="true"
                :options="channels"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                :multiple="true"
                :placeholder="$t('chatbot.select_one_or_more_channels')"
              ></multiselect>
            </div>
            <div class="form-group">
              <label class="control-label">{{ $t('chatbot.google_cloud_key') }}</label>
              <textarea v-model="form.v2_key" required class="form-control" rows="6"></textarea>
              <span class="form-text" v-html="sanitizedTextPasteYourGoogleCloud" />
            </div>
            <!--<div class="form-group">-->
            <!--<label class="control-label">Languages:</label>-->
            <!--<multiselect v-model="selectedLanguages" label="text" track-by="id" :allow-empty="false" :options="languages" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" placeholder="Select one or more languages..."></multiselect>-->
            <!--</div>-->
            <!--<div class="form-group">-->
            <!--<label class="control-label">Default language:</label>-->
            <!--<div v-for="lang in selectedLanguages">-->
            <!--<label>-->
            <!--<input type="radio" v-model="defaultLanguage" :value="lang.id" /> {{ lang.text }}-->
            <!--</label>-->
            <!--</div>-->
            <!--</div>-->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn white" data-dismiss="modal" aria-label="Close">
              {{ $t('chatbot.cancel') }}
            </button>
            <button
              type="submit"
              class="btn success text-white"
              :disabled="form.title == '' || form.channels == null || form.culture == null"
            >
              <span v-if="loading"><i class="fa fa-spin fa-spinner"></i></span>
              <span v-if="!loading">{{ $t('chatbot.create_chatbot') }}</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
