import moment from 'moment';

type elements = {
  id: number;
};

export function normalizeDate(date: string) {
  return moment(decodeURIComponent(date)).format('YYYY-MM-DD');
}

export function sortActiveElementsFirst(elements: elements[], selectedItems: number[]) {
  const orderedElements = elements.sort((a, b) => {
    const aIsActive = selectedItems.includes(a.id);
    const bIsActive = selectedItems.includes(b.id);

    if (aIsActive && !bIsActive) return -1;
    if (!aIsActive && bIsActive) return 1;
    return 0;
  });

  return orderedElements;
}
