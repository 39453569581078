<template>
  <div data-test="time-entry-wrapper">
    <div class="flex flex-row items-center justify-center">
      <input
        ref="timeTrackingHours"
        v-model="hours"
        maxlength="2"
        class="
          w-12
          rounded-md
          border border-grey-300 border-opacity-30
          px-2
          py-1
          transition-colors
          focus:border-leaf-500
        "
        placeholder="0"
        data-test="timetracking-hour-input"
        @keydown.enter.prevent="isEditing ? editTimeEntry : saveTimeEntry"
      />
      <span class="t-text-lg-emphasize mx-2 select-none">:</span>
      <input
        ref="timeTrackingMinutes"
        v-model="minutes"
        maxlength="2"
        class="
          w-12
          rounded-md
          border border-grey-300 border-opacity-30
          px-2
          py-1
          transition-colors
          focus:border-leaf-500
        "
        placeholder="0"
        data-test="timetracking-minute-input"
        @keydown.enter.prevent="isEditing ? editTimeEntry : saveTimeEntry"
      />

      <span class="t-text-md mx-2 select-none text-grey-700">{{ $tc('general.date_at') }}</span>

      <date-time-picker2 v-model="date" class="!w-1/3" :config="timePickerConfig" />
    </div>

    <h4 class="t-text-sm-emphasize m-0 mb-1 mt-2 p-0 text-grey-700">{{ $tc('general.description') }}</h4>
    <textarea
      v-model="description"
      class="
        w-full
        rounded-md
        border-2 border-grey-300 border-opacity-30
        px-2
        py-1
        transition-colors
        focus:border-2 focus:border-leaf-500
      "
      :placeholder="$tc('general.description')"
      cols="2"
      rows="2"
      data-test="timetracking-description-input"
      @keydown.enter.prevent="isEditing ? editTimeEntry : saveTimeEntry"
    />

    <t-button
      v-if="!isEditing"
      class="mt-2 flex w-fit flex-row items-center"
      :disabled="isSaving"
      d
      data-test="timetracking-save-button"
      @click.prevent="saveTimeEntry"
    >
      <p class="m-0 p-0">{{ $tc('general.save') }}</p>
    </t-button>

    <t-button
      v-else
      class="mt-2 flex w-fit flex-row items-center"
      :disabled="isSaving"
      data-test="timetracking-save-button"
      @click.prevent="editTimeEntry"
    >
      <p class="m-0 p-0">{{ $tc('general.update') }}</p>
    </t-button>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import { defineComponent } from 'vue';

import DateTimePicker2 from '@/components/Elements/DateTimePicker2.vue';
import { addTimeEntry, editTimeEntry } from '@/components/TicketSidebar/Api/requests';

import type Tickets from '@/types/tickets';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'TimeEntry',
  components: { DateTimePicker2 },
  emits: ['add-log-entry', 'edit-entry'],

  props: {
    ticketId: {
      type: Number as PropType<Tickets['id']>,
      default: 0,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    editHours: {
      type: Number,
      default: 0,
    },
    editMinutes: {
      type: Number,
      default: 0,
    },
    editDate: {
      type: Number,
      default: parseInt((new Date().getTime() / 1000).toFixed()),
    },
    editDescription: {
      type: String,
      default: '',
    },
    entryId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      date: 0,
      hours: 0,
      minutes: 0,
      description: '',
      startTime: 0,
      endTime: 0,
      isSaving: false,

      timePickerConfig: {
        enableTime: false,
        defaultHour: new Date().getHours(),
        defaultMinute: new Date().getMinutes(),
        defaultDate: parseInt((new Date().getTime() / 1000).toFixed()),
        mode: 'single',
        time_24hr: false,
        altInput: true,
        altFormat: this.$t('general.format_day_month'),
        dateFormat: 'U',
      },
    };
  },

  mounted() {
    this.hours = this.editHours;
    this.minutes = this.editMinutes;
    this.date = this.editDate;
    this.description = this.editDescription;
  },

  methods: {
    async saveTimeEntry() {
      if (this.isSaving || (this.hours === 0 && this.minutes === 0) || !this.date) {
        return;
      }

      this.updateTime();

      try {
        this.isSaving = true;

        await addTimeEntry({
          start_time: this.startTime,
          end_time: this.endTime,
          description: this.description,
          user_id: this.$root.user.id,
          user: this.$root.user,
          ticket_id: this.ticketId,
        })
          .then((response) => {
            this.flashSuccess('Successfuly logged time');
            this.hours = 0;
            this.minutes = 0;
            this.description = '';

            this.$emit('add-log-entry', response.data);
          })
          .finally(() => {
            this.isSaving = false;
          });
      } catch (err) {
        console.error(err);
        this.flashError(err);
        this.isSaving = false;
      }
    },

    async editTimeEntry() {
      if (this.isSaving || (this.hours === 0 && this.minutes === 0) || !this.date) {
        return;
      }

      this.updateTime();

      try {
        this.isSaving = true;

        await editTimeEntry({
          id: this.entryId,
          start_time: this.startTime,
          end_time: this.endTime,
          description: this.description,
          user_id: this.$root.user.id,
          user: this.$root.user,
          ticket_id: this.ticketId,
        })
          .then((response) => {
            this.flashSuccess('Time entry successfuly edited');
            this.$emit('edit-entry', response.data);
          })
          .finally(() => {
            this.isSaving = false;
          });
      } catch (err) {
        console.error(err);
        this.flashError(err);
        this.isSaving = false;
      }
    },

    updateTime() {
      const startingTime = moment(this.date * 1000);
      this.startTime = startingTime.unix();
      startingTime.add(this.hours, 'hours').add(this.minutes, 'minutes');
      this.endTime = startingTime.unix();
    },
  },
});
</script>
