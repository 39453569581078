<template>
  <settings-base-layout
    v-if="!isMobile"
    class="reporting-container overflow-auto bg-grey-100"
    data-test="reporting-layout"
  >
    <template #header>
      <main-header max-width="1440px">
        <template #left>
          <span class="t-text-h3">{{ headingTitle }}</span>
        </template>

        <template #bottom>
          <div class="mt-2 flex w-full flex-row items-center justify-between pb-4">
            <filter-dropdown />
            <slot name="right">
              <div v-if="updatedAt">
                <p class="t-text-sm m-0 text-grey-600">{{ updatedAt }}</p>
              </div>
            </slot>
          </div>
        </template>
      </main-header>
    </template>
    <template #default>
      <div class="mt-[3px] w-full" data-test="overview">
        <div class="reporting-wrapper px-6 pb-6">
          <slot />
        </div>
      </div>
    </template>
  </settings-base-layout>
  <mobile-status v-else data-test="reporting-mobile" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import MainHeader from '@/components/common/PageHeaders/MainHeader';
import SettingsBaseLayout from '@/components/common/SettingsBaseLayout';
import FilterDropdown from '@/components/Reporting/Components/V2/FilterDropdown/FilterDropdown.vue';
import MobileStatus from '@/components/Reporting/Pages/MobileStatus.vue';
import breakpoints from '@/util/breakpoints';

export default defineComponent({
  name: 'ReportingPage',
  components: {
    SettingsBaseLayout,
    MainHeader,
    MobileStatus,
    FilterDropdown,
  },

  props: {
    headingTitle: {
      type: String,
      default: '',
    },
    updatedAt: {
      type: String,
      default: '',
    },
  },
  emits: ['add-button'],

  data() {
    return {
      breakpoints,
    };
  },

  computed: {
    isMobile() {
      return this.breakpoints.w <= 991;
    },
  },

  methods: {
    handleAddButtonPressed() {
      this.$emit('add-button');
    },
  },
});
</script>

<style lang="scss" scoped>
.reporting-wrapper {
  max-width: 1440px;
  margin: 0 auto;
}
.reporting-container {
  min-width: 1200px;
  width: calc(100vw - 330px);
  @media (max-width: 1500px) {
    min-width: 600px;
    max-width: calc(100vw - 345px);
  }
  @media (max-width: 992px) {
    min-width: 0px;
    width: 100%;
    max-width: 100%;
  }
}
</style>
