<template>
  <div class="flex h-full flex-col overflow-auto">
    <div class="mx-6 mb-4 mt-6 flex flex-row items-center">
      <t-thumbnail class="mr-3 bg-white">
        <webhooks-linear />
      </t-thumbnail>

      <h1 class="t-text-h3 m-0 p-0 text-grey-900">{{ $tc('apps_integration.webhooks') }}</h1>

      <t-button class="ml-auto" @click.prevent="navigateToCreateWebhook">
        {{ $tc('integration_hub.add_webhook') }}
      </t-button>
    </div>

    <div
      v-if="isLoaded && webhooks.length > 0"
      class="mx-6 mt-6 grid gap-3 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl3:grid-cols-4"
    >
      <router-link
        v-for="webhook in webhooks"
        :key="webhook.id"
        data-test="integration-card-link-to-webhook"
        :to="`/admin/integrations/webhooks/${webhook.id}`"
      >
        <t-card class="flex h-full flex-col bg-white" :focusable="true">
          <t-thumbnail size="large" class="mb-4">
            <webhooks-linear />
          </t-thumbnail>

          <h2 class="t-text-md-emphasize m-0 text-ellipsis p-0 text-grey-800">{{ webhook.title }}</h2>
          <h3 class="t-text-sm m-0 text-ellipsis p-0 text-grey-600">{{ getHookText(webhook.type) }}</h3>
        </t-card>
      </router-link>
    </div>

    <div v-else-if="isLoaded" class="m-auto flex h-full flex-col items-center justify-center">
      <t-thumbnail class="mb-4 bg-white">
        <webhooks-linear />
      </t-thumbnail>

      <h2 class="t-text-md-emphasize m-0 mb-2 p-0 text-grey-800">
        {{ $tc('integration_hub.you_dont_have_any_webhooks_installed') }}
      </h2>
      <h3 class="t-text-sm m-0 mb-10 p-0 text-grey-700">
        {{ $tc('integration_hub.here_we_showcase_all_the_webhooks_you_installed') }}
      </h3>

      <t-button class="mx-auto w-fit" @click.prevent="navigateToCreateWebhook">
        {{ $tc('integration_hub.add_webhook') }}
      </t-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { WebhooksLinear } from '@trengo/trengo-icons';
import axios from 'axios';
import { onMounted, ref } from 'vue';

import { vm } from '@/ApplicationHandler';

import type { Webhook } from '@/types';

const webhooks = ref<Webhook[]>([]);
const isLoaded = ref(false);

onMounted(() => {
  axios.get('/api/v2/webhooks').then((result) => {
    webhooks.value = result.data.data;
    isLoaded.value = true;
  });
});

function navigateToCreateWebhook() {
  vm.$router.push({ name: 'webhookCreate' });
}

function getHookText(type: Webhook['type']): string {
  switch (type) {
    case 'INBOUND':
      return vm.$tc('apps_integration.inbound_message');
    case 'OUTBOUND':
      return vm.$tc('apps_integration.outbound_message');
    case 'NOTE':
      return vm.$tc('apps_integration.internal_message');
    case 'TICKET_LABEL_ADDED':
      return vm.$tc('apps_integration.label_added_to_ticket');
    case 'TICKET_LABEL_DELETED':
      return vm.$tc('apps_integration.label_removed_from_ticket');
    case 'TICKET_ASSIGNED':
      return vm.$tc('apps_integration.ticket_assigned');
    case 'TICKET_CLOSED':
      return vm.$tc('apps_integration.ticket_closed');
    case 'TICKET_REOPENED':
      return vm.$tc('apps_integration.ticket_reopened');
    case 'TICKET_MARKED_AS_SPAM':
      return vm.$tc('apps_integration.ticket_marked_as_spam');
    case 'TICKET_UNMARKED_AS_SPAM':
      return vm.$tc('apps_integration.ticket_unmarked_as_spam');
    case 'VOICE_CALL_STARTED':
      return vm.$tc('apps_integration.voice_call_started');
    case 'VOICE_CALL_ENDED':
      return vm.$tc('apps_integration.voice_call_ended');
    case 'VOICE_CALL_RECORDED':
      return vm.$tc('apps_integration.voice_call_recorded');
    case 'VOICE_CALL_MISSED':
      return vm.$tc('apps_integration.voice_call_missed');
    case 'VOICE_CALL_ROUTE_NUMBER':
      return vm.$tc('apps_integration.voice_call_ivr_route');
  }
}
</script>
