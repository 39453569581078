import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mx-4 mb-2 rounded-lg bg-error-500 p-4 text-white",
  "data-test": "whatsapp-migration-banner"
}
const _hoisted_2 = {
  class: "mb-1",
  "data-test": "wa-banner-message"
}
const _hoisted_3 = {
  key: 0,
  class: "mx-1"
}
const _hoisted_4 = {
  key: 1,
  class: "m-0 p-0",
  "data-test": "wa-channel-no-permission"
}
const _hoisted_5 = {
  key: 0,
  class: "mx-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_t_tooltip = _resolveComponent("t-tooltip")!

  return (_ctx.numberOfChannelsToMigrate > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.message), 1 /* TEXT */),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.channelsToMigrate, (channel, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: channel.id,
            class: "inline-block"
          }, [
            (_ctx.hasPermission)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: `/admin/channels2/wa_business/${channel.id}`,
                  tag: "span",
                  class: "pointer font-bold hover:text-grey-300",
                  "data-test": "wa-channel-to-migrate"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(channel.display_name) + " ", 1 /* TEXT */),
                    (_ctx.showSeparator(index))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, "•"))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))
              : (_openBlock(), _createElementBlock("p", _hoisted_4, [
                  _createVNode(_component_t_tooltip, {
                    text: _ctx.$t('whatsapp.migration_banner_tooltip'),
                    position: "right",
                    "data-test": "permission-tooltip"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(channel.display_name), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["text"]),
                  (_ctx.showSeparator(index))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, "•"))
                    : _createCommentVNode("v-if", true)
                ]))
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
    : _createCommentVNode("v-if", true)
}