import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useInboxNavigationStore = defineStore('inboxNavigation', () => {
  const isSidebarVisible = ref(true);
  const toggle = () => {
    isSidebarVisible.value = !isSidebarVisible.value;
  };

  return { isSidebarVisible, toggle };
});
