<template>
  <div class="h-full">
    <logo-header class="bg-grey-100" />
    <div class="justify-content flex h-full items-center bg-grey-100">
      <div style="max-width: 380px; margin: 0 auto; min-width: 350px">
        <div v-if="successMessage" id="LoginErrors" class="alert alert-success">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
          {{ successMessage }}
        </div>
        <div
          class="box box-shadow-z2 animated fadeIn shadow"
          style="
            animation-duration: 0.2s;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09) !important;
            border-radius: 10px;
            padding: 2.25rem;
          "
        >
          <div class="text-center">
            <img
              :src="`${
                // @ts-ignore
                $root.assetsURL
              }img/trengo-logo.png`"
              class="mb-8"
              alt=""
              style="width: 90%"
            />
          </div>
          <div class="box-body">
            <div v-if="errorMessage" id="LoginErrors" class="alert alert-danger">
              <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
              {{ errorMessage }}
            </div>
            <form v-if="showLogin" id="form-login" class="form-horizontal text-left" @submit.prevent="signIn">
              <div class="form-group row">
                <div class="col-xs-12">
                  <strong>Email:</strong>
                  <input
                    ref="email"
                    v-model="email"
                    type="text"
                    placeholder="Enter your email..."
                    autofocus
                    name="email"
                    class="form-control mt-2"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-xs-12">
                  <strong>Password:</strong>
                  <input
                    ref="password"
                    v-model="password"
                    type="password"
                    placeholder="Enter your password..."
                    name="password"
                    class="form-control mt-2"
                  />
                </div>
              </div>
              <div class="row-col m-b-md">
                <div class="col-xs">
                  <label>
                    <input v-model="remember" type="checkbox" name="remember" />
                    <i class="success"></i>
                    <span style="margin: 1px 3px; display: inline-block">Remember me</span>
                  </label>
                </div>
                <div class="col-xs text-right">
                  <router-link class="open-external" to="/reset-password/request-email">Forgot password?</router-link>
                </div>
              </div>
              <div class="m-y-">
                <button type="submit" class="btn btn-lg btn-block success rounded">Login</button>
              </div>
            </form>

            <form
              v-if="showTfa"
              id="tfa_login"
              class="form-horizontal text-left"
              @submit.prevent="validateTwoFactorToken"
            >
              <div v-if="tfaErrorMessage" id="TfaError" class="alert alert-danger">{{ tfaErrorMessage }}</div>
              <div class="form-group row">
                <div class="col-xs-12">
                  <strong>Google Authenticator:</strong>
                  <input
                    v-model="code"
                    type="text"
                    autofocus
                    name="code"
                    class="form-control mt-2"
                    placeholder="Code"
                  />
                </div>
                <input v-model="secret" type="hidden" name="secret" />
              </div>
              <div class="m-b-md flex">
                <div class="flex-1">
                  <label>
                    <input v-model="trustDevice" type="checkbox" name="trust_device" />
                    <i class="success"></i>
                    <span style="margin: 1px 3px; display: inline-block">Trust this device</span>
                  </label>
                </div>
                <div class="md-auto text-right" @click="resetTwoFactor">
                  <a id="ResetLink" class="open-external">Reset 2FA</a>
                </div>
              </div>
              <div class="m-y-">
                <button type="submit" class="btn btn-lg btn-block success rounded">Login</button>
              </div>
            </form>
          </div>
        </div>
        <div class="mt-4 text-center text-grey-600">
          <a href="https://trengo.com/getdemo" class="open-external">Get a demo</a>
        </div>
      </div>
    </div>
    <help-center-button />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import HelpCenterButton from '@/components/common/HelpCenterButton';
import LogoHeader from '@/components/common/PageHeaders/LogoHeader';
import { STATUS_CODE } from '@/Configs/Constants/StatusCodes';
import { awaitUserDataAndTrack } from '@/util/analytics/segmentAnalytics';

import { post2FAToken, postLogin, reset2FA } from './api';
import { useCredentialNotifications } from './useCredentialNotifications';

import type { LoginData } from './types';
import type { AxiosError } from 'axios';

export default defineComponent({
  components: { LogoHeader, HelpCenterButton },
  data(): LoginData {
    return {
      showLogin: true,
      showTfa: false,
      email: null,
      password: null,
      remember: true,
      trustDevice: false,
      errorMessage: null,
      successMessage: null,
      tfaErrorMessage: null,
      code: null,
      secret: null,
    };
  },

  methods: {
    prepare2FAForm(secret: string) {
      this.errorMessage = false;
      this.showLogin = false;
      this.showTfa = true;
      this.secret = secret;
    },
    async signIn() {
      const email = !this.email ? this.$refs.email.value : this.email;
      const password = !this.password ? this.$refs.password.value : this.password;
      try {
        const res = await postLogin({ email, password, remember: this.remember ? 1 : 0 });

        if (res?.data['2fa']) {
          this.prepare2FAForm(res?.data['2fa']);
        } else {
          this.$root.initApplication();
          await awaitUserDataAndTrack();
          this.$router.push('/tickets');
        }
      } catch (error: unknown) {
        const err = error as AxiosError;
        console.error(err);
        switch (err?.response?.status) {
          case STATUS_CODE.FORBIDDEN: {
            const { mfa_token } = err.response.data;
            this.prepare2FAForm(mfa_token);
            break;
          }
          case STATUS_CODE.UNPROCESSABLE_ENTITY:
          default: {
            if (err?.response?.data?.errors?.email) {
              this.errorMessage = err.response.data.errors.email[0];
            } else if (err?.response?.data?.errors?.password) {
              this.errorMessage = err.response.data.errors.password[0];
            }
            $('#growls').hide();
          }
        }
      }
    },

    async validateTwoFactorToken() {
      try {
        await post2FAToken({
          otp: this.code,
          mfa_token: this.secret,
          remember: this.remember ? 1 : 0,
          trust_device: this.trustDevice ? 1 : 0,
        });
        this.$root.initApplication();
        await awaitUserDataAndTrack();
        this.$router.push('/tickets');
      } catch (error: unknown) {
        const err = error as AxiosError;
        this.tfaErrorMessage = err?.response?.data.error;
        this.successMessage = null;
        $('#growls').hide();
      }
    },

    resetTwoFactor() {
      reset2FA(this.secret)
        .then((res) => {
          this.showLogin = true;
          this.showTfa = false;
          this.successMessage = res.data.message;
          this.tfaErrorMessage = null;
        })
        .catch((_err) => {
          this.successMessage = null;
          this.tfaErrorMessage = 'Something went wrong. Try again later';
          $('#growls').hide();
        });
    },
  },

  async mounted() {
    // Redirect right away if already redirected before (this means the state has been transferred before already)
    if ((await this.$tStorage.getItem('redirected_to_com_domain')) && window.location.hostname === 'app.trengo.eu') {
      window.location.href = window.location.href.replace(window.location.hostname, 'app.trengo.com');
      return;
    }

    $('#TrengoLoader').hide();

    // Check if password has been reset, if true show notification
    if (useCredentialNotifications().hasNotification) {
      this.successMessage = useCredentialNotifications().notification;
    }

    if (this.$route.query.twoFaReset) {
      this.successMessage = 'Your 2FA has been reset successfully. You are now able to login without 2FA.';
      this.$router.replace({ query: null });
    }

    // @ts-ignore
    if (window.isLoadedFromApp) {
      $('body').on('click', 'a', function (e) {
        if ($(this).hasClass('open-external')) {
          e.preventDefault();
          parent.postMessage('app-browse-url:' + encodeURI($(this).attr('href') as string), '*');
        }
      });
    }

    parent.postMessage('path:login', '*');
  },
});
</script>
