<template>
  <t-table-list-row class="users-list-item table-fixed py-1" infinite-scrolling v-bind="$attrs">
    <div v-if="!isMobile" class="contents">
      <t-table-list-cell>
        <div class="t-text-sm flex w-full items-center justify-between overflow-visible py-3 pr-4">
          <user-info :user="user" />
          <div v-show="userIsUnverified || twoFAEnabled" class="ml-2 flex-shrink-0">
            <t-tag class="w-full select-none" size="sm">
              {{ twoFAEnabled ? $t('user_management.2fa') : $t('user_management.invite_pending') }}
            </t-tag>
          </div>
        </div>
      </t-table-list-cell>
      <t-table-list-cell>
        <teams-info :teams="user.teams" />
      </t-table-list-cell>
      <t-table-list-cell>
        <t-action-menu
          v-model="userRoleDropdownIsOpen"
          width="max-content"
          :trigger-on-click="false"
          class="inline-block"
          :class="[
            {
              'cursor-pointer': !isTheOnlyAdministrator,
              'cursor-not-allowed': !isEditRoleDropdownEnabled,
            },
          ]"
          fade
        >
          <template #trigger>
            <span
              v-tooltip="dropdownTooltip"
              class="t-text-sm inline-flex select-none items-center rounded-lg py-1.5 pl-2 pr-1.5 text-grey-800"
              :class="roleDropdownClasses"
              @click="toggleUserRoleDropdown"
            >
              <span v-if="currentRole">
                {{ $t(`user_management.${currentRole.toLowerCase()}`) }}
              </span>
              <chevron-down-linear v-if="isEditRoleDropdownEnabled" size="1rem" class="icon ml-1.5 text-grey-600" />
            </span>
          </template>
          <template #content>
            <div class="flex flex-col">
              <t-action-button
                v-for="role in roles"
                :key="role.value"
                class="t-text-sm h-28px"
                :disabled="role.isDisabled"
                data-test="user-role-dropdown-option"
                @click.stop="updateUserRole(role.value)"
              >
                {{ $t(role.titleKey) }}
              </t-action-button>
            </div>
          </template>
        </t-action-menu>
      </t-table-list-cell>
      <t-table-list-cell>
        <div v-if="showOptionsDropdown" class="mr-3">
          <t-action-menu v-model="optionsDropdownIsOpen" position="left" fade width="max-content">
            <template #trigger>
              <t-icon-button
                size="md"
                class="dropdown rounded-none"
                :class="{ 'dropdown-open': optionsDropdownIsOpen }"
              />
            </template>
            <template #content>
              <div class="flex flex-col">
                <t-action-button v-if="canEditUser" data-test="edit-user" @click.stop="editUser()">
                  <edit3-linear size="1.1rem" class="mr-2 text-grey-700" />
                  <span class="t-text-sm text-grey-700">{{ $t('user_management.edit') }}</span>
                </t-action-button>
                <t-action-button
                  v-tooltip="{
                    placement: 'top',
                    content: buttonText,
                    classes: 'tooltip-vuetify',
                    show: isTooltipVisible,
                    trigger: 'manual',
                  }"
                  data-test="copy-user-id"
                  @click="handleCopyToClipboard(user.id)"
                >
                  <clipboard-linear size="1.1rem" class="mr-2 text-grey-700" />
                  <span class="t-text-sm text-grey-700">ID: {{ user.id }}</span>
                </t-action-button>
                <t-action-button v-if="userIsUnverified" data-test="resend-invite" @click="resendVerification(user)">
                  <send2-linear size="1.1rem" class="mr-2 text-grey-700" />
                  <span class="t-text-sm text-grey-700">{{ $t('user_management.resend_invite') }}</span>
                </t-action-button>
                <t-action-button
                  v-if="userCanBeDeleted"
                  data-test="delete-user"
                  @click="
                    toggleOptionsDropdown();
                    deleteUser();
                  "
                >
                  <trash-linear size="1.1rem" class="mr-2 text-error-500" />
                  <span class="t-text-sm text-error-500">{{ $t('user_management.delete') }}</span>
                </t-action-button>
              </div>
            </template>
          </t-action-menu>
        </div>
      </t-table-list-cell>
    </div>
    <div v-else class="contents">
      <t-table-list-cell>
        <div class="t-text-sm flex w-full items-center justify-between overflow-visible py-3">
          <user-info :user="user" :is-mobile="true" />
          <div v-show="userIsUnverified || twoFAEnabled" class="ml-2 flex-shrink-0">
            <t-tag class="w-full select-none" size="sm">
              {{ twoFAEnabled ? $t('user_management.2fa') : $t('user_management.invite_pending') }}
            </t-tag>
          </div>
        </div>
      </t-table-list-cell>
    </div>
  </t-table-list-row>
</template>

<script lang="ts">
import { ChevronDownLinear, Edit3Linear, Send2Linear, TrashLinear, ClipboardLinear } from '@trengo/trengo-icons';
import { map } from 'lodash';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import { sendVerification } from '@/components/Users/api';
import { editUser } from '@/components/Users/components/EditUsers/Api';
import { PERMISSION, USER_ROLE } from '@/Configs/Constants';
import UserStatus from '@/Configs/Constants/UserStatus';
import { useUserStore, useUsersStore, useFeatureFlagStore } from '@/store/pinia';
import breakpoints from '@/util/breakpoints';
import { copyToClipboard } from '@/util/helpers';

import { useRolesDropdown } from '../../composables/rolesDropdown';
import TeamsInfo from '../TeamsInfo';
import UserInfo from '../UserInfo';

import type { User } from '@/types';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'UsersListItem',
  components: {
    TeamsInfo,
    ChevronDownLinear,
    TrashLinear,
    UserInfo,
    Edit3Linear,
    Send2Linear,
    ClipboardLinear,
  },
  props: {
    user: {
      type: Object as PropType<User>,
      default: () => ({}),
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['openModal', 'delete', 'resendInvite'],
  setup(props) {
    const { userCanBeDeleted, currentRole, getRoles, isTheOnlyAdministrator, isTheOnlyPrimaryUser, hasAdminRole } =
      useRolesDropdown(props.user);

    return {
      roles: getRoles({ allowObserver: false }),
      userCanBeDeleted,
      currentRole,
      isTheOnlyAdministrator,
      isTheOnlyPrimaryUser,
      isAdministrator: hasAdminRole(currentRole),
      isObserver: currentRole === USER_ROLE.OBSERVER,
    };
  },
  data() {
    return {
      optionsDropdownIsOpen: false,
      userRoleDropdownIsOpen: false,
      breakpoints,
      buttonText: this.$t('general.copied'),
      isTooltipVisible: false,
    };
  },
  computed: {
    ...mapStores(useFeatureFlagStore, useUserStore, useUsersStore),
    dropdownTooltip() {
      let showTooltip = false;
      let content;

      if (this.isTheOnlyAdministrator) {
        showTooltip = true;
        content = this.$t('user_management.organisation_must_have_at_least_one_administrator');
      }

      if (this.isObserver) {
        showTooltip = true;
        content = this.$t('user_management.upgrading_this_user_to_another_role_can_not_be_reversed');
      }

      return {
        placement: 'top',
        content: content,
        popperClass: showTooltip ? 'tooltip-vuetify tooltip-bigger' : 'invisible',
        trigger: this.isMobile ? 'hover' : 'hover focus click',
      };
    },
    canEditUser() {
      if (this.isAdministrator && !this.userStore.hasPermission(PERMISSION.PERMISSIONS__USERS__ASSIGN_ADMIN_ROLE)) {
        return false;
      }

      return true;
    },
    showOptionsDropdown() {
      return this.canEditUser || this.userIsUnverified || this.userCanBeDeleted;
    },
    userIsUnverified() {
      return this.user.status === UserStatus.UNVERIFIED;
    },
    twoFAEnabled() {
      return this.user.two_factor_authentication_enabled;
    },
    roleDropdownClasses() {
      return {
        'bg-grey-300': this.userRoleDropdownIsOpen,
        't-action-menu-animation hover:bg-grey-200': !this.userRoleDropdownIsOpen,
      };
    },
    isEditRoleDropdownEnabled() {
      if (this.isAdministrator && !this.userStore.hasPermission(PERMISSION.PERMISSIONS__USERS__ASSIGN_ADMIN_ROLE)) {
        // FIXME: Do we need this logic? If selected user is an admin, shouldn't logged in user be able to change it to some other role than admin?
        return false;
      }

      if (this.isTheOnlyPrimaryUser) {
        return false;
      }

      return true;
    },
  },
  watch: {
    user() {
      this.refreshRolesDropdown(this.user);
    },
  },
  methods: {
    editUser() {
      this.toggleOptionsDropdown();
      this.$emit('openModal', this.user);
    },
    handleCopyToClipboard(userId) {
      copyToClipboard(userId);
      this.isTooltipVisible = true;
      setTimeout(() => {
        this.isTooltipVisible = false;
        this.toggleOptionsDropdown();
      }, 2000);
    },
    async updateUserRole(role) {
      if (this.currentRole === role) {
        return;
      }

      this.currentRole = role;
      this.toggleUserRoleDropdown();
      await this.updateUser(role);
    },
    deleteUser() {
      this.$emit('delete', this.user);
    },
    resendInvite() {
      this.toggleOptionsDropdown();
      this.$emit('resendInvite', this.user.id);
    },
    toggleOptionsDropdown() {
      this.optionsDropdownIsOpen = !this.optionsDropdownIsOpen;
    },
    toggleUserRoleDropdown() {
      if (!this.isEditRoleDropdownEnabled) {
        this.userRoleDropdownIsOpen = false;
        return;
      }

      this.userRoleDropdownIsOpen = !this.userRoleDropdownIsOpen;
    },

    async resendVerification(user) {
      this.toggleOptionsDropdown();
      sendVerification(user.id)
        .then(() => {
          this.flashSuccess(this.$t('user_management.invite_was_sent_successfully'));
        })
        .catch((e) => {
          this.saving = false;
          console.error('Error while sending invite: ', e);
        });
    },

    updateUser(role) {
      const userData = { ...this.getUserDataObject(), role };
      editUser(this.user.id, userData)
        .then(({ data }) => {
          this.refreshRolesDropdown(data);
          this.usersStore.updateUser(data).then(() => {
            this.$root.users = this.usersStore.users;
          });

          this.flashSuccess(this.$t('user_management.user_role_was_updated_successfully'));
        })
        .catch((e) => {
          console.error('Error while saving user: ', e);
        });
    },
    getUserDataObject() {
      return {
        ...{ team_ids: map(this.user.teams, 'id') },
        ...{ custom_fields_mapped: this.mapCustomFields(this.user) },
        ...{
          email: this.user.email,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          is_primary: this.user.is_primary,
          role: this.user.role.name,
          locale_code: this.user.locale_code,
        },
      };
    },
    refreshRolesDropdown(user: User) {
      const { getRoles, userCanBeDeleted, currentRole, isTheOnlyAdministrator, isTheOnlyPrimaryUser, hasAdminRole } =
        useRolesDropdown(user);

      Object.assign(this, {
        roles: getRoles({ allowObserver: false }),
        userCanBeDeleted,
        currentRole,
        isTheOnlyAdministrator,
        isTheOnlyPrimaryUser,
        isAdministrator: hasAdminRole(currentRole),
        isObserver: currentRole === USER_ROLE.OBSERVER,
      });
    },
    mapCustomFields() {
      return (this.$root.customUserFields || []).map((field) => {
        const userField = this.user.custom_fields.find((userField) => {
          return userField.id === field.id;
        });

        let value = '';

        if (userField) {
          value = userField.pivot.value;
        }

        return { title: field.title, id: field.id, value: value };
      });
    },
  },
});
</script>

<style src="./UsersListItemGlobal.scss" lang="scss" />
