<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

import { vm } from '@/ApplicationHandler';
import { toggleVoipStatus } from '@/components/Voip/api/requests';
import eventBus from '@/eventBus';

type VoipStatuses = 'OFFLINE' | 'ONLINE' | 'CALLING';

defineProps<{ labels?: unknown }>();

const prevStatus = ref<Exclude<VoipStatuses, 'CALLING'>>('OFFLINE');
const onlineStatus = ref<VoipStatuses>('OFFLINE');

const isCalling = computed(() => {
  return vm.$root.user.voip_status === 'CALLING' || onlineStatus.value === 'CALLING';
});

async function setUserStatus(status: VoipStatuses) {
  if (status === 'CALLING' && onlineStatus.value !== 'CALLING') {
    prevStatus.value = vm.$root.user.voip_status;
  }

  onlineStatus.value = status;
  vm.$root.user.voip_status = status;

  await toggleVoipStatus(status);
}

function toggleStatus() {
  if (onlineStatus.value === 'CALLING') {
    return;
  }

  if (onlineStatus.value === 'ONLINE') {
    setUserStatus('OFFLINE');
  } else if (onlineStatus.value === 'OFFLINE') {
    setUserStatus('ONLINE');
  }
}

onMounted(() => {
  if (vm.$root.user.voip_status === 'OFFLINE' || vm.$root.user.voip_status === 'ONLINE') {
    prevStatus.value = vm.$root.user.voip_status;
  }

  eventBus.$on('voip.user_call_started', () => {
    if (vm.$root.user.voip_status !== 'CALLING') {
      setUserStatus('CALLING');
    }
  });

  eventBus.$on('voip.user_call_ended', () => {
    if (vm.$root.user.voip_status === 'CALLING') {
      setUserStatus(prevStatus.value);
    }
  });

  eventBus.$on('voip.reset_user_status', () => {
    setUserStatus('ONLINE');
  });
});

const isOnline = computed(() => {
  return vm.$root.user.voip_status === 'ONLINE';
});
</script>

<template>
  <div
    class="
      selector-toggle-status-voip
      mb-1
      mt-1
      flex
      cursor-pointer
      items-center
      rounded-lg
      border-1
      p-2
      px-3
      leading-none
      text-white
      hover:border-grey-600 hover:brightness-90
    "
    :class="isOnline ? 'border-grey-700' : 'border-grey-800'"
    role="button"
    data-test="profile-settings-item-voip-status"
    @click.prevent="toggleStatus"
  >
    <i class="material-icons text-md" :class="{ 'text-green': isOnline, 'text-red': !isOnline }">phone</i>

    <span class="ml-2">
      <span v-if="isOnline" v-html="$t('tickets.status_voip_online')"></span>
      <span v-else class="text-grey-500" v-html="$t('tickets.status_voip_away')"></span>
    </span>
    <span class="ml-auto text-xs leading-none text-grey-500">
      <t-switch ref="switch" v-model="isOnline" off-color="red" size="sm" label :disabled="isCalling" />
    </span>
  </div>
</template>
