<template>
  <div class="flex w-full flex-1">
    <portal to="sidebar">
      <div v-show="isBetaUser()" class="pl-4 pt-2">
        <collapse collapse-key="statistics" :default-open="true">
          <template #collapse-title>
            <div>
              <h6>{{ $t('reports.navigation_header_reporting') }}</h6>
            </div>
          </template>
          <template #collapse-content>
            <div class="relative flex h-[90vh] flex-col justify-between overflow-y-visible">
              <div>
                <div>
                  <router-link
                    class="collapse-menu-item flex cursor-pointer items-center rounded"
                    data-tag="a"
                    exact-active-class="bg-grey-200 text-grey-900"
                    to="/reports/old"
                  >
                    <div style="width: 30px"><i class="far fa-folder md-18"></i></div>
                    {{ $t('reports.navigation_availability') }}
                  </router-link>
                </div>

                <div>
                  <router-link
                    class="collapse-menu-item flex cursor-pointer items-center rounded"
                    data-tag="a"
                    exact-active-class="bg-grey-200 text-grey-900"
                    :to="'/reports/old/conversations?filters=' + $route.query.filters"
                  >
                    <div style="width: 30px"><i class="fa fa-folder-o md-18"></i></div>
                    {{ $t('reports.navigation_conversations_report') }}
                  </router-link>
                </div>
                <div>
                  <router-link
                    class="collapse-menu-item flex cursor-pointer items-center rounded"
                    data-tag="a"
                    exact-active-class="bg-grey-200 text-grey-900"
                    :to="'/reports/old/messages?filters=' + $route.query.filters"
                  >
                    <div style="width: 30px"><i class="fa fa-folder-o md-18"></i></div>
                    {{ $t('reports.navigation_messages_report') }}
                  </router-link>
                </div>
                <div>
                  <router-link
                    class="collapse-menu-item flex cursor-pointer items-center rounded"
                    data-tag="a"
                    exact-active-class="bg-grey-200 text-grey-900"
                    :to="'/reports/old/productivity?filters=' + $route.query.filters"
                  >
                    <div style="width: 30px"><i class="fa fa-folder-o md-18"></i></div>
                    {{ $t('reports.navigation_resolutions_report') }}
                  </router-link>
                </div>
                <div>
                  <router-link
                    class="collapse-menu-item flex cursor-pointer items-center rounded"
                    data-tag="a"
                    exact-active-class="bg-grey-200 text-grey-900"
                    :to="'/reports/old/channels?filters=' + $route.query.filters"
                  >
                    <div style="width: 30px"><i class="fa fa-folder-o md-18"></i></div>
                    {{ $t('reports.navigation_channels_report') }}
                  </router-link>
                </div>
                <div>
                  <router-link
                    class="collapse-menu-item flex cursor-pointer items-center rounded"
                    data-tag="a"
                    exact-active-class="bg-grey-200 text-grey-900"
                    :to="'/reports/old/users?filters=' + $route.query.filters"
                  >
                    <div style="width: 30px"><i class="fa fa-folder-o md-18"></i></div>
                    {{ $t('reports.navigation_users_report') }}
                  </router-link>
                </div>
                <div>
                  <router-link
                    class="collapse-menu-item flex cursor-pointer items-center rounded"
                    data-tag="a"
                    exact-active-class="bg-grey-200 text-grey-900"
                    :to="'/reports/old/labels?filters=' + $route.query.filters"
                  >
                    <div style="width: 30px"><i class="fa fa-folder-o md-18"></i></div>
                    {{ $t('reports.navigation_labels_report') }}
                  </router-link>
                </div>
                <div>
                  <router-link
                    class="collapse-menu-item flex cursor-pointer items-center rounded"
                    data-tag="a"
                    exact-active-class="bg-grey-200 text-grey-900"
                    to="/reports/old/time-tracking"
                  >
                    <div style="width: 30px"><i class="far fa-folder md-18"></i></div>
                    {{ $t('reports.navigation_time_tracking') }}
                  </router-link>
                </div>
                <div>
                  <router-link
                    class="collapse-menu-item flex cursor-pointer items-center rounded"
                    data-tag="a"
                    exact-active-class="bg-grey-200 text-grey-900"
                    to="/reports/old/export"
                  >
                    <div style="width: 30px"><i class="far fa-folder md-18"></i></div>
                    {{ $t('statistics.export') }}
                  </router-link>
                </div>
                <div>
                  <router-link
                    class="collapse-menu-item flex cursor-pointer items-center rounded"
                    data-tag="a"
                    exact-active-class="bg-grey-200 text-grey-900"
                    :to="'/reports/old/csats?filters=' + $route.query.filters"
                  >
                    <div style="width: 30px"><i class="fa fa-folder-o md-18"></i></div>
                    {{ $t('statistics.csats') }}
                  </router-link>
                </div>
              </div>
              <t-list-item :label="$t('reports_v2.switch_new')" size="sm" @click="handleRedirectToOldReporting">
                <template #prefix>
                  <chart-column-linear size="1.3rem" />
                </template>
              </t-list-item>
            </div>
          </template>
        </collapse>
      </div>
    </portal>
    <div class="flex w-full min-w-0 flex-col bg-grey-100">
      <div class="scroll-on-hover flex h-full w-full min-w-0 flex-1 p-4">
        <router-view class="view"></router-view>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { ChartColumnLinear } from '@trengo/trengo-icons';

import Collapse from '@/components/Collapse';

import { getReportsList } from '../api';

export default {
  components: { Collapse, ChartColumnLinear },
  data: function () {
    return {
      list: {},
    };
  },
  mounted: function () {
    if (window.is_mobile_device()) {
      this.$router.push('/');

      return;
    }

    this.getList();
  },
  methods: {
    handleRedirectToOldReporting() {
      this.$router.push({ name: 'reportingDashboard' });
    },
    getList() {
      getReportsList().then((res) => {
        this.list = res.data;
      });
    },
    isBetaUser() {
      return true;
    },
  },
};
</script>

<style type="text/scss">
.stats-nav .nav-link {
  padding: 15px 20px;
}
</style>
