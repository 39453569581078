<script type="text/babel">
import _ from 'lodash';

export default {
  data: function () {
    return {
      method: 'file',
      seperator: ';',
      text: '',
      upload_type: 'csv',
      upload_result: null,
      column: [],
      loading: false,
      data: '',
      contact_groups: {},
      contact_group: null,
      importing: false,
      res: null,
      history: [],
      importedContacts: [],
    };
  },

  mounted() {
    this.loadGroups();
    this.loadHistory();
  },

  methods: {
    sendForm() {
      this.loading = true;

      var data = new FormData($('form#ContactImportForm')[0]);
      data.append('method', this.method);
      data.append('seperator', this.seperator);
      data.append('text', this.text);

      axios
        .post('/client-api/sms/contacts/upload', data)
        .then((res) => {
          this.upload_result = res.data;
          this.loading = false;

          this.column[0] = 'phone';
          this.column[1] = 'name';

          _.each(_.range(2, 10), (i) => {
            this.column[i] = null;
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },

    importContacts() {
      this.importing = true;
      axios
        .post('/client-api/sms/contacts/import', {
          columns: this.column,
          data: this.upload_result.rows.data,
          contact_group: this.contact_group,
        })
        .then((res) => {
          this.reset();
          this.res = res.data;
          this.importing = false;
          this.loadHistory();
        })
        .catch(() => {
          this.importing = false;
        });
    },

    loadGroups() {
      $.getJSON('/client-api/sms/contacts/groups?placeholder=true').then((groups) => {
        this.contact_groups = groups;
      });
    },

    cancel() {},

    reset() {
      this.method = 'file';
      this.seperator = ';';
      (this.text = ''), (this.upload_type = 'csv');
      this.upload_result = null;
      this.column = [];
      this.res = null;
    },

    loadHistory() {
      $.getJSON('/client-api/sms/contacts/import/history').then((history) => {
        this.history = history;
      });
    },

    removeImport(import_id) {
      if (confirm(this.$t('sms.delete_imported_contacts_confirm')) == false) {
        return;
      }

      $.post('/client-api/sms/contacts/import/delete/' + import_id).then(() => {
        this.loadHistory();
      });
    },

    listImportedContacts: function (import_id) {
      this.importedContacts = [];
      $('#ImportedContactsModal').modal('show');
      $.getJSON('/client-api/sms/contacts/import/view/' + import_id).then((contacts) => {
        this.importedContacts = contacts;
      });
    },

    openContact: function (contact_id) {
      $('#ImportedContactsModal').modal('hide');
      this.$router.push('/contacts/' + contact_id);
    },
    formatCreatedTime(time) {
      return moment(time).fromNow();
    },
  },
};
</script>

<template>
  <div class="dark-light w-full min-w-0 flex-1 overflow-y-scroll py-4 pr-4">
    <div class="box">
      <div class="box-header b-b">
        <h3>{{ $t('sms.import_contacts') }}</h3>
      </div>
      <div class="box-body">
        <div v-if="upload_result == null">
          <div
            v-if="res"
            class="alert"
            :class="{ 'alert-danger': res.type == 'error', 'alert-success': res.type == 'success' }"
          >
            {{ res.message }}
            <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
          </div>

          <form id="ContactImportForm" class="form-horizontal p-x-1" data-pjax="false" @submit.prevent="sendForm()">
            <div class="form-group row">
              <label class="form-control-label">{{ $t('sms.select_import_method') }}</label>
              <div>
                <label class="radio p-r-1">
                  <input v-model="method" type="radio" value="file" />
                  {{ $t('sms.import_from_file') }}
                </label>
                <label class="radio-inline">
                  <input v-model="method" type="radio" value="text" />
                  {{ $t('sms.import_from_text') }}
                </label>
              </div>
            </div>
            <div v-show="method == 'text'" class="form-group row">
              <label class="form-control-label">{{ $t('sms.paste_text') }}</label>
              <textarea v-model="text" class="form-control" rows="10"></textarea>
            </div>
            <div v-show="method == 'file'" class="form-group row">
              <label class="form-control-label block">{{ $t('sms.select_file') }}</label>
              <input type="file" name="file" />
            </div>
            <div class="form-group row">
              <label class="form-control-label">{{ $t('sms.format_example') }}</label>
              <pre class="p-a-1">
31643761826;Marcel van de Weerd
        31643761823;Patrick Meutzner
        31643761821;John Doe</pre
              >
            </div>
            <div class="form-group row">
              <label class="form-control-label">{{ $t('sms.seperator') }}</label>
              <div>
                <label class="radio-inline p-r-1">
                  <input v-model="seperator" type="radio" value=";" />
                  ;
                </label>
                <label class="radio-inline p-r-1">
                  <input v-model="seperator" type="radio" value="," />
                  ,
                </label>
                <label class="radio-inline">
                  <input v-model="seperator" type="radio" value="|" />
                  |
                </label>
              </div>
            </div>
            <div class="form-group row">
              <button type="submit" class="btn success text-white">
                <i v-show="loading" class="fa fa-spin fa-spinner"></i>
                <span v-show="!loading">{{ $t('sms.select_columns') }}</span>
              </button>
            </div>
          </form>
        </div>

        <div v-if="upload_result != null">
          <label>{{ $t('sms.select_contact_group') }}</label>
          <select v-model="contact_group" class="form-control">
            <option v-for="group in contact_groups" :value="group.id">{{ group.title }}</option>
          </select>
          <hr />
          <div class="alert alert-grey">
            {{ $t('sms.import_intro') }}
          </div>
          <table class="table-bordered table">
            <thead>
              <tr>
                <td v-for="(col, k) in upload_result.rows.count">
                  <select v-model="column[k]" class="form-control">
                    <option v-for="col in upload_result.columns" :value="col.id">{{ col.title }}</option>
                  </select>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in upload_result.rows.data">
                <td v-for="col in row">
                  {{ col }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col-md-12">
              <hr />
              <button type="submit" class="btn success text-white" @click="importContacts">
                <i v-show="importing" class="fa fa-spin fa-spinner"></i>
                <span v-show="!importing">{{ $t('sms.start_import') }}</span>
              </button>
              <a class="btn btn-white" @click="reset">{{ $t('general.cancel') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <div v-if="upload_result == null">
        <div class="box-header b-b">
          <h3>{{ $t('sms.import_history') }}</h3>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th width="40%">{{ $t('sms.date') }}</th>
              <th width="40%">{{ $t('sms.contacts_imported') }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in history">
              <td>{{ formatCreatedTime(row.created_at) }}</td>
              <td>
                <a style="text-decoration: underline" @click="listImportedContacts(row.id)">{{ row.count }}</a>
              </td>
              <td align="right">
                <a class="text-muted" @click="removeImport(row.id)">
                  {{ $t('sms.delete_imported_contacts') }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div id="ImportedContactsModal" class="modal">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ $t('sms.imported_contacts') }}</h5>
              <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <ul class="list-group" style="max-height: 600px; overflow-y: scroll; display: block">
                <li v-for="contact in importedContacts" class="list-group-item" @click="openContact(contact.id)">
                  {{ contact.phone }}
                  <span class="pull-right text-muted">{{ contact.name }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
