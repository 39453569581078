<template>
  <div>
    <div class="mb-2 text-xl font-bold leading-7 text-grey-900">Good to know</div>
    <div class="mb-2">To create a WhatsApp Business account you need the following:</div>
    <div>
      <ul class="mb-10 list-disc">
        <li>The name of your company (as visible to customers)</li>
        <li>The legal company name</li>
        <li>The company’s official address</li>
      </ul>
    </div>

    <div class="mb-2 text-xl font-bold leading-7 text-grey-900">Create your WhatsApp Business account</div>
    <div class="mb-6 leading-6">
      When the right WhatsApp Business account and phone number are selected, you are ready to go to the next step.
    </div>

    <slot name="body-append" />
  </div>
</template>

<script>
export default {
  name: 'StepFive',
};
</script>
