import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4a60ab2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "users-list" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = {
  key: 0,
  class: "title text-ellipsis font-medium text-grey-900"
}
const _hoisted_5 = { class: "subtitle text-ellipsis pl-3 font-medium text-grey-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_users_list_header = _resolveComponent("users-list-header")!
  const _component_users_list_main = _resolveComponent("users-list-main")!
  const _component_manage_roles_modal = _resolveComponent("manage-roles-modal")!
  const _component_add_user_modal = _resolveComponent("add-user-modal")!
  const _component_edit_user_modal = _resolveComponent("edit-user-modal")!
  const _component_delete_user_modal = _resolveComponent("delete-user-modal")!
  const _component_hashtag_linear = _resolveComponent("hashtag-linear")!
  const _component_delete_team_chat_last_owner_modal = _resolveComponent("delete-team-chat-last-owner-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_users_list_header, {
        "is-mobile": _ctx.isMobile,
        onOpenModal: _ctx.handleOpenAddModal,
        onOpenRolesModal: _ctx.handleOpenRolesModal
      }, null, 8 /* PROPS */, ["is-mobile", "onOpenModal", "onOpenRolesModal"]),
      _createVNode(_component_users_list_main, {
        list: _ctx.list,
        loading: _ctx.loading,
        "infinite-loading-reset-counter": _ctx.infiniteLoadingResetCounter,
        "is-mobile": _ctx.isMobile,
        onInfiniteScroll: _ctx.onInfinite,
        onOpenModal: _ctx.handleOpenEditUserModal,
        onResendInvite: _ctx.resendInvite,
        onClickListItem: _ctx.handleOpenEditUserModal,
        onDelete: _ctx.handleDeleteUser
      }, null, 8 /* PROPS */, ["list", "loading", "infinite-loading-reset-counter", "is-mobile", "onInfiniteScroll", "onOpenModal", "onResendInvite", "onClickListItem", "onDelete"])
    ]),
    (_ctx.openManageRoles && _ctx.userStore.hasPermission(_ctx.PERMISSION.PERMISSIONS__ROLES__MANAGE))
      ? (_openBlock(), _createBlock(_component_manage_roles_modal, {
          key: 0,
          "is-open": _ctx.openManageRoles,
          onHandleOpenRolesModal: _ctx.handleOpenRolesModal
        }, null, 8 /* PROPS */, ["is-open", "onHandleOpenRolesModal"]))
      : _createCommentVNode("v-if", true),
    (_ctx.openAddUser && _ctx.userStore.hasPermission(_ctx.PERMISSION.PERMISSIONS__USERS__MANAGE))
      ? (_openBlock(), _createBlock(_component_add_user_modal, {
          key: 1,
          modelValue: _ctx.openAddUser,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openAddUser) = $event)),
          onUpdate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update')))
        }, null, 8 /* PROPS */, ["modelValue"]))
      : _createCommentVNode("v-if", true),
    (_ctx.isEditUserModalOpen && _ctx.record && _ctx.userStore.hasPermission(_ctx.PERMISSION.PERMISSIONS__USERS__MANAGE))
      ? (_openBlock(), _createBlock(_component_edit_user_modal, {
          key: 2,
          modelValue: _ctx.openEditUser,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.openEditUser) = $event)),
          "is-mobile": _ctx.isMobile,
          "initial-record-prop": _ctx.record,
          onUpdate: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update'))),
          onDelete: _ctx.handleDeleteUser,
          onResendInvite: _ctx.resendInvite
        }, null, 8 /* PROPS */, ["modelValue", "is-mobile", "initial-record-prop", "onDelete", "onResendInvite"]))
      : _createCommentVNode("v-if", true),
    (_ctx.openDeleteUserModal && _ctx.canDelete(_ctx.user))
      ? (_openBlock(), _createBlock(_component_delete_user_modal, {
          key: 3,
          modelValue: _ctx.openDeleteUserModal,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.openDeleteUserModal) = $event)),
          name: _ctx.user?.first_name,
          onConfirm: _ctx.confirmDeleteUser
        }, null, 8 /* PROPS */, ["modelValue", "name", "onConfirm"]))
      : _createCommentVNode("v-if", true),
    (_ctx.openDeleteTeamChatLastOwnerModal)
      ? (_openBlock(), _createBlock(_component_delete_team_chat_last_owner_modal, {
          key: 4,
          modelValue: _ctx.openDeleteTeamChatLastOwnerModal,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.openDeleteTeamChatLastOwnerModal) = $event)),
          class: "delete-user-modal",
          name: `${_ctx.user?.first_name} ${_ctx.user?.last_name}`,
          onConfirm: _ctx.handleDeleteUserAndPassOwnership,
          onCancel: _ctx.handleCloseEditUserModal
        }, {
          body: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["flex flex-col pb-4 pt-4", { '-mr-2': _ctx.isDesktop }])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["scroll-container", {
              'scroll-on-hover': _ctx.isDesktop && _ctx.threads && _ctx.threads.length > 6,
              'md:overflow-y-scroll': !_ctx.isDesktop,
            }])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedThreads, (thread) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: thread.id,
                    class: "w-100 flex items-center justify-between py-4 pl-4 pr-4 md:py-3 md:pl-8 md:pr-8 lg:pl-0"
                  }, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_hashtag_linear, { class: "icon-hashtag-linear mr-3 text-grey-400" }),
                      thread
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(thread.subject), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getThreadOwnerAsUser(thread).fullName), 1 /* TEXT */)
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ], 2 /* CLASS */)
            ], 2 /* CLASS */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "name", "onConfirm", "onCancel"]))
      : _createCommentVNode("v-if", true)
  ]))
}