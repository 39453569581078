<template>
  <t-modal
    v-model="shouldOpenModal"
    class="enable-workload-modal"
    :title="$t('team.workload_balancing_title')"
    :close-on-backdrop-click="false"
    initial-min-height="45rem"
    @close="handleCancel"
  >
    <div class="enable-workload-modal-main">
      <channel-items-list
        v-if="step === 1"
        :channel-is-connected-to-team="thereIsChannelConnectedToTeam"
        :team="team"
        :channels="channels"
        @remove="removeChannel"
      />
      <template v-else>
        <add-all-conversations v-model="addAllConversations" />
        <div class="my-6">
          <div class="t-text-desktop-label-lg mb-1">{{ $t('team.set_conversation_limit_title') }}</div>
          <div class="t-text-desktop-paragraph-sm sm:t-text-desktop-paragraph-md text-grey-700">
            {{ $t('team.conversation_limit_description') }}
          </div>
        </div>
        <div ref="users-table" class="overflow-auto">
          <users-table :users="users" @change="handleUserChanges" />
        </div>
        <close-dialog v-if="decidedToCancel" :element="$refs['footer-body']" @cancel="onCancel" />
      </template>
    </div>

    <template #footer>
      <div ref="footer-body" class="flex w-full flex-col items-center justify-end gap-3 md:flex-row">
        <t-button
          class="w-full md:w-auto"
          size="md"
          :disabled="decidedToCancel"
          btn-style="secondary"
          data-test="modal-cancel-btn"
          @click="handleCancel"
        >
          {{ $t('general.cancel') }}
        </t-button>
        <t-button
          v-if="step === 1"
          class="w-full md:w-auto"
          :disabled="thereIsChannelConnectedToTeam"
          size="md"
          data-test="enable-workload-modal-button"
          @click="handleGoToNextStep"
        >
          {{ $t('general.next_step') }}
        </t-button>
        <t-button v-else class="w-full md:w-auto" size="md" data-test="enable-workload-modal-button" @click="onAdd">
          {{ $t('general.activate') }}
        </t-button>
      </div>
    </template>
  </t-modal>
</template>

<script>
import { cloneDeep } from 'lodash';

import breakpoints from '@/util/breakpoints';

import AddAllConversations from './components/AddAllConversations';
import ChannelItemsList from './components/ChannelItemsList';
import CloseDialog from './components/CloseDialog';
import UsersTable from './components/UsersTable';

export default {
  name: 'EnableWorkLoadModal',
  emits: ['cancel', 'submit', 'remove-channel'],
  components: {
    ChannelItemsList,
    CloseDialog,
    AddAllConversations,
    UsersTable,
  },
  data() {
    return {
      step: 1,
      breakpoints,
      addAllConversations: false,
      decidedToCancel: false,
      newUsersObject: [],
    };
  },
  created() {
    const usersWithZeroLimit = this.users.map((user) => {
      user.load_balancing_ticket_limit = 0;
      return user;
    });
    this.newUsersObject = cloneDeep(usersWithZeroLimit);
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    team: {
      type: String,
      default: '',
    },
    channels: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    thereIsChannelConnectedToTeam() {
      return (
        this.channels
          .map((item) => {
            return item.workloadBalancingTeams?.length > 0;
          })
          .filter(Boolean).length > 0
      );
    },
    isMobile() {
      return !this.breakpoints.comparisons.gt_md;
    },
    shouldOpenModal() {
      return this.isOpen;
    },
  },
  methods: {
    removeChannel(channel) {
      this.$emit('remove-channel', channel);
    },
    handleGoToNextStep() {
      this.step = 2;
    },
    handleUserChanges(users) {
      this.newUsersObject = users;
    },
    onAdd() {
      const data = { users: this.newUsersObject, add_all_conversations: this.addAllConversations };
      this.$emit('submit', data);
      this.onCancel();
    },
    handleCancel() {
      if (this.step === 1) {
        this.onCancel();
        return false;
      }
      this.decidedToCancel = true;
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
