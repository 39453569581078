import messagesApi from './Messages';
import threadsApi from './Threads';

export default {
  threads: threadsApi,
  messages: messagesApi,
};

export const messages = messagesApi;
export const threads = threadsApi;
