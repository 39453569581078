<template>
  <portal to="body-portal">
    <div ref="modal" class="modal">
      <div class="modal-centered height-auto" style="max-width: 950px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Sender domains</h5>
            <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close-btn">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body" style="max-height: 500px; overflow-y: auto">
            <table id="general-table" class="table-striped table-vcenter table">
              <thead>
                <tr>
                  <th width="20%">Domain</th>
                  <th width="20%">Mailbox</th>
                  <th width="20%">DKIM</th>
                  <th width="20%">SPF</th>
                  <th width="20%"></th>
                  <th class="text-center"><i class="fa fa-flash"></i></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="domain in senderDomains">
                  <td>{{ domain['domain_only'] }}</td>

                  <td v-show="!domain['email_verified'] || domain.mailbox == null">
                    <i class="fa fa-close text-warning"></i>
                    Requires setup
                    <br />
                    <a class="text-muted" @click="verifyDomain(domain)">View instructions</a>
                  </td>
                  <td v-show="domain['email_verified'] && domain.mailbox != null">{{ domain.mailbox }}</td>

                  <td v-show="!domain['dkim_valid']">
                    <i class="fa fa-close text-warning"></i>
                    DKIM invalid
                    <br />
                    <a class="text-muted" @click="showSettings(domain['id'], 'dkim')">View DKIM settings</a>
                  </td>
                  <td v-show="domain['dkim_valid']"><i class="fa fa-check text-success"></i></td>

                  <td v-show="!domain['spf_valid']">
                    <i class="fa fa-close text-warning"></i>
                    SPF invalid
                    <br />
                    <a class="text-muted" @click="showSettings(domain['id'], 'spf')">View SPF settings</a>
                  </td>

                  <td v-show="domain['spf_valid']" @click="deleteDomain(domain['id'])">
                    <i class="fa fa-check text-success"></i>
                  </td>

                  <td>
                    <button
                      class="btn btn-xs btn-default"
                      :disabled="domain.loading"
                      @click="checkStatus(domain)"
                      v-text="domain.loading ? 'Testing...' : 'Test settings'"
                    ></button>
                  </td>

                  <td class="text-center">
                    <a @click="deleteDomain(domain['id'])">
                      <i class="material-icons text-red">remove_circle_outline</i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer text-left">
            <button type="button" class="btn btn-default" @click="startAddDomain">New domain</button>
            <button type="button" class="btn btn-link text-muted" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>

    <div ref="verifyModal" class="modal">
      <div class="modal-centered height-auto" style="max-width: 750px">
        <div class="modal-content">
          <form class="form-horizontal" @submit.prevent="verifyDomainSubmit">
            <div class="modal-header">
              <h5 class="modal-title">Verify domain {{ selectedDomain.domain_only }}</h5>
              <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close-btn">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <p>
                Choose a mailbox for the domain {{ selectedDomain.domain_only }}. We will send an email to this account
                to verify that you have access to the mailbox. You
                <strong>MUST</strong>
                forward this email to `verify@trengo.com` and we will verify the domain as soon as possible.
              </p>
              <div class="form-group row">
                <label class="col-sm-2 form-control-label">
                  Mailbox:
                  <span class="text-danger">*</span>
                </label>
                <div class="col-sm-10">
                  <div class="input-group">
                    <input v-model="selectedDomain.mailbox" required class="form-control" placeholder="info" />
                    <span class="input-group-addon">@</span>
                    <input v-model="selectedDomain.domain_only" required disabled class="form-control" />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer text-left">
              <button type="submit" class="btn btn-default" :class="{ loader: verifying }">
                Send verification email
              </button>
              <button type="button" class="btn btn-link text-muted" data-dismiss="modal">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div ref="newModal" class="modal">
      <div class="modal-centered height-auto">
        <div class="modal-content">
          <form class="form-horizontal" @submit.prevent="addDomain">
            <div>
              <div class="modal-header">
                <h5 class="modal-title">Add domain</h5>
                <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close-btn">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="form-group row">
                  <label class="col-sm-2 form-control-label">
                    Domain:
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-sm-10">
                    <div class="input-group">
                      <input v-model="domain.domain" required placeholder="example.com" class="form-control" />
                      <span class="form-text">Enter the domain without www or http(s), for example: trengo.com</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer text-left">
                <button type="submit" class="btn success text-white" :class="{ loader: adding }">Add domain</button>
                <button type="button" class="btn btn-link text-muted" data-dismiss="modal">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div ref="dkimModal" class="modal">
      <div class="modal-centered height-auto">
        <div class="modal-content">
          <form class="form-horizontal" @submit.prevent="addDomain">
            <div>
              <div class="modal-header">
                <h5 class="modal-title">DKIM setup</h5>
                <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close-btn">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div v-show="!checkLoading" class="modal-body">
                <div v-show="checkDomain.dkim_error != null" class="alert alert-danger">
                  {{ checkDomain.dkim_error }}
                </div>
                <p>
                  To enable DKIM, create a TXT record for
                  <strong>mandrill._domainkey.{{ checkDomain.domain }}</strong>
                  with the following value:
                </p>
                <pre class="mb-0 p-4" v-text="checkDomain.dkim"></pre>
              </div>
              <div v-show="!checkLoading" class="modal-footer text-left">
                <a
                  class="text-info"
                  href="https://mandrill.zendesk.com/hc/en-us/articles/205582277-How-do-I-add-DNS-records-for-my-sending-domains"
                  target="_blank"
                >
                  Not sure how to change DKIM settings?
                </a>
              </div>
              <div v-show="checkLoading" class="modal-body p-4 text-center">
                <i class="fa fa-spinner fa-spin"></i>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div ref="spfModal" class="modal">
      <div class="modal-centered height-auto">
        <div class="modal-content">
          <form class="form-horizontal" @submit.prevent="addDomain">
            <div>
              <div class="modal-header">
                <h5 class="modal-title">SPF setup</h5>
                <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close-btn">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div v-show="!checkLoading" class="modal-body">
                <div v-show="checkDomain.spf_error != null" class="alert alert-danger">{{ checkDomain.spf_error }}</div>
                <p>
                  To enable SPF, create a TXT record for
                  <strong>{{ checkDomain.domain }}</strong>
                  with the following value:
                </p>
                <pre class="p-4" v-text="checkDomain.spf"></pre>
                <p class="mb-0">
                  If you already have a TXT record with SPF information, you'll need to add Mandrill's servers to that
                  record by adding include:spf.mandrillapp.com in the record (before the last operator, which is usually
                  ?all, ~all, or -all).
                </p>
              </div>
              <div v-show="!checkLoading" class="modal-footer text-left">
                <a class="text-info" href="https://mandrill.zendesk.com/hc/en-us/articles/360038803434" target="_blank">
                  Not sure how to change SPF settings?
                </a>
              </div>
              <div v-show="checkLoading" class="modal-body p-4 text-center">
                <i class="fa fa-spinner fa-spin"></i>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </portal>
</template>

<script type="text/babel">
export default {
  name: 'SenderDomains',
  emits: ['changed'],
  props: {
    record: {
      type: Object,
      default: () => ({}),
    },
    senderDomains: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      domain: {
        loading: false,
      },
      selectedDomain: {},
      adding: false,
      verifying: false,
      checkDomain: {},
      checkLoading: null,
    };
  },

  mounted() {
    $(this.$refs.newModal).on('hidden.bs.modal', () => {
      $(this.$refs.modal).modal('show');
    });
  },

  methods: {
    verifyDomain(domain) {
      this.selectedDomain = {
        id: domain.id,
        domain: domain.domain,
        mailbox: domain.mailbox,
        domain_only: domain.domain_only,
      };

      $(this.$refs.verifyModal).modal('show');
    },

    verifyDomainSubmit() {
      if (this.verifying) return;
      this.verifying = true;

      axios
        .put('/api/v2/email/sender_domains/' + this.selectedDomain.id, { mailbox: this.selectedDomain.mailbox })
        .then((res) => {
          this.$emit('changed');

          if (res.data.already_verified == false) {
            this.flashSuccess(
              'Verification email sent successfully. You must forward this email to verify@trengo.com.'
            );
          } else {
            this.flashSuccess('Mailbox saved. This domain was already verified by your organisation.');
          }

          this.verifying = false;
          $(this.$refs.verifyModal).modal('hide');
        })
        .catch(() => {
          this.verifying = false;
        });
    },

    showSettings(id, type) {
      if (type == 'spf') {
        $(this.$refs.spfModal).modal();
      }

      if (type == 'dkim') {
        $(this.$refs.dkimModal).modal();
      }

      this.checkLoading = true;
      axios
        .get('/api/v2/email/sender_domains/' + id)
        .then((res) => {
          this.checkDomain = res.data;
          this.checkLoading = false;
        })
        .catch(() => {
          this.checkLoading = false;
        });
    },

    checkStatus(domain) {
      this.checking = true;
      this.domain = Object.assign({}, this.domain, { loading: true });
      axios
        .get('/api/v2/email/sender_domains/' + domain.id, { params: { test: true } })
        .then((res) => {
          this.$emit('changed');
          this.domain = Object.assign({}, this.domain, { loading: false });
        })
        .catch(() => {
          this.checking = false;
          this.domain = Object.assign({}, this.domain, { loading: false });
        });
    },

    deleteDomain(id) {
      if (!confirm('Are you sure you want to delete this domain?')) {
        return;
      }
      axios.delete('/api/v2/email/sender_domains/' + id).then((result) => {
        this.$emit('changed');
      });
    },

    startAddDomain() {
      $(this.$refs.newModal).modal('show');
      $(this.$refs.modal).modal('hide');
    },

    addDomain() {
      if (this.adding) return;
      this.adding = true;
      axios
        .post('/api/v2/email/sender_domains', this.domain)
        .then((result) => {
          this.$emit('changed');
          $(this.$refs.newModal).modal('hide');
          this.domain = {};
          this.adding = false;
        })
        .catch(() => {
          this.adding = false;
        });
    },
  },
};
</script>
