import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const labelsRoutes: RouteRecord[] = [
  {
    path: '/admin/labels',
    component: require('../../components/Labels/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__LABELS__MANAGE },
    children: [
      {
        path: '/admin/labels/create',
        component: require('../../components/Labels/Edit').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__LABELS__MANAGE },
      },
      {
        path: '/admin/labels/:id',
        component: require('../../components/Labels/Edit').default,
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__LABELS__MANAGE },
      },
      {
        path: '',
        component: require('../../components/AdminIndexBox').default,
        props: { boxName: 'labels' },
        meta: { permissionRequired: PERMISSION.SETTINGS__LABELS__MANAGE },
      },
    ],
  },
];

export default labelsRoutes;
