<template>
  <div class="search-entities">
    <input
      v-model="computedValue"
      class="search-entities-input"
      v-bind="$attrs"
      type="search"
      placeholder="Search..."
      data-test="search-entities-field"
      @input="handleChangeValue"
    />

    <button v-if="computedValue" class="clear fade-in" @click="clear">
      <cross-circle-fill />
    </button>

    <button class="button">
      <search-linear />
    </button>
  </div>
</template>

<script>
import { CrossCircleFill, SearchLinear } from '@trengo/trengo-icons';

export default {
  name: 'ContactsSearch',
  emits: ['update:modelValue'],
  components: {
    SearchLinear,
    CrossCircleFill,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleRemoveSearch);
  },
  unmounted() {
    document.removeEventListener('keydown', this.handleRemoveSearch);
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    handleRemoveSearch(e) {
      if (e.code === 'Escape') {
        this.clear();
      }
    },
    handleChangeValue(e) {
      this.computedValue = e.target.value;
    },
    clear() {
      this.computedValue = '';
    },
  },
};
</script>

<style lang="scss" scoped src="./SearchEntities.scss" />
