<template>
  <div class="wab-setting-item">
    <div :class="`pt-${paddingTop}`">
      <div class="t-text-desktop-label-md mb-1" v-if="$slots.label"><slot name="label" /></div>
      <div class="t-text-desktop-paragraph-xs mb-2 text-grey-600" v-if="$slots.desc">
        <slot name="desc" />
      </div>

      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'WabSettingItem',
  props: {
    paddingTop: {
      type: Number,
      default: 8, // TODO: tailwind validate
    },
  },
};
</script>
