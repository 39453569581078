import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7b5922fb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-row items-center justify-start" }
const _hoisted_2 = { class: "flex flex-row items-center justify-center" }
const _hoisted_3 = { class: "flex flex-row items-center justify-center" }
const _hoisted_4 = { class: "flex flex-row items-center justify-center" }
const _hoisted_5 = { class: "flex flex-row items-center justify-end" }
const _hoisted_6 = { class: "w-18 flex h-8 items-center justify-between rounded-lg border border-grey-300 px-2 py-3" }
const _hoisted_7 = {
  key: 0,
  class: "empty-state absolute top-0 h-full w-full",
  "data-test": "error-state"
}
const _hoisted_8 = { class: "flex h-full w-full flex-col items-center justify-center" }
const _hoisted_9 = { class: "mb-5 rounded-xl border border-grey-500 bg-white p-2" }
const _hoisted_10 = { class: "mb-2" }
const _hoisted_11 = { class: "t-text-md-emphasize m-0 text-grey-800" }
const _hoisted_12 = { class: "m-0 text-grey-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_table_list_head = _resolveComponent("t-table-list-head")!
  const _component_t_table_list_row = _resolveComponent("t-table-list-row")!
  const _component_t_table_list_cell = _resolveComponent("t-table-list-cell")!
  const _component_Grid07Linear = _resolveComponent("Grid07Linear")!
  const _component_t_table_list = _resolveComponent("t-table-list")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_t_table_list, {
      alternate: "",
      class: "relative p-0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_t_table_list_row, {
          head: "",
          "data-test": "skeleton-table-header"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_t_table_list_head),
            _createVNode(_component_t_table_list_head),
            _createVNode(_component_t_table_list_head),
            _createVNode(_component_t_table_list_head),
            _createVNode(_component_t_table_list_head)
          ]),
          _: 1 /* STABLE */
        }),
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4, 5, 6], (item) => {
          return _createVNode(_component_t_table_list_row, { key: item }, {
            default: _withCtx(() => [
              _createVNode(_component_t_table_list_cell, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", {
                      class: _normalizeClass([_ctx.animationClass, "mr-2 h-8 w-8 rounded-full bg-grey-300"])
                    }, null, 2 /* CLASS */),
                    _createElementVNode("div", {
                      class: _normalizeClass([_ctx.animationClass, "h-2 w-16 rounded-xl bg-grey-300"])
                    }, null, 2 /* CLASS */)
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_t_table_list_cell, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", {
                      class: _normalizeClass([_ctx.animationClass, "h-2 w-16 rounded-xl bg-grey-300"])
                    }, null, 2 /* CLASS */)
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_t_table_list_cell, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", {
                      class: _normalizeClass([_ctx.animationClass, "h-2 w-16 rounded-xl bg-grey-300"])
                    }, null, 2 /* CLASS */)
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_t_table_list_cell, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", {
                      class: _normalizeClass([_ctx.animationClass, "h-2 w-16 rounded-xl bg-grey-300"])
                    }, null, 2 /* CLASS */)
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_t_table_list_cell, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", {
                        class: _normalizeClass([_ctx.animationClass, "h-2 w-16 rounded-xl bg-grey-300"])
                      }, null, 2 /* CLASS */)
                    ])
                  ])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)
        }), 64 /* STABLE_FRAGMENT */)),
        (_ctx.showError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_Grid07Linear, { size: "1.4rem" })
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('reports_v2.no_result_match_your_filter')), 1 /* TEXT */)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('reports_v2.no_result_match_your_filter_description')), 1 /* TEXT */)
                ])
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ]))
}