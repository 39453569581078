<script>
export default {
  name: 'StepWizard',
  emits: ['update:activeStepWizardIndex'],
  props: {
    steps: {
      type: Array,
      default: () => [],
      required: true,
    },
    activeStepWizardIndex: {
      type: Number,
      required: true,
    },
  },
};
</script>
<template>
  <div class="flex flex-col">
    <template v-for="index in steps">
      <div v-if="index === activeStepWizardIndex">
        <slot :name="'step_' + index">Step {{ index }}</slot>
      </div>
    </template>
    <div class="my-4"></div>
    <div class="absolute my-4 -ml-16 w-full" style="bottom: 0">
      <div class="flex justify-center">
        <div
          v-for="index in steps"
          :class="index === activeStepWizardIndex ? 'bg-green' : 'bg-grey-200'"
          class="dot pointer mx-1"
          @click="$emit('update:activeStepWizardIndex', index)"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.dot {
  width: 14px;
  height: 14px;
  border-radius: 9999px;
}
</style>
