import { capitalize } from 'lodash';

import { SHORT_MONTH_LIST } from '@/Configs/Constants';

import { FULL_BAR_LENGTH } from './constants';

import type { BarValues } from './types';

export const formatAmount = (format: 'currency' | 'number', amount: number) => {
  const locale = 'nl-NL';
  const Euro = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
  });
  switch (format) {
    case 'currency':
      return Euro.format(amount / 100);
    case 'number':
      return amount.toLocaleString(locale, {
        style: 'decimal',
      });
    default:
      return amount;
  }
};

export const generateChartLabels = (month: string) => {
  const startingMonthIndex = SHORT_MONTH_LIST.findIndex((item) => item === month.toLowerCase());
  return Array.from({ length: 12 }, (_, i) => {
    const monthIndex = (startingMonthIndex + i) % 12;
    return capitalize(SHORT_MONTH_LIST[monthIndex]);
  });
};

export const calculateDividedBarValues = (conversationValues: {
  spentAmount: number;
  inProgressAmount: number;
  quota: number;
}): BarValues[] => {
  const { spentAmount, inProgressAmount, quota } = conversationValues;
  if (quota < 0 || inProgressAmount < 0 || spentAmount < 0) {
    return [];
  }

  if (quota === 0) {
    return [{ ...conversationValues, barLength: FULL_BAR_LENGTH }];
  }

  const isOverQuota = spentAmount > quota;
  const totalConversations = spentAmount + inProgressAmount;
  const oneBarLength = totalConversations / FULL_BAR_LENGTH;

  const firstBarLength = Math.max(1, Math.floor(quota / oneBarLength));

  const firstBarValues: BarValues = {
    spentAmount: isOverQuota ? quota : spentAmount,
    inProgressAmount: isOverQuota ? 0 : quota - spentAmount,
    quota: quota,
    barLength: firstBarLength,
  };

  const secondBarQuota = totalConversations - quota;
  const secondBarSpentAmount = spentAmount - firstBarValues.spentAmount;

  const secondBarValues: BarValues = {
    spentAmount: secondBarSpentAmount,
    inProgressAmount: secondBarQuota - secondBarSpentAmount,
    quota: secondBarQuota,
    barLength: FULL_BAR_LENGTH - firstBarLength,
  };

  return [firstBarValues, secondBarValues];
};
