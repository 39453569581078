<script setup lang="ts">
import { ChevronDownLinear } from '@trengo/trengo-icons';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useChannelTypes } from '@/store/pinia/channels/hooks/useChannelTypes';

import { useChannels } from './useChannels';
import { useInboxSettingsChannelsStore } from './useInboxSettingsChannelsStore';

import type { ChannelState } from './types';
import type { CHANNEL_SETTING } from './useInboxSettingsChannelsStore';
import type { ChannelType } from '@/store/pinia/channels/types';

const props = defineProps<{
  setting: valueof<typeof CHANNEL_SETTING>;
}>();

type ChannelKey = keyof typeof channelAssets.value;

const inboxSettingsChannelsStore = useInboxSettingsChannelsStore();
const { channelAssets } = useChannelTypes();
const { t } = useI18n();
const { channels, activeChannelIds } = useChannels(props.setting);
const selectedChannelIdList = ref(activeChannelIds);

const toggle = (channelType: ChannelType, channelId: ChannelState['id']) => {
  if (!selectedChannelIdList.value[channelType]) {
    selectedChannelIdList.value[channelType] = [];
  }

  if (selectedChannelIdList.value[channelType].includes(channelId)) {
    selectedChannelIdList.value[channelType] = selectedChannelIdList.value[channelType].filter(
      (id) => id !== channelId
    );
  } else {
    selectedChannelIdList.value[channelType].push(channelId);
  }
};

const updateActiveChannels = async () => {
  await inboxSettingsChannelsStore.updateActiveChannels(selectedChannelIdList.value, props.setting);
};

const toggleChannel = async (channelType: ChannelType, channelId: ChannelState['id']) => {
  toggle(channelType, channelId);
};

/**
 * Toggles all channels in a channel list based on the channel type.
 * If all channels are selected, it will deselect all channels.
 * If some channels are selected, it will select all channels.
 * If no channels are selected, it will select all channels.
 */
const toggleChannelList = (channelName: ChannelType) => {
  const toggleAllChannels = () => {
    channels.value?.[channelName]?.forEach((channel) => {
      toggleChannel(channelName, channel.id);
    });
  };

  const channelNameList = selectedChannelIdList.value[channelName];
  const channelTypeLength = channels.value?.[channelName]?.length ?? 0;

  const areAllChannelsSelected = channelNameList?.length === channelTypeLength;
  const areSomeChannelsSelected = channelNameList?.length && channelNameList.length < channelTypeLength;

  if (areSomeChannelsSelected) {
    selectedChannelIdList.value[channelName] = [];
    toggleAllChannels();
  } else if (areAllChannelsSelected) {
    selectedChannelIdList.value[channelName] = [];
  } else {
    toggleAllChannels();
  }
  updateActiveChannels();
};

const visibleChannels = ref<string[]>([]);
const getVisibleInboxSettingsChannels = () => {
  return typeof sessionStorage.visibleInboxSettingsChannels !== 'undefined'
    ? JSON.parse(sessionStorage.visibleInboxSettingsChannels)
    : [];
};

onMounted(() => {
  visibleChannels.value = getVisibleInboxSettingsChannels()[props.setting] ?? [];
});

/**
 * Toggles the visibility of a channel list based on its type.
 */
const toggleVisibleChannel = (newChannel: ChannelType) => {
  if (visibleChannels.value.includes(newChannel)) {
    visibleChannels.value = visibleChannels.value.filter((channel) => channel !== newChannel);
  } else {
    visibleChannels.value.push(newChannel);
  }

  sessionStorage.visibleInboxSettingsChannels = JSON.stringify({
    ...(getVisibleInboxSettingsChannels() || {}),
    [props.setting]: visibleChannels.value,
  });
};
</script>

<template>
  <header data-test="channel-list-header">
    <h2 class="mb-8 text-lg font-bold">
      {{ t('settings.inbox_page__header_title') }}
    </h2>
    <div class="flex gap-3">
      <div class="grid place-content-center">
        <span class="rounded-xl border-1 border-solid border-[#E1E3E5] p-2" data-test="channel-list-icon">
          <slot name="icon" />
        </span>
      </div>
      <div class="flex flex-col">
        <h3 class="mb-1 text-base font-semibold" data-test="channel-list-subtitle">
          <slot name="subtitle" />
        </h3>
        <p class="text-sm text-grey-700" data-test="channel-list-description">
          <slot name="description" />
        </p>
      </div>
    </div>
  </header>
  <!-- eslint-disable-next-line vue/valid-template-root -->
  <div data-test="channel-list-section" class="rounded-lg border-1 border-solid border-[#E1E3E5] p-4">
    <h3 class="text-base font-semibold">{{ t('channels.enabled_channels') }}</h3>
    <ul class="min-h-[15rem] p-2">
      <TransitionGroup name="fade">
        <li
          v-for="(channelList, type) in channels"
          :key="type"
          :data-test="`channel-list-list-item-${type}`"
          class="list-none rounded-md bg-grey-200"
        >
          <div
            class="my-2 flex items-center justify-between rounded-md p-1 hover:bg-grey-300"
            @click.self="toggleVisibleChannel(type)"
          >
            <t-input-checkbox
              :key="type"
              :model-value="selectedChannelIdList[type]?.length === channelList?.length"
              class="relative ms-2 flex"
              @change="toggleChannelList(type)"
            >
              <div class="flex items-center gap-1 p-2">
                <div
                  class="grid size-8 place-content-center rounded-lg border-1 border-solid border-grey-300 bg-white p-1"
                >
                  <img
                    :src="channelList?.[0].icon"
                    class="rounded-full"
                    :aria-labelledby="`${channelAssets[type as ChannelKey]?.title}-img`"
                  />
                </div>
                <div :id="`${channelAssets[type as ChannelKey]?.title}-img`">
                  {{ channelAssets[type as ChannelKey]?.title }}
                </div>
                <Transition name="fade">
                  <!-- FIXME: This is a feature missing. The following div should be inside the checkbox component. -->
                  <div
                    v-if="
                      selectedChannelIdList[type]?.length && selectedChannelIdList[type]?.length !== channelList?.length
                    "
                    class="
                      absolute
                      left-0
                      top-[50%]
                      grid
                      size-[1.125rem]
                      translate-y-[-50%]
                      place-content-center
                      rounded-[.25rem]
                      bg-leaf-500
                    "
                  >
                    <span class="h-[1.5px] w-[9px] bg-white" />
                  </div>
                </Transition>
              </div>
            </t-input-checkbox>
            <div class="flex items-center gap-2">
              <span
                class="grid size-6 place-content-center rounded-[50%] bg-grey-300 text-xs font-semibold text-grey-700"
              >
                {{ activeChannelIds[type]?.length }}
              </span>
              <button
                type="button"
                class="grid size-6 place-content-center rounded-[50%] hover:bg-white focus:bg-white"
                data-test="channel-list-toggle-chevron"
                @click="toggleVisibleChannel(type)"
              >
                <ChevronDownLinear
                  size="1.125rem"
                  class="text-grey-600 transition-transform"
                  :class="{ 'rotate-180': visibleChannels.includes(type) }"
                />
              </button>
            </div>
          </div>
          <TransitionGroup name="list">
            <ul
              v-for="channel in channelList"
              v-show="visibleChannels.includes(type as ChannelType)"
              :key="channel.id"
              :data-test="`channel-list-subitems-${channel.name}`"
              class="list-none rounded-lg bg-grey-200 px-4 pb-2"
            >
              <t-list-item-checkbox
                class="transition-none hover:bg-grey-300"
                :label="channel.name"
                size="md"
                :model-value="selectedChannelIdList[type]?.includes(channel.id)"
                @change="
                  () => {
                    toggleChannel(type, channel.id);
                    updateActiveChannels();
                  }
                "
              />
            </ul>
          </TransitionGroup>
        </li>
      </TransitionGroup>
    </ul>
  </div>
</template>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.25s ease-in-out;
}

.list-enter-from,
.list-leave-to {
  opacity: 0.2;
  z-index: -1;
  transform: translateX(-15px);
}
</style>
