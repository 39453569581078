import { BASE_URL } from '@/api/constants';
import { request } from '@/util';

import type { BaseCustomIntegration } from '@/store/types/integrations';
import type { AxiosPromise } from 'axios';

export async function createCustomIntegration(
  customIntegration: BaseCustomIntegration
): Promise<AxiosPromise<BaseCustomIntegration>> {
  return request(`${BASE_URL}plugins`, 'POST', customIntegration);
}

export async function updateCustomIntegration(
  customIntegration: BaseCustomIntegration
): Promise<AxiosPromise<BaseCustomIntegration>> {
  return request(`${BASE_URL}plugins/${customIntegration.id}`, 'PUT', customIntegration);
}

export async function deleteCustomIntegration(integrationId: BaseCustomIntegration['id']) {
  return request(`${BASE_URL}plugins/${integrationId}`, 'DELETE');
}

export async function fetchPlugin(integrationId: number): Promise<AxiosPromise<BaseCustomIntegration>> {
  return request(`${BASE_URL}plugins/${integrationId}`, 'GET');
}
