<template>
  <div class="presentation_wrapper">
    <div class="presentation-items__wrapper">
      <div class="presentation-item">
        <div class="presentation-item__icon"><lock2-linear class="text-galaxy-700" size="1.25rem" /></div>
        <div class="presentation-item__body">
          <div class="presentation-item__body__text">
            <p class="mb-0">{{ $t('onboarding.channels_presentation_data_safe') }}</p>
          </div>
          <div class="presentation-item__body__description">
            <p class="mb-0">{{ $t('onboarding.channels_presentation_data_safe_description') }}</p>
          </div>
        </div>
      </div>
      <div class="presentation-item mt-12">
        <div class="presentation-item__icon"><import3-linear class="text-galaxy-700" size="1.25rem" /></div>
        <div class="presentation-item__body">
          <div class="presentation-item__body__text">
            <p class="mb-0">{{ $t('onboarding.channels_presentation_focus_important') }}</p>
          </div>
          <div class="presentation-item__body__description">
            <p class="mb-0">{{ $t('onboarding.channels_presentation_focus_important_description') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="presentation-application-overview">
      <img :src="applicationImage" />
    </div>
  </div>
</template>
<script>
import { Import3Linear, Lock2Linear } from '@trengo/trengo-icons';
import ApplicationOverview from './assets/application_overview.svg';
export default {
  name: 'PresentationView',
  components: { Lock2Linear, Import3Linear },
  data() {
    return {
      applicationImage: ApplicationOverview,
    };
  },
};
</script>
<style src="./PresentationView.scss" lang="scss" scoped />
