import eventBus from '@/eventBus';
export default {
  namespaced: true,
  state: {
    enabled: ((window.AGENCY || {}).data || {}).onboarding_steps_enabled || false,
    show_new_badge: false,
    stepsSeen: [],
    unSeenSteps: [],
    steps: [],
  },
  getters: {
    getSteps(state) {
      return state.steps;
    },
    getStepByType: (state) => (type) => {
      return state.steps.find((step) => step.type === type);
    },
    getShowNewBadge(state) {
      return state.show_new_badge;
    },
    getSeenStepsType(state) {
      return state.stepsSeen;
    },
    getUnSeenStepsTypes(state) {
      return state.unSeenSteps.map((item) => item.type);
    },
  },
  mutations: {
    setSteps(state, data) {
      state.steps = data;
      eventBus.$emit('onboarding-steps.set');
    },
    setShowNewBadge(state, data) {
      state.show_new_badge = data;
    },
    setStepsSeen(state, data) {
      state.stepsSeen = data;
    },
    setUnSeenSteps(state, data) {
      state.unSeenSteps = data;
    },
    completeStep(state, { type }) {
      const step = state.steps.find((step) => step.type === type);
      if (!step) {
        return;
      }

      step.completed = true;
      eventBus.$emit('onboarding-steps.set');
    },
  },
  actions: {
    fetchSteps({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v2/onboarding_steps')
          .then((r) => {
            commit('setSteps', r.data || []);
            resolve(r.data);
          })
          .catch((e) => reject(e));
      });
    },
    completeStep({ commit, getters }, { type }) {
      let step = getters.getStepByType(type);
      if (!step || step?.completed) {
        return;
      }

      return new Promise((resolve, reject) => {
        axios
          .put('/api/v2/onboarding_steps/complete/' + type)
          .then(() => {
            commit('setShowNewBadge', type !== 'user.create_account');
            commit('completeStep', { type: type });
            resolve();
          })
          .catch((e) => reject(e));
      });
    },
    fetchStepsSeen({ commit, state, rootState }) {
      const fetchSeenStepsFromUserConfigModule = rootState.userConfig.config['onboarding_steps.steps_seen'];
      commit('setStepsSeen', fetchSeenStepsFromUserConfigModule);
      const unSeenCompletedSteps = state?.steps.filter((step) => {
        return !fetchSeenStepsFromUserConfigModule?.find((s) => s === step.type) && step.completed;
      });
      const userHasNotCompletedAnyTask = !fetchSeenStepsFromUserConfigModule.length;
      commit('setShowNewBadge', userHasNotCompletedAnyTask || unSeenCompletedSteps.length);
      commit('setUnSeenSteps', unSeenCompletedSteps);
      eventBus.$emit('onboarding-steps-seen.fetched');
    },
    fetchShowNewBadge({ commit }) {
      commit('setShowNewBadge', true);
    },
    updateBadgeStatus({ commit }, value) {
      commit('setShowNewBadge', value);
    },
    simulateCompleteAll({ commit, state }, { vm = {}, user = {} }) {
      state.steps.forEach((step) => {
        commit('completeStep', { type: step.type, vm: vm, user: user });
      });
    },
  },
};
