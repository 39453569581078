<script type="text/babel">
import Preferences from './Sections/Preferences';
import TwoFactorAuth from './Sections/TwoFactorAuth';
import UpdatePassword from './Sections/UpdatePassword';
import UpdateProfile from './Sections/UpdateProfile';
import Collapse from '../Collapse';

export default {
  components: {
    Collapse,
    UpdatePassword,
    UpdateProfile,
    TwoFactorAuth,
    Preferences,
  },
};
</script>

<template>
  <div class="flex w-full min-w-0 flex-1">
    <portal to="sidebar">
      <div class="select-none pl-4 pt-2">
        <collapse collapse-key="my_profile" :default-open="true">
          <template #collapse-title>
            <div>
              <h6>{{ $t('user_profile.general_my_profile') }}</h6>
            </div>
          </template>
          <template #collapse-content>
            <div style="margin-left: 20px">
              <div>
                <router-link
                  class="mb-2 flex items-center text-grey-600"
                  active-class="font-bold text-black"
                  to="/profile/overview"
                >
                  <div class="mr-4"><i class="fa fa-folder-o md-18"></i></div>
                  {{ $t('user_profile.general_overview') }}
                </router-link>
              </div>
              <div>
                <router-link
                  class="mb-2 flex items-center text-grey-600"
                  active-class="font-bold text-black"
                  to="/profile/password"
                >
                  <div class="mr-4"><i class="fa fa-folder-o md-18"></i></div>
                  {{ $t('user_profile.general_change_password') }}
                </router-link>
              </div>
              <div>
                <router-link
                  class="mb-2 flex items-center text-grey-600"
                  active-class="font-bold text-black"
                  to="/profile/2fa"
                >
                  <div class="mr-4"><i class="fa fa-folder-o md-18"></i></div>
                  {{ $t('user_profile.general_two_factor_auth') }}
                </router-link>
              </div>
              <div>
                <router-link
                  class="mb-2 flex items-center text-grey-600"
                  active-class="font-bold text-black"
                  to="/profile/preferences"
                >
                  <div class="mr-4"><i class="fa fa-folder-o md-18"></i></div>
                  {{ $t('user_profile.general_preferences') }}
                </router-link>
              </div>
            </div>
          </template>
        </collapse>
      </div>
    </portal>
    <div class="scroll-on-hover flex flex-1 pt-16">
      <div class="container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
