<template>
  <item-button @click="selectSetting">
    <img class="h-28px w-28px" :alt="name" :src="icon" />
    <span class="t-text-button-sm mt-1">
      {{ name }}
    </span>
  </item-button>
</template>

<script>
import eventBus from '@/eventBus';

import ItemButton from './ItemButton';
export default {
  name: 'ModalItem',
  components: { ItemButton },
  props: {
    variant: {
      type: String,
      default: 'channel',
      validator(value) {
        return ['channel', 'integration'].includes(value);
      },
    },
    icon: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  methods: {
    selectSetting() {
      const data = this.variant === 'channel' ? { type: this.type, id: this.id } : this.type;
      if (this.variant === 'channel') {
        eventBus.$emit('choose-channel', data);
      } else {
        eventBus.$emit('choose-integration', data);
      }
    },
  },
  computed: {
    computedName() {
      return this.name.toLowerCase();
    },
  },
};
</script>
