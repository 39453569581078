<template>
  <router-link
    :to="`/tickets/${ticket.id}`"
    class="mb-2 flex flex-col border-b px-2 py-3 transition-colors hover:border-grey-300 hover:bg-grey-200"
    data-test="contact-moment-card-wrapper"
    :class="ticket.id === currentTicket.id ? 'rounded-md border-transparent bg-grey-200' : 'border-grey-300'"
  >
    <div class="mb-2 flex flex-row items-center">
      <p class="t-text-xs-emphasize my-0 min-w-[fit-content] py-0 text-grey-600" data-test="contact-moment-date">
        <date-time v-if="ticket.latest_message" :time="ticket.latest_message.created_at" :pretty="$root.prettyDates" />
        <date-time v-else :time="ticket.created_at" :pretty="$root.prettyDates" />
      </p>

      <p v-if="ticket.contact.name" class="t-text-xs-emphasize mx-2 my-0 p-0 text-center text-grey-600">&bull;</p>

      <p class="t-text-xs-emphasize my-0 text-ellipsis p-0 text-grey-600" data-test="contact-moment-contact-name">
        {{ ticket.contact.name }}
      </p>
    </div>

    <div class="my-0 mb-2 flex flex-col">
      <p v-if="ticket.subject" class="t-text-sm-emphasize my-0 mb-1 text-ellipsis p-0">
        {{ ticket.subject }}
      </p>

      <p
        v-if="ticket.latest_message && ticket.latest_message.message"
        class="t-text-sm my-0 line-clamp-3 p-0"
        data-test="contact-moment-latestMessage"
      >
        {{ ticket.latest_message.message }}
      </p>
    </div>

    <div class="flex flex-row items-center">
      <channel-icon
        :style="{ color: statusColor }"
        :channel="ticket.channel.type"
        class="mr-1"
        data-test="contact-moment-channel-icon"
      />

      <p class="t-text-xs-emphasize m-0 text-ellipsis p-0" data-test="contact-moment-channel-type">
        {{ ticket.channel.title }}
      </p>

      <paperclip-linear v-if="shouldShowAttachmentIcon" class="ml-auto" data-test="contact-moment-attachment-icon" />
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { PaperclipLinear } from '@trengo/trengo-icons';
import { computed } from 'vue';

import ChannelIcon from '@/components/ChannelIcon.vue';
import DateTime from '@/components/DateTime.vue';

import type Profile from '@/types/profile';
import type Ticket from '@/types/tickets';

const props = defineProps<{
  ticket: Ticket;
  currentTicket: Ticket;
  profiles: Profile[];
}>();

const shouldShowAttachmentIcon = computed(() => {
  return props.ticket?.latest_message?.attachments && props.ticket.latest_message.attachments.length > 0;
});

const statusColor = computed(() => {
  switch (props.ticket.status) {
    case 'OPEN':
      return '#F3C111';
    case 'ASSIGNED':
      return '#14B29F';
    case 'CLOSED':
    default:
      return '#ADADAD';
  }
});
</script>
