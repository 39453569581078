import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "create-broadcast flex h-full w-full flex-col overflow-y-scroll bg-white" }
const _hoisted_2 = { class: "create-broadcast-content w-536px flex-1 self-center pt-12" }
const _hoisted_3 = { class: "create-broadcast-header relative flex items-center bg-white" }
const _hoisted_4 = { class: "header-content" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "mx-2 font-semibold" }
const _hoisted_7 = { class: "t-text-desktop-h3 mb-12" }
const _hoisted_8 = { class: "t-text-desktop-label-md" }
const _hoisted_9 = { class: "t-text-desktop-label-md text-grey-600" }
const _hoisted_10 = { class: "t-text-desktop-paragraph-xs mb-2 text-grey-600" }
const _hoisted_11 = { class: "t-text-desktop-label-md mb-2 mt-8" }
const _hoisted_12 = {
  key: 0,
  class: "t-text-desktop-paragraph-sm mx-4 my-3 text-grey-600"
}
const _hoisted_13 = {
  key: 1,
  class: "t-text-desktop-paragraph-sm mx-4 my-3 text-grey-600"
}
const _hoisted_14 = {
  key: 2,
  class: "t-text-desktop-paragraph-sm mx-4 my-3 text-grey-600"
}
const _hoisted_15 = {
  key: 3,
  class: "mx-4 my-3 flex h-40px flex-row rounded-xl bg-grey-200 px-4"
}
const _hoisted_16 = ["src"]
const _hoisted_17 = ["placeholder"]
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "t-text-desktop-paragraph-sm mx-4 border-t border-grey-300 px-2 py-4 text-grey-600" }
const _hoisted_20 = ["src"]
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { class: "t-text-desktop-paragraph-sm mx-4 border-t border-grey-300 px-2 py-4 text-grey-600" }
const _hoisted_23 = ["src"]
const _hoisted_24 = {
  key: 1,
  class: "mt-6"
}
const _hoisted_25 = { class: "t-text-desktop-label-md" }
const _hoisted_26 = { class: "t-text-desktop-paragraph-xs mb-2 text-grey-600" }
const _hoisted_27 = {
  key: 0,
  class: "t-text-desktop-paragraph-sm mx-4 my-3 text-grey-600"
}
const _hoisted_28 = { key: 2 }
const _hoisted_29 = { class: "t-text-desktop-label-md mb-2 mt-6" }
const _hoisted_30 = { key: 0 }
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { class: "whitespace-nowrap" }
const _hoisted_33 = { class: "footer sticky bottom-0 mt-12 border-t-1 border-grey-300" }
const _hoisted_34 = { class: "m-auto flex w-536px justify-between py-4" }
const _hoisted_35 = {
  class: "t-text-desktop-label-lg text-grey-900",
  "data-test": "cost-label"
}
const _hoisted_36 = { class: "t-text-desktop-paragraph-sm text-grey-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_atomic_input = _resolveComponent("atomic-input")!
  const _component_t_dropdown = _resolveComponent("t-dropdown")!
  const _component_t_error_item = _resolveComponent("t-error-item")!
  const _component_whatsapp_composer = _resolveComponent("whatsapp-composer")!
  const _component_sms_composer = _resolveComponent("sms-composer")!
  const _component_info_linear = _resolveComponent("info-linear")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!
  const _component_schedule_send_date = _resolveComponent("schedule-send-date")!
  const _component_user_cross_linear = _resolveComponent("user-cross-linear")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_wab_recipients = _resolveComponent("wab-recipients")!
  const _component_cross_octagon_linear = _resolveComponent("cross-octagon-linear")!
  const _component_external_link2_linear = _resolveComponent("external-link2-linear")!
  const _component_atomic_button = _resolveComponent("atomic-button")!
  const _component_whatsapp_disclaimer_modal = _resolveComponent("whatsapp-disclaimer-modal")!
  const _component_confirm_broadcast_modal = _resolveComponent("confirm-broadcast-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: '/broadcasting',
            class: "pointer flex items-center pb-6",
            "data-test": "back-button"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                class: "mr-2 inline-block h-3",
                src: `${_ctx.$root.assetsURL}img/action_icons/icon-arrow-left.svg`,
                alt: "back"
              }, null, 8 /* PROPS */, _hoisted_5),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('broadcast.back_to_overview_button')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.titleLabel), 1 /* TEXT */),
      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('broadcast.broadcast_name')), 1 /* TEXT */),
      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('broadcast.broadcast_name_optional_label')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('broadcast.for_internal_use_only_and_not_visible_to_your_customers')), 1 /* TEXT */),
      _createVNode(_component_atomic_input, {
        modelValue: _ctx.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
        modelModifiers: { trim: true },
        size: "md",
        placeholder: _ctx.$t('broadcast.broadcast_name_placeholder'),
        autofocus: _ctx.action === 'create',
        "data-test": "name-input"
      }, null, 8 /* PROPS */, ["modelValue", "placeholder", "autofocus"]),
      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('broadcast.broadcast_sender')), 1 /* TEXT */),
      _createVNode(_component_t_dropdown, {
        modelValue: _ctx.sender,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sender) = $event)),
        items: _ctx.computedFilteredSenders,
        "value-index": "id",
        "text-index": "title",
        placeholder: _ctx.$t('broadcast.broadcast_sender'),
        "light-version": "",
        size: "md",
        "item-class": "t-text-desktop-paragraph-md text-grey-800",
        "data-test": "sender-dropdown",
        error: !_ctx.validation.sender,
        disabled: _ctx.isEditingBroadcast
      }, {
        start: _withCtx(() => [
          (
              !_ctx.senders.length && _ctx.isSmsEnabled && _ctx.userStore.hasPermission(_ctx.PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)
            )
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t('broadcast.broadcast_no_whatsapp_and_sms_sender_channel_error')), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          (
              !_ctx.senders.length && !_ctx.isSmsEnabled && _ctx.userStore.hasPermission(_ctx.PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)
            )
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.$t('broadcast.broadcast_no_whatsapp_sender_channel_error')), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          (!_ctx.senders.length && !_ctx.userStore.hasPermission(_ctx.PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE))
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.$t('broadcast.broadcast_no_sender_check_with_admin_error')), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          (_ctx.senders.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("img", {
                  alt: "icon-search",
                  style: {"width":"16px"},
                  src: `${_ctx.$root.assetsURL}img/icon-search.svg`,
                  class: "mr-2"
                }, null, 8 /* PROPS */, _hoisted_16),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search_value) = $event)),
                  class: "t-text-desktop-paragraph-md w-full bg-transparent text-grey-600 placeholder-grey-600",
                  placeholder: _ctx.$t('broadcast.search_in_broadcast_channels')
                }, null, 8 /* PROPS */, _hoisted_17), [
                  [_vModelText, _ctx.search_value]
                ])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        end: _withCtx(() => [
          (_ctx.requiresWhatsappUpgrade)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("img", {
                    class: "mr-2 inline-block h-4",
                    src: `${_ctx.$root.assetsURL}img/wab-broadcasting/whatsapp-grey.svg`
                  }, null, 8 /* PROPS */, _hoisted_20),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('broadcast.whatsapp_is_only_available_on_enterprise')), 1 /* TEXT */)
                ])
              ]))
            : _createCommentVNode("v-if", true),
          (!_ctx.isSmsEnabled && _ctx.userStore.hasPermission(_ctx.PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE))
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("img", {
                    class: "mr-2 inline-block h-4",
                    src: `${_ctx.$root.assetsURL}img/wab-broadcasting/sms-grey.svg`
                  }, null, 8 /* PROPS */, _hoisted_23),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('broadcast.please_contact_support_to_enable_sms_for_broadcasting')), 1 /* TEXT */)
                ])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "items", "placeholder", "error", "disabled"]),
      (!_ctx.validation.sender)
        ? (_openBlock(), _createBlock(_component_t_error_item, {
            key: 0,
            "data-test": "sender-error",
            class: "mb-1 mt-1",
            text: _ctx.$t('broadcast.select_broadcast_sender')
          }, null, 8 /* PROPS */, ["text"]))
        : _createCommentVNode("v-if", true),
      (_ctx.senderObject && _ctx.senderObject.type === _ctx.WA_BUSINESS)
        ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
            _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t('broadcast.message_template')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t('broadcast.broadcast_dont_spam_with_not_allowed_messages')), 1 /* TEXT */),
            _createVNode(_component_t_dropdown, {
              modelValue: _ctx.templateId,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.templateId) = $event)),
              items: _ctx.wa_templates,
              "value-index": "id",
              "text-index": "title",
              placeholder: _ctx.$t('broadcast.select_template_field_label'),
              "light-version": "",
              size: "md",
              "item-class": "t-text-desktop-paragraph-md text-grey-800",
              "data-test": "template-dropdown",
              error: !_ctx.validation.template
            }, {
              start: _withCtx(() => [
                (!_ctx.wa_templates.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(_ctx.$t('broadcast.broadcast_no_template_create_or_wait_for_whatsapp_error')), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "items", "placeholder", "error"]),
            (!_ctx.validation.template)
              ? (_openBlock(), _createBlock(_component_t_error_item, {
                  key: 0,
                  "data-test": "template-error",
                  class: "mb-1 mt-1",
                  text: _ctx.$t('broadcast.please_select_a_template_for_your_broadcast')
                }, null, 8 /* PROPS */, ["text"]))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_whatsapp_composer, {
              modelValue: _ctx.tags,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tags) = $event)),
              template: _ctx.template,
              error: !_ctx.validation.tags,
              onTags: _ctx.updateTags
            }, null, 8 /* PROPS */, ["modelValue", "template", "error", "onTags"])
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.senderObject && _ctx.senderObject.type === _ctx.SMS)
        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
            _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t('broadcast.broadcast_message')), 1 /* TEXT */),
            _createVNode(_component_sms_composer, {
              channel: _ctx.senderObject,
              message: _ctx.message,
              error: !_ctx.validation.message,
              onMessage: _ctx.updateMessage
            }, null, 8 /* PROPS */, ["channel", "message", "error", "onMessage"]),
            (_ctx.tagsAdded)
              ? (_openBlock(), _createBlock(_component_t_inline_banner, {
                  key: 0,
                  class: "tags-warning mt-4"
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_info_linear)
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('broadcast.broadcast_tag_content_length_error_hint')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_schedule_send_date, {
        validation: _ctx.validation,
        "date-radio": _ctx.dateRadio,
        date: _ctx.date,
        time: _ctx.time,
        onTime: _ctx.updateTime,
        onDate: _ctx.updateDate,
        onDateRadio: _ctx.updateDateRadio
      }, null, 8 /* PROPS */, ["validation", "date-radio", "date", "time", "onTime", "onDate", "onDateRadio"]),
      (_ctx.showRecipientsWarning)
        ? (_openBlock(), _createBlock(_component_t_inline_banner, {
            key: 3,
            type: "warning",
            class: "tags-warning mt-2",
            "data-test": "invalid-numbers-warning"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_user_cross_linear)
            ]),
            action: _withCtx(() => [
              _createVNode(_component_t_button, {
                size: "sm",
                "btn-style": "secondary",
                "data-test": "close-recipients-warning-button",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showRecipientsWarning = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Close ")
                ]),
                _: 1 /* STABLE */
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('broadcast.some_numbers_are_not_valid_and_have_not_been_added')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_wab_recipients, {
        modelValue: _ctx.recipientsLocal,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.recipientsLocal) = $event)),
        error: !_ctx.validation.recipients,
        "show-limit": _ctx.senderObject && _ctx.senderObject.type === _ctx.WA_BUSINESS,
        "is-editing": _ctx.isEditingBroadcast
      }, null, 8 /* PROPS */, ["modelValue", "error", "show-limit", "is-editing"]),
      (!_ctx.validation.recipients)
        ? (_openBlock(), _createBlock(_component_t_error_item, {
            key: 4,
            "data-test": "recipients-error",
            class: "mb-1 mt-1",
            text: 
          _ctx.$t('broadcast.recipients_error', {
            date: _ctx.dateRadio === 'now' ? _ctx.$t('general.send').toLowerCase() : _ctx.$t('general.schedule').toLowerCase(),
          })
        
          }, null, 8 /* PROPS */, ["text"]))
        : _createCommentVNode("v-if", true),
      (_ctx.insufficientBalance)
        ? (_openBlock(), _createBlock(_component_t_inline_banner, {
            key: 5,
            type: "error",
            class: "tags-warning mt-12",
            "data-test": "insufficient-balance-warning"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_cross_octagon_linear)
            ]),
            action: _withCtx(() => [
              _createVNode(_component_t_button, {
                class: "flex items-center",
                size: "sm",
                "btn-style": "secondary",
                "data-test": "topup-button",
                onClick: _ctx.topUp
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.$t('broadcast.top_up')), 1 /* TEXT */),
                  _createVNode(_component_external_link2_linear, {
                    size: "14px",
                    class: "info-icon ml-2 text-grey-900"
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["onClick"])
            ]),
            default: _withCtx(() => [
              (_ctx.userStore.hasPermission(_ctx.PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE))
                ? (_openBlock(), _createElementBlock("div", _hoisted_30, _toDisplayString(_ctx.$t('broadcast.you_dont_have_enough_balance_to_send_this_broadcast')), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              (!_ctx.userStore.hasPermission(_ctx.PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE))
                ? (_openBlock(), _createElementBlock("div", _hoisted_31, _toDisplayString(_ctx.$t('broadcast.broadcast_not_enough_balance_contact_admin_error')), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("footer", _hoisted_33, [
      _createElementVNode("div", _hoisted_34, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_35, "€" + _toDisplayString(_ctx.formattedAmount), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.costLabel), 1 /* TEXT */)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_atomic_button, {
            size: "md",
            "btn-style": "secondary",
            "data-test": "cancel-button",
            onClick: _ctx.handleGoBack
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('general.cancel')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"]),
          _createVNode(_component_atomic_button, {
            size: "md",
            class: "ml-3",
            "data-test": "submit-button",
            disabled: _ctx.insufficientBalance,
            onClick: _ctx.handleSubmit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.submitLabel), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled", "onClick"])
        ])
      ])
    ]),
    _createVNode(_component_whatsapp_disclaimer_modal, {
      modelValue: _ctx.whatsappDisclaimerModalIsOpen,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.whatsappDisclaimerModalIsOpen) = $event)),
      onSubmit: _ctx.disclaimerAccepted
    }, null, 8 /* PROPS */, ["modelValue", "onSubmit"]),
    _createVNode(_component_confirm_broadcast_modal, {
      modelValue: _ctx.confirmBroadcastModalIsOpen,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.confirmBroadcastModalIsOpen) = $event)),
      "sender-type": _ctx.senderObject && _ctx.senderObject.type,
      "message-length": _ctx.stripHtml(_ctx.message).length,
      scheduled: _ctx.dateRadio === 'later',
      "tags-added": _ctx.tagsAdded,
      "send-date": _ctx.sendDateModalLabel,
      "total-amount": _ctx.amount,
      balance: _ctx.balance,
      "recipients-quantity": _ctx.recipients.contacts.length + _ctx.recipients.numbers.length,
      "is-confirm-btn-disabled": _ctx.isConfirmBtnDisabled,
      onSubmit: _ctx.confirmBroadcast
    }, null, 8 /* PROPS */, ["modelValue", "sender-type", "message-length", "scheduled", "tags-added", "send-date", "total-amount", "balance", "recipients-quantity", "is-confirm-btn-disabled", "onSubmit"])
  ]))
}