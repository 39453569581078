<script type="text/babel">
import eventBus from '@/eventBus';

import TimeLeftAgo from '../../Elements/TimeLeftAgo';
import TimePicker from '../../Elements/TimePicker';
export default {
  name: 'Preferences',
  data() {
    return {
      saving: null,
      selected: null,
      datum: null,
      gamificationEnabled: false,
    };
  },

  components: {
    TimePicker,
    TimeLeftAgo,
  },

  watch: {
    computedGamificationEnabled(newValue) {
      this.gamificationEnabled = !!newValue;
    },
  },

  computed: {
    computedGamificationEnabled() {
      return !!this.$store.getters['userConfig/get']('onboarding_steps.enabled', false);
    },
  },

  mounted() {
    this.loadSchedule();
    this.initGamificationEnabled();
  },

  methods: {
    initGamificationEnabled() {
      this.gamificationEnabled = this.computedGamificationEnabled;
    },

    saveGamificationEnabled() {
      this.$store
        .dispatch('userConfig/put', { key: 'onboarding_steps.enabled', value: !!this.gamificationEnabled })
        .then((result) => {
          eventBus.$emit('onboarding-steps-init', !!this.gamificationEnabled);
        });
    },

    setSelected() {
      this.selected = 'enabled';
      if (this.$root.doNotDisturb.expires_at) {
        this.selected = 'expires_at';
      }
      if (this.$root.doNotDisturb.enabled) {
        this.selected = 'disabled';
      }
    },
    loadSchedule() {
      axios.get('/api/v2/me/do_not_disturb_schedule').then((response) => {
        this.$root.doNotDisturb = response.data;
        this.$store.commit('misc/setDoNotDisturb', this.$root.doNotDisturb);

        if (this.$root.doNotDisturb.expires_at && !moment(this.$root.doNotDisturb.expires_at).isAfter(moment())) {
          // Expires_at is expired (set date before now), reset
          this.$root.doNotDisturb.expires_at = null;
        }
        this.setSelected();
      });
    },

    submit() {
      if (this.saving == true) {
        return;
      }
      this.saving = true;

      let snooze;
      switch (this.selected) {
        case 'enabled':
          this.$root.doNotDisturb.enabled = false;
          this.$root.doNotDisturb.expires_at = null;
          break;
        case 'disabled':
          this.$root.doNotDisturb.enabled = true;
          break;
        case 'disabled_30_minutes':
          snooze = 30 * 60 * 1000;
          break;
        case 'disabled_1_hour':
          snooze = 60 * 60 * 1000;
          break;
        case 'disabled_4_hours':
          snooze = 4 * 60 * 60 * 1000;
          break;
        case 'disabled_1_week':
          snooze = 7 * 24 * 60 * 60 * 1000;
          break;
      }
      if (snooze > 0) {
        this.$root.doNotDisturb.enabled = false;
        this.$root.doNotDisturb.expires_at = null;
        snooze = moment().valueOf() + snooze;
        snooze = moment(snooze).format('YYYY-MM-DD HH:mm:ss');
        snooze = moment.tz(snooze, this.$root.user.timezone).format('YYYY-MM-DD HH:mm:ss');
        this.$root.doNotDisturb.expires_at = snooze;
      }
      this.setSelected();

      if (this.$root.doNotDisturb.id === null) {
        axios.post('/api/v2/me/do_not_disturb_schedule', this.$root.doNotDisturb).then((response) => {
          this.flashSuccess('Saved');
          this.saving = false;
          this.loadSchedule();
        });
      } else {
        axios
          .put('/api/v2/me/do_not_disturb_schedule/' + this.$root.doNotDisturb.id, this.$root.doNotDisturb)
          .then((response) => {
            this.flashSuccess('Updated');
            this.saving = false;
            this.loadSchedule();
          });
      }

      this.saveGamificationEnabled();
    },
  },
};
</script>
<template>
  <div class="row-col">
    <div class="col-md-12">
      <div class="box">
        <div class="box-header b-b">
          <h2>{{ $t('user_profile.notifications') }}</h2>
        </div>
        <div class="box-body">
          <form @submit.prevent="submit">
            <div class="form-group row">
              <label class="col-md-3 form-control-label">{{ $t('user_profile.notifications') }}</label>
              <div class="col-md-9">
                <div class="flex items-center">
                  <select v-model="selected" class="form-control" style="width: auto">
                    <option value="disabled">{{ $t('user_profile.disabled') }}</option>
                    <option value="enabled">{{ $t('user_profile.enabled') }}</option>
                    <option value="disabled_30_minutes">{{ $t('user_profile.disabled_30_minutes') }}</option>
                    <option value="disabled_1_hour">{{ $t('user_profile.disabled_1_hour') }}</option>
                    <option value="disabled_4_hours">{{ $t('user_profile.disabled_4_hours') }}</option>
                    <option value="disabled_1_week">{{ $t('user_profile.disabled_1_week') }}</option>
                    <option
                      v-if="!$root.doNotDisturb.enabled && $root.doNotDisturb.expires_at !== null"
                      value="expires_at"
                    >
                      {{ $t('user_profile.snoozed') }}
                    </option>
                  </select>
                  <strong v-if="$root.doNotDisturb.enabled" class="ml-4">
                    {{ $t('user_profile.disabled') }}
                  </strong>
                  <strong v-if="!$root.doNotDisturb.enabled && $root.doNotDisturb.expires_at !== null" class="ml-4">
                    <div>
                      Snoozed for
                      <time-left-ago :date="$root.doNotDisturb.expires_at"></time-left-ago>
                    </div>
                  </strong>
                </div>
                <span class="form-text">{{ $t('user_profile.do_not_disturb_text') }}</span>
                <div v-if="selected != 'disabled'">
                  <hr />
                  <div class="mt-4">
                    <div class="flex items-center">
                      <label class="ui-switch success m-r mb-0">
                        <input v-model="$root.doNotDisturb.automatically_enabled" type="checkbox" />
                        <i></i>
                      </label>
                      {{ $t('user_profile.automatically_disable') }}
                    </div>
                    <div v-show="$root.doNotDisturb.automatically_enabled" class="mt-2 mt-4 flex items-center">
                      <div class="mr-2">{{ $t('user_profile.from') }}</div>
                      <div style="width: 150px">
                        <time-picker v-if="$root.doNotDisturb.from" v-model="$root.doNotDisturb.from"></time-picker>
                      </div>
                      <div class="mx-2">{{ $t('user_profile.to') }}</div>
                      <div style="width: 150px">
                        <time-picker v-if="$root.doNotDisturb.to" v-model="$root.doNotDisturb.to"></time-picker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-3"></div>
              <div class="col-md-9">
                <button class="btn success" type="submit">{{ $t('user_profile.save') }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.automaticly_enabled_schedule_hide {
  opacity: 0.3;
}
</style>
