<template>
  <sidebar-dropdown
    :title="$tc('ticket_sidebar.attachments')"
    collapse-key="attachmentsDropdown"
    data-test="attachments-dropdown"
  >
    <div class="flex max-h-[250px] flex-col overflow-y-auto">
      <message-attachment
        v-for="attachment in attachments"
        :key="attachment.full_url"
        :attachment="attachment"
        :is-deletable="false"
        :has-download-button="true"
        class="!bg-transparent"
      />
    </div>
  </sidebar-dropdown>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import MessageAttachment from '@/components/MessageFeed/MessageAttachment.vue';
import SidebarDropdown from '@/components/TicketSidebar/SidebarDropdown.vue';

export default defineComponent({
  name: 'AttachmentsDropdown',

  components: { SidebarDropdown, MessageAttachment },

  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
  },
});
</script>
