<template>
  <div data-test="trying-templates">
    <div>
      <p class="t-text-md mb-6 text-grey-600">{{ $t('whatsapp.value_of_templates') }}</p>
      <div class="relative">
        <img :src="backgroundImageSrc" class="mb-4 h-80 w-140 rounded-lg" />
        <img :src="phoneImageSrc" :class="`absolute bottom-0 left-0 right-0 m-auto ${isMobile && 'h-88'}`" />

        <messages
          :messages="messages"
          wrapper-classes="absolute
            left-0
            right-0
            bottom-0
            m-auto
            flex flex-col
            overflow-y-auto overflow-x-hidden
            p-2
            xs2:h-56 xs2:w-5/6
            sm:h-52 sm:w-79"
        />
      </div>

      <p class="t-text-md-emphasize mb-2 text-grey-800">{{ $t('whatsapp.give_it_a_go') }}</p>

      <div :class="`template-wrapper template-wrapper-${templateSent ? 'inactive' : 'active'}`">
        <p>
          {{ $t('whatsapp.template_example_text', { name: defaultTemplateName }) }}
        </p>

        <t-button
          data-test="send-template-button"
          size="sm"
          class="flex items-center justify-center"
          :disabled="templateSent"
          @click="sendTemplateMessage"
        >
          <t-spinner v-if="isLoading" class="mr-2" />
          {{ $t('whatsapp.send_template') }}
        </t-button>
      </div>

      <p v-if="templateSent" class="t-text-sm mt-2 flex items-center text-leaf-500">
        <check-circle-fill class="mr-1" size="18" />
        {{ getSentMessage() }}
      </p>
    </div>
  </div>
</template>
<script lang="ts">
import { CheckCircleFill } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import eventBus from '@/eventBus';
import breakpoints from '@/util/breakpoints';

import { postTemplateMessage } from '../../api';
import backgroundImage from '../../assets/background_template.png';
import phoneImage from '../../assets/phone.svg';
import { MessageTypes, Steps } from '../../constants';
import Messages from '../Messages';

type Message = {
  message: string;
  type: typeof MessageTypes;
};

type TryingTemplates = {
  messages: Message[];
  messageTypes: typeof MessageTypes;
  templateSent: boolean;
  isLoading: boolean;
  defaultTemplateName: string;
  backgroundImageSrc: string;
  phoneImageSrc: string;
};

export default defineComponent({
  name: 'TryingTemplates',
  components: { CheckCircleFill, Messages },
  props: {
    ticketId: {
      type: String,
      default: '',
    },
  },
  data(): TryingTemplates {
    return {
      messages: [],
      messageTypes: MessageTypes,
      templateSent: false,
      isLoading: false,
      defaultTemplateName: '{{name}}',
      backgroundImageSrc: backgroundImage,
      phoneImageSrc: phoneImage,
    };
  },
  computed: {
    isMobile() {
      return !breakpoints.comparisons.gt_sm;
    },
  },
  mounted() {
    this.bindAgencyChannel();
  },
  unmounted() {
    this.unbindAgencyChannel();
  },
  methods: {
    getSentMessage() {
      return this.isMobile ? this.$t('whatsapp.template_sent_mobile') : this.$t('whatsapp.template_sent');
    },
    sendTemplateMessage() {
      if (!this.ticketId) return;

      this.isLoading = true;
      const userName = this.$root.user.first_name;
      const messageToSend = this.$t('whatsapp.template_example_text', { name: userName });

      postTemplateMessage(this.ticketId, { message: messageToSend })
        .then(() => {
          this.messages.push({ message: messageToSend, type: MessageTypes.OUTGOING });
          this.templateSent = true;
        })
        .catch((err) => {
          this.templateSent = false;
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    bindAgencyChannel() {
      this.$root.agencyChannel.bind('SandboxMessage', (data) => this.onReply(data));
    },
    unbindAgencyChannel() {
      this.$root.agencyChannel.unbind('SandboxMessage');
    },
    onReply(data) {
      if (data.message.ticket_id !== this.ticketId || !this.templateSent) return;

      const messages = [...this.messages, { message: data.message.message, type: MessageTypes.INCOMING }];

      const replyData = {
        messages: messages,
        ticketId: data.message.ticket_id,
        nextStep: Steps.TRYING_TEMPLATES_SUCCESS,
      };

      eventBus.$emit('on-reply', replyData);
    },
  },
});
</script>
<style lang="scss" scoped>
@import 'src/assets/sass/typography.scss';

.template-wrapper {
  @apply rounded-lg border p-3;

  p {
    @apply t-text-md;
  }

  &-active {
    @apply border-grey-400;

    p {
      @apply text-grey-600;
    }
  }

  &-inactive {
    @apply border-grey-300 bg-grey-100;

    p {
      @apply text-grey-500;
    }
  }
}
</style>
