<template>
  <div>
    <div v-if="action === 'create' && !loading" class="box">
      <div class="box-header">
        <h2>{{ $t('facebook.settings') }}</h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('facebook.connected_account') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">
                <strong>{{ user }}</strong>
                <span class="text-muted text-xs" style="cursor: pointer" @click="connect">
                  (
                  <i class="fa fa-refresh"></i>
                  )
                </span>
              </p>
              <span class="form-text">
                {{ $t('facebook.connected_account_description') }}
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">
              {{ $t('facebook.facebook_page') }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-10">
              <select v-model="record.username" class="form-control">
                <option v-for="page in pages" :value="page.id">{{ page.name }} (#{{ page.id }})</option>
              </select>
              <span class="form-text">
                {{ $t('facebook.facebook_page_description') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="action === 'info'" class="box">
      <div class="box-header">
        <h2>{{ $t('facebook.facebook') }}</h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('facebook.facebook_page') }}</label>
          <div class="col-sm-10">
            <div class="form-control-static">
              <span>
                <a target="_blank" class="text-info" :href="'https://facebook.com/' + record.username">
                  <i class="fa fa-external-link"></i>
                  {{ record.name }} (#{{ record.username }})
                </a>
              </span>
              <span class="form-text">{{ $t('facebook.connected_facebook_page') }}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('facebook.connection') }}</label>
          <div class="col-sm-10">
            <div class="form-control-static">
              <button
                class="btn btn-default mb-4"
                type="button"
                :class="{ loader: loading }"
                style="background-color: #4267b2; color: white"
                @click="reconnect"
              >
                {{ $t('facebook.reconnect_facebook_page') }}
              </button>
              <div v-if="grantedScopes.length" class="alert alert-success">
                {{ $t('facebook.granted_permissions') }}
                <ul style="margin: 0">
                  <li v-for="grantedScope in grantedScopes">{{ grantedScope }}</li>
                </ul>
              </div>
              <div v-if="missingScopes.length || record.password_is_null" class="alert alert-danger">
                <template v-if="missingScopes.length">
                  {{ $t('facebook.missing_permissions') }}
                  <ul style="margin: 0">
                    <li v-for="missingScope in missingScopes">{{ missingScope }}</li>
                  </ul>
                  <br />
                </template>
                <strong>{{ $t('facebook.please_reconnect_the_facebook_page') }}</strong>
                <br />
                {{ $t('facebook.new_permission_instructions') }}
                <a href="https://www.facebook.com/settings?tab=business_tools" target="_blank" class="underline">
                  {{ $t('facebook.here') }}
                </a>
              </div>
              <span class="form-text">
                {{ $t('facebook.reconnect_facebook_description') }}
              </span>
            </div>
          </div>
        </div>

        <div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('facebook.private_messages') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">
                <label class="ui-switch success m-t-xs m-r" @change="checkScopes()">
                  <input v-model="record.facebookChannel.private_messages" type="checkbox" true-value="1" />
                  <i></i>
                </label>
                <span class="form-text">{{ $t('facebook.receive_facebook_messenger_conversations') }}</span>
              </p>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 form-control-label">{{ $t('facebook.feed_messages') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">
                <label class="ui-switch success m-t-xs m-r" @change="checkScopes()">
                  <input v-model="record.facebookChannel.feed_messages" type="checkbox" true-value="1" />
                  <i></i>
                </label>
                <span class="form-text">{{ $t('facebook.receive_facebook_wall_posts') }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  name: 'FacebookChannel',
  emits: ['update-loaded', 'call-init-warning-method', 'call-update-channel-method'],
  data() {
    return {
      loading: false,
      user: null,
      pages: [],
      grantedScopes: [],
      missingScopes: [],
    };
  },

  props: {
    action: {
      type: String,
      default: 'create',
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },

  mounted() {
    if (this.action === 'create') {
      if (this.$route.query.access_token) {
        this.authenticate().then(() => {
          this.checkScopes().then(() => {
            this.$emit('call-init-warning-method');
          });
        });
      } else {
        this.connect();
      }
    }

    if (this.action === 'edit') {
      if (this.$route.query.access_token) {
        this.reauthenticate().then(() => {
          this.$emit('call-init-warning-method');
        });
      }
    }

    if (this.action === 'info') {
      this.checkScopes().then(() => {
        this.$emit('call-init-warning-method');
      });
    }
  },

  methods: {
    connect() {
      this.$emit('update-loaded', false);
      this.loading = true;
      axios
        .post('/api/v2/facebook/authenticate')
        .then((res) => {
          window.location.href = res.data.url;
        })
        .catch(() => {
          this.$emit('call-init-warning-method', false);
          this.loading = false;
          this.$router.push('/admin/channels2/facebook');
        });
    },

    reconnect() {
      this.loading = true;
      axios
        .post('/api/v2/facebook/authenticate?cid=' + this.record.id)
        .then((res) => {
          this.loading = false;
          window.location.href = res.data.url;
        })
        .catch(() => {
          this.loading = false;
          this.$emit('call-init-warning-method', false);
          this.$router.push('/admin/channels2/facebook');
        });
    },

    async authenticate() {
      let params = {
        access_token: this.$route.query.access_token,
      };
      this.record.password = params['access_token'];
      this.loading = true;

      await axios
        .get('/api/v2/facebook/pages', { params: params })
        .then((res) => {
          this.pages = res.data.pages;
          this.user = res.data.user;
          this.loading = false;

          if (this.pages.length) {
            this.record.username = this.pages[0].id;
          }

          if (this.pages.length === 1 && this.pages[0].name) {
            this.record.title = this.pages[0].name;
          } else {
            this.record.title = 'Facebook';
          }
        })
        .catch(() => {
          this.loading = false;
          this.$emit('call-init-warning-method', false);
          this.$router.push('/admin/channels2/facebook');
        });

      // Set default settings
      this.record.facebookChannel.private_messages = 1;
      this.record.facebookChannel.feed_inbox = 0;
      this.record.facebookChannel.feed_messages = 1;
      this.record.facebookChannel.page_mentions = 0;
    },

    async reauthenticate() {
      if (this.loading) {
        return;
      }

      this.record.password = this.$route.query.access_token;
      this.record.password_is_null = false;
      this.$emit('call-update-channel-method');
      this.$emit('call-init-warning-method', false);
      router.push('/admin/channels2/facebook/' + this.$route.params.id);
    },

    updated() {
      this.checkScopes();
    },

    async checkScopes() {
      if (!this.record.id) {
        return;
      }

      await axios
        .get('/api/v2/facebook/pages/' + this.record.id, {
          params: {
            private_messages: this.record.facebookChannel.private_messages,
            feed_messages: this.record.facebookChannel.feed_messages,
          },
        })
        .then((res) => {
          this.grantedScopes = res.data.grantedScopes;
          this.missingScopes = res.data.missingScopes;
          this.record.password_is_null = res.data.password_is_null;
          this.$emit('call-init-warning-method', false);
        })
        .catch((error) => {
          this.record.password_is_null = true;
        });
    },
  },
  computed: {
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
