<script type="text/babel">
import _ from 'lodash';

export default {
  emits: ['update:contacts'],
  props: {
    contacts: { type: Array, default: () => [] },
  },
  data() {
    return {
      showAddContactsForm: false,
      allContacts: [],
      contact_groups: [],
      phone: null,
      savingContact: false,
      searchTerm: '',
      selectedContacts: [],
    };
  },

  components: {},

  mounted() {
    this.loadGroups();
  },

  methods: {
    loadGroups() {
      axios.get('/api/v2/contact_groups').then((res) => {
        this.contact_groups = res.data;
      });
    },

    addFromGroup(group_id) {
      $.getJSON('/client-api/sms/contacts/groups/' + group_id).then((contacts) => {
        let newContacts = [...this.contacts];
        _.each(contacts, (contact) => {
          newContacts.push(contact);
        });
        this.$emit('update:contacts', newContacts);
        this.showAddContactsForm = false;
      });
    },

    addContact() {
      this.savingContact = true;
      $.post('/client-api/sms/contacts', { phone: this.phone }, (res) => {
        if (res != null) {
          let newContacts = [...this.contacts];
          _.each(res, (contact, i) => {
            if (contact.phone != null) {
              newContacts.push(contact);
            }
          });
          this.$emit('update:contacts', newContacts);
        }

        this.phone = null;
        this.showAddContactsForm = false;
        this.savingContact = false;
      });
    },

    openContactsListModal(i) {
      this.showAddContactsForm = false;
      $('#AllContactsList').modal('show');
      $.getJSON('/client-api/sms/contacts/list?term=' + this.searchTerm).then((contacts) => {
        this.allContacts = contacts;
      });
    },

    removeContact(i) {
      let newContacts = [...this.contacts];
      newContacts.splice(i, 1);
      this.$emit('update:contacts', newContacts);
    },

    insertContacts() {
      $('#AllContactsList').modal('hide');
      let newContacts = [...this.contacts];
      _.each(this.selectedContacts, (contact) => {
        newContacts.push(contact);
      });
      this.$emit('update:contacts', newContacts);
      this.selectedContacts = [];
    },
  },
};
</script>

<template>
  <div>
    <a class="btn btn-outline b-black btn-sm text-black" @click="openContactsListModal()">
      {{ $t('sms.add_from_contacts') }}
    </a>
    <a
      class="btn btn-outline b-black btn-sm text-black"
      :class="{ active: showAddContactsForm }"
      @click="showAddContactsForm = !showAddContactsForm"
    >
      {{ $t('sms.add_from_text') }}
    </a>
    <div v-if="contact_groups.length > 0" class="btn-group dropdown">
      <a class="btn btn-outline b-black btn-sm text-black" data-toggle="dropdown">
        {{ $t('sms.add_from_group') }}
        <i class="fa fa-angle-down"></i>
      </a>
      <ul class="dropdown-menu animated">
        <li
          v-for="group in contact_groups"
          style="cursor: pointer"
          class="dropdown-item"
          @click="addFromGroup(group.id)"
        >
          <a>{{ group.name }}</a>
        </li>
      </ul>
    </div>
    <div v-if="showAddContactsForm == true" class="form-group b-a p-a-1 m-t-1">
      <div class="input-group">
        <strong class="p-b-1">{{ $t('sms.add_numbers_title') }}</strong>
        <br />
        <span class="text-muted">{{ $t('sms.add_numbers_title_help') }}</span>
        <br />
        <textarea
          v-model="phone"
          type="text"
          :placeholder="$t('sms.phone')"
          class="form-control AddContactsTextArea"
        ></textarea>
        <a
          :disabled="savingContact || phone == null"
          class="btn btn-default"
          @click="addContact"
          v-text="$t('sms.add_phone')"
        ></a>
      </div>
    </div>
    <div v-if="contacts.length > 0" class="list-group m-t-1 ContactListGroup">
      <div v-for="(contact, i) in contacts" class="list-group-item">
        <i class="fa fa-remove" style="cursor: pointer; color: grey" @click="removeContact(i)"></i>
        {{ contact.phone }}
        <span v-if="contact.name != null" class="text-muted">({{ contact.name }})</span>
      </div>
    </div>
    <div style="padding-top: 5px">
      <small class="text-muted">{{ $t('sms.contacts_length') }}: {{ contacts.length }}</small>
    </div>

    <div id="AllContactsList" class="modal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('sms.insert_contacts') }}</h5>
            <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <input
              v-model="searchTerm"
              type="text"
              class="form-control m-b-1"
              placeholder="Search.."
              @keyup="openContactsListModal()"
            />
            <ul class="list-group ContactListGroup">
              <li v-for="contact in allContacts" class="list-group-item">
                <label>
                  <input v-model="selectedContacts" type="checkbox" :value="contact" />
                  {{ contact.phone }}
                </label>
                <span v-if="contact.name != ''" class="text-muted text-right">{{ contact.name }}</span>
              </li>
            </ul>
            <br />
            <a class="btn success text-white text-white" @click="insertContacts()">
              {{ $t('sms.insert_contacts') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.ContactListGroup {
  max-height: 600px;
  overflow-y: scroll;
  display: block;
}

.AddContactsTextArea {
  height: 150px;
  margin: 10px 0;
}
</style>
