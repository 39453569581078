<template>
  <div class="custom-fields-empty py-16 text-center">
    {{ $t('custom_fields.no_custom_fields') }} "{{ capitalizedType }}"
  </div>
</template>

<script>
import { capitalize } from 'lodash';

export default {
  name: 'CustomFieldsEmpty',
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    capitalizedType() {
      return capitalize(this.type);
    },
  },
};
</script>
