<template>
  <div style="width: 100%; height: 100%">
    <composer
      v-if="ticket !== null"
      :ticket="ticket"
      :popout="true"
      :path="'/tickets/' + ticket.id"
      :draft-key="'ticket' + ticket.id"
      @sent="onSent"
    ></composer>
    <composer
      v-if="newTicket && !forwarding"
      :popout="true"
      :new-ticket="true"
      draft-key="new"
      path="/tickets/create"
      @sent="onSent"
    ></composer>
    <composer
      v-if="newTicket && forwarding"
      :popout="true"
      :autofocus="true"
      :draft-key="forwardDraftKey"
      :forward-ticket-id="forwardTicketId"
      :forward-message-id="forwardMessageId"
      :new-ticket="true"
      :forwarding="true"
      :path="'/tickets/forward/' + forwardTicketId"
      @sent="onSent"
    ></composer>
    <portal-target name="body-portal" multiple></portal-target>
  </div>
</template>

<script type="text/babel">
import Composer from './ReplyForm/Composer';
import ticketRepository from '../repositories/Ticket';
import { waitForEvent } from '../start/util';
export default {
  data() {
    return {
      ticket: null,
      newTicket: false,
      forwarding: false,
      forwardTicketId: null,
      forwardMessageId: null,
    };
  },

  computed: {
    forwardDraftKey() {
      if (!this.forwarding) {
        return null;
      }
      let draftKey = 'forward.' + this.forwardTicketId;
      if (this.forwardMessageId) {
        draftKey += '' + this.forwardMessageId;
      }
      return draftKey;
    },
  },

  async mounted() {
    if (this.$route.params.ticketId) {
      if (!this.$store.state.initialData.user.id) {
        await waitForEvent('initial-data.loaded');
      }

      axios.get('/api/v2/tickets/' + this.$route.params.ticketId).then((res) => {
        this.ticket_id = res.data.id;
        this.ticket = res.data;
      });
    } else {
      if (this.$route.query.forward) {
        this.forwardTicketId = this.$route.query.ticket_id;
        this.forwardMessageId = this.$route.query.message_id;
        this.forwarding = true;
      }
      this.newTicket = true;
    }
  },

  components: {
    Composer,
  },

  methods: {
    async onSent(ticket_id, send_and_close) {
      if (send_and_close) {
        await ticketRepository.closeTicket(ticket_id);
      }

      if (this.newTicket) {
        opener.router.push('/tickets/' + ticket_id);
      }

      setTimeout(() => {
        window.close();
      }, 200);
    },

    appendMessage() {},
  },
};
</script>
