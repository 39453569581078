<template>
  <div id="MergeTicketModal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('tickets.merge_ticket') }}</h5>
          <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div v-if="loaded" class="modal-body">
          <p>{{ $t('tickets.merge_ticket_intro') }}</p>

          <div class="form-group">
            <select v-model="form.ticket_id" class="form-control">
              <option v-for="ticket in tickets" :key="ticket.id" :value="ticket.id">
                {{ formatDate(ticket.created_at) }} - #{{ ticket.id }}

                <span v-if="ticket?.subject">- {{ ticket.subject }}</span>

                <span v-if="ticket?.channel?.display_name">
                  - {{ $t('channels.channel') }}: {{ ticket.channel.display_name }}
                </span>
              </option>
            </select>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn success rounded-full"
            :disabled="!loaded || form.ticket_id == null"
            @click="merge"
          >
            {{ $t('tickets.merge_ticket') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment';

import eventBus from '@/eventBus';

import contactRepository from '../repositories/Contact';
import ticketRepository from '../repositories/Ticket';

import type { Channel } from '@/store/pinia/channels/types';
import type Ticket from '@/types/tickets';

export default {
  data() {
    return {
      currentTicket: {} as Ticket,
      tickets: [] as Ticket[],
      form: {
        ticket_id: null,
      },
      loaded: false,
    };
  },

  mounted() {
    eventBus.$off('modals.merge-ticket.open');
    eventBus.$on('modals.merge-ticket.open', (ticket: Ticket) => {
      this.loaded = false;
      this.tickets = [];
      this.currentTicket = ticket;
      this.form.ticket_id = null;
      this.fetchTickets(ticket);
    });
  },

  methods: {
    formatDate(date: string) {
      return moment(date).format('DD-MM-YYYY, HH:mm');
    },

    fetchTickets(ticket: Ticket) {
      const channelIdsWithSameChannelType = this.$root.channels
        .filter((channel: Channel) => channel.type === ticket.channel?.type)
        .map((channel: Channel) => channel.id);

      contactRepository.fetchTickets(ticket['contact_id'], channelIdsWithSameChannelType).then((result) => {
        this.loaded = true;

        this.tickets = result.data.filter(
          (t: Ticket) => t.channel?.type === ticket.channel?.type && t.id !== ticket.id,
        );

        if (this.tickets.length === 0) {
          return;
        }

        this.form.ticket_id = this.tickets[0].id;
      });

      $('#MergeTicketModal').modal();
    },

    merge() {
      if (!this.form.ticket_id) {
        return;
      }

      ticketRepository.mergeTicket(this.currentTicket.id, this.form.ticket_id).then(() => {
        $('#MergeTicketModal').modal('hide');

        eventBus.$emit('ticket.current.reload', {
          focus: true,
          withMessages: true,
        });
      });
    },
  },
};
</script>
