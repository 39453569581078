<template>
  <div>
    <div class="rounded-xl px-2 py-1" style="background-color: rgb(247, 247, 247); overflow: hidden">
      <div class="my-1 text-grey-600">
        <i class="text-md material-icons mx-2 mb-3" style="position: relative; top: 4px">flash_on</i>
        {{ $t('flowbot.step_presenter_dynamic_dynamic') }}
        <span class="font-bold">{{ element.meta.method }}</span>
        <!--                <span v-if="element.meta.url" class="whitespace-nowrap success rounded mx-1 py-1 px-2 mt-2 mb-3">{{ element.meta.url }}</span>-->
        <div v-if="element.meta.url" class="mx-3 break-words rounded" v-html="formatText(element.meta.url)"></div>
        <div class="m-3">
          <div v-if="showHeaders" class="mb-2 mt-1">
            <div class="font-bold">{{ $t('flowbot.step_presenter_dynamic_headers') }}</div>
            <div v-for="header in filteredElementMetaHeaders" :key="header.key" class="text-ellipsis">
              <span v-html="formatText(header.key)"></span>
              :
              <span v-html="formatText(header.value)"></span>
            </div>
          </div>
          <div class="my-1">
            <div class="font-bold">{{ $t('flowbot.step_presenter_dynamic_body') }}</div>
            <div class="text-ellipsis">
              <span>ticket_id</span>
              :
              <span v-html="formatText('{ ticket_id }')"></span>
            </div>
            <div class="text-ellipsis">
              <span>contact_id</span>
              :
              <span v-html="formatText('{ contact_id }')"></span>
            </div>
            <div class="text-ellipsis">
              <span>channel_id</span>
              :
              <span v-html="formatText('{ channel_id }')"></span>
            </div>
            <template v-if="showBody">
              <div v-for="body in filteredElementMetaBody" :key="body.key || body.value" class="text-ellipsis">
                <span v-html="formatText(body.key)"></span>
                :
                <span v-html="formatText(body.value)"></span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import textFormatter from '../mixins/TextFormatter';

export default {
  props: ['element'],

  mixins: [textFormatter],

  computed: {
    filteredElementMetaBody() {
      return this.element.meta.body.filter((body) => body.key || body.value);
    },
    filteredElementMetaHeaders() {
      return this.element.meta.headers.filter((header) => header.key && header.value);
    },
    showHeaders() {
      return this.element.meta.headers.length && this.element.meta.headers[0].key && this.element.meta.headers[0].value;
    },
    showBody() {
      return this.element.meta.body.length && this.element.meta.body[0].key && this.element.meta.body[0].value;
    },
  },
};
</script>
