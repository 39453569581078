<template>
  <div id="accordion" class="row-col p-4">
    <edit-page-header
      v-if="isFeatureFlagEnabled"
      :show-playground="showPlayground"
      :is-active="!!getSafe(getFlowId, 'is_active')"
      :save="saveFlowbot"
      :remove="removeFlowbot"
      :loaded="loaded"
      :async-update="asyncUpdate"
      :flow-name="record.title"
      :is-saving="isSavingFlowbot"
    />
    <div v-else class="mb-1 flex">
      <div class="flex-grow">
        <button
          v-tooltip.top="{
            placement: 'top',
            content: $t('flowbot.button_hover_toggle_full_screen'),
            delay: { show: 500, hide: 0 },
          }"
          onclick="this.blur()"
          class="btn btn-md rounded-lg-i b-black text-muted valign-center hidden-md-down ml-1"
          @click="toggleFullScreen()"
        >
          <i class="material-icons mr-2">fullscreen</i>
          - {{ $t('flowbot.button_full_screen') }}
        </button>
      </div>
      <div class="ml-auto">
        <button
          v-tooltip.top="{
            placement: 'bottom',
            content: tooltipContent,
            delay: { show: 500, hide: 0 },
          }"
          onclick="this.blur()"
          class="btn rounded-lg-i b-black text-muted mx-2 mb-4"
          :class="{
            disabled: !getSafe(getFlowId, 'is_active'),
          }"
          @click="showPlayground()"
        >
          <i class="material-icons md-18 mr-2" style="vertical-align: -3px">play_arrow</i>
          {{ $t('flowbot.button_playground') }}
        </button>
        <button onclick="this.blur()" class="btn rounded-lg-i b-black text-muted mx-2 mb-4" @click="saveFlowbot(true)">
          <i class="material-icons md-18 mr-2" style="vertical-align: -3px">filter_none</i>
          {{ $t('flowbot.button_copy') }}
        </button>
        <button onclick="this.blur()" class="btn rounded-lg-i b-black text-muted mx-2 mb-4" @click="removeFlowbot()">
          <i class="material-icons md-18 mr-2" style="vertical-align: -3px">delete</i>
          {{ $t('flowbot.button_delete') }}
        </button>
        <button
          onclick="this.blur()"
          :class="{ loader: !loaded || asyncUpdate }"
          class="btn rounded-lg-i success mb-4 ml-2"
          @click="saveFlowbot()"
        >
          <i class="material-icons md-18 mr-2" style="vertical-align: -3px">save</i>
          {{ $t('flowbot.button_save') }}
        </button>
      </div>
    </div>
    <upgrade-flowbot-banner
      v-if="shouldShowUpgradeUtility"
      :entitlement-status="entitlementStatus"
      :feature-name="featureName"
    />
    <configuration-card
      card-title="design-your-flowbot"
      card-order="1"
      :collapsed="true"
      aria-expanded="true"
      data-parent="#accordion"
      @clicked="checkSectionsDone()"
    >
      <template #card-title>
        <span>
          <span v-if="sectionsDone[0] && sectionsTouched[0]" class="step done">
            <i class="material-icons md-18" style="padding-top: 0.15rem">check</i>
          </span>
          <span v-else-if="!sectionsDone[0] && sectionsTouched[0]" class="step warning">1</span>
          <span v-else class="step">1</span>
          {{ $t('flowbot.setting_card_title_design_your_flowbot') }}
        </span>
      </template>
      <template #card-content>
        <div>
          <div v-if="loaded" class="p-4">
            <div class="p-a flex bg-white">
              <div style="max-width: 300px" class="border-r">
                <div class="pr-4">
                  <draggable
                    v-model="record.steps"
                    handle=".handle"
                    group="steps"
                    :group="{ name: 'steps', put: false }"
                    item-key="id"
                  >
                    <div
                      v-for="(step, index) in record.steps"
                      :key="step.id"
                      class="border-grey mb-3 cursor-pointer rounded-lg border p-2 pb-2"
                      :class="{ 'active-step': editStep === step.id }"
                      @click="
                        () => {
                          editStep = step.id;
                          checkSectionsDone();
                          sectionsTouched[0] = true;
                        }
                      "
                    >
                      <div class="flex">
                        <span style="cursor: move !important" class="handle">
                          <i class="material-icons">drag_handle</i>
                        </span>
                        <span class="mr-1 text-ellipsis font-bold">
                          <span class="text-success">{{ alphabet[index % alphabet.length] }}:</span>
                          {{ step.title }}
                        </span>
                        <span
                          v-if="step.actions.length && showActionEndingWarning(step)"
                          class="
                            ml-auto
                            whitespace-nowrap
                            rounded-lg
                            border border-orange
                            pl-1
                            pr-1
                            text-xs text-orange
                          "
                          style="height: 18px"
                        >
                          {{ step.actions.length }}/
                          {{
                            $tc('flowbot.button_step_label_amount_actions', step.actions.length, {
                              amount: step.actions.length,
                            })
                          }}
                        </span>
                        <span
                          v-else-if="step.actions.length"
                          class="ml-auto whitespace-nowrap rounded-lg border border-green pl-1 pr-1 text-xs text-green"
                          style="height: 18px"
                        >
                          {{
                            $tc('flowbot.button_step_label_amount_actions', step.actions.length, {
                              amount: step.actions.length,
                            })
                          }}
                        </span>
                        <span
                          v-else-if="step.buttons.length"
                          class="ml-auto whitespace-nowrap rounded-lg border border-green pl-1 pr-1 text-xs text-green"
                          style="height: 18px"
                        >
                          {{
                            $tc('flowbot.button_button_step_label_amount_buttons', step.buttons.length, {
                              amount: step.buttons.length,
                            })
                          }}
                        </span>
                        <span
                          v-else
                          class="
                            ml-auto
                            whitespace-nowrap
                            rounded-lg
                            border border-orange
                            pl-1
                            pr-1
                            text-xs text-orange
                          "
                          style="height: 18px"
                        >
                          {{ $t('flowbot.button_0_actions_or_buttons') }}
                        </span>
                      </div>
                      <div
                        class="text-ellipsis text-grey-600"
                        style="width: 215px"
                        v-html="
                          formatText(
                            step.contents
                              .filter((contents) => {
                                return ['text', 'image', 'video', 'file', 'user_input', 'location_finder'].includes(
                                  contents.type
                                );
                              })
                              .map((contents) => {
                                return contents.meta.message;
                              })
                              .join(' '),
                            true
                          )
                        "
                      ></div>
                    </div>
                  </draggable>
                  <div
                    class="cursor-pointer rounded-lg border border-green pb-2 pt-2 text-center text-green"
                    @click="addStep() || checkSectionsDone()"
                  >
                    {{ $t('flowbot.button_plus_add_step') }}
                  </div>
                </div>
              </div>
              <div style="overflow-x: scroll; width: 100%" class="pl-16">
                <div v-for="(step, index) in filteredRecordSteps" :key="step.id">
                  <div class="mb-6 flex">
                    <div class="text-md form-inline valign-center mr-1 font-bold">
                      <span class="text-success whitespace-nowrap">
                        {{ $t('flowbot.step_title') }}
                        {{ alphabet[indexOfStep % alphabet.length] }}:
                      </span>
                      <input v-model="step.title" type="text" class="form-control rounded-lg-i ml-2" />
                      <!-- {{ step.title }}-->
                    </div>
                    <div class="ml-auto text-right">
                      <button
                        onclick="this.blur()"
                        class="
                          btn btn-sm
                          rounded-lg-i
                          btn-outline-secondary
                          valign-center
                          b-black
                          text-muted
                          mt-1
                          text-grey-600
                        "
                        @click="duplicateStep(step.id)"
                      >
                        <i class="text-md material-icons mr-1">filter_none</i>
                        <span class="p-1">{{ $t('flowbot.button_copy_step') }}</span>
                      </button>
                      <button
                        onclick="this.blur()"
                        class="
                          btn btn-sm
                          rounded-lg-i
                          btn-outline-secondary
                          valign-center
                          b-black
                          text-muted
                          ml-2
                          mt-1
                          text-grey-600
                        "
                        @click="deleteStep(step.id)"
                      >
                        <i class="text-md material-icons mr-1">delete</i>
                        <span class="p-1">{{ $t('flowbot.button_delete_step') }}</span>
                      </button>
                    </div>
                  </div>
                  <div class="mb-6 flex items-start" style="min-width: 650px">
                    <div
                      style="
                        position: relative;
                        width: 400px;
                        border-radius: 20px;
                        box-shadow: rgb(210, 210, 210) 0 0 25px;
                      "
                    >
                      <div class="pos-relative mb-4 overflow-hidden" style="border-radius: 20px">
                        <div style="height: 100px">
                          <div class="bg-green-ball bg-green"></div>
                          <div class="m-4">
                            <div
                              style="
                                background-color: rgb(19 146 131);
                                border-radius: 10px;
                                width: 220px;
                                height: 18px;
                                position: relative;
                                z-index: 2;
                              "
                              class="mb-3"
                            ></div>
                            <div
                              style="
                                background-color: rgb(19 146 131);
                                border-radius: 10px;
                                width: 140px;
                                height: 18px;
                                position: relative;
                                z-index: 2;
                              "
                            ></div>
                          </div>
                        </div>
                        <div
                          class="pb-2 pt-2 text-center text-grey-600"
                          style="border-bottom: 2px dashed #dfe0e2"
                        ></div>
                        <div
                          style="
                            display: block;
                            text-align: center;
                            margin-top: -13px;
                            background: white;
                            width: 150px;
                            margin-left: 125px;
                            font-size: 14px;
                          "
                          class="text-grey-600"
                        >
                          {{ $t('flowbot.step_start_this_step_with') }}
                        </div>
                      </div>
                      <div class="pl-6 pr-4" style="line-height: 1.4rem !important">
                        <!-- Contents -->
                        <draggable
                          v-model="step.contents"
                          group="messages"
                          handle=".drag-handle"
                          style="width: 620px"
                          @change="changed"
                        >
                          <div v-for="element in step.contents" :key="step.id" class="step-content flex flex-col">
                            <div class="mb-4 flex" style="width: auto; position: relative">
                              <div style="width: 353px">
                                <div class="flex-1 overflow-hidden rounded-xl">
                                  <div v-if="element.meta" class="p-3">
                                    <component
                                      :is="getComponentType(element).component + 'Presenter'"
                                      :element="element"
                                      :location-finder-action-is-applied="locationFinderActionIsApplied ? 'true' : null"
                                    ></component>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="step-content-actions text-muted mt-3 text-lg"
                                style="width: 250px; padding-left: 53px"
                              >
                                <button
                                  v-tooltip.top="{
                                    placement: 'top',
                                    content: $t('flowbot.edit_hover'),
                                    delay: { show: 500, hide: 0 },
                                  }"
                                  onclick="this.blur()"
                                  class="btn btn-sm btn-outline-secondary rounded-lg-i h-10 w-10"
                                  @click="editContent = element.id"
                                >
                                  <i class="material-icons text-md text-grey-600">create</i>
                                </button>
                                <button
                                  v-tooltip.top="{
                                    placement: 'top',
                                    content: $t('flowbot.move_up_down_hover'),
                                    delay: { show: 500, hide: 0 },
                                  }"
                                  onclick="this.blur()"
                                  class="drag-handle btn btn-sm btn-outline-secondary rounded-lg-i h-10 w-10"
                                  style="cursor: move !important"
                                >
                                  <i class="material-icons text-md text-grey-600">open_with</i>
                                </button>
                                <button
                                  v-tooltip.top="{
                                    placement: 'top',
                                    content: $t('flowbot.copy_hover'),
                                    delay: { show: 500, hide: 0 },
                                  }"
                                  onclick="this.blur()"
                                  class="btn btn-sm btn-outline-secondary rounded-lg-i h-10 w-10"
                                  @click="duplicateContent(element)"
                                >
                                  <i class="material-icons text-md text-grey-600">filter_none</i>
                                </button>
                                <button
                                  v-tooltip.top="{
                                    placement: 'top',
                                    content: $t('flowbot.delete_hover'),
                                    delay: { show: 500, hide: 0 },
                                  }"
                                  onclick="this.blur()"
                                  class="btn btn-sm btn-outline-secondary rounded-lg-i h-10 w-10"
                                  @click="remove(element)"
                                >
                                  <i class="material-icons text-md text-grey-600">delete</i>
                                </button>
                              </div>
                              <!-- Edit content -->
                              <div
                                v-if="element.id === editContent"
                                class="ml-32 mt-3 rounded-lg border border-green p-6 pt-4"
                                :style="{ width: getComponentType(element).width }"
                                style="
                                  position: absolute;
                                  margin-left: 405px;
                                  top: 0;
                                  left: 0;
                                  z-index: 999;
                                  background-color: white;
                                "
                              >
                                <div class="flex">
                                  <span class="valign-center font-bold text-green">
                                    <i class="material-icons mr-4">{{ getComponentType(element).icon }}</i>
                                    <span class="mb-1">
                                      {{
                                        $t('flowbot.add_content_edit_content_type_title', {
                                          contentType: getComponentType(element).title.toLowerCase(),
                                        })
                                      }}
                                    </span>
                                  </span>
                                  <span class="text-md pointer ml-auto text-grey-500" @click="editContent = null">
                                    <i class="material-icons">remove</i>
                                  </span>
                                </div>
                                <component
                                  :is="getComponentType(element).component + 'Editor'"
                                  :element="element"
                                  :type="element.type"
                                  :custom-fields="customFields"
                                  :flowbot-title="record.title"
                                ></component>
                                <button
                                  class="btn btn-md rounded-lg-i btn-outline-success valign-center text-success m-t-1"
                                  @click="editContent = null"
                                >
                                  <i class="material-icons mr-2">done</i>
                                  {{ $t('flowbot.add_content_done_button') }}
                                </button>
                                <button
                                  v-tooltip.top="{
                                    placement: 'top',
                                    content: $t('flowbot.add_content_delete_button_hover'),
                                    delay: { show: 500, hide: 0 },
                                  }"
                                  onclick="this.blur()"
                                  class="btn btn-sm btn-outline-secondary rounded-lg-i m-t-1 pull-right h-10 w-10"
                                  @click="remove(element)"
                                >
                                  <i class="material-icons text-md text-grey-600">delete_outline</i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </draggable>

                        <div
                          v-if="step.contents.length < 1"
                          :class="[sectionsTouched[0] && !step.contents.length ? 'text-background' : 'text-background']"
                          class="my-6 text-center text-grey-600"
                        >
                          {{ $t('flowbot.step_choose_content_to_add') }}
                        </div>

                        <!-- Add content -->
                        <div class="flex flex-col">
                          <div class="mb-4 flex" style="position: relative">
                            <div style="width: 365px">
                              <div
                                class="pointer flex-1 overflow-hidden rounded text-center font-bold text-green"
                                @click="onAddElement()"
                              >
                                <span class="valign-center">
                                  <i class="material-icons mr-2">rate_review</i>
                                  <span class="mb-1">{{ $t('flowbot.step_add_content') }}</span>
                                </span>
                                <!-- chat_bubble -->
                              </div>
                            </div>
                            <div
                              v-if="showAdd()"
                              style="
                                width: 335px;
                                position: absolute;
                                margin-left: 405px;
                                top: 0;
                                z-index: 999;
                                background-color: white;
                              "
                              class="ml-32 rounded-lg border border-green p-6"
                            >
                              <div>
                                <div class="text-success valign-center mb-3 ml-1 w-full font-bold">
                                  <i class="material-icons mr-2">rate_review</i>
                                  <span class="mb-1">{{ $t('flowbot.step_choose_content') }}</span>
                                  <div class="pointer ml-auto" @click="hideAdd()">
                                    <i class="material-icons text-grey-500">remove</i>
                                  </div>
                                </div>
                                <div class="flex flex-wrap">
                                  <div
                                    v-for="type in types"
                                    :key="type.id"
                                    class="
                                      pointer
                                      valign-center
                                      mx-1
                                      my-1
                                      flex-shrink-0
                                      rounded-lg
                                      border border-grey-400 border-grey-800
                                      p-2
                                    "
                                    @click="add(type)"
                                  >
                                    <i class="material-icons text-success mr-1">{{ type.icon }}</i>
                                    {{ type.title }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- End of step buttons or actions header -->
                      <div class="mb-4 text-grey-600">
                        <div class="pb-2 pt-2 text-center" style="border-bottom: 2px dashed #dfe0e2"></div>
                        <div
                          style="
                            display: block;
                            text-align: center;
                            margin-top: -13px;
                            background: white;
                            width: 150px;
                            margin-left: 125px;
                            font-size: 14px;
                          "
                        >
                          {{ $t('flowbot.step_info_text_end_this_step_with') }}
                        </div>
                      </div>

                      <div class="flex" style="border-bottom: 2px dashed #dfe0e2; padding-bottom: 28px">
                        <div
                          class="pointer flex flex-1 flex-col text-center"
                          style="border-right: 2px dashed #dfe0e2"
                          @click="addButton(step, true)"
                        >
                          <span class="text-lg text-green">
                            <i style="font-size: 40px" class="material-icons">rotate_right</i>
                          </span>
                          <!-- rotate_right / call_split/ device_hub -->
                          <span>
                            <span class="font-bold text-green">{{ $t('flowbot.step_info_text_buttons_1') }}</span>
                            {{ $t('flowbot.step_info_text_buttons_2') }}
                          </span>
                        </div>
                        <div class="pointer flex flex-1 flex-col text-center" @click="addAction(step, true)">
                          <span class="text-lg text-green">
                            <i style="font-size: 40px" class="material-icons">widgets</i>
                          </span>
                          <!-- widgets / play_for_work / where_to_vote / navigation / location_on / vertical_align_bottom / save_alt -->
                          <span>
                            <span class="font-bold text-green">{{ $t('flowbot.step_actions') }}</span>
                          </span>
                        </div>
                      </div>

                      <div v-if="step.buttons.length < 1 && step.actions.length < 1" class="py-16 text-center">
                        <div
                          class="
                            m-t-1
                            font-italic
                            valign-center
                            rounded
                            border border-orange
                            p-2
                            px-2
                            pr-4
                            pt-2
                            text-grey-600 text-orange
                          "
                        >
                          <i class="material-icons md-18 mx-2">error</i>
                          {{ $t('flowbot.step_end_this_step_with_buttons_or_actions') }}
                        </div>
                      </div>

                      <!-- Buttons -->
                      <template v-if="step.buttons.length">
                        <div class="row row-flowbot">
                          <div class="col-sm-1 col-flowbot"></div>
                          <div class="col-sm-1 col-flowbot-right">
                            <div class="col-sm-4 font-bold" style="padding-left: 0">
                              {{ $t('flowbot.button_text') }}
                            </div>
                            <div class="col-sm-5 font-bold">{{ $t('flowbot.step_select_step') }}</div>
                          </div>
                        </div>

                        <draggable v-model="step.buttons" handle=".button-drag-handle">
                          <div
                            v-for="(button, btnIndex) in step.buttons"
                            :key="btnIndex"
                            class="step-button row row-flowbot"
                          >
                            <div class="col-sm-1 col-flowbot">
                              <div class="pl-6 text-right text-sm">
                                <div
                                  class="
                                    inline
                                    cursor-pointer
                                    break-words
                                    border border-green
                                    pb-2
                                    pl-4
                                    pr-4
                                    pt-2
                                    text-green
                                  "
                                  style="border-radius: 10px; font-size: 1rem"
                                  @click="goToStepFromButton(button)"
                                >
                                  {{ button.title }}
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-1 col-flowbot-right">
                              <div class="row">
                                <div class="col-sm-4 font-bold">
                                  <input v-model="button.title" class="form-control rounded-lg-i" type="text" />
                                </div>
                                <div class="col-sm-5 font-bold">
                                  <select
                                    v-model="button.next_step_id"
                                    class="form-control rounded-lg-i"
                                    name="select"
                                    @change="toNewStep"
                                  >
                                    <option :value="null" selected disabled hidden>
                                      {{ $t('flowbot.step_select_a_step') }}
                                    </option>
                                    <option v-for="(step, index) in record.steps" :key="step.id" :value="step.id">
                                      {{ alphabet[index % alphabet.length] }}: {{ step.title }}
                                    </option>
                                    <option value="toNewStep">
                                      {{ alphabet[record.steps.length % alphabet.length] }}:
                                      {{ $t('flowbot.step_create_new_step') }}
                                      {{ button.title || $t('flowbot.new_step') }}
                                    </option>
                                  </select>
                                </div>
                                <div class="col-sm-3 step-button-actions">
                                  <button
                                    v-tooltip.top="{
                                      placement: 'top',
                                      content: $t('flowbot.move_up_down_hover'),
                                      delay: { show: 500, hide: 0 },
                                    }"
                                    class="button-drag-handle btn btn-sm btn-outline-secondary rounded-lg-i h-10 w-10"
                                    style="margin-right: 1px; cursor: move !important; border-color: #dae1e7"
                                  >
                                    <i class="material-icons text-md">open_with</i>
                                  </button>
                                  <button
                                    v-tooltip.top="{
                                      placement: 'top',
                                      content: $t('flowbot.remove_hover'),
                                      delay: { show: 500, hide: 0 },
                                    }"
                                    onclick="this.blur()"
                                    class="btn btn-sm btn-outline-secondary rounded-lg-i h-10 w-10"
                                    style="border-color: #dae1e7"
                                    @click="step.buttons.splice(btnIndex, 1)"
                                  >
                                    <i class="material-icons text-md">remove</i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </draggable>
                        <div class="row row-flowbot">
                          <div class="col-sm-1 col-flowbot"></div>
                          <div class="col-sm-1 col-flowbot-right">
                            <div>
                              <a
                                class="btn btn-md rounded-lg-i btn-outline-success valign-center text-success"
                                @click="addButton(step)"
                              >
                                <i class="material-icons">add</i>
                                {{ $t('flowbot.step_add_button') }}
                              </a>
                            </div>
                          </div>
                        </div>
                      </template>

                      <!-- Actions -->
                      <div v-if="step.actions.length" class="mb-4">
                        <div class="row row-flowbot">
                          <div class="col-sm-1 col-flowbot"></div>
                          <div class="col-sm-1 col-flowbot-right">
                            <div class="col-sm-5 font-bold" style="padding-left: 0">
                              {{ $t('flowbot.actions_select_action') }}
                            </div>
                            <div class="col-sm-5 font-bold">{{ $t('flowbot.actions_value') }}</div>
                          </div>

                          <div v-for="(action, index) in step.actions" :key="index" class="step-action valign-center">
                            <div class="col-sm-1 col-flowbot">
                              <div class="text-muted font-italic break-words text-right text-sm">
                                <template v-if="action.action === 'mark_as_new_ticket'">
                                  {{ $t('flowbot.step_action_title_mark_as_new_ticket') }}
                                </template>
                                <template v-if="action.action === 'assign_to_location_finder_team'">
                                  {{ $t('flowbot.actions_assign_to_location_finder_found_team') }}
                                </template>
                                <template v-if="action.action === 'assign_to_team'">
                                  {{ $t('flowbot.actions_assign_to_team') }}
                                  {{
                                    getSafe(
                                      $root.teams.find((team) => team.id === action.meta),
                                      'name'
                                    )
                                  }}
                                </template>
                                <template v-if="action.action === 'attach_label'">
                                  {{ $t('flowbot.actions_attach_label') }}
                                  {{
                                    getSafe(
                                      $root.labels.find((label) => label.id === action.meta),
                                      'name'
                                    )
                                  }}
                                </template>
                                <template v-if="action.action === 'add_to_contact_group'">
                                  {{ $t('flowbot.actions_add_to_contact_group') }}
                                  {{
                                    getSafe(
                                      $root.contactGroups.find((contact_group) => contact_group.id === action.meta),
                                      'name'
                                    )
                                  }}
                                </template>
                                <template v-if="action.action === 'forward_ticket'">
                                  {{ $t('flowbot.actions_forward_ticket_to_e_mail_address', { email: action.meta }) }}
                                </template>
                                <template v-if="action.action === 'go_to_step'">
                                  {{ $t('flowbot.actions_go_to_step') }}
                                  <template v-if="action.meta">
                                    {{
                                      alphabet[
                                        record.steps.findIndex((step) => {
                                          return step.id === action.meta;
                                        }) % alphabet.length
                                      ]
                                    }}:
                                    <template
                                      v-if="
                                        record.steps.find((step) => {
                                          return step.id === action.meta;
                                        })
                                      "
                                    >
                                      {{
                                        record.steps.find((step) => {
                                          return step.id === action.meta;
                                        }).title
                                      }}
                                    </template>
                                  </template>
                                </template>
                                <template v-if="action.action === 'close_ticket'">
                                  {{ $t('flowbot.actions_close_ticket') }}
                                </template>
                              </div>
                            </div>
                            <div class="col-sm-1 col-flowbot-right">
                              <div class="row">
                                <div class="col-sm-5 font-bold">
                                  <select
                                    v-model="action.action"
                                    class="form-control rounded-lg-i"
                                    @change="
                                      () => {
                                        action.meta = null;
                                      }
                                    "
                                  >
                                    <option selected disabled hidden :value="null">
                                      {{ $t('flowbot.actions_select_action') }}
                                    </option>
                                    <optgroup label="Regular actions">
                                      <option
                                        v-for="(action_type, index) in regular_action_types"
                                        :key="index"
                                        :value="action_type.value"
                                      >
                                        {{ action_type.title }}
                                      </option>
                                    </optgroup>
                                    <optgroup label="Ending actions">
                                      <template v-for="(action_type, i) in ending_action_types">
                                        <option
                                          v-if="
                                            action_type.value !== 'assign_to_location_finder_team' ||
                                            flowHasALocationFinder
                                          "
                                          :key="i"
                                          :value="action_type.value"
                                          :disabled="isDisabledAction(index, action_type.value)"
                                        >
                                          {{ action_type.title }}
                                        </option>
                                      </template>
                                    </optgroup>
                                  </select>
                                </div>
                                <div class="col-sm-5 font-bold">
                                  <template v-if="action.action === 'assign_to_team'">
                                    <select
                                      id="assign_to_team"
                                      v-model="action.meta"
                                      class="form-control rounded-lg-i"
                                      name="assign_to_team"
                                    >
                                      <option selected disabled hidden :value="null">
                                        {{ $t('flowbot.actions_select_team') }}
                                      </option>
                                      <option v-for="team in $root.teams" :key="team.id" :value="team.id">
                                        {{ team.name }}
                                      </option>
                                    </select>
                                  </template>
                                  <template v-if="action.action === 'attach_label'">
                                    <select
                                      id="attach_label"
                                      v-model="action.meta"
                                      class="form-control rounded-lg-i"
                                      name="attach_label"
                                    >
                                      <option selected disabled hidden :value="null">
                                        {{ $t('flowbot.actions_select_label') }}
                                      </option>
                                      <option v-for="label in $root.labels" :key="label.id" :value="label.id">
                                        {{ label.name }}
                                      </option>
                                    </select>
                                  </template>
                                  <template v-if="action.action === 'add_to_contact_group'">
                                    <select
                                      id="add_to_contact_group"
                                      v-model="action.meta"
                                      class="form-control rounded-lg-i"
                                      name="add_to_contact_group"
                                    >
                                      <option selected disabled hidden :value="null">
                                        {{ $t('flowbot.actions_select_contact_group') }}
                                      </option>
                                      <option
                                        v-for="contact_group in $root.contactGroups"
                                        :key="contact_group.id"
                                        :value="contact_group.id"
                                      >
                                        {{ contact_group.name }}
                                      </option>
                                    </select>
                                  </template>
                                  <template v-if="action.action === 'go_to_step'">
                                    <select
                                      id="go_to_step"
                                      v-model="action.meta"
                                      class="form-control rounded-lg-i"
                                      name="add_to_contact_group"
                                      @change="toNewStep"
                                    >
                                      <option selected disabled hidden :value="null">
                                        {{ $t('flowbot.actions_select_step') }}
                                      </option>
                                      <option v-for="(step, index) in record.steps" :key="step.id" :value="step.id">
                                        {{ alphabet[index % alphabet.length] }}: {{ step.title }}
                                      </option>
                                      <option value="toNewStep">
                                        {{ alphabet[record.steps.length % alphabet.length] }}:
                                        {{ $t('flowbot.actions_create_new_step') }}
                                      </option>
                                    </select>
                                  </template>
                                  <template v-if="action.action === 'forward_ticket'">
                                    <input
                                      v-model="action.meta"
                                      class="form-control rounded-lg-i"
                                      style="height: 2.5rem; width: 215px"
                                      name="forward_ticket"
                                    />
                                  </template>
                                </div>
                                <div class="step-action-actions col-sm-2">
                                  <button
                                    v-tooltip.top="{
                                      placement: 'top',
                                      content: $t('flowbot.remove_hover'),
                                      delay: { show: 500, hide: 0 },
                                    }"
                                    onclick="this.blur()"
                                    class="btn btn-sm btn-outline-secondary rounded-lg-i valign-center h-10"
                                    style="border-color: #dae1e7"
                                    @click="step.actions.splice(index, 1)"
                                  >
                                    <i class="material-icons text-md">remove</i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-1 col-flowbot">
                            <div v-if="showActionEndingWarning(step)" class="text-center" style="margin-top: 14px">
                              <div
                                class="
                                  font-italic
                                  valign-center
                                  rounded
                                  border border-orange
                                  p-2
                                  px-2
                                  pr-4
                                  pt-2
                                  text-grey-600 text-orange
                                "
                              >
                                <i class="material-icons md-18 mx-2">error</i>
                                {{ $t('flowbot.actions_actions_should_stop_or_continue_flow') }}
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-1 col-flowbot-right mt-3">
                            <a
                              class="btn btn-md rounded-lg-i btn-outline-success valign-center text-success"
                              @click="addAction(step)"
                            >
                              <i class="material-icons">add</i>
                              {{ $t('flowbot.actions_add_action') }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="btn rounded-lg-i btn-outline-success valign-center text-success ml-2"
              type="button"
              data-parent="#accordion"
              aria-expanded="false"
              aria-controls="#configure-your-flowbot_section"
              data-toggle="collapse"
              data-target="#configure-your-flowbot_section"
              @click="checkSectionsDone((touchedSection = 0))"
            >
              {{ $t('flowbot.ready_configure_your_flowbot') }}
            </button>
          </div>
        </div>
      </template>
    </configuration-card>

    <configuration-card
      card-title="configure-your-flowbot"
      card-order="2"
      :collapsed="false"
      aria-expanded="false"
      data-parent="#accordion"
      @clicked="checkSectionsDone()"
    >
      <template #card-title>
        <span>
          <span v-if="sectionsDone[1] && sectionsTouched[1]" class="step done">
            <i class="material-icons md-18" style="padding-top: 0.15rem">check</i>
          </span>
          <span v-else-if="!sectionsDone[1] && sectionsTouched[1]" class="step warning">2</span>
          <span v-else class="step">2</span>
          {{ $t('flowbot.setting_card_title_configure_your_flowbot') }}
        </span>
      </template>
      <template #card-content>
        <div>
          <div class="px-8 py-4">
            <div class="form-group row border-b pb-4">
              <label class="col-xl-2 form-control-label valign-center">
                <i class="material-icons mr-4">folder_open</i>
                {{ $t('flowbot.setting_internal_title') }}
              </label>
              <div class="col-xl-5">
                <input
                  v-model="record.title"
                  type="text"
                  :class="{ 'outline-warning': sectionsTouched[1] && !record.title }"
                  class="form-control"
                  required
                />
              </div>
              <div class="col-xl-5 form-text">
                <span
                  v-if="
                    flowHasALocationFinder &&
                    flowsProp.find((flow) => {
                      return flow.id === record.id;
                    }) &&
                    flowsProp.find((flow) => {
                      return flow.id === record.id;
                    }).title !== record.title
                  "
                  class="text-orange"
                >
                  {{ $t('flowbot.setting_info_text_changing_title_with_location_finder_will_reset_found_locations') }}
                </span>
                <span v-else>{{ $t('flowbot.setting_the_internal_name_for_this_flow') }}</span>
              </div>
            </div>
            <div class="form-group row border-b pb-4">
              <label class="col-xl-2 form-control-label valign-center">
                <i class="material-icons mr-4">image_aspect_ratio</i>
                {{ $t('flowbot.channels') }}
              </label>
              <div class="col-xl-5">
                <multiselect
                  v-model="record.channels"
                  label="text"
                  track-by="id"
                  :options="availableChannels"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :multiple="true"
                  :placeholder="$t('flowbot.select_one_or_more_channels')"
                ></multiselect>
              </div>
              <div class="col-xl-5">
                <span class="form-text">{{ $t('flowbot.setting_the_channels_on_which_this_flow_is_active') }}</span>
              </div>
            </div>
            <div class="form-group row border-b pb-4">
              <label class="col-xl-2 form-control-label valign-center">
                <i class="material-icons mr-4">chat_bubble_outline</i>
                {{ $t('flowbot.setting_web_chat_start_message') }}
              </label>
              <div class="col-xl-5">
                <input
                  v-model="record.web_chat_start_message"
                  :class="{ 'outline-warning': sectionsTouched[1] && !record.web_chat_start_message }"
                  type="text"
                  :placeholder="$t('flowbot.setting_welcome_placeholder')"
                  class="form-control"
                />
              </div>
              <div class="col-xl-5">
                <span class="form-text" style="margin-top: 0">
                  {{ $t('flowbot.setting_this_sentence_will_be_used_on_the_web_chat_as_starting_welcoming_message') }}
                </span>
              </div>
            </div>
            <div class="form-group row border-b pb-4">
              <label class="col-xl-2 form-control-label valign-center">
                <i class="material-icons mr-4">replay</i>
                {{ $t('flowbot.setting_reset_or_transfer_on_keywords') }}
              </label>
              <div class="col-xl-5">
                <label class="flex items-center leading-none">
                  <input v-model="record.reset_on_abort" type="radio" name="reset_on_abort" value="1" />
                  <i class="success ml-2"></i>
                  <span>{{ $t('flowbot.setting_toggle_reset') }}</span>
                </label>
                <label class="flex items-center leading-none">
                  <input v-model="record.reset_on_abort" type="radio" name="reset_on_abort" value="0" />
                  <i class="success ml-2"></i>
                  <span>{{ $t('flowbot.setting_toggle_transfer') }}</span>
                </label>
              </div>
              <div class="col-xl-5">
                <span class="form-text" style="margin-top: 0">
                  {{ $t('flowbot.setting_toggle_info_text') }}
                </span>
              </div>
            </div>
            <div class="form-group row border-b pb-4">
              <label class="col-xl-2 form-control-label valign-center">
                <i class="material-icons mr-4">vpn_key</i>
                <template v-if="record.reset_on_abort">{{ $t('flowbot.setting_toggle_reset_keywords') }}</template>
                <template v-else>{{ $t('flowbot.setting_toggle_transfer_keywords') }}</template>
              </label>
              <div class="col-xl-5">
                <div class="list-group">
                  <form @submit.prevent="addKeyword">
                    <input
                      v-model="abort_keyword"
                      type="text"
                      :class="{ 'outline-warning': sectionsTouched[1] && !record.abort_keywords.length }"
                      :placeholder="$t('flowbot.setting_add_keyword_sentence')"
                      class="form-control mb-2"
                    />
                  </form>
                  <div
                    v-for="(keyword, index) in record.abort_keywords"
                    :key="index"
                    class="mb-2 mr-2 inline-block whitespace-nowrap"
                  >
                    <span href="#" class="flowbot-keyword list-group-item">
                      {{ keyword }}
                      <span class="flowbot-keyword-delete ml-1 mt-1 py-4" @click="deleteKeyword(index)">
                        <i class="material-icons text-md text-grey-800">close</i>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-xl-5">
                <span class="form-text" style="margin-top: 0">
                  <template v-if="record.reset_on_abort">
                    {{ $t('flowbot.setting_reset_on_abort_info_text_reset_to_the_start') }}
                  </template>
                  <template v-else>
                    {{ $t('flowbot.setting_reset_on_abort_info_text_transferred_to_support') }}
                  </template>
                </span>
              </div>
            </div>
            <div class="form-group row border-b pb-4">
              <label class="col-xl-2 form-control-label valign-center">
                <i class="material-icons mr-4">keyboard_tab</i>
                <template v-if="record.reset_on_abort">
                  {{ $t('flowbot.setting_reset_info_text_reset_with_keywords_message') }}
                </template>
                <template v-else>{{ $t('flowbot.setting_reset_info_text_transfer_with_keywords_message') }}</template>
              </label>
              <div class="col-xl-5">
                <input
                  v-model="record.abort_keywords_message"
                  :class="{ 'outline-warning': sectionsTouched[1] && !record.abort_keywords_message }"
                  type="text"
                  :placeholder="$t('flowbot.setting_reset_keywords_placeholder')"
                  class="form-control"
                />
                <template v-if="record.abort_keywords_message && record.abort_keywords">
                  <div class="rounded-corners chat-message p-y-sm success mt-3 inline rounded px-4 text-left">
                    {{ record.abort_keywords_message }} {{ record.abort_keywords.join(', ') }}
                  </div>
                </template>
              </div>
              <div class="col-xl-5">
                <span class="form-text" style="margin-top: 0">
                  <template v-if="record.reset_on_abort">
                    {{ $t('flowbot.setting_reset_reply_message_info_text_reset') }}
                  </template>
                  <template v-else>
                    {{ $t('flowbot.setting_reset_reply_message_info_text_transfer') }}
                  </template>
                </span>
              </div>
            </div>
            <div v-if="!record.reset_on_abort" class="form-group row border-b pb-4">
              <label class="col-xl-2 form-control-label valign-center">
                <i class="material-icons mr-4">keyboard_tab</i>
                {{ $t('flowbot.setting_reset_transfer_message') }}
              </label>
              <div class="col-xl-5">
                <input
                  v-model="record.transfer_message"
                  type="text"
                  class="form-control"
                  :class="{ 'outline-warning': sectionsTouched[1] && !record.transfer_message }"
                />
              </div>
              <div class="col-xl-5">
                <span class="form-text" style="margin-top: 0">
                  {{ $t('flowbot.setting_reset_transfer_message_info_text') }}
                </span>
              </div>
            </div>
            <div class="form-group row border-b pb-4">
              <label class="col-xl-2 form-control-label valign-center">
                <i class="material-icons mr-4">format_list_bulleted</i>
                {{ $t('flowbot.setting_button_order_type') }}
              </label>
              <div class="col-xl-5">
                <multiselect
                  :model-value="selectedButtonOrderType"
                  label="name"
                  :options="button_order_types"
                  :class="{ 'outline-warning': sectionsTouched[1] && !record.button_order_type }"
                  :searchable="true"
                  :allow-empty="false"
                  :close-on-select="true"
                  :placeholder="$t('flowbot.setting_select_an_order_type')"
                  @update:modelValue="selectButtonOrderType"
                ></multiselect>
              </div>
              <div class="col-xl-5">
                <span class="form-text" style="margin-top: 0">
                  {{ $t('flowbot.setting_button_info_text_for_aesthetic_purposes') }}
                </span>
              </div>
            </div>
            <div class="form-group row border-b pb-4">
              <label class="col-xl-2 form-control-label valign-center">
                <i class="material-icons mr-4">undo</i>
                {{ $t('flowbot.setting_button_fallback_message') }}
              </label>
              <div class="col-xl-5">
                <input
                  v-model="record.button_fallback_message"
                  :class="{ 'outline-warning': sectionsTouched[1] && !record.button_fallback_message }"
                  type="text"
                  :placeholder="$t('flowbot.setting_no_choice_recognized_please_try_again')"
                  class="form-control"
                />
              </div>
              <div class="col-xl-5">
                <span class="form-text" style="margin-top: 0">
                  {{ $t('flowbot.setting_info_text_if_no_button_option_has_been_recognized') }}
                </span>
              </div>
            </div>
            <div class="form-group row border-b pb-4">
              <label class="col-xl-2 form-control-label valign-center">
                <i class="material-icons mr-4">radio_button_checked</i>
                {{ $t('flowbot.setting_button_guiding_message') }}
              </label>
              <div class="col-xl-5">
                <input
                  v-model="record.button_guiding_text"
                  :class="{ 'outline-warning': sectionsTouched[1] && !record.button_guiding_text }"
                  type="text"
                  :placeholder="$t('flowbot.setting_button_guiding_placeholder_make_a_choice')"
                  class="form-control"
                />
              </div>
              <div class="col-xl-5">
                <span class="form-text" style="margin-top: 0">
                  {{ $t('flowbot.setting_button_guiding_placeholder_as_a_mandatory_guiding_text') }}
                </span>
              </div>
            </div>

            <div v-if="isEntitledToChatbot" class="form-group row border-b pb-4">
              <div v-if="isEntitledToChatbot" class="form-group row border-b pb-4">
                <label class="col-xl-2 form-control-label valign-center">
                  <i class="material-icons mr-4">android</i>
                  {{ $t('flowbot.setting_optional_chatbot_questions_fallback') }}
                </label>
                <div class="col-xl-5">
                  <multiselect
                    v-model="record.chatbot"
                    label="title"
                    track-by="id"
                    :allow-empty="true"
                    :options="chatbots"
                    :searchable="true"
                    :close-on-select="true"
                    :placeholder="$t('general.select_a_chatbot')"
                  >
                    <template #noResult>
                      <span>{{ $t('general.query_no_results') }}</span>
                    </template>
                  </multiselect>
                </div>
                <div class="col-xl-5">
                  <span class="form-text" style="margin-top: 0">
                    {{ $t('flowbot.setting_chatbot_fallback_info_text') }}
                  </span>
                </div>
              </div>

              <button
                class="btn success rounded-lg-i valign-center -ml-3 text-white"
                type="button"
                data-parent="#accordion"
                aria-expanded="false"
                aria-controls="#save-and-publication_section"
                data-toggle="collapse"
                data-target="#save-and-publication_section"
                @click="checkSectionsDone((touchedSection = 1))"
              >
                {{ $t('flowbot.setting_ready_save_and_publication') }}
              </button>
            </div>
          </div>
        </div>
      </template>
    </configuration-card>

    <configuration-card
      card-title="save-and-publication"
      card-order="3"
      :collapsed="false"
      aria-expanded="false"
      data-parent="#accordion"
      @clicked="checkSectionsDone()"
    >
      <template #card-title>
        <span>
          <span v-if="sectionsDone[2]" class="step done">
            <i class="material-icons md-18" style="padding-top: 0.15rem">check</i>
          </span>
          <span v-else-if="!sectionsDone[2] && sectionsTouched[2]" class="step warning">3</span>
          <span v-else class="step">3</span>
          {{ $t('flowbot.setting_card_title_save_and_publication') }}
        </span>
      </template>
      <template #card-content>
        <div>
          <div class="p-4 px-8">
            <div class="form-group row border-b py-4">
              <label class="col-lg-2 form-control-label valign-center">
                <i class="material-icons mr-4">device_hub</i>
                {{ $t('flowbot.setting_connected_widget_automations') }}
              </label>
              <div class="col-lg-5">
                <span v-for="automation in record.widget_automations" :key="automation.id">
                  <router-link
                    :to="'/admin/widget_automation/' + automation.id"
                    class="btn rounded-lg-i btn-outline-secondary b-black text-muted mx-2 mb-4"
                    style="white-space: normal"
                  >
                    <i class="fa fa-fw fa-edit mr-2"></i>
                    {{ automation.title }}
                  </router-link>
                </span>
              </div>
              <div class="col-lg-5">
                <span class="form-text" style="margin-top: 0">
                  {{ $t('flowbot.setting_automation_info_text') }}
                  <router-link to="/admin/widget_automation/create/setFlowbot" class="btn btn-xs btn-default">
                    {{ $t('flowbot.setting_automation_info_button') }}
                  </router-link>
                </span>
              </div>
            </div>

            <div class="form-group row pb-4">
              <label class="col-xl-2 form-control-label valign-center">
                <i class="material-icons mr-4">remove_red_eye</i>
                {{ $t('flowbot.setting_publish') }}
              </label>
              <div class="col-xl-5 flex flex-row items-center">
                <label class="ui-switch success m-t-sm m-r">
                  <input
                    v-model="record.is_active"
                    type="checkbox"
                    :disabled="shouldShowUpgradeUtility"
                    true-value="1"
                  />
                  <i></i>
                </label>
                <span v-if="shouldShowUpgradeUtility" class="m-t-sm">
                  <t-badge variant="upgrade" :text="updateBadgeText">
                    <template #icon>
                      <arrow-top-circle-linear size="0.8rem" />
                    </template>
                  </t-badge>
                </span>
              </div>
              <div class="col-xl-5">
                <span class="text-muted">
                  {{ $t('flowbot.setting_publish_consent') }}
                </span>
              </div>
            </div>

            <button
              :class="{ loader: !loaded || asyncUpdate }"
              class="btn success rounded-lg-i valign-center -ml-3 text-white"
              @click="checkSectionsDone((touchedSection = 2)) || saveFlowbot()"
            >
              <i class="material-icons mr-2">save</i>
              {{ $t('flowbot.button_save') }}
            </button>
            <br />
            <br />
            <div v-if="record.is_active && (!record.meta || !record.meta.last_published_at)" class="form-group row">
              <label>
                <input v-model="record.terms" type="checkbox" required value="1" />
                <span v-html="markupSanitizer($t('flowbot.i_am_aware_of_the_additional_pricing_for_flowbots'))" />
              </label>
            </div>
          </div>
        </div>
      </template>
    </configuration-card>
    <playground v-if="playground" ref="playground" :record="record" :playground-ref="playgroundRef"></playground>
  </div>
</template>

<script type="text/babel">
import { ArrowTopCircleLinear } from '@trengo/trengo-icons';
import _ from 'lodash';
import { mapStores } from 'pinia';
import { VueDraggableNext } from 'vue-draggable-next';
import Multiselect from 'vue-multiselect';
import { mixin as VueClickAway } from 'vue3-click-away';

import { FEATURE_FLAG_SETTINGS, FEATURE, ENTITLEMENT_STATUS, PRICING_MODEL } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useEntitlementsStore, useFeatureFlagStore, useSubscriptionStore } from '@/store/pinia';
import { duplicateObject } from '@/util/helpers';
import markupSanitizer from '@/util/markupSanitizer';
import { randomString } from '@/util/stringHelpers';

import { getFlows } from './api';
import EditPageHeader from './components/EditPageHeader';
import delayEditor from './Editors/Delay';
import dynamicEditor from './Editors/Dynamic';
import fileEditor from './Editors/File';
import imageEditor from './Editors/Image';
import location_finderEditor from './Editors/LocationFinder';
import textEditor from './Editors/Text';
import user_inputEditor from './Editors/UserInput';
import videoEditor from './Editors/Video';
import textFormatter from './mixins/TextFormatter';
import playground from './Playground';
import delayPresenter from './Presenters/Delay';
import dynamicPresenter from './Presenters/Dynamic';
import filePresenter from './Presenters/File';
import imagePresenter from './Presenters/Image';
import location_finderPresenter from './Presenters/LocationFinder';
import textPresenter from './Presenters/Text';
import user_inputPresenter from './Presenters/UserInput';
import videoPresenter from './Presenters/Video';
import UpgradeFlowbotBanner from './UpgradeFlowbotBanner';
import RouterWarningOnEdit from '../../mixins/RouterWarningOnEdit';
import PusherHelper from '../../util/PusherHelper';
import configurationCard from '../ConfigurationCard';
import ColorPicker from '../Elements/ColorPicker';

export default {
  name: 'EditFlow',
  emits: ['update', 'call-parent-handleDeleteFlowbot-method'],
  props: {
    action: {
      type: String,
      default: 'create',
    },
    flowsProp: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    UpgradeFlowbotBanner,
    EditPageHeader,
    Multiselect,
    draggable: VueDraggableNext,
    ColorPicker,
    configurationCard,
    playground,

    delayEditor,
    textEditor,
    user_inputEditor,
    dynamicEditor,
    fileEditor,
    imageEditor,
    videoEditor,
    location_finderEditor,
    textPresenter,
    delayPresenter,
    user_inputPresenter,
    dynamicPresenter,
    filePresenter,
    imagePresenter,
    videoPresenter,
    location_finderPresenter,
    ArrowTopCircleLinear,
  },

  computed: {
    ...mapStores(useEntitlementsStore),
    indexOfStep() {
      return this.record.steps.findIndex((step) => step.id === this.editStep);
    },
    shouldShowUpgradeUtility() {
      return !this.isEntitled && this.hasUsageBasedSubscription;
    },
    entitlementStatus() {
      const flowsQuantity = this.flows.length + 1;
      return this.entitlementsStore?.getEntitlementStatusFor(
        FEATURE.CUSTOMER_SERVICE__AUTOMATION__FLOWBOT,
        flowsQuantity
      );
    },
    updateBadgeText() {
      switch (this.entitlementStatus) {
        case this.ENTITLEMENT_STATUS.REQUIRES_ADDON:
          return this.$t('entitlement.requires_add_on');
        case this.ENTITLEMENT_STATUS.REQUIRES_ADDON_AND_PLAN_UPGRADE:
          return this.$t('entitlement.boost');

        default:
          return this.$t('entitlement.boost');
      }
    },
    isEntitled() {
      const featureId = FEATURE.CUSTOMER_SERVICE__AUTOMATION__FLOWBOT;
      const flowsQuantity = this.flows.length + 1;
      return this.entitlementsStore?.isEntitledTo(featureId, flowsQuantity);
    },
    hasUsageBasedSubscription() {
      return useSubscriptionStore().subscription.pricingModel === PRICING_MODEL.USAGE_BASED;
    },
    isFeatureFlagEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_SETTINGS.TA_SETTINGS_FLOWBOT);
    },
    filteredRecordSteps() {
      return this.record.steps.filter((step) => step.id === this.editStep);
    },
    getFlowId() {
      return this.flowsProp.find((f) => f.id === this.record.id);
    },
    availableChannels() {
      return this.channels.filter((channel) => {
        return (
          ['TELEGRAM', 'FACEBOOK', 'CHAT', 'WA_BUSINESS', 'GBM', 'INSTAGRAM'].includes(channel.type) ||
          (channel.type === 'SMS' && channel.smsChannel && channel.smsChannel.type === 'SEND_AND_RECEIVE')
        );
      });
    },

    selectedButtonOrderType() {
      return this.button_order_types.find((b) => {
        return b.type === this.record.button_order_type;
      });
    },

    flowHasALocationFinder() {
      return (
        this.record.steps.filter((step) => {
          return (
            step.contents.filter((content) => {
              return content.type === 'location_finder';
            }).length > 0
          );
        }).length > 0
      );
    },

    locationFinderActionIsApplied() {
      return (
        this.record.steps.filter((step) => {
          return (
            typeof step.actions === 'object' &&
            step.actions.length > 0 &&
            step.actions.filter((action) => {
              return action.action === 'assign_to_location_finder_team';
            }).length > 0
          );
        }).length > 0
      );
    },

    customFields() {
      let customFields = this.$root.customTicketFields.map(function (customField) {
        return {
          id: customField.id,
          value: 'Ticket ' + customField.title,
          title: 'Custom ticket field: ' + customField.title,
          type: customField.type,
        };
      });
      Array.prototype.push.apply(customFields, [
        {
          id: '',
          value: 'Contact full_name',
          title: this.$t('flowbot.user_input_contact_name_placeholder'),
          type: 'CONTACT',
        },
        {
          id: '',
          value: 'Contact email',
          title: this.$t('flowbot.user_input_contact_email_address_placeholder'),
          type: 'CONTACT',
        },
      ]);
      if (this.flowHasALocationFinder) {
        customFields.push({
          id: '',
          value: 'Contact ' + this.record.title + ' Location',
          title: 'Contact field Location finder',
          type: 'CONTACT',
        });
      }
      Array.prototype.push.apply(
        customFields,
        this.$root.customContactFields.map(function (customField) {
          return {
            id: customField.id,
            value: 'Contact ' + customField.title,
            title: 'Custom contact field: ' + customField.title,
            type: customField.type,
          };
        })
      );
      Array.prototype.push.apply(customFields, [
        { id: '', value: 'Profile name', title: 'Profile name', type: 'PROFILE' },
      ]);
      Array.prototype.push.apply(
        customFields,
        this.$root.customProfileFields.map(function (customField) {
          return {
            id: customField.id,
            value: 'Profile ' + customField.title,
            title: 'Custom profile field: ' + customField.title,
            type: customField.type,
          };
        })
      );
      return customFields;
    },

    tooltipContent() {
      const buttonHoverText = this.$t('flowbot.button_hover_test_your_flow');
      const activateFlowText = this.$t(
        'flowbot.button_hover_activate_your_flow_before_testing_no_channel_selection_required'
      );
      const isActive = this.getSafe(this.getFlowId, 'is_active');
      let content = buttonHoverText;
      if (!isActive) {
        content += '<br>' + activateFlowText;
      }
      return content;
    },
    isEntitledToChatbot() {
      return false;
    },
  },

  mixins: [RouterWarningOnEdit, VueClickAway, textFormatter],

  watch: {
    record: {
      handler: function () {
        if (this.sectionsDoneTimer) {
          window.clearTimeout(this.sectionsDoneTimer);
        }
        this.sectionsDoneTimer = window.setTimeout(
          function () {
            this.checkSectionsDone();
          }.bind(this),
          200
        );
      },
      deep: true,
    },
    editStep(val) {
      if (this.loaded && this.record.id) {
        this.$tStorage.setItem(this.record.id + '_editStep', val);
      }
    },
    'record.title'(newVal, oldVal) {
      if (this.loaded) {
        this.updateTitle(newVal, oldVal);
      }
    },
    'record.reset_on_abort'() {
      if (
        this.record.reset_on_abort &&
        this.record.abort_keywords_message === 'If you want to talk to a human you can exit any time with'
      ) {
        this.record.abort_keywords_message = 'If you want to start over you can use any of these words';
      } else if (
        !this.record.reset_on_abort &&
        this.record.abort_keywords_message === 'If you want to start over you can use any of these words'
      ) {
        this.record.abort_keywords_message = 'If you want to talk to a human you can exit any time with';
      }
    },
  },

  data() {
    return {
      isSavingFlowbot: false,
      flows: [],
      ENTITLEMENT_STATUS,
      featureName: 'Flowbots',
      parent: '',
      record: {
        id: 'create',
        title: this.$t('flowbot.internal_title_my_flow'),
        steps: [
          {
            id: null,
            title: this.$t('flowbot.my_step'),
            meta: {},
            contents: [],
            actions: [],
            buttons: [],
          },
        ],
        abort_keywords: [this.$t('flowbot.transfer_keywords_stop'), this.$t('flowbot.transfer_keywords_help'), '😡'],
        abort_keywords_message: this.$t('flowbot.reset_with_keywords_message'),
        reset_on_abort: 0,
        transfer_message: this.$t('flowbot.transfer_message'),
        button_guiding_text: this.$t('flowbot.button_guiding_message'),
        button_fallback_message: this.$t('flowbot.button_fallback_message'),
        // web_chat_start_message: 'Welcome to our automated world wide web flow!',
        web_chat_start_message: this.$t('flowbot.web_chat_start_message'), // Hi :wave:! Ik ben de Flowbot van Trengo en ik ga je helpen. Mocht je een medewerker willen spreken, typ simpelweg Stop of Help dan verbind ik je door!
        button_order_type: 'emojis',
        channels: [],
        is_active: 0,
        chatbot: {},
        meta: {},
      },

      sectionsDone: [false, false, false],
      sectionsTouched: [false, false, false],
      sectionsDoneTimer: null,
      abort_keyword: '', // Placeholder value for adding new abort keywords
      drag: false,
      dragElement: false,
      loaded: false,
      saving: false,
      editStep: null, // current step id being edited
      editContent: null, // current content id being edited
      addElement: false,
      alphabet: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ],
      special: [
        'zeroth',
        'first',
        'second',
        'third',
        'fourth',
        'fifth',
        'sixth',
        'seventh',
        'eighth',
        'ninth',
        'tenth',
        'eleventh',
        'twelfth',
        'thirteenth',
        'fourteenth',
        'fifteenth',
        'sixteenth',
        'seventeenth',
        'eighteenth',
        'nineteenth',
      ],
      deca: ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'],
      channels: [],
      playground: false,
      asyncUpdate: false,

      // flowbot_contents
      types: [
        {
          title: this.$t('flowbot.step_type_title_text'),
          type: 'text',
          component: 'text',
          icon: 'format_size',
          width: '350px',
          id: 1,
        },
        {
          title: this.$t('flowbot.step_type_title_video'),
          type: 'video',
          component: 'video',
          icon: 'videocam',
          width: '350px',
          id: 3,
        },
        {
          title: this.$t('flowbot.step_type_title_image'),
          type: 'image',
          component: 'image',
          icon: 'crop_original',
          width: '350px',
          id: 4,
        },
        {
          title: this.$t('flowbot.step_type_title_file'),
          type: 'file',
          component: 'file',
          icon: 'description',
          width: '350px',
          id: 5,
        },
        {
          title: this.$t('flowbot.step_type_title_delay'),
          type: 'delay',
          component: 'delay',
          icon: 'more_horiz',
          width: '300px',
          id: 6,
        },
        {
          title: this.$t('flowbot.step_type_title_user_input'),
          type: 'user_input',
          component: 'user_input',
          icon: 'question_answer', // perm_identity // keyboard
          width: '450px',
          id: 7,
        },
        {
          title: this.$t('flowbot.step_type_title_dynamic_content'),
          type: 'dynamic',
          component: 'dynamic',
          icon: 'code',
          width: '450px',
          id: 8,
        },
        {
          title: this.$t('flowbot.step_type_title_location_finder'),
          type: 'location_finder',
          component: 'location_finder',
          icon: 'map', // room
          width: '450px',
          id: 9,
        },
      ],

      ending_action_types: [
        {
          title: this.$t('flowbot.step_action_title_mark_as_new_ticket'),
          value: 'mark_as_new_ticket',
        },
        {
          title: this.$t('flowbot.step_action_title_assign_to_location_finder_team'),
          value: 'assign_to_location_finder_team',
        },
        {
          title: this.$t('flowbot.step_action_title_assign_to_team'),
          value: 'assign_to_team',
        },
        {
          title: this.$t('flowbot.step_action_title_close_ticket'),
          value: 'close_ticket',
        },
        {
          title: this.$t('flowbot.step_action_title_go_to_step'),
          value: 'go_to_step',
        },
      ],

      regular_action_types: [
        {
          title: this.$t('flowbot.step_action_title_attach_label'),
          value: 'attach_label',
        },
        {
          title: this.$t('flowbot.step_action_title_add_to_contact_group'),
          value: 'add_to_contact_group',
        },
        {
          title: this.$t('flowbot.step_action_title_forward_to_e_mail'),
          value: 'forward_ticket',
        },
      ],

      button_order_types: [
        {
          name: this.$t('flowbot.button_order_name_1_10_numbers'),
          type: 'numbers',
        },
        {
          name: this.$t('flowbot.button_order_name_a_z_alphabet'),
          type: 'alphabet',
        },
        {
          name: this.$t('flowbot.button_order_name_1_10_emojis'),
          type: 'emojis',
        },
      ],

      chatbots: [],
      playgroundRef: null,
    };
  },
  async mounted() {
    await this.loadList();
    if (this.action === 'edit') {
      axios.get('/api/v2/flowbot/' + this.$route.params.id).then(async (response) => {
        this.record = response.data;
        if (response.data.steps.length) {
          let previousEditStep = parseInt(await this.$tStorage.getItem(response.data.id + '_editStep'));
          if (this.record.steps.find((step) => step.id === previousEditStep)) {
            this.editStep = previousEditStep;
          } else {
            this.editStep = response.data.steps[0].id;
          }
        }
        this.checkSectionsDone();

        setTimeout(
          function () {
            this.initWarning();
            // this.loaded = true;
          }.bind(this),
          500
        );
      });

      this.sectionsTouched.fill(true);
    }

    if (this.action === 'create') {
      let randomId = this.generateRandomId();
      this.record.steps[0].id = randomId;
      this.editStep = randomId;
      this.initWarning();
    }

    this.channels = this.$root.channels;

    if (this.isEntitledToChatbot) {
      axios.get('/api/v2/chatbot').then((res) => {
        this.chatbots = res.data;
      });
    }

    this.bind();
  },

  beforeUnmount() {
    PusherHelper.unbindPusherEvent(this.$root.userChannel, 'flowbot_creation_messages' + this.$route.params.id);
  },

  methods: {
    markupSanitizer: markupSanitizer,

    async loadList() {
      try {
        const result = await getFlows(this.page, false);
        this.flows = 'data' in result.data ? result.data.data : result.data;
        this.loaded = true;
      } catch (error) {
        console.error(error);
      }
    },

    bind() {
      PusherHelper.bindPusherEvent(
        this.$root.userChannel,
        'flowbot_creation_messages' + this.$route.params.id,
        this.asyncUpdateOrCreateResponse
      );
    },
    asyncUpdateOrCreateResponse(data) {
      this.asyncUpdate = false;
      if (data && data.error_message) {
        this.flashError(data.error_message);
      } else {
        this.initWarning();
        this.flashSuccess('Your flowbot has been validated and successfully saved 👍');
        this.$emit('update', {
          id: data.id,
        });
      }
    },

    toNewStep(event) {
      if (event.target.value === 'toNewStep') {
        this.getCurrentStep().buttons.forEach((button) => {
          if (button.next_step_id === 'toNewStep') {
            this.addStep(false, button.title !== this.$t('flowbot.new_step') ? button.title : null);
            button.next_step_id = this.record.steps[this.record.steps.length - 1].id;
          }
        });
        this.getCurrentStep().actions.forEach((action) => {
          if (action.meta === 'toNewStep') {
            this.addStep(false);
            action.meta = this.record.steps[this.record.steps.length - 1].id;
          }
        });
      }
    },

    generateRandomId() {
      return 's_' + randomString(10); // must be string for creating/updating
    },

    getComponentType(contents) {
      return this.types.find((type) => {
        return type.type === contents.type;
      });
    },

    showAdd() {
      return this.editContent === null && this.addElement;
    },

    hideAdd() {
      this.addElement = false;
    },

    onAddElement() {
      this.addElement = true;
      this.editContent = null;
    },

    addStep(goToCreatedStep = true, title = '') {
      let id = this.generateRandomId();
      this.record.steps.push({
        id: id,
        title:
          title ||
          this.$t('flowbot.my_ordinal_number_step', {
            ordinalNumber: this.stringifyNumber(this.record.steps.length + 1),
          }),
        meta: {},
        contents: [],
        buttons: [],
        actions: [],
      });
      if (goToCreatedStep) {
        this.editStep = id;
      }

      this.sectionsTouched.splice(0, 1, false);
    },

    remove(item) {
      this.getCurrentStep().contents.splice(this.getCurrentStep().contents.indexOf(item), 1);
    },

    duplicateContent(item) {
      this.editContent = null;
      let content = JSON.parse(JSON.stringify(item));
      content.id = this.generateRandomId();
      this.getCurrentStep().contents.splice(this.getCurrentStep().contents.indexOf(item), 0, content);
    },

    changed(changed) {
      if (changed.added) {
        this.onAdded(changed.added.element, changed.added.newIndex);
      }
    },

    add(item) {
      this.getCurrentStep().contents.push(item);

      this.onAdded(item);
      this.addElement = false;
    },

    // Add step
    onAdded(obj) {
      // Unbind from source
      for (let i = 0; i < this.types.length; i++) {
        if (this.types[i].type === obj.type) {
          this.types[i] = duplicateObject(obj);
        }
      }

      let item = obj;

      item.id = this.generateRandomId();

      item.meta = {
        message: '',
      };
      this.editContent = item.id;

      if (item.type === 'text') {
        item['meta'] = {
          message: null,
        };
      }

      if (item.type === 'delay') {
        item['meta'] = {
          delay_in_seconds: 1,
          send_typing: true,
        };
      }

      if (item.type === 'user_input') {
        item['meta'] = {
          message: this.$t('flowbot.user_input_message_what_is_your_name'),
          input_type: 'name',
          fallback_message: this.$t('flowbot.user_input_fallback_message_for_invalid_replies'),
          custom_field: {
            id: '',
            value: 'Contact full_name',
            title: this.$t('flowbot.user_input_contact_name_placeholder'),
            type: 'CONTACT',
          },
          list: [],
          field_type: 'contact',
          confirm_last_input: 0,
          confirm_question: this.$t('flowbot.user_input_confirm_message_for_previous_input'),
          confirm_title: this.$t('flowbot.user_input_confirm_message_previous_input_yes'),
          refute_title: this.$t('flowbot.user_input_confirm_message_previous_input_no'),
        };
      }

      if (item.type === 'dynamic') {
        item['meta'] = {
          url: null,
          method: 'POST',
          headers: [{ key: null, value: null }],
          body: [{ key: null, value: null }],
        };
      }

      if (item.type === 'image' || item.type === 'video' || item.type === 'file') {
        item['meta'] = {
          message: null,
          file_path: null,
          client_name: null,
        };
      }

      if (item.type === 'location_finder') {
        item['meta'] = {
          message: this.$t('flowbot.location_finder_message_for_new_contacts'),
          locations: [],
          suggest_locations: false,
          suggest_location_guiding_text: this.$t('flowbot.location_finder_suggest_guiding_text'),
          fallback_message_unknown_location: this.$t('flowbot.location_finder_fallback_message_for_unknown_locations'),
          fallback_message_too_many_locations: this.$t(
            'flowbot.location_finder_fallback_message_for_too_many_locations'
          ),
          confirm_last_input: 0,
          confirm_question: this.$t('flowbot.location_finder_confirm_message_for_previous_location', {
            flowTitle: this.record.title,
          }),
          confirm_title: this.$t('flowbot.location_finder_accept_yes'),
          refute_title: this.$t('flowbot.location_finder_decline_no'),
        };
      }
    },

    getCurrentStep() {
      return this.record.steps.find((step) => step.id === this.editStep);
    },

    async addButton(step, reset = false) {
      this.editContent = null;

      let storageName = this.record.id + '_' + this.getCurrentStep().id;
      if (reset) {
        if (this.getCurrentStep().actions.length > 0) {
          this.$tStorage.setItem(storageName + '_actions', this.getCurrentStep().actions);
        }
        step.actions = [];
        if (await this.$tStorage.getItem(storageName + '_buttons')) {
          this.getCurrentStep().buttons = await this.$tStorage.getItem(storageName + '_buttons');
          return;
        }
      }
      this.getCurrentStep().buttons.push({
        title: this.$t('flowbot.new_step'),
        next_step_id: null,
      });
    },

    async addAction(step, reset = false) {
      this.editContent = null;

      let storageName = this.record.id + '_' + this.getCurrentStep().id;
      if (reset) {
        if (this.getCurrentStep().buttons.length > 0) {
          this.$tStorage.setItem(storageName + '_buttons', this.getCurrentStep().buttons);
        }
        step.buttons = [];
        if (await this.$tStorage.getItem(storageName + '_actions')) {
          this.getCurrentStep().actions = await this.$tStorage.getItem(storageName + '_actions');
          return;
        }
      }
      this.getCurrentStep().actions.push({
        action: null,
        meta: null,
      });
    },

    checkSectionsDone(touchedSection = null) {
      this.record.steps.forEach((step) => {
        step.done = Boolean(
          step.contents.length &&
            ((step.actions.length && !step.buttons.length) || (!step.actions.length && step.buttons.length))
        ); // Every step requires contents, and actions OR buttons but not both
      });

      this.sectionsDone.splice(
        0,
        1,
        !!(
          // Every step requires actions OR buttons but not both
          this.record.steps.every(
            (step) =>
              (step.actions.length &&
                !step.buttons.length &&
                step.actions.filter((act) => {
                  return _.map(this.ending_action_types, 'value').includes(act.action);
                }).length > 0) ||
              (!step.actions.length && step.buttons.length)
          )
        )
      );

      this.sectionsDone.splice(
        1,
        1,
        !!(
          this.record.title &&
          this.record.abort_keywords.length &&
          this.record.abort_keywords_message &&
          this.record.transfer_message &&
          this.record.button_fallback_message &&
          this.record.web_chat_start_message &&
          this.record.button_guiding_text
        )
      );

      this.sectionsDone.splice(2, 1, this.record.is_active);

      if (!this.sectionsDone.every((sectionDone) => sectionDone === true)) {
        this.sectionsDone.splice(2, 1, this.record.is_active);
      }

      if (touchedSection !== null) {
        this.sectionsTouched.splice(touchedSection, 1, true);
      }
    },

    addKeyword() {
      if (this.abort_keyword) {
        this.record.abort_keywords.push(this.abort_keyword);
        this.abort_keyword = '';
      }
    },

    deleteKeyword(index) {
      this.record.abort_keywords.splice(index, 1);
    },

    toggleFullScreen() {
      eventBus.$emit('flowbot.editor.fullscreen');
    },

    async saveFlowbot(copy = false) {
      if (this.isSavingFlowbot) {
        return;
      }

      if (this.record.is_active && (!this.record.meta || !this.record.meta.last_published_at) && !this.record.terms) {
        this.flashError(this.$t('flowbot.the_terms_must_be_accepted'));
        return;
      }

      this.checkSectionsDone();
      let indexCurrentlyEditStep = 0;
      if (await this.$tStorage.getItem(this.record.id + '_editStep')) {
        indexCurrentlyEditStep = this.record.steps.findIndex(
          async (step) => step.id === parseInt(await this.$tStorage.getItem(this.record.id + '_editStep'))
        );
      }

      this.isSavingFlowbot = true;

      if (this.action === 'edit') {
        axios
          .put('/api/v2/flowbot/' + this.$route.params.id, this.record)
          .then((response) => {
            if (response.data && response.data.message === 'validating async') {
              this.flashWarning(this.$t('flowbot.take_coffee_while_your_flowbot_is_being_validated'));
              this.asyncUpdate = true;
            } else {
              this.record = response.data;
              if (this.record.steps[indexCurrentlyEditStep]) {
                this.editStep = this.record.steps[indexCurrentlyEditStep].id;
              }
              setTimeout(
                function () {
                  this.initWarning();
                }.bind(this),
                500
              );
              this.flashSuccess(this.$t('flowbot.flowbot_has_been_successfully_updated'));
            }
            if (copy) {
              this.copyFlowbot();
            } else {
              this.$emit('update', { id: response.data.id });
            }
          })
          .finally(() => {
            this.isSavingFlowbot = false;
          });
      } else {
        axios
          .post('/api/v2/flowbot', this.record)
          .then((response) => {
            // Saved flowbot
            if (response.data && response.data.message === 'validating async') {
              this.flashWarning(this.$t('flowbot.take_coffee_while_your_flowbot_is_being_validated'));
              this.asyncUpdate = true;
            } else {
              this.record = response.data;
              // this.editStep = this.record.steps[stepIndex].id;
              setTimeout(
                function () {
                  this.initWarning();
                }.bind(this),
                500
              );
              this.flashSuccess('The flowbot has been successfully created.');
              this.$root.userChannel.unbind('flowbot_creation_messagesundefined');
              this.$root.userChannel.bind('flowbot_creation_messages' + response.data.id, (data) =>
                this.asyncUpdateOrCreateResponse(data)
              );
            }

            if (copy) {
              this.copyFlowbot();
            } else {
              this.initWarning(false);
              this.$emit('update', { id: response.data.id });
            }
          })
          .finally(() => {
            this.isSavingFlowbot = false;
          });
      }
    },

    copyFlowbot() {
      this.record.title += ' copy';
      this.record.is_active = 0;
      axios.post('/api/v2/flowbot', this.record).then((response) => {
        this.initWarning();
        if (response.data && response.data.message === 'validating async') {
          this.flashWarning(this.$t('flowbot.take_coffee_while_your_flowbot_is_being_validated'));
          this.asyncUpdate = true;
        } else {
          this.record = response.data;
          setTimeout(
            function () {
              this.initWarning();
            }.bind(this),
            500
          );
          this.flashSuccess(this.$t('flowbot.flowbot_has_been_successfully_copied'));
          this.$emit('update', {
            id: response.data.id,
          });
        }
      });
    },

    getSafe(obj = [], property = '') {
      return _.get(obj, property);
    },

    duplicateStep(id) {
      const stepIndex = this.record.steps.findIndex((step) => step.id === id);
      let step = JSON.parse(JSON.stringify(this.record.steps[stepIndex]));
      step.id = this.generateRandomId();
      step.title += ' copy';
      this.record.steps.push(step);
    },

    async deleteStep(id) {
      const stepIndex = this.record.steps.findIndex((step) => step.id === id);

      const shouldDeleteStep = await this.$tConfirm(this.$t('flowbot.are_you_sure_you_want_to_delete_this_step'), {
        delete: true,
        title: this.$t('general.are_you_sure'),
      });

      if (shouldDeleteStep) {
        if (this.editStep === id) {
          this.editStep = null;
        }

        if (typeof this.record.steps[stepIndex - 1] === 'object') {
          this.editStep = this.record.steps[stepIndex - 1].id;
        }
        this.record.steps.splice(stepIndex, 1);
      }
    },

    async removeFlowbot() {
      this.initWarning(false);
      this.$emit('call-parent-handleDeleteFlowbot-method', this.record.id);
    },

    // https://stackoverflow.com/a/20426113/3344029
    stringifyNumber(n) {
      if (n < 20) {
        return this.special[n];
      }
      if (n % 10 === 0) {
        return this.deca[Math.floor(n / 10) - 2] + 'ieth';
      }
      return this.deca[Math.floor(n / 10) - 2] + 'y-' + this.special[n % 10];
    },

    selectButtonOrderType(selectedButtonOrderType) {
      this.record.button_order_type = selectedButtonOrderType.type;
    },

    updateTitle(newTitle, oldTitle) {
      this.record.steps.forEach((step) => {
        step.contents.forEach((content) => {
          if (content.meta) {
            for (let key in content.meta) {
              if (content.meta.hasOwnProperty(key)) {
                if (typeof content.meta[key] === 'string' && content.meta[key].includes(oldTitle)) {
                  content.meta[key] = content.meta[key].replace(
                    '{ Contact ' + oldTitle + ' Location }',
                    '{ Contact ' + newTitle + ' Location }'
                  );
                }
              }
            }
          }
        });
      });
      this.record.steps.forEach((step) => {
        step.buttons.forEach((button) => {
          for (let key in button) {
            if (button.hasOwnProperty(key)) {
              if (typeof button[key] === 'string' && button[key].includes(oldTitle)) {
                button[key] = button[key].replace(
                  '{ Contact ' + oldTitle + ' Location }',
                  '{ Contact ' + newTitle + ' Location }'
                );
              }
            }
          }
        });
      });
    },

    isDisabledAction(index, action_type_value) {
      let conflicting_action_types = _.map(this.ending_action_types, 'value');
      // returns if action should be disabled (if action is a conflicting action_type and is not current action)
      return (
        conflicting_action_types.includes(action_type_value) &&
        this.getCurrentStep().actions.findIndex((act) => {
          return conflicting_action_types.includes(act.action);
        }) > -1 &&
        this.getCurrentStep().actions.findIndex((act) => {
          return conflicting_action_types.includes(act.action);
        }) !== index
      );
    },

    goToStepFromButton(button) {
      if (button.next_step_id) {
        this.editStep = button.next_step_id;
      }
    },

    showActionEndingWarning(step) {
      return (
        this.sectionsTouched[0] &&
        step.actions.length > 0 &&
        step.actions.filter((act) => {
          return _.map(this.ending_action_types, 'value').includes(act.action);
        }).length < 1
      );
    },

    showPlayground() {
      let dbRecord = this.flowsProp.find((f) => f.id === this.record.id);
      if (!dbRecord || !dbRecord.is_active) {
        alert(this.$t('flowbot.please_activate_flowbot_before_testing'));
        return;
      }
      if (this.isDirty() && !confirm(this.$t('flowbot.your_new_flowbot_has_not_saved_continue_with_older'))) {
        return;
      }
      this.playground = true;
      setTimeout(() => {
        $(this.$refs.playground.$el).modal('show');
        this.playgroundRef = this.$refs.playground;
      }, 100);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.onBeforeRouteLeaveGuard(to, from, next);
  },
  beforeRouteUpdate(to, from, next) {
    this.onBeforeRouteUpdateGuard(to, from, next);
  },
};
</script>

<style lang="scss" scoped>
.step-content .step-content-actions {
  visibility: hidden;
}

.step-content:hover .step-content-actions {
  visibility: visible;
}

.active-step {
  background-color: #f1f1f1;
}

.row-flowbot {
  margin: 0;
  width: 1002px;
}

.col-flowbot {
  width: 400px;
  padding: 5px 20px;
}

.col-flowbot-right {
  width: 600px;
  padding: 5px 20px 5px 30px;
}

.text-background {
  color: rgb(211, 211, 211);
}

.bg-green-ball {
  height: 155px;
  border-radius: 100% 0 100% 80%;
  margin-top: -74px;
  margin-left: -190px;
  width: calc(100% + 280px);
  position: absolute;
  z-index: 1;
}

.rounded-lg-i {
  border-radius: 0.5rem !important;
}

.flowbot-keyword {
  display: inline-block;
  background-color: #f1f1f1;
  padding: 8px 12px;
  margin-bottom: 0 !important;
}

.flowbot-keyword-delete {
  position: relative;
  top: 4px;
  cursor: pointer;
}

.list-group-item {
  margin-bottom: 10px;
  border-radius: 9px;
  border-color: #d9d9d9;
}

.btn-outline-success {
  border-color: #14b29f;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid #14b29f;
    color: white !important;
    background-color: #14b29f;
  }
}

.outline-warning {
  border-color: #f6993f;
}

.radio-button {
  margin-top: 1px;

  i:before {
    width: 16px;
    height: 16px;
    left: 1px;
    top: 1px;
  }

  .success {
    margin-top: 2px;
  }
}

.btn.disabled {
  opacity: 0.4;
}
</style>
<style lang="css" scoped>
.outline-warning :deep(.multiselect__tags) {
  border-color: #f6993f;
}
</style>
