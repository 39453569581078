<template>
  <div class="mt-6 flex flex-col">
    <t-input-text
      id="url"
      v-model="$props.modelValue.url"
      size="sm"
      :label="$tc('integrations.picqer_label_subdomain')"
      :sub-label="$tc('integrations.picqer_sublabel_subdomain')"
      :placeholder="$tc('integrations.picqer_placeholder_subdomain')"
      :class="{ 'mb-6': !errors.url }"
      :has-error="errors.url"
      @blur="checkUrl"
    />
    <t-error-item v-if="errors.url" :text="$tc('integration_hub.error_picqer_url_format')" danger-icon class="mb-4" />

    <t-input-text
      id="apiKey"
      v-model="$props.modelValue.api_key"
      size="sm"
      :label="$tc('integrations.picqer_label_api_key')"
      :class="{ 'mb-6': !errors.apiKey }"
      :has-error="errors.apiKey"
      @blur="checkApiKey"
    />
    <t-error-item
      v-if="errors.apiKey"
      :text="$tc('integration_hub.error_api_key_must_be_filled_out')"
      danger-icon
      class="mb-4"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import type { CustomPicqerIntegration } from '@/store/types/integrations';

const props = defineProps<{ modelValue: CustomPicqerIntegration['meta'] }>();
const emit = defineEmits(['on-validated', 'on-invalidated']);

const errors = ref({ url: false, apiKey: false });

function validateFields() {
  checkUrl();
  checkApiKey();

  Object.values(errors.value).some((item) => item) ? emit('on-invalidated') : emit('on-validated');
}

defineExpose({ validateFields });

function checkUrl() {
  const startsWithHttps = /^(https:?\/?\/?)/;
  const endsWithPicqer = /\.picqer\.com/;

  errors.value.url =
    !props.modelValue.url ||
    props.modelValue.url === '' ||
    startsWithHttps.test(props.modelValue.url) ||
    endsWithPicqer.test(props.modelValue.url);
}

function checkApiKey() {
  errors.value.apiKey = !props.modelValue.api_key || props.modelValue.api_key === '';
}
</script>
