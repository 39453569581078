<template>
  <t-accordion :is-open="isOpen" :classes="accordionClass">
    <template #title>
      <div class="flex select-none items-center justify-between" @click="isOpen = !isOpen">
        <div class="align-center flex flex-row items-center">
          <span class="mr-2 text-base font-bold">{{ dns.title }}</span>
          <span class="text-base font-medium text-grey-500">{{ dns.description }}</span>
        </div>
        <div>
          <span class="align-center flex cursor-pointer items-center justify-center">
            <img :src="`${$root.assetsURL}img/chevron-down.svg`" alt="down" />
          </span>
        </div>
      </div>
    </template>

    <template #body>
      <div class="flex select-none flex-col p-4">
        <div
          v-if="dns.description === 'Verify your domain' && dns.dns_value === 'mandrill_verify.null'"
          class="rounded-xl bg-grey-300 px-4 py-6"
        >
          <div class="flex flex-row items-start">
            <span class="mr-1 flex w-36px items-start justify-start">
              <img
                class="mr-1 pr-1"
                :src="dns.is_valid ? $root.assetsURL + 'img/check-circle.svg' : $root.assetsURL + 'img/info.svg'"
                alt="status"
              />
            </span>
            <span class="w-full">
              <template v-if="dns.is_valid">{{ $t('email_settings.domain_verification_completed') }}</template>
              <template v-else>
                <strong>{{ $t('email_settings.domain_verification_incomplete') }}</strong>
                <br />
                {{ $t('email_settings.verification_email_sent_message') }}
              </template>
            </span>
          </div>
        </div>
        <template v-else>
          <div v-if="dns.error && dns.description === 'SPF'" class="rounded-xl bg-grey-300 px-4 py-6">
            <div class="flex flex-row items-start">
              <span class="mr-1 flex h-36px w-36px items-start justify-start">
                <img class="mr-1 pr-1" :src="`${$root.assetsURL}img/info.svg`" alt="status" />
              </span>
              <span class="w-full">
                {{ $t('email_settings.existing_spf_record_warning') }}
              </span>
            </div>
          </div>
          <template v-else>
            <div class="align-center mb-12px mt-8 flex flex-row items-center">
              <div class="mr-8 w-12">
                <span class="title-text-bold">{{ $t('email_settings.type') }}</span>
              </div>
              <div>
                <span class="title-text-bold ml-1">
                  {{ dns.type_value }}
                </span>
              </div>
            </div>
            <div class="align-center mb-12px flex flex-row items-center">
              <div class="w-14 mr-10">
                <span class="title-text-bold">{{ $t('email_settings.name') }}</span>
              </div>
              <div class="flex w-full flex-row items-center">
                <div class="accordion-inputs w-84">
                  <atomic-input v-if="!isNameCopied" size="lg" readonly :model-value="dns.name_value" />
                  <span
                    v-else
                    :class="[responsiveInputSize]"
                    class="
                      copy-container
                      input-container
                      flex
                      w-full
                      items-center
                      justify-center
                      rounded-xl
                      border-1 border-grey-200
                      p-4
                      font-semibold
                    "
                  >
                    <i class="fas fa-check-circle mr-2 text-xl lg:text-xl" />
                    {{ $t('email_settings.copied') }}
                  </span>
                </div>
                <copy-btn :disabled="!!dns.error" :data="dns.name_value" @click="handleButtonClick('name')" />
              </div>
            </div>
            <div class="align-start mb-12px flex flex-row items-start">
              <div class="w-14 mr-10">
                <span class="title-text-bold">{{ $t('email_settings.value') }}</span>
              </div>
              <div class="flex w-full flex-row items-center">
                <div class="accordion-inputs w-84">
                  <div v-if="!isValueCopied" ref="valueInputRef">
                    <t-textarea
                      id="dnsDescription"
                      readonly
                      :model-value="dns.dns_value"
                      :height-auto="dns.description !== 'DKIM'"
                      height="272px"
                    />
                  </div>
                  <span
                    v-else
                    :class="[responsiveInputSize]"
                    :style="{ height: valueCopyHeight + 'px' }"
                    class="
                      copy-container
                      input-container
                      flex
                      w-full
                      items-center
                      justify-center
                      rounded-xl
                      border-1 border-grey-200
                      py-4
                      font-semibold
                    "
                  >
                    <i class="fas fa-check-circle mr-2 text-xl lg:text-xl" />
                    Copied!
                  </span>
                </div>
                <copy-btn :disabled="!!dns.error" :data="dns.dns_value" @click="handleButtonClick('value')" />
              </div>
            </div>
          </template>
        </template>
      </div>
    </template>
  </t-accordion>
</template>

<script>
import breakpoints from '@/util/breakpoints';

import CopyBtn from '../CopyBtn';

export default {
  name: 'DsnAccordion',
  components: { CopyBtn },
  props: {
    dns: {
      type: Object,
      required: true,
      error: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      type_value: {
        type: String,
        default: '',
      },
      dns_value: {
        type: String,
        default: '',
      },
      name_value: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      is_valid: {
        type: Boolean,
        default: false,
      },
    },
  },
  data() {
    return {
      breakpoints,
      isNameCopied: false,
      isValueCopied: false,
      isOpen: !this.dns.is_valid,
      isCopied: false,
      valueCopyHeight: 0,
    };
  },
  methods: {
    handleButtonClick(type) {
      if (type === 'name') {
        this.isNameCopied = true;
      } else {
        this.isValueCopied = true;
        this.valueCopyHeight = this.$refs['valueInputRef'].clientHeight;
      }
      setTimeout(() => {
        this.isNameCopied = false;
        this.isValueCopied = false;
      }, 2000);
    },
    isLgOrXl() {
      const { comparisons } = this.breakpoints;
      return comparisons.eq_lg || comparisons.gt_lg;
    },
  },
  computed: {
    accordionClass() {
      return {
        container: 'bg-white relative rounded-xl border-1 border-grey-300',
        title: [this.borderBottom, 'w-full p-4 text-left'],
      };
    },
    getStatusImage() {
      return this.dns.is_valid
        ? this.$root.assetsURL + 'img/status_icon_success.svg'
        : this.$root.assetsURL + 'img/alert.svg';
    },
    responsiveInputSize() {
      return this.isLgOrXl() ? 'lg' : 'md';
    },
    borderBottom() {
      return { 'border-b': this.isOpen, 'border-b-1': this.isOpen, 'border-grey-300': this.isOpen };
    },
  },
};
</script>
<style src="./DnsAccordion.scss" lang="scss" />
