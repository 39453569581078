import axios from 'axios';

/**
 * Initiates a file download for an attachment
 *
 * @param url The url of file to be downloaded
 * @param filename The downloaded file's name
 */
export default async function downloadFile(url: string, filename: string) {
  await axios.get(url, { responseType: 'blob' }).then((res) => {
    const file = new File([res.data], filename);
    const url = window.URL.createObjectURL(file);

    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}
