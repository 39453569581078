<template>
  <api-token></api-token>
</template>

<script type="text/babel">
import ApiToken from '../Pages/Sections/ApiToken.vue';

export default {
  components: {
    ApiToken,
  },
};
</script>
