<template>
  <div id="wrapper" class="h-auto overflow-auto pb-8" @scroll="handleScroll">
    <div id="header" ref="header" class="top-0 z-50 w-full" :class="stickyHeaderClasses" data-test="settings-header">
      <slot name="header"></slot>
    </div>

    <div id="content" ref="content" class="h-full" data-test="settings-content">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import type { SettingsBaseLayout } from './types';

export default defineComponent({
  props: {
    scroll: {
      type: Boolean,
      default: false,
    },
  },
  data(): SettingsBaseLayout {
    return {
      isHeaderSticky: false,
      headerHeight: '80',
      content: null,
    };
  },
  computed: {
    stickyHeaderClasses() {
      return {
        sticky: !this.scroll,
        'border-b border-grey-300': this.isHeaderSticky,
      };
    },
  },
  mounted() {
    if (!this.scroll) {
      const header = this.$refs.header;
      this.headerHeight = header.clientHeight || this.headerHeight;
      this.content = this.$refs.content;
    }
  },
  methods: {
    handleScroll() {
      const contentPosition = this.content.getBoundingClientRect().top;
      const padding = 3;
      this.isHeaderSticky = this.headerHeight >= contentPosition - padding;
    },
  },
});
</script>
