export default {
  defaultIcon: 'fa-question-circle',
  defaultColor: 'rgba(0, 0, 0, 0.2)',
  EMAIL: {
    title: 'E-mail',
    icon: 'fa-envelope',
    adminUrl: '/admin/channels2/email',
  },
  SMS: {
    title: 'SMS',
    icon: 'fa-commenting',
    adminUrl: '/admin/channels2/sms',
  },
  CHAT: {
    title: 'Live chat',
    icon: 'fa-comments',
    adminUrl: '/admin/channels2/chat',
  },
  WHATSAPP: {
    title: 'WhatsApp',
    icon: 'fa-whatsapp',
    iconColor: '#075e54',
    adminUrl: '/admin/channels/list/whatsapp',
  },
  WA_BUSINESS: {
    title: 'WhatsApp Business',
    icon: 'fa-whatsapp',
    iconColor: '#075e54',
    adminUrl: '/admin/channels2/wa_business',
  },
  TELEGRAM: {
    title: 'Telegram',
    icon: 'fa-telegram',
    iconColor: '#0088cc',
    adminUrl: '/admin/channels2/telegram',
  },
  VOIP: {
    title: 'Voice',
    icon: 'fa-phone',
    adminUrl: '/admin/channels2/voip',
  },
  FACEBOOK: {
    title: 'Facebook',
    icon: 'fa-facebook',
    iconColor: '#4267B2',
    adminUrl: '/admin/channels2/facebook',
  },
  HELP_CENTER: {
    title: 'Help center',
    icon: 'fa-question-circle',
    adminUrl: '/help_center',
  },
  INSTAGRAM: {
    title: 'Instagram',
    icon: 'fa-instagram',
    adminUrl: '/admin/channels2/instagram',
    iconColor: '#833ab4',
  },
  GOOGLE_BUSINESS_MANAGER: {
    title: 'Google Business',
    icon: 'fa-google',
    adminUrl: '/admin/channels2/gbm',
    iconColor: '#833ab4',
  },
};
