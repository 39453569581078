import { formatPrice } from '@/util/currency';

import { MULTIPLIER_EURO_25, PLAN_NAME } from './constants';

import type { PlanId } from '@/types';

export const getPlanName = (planId: PlanId) => PLAN_NAME[planId];

export const getCarrierCostOptions = (currentCarrierCosts?: number) => {
  const options = [];
  for (let i = 0; i <= 40; i++) {
    const isCurrent = currentCarrierCosts === i;
    options.push({
      text: formatPrice(i * MULTIPLIER_EURO_25) + (isCurrent ? ' (Current)' : ''),
      value: i,
    });
    if (i >= 20) i += 3; // multiply by 100
  }
  return options;
};
