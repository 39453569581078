<template>
  <div :class="{ 'h-400p': this.isCsatChart }">
    <div v-if="metrics.length && report !== 'csats'" class="mb-4 inline-flex rounded-lg border border-grey-200 p-2">
      <div
        v-for="(metric, index) in metrics"
        :key="index"
        class="mx-1 cursor-pointer select-none rounded-lg px-3 py-1 font-bold"
        :class="{
          'cursor-pointer': currentMetric !== metric,
          'shadow-full bg-black text-white': currentMetric === metric,
        }"
        @click="currentMetric = metric"
      >
        {{ $t('reports.' + metric) }}
      </div>
    </div>
    <div class="chart-container">
      <canvas ref="report"></canvas>
    </div>
  </div>
</template>

<script>
import { Chart, TimeScale, LinearScale } from 'chart.js/auto';
import 'chartjs-adapter-spacetime';

import { map, max } from 'lodash';

import Util from '../Util/Util';

import 'gridjs/dist/theme/mermaid.css';

export default {
  props: {
    firstSetData: {},
    secondSetData: {},
    interval: {},
    metrics: {},
    report: {},
  },
  data() {
    return {
      chart: null,
      currentMetric: '',
      isCsatChart: this.report === 'csats',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.render();
    });
    if (this.metrics.length) {
      this.currentMetric = this.metrics[0];
    }
  },
  watch: {
    currentMetric() {
      this.render();
    },
    firstSetData() {
      this.render();
    },
    secondSetData() {
      this.render();
    },
  },
  computed: {
    mode() {
      return this.getModeByMetric(this.currentMetric);
    },
  },
  methods: {
    render() {
      if (this.chart) {
        this.chart.destroy();
      }
      var firstSet = this.firstSetData;
      Chart.register(TimeScale, LinearScale, {
        id: 'drawingPlugins',
        beforeDatasetsDraw: function (chart) {
          if (chart.tooltip._active && chart.tooltip._active.length) {
            var activePoint = chart.tooltip._active[0],
              ctx = chart.ctx,
              y_axis = chart.scales['y'],
              x = activePoint.element.x,
              topY = y_axis.top,
              bottomY = y_axis.bottom;
            // draw line
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 2;
            ctx.strokeStyle = '#252A47';
            ctx.stroke();
            ctx.restore();
          }
        },
        afterDraw: (chart) => {
          if (chart.scales['second-y-axis']) {
            var ctx = chart.ctx;
            ctx.save();
            ctx.font = '600 14px Inter';
            ctx.fillStyle = '#333335';
            var y = 10;

            ctx.textAlign = 'left';
            ctx.clearRect(0, 0, 200, 30);
            ctx.fillText('Satisfaction score', 5, y);

            ctx.textAlign = 'right';
            ctx.clearRect(chart.width - 150, 0, 300, 20);
            ctx.fillText('Surveys received', chart.width - 10, y);
            ctx.restore();
          }
        },
      });
      const highestCurrentMetric = this.isCsatChart ? this.metrics[0] : this.currentMetric;
      const highestPreviousMetric = this.isCsatChart ? this.metrics[1] : this.currentMetric;
      let highestCurrent = max(map(this.firstSetData, (c) => c[highestCurrentMetric]));
      let highestPrev = max(map(this.secondSetData, (c) => c[highestPreviousMetric]));

      if (highestPrev > highestCurrent) {
        highestCurrent = highestPrev;
      }

      let stepSize = null;

      if (this.mode === 'time') {
        // under 5 min, show per 30s
        if (highestCurrent < 300) {
          stepSize = 30;
        }
        // under 10 min, show per minute
        if (stepSize === null && highestCurrent < 600) {
          stepSize = 60;
        }
        // under one hour, show per 5min
        if (stepSize === null && highestCurrent < 3600) {
          stepSize = 300;
        }
        // // under 4 hour, show per 15 min
        // if (ticks === null && highestCurrent < 14400) {
        //     ticks = 900;
        // }
        // under 8 hour, show per 30min
        if (stepSize === null && highestCurrent < 28800) {
          stepSize = 1800;
        }
        // under 24 hour, show per hour
        if (stepSize === null && highestCurrent < 86400) {
          stepSize = 3600;
        }
        // or else, show per day
        if (stepSize === null) {
          stepSize = 86400;
        }
      }

      var chart = this.$refs.report.getContext('2d');

      var gradient = chart.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, 'rgba(172, 181, 209, 0.25)');
      gradient.addColorStop(1, 'rgba(172, 181, 209, 0)');
      this.chart = new Chart(chart, {
        type: 'line',

        options: {
          interaction: {
            intersect: !this.isCsatChart ? true : false,
            mode: !this.isCsatChart ? 'nearest' : 'index',
          },
          animation: {
            duration: 0,
          },
          layout: {
            padding: {
              top: this.isCsatChart ? 20 : 0,
            },
          },
          responsive: true,
          maintainAspectRatio: !this.isCsatChart,
          elements: {
            point: {
              hitRadius: 15,
              hoverRadius: 7,
            },
            line: {
              tension: 0.5,
            },
          },

          plugins: {
            legend: {
              position: this.isCsatChart ? 'top' : 'bottom',
              lineWidth: 0,
              labels: {
                defaultFontFamily: 'Inter',
                defaultFontSize: 15,
              },
            },
            tooltip: !this.isCsatChart
              ? {
                  backgroundColor: '#252A47',
                  titleFont: { family: 'Inter', size: 12, style: 'normal' },
                  titleColor: '#CBCBD1',

                  callbacks: {
                    label: (tooltipContext) => {
                      if (this.mode === 'time') {
                        return Util.secondsToString(tooltipContext.parsed.y, false);
                      } else {
                        return tooltipContext.parsed.y;
                      }
                    },
                    title: (tooltipContext) => {
                      if (tooltipContext[0].dataset.label === 'Previous period') {
                        return moment
                          .unix(this.secondSetData[tooltipContext[0].dataIndex].timestamp / 1000)
                          .format('LLLL');
                      } else {
                        return moment
                          .unix(this.firstSetData[tooltipContext[0].dataIndex].timestamp / 1000)
                          .format('LLLL');
                      }
                    },
                  },
                  bodyFont: { family: 'Inter', size: 24, weight: 'bold' },
                  xPadding: 10,
                  titleMarginBottom: 5,
                  cornerRadius: 4,
                  displayColors: false,
                }
              : {
                  // Disable the on-canvas tooltip
                  enabled: false,
                  position: 'nearest',

                  external: function (context) {
                    // Tooltip Element
                    let tooltipEl = document.getElementById('chartjs-tooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                      tooltipEl = document.createElement('div');
                      tooltipEl.id = 'chartjs-tooltip';
                      tooltipEl.innerHTML = "<div class='main'></div>";
                      document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                      tooltipEl.style.opacity = 0;
                      return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                      tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                      tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem) {
                      return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                      var firstSetInDataPoint =
                        tooltipModel.dataPoints && tooltipModel.dataPoints[0] && firstSet
                          ? firstSet[tooltipModel.dataPoints[0].dataIndex]
                          : null;
                      var titleLines = firstSetInDataPoint
                        ? moment(firstSetInDataPoint.timestamp).format('dddd, D MMMM YYYY')
                        : '';
                      var bodyLines = tooltipModel.body.map(getBody);
                      var scoresLine = bodyLines[0];
                      var surveysLine = bodyLines[1];
                      tooltipEl.classList.remove('survey');
                      tooltipEl.classList.remove('score');
                      const noSurveys = (surveysLine && surveysLine == 'Surveys: 0') || scoresLine == 'Score: 0';
                      if (noSurveys) {
                        tooltipEl.classList.add('survey');
                      } else {
                        tooltipEl.classList.add('score');
                      }

                      let innerHtml = '<div>';
                      innerHtml += !noSurveys ? "<div class='title'>" + titleLines + '</div>' : '';

                      innerHtml += "</div><div class='body'>";
                      if (noSurveys) {
                        innerHtml += '0 surveys';
                      } else {
                        bodyLines.forEach(function (body, i) {
                          const value = String(body).match(/\d+/)[0];
                          if (!noSurveys && body[0].includes('Surveys')) {
                            innerHtml += '<div class="card-message">' + value + ' surveys</div></div>';
                          } else if (body[0].includes('Score')) {
                            let uri;
                            let message;
                            if (value > 80) {
                              uri = 'very_satisfied_emoji';
                              message = 'Very satisfied';
                            } else if (value > 60 && value <= 80) {
                              uri = 'satisfied_emoji';
                              message = 'Satisfied';
                            } else if (value > 40 && value <= 60) {
                              uri = 'neutral_emoji';
                              message = 'Neutral';
                            } else if (value > 20 && value <= 40) {
                              uri = 'dissatisfied_emoji';
                              message = 'Unsatisfied';
                            } else if (value > 0 && value <= 20) {
                              uri = 'very_dissatisfied_emoji';
                              message = 'Very unsatisfied';
                            }
                            innerHtml +=
                              '<div class="score"><div>' +
                              value +
                              '%</div><img src="https://assets.trengo.com/release/img/csat/' +
                              uri +
                              '.svg"><div class="card-message">' +
                              message +
                              '</div></div>';
                          }
                        });
                      }

                      innerHtml += '</div>';

                      var tableRoot = tooltipEl.querySelector('.main');
                      tableRoot.innerHTML = innerHtml;
                    }

                    const position = context.chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                  },
                },
          },

          scales: {
            x: {
              grid: {
                display: this.isCsatChart ? true : false,
                tickBorderDash: [2, 2],
                color: '#EAEAEA',
              },
              ticks: {
                padding: 20,
                font: {
                  size: this.isCsatChart ? 12 : 16,
                  family: 'Inter',
                  weight: this.isCsatChart ? 500 : 600,
                },
                color: this.isCsatChart ? '#838389' : '#3F3F41',
                autoSkip: true,
                maxTicksLimit: 10,
              },
              type: 'time',
              time: {
                unit: this.interval,
                isoWeekday: true,
                tooltipFormat: 'LLLL',
                displayFormats: {
                  month: 'MMM',
                },
              },
              offset: this.isCsatChart,
            },

            y: {
              grid: {
                color: '#EAEAEA',
              },
              suggestedMax: this.isCsatChart ? 100 : 10,
              suggestedMin: 0,
              beginAtZero: true,
              ticks: {
                font: {
                  size: 14,
                  family: 'Inter',
                  weight: this.isCsatChart ? 500 : 600,
                },
                padding: this.isCsatChart ? 12 : 20,
                color: this.isCsatChart ? '#838389' : '#3F3F41',
                autoSkip: true,
                maxTicksLimit: this.isCsatChart ? 11 : 10,
                stepSize: stepSize,
                callback: (label) => {
                  if (this.mode === 'time') {
                    return Util.secondsToString(label, false, '');
                  }
                  if (this.isCsatChart) {
                    return label + '%';
                  } else {
                    if (Math.floor(label) === label) {
                      return label; // only round values
                    }
                  }
                },
              },
            },

            ...(this.isCsatChart && {
              'second-y-axis': {
                type: 'linear',
                position: 'right',
                suggestedMax: highestPrev < 10 ? 10 : highestPrev,
                suggestedMin: 0,
                beginAtZero: true,
                grid: {
                  display: false,
                },
                ticks: {
                  stepSize: Math.round(highestPrev / 9),
                  font: {
                    size: 14,
                    family: 'Inter',
                    weight: this.isCsatChart ? 500 : 600,
                  },
                  padding: 12,
                  color: '#838389',
                  count: 11,
                  callback: (label) => {
                    if (this.mode === 'time') {
                      return Util.secondsToString(label, false, '');
                    } else {
                      if (Math.floor(label) === label) {
                        return label; // only round values
                      }
                    }
                  },
                },
              },
            }),
          },
        },
        data: {
          labels: map(this.firstSetData, (s) => {
            return s.timestamp;
          }),
          datasets: this.isCsatChart
            ? [
                {
                  label: 'Score',
                  data: map(this.firstSetData, (v) => {
                    let s = v[this.metrics[0]];
                    if (!s) {
                      s = 0;
                    }
                    return s;
                  }),
                  borderColor: '#252A47',
                  backgroundColor: '#252A47',
                  fill: false,
                  borderWidth: 2,
                  pointRadius: 0,
                  pointHoverBackgroundColor: '#fff',
                  pointBackgroundColor: '#fff',
                  pointHoverBorderWidth: 2,
                  pointBorderWidth: 2,
                  pointHitRadius: 20,
                },
                {
                  label: 'Surveys',
                  yAxisID: 'second-y-axis',
                  data: map(this.secondSetData, (v) => {
                    let s = v[this.metrics[1]];
                    if (!s) {
                      s = 0;
                    }
                    return s;
                  }),
                  fill: false,
                  borderColor: '#ACB5D1',
                  backgroundColor: '#ACB5D1',
                  borderWidth: 1,
                  pointRadius: 0,
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderWidth: 2,
                  type: 'bar',
                  barThickness: 24,
                  pointHitRadius: 20,
                },
              ]
            : [
                {
                  label: 'Current period',
                  order: 1,
                  data: map(this.firstSetData, (v) => {
                    let s = v[this.currentMetric];
                    if (!s) {
                      s = 0;
                    }
                    return s;
                  }),
                  borderColor: '#252A47',
                  borderWidth: 2,
                  pointRadius: 3,
                  backgroundColor: gradient,
                  fill: true,
                  legend: {
                    position: 'bottom',
                    lineWidth: 0,
                  },
                  pointHoverBackgroundColor: '#fff',
                  pointBackgroundColor: '#fff',
                  pointHoverBorderWidth: 2,
                  pointBorderWidth: 2,
                },
                {
                  label: 'Previous period',
                  order: 2,
                  data: map(this.secondSetData, (v) => {
                    let s = v[this.currentMetric];
                    if (!s) {
                      s = 0;
                    }
                    return s;
                  }),
                  fill: false,
                  borderColor: '#D0D6E6',
                  borderWidth: 1,
                  pointRadius: 0,
                  legend: {
                    position: 'bottom',
                    lineWidth: 0,
                  },
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderWidth: 2,
                },
              ],
        },
      });
    },

    getModeByMetric(metric) {
      let time = ['avg_first_reply_time', 'avg_resolution_time'];
      if (time.includes(metric)) {
        return 'time';
      }
      return 'default';
    },
  },
};
</script>
<style lang="scss">
#tooltip {
  position: absolute;
}

#chartjs-tooltip .main {
  color: var(--color-white);
  border-radius: 4px;
  font-family: 'Inter', 'Arial', sans-serif;
}

#chartjs-tooltip.survey {
  .main {
    background-color: var(--color-galaxy-800);
  }

  .title {
    padding-top: 4px;
  }

  .body {
    padding: 0 8px 4px 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
  }
}

#chartjs-tooltip.score {
  .main {
    background-color: var(--color-galaxy-900);
  }

  .title {
    padding: 8px 12px 0 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--color-grey-400);
  }

  .body {
    padding: 0 8px 8px 8px;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    flex-direction: column;

    .score {
      display: flex;
      align-items: center;
    }

    img {
      width: 32px;
      height: 32px;
      padding: 0px 2px 0px 4px;
    }

    .card-message {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: var(--color-grey-100);
    }
  }
}

.chart-container {
  // with width 100% it doesnt resize for some reason
  width: 99%;
  height: 100%;
}
</style>
