<template>
  <step-one
    v-if="currentStep === 'scanQRCode'"
    :is-open="isOpen"
    :is-reset-flow="isResetFlow"
    :qr="qr"
    :secret="secret"
    @close="handleClose"
    @goToStep="handleTransition"
  />
  <step-two
    v-else-if="currentStep === 'enterVerificationCode'"
    :is-open="isOpen"
    @close="handleClose"
    @goToStep="handleTransition"
  />
  <step-three
    v-else-if="currentStep === 'success'"
    :is-open="isOpen"
    :is-reset-flow="isResetFlow"
    @close="handleClose"
    @goToStep="handleTransition"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import StepOne from './StepOne.vue';
import StepThree from './StepThree.vue';
import StepTwo from './StepTwo.vue';
import { SETUP_STEP } from '../constants';

import type { ModalData, SetupStep } from '../types';

const defaultCurrentStep = SETUP_STEP.ONE;

export default defineComponent({
  name: 'TwoFactorAuthSetupModal',
  emits: ['close'],
  components: {
    StepOne,
    StepTwo,
    StepThree,
  },
  data(): ModalData {
    return {
      currentStep: defaultCurrentStep,
    };
  },
  props: {
    isOpen: Boolean,
    isResetFlow: Boolean,
    secret: String,
    qr: String,
  },
  methods: {
    handleTransition(step: SetupStep) {
      this.currentStep = step;
    },
    handleClose() {
      this.currentStep = defaultCurrentStep;
      this.$emit('close');
    },
  },
});
</script>
