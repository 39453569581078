import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-test": "reporting-is-entitled",
  class: "flex flex-grow"
}
const _hoisted_2 = {
  key: 1,
  "data-test": "reporting-is-not-entitled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewReporting = _resolveComponent("NewReporting")!
  const _component_OldReporting = _resolveComponent("OldReporting")!
  const _component_reporting_entitlement_page = _resolveComponent("reporting-entitlement-page")!

  return ($options.isEntitled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($data.renderNewReporting)
          ? (_openBlock(), _createBlock(_component_NewReporting, { key: 0 }))
          : (_openBlock(), _createBlock(_component_OldReporting, { key: 1 }))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_reporting_entitlement_page)
      ]))
}