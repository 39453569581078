import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_2 = {
  class: "t-text-md mb-0 p-0 text-grey-800",
  "data-test": "ticket-field-title"
}
const _hoisted_3 = { class: "t-text-sm-emphasize m-0 p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_field_item = _resolveComponent("custom-field-item")!
  const _component_info_linear = _resolveComponent("info-linear")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!
  const _component_sidebar_dropdown = _resolveComponent("sidebar-dropdown")!

  return (_openBlock(), _createBlock(_component_sidebar_dropdown, {
    title: _ctx.$tc('ticket_sidebar.ticket_fields'),
    "collapse-key": "ticketFieldsDropdown",
    "data-test": "ticket-fields-dropdown"
  }, {
    default: _withCtx(() => [
      (_ctx.fields.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
              return (_openBlock(), _createElementBlock("div", {
                key: field.id,
                "data-test": "ticket-field-item-wrapper"
              }, [
                _createElementVNode("p", _hoisted_2, _toDisplayString(field.title), 1 /* TEXT */),
                _createVNode(_component_custom_field_item, {
                  class: "mb-2",
                  field: field,
                  onSave: ($event: any) => (_ctx.save(field))
                }, null, 8 /* PROPS */, ["field", "onSave"])
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createBlock(_component_t_inline_banner, {
            key: 1,
            type: "default"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_info_linear)
            ]),
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$tc('ticket_sidebar.no_ticket_fields_setup')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]))
}