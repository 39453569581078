import { sortBy } from 'lodash';

import { getRules } from '@/api';

import type { Rule } from '@/types';

export const fetchRules = async (page: number, paginate: boolean) => {
  try {
    const result = await getRules(page, paginate);
    if ('data' in result.data) {
      return result.data.data;
    } else {
      return sortBy(result.data, function (rule: Rule) {
        return rule.name.toLowerCase();
      });
    }
  } catch (error) {
    throw new Error('Error while fetching rules', error as ErrorOptions | undefined);
  }
};
