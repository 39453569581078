import { vm } from '@/ApplicationHandler';

import Automation from '../Automation';

const SetFlowbotTemplate = class {
  constructor() {
    this.title = vm.$t('widget_automation.page_enter_a_title');
    this.active = false;
    this.payload = {
      actions: [Automation.Action(Automation.actionTypes.setFlowbot)],
      conditions: [
        //new Automation.Condition(Automation.audienceConditionTypes.last_seen, false)
      ],
    };
  }
};

export default SetFlowbotTemplate;
