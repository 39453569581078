<template>
  <div>
    <steps
      class="md:ml-8"
      :active-step-number="setupStatusAtStepNumber"
      :steps="[
        { title: 'Number connected' },
        { title: 'Verify with Facebook business' },
        { title: 'Get approved by Whatsapp' },
        { title: 'Full access without limitations! 🎉' },
      ]"
    >
      <template #step_indicator_1>
        <div>
          <round-check-indicator :checked="setupStatusAtStepNumber > 1" />
        </div>
      </template>
      <template v-if="setupStatusAtStepNumber === 1" #step_1>
        <div class="font-medium">
          <div>You can now chat with your customers (max. 10 phone numbers) per day</div>
        </div>
      </template>

      <template #step_indicator_2>
        <round-check-indicator :checked="setupStatusAtStepNumber > 2" />
      </template>
      <template v-if="setupStatusAtStepNumber === 2" #step_2>
        <div>
          <div class="my-2 gap-2 font-medium md:grid">
            <div>Status:</div>
            <div>
              <atomic-status-indicator>Unverified</atomic-status-indicator>
            </div>
            <div>Reward:</div>
            <div>Chat with up to 1500 phone numbers</div>
            <div class="col-span-2 mt-2 text-center">
              <atomic-button btn-style="primary" size="sm" type="button" @click="goTo360dashboard">
                Start Facebook verification
              </atomic-button>
            </div>
          </div>
        </div>
      </template>

      <template #step_indicator_3>
        <div>
          <round-check-indicator :checked="setupStatusAtStepNumber > 3" />
        </div>
      </template>

      <template v-if="setupStatusAtStepNumber === 3" #step_3>
        <div>
          <div class="my-2 gap-2 font-medium md:grid">
            <div>Status:</div>
            <div>
              <atomic-status-indicator>Sandbox</atomic-status-indicator>
            </div>
            <div>Reward:</div>
            <div>Full access</div>
            <div class="col-span-2">
              <div class="mt-2 rounded bg-galaxy-100 px-4 py-2">Wait for Whatsapp to verify your request</div>
            </div>
          </div>
        </div>
      </template>

      <template #step_indicator_4>
        <div>
          <round-check-indicator :checked="setupStatusAtStepNumber > 4" />
        </div>
      </template>
      <template v-if="setupStatusAtStepNumber >= 4" #step_4>
        <div>
          <div>
            Try it out by sending a test message to
            <a href="#" class="font-semibold hover:underline" @click="sendWhatsAppMessage">{{ phoneNumber }}</a>
          </div>
          <atomic-button class="my-2" btn-style="primary" size="sm" type="button" @click="$router.push('/tickets')">
            Go to inbox
          </atomic-button>
        </div>
      </template>
    </steps>
  </div>
</template>

<script>
import AtomicStatusIndicator from './components/OldStatusIndicator';
import RoundCheckIndicator from './RoundCheckIndicator';
import Steps from './Steps';

export default {
  name: 'WhatsApp360DialogStatus',
  emits: ['reloadChannelIndexList'],
  props: {
    phoneNumber: {
      type: String,
      required: true,
    },
    channel: {
      type: Object,
      required: true,
    },
  },
  components: {
    Steps,
    RoundCheckIndicator,
    AtomicStatusIndicator,
  },
  computed: {
    setupStatusAtStepNumber() {
      switch (this.status) {
        case 'UNVERIFIED':
          return 2;
        case 'PENDING':
          return 3;
        case 'ACTIVE':
          return 5;
        default:
          return 0;
      }
    },
  },
  data() {
    return {
      status: null,
    };
  },
  mounted() {
    axios.get('/api/v2/wa_business_setup/' + this.channel.id).then((res) => {
      this.status = res.data?.status;
      if (this.status !== this.channel.status) {
        this.$emit('reloadChannelIndexList');
      }
    });
  },
  methods: {
    goTo360dashboard() {
      window.open('https://hub.360dialog.com/auth/login', '_blank', 'noopener');
    },
    sendWhatsAppMessage() {
      window.open('https://wa.me/' + this.phoneNumber, '_blank', 'noopener');
    },
  },
};
</script>

<style scoped></style>
