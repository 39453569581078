<script lang="ts">
import _ from 'lodash';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import Multiselect from 'vue-multiselect';

import { PERMISSION } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useUserStore } from '@/store/pinia';

import avatar from '../Avatar.vue';

export default defineComponent({
  props: {
    profile: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    type: {
      type: String,
      required: true,
      default: '',
    },
  },

  data() {
    return {
      options: [],
      contacts: [],
      selected: null,
      isLoading: false,
      attachMode: false,
      PERMISSION,
    };
  },

  components: {
    avatar,
    Multiselect,
  },

  computed: {
    ...mapStores(useUserStore),
    list() {
      var list = [];
      $.each(this.profile.contacts, (k, v) => {
        if (v.pivot.type == this.type) {
          list.push(v);
        }
      });
      return list;
    },
  },

  methods: {
    getIcon() {
      switch (this.type) {
        case 'WHATSAPP':
        case 'WA_BUSINESS':
          return 'fa fa-whatsapp';
        case 'TELEGRAM':
          return 'fa fa-paper-plane';
        case 'SMS':
          return 'far fa-sms';
        case 'EMAIL':
          return 'fa fa-envelope-o';
        case 'VOIP':
          return 'fa fa-phone';
        case 'FACEBOOK':
          return 'fa fa-facebook';
        case 'CHAT':
          return 'fa fa-comments-o';
        case 'GBM':
          return 'fa fa-google';
        case 'INSTAGRAM':
          return 'fa fa-instagram';
      }
    },

    limitText(count) {
      return `and ${count} other contacts`;
    },

    asyncFind: _.debounce(function (query) {
      this.isLoading = true;
      if (!query) {
        this.isLoading = false;
        return;
      }
      axios
        .get('/api/v2/contacts', { params: { channel_type: this.type, term: query, profile_id: this.profile.id } })
        .then((response) => {
          this.contacts = response.data.data;
          this.isLoading = false;
        });
    }, 250),

    onSelected(selected) {
      this.attach(selected);
      this.selected = null;
      this.attachMode = false;
      this.contacts = [];
    },

    attach(contact) {
      contact.pivot = {
        type: this.type,
      };
      this.profile.contacts.push(contact);

      axios
        .post('/api/v2/profiles/' + this.profile.id + '/contacts', { contact_id: contact.id, type: this.type })
        .then(() => {
          eventBus.$emit('profile.timeline.reload');
          eventBus.$emit('ticket.contact.' + contact.id + '.reload');
        });
    },

    detach(id) {
      this.profile.contacts.forEach((v, k) => {
        if (v.pivot && v.pivot.type == this.type && v.id == id) {
          this.profile.contacts.splice(k, 1);
        }
      });

      axios
        .delete('/api/v2/profiles/' + this.profile.id + '/contacts/' + id, { params: { type: this.type } })
        .then(() => {
          eventBus.$emit('profile.timeline.reload');
          eventBus.$emit('ticket.contact.' + id + '.reload');
        });
    },

    openContact(id) {
      this.$router.push('/contacts/' + id);
    },

    createTicket(contact, type) {
      let channel = this.$root.channels.filter((c) => {
        return c.type === type;
      })[0];

      if (channel == null) {
        return;
      }

      eventBus.$emit('modals.create-ticket.open', {
        contact: contact,
        channel: channel,
      });
    },

    callContact(contact) {
      eventBus.$emit('voip.call', {
        phone: '+' + contact.phone,
      });
    },

    toggleAttachMode() {
      this.attachMode = !this.attachMode;
      if (this.attachMode) {
        setTimeout(() => {
          this.$refs.multiselect.$el.focus();
        }, 0);
      }
    },
  },
});
</script>

<template>
  <div class="mb-4">
    <div class="flex">
      <h6 class="mb-4">
        <span class="inline-block-20px"><i :class="getIcon()"></i></span>
        {{ $t('profile.channel_section_title_' + type) }}
        <span class="text-grey-500">{{ $t('profile.channel_section_contacts') }}</span>
      </h6>
      <div class="ml-auto" style="margin-top: -2px">
        <a
          v-tooltip.top="$t('profile.channel_section_add_contact')"
          class="btn btn-icon btn-xs rounded bg-grey-200"
          @click="toggleAttachMode"
        >
          <i style="margin-top: -1px" class="material-icons text-sm text-grey-600">add</i>
        </a>
      </div>
    </div>
    <div v-show="attachMode" class="mb-4 flex items-center rounded-lg border-2 border-grey-200 p-4">
      <multiselect
        ref="multiselect"
        v-model="selected"
        label="display_name"
        :reset-after="true"
        :placeholder="
          $t('profile.channel_section_search_contact', {
            type: $t('profile.channel_section_title_' + type),
          })
        "
        track-by="id"
        open-direction="bottom"
        :options="contacts"
        :searchable="true"
        :loading="isLoading"
        :internal-search="false"
        :options-limit="300"
        :limit="3"
        :clear-on-select="true"
        :close-on-select="true"
        :limit-text="limitText"
        :max-height="600"
        :show-no-options="false"
        @select="onSelected"
        @search-change="asyncFind"
      >
        <template #clear="props">
          <div v-if="contacts.length" class="multiselect__clear" @mousedown.prevent.stop="clearAll(props.search)"></div>
        </template>
        <template #noResult>
          <span>No results found.</span>
        </template>
      </multiselect>
    </div>
    <div v-if="list.length > 0">
      <div v-for="contact in list" class="mb-2 flex items-center rounded-lg border-2 border-grey-200 p-4">
        <div @click="openContact(contact.id)">
          <avatar width="45" :color="contact.color" :abbr="contact.abbr" :image="contact.profile_image"></avatar>
        </div>
        <div class="ml-2 min-w-0 flex-1">
          <div class="flex">
            <h6 class="mb-0 pb-0" @click="openContact(contact.id)">{{ contact.full_name }}</h6>
            <div class="ml-auto">
              <a
                v-if="type == 'VOIP' && userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__CREATE)"
                v-tooltip.top="$t('profile.call_contact')"
                @click="callContact(contact)"
              >
                <i style="font-size: 16px" class="material-icons text-grey-600">phone</i>
              </a>
              <a
                v-if="type != 'CHAT' && userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__CREATE)"
                v-tooltip.top="$t('profile.add_ticket')"
                @click="createTicket(contact, type)"
              >
                <i style="font-size: 16px" class="material-icons text-grey-600">add</i>
              </a>
              <a v-tooltip.top="$t('profile.detach_contact')" @click="detach(contact.id)">
                <i style="font-size: 16px" class="material-icons text-grey-600">close</i>
              </a>
            </div>
          </div>
          <span class="text-ellipsis text-xs text-grey-600">
            <span v-if="contact.email != null">{{ contact.email }}</span>
            <span v-if="contact.is_phone == true">{{ contact.formatted_phone }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
