import { request } from '@/util';

import type { AutoReplyResponse } from '../types';
import type { AxiosPromise, AxiosResponse } from 'axios';

const base_url = '/api/v2/';

export function getAutoReplies(page: number, hasPagination: boolean): AxiosPromise<AutoReplyResponse> {
  return request(`${base_url}auto_reply_sets?pagination=${hasPagination}&page=${page}`, 'GET', {});
}

export function deleteAutoReplies(id: number): AxiosPromise<AxiosResponse> {
  return request(`${base_url}auto_reply_sets/${id}`, 'DELETE');
}
