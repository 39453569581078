<template>
  <a :href="path" target="_blank" class="open-external">
    <div
      class="image-message"
      :style="[{ 'background-image': loaded ? `url(${path})` : '', 'max-width': customMaxWidth }]"
    >
      <img :src="path" :alt="alt" style="visibility: hidden; display: inherit" />
      <i v-show="!loaded" class="fa fa-spin fa-spinner" style="font-size: 20px"></i>
    </div>
    <div v-if="caption" class="image-message-caption my-1 px-4 py-1">
      {{ caption }}
    </div>
  </a>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    caption: {
      type: String,
      default: '',
    },
    customMaxWidth: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      loaded: false,
    };
  },

  mounted() {
    var el = this;
    $('<img/>')
      .attr('src', this.path)
      .on('load', function () {
        $(this).remove(); // prevent memory leaks as @benweet suggested
        el.loaded = true;
      });
  },

  methods: {},
};
</script>
<style scoped>
.image-message {
  border-radius: 10px;
  background-color: #ccd6dd;
  overflow: hidden;
  max-width: 400px;
  max-height: 200px;
  background-position: center center;
  background-size: cover;
  /*line-height:200px;*/
  text-align: center;
  margin-bottom: 0 !important;
}

.image-message-caption {
  max-width: 400px;
}
</style>
