<template>
  <div class="setup-password">
    <logo-header />
    <div class="setup-password__content">
      <div class="setup-password__header">
        <t-thumbnail class="mx-auto mb-6" size="large">
          <key-linear />
        </t-thumbnail>

        <h2>{{ $t('login.setup_password_headline') }}, {{ getUserFirstName }}!</h2>
        <p>
          {{ $t('login.setup_password_subheadline') }}
        </p>
      </div>

      <div class="setup-password__form">
        <form method="POST" class="flex flex-col gap-4" @submit.prevent="save">
          <t-input-text
            id="setupPassword"
            ref="password"
            v-model="form.password"
            :has-error="!isPasswordValid"
            :type="isPasswordVisible ? 'text' : 'password'"
            :label="$t('general.password')"
            :placeholder="$t('general.choose_password')"
            :sub-label="$t('login.setup_password_input_sublabel')"
          >
            <template #end>
              <component
                :is="passwordIcon"
                v-tooltip.top="{ placement: 'top', content: passwordTooltip, delay: { show: 200, hide: 0 } }"
                class="setup-password__form-icon"
                size="1.25rem"
                @click="togglePassword"
              />
            </template>
          </t-input-text>

          <t-error-item v-if="!isPasswordValid" :text="$t('login.validation_message_length')" />

          <t-input-text
            id="setupPasswordConfirm"
            ref="confirm"
            v-model="form.password_confirmation"
            :has-error="!isPasswordConfirmValid"
            :type="isPasswordConfirmVisible ? 'text' : 'password'"
            :label="$t('general.confirm_password')"
            :placeholder="$t('general.repeat_password')"
          >
            <template #end>
              <component
                :is="confirmIcon"
                v-tooltip.top="{ placement: 'top', content: confirmTooltip, delay: { show: 200, hide: 0 } }"
                class="setup-password__form-icon"
                size="1.25rem"
                @click="togglePasswordConfirm"
              />
            </template>
          </t-input-text>

          <t-error-item v-if="!isPasswordConfirmValid" :text="$t('login.validation_message_password_match')" />

          <t-button btn-style="primary" class="setup-password__form-action">
            {{ $t('general.create_account') }}
          </t-button>
        </form>
      </div>
    </div>
    <help-center-button />
  </div>
</template>

<script lang="ts">
import { EyeLinear, EyeSlashLinear, ExternalLinkLinear, KeyLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import HelpCenterButton from '@/components/common/HelpCenterButton';
import LogoHeader from '@/components/common/PageHeaders/LogoHeader';

import { setupPassword } from './api';

export default defineComponent({
  name: 'SetupPassword',
  components: {
    LogoHeader,
    ExternalLinkLinear,
    EyeLinear,
    EyeSlashLinear,
    HelpCenterButton,
    KeyLinear,
  },
  data() {
    return {
      isPasswordVisible: false,
      isPasswordConfirmVisible: false,
      isPasswordValid: true,
      isPasswordConfirmValid: true,
      isSaving: false,
      form: {
        password: '',
        password_confirmation: '',
      },
    };
  },
  computed: {
    getUserFirstName() {
      return this.$root.user.first_name;
    },
    confirmIcon() {
      return this.isPasswordConfirmVisible ? 'eye-slash-linear' : 'eye-linear';
    },
    passwordIcon() {
      return this.isPasswordVisible ? 'eye-slash-linear' : 'eye-linear';
    },
    passwordTooltip() {
      return this.isPasswordVisible ? this.$t('general.hide_password') : this.$t('general.show_password');
    },
    confirmTooltip() {
      return this.isPasswordConfirmVisible ? this.$t('general.hide_password') : this.$t('general.show_password');
    },
  },
  methods: {
    togglePassword() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    togglePasswordConfirm() {
      this.isPasswordConfirmVisible = !this.isPasswordConfirmVisible;
    },
    validate() {
      if (this.form.password.trim().length < 8) {
        this.isPasswordValid = false;
      } else {
        this.isPasswordValid = true;
      }

      if (this.form.password !== this.form.password_confirmation && this.isPasswordValid) {
        this.isPasswordConfirmValid = false;
      } else {
        this.isPasswordConfirmValid = true;
      }
    },
    async save() {
      if (this.isSaving) {
        return;
      }
      this.validate();

      if (this.isPasswordValid && this.isPasswordConfirmValid) {
        this.isSaving = true;
        try {
          await setupPassword(this.form);
          window.location.href = '/tickets';
        } finally {
          this.isSaving = false;
        }
      }
    },
  },
});
</script>

<style scoped lang="scss" src="./SetupPassword.scss" />
