import { request } from '@/util';

import type { Chatbot, ChatbotsResponse } from '@/types/chatbots';
import type { AxiosPromise, AxiosResponse } from 'axios';

const api_url = '/api/v2/chatbot';

export function getChatbots(page: number, pagination: boolean): AxiosPromise<ChatbotsResponse | Chatbot[]> {
  return request(`${api_url}?pagination=${pagination}&page=${page}`, 'GET', {});
}

export function deleteChatbot(id: number): AxiosPromise<AxiosResponse> {
  return request(`${api_url}/${id}`, 'DELETE');
}
