<template>
  <div class="block h-screen overflow-y-scroll md:h-full">
    <div class="mx-4 flex flex-col items-start px-0 pb-40 pt-20 leading-none md:px-2 md:pb-20 lg:mx-20">
      <t-feature-icon :icon="FEATURE_ICON.WIDGET" class="mb-8" />

      <div class="settings-landing-content">
        <h1 class="mb-3 max-w-full text-4xl font-bold text-grey-800 xl:max-w-md">
          {{ $t('widget_automation.page_title_widget_automation') }}
        </h1>
        <t-badge
          id="upgrade-badge"
          variant="upgrade"
          :text="$t('entitlement.upgrade_to_plan_long', { plan: minimumRequiredPlan })"
        >
          <template #icon><arrow-top-circle-linear size="1.25rem" /></template>
        </t-badge>
        <t-unordered-list :items="list" class="mt-8 max-w-full xl:max-w-md" size="lg"></t-unordered-list>
        <section class="mt-8 flex w-full flex-col md:w-auto xl:flex-row">
          <t-button
            v-if="hasPermissionToUpgrade"
            btn-style="primary"
            size="md"
            class="mb-4 mr-0 xl:mb-0 xl:mr-4"
            @click="$router.push('/admin/subscription_settings')"
          >
            <div class="flex items-center justify-center">
              <arrow-top-circle-linear size="1.25rem" class="mr-1" />
              {{ $t('entitlement.upgrade_to_plan', { plan: minimumRequiredPlan }) }}
            </div>
          </t-button>

          <p v-else class="t-text-sm mt-3 text-grey-700">
            {{ $t('admin_inboxes.ask_your_administrator_to_upgrade') }}
          </p>
        </section>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ExternalLinkLinear, ArrowTopCircleLinear } from '@trengo/trengo-icons';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import TFeatureIcon from '@/components/common/TFeatureIcon';
import { FEATURE, PERMISSION, FEATURE_ICON } from '@/Configs/Constants';
import { useEntitlementsStore, useUserStore } from '@/store/pinia';

export default defineComponent({
  name: 'WidgetAutomationEntitlementPage',
  components: { ArrowTopCircleLinear, ExternalLinkLinear, TFeatureIcon },

  props: {
    widgetAutomationsCount: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      list: [
        this.$t('widget_automation.entitlement_item_1'),
        this.$t('widget_automation.entitlement_item_2'),
        this.$t('widget_automation.entitlement_item_3'),
      ],
      FEATURE_ICON,
    };
  },

  computed: {
    ...mapStores(useEntitlementsStore, useUserStore),
    minimumRequiredPlan() {
      return this.entitlementsStore?.getMinimumRequiredPlanFor(
        FEATURE.CUSTOMER_SERVICE__AUTOMATION__WIDGET,
        this.widgetAutomationsCount
      );
    },
    hasPermissionToUpgrade() {
      return this.userStore.hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE);
    },
  },
});
</script>

<style scoped lang="scss">
#upgrade-badge {
  @apply t-text-sm-emphasize h-8 w-fit px-3 py-[6px] #{!important};
}
</style>
