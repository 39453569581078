import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6139b262"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block h-screen overflow-y-scroll md:h-full" }
const _hoisted_2 = { class: "mx-4 flex flex-col items-start px-0 pb-40 pt-20 leading-none md:px-2 md:pb-20 lg:mx-20" }
const _hoisted_3 = { class: "settings-landing-content" }
const _hoisted_4 = { class: "mb-3 max-w-full text-4xl font-bold text-grey-800 xl:max-w-md" }
const _hoisted_5 = { class: "mt-3 text-base font-medium text-grey-700" }
const _hoisted_6 = { class: "mt-8 flex w-full flex-col md:w-auto xl:flex-row" }
const _hoisted_7 = { class: "flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_feature_icon = _resolveComponent("t-feature-icon")!
  const _component_arrow_top_circle_linear = _resolveComponent("arrow-top-circle-linear")!
  const _component_t_badge = _resolveComponent("t-badge")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_external_link_linear = _resolveComponent("external-link-linear")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_t_feature_icon, {
        icon: _ctx.FEATURE_ICON.WIDGET,
        class: "mb-8"
      }, null, 8 /* PROPS */, ["icon"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('admin_inboxes.web_widgets')), 1 /* TEXT */),
        (!_ctx.isEntitled)
          ? (_openBlock(), _createBlock(_component_t_badge, {
              key: 0,
              id: "upgrade-badge",
              variant: "upgrade",
              text: _ctx.entitlementUpgradeBadgeText
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_arrow_top_circle_linear, { size: "1.25rem" })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["text"]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('admin_inboxes.web_widgets_intro')), 1 /* TEXT */),
        _createElementVNode("section", _hoisted_6, [
          (_ctx.isEntitled)
            ? (_openBlock(), _createBlock(_component_t_button, {
                key: 0,
                size: "md",
                class: "open-external mb-4 mr-0 xl:mb-0 xl:mr-4",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/admin/web_widgets/create')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('admin_inboxes.add_web_widget')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          (!_ctx.isEntitled)
            ? (_openBlock(), _createBlock(_component_t_button, {
                key: 1,
                "btn-style": "primary",
                size: "md",
                class: "mb-4 mr-0 xl:mb-0 xl:mr-4",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'subscription' })))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_arrow_top_circle_linear, {
                      size: "1.25rem",
                      class: "mr-1"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.entitlementUpgradeButtonText), 1 /* TEXT */)
                  ])
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_t_button, {
            size: "md",
            "btn-style": "secondary",
            href: "https://help.trengo.com/en/articles/68462-add-a-website-widget-to-your-trengo-inbox",
            rel: "noreferrer noopener",
            target: "_blank",
            class: "open-external flex flex-row items-center"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('admin_inboxes.learn_more')) + " ", 1 /* TEXT */),
              _createVNode(_component_external_link_linear, { class: "ml-1" })
            ]),
            _: 1 /* STABLE */
          })
        ])
      ])
    ])
  ]))
}