<template>
  <div class="pt-4">
    <div
      v-if="error"
      class="pointer break-words border-l-4 border-orange-light bg-orange-lightest p-4 text-orange-darker"
      role="alert"
      @click="$router.push('/admin/developers/plugins/' + plugin.id)"
    >
      <p>{{ error }}</p>
    </div>
    <loader v-if="loading"></loader>
    <div v-else>
      <div v-if="found">
        <div style="height: 27px; border-radius: 0px" class="mb-4">
          <div class="flex items-center justify-center" style="height: 18px">
            <span
              class="text-uppercase ticket-sub-filter flex-1 border-b-2 text-center text-sm text-grey-600"
              :class="tab == 'contact' ? 'border-green' : ''"
              @click="tab = 'contact'"
            >
              Contact
            </span>
            <span
              class="text-uppercase ticket-sub-filter flex-1 border-b-2 text-center text-sm text-grey-600"
              :class="tab == 'notes' ? 'border-green' : ''"
              @click="tab = 'notes'"
            >
              Engagements
            </span>
            <span
              class="text-uppercase ticket-sub-filter flex-1 border-b-2 text-center text-sm text-grey-600"
              :class="tab == 'deals' ? 'border-green' : ''"
              @click="tab = 'deals'"
            >
              Deals
            </span>
          </div>
        </div>

        <div v-if="tab == 'contact'">
          <contact
            :ticket="ticket"
            :plugin="plugin"
            :contact="contact"
            :attributes="attributes"
            :settings="settings"
            :has-property="hasProperty"
            :get-status="getStatus"
            :get-owner="getOwner"
            :owners="owners"
            @call-parent-loadData-method="loadData"
          ></contact>
        </div>
        <div v-if="tab == 'notes'">
          <notes :ticket="ticket" :plugin="plugin" :get-owner="getOwner" :owners="owners" :settings="settings"></notes>
        </div>
        <div v-if="tab == 'deals'">
          <deals
            :ticket="ticket"
            :plugin="plugin"
            :attributes="attributes"
            :get-owner="getOwner"
            :owners="owners"
            :settings="settings"
            :get-deal-stages="getDealStages"
            :get-deal-status="getDealStatus"
            :get-deal-pipelines="getDealPipelines"
            :has-property="hasProperty"
          ></deals>
        </div>
      </div>
      <div v-else>
        <div v-show="!create && !linkContact" class="text-muted text-center">
          <div class="box">
            <div class="box-body">
              <span>No matches found.</span>
              <div>
                <button class="btn white btn-xs mt-4" @click="create = true">Create contact</button>
                <button class="btn white btn-xs mt-4" @click="linkContact = true">Link contact</button>
              </div>
            </div>
          </div>
        </div>

        <div v-show="create">
          <div class="box">
            <div class="box-body">
              <form @submit.prevent="createContact">
                <div class="form-group">
                  <label class="text-muted mb-1">Company name</label>
                  <input
                    v-model="contactForm.company"
                    type="text"
                    class="form-control mb-2"
                    placeholder="Company name"
                  />
                </div>
                <div v-show="settings.name" class="form-group">
                  <label class="text-muted mb-1">Name</label>
                  <input v-model="contactForm.name" type="text" class="form-control mb-2" placeholder="Name" />
                </div>
                <div v-show="settings.firstName" class="form-group">
                  <label class="text-muted mb-1">First name</label>
                  <input
                    v-model="contactForm.first_name"
                    type="text"
                    class="form-control mb-2"
                    placeholder="First name"
                  />
                </div>
                <div v-show="settings.lastName" class="form-group">
                  <label class="text-muted mb-1">Last name</label>
                  <input
                    v-model="contactForm.last_name"
                    type="text"
                    class="form-control mb-2"
                    placeholder="Last name"
                  />
                </div>
                <div class="form-group">
                  <label class="text-muted mb-1">Email</label>
                  <input v-model="contactForm.email" type="text" class="form-control mb-2" placeholder="Email" />
                </div>
                <div class="form-group">
                  <label class="text-muted mb-1">Phone</label>
                  <input v-model="contactForm.phone" type="text" class="form-control mb-2" placeholder="Phone" />
                </div>
                <div v-show="settings.hasContactStatus" class="form-group">
                  <label class="text-muted mb-1">Status</label>
                  <select v-model="contactForm.status" class="form-control mb-2">
                    <option v-for="(v, k) in attributes.contact_statuses" :value="k">{{ v }}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="text-muted mb-1">Owner</label>
                  <select v-model="contactForm.owner_id" class="form-control mb-2">
                    <option v-for="owner in owners" :value="owner.owner_id">
                      {{ owner.full_name }} ({{ owner.email }})
                    </option>
                  </select>
                </div>
                <button type="submit" class="btn success btn-block" :disabled="saving" :class="{ loader: saving }">
                  Create contact
                </button>
                <div class="text-muted mt-2 text-center">
                  <a @click="create = false">Cancel</a>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div v-show="linkContact">
          <div class="box">
            <div class="box-body">
              <form @submit.prevent="linkContactSubmit">
                <label class="text-muted mb-1">Contact</label>

                <multiselect
                  v-model="contactLinkForm"
                  label="email"
                  select-label=""
                  placeholder="Search"
                  :allow-empty="false"
                  deselect-label=""
                  track-by="contact_id"
                  open-direction="bottom"
                  :options="contacts"
                  :searchable="true"
                  :loading="searchingContacts"
                  :internal-search="false"
                  :options-limit="300"
                  :limit="3"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :max-height="600"
                  :multiple="false"
                  @search-change="contactFind"
                >
                  <!--<template slot="clear" slot-scope="props">-->
                  <!--<div class="multiselect__clear"-->
                  <!--v-if="profiles.length"-->
                  <!--@mousedown.prevent.stop="clearAll(props.search)"></div>-->
                  <!--</template>-->
                  <template #noResult>
                    <span>No results found for search query</span>
                  </template>
                  <template #option="prop">
                    <div>
                      <template v-if="settings.name">
                        {{ prop.option.name }}
                      </template>
                      <template v-else>{{ prop.option.first_name }} {{ prop.option.last_name }}</template>
                      {{ prop.option.company }} {{ prop.option.email }}
                    </div>
                  </template>
                </multiselect>
                <button type="submit" class="btn success btn-block mt-2">Link contact</button>
                <div class="text-muted mt-2 text-center">
                  <a @click="linkContact = false">Cancel</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Multiselect from 'vue-multiselect';

import eventBus from '@/eventBus';

import Contact from './Contact';
import Deals from './Deals';
import Notes from './Notes';
import Loader from '../Loader';

export default {
  name: 'Generic',
  emits: ['unauthorized', 'update-external-url'],
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    plugin: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  components: {
    Multiselect,
    Notes,
    Contact,
    Deals,
    Loader,
  },

  data() {
    return {
      contactLinkForm: {},
      contacts: [],
      orders: [],
      searchingContacts: false,
      saving: false,
      found: false,
      loading: false,
      create: false,
      linkContact: false,
      contactForm: {},
      owners: [],
      contact: {
        properties: {},
      },
      notes: [],
      deals: [],
      dealProperties: {},
      dealPipelines: [],

      tab: 'contact',

      noteForm: {
        body: '',
        type: 'NOTE',
        ownerId: null,
      },
      writeNote: false,
      contactProperties: [],
      attributes: {
        contact_statuses: [],
        contact_stages: [],
        deal_stages: [],
        deal_pipelines: [],
        deal_statuses: [],
        company: {
          company_domain: null,
        },
        account_details: {},
      },
      error: null,
    };
  },
  async created() {
    this.tab = (await this.$tStorage.getItem('crm_tab')) || 'contact';
  },
  watch: {
    auth() {
      this.loadData();
    },

    tab(t) {
      this.$tStorage.setItem('crm_tab', t);
    },

    ticket() {
      this.loadData();
    },
    create() {
      let isPhone = this.ticket.contact.phone != null && this.ticket.contact.phone.replace(/\D+/g, '').length == 11;

      if (this.settings.name) {
        this.contactForm = {
          name: this.ticket.contact.full_name,
          email: this.ticket.contact.email,
          phone: isPhone ? this.ticket.contact.phone : '',
        };
      } else {
        this.contactForm = {
          first_name: this.ticket.contact.full_name,
          email: this.ticket.contact.email,
          phone: isPhone ? this.ticket.contact.phone : '',
        };
      }
    },
  },

  mounted() {
    this.loadData();
    axios.get('/api/v2/integrations/crm/owners?plugin_id=' + this.plugin.id).then((r) => (this.owners = r.data));
    axios
      .get('/api/v2/integrations/crm/attributes?plugin_id=' + this.plugin.id)
      .then((r) => (this.attributes = r.data));

    eventBus.$off('integrations.error.' + this.plugin.id);
    eventBus.$on('integrations.error.' + this.plugin.id, (message) => {
      this.error = message;
      setTimeout(() => (this.error = null), 5000);
    });
  },

  methods: {
    linkContactSubmit() {
      let data = {
        crm_contact_id: this.contactLinkForm.contact_id,
      };
      axios
        .post(
          '/api/v2/integrations/crm/attach_contacts?plugin_id=' +
            this.plugin.id +
            '&contact_id=' +
            this.ticket.contact.id,
          data
        )
        .then((res) => {
          this.loadData();
        });
    },

    getOwner(id) {
      return this.owners.find((s) => s.owner_id == id) || {};
    },

    getStatus(id) {
      return this.attributes.contact_statuses[id];
    },

    getDealStatuses() {
      return this.attributes.deal_statuses;
    },

    getContactStage() {},

    getStage(id) {
      return this.getStages()[id];
    },

    getStages() {
      return this.attributes.contact_stages;
    },

    getDealStage(id, pipeline = 'default') {
      if (typeof this.attributes.deal_stages[pipeline] === 'undefined') {
        return false;
      }

      return this.attributes.deal_stages[pipeline][id];
    },

    getDealStages(pipeline = 'default') {
      return this.attributes.deal_stages[pipeline];
    },

    getDealStatus(id) {
      return this.getDealStatuses()[id];
    },

    getDealPipelines() {
      return this.attributes.deal_pipelines;
    },

    createContact() {
      if (this.saving) return;
      this.saving = true;
      axios
        .post(
          '/api/v2/integrations/crm/contacts?plugin_id=' + this.plugin.id + '&contact_id=' + this.ticket.contact.id,
          this.contactForm
        )
        .then((res) => {
          this.loadData();
          this.saving = false;
        })
        .catch(() => {
          this.saving = false;
        });
    },

    loadData() {
      this.deals = [];
      this.customer = {};
      this.orders = [];
      this.loading = true;

      axios
        .get('/api/v2/integrations/crm?plugin_id=' + this.plugin.id + '&ticket_id=' + this.ticket.id)
        .then((res) => {
          this.loading = false;

          if (res.data.unauthorized) {
            this.$emit('unauthorized');
            return;
          }

          if (!res.data.contact) {
            this.found = false;
          } else {
            this.contact = res.data.contact;
            this.contactProperties = res.data.properties;
            this.$emit('update-external-url', this.contact.url);
            this.found = true;
          }
        })
        .catch((e) => {
          this.found = false;
          this.loading = false;
        });
    },

    contactFind: _.debounce(function (query) {
      this.searchingContacts = true;
      if (query === '') {
        this.searchingContacts = false;
        return;
      }

      axios
        .get('/api/v2/integrations/crm/contacts?plugin_id=' + this.plugin.id, { params: { term: query } })
        .then((response) => {
          this.contacts = response.data ? response.data : [];
          this.searchingContacts = false;
        });
    }, 300),

    hasProperty(obj, name) {
      return obj.hasOwnProperty(name) && obj[name] != '';
    },
  },
};
</script>
