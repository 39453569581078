import type { MIDDLEWARE_ERROR } from '@/Configs/Constants';

type Message = valueof<typeof MIDDLEWARE_ERROR>;

export class MiddlewareError extends Error {
  constructor(public message: Message) {
    super();
    this.message = message;
  }
}
