<template>
  <div>
    <FileEditor :element="element" :accept="'image/jpeg,image/png,image/bmp'"></FileEditor>
    <TextEditor
      v-bind="$props"
      title="Caption"
      :placeholder="$t('flowbot.step_image_optional_image_caption_placeholder')"
    ></TextEditor>
  </div>
</template>
<script>
import FileEditor from './File';
import TextEditor from './Text';

export default {
  props: ['element', 'customFields'],

  components: {
    FileEditor,
    TextEditor,
  },

  methods: {},
};
</script>
