<template>
  <modal-base
    v-model="computedValue"
    :title="$t('getting_started.title_add_a_channel')"
    :sub-title="$t('getting_started.connect_with_your_customers')"
  >
    <template #content>
      <div class="mb-6">
        <channels-list />
      </div>
    </template>
  </modal-base>
</template>

<script>
import ModalBase from '../../ModalBase';
import ChannelsList from '../ChannelsList';
export default {
  name: 'AddChannelModal',
  emits: ['update:modelValue'],
  components: { ChannelsList, ModalBase },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
