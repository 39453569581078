<template>
  <span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-stroke">
      <path
        d="M2.75 9.15C2.75 6.90979 2.75 5.78968 3.18597 4.93404C3.56947 4.18139 4.18139 3.56947 4.93404 3.18597C5.78968 2.75 6.90979 2.75 9.15 2.75H14.85C17.0902 2.75 18.2103 2.75 19.066 3.18597C19.8186 3.56947 20.4305 4.18139 20.814 4.93404C21.25 5.78968 21.25 6.90979 21.25 9.15V14.85C21.25 17.0902 21.25 18.2103 20.814 19.066C20.4305 19.8186 19.8186 20.4305 19.066 20.814C18.2103 21.25 17.0902 21.25 14.85 21.25H9.15C6.90979 21.25 5.78968 21.25 4.93404 20.814C4.18139 20.4305 3.56947 19.8186 3.18597 19.066C2.75 18.2103 2.75 17.0902 2.75 14.85V9.15Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="svg-stroke"
      />
      <circle
        cx="12"
        cy="9.91667"
        r="2.66667"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="svg-stroke"
      />
      <path
        d="M16 16.5C16 14.7909 14.2091 13.25 12 13.25C9.79086 13.25 8 14.7909 8 16.5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="svg-stroke"
      />
      <path d="M0.75 8C0.75 7.44772 1.19772 7 1.75 7H2.75V9H1.75C1.19772 9 0.75 8.55228 0.75 8V8Z" class="svg-fill" />
      <path
        d="M0.75 12C0.75 11.4477 1.19772 11 1.75 11H2.75V13H1.75C1.19772 13 0.75 12.5523 0.75 12V12Z"
        class="svg-fill"
      />
      <path
        d="M0.75 16C0.75 15.4477 1.19772 15 1.75 15H2.75V17H1.75C1.19772 17 0.75 16.5523 0.75 16V16Z"
        class="svg-fill"
      />
    </svg>

    <span class="icon-active">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_9260_95548)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.883 2L14.883 2C15.9752 2 16.8406 1.99999 17.5383 2.05699C18.2517 2.11528 18.8553 2.23688 19.4065 2.51772C20.3002 2.97312 21.0269 3.69978 21.4823 4.59355C21.7631 5.14472 21.8847 5.74835 21.943 6.46174C22 7.15935 22 8.02485 22 9.11698L22 14.883C22 15.9752 22 16.8407 21.943 17.5383C21.8847 18.2517 21.7631 18.8553 21.4823 19.4065C21.0269 20.3002 20.3002 21.0269 19.4065 21.4823C18.8553 21.7631 18.2517 21.8847 17.5383 21.943C16.8406 22 15.9752 22 14.883 22L9.11698 22C8.02484 22 7.15935 22 6.46173 21.943C5.74834 21.8847 5.14472 21.7631 4.59354 21.4823C3.69977 21.0269 2.97312 20.3002 2.51772 19.4065C2.23688 18.8553 2.11527 18.2517 2.05699 17.5383C1.99999 16.8407 1.99999 15.9752 2 14.8831L2 14.883L2 9.117C1.99999 8.02485 1.99999 7.15936 2.05699 6.46174C2.11527 5.74835 2.23688 5.14472 2.51772 4.59355C2.97312 3.69978 3.69977 2.97312 4.59354 2.51772C5.14472 2.23688 5.74834 2.11528 6.46173 2.05699C7.15934 1.99999 8.02483 2 9.11695 2L9.117 2L14.883 2ZM12 8C10.9414 8 10.0833 8.85812 10.0833 9.91667C10.0833 10.9752 10.9414 11.8333 12 11.8333C13.0585 11.8333 13.9166 10.9752 13.9166 9.91667C13.9166 8.85812 13.0585 8 12 8ZM8.58331 9.91667C8.58331 8.0297 10.113 6.5 12 6.5C13.887 6.5 15.4166 8.0297 15.4166 9.91667C15.4166 11.1391 14.7746 12.2117 13.8094 12.8154C15.4851 13.4169 16.75 14.8188 16.75 16.5C16.75 16.9142 16.4142 17.25 16 17.25C15.5858 17.25 15.25 16.9142 15.25 16.5C15.25 15.3319 13.9343 14 12 14C10.0657 14 8.75 15.3319 8.75 16.5C8.75 16.9142 8.41421 17.25 8 17.25C7.58579 17.25 7.25 16.9142 7.25 16.5C7.25 14.8188 8.51491 13.4169 10.1906 12.8155C9.22534 12.2117 8.58331 11.1391 8.58331 9.91667Z"
            fill="white"
          />
          <path d="M0.75 8C0.75 7.44772 1.19772 7 1.75 7H2.75V9H1.75C1.19772 9 0.75 8.55228 0.75 8Z" fill="white" />
          <path
            d="M0.75 12C0.75 11.4477 1.19772 11 1.75 11H2.75V13H1.75C1.19772 13 0.75 12.5523 0.75 12Z"
            fill="white"
          />
          <path
            d="M0.75 16C0.75 15.4477 1.19772 15 1.75 15H2.75V17H1.75C1.19772 17 0.75 16.5523 0.75 16Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_9260_95548">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  </span>
</template>
