export default {
  methods: {
    joinTrengoVideoRoom(roomId) {
      let popup;
      if (!window.isLoadedFromApp && !window.isElectron) {
        popup = window.open();
      }

      axios.get(`/api/v2/video_calling/rooms/${roomId}`).then((r) => {
        if (r.data?.status === 'ended') {
          this.flashWarning('This meeting has ended, please start a new meeting.');
          popup.close();
          return;
        }

        if (window.isLoadedFromApp) {
          parent.postMessage(
            'app-browse-url:' + encodeURI(`${window.VIDEO_CALLING_URL}/${r.data?.participant_token}`),
            '*'
          );
        } else if (window.isElectron) {
          shell.openExternal(`${window.VIDEO_CALLING_URL}/${r.data?.participant_token}`);
        } else {
          popup.location = `${window.VIDEO_CALLING_URL}/${r.data?.participant_token}`;
        }
      });
    },
  },
};
