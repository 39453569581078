import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const teamsRoutes: RouteRecord[] = [
  {
    path: '/admin/teams',
    component: require('../../components/Teams/Index').default,
    meta: { permissionRequired: PERMISSION.PERMISSIONS__TEAMS__MANAGE },
    children: [
      {
        path: '/admin/teams/create',
        component: require('../../components/PageNotFound').default,
        meta: { permissionRequired: PERMISSION.PERMISSIONS__TEAMS__MANAGE },
      },
      {
        path: '/admin/teams/:id',
        component: require('../../components/Teams/pages/Edit').default,
        meta: { permissionRequired: PERMISSION.PERMISSIONS__TEAMS__MANAGE },
      },
      {
        path: '',
        component: require('../../components/AdminIndexBox').default,
        props: { boxName: 'teams' },
        meta: { permissionRequired: PERMISSION.PERMISSIONS__TEAMS__MANAGE },
      },
    ],
  },
];

export default teamsRoutes;
