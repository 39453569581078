import axios from 'axios';

import { request } from '@/util/request';

import { ENDPOINT } from '../constants';

import type { TransactionMonth } from '@/types';
import type { AxiosPromise } from 'axios';

type AuthoriseDebitTransactionPayload = {
  category: 'SMS' | 'VOIP' | 'WHATSAPP';
  currency: 'EUR' | 'USD';
  amount: number;
};

type DownloadTransactionPayload = {
  month: string;
};

type AuthoriseDebitTransactionResponse = AxiosPromise<{
  is_authorised: boolean;
}>;

export function authoriseDebitTransaction(
  payload: AuthoriseDebitTransactionPayload
): AuthoriseDebitTransactionResponse {
  return request(ENDPOINT.AUTHORISE_DEBIT_TRANSACTION, 'POST', payload);
}

export function fetchTransactions(): AxiosPromise<TransactionMonth[]> {
  return request(ENDPOINT.TRANSACTIONS, 'GET');
}

export function downloadTransactions(payload: DownloadTransactionPayload): AxiosPromise<any> {
  return axios.post(ENDPOINT.TRANSACTIONS_DOWNLOAD, payload, { responseType: 'blob' });
}
