<script type="text/babel">
import avatar from '../../components/Avatar.vue';

export default {
  props: ['profile'],

  components: {
    avatar,
  },

  data() {
    return {
      processing: false,
    };
  },

  methods: {
    uploadAvatar(e) {
      this.processing = true;
      if (!e.target.files.length) return;
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);

      var formData = new FormData();
      formData.append('image', file);

      axios
        .post('/api/v2/profiles/' + this.profile.id + '/image', formData)
        .then((result) => {
          this.processing = false;
          this.profile.profile_image = result.data.path;
        })
        .catch(() => {
          this.processing = false;
        });
    },

    async deleteAvatar() {
      const shouldDeleteAvatar = await this.$tConfirm('Are you sure you want to delete this avatar?', {
        delete: true,
        title: this.$t('general.are_you_sure'),
      });
      if (shouldDeleteAvatar) {
        this.processing = true;
        axios
          .delete('/api/v2/profiles/' + this.profile.id + '/image/0')
          .then(() => {
            this.profile.profile_image = null;
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },
  },
};
</script>

<template>
  <div>
    <label
      for="AvatarSelector"
      class="btn btn-icon white btn-xs pull-right rounded"
      style="margin-left: -16px; z-index: 20"
    >
      <span v-if="processing">
        <i class="fa fa-spinner fa-spin text-sm"></i>
      </span>
      <span v-else>
        <span v-if="profile.profile_image == null">
          <i class="material-icons text-sm" v-tooltip.top="$t('profile.select_avatar')">edit</i>
          <input type="file" id="AvatarSelector" style="display: none" @change="uploadAvatar" />
        </span>
        <span v-else>
          <i class="material-icons text-sm" @click="deleteAvatar" v-tooltip.top="$t('profile.delete_avatar')">close</i>
        </span>
      </span>
    </label>

    <avatar
      :color="profile.color"
      :abbr="profile.abbr"
      :image="profile.profile_image"
      width="45"
      data-hj-suppress
    ></avatar>
  </div>
</template>
