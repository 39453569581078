<template>
  <div ref="modal" class="modal" id="AtomicModal" role="dialog">
    <div v-if="active" class="modal-dialog" role="document">
      <div class="modal-content">
        <div v-if="$slots.header" class="modal-header">
          <h5 class="modal-title"><slot name="header" /></h5>
        </div>
        <div class="modal-body">
          <slot />
        </div>
        <div v-if="$slots.footer" class="modal-footer">
          <slot name="footer">
            <button type="button" class="btn white" data-dismiss="modal" aria-label="Close">Cancel</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
    };
  },

  methods: {
    open() {
      this.active = true;

      this.$nextTick(() => {
        $(this.$refs.modal).modal('show');
      });
    },

    close() {
      this.active = false;
      $(this.$refs.modal).modal('hide');
    },
  },

  mounted() {},

  beforeUnmount() {
    this.close();
  },
};
</script>

<style scoped>
.modal-dialog {
  max-width: 950px;
}
</style>
