<script>
import select2 from '../../Select2';

export default {
  components: {
    select2,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    channels: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<template>
  <div class="box">
    <div class="box-header b-b">
      <h3>{{ $t('web_widget.voice') }}</h3>
    </div>
    <div class="box-body">
      <div class="form-group row">
        <label class="col-md-2 form-control-label">{{ $t('web_widget.enabled') }}</label>
        <div class="col-md-10">
          <p class="form-control-static">
            <label class="ui-switch success m-t-xs m-r">
              <input v-model="record.voice.enabled" type="checkbox" />
              <i></i>
            </label>
          </p>
        </div>
      </div>
      <div v-if="record.voice.enabled">
        <div class="form-group row">
          <label class="col-md-2 form-control-label">
            {{ $t('web_widget.channel') }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-md-10">
            <select2
              v-model="record.voice.channel_id"
              :options="channels"
              :placeholder="$t('web_widget.select_a_channel')"
            ></select2>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 form-control-label">{{ $t('web_widget.call_via_browser') }}</label>
          <div class="col-md-10">
            <label class="ui-switch success m-t-xs m-r">
              <input v-model="record.voice.allow_calls" type="checkbox" :true-value="1" />
              <i></i>
            </label>
            <span class="form-text">
              {{ $t('web_widget.call_via_browser_description') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
