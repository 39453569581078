<template>
  <t-modal v-model="shouldOpenModal" variant="narrow" @close="handleClose">
    <template #title>
      <h2 class="t-text-h2">{{ $t('onboarding.skip_popup_title') }}?</h2>
    </template>

    <p class="t-text-md text-grey-700">{{ $t('onboarding.skip_popup_body') }}</p>

    <template #footer>
      <t-button size="md" btn-style="secondary" @click="handleClose">
        {{ $t('onboarding.skip_popup_cancel') }}
      </t-button>
      <t-button size="md" @click="handleSkip">
        {{ $t('onboarding.skip_popup_confirm') }}
      </t-button>
    </template>
  </t-modal>
</template>

<script>
import updateOnboardingStep from '../api';

export default {
  name: 'OnboardingSkipModal',
  emits: ['close'],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    shouldOpenModal() {
      return this.isOpen;
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    handleSkip() {
      updateOnboardingStep(7)
        .then(() => {
          this.$emit('close');
          this.$router.push('/welcome');
        })
        .catch(() => {
          setTimeout(() => {
            updateOnboardingStep(6);
          }, 3000);
        });
    },
  },
};
</script>
