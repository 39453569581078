import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo_header = _resolveComponent("logo-header")!
  const _component_two_factor_auth_setup_stepper = _resolveComponent("two-factor-auth-setup-stepper")!
  const _component_help_center_button = _resolveComponent("help-center-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_logo_header),
    _createVNode(_component_two_factor_auth_setup_stepper, {
      qr: _ctx.qr,
      secret: _ctx.secret
    }, null, 8 /* PROPS */, ["qr", "secret"]),
    _createVNode(_component_help_center_button)
  ]))
}