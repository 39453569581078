export default {
  methods: {
    /**
     * set fields errors
     *
     * @param {string} type
     * @param id
     * @param {string[]} errors
     */
    setFieldServerError(type, id, errors) {
      this.dirty = true;

      const setField = (field) => {
        if (field.id === id) {
          field.errors = errors?.value;
        }
        return field;
      };

      if (this.fields?.length) {
        this.fields.map(setField);
      } else if (this.fields[type]?.length) {
        this.fields[type] = this.fields[type].map(setField);
      }
    },
    /**
     * reset fields errors
     *
     * @param {string} type
     * @param id
     */
    resetFieldServerError(type, id) {
      const resetField = (field) => {
        if (field.id === id) {
          field.errors = null;
        }
        return field;
      };

      if (this.fields?.length) {
        this.fields.map(resetField);
      } else if (this.fields[type]?.length) {
        this.fields[type] = this.fields[type].map(resetField);
      }
    },
  },
};
