<template>
  <div>
    <content-editable
      ref="editor"
      v-model="tempValue"
      autofocus
      :max-height="'none'"
      class="form-control border"
      style="border-radius: 0.5rem 0.5rem 0 0.5rem"
      @blur="onContentEditableBlur"
    ></content-editable>
    <div class="clear">
      <span
        class="bg-gray-500 pull-right rounded-bottom flex border-b border-l border-r px-2 pt-1 text-sm text-grey-600"
        style="background-color: #ececec"
      >
        <dropdown :options="options" label="label" max-height="330px" width="250px" @selected="selected">
          <template #toggle>
            <span v-tooltip="{ content: $t('flowbot.insert_variable_hover'), delay: 300 }" class="pointer">
              <i class="text-md material-icons text-grey-600">label</i>
            </span>
          </template>
          <template #option="prop">
            <div class="flex">
              <span class="ml-2 flex-1 text-ellipsis" style="padding-right: 4px">{{ prop.option.title }}</span>
            </div>
          </template>
        </dropdown>
      </span>
    </div>
  </div>
</template>

<script>
import ContentEditable from '../../ContentEditable';
import Dropdown from '../../ReplyForm/Dropdown';

export default {
  name: 'DynamicKeyOrValueInput',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    customFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tempValue: '',
    };
  },

  components: {
    ContentEditable,
    Dropdown,
  },

  watch: {
    modelValue: function (val) {
      // if (document.activeElement !== this.$refs.editor.$el) {
      //     this.$refs.editor.$el.innerHTML = val;
      // }
      if (this.$refs.editor.$el.innerHTML !== val) {
        this.$refs.editor.$el.innerHTML = val;
      }
      val = val.replace('<br>', ''); // Firefox leading <br> fix
      this.tempValue = val;
    },
    tempValue: function (val) {
      this.$emit('update:modelValue', val);
    },
  },

  mounted() {
    this.tempValue = this.modelValue;
    this.$refs.editor.$el.innerHTML = this.modelValue;
  },

  methods: {
    selected(data) {
      this.$refs.editor.insertText('{ ' + data.value + ' }');
    },

    focus() {
      // Autofocus fix
      setTimeout(
        function (that) {
          window.placeCaretAtEnd(that.$refs.editor.$el);
        },
        50,
        this
      );
    },

    onContentEditableBlur(value) {
      this.tempValue = value;
    },
  },

  computed: {
    options() {
      return [
        { id: 0, title: 'Ticket ticket_id', value: 'Ticket ticket_id' },
        { id: 0, title: 'Ticket contact_id', value: 'Ticket contact_id' },
        { id: 0, title: 'Ticket channel_id', value: 'Ticket channel_id' },
        ...this.customFields,
      ];
    },
  },
};
</script>

<style>
.rounded-bottom {
  border-radius: 0 0 10px 10px;
}
</style>
