<script type="text/babel">
import { keys, map, take, toArray } from 'lodash';
import { nextTick } from 'vue';

import eventBus from '@/eventBus';

import Multiselect from './Multiselect.vue';
import Avatar from '../../Avatar';

export default {
  name: 'PhoneInput',
  emits: ['selected'],
  data() {
    return {
      contacts: [],
      contact: [],
      loading: false,
      query: '',
      searchEnabled: true,
      searchTimer: null,
    };
  },

  props: {
    dark: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    Multiselect,
    Avatar,
  },

  mounted() {
    this.initCountrySelector();

    this.$refs.input.$refs.search.onkeyup = (e) => {
      this.searchEnabled = true;
      if (e.keyCode == 13 && this.contacts.length == 0) {
        this.select({
          phone_international: this.$refs.input.$refs.search.value,
        });
      }
    };
  },

  unmounted() {
    eventBus.$off('voip.key-pressed');
  },

  methods: {
    initCountrySelector() {
      let input = this.$refs.input.$refs.search;
      $(input).intlTelInput(this.getCountrySelectorConfig());
    },

    getCountrySelectorConfig() {
      return {
        initialCountry: 'auto',
        preferredCountries: ['nl', 'be', 'de', 'fr', 'es', 'gb', 'it', 'za'],
        onlyCountries: map(keys(this.$root.countries), (k) => k.toLowerCase()),
        utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.4/js/utils.js',
        geoIpLookup: function (callback) {
          $.get('https://ipinfo.io', function () {}, 'jsonp').always(function (resp) {
            var countryCode = resp && resp.country ? resp.country : '';
            if (countryCode === '') {
              countryCode = 'NL'; // @todo Load from profile
            }
            callback(countryCode);
          });
        },
        autoPlaceholder: 'off',
      };
    },

    findContacts(query) {
      this.query = query;

      // Do not search if using dialpad
      if (!this.searchEnabled) {
        this.contacts = [];
        return;
      }

      this.contacts = [];

      clearTimeout(this.searchTimer);

      this.searchTimer = setTimeout(() => {
        window.singleRequest('get', '/client-api/voip/contacts?search=' + query).then((res) => {
          this.loading = false;
          this.contacts = toArray(take(res.data, 5));

          if (res.data.length == 0) {
          }
        });
      }, 250);
    },

    select(item) {
      this.$emit('selected', item.phone_international);
    },

    onClose(val) {
      nextTick(() => {
        try {
          this.$refs.input.$refs.search.value = this.query;
          this.$refs.input.search = this.query;
        } catch (e) {}
      });
    },
  },
};
</script>

<template>
  <div :class="{ 'voip-input-dark': dark, 'voip-input-white': !dark }">
    <div class="voip_phone_input">
      <multiselect
        ref="input"
        v-model="contact"
        label="name"
        track-by="id"
        :placeholder="$t('voip.voip_enter_phone_number_or_name')"
        deselect-label=""
        :preserve-search="true"
        :internal-search="false"
        :reset-after="true"
        :options="contacts"
        :multiple="false"
        :allow-empty="true"
        :searchable="true"
        :loading="loading"
        :options-limit="4"
        :limit="3"
        :max-height="600"
        :show-no-results="false"
        :show-labels="false"
        @disableSearch="searchEnabled = false"
        @close="onClose"
        @input="select"
        @search-change="findContacts"
        @select="(item) => select(item)"
      >
        <template #option="contact">
          <div class="flex" style="height: 35px">
            <div>
              <avatar
                width="35"
                :abbr="contact.option.abbr"
                :color="contact.option.color"
                :image="contact.option.profile_image"
              ></avatar>
            </div>
            <div class="flex-1 pl-4">
              <div>
                <span v-if="contact.option.name">{{ contact.option.name }}</span>
                <span v-else>{{ contact.option.phone_international }}</span>
              </div>
              <div class="pt-1 text-sm" style="opacity: 0.7">{{ contact.option.phone_international }}</div>
            </div>
          </div>
        </template>
        <template #caret></template>
      </multiselect>
    </div>
  </div>
</template>

<style lang="scss">
.voip-input-dark,
.voip-input-white {
  .flag-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 1px;
    margin-top: -10px;
    margin-bottom: -10px;
    margin-left: -5px;
  }
}

.voip-input-dark {
  .multiselect__tags {
    border-radius: 0px !important;
    background: #303030;
    border: none;
  }

  .multiselect__select:before {
    border-color: #fff transparent transparent;
  }

  .multiselect__single {
    background: #303030;
    color: white;
    text-align: center;
    padding-top: 2px;
  }

  input.multiselect__input {
    padding-left: 45px !important;
    background: #303030;
    color: white;
    padding-top: 1px;
  }

  input.multiselect__input::placeholder {
    color: white;
    opacity: 1;
  }

  .intl-tel-input .selected-flag .iti-arrow {
    border-top: 4px solid #fff;
  }

  .multiselect__single,
  .multiselect__option {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: white;
    border-bottom: 1px solid #4f5261;
  }

  .multiselect__single,
  .multiselect__option--highlight {
    background: #4f5261 !important;
    color: white !important;
  }
}

.voip-input-white {
  .multiselect__tags {
    border-radius: 10px !important;
  }

  .multiselect__content {
    background: white;
    border-radius: 10px !important;
  }
}
</style>
