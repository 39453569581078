<template>
  <t-modal v-model="computedValue" class="broadcast-details" :title="name" @close="computedValue = false">
    <broadcast-details-item :title="messageLabel" :loading="loading">
      <span v-html="messageComputed"></span>
    </broadcast-details-item>
    <broadcast-details-item :title="$t('broadcast.broadcast_sender')" :loading="loading">
      <span class="t-text-desktop-paragraph-sm flex items-center">
        <broadcasting-channel-icon :type="senderType" width="24px" height="24px" />
        <span class="ml-2">{{ sender }}</span>
      </span>
    </broadcast-details-item>
    <broadcast-details-item :title="$t('broadcast.broadcast_status')" :center="status !== 'FAILED'" :loading="loading">
      <broadcast-status-badge
        :status-tooltip="statusTooltip"
        :status-type="statusType"
        :status="getBroadcastStatusDisplayText"
      />
      <div v-if="status === 'FAILED'" class="mt-2">
        {{ $t('broadcast.the_broadcast_has_failed_to_send_a_few_things_to_check') }}
        <ul>
          <li>{{ $t('broadcast.make_sure_the_right_channel_was_used_for_sending') }}</li>
          <li>{{ $t('broadcast.confirm_that_your_remaining_balance_is_high_enough') }}</li>
        </ul>
      </div>
    </broadcast-details-item>
    <broadcast-details-item :title="sendDateLabel" center :loading="loading">{{ sentAtDetail }}</broadcast-details-item>
    <broadcast-details-item
      v-if="hasFailedRecipients"
      :title="$t('broadcast.broadcast_failed_recipients')"
      :loading="loading"
    >
      <broadcast-details-recipients :recipients="failedRecipients" :is-successful-recipients="false" />
    </broadcast-details-item>
    <broadcast-details-item :title="$t('broadcast.broadcast_all_recipients')" :loading="loading">
      <broadcast-details-recipients :recipients="recipients" />
    </broadcast-details-item>
    <broadcast-details-item :title="$t('broadcast.broadcast_cost')" center :loading="loading">
      €{{ costCurrency }}
    </broadcast-details-item>

    <template #footer>
      <div class="flex gap-3" :class="{ 'footer-loading': loading }">
        <t-button v-if="canDelete" btn-style="danger" @click="onDelete">{{ deleteText }}</t-button>

        <t-button :btn-style="canEdit ? 'secondary' : 'primary'" @click="computedValue = false">
          {{ $t('broadcast.broadcast_close_button') }}
        </t-button>
        <t-button v-if="canEdit" @click="editBroadcast">
          {{ $t('broadcast.broadcast_edit_button') }}
        </t-button>
      </div>
    </template>
  </t-modal>
</template>

<script>
import { capitalize } from 'lodash';
import moment from 'moment';

import BroadcastDetailsItem from '@/components/WabBroadcasting/components/BroadcastDetailsItem';
import BroadcastDetailsRecipients from '@/components/WabBroadcasting/components/BroadcastDetailsRecipients';
import BroadcastingChannelIcon from '@/components/WabBroadcasting/components/BroadcastingChannelIcon';
import BroadcastStatusBadge from '@/components/WabBroadcasting/components/BroadcastStatusBadge';
import {
  broadcastStatusDisplayText,
  currency,
  replaceSmsTags,
  replaceTags,
  statusTooltip,
  statusTypeConvert,
} from '@/components/WabBroadcasting/utils/Utils';
import { BROADCAST_STATUS, CHANNEL_TYPE } from '@/Configs/Constants';

export default {
  name: 'BroadcastDetails',
  emits: ['update:modelValue', 'delete'],
  components: {
    BroadcastDetailsRecipients,
    BroadcastingChannelIcon,
    BroadcastDetailsItem,
    BroadcastStatusBadge,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    sender: {
      type: String,
      default: '',
    },
    senderType: {
      type: String,
      default: CHANNEL_TYPE.SMS,
      validator: (val) => [CHANNEL_TYPE.SMS, CHANNEL_TYPE.WA_BUSINESS].includes(val),
    },
    status: {
      type: String,
      default: BROADCAST_STATUS.SCHEDULED,
      validator: (value) =>
        [
          BROADCAST_STATUS.NONE,
          BROADCAST_STATUS.SCHEDULED,
          BROADCAST_STATUS.SENT,
          BROADCAST_STATUS.FAILED,
          BROADCAST_STATUS.SENDING,
          BROADCAST_STATUS.CREATED,
        ].includes(value),
    },
    sendDate: {
      type: String,
      default: '',
    },
    cost: {
      type: [String, Number],
      default: '',
    },
    recipients: {
      type: Array,
      default: () => [],
    },
    progress: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
    statusType() {
      return statusTypeConvert(this.status);
    },
    statusTooltip() {
      return this.isPartlySent ? statusTooltip(BROADCAST_STATUS.PARTLY_SENT) : statusTooltip(this.status);
    },
    statusText() {
      return capitalize(this.status);
    },
    costCurrency() {
      return currency(this.cost);
    },
    sentAtDetail() {
      const tz = this.$root.user.timezone;
      return this.sendDate
        ? moment.tz(this.sendDate, '', tz).format(`DD/MM/YYYY [${this.$t('general.at')}] HH:mm`)
        : '-';
    },
    messageComputed() {
      if (this.senderType === CHANNEL_TYPE.SMS) {
        return replaceSmsTags(stripHtml(this.message));
      }
      return replaceTags(stripHtml(this.message));
    },
    messageLabel() {
      return this.senderType === CHANNEL_TYPE.SMS
        ? this.$t('broadcast.broadcast_message')
        : this.$t('broadcast.broadcast_message_template');
    },
    sendDateLabel() {
      return this.status === BROADCAST_STATUS.SCHEDULED
        ? this.$t('broadcast.broadcast_scheduled_date')
        : this.$t('broadcast.broadcast_sent_date');
    },
    deleteText() {
      if (this.status === BROADCAST_STATUS.SCHEDULED) return this.$t('broadcast.broadcast_cancel_and_delete_button');
      return this.$t('broadcast.broadcast_delete_button');
    },
    canEdit() {
      return this.status === BROADCAST_STATUS.SCHEDULED;
    },
    canDelete() {
      return [BROADCAST_STATUS.SCHEDULED, BROADCAST_STATUS.FAILED].includes(this.status);
    },
    failedRecipients() {
      return this.recipients.filter((item) => item.status?.toLowerCase() === 'failed');
    },
    hasFailedRecipients() {
      return this.progress?.failed_jobs > 0;
    },
    totalFailedRecipients() {
      return this.progress?.failed_jobs;
    },
    isPartlySent() {
      return this.status === BROADCAST_STATUS.SENT && this.totalFailedRecipients > 0;
    },
    getBroadcastStatusDisplayText() {
      const displayText = this.isPartlySent ? BROADCAST_STATUS.PARTLY_SENT : this.status;
      return broadcastStatusDisplayText(displayText);
    },
  },
  methods: {
    onDelete() {
      this.computedValue = false;
      const { id, name } = this;
      this.$emit('delete', { id, name });
    },
    editBroadcast() {
      this.computedValue = false;
      this.$nextTick(() => {
        this.$router.push('/broadcasting/' + this.id);
      });
    },
  },
};
</script>

<style scoped lang="scss" src="./BroadcastDetails.scss" />
