<script lang="ts" setup>
import type { FeatureIcon } from '@/types';

const props = defineProps<{
  icon: FeatureIcon;
}>();

const urlPrefix = Assets_path || '/';
</script>

<template>
  <div class="h-[88px] w-[88px]">
    <img
      :alt="`${props.icon}-icon`"
      :data-test="`${props.icon}-icon`"
      :src="`${urlPrefix}img/feature-icon/${props.icon}.svg`"
    />
  </div>
</template>
`;
