<template>
  <div id="ReminderTicketModal" ref="modal" class="modal" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('tickets.set_reminder') }}</h5>
          <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{ $t('tickets.reminder_intro') }}</p>
          <div class="form-group">
            <label>
              <strong>{{ $t('tickets.remind_at') }}</strong>
            </label>
            <input v-model="remind_at" type="text" class="form-control input-datetimepicker" />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn success rounded-full" @click="setReminder()">
            {{ $t('tickets.set_reminder') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import eventBus from '@/eventBus';

export default {
  name: 'ReminderTicketModal',
  data: function () {
    return {
      loaded: false,
      remind_at: '',
      ticket: {},
    };
  },

  mounted: function () {
    eventBus.$off('modals.remind-ticket.open');
    eventBus.$on('modals.remind-ticket.open', (data) => {
      this.loaded = false;
      this.ticket = data.ticket;
      this.openModal();
    });
  },

  methods: {
    openModal() {
      this.open = true;
      $(this.$refs.modal).modal({
        keyboard: false,
        //                    backdrop: 'static',
      });
      this.$nextTick(() => {
        $('.input-datetimepicker').datetimepicker({
          format: 'd-m-Y H:i',
          minDate: 0,
        });
        var vm = this;
        $('.input-datetimepicker').on('change', function () {
          vm.remind_at = $(this).val();
        });
      });
      this.loaded = true;
      this.resetDate();
    },

    resetDate() {
      this.remind_at = moment().add('w', 1).format('DD-MM-YYYY') + ' 13:00';
    },

    closeModal() {
      this.open = false;
      $(this.$refs.modal).modal('hide');
      this.resetDate();
    },

    setReminder() {
      axios
        .post('/api/v2/tickets/' + this.ticket.id + '/reminders', {
          remind_at: moment(this.remind_at, 'DD-MM-Y HH:mm').format('Y-MM-DD HH:mm'),
        })
        .then((result) => {
          this.loaded = false;
          eventBus.$emit('modals.ticket_reminder.added', { reminder: result.data });
          this.closeModal();
          eventBus.$emit('ticket-reminder-set');
        });
    },
  },
};
</script>
