import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a285b168"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  "data-test": "online-status",
  class: "mb-6 h-full w-full rounded-xl border border-grey-300 bg-white p-6",
  width: "100%"
}
const _hoisted_2 = { class: "flex h-full flex-col items-start" }
const _hoisted_3 = { class: "flex flex-row items-center" }
const _hoisted_4 = { class: "mr-2 rounded-xl border border-grey-500 p-2" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "t-text-h5 m-0 text-grey-800" }
const _hoisted_7 = {
  key: 1,
  class: "m-0 flex text-grey-600"
}
const _hoisted_8 = {
  key: 2,
  "data-test": "view-all-agents",
  class: "mt-6 flex w-full flex-row-reverse justify-items-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_calendar_person_linear = _resolveComponent("calendar-person-linear")!
  const _component_online_status_skeleton = _resolveComponent("online-status-skeleton")!
  const _component_online_status_items = _resolveComponent("online-status-items")!
  const _component_arrow_right_linear = _resolveComponent("arrow-right-linear")!
  const _component_t_button_text = _resolveComponent("t-button-text")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_calendar_person_linear, { size: "1.4rem" })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('reports_v2.online_status')), 1 /* TEXT */),
          (_ctx.showSkeleton)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: _normalizeClass([{ 'animation-loading': _ctx.showSkeleton }, "m-0 mt-1 h-3 w-128px rounded-3xl bg-grey-300"])
              }, null, 2 /* CLASS */))
            : _createCommentVNode("v-if", true),
          (!_ctx.showSkeleton && !_ctx.showEmptyStateSkeleton)
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t('reports_v2.available_agents_count', { available: _ctx.aggregates.online, total: _ctx.aggregates.total })), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      (_ctx.showSkeleton || _ctx.showEmptyStateSkeleton)
        ? (_openBlock(), _createBlock(_component_online_status_skeleton, {
            key: 0,
            "show-error": _ctx.showEmptyStateSkeleton
          }, null, 8 /* PROPS */, ["show-error"]))
        : _createCommentVNode("v-if", true),
      (!_ctx.showSkeleton && !_ctx.showEmptyStateSkeleton)
        ? (_openBlock(), _createBlock(_component_online_status_items, {
            key: 1,
            users: _ctx.users
          }, null, 8 /* PROPS */, ["users"]))
        : _createCommentVNode("v-if", true),
      (_ctx.showViewAllAgentsButton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_router_link, { to: "/reports/agents_availability" }, {
              default: _withCtx(() => [
                _createVNode(_component_t_button_text, { size: "sm" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('reports_v2.view_all_agents')) + " ", 1 /* TEXT */),
                    _createVNode(_component_arrow_right_linear, { size: "1.2rem" })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}