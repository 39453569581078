// FIXME: https://github.com/import-js/eslint-plugin-import/issues/1479
// eslint-disable import/no-duplicates
import { formatWithOptions } from 'date-fns/fp';
import en from 'date-fns/locale/en-GB';
import moment from 'moment';

export const formatDate = (date: Date) => {
  const tz = moment.tz(date, window.APP_TIMEZONE);
  return tz.clone().tz(window.APP_TIMEZONE).format('DD-MM-Y');
};

export const formatDateTime = (date: Date) => {
  return moment.tz(date, window.APP_TIMEZONE).tz(window.USER_TIMEZONE).format('DD-MM-YYYY, HH:mm');
};

export const formatTime = (date: Date) => {
  return moment(date).format('DD-MM-YYYY, HH:mm');
};

export const formatEpochDate = (epochDate: EpochTimeStamp, format = 'MMMM d, yyyy') => {
  const date = new Date(epochDate * 1000);

  return formatWithOptions({ locale: en }, format, date);
};

export const formatEpochMillisecondDate = (epochDate: EpochTimeStamp, format = 'MMMM d, yyyy') => {
  const date = new Date(epochDate);

  return formatWithOptions({ locale: en }, format, date);
};

export const toEpochDate = (date: Date): EpochTimeStamp => Math.floor(date.getTime() / 1000);
