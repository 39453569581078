<script lang="ts">
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import EditUserModal from '@/components/Users/components/EditUsers/EditUserModal';
import { PERMISSION } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useUserStore } from '@/store/pinia';

import Avatar from '../../Avatar';

export default defineComponent({
  name: 'UserPanel',
  components: { Avatar, EditUserModal },
  computed: {
    ...mapStores(useUserStore),
    chatStatusColor() {
      if (this.user.user_status === 'ONLINE' && this.user.is_online) {
        return '#14B29F';
      }
      return '#89909C';
    },
    voipStatusColor() {
      if (this.user.voip_status === 'CALLING') {
        return '#FFE37E';
      }
      if (this.user.voip_status === 'ONLINE' && this.user.is_online) {
        return '#14B29F';
      }
      return '#89909C';
    },
    statusColor() {
      if (this.user.is_online) {
        return '#14B29F';
      }
      return '#89909C';
    },
    statusCanBeChanged() {
      if (!this.userStore.hasPermission(PERMISSION.PERMISSIONS__USERS__MANAGE) || !this.user.is_online) {
        return false;
      }
      return true;
    },
    voipTooltip() {
      if (!this.statusCanBeChanged || this.user.voip_status === 'CALLING') {
        return;
      }

      if (this.newVoipStatus === 'ONLINE') {
        return this.$t('reports.availability_user_set_available_for_voice');
      }
      return this.$t('reports.availability_user_set_unavailable_for_voice');
    },
    chatTooltip() {
      if (!this.statusCanBeChanged) {
        return;
      }

      if (this.newChatStatus === 'ONLINE') {
        return this.$t('reports.availability_user_set_available_for_chat');
      }
      return this.$t('reports.availability_user_set_unavailable_for_chat');
    },
    newVoipStatus() {
      let newStatus;
      if (!this.user.is_online) {
        return;
      }
      if (!this.user.voip_status || this.user.voip_status === 'OFFLINE') {
        newStatus = 'ONLINE';
      }
      if (this.user.voip_status === 'ONLINE') {
        newStatus = 'OFFLINE';
      }
      if (this.user.voip_status === 'CALLING') {
        return;
      }
      return newStatus;
    },
    newChatStatus() {
      let newStatus;
      if (!this.user.is_online) {
        return;
      }
      if (!this.user.user_status || this.user.user_status === 'AWAY') {
        newStatus = 'ONLINE';
      }
      if (this.user.user_status === 'ONLINE') {
        newStatus = 'AWAY';
      }
      return newStatus;
    },
  },
  data() {
    return {
      user: this.initialUser,
      openEditUserModal: false,
      PERMISSION,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    initialUser: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onVoipStatusChange() {
      let newStatus = this.newVoipStatus;
      if (!newStatus) {
        return;
      }
      this.user.voip_status = newStatus;
      axios.get('/auth/set-voip-status/' + this.user.voip_status + '?user_id=' + this.user.id);
      if (this.user.voip_status === 'ONLINE') {
        this.notify(this.$t('reports.availability_user_now_available_for_voice'));
      } else {
        this.notify(this.$t('reports.availability_user_now_unavailable_for_voice'));
      }
    },
    onChatStatusChange() {
      let newStatus = this.newChatStatus;
      if (!newStatus) {
        return;
      }
      this.user.user_status = newStatus;
      axios.get('/auth/set-status/' + this.user.user_status + '?user_id=' + this.user.id);
      if (this.user.user_status === 'ONLINE') {
        this.notify(this.$t('reports.availability_user_now_available_for_chat'));
      } else {
        this.notify(this.$t('reports.availability_user_now_unavailable_for_chat'));
      }
    },
    notify(message) {
      eventBus.$emit('toast', {
        from: this.user,
        message: message,
      });
    },
    handleOpenEditUserModal() {
      this.openEditUserModal = true;
    },
    handleSetUser(user) {
      this.user = user;
    },
  },
});
</script>

<template>
  <div class="flex min-w-0 flex-1 select-none rounded-lg border-2 border-grey-200 p-4 text-black">
    <edit-user-modal
      v-if="openEditUserModal && user && userStore.hasPermission(PERMISSION.PERMISSIONS__USERS__MANAGE)"
      v-model="openEditUserModal"
      :initial-record-prop="user"
      @update="handleSetUser"
    />
    <div class="flex w-full min-w-0">
      <div class="relative">
        <div class="cursor-pointer" @click="handleOpenEditUserModal">
          <avatar width="48" :color="user.color" :abbr="user.abbr" :image="user.profile_image"></avatar>
        </div>
        <div class="absolute" style="bottom: -2px; right: -2px">
          <div class="h-[18px] w-[18px] rounded-full border-2 border-white" :style="{ background: statusColor }"></div>
        </div>
      </div>
      <div class="ml-4 mt-1 min-w-0 flex-1 select-none leading-none">
        <div class="mt-1 flex w-full">
          <div class="min-w-0 flex-1">
            <div class="text-ellipsis font-bold">{{ user.first_name }} {{ user.last_name }}</div>
          </div>
          <div class="ml-auto flex pl-4">
            <div>
              <atomic-icon
                v-if="$root.agencyHasChatChannel"
                v-tooltip="{ content: chatTooltip, placement: 'bottom', distance: 3 }"
                size="16px"
                icon="fa-comment-alt"
                :class="{ 'cursor-pointer': chatTooltip }"
                :color="chatStatusColor"
                @click="onChatStatusChange()"
              ></atomic-icon>
              <atomic-icon
                v-if="$root.agencyHasVoipChannel"
                v-tooltip="{ content: voipTooltip, placement: 'bottom', distance: 3 }"
                size="16px"
                class="ml-2"
                icon="fa-phone"
                :class="{ 'cursor-pointer': voipTooltip }"
                :color="voipStatusColor"
                @click="onVoipStatusChange()"
              ></atomic-icon>
            </div>
          </div>
        </div>
        <div>
          <div v-if="user.teams.length" class="text-muted w-3/4 text-ellipsis text-ellipsis text-sm">
            {{ user.teams.map((t) => t.name).join(', ') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
