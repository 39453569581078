<template>
  <div :class="`flex min-h-416px w-full flex-col items-center ${getClassForStep()}`">
    <transition-group name="fade">
      <connect-whatsapp v-if="step === steps.CONNECT_WHATSAPP" key="connect-whatsapp" data-test="connect-whatsapp" />
      <validating-connection
        v-if="step === steps.VALIDATING_CONNECTION"
        key="validating-connection"
        data-test="validating-connection"
      />

      <establishing-connection
        v-if="step === steps.ESTABLISHING_CONNECTION"
        key="establishing-connection"
        data-test="establishing-connection"
      />

      <message-recieved v-if="step === steps.MESSAGE_RECIEVED" key="message-recieved" data-test="message-recieved" />

      <trying-templates
        v-if="step === steps.TRYING_TEMPLATES"
        key="trying-templates"
        data-test="trying-templates"
        :ticket-id="ticketId"
      />

      <trying-templates-success
        v-if="step === steps.TRYING_TEMPLATES_SUCCESS"
        key="trying-templates-success"
        data-test="trying-templates-success"
        :ticket-id="ticketId"
        :messages="messages"
      />
    </transition-group>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import ConnectWhatsapp from './components/ConnectWhatsapp';
import EstablishingConnection from './components/EstablishingConnection';
import MessageRecieved from './components/MessageRecieved';
import TryingTemplates from './components/TryingTemplates';
import TryingTemplatesSuccess from './components/TryingTemplatesSuccess';
import ValidatingConnection from './components/ValidatingConnection';
import { Steps } from './constants';

import type { Message } from './constants';
import type { PropType } from 'vue';

type SandboxData = {
  steps: typeof Steps;
};

export default defineComponent({
  name: 'WhatsappBusinessSandbox',
  components: {
    ConnectWhatsapp,
    EstablishingConnection,
    ValidatingConnection,
    MessageRecieved,
    TryingTemplates,
    TryingTemplatesSuccess,
  },
  props: {
    step: {
      type: String as PropType<keyof typeof Steps>,
      default: Steps.CONNECT_WHATSAPP,
    },
    ticketId: {
      type: String,
      default: '',
    },
    messages: {
      type: [] as PropType<Message>,
      default: [],
    },
  },
  data(): SandboxData {
    return {
      steps: Steps,
    };
  },
  methods: {
    getClassForStep() {
      return this.step === Steps.CONNECT_WHATSAPP ? 'connect-whatsapp-sandbox-step' : 'sandbox-steps';
    },
  },
});
</script>
<style scoped lang="scss" src="./WhatsappBusinessSandbox.scss" />
