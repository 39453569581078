<template>
  <div class="onboarding-slides absolute h-full w-full bg-white" data-test="onboarding-slides">
    <div class="flex flex-col">
      <div class="flex h-screen flex-col items-center justify-center">
        <div class="onboarding-steps" data-test="onboarding-slides-items">
          <onboarding-slides v-for="(item, index) in items" :key="index" :item="item" :active="index === active" />
        </div>
        <div v-if="active < 2" class="t-text-desktop-label-sm absolute bottom-10 text-leaf-500">
          <span class="flex cursor-pointer flex-row items-center" @click="goToGetStartedPage">
            <atomic-button data-test="onboarding-slides-skip" btn-style="link" class="mr-2">
              <span class="flex flex-row">I just want to try the tool</span>
              <span class="arrow-right">
                <arrow-right-linear :size="24" />
              </span>
            </atomic-button>
          </span>
        </div>
        <navigation
          :active="active"
          class="mt-10"
          :is-first="active === 0"
          @prev="handleGoToPrev"
          @next="handleGoToNext"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowRightLinear } from '@trengo/trengo-icons';

import Navigation from './components/Navigation';
import OnboardingSlides from './components/OnboardingSlides';

export default {
  name: 'OnboardingSteps',
  components: { ArrowRightLinear, OnboardingSlides, Navigation },
  data() {
    return {
      active: 0,
      items: [
        {
          title: `Welcome to Trengo, ${this.$root.user.first_name}!`,
          banner: this.$root.assetsURL + 'img/onboarding/1.webp',
          description:
            'The smart conversations platform that covers your customer communication strategy end-to-end. Make customer communication easy.',
        },
        {
          title: `All your channels in one shared view`,
          banner: this.$root.assetsURL + 'img/onboarding/2.webp',
          description:
            'Connect all your online channels with Trengo. Centralize your communication into a single, smart solution that helps you convert and retain customers.',
        },
        {
          title: `Work smarter, not harder`,
          banner: this.$root.assetsURL + 'img/onboarding/3.webp',
          description:
            'Scale your customer communication by automating repetitive processes and predicting conversation patterns with customers.',
        },
      ],
    };
  },
  mounted() {
    if (this.userOnboardingHasBeenFinished) {
      this.$router.push('/tickets');
      return false;
    }
    document.addEventListener('keyup', this.handleKeyboardEvent);
  },
  methods: {
    handleKeyboardEvent(event) {
      if (event.keyCode === 39 || (event.keyCode === 13 && this.active === 2)) {
        this.handleGoToNext();
      } else if (event.keyCode === 37) {
        this.handleGoToPrev();
      }
    },
    handleGoToNext() {
      if (this.active === 2) {
        this.goToGetStartedPage();
        return;
      }
      this.active = this.active === 2 ? 2 : this.active + 1;
    },
    goToGetStartedPage() {
      this.finalize().finally(() => {
        document.removeEventListener('keyup', this.handleKeyboardEvent);
        if (this.$root.onboarding) {
          this.$root.onboarding = null;
        }
        this.$router.push('/admin/get-started');
      });
    },
    finalize() {
      return axios.post('/client-api/onboarding/finalize');
    },
    handleGoToPrev() {
      this.active = this.active === 0 ? 0 : this.active - 1;
    },
  },
  computed: {
    userOnboardingHasBeenFinished() {
      return !this.$root.onboarding;
    },
  },
};
</script>

<style lang="scss" scoped src="./OnboardingSteps.scss" />
