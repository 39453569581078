<script type="text/babel">
import Mismatch from './Mismatch.vue';

export default {
  props: ['record'],

  components: {
    Mismatch,
  },

  data() {
    return {
      list: [],
      questions: [],
      loading: false,
    };
  },

  mounted() {
    this.loading = true;
    axios.get('/api/v2/chatbot/' + this.record.id + '/mismatches').then((res) => {
      this.list = res.data;
      this.loading = false;
    });
    axios.get('/api/v2/chatbot/' + this.record.id + '/questions').then((res) => (this.questions = res.data));
  },

  methods: {
    onDestroyed(mismatch) {
      this.list.splice(this.list.map((item) => item.id).indexOf(mismatch.id), 1);
    },
  },
};
</script>

<template>
  <div class="pos-relative">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis">
            Mismatches
            <small class="text-muted">({{ list.length }})</small>
          </span>
        </li>
      </ul>
    </div>
    <div class="p-a p-b-0 container">
      <div v-show="list.length > 0" class="box">
        <div class="box-header">
          <h2>Mismatches</h2>
        </div>
        <div class="box-divider m-a-0"></div>
        <div class="box-body">
          <mismatch
            v-for="mismatch in list"
            :key="mismatch.id"
            :record="mismatch"
            :questions="questions"
            @unmounted="onDestroyed"
          ></mismatch>
        </div>
      </div>
      <div v-if="list.length == 0 && !loading" class="m-t-3 text-center">
        <div style="max-width: 50%; margin: 0 auto">
          <i class="fa fa-smile-o text-muted" style="font-size: 60px"></i>
          <p class="m-t-1 m-b-1 text-md">{{ $t('chatbot.your_chatbot_does_not_have_any_mismatches') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
