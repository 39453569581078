import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-80828d46"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  "data-test": "online-status-skeleton",
  class: "relative mt-6 grid w-full grid-cols-1 flex-row gap-x-6 xl:grid-cols-2"
}
const _hoisted_2 = { class: "hidden xl:block" }
const _hoisted_3 = {
  key: 0,
  "data-test": "no-agents-available",
  class: "empty-state absolute bottom-full top-0 flex h-full w-full flex-col items-center justify-center bg-transparent"
}
const _hoisted_4 = { class: "mb-5 rounded-xl border border-grey-500 p-2" }
const _hoisted_5 = { class: "mb-2" }
const _hoisted_6 = { class: "t-text-md-emphasize m-0 text-grey-800" }
const _hoisted_7 = { class: "m-0 text-grey-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_online_status_user_item = _resolveComponent("online-status-user-item")!
  const _component_calendar_person_linear = _resolveComponent("calendar-person-linear")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4], (skeleton) => {
        return _createVNode(_component_online_status_user_item, {
          key: skeleton,
          "data-test": "online-status-user-item",
          skeleton: true,
          animation: !_ctx.showError,
          "has-border": skeleton !== 4
        }, null, 8 /* PROPS */, ["animation", "has-border"])
      }), 64 /* STABLE_FRAGMENT */))
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4], (skeleton) => {
        return _createVNode(_component_online_status_user_item, {
          key: skeleton,
          "data-test": "online-status-user-item",
          skeleton: true,
          animation: !_ctx.showError,
          "has-border": skeleton !== 4
        }, null, 8 /* PROPS */, ["animation", "has-border"])
      }), 64 /* STABLE_FRAGMENT */))
    ]),
    (_ctx.showError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_calendar_person_linear, { size: "1.4rem" })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('reports_v2.no_agents_available')), 1 /* TEXT */)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('reports_v2.no_agents_available_description')), 1 /* TEXT */)
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}