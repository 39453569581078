<template>
  <div v-if="messages.length" class="toast-container hidden-lg-down p-4">
    <div
      v-for="(message, i) in messages"
      :key="i"
      class="animated slideInDown flex cursor-pointer hover:brightness-90"
      style="animation-duration: 0.2s"
      @mouseover="onMouseOver(message)"
      @mouseleave="onMouseLeave(message)"
    >
      <div
        class="toast shadow-full mb-4 flex min-w-0 select-none rounded-lg bg-black p-4 text-white"
        @click="clicked(message, $event)"
      >
        <avatar
          v-if="message.from"
          width="40"
          :color="message.from.color"
          :abbr="message.from.abbr"
          :image="message.from.profile_image"
        ></avatar>
        <div v-else>
          <img :src="`${$root.assetsURL}img/chatbot.png`" alt="chatbot" width="40" class="rounded-full" />
        </div>
        <div class="ml-3 min-w-0 flex-1 leading-none">
          <div class="mb-1 flex leading-none">
            <span v-if="message.from" class="_600 text-ellipsis">
              {{ message.from.full_name || message.from.name }}
            </span>
            <span v-else class="_600 text-ellipsis leading-none">Trengo</span>
            <i
              class="material-icons text-md -mr-2 -mt-2 ml-auto ml-auto text-grey-500"
              data-prevent-default
              @click="deleteMessage(message)"
            >
              close
            </i>
          </div>
          <div v-if="message.subject" class="mb-1 text-ellipsis leading-none text-grey-500">
            {{ message.subject }}
          </div>
          <div v-if="message.message" class="mb-1 flex min-w-0 items-center">
            <span
              class="min-w-0"
              :class="{ 'text-ellipsis leading-none': message.subject, 'max-2-lines': !message.subject }"
            >
              <span class="" :class="{ 'text-grey-400': message.subject, 'text-grey-500': !message.subject }">
                {{ message.message }}
              </span>
            </span>
            <div
              v-if="message.channel"
              class="ml-auto mt-auto flex min-w-0 flex-shrink-0 items-center pl-4 text-xs leading-none text-grey-500"
            >
              <span class="mr-1 text-ellipsis">{{ (message.channel || {}).title }}</span>
              <channel-icon class="text-sm" :channel="message.channel.type"></channel-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus';

import Avatar from './Avatar';
import ChannelIcon from './ChannelIcon';

export default {
  name: 'Toast',
  components: { ChannelIcon, Avatar },
  data() {
    return {
      messages: [],
      pause: false,
    };
  },
  mounted() {
    eventBus.$on('toast', (msg) => {
      this.messages.unshift(msg);
      this.messages = this.messages.slice(0, 5);
      this.setClearTimer(msg);
    });
  },
  unmounted() {
    eventBus.$off('toast');
  },
  methods: {
    onTimeout(msg) {
      if (this.pause) {
        return;
      }
      this.deleteMessage(msg);
    },
    deleteMessage(msg) {
      this.deleteClearTimer(msg);
      this.messages.splice(this.messages.indexOf(msg), 1);
    },
    clicked(msg, e) {
      if (e.target.hasAttribute('data-prevent-default')) {
        return;
      }
      this.deleteMessage(msg);
      if (msg.url) {
        if (msg.url.startsWith('event:')) {
          let p = msg.url.replace('event:', '').split(',');
          eventBus.$emit(p[0], p[1]);
        } else {
          vue_redirect(msg.url);
        }
      }
    },
    onMouseOver(message) {
      this.deleteClearTimer(message);
    },
    onMouseLeave(message) {
      this.setClearTimer(message);
    },
    setClearTimer(message) {
      this.deleteClearTimer(message);
      message.timeout = setTimeout(() => this.onTimeout(message), 3500);
    },
    deleteClearTimer(message) {
      if (!message.timeout) {
        return;
      }
      clearTimeout(message.timeout);
    },
  },
};
</script>

<style scoped>
.toast-container {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999999;
}

.toast {
  width: 300px;
}

.max-2-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 19px;
}
</style>
