<template>
  <div>
    <div class="row action flex flex-col py-4 md:flex-row">
      <div class="col-md-3 mb-1 md:mb-0">
        <div class="row">
          <div class="col-xs-2 pt-2 text-center" style="padding-right: 0">
            <i class="material-icons">help</i>
          </div>

          <div class="col-xs-10 pt-2">
            <span class="pt-2">{{ $t('widget_automation.page_helpcenter_select_widget') }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-2 mb-1 md:mb-0">
        <select v-model="record.web_widget_id" class="form-control">
          <option v-for="widget in widgets" :key="widget.id" :value="widget.id">
            {{ widget.title }}
          </option>
        </select>
      </div>
    </div>

    <div class="row action flex flex-col py-4 md:flex-row">
      <div class="col-md-3 mb-1 md:mb-0">
        <div class="row">
          <div class="col-xs-2 pt-2 text-center" style="padding-right: 0">
            <i class="material-icons">library_books</i>
          </div>

          <div class="col-xs-10 pt-2">
            <span class="pt-2">
              {{ $t('widget_automation.page_helpcenter_select_which_article_should_be_opened') }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-2 mb-1 md:mb-0">
        <select v-model="action.payload.articleId" :disabled="!isHelpCenter" class="form-control">
          <option v-for="article in publishedArticles" :key="article.id" :allow-empty="false" :value="article.id">
            {{ getTranslation(article).title }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpenHelpCenter',

  props: {
    action: Object,
    actionType: Object,
    record: Object,
  },

  data() {
    return {
      isHelpCenter: false,
      localeCode: null,
      articles: [],
      widgets: [],
    };
  },
  computed: {
    publishedArticles() {
      return this.articles.filter((article) => this.getTranslation(article).published === 1);
    },
  },
  mounted() {
    this.init();
  },

  methods: {
    init() {
      if (this.record.web_widget_id) {
        this.getWidgetHelpCenter(this.record.web_widget_id);
      }

      axios.get('/api/v2/web_widgets').then((res) => {
        this.widgets = res.data.data;
      });
    },

    async getWidgetHelpCenter(widget_id) {
      this.isHelpCenter = true;
      let res = await axios.get('/api/v2/web_widgets/' + widget_id);

      if (!res.data.helpCenterChannel?.id) {
        this.articles = [];
        this.isHelpCenter = false;
        return;
      }

      res = await axios.get('/api/v2/channels/' + res.data.helpCenterChannel.id);

      if (!res.data.helpCenter?.id) {
        return;
      }

      this.action.payload.helpCenterName = res.data.helpCenter.name;
      this.localeCode = res.data.helpCenter.default_language;

      axios
        .get(
          '/api/v2/help_center/' +
            res.data.helpCenter.id +
            '/articles?localeCode=' +
            res.data.helpCenter.default_language +
            '&term=&filter=null'
        )
        .then((res) => {
          this.articles = res.data;
        });
    },

    getTranslation(record) {
      var trans = record.translations.filter((trans) => {
        return trans.locale_code == this.localeCode;
      })[0];

      if (trans == null) {
        trans = {
          published: false,
          fresh: true,
          title: null,
        };
      }

      return trans;
    },
  },

  watch: {
    'action.payload.articleId'(v) {
      if (!v) {
        return (this.action.payload.articleName = null);
      }

      let article = this.articles.find((article) => {
        return article.id === v;
      });
      this.action.payload.articleName = this.getTranslation(article).title;
    },

    'record.web_widget_id'(v) {
      this.action.payload.articleName = null;
      this.action.payload.articleId = null;
      this.action.payload.helpCenterName = null;

      if (!v) {
        return;
      }

      this.getWidgetHelpCenter(v);
    },
  },
};
</script>
