import type { CustomTooltipItem } from '@/components/Reporting/Components/V2/Charts/types/CustomTooltipItem';
import type { TranslationFunction } from '@/types';

export type DataSet = (number | null)[];

export function getWorkloadManagementChartConfiguration(
  t: TranslationFunction,
  showError: boolean,
  isLoading: boolean
) {
  let title: string;
  let titleReturned = false;

  const titleCallback = (tooltipContext: CustomTooltipItem[]) => {
    //const day = getDayFromTooltipContext(tooltipContext[0], t);
    const day = tooltipContext[0].label;

    if (tooltipContext[0] && tooltipContext[1]) {
      //Means two lines are overlapping
      title = t('reports_v2.created_closed_conversations', { day });
    } else {
      if (tooltipContext[0].dataset.label === t('reports_v2.created_converstions')) {
        title = t('reports_v2.conversations_created_on', { day });
      } else {
        title = t('reports_v2.conversations_closed_on', { day });
      }
    }

    return title;
  };

  const labelCallback = (tooltipContext: CustomTooltipItem) => {
    //const day = getDayFromTooltipContext(tooltipContext, t);
    const day = tooltipContext.label;

    if (title === t('reports_v2.created_closed_conversations', { day }) && !titleReturned) {
      titleReturned = true;
      return tooltipContext.formattedValue;
    } else if (title !== t('reports_v2.created_closed_conversations', { day })) {
      titleReturned = false;
      return tooltipContext.formattedValue;
    } else {
      titleReturned = false;
      return false;
    }
  };

  return {
    scales: {
      x: {
        stacked: true,
        offset: true,
        ticks: {
          maxRotation: 90,
          autoSkip: false,
          suggestedMin: 0,
          padding: 8,
          color: '#000',
          font: {
            size: 12,
            lineHeight: 1,
          },
        },
        suggestedMin: 0,
        min: 0,
        display: true,
        grid: {
          drawTicks: false,
          display: true,
          color: '#E1E3E5',
          borderColor: '#E1E3E5',
          lineWidth: 1,
        },
        title: {
          display: false,
        },
        border: {
          color: '#E1E3E5',
          lineWidth: 1,
        },
        beginAtZero: true,
      },
      y: {
        ticks: {
          suggestedMin: 0,
          padding: 16,
          color: isLoading || showError ? 'transparent' : '#70767B',
          font: {
            size: 14,
          },
          callback: function (value: unknown, index: number) {
            if (index % 2 === 0) {
              return value;
            }
          },
        },
        grace: '20%',
        min: 0,
        suggestedMin: 0,
        grid: {
          drawTicks: false,
          display: true,
        },
        beginAtZero: true,
        title: {
          display: false,
        },
      },
    },
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          mode: 'xy' as const,
          speed: 100,
        },
        pan: {
          enabled: true,
          mode: 'xy' as const,
          speed: 100,
        },
      },
      ...(!isLoading
        ? {
            tooltip: {
              yAlign: 'bottom' as const,
              position: 'average' as const,
              backgroundColor: '#292C2E',
              titleAlign: 'center' as const,
              titleFont: { family: 'Inter', size: 14, style: 'normal', weight: 'bold' } as const,
              titleColor: '#FFF',
              bodyAlign: 'center' as const,
              bodyFont: { family: 'Inter', size: 24, weight: 'bold' },

              callbacks: {
                label: (tooltipContext: CustomTooltipItem) => labelCallback(tooltipContext),
                title: (tooltipContext: CustomTooltipItem[]) => titleCallback(tooltipContext),
              },

              padding: {
                left: 12,
                right: 12,
                top: 6,
                bottom: 6,
              },
              cornerRadius: 8,
              displayColors: false,
            },
          }
        : {
            tooltip: {
              enabled: false,
            },
          }),

      legend: {
        labels: {
          padding: 24,
          pointStyle: 'rectRounded',
          usePointStyle: true,
          font: {
            size: 14,
          },
        },
        display: true,
        position: 'bottom' as const,
        align: 'center' as const,
        onClick: null,
      },
    },
  };
}
