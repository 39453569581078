<script lang="ts">
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import { PERMISSION } from '@/Configs/Constants';
import { useUserStore } from '@/store/pinia';

import avatarSection from './AvatarSection';
import channelSection from './ChannelSection.vue';
import notes from './Notes.vue';
import profileCustomFieldEditor from './ProfileCustomFieldEditor.vue';
import inlineEditable from './ProfileNameEditable';
import timeline from '../Timeline.vue';

export default defineComponent({
  data() {
    return {
      loaded: false,
      profile: {
        notes: {},
        user: {},
      },
      selected: null,
      options: ['list', 'of', 'options'],
      processingAvatar: false,
      PERMISSION,
    };
  },

  props: {
    profile_id: {
      default: null,
    },
  },

  components: {
    avatarSection,
    inlineEditable,
    profileCustomFieldEditor,
    notes,
    channelSection,
    timeline,
  },

  computed: {
    ...mapStores(useUserStore),
  },

  mounted() {
    axios.get('/api/v2/profiles/' + this.getProfileId()).then((result) => {
      this.profile = result.data;
      this.loaded = true;
    });
  },

  methods: {
    ago(date) {
      return moment(date).format('DD/MM/YY');
    },

    updateAbbr(v) {
      this.profile.abbr = v.value.substr(0, 2);
    },

    async deleteProfile() {
      const shouldDeleteProfile = await this.$tConfirm('Are you sure you want to delete this profile?', {
        delete: true,
        title: this.$t('general.are_you_sure'),
      });
      if (shouldDeleteProfile) {
        axios.delete('/api/v2/profiles/' + this.getProfileId()).then(() => {
          router.push('/profiles');
        });
      }
    },

    getProfileId() {
      if (this.profile_id == null) {
        return this.$route.params.id;
      }

      return this.profile_id;
    },

    getReturnUrl() {
      return this.$router.back();
    },
  },
});
</script>

<template>
  <div class="w-full flex-1">
    <div v-if="loaded" class="w-full">
      <div class="mb-4 mt-4 flex flex-1 flex-wrap items-center lg:mb-8">
        <div class="flex min-w-0 flex-1 items-center pr-4">
          <div class="pl-4 lg:p-0">
            <a @click="getReturnUrl()"><i class="material-icons md-32">arrow_back</i></a>
          </div>
          <div class="mx-4">
            <avatar-section :profile="profile"></avatar-section>
          </div>
          <div class="text-ellipsis">
            <h6 id="ContactName" class="mb-1 text-ellipsis pb-0 leading-none">
              <inline-editable
                mode="inline"
                placement="top"
                :profile_id="profile.id"
                :value="profile.name"
                data-hj-suppress
                @saved="updateAbbr"
              ></inline-editable>
            </h6>
            <div class="text-muted text-ellipsis">
              {{ profile.identifier }}
            </div>
          </div>
        </div>
        <div class="hidden-md-down ml-auto flex flex-wrap">
          <a
            v-if="userStore.hasPermission(PERMISSION.CONTACTS__PROFILES__DELETE)"
            class="rounded bg-grey-200 px-4 py-2 text-grey-500"
            @click="deleteProfile"
          >
            <i class="fa fa-trash mr-1"></i>
            {{ $t('profile.delete') }}
          </a>
        </div>
        <div v-if="userStore.hasPermission(PERMISSION.CONTACTS__PROFILES__DELETE)" class="hidden-sm-up">
          <div class="nav-item dropdown">
            <a href="#" data-toggle="dropdown" class="mr-4">
              <i class="material-icons">more_horiz</i>
            </a>
            <div role="menu" class="dropdown-menu pull-right">
              <a class="dropdown-item" @click="deleteProfile()">
                <i class="fa fa-trash mr-1"></i>
                {{ $t('profile.delete') }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 flex flex-1 flex-wrap p-4 lg:mt-0 lg:p-0" data-hj-suppress>
        <div class="w-full pb-4 lg:w-1/3 lg:pr-6">
          <channel-section :profile="profile" type="FACEBOOK"></channel-section>
          <channel-section :profile="profile" type="EMAIL"></channel-section>
          <channel-section v-if="$root.waEnabled" :profile="profile" type="WHATSAPP"></channel-section>
          <channel-section :profile="profile" type="WA_BUSINESS"></channel-section>
          <channel-section :profile="profile" type="SMS"></channel-section>
          <channel-section :profile="profile" type="CHAT"></channel-section>
          <channel-section :profile="profile" type="VOIP"></channel-section>
          <channel-section :profile="profile" type="TELEGRAM"></channel-section>
          <channel-section :profile="profile" type="GBM"></channel-section>
          <channel-section :profile="profile" type="INSTAGRAM"></channel-section>
        </div>
        <div class="mt-2 w-full pb-4 lg:mt-0 lg:w-1/3 lg:pr-6">
          <h6 class="mb-4">{{ $t('profile.custom_fields') }}</h6>
          <div class="border-b border-grey-300 pb-4">
            <profile-custom-field-editor :profile="profile" :aligned="true"></profile-custom-field-editor>
          </div>
          <notes class="mt-8" :profile="profile"></notes>
        </div>
        <div class="mt-2 w-full pb-4 lg:mt-0 lg:w-1/3">
          <h6 class="mb-4">{{ $t('ticket_sidebar.contact_moments') }}</h6>
          <timeline :profile="profile"></timeline>
        </div>
      </div>
    </div>
  </div>
</template>
