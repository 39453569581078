import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "rounded-xl border border-solid border-grey-300 bg-grey-200 px-3 py-3" }
const _hoisted_2 = {
  key: 0,
  class: "mb-2 text-sm font-bold text-grey-700"
}
const _hoisted_3 = { class: "t-text-sm text-grey-60 mb-0" }
const _hoisted_4 = {
  key: 2,
  class: "t-text-xs mb-0 mt-2 text-grey-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_file_input = _resolveComponent("t-file-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.headerSubType === _ctx.TEMPLATE_HEADER_TYPE.TEXT.toUpperCase())
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.headerText), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    (_ctx.headerSubType === _ctx.TEMPLATE_HEADER_TYPE.IMAGE.toUpperCase())
      ? (_openBlock(), _createBlock(_component_t_file_input, {
          key: 1,
          class: "mb-2",
          value: _ctx.selectedImage,
          "force-validation": _ctx.forceFileValidation,
          "max-file-size": {
        size: _ctx.TEMPLATE_HEADER_FILE_LIMIT_IN_BYTES,
        text: _ctx.$t('whatsapp.image_sample_max_file_size', { fileSize: _ctx.maxFileSize }),
      },
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('handle-file-change', $event))),
          onReset: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('reset')))
        }, null, 8 /* PROPS */, ["value", "force-validation", "max-file-size"]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.body), 1 /* TEXT */),
    (_ctx.footer)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.footer), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ]))
}