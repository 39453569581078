import { onMounted, ref } from 'vue';

import { fetchNextPaymentEstimate } from '@/api/modules/subscriptionChargebee';
import { currencyHelpers, dateHelpers } from '@/util';

export const useChargebeeDuePayment = () => {
  const subscriptionDuePrice = ref('');
  const subscriptionDueDate = ref(''); // TODO: check if the same field inside pinia is needed
  const fetchDuePayment = async () => {
    try {
      const { data } = await fetchNextPaymentEstimate();
      subscriptionDuePrice.value = currencyHelpers.formatPrice(data?.amount_in_cents);
      subscriptionDueDate.value = dateHelpers.formatEpochDate(data?.next_billing_date);
    } catch (error) {
      console.error('Error fetching due payment', error);
    }
  };

  onMounted(() => {
    fetchDuePayment();
  });
  return { subscriptionDuePrice, subscriptionDueDate };
};
