<template>
  <div>
    <div class="mb-2 grid grid-cols-1 gap-2 lg:grid-cols-4">
      <modal-item
        variant="integration"
        :name="$t('apps_integration.modal_item_shopify')"
        type="shopify"
        :icon="getChannelIcon('shopify')"
        id="shopify"
      />
      <modal-item
        variant="integration"
        :name="$t('apps_integration.modal_item_woocommerce')"
        type="whoocommerce"
        :icon="getChannelIcon('woo')"
        id="Woocommerce"
      />
      <modal-item
        variant="integration"
        :name="$t('apps_integration.modal_item_slack')"
        type="slack"
        :icon="getChannelIcon('Slack')"
        id="slack"
      />
      <modal-item
        variant="integration"
        :name="$t('apps_integration.modal_item_hubspot')"
        type="hubspot"
        :icon="getChannelIcon('hubspot')"
        id="hubspot"
      />
    </div>
    <div class="mb-2 grid grid-cols-1 gap-2 lg:grid-cols-4">
      <modal-item
        variant="integration"
        :name="$t('apps_integration.modal_item_pipedrive')"
        type="pipedrive"
        :icon="getChannelIcon('pipedrive')"
        id="pipedrive"
      />
      <modal-item
        variant="integration"
        :name="$t('apps_integration.modal_item_lightspeed')"
        type="lightspeed"
        :icon="getChannelIcon('lightspeed')"
        id="lightspeed"
      />
      <modal-item
        variant="integration"
        :name="$t('apps_integration.modal_item_magento1')"
        type="magento1"
        :icon="getChannelIcon('magento')"
        id="magento"
      />
      <modal-item
        variant="integration"
        :name="$t('apps_integration.modal_item_magento2')"
        type="magento"
        :icon="getChannelIcon('magento')"
        id="magento2"
      />
    </div>
    <div class="mb-2 grid grid-cols-1 gap-2 lg:grid-cols-4">
      <modal-item
        variant="integration"
        :name="$t('apps_integration.modal_item_bol_com')"
        type="bol.com"
        :icon="getChannelIcon('bolcom')"
        id="bol.com"
      />
      <modal-item
        variant="integration"
        :name="$t('apps_integration.modal_item_picqer')"
        type="picqer"
        :icon="getChannelIcon('picqer')"
        id="picqer"
      />
      <modal-item
        variant="integration"
        :name="$t('apps_integration.modal_item_twilio')"
        type="twilio"
        :icon="getChannelIcon('twilio')"
        id="twilio"
      />
      <modal-item
        variant="integration"
        :name="$t('apps_integration.modal_item_zapier')"
        type="zapier"
        :icon="getChannelIcon('zapier')"
        id="zapier"
      />
    </div>
  </div>
</template>

<script>
import ModalItem from '../../ModalItem';
export default {
  name: 'IntegrationsList',
  components: { ModalItem },
  methods: {
    getChannelIcon(type) {
      return `${this.$root.assetsURL}img/integrations/${type.toLowerCase()}.svg`;
    },
  },
};
</script>
