<template>
  <div v-show="computedValue" ref="get_started_popup" class="fade-in get-started-popup-wrapper shadow-sm">
    <div class="relative px-4 py-3 sm:p-6">
      <div class="align-center flex flex items-center justify-center pb-3 sm:justify-between">
        <div class="t-text-desktop-h6">Explore Trengo</div>
        <div class="absolute right-4 cursor-pointer sm:relative sm:right-0 sm:right-0" @click="handleCloseModal">
          <cross-linear :size="iconSize" />
        </div>
      </div>
      <div class="divider sm:hidden" />

      <div class="pb-6 pt-6 sm:pt-0">
        <t-progress :percentage="percentage" />
      </div>

      <div class="pb-6">
        <popup-item step="user.create_account" text="Create your account" />
        <popup-item step="user.assigned_first_ticket" text="Assign your first conversation" />
        <popup-item step="user.internal_comment_on_first_ticket" text="Leave internal comment" />
        <popup-item step="user.reply_conversation" text="Reply a conversation" />
        <popup-item step="user.archived_ticket" text="Close your first conversation" />
        <popup-item
          v-if="userStore.hasPermission(PERMISSION.SETTINGS__CHANNELS__MANAGE)"
          step="user.add_first_channel"
          text="Connect your first channel"
        />
        <popup-item step="user.invite_first_team_member" text="Invite a team member" last />
        <div class="divider mt-6 sm:hidden" />
      </div>

      <div class="mb-3 mt-6 pt-1 sm:mb-0 sm:mt-0 sm:pt-0">
        <atomic-button :btn-style="buttonType" class="overview-button" @click="handleClickSeeOverview">
          <div class="t-text-desktop-label-sm">See overview</div>
        </atomic-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CrossLinear } from '@trengo/trengo-icons';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { PERMISSION } from '@/Configs/Constants';
import ONBOARDING_STEPS from '@/Configs/Constants/OnboardingSteps';
import { useUserStore } from '@/store/pinia';
import breakpoints from '@/util/breakpoints';

import PopupItem from './components/PopupItem';

export default defineComponent({
  name: 'GetStartedPopup',
  emits: ['update:modelValue'],
  components: { PopupItem, CrossLinear },
  data() {
    return {
      breakpoints,
      onboaridngSteps: [],
      percentage: 0,
      PERMISSION,
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleClickOutSide(e) {
      const popUpDOM = this.$refs.get_started_popup;
      if (!popUpDOM.contains(e?.target) && this.computedValue) {
        this.handleCloseModal();
      }
    },
    handleEscapePressed(e) {
      if (e.keyCode === 27 || e.key === 'Escape') {
        this.handleCloseModal();
      }
    },
    calculatePercentage() {
      const steps = this.getSteps;
      let completedSteps = [];
      steps.forEach((step) => {
        if (!ONBOARDING_STEPS.includes(step.type)) {
          return;
        }
        const onboardingStepsState = step?.completed;
        if (onboardingStepsState) {
          completedSteps.push(step);
        }
      });
      this.percentage = Math.floor((completedSteps.length * 100) / ONBOARDING_STEPS.length);
    },
    handleCloseModal() {
      this.computedValue = false;
    },
    handleStepCompleted(step) {
      return this.getStepByType(step)?.completed;
    },
    closeJqueryModals() {
      const getAllModalCloseButtons = document?.getElementsByClassName('modal-close-btn');
      getAllModalCloseButtons?.forEach((modal) => {
        modal?.click();
      });
    },
    handleClickSeeOverview() {
      this.$router.push('/admin/get-started');
      this.handleCloseModal();
      this.closeJqueryModals();
    },
  },
  mounted() {
    document.addEventListener('keyup', this.handleEscapePressed);
    document.addEventListener('click', this.handleClickOutSide);
  },
  unmounted() {
    document.removeEventListener('keyup', this.handleEscapePressed);
    document.removeEventListener('click', this.handleClickOutSide);
  },
  computed: {
    ...mapStores(useUserStore),
    ...mapGetters({
      getSteps: 'gamification/getSteps',
    }),
    iconSize() {
      return this.isLgOrXl ? '1rem' : '1.5rem';
    },
    buttonType() {
      return this.isLgOrXl ? 'secondary' : 'primary';
    },
    isLgOrXl() {
      const { comparisons } = this.breakpoints;
      return comparisons.eq_md || comparisons.eq_lg || comparisons.eq_xl;
    },
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  watch: {
    $route() {
      this.handleCloseModal();
    },
    modelValue: {
      handler() {
        this.calculatePercentage();
      },
      immediate: true,
    },
  },
});
</script>
<style scoped src="./GetStartedPopup.scss" lang="scss" />
