import localforage from 'localforage/dist/localforage.nopromises';

localforage.config({
  driver: localforage.LOCALSTORAGE,
  name: 'trengo',
  version: 1.0,
  storeName: 'tStorage', // Should be alphanumeric, with underscores.
  description: 'Trengo Storage',
});

/**
 * @description Localstorage wrapper
 *
 * @type {{clear(): *, getItem(string): *, store(): *, setItem(string, (string|Object|boolean|number)): *, removeItem(*): *}}
 */
const tStorage = {
  /**
   * @description set value to localStorage
   *
   * @param {string} key
   * @param {string|object|boolean|number} value
   * @returns {Promise}
   */
  setItem(key, value) {
    return localforage.setItem(key, value);
  },
  /**
   * @description get value from localStorage
   *
   * @param {string} key
   * @returns {Promise}
   */
  getItem(key) {
    return localforage.getItem(key);
  },
  /**
   * @description remove value from localStorage
   * @param key
   * @returns {Promise}
   */
  removeItem(key) {
    return localforage.removeItem(key);
  },
  /**
   * @description clear localStorage
   * @warning this will clear all localStorage
   * @returns {Promise}
   */
  clear() {
    return localforage.clear();
  },
  /**
   * @description store localStorage
   *
   * @returns {Promise}
   */
  getAll() {
    return localforage.iterate;
  },
};

export default tStorage;
