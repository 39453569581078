<template>
  <div class="row-col">
    <div>
      <span class="text-md p-y-1 white p-x-1 b-b hidden-md-up text-ellipsis">
        <a data-toggle="modal" data-target="#settings-third" class="pull-left">
          <span class="btn btn-sm btn-icon white m-r-1">
            <i class="fa fa-list"></i>
          </span>
        </a>

        Plugins
      </span>
    </div>
    <div class="row-col">
      <div class="row-col">
        <div id="settings-third" class="col-xs modal fade aside aside-md b-r w-medium w-200px">
          <div class="nav-settings light left b-primary row-col">
            <ul>
              <li class="text-md white header">
                <strong>Apps</strong>
                <a data-dismiss="modal" class="hidden-md-up text-muted m-l-auto m-r-sm m-l-1 text-lg">×</a>
                <a class="m-l-auto btn btn-icon btn-sm success rounded text-white">
                  <i class="material-icons md-18" @click="navigateToCreate">add</i>
                </a>
              </li>
            </ul>
            <div class="row-row">
              <div class="row-body scrollable hove">
                <div class="row-inner">
                  <ul>
                    <li v-for="item in plugins">
                      <router-link
                        class="nav-settings-link text-truncate"
                        data-dismiss="modal"
                        :to="getItemRoute(item.id)"
                        :class="{ active: $route.params.id === item.id }"
                      >
                        {{ item.name }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-col">
          <router-view v-if="loaded" :key="$route.params.id" @call-loadlist-method="loadList"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

export default {
  data() {
    return {
      loaded: false,
      plugins: {},
    };
  },

  mounted() {
    this.loadList(false);
  },

  computed: {
    redirectRoute() {
      if (useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.INTEGRATION_HUB)) {
        return '/admin/integrations/plugins/';
      }

      return '/admin/developers/plugins/';
    },
  },

  methods: {
    loadList(reload_root = true) {
      axios.get('/api/v2/plugins').then((result) => {
        this.plugins = result.data.data;
        this.loaded = true;

        if (reload_root) {
          this.$root.plugins = this.plugins;
        }
      });
    },

    getItemRoute(itemId) {
      return this.redirectRoute + itemId;
    },

    navigateToCreate() {
      this.$router.push(`${this.redirectRoute}create`);
    },
  },
};
</script>
