<template>
  <div v-if="!showCreateChannelModal" id="channel-created-modal" ref="modal" class="modal fade" role="dialog">
    <div class="modal-centered modal-dialog">
      <button
        type="button"
        class="modal-close-btn"
        data-dismiss="modal"
        aria-label="Close"
        @click="handleClearOnBoarding"
      >
        <i class="material-icons">close</i>
      </button>

      <div class="index-icon" :class="`bg-${computedColor.color}`">
        <img class="w-12" alt="inbox-in" :src="`${$root.assetsURL}img/inbox-in-filled.svg`" />
        <div
          v-if="isOnboarding"
          class="icon_badge align-center absolute flex flex-row justify-center rounded border border-white bg-white p-0"
        >
          <img :src="getChannelIcon(badge)" :alt="badge" />
        </div>
        <div v-else class="circle">
          <i class="material-icons">check</i>
        </div>
      </div>

      <div class="content mt-12 text-center">
        <h2 class="title font-bold">{{ computedTitle }}</h2>
        <div class="desc mb-12 mt-4 text-base font-medium" :class="{ 'text-xl text-grey-800': isOnboarding }">
          <span v-html="computedDescription" />
        </div>

        <div class="actions mx-auto">
          <div @click.prevent="isOnboarding ? openCreateChannelModal() : openInboxWithChannelFilter()">
            <span v-if="isOnboarding">
              <atomic-button class="inbox-btn hidden flex-row items-center justify-center p-1 lg:flex" size="lg">
                Add more channels
                <i class="far fa-arrow-right ml-2 text-sm text-white"></i>
              </atomic-button>
              <atomic-button class="inbox-btn block flex flex-row lg:hidden">
                Add more channels
                <i class="far fa-arrow-right ml-2 text-sm text-white"></i>
              </atomic-button>
            </span>
            <span v-else>
              <atomic-button class="inbox-btn hidden lg:block" size="lg">See emails in inbox</atomic-button>
              <atomic-button class="inbox-btn block lg:hidden">See emails in inbox</atomic-button>
            </span>
          </div>

          <div
            v-if="isOnboarding"
            class="mt-8 flex cursor-pointer items-center justify-center text-lg font-semibold"
            @click="openInboxWithChannelFilter"
          >
            <span>Go to the inbox</span>
          </div>
          <router-link
            v-else
            class="mt-8 flex items-center justify-center font-semibold"
            :to="`/admin/channels2/email/${createdId}`"
          >
            Finish setting up your channel
            <i class="material-icons ml-2 text-sm text-black">east</i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { capitalize } from 'lodash';

import { CHANNEL_TYPE } from '@/Configs/Constants';
import eventBus from '@/eventBus';

export default {
  name: 'ChannelCreatedModal',
  data() {
    return {
      showCreateChannelModal: false,
    };
  },
  props: {
    badge: {
      type: String,
      default() {
        return CHANNEL_TYPE.EMAIL;
      },
    },
    color: {
      type: String,
      default: 'sky-100',
    },
    route: {
      type: String,
      default: '/tickets',
    },
  },
  computed: {
    isBulk() {
      return this.$route.query.isBulk === 'true';
    },
    isOnboarding() {
      return this.$root.isInOnboardingFlow;
    },
    createdId() {
      if (this.$route.query.createdId) {
        eventBus.$emit('user-add-first-channel');
      }
      return this.$route.query.createdId;
    },
    computedTitle() {
      return this.isBulk ? 'Channel created and Messages are being imported!' : 'Channel created!';
    },
    computedDescription() {
      return this.isBulk
        ? `In a couple of minutes you can manage all your ${this.badge.toLowerCase()} messages directly in Trengo, making your day-to-day easier and more productive.`
        : this.isOnboarding
        ? `From now on, you can manage all your <span class="font-semibold">${capitalize(
            this.badge
          )}</span> messages directly in Trengo, making your day-to-day easier and more productive.`
        : 'From now on, you can manage all emails directly in Trengo, making your day-to-day easier and more productive.';
    },
    computedColor() {
      const badge = this.badge;
      const {
        WHATSAPP,
        TELEGRAM,
        FACEBOOK,
        CHAT,
        CUSTOM,
        EMAIL,
        SMS,
        VOIP,
        HELP_CENTER,
        INSTAGRAM,
        WA_BUSINESS,
        GBM,
        ZIPWHIP,
        VOYS,
      } = CHANNEL_TYPE;
      const skyColors = [EMAIL, FACEBOOK, TELEGRAM, HELP_CENTER];
      const galaxyColors = [SMS, CUSTOM, VOIP, VOYS, ZIPWHIP];
      const purpleColors = [CHAT];
      const successColors = [WHATSAPP, WA_BUSINESS];
      const sunColors = [INSTAGRAM, GBM];
      let color = {
        ...(skyColors.includes(badge) && { color: 'sky-100' }),
        ...(galaxyColors.includes(badge) && { color: 'galaxy-100' }),
        ...(purpleColors.includes(badge) && { color: 'purple-100' }),
        ...(successColors.includes(badge) && { color: 'success-100' }),
        ...(sunColors.includes(badge) && { color: 'sun-100' }),
      };
      color = color ? color : 'sky-100';

      return color;
    },
  },
  mounted() {
    if (this.createdId && this.badge === CHANNEL_TYPE.EMAIL) {
      $('#channel-created-modal').modal('show');
    }
  },
  methods: {
    handleRequestFinalize() {
      axios
        .post('/client-api/onboarding/finalize')
        .then(() => {})
        .catch(() => {});
    },
    handleClearOnBoarding() {
      this.handleRequestFinalize();
      this.$root.isInOnboardingFlow = false;
    },
    getChannelIcon(channelType) {
      return `${this.$root.assetsURL}img/channels_icons/${channelType.toLowerCase()}.svg`;
    },
    openCreateChannelModal() {
      eventBus.$emit('showOnBoardingModal');
      $('#channel-created-modal').modal('hide');
    },
    openInboxWithChannelFilter() {
      const channelId = this.createdId;
      this.$root.isInOnboardingFlow = false;
      router.push(this.route);
      this.$nextTick(() => {
        this.handleRequestFinalize();
        eventBus.$emit('closeOnBoardingModals');
        eventBus.$emit('tickets.filters.apply', ...['channels', channelId]);
      });
    },
  },
};
</script>

<style scoped lang="scss" src="./ChannelCreatedModal.scss" />
