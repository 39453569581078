<template>
  <a
    v-tooltip="{ popperClass: 'chat_sidebar', placement: 'left', content: `${user.first_name} ${user.last_name}` }"
    class="relative"
    :class="{ 'cursor-default': !chatEnabled }"
    @click.prevent="onClick"
  >
    <avatar :color="user.color" :abbr="user.abbr" :image="user.profile_image" />

    <div
      v-if="unreadMessageCount === 0 && user.voip_status !== null"
      class="absolute left-6 top-1 h-fit rounded-full p-1 text-white"
      :style="{ background: voipColor }"
    >
      <div class="h-fit w-full">
        <phone-fill size="0.5rem" />
      </div>
    </div>

    <div
      v-else-if="unreadMessageCount > 0 && chatEnabled"
      class="absolute left-6 top-1 rounded-full px-1.5 text-white"
      style="background: red"
    >
      <p class="m-0 p-0 text-[70%] font-bold">{{ unreadMessageCount }}</p>
    </div>
  </a>
</template>

<script lang="ts">
import { PhoneFill } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import Avatar from '@/components/Avatar.vue';

import type { User } from '@/types';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'UserIndicator',

  components: { PhoneFill, Avatar },

  props: {
    user: {
      type: Object as PropType<User>,
      default: () => ({}),
    },
    chatEnabled: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters({ threadsByUserId: 'chat/threadByUserId' }),

    unreadMessageCount(): number {
      return this?.threadsByUserId(this?.user?.id)?.getUnreadMessageCount() ?? 0;
    },

    userThreadIdentifier(): string {
      return this?.threadsByUserId(this?.user?.id)?.identifier ?? '';
    },

    voipColor(): 'green' | 'orange' | 'red' {
      switch (this.user.voip_status) {
        case 'ONLINE':
          return 'green';
        case 'CALLING':
          return 'orange';
        case 'OFFLINE':
        default:
          return 'red';
      }
    },
  },

  methods: {
    onClick() {
      if (this.chatEnabled) {
        this.$router.push(`/chat/${this.userThreadIdentifier}`);
      }
    },
  },
});
</script>
