<template>
  <atomic-popup v-if="videoCall.message" class="video-call select-none" style="top: 45%">
    <div v-if="thread.isUser()" class="text-center">
      <avatar
        :color="user.color"
        :abbr="user.abbr"
        :image="user.profileImage"
        :width="100"
        :height="100"
        :font-size="'38px'"
        class="white mb-4"
      />
    </div>
    <div v-if="thread.isGroup()" class="header flex justify-center bg-grey-800 text-center" style="height: 175px">
      <atomic-icon class="fa-hashtag flex items-center text-grey-500" :size="'6em'"></atomic-icon>
    </div>

    <div class="bg-black px-6 pb-8 pt-6 text-center font-medium text-white" style="font-size: 18px">
      <strong>{{ thread.name }}</strong>
      <template v-if="thread.isUser()">
        {{ $t('internal_chat.started_a_meeting_via', { name: this.meetingAppName }) }}
      </template>
      <template v-if="thread.isGroup()">
        {{ $t('internal_chat.meeting_started_via', { name: this.meetingAppName }) }}
      </template>
    </div>

    <div class="inline-flex w-full justify-around">
      <button
        class="btn flex items-center justify-center bg-red"
        role="button"
        aria-label="End call"
        @click="hidePopup('declined')"
      >
        <atomic-icon class="fa-phone-slash fa-lg" icon="fa-phone-slash" color="white" :size="'1.33em'"></atomic-icon>
      </button>

      <a
        v-if="isZoom"
        :href="videoCall.room"
        target="_blank"
        class="btn open-external items-center justify-center bg-green"
        style="display: flex"
        role="button"
        aria-label="Accept call"
        @click="hidePopup('accept')"
      >
        <atomic-icon class="fa-phone-alt" icon="fa-phone-alt" color="white" :size="'1.33em'"></atomic-icon>
      </a>
      <a
        v-else
        target="_blank"
        class="btn items-center justify-center bg-green"
        style="display: flex"
        role="button"
        aria-label="Accept call"
        @click="acceptButton"
      >
        <atomic-icon class="fa-phone-alt" icon="fa-phone-alt" color="white" :size="'1.33em'"></atomic-icon>
      </a>
    </div>
  </atomic-popup>
</template>

<script>
import { mapGetters } from 'vuex';

import eventBus from '@/eventBus';
import pusherHelper from '@/util/PusherHelper';

import VideoCallingMixin from '../../../mixins/VideoCalling';
import { waitForEvent } from '../../../start/util';
import Avatar from '../../Avatar';

const RINGING_SECONDS = 16;

export default {
  name: 'VideoCallPopup',
  components: { Avatar },
  data() {
    return {
      videoCall: {
        message: null,
        room: null,
        userId: null,
        audio: null,
        timeout: null,
      },
    };
  },
  mixins: [VideoCallingMixin],
  computed: {
    ...mapGetters({
      userById: 'usersInternalChat/userById',
    }),
    isZoom() {
      return this.videoCall.message.bodyType === 'ZOOM_CALL';
    },
    user() {
      return this.userById(this.videoCall.userId);
    },
    thread() {
      return this.videoCall.message?.getThread();
    },
    meetingAppName() {
      return this.isZoom ? 'Zoom' : 'Trengo';
    },
  },
  mounted() {
    Object.assign(this.videoCall, {
      audio: new Audio('https://trengo.s3.eu-central-1.amazonaws.com/videocalling/audio/ringing.mp3'),
    });
    this.videoCall.audio.pause();
    this.videoCall.audio.loop = true;

    eventBus.$on('chat@VIDEO_CALL_STARTED', async ({ message, userId, room }) => {
      await this.unbind();

      Object.assign(this.videoCall, {
        message,
        userId,
        room,
      });

      this.playAudio();

      clearTimeout(this.videoCall.timeout);
      this.videoCall.timeout = setTimeout(this.hidePopup, RINGING_SECONDS * 1000);

      // wait for app to be loaded
      await waitForEvent('initial-data.loaded', this.$store.state.initialData.user.id);

      // close in all tabs when closing modal
      this.$nextTick(() => {
        if (this.thread) {
          pusherHelper.bindPusherEvent(this.thread.pusherChannel, 'client-video-call-response', this.onResponse);
        }
      });
    });
  },
  beforeUnmount() {
    this.unbind();
  },
  methods: {
    async unbind() {
      if (this.thread) {
        return pusherHelper.unbindPusherEvent(this.thread.pusherChannel, 'client-video-call-response', this.onResponse);
      }
    },
    onResponse(response, meta) {
      if (parseInt(meta.user_id) === this.$store.state.usersInternalChat.currentUserId) {
        this.hidePopup();
      }
    },
    acceptButton() {
      this.joinTrengoVideoRoom(this.videoCall.room);
      this.hidePopup('accept');
    },
    hidePopup(response = null) {
      this.stopAudio();

      clearTimeout(this.videoCall.timeout);

      // if accept or decline, send it to all participants of the thread
      if (response && this.thread) {
        this.thread.pusherChannel.trigger('client-video-call-response', {
          action: response,
          videoCall: this.videoCall,
        });
        if (response === 'declined') {
          axios.put(`/api/v2/video_calling/rooms/${this.videoCall.room}/participantStatus`, { status: 'declined' });
        }
      }

      Object.assign(this.videoCall, {
        message: null,
        userId: null,
        room: null,
      });
    },
    stopAudio() {
      this.videoCall.audio.pause();
    },
    playAudio() {
      if (this.$root.user.voip_status === 'CALLING') {
        return;
      }

      this.videoCall.audio.play().catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.video-call {
  padding: 2.25rem;
  background: theme('colors.black');
  width: 300px;
  border-radius: 40px;
  .header {
    height: 155px;
    border-radius: 20px;
  }
  img {
    border-radius: 20px;
  }
  button,
  a {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    padding: 0;
    line-height: 0;
  }
}
</style>
