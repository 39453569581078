<template>
  <div class="mx-auto ml-16 flex flex-col">
    <div class="mb-4 mt-8 flex flex-row items-center">
      <button
        class="mr-6 rounded-full p-1.5 transition-colors hover:bg-grey-300"
        @click.prevent="$router.push({ name: 'webhooks' })"
      >
        <arrow-left-linear size="1.3rem" />
      </button>

      <h1 v-if="action === 'edit'" class="t-text-h4 m-0 p-0 text-grey-800">{{ webhook.title }}</h1>
      <h1 v-else class="t-text-h4 m-0 p-0 text-grey-800">{{ $tc('integration_hub.create_new_webhook') }}</h1>
    </div>

    <form @submit.prevent.stop>
      <t-card class="flex flex-col bg-white">
        <div :class="{ 'mb-6': !errors.url }">
          <t-input-text
            id="url"
            v-model="webhook.url"
            :label="$t('apps_integration.url')"
            :allow-clear="false"
            size="sm"
            placeholder="https://example.com"
            :required="true"
            type="url"
            :has-error="errors.url"
            @keydown.enter.prevent="saveWebhook"
            @blur="checkUrl"
          />
          <t-error-item
            v-if="errors.url"
            :text="$tc('integration_hub.error_url_must_be_valid')"
            danger-icon
            class="mb-6"
          />
        </div>

        <label for="event" class="font-bold text-grey-800">{{ $t('apps_integration.event') }}</label>
        <t-dropdown
          id="event"
          v-model="webhook.type"
          :items="dropdownItems"
          value-index="value"
          text-index="title"
          size="sm"
          :class="{ 'mb-6': !errors.event }"
          :error="true"
          @input="checkEvent"
        />
        <t-error-item
          v-if="errors.event"
          :text="$tc('integration_hub.error_event_must_select')"
          danger-icon
          class="mb-6"
        />
        <div class="mb-6">
          <t-input-text
            v-if="action === 'edit' && webhook.signing_secret"
            id="signing_secret"
            v-model="webhook.signing_secret"
            disabled
            :label="$t('apps_integration.signing_secret')"
            size="sm"
          />
        </div>

        <t-inline-banner type="default" class="mb-6">
          <div class="flex flex-row items-center">
            <div class="mr-2 flex-shrink-0">
              <info-linear size="1.5rem" />
            </div>

            <p
              class="t-text-sm m-0 mr-2 p-0 text-grey-800"
              v-html="$t('apps_integration.check_trengo_documentation_for_webhook')"
            />

            <t-button
              btn-style="secondary"
              class="ml-auto flex w-fit flex-shrink-0 flex-row items-center"
              @click.prevent="openDocumentation"
              @keydown.enter.prevent.stop
            >
              {{ $tc('integration_hub.check_documentation') }}
              <arrow-right-linear size="1.25rem" class="ml-1" />
            </t-button>
          </div>
        </t-inline-banner>

        <div class="flex flex-row">
          <t-button :disabled="isSaving" :class="{ loader: isSaving }" @click.prevent="saveWebhook">
            {{ $t('apps_integration.save_changes') }}
          </t-button>

          <t-button
            v-if="action === 'edit'"
            :class="{ loader: isSaving }"
            btn-style="danger-alt"
            class="ml-3"
            :disabled="isSaving"
            @click.prevent="deleteWebhook"
          >
            {{ $t('apps_integration.delete_webhook') }}
          </t-button>
        </div>
      </t-card>
    </form>
  </div>
</template>

<script lang="ts">
import { InfoLinear, ArrowRightLinear, ArrowLeftLinear } from '@trengo/trengo-icons';
import axios from 'axios';
import { cloneDeep, isEqual } from 'lodash';
import validUrlLibrary from 'valid-url';
import { defineComponent } from 'vue';

import type { Webhook } from '@/types';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'WebhookEdit',

  components: {
    InfoLinear,
    ArrowRightLinear,
    ArrowLeftLinear,
  },

  props: {
    action: {
      type: String as PropType<'create' | 'edit'>,
      default: 'edit',
    },
  },

  data() {
    return {
      webhook: {} as Webhook,
      initialWebhook: {} as Webhook,
      isSaving: false,
      errors: {
        url: false,
        event: false,
      },
      dropdownItems: [
        { title: this.$tc('apps_integration.inbound_message'), value: 'INBOUND' },
        { title: this.$tc('apps_integration.outbound_message'), value: 'OUTBOUND' },
        { title: this.$tc('apps_integration.internal_message'), value: 'NOTE' },
        { title: this.$tc('apps_integration.label_added_to_ticket'), value: 'TICKET_LABEL_ADDED' },
        { title: this.$tc('apps_integration.label_removed_from_ticket'), value: 'TICKET_LABEL_DELETED' },
        { title: this.$tc('apps_integration.ticket_assigned'), value: 'TICKET_ASSIGNED' },
        { title: this.$tc('apps_integration.ticket_closed'), value: 'TICKET_CLOSED' },
        { title: this.$tc('apps_integration.ticket_reopened'), value: 'TICKET_REOPENED' },
        { title: this.$tc('apps_integration.ticket_marked_as_spam'), value: 'TICKET_MARKED_AS_SPAM' },
        { title: this.$tc('apps_integration.ticket_unmarked_as_spam'), value: 'TICKET_UNMARKED_AS_SPAM' },
        { title: this.$tc('apps_integration.voice_call_started'), value: 'VOICE_CALL_STARTED' },
        { title: this.$tc('apps_integration.voice_call_ended'), value: 'VOICE_CALL_ENDED' },
        { title: this.$tc('apps_integration.voice_call_recorded'), value: 'VOICE_CALL_RECORDED' },
        { title: this.$tc('apps_integration.voice_call_missed'), value: 'VOICE_CALL_MISSED' },
        { title: this.$tc('apps_integration.voice_call_ivr_route'), value: 'VOICE_CALL_ROUTE_NUMBER' },
      ],
    };
  },

  computed: {
    isDirty() {
      return !isEqual(this.webhook, this.initialWebhook);
    },

    hasErrors() {
      return Object.values(this.errors).some((item) => item);
    },
  },

  mounted() {
    if (this.action === 'edit' && this.$route.params.id) {
      axios.get(`/api/v2/webhooks/${this.$route.params.id}`).then((result) => {
        this.webhook = result.data;
        this.initialWebhook = cloneDeep(this.webhook);
      });
    }
  },

  methods: {
    openDocumentation() {
      window.open('https://developers.trengo.com/docs/webhooks', '_blank');
    },

    saveWebhook() {
      this.checkUrl();
      this.checkEvent();

      if (this.isSaving || this.hasErrors) {
        return;
      }

      this.isSaving = true;

      this.action === 'create' ? this.createWebhook() : this.updateWebhook();
    },

    async createWebhook() {
      axios
        .post('/api/v2/webhooks', this.webhook)
        .then(() => {
          // Clean initial state so it doesn't trigger the dirty state warning before redirecting
          this.initialWebhook = this.webhook;

          this.flashSuccess(this.$t('apps_integration.the_webhook_has_been_created_successfully'));
          this.$router.push({ name: 'webhooks' });
        })
        .catch((err) => {
          console.error(err);
          this.flashError(err?.message);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },

    async updateWebhook() {
      axios
        .put(`/api/v2/webhooks/${this.webhook.id}`, this.webhook)
        .then(() => {
          this.initialWebhook = this.webhook;
          this.flashSuccess(this.$t('apps_integration.the_webhook_has_been_updated_successfully'));
        })
        .catch((err) => {
          console.error(err);
          this.flashError(err.message);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },

    async deleteWebhook() {
      axios.delete(`/api/v2/webhooks/${this.webhook.id}`).then(() => {
        this.$router.push({ name: 'webhooks' });
        this.flashSuccess(this.$t('apps_integration.the_webhook_has_been_deleted_successfully'));
      });
    },

    checkUrl() {
      this.errors.url = !validUrlLibrary.isUri(this.webhook.url);
    },

    checkEvent() {
      this.errors.event = this.webhook.type === undefined || this.webhook.type === '';
    },
  },

  beforeRouteLeave(_to, _from, next) {
    if (this.isDirty) {
      if (window.confirm(this.$t('general.warning_text_leaving_page_unsaved_changes'))) {
        next();
      } else {
        return;
      }
    } else {
      next();
    }
  },
});
</script>
