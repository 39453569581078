<template>
  <div
    data-test="load-balancing"
    class="
      my-1
      flex flex-row
      items-start
      rounded-md
      border-1 border-grey-300
      bg-white
      px-4
      py-3
      sm:mb-0 sm:items-center
    "
  >
    <div class="flex h-8 w-8 items-center justify-center rounded-xl bg-sky-200 p-2">
      <load-balance-linear class="text-sky-700" size="1rem" />
    </div>
    <div class="ml-4 flex flex-col items-start sm:flex-row sm:items-center">
      <div class="mr-4 flex flex-col">
        <div class="work-load-wrapper">
          <div class="t-text-desktop-label-xs sm:t-text-desktop-label-sm">
            {{ $t('team.workload_balancing_title') }}
          </div>
        </div>
        <div class="sm:t-text-desktop-paragraph-xs t-text-desktop-paragraph-sm text-grey-600">
          {{ $t('team.workload_balancing_slogan') }}
          <a
            target="_blank"
            href="https://help.trengo.com/en/articles/329574-setting-up-workload-balancing"
            class="open-external underline"
          >
            {{ $t('team.learn_more') }}
          </a>
        </div>
      </div>
      <t-button
        :btn-style="isEnabled ? 'danger-alt' : 'secondary'"
        class="mt-2 sm:mt-0"
        data-test="load-balancing-enable-disable"
        size="md"
        @click="handleToggleWorkLoadBalancing()"
      >
        {{ workLoadButtonStatusTitle }}
      </t-button>
    </div>
  </div>
</template>

<script>
import { LoadBalanceLinear } from '@trengo/trengo-icons';

export default {
  name: 'WorkloadBalancingToggle',
  emits: ['click'],
  props: {
    isEnabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { LoadBalanceLinear },
  methods: {
    handleToggleWorkLoadBalancing() {
      this.$emit('click');
    },
  },
  computed: {
    workLoadButtonStatusTitle() {
      return this.isEnabled
        ? this.$t('team.deactivate_workload_balancing_button')
        : this.$t('team.setup_workload_balancing_button');
    },
  },
};
</script>

<style lang="scss" src="./WorkloadBalancingToggle.scss" scoped />
