export const operator = {
  CONTAINS: 'contains',
  NOT_CONTAINS: "doesn't contain",
  EQUALS: 'equals',
  NOT_EQUALS: 'is different than',
  CONTAINS_WORD: 'contains the word',
  NOT_CONTAINS_WORD: "doesn't contain the word",
  STARTS_WITH: 'starts with',
  NOT_STARTS_WITH: "doesn't start with",
  ENDS_WITH: 'ends with',
  NOT_ENDS_WITH: "doesn't end with",
  TIME_AGO: 'ago',
  BUSINESS_HOURS: 'business hours',
  BEFORE: 'before',
  AFTER: 'after',
  IN: 'in',
  NOT_IN: 'not in',
};

export const field = {
  'contact.name': 'Contact name',
  'contact.email': 'Contact email',
  'contact.phone': 'Contact phone',
  'ticket.status': 'Ticket status',
  'email.subject': 'Email subject',
  'ticket.team': 'Ticket team',
  'time.unreplied': 'Time unreplied',
  'business_hours.open': 'Is inside',
  'business_hours.closed': 'Is outside',
  datetime: 'Date time',
  'ticket.labels': 'Ticket labels',
  'last_message.direction': 'Last message direction',
  'has.attachment': 'Has attachment',
  'message.message': 'Message',
  'message.first': 'Message begins',
  'ticket.custom_field': 'Custom ticket field',
  'profile.custom_field': 'Custom profile field',
  'contact.custom_field': 'Custom contact field',
  'causer.type': 'Causer type',
  'contact.group': 'Contact group',
  'contact.profile': 'Contact profile',
};

export const type = {
  CLOSE_TICKET: 'Close ticket',
  DELETE_TICKET: 'Delete ticket',
  MARK_AS_SPAM: 'Mark as spam',
  UNMARK_AS_SPAM: 'Unmark as spam',
  ASSIGN_TO_USER: 'Assign to user',
  ASSIGN_TO_TEAM: 'Assign to team',
  ASSIGN_ROUND_ROBIN_TO_TEAM: 'Assign round robin to team',
  ATTACH_LABEL: 'Attach label',
  DETACH_LABEL: 'Detach label',
  ADD_TO_CONTACT_GROUP: 'Add to contact group',
  REMOVE_FROM_CONTACT_GROUP: 'Remove from contact group',
  SEND_NOTIFICATION_TO_USERS: 'Send notification to users',
  FORWARD_TO_EMAIL: 'Forward to email',
  SEND_MESSAGE: 'Send message',
  SEND_HTML_RICH_MESSAGE: 'Send HTML rich message',
  TRIGGER_CSAT: 'Trigger CSAT',
  SEND_TO_SLACK: 'Send to Slack',
  SET_FLOWBOT: 'Set flowbot',
  SET_TICKET_RESULT: 'Set a ticket result',
};
