<template>
  <div>
    <div class="text-lg leading-7">Great stuff! You can finish setting up your new channel on the next page.</div>
    <div class="relative mt-20 flex">
      <div class="gradient left"></div>
      <div class="gradient right"></div>
      <div class="absolute flex h-full w-full items-center justify-center">
        <img :src="`${$root.assetsURL}img/whatsapp_logo.svg`" alt="whatsapp" />
        <div class="relative ml-2 mr-2.5 flex items-center">
          <img :src="`${$root.assetsURL}img/dashed_line.svg`" alt="dashed line" class="absolute" />
          <div class="h-2 w-2 rounded-full bg-success-300" />
          <span class="z-10 ml-4 mr-3 rounded-full bg-grey-100">
            <img :src="`${$root.assetsURL}img/checkmark_dot.svg`" alt="whatsapp" />
          </span>
          <div class="h-2 w-2 rounded-full bg-success-300" />
        </div>
        <img :src="`${$root.assetsURL}img/trengo_logo.svg`" alt="trengo" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepSix',
};
</script>

<style lang="scss" scoped>
.gradient {
  width: 355px;
  height: 355px;
  &.left {
    background: radial-gradient(50% 50% at 50% 50%, #f3ddff 0%, rgba(243, 221, 255, 0) 100%);
    margin-left: 120px;
  }
  &.right {
    background: radial-gradient(50% 50% at 50% 50%, #d7f3ff 0%, rgba(215, 243, 255, 0) 100%);
    margin-left: -243px;
    margin-top: -50px;
  }
}
</style>
