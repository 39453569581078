<template>
  <div v-if="customFieldsLoaded" class="h-full overflow-y-scroll">
    <mobile-preview-card>{{ $t('general.feature_only_available_on_desktop') }}</mobile-preview-card>
    <router-view v-if="hasParams" />
    <custom-fields-list
      v-else-if="hasCustomFields"
      :custom-contact-fields="customContactFields"
      :custom-profile-fields="customProfileFields"
      :custom-ticket-fields="customTicketFields"
      :custom-user-fields="customUserFields"
      class="hidden-md-down"
    >
      <router-view />
    </custom-fields-list>
    <div v-else class="hidden-md-down">
      <div class="flex h-full flex-col items-start pl-20 pt-20">
        <t-feature-icon :icon="FEATURE_ICON.CUSTOM_FIELDS" class="mb-8" />
        <h2 class="text-4xl font-bold">{{ $t('custom_fields.custom_fields') }}</h2>
        <div class="mt-3 w-max rounded-3xl bg-grey-400 px-4 py-2">
          <p class="m-0 text-sm font-semibold">{{ $t('custom_fields.takes_less_than_1_min') }}</p>
        </div>
        <div class="mt-6 flex items-center">
          <img :src="`${$root.assetsURL}img/check-dark.svg`" class="custom-field-list-icon" />
          <div class="ml-3 font-medium">{{ $t('custom_fields.collect_customer_info') }}</div>
        </div>
        <div class="mt-6 flex items-center">
          <img :src="`${$root.assetsURL}img/check-dark.svg`" class="custom-field-list-icon" />
          <div class="ml-3 font-medium">{{ $t('custom_fields.add_more_data') }}</div>
        </div>
        <div class="mt-6 flex items-center">
          <img :src="`${$root.assetsURL}img/check-dark.svg`" class="custom-field-list-icon" />
          <div class="ml-3 font-medium">
            {{ $t('custom_fields.organize_and_prioritize_conversations') }}
          </div>
        </div>
        <atomic-button size="md" class="mt-10 block" @click="goToCustomField">
          {{ $t('custom_fields.add_custom_field') }}
        </atomic-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

import MobilePreviewCard from '@/components/common/MobilePreviewCard';
import TFeatureIcon from '@/components/common/TFeatureIcon';
import { FEATURE_ICON } from '@/Configs/Constants';

import CustomFieldsList from './CustomFieldsList';

export default defineComponent({
  data() {
    return {
      loaded: false,
      FEATURE_ICON,
    };
  },

  components: {
    CustomFieldsList,
    MobilePreviewCard,
    TFeatureIcon,
  },
  mounted() {
    this.loadCustomFields();
  },
  computed: {
    ...mapState({
      customFields: (state) => state.customFields.customFields,
      customUserFields: (state) => state.customFields.customUserFields,
      customProfileFields: (state) => state.customFields.customProfileFields,
      customTicketFields: (state) => state.customFields.customTicketFields,
      customContactFields: (state) => state.customFields.customContactFields,
      customFieldsLoading: (state) => state.customFields.loading,
      customFieldsLoaded: (state) => state.customFields.loaded,
    }),
    hasParams() {
      return Object.keys(this.$route.params).length > 0;
    },
    hasCustomFields() {
      return !!this.customFields?.length > 0;
    },
  },
  methods: {
    ...mapActions({
      loadCustomFields: 'customFields/loadCustomFields',
    }),
    goToCustomField() {
      this.$router.push('/admin/custom_fields/create/USER');
    },
  },
});
</script>

<style lang="scss" scoped src="./CustomFields.scss" />
