<script type="text/babel">
import { map, toArray } from 'lodash';

import IconPicker from '../IconPicker.vue';

export default {
  props: {
    helpCenter: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    localeCodes: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      targetOptions: {
        categoryOptions: [],
        sectionOptions: [],
        articleOptions: [],
      },
      block: {
        target: 'external_url',
        icon: '',
        // open_new_window: true,
        translations: [],
      },
      localeCode: this.helpCenter.default_language,
      saving: false,
    };
  },
  watch: {
    target(newTarget) {
      if (
        ['category', 'section', 'article'].indexOf(newTarget) >= 0 &&
        !this.targetOptions[newTarget + 'Options'].length
      ) {
        this[newTarget + 'Options']();
      }
    },
  },
  computed: {
    filteredHelpCenterLanguages() {
      return this.localeCodes[this.localeCode] ? this.helpCenter.languages : [];
    },
    target() {
      return this.block.target;
    },
  },
  methods: {
    categoryOptions() {
      axios.get('/api/v2/help_center/' + this.$route.params.id + '/categories').then((r) => {
        this.targetOptions.categoryOptions = toArray(
          map(r.data, (category) => {
            return {
              id: category.id,
              title: this.getOptionTranslation(category).title,
            };
          })
        );
      });
    },
    sectionOptions() {
      axios.get('/api/v2/help_center/' + this.$route.params.id + '/categories?with=sections').then((r) => {
        this.targetOptions.sectionOptions = toArray(
          map(r.data, (category) => {
            return {
              id: category.id,
              title: this.getOptionTranslation(category).title,
              children: toArray(
                map(category.sections, (section) => {
                  return {
                    id: section.id,
                    title: this.getOptionTranslation(section).title,
                  };
                })
              ),
            };
          })
        );
      });
    },
    articleOptions() {
      axios.get('/api/v2/help_center/' + this.$route.params.id + '/articles').then((r) => {
        this.targetOptions.articleOptions = toArray(
          map(r.data, (article) => {
            return {
              id: article.id,
              title: this.getOptionTranslation(article).title,
            };
          })
        );
      });
    },
    loadBlock() {
      axios.get('/api/v2/help_center/' + this.helpCenter.id + '/blocks/' + this.$route.params.blockId).then((r) => {
        this.block = r.data;
      });
    },
    save() {
      axios
        .put('/api/v2/help_center/' + this.helpCenter.id + '/blocks/' + this.$route.params.blockId, this.block)
        .then((r) => {
          this.block = r.data;
          this.flashSuccess(this.$t('help_center.the_block_has_been_saved'));
        });
    },
    async remove() {
      const shouldDeleteBlock = await this.$tConfirm(
        this.$t('help_center.are_you_sure_you_want_to_delete_this_block'),
        {
          delete: true,
          title: this.$t('help_center.are_you_sure'),
        }
      );
      if (shouldDeleteBlock) {
        axios.delete('/api/v2/help_center/' + this.helpCenter.id + '/blocks/' + this.$route.params.blockId).then(() => {
          this.$router.push('/admin/help_center/' + this.helpCenter.id + '/blocks');
        });
      }
    },
    getTranslation(record) {
      let trans = record.translations.filter((trans) => {
        return trans.locale_code === this.localeCode;
      })[0];
      if (trans == null) {
        trans = {
          locale_code: this.localeCode,
          title: '',
          description: '',
          external_url: '',
        };
        this.block.translations.push(trans);
      }
      return trans;
    },
    getOptionTranslation(record) {
      let trans = record.translations.filter((trans) => {
        return trans.locale_code === this.localeCode;
      })[0];
      if (trans == null) {
        trans = {
          title: '',
        };
      }
      return trans;
    },
  },
  mounted() {
    this.loadBlock();
  },
  components: {
    IconPicker,
  },
};
</script>

<template>
  <div class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis">
            {{ getTranslation(block).title }}
          </span>
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <div class="container" style="padding: 15px 0">
            <div class="col-md-12">
              <div class="p-b-1 display-flex">
                <div style="width: 75px">
                  <icon-picker v-model="block.icon"></icon-picker>
                </div>
                <div style="width: calc(100% - 150px)">
                  <input
                    v-model="getTranslation(block).title"
                    class="input-transparant display-block"
                    :placeholder="$t('help_center.the_block_title')"
                    style="width: 100%; font-size: 20px"
                  />
                  <span class="text-muted">
                    <input
                      v-model="getTranslation(block).description"
                      class="input-transparant display-block"
                      :placeholder="$t('help_center.the_block_description')"
                      style="width: 100%"
                    />
                  </span>
                </div>
                <div class="dropdown m-l-auto m-t-sm">
                  <button
                    v-if="localeCodes[localeCode]"
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                  >
                    {{ localeCodes[localeCode].text }}
                  </button>
                  <div class="dropdown-menu">
                    <a
                      v-for="lang in filteredHelpCenterLanguages"
                      :key="lang"
                      class="dropdown-item"
                      @click="localeCode = lang"
                    >
                      {{ localeCodes[lang].text }}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="box">
                <div class="box-body">
                  <!--<pre>{{block}}</pre>-->
                  <form>
                    <div class="form-group row">
                      <div class="col-sm-2">{{ $t('help_center.link_type') }}</div>
                      <div class="col-sm-10">
                        <div class="btn-group btn-group-toggle">
                          <label class="btn btn-secondary" :class="{ active: block.target === 'external_url' }">
                            <input v-model="block.target" type="radio" value="external_url" />
                            {{ $t('help_center.external_url') }}
                          </label>
                          <label class="btn btn-secondary" :class="{ active: block.target === 'category' }">
                            <input v-model="block.target" type="radio" value="category" />
                            {{ $t('help_center.category') }}
                          </label>
                          <label class="btn btn-secondary" :class="{ active: block.target === 'section' }">
                            <input v-model="block.target" type="radio" value="section" />
                            {{ $t('help_center.section') }}
                          </label>
                          <label class="btn btn-secondary" :class="{ active: block.target === 'article' }">
                            <input v-model="block.target" type="radio" value="article" />
                            {{ $t('help_center.article') }}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div v-if="block.target === 'external_url'" class="form-group row">
                      <label class="col-sm-2 col-form-label" for="external_url">
                        {{ $t('help_center.external_url') }}
                      </label>
                      <div class="col-sm-10">
                        <input
                          id="external_url"
                          v-model="getTranslation(block).external_url"
                          type="text"
                          :placeholder="$t('help_center.example_placeholder')"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div v-else class="form-group row">
                      <label :for="block.target" class="col-sm-2 col-form-label" style="text-transform: capitalize">
                        {{ block.target }}
                      </label>
                      <div class="col-sm-10">
                        <select :id="block.target" v-model="block[block.target + '_id']" class="form-control">
                          <template v-for="option in targetOptions[block.target + 'Options']">
                            <optgroup v-if="option.children" :key="option.id" :label="option.title">
                              <option v-for="child in option.children" :key="child.id" :value="child.id">
                                {{ child.title }}
                              </option>
                            </optgroup>
                            <option v-else :key="option.id" :value="option.id">{{ option.title }}</option>
                          </template>
                        </select>
                      </div>
                    </div>

                    <!--<div class="form-group row">
                                            <label class="col-sm-2 col-form-label" for="open_new_window">
                                                Link opens in new window
                                       \     </label>
                                            <div class="col-sm-10">
                                                <label class="ui-switch success m-t-xs m-r"><input id="open_new_window" v-model="block.open_new_window" type="checkbox" value="1"><i></i></label>
                                            </div>
                                        </div>-->
                  </form>
                </div>
              </div>
              <div class="box">
                <div class="box-body flow-root">
                  <div class="pull-left">
                    <button
                      class="btn btn-success text-white"
                      :disabled="saving"
                      @click="save()"
                      v-text="saving ? $t('help_center.saving') : $t('help_center.save')"
                    ></button>
                    <a v-if="helpCenter.published" class="btn white" target="_blank" :href="this.helpCenter.url">
                      <i class="fa fa-external-link"></i>
                      {{ $t('help_center.view_in_browser') }}
                    </a>
                  </div>
                  <div class="pull-right">
                    <button class="btn white" @click="remove()">
                      {{ $t('general.delete') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
label.btn input[type='radio'] {
  display: none;
}
</style>
