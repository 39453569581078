import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mx-auto ml-16 flex flex-col" }
const _hoisted_2 = { class: "mb-4 mt-8 flex flex-row items-center" }
const _hoisted_3 = {
  key: 0,
  class: "t-text-h4 m-0 p-0 text-grey-800"
}
const _hoisted_4 = {
  key: 1,
  class: "t-text-h4 m-0 p-0 text-grey-800"
}
const _hoisted_5 = {
  for: "appType",
  class: "t-text-sm-emphasize text-grey-800"
}
const _hoisted_6 = { class: "mt-6 flex flex-row items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_left_linear = _resolveComponent("arrow-left-linear")!
  const _component_t_input_text = _resolveComponent("t-input-text")!
  const _component_t_error_item = _resolveComponent("t-error-item")!
  const _component_t_dropdown = _resolveComponent("t-dropdown")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_card = _resolveComponent("t-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "mr-6 rounded-full p-1.5 transition-colors hover:bg-grey-300",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$router.push({ name: 'plugins' })), ["prevent"]))
      }, [
        _createVNode(_component_arrow_left_linear, { size: "1.3rem" })
      ]),
      (_ctx.action === 'edit')
        ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.initialIntegration.name), 1 /* TEXT */))
        : (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_ctx.$tc('integration_hub.add_app')), 1 /* TEXT */))
    ]),
    (_ctx.isLoaded)
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          onSubmit: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent","stop"]))
        }, [
          _createVNode(_component_t_card, { class: "flex flex-col bg-white" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass({ 'mb-6': !_ctx.errors.name })
              }, [
                _createVNode(_component_t_input_text, {
                  id: "name",
                  modelValue: _ctx.integration.name,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.integration.name) = $event)),
                  size: "sm",
                  label: _ctx.$tc('integrations.label_name'),
                  "sub-label": _ctx.$tc('integrations.subtitle_name'),
                  placeholder: _ctx.$tc('integration_hub.placeholder_my_plugin'),
                  "has-error": _ctx.errors.name,
                  onBlur: _ctx.checkName
                }, null, 8 /* PROPS */, ["modelValue", "label", "sub-label", "placeholder", "has-error", "onBlur"]),
                (_ctx.errors.name)
                  ? (_openBlock(), _createBlock(_component_t_error_item, {
                      key: 0,
                      text: _ctx.$tc('integration_hub.error_name_must_be_filled_out'),
                      "danger-icon": "",
                      class: "mb-4"
                    }, null, 8 /* PROPS */, ["text"]))
                  : _createCommentVNode("v-if", true)
              ], 2 /* CLASS */),
              _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('integrations.label_app_type')), 1 /* TEXT */),
              _createVNode(_component_t_dropdown, {
                id: "appType",
                modelValue: _ctx.integration.type,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.integration.type) = $event)),
                size: "sm",
                items: _ctx.dropdownItems,
                "value-index": "value",
                "text-index": "title",
                error: _ctx.errors.type,
                disabled: _ctx.action === 'edit',
                onInput: _ctx.checkType
              }, null, 8 /* PROPS */, ["modelValue", "items", "error", "disabled", "onInput"]),
              (_ctx.errors.type)
                ? (_openBlock(), _createBlock(_component_t_error_item, {
                    key: 0,
                    text: _ctx.$tc('integration_hub.error_you_must_select_app_type'),
                    "danger-icon": ""
                  }, null, 8 /* PROPS */, ["text"]))
                : _createCommentVNode("v-if", true),
              (_ctx.integration.type !== 'slack')
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.integration.type), {
                    key: 1,
                    ref: "childForm",
                    modelValue: _ctx.integration.meta,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.integration.meta) = $event)),
                    onOnValidated: _cache[4] || (_cache[4] = ($event: any) => (_ctx.errors.meta = false)),
                    onOnInvalidated: _cache[5] || (_cache[5] = ($event: any) => (_ctx.errors.meta = true))
                  }, null, 40 /* PROPS, NEED_HYDRATION */, ["modelValue"]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_t_button, {
                  class: _normalizeClass({ loader: _ctx.isSaving }),
                  disabled: _ctx.isSaving || _ctx.isDeleting,
                  onClick: _withModifiers(_ctx.saveIntegration, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$tc('general.save_changes')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["class", "disabled", "onClick"]),
                (_ctx.action === 'edit')
                  ? (_openBlock(), _createBlock(_component_t_button, {
                      key: 0,
                      class: _normalizeClass([{ loader: _ctx.isDeleting }, "ml-3"]),
                      disabled: _ctx.isSaving || _ctx.isDeleting,
                      "btn-style": "danger-alt",
                      onClick: _withModifiers(_ctx.deleteIntegration, ["prevent"])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$tc('integrations.uninstall_plugin')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["class", "disabled", "onClick"]))
                  : _createCommentVNode("v-if", true)
              ])
            ]),
            _: 1 /* STABLE */
          })
        ], 32 /* NEED_HYDRATION */))
      : _createCommentVNode("v-if", true)
  ]))
}