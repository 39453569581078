<template>
  <div>
    <div
      class="
        learn-more-item
        grid
        w-full
        cursor-pointer
        select-none
        grid-cols-3
        gap-2
        rounded-xl
        border-1 border-grey-400
        bg-white
        p-4
        shadow-xs
        hover:border-grey-500
      "
      @click="goToLink"
    >
      <img
        :src="$root.assetsURL + 'img/overview_page_icons/learn_more_item/' + icon + '.svg'"
        aria-hidden="true"
        alt="Icon"
        class="w-full"
        :class="'text-' + color"
      />
      <div class="flex flex-col justify-center overflow-hidden">
        <span class="text-truncate mb-2 text-sm font-semibold leading-4">{{ title }}</span>
        <span class="text-truncate text-xs font-medium leading-5 text-grey-500">{{ subtitle }}</span>
      </div>
      <div class="flex justify-end">
        <t-button size="lg" btn-style="secondary" icon-only @click="goToSettingRoute">
          <template #icon><arrow-right-linear size="1.5rem" /></template>
        </t-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowRightLinear } from '@trengo/trengo-icons';

import eventBus from '@/eventBus';
export default {
  name: 'LearnMoreItem',
  components: { ArrowRightLinear },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    settingLink: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
      default: 'user_square_duotone',
    },
    color: {
      type: String,
      default: 'sky-500',
    },
    type: {
      type: String,
      default: '',
    },
  },
  methods: {
    goToLink() {
      window.open(this.link, '_blank');
    },
    goToSettingRoute() {
      this.$router.push(this.settingLink);
    },
  },
};
</script>

<style scoped src="./LearnMoreItem.scss" lang="scss"></style>
