<template>
  <div class="skeleton-loader" :class="bindClass"></div>
</template>

<script>
export default {
  props: {
    height: {
      type: [Number, String],
      default: 22,
    },
    width: {
      type: [Number, String],
      default: 200,
    },
    color: {
      type: String,
      default: '#EAEAEA',
    },
    animation: {
      type: String,
      default: 'wave',
    },
    type: {
      type: String,
      default: 'rect',
    },
    borderRadius: {
      type: [Number, String],
      default: '12px',
    },
  },
  computed: {
    style() {
      return {
        width: typeof this.width === 'number' ? `${this.width}px` : this.width,
        height: typeof this.height === 'number' ? `${this.height}px` : this.height,
        'background-color': `${this.color}`,
        'border-radius': this.radius,
      };
    },
    bindClass() {
      return `animation--${this.animation} shape--${this.type} shape--${this.borderRadius ? 'round' : 'flat'}`;
    },
  },
  mounted() {
    const width = typeof this.width === 'number' ? `${this.width}px` : this.width;
    const height = typeof this.height === 'number' ? `${this.height}px` : this.height;
    const background = `${this.color}`;
    const loader = this.$el;
    loader.style.setProperty('width', width);
    loader.style.setProperty('height', height);
    loader.style.setProperty('background-color', background);
    loader.style.setProperty('border-radius', this.borderRadius);
  },
};
</script>

<style lang="scss" scoped src="./SkeletonLoader.scss" />
