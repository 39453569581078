<template>
  <div
    v-click-outside="hide"
    class="focus:ring-offset-gray-100 focus:ring-indigo-500 relative inline-block w-full text-left"
  >
    <div class="relative flex w-full flex-wrap items-stretch">
      <atomic-input
        ref="dropdown"
        v-model="computedValue"
        type="text"
        :class="{ active: show, error }"
        :placeholder="placeholder"
        class="t-search-bar t-text-desktop-paragraph-md border border-grey-300"
        :size="size"
        :prepend-icon="prependIcon"
        :prepend-icon-size="prependIconSize"
        data-test="search-bar"
        @click="showDropdown"
        @keydown="onKeyDown"
      />
    </div>

    <transition
      enter-active-class="duration-100 ease-out"
      enter-class="-translate-x-full opacity-0"
      enter-to-class="translate-x-0 opacity-100"
      leave-active-class="duration-100 ease-in"
      leave-class="translate-x-0 opacity-100"
      leave-to-class="-translate-x-full opacity-0"
    >
      <div v-if="show" class="t-search-bar-container">
        <div
          class="
            floating-menu
            absolute
            right-0
            z-50
            mt-0
            w-max
            min-w-full
            origin-top-right
            select-none
            rounded-xl
            bg-white
            shadow-lg
            ring-1 ring-black ring-opacity-5
            focus:outline-none
          "
        >
          <slot name="start" />
          <div
            v-if="options.length"
            ref="list"
            :style="{ 'max-height': this.height + 'px', overflow: 'auto' }"
            class="my-3 py-1"
          >
            <div v-for="item in options" :key="item.id">
              <div @click="hide">
                <slot :item="item" name="item" />
              </div>
            </div>
          </div>
          <slot name="end" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
export default {
  name: 'SearchBar',
  emits: ['update:modelValue', 'keydown'],
  props: {
    modelValue: {
      type: String,
      required: true,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: { type: String, default: '' },
    size: { type: String, default: 'md' },
    error: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: '',
    },
    prependIconSize: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 170,
    },
    scrollHeight: {
      type: Number,
      default: 0,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      searchStarts: false,
      show: false,
    };
  },
  watch: {
    show(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.list.scrollTop = this.scrollHeight;
        });
      }
    },
  },
  methods: {
    startSearch(value) {
      this.searchStarts = !!value && !this.options.length;
    },
    callback(value) {
      this.computedValue = value.value;
    },
    showDropdown() {
      this.show = true;
    },
    toggleDropdown() {
      this.show = !this.show;
    },
    hide() {
      this.show = false;
    },
    onKeyDown($event) {
      this.$emit('keydown', $event);
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.startSearch(value);

        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped src="./SearchBar.scss" />
