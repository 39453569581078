<template>
  <div
    v-on-click-outside="onClickOutside"
    class="
      absolute
      bottom-[4rem]
      z-[99999]
      flex
      max-h-[35rem]
      flex-col
      overflow-y-auto
      rounded-md
      border border-grey-300
      bg-white
      p-2
      px-4
      shadow-md
      xs:w-[96%]
      md:w-[93%]
    "
  >
    <h1 class="t-text-sm-emphasize m-0 mb-1 px-3 pb-1 pt-2 text-grey-800" data-test="customise-sidebar-item-label">
      {{ label }}
    </h1>

    <ul v-if="items.length > 0" class="flex list-none flex-col p-0">
      <sidebar-item-toggle
        v-for="item in items"
        :key="item.itemId"
        :item="item"
        data-test="customise-sidebar-toggle-button"
        @item-toggled="emit('toggle-item', item.itemId, item.visible)"
      />
    </ul>

    <div v-else class="flex flex-col items-center pb-6 pt-4">
      <t-thumbnail class="mx-auto mb-2 bg-white">
        <integration-linear size="1.5rem" />
      </t-thumbnail>

      <h1 class="t-text-md-emphasize m-0 mx-auto mb-2 p-0 text-grey-800">
        {{ $tc('integration_hub.no_integrations_available') }}
      </h1>

      <h2 class="t-text-sm m-0 p-0 text-center text-grey-700">
        {{ $tc('integration_hub.integrations_need_to_be_installed') }}
      </h2>
    </div>
  </div>
</template>

<script setup lang="ts">
// Couldn't get this to work at all with the existing v-click-outside directive we have
import { IntegrationLinear } from '@trengo/trengo-icons';
import { vOnClickOutside } from '@vueuse/components';

import SidebarItemToggle from '@/components/TicketSidebar/SidebarItemToggle.vue';

import type { SidebarItem } from '@/components/TicketSidebar/Index.vue';

defineProps<{
  label: string;
  items: SidebarItem[];
}>();

const emit = defineEmits<{
  (e: 'toggle-item', itemId: SidebarItem['itemId'], itemVisibility: SidebarItem['visible']): void;
  (e: 'close-list'): void;
}>();

const onClickOutside = (event: MouseEvent) => {
  // Prevent double trigger of the close event if the event target is the button that triggers the event
  if (event.target instanceof HTMLElement && event.target.dataset.test === 'customise-sidebar-toggle-button') {
    return;
  } else {
    emit('close-list');
  }
};
</script>
