import type { Integration } from '@/store/types/integrations';

function screenshotPathsCRM(integration: string, hasActivities = true, hasOpportunities = true): string[] {
  return [
    `images/integration-hub/${integration}/install.jpg`,
    `images/integration-hub/${integration}/contacts.jpg`,
    ...(hasOpportunities ? [`images/integration-hub/${integration}/opportunities.jpg`] : []),
    ...(hasActivities ? [`images/integration-hub/${integration}/activities.jpg`] : []),
  ];
}

function screenshotPathsEcom(integration: string): string[] {
  return [
    `images/integration-hub/${integration}/install.jpg`,
    `images/integration-hub/${integration}/view1.jpg`,
    `images/integration-hub/${integration}/view2.jpg`,
  ];
}

const MetaDataSupportText = 'Help Center';

const integrations = [
  {
    filterType: 'crm',
    icon: 'logo-hubspot',
    name: 'HubSpot',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_hubspot',
    shortDescription: 'integration_hub.short_description_hubspot',
    screenshots: screenshotPathsCRM('hubspot', false),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: ['integration_hub.requirements_account', 'integration_hub.requirements_admin_privileges'],
      support: [
        {
          text: MetaDataSupportText,
          url: 'https://help.trengo.com/en/articles/419114-beta-integrating-hubspot-with-trengo',
        },
      ],
    },
    service_id: 'crm+hubspot',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'crm',
    icon: 'logo-salesforce',
    name: 'Salesforce',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_salesforce',
    shortDescription: 'integration_hub.short_description_salesforce',
    screenshots: screenshotPathsCRM('salesforce'),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: [
        'integration_hub.requirements_account',
        'integration_hub.requirements_admin_privileges',
        'integration_hub.requirements_instance_url',
        'integration_hub.requirements_environment_name',
      ],
      support: [
        {
          text: MetaDataSupportText,
          url: 'https://help.trengo.com/en/articles/419123-beta-integrating-salesforce-with-trengo',
        },
      ],
    },
    service_id: 'crm+salesforce',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'crm',
    icon: 'logo-microsoft-dynamic',
    name: 'Microsoft Dynamics CRM',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_microsoft_dynamics',
    shortDescription: 'integration_hub.short_description_microsoft_dynamics',
    screenshots: screenshotPathsCRM('microsoft_dynamics'),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: [
        'integration_hub.requirements_account',
        'integration_hub.requirements_admin_privileges',
        'integration_hub.requirements_org_url',
      ],
      support: [
        {
          text: MetaDataSupportText,
          url: 'https://help.trengo.com/en/articles/419115-beta-integrating-microsoft-dynamics-with-trengo',
        },
      ],
    },
    service_id: 'crm+microsoft-dynamics',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'crm',
    icon: 'logo-pipedrive',
    name: 'Pipedrive',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_pipedrive',
    shortDescription: 'integration_hub.short_description_pipedrive',
    screenshots: screenshotPathsCRM('pipedrive', false),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: ['integration_hub.requirements_account', 'integration_hub.requirements_admin_privileges'],
      support: [
        {
          text: MetaDataSupportText,
          url: 'https://help.trengo.com/en/articles/419120-beta-integrating-pipedrive-with-trengo',
        },
      ],
    },
    service_id: 'crm+pipedrive',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'crm',
    icon: 'logo-zoho',
    name: 'Zoho CRM',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_zoho',
    shortDescription: 'integration_hub.short_description_zoho',
    screenshots: screenshotPathsCRM('zoho', false, false),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: [
        'integration_hub.requirements_account',
        'integration_hub.requirements_admin_privileges',
        'integration_hub.requirements_api_domain',
        'integration_hub.requirements_accounts_server',
      ],
      support: [
        {
          text: MetaDataSupportText,
          url: 'https://help.trengo.com/en/articles/419124-beta-integrating-zoho-crm-with-trengo',
        },
      ],
    },
    service_id: 'crm+zoho-crm',
    form_fields: [],
  },
  {
    filterType: 'crm',
    icon: 'logo-zendesk',
    name: 'Zendesk Sell',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_zendesk_sell',
    shortDescription: 'integration_hub.short_description_zendesk_sell',
    screenshots: screenshotPathsCRM('zendesk', false),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: ['integration_hub.requirements_account', 'integration_hub.requirements_admin_privileges'],
      support: [{ text: MetaDataSupportText, url: '' }],
    },
    service_id: 'crm+zendesk-sell',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'crm',
    icon: 'logo-salesflare',
    name: 'Salesflare',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_salesflare',
    shortDescription: 'integration_hub.short_description_salesflare',
    screenshots: screenshotPathsCRM('salesflare', false),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: [
        'integration_hub.requirements_account',
        'integration_hub.requirements_admin_privileges',
        'integration_hub.requirements_api_key',
      ],
      support: [
        {
          text: MetaDataSupportText,
          url: 'https://help.trengo.com/en/articles/419125-beta-integrating-salesflare-with-trengo',
        },
      ],
    },
    service_id: 'crm+salesflare',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'crm',
    icon: 'logo-close',
    name: 'Close.io',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_closeio',
    shortDescription: 'integration_hub.short_description_closeio',
    screenshots: ['images/integration-hub/dummy-screenshot-1.png', 'images/integration-hub/dummy-screenshot-2.png'],
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: ['integration_hub.requirements_account', 'integration_hub.requirements_admin_privileges'],
      support: [{ text: MetaDataSupportText, url: '' }],
    },
    service_id: 'crm+close',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'crm',
    icon: 'logo-act',
    name: 'Act',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_act',
    shortDescription: 'integration_hub.short_description_act',
    screenshots: screenshotPathsCRM('act', false),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: [
        'integration_hub.requirements_account',
        'integration_hub.requirements_admin_privileges',
        'integration_hub.requirements_instance_url',
        'integration_hub.requirements_db_name',
      ],
      support: [
        {
          text: MetaDataSupportText,
          url: 'https://help.trengo.com/en/articles/419126-beta-integrating-act-with-trengo',
        },
      ],
    },
    service_id: 'crm+act',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'crm',
    icon: 'logo-google-contact',
    name: 'Google contact',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_google_contact',
    shortDescription: 'integration_hub.short_description_google_contact',
    screenshots: ['images/integration-hub/dummy-screenshot-1.png', 'images/integration-hub/dummy-screenshot-2.png'],
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: ['integration_hub.requirements_account', 'integration_hub.requirements_admin_privileges'],
      support: [{ text: MetaDataSupportText, url: '' }],
    },
    service_id: 'crm+google-contacts',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'crm',
    icon: 'logo-activecampaign',
    name: 'ActiveCampaign',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_activecampaign',
    shortDescription: 'integration_hub.short_description_activecampaign',
    screenshots: screenshotPathsCRM('activecampaign', false),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: [
        'integration_hub.requirements_account',
        'integration_hub.requirements_admin_privileges',
        'integration_hub.requirements_base_url',
        'integration_hub.requirements_api_key',
      ],
      support: [
        {
          text: MetaDataSupportText,
          url: 'https://help.trengo.com/en/articles/419127-beta-integrating-active-campaign-with-trengo',
        },
      ],
    },
    service_id: 'crm+activecampaign',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'crm',
    icon: 'logo-flexmail',
    name: 'Flexmail',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_flexmail',
    shortDescription: 'integration_hub.short_description_flexmail',
    screenshots: ['images/integration-hub/dummy-screenshot-1.png', 'images/integration-hub/dummy-screenshot-2.png'],
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: ['integration_hub.requirements_account', 'integration_hub.requirements_admin_privileges'],
      support: [{ text: MetaDataSupportText, url: '' }],
    },
    service_id: 'crm+flexmail',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'crm',
    icon: 'logo-copper',
    name: 'Copper',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_copper',
    shortDescription: 'integration_hub.short_description_copper',
    screenshots: screenshotPathsCRM('copper', false),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: [
        'integration_hub.requirements_account',
        'integration_hub.requirements_admin_privileges',
        'integration_hub.requirements_email_of_api_key_owner',
        'integration_hub.requirements_api_key',
      ],
      support: [
        {
          text: MetaDataSupportText,
          url: 'https://help.trengo.com/en/articles/419128-beta-integrating-copper-with-trengo',
        },
      ],
    },
    service_id: 'crm+copper',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'crm',
    icon: 'logo-teamlead',
    name: 'Teamleader',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_teamleader',
    shortDescription: 'integration_hub.short_description_teamleader',
    screenshots: screenshotPathsCRM('teamleader', false),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: ['integration_hub.requirements_account', 'integration_hub.requirements_admin_privileges'],
      support: [{ text: MetaDataSupportText, url: '' }],
    },
    service_id: 'crm+teamleader',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'commerce',
    icon: 'logo-big-commerce',
    name: 'Big Commerce',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_big_commerce',
    shortDescription: 'integration_hub.short_description_big_commerce',
    screenshots: screenshotPathsEcom('big_commerce'),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: [
        'integration_hub.requirements_account',
        'integration_hub.requirements_admin_privileges',
        'integration_hub.requirements_api_access_token',
        'integration_hub.requirements_store_hash',
      ],
      support: [
        {
          text: MetaDataSupportText,
          url: 'https://help.trengo.com/en/articles/419131-beta-integrating-bigcommerce-with-trengo',
        },
      ],
    },
    service_id: 'ecommerce+bigcommerce',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'commerce',
    icon: 'logo-bol',
    name: 'Bol',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_bol',
    shortDescription: 'integration_hub.short_description_bol',
    screenshots: ['images/integration-hub/dummy-screenshot-1.png', 'images/integration-hub/dummy-screenshot-2.png'],
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: ['integration_hub.requirements_account', 'integration_hub.requirements_admin_privileges'],
      support: [{ text: MetaDataSupportText, url: '' }],
    },
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'commerce',
    icon: 'logo-lightspeed',
    name: 'Lightspeed',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_lightspeed',
    shortDescription: 'integration_hub.short_description_lightspeed',
    screenshots: screenshotPathsEcom('lightspeed'),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: [
        'integration_hub.requirements_account',
        'integration_hub.requirements_admin_privileges',
        'integration_hub.requirements_store_id',
      ],
      support: [
        {
          text: MetaDataSupportText,
          url: 'https://help.trengo.com/en/articles/419141-beta-integrating-lightspeed-e-series-with-trengo',
        },
      ],
    },
    service_id: 'ecommerce+lightspeed',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'commerce',
    icon: 'logo-magento',
    name: 'Magento',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_magento',
    shortDescription: 'integration_hub.short_description_magento',
    screenshots: screenshotPathsEcom('magento'),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: [
        'integration_hub.requirements_account',
        'integration_hub.requirements_admin_privileges',
        'integration_hub.requirements_shop_name',
        'integration_hub.requirements_shop_domain',
        'integration_hub.requirements_consumer_key',
        'integration_hub.requirements_consumer_secret',
        'integrations.magento2_label_access_token',
        'integrations.magento2_label_access_token_secret',
        'integration_hub.requirements_domain_url',
      ],
      support: [
        {
          text: MetaDataSupportText,
          url: 'https://help.trengo.com/en/articles/419132-beta-integrating-magento-2-with-trengo',
        },
      ],
    },
    service_id: 'ecommerce+magento',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'commerce',
    icon: 'logo-mollie',
    name: 'Mollie',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_mollie',
    shortDescription: 'integration_hub.short_description_mollie',
    screenshots: ['images/integration-hub/dummy-screenshot-1.png', 'images/integration-hub/dummy-screenshot-2.png'],
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: ['integration_hub.requirements_account', 'integration_hub.requirements_admin_privileges'],
      support: [{ text: MetaDataSupportText, url: '' }],
    },
    service_id: 'ecommerce+mollie',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'commerce',
    icon: 'logo-picqer',
    name: 'Picqer',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_picqer',
    shortDescription: 'integration_hub.short_description_picqer',
    screenshots: screenshotPathsEcom('picqer'),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: [
        'integration_hub.requirements_account',
        'integration_hub.requirements_admin_privileges',
        'integration_hub.requirements_api_key',
        'integration_hub.requirements_subdomain',
      ],
      support: [
        {
          text: MetaDataSupportText,
          url: 'https://help.trengo.com/en/articles/419133-beta-integrating-picquer-with-trengo',
        },
      ],
    },
    service_id: 'ecommerce+picqer',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'commerce',
    icon: 'logo-prestashop',
    name: 'Prestashop',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_prestashop',
    shortDescription: 'integration_hub.short_description_prestashop',
    screenshots: screenshotPathsEcom('prestashop'),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: [
        'integration_hub.requirements_account',
        'integration_hub.requirements_admin_privileges',
        'integration_hub.requirements_shop_domain',
        'integration_hub.requirements_admin_url',
        'integration_hub.requirements_webservice',
      ],
      support: [
        {
          text: MetaDataSupportText,
          url: 'https://help.trengo.com/en/articles/419134-beta-integrating-prestashop-with-trengo',
        },
      ],
    },
    service_id: 'ecommerce+prestashop',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'commerce',
    icon: 'logo-shopify',
    name: 'Shopify',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_shopify',
    shortDescription: 'integration_hub.short_description_shopify',
    screenshots: screenshotPathsEcom('shopify'),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: [
        'integration_hub.requirements_account',
        'integration_hub.requirements_admin_privileges',
        'integration_hub.requirements_shop_name',
        'integration_hub.requirements_admin_access_token',
      ],
      support: [
        {
          text: MetaDataSupportText,
          url: 'https://help.trengo.com/en/articles/419135-beta-integrating-shopify-with-trengo',
        },
      ],
    },
    service_id: 'ecommerce+shopify',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'commerce',
    icon: 'logo-shopware',
    name: 'Shopware',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_shopware',
    shortDescription: 'integration_hub.short_description_shopware',
    screenshots: screenshotPathsEcom('shopware'),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: [
        'integration_hub.requirements_account',
        'integration_hub.requirements_admin_privileges',
        'integration_hub.requirements_shop_domain',
        'integration_hub.requirements_client_id',
        'integration_hub.requirements_client_secret_key',
      ],
      support: [
        {
          text: MetaDataSupportText,
          url: 'https://help.trengo.com/en/articles/419136-beta-integrating-shopware-with-trengo',
        },
      ],
    },
    service_id: 'ecommerce+shopware',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'commerce',
    icon: 'logo-wix',
    name: 'Wix',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_wix',
    shortDescription: 'integration_hub.short_description_wix',
    screenshots: screenshotPathsEcom('wix'),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: [
        'integration_hub.requirements_account',
        'integration_hub.requirements_admin_privileges',
        'integration_hub.requirements_api_key',
        'integration_hub.requirements_shop_domain',
        'integration_hub.requirements_site_id',
      ],
      support: [
        {
          text: MetaDataSupportText,
          url: 'https://help.trengo.com/en/articles/419138-beta-integrating-wix-with-trengo',
        },
      ],
    },
    service_id: 'ecommerce+wix',
    installations: [],
    form_fields: [],
  },
  {
    filterType: 'commerce',
    icon: 'logo-woo',
    name: 'WooCommerce',
    isInstalled: false,
    longDescription: 'integration_hub.long_description_woo',
    shortDescription: 'integration_hub.short_description_woo',
    screenshots: screenshotPathsEcom('woocommerce'),
    metadata: {
      compatible_features: [{ description: 'Send Conversations', name: 'Inbox', icon: 'icon' }],
      requirements: [
        'integration_hub.requirements_account',
        'integration_hub.requirements_admin_privileges',
        'integration_hub.requirements_shop_domain',
        'integration_hub.requirements_admin_url',
        'integration_hub.requirements_consumer_key',
        'integration_hub.requirements_consumer_secret',
      ],
      support: [
        {
          text: MetaDataSupportText,
          url: 'https://help.trengo.com/en/articles/419140-beta-integrating-woocommerce-with-trengo',
        },
      ],
    },
    service_id: 'ecommerce+woocommerce',
    installations: [],
    form_fields: [],
  },
] as Array<Partial<Integration>>;

export default integrations;
