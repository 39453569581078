import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex w-full flex-col md:w-auto xl:flex-row" }
const _hoisted_2 = { class: "flex items-center justify-center" }
const _hoisted_3 = { class: "flex items-center justify-center" }
const _hoisted_4 = {
  key: 0,
  class: "t-text-sm mt-3 text-grey-700"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_button = _resolveComponent("t-button")!
  const _component_arrow_top_circle_linear = _resolveComponent("arrow-top-circle-linear")!
  const _component_external_link_linear = _resolveComponent("external-link-linear")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isEntitled)
        ? (_openBlock(), _createBlock(_component_t_button, {
            key: 0,
            size: "md",
            class: "open-external mb-4 mr-0 xl:mb-0 xl:mr-4",
            onClick: _ctx.handleClickPrimaryButton
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.page.primary_button_text), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"]))
        : _createCommentVNode("v-if", true),
      (_ctx.isAdministrator && _ctx.requiresPlanUpgrade)
        ? (_openBlock(), _createBlock(_component_t_button, {
            key: 1,
            "btn-style": "primary",
            size: "md",
            type: "button",
            label: "Button",
            class: "mb-4 mr-0 xl:mb-0 xl:mr-4",
            onClick: _ctx.redirectToSubscription
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_arrow_top_circle_linear, {
                  size: "1.25rem",
                  class: "mr-1"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.page.entitlement_upgrade_button_text), 1 /* TEXT */)
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"]))
        : _createCommentVNode("v-if", true),
      (_ctx.isAdministrator && _ctx.requiresAddOn)
        ? (_openBlock(), _createBlock(_component_t_button, {
            key: 2,
            "btn-style": "primary",
            size: "md",
            type: "button",
            label: "Button",
            class: "mb-4 mr-0 xl:mb-0 xl:mr-4",
            onClick: _ctx.redirectToSubscription
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_arrow_top_circle_linear, {
                  size: "1.25rem",
                  class: "mr-1"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.page.entitlement_add_on_button_text), 1 /* TEXT */)
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"]))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_t_button, {
        size: "md",
        "btn-style": "secondary",
        href: _ctx.page.secondary_button_url,
        rel: "noreferrer noopener",
        target: "_blank",
        class: "open-external flex flex-row items-center"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.page.secondary_button_text) + " ", 1 /* TEXT */),
          _createVNode(_component_external_link_linear, { class: "ml-1" })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["href"])
    ]),
    (!_ctx.isAdministrator && _ctx.requiresPlanUpgrade)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('admin_inboxes.ask_your_administrator_to_upgrade')), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ]))
}