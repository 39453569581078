export const Steps = {
  CONNECT_WHATSAPP: 'CONNECT_WHATSAPP',
  MESSAGE_RECIEVED: 'MESSAGE_RECIEVED',
  ESTABLISHING_CONNECTION: 'ESTABLISHING_CONNECTION',
  VALIDATING_CONNECTION: 'VALIDATING_CONNECTION',
  TRYING_TEMPLATES: 'TRYING_TEMPLATES',
  TRYING_TEMPLATES_SUCCESS: 'TRYING_TEMPLATES_SUCCESS',
} as const;

export const MessageTypes = {
  INCOMING: 'INCOMING',
  OUTGOING: 'OUTGOING',
} as const;

export type Message = {
  message: string;
  type: typeof MessageTypes;
};
