<template>
  <sidebar-dropdown
    :title="$tc('ticket_sidebar.profile_fields')"
    collapse-key="profileFieldsDropdown"
    data-test="profile-fields-dropdown"
  >
    <profile-custom-field-editor :profile="profile" />
  </sidebar-dropdown>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import ProfileCustomFieldEditor from '@/components/Profiles/ProfileCustomFieldEditor.vue';
import SidebarDropdown from '@/components/TicketSidebar/SidebarDropdown.vue';

import type Profile from '@/types/profile';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'ProfileFieldsDropdown',

  props: {
    profile: {
      type: Object as PropType<Profile>,
      default: () => ({}),
    },
  },

  components: { ProfileCustomFieldEditor, SidebarDropdown },
});
</script>
