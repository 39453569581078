import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex cursor-pointer flex-col justify-between" }
const _hoisted_2 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_up_linear = _resolveComponent("chevron-up-linear")!
  const _component_chevron_down_linear = _resolveComponent("chevron-down-linear")!
  const _component_t_popover = _resolveComponent("t-popover")!

  return (_openBlock(), _createBlock(_component_t_popover, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.open) = $event)),
    "data-test": "table-sort",
    "click-outside-close": ""
  }, {
    trigger: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_chevron_up_linear, { size: "0.75rem" }),
        _createVNode(_component_chevron_down_linear, {
          class: "-mt-[0.4rem]",
          size: "0.75rem"
        })
      ])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.positionClass, "absolute -left-20 z-10 mt-2 flex flex-col items-center rounded-xl border border-grey-300 bg-white p-3 shadow-500"]),
          "data-test": "table-sort-items"
        }, [
          _renderSlot(_ctx.$slots, "item")
        ], 2 /* CLASS */)
      ])
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["modelValue"]))
}