import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "list-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_badge = _resolveComponent("t-badge")!
  const _component_caret_down_fill = _resolveComponent("caret-down-fill")!
  const _component_t_list_item = _resolveComponent("t-list-item")!
  const _component_date_picker = _resolveComponent("date-picker")!
  const _component_t_popover = _resolveComponent("t-popover")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_component_t_popover, {
      modelValue: _ctx.isOpen,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isOpen) = $event)),
      class: "relative",
      "click-outside-close": ""
    }, {
      trigger: _withCtx(() => [
        _createVNode(_component_t_list_item, {
          label: _ctx.label,
          class: "relative",
          active: _ctx.isOpen
        }, {
          postfix: _withCtx(() => [
            (_ctx.hasBadge)
              ? (_openBlock(), _createBlock(_component_t_badge, {
                  key: 0,
                  class: "flex items-center",
                  text: "1"
                }))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_caret_down_fill, {
              size: "1.25rem",
              class: "mt-1 -rotate-90 text-grey-600"
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label", "active"])
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.containerHeight, "filter-dropdown-second-layer-calendar absolute z-50 mt-2 w-96 overflow-hidden rounded-xl border border-grey-300 bg-white shadow-500"])
        }, [
          _createVNode(_component_date_picker, {
            modelValue: _ctx.date,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
            onSelection: _ctx.setDate
          }, null, 8 /* PROPS */, ["modelValue", "onSelection"])
        ], 2 /* CLASS */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}