import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_linear = _resolveComponent("text-linear")!
  const _component_t_multi_choice_button = _resolveComponent("t-multi-choice-button")!
  const _component_image_3_linear = _resolveComponent("image-3-linear")!
  const _component_t_multi_choice = _resolveComponent("t-multi-choice")!

  return (_openBlock(), _createBlock(_component_t_multi_choice, {
    modelValue: _ctx.headerType,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.headerType) = $event)),
      _ctx.handleSelectedHeader
    ],
    disabled: _ctx.disabled,
    stretch: true,
    class: "my-5 flex w-full justify-between gap-x-4",
    "child-class": "w-1/2"
  }, {
    text: _withCtx(({ selected }) => [
      _createVNode(_component_t_multi_choice_button, {
        title: "Text",
        desc: "Title header",
        selected: selected,
        vertical: "",
        "show-check-icon": false,
        disabled: _ctx.disabled
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_text_linear, {
            selected: "",
            size: "1.5rem"
          })
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["selected", "disabled"])
    ]),
    image: _withCtx(({ selected }) => [
      _createVNode(_component_t_multi_choice_button, {
        title: "Image",
        desc: "JPG or PNG",
        vertical: "",
        selected: selected,
        "show-check-icon": false,
        disabled: _ctx.disabled
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_image_3_linear, {
            selected: "",
            size: "1.5rem"
          })
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["selected", "disabled"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "disabled", "onUpdate:modelValue"]))
}