<template>
  <div v-if="enabled" class="notification-container none absolute flex flex-col lg:block">
    <transition-group name="slide-fade">
      <div v-if="popover" key="popover" class="notification flex items-center" role="button" @click="clickPopover">
        <i class="material-icons text-muted mr-8">star</i>
        <span>{{ $t('onboarding.popover_text') }}</span>
      </div>
      <div
        v-for="notification in notifications"
        :key="notification.text"
        class="notification flex items-center"
        role="button"
        @click="click(notification)"
      >
        <!--<i class="absolute text-grey-400 hover:text-grey-500 fa fa-close" role="button" @click="close(notification)" style="right: .5rem; top: .25rem;"></i>-->
        <i class="material-icons mr-8 text-yellow">star</i>
        <span>{{ notification.text }}</span>
      </div>
    </transition-group>
  </div>
</template>

<script>
import eventBus from '@/eventBus';
export default {
  name: 'OnboardingNotifications',
  data() {
    return {
      notifications: [],
      enabled: false,
    };
  },
  mounted() {
    eventBus.$on('onboarding-notification', (step) => {
      if (step === 'user.setup_first_web_widget') {
        return;
      }
      eventBus.$emit('showOnboardingProgressPopup', true);
    });
  },
  unmounted() {
    eventBus.$off('onboarding-notification');
  },
  methods: {
    clickPopover() {
      this.$store.dispatch('userConfig/put', { key: 'onboarding_steps.popover', value: false });

      this.$router.push('/profile/get-started');
    },
    close(notification) {
      let index = this.notifications.indexOf(notification);
      if (index > -1) {
        this.notifications.splice(index, 1);
      }
    },
    click(notification) {
      this.close(notification);

      this.$router.push('/profile/get-started');
    },
  },
  computed: {
    popover() {
      return !!this.$store.getters['userConfig/get']('onboarding_steps.popover');
    },
    // enabled() {
    //     return !!this.$store.getters['userConfig/get']('onboarding_steps.enabled')
    // }
  },
};
</script>

<style lang="scss" scoped>
.notification-container {
  right: 80px;
  top: 0.5rem;
  z-index: 9999;
  padding: 0.5rem 1rem;
  max-height: calc(100vh - 3.5rem);
  overflow-y: auto;
  overflow-x: visible;

  .notification {
    //opacity: 0.9;
    max-width: 1337px;
    border-radius: 10px;
    border: none;
    padding: 1.5rem 2rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.11) 0 0 0.5rem 0;
    margin: 0.5rem 0;
    transition: all 200ms ease-out;
    /*&:hover {
                opacity: 1;
            }*/
  }
}
</style>
