import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_online_status_user_item_skeleton = _resolveComponent("online-status-user-item-skeleton")!
  const _component_online_status_item = _resolveComponent("online-status-item")!

  return (_openBlock(), _createElementBlock("div", {
    "data-test": "online-status-user-item",
    class: _normalizeClass(["flex flex-row p-3", _ctx.borderClass])
  }, [
    (_ctx.skeleton)
      ? (_openBlock(), _createBlock(_component_online_status_user_item_skeleton, {
          key: 0,
          animation: _ctx.animation
        }, null, 8 /* PROPS */, ["animation"]))
      : (_openBlock(), _createBlock(_component_online_status_item, {
          key: 1,
          user: _ctx.user
        }, null, 8 /* PROPS */, ["user"]))
  ], 2 /* CLASS */))
}