import type { DropDownItems } from '@/components/Reporting/Components/V2/AvailableColumnsDropdown/utils';

const AvailableColumns: { readonly [key in keyof DropDownItems]: boolean } = {
  Assignee: true,
  ConversationStatus: true,
  AttachedLabels: false,
  Channel: false,
  FirstResolutionTime: false,
  TotalResolutionTime: false,
  FirstResponseTime: false,
  FirstResolutionTimeWithoutBusinessHours: false,
  TotalResolutionTimeWithoutBusinessHours: false,
  FirstResponseTimeWithoutBusinessHours: false,
  CreatedAt: false,
  AssignedAt: false,
  ClosedAt: false,
  MessagesSent: false,
  ChannelType: false,
  ContactEmail: false,
  ContactPhone: false,
  TicketCustomFields: false,
  ContactCustomFields: false,
  ProfileCustomFields: false,
  TicketResult: false,
} as const;
export const DefaultAvailableColumns = Object.seal(AvailableColumns);

export const AvailableColumnsMapper: { readonly [key: string]: string } = {
  ConversationStatus: 'status',
  Assignee: 'assignee',
  AttachedLabels: 'labels',
  Channel: 'channel',
  FirstResolutionTime: 'first_resolution_time_with_business_hours',
  FirstResolutionTimeWithoutBusinessHours: 'first_resolution_time_without_business_hours',
  FirstResponseTime: 'first_reply_time_with_business_hours',
  FirstResponseTimeWithoutBusinessHours: 'first_reply_time_without_business_hours',
  TotalResolutionTime: 'total_resolution_time_with_business_hours',
  TotalResolutionTimeWithoutBusinessHours: 'total_resolution_time_without_business_hours',
  CreatedAt: 'created_at',
  AssignedAt: 'assigned_at',
  ClosedAt: 'closed_at',
  MessagesSent: 'messages_sent',
  ChannelType: 'channel_type',
  ContactEmail: 'contact_email',
  ContactPhone: 'contact_phone',
  TicketCustomFields: 'ticket_custom_fields',
  ContactCustomFields: 'contact_custom_fields',
  ProfileCustomFields: 'profile_custom_fields',
  TicketResult: 'ticket_result',
} as const;

export type Status = 'ASSIGNED' | 'CLOSED' | 'OPEN';
export type CSATScore = 1 | 2 | 3 | 4 | 5;

export type TicketInsightsResponse = {
  ticket_id: number;
  assignee: Assignee;
  ticket_status: Status;
  attached_label: AttachedLabel[];
  channel: Channel;
  csat: CSATScore;
  first_resolution_time_with_business_hours: number;
  first_resolution_time_without_business_hours: number;
  total_resolution_time_with_business_hours: number;
  total_resolution_time_without_business_hours: number;
  first_reply_time_with_business_hours: number;
  first_reply_time_without_business_hours: number;
  created_at: number | string | null;
  assigned_at: number | string | null;
  closed_at: number | string | null;
  messages_sent: number;
  channel_type: string | null;
  contact_email: string | null;
  contact_phone: string | null;
  ticket_custom_fields: string | null;
  contact_custom_fields: string | null;
  profile_custom_fields: string | null;
  ticket_result: string | null;
};

export type TicketInsightsResponses = {
  data: TicketInsightsResponse[];
  meta: {
    total: number;
  };
};

export type Assignee = {
  profile_image: string;
  full_name: string;
  id: number;
};

export type AttachedLabel = {
  name: string;
  id: number;
};

export type Channel = {
  id: number;
  type: string;
  title: string;
};

function getRandomValue<T>(data: T[]): T {
  const randomIndex = Math.floor(Math.random() * data.length);
  return data[randomIndex];
}

function randomSeconds() {
  const minSeconds = 1 * 60;
  const maxSeconds = 48 * 3600;
  const randomSeconds = Math.floor(Math.random() * (maxSeconds - minSeconds + 1)) + minSeconds;
  return randomSeconds;
}

function randomTimestamp() {
  const now = Date.now();
  const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000; // Rough estimate of one year in milliseconds
  const randomMilliseconds = Math.floor(Math.random() * oneYearInMilliseconds);
  return now - randomMilliseconds;
}

function generateRandomId() {
  return Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000;
}

const generateRandomString = (length: number): string =>
  Array.from(
    { length },
    () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'[Math.floor(Math.random() * 62)],
  ).join('');

const randomBetween = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1)) + min;

const generateDynamicArray = (): { id: number; name: string }[] => {
  const arrayLength = randomBetween(1, 10);
  const stringLength = randomBetween(5, 15);

  return Array.from({ length: arrayLength }, () => ({
    id: generateRandomId(),
    name: generateRandomString(stringLength),
  }));
};

export const MockData: (page: number) => Promise<TicketInsightsResponses> = (page: number) => {
  return new Promise((resolve) => {
    const data: TicketInsightsResponse[] = [];
    if (page === 10) {
      resolve({ data: [], meta: { total: 0 } });
    }
    for (let i = 0; i < 25; i++) {
      const id = generateRandomId();
      data.push({
        ticket_id: id,
        assignee: {
          id: id,
          profile_image: `https://i.pravatar.cc/150?u=${id}`,
          full_name:
            i % 2 === 0
              ? 'Charlotte Thompson Charlotte Thompson Charlotte Thompson Charlotte Thompson Charlotte Thompson'
              : 'Charlotte Thompson',
        },
        ticket_status: getRandomValue(['ASSIGNED', 'CLOSED', 'OPEN']),
        attached_label: generateDynamicArray(),
        channel: {
          id: 123,
          type: 'WHATSAPP_BUSINESS',
          title: 'Whatsapp',
        },
        csat: getRandomValue([1, 2, 3, 4, 5]),
        first_resolution_time_with_business_hours: i % 2 === 0 ? 0 : randomSeconds(),
        first_resolution_time_without_business_hours: i % 2 === 0 ? 0 : randomSeconds(),
        total_resolution_time_with_business_hours: i % 2 === 0 ? 0 : randomSeconds(),
        total_resolution_time_without_business_hours: i % 2 === 0 ? 0 : randomSeconds(),
        first_reply_time_with_business_hours: i % 2 === 0 ? 0 : randomSeconds(),
        first_reply_time_without_business_hours: i % 2 === 0 ? 0 : randomSeconds(),
        created_at: i % 2 === 0 ? 0 : randomTimestamp(),
        assigned_at: i % 2 === 0 ? 0 : randomTimestamp(),
        closed_at: i % 2 === 0 ? 0 : randomTimestamp(),
        messages_sent: randomBetween(0, 100),
        channel_type: 'WHATSAPP',
        ticket_custom_fields: 'Field: value | Field 2: value 2',
        contact_email: 'john@trengo.com',
        contact_phone: '+31643809612',
        contact_custom_fields: null,
        profile_custom_fields: null,
        ticket_result: 'resolved',
      });
    }
    setTimeout(() => {
      resolve({ data, meta: { total: 100 } });
    }, 5000);
  });
};

type DetailPages = 'new_conversation' | 'assigned_conversation' | 'resolution_time' | 'first_response_time';

export function manipulateThePageColumns(page: string): typeof DefaultAvailableColumns {
  const columnsBaseOnPage: Record<DetailPages, Partial<typeof DefaultAvailableColumns>> = {
    new_conversation: {
      Assignee: false,
      ConversationStatus: true,
      CreatedAt: true,
    },
    assigned_conversation: {
      Assignee: true,
      ConversationStatus: true,
      CreatedAt: true,
      AssignedAt: true,
    },
    resolution_time: {
      FirstResolutionTime: true,
      TotalResolutionTime: true,
      Assignee: true,
      ConversationStatus: true,
      ClosedAt: true,
    },
    first_response_time: {
      FirstResponseTime: true,
      Assignee: true,
      ConversationStatus: true,
      CreatedAt: true,
    },
  };
  return { ...DefaultAvailableColumns, ...columnsBaseOnPage[page as keyof typeof columnsBaseOnPage] };
}
