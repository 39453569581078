import ThreadModel from '../Models/ThreadModel';

export default {
  setThreads(state, threads) {
    state.threads = threads;
  },
  appendThread(state, thread) {
    return state.threads.push(thread);
  },
  replaceThread(state, { oldThread, newThread, keep = [] }) {
    let i = state.threads.indexOf(oldThread);
    if (i > -1) {
      if (!newThread.personal) {
        ThreadModel.personalFields.forEach((f) => {
          newThread[f] = oldThread[f];
        });
      }

      // keep some fields from the old thread
      keep.forEach((f) => {
        newThread[f] = oldThread[f];
      });

      return (state.threads[i] = newThread);
    } else {
      return false;
    }
  },
  updateThread(state, { oldThread, newThread, properties = [] }) {
    let i = state.threads.indexOf(oldThread);
    if (i > -1) {
      properties.forEach((property) => {
        // skip personal fields if necessary
        if (!newThread.personal && newThread.personalFields?.includes(property)) {
          // todo why is personalFields undefined sometimes?
          return;
        }
        state.threads[i][property] = newThread[property];
      });

      return true;
    } else {
      return false;
    }
  },
  removeThread(state, thread) {
    let i = state.threads.indexOf(thread);
    if (i > -1) {
      return state.threads.splice(i, 1);
    } else {
      return false;
    }
  },
  removeThreadParticipant(state, { thread, index }) {
    thread.participants.splice(index, 1);
  },
  setCurrentThread(state, threadIdentifier) {
    state.currentThread = threadIdentifier;
  },
  setLastRead(state, { thread, lastRead }) {
    thread.lastRead = parseInt(lastRead) || null;
  },
  setMuted(state, { thread, muted }) {
    thread.muted = !!muted;
  },
  setPinned(state, { message, pinned }) {
    message.pinned = !!pinned;
  },
  setUnread(state, { thread, unread }) {
    thread.unread = parseInt(unread) || null;
  },
  setMarkUnread(state, { thread, unread }) {
    thread.markUnread = unread;
  },
  setUnreadMentions(state, { thread, unreadMentions }) {
    thread.unreadMentions = parseInt(unreadMentions) || null;
  },
  setLastMessageId(state, { thread, messageId }) {
    thread.lastMessageId = parseInt(messageId) || null;
  },
  setLastMessageTime(state, { thread, messageTime }) {
    thread.lastMessageTime = parseInt(messageTime) || null;
  },
  setAttachments(state, { thread, attachments }) {
    thread.attachments = attachments;
  },
  setAttachmentsTotal(state, { thread, total }) {
    thread.attachmentsTotal = total;
  },
  incrementAttachments(state, { thread }) {
    thread.attachmentsPage = thread.attachmentsPage + 1;
  },
  decrementAttachments(state, { thread }) {
    thread.attachmentsPage = thread.attachmentsPage - 1;
  },
  setSearchQuery(state, { thread, searchQuery }) {
    thread.searchQuery = searchQuery;
  },
  setFirstUnreadMessageId(state, { thread, messageId }) {
    thread.firstUnreadMessageId = parseInt(messageId) || null;
  },
  replaceMessage(state, { thread, oldMessage, newMessage }) {
    let i = thread.messages.indexOf(oldMessage);
    if (i > -1) {
      return thread.messages.splice(i, 1, newMessage);
    } else {
      return false;
    }
  },
  updateMessage(state, { thread, messageId, newMessage }) {
    let i = thread.messages.findIndex((m) => m.id === messageId);
    if (i > -1) {
      return thread.messages.splice(i, 1, newMessage);
    } else {
      return false;
    }
  },
  replaceMessages(state, { thread, messages }) {
    return (thread.messages = messages);
  },
  appendMessage(state, { message, thread }) {
    return thread.messages.push(message);
  },
  rollbackMessage(state, { message, thread }) {
    return thread.messages.splice(thread.messages.indexOf(message), 1);
  },
  prependMessages(state, { messages, thread }) {
    return thread.messages.unshift(...messages);
  },
  appendMessages(state, { messages, thread }) {
    return thread.messages.push(...messages);
  },
  appendAttachments(state, { attachments, thread }) {
    return thread.attachments.push(...attachments);
  },
  incrementReaction(state, { messageModel, reactionResource }) {
    let exists = messageModel.reactions.findIndex((r) => r.emoji === reactionResource.emoji);

    if (exists > -1) {
      messageModel.reactions[exists].users.push(reactionResource.user_id);
    } else {
      messageModel.reactions.push({
        emoji: reactionResource.emoji,
        users: [reactionResource.user_id],
      });
    }
  },
  decrementReaction(state, { messageModel, reactionResource }) {
    let exists = messageModel.reactions.findIndex((r) => r.emoji === reactionResource.emoji);

    if (exists > -1) {
      let userIndex = messageModel.reactions[exists].users.indexOf(reactionResource.user_id);
      if (userIndex > -1) {
        messageModel.reactions[exists].users.splice(userIndex, 1);
      }
      if (!messageModel.reactions[exists].users.length) {
        messageModel.reactions.splice(exists, 1);
      }
    }
  },
};
