<script type="text/babel">
import _, { map } from 'lodash';

import eventBus from '@/eventBus';

import contactSelector from './ContactSelector.vue';
import textareaSmsCounter from './TextareaSmsCounter';
export default {
  name: 'BulkSms',
  props: {
    default_channel: {
      type: Number,
      default: null,
    },
    channels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      channel_id: null,
      loading: false,
      message: '',
      contacts: [],
      contact_groups: [],
      tags: [],
      quick_replies: [],
      send_at: '',
      summary: null,
      showSummaryContacts: false,
      send_moment: 'now',
      history: [],
      bulkContacts: [],
      enabled: typeof window.AGENCY.enable_bulk_sms == 'undefined' || window.AGENCY.enable_bulk_sms == 1,
      shouldShowChannels: {
        broadcasting: typeof window.AGENCY.enable_broadcasting == 'undefined' || window.AGENCY.enable_broadcasting == 1,
      },
    };
  },

  computed: {
    filteredHistory() {
      return this.history.filter((message) => message.channel);
    },
  },

  components: {
    textareaSmsCounter,
    contactSelector,
  },

  mounted() {
    this.channel_id = this.default_channel;

    if (this.channel_id != null) {
      this.loadQuickReplies();
    }

    this.loadHistory();
    this.loadTags();

    var vm = this;
    $('.input-datetimepicker').on('change', function () {
      vm.send_at = $(this).val();
    });
  },

  watch: {
    default_channel: function (val) {
      this.channel_id = val;
    },

    send_moment: function (v) {
      if (v == 'scheduled') {
        $('.input-datetimepicker').datetimepicker({
          format: 'Y-m-d H:i',
        });
      }
    },
  },

  methods: {
    saveBulkSms() {
      var ids = [];
      _.each(this.summary.contacts, function (contact, i) {
        ids.push(contact.id);
      });

      if (this.send_moment == 'now') {
        this.send_at = null;
      }

      var data = {
        channel_id: this.channel_id,
        message: this.message,
        contacts: ids,
        send_at: this.send_at,
      };

      this.loading = true;

      axios.post('/client-api/sms/bulk', data).then((response) => {
        this.loading = false;
        $('#SummaryModal').modal('hide');
        this.loadHistory();
        this.reset();
        eventBus.$emit('sms.calculate-balance');
      });
    },
    loadSummary() {
      if (this.shouldShowChannels.broadcasting) return;
      var ids = [];
      _.each(this.contacts, function (contact, i) {
        ids.push(contact.id);
      });
      var data = {
        channel_id: this.channel_id,
        message: this.message,
        contacts: ids,
      };

      this.loading = true;

      $.post('/client-api/sms/bulk/summary', data).then((summary) => {
        this.summary = summary;
        this.loading = false;
        $('#SummaryModal').modal('show');
      });
    },

    loadHistory() {
      $.getJSON('/client-api/sms/bulk/history', (list) => {
        this.history = list;
      });
    },

    loadTags() {
      this.tags.push('name');
      map(this.$root.customContactFields, (f) => {
        this.tags.push(f.title);
      });
    },

    loadQuickReplies() {
      axios.get('/api/v2/quick_replies?type=SMS&channel_id=' + this.channel_id).then((res) => {
        this.quick_replies = res.data.data;
      });
    },

    insertTag(tag) {
      this.message = this.message + ' [' + tag + ']';
    },

    insertQuickReply(reply) {
      this.message = reply.message + '' + this.message;
    },

    isValid: function () {
      if (!this.enabled) {
        return false;
      }

      return (
        this.channel_id != null &&
        this.message != '' &&
        this.contacts.length != 0 &&
        (this.send_moment == 'now' || (this.send_moment == 'scheduled' && this.send_at != ''))
      );
    },

    reset() {
      this.contacts = [];
      this.message = '';
      this.send_moment = 'now';
    },

    async removeMessage(id) {
      const shouldDeleteMessage = await this.$tConfirm('Are you sure?', {
        delete: true,
        title: this.$t('general.are_you_sure'),
      });
      if (shouldDeleteMessage) {
        $.post('/client-api/sms/bulk/delete', { id: id }).then(() => {
          this.loadHistory();
          eventBus.$emit('sms.calculate-balance');
        });
      }
    },

    getStatusLabel: function (status) {
      if (status == 'SENT') {
        return '<label class="label success">' + this.$t('sms.status_sent') + '</label>';
      }

      if (status == 'SENDING') {
        return '<label class="label info">' + this.$t('sms.status_sending') + '</label>';
      }

      if (status == 'SCHEDULED') {
        return '<label class="label default">' + this.$t('sms.status_scheduled') + '</label>';
      }

      return status;
    },

    showBulkMessage(id) {
      $('#BulkContacts').modal('show');

      $.getJSON('/client-api/sms/bulk/show/' + id).then((res) => {
        this.bulkContacts = res.contacts;
      });
    },
    formattedSendAt(time) {
      return moment(time).format('D-MM-YYYY, HH:mm');
    },
  },
};
</script>

<template>
  <div class="w-full">
    <div class="box relative">
      <div v-if="shouldShowChannels.broadcasting" class="absolute inset-0 z-40 bg-white opacity-80"></div>

      <div class="box-header b-b">
        <h3>{{ $t('sms.bulk_sms') }}</h3>
      </div>
      <div class="box-body">
        <div v-if="!enabled" class="alert alert-danger">Please contact Trengo in order to enable bulk SMS.</div>
        <form class="py-4" @submit.prevent="loadSummary">
          <div class="form-group">
            <label>{{ $t('sms.sender') }}</label>
            <select v-model="channel_id" class="form-control" @change="loadQuickReplies()">
              <option v-for="channel in channels" :key="channel.id" :value="channel.id">
                {{ channel.title }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>{{ $t('sms.receivers') }}</label>
            <br />
            <contact-selector :contacts="contacts" @update:contacts="contacts = $event"></contact-selector>
          </div>
          <div class="form-group">
            <label>{{ $t('sms.content') }}</label>
            <textarea-sms-counter v-model="message"></textarea-sms-counter>
            <div class="btn-group" role="group">
              <button
                type="button"
                class="btn btn-default btn-xs dropdown-toggle"
                style="margin-right: 5px; margin-top: 6px"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ $t('sms.insert_quick_reply') }}
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li v-for="quick_reply in quick_replies" :key="quick_reply.title">
                  <a @click="insertQuickReply(quick_reply)">{{ quick_reply.title }}</a>
                </li>
              </ul>
            </div>
            <a
              v-for="tag in tags"
              :key="tag"
              class="btn btn-xs btn-default"
              style="margin-right: 5px; margin-top: 6px"
              @click="insertTag(tag)"
            >
              [{{ tag }}]
            </a>
          </div>
          <div class="form-group">
            <label>{{ $t('sms.send_moment') }}</label>
            <div>
              <label class="radio m-r-1">
                <input v-model="send_moment" type="radio" value="now" />
                {{ $t('sms.send_moment_now') }}
              </label>
              <label class="radio">
                <input v-model="send_moment" type="radio" value="scheduled" />
                {{ $t('sms.send_moment_scheduled') }}
              </label>
            </div>
          </div>
          <div v-show="send_moment == 'scheduled'" class="form-group">
            <label>{{ $t('sms.send_at') }}</label>
            <input v-model="send_at" type="text" class="form-control input-datetimepicker" />
          </div>

          <div class="form-group">
            <button type="submit" class="btn success text-white" :disabled="!isValid()">
              <i v-show="loading" class="fa fa-spin fa-spinner"></i>
              <span v-show="!loading">{{ $t('sms.confirm') }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="box w-full">
      <div class="box-header b-b">
        <h3>{{ $t('sms.bulk_history') }}</h3>
      </div>
      <div class="box-body">
        <table class="table">
          <thead>
            <tr>
              <th width="16%">{{ $t('sms.sender') }}</th>
              <th width="16%">{{ $t('sms.message') }}</th>
              <th width="16%">{{ $t('sms.contacts') }}</th>
              <th width="16%">{{ $t('sms.credits') }}</th>
              <th width="16%">{{ $t('sms.send_at') }}</th>
              <th width="16%">{{ $t('sms.status') }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="message in filteredHistory" :key="message.id">
              <td>{{ message.channel.sms_channel.sender_name }}</td>
              <td>{{ message.message }}</td>
              <td>
                <a style="text-decoration: underline" @click="showBulkMessage(message.id)">
                  {{ message.contacts_count }}
                </a>
              </td>
              <td>{{ message.credits }}</td>
              <td>{{ formattedSendAt(message.send_at) }}</td>
              <td>
                <span v-html="getStatusLabel(message.status)"></span>
              </td>
              <td align="right">
                <a
                  v-if="message.status == 'SCHEDULED'"
                  class="btn btn-default btn-xs"
                  @click="removeMessage(message.id)"
                >
                  {{ $t('sms.delete_scheduled_message') }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div id="SummaryModal" class="modal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('sms.summary') }}</h5>
            <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{ $t('sms.bulk_sms_summary_intro') }}</p>
            <form v-if="summary != null" data-pjax="false" @submit.prevent="saveBulkSms">
              <div v-show="summary.costs > summary.balance" class="alert alert-danger">
                {{ $t('sms.error_insufficient_balance') }}
              </div>
              <table class="table">
                <template v-if="summary.credits_required">
                  <tr>
                    <td width="300">{{ $t('sms.balance') }}</td>
                    <td>&euro; {{ summary.balance }}</td>
                  </tr>
                  <tr>
                    <td width="300">{{ $t('sms.credits_needed') }}</td>
                    <td :class="{ 'text-danger': summary.costs > summary.balance }">&euro; {{ summary.costs }}</td>
                  </tr>
                </template>
                <tr>
                  <td>{{ $t('sms.contacts') }}</td>
                  <td>
                    <span
                      style="text-decoration: underline; cursor: pointer"
                      @click="showSummaryContacts = !showSummaryContacts"
                    >
                      {{ summary.contacts.length }}
                    </span>
                    <div v-if="showSummaryContacts == true" style="max-height: 500px; overflow-y: auto" class="p-t-1">
                      <div v-for="contact in summary.contacts" :key="contact.phone">
                        {{ contact.phone }}
                        <span v-if="contact.name != null">({{ contact.name }})</span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('sms.message') }}</td>
                  <td v-html="message.replace(/(?:\r\n|\r|\n)/g, '<br />')"></td>
                </tr>
                <tr>
                  <td>{{ $t('sms.send_moment') }}</td>
                  <td>
                    <span v-show="send_moment == 'now'">{{ $t('sms.send_moment_now') }}</span>
                    <span v-show="send_moment == 'scheduled'">{{ formattedSendAt(send_at) }}</span>
                  </td>
                </tr>
              </table>
              <button type="submit" class="btn success text-white" :disabled="summary.costs > summary.balance">
                <i v-show="loading" class="fa fa-spin fa-spinner"></i>
                <span v-show="!loading">{{ $t('sms.send') }}</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div id="BulkContacts" class="modal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('sms.contacts') }}</h5>
            <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="list-group" style="max-height: 600px; overflow-y: scroll; display: block">
              <li v-for="contact in bulkContacts" :key="contact.phone" class="list-group-item">
                {{ contact.phone }}
                <span class="pull-right">
                  <label v-if="contact.pivot.sms_message_id != null" class="label success">
                    {{ $t('sms.status_sent') }}
                  </label>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
