<script type="text/babel">
import { map } from 'lodash';
import { mapGetters } from 'vuex';

import { updateChannel, getChannel } from '@/api/modules/channels';
import { CHANNEL_TYPE } from '@/Configs/Constants';
import { TRENGO } from '@/Configs/Constants/ChannelLandingPageTypes';

import RouterWarningOnEdit from '../../mixins/RouterWarningOnEdit';
import Avatar from '../Avatar';
import ColorPicker from '../Elements/ColorPicker';

let previewSound;

export default {
  emits: ['reloadChannelIndexList'],
  props: {
    action: {
      type: String,
      default: 'create',
    },
    channelType: {
      type: String,
      default: '',
    },
  },

  components: {
    ColorPicker,
    Avatar,
  },

  mixins: [RouterWarningOnEdit],

  watch: {
    teamsList(value) {
      this.teams = value;
    },
    'record.is_private'(v) {
      if (v && this.action === 'create') {
        this.record.users = this.$root.user;
      }
    },
    businessHoursList(value) {
      if (this.loaded && !this.record.business_hour_id && value && value.length) {
        this.record.business_hour_id = value[0]['id'];
        this.$nextTick(() => this.initWarning());
      }
    },
  },

  computed: {
    ...mapGetters({
      teamsList: 'teams/teamsList',
      businessHours: 'business_hours/businessHours',
    }),
    privateInboxUsers() {
      return this.$root.getBillableUsers();
    },

    showInfoChannel() {
      if (this.action === 'create') {
        return false;
      }

      return [
        CHANNEL_TYPE.FACEBOOK,
        CHANNEL_TYPE.TELEGRAM,
        CHANNEL_TYPE.EMAIL,
        CHANNEL_TYPE.ZIPWHIP,
        CHANNEL_TYPE.INSTAGRAM,
        CHANNEL_TYPE.WA_BUSINESS,
      ].includes(this.record.type);
    },

    canBePrivate() {
      return this.record.type === CHANNEL_TYPE.EMAIL;
    },

    hasAutoReplies() {
      if (this.record.type === CHANNEL_TYPE.SMS && this.record.smsChannel.type === 'SEND_ONLY') {
        return false;
      }

      return ![CHANNEL_TYPE.VOIP, CHANNEL_TYPE.EMAIL, CHANNEL_TYPE.FACEBOOK, CHANNEL_TYPE.INSTAGRAM].includes(
        this.record.type
      );
    },

    hasBusinessHours() {
      if (this.record.type === CHANNEL_TYPE.EMAIL) {
        return false;
      }
      return !(this.record.type === CHANNEL_TYPE.VOIP && this.record.voipChannel.provider === CHANNEL_TYPE.VOYS);
    },

    hasColorInput() {
      if (this.record.type === CHANNEL_TYPE.SMS && this.record.smsChannel.type === 'SEND_ONLY') {
        return false;
      }

      return this.record.type !== CHANNEL_TYPE.VOIP;
    },

    hasNotificationSound() {
      if (this.record.type === CHANNEL_TYPE.SMS && this.record.smsChannel.type === 'SEND_ONLY') {
        return false;
      }

      // voip uses different sound
      return this.record.type !== CHANNEL_TYPE.VOIP;
    },

    hasNotificationEmail() {
      if (this.record.type === CHANNEL_TYPE.SMS && this.record.smsChannel.type === 'SEND_ONLY') {
        return false;
      }

      if (this.record.type === CHANNEL_TYPE.EMAIL) {
        return false;
      }

      return this.record.type !== CHANNEL_TYPE.VOIP;
    },

    hasReopenClosedTickets() {
      return [
        CHANNEL_TYPE.EMAIL,
        CHANNEL_TYPE.WA_BUSINESS,
        CHANNEL_TYPE.FACEBOOK,
        CHANNEL_TYPE.TELEGRAM,
        CHANNEL_TYPE.SMS,
        CHANNEL_TYPE.INSTAGRAM,
      ].includes(this.record.type);
    },

    sentUpdatedEvent() {
      return this.record.type === CHANNEL_TYPE.FACEBOOK || this.record.type === CHANNEL_TYPE.INSTAGRAM;
    },

    shouldSaveOnCreate() {
      return this.record.type === CHANNEL_TYPE.VOIP && this.record.voipChannel.provider === CHANNEL_TYPE.VOYS;
    },

    showMoreGeneralSettings() {
      return this.action !== 'create';
    },
  },

  data() {
    return {
      ready: true,
      dirty: false,
      uploading: false,
      loaded: false,
      teams: [],
      // businessHours: [],
      saving: false,
      createText: 'Create channel',
      showFooter: true,
      showToastFlag: true,
      record: {
        type: this.channelType || this.$route.params.channel.toUpperCase(),
        notification_email: '',
        notification_sound: 'chat.mp3',
        username: '',
        password: '',
        password_is_null: false,
        title: this.channelType || this.$route.params.channel.toUpperCase(),
        text: '',
        color: null,
        teams: [],
        users: [],
        reopen_closed_ticket: 1,
        business_hour_id: null,
        is_private: false,
        reassign_reopened_ticket: false,
        reopen_closed_ticket_time_window_days: 30,
      },
    };
  },

  mounted() {
    this.channel = this.$route.params.type;

    if (this.action === 'edit') {
      this.fetchChannel();
    } else {
      this.$nextTick(() => this.initWarning());
    }

    this.$store.dispatch('business_hours/fetchBusinessHours');
    this.$store.dispatch('teams/fetchTeams');
  },

  methods: {
    showToast(type, msg) {
      if (this.showToastFlag) {
        if (type === 'success') {
          this.flashSuccess(msg);
        }
      }
    },
    fetchChannel() {
      getChannel(this.$route.params.id).then((result) => {
        this.record = result.data;
        this.loaded = true;
        if (!this.record.business_hour_id && this.businessHours && this.businessHours.length) {
          this.record.business_hour_id = this.businessHours[0]['id'];
        }
        if (
          this.record.emailChannel.meta.reply_all &&
          !this.record.emailChannel.meta.hasOwnProperty('email_addresses')
        ) {
          this.record.emailChannel.meta.email_addresses = [];
        }
        this.$nextTick(() => this.initWarning());
        if (typeof this.fetchedChannel === 'function') {
          this.fetchedChannel();
        }
      });
    },
    async save() {
      if (this.saving || this.uploading) {
        return;
      }

      this.saving = true;

      if (this.$refs.channelAction && this.$refs.channelAction.beforeSave) {
        await this.$refs.channelAction.beforeSave();
      }

      if (this.action === 'create') {
        this.createChannel();
        return;
      }
      this.updateChannel();
    },

    createChannel() {
      axios
        .post('/api/v2/channels', { ...this.record, ...{ team_ids: this.mapTeamIds(), user_ids: this.mapUserIds() } })
        .then((res) => {
          this.saving = false;
          this.$emit('reloadChannelIndexList');
          this.initWarning(false);
          router.push('/admin/channels2/' + this.record.type.toLowerCase() + '/' + res.data.id);
          this.showToast('success', this.$t('settings.channels_the_channel_has_been_created_successfully'));
          this.onSaved();
        })
        .catch(() => {
          this.saving = false;
        });
    },

    updateChannel() {
      const emailChannelMeta = this.record.emailChannel?.meta;
      const syncFolders = this.record.emailChannel?.meta?.syncFolders;
      const isSyncFoldersBoolean = typeof this.record.emailChannel?.meta?.syncFolders === 'boolean';
      const hasSyncFoldersProperty = emailChannelMeta && 'syncFolders' in emailChannelMeta;
      if (hasSyncFoldersProperty) {
        emailChannelMeta.syncFolders = isSyncFoldersBoolean ? syncFolders : Boolean(syncFolders?.length);
      }

      const emailChannel = this.record.emailChannel
        ? {
            ...this.record.emailChannel,
            ...{ meta: emailChannelMeta },
          }
        : null;

      const payload = {
        ...this.record,
        ...{ team_ids: this.mapTeamIds(), user_ids: this.mapUserIds(), emailChannel },
      };

      updateChannel(this.record.id, payload)
        .then((res) => {
          this.saving = false;
          this.recordIsDirty = false;
          this.$emit('reloadChannelIndexList');
          this.showToast('success', this.$t('channels.update_successful'));
          this.onSaved();

          if (this.sentUpdatedEvent) {
            this.$refs.channelInfo.updated();
          }
          if (this.$route.query.action === 'reconnect') {
            this.$nextTick(() => this.initWarning());
            return;
          }
          this.fetchChannel();
        })
        .catch(() => {
          this.saving = false;
        });
    },

    async deleteChannel() {
      const message = this.getDeleteChannelAlertContent(this.isForwardingChannel);
      const shouldDeleteChannel = await this.$tConfirm(message, {
        delete: true,
        title: this.$t('channels.delete_confirm_title'),
      });
      if (shouldDeleteChannel) {
        axios.delete('/api/v2/channels/' + this.record.id).then(() => {
          router.push('/admin/channels2/' + this.record.type.toLowerCase());
          this.showToast('success', this.$t('channels.delete_successful'));
          this.onDeleted();
        });
      }
    },

    mapTeamIds() {
      if (this.record.is_private) {
        return [];
      }
      return map(this.record.teams, 'id');
    },

    mapUserIds() {
      if (!this.record.users || !this.record.is_private) {
        return [];
      }
      return [this.record.users.id || this.record.users[0].id];
    },

    updateColorValue(color) {
      this.record.color = color.hex;
    },

    onSaved() {
      this.$root.loadAllChannels();
      return this.$root.loadAuthorizedChannels();
    },

    onDeleted() {
      this.$root.loadAllChannels();
      return this.$root.loadAuthorizedChannels();
    },

    playNotificationSound() {
      if (!this.record.notification_sound) {
        return;
      }
      let preview;
      if (!previewSound) {
        preview = new Audio('/sound/channel_notification/' + this.record.notification_sound);
        previewSound = preview;
      } else {
        previewSound.src = '/sound/channel_notification/' + this.record.notification_sound;
        previewSound.load();
        preview = previewSound;
      }
      preview.play();
    },
    getDeleteChannelAlertContent(isForwardingChannel) {
      let message;
      if (isForwardingChannel) {
        message =
          this.$t('channels.delete_confirm_will_remove_all_conversations') +
          '<br>' +
          `<span class="mt-1.5 inline-block">
            ${this.$t('channels.delete_confirm_will_remove_all_conversations_forwarding_channel')}
          </span>`;
      } else {
        message = this.$t('channels.delete_confirm_will_remove_all_conversations');
      }
      return message;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.onBeforeRouteLeaveGuard(to, from, next);
  },
  beforeRouteUpdate(to, from, next) {
    this.onBeforeRouteUpdateGuard(to, from, next);
  },
};
</script>
