<template>
  <div class="flex h-8 w-8 items-center text-center">
    <div v-if="checked" class="flex h-full w-full items-center rounded-full bg-leaf-400 text-white">
      <i class="fas fa-check mx-auto" />
    </div>
    <div v-else class="mx-auto h-3 w-3 rounded-full border-2 border-grey-400 bg-white" />
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: true,
    },
  },
  name: 'RoundCheckIndicator',
};
</script>

<style scoped></style>
