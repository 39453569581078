<template>
  <div class="permissions-list-item">
    <div class="permissions-list-item__icon">
      <slot name="icon" />
    </div>
    <div class="permissions-list-item__content">
      <span class="permissions-list-item__content--title">{{ title }}</span>
      <span class="permissions-list-item__content--subtitle">{{ subtitle }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PermissionsListItem',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss" src="./PermissionsListItem.scss" />
