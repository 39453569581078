import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-96c11bf6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "t-text-md text-grey-800",
  "data-test": "integration-description"
}
const _hoisted_2 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_3 = { class: "t-text-md-emphasize mb-2 text-grey-800" }
const _hoisted_4 = {
  key: 1,
  class: "pb-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_dropdown = _resolveComponent("t-dropdown")!
  const _component_t_input_text = _resolveComponent("t-input-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('integration_hub.install_modal_form_description', { name: _ctx.integration.name })), 1 /* TEXT */),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
      return (_openBlock(), _createElementBlock("div", null, [
        (!field.hidden && field.type === 'select')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(field.label), 1 /* TEXT */),
              _createVNode(_component_t_dropdown, {
                modelValue: field.value,
                "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                "text-index": "label",
                disabled: field.disabled,
                error: field.hasError,
                placeholder: field.placeholder,
                items: field.options,
                "value-index": "value",
                size: "lg"
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "error", "placeholder", "items"])
            ]))
          : (!field.hidden)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_t_input_text, {
                  id: field.id,
                  modelValue: field.value,
                  "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                  "has-error": field.hasError,
                  type: field.sensitive ? 'password' : 'text',
                  required: field.required,
                  label: field.label,
                  "sub-label": field.description,
                  placeholder: field.placeholder
                }, _createSlots({ _: 2 /* DYNAMIC */ }, [
                  (field.prefix)
                    ? {
                        name: "prefix",
                        fn: _withCtx(() => [
                          _createTextVNode(_toDisplayString(field.prefix), 1 /* TEXT */)
                        ]),
                        key: "0"
                      }
                    : undefined,
                  (field.suffix)
                    ? {
                        name: "suffix",
                        fn: _withCtx(() => [
                          _createTextVNode(_toDisplayString(field.suffix), 1 /* TEXT */)
                        ]),
                        key: "1"
                      }
                    : undefined
                ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["id", "modelValue", "onUpdate:modelValue", "has-error", "type", "required", "label", "sub-label", "placeholder"])
              ]))
            : _createCommentVNode("v-if", true)
      ]))
    }), 256 /* UNKEYED_FRAGMENT */))
  ]))
}