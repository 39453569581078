<template>
  <sidebar-dropdown
    :title="$tc('ticket_sidebar.ticket_fields')"
    collapse-key="ticketFieldsDropdown"
    data-test="ticket-fields-dropdown"
  >
    <div v-if="fields.length > 0" class="flex flex-col">
      <div v-for="field in fields" :key="field.id" data-test="ticket-field-item-wrapper">
        <p class="t-text-md mb-0 p-0 text-grey-800" data-test="ticket-field-title">{{ field.title }}</p>

        <custom-field-item class="mb-2" :field="field" @save="save(field)" />
      </div>
    </div>

    <template v-else>
      <t-inline-banner type="default">
        <template #icon>
          <info-linear />
        </template>

        <p class="t-text-sm-emphasize m-0 p-0">{{ $tc('ticket_sidebar.no_ticket_fields_setup') }}</p>
      </t-inline-banner>
    </template>
  </sidebar-dropdown>
</template>

<script lang="ts">
import { InfoLinear } from '@trengo/trengo-icons';
import { cloneDeep, lowerCase, remove, some } from 'lodash';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import { storeTicketCustomField } from '@/components/TicketSidebar/Api';
import CustomFieldItem from '@/components/TicketSidebar/CustomFieldItem';
import SidebarDropdown from '@/components/TicketSidebar/SidebarDropdown.vue';
import ticketSidebarMixins from '@/components/TicketSidebar/ticketSidebarMixins';
import eventBus from '@/eventBus';

import type Ticket from '@/types/tickets';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'TicketFieldsDropdown',

  props: {
    ticket: {
      type: Object as PropType<Ticket>,
      default: () => ({}),
    },
  },

  mixins: [ticketSidebarMixins],

  data: function () {
    return {
      sidebarShowTicketFields: false,
      fields: [],
    };
  },

  mounted() {
    this.loadList();

    eventBus.$on('ticket.current.reload.done', () => {
      this.$nextTick(() => {
        this.loadList();
      });
    });
  },

  components: {
    InfoLinear,
    SidebarDropdown,
    CustomFieldItem,
  },

  computed: {
    ...mapState({
      storedFields: (state) => state.customFields.customTicketFields,
      customFieldsLoading: (state) => state.customFields.loading,
      customFieldsLoaded: (state) => state.customFields.loaded,
    }),
  },

  watch: {
    customFieldsLoaded(loaded) {
      if (loaded) {
        this.loadList();
      }
    },
  },

  methods: {
    save(field) {
      const fieldValue = field.value || null;
      this.commitFields();
      storeTicketCustomField(this.ticket.id, field.id, fieldValue)
        .then(() => {
          this.resetFieldServerError(lowerCase(field.type), field.id);
        })
        .catch((error) => {
          this.setFieldServerError(lowerCase(field.type), field.id, error.response?.data?.errors);
        })
        .finally(() => {
          this.$nextTick(this.commitFields);
        });
    },

    commitFields() {
      this.$store.commit('customFields/setTicketTicketFields', cloneDeep(this.fields));
    },

    loadList() {
      this.fields = cloneDeep(this.storedFields);

      remove(this.fields, (field) => {
        return field.channels.length && !some(field.channels, ['id', this.ticket.channel.id]);
      });

      this.fields = this.fields.map((field) => {
        return {
          ...field,
          value: this.ticket?.custom_data ? this.ticket.custom_data[field.title] : null,
          ticketId: this.ticket?.id,
        };
      });

      this.commitFields();
    },
  },
});
</script>
