<template>
  <div style="width: 100%" class="m-b-1 b-b p-b">
    <div class="display-flex m-b-sm">
      <div style="width: 90px; padding-top: 10px">
        <span class="text-muted">{{ $t('chatbot.question') }}</span>
      </div>
      <div style="flex: 1" class="m-x-xs">
        <input v-model="item.query" type="text" class="form-control" />
      </div>
      <div style="width: 80px; margin-bottom: -50px" class="text-right">
        <a
          v-show="item.question != null && item.question != ''"
          class="btn btn-sm btn-default text-success"
          @click="save(item)"
        >
          <i v-show="item.id != saving" class="fa fa-check"></i>
          <i v-show="item.id == saving" class="fa fa-spinner fa-spin"></i>
        </a>
        <a class="btn btn-sm btn-default text-muted" @click="destroy(item)"><i class="fa fa-trash"></i></a>
      </div>
    </div>
    <div class="display-flex m-x-xs">
      <div style="width: 90px; padding-top: 10px">
        <span class="text-muted">{{ $t('chatbot.answer') }}</span>
      </div>
      <div style="flex: 1; padding-right: 80px">
        <multiselect
          v-show="create != item.id"
          v-model="item.question"
          label="answer"
          track-by="id"
          :allow-empty="true"
          :options="questions"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :multiple="false"
          :placeholder="$t('chatbot.select_an_answer')"
        >
          <template #beforeList>
            <div class="text-success" style="padding: 10px 12px 10px 12px; cursor: pointer" @click="addAnswer(item)">
              <i class="fa fa-plus"></i>
              {{ $t('chatbot.create_new') }}
            </div>
          </template>
        </multiselect>

        <span
          v-if="item.question && item.score > 0 && item.chatbot_question_id == item.question.id"
          class="text-muted"
          style="padding-top: 7px; font-size: 12px"
        >
          Confidence:
          <label class="label label-default">{{ item.score }}%</label>
        </span>

        <div v-show="create == item.id">
          <div class="input-group">
            <input v-model="item.question" type="text" class="form-control" />
            <span class="input-group-btn">
              <button class="btn btn-default" type="button" @click="create = null"><i class="fa fa-times"></i></button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import Multiselect from 'vue-multiselect';

export default {
  name: 'Mismatch',
  emits: ['unmounted'],
  props: {
    record: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    questions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    Multiselect,
  },

  data() {
    return {
      create: null,
      item: this.record,
      saving: null,
    };
  },

  methods: {
    save(mismatch) {
      if (this.saving != null) {
        return;
      }

      let url =
        this.create == this.item.id
          ? '/api/v2/chatbot/' + this.record.chatbot_id + '/mismatches'
          : '/api/v2/chatbot/' + this.record.chatbot_id + '/mismatches/' + mismatch.id;

      this.saving = mismatch.id;
      axios({
        url: url,
        data: { mismatch_id: mismatch.id, question: mismatch.question, query: mismatch.query },
        method: this.create == this.item.id ? 'POST' : 'PUT',
      })
        .then(() => {
          this.flashSuccess(this.$t('chatbot.your_mismatch_has_been_processed'));
          this.saving = null;
          this.$emit('unmounted', mismatch);
          this.saving = null;
        })
        .catch(() => {
          this.flashError(this.$t('chatbot.failed_to_save_mismatch'));
          this.saving = null;
        });
    },

    destroy(mismatch) {
      this.$emit('unmounted', mismatch);
      axios.delete('/api/v2/chatbot/' + this.record.chatbot_id + '/mismatches/' + mismatch.id);
    },

    addAnswer(item) {
      this.create = item.id;
      item.question = null;
    },
  },
};
</script>
