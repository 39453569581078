<script type="text/babel">
export default {
  props: ['profile_id', 'url', 'mode', 'placement', 'inputClass', 'value'],

  mounted() {
    this.init();
  },

  watch: {
    pk() {
      this.init();
    },
    value() {
      this.init();
    },
  },

  methods: {
    init() {
      $(this.$el)
        .editable({
          mode: this.mode,
          type: 'text',
          placement: this.placement,
          send: 'never',
        })
        .on('save', (a, b) => {
          axios.put('/api/v2/profiles/' + this.profile_id, { name: b.newValue });
        });
    },
  },
};
</script>

<template>
  <span style="cursor: pointer">{{ value }}</span>
</template>
