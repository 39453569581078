<template>
  <sidebar-dropdown
    :title="noteType === 'contact' ? $tc('ticket_sidebar.contact_notes') : $tc('ticket_sidebar.profile_notes')"
    :collapse-key="`${noteType}NotesDropdown`"
    :data-test="`${noteType}-notes-dropdown`"
    @mounted="onDropdownChange"
    @toggled="onDropdownChange"
  >
    <textarea
      id="newNote"
      v-model="newNote"
      rows="2"
      placeholder="Enter new note text"
      class="
        mb-1.5
        resize-none
        rounded-md
        border-2 border-grey-300 border-opacity-30
        px-1.5
        py-1
        transition-colors
        focus:border-leaf-500
      "
      data-test="note-textarea"
      @keydown.enter.prevent="addNote"
      @keydown.esc.prevent="newNote = ''"
    />

    <div class="mb-2 flex flex-row">
      <t-button btn-style="secondary" class="w-fit" data-test="note-cancel-button" @click.prevent="newNote = ''">
        {{ $tc('general.cancel') }}
      </t-button>
      <t-button class="ml-2 w-fit" :disabled="isSaving" data-test="note-save-button" @click.prevent="addNote">
        {{ $tc('general.save') }}
      </t-button>
    </div>

    <div v-if="hasFetched" class="flex max-h-[250px] flex-col overflow-y-auto">
      <div
        v-for="note in notes"
        :key="note.id"
        class="mb-1.5 flex flex-col rounded-md border-2 border-grey-300 border-opacity-30 px-2 py-1.5"
      >
        <div class="flex flex-row items-center">
          <avatar
            v-if="note.user"
            width="20"
            :color="note.user.color"
            :abbr="note.user.abbr"
            :image="note.user.profile_image"
          />
          <avatar v-else width="20" color="#000000" abbr="A" />

          <p class="t-text-sm-emphasize m-0 ml-2 text-ellipsis p-0" data-test="note-user-name">
            {{ note.user ? note.user.full_name : $tc('general.deleted_user') }}
          </p>

          <time-left-ago :date="note.created_at" class="ml-1" />
        </div>

        <p class="m-0 mt-2 break-words p-0" data-test="note-content" v-html="note.body || note.note" />
      </div>
    </div>

    <t-spinner v-else class="mx-auto" size="1.6rem" />
  </sidebar-dropdown>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import Avatar from '@/components/Avatar.vue';
import TimeLeftAgo from '@/components/Elements/TimeLeftAgo.vue';
import {
  getContactNotes,
  getProfileNotes,
  saveNewContactNote,
  saveNewProfileNote,
} from '@/components/TicketSidebar/Api/requests';
import useSidebarFetch from '@/components/TicketSidebar/composables/dataFetch';
import SidebarDropdown from '@/components/TicketSidebar/SidebarDropdown.vue';

import type Contact from '@/types/contact';
import type Profile from '@/types/profile';

type Props = {
  contact?: Contact;
  profile?: Profile;
  noteType?: 'contact' | 'profile';
};

const props = withDefaults(defineProps<Props>(), {
  noteType: 'contact',
});

const notes = ref([]);
const newNote = ref('');

async function fetchNotes() {
  const fetchFunction = props.noteType === 'contact' ? getContactNotes : getProfileNotes;
  const id = props.noteType === 'contact' ? props.contact?.id : props.profile?.id;

  try {
    await fetchFunction(id as number)
      .then((response) => {
        notes.value = response.data.notes;
      })
      .finally(() => {
        hasFetched.value = true;
      });
  } catch (err) {
    console.error(err);
    this.flashError(err);
  }
}

const isSaving = ref(false);

async function addNote() {
  if (isSaving.value || !newNote.value) {
    return;
  }

  const saveNote = props.noteType === 'contact' ? saveNewContactNote : saveNewProfileNote;
  const id = props.noteType === 'contact' ? props.contact.id : props.profile.id;

  try {
    isSaving.value = true;

    await saveNote(id, newNote.value.replace(/\r?\n/, '<br>'))
      .then(async () => {
        newNote.value = '';
        await fetchNotes();
      })
      .finally(() => {
        isSaving.value = false;
      });
  } catch (err) {
    console.error(err);
    this.flashError(err);
    isSaving.value = false;
  }
}

const { hasFetched, setDropdownStatus } = useSidebarFetch();

const onDropdownChange = (status: boolean) => setDropdownStatus(status, fetchNotes);
</script>
