<template>
  <div class="dropdown select-none" style="border-radius: 10px">
    <dropdown
      :options="notifications"
      :search="false"
      dropdown-class="dropdown-notifications"
      :width="'444px'"
      max-height="400px"
      :hover="false"
      placement="right"
      @update:model-value="openNotification"
      @close="$emit('close')"
    >
      <template #heading>
        <div class="_500 py-4 text-center text-base leading-none text-black">
          {{ $t('general.notifications') }}
          <div v-if="!loading && notifications.length > 0" class="">
            <div
              class="mb-1 mt-1 flex cursor-pointer items-center justify-center pt-4 text-xs leading-none text-grey-600"
            >
              <span role="button" @click="deleteAll">{{ $t('notification.delete_all') }}</span>
              <span class="mx-2">|</span>
              <span role="button" @click="readAll">{{ $t('notification.read_all') }}</span>
            </div>
          </div>
        </div>
        <sticky-notifications />
        <div v-show="loading" class="mb-6 mt-2 text-center">
          <i class="fa fa-spinner fa-lg fa-spin"></i>
        </div>
        <div v-show="!loading && notifications.length == 0" class="mb-6 mt-2 text-center text-grey-600">
          {{ $t('notification.empty_list') }}
        </div>
      </template>
      <template #toggle>
        <a
          id="keyboardShortCutShowNotificationsRef"
          class="icon-navigation"
          data-test="main-navigation-notification"
          @click="onClicked"
        >
          <icon-notification class="icon-notification" />
          <t-badge
            v-show="count > 0"
            variant="notification"
            :text="count < 1000 ? count.toString() : 999"
            class="icon-label absolute lg:right-0"
          />
        </a>
      </template>
      <template #option="notification">
        <div class="flex max-h-full min-w-0 flex-1 border-grey-200 p-2 py-0 xl:max-h-none">
          <div class="mr-3">
            <avatar
              :color="notification.option.data.user.color"
              :abbr="notification.option.data.user.abbr"
              :image="notification.option.data.user.profile_image"
            ></avatar>
          </div>
          <div
            class="flex min-w-0 flex-1 flex-shrink-0"
            :class="{
              'font-bold': notification.option.read_at == null,
              'text-ellipsis': !isSystemNotification(notification),
              'w-full': isSystemNotification(notification),
            }"
          >
            <div
              class="min-w-0 flex-shrink-0"
              :class="isSystemNotification(notification) ? 'w-full whitespace-normal' : 'text-ellipsis'"
            >
              <span>
                <strong v-if="notification.option.type === NOTIFICATION_TYPE.WABA_REVIEW_REMINDER">
                  {{ $t('notification.notifications_reminder') }}
                </strong>
                {{ formatNotification(notification.option) }}
              </span>
              <br />
              <div
                v-if="notification.option.data.message"
                :class="isSystemNotification(notification) ? 'whitespace-normal' : 'text-ellipsis'"
              >
                {{ notification.option.data.message }}
              </div>
              <small v-if="notification.option.data.contact_name" class="text-muted text-ellipsis">
                Contact: {{ notification.option.data.contact_name }}
              </small>
              <div class="flex-shrink-0 flex-nowrap text-xs font-normal text-grey-600">
                <datetime :time="notification.option.created_at" :pretty="$root.prettyDates"></datetime>
              </div>
            </div>
          </div>
        </div>
      </template>
    </dropdown>
  </div>
</template>

<script lang="ts">
import Avatar from '@/components/Avatar';
import StickyNotifications from '@/components/Notifications/Components/StickyNotifications';
import iconNotification from '@/components/sidebarNavigationIcons/iconNotification';
import eventBus from '@/eventBus';
import { request } from '@/util';
import { segmentTrackEvent } from '@/util/analytics/segmentAnalytics';

import { NOTIFICATION_TYPE } from './constants';
import { getRouteFromType, getTranslationParamsFromType } from './utils';
import Dropdown from '../ReplyForm/Dropdown';

export default {
  name: 'NotificationPanel',

  components: {
    Avatar,
    Dropdown,
    StickyNotifications,
    iconNotification,
  },
  emits: ['open', 'close'],
  data() {
    return {
      notifications: [],
      count: 0,
      loading: false,
      open: false,
    };
  },
  computed: {
    NOTIFICATION_TYPE() {
      return NOTIFICATION_TYPE;
    },
  },

  mounted() {
    this.bind();
    eventBus.$on('referral_button_closed', this.referralButtonClosed);
    eventBus.$on('notification.clicked', (notification_id) => {
      this.count--;
      request(`/client-api/notifications/${notification_id}/read`, 'POST');
    });

    this.fetchCount();
  },
  unmounted() {
    eventBus.$off('referral_button_closed', this.referralButtonClosed);
    eventBus.$off('notification.clicked');
  },

  methods: {
    isNotificationTypeWABA(type) {
      return [
        NOTIFICATION_TYPE.WABA_BANNED,
        NOTIFICATION_TYPE.WABA_REVIEW_COMPLETED,
        NOTIFICATION_TYPE.WABA_REVIEW_REMINDER,
      ].includes(type);
    },
    isSystemNotification(notification) {
      return notification.option.data.user.id === null;
    },
    referralButtonClosed() {
      this.count -= 1;
    },
    countReferralAsNotification() {
      const referralExists = !this.$store.getters['userConfig/get']('referral_button_bol_pressed');
      const accountIsEqualOrMoreThanThreeMonths =
        moment().diff(this.$root.companyProfile.profile.created_at, 'months') >= 3;
      if (
        referralExists &&
        accountIsEqualOrMoreThanThreeMonths &&
        this.$root.companyProfile.profile.invoice_country === 'NL'
      ) {
        this.count += 1;
      }
    },
    bind() {
      if (this.$root.userChannel.subscribed) {
        this.$root.userChannel.bind('notification', (notification) => this.newNotification(notification));
        this.$root.userChannel.bind('reload-notifications', this.reloadNotifications());
      } else {
        eventBus.$once('pusher.private-user-' + this.$root.user.id + '.subscribed', this.bind);
      }
    },

    newNotification(notification) {
      this.notifications.unshift(notification);
      this.count++;

      // Notify if current page is not the target
      const target = '/tickets/' + notification['data']['ticket_id'];
      if (target !== window.location.pathname || !window.hasWindowFocus) {
        if (notification.type !== NOTIFICATION_TYPE.NEW_TICKET_MESSAGE) {
          this.$root.notify(
            notification.data.user || null,
            this.formatNotification(notification),
            null,
            null,
            '/tickets/' + notification['data']['ticket_id'],
            notification['data']['id'],
          );
        }
        if (this.isNotificationTypeWABA(notification['type'])) {
          this.$root.notify(
            notification.data.user || null,
            this.formatNotification(notification),
            null,
            null,
            '/admin/channels2/wa_business/' + notification['data']['channel_id'],
            notification['data']['id'],
          );
        }
      }
    },

    reloadNotifications() {
      this.fetchCount();
      this.fetchList();
    },

    fetchCount() {
      request('/client-api/notifications/count', 'GET').then((res) => {
        this.count = res.data;
        this.$nextTick(() => {
          this.countReferralAsNotification();
        });
      });
    },

    handleSegmentTracking() {
      segmentTrackEvent({
        event_name: 'notifications',
        action: 'open',
        object_title: 'Notifications',
      });
    },

    onClicked() {
      this.fetchList();
      this.handleSegmentTracking();
      this.$emit('open', 'mainNavNotifications');
    },

    fetchList(reset = true) {
      if (reset) {
        this.notifications = [];
        this.loading = true;
      }

      request('/client-api/notifications/list', 'GET').then((res) => {
        this.notifications = res.data;
        this.loading = false;
      });
    },

    formatNotification(notification) {
      try {
        const translationParams = getTranslationParamsFromType(notification);
        return this.$t(...translationParams);
      } catch (err) {
        console.error(err);
      }
    },

    openNotification(notification) {
      try {
        this.count--;
        request(`/client-api/notifications/${notification.id}/read`, 'POST');
        const route = getRouteFromType(notification);
        this.$router.push(route);
      } catch (err) {
        this.flashError(err);
        console.error(err);
      }
    },

    readAll() {
      this.count = 0;
      request('/client-api/notifications/read-all', 'POST').then(() => {
        this.fetchList(false);
      });
    },

    deleteAll() {
      this.notifications = [];
      this.count = 0;
      request('/client-api/notifications/delete-all', 'POST');
      if (is_mobile_device()) {
        window.location.reload(); // tmp fix for preventing Dropdown.vue to break
      }
    },
  },
};
</script>

<style lang="scss">
.dropdown-notifications {
  box-shadow:
    0 12px 93px rgba(0, 0, 0, 0.12),
    0 2.68036px 20.7728px rgba(0, 0, 0, 0.08),
    0 0.798012px 6.1846px rgba(0, 0, 0, 0.06) !important;
  bottom: 79px !important;
  left: 88px !important;
}

@media (max-width: 991px) {
  .icon-navigation .icon-notification svg {
    fill: var(--color-grey-900);
  }
}
</style>
