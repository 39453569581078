import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-10f6163e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "preview flex flex-col items-center justify-center rounded-lg bg-contain bg-center p-12" }
const _hoisted_2 = {
  key: 0,
  class: "message-width preview__card relative min-w-[94px] break-words rounded-md bg-white px-2 pb-2 pt-3"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "mt-2 pb-3" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "preview__footer flex items-center justify-between" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "time mb-0 ml-auto text-sm leading-4" }
const _hoisted_10 = { class: "mb-0 break-words text-center text-base font-normal leading-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.shouldShowMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!!_ctx.templateHeader && _ctx.selectedHeaderType === _ctx.TEMPLATE_HEADER_TYPE.TEXT)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: "mb-0 text-lg font-bold leading-5",
                innerHTML: _ctx.formattedText(_ctx.templateHeader)
              }, null, 8 /* PROPS */, _hoisted_3))
            : _createCommentVNode("v-if", true),
          (_ctx.selectedHeaderType === _ctx.TEMPLATE_HEADER_TYPE.IMAGE)
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: _ctx.imageSrc,
                class: "preview__image"
              }, null, 8 /* PROPS */, _hoisted_4))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", {
              class: "mb-0 text-base font-medium leading-5",
              innerHTML: _ctx.formattedText(_ctx.templateBody)
            }, null, 8 /* PROPS */, _hoisted_6)
          ]),
          _createElementVNode("div", _hoisted_7, [
            (!!_ctx.templateFooter)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: "footer mb-0 mr-2 w-4/6 break-words text-sm font-normal leading-4",
                  innerHTML: _ctx.formattedText(_ctx.templateFooter)
                }, null, 8 /* PROPS */, _hoisted_8))
              : _createCommentVNode("v-if", true),
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.currentTime), 1 /* TEXT */)
          ])
        ]))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredButtons, (btn) => {
      return (_openBlock(), _createElementBlock("div", {
        key: btn.id,
        class: "preview__buttons message-width mt-0.5 flex w-full items-center justify-center rounded-sm bg-white px-3 py-2"
      }, [
        _createElementVNode("p", _hoisted_10, _toDisplayString(btn.text), 1 /* TEXT */)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}