<script type="text/babel">
import { ArrowTopCircleLinear } from '@trengo/trengo-icons';
import { toArray } from 'lodash';
import { mapStores } from 'pinia';

import { ENTITLEMENT_STATUS, FEATURE } from '@/Configs/Constants';
import { useEntitlementsStore } from '@/store/pinia';

import Preview from './Preview.vue';

export default {
  name: 'HelpCenterTheme',
  emits: ['call-load-help-center-method'],
  components: {
    ArrowTopCircleLinear,
    Preview,
  },

  props: {
    helpCenter: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      languages: [],
      selectedLanguages: [],
      urlType: 'default',
      color: this.helpCenter.theme.primary_color,
      logo_path: this.helpCenter.theme.logo_path,
      favicon_path: this.helpCenter.theme.favicon_path,
      favicon: null,
      custom_css: this.helpCenter.theme.custom_css || '',
      saving: false,
      selectedLogo: null,
      selectedFavicon: null,
      branding_enabled: true,
    };
  },

  watch: {
    selectedObjects(newValues) {
      this.selectedIds = newValues.map((obj) => obj.id);
    },
  },

  mounted() {
    this.branding_enabled = this.isEntitled ? this.helpCenter.branding_enabled : true;
    axios.get('/api/v2/locale_codes').then((languages) => {
      this.localeCodes = {};
      languages.data.forEach((l) => {
        Object.assign(this.localeCodes, {
          [l.id]: {
            id: l.id,
            text: l.name,
          },
        });
      });
      this.languages = toArray(this.localeCodes);
    });

    $('.input-colorpicker')
      .colorpicker({ format: 'hex' })
      .change((e) => {
        this.color = e.target.value;
      });
  },

  computed: {
    ...mapStores(useEntitlementsStore),
    requiresPlanUpgrade() {
      return (
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__HELPCENTER__WHITELABEL) ===
        ENTITLEMENT_STATUS.REQUIRES_PLAN_UPGRADE
      );
    },
    isEntitled() {
      return this.entitlementsStore?.isEntitledTo(FEATURE.CUSTOMER_SERVICE__HELPCENTER__WHITELABEL);
    },
    minimumRequiredPlan() {
      return this.entitlementsStore?.getMinimumRequiredPlanFor(FEATURE.CUSTOMER_SERVICE__HELPCENTER__WHITELABEL);
    },
    trengoBrandingText() {
      return this.$t('help_center.upgrade_to_plan', { plan: this.minimumRequiredPlan });
    },
  },

  methods: {
    onLogoSelected(value) {
      this.selectedLogo = value || [];
      // Encode the file using the FileReader API
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        this.logo_path = base64String;
      };
      reader.readAsDataURL(value[0]);
    },

    onFaviconSelected(value) {
      this.selectedFavicon = value || [];
      // Encode the file using the FileReader API
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        this.favicon_path = base64String;
      };
      reader.readAsDataURL(value[0]);
    },

    save() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      var formData = new FormData();
      formData.append('primary_color', this.color);
      formData.append('custom_css', this.custom_css);
      formData.append('branding_enabled', this.branding_enabled);

      if (this.logo_path != this.helpCenter.theme.logo_path) {
        formData.append('logo', this.selectedLogo[0]);
      }

      if (this.favicon_path != this.helpCenter.theme.favicon_path) {
        formData.append('favicon', this.selectedFavicon[0]);
      }

      axios
        .post('/api/v2/help_center/' + this.helpCenter.id + '/theme', formData)
        .then((res) => {
          this.saving = false;
          this.flashSuccess(this.$t('help_center.you_have_successfully_updated_your_help_center_settings'));
          this.$emit('call-load-help-center-method');
        })
        .catch((e) => {
          this.saving = false;
        });
    },
  },
};
</script>

<template>
  <div class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis">{{ $t('help_center.theme') }}</span>
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body scrollable hove">
        <div class="row-inner">
          <div class="p-a container">
            <div class="row">
              <div class="col-md-12">
                <div class="box">
                  <form @submit.prevent="save">
                    <div class="box-body">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group col-md-3">
                            <label>
                              <strong>{{ $t('help_center.logo') }}</strong>
                            </label>
                            <img :src="logo_path" class="img-responsive m-b" />
                            <t-file-input
                              id="logo-input"
                              :value="selectedLogo"
                              :max-file-size="{ size: 5000000, text: '' }"
                              @update:value="onLogoSelected"
                              @reset="logo_path = helpCenter.theme.logo_path"
                            />

                            <span class="form-text text-xs">
                              {{ $t('help_center.recommended_resolution_300px_x_90px_must_be_a_jpg_or_png_file') }}
                            </span>
                          </div>
                          <div class="form-group col-md-3">
                            <div class="form-group">
                              <label>
                                <strong>{{ $t('help_center.primary_color') }}</strong>
                              </label>
                              <div class="input-group input-colorpicker">
                                <span class="input-group-addon"><i></i></span>
                                <input v-model="color" type="text" class="form-control input-colorpicker" />
                              </div>
                            </div>
                            <div class="form-group">
                              <label>
                                <strong>{{ $t('help_center.favicon') }}</strong>
                              </label>
                              <img :src="favicon_path" class="img-responsive m-b" />
                              <t-file-input
                                id="favicon-input"
                                :value="selectedFavicon"
                                :max-file-size="{ size: 5000000, text: '' }"
                                @update:value="onFaviconSelected"
                                @reset="favicon_path = helpCenter.theme.favicon_path"
                              />
                              <span class="form-text text-xs">
                                {{ $t('help_center.recommended_resolution_32px_x_32px_must_be_a_png_file') }}
                              </span>
                            </div>
                          </div>
                          <div class="form-group col-md-6">
                            <div class="form-group">
                              <label>
                                <strong>{{ $t('help_center.custom_css') }}</strong>
                              </label>
                              <textarea v-model="custom_css" class="form-control" rows="4"></textarea>
                              <span class="form-text text-xs">
                                {{
                                  $t(
                                    'help_center.you_are_able_to_append_extra_css_code_into_the_head_tag_in_order_to_overwrite_the_template_defaults'
                                  )
                                }}
                              </span>
                            </div>
                            <div class="form-group">
                              <div v-if="requiresPlanUpgrade" class="flex justify-between">
                                <div class="mr-4 flex flex-col">
                                  <p class="text-[15px] font-bold">{{ $t('entitlement.trengo_branding') }}</p>
                                  <p
                                    class="text-[12px] font-medium leading-5 text-[#888888]"
                                    v-html="trengoBrandingText"
                                  ></p>
                                </div>
                                <div class="flex items-center justify-end">
                                  <t-badge variant="upgrade" :text="minimumRequiredPlan" class="mr-3 w-fit">
                                    <template #icon><arrow-top-circle-linear size="1rem" /></template>
                                  </t-badge>
                                  <label class="ui-switch m-t-xs">
                                    <input v-model="branding_enabled" type="checkbox" disabled />
                                    <i></i>
                                    <span class="label-style"></span>
                                  </label>
                                </div>
                              </div>
                              <div v-if="isEntitled" class="flex justify-between">
                                <div class="mr-4 flex flex-col">
                                  <p class="text-[15px] font-bold">{{ $t('entitlement.trengo_branding') }}</p>
                                  <p
                                    class="text-[12px] font-medium leading-5 text-[#888888]"
                                    v-html="$t('help_center.include_powered_by_trengo')"
                                  ></p>
                                </div>
                                <div class="flex items-center justify-end">
                                  <label class="ui-switch m-t-xs">
                                    <input v-model="branding_enabled" type="checkbox" />
                                    <i></i>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row b-t p-t">
                        <div class="col-md-12">
                          <button
                            type="submit"
                            class="btn btn-success text-white"
                            :disabled="saving"
                            v-text="saving ? $t('help_center.saving') : $t('help_center.save')"
                          ></button>
                          <a
                            v-show="helpCenter.published"
                            class="btn white m-l-1"
                            target="_blank"
                            :href="helpCenter.url"
                          >
                            <i class="fa fa-external-link"></i>
                            {{ $t('help_center.view_in_browser') }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-md-12">
                <div class="box">
                  <div class="box-header b-b">
                    <h2>{{ $t('help_center.preview') }}</h2>
                  </div>
                  <div class="box-body p-y-lg">
                    <div style="max-width: 900px; margin: 0 auto">
                      <Preview
                        :color="color"
                        :name="helpCenter.name"
                        :logo="logo_path"
                        :title="$t('help_center.your_help_center_title_here')"
                      ></Preview>
                      <div class="m-t-1 alert alert-grey">
                        {{
                          $t('help_center.this_preview_only_contains_dummy_data_not_your_real_categories_and_articles')
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.ui-switch .label-style {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: 30px;
  background: transparent;
}

.ui-switch input[type='checkbox']:disabled ~ .label-style {
  background: white;
  opacity: 0.6;
}
</style>
