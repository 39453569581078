<template>
  <div>
    <div>
      <div class="mb-2 mt-2 font-bold">URL</div>
      <input
        v-model="element.meta.url"
        type="url"
        class="form-control custom-placeholder-styling"
        placeholder="https://example.com/webhook"
      />
    </div>
    <div class="mt-4">
      <div class="mb-2 mt-2 font-bold">{{ $t('flowbot.step_dynamic_method') }}</div>
      <select v-model="element.meta.method" class="form-control" disabled>
        <option value="POST">{{ $t('flowbot.step_dynamic_method_post') }}</option>
      </select>
    </div>
    <div class="mt-4">
      <div class="mb-2 mt-2 font-bold">{{ $t('flowbot.step_dynamic_headers') }}</div>
      <div class="flex text-grey-600">
        <div class="text-uppercase flex-1 text-xs">
          <strong>{{ $t('flowbot.step_dynamic_headers_key') }}</strong>
        </div>
        <div class="text-uppercase flex-1 text-xs">
          <strong>{{ $t('flowbot.step_dynamic_headers_value') }}</strong>
        </div>
      </div>
      <div v-for="header in headers" class="mt-1 flex">
        <div class="mr-1 flex-1">
          <dynamic-key-or-value-input v-model="header.key" v-bind="$props"></dynamic-key-or-value-input>
        </div>
        <div class="mr-1 flex-1">
          <dynamic-key-or-value-input v-model="header.value" v-bind="$props"></dynamic-key-or-value-input>
        </div>
        <div v-if="headers.length > 1" class="justify-center text-grey-600">
          <i class="material-icons pointer text-xs" @click="deleteHeader(header)">close</i>
        </div>
      </div>
      <span
        class="
          ml-auto
          mt-1
          inline-block
          cursor-pointer
          rounded-lg
          border border-green
          px-2
          py-1
          text-center text-xs text-green
        "
        @click="addHeader"
      >
        + add
      </span>
    </div>
    <div class="mt-4">
      <div class="mb-2 mt-2 font-bold">{{ $t('flowbot.step_dynamic_body') }}</div>
      <div class="flex text-grey-600">
        <div class="text-uppercase flex-1 text-xs">
          <strong>{{ $t('flowbot.step_dynamic_body_key') }}</strong>
        </div>
        <div class="text-uppercase flex-1 text-xs">
          <strong>{{ $t('flowbot.step_dynamic_body_value') }}</strong>
        </div>
      </div>

      <div class="mt-1 flex">
        <div class="mr-1 flex-1"><div class="form-control cursor-not-allowed border">ticket_id</div></div>
        <div class="mr-1 flex-1"><div class="form-control cursor-not-allowed border">{ Ticket ticket_id }</div></div>
      </div>
      <div class="mt-1 flex">
        <div class="mr-1 flex-1"><div class="form-control cursor-not-allowed border">contact_id</div></div>
        <div class="mr-1 flex-1"><div class="form-control cursor-not-allowed border">{ Ticket contact_id }</div></div>
      </div>
      <div class="mt-1 flex">
        <div class="mr-1 flex-1"><div class="form-control cursor-not-allowed border">channel_id</div></div>
        <div class="mr-1 flex-1"><div class="form-control cursor-not-allowed border">{ Ticket channel_id }</div></div>
      </div>

      <div v-for="bod in body" class="mt-1 flex">
        <div class="mr-1 flex-1">
          <dynamic-key-or-value-input v-model="bod.key" v-bind="$props"></dynamic-key-or-value-input>
        </div>
        <div class="mr-1 flex-1">
          <dynamic-key-or-value-input v-model="bod.value" v-bind="$props"></dynamic-key-or-value-input>
        </div>
        <div v-if="body.length > 1" class="justify-center text-grey-600">
          <i class="material-icons pointer text-xs" @click="deleteBody(body)">close</i>
        </div>
      </div>
      <span
        class="
          ml-auto
          mt-1
          inline-block
          cursor-pointer
          rounded-lg
          border border-green
          px-2
          py-1
          text-center text-xs text-green
        "
        @click="addBody"
      >
        {{ $t('flowbot.step_dynamic_plus_add') }}
      </span>
    </div>
  </div>
</template>

<script>
import DynamicKeyOrValueInput from './DynamicKeyOrValueInput';

export default {
  name: 'Dynamic',
  props: {
    element: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    customFields: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      headers: [
        {
          key: null,
          value: null,
        },
      ],
      body: [
        {
          key: null,
          value: null,
        },
      ],
    };
  },

  watch: {
    // element: {
    //     handler(val) {
    //         console.log('changed');
    //         this.element.meta.headers = this.element.meta.headers.filter(header => {
    //             console.log(header);
    //             return true;
    //         });
    //     },
    //     deep: true,
    // }
    headers: {
      handler(val) {
        this.element.meta.headers = this.headers.filter((header) => {
          return header.key && header.value;
        });
      },
      deep: true,
    },

    body: {
      handler(val) {
        this.element.meta.body = this.body.filter((body) => {
          return body.key && body.value;
        });
      },
      deep: true,
    },
  },

  mounted() {
    if (this.element.meta.headers) {
      this.headers = this.element.meta.headers;
    }
    if (this.element.meta.body) {
      this.body = this.element.meta.body;
    }
  },

  components: {
    DynamicKeyOrValueInput,
  },

  methods: {
    addHeader() {
      // this.element.meta.headers.push({ key: null, value: null});
      this.headers.push({ key: null, value: null });
    },

    deleteHeader(header) {
      // this.element.meta.headers.splice(this.element.meta.headers.indexOf(header), 1);
      this.headers.splice(this.headers.indexOf(header), 1);
    },

    addBody() {
      // this.element.meta.body.push({ key: null, value: null});
      this.body.push({ key: null, value: null });
    },

    deleteBody(body) {
      // this.element.meta.body.splice(this.element.meta.body.indexOf(body), 1);
      this.body.splice(this.body.indexOf(body), 1);
    },
  },
};
</script>
