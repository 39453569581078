<template>
  <div class="flex flex-col justify-between bg-grey-100">
    <div class="px-12 py-8">
      <div class="flex flex-col">
        <span class="mb-2 pr-4 text-base font-bold">
          {{ $t('email_settings.add_the_following_dns_records_to_verify_your_email') }}
        </span>
        <span>
          {{ $t('email_settings.visit_the_admin_console_of_your_domain_registrar') }}
        </span>
      </div>
      <div class="py-8">
        <warning-careful
          :id="id"
          :title="$t('email_settings.careful')"
          :body="$t('email_settings.careful_warning_subtitle')"
        />
        <warning-careful
          :id="id"
          class="mt-4"
          :title="$t('email_settings.attention')"
          :body="$t('email_settings.forwarding_channels_in_trengo')"
          :bg-color="'bg-sun-600'"
        />
      </div>
      <dns-accordion :dns="dns1" />
      <div class="mt-3" />
      <dns-accordion :dns="dns2" />
    </div>
  </div>
</template>

<script>
import DnsAccordion from '../DnsAccordion';
import { Careful } from '../Warning';

export default {
  name: 'DnsDetails',
  components: { WarningCareful: Careful, DnsAccordion },
  props: {
    id: {
      type: Number,
      default: 0,
      required: true,
    },
    dkim: {
      type: Object,
      required: true,
    },
    spf: {
      type: Object,
      required: true,
    },
    domain: {
      type: Object,
      required: true,
    },
  },
  data() {
    const { domain_verify_txt_key } = this.domain;

    return {
      showEmailInstruction: false,
      dns1: {
        type_value: 'TXT',
        title: this.$t('email_settings.dns_record_1'),
        description: this.$t('email_settings.spf'),
        dns_value: 'v=spf1 include:mailgun.org ~all',
        error: this.spf.error,
        name_value: this.spf.name,
        is_valid: this.spf.is_valid,
      },
      dns2: {
        type_value: 'TXT',
        title: this.$t('email_settings.dns_record_2'),
        description: this.$t('email_settings.dkim'),
        dns_value: this.dkim.value,
        error: this.dkim.error,
        name_value: this.dkim.name,
        is_valid: this.dkim.is_valid,
      },
    };
  },
  // watch: {
  //   domain: {
  //     handler(value) {
  //       const data = {
  //         dns_value: 'mandrill_verify.' + value.domain_verify_txt_key,
  //         name_value: value.value,
  //         is_valid: value.is_valid,
  //       };
  //       this.dns1 = Object.assign({}, this.dns1, data);
  //     },
  //     deep: true,
  //   },
  //   spf: {
  //     handler(value) {
  //       const data = {
  //         error: value.error,
  //         name_value: value.name,
  //         is_valid: value.is_valid,
  //       };
  //       this.dns2 = Object.assign({}, this.dns2, data);
  //     },
  //     deep: true,
  //   },
  //   dkim: {
  //     handler(value) {
  //       const data = {
  //         dns_value: value.value,
  //         error: value.error,
  //         name_value: value.name,
  //         is_valid: value.is_valid,
  //       };
  //       this.dns3 = Object.assign({}, this.dns3, data);
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    handleEmailInstruction(value) {
      this.showEmailInstruction = value;
    },
  },
};
</script>
