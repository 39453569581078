<template>
  <div class="popup absolute m-auto">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.popup {
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999999999;
  max-width: 650px;
}
</style>
