<template>
  <div class="flex w-full flex-row justify-between">
    <div class="grid w-full grid-cols-4 gap-0">
      <div class="col-span-4 flex flex-row items-center sm:justify-between">
        <span class="mr-2">
          <avatar width="32" :color="user.color" :abbr="user.abbr" :image="user.profile_image"></avatar>
        </span>
        <span class="t-text-desktop-paragraph-sm m-0 mr-2 w-5/6 truncate text-ellipsis p-0 text-grey-800 sm:w-full">
          {{ user.name }}
        </span>
        <div v-if="showInviteBadgeInFirstCell" class="ml-4 mr-4 w-full">
          <t-tag class="w-max" size="sm">{{ $t('team.invite_pending_title') }}</t-tag>
        </div>
      </div>
      <div class="col-end-6 flex flex-row items-center justify-between">
        <div v-if="showInputStepper" class="w-max">
          <t-input-stepper
            v-model="computedStepperValue"
            :disabled="userPending"
            @input-changed="handleChangeInputValue"
            @increment="handleChangeInputValue"
            @decrement="handleChangeInputValue"
          />
        </div>
        <div v-if="showInviteBadgeInLastCell" class="w-96px">
          <t-tag class="w-full" size="sm">{{ $t('team.invite_pending_title') }}</t-tag>
        </div>
        <div v-if="showMore" class="ml-1">
          <popover-menu v-model="optionsDropdownIsOpen" @open="handleOpenDropDown" @close="handleCloseDropDown">
            <div
              data-test="remove-user"
              class="flex cursor-pointer flex-row items-center justify-start rounded-lg px-2 py-1 hover:bg-grey-200"
              @click="deleteUser()"
            >
              <trash-linear size="1.25rem" class="mr-1 text-error-500" />
              <div data-test="team-members-remove-user" class="t-text-desktop-paragraph-sm text-error-500">
                {{ $t('team.remove_from_team_button') }}
              </div>
            </div>
          </popover-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TrashLinear } from '@trengo/trengo-icons';
import { cloneDeep } from 'lodash';

import Avatar from '@/components/Avatar';
import breakpoints from '@/util/breakpoints';

import PopoverMenu from '../PopoverMenu';

export default {
  name: 'UserRow',
  emits: ['input-value-changed', 'remove', 'change-limit'],
  components: { PopoverMenu, TrashLinear, Avatar },
  data() {
    return {
      limit_value: 0,
      optionsDropdownIsOpen: false,
      breakpoints,
    };
  },
  props: {
    userPending: {
      type: Boolean,
      default: false,
    },
    hasLimit: {
      type: Boolean,
      default: false,
    },
    showMore: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: [Number, String],
      default: 0,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.limit_value = this.limit;
  },
  watch: {
    limit_value(value) {
      this.$emit('change-limit', value);
    },
    limit(value) {
      this.computedStepperValue = value;
    },
  },
  methods: {
    handleChangeInputValue() {
      const clonedUser = cloneDeep(this.user);
      clonedUser.load_balancing_ticket_limit = this.computedStepperValue;
      this.$emit('input-value-changed', clonedUser);
    },
    handleOpenDropDown() {
      this.optionsDropdownIsOpen = true;
    },
    handleCloseDropDown() {
      this.optionsDropdownIsOpen = false;
    },
    deleteUser() {
      this.$emit('remove', this.user);
    },
  },
  computed: {
    isMobile() {
      return !this.breakpoints.comparisons.gt_md;
    },
    showInputStepper() {
      const userIsPendingAndInMobile = this.showInviteBadgeInLastCell;
      return this.hasLimit && !userIsPendingAndInMobile;
    },
    showInviteBadgeInLastCell() {
      return this.userPending && this.isMobile;
    },
    showInviteBadgeInFirstCell() {
      return this.userPending && !this.isMobile;
    },
    computedStepperValue: {
      get() {
        return this.limit_value;
      },
      set(val) {
        this.limit_value = val;
      },
    },
  },
};
</script>
