import { request } from '@/util/request';

import { ENDPOINT } from '../constants';

import type {
  ChargebeeSubscription,
  ChargebeeSubscriptionCheckout,
  ChargebeeSubscriptionCheckoutRequest,
  ChargebeeSubscriptionCheckoutSummary,
  PlanPriceId,
} from '@/types';
import type { AxiosPromise } from 'axios';

type HostedPage = Record<string, unknown>;
type PortalSession = Record<string, unknown>;

type NextPaymentEstimate = {
  chargebee_subscription_id: string;
  amount_in_cents: number;
  currency: string;
  next_billing_date: EpochTimeStamp;
};

export function fetchCustomerSubscription(): AxiosPromise<ChargebeeSubscription> {
  return request(ENDPOINT.CHARGEBEE_SUBSCRIPTION, 'GET');
}

export function fetchCustomerSubscriptionCheckout(): AxiosPromise<ChargebeeSubscriptionCheckout> {
  return request(ENDPOINT.CHARGEBEE_SUBSCRIPTION_CHECKOUT, 'GET');
}

export function postSubscriptionCheckout(
  checkoutRequest: ChargebeeSubscriptionCheckoutRequest
): AxiosPromise<ChargebeeSubscriptionCheckoutSummary> {
  return request(ENDPOINT.CHARGEBEE_SUBSCRIPTION_CHECKOUT_UPDATE_PREVIEW, 'POST', checkoutRequest);
}

export function postSubscriptionCheckoutUpdate(
  checkoutUpdateRequest: ChargebeeSubscriptionCheckoutRequest
): AxiosPromise<{ subscription: { next_billing_at: EpochTimeStamp } }> {
  return request(ENDPOINT.CHARGEBEE_SUBSCRIPTION_CHECKOUT_UPDATE, 'POST', checkoutUpdateRequest);
}

export function openChargebeeCheckout(priceId: PlanPriceId): AxiosPromise<HostedPage> {
  return request(ENDPOINT.CHARGEBEE_GENERATE_CHECKOUT, 'POST', {
    item_price_id: priceId,
  });
}

export function openChargebeePortal(): AxiosPromise<PortalSession> {
  return request(ENDPOINT.CHARGEBEE_PORTAL_SESSION, 'GET');
}

export function fetchNextPaymentEstimate(): AxiosPromise<NextPaymentEstimate> {
  return request(ENDPOINT.CHARGEBEE_SUBSCRIPTION_NEXT_PAYMENT_ESTIMATE, 'GET');
}
