<script lang="ts">
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import { CHANNEL_TYPE, PERMISSION } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useUserStore } from '@/store/pinia';

import composer from './ReplyForm/Composer.vue';

export default defineComponent({
  name: 'ForwardTicketModal',
  data: function () {
    return {
      ticket: {},
      message_id: null,
      subject: '',
      open: false,
      channelError: false,
      draftKey: '',
    };
  },

  computed: {
    ...mapStores(useUserStore),
    forwardingTicketContext() {
      if (this.ticket?.channel?.type !== CHANNEL_TYPE.EMAIL) {
        return null;
      }
      return {
        ...this.ticket,
        contact: null,
      };
    },
  },

  components: {
    composer,
  },

  mounted: function () {
    eventBus.$off('modals.forward-ticket.open');

    eventBus.$on('modals.forward-ticket.open', (data) => {
      if (!this.hasEmailChannels()) {
        swal({
          text: this.$t('tickets.forward_no_channels_warning'),
          animation: false,
          confirmButtonText: this.$t('tickets.forward_no_channels_warning_confirm'),
          cancelButtonText: this.$t('tickets.forward_no_channels_warning_abort'),
          showCancelButton: true,
          footer:
            '<a class="text-muted" href="http://help.trengo.com/nl/articles/1903-conversaties-doorsturen" target="_blank">' +
            this.$t('tickets.forward_no_channels_explanation') +
            '</a>',
        }).then((result) => {
          if (result.value && this.userStore.hasPermission(PERMISSION.SETTINGS__CHANNELS__MANAGE)) {
            vue_redirect('/admin/channels2/email');
          }
        });
        return;
      }

      this.ticket = data.ticket;
      this.message_id = data.message_id;
      this.draftKey = 'forward.' + data.ticket.id;

      if (data.message_id) {
        this.draftKey += '' + data.message_id;
      }

      //+''+(ticket.message_id);
      this.openModal();
    });

    $(this.$refs.modal).on('hidden.bs.modal', () => {
      this.open = false;
    });
  },

  methods: {
    setDefaultSubject() {
      if (this.ticket.channel.type == 'EMAIL') {
        this.subject = this.ticket.subject;
      } else {
        this.subject = this.$t('tickets.forward_subject_placeholder');
      }
    },

    fetchConversation: function (ticket) {},

    onSent(ticket_id) {
      vue_redirect('/tickets/' + ticket_id);
      //                this.flashSuccess(this.$t('tickets.composer_forwarded_success'));
      this.closeModal();
    },

    hasEmailChannels() {
      return (
        this.$root.channels.filter((c) => {
          return c.type == 'EMAIL';
        }).length > 0
      );
    },

    openModal() {
      this.open = true;
      $(this.$refs.modal).modal({});
    },

    closeModal() {
      this.open = false;
      $(this.$refs.modal).modal('hide');
    },
  },
});
</script>

<template>
  <div id="ForwardTicketModal" ref="modal" class="modal" role="dialog">
    <div
      class="modal-centered modal-dialog"
      style="height: 90vh; overflow-y: auto; transform: translate(-50%, -50%); margin: 0"
    >
      <composer
        v-if="open && !channelError"
        :default-close="true"
        :autofocus="true"
        :draft-key="draftKey"
        :ticket="forwardingTicketContext"
        :forward-ticket-id="ticket.id"
        :forward-message-id="message_id"
        :new-ticket="true"
        :forwarding="true"
        :path="'/tickets/forward/' + this.ticket.id"
        @sent="onSent"
        @popout="closeModal"
      />
    </div>
  </div>
</template>

<style scoped>
@media (max-width: 543px) {
  .composer-container {
    border-radius: 0 !important;
  }
  .modal-header {
    border-radius: 0 !important;
  }
  .modal-centered {
    border-radius: 0 !important;
  }
}
</style>
