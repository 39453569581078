import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mx-auto ml-16 flex flex-col" }
const _hoisted_2 = { class: "mb-4 mt-8 flex flex-row items-center" }
const _hoisted_3 = {
  key: 0,
  class: "t-text-h4 m-0 p-0 text-grey-800"
}
const _hoisted_4 = {
  key: 1,
  class: "t-text-h4 m-0 p-0 text-grey-800"
}
const _hoisted_5 = {
  for: "event",
  class: "font-bold text-grey-800"
}
const _hoisted_6 = { class: "mb-6" }
const _hoisted_7 = { class: "flex flex-row items-center" }
const _hoisted_8 = { class: "mr-2 flex-shrink-0" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "flex flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_left_linear = _resolveComponent("arrow-left-linear")!
  const _component_t_input_text = _resolveComponent("t-input-text")!
  const _component_t_error_item = _resolveComponent("t-error-item")!
  const _component_t_dropdown = _resolveComponent("t-dropdown")!
  const _component_info_linear = _resolveComponent("info-linear")!
  const _component_arrow_right_linear = _resolveComponent("arrow-right-linear")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!
  const _component_t_card = _resolveComponent("t-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "mr-6 rounded-full p-1.5 transition-colors hover:bg-grey-300",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$router.push({ name: 'webhooks' })), ["prevent"]))
      }, [
        _createVNode(_component_arrow_left_linear, { size: "1.3rem" })
      ]),
      (_ctx.action === 'edit')
        ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.webhook.title), 1 /* TEXT */))
        : (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_ctx.$tc('integration_hub.create_new_webhook')), 1 /* TEXT */))
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent","stop"]))
    }, [
      _createVNode(_component_t_card, { class: "flex flex-col bg-white" }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass({ 'mb-6': !_ctx.errors.url })
          }, [
            _createVNode(_component_t_input_text, {
              id: "url",
              modelValue: _ctx.webhook.url,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.webhook.url) = $event)),
              label: _ctx.$t('apps_integration.url'),
              "allow-clear": false,
              size: "sm",
              placeholder: "https://example.com",
              required: true,
              type: "url",
              "has-error": _ctx.errors.url,
              onKeydown: _withKeys(_withModifiers(_ctx.saveWebhook, ["prevent"]), ["enter"]),
              onBlur: _ctx.checkUrl
            }, null, 8 /* PROPS */, ["modelValue", "label", "has-error", "onKeydown", "onBlur"]),
            (_ctx.errors.url)
              ? (_openBlock(), _createBlock(_component_t_error_item, {
                  key: 0,
                  text: _ctx.$tc('integration_hub.error_url_must_be_valid'),
                  "danger-icon": "",
                  class: "mb-6"
                }, null, 8 /* PROPS */, ["text"]))
              : _createCommentVNode("v-if", true)
          ], 2 /* CLASS */),
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('apps_integration.event')), 1 /* TEXT */),
          _createVNode(_component_t_dropdown, {
            id: "event",
            modelValue: _ctx.webhook.type,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.webhook.type) = $event)),
            items: _ctx.dropdownItems,
            "value-index": "value",
            "text-index": "title",
            size: "sm",
            class: _normalizeClass({ 'mb-6': !_ctx.errors.event }),
            error: true,
            onInput: _ctx.checkEvent
          }, null, 8 /* PROPS */, ["modelValue", "items", "class", "onInput"]),
          (_ctx.errors.event)
            ? (_openBlock(), _createBlock(_component_t_error_item, {
                key: 0,
                text: _ctx.$tc('integration_hub.error_event_must_select'),
                "danger-icon": "",
                class: "mb-6"
              }, null, 8 /* PROPS */, ["text"]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.action === 'edit' && _ctx.webhook.signing_secret)
              ? (_openBlock(), _createBlock(_component_t_input_text, {
                  key: 0,
                  id: "signing_secret",
                  modelValue: _ctx.webhook.signing_secret,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.webhook.signing_secret) = $event)),
                  disabled: "",
                  label: _ctx.$t('apps_integration.signing_secret'),
                  size: "sm"
                }, null, 8 /* PROPS */, ["modelValue", "label"]))
              : _createCommentVNode("v-if", true)
          ]),
          _createVNode(_component_t_inline_banner, {
            type: "default",
            class: "mb-6"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_info_linear, { size: "1.5rem" })
                ]),
                _createElementVNode("p", {
                  class: "t-text-sm m-0 mr-2 p-0 text-grey-800",
                  innerHTML: _ctx.$t('apps_integration.check_trengo_documentation_for_webhook')
                }, null, 8 /* PROPS */, _hoisted_9),
                _createVNode(_component_t_button, {
                  "btn-style": "secondary",
                  class: "ml-auto flex w-fit flex-shrink-0 flex-row items-center",
                  onClick: _withModifiers(_ctx.openDocumentation, ["prevent"]),
                  onKeydown: _cache[4] || (_cache[4] = _withKeys(_withModifiers(() => {}, ["prevent","stop"]), ["enter"]))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$tc('integration_hub.check_documentation')) + " ", 1 /* TEXT */),
                    _createVNode(_component_arrow_right_linear, {
                      size: "1.25rem",
                      class: "ml-1"
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
              ])
            ]),
            _: 1 /* STABLE */
          }),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_t_button, {
              disabled: _ctx.isSaving,
              class: _normalizeClass({ loader: _ctx.isSaving }),
              onClick: _withModifiers(_ctx.saveWebhook, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('apps_integration.save_changes')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled", "class", "onClick"]),
            (_ctx.action === 'edit')
              ? (_openBlock(), _createBlock(_component_t_button, {
                  key: 0,
                  class: _normalizeClass([{ loader: _ctx.isSaving }, "ml-3"]),
                  "btn-style": "danger-alt",
                  disabled: _ctx.isSaving,
                  onClick: _withModifiers(_ctx.deleteWebhook, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('apps_integration.delete_webhook')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["class", "disabled", "onClick"]))
              : _createCommentVNode("v-if", true)
          ])
        ]),
        _: 1 /* STABLE */
      })
    ], 32 /* NEED_HYDRATION */)
  ]))
}