import { MiddlewareError } from '@/api/errors/middlewareError';
import { MIDDLEWARE_ERROR } from '@/Configs/Constants/middlewareError';
import { useUserStore } from '@/store/pinia';

import type { Middleware } from './types';
import type { Permission } from '@/types';

export const checkPermission: Middleware = async (to) => {
  if (to.meta?.allowUnauthenticated) {
    return true;
  }
  const userStore = useUserStore();
  await userStore.init();

  const isPermissionRequired = (permission?: Permission): permission is Permission => Boolean(permission);
  if (!isPermissionRequired(to.meta.permissionRequired)) {
    return true;
  }
  if (!userStore.hasPermission(to.meta.permissionRequired)) {
    throw new MiddlewareError(MIDDLEWARE_ERROR.PERMISSION_REQUIRED);
  }
};
