import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-1 flex w-full flex-row items-center rounded-md transition-colors last:mb-0 hover:bg-grey-200 active:bg-grey-300" }
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_switch = _resolveComponent("t-switch")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.item.name,
      class: "t-text-sm m-0 w-full cursor-pointer select-none text-ellipsis px-3 py-2 text-grey-800",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.item.visible = !_ctx.item.visible), ["prevent"]))
    }, _toDisplayString(_ctx.item.label || _ctx.item.name), 9 /* TEXT, PROPS */, _hoisted_2),
    _createVNode(_component_t_switch, {
      id: _ctx.item.name,
      modelValue: _ctx.item.visible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.visible) = $event)),
      class: "ml-auto pr-2",
      size: "md",
      onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
    }, null, 8 /* PROPS */, ["id", "modelValue"])
  ]))
}