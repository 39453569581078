<template>
  <flat-pickr ref="el" v-model="modelProxy" :config="config" @on-change="onChange" @on-close="onClose"></flat-pickr>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  name: 'DateTimePicker2',
  emits: ['close', 'update:modelValue'],
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    FlatPickr,
  },

  data() {
    return {
      modelProxy: this.config.dateFormat === 'U' ? this.modelValue * 1000 : this.modelValue, // input (initial value) should be in milliseconds
    };
  },

  methods: {
    onChange(dates) {
      this.$emit('update:modelValue', dates[0].getTime() / 1000);
    },
    onClose() {
      this.$emit('close');
    },
  },

  mounted() {
    if (!this.config) {
      this.config = {
        enableTime: true,
        defaultHour: now.getHours(),
        defaultMinute: now.getMinutes(),
        defaultDate: now,
        //mode: 'single',
        //allowInput: true,
        //time_24hr: false,
        //altInput: true,
        //altFormat: 'F j, Y H:m',
        //dateFormat: 'Y-m-d H:i:s', // this doesn't change the input format, only output. E.g. U outputs seconds, but input (initial value) should be in milliseconds
        //inline: true,
      };
    }
  },

  computed: {},

  watch: {
    modelValue(v) {
      this.modelProxy = this.config.dateFormat === 'U' ? v * 1000 : v; // U outputs seconds, but input (initial value) should be in milliseconds;
    },
  },
};
</script>

<style scoped></style>
