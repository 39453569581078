import { onBeforeUnmount, onMounted } from 'vue';

export const useKeyUpCallback = (key: string, callback: () => void) => {
  const addEscEvent = (event: KeyboardEvent) => {
    if (event.key === key) {
      callback();
    }
  };

  onMounted(() => {
    document.addEventListener('keydown', addEscEvent);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('keydown', addEscEvent);
  });
};
