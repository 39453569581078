<template>
  <t-modal
    v-model="isOpen"
    :title="$t('auth.two_factor_authentication_grace_period_reminder_modal_title')"
    variant="narrow"
    @close="handleClose"
  >
    <div
      class="
        relative
        mb-6
        overflow-hidden
        rounded-xl
        before:absolute before:h-full before:w-full before:rounded-xl before:border-1 before:border-[rgba(0,0,0,0.1)]
      "
    >
      <img
        :src="`${
          /* @ts-ignore */
          $root.assetsURL
        }images/2fa_enabled.gif`"
        alt="two factor illustration"
      />
    </div>
    <p class="t-text-md">{{ $t('auth.two_factor_authentication_grace_period_reminder_modal_description') }}</p>
    <template #footer>
      <t-button btn-style="secondary" @click="handleClose">
        {{ $t('auth.two_factor_authentication_grace_period_reminder_modal_skip') }}
      </t-button>
      <t-button @click="handleRedirect">
        {{ $t('auth.two_factor_authentication_grace_period_reminder_modal_cta_label') }}
      </t-button>
    </template>
  </t-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { calculate24HoursFromNow, checkIfAfterDeadline } from '@/util/mandatoryTwoFactorAuth';

const DISMISSED_UNTIL_KEY = 'two_factor_authentication_reminder_dismissed_until';

export default defineComponent({
  data() {
    return {
      isOpen: false,
    };
  },
  async mounted() {
    try {
      if (!this.isMFAMandatory || !this.mFAMandatoryFrom || this.userHas2FAEnabled) {
        return;
      }
      const now = Date.now();
      const dismissedUntil = await this.$tStorage.getItem(DISMISSED_UNTIL_KEY);
      const { isAfterSoftDeadline } = checkIfAfterDeadline(this.mFAMandatoryFrom, now);
      const isSnoozed = dismissedUntil && now < dismissedUntil;
      if (!isSnoozed && isAfterSoftDeadline) {
        this.isOpen = true;
      }
    } catch (err) {
      console.error(err);
    }
  },
  computed: {
    ...mapGetters({
      isMFAMandatory: 'initialData/isMFAMandatory',
      mFAMandatoryFrom: 'initialData/mFAMandatoryFrom',
      userHas2FAEnabled: 'initialData/userHas2FAEnabled',
    }),
  },
  methods: {
    dismissReminder() {
      const tomorrow = calculate24HoursFromNow(Date.now());
      this.$tStorage.setItem(DISMISSED_UNTIL_KEY, tomorrow);
    },
    handleClose() {
      this.isOpen = false;
      this.dismissReminder();
    },
    handleRedirect() {
      this.handleClose();
      this.$router.push('/profile/2fa');
    },
  },
});
</script>
