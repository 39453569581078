import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "data-test": "message-box",
  class: "mb-3 rounded-lg p-3 xs2:w-60 sm:w-64"
}
const _hoisted_2 = { class: "t-text-sm mb-0 text-grey-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.message), 1 /* TEXT */)
  ]))
}