<template>
  <div data-test="trying-templates">
    <div>
      <p v-if="isMobile" class="t-text-md mb-6 text-grey-600">{{ $t('whatsapp.templates_success_subheader') }}</p>
      <div class="relative">
        <img :src="backgroundImageSrc" class="mb-4 h-100 w-140 rounded-lg" />
        <img
          :src="phoneImageSrc"
          :class="`sm:h-86 absolute bottom-0 left-0 right-0 m-auto sm:w-86 ${isMobile && 'h-88 w-80'}`"
        />

        <messages
          :messages="messages"
          wrapper-classes="absolute
            left-0
            right-0
            bottom-0
            m-auto
            flex flex-col
            overflow-y-auto overflow-x-hidden
            xs2:px-3 sm:px-5
            xs2:h-72 xs2:w-72 xs2:pt-8 sm:pt-12
            sm:h-80 sm:w-79"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import eventBus from '@/eventBus';
import breakpoints from '@/util/breakpoints';

import backgroundImage from '../../assets/background_template.png';
import phoneImage from '../../assets/phone-scaled.svg';
import { MessageTypes } from '../../constants';
import Messages from '../Messages';

import type { Message } from '../../constants';
import type { PropType } from 'vue';

type TryingTemplatesSuccess = {
  messagesData: Message[];
  messageTypes: typeof MessageTypes;
  templateSent: boolean;
  defaultTemplateName: string;
  backgroundImageSrc: string;
  phoneImageSrc: string;
};

export default defineComponent({
  name: 'TryingTemplates',
  components: { Messages },
  props: {
    ticketId: {
      type: String,
      default: '',
    },
    messages: {
      type: [] as PropType<Message>,
      default: [],
    },
  },
  data(): TryingTemplatesSuccess {
    return {
      messagesData: [],
      messageTypes: MessageTypes,
      templateSent: false,
      defaultTemplateName: '{{ name }}',
      backgroundImageSrc: backgroundImage,
      phoneImageSrc: phoneImage,
    };
  },
  computed: {
    isMobile() {
      return !breakpoints.comparisons.gt_sm;
    },
  },
  beforeMount() {
    this.messagesData = this.messages;
  },
  mounted() {
    eventBus.$emit('confetti');
  },
});
</script>
