<template>
  <div style="height: calc(100% - 80px)" class="flex justify-center overflow-auto">
    <div class="m-auto flex max-w-md flex-col items-center justify-center gap-3 px-5">
      <t-thumbnail size="large" class="mb-3 flex-shrink-0">
        <scan-linear />
      </t-thumbnail>
      <h3 class="t-text-h3 mb-1 text-center">
        {{ $t('auth.two_factor_authentication_setup_step_one_title') }}
      </h3>
      <p class="t-text-md text-center text-grey-800">
        {{ $t('auth.two_factor_authentication_setup_step_one_description') }}
      </p>
      <div class="mb-4 flex justify-center">
        <img :src="qr" class="max-w-[160px] rounded-xl border border-solid p-1 md:h-auto md:p-2" />
      </div>
      <div class="w-full md:w-96">
        <t-input-text
          id="twoFactorAuthenticationSecret"
          ref="secret"
          :model-value="secret"
          :sub-label="$t('auth.two_factor_authentication_setup_step_one_secret_label')"
          readonly
        >
          <template #end>
            <button
              v-tooltip="{
                placement: 'top',
                content: buttonText,
                popperClass: 'tooltip-vuetify',
                show: isTooltipVisible,
                trigger: 'manual',
              }"
              class="flex"
              @click="handleCopyToClipboard"
            >
              <copy-linear />
            </button>
          </template>
        </t-input-text>
      </div>
      <div class="mb-4 mt-4 flex w-full flex-col-reverse items-stretch justify-center gap-3 md:flex-row md:items-start">
        <t-button size="md" btn-style="secondary" @click="handleBack">
          <arrow-left-linear class="inline" />
          {{ $t('general.back') }}
        </t-button>
        <t-button size="md" @click="handleContinue">
          {{ $t('general.continue') }}
        </t-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ArrowLeftLinear, CopyLinear, ScanLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import { copyToClipboard } from '@/util/helpers';

import { SETUP_STEP } from '../constants';

export default defineComponent({
  name: 'TwoFactorAuthSetupStepOne',
  components: { ArrowLeftLinear, CopyLinear, ScanLinear },
  emits: ['goToStep'],
  props: {
    isSaving: Boolean,
    qr: String,
    secret: String,
  },
  data() {
    return {
      buttonText: this.$t('general.copied'),
      isTooltipVisible: false,
    };
  },
  methods: {
    handleContinue() {
      this.$emit('goToStep', SETUP_STEP.TWO);
    },
    handleCopyToClipboard() {
      copyToClipboard(this.secret);
      this.$refs.secret.$refs.input.select();
      this.isTooltipVisible = true;
      setTimeout(() => {
        this.isTooltipVisible = false;
      }, 2000);
    },
    handleBack() {
      this.$emit('goToStep', SETUP_STEP.ZERO);
    },
  },
});
</script>
