<template>
  <div class="flex flex-col justify-between px-12 py-8">
    <div class="align-center flex flex-row items-center">
      <span class="pr-4 text-2xl font-bold leading-10">
        {{ domain.address }}
      </span>
      <span
        class="align-center flex h-8 w-8 cursor-pointer items-center justify-center rounded-xl bg-grey-100"
        @click="handleGoToEdit"
      >
        <img class="w-4" :src="`${$root.assetsURL}img/edit.svg`" alt="edit" />
      </span>
    </div>
    <div class="flex flex-col justify-between sm:flex-row">
      <div class="flex flex-row items-center">
        <img class="mr-1 pr-1" :src="getStatusImage" alt="status" />
        <span :class="{ 'text-sun-600': !isValid, 'text-success-700': isValid }" class="text-base font-semibold">
          {{ isValid ? this.$t('email_settings.verified') : this.$t('email_settings.unverified') }}
        </span>
      </div>
      <div class="mt-4 sm:mt-0">
        <atomic-button size="sm" btn-style="secondary" @click="handleCheckStatus">
          <span class="pr-2"><i class="fal" :class="showLoadingOrWifi" /></span>
          {{ $t('email_settings.check_status') }}
        </atomic-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getSenderDomains } from '@/components/Channels/EmailSettings/Pages/ChannelSettings/api';
import eventBus from '@/eventBus';

export default {
  name: 'InfoCard',
  emits: ['update:modelValue'],
  data() {
    return {
      isLoading: false,
    };
  },
  props: {
    isValid: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      required: true,
    },
    domain: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showLoadingOrWifi() {
      return this.isLoading ? 'fa-spinner animate-spin ml-1' : 'fa-wifi';
    },
    getStatusImage() {
      return this.isValid
        ? this.$root.assetsURL + 'img/info_duotone_success.svg'
        : this.$root.assetsURL + 'img/alert.svg';
    },
  },
  methods: {
    handleCheckStatus() {
      this.isLoading = true;
      getSenderDomains(this.id, true).then((response) => {
        //Todo : handle response
        const { data } = response;
        eventBus.$emit('verifyDNS', data);
        this.isLoading = false;
      });
    },
    handleGoToEdit() {
      this.$emit('update:modelValue', true);
    },
  },
};
</script>
