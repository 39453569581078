import moment from 'moment';

import type { CustomTooltipItem } from '../types/CustomTooltipItem';
import type { TranslationFunction } from '@/types';

type WeekDayIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export function getDayKey(dayOfWeek: WeekDayIndex) {
  const pluralDayTranslationKey = {
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
  };

  return pluralDayTranslationKey[dayOfWeek];
}

export function getDayOfWeek(t: (key: string) => string, timestamp: number) {
  const date = moment(timestamp).locale('en').format('dddd');
  return t(`general.${date.toLocaleLowerCase()}`);
}

export function getDayFromTooltipContext(tooltipContext: CustomTooltipItem, t: TranslationFunction) {
  const dayNumber = tooltipContext.dataIndex === 6 ? 0 : tooltipContext.dataIndex + 1;
  const dayKey = getDayKey(dayNumber as WeekDayIndex);
  return t(`general.${dayKey}`);
}
