import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0d224503"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex h-full w-full flex-col items-center justify-center" }
const _hoisted_2 = { class: "t-text-md-emphasize mb-2 mt-4 text-grey-800" }
const _hoisted_3 = { class: "t-text-sm mb-0 text-grey-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_thumbnail = _resolveComponent("t-thumbnail")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`skeleton-background absolute right-0 top-0 z-10 ${_ctx.classes}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_t_thumbnail, { class: "bg-white" }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconName)))
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1 /* TEXT */),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.subTitle), 1 /* TEXT */)
    ])
  ], 2 /* CLASS */))
}