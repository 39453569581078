<template>
  <div>
    <setting-item :custom-class="{ container: 'mb-4 sm:mb-1 mt-0 pt-5 sm:pt-4 pb-4 cursor-pointer' }">
      <template #title>{{ $t('email_settings.synchronize_conversation_status') }}</template>
      <template #formText>
        <div class="mb-2">
          {{
            $t(
              'email_settings.if_this_option_is_enabled_the_conversation_status_will_be_synchronized_as_label_in_gmail'
            )
          }}
          <br />
          {{ $t('email_settings.synchronize_conversation_status_subtitle_two') }}
        </div>
      </template>
      <template #toggle>
        <label class="ui-switch success m-t-xs">
          <input v-model="computedRecord.emailChannel.meta.syncStatus" type="checkbox" />
          <i></i>
        </label>
      </template>
    </setting-item>

    <div v-if="hasAvailableFolders">
      <setting-item :show-border="false" :custom-class="{ container: 'mb-4 mt-0 pt-0 sm:pt-5 pb-4 cursor-pointer' }">
        <template #title>{{ $t('email_settings.only_synchronize_selected_labels') }}</template>
        <template #formText>
          {{ $t('email_settings.only_emails_with_the_selected_labels_will_be_synchronized_in_trengo') }}
        </template>
        <template #toggle>
          <label class="ui-switch success m-t-xs">
            <input v-model="computedRecord.emailChannel.meta.syncFolders" type="checkbox" />
            <i class="success"></i>
          </label>
        </template>
        <template #moreSettings>
          <div v-show="computedRecord.emailChannel.meta.syncFolders" class="scroll-box-container py-1">
            <div class="scroll-box mt-4">
              <div
                v-for="(folderName, folderId) in externalProviderSettings.availableFolders"
                :key="folderId"
                class="my-1 flex"
              >
                <label class="ui-switch success m-r" style="margin-top: 3px">
                  <input
                    :id="folderId"
                    v-model="computedRecord.emailChannel.meta.selectedFolders"
                    type="checkbox"
                    :value="folderId"
                  />
                  <i></i>
                </label>
                <label :for="folderId" class="text-black-dk">{{ folderName }}</label>
              </div>
            </div>
          </div>
        </template>
      </setting-item>
    </div>

    <setting-item :show-border="true" :custom-class="{ container: 'mb-4 mt-0 pt-0 sm:pt-5 pb-4' }">
      <template #title>{{ $t('email_settings.bulk_import') }}</template>
      <template #formText>
        <div class="sm:mb-2">
          {{ $t('email_settings.bulk_import_subtitle') }}
        </div>
      </template>
      <template #toggle>
        <atomic-button
          :disabled="isImportDisabled"
          btn-style="secondary items-center flex flex-row"
          size="sm"
          aria-label="Import"
          @click="importMessages"
        >
          <div :class="{ 'opacity-20': isImportDisabled }" class="mr-2 h-16px w-16px">
            <img :src="`${$root.assetsURL}img/import.svg`" alt="import" />
          </div>
          <span>{{ $t('email_settings.import') }}</span>
        </atomic-button>
      </template>
    </setting-item>

    <setting-item :show-border="false" :custom-class="{ container: 'mb-4 mt-0 pt-0 sm:pt-6 pb-3 mb-3' }">
      <template #title>
        <div class="w-full sm:flex">
          <div class="mr-6 mt-2.5 sm:mb-0">{{ $t('email_settings.reconnect') }}</div>
        </div>
      </template>
      <template #toggle>
        <t-button btn-style="secondary" size="md" class="flex items-center" @click="reconnect">
          <rotate-ccw-linear size="1.1rem" class="mr-1.5" />
          <span class="text-sm font-bold">{{ strippedReconnectString }}</span>
        </t-button>
      </template>
      <template #moreSettings>
        <div
          v-if="externalProviderSettings.loadedAccountInfo && externalProviderSettings.accountError"
          class="alert alert-danger mt-4"
        >
          <p v-if="externalProviderSettings.accountInfo">
            {{ externalProviderSettings.accountInfo }}
          </p>
          <strong>{{ $t('email_settings.please_reconnect') }}</strong>
        </div>
      </template>
    </setting-item>
  </div>
</template>

<script>
import { RotateCcwLinear } from '@trengo/trengo-icons';

import { GMAIL } from '@/Configs/Constants/ChannelLandingPageTypes';

import SettingItem from '../../Components/SettingItem';

export default {
  name: 'GmailSettings',
  emits: ['input', 'switch'],
  components: {
    RotateCcwLinear,
    SettingItem,
  },
  props: {
    action: {
      type: String,
      default: '',
    },
    record: {
      type: Object,
      default: () => ({}),
    },
    externalProviderSettings: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isImportDisabled() {
      return this.externalProviderSettings.loadedAccountInfo && this.externalProviderSettings.accountError;
    },
    syncStatusComputed: {
      get() {
        return this.computedRecord.emailChannel.meta.syncStatus;
      },
      set(val) {
        this.computedRecord.emailChannel.meta.syncStatus = val;
      },
    },
    computedRecord: {
      get() {
        return this.record;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    hasAvailableFolders() {
      return Object.keys(this.externalProviderSettings.availableFolders).length > 0;
    },
    strippedReconnectString() {
      return this.$t('email_settings.reconnect').slice(0, -1);
    },
  },
  methods: {
    reconnect() {
      this.$emit('reconnectChannel', GMAIL);
    },
    importMessages() {
      axios.post(`/api/v2/channels/${this.computedRecord.id}/importMessages`).then(() => {
        this.flashSuccess(this.$t('email_settings.bulk_import_success'));
      });
    },
  },
};
</script>

<style lang="scss" scoped src="./GmailSettings.scss" />
