<template>
  <div class="wab-recipients-contacts">
    <contacts-search v-if="!noContacts" v-model="searchKey" class="search-field" />

    <div class="status">
      <loader-linear v-if="loading" size="1.5rem" class="spin" />
      <wab-contact-status v-else-if="noContacts">{{ $t('broadcast.no_contacts') }}</wab-contact-status>
      <wab-contact-status v-else-if="noResults">
        {{ $t('broadcast.no_contacts_found_for', { searchKey: searchKey }) }}
      </wab-contact-status>
    </div>

    <div class="items">
      <wab-recipients-contact-item
        v-for="item in computedList"
        :key="item.id"
        :phone="item.phone"
        :name="item.name"
        :model-value="isSelected(item)"
        :is-selected-in-group="isInGroup(item)"
        :is-selected-in-manual="isInManual(item)"
        :disable="!!(isInGroup(item) || isInManual(item))"
        @update:modelValue="onInput($event, item)"
      />
    </div>
  </div>
</template>

<script>
import { LoaderLinear } from '@trengo/trengo-icons';

import { getContacts } from '@/components/WabBroadcasting/api';
import ContactsSearch from '@/components/WabBroadcasting/components/ContactsSearch';
import WabContactStatus from '@/components/WabBroadcasting/components/WabContactStatus';
import WabRecipientsContactItem from '@/components/WabBroadcasting/components/WabRecipientsContactItem';

export default {
  name: 'WabRecipientsContacts',
  emits: ['select'],
  components: {
    ContactsSearch,
    WabRecipientsContactItem,
    WabContactStatus,
    LoaderLinear,
  },
  props: {
    selected: {
      type: Object,
      default: () => ({}),
    },
    manual: {
      type: Array,
      default: () => [],
    },
    selectedGroups: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      searchKey: '',
      list: [],
      loading: false,
      page: 1,
    };
  },
  watch: {
    searchKey(term) {
      this.page = 1;
      this.list = [];
      this.requestContacts({ term });
    },
  },
  computed: {
    computedList() {
      return this.list.filter((contact) => !!contact.phone);
    },
    noContacts() {
      return !this.searchKey && !this.computedList.length && !this.loading;
    },
    noResults() {
      return this.searchKey && !this.computedList.length;
    },
  },
  mounted() {
    this.requestContacts({ page: 1 });
  },
  methods: {
    requestContacts(params) {
      this.loading = true;

      return getContacts(params)
        .then((contacts) => {
          const contactsList = Object.values(contacts?.data);
          if (contactsList?.length) {
            this.list = contactsList;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onInput(isSelected, item) {
      this.$emit('select', {
        isSelected,
        id: item.id,
        phone: item.phone,
        name: item.name,
      });
    },
    isInGroup(item) {
      if (this.isSelected(item)) {
        return Object.keys(this.selectedGroups).includes(this.selected[item.id]?.groupId?.toString());
      }
    },
    isInManual(item) {
      return Object.values(this.manual)
        .map((o) => o.value?.replace(/\D/g, ''))
        .includes(item.phone?.replace(/\D/g, ''));
    },
    isSelected(item) {
      return !!this.selected[item.id];
    },
  },
};
</script>

<style scoped lang="scss" src="./WabRecipientsContacts.scss" />
