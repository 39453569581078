<template>
  <div>
    <div id="KeyShortcutsModal" ref="modal" class="modal" role="dialog" style="z-index: 99999">
      <div class="modal-centered modal-centered-lg plan-upgrade-modal" role="document" style="height: auto">
        <div class="p-8">
          <h4 class="mb-4">Keyboard shortcuts</h4>
          <div>
            Press
            <kbd>?</kbd>
            to show and hide this cheat sheet
          </div>

          <div class="my-4">
            <div class="my-2">
              <div>Search and go to anything</div>
              <div>
                <kbd>{{ metaKey }}</kbd>
                +
                <kbd>E</kbd>
              </div>
            </div>

            <h5 class="my-4">In the inbox</h5>
            <div class="my-2">
              <div>Search conversations</div>
              <div><kbd>/</kbd></div>
            </div>
            <div class="my-2">
              <div>Next conversation</div>
              <div><kbd>j</kbd></div>
            </div>
            <div class="my-2">
              <div>Previous conversation</div>
              <div><kbd>k</kbd></div>
            </div>

            <h6 class="my-4">In a conversation</h6>
            <div class="my-2">
              <div>Set reminder</div>
              <div><kbd>r</kbd></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import eventBus from '@/eventBus';

export default {
  data() {
    return {
      keysDown: [],
      usedShortCutTimer: null,
    };
  },

  computed: {
    metaKey() {
      if (/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)) {
        return '⌘';
      }
      return 'Ctrl';
    },
  },

  mounted() {
    this.mountKeyboard();
  },

  beforeUnmount() {
    this.unmountKeyboard();
  },

  methods: {
    mountKeyboard() {
      this.unmountKeyboard();
      document.addEventListener('keydown', this.handleKeyDownEvent);
      document.addEventListener('keyup', this.handleKeyUpEvent);
      eventBus.$on('froalaKeyDown', this.handleKeyDownEvent);
      eventBus.$on('froalaKeyUp', this.handleKeyUpEvent);
    },

    unmountKeyboard() {
      document.removeEventListener('keydown', this.handleKeyDownEvent);
      document.removeEventListener('keyup', this.handleKeyUpEvent);
      eventBus.$off('froalaKeyDown');
      eventBus.$off('froalaKeyUp');
    },

    isInsideInteractiveElement() {
      return (
        document &&
        document.activeElement &&
        (['input', 'select', 'button', 'textarea'].indexOf(document.activeElement.tagName.toLowerCase()) > -1 ||
          document.activeElement.isContentEditable)
      );
    },

    handleKeyDownEvent(event) {
      if (this.keysDown.indexOf(event.key) < 0) {
        this.keysDown.push(event.key);
      }
      setTimeout(() => {
        this.handleKeyUpEvent(event);
      }, 2000);

      if (!this.modifiersPressed() && this.isInsideInteractiveElement()) {
        if (event.key === 'Escape') {
          document.activeElement.blur();
        }
        return;
      }
      switch (event.key) {
        case 'Escape':
          $('.modal.in').modal('hide');
          $('.modal-backdrop.in').remove();
          return;
        case 'e':
          if (this.modifiersPressed(['Meta'])) {
            eventBus.$emit('jumpToModal');
            return;
          }
          return;
      }
    },
    modifiersPressed(modifiers = ['Alt', 'Control', 'Shift', 'Meta']) {
      return modifiers.some((k) => this.keysDown.indexOf(k) >= 0);
    },
    handleKeyUpEvent(event) {
      setTimeout(() => {
        this.keysDown = this.keysDown.filter((k) => k !== event.key);
      }, 150);
    },
    usedShortCut() {
      this.$root.usedShortCut = true;
      clearTimeout(this.usedShortCutTimer);
      this.usedShortCutTimer = setTimeout(() => {
        this.$root.usedShortCut = false;
      }, 2000);
    },
    goToTicket(nodeRelationName = 'nextSibling') {
      this.usedShortCut();
      if (!document.getElementById('ticket-list')) {
        return;
      }
      const currentTicket = document.getElementById('ticket-list').querySelector('.list-item.active');
      if (!currentTicket) {
        // Cannot find current active ticket, click first in list
        document.getElementById('ticket-list').querySelector('.list-item .ticket-button')?.click();
        return;
      }
      // Click next ticket
      currentTicket[nodeRelationName]?.querySelector('.ticket-button')?.click();
    },
  },
};
</script>
<style scoped>
body {
  font-family: Raleway, sans-serif;
  margin: 2rem;
  font-size: 2rem;
}

kbd {
  color: inherit;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.1em 0.5em;
  margin: 0 0.2em;
  box-shadow:
    0 1px 0px rgba(0, 0, 0, 0.2),
    0 0 0 2px #fff inset;
  background-color: #f7f7f7;
}
</style>
