<template>
  <div data-test="message-recieved" class="flex flex-col items-center justify-center">
    <checkbox-circle-linear size="1.75rem" class="text-grey-600" />
    <p class="t-text-md mt-4 text-grey-600">{{ $t('whatsapp.we_recieved_your_reply') }}</p>
  </div>
</template>
<script lang="ts">
import { CheckboxCircleLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import eventBus from '@/eventBus';

import { Steps } from '../../constants';

export default defineComponent({
  name: 'MessageRecieved',
  components: { CheckboxCircleLinear },

  mounted() {
    setTimeout(() => {
      eventBus.$emit('go-to-step', Steps.TRYING_TEMPLATES);
    }, 2400);
  },
});
</script>
