<template>
  <div>
    <div v-if="metrics.length" class="mb-4 flex">
      <div
        v-for="metric in metrics"
        class="
          border-10
          pos-relative
          metric
          mb-4
          mr-4
          flex
          select-none
          overflow-hidden
          rounded-lg rounded-lg
          border border-grey-300
        "
      >
        <div class="flex items-end">
          <div class="flex">
            <div class="flex-shrink-0 px-4 py-4 pt-6">
              <div class="mb-1 text-grey-700">
                {{ $t('reports.' + metric) }}
              </div>
              <div class="flex items-end leading-none">
                <span style="font-size: 40px" class="flex-shrink-0 font-bold leading-none">
                  <span
                    v-if="getModeByMetric(metric) === 'time'"
                    v-text="secondsToString(getAggregatedValue(metric), false)"
                  ></span>
                  <span v-else v-text="numberFormat(getAggregatedValue(metric))"></span>
                </span>
              </div>
            </div>

            <div v-if="showPeriodDifference(metric)" class="ml-auto flex items-end">
              <svg
                v-if="valueIsPositive(metric)"
                width="121"
                height="81"
                viewBox="0 0 121 81"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                :class="{ 'flip-x': metricLowerIsBetter(metric) }"
              >
                <path
                  d="M69.2291 36.7192C48.4654 71.1681 16.4248 81.2601 3 82H128V4.31231C117.061 0.760871 89.9928 2.27023 69.2291 36.7192Z"
                  fill="url(#graph_positive)"
                />
                <path
                  d="M2 83C15.747 82.2414 46.5566 71.8939 67.8186 36.5728C89.0807 1.25175 116.799 -0.295816 128 3.34553"
                  stroke="#249888"
                  stroke-width="3"
                  stroke-linecap="round"
                />
                <defs>
                  <radialGradient
                    id="graph_positive"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(47.6429 48.9354) rotate(45) scale(85.6513 135.524)"
                  >
                    <stop stop-color="#249888" stop-opacity="0.24" />
                    <stop offset="1" stop-color="#249888" stop-opacity="0" />
                  </radialGradient>
                </defs>
              </svg>
              <svg
                v-if="!valueIsPositive(metric)"
                width="124"
                height="81"
                viewBox="0 0 124 81"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                :class="{ 'flip-x': metricLowerIsBetter(metric) }"
              >
                <path
                  d="M58.7709 35.7192C79.5346 70.1681 111.575 80.2601 125 81H0V3.31231C10.9387 -0.239129 38.0072 1.27023 58.7709 35.7192Z"
                  fill="url(#graph_negative)"
                />
                <path
                  d="M125 83C111.253 82.2414 80.4434 71.8939 59.1814 36.5728C37.9193 1.25175 10.2013 -0.295816 -1 3.34553"
                  stroke="#E8374C"
                  stroke-width="3"
                  stroke-linecap="round"
                />
                <defs>
                  <radialGradient
                    id="graph_negative"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(80.3571 18.5) rotate(135) scale(103.743 164.15)"
                  >
                    <stop stop-color="#E8374C" stop-opacity="0.24" />
                    <stop offset="1" stop-color="#E8374C" stop-opacity="0" />
                  </radialGradient>
                </defs>
              </svg>
              <div
                style="
                  position: absolute;
                  top: 20px;
                  right: 20px;
                  background: rgba(255, 255, 255, 0.6);
                  backdrop-filter: blur(24px);
                  border-radius: 12px;
                  color: black;
                  padding: 4px 6px;
                "
              >
                <span v-if="getModeByMetric(metric) === 'time'" v-show="getAggregatedValueDiff(metric) !== 0">
                  <span v-if="getAggregatedValueDiff(metric) < 0">-</span>
                  <span v-else>+</span>
                  <span v-text="secondsToString(getAggregatedValueDiff(metric), false)"></span>
                </span>
                <span v-else>
                  <span v-if="getAggregatedValueDiff(metric) > 0">+</span>
                  <span v-text="numberFormat(getAggregatedValueDiff(metric))"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Util from '../Util/Util';

export default {
  props: {
    metrics: {},
    currentMetric: {},
    currentData: {},
    previousData: {},
  },
  data: function () {
    return {};
  },
  methods: {
    valueIsPositive(metric) {
      if (this.metricLowerIsBetter(metric)) {
        return this.getAggregatedValueDiff(metric) < 0;
      }
      return this.getAggregatedValueDiff(metric) > 0;
    },

    metricLowerIsBetter(metric) {
      return this.getModeByMetric(metric) === 'time' || metric === 'avg_replies_until_resolution';
    },

    showPeriodDifference(metric) {
      let now = parseInt(this.getAggregatedValue(metric, false));
      let prev = parseInt(this.getAggregatedValue(metric, true));

      return now !== 0 && prev !== 0 && now - prev !== 0;
    },

    getAggregatedValue(field, prev = false) {
      if (prev) {
        return this.previousData[field] || 0;
      } else {
        return this.currentData[field] || 0;
      }
    },
    getAggregatedValueDiff(field) {
      let now = parseInt(this.getAggregatedValue(field, false));
      let prev = parseInt(this.getAggregatedValue(field, true));
      if (this.getModeByMetric(field) === 'time' && (now === 0 || prev === 0)) {
        return 0;
      }
      return now - prev;
    },
    secondsToString(seconds, auto_limit = true) {
      return Util.secondsToString(seconds, auto_limit);
    },
    numberFormat(text) {
      if (text === 0) {
        return '-';
      }
      return Util.numberFormat(text);
    },
    getModeByMetric(metric) {
      let time = ['avg_first_reply_time', 'avg_resolution_time'];
      if (time.includes(metric)) {
        return 'time';
      }
      return 'default';
    },
  },
};
</script>

<style scoped lang="scss">
.metric {
  min-width: 200px;
}
</style>
