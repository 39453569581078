<script type="text/babel">
import eventBus from '@/eventBus';

import Avatar from './Avatar';

export default {
  name: 'ConfirmMentionUnauthorizedUsersModal',
  emits: ['accept'],
  components: {
    Avatar,
  },

  data() {
    return {
      unauthorizedUserIds: [],
    };
  },

  mounted() {
    eventBus.$off('modals.confirm-mention.open');
    eventBus.$on('modals.confirm-mention.open', (unauthorizedUserIds) => {
      this.unauthorizedUserIds = unauthorizedUserIds;
      $('#ConfirmMentionUnauthorizedUsersModal').modal();
      // Autofocus on button
      this.$nextTick(() => {
        $('#confirmButton').focus();
      });
    });
  },

  computed: {
    unauthorizedUsers() {
      return this.$root.users.filter((u) => this.unauthorizedUserIds.includes(u.id));
    },
  },

  methods: {
    confirm() {
      $('#ConfirmMentionUnauthorizedUsersModal').modal('hide');
      this.$emit('accept');
    },
  },
};
</script>

<template>
  <div id="ConfirmMentionUnauthorizedUsersModal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('tickets.confirm_mentioning_unauthorized_users') }}</h5>
          <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{ $t('tickets.confirm_mentioning_text') }}</p>
          <ul class="list-unstyled ml-8">
            <li v-for="user in unauthorizedUsers" class="mb-3 flex">
              <avatar :color="user.color" :abbr="user.abbr" :image="user.profile_image" class="mr-3 flex" />
              <div class="relative">
                <div
                  class="absolute m-0"
                  style="
                    width: 250px;
                    margin: 0;
                    top: 50%;
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                  "
                >
                  {{ user.full_name }}
                  <div v-if="user.teams.length" class="text-muted text-ellipsis text-sm">
                    {{ user.teams.map((t) => t.name).join(', ') }}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button id="confirmButton" type="button" class="btn success rounded-full" autofocus @click="confirm">
            {{ $t('general.confirm_action') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
