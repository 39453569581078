<template>
  <div ref="modal" class="modal">
    <div class="modal-centered height-auto">
      <div class="modal-content">
        <div>
          <div class="modal-header">
            <h5 class="modal-title">Activation</h5>
            <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close-btn">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              You will receive an activation code to register a phone number on the WhatsApp network. Make sure your
              phone number is able to receive SMS or Voice.
            </p>
            <div class="form-group row">
              <label class="col-sm-2 form-control-label">Phone number:</label>
              <div class="col-sm-10">
                <p class="form-control-static" v-text="phone"></p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 form-control-label">Method:</label>
              <div class="col-sm-10 pt-2">
                <label>
                  <input v-model="channel.meta.verification_method" type="radio" value="sms" />
                  SMS
                </label>
                <label class="ml-2">
                  <input v-model="channel.meta.verification_method" type="radio" value="voice" />
                  Voice
                </label>
              </div>
            </div>
            <div v-if="channel.meta.verification_code_sent" class="form-group row">
              <label class="col-sm-2 form-control-label">Activation code:</label>
              <div class="col-sm-10">
                <input
                  v-model="channel.meta.verification_code"
                  type="text"
                  autofocus
                  placeholder="Eg: 123456"
                  class="form-control"
                />
                <span v-if="channel.meta.verification_code_sent" class="form-text">
                  Enter your 6 digit verification code
                </span>
              </div>
            </div>
            <div v-if="channel.meta.verification_method === 'voice'" class="form-group row">
              <label class="col-sm-2 form-control-label"></label>
              <div class="col-sm-10">
                <label>
                  <input v-model="terms" type="checkbox" :value="true" />
                  I have verified that this number is able to receive an automated phone call from the US, spam
                  protection is disabled, long welcome greetings are temporarily disabled.
                </label>
              </div>
            </div>
            <div v-if="channel.meta.verification_method === 'sms'" class="form-group row">
              <label class="col-sm-2 form-control-label"></label>
              <div class="col-sm-10">
                <label>
                  <input v-model="terms" type="checkbox" :value="true" />
                  I have verified that this number is able to receive text messages.
                </label>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-2 form-control-label"></label>
              <div class="col-sm-10">
                <button
                  type="button"
                  :disabled="loading || !terms"
                  class="btn success text-white"
                  @click="submit()"
                  v-text="channel.meta.verification_code_sent ? 'Activate' : 'Request code'"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  name: 'WaRegistrationModal',
  props: {
    channel: {
      type: Object,
      default: () => ({}),
    },
    phone: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      loading: false,
      terms: false,
    };
  },

  mounted() {},

  watch: {
    'channel.meta.verification_method'() {
      this.terms = false;
    },
  },

  methods: {
    submit() {
      if (this.loading) return;
      this.loading = true;
      if (this.channel.meta.verification_code_sent) {
        if (this.channel.meta.verification_code.length !== 6) {
          this.loading = false;
          return;
        }
        axios
          .post('/api/v2/wa/activation', {
            channel_id: this.channel.channel_id,
            method: this.channel.meta.verification_method,
            verification_code: this.channel.meta.verification_code,
          })
          .then((res) => {
            this.loading = false;
            this.channel.meta.verified = true;
            this.flashSuccess('You number has been activated successfully.');
            $(this.$refs.modal).modal('hide');
          })
          .catch((res) => {
            this.loading = false;
          });
      } else {
        axios
          .post('/api/v2/wa/activation_code', {
            channel_id: this.channel.channel_id,
            method: this.channel.meta.verification_method,
            code: this.channel.meta.verification_code,
          })
          .then((res) => {
            this.loading = false;
            this.channel.meta.verification_code_sent = true;
            this.flashSuccess('Code sent successfully.');
          })
          .catch((res) => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
