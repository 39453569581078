<template>
  <div class="flex h-full flex-col overflow-auto">
    <div class="mx-6 mb-4 mt-6 flex flex-row items-center">
      <t-thumbnail class="mr-3 bg-white">
        <custom-integration-linear />
      </t-thumbnail>

      <h1 class="t-text-h3 m-0 p-0 text-grey-900">{{ $tc('apps_integration.custom_integrations') }}</h1>

      <t-button class="ml-auto" @click.prevent="$router.push({ name: 'createPlugins' })">
        {{ $tc('integration_hub.add_app') }}
      </t-button>
    </div>

    <div
      v-if="isLoaded && integrations.length > 0"
      class="mx-6 mt-6 grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl3:grid-cols-4"
    >
      <router-link
        v-for="integration in integrations"
        :key="integration.id"
        data-test="integration-card-link-to-custom-integration"
        :to="`/admin/integrations/plugins/${integration.id}`"
      >
        <t-card class="flex h-full flex-col bg-white" :focusable="true">
          <t-thumbnail size="large" class="mb-4">
            <custom-integration-linear />
          </t-thumbnail>

          <h2 class="t-text-md-emphasize m-0 text-ellipsis p-0 text-grey-800">{{ integration.name }}</h2>
          <h3 class="t-text-sm m-0 text-ellipsis p-0 text-grey-600">{{ getTypeText(integration.type) }}</h3>
        </t-card>
      </router-link>
    </div>

    <div v-else-if="isLoaded" class="m-auto flex h-full flex-col items-center justify-center">
      <t-thumbnail class="mb-4 bg-white">
        <custom-integration-linear />
      </t-thumbnail>

      <h2 class="t-text-md-emphasize m-0 mb-2 p-0 text-grey-800">
        {{ $tc('integration_hub.you_dont_have_any_custom_integrations_installed') }}
      </h2>
      <h3 class="t-text-sm m-0 mb-10 p-0 text-grey-700">
        {{ $tc('integration_hub.here_we_showcase_all_the_custom_integrations_you_installed') }}
      </h3>

      <t-button class="mx-auto w-fit" @click.prevent="$router.push({ name: 'createPlugins' })">
        {{ $tc('integration_hub.add_app') }}
      </t-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CustomIntegrationLinear } from '@trengo/trengo-icons';
import axios from 'axios';
import { onMounted, ref } from 'vue';

import { vm } from '@/ApplicationHandler';

import type { BaseCustomIntegration } from '@/store/types/integrations';

const integrations = ref<BaseCustomIntegration[]>([]);
const isLoaded = ref(false);

onMounted(() => {
  axios.get('/api/v2/plugins').then((res) => {
    integrations.value = res.data.data;
    isLoaded.value = true;
  });
});

function getTypeText(type: BaseCustomIntegration['type']) {
  switch (type) {
    case 'bol':
      return vm.$tc('integrations.option_bol');
    case 'custom':
      return vm.$tc('integrations.option_custom_app');
    case 'hubspot':
      return vm.$tc('integrations.option_hubspot');
    case 'lightspeed':
      return vm.$tc('integrations.option_lightspeed');
    case 'magento':
      return vm.$tc('integrations.option_magento_2');
    case 'magento1':
      return vm.$tc('integrations.option_magento_1');
    case 'picqer':
      return vm.$tc('integrations.option_picquer');
    case 'pipedrive':
      return vm.$tc('integrations.option_pipedrive');
    case 'shopify2':
      return vm.$tc('integrations.option_shopify');
    case 'slack':
      return vm.$tc('integrations.option_slack');
    case 'whoocommerce':
      return vm.$tc('integrations.option_woocommerce');
  }
}
</script>
