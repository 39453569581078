<template>
  <div>
    <div class="mb-2 text-xl font-bold leading-7 text-grey-900">{{ $t('whatsapp.good_to_know') }}</div>
    <div class="mb-2">{{ $t('whatsapp.to_create_a_whatsapp_business_account_you_need_the_following') }}</div>
    <div>
      <ul class="mb-10 list-disc">
        <li>{{ $t('whatsapp.the_name_of_your_company_as_visible_to_customers') }}</li>
        <li>{{ $t('whatsapp.the_legal_company_name') }}</li>
        <li>{{ $t('whatsapp.the_companys_official_address') }}</li>
      </ul>
    </div>

    <div class="mb-2 text-xl font-bold leading-7 text-grey-900">
      {{ $t('whatsapp.create_your_whatsapp_business_account') }}
    </div>
    <div class="mb-10">
      {{ $t('whatsapp.redirect_you_to_the_facebook_business_manager_follow_steps') }}
    </div>
    <button
      :aria-label="$t('whatsapp.continue_with_facebook')"
      class="
        btn-fb
        t-text-desktop-label-sm
        inline-flex
        items-center
        px-4
        py-3
        text-grey-100
        transition-all
        duration-300
        ease-linear
      "
      @click="facebookLogin"
    >
      <img :src="`${$root.assetsURL}img/facebook/logo.svg`" alt="facebook" class="mr-2" />
      {{ $t('whatsapp.continue_with_facebook') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'StepFour',
  emits: ['facebookLogin'],
  methods: {
    facebookLogin() {
      this.$emit('facebookLogin');
    },
  },
};
</script>

<style scoped lang="scss">
.btn-fb {
  background: #4267b2;
  border-radius: 5px;
  &:hover {
    background: #385995;
  }
}
</style>
