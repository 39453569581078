import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { "data-test": "trying-templates" }
const _hoisted_2 = {
  key: 0,
  class: "t-text-md mb-6 text-grey-600"
}
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_messages = _resolveComponent("messages")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (_ctx.isMobile)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('whatsapp.templates_success_subheader')), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.backgroundImageSrc,
          class: "mb-4 h-100 w-140 rounded-lg"
        }, null, 8 /* PROPS */, _hoisted_4),
        _createElementVNode("img", {
          src: _ctx.phoneImageSrc,
          class: _normalizeClass(`sm:h-86 absolute bottom-0 left-0 right-0 m-auto sm:w-86 ${_ctx.isMobile && 'h-88 w-80'}`)
        }, null, 10 /* CLASS, PROPS */, _hoisted_5),
        _createVNode(_component_messages, {
          messages: _ctx.messages,
          "wrapper-classes": "absolute\n            left-0\n            right-0\n            bottom-0\n            m-auto\n            flex flex-col\n            overflow-y-auto overflow-x-hidden\n            xs2:px-3 sm:px-5\n            xs2:h-72 xs2:w-72 xs2:pt-8 sm:pt-12\n            sm:h-80 sm:w-79"
        }, null, 8 /* PROPS */, ["messages"])
      ])
    ])
  ]))
}