<template>
  <div class="contents" data-test="stats">
    <stat-row>
      <t-card :focusable="true" class="bg-white">
        <div
          class="flex flex-col items-start"
          @click="handleRedirectToInsightTicketsPage('new_conversation', { statuses: ['OPEN'] })"
        >
          <p class="t-text-md m-0 mb-1 flex flex-row items-center">
            <span class="mr-1">{{ $t('statistics.report_new_tickets') }}</span>
            <t-tooltip :text="$t('reports_v2.new_conversations_tooltip')" position="right">
              <info-fill class="text-grey-600" size="1rem" />
            </t-tooltip>
          </p>

          <div v-if="!isLoaded" class="animation-loading mt-4 h-6 w-20 rounded-3xl bg-grey-300"></div>

          <div v-else class="flex w-full flex-row items-center justify-between">
            <p class="t-text-h1 m-0 mt-1 cursor-pointer">
              <span class="t-text-h1 m-0 mt-1 cursor-pointer">
                {{ newTickets }}
              </span>
            </p>
          </div>
        </div>
      </t-card>
      <t-card :focusable="true" class="bg-white">
        <div
          class="flex flex-col items-start"
          @click="handleRedirectToInsightTicketsPage('assigned_conversation', { statuses: ['ASSIGNED'] })"
        >
          <p class="t-text-md m-0 mb-1 flex flex-row items-center">
            <span class="mr-1">{{ $t('statistics.report_assigned_tickets') }}</span>
            <t-tooltip :text="$t('reports_v2.assigned_conversations_tooltip')" position="top">
              <info-fill class="text-grey-600" size="1rem" />
            </t-tooltip>
          </p>

          <div v-if="!isLoaded" class="animation-loading mt-4 h-6 w-20 rounded-3xl bg-grey-300"></div>

          <div v-else class="flex w-full flex-row items-center justify-between">
            <p class="t-text-h1 m-0 mt-1 cursor-pointer">
              {{ assignedTickets }}
            </p>
          </div>
        </div>
      </t-card>
    </stat-row>
    <stat-row>
      <t-card :focusable="true" class="bg-white">
        <div
          class="flex flex-col items-start"
          @click="handleRedirectToInsightTicketsPage('resolution_time', getStartAndClosedFilters)"
        >
          <p class="t-text-md m-0 mb-1 flex flex-row items-center">
            <span class="mr-1">{{ $t('statistics.report_resolution_time') }}</span>
            <t-tooltip :text="$t('reports_v2.resolution_time_tooltip')" position="right">
              <info-fill class="text-grey-600" size="1rem" />
            </t-tooltip>
          </p>
          <div v-if="!isLoaded" class="animation-loading mt-4 h-6 w-20 rounded-3xl bg-grey-300"></div>

          <div v-else>
            <p class="t-text-xs m-0 mb-1 text-left text-grey-600">
              {{ $t('statistics.average_from_weeks', { weeks: 4 }) }}
            </p>
            <div class="flex w-full flex-row items-center justify-between">
              <p class="t-text-h1 m-0 mt-1 cursor-pointer">{{ resolutionTime }}</p>
            </div>
          </div>
        </div>
      </t-card>
      <t-card :focusable="true" class="bg-white">
        <div
          class="flex flex-col items-start"
          @click="handleRedirectToInsightTicketsPage('first_response_time', getCreatedStartAndClosedFilters)"
        >
          <p class="t-text-md m-0 mb-1 flex flex-row items-center">
            <span class="mr-1">{{ $t('statistics.report_first_response_time') }}</span>
            <t-tooltip :text="$t('reports_v2.first_response_time_tooltip')" position="top">
              <info-fill class="text-grey-600" size="1rem" />
            </t-tooltip>
          </p>
          <div v-if="!isLoaded" class="animation-loading mt-4 h-6 w-20 rounded-3xl bg-grey-300"></div>

          <div v-else>
            <p class="t-text-xs m-0 mb-1 text-grey-600">{{ $t('statistics.average_from_weeks', { weeks: 4 }) }}</p>
            <div class="flex w-full flex-row items-center justify-between">
              <p class="t-text-h1 m-0 mt-1 cursor-pointer">{{ firstResponseTime }}</p>
            </div>
          </div>
        </div>
      </t-card>
    </stat-row>
  </div>
</template>

<script lang="ts">
import { InfoFill } from '@trengo/trengo-icons';
import moment from 'moment';
import { defineComponent } from 'vue';

import StatRow from '@/components/Reporting/Components/V2/StatRow.vue';

export default defineComponent({
  name: 'Stats',
  components: { StatRow, InfoFill },
  props: {
    newTickets: {
      type: Number,
      default: 0,
    },
    assignedTickets: {
      type: Number,
      default: 0,
    },
    resolutionTime: {
      type: String,
      default: '0m 0s',
    },
    firstResponseTime: {
      type: String,
      default: '0m 0s',
    },
    isLoaded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getStartAndClosedFilters() {
      const { encodedStartDate, encodedEndDate } = this.getDatesFromLast4Weeks();

      return {
        closed_start_date: encodedStartDate,
        closed_end_date: encodedEndDate,
      };
    },
    getCreatedStartAndClosedFilters() {
      const { encodedStartDate, encodedEndDate } = this.getDatesFromLast4Weeks();

      return {
        created_start_date: encodedStartDate,
        created_end_date: encodedEndDate,
      };
    },
  },
  methods: {
    getDatesFromLast4Weeks() {
      const startDate = moment()
        .tz(this.$root.user.timezone)
        .subtract(4, 'week')
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ssZ');
      const endDate = moment().tz(this.$root.user.timezone).format('YYYY-MM-DDTHH:mm:ssZ');
      const encodedStartDate = encodeURIComponent(startDate);
      const encodedEndDate = encodeURIComponent(endDate);

      return { encodedStartDate, encodedEndDate };
    },
    handleRedirectToInsightTicketsPage(page: string, options?: { [key: string]: any }) {
      const queryParams = this.$route.query.filters || '""';
      const queryOptions = options || {};
      const combinedFilters = { ...JSON.parse(queryParams), ...queryOptions };
      const filters = JSON.stringify(combinedFilters);

      this.$router.push({
        name: 'reportingTicketInsightsSpecificPage',
        params: { page },
        query: { filters },
      });
    },
  },
});
</script>

<style scoped lang="scss" src="../../../Components/V2/assets/skeleton.scss" />
