export const EVENT_NAME = {
  BILLING_HISTORY: 'billing_history',
  COMMITMENT: 'commitment',
  EXTRA_USAGE: 'extra_usage',
  NOTIFICATIONS: 'notifications',
  RULE_CREATED: 'Rule created',
  SETTINGS_PAGE: 'Settings page',
  SUBSCRIPTION: 'subscription',
  SUBSCRIPTION_CANCEL: 'subscription_cancel',
  SUBSCRIPTION_EDIT: 'subscription_edit',
  TRANSACTION: 'transaction',
} as const;

export const DATA_TRACKING_DEVICE_TYPE = {
  MOBILE: 'Mobile',
  TABLET: 'Tablet',
  DESKTOP: 'Desktop',
} as const;

export const DATA_TRACKING_APP_ACCESS_MEDIUM = {
  APP: 'App',
  BROWSER: 'Browser',
} as const;

export const CATEGORY_NAME = {
  BALANCE: 'balance',
  CONVERSATION: 'conversation',
  CONVERSATIONS: 'conversations',
  PLAN: 'plan',
  TRANSACTION_BREAKDOWN: 'transaction breakdown',
} as const;

export const ACTION_NAME = {
  ADDED: 'added',
  BEGIN: 'begin',
  COMPLETED: 'completed',
  DOWNLOADED: 'downloaded',
  EDIT: 'edit',
  OPEN: 'open',
  PAY_NOW: 'pay_now',
  SELECTED: 'selected',
} as const;
