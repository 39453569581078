<script type="text/babel">
export default {
  data() {
    return {
      loading: false,
      provider: null,
    };
  },

  mounted() {},

  methods: {
    createTwilioAccount() {
      window.open('https://www.twilio.com/try-twilio');
    },
  },

  beforeUnmount() {
    let createEmailModal = $('#CreateSmsModal');
    if (createEmailModal.length) {
      createEmailModal.modal('hide');
    }

    let modalBackdrop = $('.modal-backdrop.in');
    if (modalBackdrop.length) {
      modalBackdrop.remove();
    }
  },
};
</script>
<template>
  <div id="CreateSmsModal" class="modal" role="dialog">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <template v-if="provider !== null">
              <button type="button" class="float-left ml-1 mr-4 mt-1" @click="provider = null">
                <i class="fa fa-arrow-left" style="color: #dae1e7" aria-hidden="true"></i>
              </button>
            </template>
            <h5 class="modal-title">{{ $t('sms.sms_create_new_sms_channel') }}</h5>
            <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div id="email_connect_modal_body" class="modal-body">
            <template v-if="!provider">
              <div class="form-group">
                <label class="control-label">
                  {{ $t('sms.sms_our_sms_platform_is_built_on') }}
                </label>
              </div>
              <div class="form-group">
                <div class="mt-8 flex w-full flex-col items-center justify-center">
                  <router-link
                    tag="button"
                    :to="'/admin/channels2/sms/create?provider=TWILIO'"
                    type="button"
                    active-class=""
                    class="btn email-provider-button shadow-full grey rounded"
                    style="background-color: white; color: #696969"
                    data-dismiss="modal"
                  >
                    <div class="pull-left email-provider-icon rounded">
                      <img
                        :src="`${$root.assetsURL}img/twilio.png`"
                        style="height: 24px; width: 24px"
                        class="rounded"
                      />
                    </div>
                    {{ $t('sms.sms_use_my_own_twilio_account') }}
                  </router-link>
                  <button
                    type="button"
                    class="btn email-provider-button shadow-full grey rounded"
                    style="background-color: white; color: #696969"
                    @click.prevent="createTwilioAccount()"
                  >
                    <div class="pull-left email-provider-icon rounded">
                      <img
                        :src="`${$root.assetsURL}img/twilio.png`"
                        style="height: 24px; width: 24px; filter: grayscale(100%)"
                        class="rounded opacity-50"
                      />
                    </div>
                    {{ $t('sms.sms_i_dont_have_a_twilio_account') }}
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss">
.email-provider-button {
  padding: 13px 0 8px 0;
  margin: 20px auto;
  min-width: 350px;
  display: block;
}
.email-provider-icon {
  height: 30px;
  width: 30px;
  margin-top: -5px;
  margin-left: -7px;
  background: white;
  padding-top: 3px;
}
.email-provider-icon img {
  height: 18px;
  width: 18px;
}
</style>
