<script type="text/babel">
export default {
  props: ['call'],

  watch: {
    'call.status'() {
      if (this.call.isRingingInbound()) {
        this.startRinging();
      } else {
        this.endRinging();
      }
    },
  },

  methods: {
    async startRinging() {
      let audio = this.$refs.inbound;

      if (await this.$tStorage.getItem('voip.rt_device')) {
        audio.setSinkId(await this.$tStorage.getItem('voip.rt_device'));
      }

      audio.play();
    },

    endRinging() {
      this.$refs.inbound.pause();
      this.$refs.inbound.currentTime = 0;
    },
  },
};
</script>

<template>
  <span>
    <audio ref="inbound" loop>
      <source src="https://media.twiliocdn.com/sdk/js/client/sounds/releases/1.0.0/incoming.mp3" type="audio/mpeg" />
    </audio>
    <audio ref="outbound" loop>
      <source src="https://s3.eu-central-1.amazonaws.com/trengo/voip/audio/ringing.mp3" type="audio/mpeg" />
    </audio>
  </span>
</template>
