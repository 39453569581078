<template>
  <t-modal
    v-model="isOpen"
    :title="$t('auth.two_factor_authentication_setup_step_two_title')"
    data-test="two-factor-authentication-setup-step-two"
    variant="narrow"
    @close="handleClose"
  >
    <p class="t-text-md mb-6">
      {{ $t('auth.two_factor_authentication_setup_step_two_description') }}
    </p>
    <t-pin-input
      value=""
      :size="6"
      :error-message="validationMessage"
      :disabled="isVerifying"
      autofocus
      class="mb-6"
      @complete="handleSubmit"
      @input="storeValue"
    />
    <template #footer>
      <t-button size="md" btn-style="secondary" class="md:mr-auto" @click="handleBack">
        {{ $t('general.back') }}
      </t-button>
      <t-button size="md" btn-style="secondary" @click="handleClose">
        {{ $t('general.cancel') }}
      </t-button>
      <t-button size="md" :disabled="isVerifying || isInvalid" @click="handleContinue">
        {{
          isVerifying
            ? $t('auth.two_factor_authentication_setup_step_two_is_verifying_label')
            : $t('auth.two_factor_authentication_setup_step_two_verify_label')
        }}
      </t-button>
    </template>
  </t-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';

import { sendVerificationCode } from '../api';
import { SETUP_STEP } from '../constants';

import type { StepTwoData } from '../types';

export default defineComponent({
  name: 'TwoFactorAuthSetupStepTwo',
  emits: ['close', 'goToStep'],
  props: {
    isOpen: Boolean,
  },
  data(): StepTwoData {
    return {
      otp: null,
      isVerifying: false,
      isInvalid: false,
      errorMessage: '',
    };
  },
  computed: {
    validationMessage() {
      return this.isInvalid ? this.errorMessage : '';
    },
  },
  watch: {
    otp(_value) {
      this.isInvalid = false;
    },
  },
  methods: {
    ...mapMutations({
      setUserHas2FAEnabled: 'initialData/setUserHas2FAEnabled',
    }),
    handleBack() {
      this.$emit('goToStep', SETUP_STEP.ONE);
    },
    storeValue(value: string) {
      this.otp = value;
    },
    handleContinue() {
      this.handleSubmit(this.otp);
    },
    async handleSubmit(value: string | null) {
      if (this.isInvalid) return;
      this.isVerifying = true;
      try {
        await sendVerificationCode(value);
        this.setUserHas2FAEnabled(true);
        this.$emit('goToStep', SETUP_STEP.THREE);
      } catch (err) {
        this.isInvalid = true;
        this.errorMessage = err?.response?.data?.message || '';
      } finally {
        this.isVerifying = false;
      }
    },
    handleClose() {
      this.$emit('close');
    },
  },
});
</script>
