import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_integration_card_icon = _resolveComponent("integration-card-icon")!
  const _component_plus_linear = _resolveComponent("plus-linear")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_install_modal = _resolveComponent("install-modal")!
  const _component_installed_modal = _resolveComponent("installed-modal")!
  const _component_installation_failed_modal = _resolveComponent("installation-failed-modal")!
  const _component_t_tab_item = _resolveComponent("t-tab-item")!
  const _component_t_badge = _resolveComponent("t-badge")!
  const _component_main_header = _resolveComponent("main-header")!

  return (_ctx.integration)
    ? (_openBlock(), _createBlock(_component_main_header, {
        key: 0,
        class: "border-b-1 border-grey-300 bg-white",
        title: _ctx.integration.name,
        width: "66.67%"
      }, _createSlots({
        left: _withCtx(() => [
          _createVNode(_component_integration_card_icon, {
            class: "rounded-lg border-1 border-grey-300 border-opacity-10 bg-white p-1",
            icon: _ctx.integration.icon
          }, null, 8 /* PROPS */, ["icon"])
        ]),
        right: _withCtx(() => [
          (_ctx.showButton)
            ? (_openBlock(), _createBlock(_component_t_button, {
                key: 0,
                "btn-style": _ctx.hasInstallations ? 'secondary' : 'primary',
                "data-test": "install-integration-button",
                class: "flex flex-row items-center",
                onClick: _ctx.showInstallModal
              }, {
                default: _withCtx(() => [
                  (_ctx.hasInstallations)
                    ? (_openBlock(), _createBlock(_component_plus_linear, {
                        key: 0,
                        size: "1.5rem",
                        class: "mr-1"
                      }))
                    : _createCommentVNode("v-if", true),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('integration_hub.install_integration')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["btn-style", "onClick"]))
            : _createCommentVNode("v-if", true),
          _withDirectives(_createVNode(_component_install_modal, {
            "is-open": _ctx.isInstallModalOpen,
            onClose: _ctx.showModal
          }, null, 8 /* PROPS */, ["is-open", "onClose"]), [
            [_vShow, _ctx.isInstallModalOpen]
          ]),
          _createVNode(_component_installed_modal, {
            "is-open": _ctx.isInstalledModalOpen,
            integration: _ctx.integration,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isInstalledModalOpen = false))
          }, null, 8 /* PROPS */, ["is-open", "integration"]),
          _createVNode(_component_installation_failed_modal, {
            "is-open": _ctx.isFailedModalOpen,
            integration: _ctx.integration,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFailedModalOpen = false))
          }, null, 8 /* PROPS */, ["is-open", "integration"])
        ]),
        _: 2 /* DYNAMIC */
      }, [
        (_ctx.showTabs)
          ? {
              name: "bottom",
              fn: _withCtx(() => [
                _createVNode(_component_t_tab_item, {
                  label: _ctx.$t('integration_hub.description'),
                  "is-active": _ctx.page === 'description',
                  size: "sm",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setPage('description')))
                }, null, 8 /* PROPS */, ["label", "is-active"]),
                (_ctx.integration.installations)
                  ? (_openBlock(), _createBlock(_component_t_tab_item, {
                      key: 0,
                      label: _ctx.$t('integration_hub.installed'),
                      "is-active": _ctx.page === 'installed',
                      size: "sm",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setPage('installed')))
                    }, {
                      postfix: _withCtx(() => [
                        _createVNode(_component_t_badge, {
                          text: _ctx.integration.installations.length
                        }, null, 8 /* PROPS */, ["text"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label", "is-active"]))
                  : _createCommentVNode("v-if", true)
              ]),
              key: "0"
            }
          : undefined
      ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["title"]))
    : _createCommentVNode("v-if", true)
}